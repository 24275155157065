<template>
  <div>
    <steps :active="0" :steps="steps"></steps>
    <related-products @next="nextStep" v-model="goodsList"></related-products>
    <div class="step-btn">
      <el-button type="primary" @click="nextStep">完成，下一步</el-button>
    </div>
  </div>
</template>

<script>
import steps from "COMPS/widgets/steps";
import relatedProducts from "../relatedProducts";

export default {
  data() {
    return {
      steps: [
        {
          name: "关联商品"
        },
        {
          name: "基本信息"
        },
        {
          name: "设置"
        }
      ],
      goodsList: []
    };
  },
  components: { steps, relatedProducts },
  mounted() {
    if (this.$store.state.virtualGoods.relatedProducts.length) {
      this.goodsList = this.$store.state.virtualGoods.relatedProducts;
    } else {
      if (this.$route.params.id) {
        this.getGoodsInfo();
      }
    }
  },
  methods: {
    nextStep() {
      if (!this.goodsList.length) {
        return this.$message({
          message: "请先关联商品",
          type: "warning"
        });
      }
      this.$store.commit("savePhysicalGoods_relatedProducts", this.goodsList);
      this.$router.push("/dim/fillInVirtualGoodsInfo");
    }
  }
};
</script>

<style lang="scss" scoped>
.step-btn {
  margin-top: 40px;
  text-align: center;
}
</style>
