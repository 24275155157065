<template>
    <div>
        <el-card>
            <el-steps :active="active+1" align-center style="margin-bottom: 20px;">
                <el-step title="审核业务" description="配置审核编码,业务审核说明"></el-step>
                <el-step title="审核版本" description="版本,是否启用"> </el-step>
                <el-step title="审核节点" description="审核节点,顺序"> </el-step>
                <el-step title="审核角色" description="审核角色,审核人员"> </el-step>
                <!--<el-step title="审核人员" description="角色人员"></el-step>-->
            </el-steps>
            <template v-if="active==0">
                 <audit-configuration-list ref="configList"></audit-configuration-list>
            </template>
            <template v-if="active==1">
                <audit-version-configuration-list :config-info="configInfo"  ref="versionConfigList"></audit-version-configuration-list>
            </template>
            <template v-if="active==2">
                <audit-node-configuration-list :version-info="versionInfo"  ref="nodeConfigList"></audit-node-configuration-list>
            </template>
            <template v-if="active==3">
                <audit-node-role-configuration-list :node-info="nodeInfo" ref="roleConfigList"></audit-node-role-configuration-list>
            </template>
            <!--<template v-if="active==4">
                <audit-node-person-configuration-list :role-info="nodeRoleInfo"  ref="personConfigList"></audit-node-person-configuration-list>
            </template>-->

            <div style="text-align: center;margin-top: 20px;">
                <el-button @click.native="previous" v-if="active!=0">上一步</el-button>
                <el-button  type="primary" @click.native="next">{{nextStepName}}</el-button>
            </div>

        </el-card>

    </div>
</template>

<script>
    import Objects from "COMJS/Objects";
    import auditConfigurationList from './auditConfigurationList.vue';
    import auditVersionConfigurationList from './auditVersionConfigurationList.vue';
    import auditNodeConfigurationList from './auditNodeConfigurationList.vue';
    import auditNodeRoleConfigurationList from './auditNodeRoleConfigurationList.vue';
    //import auditNodePersonConfigurationList from './auditNodePersonConfigurationList.vue';

    export default {
        name: "auditManager",
        components:{
            //listView: SimpleListView,
            //pageTable: PageTable,
            auditConfigurationList: auditConfigurationList,
          auditVersionConfigurationList: auditVersionConfigurationList,
          auditNodeConfigurationList: auditNodeConfigurationList,
          auditNodeRoleConfigurationList: auditNodeRoleConfigurationList,
          //auditNodePersonConfigurationList: auditNodePersonConfigurationList
        },
        data() {
            return {
                configId:'',
                configInfo:{},
                versionId:'',
                versionInfo:{},
                nodeId:'',
                nodeInfo:{},
                nodeRoleId:'',
                nodeRoleInfo:{},
                active: 0,

            };
        },
        methods: {
            next() {
                let nextFlag = false;
                switch (this.active) {
                    case 0:
                        let configList = this.$refs.configList.$refs.auditConfigurationPageTable.getCheckedData();
                        if(Objects.isNotEmpty(configList)&&configList.length===1){
                            nextFlag = true;
                            //this.configId  = configList[0].auditConfigurationId;
                            this.configInfo = configList[0];
                        }else{
                            nextFlag = false;
                        }
                        break;
                    case 1:
                        let versonConfigList = this.$refs.versionConfigList.$refs.auditVersionConfigurationPageTable.getCheckedData();
                        if(Objects.isNotEmpty(versonConfigList)&&versonConfigList.length===1){
                            nextFlag = true;
                            //this.versionId  = versonConfigList[0].auditVersionConfigurationId;
                            this.versionInfo = versonConfigList[0];
                        }else{
                            nextFlag = false;
                        }
                        break;
                    case 2:
                        let nodeConfigList = this.$refs.nodeConfigList.$refs.auditNodeConfigurationPageTable.getCheckedData();
                        if(Objects.isNotEmpty(nodeConfigList)&&nodeConfigList.length===1){
                            nextFlag = true;
                            //this.nodeId  = nodeConfigList[0].auditNodeConfigurationId;
                            this.nodeInfo = nodeConfigList[0];
                        }else{
                            nextFlag = false;
                        }
                        break;
                    case 3:
                        /*let nodeRoleConfigList = this.$refs.nodeRoleConfigList.$refs.auditNodeRoleConfigurationPageTable.getCheckedData();
                        if(Objects.isNotEmpty(nodeRoleConfigList)&&nodeRoleConfigList.length===1){
                            nextFlag = true;
                            //this.nodeId  = nodeConfigList[0].auditNodeConfigurationId;
                            this.nodeRoleInfo = nodeRoleConfigList[0];
                        }else{
                            nextFlag = false;
                        }*/
                        nextFlag =true;
                        break;
                    case 4:
                        return true;
                        break;
                    default:
                        return false;
                        break;
                }
                if(nextFlag){
                    this.active = this.active+1;
                    if (this.active> 3) this.active = 0;
                }else{
                    this.$message({
                        message: '请选择一条审核配置记录,做下一步操作!',
                        type: 'warning'
                    });
                }
            },
            previous() {
                switch (this.active) {
                    case 0:
                        return false;
                        break;
                    case 1:
                        //return true;
                        break;
                    case 2:
                        //return true;
                        break;
                    case 3:
                        //return true;
                        break;
                    case 4:
                        //return true;
                        break;
                    default:
                        //return true;
                        break;
                }
                this.active = this.active-1;
                if (this.active<0) this.active = 0;
            }
        },
        computed:{
            nextStepName(){
                let name  ='下一步';
                if(this.active===3){
                    name = '完成';
                }else{
                    name  ='下一步';
                }
                return name;
            }
        }
    }
</script>

<style scoped>

</style>
