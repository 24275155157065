<template>
  <div class="productRelate-edit">
    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">关联信息</div>
      <div class="form-item">
        <span class="form-label">商品编码：</span>
        <span class="form-value">{{ goodsRelate.goodsCode }}</span>
      </div>
      <div class="form-item">
        <span class="form-label">商品状态：</span>
        <span class="form-value">{{ goodsRelate.goodsName }}</span>
      </div>
      <div class="form-item">
        <span class="form-label">商品关联库品</span>
      </div>
      <div class="form-item">
        <span class="form-label"
          ><el-button @click="showDialog">添加库品</el-button></span
        >
      </div>
      <div>
        <el-table :data="skuNums" fit>
          <el-table-column label="库品编码" prop="skuCode"></el-table-column>
          <el-table-column label="库品名称" prop="skuName"></el-table-column>
          <el-table-column label="库品数量" width="70">
            <template slot-scope="scope">
              <el-input v-model="scope.row.num" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="70">
            <template slot-scope="scope">
              <el-popconfirm
                style="margin-left: 0"
                title="确定删除吗？"
                @confirm="delRelateArr(scope.$index)"
              >
                <el-button>删除</el-button>
                <a slot="reference" href="javascript: void(0);" title="删除">
                  <i class="el-icon-delete-solid"></i>
                </a>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button type="primary" class="submit-btn" @click="saveRelate"
        >确定</el-button
      >
    </div>

    <el-dialog title="选择内容库品" :visible.sync="dialogVisible" width="50%">
      <div>
        <span class="search-wrapper" style="display: flex">
          <el-input
            size="mini"
            v-model="searchInfo.skuName"
            placeholder="请输入库品名称"
          ></el-input>
          <el-button
            class="search-btn"
            size="mini"
            type="primary"
            @click="searchData"
            >搜索</el-button
          >
        </span>
        <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          selection="multi"
          @pageChange="loadTableData"
          :showIndex="false"
        >
          <el-table-column
            prop="skuName"
            label="库品名称"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="skuCode"
            label="编码"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </ws-page-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="pushRelate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import relateQuery from "API/whm/relateQuery";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import whmList from "API/whm/WhmList";

export default {
  data() {
    return {
      goodsRelate: {
        goodsRelateInfo: {},
      },
      skuNums: [],
      dialogVisible: false,
      searchInfo: {
        skuCode: "",
        skuName: "",
      },
      submitRelate: {
        goodsId: "",
        goodsName: "",
        goodsCode: "",
        skuNum: "",
      },
    };
  },
  mounted() {
    this.getGoodsRelateDetail();
  },
  mixins: [pageTableMixin],
  methods: {
    getGoodsRelateDetail() {
      relateQuery
        .queryProductRelateDetail(this.$route.params.id)
        .then((data) => {
          this.goodsRelate = data;
          if (this.goodsRelate.skus !== null) {
            this.skuNums = this.goodsRelate.skus;
          } else {
            this.skuNums = [];
          }
        });
    },
    // 拼接商品关联库品字符串
    spliceRelate() {
      var tmp = "";
      for (var i = 0; i < this.skuNums.length; i++) {
        tmp = this.skuNums[i].skuId + "*" + this.skuNums[i].num;
        if (i === 0) {
          this.submitRelate.skuNum = tmp;
        } else {
          this.submitRelate.skuNum = this.submitRelate.skuNum + "+" + tmp;
        }
        tmp = "";
      }
    },
    showDialog() {
      this.dialogVisible = true;
    },
    loadTableData() {
      whmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        data.list = data.list.map((e) => {
          if (!e.goodsInfo) {
            e.goodsInfo = {};
          }
          return {
            id: e.skuId,
            skuName: e.skuName,
            skuCode: e.skuCode,
          };
        });
        this.querySuccess(data);
      });
    },
    pushRelate() {
      let list = this.$refs.pageTable.getCheckedData();
      for (var i = 0; i < list.length; i++) {
        this.skuNums.push({
          skuId: list[i].id,
          skuName: list[i].skuName,
          skuCode: list[i].skuCode,
          num: "",
        });
      }
      this.$refs.pageTable.selectedRows = [];
      this.$refs.pageTable.setPageNo(this.$refs.pageTable.pageInfo.pageNo);
      this.loadTableData();
      this.dialogVisible = false;
    },
    delRelateArr(index) {
      this.skuNums.splice(index, 1);
    },
    inputJudge(str) {
      var r = /^\+?[1-9][0-9]*$/;
      if (str !== "" && r.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    saveRelate() {
      var that = this;
      this.submitRelate.goodsId = this.goodsRelate.goodsId;
      this.submitRelate.goodsName = this.goodsRelate.goodsName;
      this.submitRelate.goodsCode = this.goodsRelate.goodsCode;
      if (this.skuNums.length !== 0) {
        var isInput = true;
        for (var i = 0; i < this.skuNums.length; i++) {
          if (this.inputJudge(this.skuNums[i].num)) {
            isInput = true;
          } else {
            isInput = false;
          }
        }
        if (isInput) {
          this.spliceRelate();
          const loading = this.$loading({
            lock: true,
            text: "保存中...",
          });
          relateQuery
            .saveProductRelateDetail(this.submitRelate)
            .then((res) => {
              if (res.msg == "OK") {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                // that.$refs.pageTable.setPage();
                that.loadTableData();
                setTimeout(function () {
                  that.$router.push("/whm/productRelate");
                }, 300);
              }
              loading.close();
            })
            .catch(() => {
              loading.close();
            });
        } else {
          this.$message.error("请输入正确的要关联的库品数量");
        }
      } else {
        this.submitRelate.skuNum = "";
        const loading = this.$loading({
          lock: true,
          text: "保存中...",
        });
        relateQuery
          .saveProductRelateDetail(this.submitRelate)
          .then((res) => {
            if (res.msg == "OK") {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            setTimeout(function () {
              that.$router.push("/whm/productRelate");
            }, 300);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 82px;
  height: 82px;
}

.productRelate-edit {
  .detail-item {
    margin-bottom: 40px;
    .submit-btn {
      margin: 20px;
      float: right;
    }
    .detail-item_title {
      line-height: 60px;
      font-weight: bold;
      font-size: 18px;
      padding-left: 20px;
      background-color: #f6f9fc;
      margin-bottom: 20px;
    }
    .form-item {
      margin-bottom: 20px;
      margin-left: 20px;
      &:last-of-type {
        margin-bottom: 0px;
      }
      .form-label {
        display: inline-block;
        text-align: left;
        width: 100px;
      }
      .form-value {
        color: #666;
      }
    }
  }
}
</style>
