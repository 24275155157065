<template>
    <div id="addImport">
        <div class="item">
            <div class="title">{{ToView?'查看导入详情':'新增导入'}}</div>
        </div>
        <div class="item">
            <div class="item-label">开放时间：</div>
            <el-date-picker style="width:20%" v-model="Data.beginTime" :disabled='ToView' type="datetime" @change='getBeginTime($event)' placeholder="选择时间"></el-date-picker>
            <div style="margin:0px 13px">~</div>
            <el-date-picker style="width:23%" v-model="Data.endTime" disabled type="datetime" placeholder="选择时间"></el-date-picker>
        </div>
        <div class="item">
            <div class="item-label">申请渠道：</div>
            <el-input v-model="Data.operationField" placeholder="请输入申请渠道" style="width:20%" :disabled='ToView'></el-input>
        </div>
        <div class="item">
            <div class="item-label">申请开放商品：</div>
            <el-button type="primary" @click="addGoods" :disabled='ToView'>添加商品</el-button>
        </div>

        <ws-page-table :data="Data.goodsInfo" ref="pageTable" style="width:100%;margin-top:30px" :pageInfo="pageInfo" @pageChange="loadTableData">
            <el-table-column label="商品名称" prop="goodsName">
                <template slot-scope="scope">
                    <el-input style="width:70%" maxlength="25" placeholder="请输入商品名称" v-model="scope.row.goodsName" :disabled='ToView'></el-input>
                </template>
            </el-table-column>
            <el-table-column label="到手价格（元）" prop="price">
                <template slot-scope="scope">
                    <el-input style="width:70%" placeholder="请输入价格" oninput="value=value.replace(/[^0-9.]/g,'')" maxlength="10" v-model="scope.row.price" :disabled='ToView'></el-input>
                </template>
            </el-table-column>
            <el-table-column label="预计数量" prop="count">
                <template slot-scope="scope">
                    <el-input style="width:70%" placeholder="请输入数量" oninput="value=value.replace(/[^0-9.]/g,'')" maxlength="10" v-model="scope.row.count" :disabled='ToView'></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" prop="createTime" v-if="!ToView">
                <template slot-scope="scope">
                    <span style="color: rgba(24, 144, 255, 1);cursor: pointer;" @click="delGoods(scope.$index)">删除</span>
                </template>
            </el-table-column>
        </ws-page-table>
        <div class="botton" v-if="!ToView">
            <el-button type="danger" @click="back" plain>取消申请</el-button>
            <el-button type="primary" @click="submit">提交申请</el-button>
        </div>
        <div class="botton" v-else>
            <el-button @click="back">返回列表</el-button>
        </div>
    </div>
</template>

<script>
let moment = require("moment");
import pageTableMixin from "@/mixin/pageTableMixin.js";
import importPermissionService from "API/som/importPermissionService";
export default {
    data() {
        return {
            Data: {
                beginTime: "",
                endTime: "",
                operationField: "",
                goodsInfo: [],
            },
            ToView:false
        };
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {
            let authorizationId = this.$route.query.authorizationId;
            if (authorizationId) {
                importPermissionService
                    .importApplyDetail(authorizationId)
                    .then((data) => {
                        this.Data=data;
                        this.ToView=true;
                    });
            }
        },
        getBeginTime(e) {
            this.Data.endTime = moment(e).valueOf() + 3600000;
        },
        addGoods() {
            let params = {
                goodsName: "",
                price: "",
                count: "",
            };
            this.Data.goodsInfo.push(params);
        },
        delGoods(index) {
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.Data.goodsInfo.splice(index, 1);
            });
        },
        back() {
            this.$router.go(-1);
        },
        submit() {
            if (this.Data.beginTime == "") {
                return this.$message({
                    message: "请选择开放时间",
                    type: "warning",
                });
            }
            if (this.Data.operationField == "") {
                return this.$message({
                    message: "请输入申请渠道",
                    type: "warning",
                });
            }
            if (this.Data.goodsInfo && this.Data.goodsInfo.length == 0) {
                return this.$message({
                    message: "必须存在一条商品记录",
                    type: "warning",
                });
            }
            for (let i in this.Data.goodsInfo) {
                if (this.Data.goodsInfo[i].goodsName == "") {
                    return this.$message({
                        message: "商品记录不完整",
                        type: "warning",
                    });
                }
                if (this.Data.goodsInfo[i].price == "") {
                    return this.$message({
                        message: "商品记录不完整",
                        type: "warning",
                    });
                }
                if (this.Data.goodsInfo[i].count == "") {
                    return this.$message({
                        message: "商品记录不完整",
                        type: "warning",
                    });
                }
            }
            let params = {
                beginTime: moment(this.Data.beginTime).valueOf(),
                operationField: this.Data.operationField,
                goodsInfo: this.Data.goodsInfo,
            };
            importPermissionService.createImportApply(params).then((data) => {
                this.$message({ type: "success", message: "提交成功" });
                this.$router.push({
                    path: "/som/importPermission",
                });
            });
        },
    },
};
</script>

<style scoped>
#addImport {
    background: rgba(255, 255, 255, 1);
    padding: 23px 28px;
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 62px;
    width: 100%;
}
.title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
.item-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 22px;
    margin-right: 12px;
}
.botton {
    height: 42px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 50px;
}
</style>