<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="showAdd">新增</el-button>
        <span class="search-wrapper">
          <el-input size="mini" v-model="searchInfo.keyword" placeholder="请输入枚举类型名称"></el-input>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </span>
      </div>
    </div>
    <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
    >
      <el-table-column prop="typeName" label="枚举类型名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="枚举类型" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="num" label="枚举数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="showDetail(scope.row.type,scope.row.typeName)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" title="编辑" @click="showEdit(scope.row.type,scope.row.typeName)">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <!--<a href="javascript: void(0);" title="删除">-->
            <!--<i class="el-icon-delete-solid" @click="deleteBrand(scope.row)"></i>-->
            <!--</a>-->
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <enum-add-popup ref="enumAddPopup" @updateTable="loadTableData" :type2="currentType" :typeName2="currentTypeName"></enum-add-popup>
    <enum-detail-popup ref="enumDetailPopup" :type2="currentType" :typeName2="currentTypeName"></enum-detail-popup>
  </div>
</template>

<script>
    import Paging from "../../../js/query/Paging";
    import enumAddPopup from "./enumAddPopup.vue";
    import BimEnumerationInfo from "API/bim/BimEnumerationInfo";
    import pageTableMixin from "@/mixin/pageTableMixin";
    import enumDetailPopup from "./enumDetailPopup.vue";

    export default {
        name: "enumList",
        data() {
            return {
                searchInfo: {
                    keyword: ""
                },
                currentType: "",
                currentTypeName: ""
            };
        },
        mixins: [pageTableMixin],
        components: {
            enumAddPopup,
            enumDetailPopup
        },
        mounted() {},
        methods: {
            loadTableData() {
                this.$refs.pageTable.tableLoading = true;
                BimEnumerationInfo
                    .queryData(this.pageInfo, this.searchInfo.keyword)
                    .then(data => {
                    this.querySuccess(data);

            });
            },
            deleteParam(row) {
                this.$confirm(`是否删除"${row.cnName}"参数?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                    BimParamService.deleteParam(row.paramId).then(() => {
                    this.loadTableData();
            });
            }).catch(() => {});
            },
            showAdd() {
                this.currentType = "";
                this.currentTypeName = "";
                this.$refs.enumAddPopup.dialogFormVisible = true;
            },
            showDetail(tpye,typeName) {
                this.currentType = tpye;
                this.currentTypeName = typeName;
                this.$refs.enumDetailPopup.dialogVisible = true;
            },
            showEdit(tpye,typeName) {
                this.currentType = tpye;
                this.currentTypeName = typeName;
                this.$refs.enumAddPopup.dialogFormVisible = true;
            },
        },
        computed: {}
    };
</script>
