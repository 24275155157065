// import { resolve } from 'core-js/fn/promise'

const frame = {template: '<router-view></router-view>'}

export default {
    path: 'dea',
    component: frame,
    children: [
        {
            name: '经销商管理',
            path: 'dealerList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/dealerList.vue')
        },
        {
            name: '经销商管理',
            path: 'dealerEdit/:id',
            meta: {noKeepAlive: false},
            component: resolve => import('PAGES/dea/dealerEdit.vue')
        },
        {
            name: '经销商详情',
            path: 'dealerDetail/:id',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/dealerDetail.vue')
        },
        {
            name: '经销商账户明细',
            path: 'dealerAccountDetail',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/dealerAccountDetail.vue')
        },
        {
            name: '经销商充值记录',
            path: 'dealerRechargeList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/dealerRechargeList.vue')
        },
        {
            name: '经销商提现记录',
            path: 'dealerWithdrawList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/dealerWithdrawList.vue')
        },
        {
            name: '物流收货',
            path: 'freightList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/freightList.vue')
        },
        {
            name: '经销商账单审核',
            path: 'billReview',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/billReview.vue')
        },
        {
            name: '经销商充值审核',
            path: 'rechargeAudit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/rechargeAudit.vue')
        },
        {
            name: '经销商提现审核',
            path: 'depositWithdrawAudit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/depositWithdrawAudit.vue')
        },
        {
            name: '信用账单',
            path: 'billManage',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/billManage.vue')
        },
        {
            name: '授权管理',
            path: 'authManager',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/dea/dealerAuthList.vue')
        },
    ]
}