<template>
  <div>
    <el-form :model="param" label-width="110px" class="step-form">
      <div class="step-wrapper">
        <div class="step-title" style="display: flex;">
          <div>序列号扫码解绑</div>
        </div>

        <div class="tips" style="color: red;">=======================开始扫描=======================</div>
        <el-form-item label="扫描或输入序列：">
          <el-input v-model="tempStr" @change="addToSet" maxlength="36"></el-input>
          <div class="tips" style="color: red;">扫描时请确保光标在本输入框内</div>
        </el-form-item>
        <el-form-item label="已扫描序列：">
          <el-tag
              v-for="tag in numSet.keys()"
              closable
              :key="tag"
              :type="'info'"
              @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <!--
          <div class="tips" style="color: red;">扫描时请确保光标不在任何输入框内</div>
          -->
        </el-form-item>
        <el-form-item label="本次解绑数量：">
          <div class="tips">{{ numSet.size }}</div>
        </el-form-item>
      </div>
    </el-form>
    <div class="steps-btn-wrapper">
      <el-button type="primary" @click="removeBind">确定解绑</el-button>
    </div>

  </div>
</template>

<script>
import Set from "COMJS/Set";
import dealerService from "API/dea/dealerService.js";
import dealerSerialInfo from "API/whm/dealerSerialInfo.js";
import selectSkuPopup from "../inventory/selectSkuPopup";
import selectSkuBatchPopup from "../inventory/selectSkuBatchPopup";

export default {
  name: "serialNumOutboundRemove",
  data() {
    return {
      tempStr:'',
      numSet: new Set()
    };
  },
  mounted() {

  },
  methods: {
    addToSet(value){
      if(value.length>36){
        this.$message({
          message: "序列号不能长于36位",
          type: "warning"
        })
        return;
      }

      this.numSet.add(value);
      this.tempStr = '';
    },
    handleClose(tag) {
      this.numSet.delete(tag);
    },
    removeBind() {
      if (this.numSet.size == 0) {
        this.$message({
          message: "请扫描录入至少一个序列号",
          type: "warning"
        })
        return;
      }
      dealerSerialInfo.delSerialNumber(this.numSet.keys()).then(data => {
        this.$message({message: "解绑成功",type:"success"})
        this.$router.push(`/whm/outbound/serialNumOutboundList`)
      }).catch(e=>{
        console.log(e);
        this.$message({message: "解绑失败",type:"fail"})
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~ASSETS/css/components/productAdd.scss";

.el-inline-radio /deep/ .el-radio {
  display: inline-block;
}

.product-add .is-in-pagination .el-input__inner {
  width: 60px;
}
</style>
