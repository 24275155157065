import axiosHttp from "../axiosHttp";

export const afterHandle = data=>{
    return axiosHttp.POST('/afterSale/afterHandle',data,false)
}

export const applyAfterSale = data=>{
    return axiosHttp.POST('/afterSale/applyAfterSale',data,false)
}

export const queryAuditsByCode = data=>{
    return axiosHttp.POST('/auditShow/queryAuditsByCode',data,false)
}
export const findAuditDetailsById = data=>{
    return axiosHttp.POST('/auditShow/findAuditDetailsById',data,false)
}
export const auditHandle = data=>{
    return axiosHttp.POST('/baAudit/auditHandle',data,false)
}


export const listAuditsByCodeCommon = data=>{
    return axiosHttp.POST('/auditShow/listAuditsByCodeCommon',data,false)
}
export const queryAuditsByIdCommon = data=>{
    return axiosHttp.POST('/auditShow/queryAuditsByIdCommon',data,false)
}
export const auditFlowHandle = param=>{
    return axiosHttp.POST('/baAudit/auditHandleNew',param,false);
}