<template>
  <div id="confirmTheRefundPop">
    <el-dialog title="确认退款" :visible.sync="dialogVisible" width="30%">
      <el-row class="row-item" type="flex" align="middle">
        售后金额：￥{{currentData.order.totalAmount?currentData.order.totalAmount.toFixed(2):0}}
      </el-row>
      <el-row class="row-item" type="flex" align="middle">
        客服确认退款：￥{{currentData.after.handleTotalAmount?Number(currentData.after.handleTotalAmount).toFixed(2):0}}
      </el-row>
      <el-row class="row-item" type="flex" align="middle">
        客服备注：{{currentData.after.remark}}
      </el-row>
      <el-row class="row-item" type="flex" align="middle">
        <el-col :span="4">实际退款：</el-col>
        <el-col :span="5">
          <el-input type="number" v-model="auditInMoney" placeholder="实际退款" @input="thisMaxValue(currentData.order.totalAmount)"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-checkbox v-model="operateState">退款的同时追回给会员的奖励</el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitReturn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import chargeFormat from "COMJS/chargeFormat";
import { auditHandle } from "API/som/checkService.js";
export default {
  data() {
    return {
      dialogVisible: false,
      operateState: true,
      currentData: {
        order: {},
        after: {},
      },
      businessAuditLogId: "",
      auditInMoney: "",
    };
  },
  methods: {
    getcurrentData(data) {
      console.log(data);
      this.currentData = data.currentData;
      this.businessAuditLogId = data.businessAuditLogId;
      this.auditInMoney = data.currentData.after.handleTotalAmount;
    },
    commitReturn(i) {
      if (!this.auditInMoney && this.auditInMoney!=0) {
        //只限制放空的情况，要允许人家填0
        return this.$message.warning("请填写实际退款");
      }
      let loading = this.$loading({
        lock: true,
        text: "提交中...",
      });
      try {
        let params = {
          userId: JSON.parse(userModule.PPUser).userId,
          businessAuditLogId: this.businessAuditLogId,
          auditResult: "success",
          afterInMoney: this.currentData.after.handleTotalAmount,
          operateState: this.operateState ? "1" : "0",
          auditInMoney: this.auditInMoney,
        };
        auditHandle(params).then((data) => {
          //console.log(data);
          loading.close();
          this.$emit("auditOk", "");
          this.dialogVisible = false;
          Object.assign(this.$data, this.$options.data());
        }).catch(e=>{
          loading.close();
        });
      } catch (error) {
        loading.close();
        //this.$emit("auditOk", "");
        //this.dialogVisible = false;
        //Object.assign(this.$data, this.$options.data());
      }
    },
    thisMaxValue(max) {
      if (this.auditInMoney > max) {
        this.auditInMoney = max;
      }
      if (this.auditInMoney < 0) {
        this.auditInMoney = 0;
      }
    },
  },
};
</script>

<style scoped>
.row-item {
  padding: 3% 0;
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>