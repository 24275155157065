<template>
  <div class="logistics-wrap"> 
    <el-form :inline="true" class="rec-inline">
      <div class="logistics-tit">收货信息</div>
      <div class="log-head-list">
        <div class="log-list-lef">
          <div class="tab-label">收货人：</div>
          <div class="tab-inp">
            {{whmInfo.receiverName}}
          </div>
        </div>
        <div class="log-list-rig">
          <div class="tab-label">收货方电话：</div>
          <div class="tab-inp">
            {{whmInfo.receivingPhone}}
          </div>
        </div>
      </div>
      <div class="log-head-list">
        <div class="tab-label">发货方地址：</div>
        <div class="tab-inp">
          {{whmInfo.receivingAddress}}
        </div>
      </div>  
      <div class="logistics-tit mt30">物流信息</div>
      <div class="log-head-list">
        <div class="tab-label">送货方式：</div>
        {{whmInfo.deliveryMethod == 0 ? "寄付" : "到付"}}
      </div>
      <div class="log-head-list">
        <div class="tab-label">运费付款：</div>
        {{whmInfo.transportationCostMethod == 0 ? "到门" : "自提"}}
      </div> 
    </el-form>    

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column prop="goodsCode" label="商品编码" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="goodsName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="goodsBarCode" label="商品条形码" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="num" label="商品数量" :show-overflow-tooltip="true"></el-table-column>      
      <el-table-column prop="skuCode" label="库品编码" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="skuNum" label="库品数量" :show-overflow-tooltip="true"></el-table-column> 
      <el-table-column prop="sku_purchase_batch_code" label="批次选择" :show-overflow-tooltip="true"></el-table-column>  
    </ws-page-table>

    <el-form ref="deaInfoForm" class="step-form">
      <el-form-item label="下单备注：">
        {{whmInfo.remark}}
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">返回</el-button>
    </div>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  data() {
    return {
      value: [],
      options: [],  
      whmInfo: {},    
    };    
  },
  mixins: [pageTableMixin],
  mounted() {
    this.getGoodsDetail();
  },
  methods: {
    backPre(){//返回上一页
      this.$router.push("/som/deliveryOrder");
    }, 
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailLogistics(this.$route.params.id).then((data) => {
        //console.log(data)
        this.whmInfo = data
        loading.close();
      });
    },
    loadTableData() {
      this.searchInfo.outboundDocumentsId = this.$route.params.id
      WhmList.queryLogisticsDetail(this.pageInfo, this.searchConfig).then((data) => {   
        for(let i in data.list){
          if(data.list[i].skuList.length > 0){
            data.list[i].sku_purchase_batch_code = ""
            data.list[i].skuCode = ""
            data.list[i].skuNum = ""
            for(let j in data.list[i].skuList){
              if(data.list[i].skuList[j].skuPurchaseBatchCode){
                data.list[i].skuCode += data.list[i].skuList[j].skuCode + '+'
                data.list[i].sku_purchase_batch_code += data.list[i].skuList[j].skuPurchaseBatchCode + "*" + data.list[i].skuList[j].skuNum + "+"
                data.list[i].skuNum += data.list[i].skuList[j].skuCode + "*" + data.list[i].skuList[j].skuNum + "+"
              }
            }
            data.list[i].skuCode =  data.list[i].skuCode.substring(0, data.list[i].skuCode.length-1);
            data.list[i].skuNum =  data.list[i].skuNum.substring(0, data.list[i].skuNum.length-1);
            data.list[i].sku_purchase_batch_code = data.list[i].sku_purchase_batch_code.substring(0, data.list[i].sku_purchase_batch_code.length-1);
          }
        }     
        console.log(data.list)
        data.list = data.list.map((e) => {
          return {
            goodsCode: e.goodsCode,
            goodsName: e.goodsName,
            goodsBarCode: e.goodsBarCode,
            num: e.num,
            skuCode: e.skuCode,            
            skuNum: e.skuNum,
            sku_purchase_batch_code: e.sku_purchase_batch_code,
          };
        });
        this.querySuccess(data);
      });      
    },  

  },
};
</script>
<style>
.tab-label{ width: 160px; text-align: right;}
.logistics-wrap .rec-inline { margin-bottom: 20px;padding: 20px;background: #fff;}
.log-head-list{ margin-top: 10px; display: flex; align-items: center;}
.log-list-lef,.log-list-rig{ display: flex; align-items: center;}
.log-list-lef{ margin-right: 16px;}
.inp118{ width: 118px;}
.inp340{ width: 352px;}
.logistics-tit{ padding:0 0 10px 0; font-size: 18px;}
.mt30{ margin-top: 30px;}
.logistics-wrap .goods-dialog-head{ display: flex; align-items: center; height: 60px;}
.logistics-wrap .el-dialog__header{ padding: 0; border-bottom: 0;}
.logistics-wrap .goods-dialog-head .v1,.goods-dialog-head .v2{ margin-right: 30px;}
.logistics-wrap .goods-dialog-head .v1{ font-size: 18px;}
.logistics-wrap .goods-dialog-head .v2,.goods-dialog-head .v3{ color:#1B73E8;}
.logistics-wrap .btn-wrap{ padding:30px 0 0 0; display: flex; justify-content: center;}
.logistics-wrap .goods-dialog-cont{ height: 360px; overflow-y:scroll;}
.logistics-wrap .step-form{ margin-top: 30px;}
.tab-inp{ min-width: 180px;}
.steps-btn-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
</style>