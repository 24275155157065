import axiosHttp from "../axiosHttp";

export default {
    pageStaffByParam(param) {
        return axiosHttp.POST("/lsStaff/pageStaffByParam", param);
    },
    saveLsStaff(param) {
        return axiosHttp.POST("/lsStaff/saveLsStaff", param);
    },
    pageStaffAddressByParam(param) {
        return axiosHttp.POST("/lsStaff/pageStaffAddressByParam", param);
    },
    saveLsStaffAddress(param) {
        return axiosHttp.POST("/lsStaff/saveLsStaffAddress", param);
    },
    removeLsStaffAddress(param) {
        return axiosHttp.POST("/lsStaff/removeLsStaffAddress", param);
    },

    queryPlanList(param){
        return axiosHttp.POST("/lsStaffPlan/listStaffPlanByParam", param);
    },
    savePlan(param){
        return axiosHttp.POST("/lsStaffPlan/saveLsStaffPlan", param);
    },
    removeLsStaffPlan(param){
        return axiosHttp.POST("/lsStaffPlan/removeLsStaffPlan", param);
    },
    handleDoPlan(param){
        return axiosHttp.POST("/lsStaffPlan/handleDoPlan", param);
    }

}