<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">商品编码：</span>
            <el-input size="mini" v-model="searchInfo.goodsCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">统计时间：</span>
            <el-date-picker
                v-model="searchInfo.timeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="loadTableData">搜索</el-button>
        </div>
      </div>
    </div>

    <el-table style="margin-top: 10px;"
              :data="tableData"
              ref="pageTable">
    <el-table-column prop="goodsCode" label="商品编号"></el-table-column>
      <el-table-column prop="goodsName" label="商品名称"></el-table-column>
      <el-table-column prop="saleCount" label="成交销量（已支付）" width="160"></el-table-column>
      <!--
      <el-table-column prop="salesCount" label="待成交销量（已下单待支付）" width="160"></el-table-column>
      -->
    </el-table>
  </div>
</template>

<script>
import orderStatisticsService from "API/som/orderStatisticsService.js";
//import tools from "COMJS/tools.js";

export default {
  name: "statisticsGroupByGoods",
  data() {
    return {
      searchInfo: {
        timeRange:[]
      },
      tableData:[]
    };
  },
  methods: {
    loadTableData() {
      orderStatisticsService.statisticsGroupByGoods(this.searchInfo).then(
          data => {
            this.tableData = data;
          }
      );
    }
  }
};
</script>