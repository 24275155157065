import axiosHttp from "../axiosHttp";

export default {
    queryPage(pageInfo,searchConfig){
        let url = "/purFactoryProductionRecord/queryPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    save(param){
        let url = "/purFactoryProductionRecord/save"
        return axiosHttp.POST(url,param)
    },
    removeById(id){
        let param = {
            productionRecordId : id
        }
        let url = `/purFactoryProductionRecord/remove`
        return axiosHttp.POST(url,param)
    },

}