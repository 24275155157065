<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <!--
        <div class="form-row">
          <el-button type="primary" @click="createAddressDialogShow">新建</el-button>
        </div>
        -->
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">客户姓名：</span>
            <el-input size="mini" v-model="searchInfo.customerName" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件人姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件人电话：</span>
            <el-input size="mini" v-model="searchInfo.recipientPhoneNum" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">标签：</span>
            <el-input size="mini" v-model="searchInfo.mark" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
          </div>
          <div class="form-item">
            <el-button size="mini" type="default" @click="exportExcel">导出Excel</el-button>
          </div>
        </div>
      </div>
      <div class="filter_right">
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :tableRowClassName="tableRowClassName"
        :showIndex="false"
        :selection="'multi'"
    >
      <el-table-column label="序号" width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.idx }} / {{ scope.row.customerTotalAddressCount }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户" width="100"></el-table-column>
      <el-table-column prop="customerWorkUnit" label="客户单位" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="customerRemark" label="客户备注" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="收件信息" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{ scope.row.recipientName }} {{ scope.row.recipientPhoneNum }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientProvince }} {{ scope.row.recipientCity }} {{ scope.row.recipientCounty }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddressDetail }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="mark" label="标签" minWidth="100">
        <template slot-scope="scope">
          <el-tag
              style="margin-right:5px;"
              v-for="(tag,idx) in scope.row.tags"
              :key="idx"
              :type="'info'">
            {{ tag }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="recordDate" label="记录时间" width="120" :formatter="dateFormatter4Table"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="编辑" @click="showEditDialog(scope.row)">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <a href="javascript: void(0);" title="关系" @click="showRelaDialog(scope.row)">
              <i class="el-icon-share"></i>
            </a>
            <a href="javascript: void(0);" title="删除">
              <i class="el-icon-delete-solid" @click="deleteAddress(scope.row.customerAddressId)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <!--添加地址-->
    <el-dialog title="新建" :visible.sync="addressDialogVisible" width="800px">
      <div style="font-size: 14px;margin-bottom: 5px">1、收件信息</div>
      <div class="address-dialog-frame">
        <div style="display: flex;">
          <el-button style="width:95px;margin-right: 15px;" @click="recipientMixInfoCheck">
            识别<i class="el-icon-bottom"></i>
          </el-button>
          <el-input
              type="textarea"
              :rows="3"
              placeholder="智能识别"
              v-model="recipientMixInfo">
          </el-input>
        </div>
        <div class="border-dashed"></div>
        <el-form label-width="100px">
          <el-form-item required label="收货人：">
            <el-input
                v-model="recipientInfo.recipientName"
                placeholder="姓名"
                class="address-dialog-input"></el-input>
            <el-input
                v-model="recipientInfo.recipientPhoneNum"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
                placeholder="手机号码"
                class="address-dialog-input"
            ></el-input>
          </el-form-item>
          <el-form-item required label="收货地址：">
            <area-cascader v-model="recipientPcc"></area-cascader>
          </el-form-item>
          <el-form-item required>
            <el-input
                v-model="recipientInfo.recipientAddressDetail"
                placeholder="详细地址"
                class="address-dialog-input-long"></el-input>
          </el-form-item>
          <el-form-item label="标签：">
            <div class="tag-row">
              <el-tag v-for="(tag,idx) in addressDialogTagList" :key="tag"
                      closable type="warning" style="margin:5px;" @close="delTagFromList(idx)">{{ tag }}
              </el-tag>
              <el-input class="input-new-tag" v-if="tagInputVisible" v-model="tagInputValue"
                        ref="save_tag_input" size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm">
              </el-input>
              <div v-else style="display: flex;align-items: center;">
                <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;"
                   @click="showTagInput"></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="font-size: 14px;margin-bottom: 5px">2、添加到</div>
      <div style="display: flex;">
        <div class="address-dialog-frame" style="width: 50%">
          <div style="font-size: 16px;margin-bottom: 15px;">已有客户</div>
          <el-autocomplete
              class="address-dialog-input"
              v-model="oldCustomerData.customerName"
              :fetch-suggestions="querySearch"
              placeholder="* 选择客户"
              @select="handleSelect"
          ></el-autocomplete>
          <el-input
              v-model="oldCustomerData.customerWorkUnit"
              placeholder="单位"
              disabled
              class="address-dialog-input mt10"></el-input>
          <el-input
              v-model="oldCustomerData.customerRemark"
              placeholder="备注"
              disabled
              class="address-dialog-input mt10"></el-input>
        </div>
        <div class="address-dialog-frame" style="width: 50%">
          <div style="font-size: 16px;margin-bottom: 15px;">新建客户</div>
          <el-input
              v-model="newCustomerData.customerName"
              placeholder="* 姓名"
              @focus="newCustomerNameFocus"
              class="address-dialog-input"></el-input>
          <el-input
              v-model="newCustomerData.customerWorkUnit"
              placeholder="单位"
              class="address-dialog-input mt10"></el-input>
          <el-input
              v-model="newCustomerData.customerRemark"
              placeholder="备注"
              class="address-dialog-input mt10"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addressDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addressDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>
    <!--编辑-->
    <el-dialog title="编辑" :visible.sync="editDialogVisible" width="800px">
      <div style="font-size: 14px;margin-bottom: 5px">收件信息</div>
      <div class="address-dialog-frame">
        <el-form label-width="100px">
          <el-form-item required label="收货人：">
            <el-input
                v-model="recipientInfo.recipientName"
                placeholder="姓名"
                class="address-dialog-input"></el-input>
            <el-input
                v-model="recipientInfo.recipientPhoneNum"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
                placeholder="手机号码"
                class="address-dialog-input"
            ></el-input>
          </el-form-item>
          <el-form-item required label="收货地址：">
            <area-cascader v-model="recipientPcc"></area-cascader>
          </el-form-item>
          <el-form-item required>
            <el-input
                v-model="recipientInfo.recipientAddressDetail"
                placeholder="详细地址"
                class="address-dialog-input-long"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
                v-model="recipientInfo.remark"
                placeholder="地址备注"
                class="address-dialog-input-long"></el-input>
          </el-form-item>
          <el-form-item label="标签：">
            <div class="tag-row">
              <el-tag v-for="(tag,idx) in addressDialogTagList" :key="tag"
                      closable type="warning" style="margin:5px;" @close="delTagFromList(idx)">{{ tag }}
              </el-tag>
              <el-input class="input-new-tag" v-if="tagInputVisible" v-model="tagInputValue"
                        ref="save_tag_input" size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm">
              </el-input>
              <div v-else style="display: flex;align-items: center;">
                <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;"
                   @click="showTagInput"></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="font-size: 14px;margin-bottom: 5px">客户信息</div>
      <div style="display: flex;">
        <div class="address-dialog-frame" style="width: 100%">
          <el-input
              v-model="oldCustomerData.customerName"
              placeholder="* 姓名"
              class="address-dialog-input"></el-input>
          <el-input
              v-model="oldCustomerData.customerWorkUnit"
              placeholder="* 单位"
              class="address-dialog-input mt10"></el-input>
          <el-input
              v-model="oldCustomerData.customerRemark"
              placeholder="备注"
              class="address-dialog-input mt10"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>
    <!--关系-->
    <el-dialog title="查看" :visible.sync="relaDialogVisible" width="800px">
      <div style="font-size: 14px;margin-bottom: 5px">客户关系</div>
      <div class="address-dialog-frame rela-dialog">
        <div>
          <el-tag type="info" style="cursor:pointer;"
                  @click="gotoThisCustomerRela(relaDialogData.parentCustomerId,relaDialogData.parentCustomerName)">
            {{relaDialogData.parentCustomerName}}
          </el-tag>
        </div>
        <div>
          <i class="el-icon-bottom"/>
        </div>
        <div>
          <el-tag>
            {{relaDialogData.currentCustomerName}}
          </el-tag>
        </div>
        <div>
          <i class="el-icon-bottom"/>
        </div>
        <div>
          <el-tag v-for="(item,idx) in relaDialogData.child" :key="idx"
                  @click="gotoThisCustomerRela(item.customerId,item.customerName)"
                  style="margin:5px;cursor:pointer;"
                  closable type="info" @close="delChildFromList(idx)">
            {{ item.customerName }}
          </el-tag>
          <el-tag
              style="margin:5px;cursor:pointer;"
              v-if="!relaDialogAddChildFlag"
              type="info" @click="relaDialogAddChild">
            <i class="el-icon-plus"/>
          </el-tag>
          <el-autocomplete
              v-else
              popper-class="my-autocomplete"
              v-model="relaDialogAddChildCustomerName"
              :fetch-suggestions="querySearch"
              placeholder="选择客户"
              @select="handleSelect4RelaDialog"
          >
            <i class="el-icon-search el-input__icon" slot="suffix"></i>
            <template slot-scope="{ item }">
              <div class="name">{{ item.customerName }}</div>
            </template>
          </el-autocomplete>
        </div>
      </div>
      <div style="font-size: 14px;margin-bottom: 5px">
        订单统计
        <el-checkbox v-model="childStatCheck">加入下级</el-checkbox>
      </div>
      <div class="address-dialog-frame"
           style="display: flex;">
        <div style="flex: 1;">
          <p class="mb15">近期订单</p>
          <el-row style="font-size: 12px;margin-bottom: 5px;">
            <el-col :span="8">时间</el-col>
            <el-col :span="8">商品</el-col>
            <el-col :span="8">批次</el-col>
          </el-row>
          <el-row v-for="(item,idx) in relaDialogLast3Orders" :key="idx" style="font-size: 12px;margin-bottom: 5px;">
            <el-col :span="8">{{ tools.formatTime(item.recordDate) }}</el-col>
            <el-col :span="8">
              鲜奶两瓶装{{ item.milkCount }}份
              <p v-if="item.giftBoxCount>0">礼盒{{ item.giftBoxCount }}份</p>
            </el-col>
            <el-col :span="8">
              {{ item.milkBatchCode }}
            </el-col>
          </el-row>
        </div>
        <div style="flex: 1;">
          <p class="mb15">全部统计</p>
          <el-row style="font-size: 12px;margin-bottom: 5px;">
            <el-col :span="8">商品</el-col>
            <el-col :span="8">数量</el-col>
            <el-col :span="8">总价</el-col>
          </el-row>
          <el-row style="font-size: 12px;margin-bottom: 5px;">
            <el-col :span="8">鲜奶两瓶装</el-col>
            <el-col :span="8">{{relaDialogMilkSum}}</el-col>
            <el-col :span="8">{{ 140 * relaDialogMilkSum }}</el-col>
          </el-row>
          <el-row style="font-size: 12px;margin-bottom: 5px;">
            <el-col :span="8">礼盒</el-col>
            <el-col :span="8">{{relaDialogGiftBoxSum}}</el-col>
            <el-col :span="8">{{ 40 * relaDialogGiftBoxSum }}</el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="relaDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="relaDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import areaCascader from "COMPS/base/areaCascader";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import customerService from "API/usr/CustomerService";
import milkOrderService from "API/fm/MilkOrderService";
import bimAreaService from "API/bim/BimAreaService";
import IoProgressService from "API/IoProgressService";
import tools from "COMJS/tools";

export default {
  name: "CustomerManager",
  mixins: [pageTableMixin],
  components: {
    areaCascader
  },
  data() {
    return {
      searchInfo: {},
      pageInfo: {
        pageSize: 10,
      },
      allCustomerList: [],
      //地址
      addressDialogVisible: false,
      recipientMixInfo: '',
      recipientInfo: {},
      recipientPcc: {
        province: "",
        city: "",
        area: ""
      },
      addressDialogData: {},
      //tag
      addressDialogTagList: [],
      tagInputVisible: false,
      tagInputValue: '',
      //现有客户
      oldCustomerData: {},
      //新客户
      newCustomerData: {},
      //编辑
      editDialogVisible:false,
      //关系
      relaDialogVisible:false,
      relaDialogData:{},
      relaDialogLast3Orders:[],
      relaDialogMilkSum: 0,
      relaDialogGiftBoxSum: 0,
      relaDialogAddChildFlag:false,
      relaDialogAddChildCustomerName:'',
      childStatCheck:false,
    }
  },
  computed: {
    tools() {
      return tools;
    },
  },
  watch: {
    childStatCheck() {
      this.getCustomerOrderStat();
    }
  },
  mounted() {
    this.loadAddCustomer()
  },
  methods: {
    loadAddCustomer() {
      customerService.queryCustomerList({}).then(res => {
        res = res.map(e => {
          return {
            ...e,
            value: e.customerName + "，" + e.customerWorkUnit
          }
        })
        this.allCustomerList = res;
        //console.log(res)
      })
    },
    loadTableData() {
      let param = {
        ...this.searchInfo,
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
      }
      customerService.queryAddressPage(param).then(res => {
        if (res.list && res.list.length > 0) {
          let id = res.list[0].customerId
          let customerIdx = 0
          let idx = 0
          res.list = res.list.map(e => {
            if (e.customerId != id) {
              id = e.customerId
              customerIdx++;
              idx = 1;
            } else {
              idx++;
            }
            let tags = []
            if (e.mark) {
              tags = e.mark.split(",");
            }
            return {
              ...e,
              tags: tags,
              idx: idx,
              flag: customerIdx % 2 //0或1
            }
          })
        }
        this.querySuccess(res);
      })
    },
    exportExcel() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录导出！', '系统提示');
        return;
      }
      customerService.exportExcelCheckRows(rows).then(res=>{
        let fileName = res.fileName;
        let module = res.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    createAddressDialogShow() {
      this.recipientMixInfo = ''
      this.recipientInfo = {}
      this.recipientPcc = {}
      this.addressDialogTagList = []
      this.oldCustomerData = {}
      this.newCustomerData = {}
      this.addressDialogVisible = true;
    },
    //保存
    addressDialogConfirm() {
      if (!this.recipientInfo || !this.recipientInfo.recipientName
          || !this.recipientInfo.recipientPhoneNum || !this.recipientInfo.recipientAddressDetail
          || !this.recipientPcc || !this.recipientPcc.province || !this.recipientPcc.city || !this.recipientPcc.area) {
        //收货信息不完整
        return this.$message({
          message: "收货信息不完整",
          type: "warning"
        });
      }
      if ((!this.oldCustomerData || !this.oldCustomerData.customerId)
          && (!this.newCustomerData || !this.newCustomerData.customerName)) {
        return this.$message({
          message: "客户信息不完整",
          type: "warning"
        });
      }
      let param = {
        ...this.recipientInfo,
        recipientProvince: this.recipientPcc.province,
        recipientCity: this.recipientPcc.city,
        recipientCounty: this.recipientPcc.area,
        mark: '',
        customerId: '',
        customerName: '',
        customerRemark: '',
        customerWorkUnit:''
      }
      if (this.addressDialogTagList && this.addressDialogTagList.length > 0) {
        param.mark = this.addressDialogTagList.join(",")
      }
      if (this.oldCustomerData && this.oldCustomerData.customerId) {
        param.customerId = this.oldCustomerData.customerId
        param.customerName = this.oldCustomerData.customerName
        param.customerRemark = this.oldCustomerData.customerRemark
        param.customerWorkUnit = this.oldCustomerData.customerWorkUnit
      } else if (this.newCustomerData && this.newCustomerData.customerName) {
        param.customerName = this.newCustomerData.customerName
        param.customerRemark = this.newCustomerData.customerRemark
        param.customerWorkUnit = this.newCustomerData.customerWorkUnit
      }
      customerService.createNewAddress(param).then(res => {
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.recipientMixInfo = ''
        this.recipientInfo = {}
        this.recipientPcc = {}
        this.addressDialogTagList = []
        this.oldCustomerData = {}
        this.newCustomerData = {}
        this.addressDialogVisible = false;
        this.loadTableData();
      })
    },
    //智能地址识别
    recipientMixInfoCheck() {
      if (!this.recipientMixInfo || this.recipientMixInfo == '') {
        return;
      }
      let param = {
        info: this.recipientMixInfo
      }
      bimAreaService.getAddrAnalysisResult(param).then(res => {
        if (res) {
          this.recipientInfo = {
            recipientName: res.name,
            recipientPhoneNum: res.tel,
            recipientAddressDetail: res.addrStr
          }
          this.recipientPcc = {
            province: res.province,
            city: res.city,
            area: res.region,
          }
        }
      })
    },
    //编辑
    showEditDialog(row){
      this.recipientInfo = {
        ...row
      }
      this.recipientPcc = {
        province: row.recipientProvince,
        city: row.recipientCity,
        area: row.recipientCounty,
      }
      this.addressDialogTagList = []
      if(row.mark){
        this.addressDialogTagList = row.mark.split(",")
      }
      this.oldCustomerData = {
        ...row
      }
      this.editDialogVisible = true;
    },
    editDialogConfirm(){
      if (!this.recipientInfo || !this.recipientInfo.recipientName
          || !this.recipientInfo.recipientPhoneNum || !this.recipientInfo.recipientAddressDetail
          || !this.recipientPcc || !this.recipientPcc.province || !this.recipientPcc.city || !this.recipientPcc.area) {
        //收货信息不完整
        return this.$message({
          message: "收货信息不完整",
          type: "warning"
        });
      }
      if (!this.oldCustomerData
          || !this.oldCustomerData.customerId || !this.oldCustomerData.customerName) {
        return this.$message({
          message: "客户信息不完整",
          type: "warning"
        });
      }
      let param = {
        ...this.recipientInfo,
        recipientProvince: this.recipientPcc.province,
        recipientCity: this.recipientPcc.city,
        recipientCounty: this.recipientPcc.area,
        mark: '',
        customerId: this.oldCustomerData.customerId,
        customerName: '',
        customerRemark: '',
        customerWorkUnit:''
      }
      if (this.addressDialogTagList && this.addressDialogTagList.length > 0) {
        param.mark = this.addressDialogTagList.join(",")
      }
      if (this.oldCustomerData && this.oldCustomerData.customerId) {
        param.customerName = this.oldCustomerData.customerName
        param.customerRemark = this.oldCustomerData.customerRemark
        param.customerWorkUnit = this.oldCustomerData.customerWorkUnit
      }
      customerService.editCustomerAddress(param).then(res => {
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.recipientMixInfo = ''
        this.recipientInfo = {}
        this.recipientPcc = {}
        this.addressDialogTagList = []
        this.oldCustomerData = {}
        this.newCustomerData = {}
        this.editDialogVisible = false;
        this.loadTableData();
      })
    },
    //删除
    deleteAddress(customerAddressId) {
      let param = {
        customerAddressId:customerAddressId
      }
      this.$confirm(`确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        customerService.delCustomerAddress(param).then((data) => {
          this.$message({type: "success", message: "删除成功"});
          this.loadTableData();
        });
      }).catch(() => {});
    },
    //关系
    async showRelaDialog(row){
      let param = {
        customerId:row.customerId,
        customerName:row.customerName
      }
      let res = await customerService.queryCustomerRelaData(param);
      if(res){
        this.relaDialogData = res
        this.relaDialogVisible = true;
        this.relaDialogAddChildFlag = false;
      }
      this.getCustomerOrderStat()
    },
    getCustomerOrderStat(){
      console.log("getCustomerOrderStat",this.relaDialogData)
      if(!this.relaDialogData
          || !this.relaDialogData.currentCustomerId){
        return;
      }
      let ids = []
      ids.push(this.relaDialogData.currentCustomerId);
      if(this.childStatCheck && this.relaDialogData.child.length>0){
        for (let item of this.relaDialogData.child) {
          ids.push(item.customerId);
        }
      }
      let param = {
        customerIdList:ids
      }
      milkOrderService.listMilkOrder(param).then(res=>{
        if(res){
          let milkSum = 0;
          let giftBoxSum = 0;
          for (let item of res) {
            if(item.milkCount && item.milkCount>0){
              milkSum += item.milkCount
            }
            if(item.giftBoxCount && item.giftBoxCount>0){
              giftBoxSum += item.giftBoxCount
            }
          }
          this.relaDialogMilkSum = milkSum;
          this.relaDialogGiftBoxSum = giftBoxSum;
          if(res.length>3){
            this.relaDialogLast3Orders = res.slice(0,3)
          }else{
            this.relaDialogLast3Orders = res
          }
        }else{
          this.relaDialogMilkSum = 0;
          this.relaDialogGiftBoxSum = 0;
          this.relaDialogLast3Orders = []
        }
      })
    },
    gotoThisCustomerRela(customerId,customerName){
      let row = {
        customerId:customerId,
        customerName:customerName
      }
      this.showRelaDialog(row)
    },
    handleSelect4RelaDialog(item){
      this.relaDialogAddChildCustomerName = item.customerName;
      //调用接口
      let param = {
        customerIdA:this.relaDialogData.currentCustomerId,
        customerIdB:item.customerId,
      }
      customerService.createNewRela(param).then(res=>{
        if(res){
          this.$message.success("关联成功");
          this.relaDialogAddChildFlag = false;
          this.relaDialogData.child.push(item);
        }
      })
    },
    delChildFromList(idx){
      let item = this.relaDialogData.child[idx];
      this.$confirm("确定删除该关联?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 接口删除
        let param = {
          customerIdA:this.relaDialogData.currentCustomerId,
          customerIdB:item.customerId,
        }
        customerService.delCustomerRela(param).then(res=>{
          this.$message.success("删除成功");
          this.relaDialogData.child.splice(idx, 1)
        })
      }).catch(() => {});

    },
    relaDialogAddChild(){
      this.relaDialogAddChildFlag = true;
      this.relaDialogAddChildCustomerName = '';
    },
    relaDialogConfirm(){

    },
    //
    showTagInput() {
      this.tagInputVisible = true;
      this.tagInputValue = '';
      //let input= this.$refs["save_tag_input"];
      //input[0].focus();
    },
    handleInputConfirm() {
      let inputValue = this.tagInputValue;
      if (inputValue) {
        this.addressDialogTagList.push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    },
    delTagFromList(idx) {
      this.addressDialogTagList.splice(idx, 1)
    },
    querySearch(queryString, cb) {
      //console.log("querySearch",queryString)
      let customerList = this.allCustomerList;
      let results = queryString ? customerList.filter(this.createFilter(queryString)) : customerList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (item) => {
        return (item.customerName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelect(item) {
      this.oldCustomerData = item;
      this.newCustomerData = {};
    },
    newCustomerNameFocus() {
      this.oldCustomerData = {};
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
    tableRowClassName({row, rowIndex}) {
      if (row.flag == 0) {
        return 'row-color1';
      }
      return 'row-color2';
    }
  }
}
</script>

<style scoped>
.address-dialog-frame {
  border: 1px solid #d3d3d3;
  padding: 20px;
  margin-bottom: 20px;
  /*background-color: #d3d6da;*/
}
.rela-dialog{
  display: flex;flex-direction: column;align-items: center;justify-content: center;
}
.rela-dialog div{
  margin-bottom: 5px;
}
.mt10 {
  margin-top: 10px;
}

.address-dialog-input {
  width: 200px !important;
  margin-right: 15px;
}

.address-dialog-input-long {
  width: 630px !important;
}

.border-dashed {
  border-bottom: 1px dashed #3d3d3d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
}

.tag-row .input-new-tag {
  width: 100px;
}


</style>