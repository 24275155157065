<template>
  <div class="box-shadow-unify stytem-log">
    <div class="log-filter">
      <div
        class="log-filter_item"
        v-for="(item, index) in filterList"
        :key="index"
        @click="changeTimeFilter(item, index)"
      >
        <span class="text" :class="{'active': filterIndex == index}">{{item.label}}</span>
        <span class="label-badge" v-if="filterIndex == index">{{logNum}}</span>
      </div>
      <el-date-picker
        v-model="date"
        :editable="false"
        @change="changeFilter"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
      <el-select v-model="userId" placeholder="用户" @change="changeFilter">
        <el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="plate" placeholder="板块" @change="changePlateFilter">
        <el-option
          v-for="item in bigEnums"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select v-model="operationType" placeholder="操作" @change="changeFilter">
        <el-option v-for="item in enums" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
    <div class="dynamic-list" v-loading="loading">
      <div
        class="dynamic"
        :class="{'active': item.isToday}"
        v-for="(item, index) in logs"
        :key="index"
      >
        <div class="dynamic-date">
          <span class="date-label" v-if="item.isToday">今天</span>
          <span class="date-text">{{item.date}}</span>
          <div class="dynamic-btn" @click="toggle(item)">
            <i class="el-icon-caret-bottom" v-if="item.isShowAll"></i>
            <i class="el-icon-caret-top" v-else></i>
          </div>
        </div>
        <ul class="timeline">
          <ws-time-line :timeLineList="item.isShowAll ? item.list : item.list.slice(0, 1)"></ws-time-line>
        </ul>
      </div>
    </div>
    <div class="bottom-tip show-more" v-if="logNum > 0 && !isNoMore">
      <span @click="showMore">显示更多</span>
    </div>
    <div class="bottom-tip" v-else>
      <span>暂无更多日志</span>
    </div>
  </div>
</template>

<script>
import wsTimeLine from "COMPS/base/timeLine";
import systemLogService from "API/bim/systemLogService";
import tools from "COMJS/tools";

export default {
  name: "productLog",
  data() {
    return {
      filterList: [
        {
          label: "所有",
          action: () => {
            this.filterDate();
          }
        },
        {
          label: "今天",
          action: () => {
            this.filterDate("today");
          }
        },
        {
          label: "昨天",
          action: () => {
            this.filterDate("yesterday");
          }
        },
        {
          label: "本周",
          action: () => {
            this.filterDate("week");
          }
        },
        {
          label: "上周",
          action: () => {
            this.filterDate("lastWeek");
          }
        },
        {
          label: "本月",
          action: () => {
            this.filterDate("month");
          }
        },
        {
          label: "上月",
          action: () => {
            this.filterDate("lastMonth");
          }
        }
      ],
      filterIndex: 0,
      value: "",
      logs: [],
      logNum: 0,
      operationType: "",
      plate: "",
      userId: "",
      date: [],
      AllEnums: [],
      // 操作类型
      enums: [
        {
          label: "全部操作类型",
          value: ""
        }
      ],
      // 操作类型
      bigEnums: [
        {
          label: "全部板块",
          value: ""
        }
      ],
      // 操作人员
      users: [
        {
          label: "全部操作人员",
          value: ""
        }
      ],
      pageNo: 1,
      isNoMore: false,
      loading: false
    };
  },
  components: {
    wsTimeLine
  },
  mounted() {
    this.getLogsPerson();
    this.queryLogs();
  },
  methods: {
    clearLogs() {
      this.logNum = "";
      this.pageNo = 1;
      this.logs = [];
      this.isNoMore = false;
    },
    // 查询日志列表
    queryLogs() {
      let params = {
        beginDate: this.date[0]
          ? tools.formatDate(new Date(this.date[0]), "yyyy-MM-dd") + " 00:00:00"
          : "",
        endDate: this.date[1]
          ? tools.formatDate(new Date(this.date[1]), "yyyy-MM-dd") + " 23:59:59"
          : "",
        userId: this.userId,
        logType: this.plate===''&& this.$route.query.logType?this.$route.query.logType:this.plate,
        operationType: this.plate===''&& this.$route.query.operationType?this.$route.query.operationType:this.plate,
        pageNo: this.pageNo,
        pageSize: 50
      };
      this.loading = true;
      systemLogService.queryLogs(params).then(data => {
          this.logNum = data.num;
          if (!data.dateLogList || !data.dateLogList.length) {
            this.loading = false;
            return (this.isNoMore = true);
          }
          if (this.pageNo == 1 || data.dateLogList.length < 50) {
            this.isNoMore = true;
          }
          let logs = [];
          logs = data.dateLogList.map(e => {
            let isToday = false;
            if (e.date == tools.formatDate(new Date(), "yyyyMMdd")) {
              isToday = true;
            }
            let list = e.userLogList.map(log => {
              return {
                id: log.userLogId,
                time: tools.formatDate(new Date(log.createTime), "hh:mm"),
                events: log.operationDescribe
              };
            });
            return {
              date: e.date.substring(4, 6) + "-" + e.date.substring(6),
              isToday: isToday,
              isShowAll: true,
              list: list
            };
          });
          this.logs = this.logs.concat(logs);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 枚举
    getLogsPerson() {
      systemLogService.getLogsPerson().then(data => {
        let enums = data.enums.map(e => {
          e.label = e.name;
          e.value = e.value;
          return e;
        });
        this.AllEnums = enums;
        this.enums = this.enums.concat(enums);
        let users = data.users.map(e => {
        if(e.bimUserInfo==null){
          e.label="-"
        }else{
          e.label = e.bimUserInfo.fullName?e.bimUserInfo.fullName:decodeURI(e.bimUserInfo.wechatNickname);
        }
        e.value = e.userId;
          return e;
        });

        this.users = this.users.concat(users);
        this.bigEnums = data.bigEnums.map(e => {
          e.label = e.name;
          e.value = e.value;
          return e;
        });
      });
    },
    // 特定时间查询
    changeTimeFilter(item, index) {
      this.filterIndex = index;
      this.clearLogs();
      item.action();
    },
    changePlateFilter() {
      this.operationType = ""
      this.enums = this.AllEnums.filter(e => e.type === this.plate);
      this.clearLogs();
      this.filterIndex = -1;
      this.queryLogs();
    },
    // 其他条件查询
    changeFilter() {
      this.clearLogs();
      this.filterIndex = -1;
      this.queryLogs();
    },
    toggle(item) {
      item.isShowAll = !item.isShowAll;
    },
    showMore() {
      this.pageNo++;
      this.queryLogs();
    },
    search() {
      this.clearLogs();
      this.queryLogs();
    },
    filterDate(timer) {
      let _time = tools.filterDateMethod(timer);
      this.$set(this.date, 0, _time.startTime);
      this.$set(this.date, 1, _time.endTime);
      this.queryLogs();
    }
  }
};
</script>

<style scoped lang="scss">
.stytem-log /deep/ .el-date-editor--daterange {
  margin-right: 30px;
}
.stytem-log {
  .log-filter {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid $borderColor;
    .log-filter_item {
      cursor: pointer;
      display: inline-block;
      padding: 6px;
      font-size: 14px;
      line-height: 20px;
      margin-right: 30px;
      transition: background 0.2s;
      &:hover {
        border-radius: 4px;
        background-color: #dcdee0;
      }
      .text {
        position: relative;
        top: -1px;
        display: inline-block;
        color: #666;
        &.active {
          color: $themeColor;
          font-weight: bold;
          &::after {
            position: absolute;
            bottom: -5px;
            display: block;
            width: 100%;
            content: " ";
            border-bottom: 2px solid $themeColor;
          }
        }
      }
      .label-badge {
        display: inline-block;
        margin-left: 6px;
        font-size: 12px;
        color: #666;
        padding: 0 5px;
        border-radius: 9px;
        background-color: #dcdee0;
      }
    }
    .el-select {
      width: 140px;
      margin-right: 30px;
    }
  }
  .dynamic-list {
    padding: 25px 20px;
    .dynamic {
      display: flex;
      margin-bottom: 20px;
      &.active {
        .dynamic-date {
          border: 2px solid $themeColor;
          &::after {
            background-color: $themeColor;
          }
        }
        .timeline {
          border: 2px solid $themeColor;
        }
      }
      .dynamic-date {
        flex: 0 0 150px;
        padding: 8px 20px;
        height: 60px;
        box-sizing: border-box;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        border-radius: 2px;
        border: 2px solid $borderColor;
        .dynamic-btn {
          cursor: pointer;
          position: absolute;
          width: 24px;
          height: 24px;
          top: 50%;
          transform: translateY(-50%);
          right: 11px;
          text-align: center;
          line-height: 24px;
          background-color: #e9f2fb;
          i {
            color: $themeColor;
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -22px;
          width: 20px;
          height: 2px;
          background-color: $borderColor;
        }
      }
      .timeline {
        flex: 1;
        border-radius: 2px;
        border: 2px solid $borderColor;
        padding: 22px 40px;
        .ws-time-line /deep/ {
          .ws-time-line-item:last-of-type {
            margin-bottom: 0;
          }
          .line {
            margin: 0 22px;
            &:after {
              bottom: -30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
  .bottom-tip {
    text-align: center;
    > span {
      display: inline-block;
      padding: 15px;
      font-size: 14px;
      color: #666;
    }
  }
  .show-more {
    > span {
      cursor: pointer;
      color: $themeColor;
    }
  }
}
</style>