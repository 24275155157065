<template>
  <div class="invlist-box">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="toEdit">新增经销商</el-button>
      </el-form-item>
      <el-form-item label="经销商账号:">
        <el-input v-model="searchInfo.dealerAccount"/>
      </el-form-item>
      <el-form-item label="经销商名称:">
        <el-input v-model="searchInfo.dealerName"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
<!--      <div style="position: absolute;top: 0px;right: 0px;">
        <el-button type="text" @click="userRolePopShow">权限管理</el-button>
      </div>-->
    </el-form>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column prop="dealerAccount" label="经销商账号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="dealerName" label="经销商名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="dealerType" label="经销商类型" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="accountType" label="账号类型" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="buyDiscount" label="拿货折扣" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="contactName" label="联系人" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="contactMobile" label="联系方式" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enabled" label="经销商状态" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
                href="javascript: void(0);"
                title="编辑"
                @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
            <a
                href="javascript: void(0);"
                title="权限"
                @click="userRolePopShow(scope.row)"
            >
              <i class="el-icon-key"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <user-role-manager ref="userRolePop" @loadTableData="loadTableData"></user-role-manager>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerService from "API/dea/dealerService";
//import sysConstant from "@/constant/SysConstant";
import UserRoleManager from "PAGES/ba/role/userRoleManager";

export default {
  name: "dealerList",
  components: {UserRoleManager},
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        dealerAccount: "",
        dealerName: "",
      },
      items: {
        dealerAccount: {filter: "like"},
        dealerName: {filter: "like"},
      },
      buyDiscount: []
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.getAgent();
    //this.loadTableData()
  },
  methods: {
    getAgent() {
      dealerService
          .listForAgent()
          .then((res) => {
            this.buyDiscount = res.map((e) => {
              //console.log(e)
              return {
                disValue: e.eventId,
                label: e.eventName,
              };
            });
            this.loadTableData()
          })
          .catch(() => {
          });
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      dealerService.queryDealerPages(this.pageInfo, this.searchConfig).then((data) => {
        // console.log(data.list)
        // console.log(this.buyDiscount)
        for (let i in data.list) {
          for (let j in this.buyDiscount) {
            if (data.list[i].buyDiscount == this.buyDiscount[j].disValue) {
              data.list[i].buyDiscount = this.buyDiscount[j].label
            }
          }
        }
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            dealerId: e.dealerId,
            bimUserId: e.bimUserId,
            dealerAccount: e.dealerAccount,
            dealerName: e.dealerName,
            dealerType: e.dealerType == "1" ? "个人" : e.dealerType == "2" ? "一般纳税人" : e.dealerType == "3" ? "小额纳税人" : "",
            accountType: e.accountType == 'agent_credit' ? "信用账户"
                : e.accountType == 'agent_deposit' ? "预存账户"
                    : e.accountType == 'agent_cash' ? "现结账户"
                        : e.accountType == 'agent' ? "机构账户" : "",
            buyDiscount: e.buyDiscount,
            contactName: e.contactName,//            
            contactMobile: e.contactMobile.toLocaleString(),//
            enabled: e.enabled == "0" ? "冻结" : e.enabled == "1" ? "正常" : "",
          };
        });
        this.querySuccess(data);
      });
    },
    toEdit(row) {
      this.$router.push(`/dea/dealerEdit/${row.dealerId}`);
    },
    toDetail(row) {
      this.$router.push(`/dea/dealerDetail/${row.dealerId}`);
    },
    userRolePopShow(row){
      this.$refs.userRolePop.$emit("openDialog",row.bimUserId);
    }
  },
};
</script>
<style>
.invlist-box .demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
  position: relative;
}

.invlist-box .el-form--inline .el-form-item {
  margin-left: 10px;
  margin-bottom: 0;
}

.invlist-box .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.invlist-box .ws-page-table .icon-operation a {
  margin-right: 20px;
}
</style>