<template>
    <div id="importPermission">
        <div class="importPermission-header">
            <div>导入申请</div>
            <el-button type="primary" @click="addGoods">新增申请</el-button>
        </div>
        <div class="importPermission-content">
            <ws-page-table :data="tableData" ref="pageTable" style="width:100%" :pageInfo="pageInfo" @pageChange="loadTableData" :pageable="true">
                <el-table-column label="申请时间" prop="createTime"></el-table-column>
                <el-table-column label="开放时间" prop="applyOpenTime"></el-table-column>
                <el-table-column label="申请状态" prop="auditState">
                    <template slot-scope="scope">
                        <span :style="scope.row.auditState=='已驳回'?'color:red':''">{{scope.row.auditState}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="申请渠道" prop="operationField"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <span style="color: rgba(24, 144, 255, 1);cursor: pointer;" @click="seeDetails(scope.row.authorizationId)">查看</span>
                    </template>
                </el-table-column>
            </ws-page-table>
        </div>
    </div>
</template>

<script>
let moment = require("moment");
import pageTableMixin from "@/mixin/pageTableMixin.js";
import importPermissionService from "API/som/importPermissionService";
export default {
    data() {
        return {
            tableData: [],
        };
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {
            importPermissionService
                .importApplyPage(this.pageInfo)
                .then((data) => {
                    data.list = data.list.map((e) => {
                        return {
                            authorizationId: e.authorizationId,
                            createTime: moment(e.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            applyOpenTime: e.applyOpenTime,
                            beginTime: moment(e.beginTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            endTime: moment(e.endTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            operationField: e.operationField,
                            rejectReason: e.rejectReason,
                            openState: e.openState,
                            goodsInfo: e.goodsInfo,
                            auditState: e.auditState,
                            customerDisplayStatee: e.customerDisplayState,
                            managerDisplayState: e.managerDisplayState,
                        };
                    });
                    this.querySuccess(data);
                });
        },
        addGoods() {
            this.$router.push({
                path: "/som/addImport",
            });
        },
        seeDetails(authorizationId) {
            this.$router.push({
                path: "/som/addImport",
                query: {
                    authorizationId: authorizationId,
                },
            });
        },
    },
};
</script>

<style scoped>
#importPermission {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
}
.importPermission-header {
    height: 75px;
    width: 96%;
    padding: 0px 32px;
    /* background: red; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.importPermission-header > div:first-child {
    margin-right: 52px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
    /* font-weight: 500; */
}
</style>