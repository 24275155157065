import axiosHttp from "../axiosHttp";

export default {
    // 权限管理分页查询
    queryAuthorizationPages(params) {
        return axiosHttp.POST('/bimUserAuthorization/getAuthorityPageList', params, false);
    },
    // 权限详情
    getAuthorizationDetail(params) {
        return axiosHttp.POST('/bimUserAuthorization/getAuthorizationByAccount/',params,false);
    },
    //创建权限管理
    createAuthorization(params){
        return axiosHttp.POST('/bimUserAuthorization/createOrEditAuthorization',params);
    },
    queryAuthorityById(authorizationId){
        return axiosHttp.GET(`/bimUserAuthorization/getAuthorityById/${authorizationId}`)
    },

    deleteAuthority(authorizationIds) {
        return axiosHttp.DELETE(`/bimUserAuthorization`, {
            delIds: authorizationIds
        })
    }



}