<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="showAdd">添加标签</el-button>
        <span class="search-wrapper">
          <el-input size="mini" v-model="searchInfo.tagName" placeholder="请输入标签名称"></el-input>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </span>
      </div>
      <div class="filter_right">
        <!---->
      </div>
    </div>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="tagCode" label="标签编号" width="180"></el-table-column>
      <el-table-column prop="tagName" label="标签名称" width="180"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="编辑" @click="showEdit(scope.row)">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <a href="javascript: void(0);" title="删除">
              <i class="el-icon-delete-solid" @click="showDelete(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <el-dialog :title="modifyDialogTitle" :visible.sync="modifyDialogVisible" width="560px">
      <el-form label-width="80px">
        <el-form-item label="标签">
          <el-input v-model="modifyObj.tagName" style="width: 180px;"></el-input>
        </el-form-item>
        <el-form-item label="编号">
          <el-input v-model="modifyObj.tagCode" style="width: 180px;"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="modifyObj.remark" style="width: 180px;"></el-input>
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doSaveOrModify">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import tagService from "API/pim/TagManagerService";

export default {
  name: "MerchandiseTagManager",
  data() {
    return {
      searchInfo: {
        tagName: ""
      },
      modifyObj:{
        tagId:"",
        tagName:"",
        tagCode:"",
        remark:""
      },
      modifyDialogTitle:"添加",
      modifyDialogVisible: false,
    };
  },
  mixins: [pageTableMixin],
  computed: {},
  mounted() {
  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      tagService.queryPages(this.pageInfo, this.searchConfig).then(
          data => {
            this.querySuccess(data);
          }
      );
    },
    showAdd() {
      this.modifyObj = {}
      this.modifyDialogVisible = true
    },
    showEdit(row) {
      this.modifyObj = row
      this.modifyDialogVisible = true;
    },
    doSaveOrModify(){
      //todo save modifyObj
      this.modifyDialogVisible = false;
    },
    showDelete(row) {
      this.$confirm(`是否删除标签："${row.tagName}"?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        tagService.removeTag(row.tagId).then(() => {
          this.loadTableData();
        });
      }).catch(() => {
      });
    }
  },
}
</script>

<style scoped>

</style>