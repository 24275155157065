<template>
  <div style="display: flex;">
    <!--管理员app工作台-->
    <el-card class="first-card">
      <div slot="header" class="clearfix">
        <span>管理员app工作台</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="openMenuAddDialog('admin',null)">添加分类
        </el-button>
      </div>
      <vuedraggable
          class="vue-draggable"
          @start="onDragStart"
          @end="onDragEnd"
          v-model="adminData"
      >
      <el-card class="second-card"
               :class="item1.menuId"
               v-for="item1 in adminData" :key="item1.menuId">
        <div slot="header" class="clearfix">
          <span>{{ item1.menuName }}</span>
          <el-button style="float: right; margin-left: 10px;" type="text" @click="openMenuEditDialog('admin',item1)">编辑
          </el-button>
        </div>
        <vuedraggable
            class="vue-draggable"
            tag="ul"
            @start="onDragStart"
            @end="onDragEnd"
            v-model="item1.subMenus"
        >
          <!-- 拖拽元素 -->
          <li
              v-for="item2 in item1.subMenus"
              :key="item2.menuId"
              class="menu-item"
              :class="item2.menuId"
              @click="openMenuEditDialog('admin',item2)"
          >
            <el-image class="menu-icon" :src="item2.menuIcon"></el-image>
            {{ item2.menuName }}
          </li>
          <li class="menu-item"
              @click="openMenuAddDialog('admin',item1)"
          >
            <div class="menu-icon plus-icon"></div>
          </li>
        </vuedraggable>
      </el-card>
      </vuedraggable>
    </el-card>

    <!--会员app工作台-->
    <el-card class="first-card">
      <div slot="header" class="clearfix">
        <span>会员app工作台</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="openMenuAddDialog('distributor',null)">添加分类
        </el-button>
      </div>
      <vuedraggable
          class="vue-draggable"
          @start="onDragStart"
          @end="onDragEnd"
          v-model="distributorData"
      >
      <el-card class="second-card"
               :class="item1.menuId"
               v-for="item1 in distributorData" :key="item1.menuId">
        <div slot="header" class="clearfix">
          <span>{{ item1.menuName }}</span>
          <el-button style="float: right; margin-left: 10px;" type="text" @click="openMenuEditDialog('distributor',item1)">编辑
          </el-button>
        </div>
        <vuedraggable
            class="vue-draggable"
            tag="ul"
            @start="onDragStart"
            @end="onDragEnd"
            v-model="item1.subMenus"
        >
          <!-- 拖拽元素 -->
          <li
              v-for="item2 in item1.subMenus"
              :key="item2.menuId"
              class="menu-item"
              :class="item2.menuId"
              @click="openMenuEditDialog('distributor',item2)"
          >
            <el-image class="menu-icon" :src="item2.menuIcon"></el-image>
            {{ item2.menuName }}
          </li>
          <li class="menu-item"
              @click="openMenuAddDialog('distributor',item1)"
          >
            <div class="menu-icon plus-icon"></div>
            <!--
            <el-image class="menu-icon" :src="url('~ASSETS/images/plus.png')"></el-image>
            -->
          </li>
        </vuedraggable>
      </el-card>
      </vuedraggable>
    </el-card>

    <el-dialog :title="(currentMenu.menuId ? '编辑' : '添加')+(currentMenu.parentName ? '功能' : '分类')"
               :visible.sync="addDialogVisible"
               @close="addDialogVisible = false" width="600px">
      <el-form label-width="140px">
        <el-form-item required label="分类：" v-if="currentMenu.parentId!='1'">
          <el-input v-model="currentMenu.parentName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item required label="名称：">
          <el-input v-model="currentMenu.menuName"></el-input>
        </el-form-item>
        <el-form-item required label="编号：">
          <el-input v-model="currentMenu.menuCode"></el-input>
        </el-form-item>
        <el-form-item required label="icon：" v-if="currentMenu.parentId!='1'">
          <draggable-upload
              :width="50"
              :height="50"
              :isSingle="true"
              :uploadUrl="uploadUrl()"
              :requestHeaders="requestHeaders"
              v-model="currentMenu.iconList"
          ></draggable-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="danger" @click="remove(currentMenu)" v-if="currentMenu.menuId">删 除</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import vuedraggable from "vuedraggable";
import bimMenuService from 'API/ba/BimMenuService'

export default {
  name: "appWorkbenchManager",
  data() {
    return {
      requestHeaders: {
        Authorization: userModule.token,
      },
      adminData: [],
      distributorData: [],
      addDialogVisible: false,
      currentMenu: {},
      currentAppType:''
    }
  },
  components: {
    draggableUpload,
    vuedraggable
  },
  mounted(){
    this.loadData()
  },
  methods: {
    loadData(){
      const _this = this
      bimMenuService.getMenuAllTree('1').then(data=>{
        _this.adminData = data
        //console.log(data)
      })
      bimMenuService.getMenuAllTree('2').then(data=>{
        _this.distributorData = data
      })
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("ccs-app");//ccs-app图片文件夹
      return url;
    },
    openMenuAddDialog(role, data) {
      //console.log(role, data)
      this.currentAppType = role
      this.currentMenu = {};
      this.currentMenu.iconList = [];
      if(data){
        //分类下加功能
        this.currentMenu.parentId = data.menuId;
        this.currentMenu.parentName = data.menuName;
      }
      this.addDialogVisible = true;
    },
    openMenuEditDialog(role,data) {
      this.currentAppType = role
      this.currentMenu = data;
      if(data.menuIcon){
        this.currentMenu.iconList = [{response:data.menuIcon}];
      }else{
        this.currentMenu.iconList = [];
      }
      this.addDialogVisible = true;
    },
    addConfirm() {
      if (this.currentMenu.menuName == null
          || this.currentMenu.menuName.length == 0) {
        return this.$message({
          message: "请填写名称",
          type: "warning"
        });
      }
      if (this.currentMenu.menuCode == null
          || this.currentMenu.menuCode.length == 0) {
        return this.$message({
          message: "请填写编码",
          type: "warning"
        });
      }
      /*if (this.currentMenu.iconList == null
          || this.currentMenu.iconList.length == 0) {
        return this.$message({
          message: "请上传图标",
          type: "warning"
        });
      }*/
      if(this.currentMenu.iconList.length>0){
        this.currentMenu.menuIcon = this.currentMenu.iconList[0].response
      }
      this.currentMenu.menuUrl = this.currentMenu.url
      if(this.currentAppType=='admin'){
        this.currentMenu.appType='1'
      }else{
        this.currentMenu.appType='2'
      }
      bimMenuService.saveOrUpdateMenu(this.currentMenu).then(data=>{
        this.loadData();
        //提示，关闭弹窗
        this.$message({type: "success", message: "添加成功!"});
        this.loadData();
        this.addDialogVisible = false;
      })
    },
    remove(data){
      //console.log('remove',data)
      //const _this = this
      this.$confirm('删除后不可撤销, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        bimMenuService.deleteMenu(data.menuId).then(data=>{
          this.loadData();
          this.addDialogVisible = false;
        })
      });
    },
    onDragStart(e) {
      //e.target.classList.add("hideShadow");
      //console.log(e.target)
    },
    onDragEnd(e) {
      let id = e.item.className;
      id = id.replaceAll('menu-item','');
      id = id.replace('second-card','');
      id = id.replaceAll('el-card','');
      id = id.replaceAll('is-always-shadow','');
      id = id.replaceAll(' ','');
      //menu-item
      //el-card second-card is-always-shadow
      //console.log(id)
      if(e.newIndex===e.oldIndex){
        return;
      }
      let name = e.item.innerText;
      //name = name.slice("&nbsp;")[0];
      this.$confirm('将['+name+']拖动至位置'+e.newIndex+', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        bimMenuService.moveMenuItemApp(id,e.newIndex).then(data=>{
          this.loadData();
        })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// 拖拽
.vue-draggable {
  display: flex;
  flex-wrap: wrap;
}

.menu-item {
  display: grid;
  text-align: center;
  margin-left: 10px;
  font-size: 14px;
  margin-bottom: 18px;
  cursor: pointer;
  width: 95px;
  height: 95px;
  .menu-icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.first-card {
  width: 520px;
  margin-left: 25px;
}

.second-card {
  width: 480px;
  margin-top: 10px;
}
.dialog-footer{
  text-align: center;
}

.plus-icon{
  background-image: url("~ASSETS/images/plus.png");
  background-size:50px 50px;
}

</style>