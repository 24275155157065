<template>
  <div>
    <el-dialog title="创建发货单" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <el-form :model="dialogData" :rules="dialogRules" ref="dialogForm"
               label-width="110px" v-loading="dialogLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="物料">
              <span>{{ materialProcessStep0.materialName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商">
              <span>{{ materialProcessStep0.materialSupplierName }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="批次">
              <span>{{ materialProcessStep0.batchCode }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购数量">
              <span>{{ materialProcessStep0.processNum }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="剩余可发货数量">
              <span>{{ materialProcessStep0.remainder }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>发货信息</el-divider>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="发货数量" prop="processNum">
              <el-input v-model.number="dialogData.processNum"
                        placeholder="数量" :maxlength="8" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="发货物流" prop="shipperId">
              <el-select v-model="dialogData.shipperId"
                         placeholder="请选择" clearable
                         style="width: 100%;">
                <el-option-group
                    v-for="group in shipperList"
                    :key="group.label"
                    :label="group.label">
                  <el-option
                      v-for="item in group.options"
                      :key="item.shipperId"
                      :label="item.shipperName"
                      :value="item.shipperId">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提单号" prop="billLadingNo">
              <el-input v-model="dialogData.billLadingNo" :maxlength="64" placeholder="提单号"
                        style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="离港时间" prop="departPortTimeDate">
              <el-date-picker
                  v-model="dialogData.departPortTimeDate"
                  style="width: 100%;"
                  type="date">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计到港时间" prop="expectPortTimeDate">
              <el-date-picker
                  v-model="dialogData.expectPortTimeDate"
                  style="width: 100%;"
                  type="date">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="hide">关 闭</el-button>
        <el-button type="primary" @click.native="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import shipperService from "API/pur/PurContractService"
import materialProcessService from "API/pur/PurMaterialProcessService"
import tools from "COMJS/tools";

export default {
  name: "Step1CreateDialog",
  data() {
    let checkNum = (rule, value, callback) => {
      if (value > this.materialProcessStep0.remainder) {
        callback(new Error('发货数量不能大于剩余数量!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogLoading: false,
      dialogData: {},
      dialogRules: {
        shipperId: [
          {required: true, message: '请选择物流'},
        ],
        billLadingNo: [
          {required: true, message: '请填写提单号'},
        ],
        departPortTimeDate: [
          {required: true, message: '请选择离港时间'},
        ],
        expectPortTimeDate: [
          {required: true, message: '请选择预计到港时间'},
        ],
        processNum: [
          {required: true, message: '请填写数量'},
          {type: 'number', message: '输入内容不是有效的数字', trigger: 'blur'},
          {validator: checkNum, trigger: 'blur'}
        ]
      },
      //
      materialProcessStep0: {},
      shipperDic: {},
      shipperList: [],
    }
  },
  mounted() {
    this.queryDataList()
  },
  methods: {
    show(step0) {
      this.materialProcessStep0 = step0;
      this.clear()
      this.dialogVisible = true
    },
    hide() {
      this.clear()
      this.dialogVisible = false
    },
    clear() {
      this.dialogData = {}
      try {
        this.$refs.dialogForm.resetFields();
      } catch (e) {
      }
    },
    save() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          let param = {
            'materialTransport': {
              ...this.dialogData,
              'shipperName': this.shipperDic[this.dialogData.shipperId],
              'departPortTime': tools.formatDate(this.dialogData.departPortTimeDate, 'yyyy-MM-dd'),
              'expectPortTime': tools.formatDate(this.dialogData.expectPortTimeDate, 'yyyy-MM-dd'),
            },
            'previousProcessId': this.materialProcessStep0.materialProcessId,
            'processNum': this.dialogData.processNum
          }
          this.dialogLoading = true;
          materialProcessService.createStep1Process(param).then(res => {
            this.$message.success("保存成功")
            this.$emit("reloadAfterSave");
            this.hide()
          }).finally(()=>{
            this.dialogLoading = false;
          })
        }
      })
    },
    queryDataList() {
      shipperService.listShipper().then(res => {
        let total = []
        let dic = {}
        if (res && res.length > 0) {
          for (const it of res) {
            dic[it.shipperId] = it.shipperName;
          }
          let list0 = res.filter(e => e.type == '0')
          if (list0.length > 0) {
            total.push({label: '海运', options: list0})
          }
          let list1 = res.filter(e => e.type == '1')
          if (list1.length > 0) {
            total.push({label: '空运', options: list1})
          }
        }
        this.shipperDic = dic;
        this.shipperList = total;
      })
    }
  }
}
</script>

<style scoped>

</style>