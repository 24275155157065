import myUtils from "COMJS/myUtils"
import sysUtil from "../constant/SysUtil"
import userModule from "@/module/userModule";

export default {
    methods: {
        ...myUtils,
        _isDistributor(){
            if(!userModule.PPUser){
                return false;
            }
            const loginUser = JSON.parse(userModule.PPUser)
            if(!loginUser.userId){
                return false;
            }
            return sysUtil.isDistributor(loginUser.role);
        },
        _isAgentCredit(){
            if(!userModule.PPUser){
                return false;
            }
            const loginUser = JSON.parse(userModule.PPUser)
            if(!loginUser.userId){
                return false;
            }
            return sysUtil.isAgentCredit(loginUser.role);
        },
        _isAgentDeposit(){
            if(!userModule.PPUser){
                return false;
            }
            const loginUser = JSON.parse(userModule.PPUser)
            if(!loginUser.userId){
                return false;
            }
            return sysUtil.isAgentDeposit(loginUser.role);
        },
        _isS2ep(){
            if(!userModule.PPUser){
                return false;
            }
            const loginUser = JSON.parse(userModule.PPUser)
            if(!loginUser.userId){
                return false;
            }
            return sysUtil.isS2ep(loginUser.role);
        },
    }
}
