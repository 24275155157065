<template>
  <div>
    <div style="display: flex;margin-bottom: 20px;height: 50px;align-items: center;">
      <div style="line-height: 50px;">
        账户余额: ¥{{ headerInfo.balanceNum }}
      </div>
      <div style="margin-left: auto;">
        <el-button type="primary" @click="showApplyDialog">申请提现</el-button>
      </div>
    </div>
    <div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
      >
        <el-table-column min-width="150" prop="createTime" label="申请时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column min-width="150" prop="auditTime" label="审核时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column min-width="150" prop="auditState" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.auditState == 'success' ? '审核通过' :
                  scope.row.auditState == 'fail' ? '审核驳回' :
                      scope.row.auditState == 'awaiting' ? '等待审核' : ''
            }}
            <p style="color: #b94a48;" v-if="scope.row.auditState == 'fail'">
              原因：{{ scope.row.remark }}
            </p>
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="alterBalance" label="提现金额">
          <template slot-scope="scope">
            <div
                :style="{'color': scope.row.auditState == 'success'?'green':scope.row.auditState == 'fail'?'':'red'}">
              ¥{{ scope.row.operNum ? chargeFormat.thousandsOf(scope.row.operNum) : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="alterBalance" label="提现账户">
          <template slot-scope="scope">
            <div>
              <p>{{ scope.row.contentObj.accountName }}</p>
              <p>{{ scope.row.contentObj.accountNo }}</p>
              <p>{{ scope.row.contentObj.bankName }}</p>
            </div>
          </template>
        </el-table-column>
        <!--
        <el-table-column width="150" label="操作">
          <template slot-scope="scope">
            <a
                href="javascript: void(0);"
                @click="showAuditDialog(scope.row)"
                class="blue-txt"
                title="查看"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </template>
        </el-table-column>
        -->
      </ws-page-table>
    </div>

    <el-dialog title="充值审核" :visible.sync="showDialog" width="500px">
      <div>
        <el-form ref="form" :model="withdrawBankInfo" label-width="100px">
          <el-row>
            <el-col :span="24" style="font-weight: 600;">
              <el-form-item label="账户余额">
                ¥ {{ headerInfo.balanceNum }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="本次提现金额">
                <el-input v-model="withdrawBankInfo.amount" @input="thisMaxValue()"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="font-weight: 600;">
              <el-form-item label="提现账户">
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="户名">
                <el-input v-model="withdrawBankInfo.accountName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="账号">
                <el-input v-model="withdrawBankInfo.accountNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="开户行">
                <el-input v-model="withdrawBankInfo.bankName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="">
                <div style="color: #b94a48">* 为了您提现的进度，请填写您充值时的银行卡信息</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="dea-btn-wrap">
        <el-button @click="showDialog=false;">关闭</el-button>
        <el-button type="primary" @click="applyDialogConfirm">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import rechargePop from "./rechargePop";
import dealerService from "API/dea/dealerService";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import Objects from "COMJS/Objects";
import chargeFormat from "COMJS/chargeFormat.js";
import userModule from "@/module/userModule";
import temporaryDialog from "PAGES/som/order/TemporaryDialog";
//import sysUtil from "@/constant/SysUtil";

export default {
  name: "dealerRechargeList",
  components: {
    draggableUpload,
    rechargePop,
    temporaryDialog
  },
  mixins: [pageTableMixin],
  computed: {
    chargeFormat() {
      return chargeFormat;
    }
  },
  data() {
    return {
      //isCreditDealer: true,
      headerInfo: {
        balanceNum: "",
        lineOfCredit: "",
        alsoLineOfCredit: "",
        refundBalance: "",
        stayClose: "",
        stayEnter: "",
      },
      showDialog: false,
      withdrawBankInfo: {},
      searchInfo: {
        bimUserId: JSON.parse(userModule.PPUser).userId,
        createTime: [],
        type: "19",
        auditState: "success,fail,awaiting"
      },
      items: {
        bimUserId: {filter: "eq"},
        createTime: {filter: "between"},
        type: {filter: "in"},
        auditState: {filter: "in"},
      },
    }
  },
  mounted() {
    //this.isCreditDealer = sysUtil.isAgentCredit(JSON.parse(userModule.PPUser).role);
    this.getDealerInfo();
  },
  methods: {
    reloadPageInfo() {
      this.getDealerInfo();
      this.loadTableData();
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      dealerService
          .queryDealerLog(this.pageInfo, this.searchConfig)
          .then((data) => {
            data.list = data.list.map((e) => {
              //console.log(e)
              return {
                ...e,
                createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss"),
                auditTime: e.auditTime ? tools.formatDate(new Date(e.auditTime), "yyyy-MM-dd hh:mm:ss") : '',
                contentObj: JSON.parse(e.content)
              };
            });
            this.querySuccess(data);
          });
    },
    getDealerInfo() {
      dealerService.findDealerLinesInfo(JSON.parse(userModule.PPUser).userId).then((data) => {
        this.headerInfo.balanceNum = (data.balanceNum - 0).toFixed(2)
      });
    },
    showApplyDialog() {
      this.withdrawBankInfo = {}
      this.showDialog = true
    },
    applyDialogConfirm() {
      if (Objects.isEmpty(this.withdrawBankInfo.amount)) {
        this.$message.error("请填写提现金额");
        return;
      }
      if (
          Objects.isEmpty(this.withdrawBankInfo.accountName) ||
          Objects.isEmpty(this.withdrawBankInfo.accountNo) ||
          Objects.isEmpty(this.withdrawBankInfo.bankName)
      ) {
        this.$message.error("请填写账户信息");
        return;
      }
      let param = {
        ...this.withdrawBankInfo,
        withdrawAmount: this.withdrawBankInfo.amount
      }
      //rechargeDeposit
      dealerService.depositWithdrawApply(param).then(data => {
        //console.log(data)
        if (data.code == 'success') {
          this.$message({
            type: "success",
            message: "提交成功，等待审核！",
          });
        }
        this.showDialog = false;
        this.reloadPageInfo();
      })
    },
    thisMaxValue() {
      if (!/^[0-9.]*$/.test(this.withdrawBankInfo.amount)) {
        this.$message.warning(`必须为数字`);
        this.withdrawBankInfo.amount = "";
        return;
      }
      if (Number(this.withdrawBankInfo.amount) > Number(this.headerInfo.balanceNum)) {
        this.withdrawBankInfo.amount = this.headerInfo.balanceNum;
        this.$message.warning(`提现金额不能高于账户余额！`);
        return;
      }
    },
  }
}
</script>

<style scoped>
.dea-txt {
  padding: 9px 0;
  display: flex;
  color: #666;
}

.dea-tit {
  font-weight: bold;
}

.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-txt .sp2 {
  margin-left: 50px;
}

.dea-btn-wrap {
  text-align: center;
}

.bill-table {
  padding: 20px 0 20px 20px;
}

.bill-table td {
  padding: 10px 100px 10px 0;
}
</style>