// import { resolve } from 'core-js/fn/promise'


const frame = {template: '<router-view></router-view>'}

export default {
    path: 'whm',
    component: frame,
    children: [
        {
            name: '库品管理',
            path: 'whmList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmList.vue')
        }, {
            name: '新增库品',
            path: 'whmEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmEdit.vue')
        }, {
            name: '查看库品',
            path: 'whmDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmDetail.vue')
        }, {
            name: '分类管理',
            path: 'whmCategory',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmCategory.vue')
        }, {
            name: '单位管理',
            path: 'whmUnit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmUnit.vue')
        }, {
            name: '规格管理',
            path: 'whmSpec',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmSpec.vue')
        },
        {
            name: '供应商管理',
            path: 'whmSupplier',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmSupplier.vue')
        }, {
            name: '新增供应商',
            path: 'whmSupEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmSupEdit.vue')
        }, {
            name: '供应商详情',
            path: 'whmSupDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmSupDetail.vue')
        }, {
            name: '领用人管理',
            path: 'whmReceive',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/whm/whmReceive.vue')
        }, {
            name: '库存管理',
            path: 'invList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invList.vue')
        },{
            name: '质保日期管理',
            path: 'invWarrantyManager',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invWarrantyManager.vue')
        }, {
            name: '新增入库',
            path: 'invEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invEdit.vue')
        }, {
            name: '批次信息',
            path: 'invBatch/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invBatch.vue')
        }, {
            name: '批次详情',
            path: 'invBatchDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invBatchDetail.vue')
        }, {
            name: '新增组合库品',
            path: 'invGroupEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invGroupEdit.vue')
        }, {
            name: '查看组合库品',
            path: 'invGroupDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/inventory/invGroupDetail.vue')
        }, {
            name: '出库单',
            path: 'outList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/outList.vue')
        }, {
            name: '出库单详情',
            path: 'outDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/outDetail.vue')
        }, {
            name: '导出记录',
            path: 'orderExportRecord',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/orderExportRecord.vue')
        }, {
            name: '领用出库',
            path: 'receiveList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/receiveList.vue')
        }, {
            name: '领用出库',
            path: 'receiveEdit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/receiveEdit.vue')
        }, {
            name: '盘点',
            path: 'inventoryList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/inventoryList.vue')
        }, {
            name: '详情',
            path: 'inventoryDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/inventoryDetail.vue')
        }, {
            name: '新增盘点',
            path: 'inventoryEdit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/inventoryEdit.vue')
        }, {
            name: '新增盘点2',
            path: 'inventoryEdit2',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/inventoryEdit2.vue')
        }, {
            name: '其他出入库',
            path: 'otherList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/otherList.vue')
        }, {
            name: '新增',
            path: 'otherEdit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/otherEdit.vue')
        }, {
            name: '申请变动',
            path: 'modifyList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/modifyList.vue')
        }, {
            name: '库存流水',
            path: 'invAmounts',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/abnormal/invAmounts.vue')
        }, {
            name: '预定统计',
            path: 'bookTotal',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/book/bookTotal.vue')
        }, {
            name: '商品关联',
            path: 'productRelate',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/relate/productRelate.vue')
        },
        {
            name: '商品关联编辑',
            path: 'productRelateEdit/:id',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/relate/productRelateEdit.vue')
        }
        , {
            name: '出库单导入',
            path: 'wayBill',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/wayBill.vue')
        },
         {
            name: '拆合包',
            path: 'DisassemblyPackage',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/whm/outbound/DisassemblyPackage.vue')
        },
        {
            path: 'logistics',
            component: frame,
            children: [
                {
                    name: '物流成本模版',
                    path: 'model',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/express/LogisticsCostModel.vue')
                },
                {
                    name: '物流成本详情',
                    path: 'detail/:id?',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/express/LogisticsCostRuleModel.vue')
                }
            ]
        },
        {
            path: 'outbound',
            component: frame,
            children: [
                {
                    name: '序列号批量生成',
                    path: 'serialNumBatch',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/serialNumOutbound/serialNumBatch.vue')
                },
                {
                    name: '序列号列表',
                    path: 'serialNumOutboundList',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/serialNumOutbound/serialNumOutboundList.vue')
                },
                {
                    name: '序列号详情',
                    path: 'serialNumOutboundDetail/:id?',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/serialNumOutbound/serialNumOutboundDetail.vue')
                },
                {
                    name: '序列号录入',
                    path: 'serialNumOutboundInput',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/serialNumOutbound/serialNumOutboundInput.vue')
                },
                {
                    name: '序列号删除',
                    path: 'serialNumOutboundRemove',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/serialNumOutbound/serialNumOutboundRemove.vue')
                },
                {
                    name: '序列号查询',
                    path: 'serialNumOutboundSearch',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/whm/serialNumOutbound/serialNumOutboundSearch.vue')
                },
            ]
        }
    ]
}