import axiosHttp from "../axiosHttp";

export default {
    // 订单导出
    exportOrderForAdmin(params) {
        return axiosHttp.POST('/somOrderExport/exportOrderForAdmin', params);
    },
    exportPackageForAdmin(params) {
        return axiosHttp.POST('/somOrderExport/exportPackageForAdmin', params);
    },
    exportOrderForDistributor(params) {
        return axiosHttp.POST('/somOrderExport/exportOrderForDistributor', params);
    },
    exportPackageForDistributor(params) {
        return axiosHttp.POST('/somOrderExport/exportPackageForDistributor', params);
    }
}