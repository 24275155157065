<template>
  <el-dialog title="查看参数" :visible.sync="dialogVisible" width="700px" v-loading="loading">
    <el-form label-width="120px">
      <el-form-item label="参数名称：">
        <span>{{info.cnName}}</span>
      </el-form-item>
      <el-form-item label="参数键：">
        <span>{{info.paramKey}}</span>
      </el-form-item>
      <el-form-item label="参数值：">
        <span>{{info.defaultValue}}</span>
      </el-form-item>
      <el-form-item label="备注：">
        <span>{{info.remark || '无'}}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
    import BimParamService from "API/bim/BimParamService";

    export default {
        props: {
            paramId: String
        },
        watch: {
            dialogVisible() {
                if (this.paramId) {
                    this.getParamDetail();
                }
            }
        },
        data() {
            return {
                dialogVisible: false,
                loading: false,
                info: {}
            };
        },
        methods: {
            getParamDetail() {
                this.info = {};
                this.loading = true;
                BimParamService.getParamDetail(this.paramId).then(data => {
                    this.info = data;
                this.loading = false;
            });
            }
        }
    };
</script>

<style scoped>
  .el-form /deep/ .el-form-item__label {
    font-size: 16px;
  }
  .el-form /deep/ .el-form-item__content {
    font-size: 16px;
  }

  .brand-icon {
    display: inline-block;
    width: 82px;
    height: 82px;
    object-fit: fill;
  }
</style>
