import { render, staticRenderFns } from "./RecommendEdit.vue?vue&type=template&id=e78261cc&scoped=true"
import script from "./RecommendEdit.vue?vue&type=script&lang=js"
export * from "./RecommendEdit.vue?vue&type=script&lang=js"
import style0 from "./RecommendEdit.vue?vue&type=style&index=0&id=e78261cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e78261cc",
  null
  
)

export default component.exports