<template>
  <el-dialog :visible.sync="dialogVisible" title="预览" top="1vh" width="740px">
    <el-image :src="src" style="width: 700px; height: 700px" fit="contain"></el-image>
  </el-dialog>
</template>

<script>
export default {
  name: "imgPreview",
  data() {
    return {
      dialogVisible: false,
      src:'',
    }
  },
  methods:{
    show(src){
      if(src){
        src = src.replace('http://','https://')
        this.src = src;
      }
      this.dialogVisible = true;
    },
    hide(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>

</style>