<template>
  <el-dialog :title="type2? '编辑枚举': '新增枚举'" :visible.sync="dialogFormVisible" width="50vw" height="600px">
      <el-form
              :model="form"
              :rules="rules"
              v-if="dialogFormVisible"
              label-width="110px"
      >
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="枚举类型名称" prop="typeName">
              <el-input
                      v-model.trim="form.typeName"
                      maxlength="32"
                      show-word-limit
                      :autofocus="true"
                      v-bind:disabled="diasabledInput"


              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="枚举类型" prop="type">
              <el-input
                      v-model.trim="form.type"
                      show-word-limit
                      maxlength="32"
                      v-bind:disabled="diasabledInput"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="枚举名称" prop="name">
              <el-input v-model.trim="form.name" maxlength="16" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="枚举值" prop="value">
              <el-input v-model.trim="form.value" maxlength="32" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="code">
              <el-input v-model.trim="form.code" maxlength="32" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input v-model.trim="form.remark" maxlength="128" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-toolbar ws-toolbar">
        <el-button type="primary" @click="submitForm">新增</el-button>
        <el-button @click="batchParamDelete">删除</el-button>
      </div>
    <ws-page-table
            ref="pageTable"
            :data="tableData"
            :pageable="true"
            :page-info="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
            height="250"
    >
      <el-table-column fixed type="selection" width="50"></el-table-column>
      <el-table-column prop="name" label="枚举名称" min-width="90" show-overflow-tooltip></el-table-column>
      <el-table-column prop="value" label="枚举值" min-width="90" show-overflow-tooltip></el-table-column>
      <el-table-column prop="code" label="编号" min-width="90" show-overflow-tooltip></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="90" show-overflow-tooltip></el-table-column>
    </ws-page-table>
  </el-dialog>
</template>


<script>
import BimEnumerationInfo from "API/bim/BimEnumerationInfo";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";
import pageTableMixin from "@/mixin/pageTableMixin";

export default {
  props: {
      type2: String,
      typeName2: String,
      enumId:String
  },
  data() {
      let validateTypeName = (rule, value, callback) => {
          var param = {
              typeName: value
          };
          BimEnumerationInfo.uniqueValidate(param).then(data => {
          if(data.typeName === value){
              callback(new Error("该枚举类型名称已存在，请前往编辑页面添加"));
              if(this.flag){  //如果是编辑页面的添加  那么就不需要进行TypeName 和Type的同名校验
                  this.flag1=true;
              }else{
                  this.flag1=false;
              }
          }else{
              this.flag1=true;
              callback();
          }
      });
      };
      let validateType = (rule, value, callback) => {
          var param = {
              type: value
          };
          BimEnumerationInfo.uniqueValidate(param).then(data => {
              if(data.type === value){
              callback(new Error("该枚举类型已存在，请前往编辑页面添加"));
              if(this.flag){  //如果是编辑页面的添加  那么就不需要进行TypeName 和Type的同名校验
                  this.flag2=true;
              }else{
                  this.flag2=false;
              }
          }else{
              this.flag2=true;
              callback();
          }
      });
      };
      let validateName = (rule, value, callback) => {
                  var param = {
                      name: value
                  };
                  BimEnumerationInfo.uniqueValidate(param).then(data => {
                      if(data.name === value){
                      callback(new Error("枚举名称重复，请重新输入"));
                      if(this.flag) {
                          this.flag3 = false;
                      }else{
                          this.flag3 = true;
                      }
                  }else{
                      this.flag3=true;
                      callback();
                  }
              });
                          };
      let validateValue = (rule, value, callback) => {
              var param = {
                  value: value
              };
              BimEnumerationInfo.uniqueValidate(param).then(data => {
                  if(data.value === value){
                  callback(new Error("枚举值重复，请重新输入"));
                  if(this.flag) {
                      this.flag4 = false;
                  }else{
                      this.flag4 = true;
                  }
              }else{
                  this.flag4=true;
                  callback();
              }

          });
      };
      let validateCode = (rule, value, callback) => {
              var param = {
                  code: value
              };
              BimEnumerationInfo.uniqueValidate(param).then(data => {
                  if(data.code === value){
                  callback(new Error("枚举编码重复，请重新输入"));
                  if(this.flag) {
                      this.flag5 = false;
                  }else{
                      this.flag5 = true;
                  }
              }else{
                  this.flag5=true;
                  callback();
              }
          });
      };
    return {
      form: {
        typeName: "",
        type: "",
        name: "",
        value: "",
        code: "",
        remark: ""
      },
        type:"",
        typeName:"",
      requestHeaders: {
        Authorization: userModule.token
      },
      dialogFormVisible: false,
      loading: false,
      formLabelWidth: "100px",
      diasabledInput:false,
      flag:false, //判断是首页添加  还是编辑页面添加
      flag1:true,
      flag2:true,
      flag3:true,
      flag4:true,
      flag5:true,
      rules: {
          typeName: [
              { validator: validateTypeName, trigger: 'blur'},
              { required: true, message:"枚举类型名称不能为空", trigger: 'change'}
          ],
          type: [
              { validator: validateType, trigger: 'blur'},
              { required: true, message:"枚举类型不能为空", trigger: 'change' }
          ],
          name: [
              { validator: validateName, trigger: 'blur'},
              { required: true, message:"枚举名称不能为空", trigger: 'change' }

          ],
          value: [
              { validator: validateValue, trigger: 'blur'},
              { required: true, message:"枚举值不能为空", trigger: 'change' }
          ],
          code: [
              { validator: validateCode, trigger: 'blur'},
              { required: true, message:"枚举编码不能为空", trigger: 'change' }

          ]

      }
    };
  },
  watch: {
    dialogFormVisible(value) {
      if (this.type2) {
          this.flag=true;
          this.diasabledInput=true; //只可读
      } else {
        this.diasabledInput=false; //可写

      }
      this.resetForm();
      this.loadTableData();
      this.form.type=this.type2;
      this.form.typeName=this.typeName2

    }
  },
  mixins: [pageTableMixin],
  methods: {
      loadTableData() {
          this.loading = true;
          BimEnumerationInfo
              .getEnumList(this.pageInfo, this.type2)
              .then(data => {
              this.querySuccess(data);
      });
      },

    submitForm() {
        if(!this.flag){
            if (this.form.type == "") {
                return this.$message({
                    message: "请填写枚举类型",
                    type: "warning"
                });
            }
            if (this.form.typeName == "") {
                return this.$message({
                    message: "请填写枚举类型名称",
                    type: "warning"
                });
            }
          }
        if (this.form.name == "") {
            return this.$message({
                message: "请填写枚举名称",
                type: "warning"
            });
        }
        if (this.form.value == "") {
            return this.$message({
                message: "请填写枚举默认值",
                type: "warning"
            });
        }
        if (this.form.code == "") {
            return this.$message({
                message: "请填写枚举编号",
                type: "warning"
            });
        }
        if(this.flag1===false||this.flag2===false||this.flag3===false||this.flag4===false||this.flag5===false){
            return this.$message({
                message: "填写的数据不合法，请重新输入",
                type: "warning"
            });
        }

      // if (this.form.remark == "") {
      //     return this.$message({
      //         message: "请填写参数备注",
      //         type: "warning"
      //     });
      // }
      let params = {
        typeName: this.form.typeName,
        type: this.form.type,
        name: this.form.name,
        value: this.form.value,
        code: this.form.code,
        remark: this.form.remark
      };
      this.loading = true;
      if (this.type2) {
        params.type = this.type2;
      }
      if (this.typeName2) {
          params.typeName = this.typeName2;
      }
        BimEnumerationInfo.saveEnumInfo(params).then(res => {
        this.loading = false;
        this.$emit("updateTable");
      });
      this.dialogFormVisible = false;
    },
    // 重置表单
    resetForm() {
      tools.clearObj(this.form);
    },
    batchParamDelete() {
        let that = this;
        let rows = this.$refs.pageTable.getCheckedData();
        if (rows.length < 1) {
            that.$alert('请选择记录！', '系统提示');
            return;
        }
        that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() =>
        that.$refs.pageTable.tableLoading = true
    ).then(() => {
            return BimEnumerationInfo.deleteEnumInfo(rows)
        }).then(response => {
            that.loadTableData();
        that.$refs.pageTable.tableLoading = false;
        this.$emit("updateTable");
    }).catch(error => {
            console.log(error);
        that.$refs.pageTable.tableLoading = false;
    })
    }
  }
};
</script>

<style></style>