<template>
  <div class="input-search">
    <el-input size="mini" v-model="searchValue" @input="searchChange" @click.native="clickInput"></el-input>
    <div class="search-result" v-click-outside="closeResult" v-if="list.length">
      <div
        class="result-item"
        v-for="(item, index) in list"
        :key="index"
        @click="selectResult(item)"
      >
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "COMJS/tools";

export default {
  props: {
    resultList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  watch: {
    resultList: {
      handler(val) {
        this.list = val;
      },
      deep: true
    }
  },
  data() {
    return {
      searchValue: "",
      list: []
    };
  },
  methods: {
    searchChange: tools.debounce(function() {
      this.$emit("input", this.searchValue);
    }, 200),
    clickInput() {
      this.$emit("click", this.searchValue);
    },
    closeResult() {
      this.list = [];
    },
    selectResult(item) {
      this.$emit("selectResult", item);
    }
  }
};
</script>

<style scoped lang="scss">
.input-search {
  display: inline-block;
  position: relative;

  ::-webkit-scrollbar {
    width: 8px;
  }

  .search-result {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 5px);
    max-height: 260px;
    overflow-y: auto;
    border: 1px solid $borderColor;
    background-color: #fff;
    z-index: 2;

    .result-item {
      cursor: pointer;
      color: #666;
      font-size: 12px;
      padding-left: 20px;
      line-height: 30px;

      &:hover {
        background-color: #f2f3f7;
      }
    }
  }
}
</style>