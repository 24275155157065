import axiosHttp from "../axiosHttp";


/* 展示活动列表 */
export const showEventList = (pageInfo,searchConfig) => {
    let url = 'pimActivity/listPimActivity?1=1'
    if (pageInfo) {
        url += "&" + pageInfo.buildQueryString();
    }
    if(searchConfig){
        url += "&" + searchConfig.buildQueryString();
    }
    return axiosHttp.GET(url)
}

/* 活动列表详情 */
export const showEventDetail = id => {
    return axiosHttp.GET('pimActivity/queryPimActivityDetails/' + id)
}

export const changeActivityViewEnable = id => {
    let param ={
        activityId:id
    }
    return axiosHttp.POST('pimActivity/changePimActivityViewEnable',param)
}


/* 获取列表和详情商品排序值 */
/*export const getShowEventIndex = () => {
    return axiosHttp.GET('pimActivity/getMaxDispNo')
}*/

/* 添加或编辑活动 */
export const editShowEvent = data=>{
    return axiosHttp.POST('pimActivity/saveOrEditPimActivity',data)
}
/* 删除活动 */
export const delShowEvent = id=>{
    return axiosHttp.DELETE('pimActivity/removePimActivity',{delIds:id})
}

export const sortShowEvent = param =>{
    return axiosHttp.POST('pimActivity/sortPimActivity',param)
}

export const putGoodsInActivityList = param =>{
    return axiosHttp.POST('/pimActivity/putGoodsInActivityList', param, false)
}
