import axiosHttp from "../axiosHttp";

export default {
  queryPage(param) {
    let url = "/pimReserve/pagePimReserveGroup"
    return axiosHttp.POST(url,param);
  },
  queryByParam(param){
    let url = "/pimReserve/queryByParam"
    return axiosHttp.POST(url,param);
  },
  createPimReserve(param){
    let url = "/pimReserve/createPimReserve"
    return axiosHttp.POST(url,param);
  },
  addOrSubReserveNum(param){
    let url = "/pimReserve/addOrSubReserveNum"
    return axiosHttp.POST(url,param);
  },
  modifyRejectProperty(param){
    let url = "/pimReserve/modifyRejectProperty"
    return axiosHttp.POST(url,param);
  },
  modifyLockProperty(param){
    let url = "/pimReserve/modifyLockProperty"
    return axiosHttp.POST(url,param);
  },
  modifyReservePriceProperty(param){
    let url = "/pimReserve/modifyReservePriceProperty"
    return axiosHttp.POST(url,param);
  },
  queryDetail(userId){
    let url = `/pimReserve/queryDetail/${userId}`
    return axiosHttp.GET(url);
  },
  deletePimReserve(param){
    let url = "/pimReserve/deletePimReserve"
    return axiosHttp.POST(url,param);
  }

}