import axiosHttp from "../axiosHttp";

export default {
  // 参数分页查询
    queryInvitePages(pageInfo, searchConfig) {
    let url = "/bimInvite/pageQueryInvite?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
// 添加高二会员
    saveInvite(BimMemberInvite) {
    BimMemberInvite.state = 1;
    return axiosHttp.POST('/bimInvite/create', BimMemberInvite)
  },
    //获取会员指定的分享二维码
    getShareCodeImg(shareInfoId){
        return axiosHttp.GET(`/bimInvite/getWmpQRCode/${shareInfoId}`);
    },
    audit(param){
        let url = "bimInvite/applyForApproval";
        return axiosHttp.POST(url, param)
    },
    getDetail(inviteId){
        return axiosHttp.GET(`/bimInvite/getInviteDetails/${inviteId}`)
    }
}