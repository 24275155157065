<template>
  <div class="dea-edit">
    <div class="step-title">{{ hasId ? "编辑经销商" : "新增经销商" }}</div>
    <el-form :model="whmInfo" ref="deaInfoForm" class="step-form">
      <el-form-item label="基本信息" class="lab-wid"> &nbsp;</el-form-item>
      <el-form-item label="经销商账号:" class="lab-wid">
        <el-input
            v-model="whmInfo.dealerAccount"
            v-if="!hasId"
            oninput="if(value.length>12)value=value.slice(0,12)"
            type="number"
        ></el-input>
        <span v-if="hasId">{{ whmInfo.dealerAccount }}</span>
      </el-form-item>
      <el-form-item label="经销商密码:" class="lab-wid" v-if="!hasId">
        <el-input v-model="whmInfo.password" show-password></el-input>
      </el-form-item>

      <el-form-item label="纳税类型:" class="lab-wid">
        <!--纳税类型: dealerType-->
        <span v-if="hasId">{{ typeValue == "1" ? "个人" : "公司" }}</span>

        <el-select v-model="typeValue" placeholder="选择纳税类型" v-if="!hasId">
          <el-option
              v-for="item in dealerType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

        <el-select
            v-model="companyValue"
            class="ml15"
            placeholder="选择纳税类型"
            v-show="typeValue && typeValue != '1'"
        >
          <el-option
              v-for="item in companyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="经销商名称:" class="lab-wid">
        <el-input
            v-model="whmInfo.dealerName"
            maxlength="35"
            v-if="!hasId"
        ></el-input>
        <span v-if="hasId">{{ whmInfo.dealerName }}</span>
      </el-form-item>
      <el-form-item label="联系人:" class="lab-wid">
        <el-input v-model="whmInfo.contactName"></el-input>
      </el-form-item>
      <el-form-item label="联系方式:" class="lab-wid">
        <el-input
            type="number"
            v-model="whmInfo.contactMobile"
            oninput="if(value.length>12)value=value.slice(0,12)"
        ></el-input>
      </el-form-item>
      <el-form-item
          label="公司税号:"
          class="lab-wid"
          v-show="typeValue == 'type2'"
      >
        <el-input type="number" v-model="whmInfo.einNumber"></el-input>
      </el-form-item>
      <!---fileSizeText="128k"-->
      <el-form-item
          label="营业执照、其他材料（最多五张）："
          class="up-img"
          v-show="typeValue != '1'"
      >
        <draggable-upload
            :width="82"
            :height="82"
            :limit="5"
            :uploadUrl="uploadUrl()"
            :requestHeaders="requestHeaders"
            v-model="companyRelateImgs"
        ></draggable-upload>
      </el-form-item>
      <el-form-item label="财务信息" class="lab-wid"> &nbsp;</el-form-item>
      <!--accountType-->
      <el-form-item label="账号类型:" class="lab-wid">
        <el-select v-model="accTypeValue" placeholder="选择类型" v-if="!hasId">
          <el-option
              v-for="item in accountType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="hasId">{{
            accTypeValue == 'agent_credit' ? "信用账户"
                : accTypeValue == 'agent_deposit' ? "预存账户"
                : accTypeValue == 'agent_cash' ? "现结账户"
                    : accTypeValue == 'agent' ? "机构账户"
                    : ""
          }}</span>
      </el-form-item>
      <el-form-item label="拿货折扣:" class="lab-wid"
                    v-show="accTypeValue == 'agent_credit' || accTypeValue == 'agent_deposit'">
        <el-select v-model="disValue" placeholder="选择折扣">
          <el-option
              v-for="item in buyDiscount"
              :key="item.disValue"
              :label="item.label"
              :value="item.disValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="信用额度:"
          class="lab-wid"
          v-show="accTypeValue == 'agent_credit'"
      >
        <el-input
            type="number"
            v-model="whmInfo.lineOfCredit"
            v-if="!hasId"
        ></el-input>
        <div v-if="hasId" class="blue-box">
          <span>{{ whmInfo.lineOfCredit }}</span>
          <span class="blue-txt" @click="openCredit">修改</span>
        </div>
      </el-form-item>
      <el-form-item
          label="剩余余额:"
          class="lab-wid"
          v-show="accTypeValue == 'agent_deposit'"
      >
        <el-input
            type="number"
            v-model="whmInfo.balanceNum"
            v-if="!hasId"
        ></el-input>
        <div v-if="hasId" class="blue-box">
          <span>{{ whmInfo.balanceNum }}</span>
          <span class="blue-txt" @click="openDeposit">修改</span>
        </div>
      </el-form-item>
      <!--settlementType-->
      <el-form-item label="结算类型:" class="lab-wid" v-show="accTypeValue=='agent_credit'">
        <el-select v-model="accValue" placeholder="选择类型">
          <el-option
              v-for="item in settlementType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <el-tooltip effect="dark"
                    content="月结账户每月1号生成上月整月账单；半月结账户每月16号生成当月1～15号账单，每月1号生成上月16～31号账单；周结账户每周一生成上周账单。"
                    placement="top">
          <i class="icon-tip"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="结算时限:" class="lab-wid" v-show="accTypeValue=='agent_credit'">
        <el-select v-model="whmInfo.validTime" placeholder="选择结算时限">
          <el-option
              v-for="item in slectData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-input type="number" v-model="whmInfo.validTime"></el-input> -->
        <span class="ml15">天</span>
        <el-tooltip effect="dark" content="账单生成后，经销商应在结算时限内上传账单支付凭证并提交审核。" placement="top">
          <i class="icon-tip"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="是否启用:" class="lab-wid">
        <el-switch v-model="isEnabled"></el-switch>
      </el-form-item>
      <el-form-item label="渠道商：" class="lab-wid">
        <el-tag
            v-for="(tag,idx) in channelNames_list"
            :key="idx"
            closable
            :type="'info'"
            @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="tagInputVisible"
            v-model="tagInputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">添加</el-button>
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">取消</el-button>
      <el-button type="primary" @click="createWhm">保存</el-button>
    </div>

    <el-dialog title="信用额度修改" :visible.sync="creditDialog">
      <div class="dea-txt">总额度：¥{{ whmInfo.lineOfCredit }}</div>
      <div class="dea-txt">可用额度：¥{{ whmInfo.alsoLineOfCredit }}</div>
      <div class="dea-txt">
        <span class="sp1">修改后可用额度：</span>
        <el-input type="number" v-model="creidtInfo.creditValue">
          <template slot="prepend">￥</template>
        </el-input>
      </div>
      <div class="dea-txt">
        <span class="sp1">修改原因：</span>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="creidtInfo.creditTextarea"
        >
        </el-input>
      </div>
      <div class="dea-btn-wrap">
        <el-button @click="closeCredit">取消</el-button>
        <el-button type="primary" @click="saveCredit">确认修改</el-button>
      </div>
      <div class="dea-table-wrap">
        <ws-page-table
            :data="tableData"
            ref="pageTable"
            :showIndex="true"
            @pageChange="loadTableData"
        >
          <el-table-column
              prop="createTime"
              label="修改时间"
              :show-overflow-tooltip="true"
          ></el-table-column>
          <!--el-table-column
              prop="oldLineOfCredit"
              label="修改前额度"
              :show-overflow-tooltip="true"
          ></el-table-column-->
          <el-table-column
              prop="newLineOfCredit"
              label="修改后额度"
              :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
              prop="newAlsoLineOfCredit"
              label="修改后可用额度"
              :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
              prop="remark"
              label="修改原因"
              :show-overflow-tooltip="true"
          ></el-table-column>
        </ws-page-table>
      </div>
    </el-dialog>

    <el-dialog title="余额修改" :visible.sync="depositDialog">
      <div class="dea-txt">累计充值余额：¥{{ whmInfo.topUpNum }}</div>
      <div class="dea-txt">可用余额：¥{{ whmInfo.balanceNum }}</div>
      <div class="dea-txt">
        <span class="sp1">修改类型：</span>
        <el-select v-model="depositInfo.type" clearable placeholder="请选择">
          <el-option
              v-for="item in depositType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="sp2">变动金额：</span>
        <el-input
            type="number"
            class="deposit-inp"
            v-model="depositInfo.depositValue"
        ></el-input
        >
        <span class="ml15">元</span>
      </div>
      <div class="dea-txt">
        <span class="sp1">修改原因：</span>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="depositInfo.depositTextarea"
        >
        </el-input>
      </div>
      <div class="dea-btn-wrap">
        <el-button @click="closeDeposit">取消</el-button>
        <el-button type="primary" @click="saveDeposit">确认修改</el-button>
      </div>
      <div class="dea-table-wrap">
        <ws-page-table
            :data="depositData"
            ref="pageTable"
            :showIndex="true"
            @pageChange="depositTableData"
        >
          <el-table-column
              prop="createTime"
              label="修改时间"
              :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
              prop="type"
              label="修改类型"
              :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
              prop="operNum"
              label="变动金额"
              :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
              prop="remark"
              label="修改原因"
              :show-overflow-tooltip="true"
          ></el-table-column>
        </ws-page-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import dealerService from "API/dea/dealerService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import tools from "COMJS/tools";
//import sysConstant from "@/constant/SysConstant";

export default {
  data() {
    return {
      dealerType: [
        {
          value: "1",
          label: "个人",
        },
        {
          value: "type2",
          label: "公司",
        },
      ],
      typeValue: "",

      companyType: [
        {
          value: "2",
          label: "一般纳税人",
        },
        {
          value: "3",
          label: "小额纳税人",
        },
      ],
      companyValue: "",

      buyDiscount: [],
      disValue: "",

      accountType: [
        {
          value: 'agent_credit',
          label: "信用账户",
        },
        {
          value: 'agent_deposit',
          label: "预存账户",
        },
        {
          value: 'livestream_group',
          label: "机构账户",
        },
        /*{
          value: 'agent_cash',
          label: "现结账户",
        },*/
      ],
      accTypeValue: "",

      settlementType: [
        {
          value: "3",
          label: "周结账户",
        },
        {
          value: "2",
          label: "半月结账户",
        },
        {
          value: "1",
          label: "月结账户",
        },
      ],
      accValue: "",

      depositType: [
        {
          value: "2",
          label: "添加",
        },
        {
          value: "3",
          label: "减少",
        },
      ],

      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        dealerAccount: "", //经销商账号
        password: "", //经销商密码
        dealerType: "", //纳税类型
        dealerName: "", //经销商名称
        contactName: "", //联系人
        contactMobile: "", //联系方式
        einNumber: "", //公司税号
        companyRelateImgs: "", //材料图
        accountType: "", //账号类型
        lineOfCredit: "", //信用额度
        balanceNum: "", //剩余额度
        settlementType: "", //结算类型
        validTime: "", //结算时限
        lineOfCredit: "", //累计充值余额
        alsoLineOfCredit: "", //可用余额
        balanceNum: "", //余额
        topUpNum: "", //累计充值余额
        //enabled: true,// 是否启用1是启用0是禁用
        channelNames: [],
      },
      channelNames_list:[],
      tagInputVisible: false,
      tagInputValue: '',
      isEnabled: true,// 是否启用1是启用0是禁用
      hasId: false,
      companyRelateImgs: [],
      creditDialog: false,
      creidtInfo: {
        creditValue: "",
        creditTextarea: "",
      },
      depositInfo: {
        type: "",
        depositValue: "",
        depositTextarea: "",
      },
      depositDialog: false,
      depositData: [],
      searchInfo: {
        type: "",
      },
      items: {
        type: {filter: "in"},
      },
      slectData: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
        {
          value: "13",
          label: "13",
        },
        {
          value: "14",
          label: "14",
        },
        {
          value: "15",
          label: "15",
        },
        {
          value: "16",
          label: "16",
        },
        {
          value: "17",
          label: "17",
        },
        {
          value: "18",
          label: "18",
        },
        {
          value: "19",
          label: "19",
        },
        {
          value: "20",
          label: "20",
        },
        {
          value: "21",
          label: "21",
        },
        {
          value: "22",
          label: "22",
        },
        {
          value: "23",
          label: "23",
        },
        {
          value: "24",
          label: "24",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "26",
          label: "26",
        },
        {
          value: "27",
          label: "27",
        },
        {
          value: "28",
          label: "28",
        },
        {
          value: "29",
          label: "29",
        },
        {
          value: "30",
          label: "30",
        },
      ],
      specifyBackUrl:'',
    };
  },
  components: {
    draggableUpload,
  },
  mixins: [pageTableMixin],
  mounted() {
    //console.log(this.$route.params.id == "undefined")
    if(this.$store.state.specifyBackUrl && this.$store.state.specifyBackUrl.length>0){
      this.specifyBackUrl = this.$store.state.specifyBackUrl;
      this.$store.commit("setSpecifyBackUrl", "");
    }
    this.getAgent();
  },
  methods: {
    saveDeposit() {
      if (!this.depositInfo.type) {
        return this.$message({
          message: "请选择修改类型",
          type: "warning",
        });
      }
      if (!this.depositInfo.depositValue) {
        return this.$message({
          message: "请输入变动金额",
          type: "warning",
        });
      }
      if (!this.depositInfo.depositTextarea) {
        return this.$message({
          message: "请输入修改原因",
          type: "warning",
        });
      }
      this.depositInfo = {
        operType: this.depositInfo.type,
        operNum: this.depositInfo.depositValue,
        remark: this.depositInfo.depositTextarea,
        dealerId: this.$route.params.id,
      };
      dealerService
          .updBalanceNum(this.depositInfo)
          .then((res) => {
            //console.log(res);
            if (res.msg == "OK") {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            this.getGoodsDetail();
            this.depositDialog = false;
          })
          .catch(() => {
            this.depositDialog = false;
          });
    },
    saveCredit() {
      if (!this.creidtInfo.creditValue) {
        return this.$message({
          message: "请输入修改后可用额度",
          type: "warning",
        });
      }
      if (!this.creidtInfo.creditTextarea) {
        return this.$message({
          message: "请输入修改原因",
          type: "warning",
        });
      }
      this.creidtInfo = {
        newAlsoLineOfCredit: this.creidtInfo.creditValue,
        remark: this.creidtInfo.creditTextarea,
        dealerId: this.$route.params.id,
      };
      dealerService
          .updLineOfCredit(this.creidtInfo)
          .then((res) => {
            //console.log(res);
            if (res.msg == "OK") {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            this.getGoodsDetail();
            this.creditDialog = false;
          })
          .catch(() => {
            this.creditDialog = false;
          });
    },
    openDeposit() {
      this.depositDialog = true;
      this.depositTableData();
    },
    openCredit() {
      this.creditDialog = true;
      this.loadTableData();
    },
    loadTableData() {
      this.searchInfo.type = '1,8';
      if (this.whmInfo.dealerId) {
        this.searchInfo.dealerId = this.whmInfo.dealerId
      }
      dealerService
          .queryDealerLog(this.pageInfo, this.searchConfig)
          .then((data) => {
            data.list = data.list.map((e) => {
              //console.log(e)
              return {
                createTime: e.createTime
                    ? tools.formatDate(
                        new Date(e.createTime),
                        "yyyy-MM-dd hh:mm:ss"
                    )
                    : "",
                oldLineOfCredit: e.oldLineOfCredit.toLocaleString(),
                newLineOfCredit: e.newLineOfCredit.toLocaleString(),
                newAlsoLineOfCredit: e.newAlsoLineOfCredit.toLocaleString(),
                remark: e.remark, //分类
              };
            });
            this.querySuccess(data);
          });
    },
    depositTableData() {
      this.searchInfo.type = '2,3';
      if (this.whmInfo.dealerId) {
        this.searchInfo.dealerId = this.whmInfo.dealerId
      }
      dealerService
          .queryDealerLog(this.pageInfo, this.searchConfig)
          .then((data) => {
            data.list = data.list.map((e) => {
              return {
                createTime: e.createTime
                    ? tools.formatDate(
                        new Date(e.createTime),
                        "yyyy-MM-dd hh:mm:ss"
                    )
                    : "",
                type: e.type == "2" ? "添加" : "减少",
                operNum: e.operNum.toLocaleString(),
                remark: e.remark,
              };
            });
            this.depositData = data.list;
          });
    },
    closeCredit() {
      this.creditDialog = false;
    },
    closeDeposit() {
      this.depositDialog = false;
    },
    getAgent() {
      dealerService
          .listForAgent()
          .then((res) => {
            this.buyDiscount = res.map((e) => {
              //console.log(e)
              return {
                disValue: e.eventId,
                label: e.eventName,
              };
            });
            if (this.$route.params.id != "undefined") {
              this.hasId = true;
              this.getGoodsDetail();
            }
          })
          .catch(() => {
          });
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("fa_dealer");
      return url;
    },
    backPre() {
      //返回上一页
      this.$router.push("/dea/dealerList");
    },
    createWhm() {
      const that = this;
      let whmInfo = this.whmInfo;
      whmInfo.dealerType = this.typeValue;
      whmInfo.buyDiscount = this.disValue;
      whmInfo.accountType = this.accTypeValue;
      whmInfo.settlementType = this.accValue;
      if (whmInfo.accountType == 'agent_credit') {
        whmInfo.balanceNum = "";
      }
      if (whmInfo.accountType == 'agent_deposit') {
        whmInfo.lineOfCredit = "";
      }

      let picsUrl = []; //获取图片地址
      for (let i in this.companyRelateImgs) {
        picsUrl.push(this.companyRelateImgs[i].response);
      }
      let picStr = picsUrl.join(",");
      whmInfo.companyRelateImgs = picStr;
      if (!whmInfo.dealerAccount) {
        return this.$message({
          message: "请添加经销商账号",
          type: "warning",
        });
      }
      if (!whmInfo.password && !this.hasId) {
        return this.$message({
          message: "请添加经销商密码",
          type: "warning",
        });
      }

      if (!whmInfo.dealerType) {
        return this.$message({
          message: "请选择纳税类型",
          type: "warning",
        });
      }
      if (whmInfo.dealerType != "1" && !this.companyValue) {
        return this.$message({
          message: "请选择公司纳税类型",
          type: "warning",
        });
      }
      if (!whmInfo.dealerName) {
        return this.$message({
          message: "请添加经销商名称",
          type: "warning",
        });
      }
      if (!whmInfo.contactName) {
        return this.$message({
          message: "请添加联系人",
          type: "warning",
        });
      }
      if (!whmInfo.contactMobile) {
        return this.$message({
          message: "请添加联系方式",
          type: "warning",
        });
      }
      if (whmInfo.dealerType == "type2" && !whmInfo.einNumber) {
        return this.$message({
          message: "请添加公司税号",
          type: "warning",
        });
      }
      if (whmInfo.dealerType == "type2" && !whmInfo.companyRelateImgs) {
        return this.$message({
          message: "请上传材料图",
          type: "warning",
        });
      }
      if (!whmInfo.accountType) {
        return this.$message({
          message: "请选择账号类型",
          type: "warning",
        });
      }
      if (whmInfo.accountType == 'agent_credit' || whmInfo.accountType == 'agent_deposit'){
        if (!whmInfo.buyDiscount) {
          return this.$message({
            message: "请选择拿货折扣",
            type: "warning",
          });
        }
      }
      if (whmInfo.accountType == 'agent_credit' && !whmInfo.lineOfCredit) {
        return this.$message({
          message: "请添加信用额度",
          type: "warning",
        });
      }
      /*if (whmInfo.accountType == 'agent_deposit' && !whmInfo.balanceNum) {
        return this.$message({
          message: "请添加剩余额度",
          type: "warning",
        });
      }*/
      if (!whmInfo.settlementType && whmInfo.accountType == 'agent_credit') {
        return this.$message({
          message: "请选择结算类型",
          type: "warning",
        });
      }
      if (!whmInfo.validTime && whmInfo.accountType == 'agent_credit') {
        return this.$message({
          message: "请添加结算时限",
          type: "warning",
        });
      }

      if (whmInfo.dealerType != "1" && this.companyValue) {
        whmInfo.dealerType = this.companyValue;
      }
      if (this.$route.params.id && this.$route.params.id != "undefined") {
        whmInfo.dealerId = this.$route.params.id;
      }
      whmInfo.enabled = this.isEnabled == false ? "0" : "1"
      whmInfo.channelNames = this.channelNames_list
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      dealerService
          .saveDealer(whmInfo)
          .then((res) => {
            if (this.$route.params.id && this.$route.params.id != "undefined") {
              if (res.msg == "OK") {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              }
            } else {
              if (res.msg == "OK") {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
              }
            }
            if(this.specifyBackUrl && this.specifyBackUrl.length>0){
              //console.log(this.specifyBackUrl)
              setTimeout(function () {
                that.$router.back()
              }, 300);
            }else {
              setTimeout(function () {
                that.$router.push("/dea/dealerList");
              }, 300);
            }
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      dealerService.queryfaDealerInfo(this.$route.params.id).then((data) => {
        //console.log(data)
        let whmInfo = this.whmInfo;
        //console.log(whmInfo)
        whmInfo.dealerAccount = data.dealerAccount; //经销商账号
        whmInfo.dealerId = data.dealerId; //经销商id
        whmInfo.password = data.password; //经销商密码
        //this.typeValue = data.dealerType.toString();
        this.typeValue = data.dealerType;
        if (this.typeValue != "1") {
          //this.companyValue = data.dealerType.toString();
          this.companyValue = data.dealerType;
        }
        whmInfo.dealerName = data.dealerName; //经销商名称
        whmInfo.contactName = data.contactName; //联系人
        whmInfo.contactMobile = data.contactMobile; //联系方式
        whmInfo.einNumber = data.einNumber; //公司税号
        //拿货折扣
        this.disValue = data.buyDiscount;
        if (data.companyRelateImgs) {
          let tempImgs = data.companyRelateImgs.split(",");
          this.companyRelateImgs =
              tempImgs.length > 0
                  ? tempImgs.map((e) => {
                    return {
                      response: e,
                      url: e,
                    };
                  })
                  : [];
        }

        this.accTypeValue = data.accountType;
        whmInfo.lineOfCredit = data.lineOfCredit; //信用额度
        whmInfo.balanceNum = data.balanceNum; //余额
        this.accValue = data.settlementType;
        whmInfo.validTime = data.validTime; //结算时限
        whmInfo.alsoLineOfCredit = data.alsoLineOfCredit; //可用余额
        whmInfo.topUpNum = data.topUpNum; //累计充值余额
        this.isEnabled = data.enabled == "0" ? false : true
        this.channelNames_list = data.channelNames;
        loading.close();
      });
    },

    handleClose(tag) {
      this.channelNames_list.splice(this.channelNames_list.indexOf(tag), 1);
    },

    showInput() {
      this.tagInputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.tagInputValue;
      if (inputValue) {
        this.channelNames_list.push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    }
  },
};
</script>

<style lang="scss">
.dea-table-wrap {
  height: 260px;
  overflow-y: scroll;
}

.dea-btn-wrap {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.dea-txt {
  padding: 9px 0;
  display: flex;
  align-items: center;
  color: #666;
}

.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-txt .sp2 {
  margin-left: 50px;
}

.blue-box {
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.deposit-inp {
  width: 150px;
}

.blue-txt {
  color: #1b73e8;
  cursor: pointer;
}
.red-txt {
  color: red;
  cursor: pointer;
}

.dea-edit .lab-wid .el-form-item__label {
  min-width: 120px;
}

.dea-edit .up-img .el-form-item__label {
  padding-left: 50px;
  min-width: 120px;
}

.dea-edit {
  background: #fff;
}

.dea-edit .step-title {
  padding: 0 0 0 20px;
  height: 60px;
  line-height: 60px;
  background: #f6f9fc;
}

.dea-edit .step-form {
  padding: 30px 0 0 0;
}

.dea-edit .el-input__inner {
  width: 150px;
}

.dea-edit .steps-btn-wrapper {
  padding: 30px 0 40px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dea-edit .el-form-item__content > .el-input {
  width: 150px;
}

.icon-tip {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/tip.png");
  background-size: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>