<template>
  <div>
    <div style="padding: 20px; background-color: white;">
      <el-form  label-width="80" inline>
        <el-form-item label="会员姓名:">
          <el-input v-model="searchInfo.distributorName" placeholder="请输入会员姓名"/>
        </el-form-item>
        <el-form-item label="会员账号:">
          <el-input onkeyup="value=value.replace(/[^\d]/g,'');" v-model="searchInfo.distributorAccount" placeholder="请输入会员账号"/>
        </el-form-item>
        <el-form-item label="账号类型:">
            <el-radio-group v-model="searchInfo.accountType" @change="reSetPage">
              <el-radio-button label="">全部类型</el-radio-button>
              <el-radio-button label="company">公司</el-radio-button>
              <el-radio-button label="personal">个人</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <div>
          <el-button size="primary" type="primary" @click="showAdd">新增</el-button>
          <el-button size="primary" type="primary" @click="reSetPage">搜索</el-button>
        </div>
      </el-form>
    </div>
    <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
    >
      <el-table-column prop="distributorName" label="会员姓名" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="distributorAccount" label="会员账号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="accountType" label="账号类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{scope.row.accountType=='company'? '公司账号':'个人账号'}}
        </template>
      </el-table-column>
      <el-table-column prop="openState" label="开启状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{scope.row.openState=='0'? '关闭':'开启'}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="$router.push(`/bim/authorizationDetail/${scope.row.distributorId}`)">
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <authorization-add-popup ref="authorizationAddPopup" @updateTable="loadTableData" :authorizationId="authorizationId" ></authorization-add-popup>
  </div>
</template>

<script>
    import Paging from "../../../js/query/Paging";
    import BimUserAuthorization from "API/bim/BimUserAuthorizationService.js";
    import pageTableMixin from "@/mixin/pageTableMixin";
    import authorizationAddPopup from "./authorizationAddPopup.vue";
    import tools from "COMJS/tools";

    export default {
        data() {
          return {
            tableData: [],
            searchInfo: {
              pageSize: 10,
              pageNo: 1,
              distributorName:"",
              distributorAccount:"",
              accountType: "",
            },
            authorizationId: ""
          }
        },
        mixins: [pageTableMixin],
        components: {
          authorizationAddPopup,
        },
        mounted() {},
        methods: {
            loadTableData() {
                this.$refs.pageTable.tableLoading = true;
              BimUserAuthorization
                    .queryAuthorizationPages(this.queryParams)
                    .then(data => {
                    this.querySuccess(data);
            });
            },
            deleteParam(row) {
                this.$confirm(`是否删除"${row.cnName}"参数?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                    BimParamService.deleteParam(row.paramId).then(() => {
                    this.loadTableData();
            });
            }).catch(() => {});
            },
            showAdd() {
              this.currentId = "";
              this.$refs.authorizationAddPopup.dialogFormVisible = true;
            },
            reSetPage(){
              this.pageInfo.pageNo=1;
              this.loadTableData();
            }

        },
        computed: {}
    };
</script>
