<template>
    <div id="FileList">
        <div class="operation">
            <div class="FileList-button">
                <!-- <el-button type="primary">上传</el-button> -->
                <el-upload class="upload-demo" :action="uploadUrl()" :headers="requestHeaders" :before-upload="beforeUpload" :data="fileData" :on-success="fileSuccess" :show-file-list="false">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
                <el-button style="margin-left:10px" type="primary" @click='addFolder'>新建文件夹</el-button>
            </div>
            <div class="FileList-search">
                <el-input placeholder="搜索文件" clearable v-model="search" prefix-icon="el-icon-search"></el-input>
                <el-button type="primary" style="margin-left:14px;margin-right:32px" @click="searchFile(tableData)">查询</el-button>
            </div>
        </div>
        <div class="FileData">
            <!-- <a></a> -->
            <div class="FileData-path">
                <ul style="float:left">
                    <li @click="Reset" style="cursor: pointer;">全部文件</li>
                    <li class="path" v-for="(item,index) in pathList" :key="index" @click="clickPath(index)">
                        <span v-show="pathList.length>0">></span>
                        <span>{{item.name}}</span>
                    </li>
                </ul>
            </div>
            <div class="FileData-table">
                <ws-page-table id="nonetable">
                    <el-table-column label="文件名"></el-table-column>
                    <el-table-column label="修改时间" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="大小" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="操作" :show-overflow-tooltip="true"></el-table-column>
                </ws-page-table>
                <ws-page-table :show-header="pathList.length>0?true:false" :data="tableData" ref="pageTable" height="550" :pageInfo="pageInfo" @pageChange="loadTableData" @cellClick="celledit">
                    <!-- <el-table-column fixed type="selection" width="50"></el-table-column> -->
                    <el-table-column prop="name" label="文件名" :show-overflow-tooltip="true">
                        <template slot="header">
                            <div class="back" @click="back">
                                <img src="../../../assets/images/back.png">
                                <span>返回上一层</span>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <img class="rowIcon" :src="scope.row.imgUrl">
                            <el-input id="plateInput" v-model="scope.row.name" @blur="noeditTbale($event)" v-if="scope.row.edit" style="width:200px"></el-input>
                            <span v-else>{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastModified" label="修改时间" :show-overflow-tooltip="true">
                        <div slot="header"></div>
                    </el-table-column>
                    <el-table-column prop="size" label="大小" :show-overflow-tooltip="true">
                        <div slot="header"></div>
                        <template slot-scope="scope">
                            <div v-if="scope.row.name.substr(scope.row.name.length - 1, 1) != '/'">{{scope.row.size}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operation" label="操作" :show-overflow-tooltip="true">
                        <div slot="header"></div>
                        <template slot-scope="scope">
                            <a @click="download(scope.row)" title="下载" v-if="scope.row.name.substr(scope.row.name.length - 1, 1) != '/'">
                                <i style="color: rgba(70, 141, 234, 1);font-size:18px;cursor: pointer;" class="el-icon-download"></i>
                            </a>
                            <a @click="Detele(scope.row,scope.$index)" title="删除">
                                <i style="color: rgba(70, 141, 234, 1); margin-left: 10px;cursor: pointer;" class="el-icon-delete-solid"></i>
                            </a>
                        </template>
                    </el-table-column>
                </ws-page-table>
            </div>
        </div>
    </div>
</template>

<script>
const moment = require("moment");
import pageTableMixin from "@/mixin/pageTableMixin.js";
import BimFileService from "API/bim/BimFileService.js";
import userModule from "@/module/userModule";
import axiosHttp from "API/axiosHttp";
import { formatFileSize } from "@/js/formatFileSize";
export default {
    data() {
        return {
            search: "",
            tableData: [],
            pathList: [],
            requestHeaders: {
                Authorization: userModule.token,
            },
            fileData: {
                module: "",
            },
        };
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {
            BimFileService.listObjects().then((data) => {
                console.log(data);
                let tableData = data;
                tableData = this.handleImgUrl(tableData);
                this.tableData = tableData;
                let params = {
                    list: tableData,
                    total: this.tableData.length,
                };
                this.querySuccess(params);
                console.log(this.$refs.pageTable);
                this.pathList = [];
            });
        },
        handleImgUrl(tableData) {
            tableData = tableData.map((e) => {
                //console.log(e.name.substr(e.name.length - 1, 1));
                return {
                    name: e.name,
                    key: e.key,
                    lastModified: moment(e.lastModified).format(
                        "YYYY-MM-DD HH:mm:ss"
                    ),
                    size:
                        e.subordinate == null
                            ? Number.isFinite(e.size)
                                ? formatFileSize(e.size)
                                : e.size
                            : "",
                    url: e.url,
                    subordinate: e.subordinate,
                    edit: e.edit,
                    imgUrl:
                        e.name.substr(e.name.length - 1, 1) == "/"
                            ? require("ASSETS/images/folderIcon.png")
                            : e.key.split(".")[1].indexOf("png") != -1
                            ? require("ASSETS/images/imgIcon.png")
                            : e.key.split(".")[1].indexOf("jpg") != -1
                            ? require("ASSETS/images/imgIcon.png")
                            : e.key.split(".")[1].indexOf("pdf") != -1
                            ? require("ASSETS/images/pdfIcon.png")
                            : e.key.split(".")[1].indexOf("xls") != -1
                            ? require("ASSETS/images/xmlIcon.png")
                            : e.key.split(".")[1].indexOf("falsh") != -1
                            ? require("ASSETS/images/falshIcon.png")
                            : e.key.split(".")[1].indexOf("html") != -1
                            ? require("ASSETS/images/htmlIcon.png")
                            : e.key.split(".")[1].indexOf("doc") != -1
                            ? require("ASSETS/images/wordIcon.png")
                            : require("ASSETS/images/imgIcon.png"),
                };
            });
            return tableData;
        },
        addFolder() {
            let params = {
                name: "",
                key: "",
                url: "",
                lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                size: "",
                subordinate: [],
                edit: true,
                imgUrl: require("ASSETS/images/folderIcon.png"),
            };
            if (this.tableData != undefined) {
                this.tableData.unshift(params);
            } else {
                this.tableData = [];
                this.tableData.push(params);
            }
            setTimeout(() => {
                // this.$refs.input.focus();
                document.getElementById("plateInput").focus();
            }, 200);
        },
        celledit(row) {
            if (row) {
                if (row.name.substr(row.name.length - 1, 1) == "/") {
                    if (row.subordinate == null) {
                        this.tableData = row.subordinate;
                    } else {
                        this.tableData = this.handleImgUrl(row.subordinate);
                    }
                    let param = {
                        name: row.name,
                        path: row.key,
                        subordinate: row.subordinate,
                    };
                    this.pathList.push(param);
                    this.fileData.module = row.key;
                }
            }
        },
        noeditTbale(event) {
            this.$confirm(`是否保存?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    //   BimActivity.delByAcitivity(id).then((data) => {
                    //     this.$message({ type: "success", message: "删除成功" });
                    //     this.loadTableData();
                    //   });
                    for (let i in this.tableData) {
                        this.tableData[i].edit = false;
                    }
                    let params = {
                        dir: event.target.value + "/",
                        module: this.fileData.module,
                    };
                    BimFileService.mkdir(params).then((data) => {
                        this.$message({ type: "success", message: "保存成功" });
                        //this.tableData[0].name = this.tableData[0].name + "/";
                        this.loadTableData()
                    });
                })
                .catch(() => {
                    this.tableData.splice(0, 1);
                });
        },
        clickPath(index) {
            let pathList = this.pathList;
            this.tableData = this.handleImgUrl(pathList[index].subordinate);
            console.log(pathList.splice(index + 1));
            this.fileData.module = pathList[index].path;
            // this.pathList=pathList.splice(index+1,"")
        },
        back() {
            this.pathList.pop();
            let index = this.pathList.length - 1;
            if (this.pathList && this.pathList.length > 0) {
                this.tableData = this.handleImgUrl(
                    this.pathList[index].subordinate
                );
                this.fileData.module = this.pathList[index].path;
            } else {
                this.Reset();
            }
        },
        download(row) {
            console.log(row);
            let key = encodeURIComponent(row.key);
            let httpUrl = this.downloadUrl(key);
            console.log(httpUrl);
            let aLink = document.createElement("a"); //创建元素不挂载
            aLink.setAttribute("download", "");
            aLink.setAttribute("href", httpUrl);
            document.body.appendChild(aLink); //fire fox和ie 一定要加这一句
            //触发下载事件
            aLink.click();
            document.body.removeChild(aLink);
        },
        beforeUpload(file) {
            console.log(file);
            let params = {
                name: file.name,
                key: this.fileData.module + file.name,
                lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                size: Number.isFinite(file.size)
                    ? formatFileSize(file.size)
                    : file.size,
                url: "",
                subordinate: null,
                edit: false,
            };
            console.log(this.table);
            if (this.tableData == undefined) {
                this.tableData = [];
            }
            this.tableData.push(params);
            this.tableData = this.handleImgUrl(this.tableData);
        },
        fileSuccess(response) {
            this.tableData[this.tableData.length - 1].url = response;
            this.$message({ type: "success", message: "上传成功" });
        },
        Detele(row, index) {
            let params = {
                key: row.key,
            };
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                BimFileService.remove(params).then((data) => {
                    this.tableData.splice(index, 1);
                    this.$message({ type: "success", message: "删除成功" });
                });
            });
        },
        Reset() {
            this.loadTableData();
            this.fileData.module = "";
        },
        searchFile(tableData) {
            let searchText = this.search;
            if (searchText != "") {
                for (let i in tableData) {
                    if (tableData[i].name.search(searchText) != -1) {
                        this.tableData = [tableData[i]];
                        this.tableData = this.handleImgUrl(this.tableData);
                        this.$message({ type: "success", message: "查询成功" });
                        break;
                    } else {
                        for (let j in tableData[i].subordinate) {
                            if (
                                tableData[i].subordinate[j].name.search(
                                    searchText
                                ) != -1
                            ) {
                                this.tableData = [tableData[i].subordinate[j]];
                                this.tableData = this.handleImgUrl(
                                    this.tableData
                                );
                                this.$message({
                                    type: "success",
                                    message: "查询成功",
                                });
                                break;
                            } else {
                                this.searchFile([tableData[i].subordinate[j]]);
                            }
                        }
                    }
                }
            } else {
                this.Reset();
            }
        },
        uploadUrl() {
            return `${axiosHttp.baseURL}/fileManager/upload`;
        },
        downloadUrl(key) {
            let url = `${axiosHttp.baseURL}/fileManager/download`;
            url = url + "?key=" + key;
            return url;
        },
    },
};
</script>

<style scoped>
.operation {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 1);
}
.FileList-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 32px;
}
.FileList-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.FileData {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    margin-top: 24px;
}
.FileData-path {
    padding: 27px 32px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
}
.FileData-path > ul {
    display: flex;
    flex-direction: row;
}
.FileData-path > ul > li > span {
    margin: 0px 5px;
}
.FileData-table {
    width: 100%;
}
.rowIcon {
    position: relative;
    top: 10px;
    margin-right: 14px;
}
.path > span:nth-child(2) {
    cursor: pointer;
    color: rgba(24, 144, 255, 1);
}
#FileList >>> #nonetable .el-table__body-wrapper {
    display: none;
}
#FileList
    >>> .el-table--enable-row-transition
    .el-table__body
    td.el-table__cell:nth-child(1) {
    cursor: pointer;
}
.back {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
}
.back > img {
    position: relative;
    top: 5px;
}
.back > span {
    margin-left: 19px;
}
</style>