import axiosHttp from "../axiosHttp";
import tools from "COMJS/tools";

export default {
    // 导出账户详情
    exportAccountDetails(param) {
        return axiosHttp.POST('/dimDistributorAccount/exportAccountDetails',param,false);
    },
    getAllAccount(){
        return axiosHttp.GET('/dimDistributorAccount/getAllAccount');
    },

    queryDistributorSales(pageInfo,searchInfo){
        let url = "/dimDistributorAccount/queryDistributorSales"
        let param = {
            "pageNo":pageInfo.pageNo,
            "pageSize":pageInfo.pageSize,
            "distributorLevel":searchInfo.distributorLevel?searchInfo.distributorLevel:null,
            "distributorName":searchInfo.distributorName?searchInfo.distributorName:null,
            "distributorAccount":searchInfo.distributorAccount?searchInfo.distributorAccount:null,
            "openTime":searchInfo.timeRange.length>0?tools.formatDate(searchInfo.timeRange[0],'yyyy-MM-dd hh:mm:ss'):null,
            "endTime":searchInfo.timeRange.length>1?tools.formatDate(searchInfo.timeRange[1],'yyyy-MM-dd hh:mm:ss'):null
        }
        return axiosHttp.POST(url,param)
    },
    queryDistributorGoodsJoin(pageInfo,searchInfo){
        let url = "/dimDistributorAccount/queryDistributorGoodsJoin"
        let param = {
            "pageNo":pageInfo.pageNo,
            "pageSize":pageInfo.pageSize,
            "distributorId":searchInfo.distributorId,
            "goodsName":searchInfo.goodsName?searchInfo.goodsName:null,
            "goodsCode":searchInfo.goodsCode?searchInfo.goodsCode:null,
            "openTime":searchInfo.timeRange.length>0?tools.formatDate(searchInfo.timeRange[0],'yyyy-MM-dd hh:mm:ss'):null,
            "endTime":searchInfo.timeRange.length>1?tools.formatDate(searchInfo.timeRange[1],'yyyy-MM-dd hh:mm:ss'):null
        }
        return axiosHttp.POST(url,param)
    }

}
