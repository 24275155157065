const frame = {template: '<router-view></router-view>'}

export default {
    path: 'report',
    component: frame,
    children: [
        {
            path: 'distributor',
            component: frame,
            children: [
                {
                    name: '会员销量统计',
                    path: 'salesStatistics',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/report/distributor/salesStatistics.vue')
                },
                {
                    name: '会员销量统计',
                    path: 'salesStatisticsDetail',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/report/distributor/salesStatisticsDetail.vue')
                }
            ]
        },
        {
            path: 'order',
            component: frame,
            children: [
                {
                    name: '商品销量统计',
                    path: 'statisticsGroupByGoods',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/report/salesStatistics/statisticsGroupByGoods.vue')
                }
            ]
        }
    ]
}