<template>
  <div class="invlist-box">
    <div style="display: flex;justify-content: right;margin-bottom: 10px;">
      <el-button type="warning" @click="showAppointDealerDialog">手动生成账单</el-button>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="经销商账号:">
        <el-input type="text" v-model="searchInfo.dealerAccount"/>
      </el-form-item>
      <el-form-item label="生成时间:">
        <el-date-picker
            v-model="timeArr"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="inv-tab">
      <div class="inv-tab-box">
        <div
            class="inv-tab-list"
            @click="onTabClick(0)"
            :class="{ active: idx == 0 }"
        >
          全部
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(1)"
            :class="{ active: idx == 1 }"
        >
          待审核
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(2)"
            :class="{ active: idx == 2 }"
        >
          审核通过
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(3)"
            :class="{ active: idx == 3 }"
        >
          审核失败
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(4)"
            :class="{ active: idx == 4 }"
        >
          账单超时
        </div>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column
          prop="createTime"
          label="生成时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="auditTime"
          label="审核时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="dealerAccount"
          label="经销商账号"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="paymentInfoStr"
          label="支付方式"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="auditState"
          label="结算状态"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="orderOnTime"
          label="账单周期"
          :show-overflow-tooltip="true"
          min-width="180"
      ></el-table-column>
      <el-table-column
          prop="payAmount"
          label="账单金额"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="endTime"
          label="账单到期"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                v-show="scope.row.auditState == '待审核'"
                href="javascript: void(0);"
                @click="showDialog(scope.row, 'review')"
                class="blue-txt"
                title="审核"
            >
              <i class="icon-review"></i>
            </a>
            <a
                v-show="scope.row.auditState != '待审核'"
                href="javascript: void(0);"
                @click="showDialog(scope.row, 'info')"
                class="blue-txt"
                title="查看"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
                href="javascript: void(0);"
                @click="exportList(scope.row)"
                class="blue-txt"
                title="下载账单"
            >
              <i class="icon-download"></i>
            </a>
            <a
                v-show="scope.row.auditState == '待申请' || scope.row.auditState == '审核失败' || scope.row.auditState == '账单超时'"
                href="javascript: void(0);"
                @click="showEditDialog(scope.row)"
                class="blue-txt"
                title="修改"
            >
              <i class="el-icon-edit-outline"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="手动生成账单" :visible.sync="appointDealerToBillDialog" width="500px">
      <div class="edit-bill-dialog">
        <h1>1、系统每月会自动生成账单。</h1>
        <h1>2、半月期账单日为每月1号和16号，整月期账单日为每月1号。</h1>
        <h1>3、指定经销商手动生成账单是特殊处理，请审慎操作！</h1>
        <div style="display: flex;">
          <h2>将为</h2>
          <el-select
              style="width: 250px;"
              v-model="appointDealerParam.showLabel" filterable placeholder="请选择" @change="creditDealerSelectedChange">
            <el-option
                v-for="item in creditDealerList"
                :key="item.dealerId"
                :label="item.dealerName+'（账号：'+item.dealerAccount+'）'"
                :value="item">
            </el-option>
          </el-select>
          <h2>手动生成账单</h2>
        </div>
        <h2 v-if="appointDealerParam.dealerId">
          周期：{{
            tools.formatDate(new Date(appointDealerParam.lastBillTime || appointDealerParam.createTime), "yyyy-MM-dd hh:mm:ss")
          }} ~ 昨日23:59:59</h2>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="appointDealerGenerateBill">确认</el-button>
      </span>
    </el-dialog>

    <el-dialog title="账单金额调整" :visible.sync="editPayAmountDialog" width="500px">
      <div class="edit-bill-dialog">
        <h1>1、账单金额调整是特殊处理，请审慎操作！</h1>
        <h1>2、账单金额不能小于0</h1>
        <h3>账单金额：{{ editParam.payAmountOriginal }}
          <span v-if="editParam.payAmountDifference>0 && editParam.differenceModel=='reduce'">
            - {{
              editParam.payAmountDifference
            }} = {{ new BigNumber(editParam.payAmountOriginal).minus(editParam.payAmountDifference).toNumber() }}
          </span>
          <span v-if="editParam.payAmountDifference>0 && editParam.differenceModel=='add'">
            + {{
              editParam.payAmountDifference
            }} = {{ new BigNumber(editParam.payAmountOriginal).add(editParam.payAmountDifference).toNumber() }}
          </span>
        </h3>
        <div>
          <el-switch
              v-model="editParam.differenceModel"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="减"
              inactive-text="增"
              active-value="reduce"
              inactive-value="add">
          </el-switch>
          <el-input v-model="editParam.payAmountDifference" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="editBillConfirm">确认</el-button>
      </span>
    </el-dialog>

    <el-dialog title="账单审核" :visible.sync="creditDialog">
      <div style="display: flex;">
        <div class="dea-txt mr20">订单金额：¥{{ billtInfo.billAmount }}</div>
        <div class="dea-txt mr20">退款金额：¥{{ billtInfo.refundAmount }}</div>
        <div class="dea-txt mr20">上期结余：¥{{ billtInfo.lastBalance }}</div>
        <div class="dea-txt mr20" v-if="billtInfo.adminModifyAmount!=0">减免金额：¥{{ billtInfo.adminModifyAmount }}</div>
      </div>
      <div class="dea-txt">账单金额：¥{{ billtInfo.payAmount }}</div>
      <div class="draw-line"></div>
      <div class="dea-txt dea-tit">账户转账支付信息</div>
      <template v-if="!billtInfo.paymentInfo">
        <div class="dea-txt">待支付</div>
      </template>
      <template v-if="billtInfo.paymentInfo=='wxpay'">
        <div class="dea-txt">支付方式：微信支付</div>
        <div class="dea-txt">微信交易单号：{{billtInfo.openBankNumber}}</div>
      </template>
      <template v-if="billtInfo.paymentInfo=='alipay'">
        <div class="dea-txt">支付方式：支付宝转账</div>
        <div class="dea-txt">付款支付宝名称：{{ billtInfo.paymentMan }}</div>
        <div class="dea-txt">付款支付宝账号：{{ billtInfo.openBankNumber }}</div>
      </template>
      <template v-if="billtInfo.paymentInfo=='giro'">
        <div class="dea-txt">支付方式：银行转账</div>
        <div class="dea-txt">付款账户名称：{{ billtInfo.paymentMan }}</div>
        <div class="dea-txt">开户银行账号：{{ billtInfo.openBankNumber }}</div>
        <div class="dea-txt">开户银行名称：{{ billtInfo.openBankName }}</div>
      </template>
      <div class="dea-txt" v-if="billtInfo.paymentInfo=='alipay' || billtInfo.paymentInfo=='giro'">
        <span class="sp1">转账凭证：</span>
        <el-image
            v-for="(item, index) in billtInfo.transferImgs"
            :key="index"
            style="width: 100px; height: 100px"
            :src="billtInfo.transferImgs[index]"
            :preview-src-list="billtInfo.transferImgs"
            :zIndex="3000"
        >
        </el-image>
      </div>
      <div class="draw-line"></div>
      <div class="dea-txt" v-show="billtInfo.auditState == '1' && infoState == 'review'">
        <!--hz-->
        <span class="sp1">备注：</span>
        <el-input v-model="auditRemark" class="reason"></el-input>
        <span class="ml15">(驳回必填)</span>
      </div>
      <div class="dea-txt">
        审核结果：{{ billtInfo.auditState == '0' ? '待申请' : billtInfo.auditState == '1' ? '待审核' : billtInfo.auditState == '2' ? '审核成功' : billtInfo.auditState == '3' ? '审核失败' : billtInfo.auditState == '4' ? '账单超时' : '' }}
      </div>
      <div class="dea-txt" v-if="billtInfo.auditState == '3'">审核原因：{{ billtInfo.remark }}</div>
      <div class="dea-btn-wrap">
        <el-button type="success" @click="saveCredit('succ')" v-show="infoState == 'review'">通过</el-button>
        <el-button type="warning" @click="saveCredit('fail')" v-show="infoState == 'review'">驳回</el-button>
        <el-button type="primary" @click="closeCredit" v-show="infoState == 'info'">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerService from "API/dea/dealerService";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService.js";
import BigNumber from "COMJS/bignumber.min"

export default {
  name: "dealerList",
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        dealerAccount: "",
        openTime: "",
        endTime: "",
        auditState: "",
      },
      items: {
        dealerAccount: {filter: "like"},
        openTime: {filter: "EQ"},
        endTime: {filter: "EQ"},
        auditState: {filter: "EQ"},
      },
      timeArr: [],
      idx: 0,
      creditDialog: false,
      billtInfo: {},
      auditRemark: "",
      infoState: "",
      zIndex: "",
      auditLogId: "",
      editPayAmountDialog: false,
      editParam: {billId: '', payAmountOriginal: 0, payAmountDifference: 0, differenceModel: 'reduce'},
      creditDealerList: [],
      appointDealerToBillDialog: false,
      appointDealerParam: {}
    };
  },
  computed: {
    BigNumber() {
      return BigNumber
    },
    tools() {
      return tools
    }
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData();
    this.queryCreditDealer();
  },
  methods: {
    exportList(row) {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      dealerService
          .queryByBillToOrder(row.billId)
          .then((data) => {
            //console.log(data)
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
            this.loadTableData();
          })
          .catch(() => {
            loading.close();
          });
    },
    saveCredit(state) {
      let auditResult = state == 'succ' ? 'success' : 'fail'
      let params = {
        businessAuditLogId: this.auditLogId,
        auditResult,
      }
      if (state == 'fail') {
        if (!this.auditRemark) {
          return this.$message({
            message: "请输入驳回原因",
            type: "warning",
          });
        }
        params.auditRemark = this.auditRemark
      }
      dealerService.auditFlowHandle(params).then((data) => {
        this.creditDialog = false;
        if (auditResult == 'success' && data.msg == "OK") {
          this.loadTableData();
          return this.$message({
            message: "通过审核",
            type: "success",
          });
        } else {
          this.loadTableData();
          return this.$message({
            message: "驳回审核",
            type: "success",
          });
        }
      });
    },
    closeCredit() {
      this.creditDialog = false;
    },
    onTabClick(idx) {
      if (this.$refs.pageTable.tableLoading) {
        //加载完才能切换tab
        return;
      }
      if (idx == 0) {
        this.auditState = "";
      } else if (idx == 1) {
        this.auditState = "1"; //待审核
      } else if (idx == 2) {
        this.auditState = "2"; //审核通过
      } else if (idx == 3) {
        this.auditState = "3"; //审核失败驳回
      } else if (idx == 4) {
        this.auditState = "4"; //审核失败驳回
      }
      this.idx = idx;
      this.loadTableData();
    },
    loadTableData() {
      this.searchInfo.openTime =
          this.timeArr && this.timeArr[0]
              ? tools.formatDate(new Date(this.timeArr[0]), "yyyy-MM-dd")
              : "";
      this.searchInfo.endTime =
          this.timeArr && this.timeArr[1]
              ? tools.formatDate(new Date(this.timeArr[1]), "yyyy-MM-dd")
              : "";
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.auditState = this.auditState || ""
      dealerService.queryBillBySystem(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          return {
            billId: e.billId,
            auditLogId: e.auditLogId,
            createTime: e.createTime
                ? tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss")
                : "",
            endTime: e.endTime
                ? tools.formatDate(new Date(e.endTime), "yyyy-MM-dd")
                : "",
            auditTime: e.auditTime,
            dealerAccount: e.dealerAccount,
            paymentInfoStr:
                e.paymentInfo == 'wxpay' ? "微信支付"
                    :e.paymentInfo == 'alipay' ? "支付宝转账"
                    :e.paymentInfo == 'giro' ? "银行转账":"",
            auditState:
                e.auditState == 0 ? "待申请"
                    : e.auditState == 1 ? "待审核"
                    : e.auditState == 2 && e.payAmount == 0 ? "审核通过(自动)"
                        : e.auditState == 2 ? "审核通过"
                            : e.auditState == 3 ? "审核失败"
                                : e.auditState == 4 ? "账单超时"
                                    : "",
            payAmountDecimal: e.payAmount,
            payAmount: e.payAmount ? e.payAmount.toLocaleString() : "0",
            orderOnTime: e.orderOnTime,
            applyTime: e.applyTime
                ? tools.formatDate(new Date(e.applyTime), "yyyy-MM-dd hh:mm:ss")
                : "",
          };
        });
        //console.log(data.list)
        this.querySuccess(data);
      });
    },
    showDialog(row, state) {
      this.infoState = state == "review" ? "review" : "info"
      let params = row.billId
      // console.log(row)
      // console.log(row.auditLogId)
      this.auditLogId = row.auditLogId
      dealerService.getFaBillInfo(params).then((data) => {
        //console.log(data)
        data.transferImgs = data.transferImgs ? data.transferImgs.split(",") : [],
            this.billtInfo = data
        this.creditDialog = true
      });
    },
    showEditDialog(row) {
      this.editParam.billId = row.billId
      this.editParam.payAmountOriginal = row.payAmountDecimal
      this.editParam.payAmountDifference = 0
      this.editPayAmountDialog = true;
    },
    editBillConfirm() {
      if (this.editParam.payAmountDifference == 0) {
        return this.$message.warning("请填写调整金额")
      }
      this.editParam.payAmountModified = 0;
      if (this.editParam.differenceModel == 'reduce') {
        this.editParam.payAmountModified = new BigNumber(this.editParam.payAmountOriginal).minus(this.editParam.payAmountDifference).toNumber()
      } else {
        this.editParam.payAmountModified = new BigNumber(this.editParam.payAmountOriginal).add(this.editParam.payAmountDifference).toNumber()
      }
      if (this.editParam.payAmountModified < 0) {
        return this.$message.warning("调整后账单金额不能小于0")
      }
      dealerService.editFaBill(this.editParam).then(data => {
        this.$message.success("提交成功");
        this.editPayAmountDialog = false;
        this.loadTableData();
      })
    },
    queryCreditDealer() {
      dealerService.listCreditDealer().then(data => {
        this.creditDealerList = data;
      })
    },
    showAppointDealerDialog() {
      this.appointDealerParam = {}
      this.appointDealerToBillDialog = true;
    },
    creditDealerSelectedChange(item) {
      //console.log(item)
      this.appointDealerParam = item;
      this.appointDealerParam.showLabel = item.dealerName + '（账号：' + item.dealerAccount + '）';
    },
    appointDealerGenerateBill() {
      if (!this.appointDealerParam.dealerId) {
        return this.$message.warning("请选择经销商")
      }
      if (tools.isSameDay(new Date(this.appointDealerParam.lastBillTime || this.appointDealerParam.createTime), new Date())) {
        return this.$message.warning("周期太短，无法生成账单")
      }
      let param = {
        dealerId: this.appointDealerParam.dealerId,
      }
      dealerService.appointDealerGenerateBill(param).then(data => {
        this.$message.success("生成成功");
        this.appointDealerToBillDialog = false;
        this.loadTableData();
        //重新拿一遍，因为lastBillTime变了
        this.queryCreditDealer()
      })
    },
  },
};
</script>
<style>
.el-image {
  margin-right: 10px;
}

.icon-review {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/review.png");
  background-size: cover;
}

.draw-line {
  border-bottom: 1px solid #666;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dea-tit {
  margin-top: 15px;
}

.mr20 {
  margin-right: 20px;
}

.reason,
.reason .el-input__inner {
  width: 200px;
}

.invlist-box .demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.invlist-box .el-form--inline .el-form-item {
  margin-left: 10px;
  margin-bottom: 0;
}

.invlist-box .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.invlist-box .ws-page-table .icon-operation a {
  margin-right: 20px;
}

.inv-tab {
  padding-top: 10px;
  padding-bottom: 20px;
  height: 70px;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  background: #fff;
}

.inv-tab-box {
  margin: 0 20px;
  height: 39px;
  display: flex;
  border-left: 1px solid #dcdee0;
  border-bottom: 1px solid #e9ecef;
}

.inv-tab-list {
  width: 90px;
  height: 38px;
  position: relative;
  text-align: center;
  line-height: 38px;
  border-top: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-bottom: 0;
  font-size: 14px;
  cursor: pointer;
}

.inv-tab-list.active:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -3px;
  width: 90px;
  height: 6px;
  background: #fff;
  z-index: 1;
}

.invlist-box .ws-page-table .icon-operation a.blue-txt {
  color: #1b73e8;
  cursor: pointer;
}

.dea-txt {
  padding: 9px 0;
  display: flex;
  align-items: center;
  color: #666;
}

.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-txt .sp2 {
  margin-left: 50px;
}

.dea-btn-wrap {
  text-align: center;
}

.icon-download {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/download.png");
  background-size: cover;
}

.edit-bill-dialog > * {
  margin-top: 10px;
}

.edit-bill-dialog > h1 {
  color: red;
}

.edit-bill-dialog > div {
  display: flex;
  align-items: center;
}

.edit-bill-dialog > div > * {
  margin-right: 10px;
}
</style>