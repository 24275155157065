<template>
  <div class="welcome-content">
    <!--img src="../../assets/images/bg/welcome.png"-->
    <div id="water"></div>
    <div class="welcome-img">
      <!--      <img src="../../assets/images/login/wdom_logo_1.jpeg">-->
      <img src="../../assets/images/login/logo_rounded.png">
    </div>
    <p class="welcome-tip">
      欢迎使用渥康后台管理系统
    </p>
    <p class="bottom">
      <!--渥康官网-->
      <a href="https://www.wdomilk.com.cn" target="_blank">https://www.wdomilk.com.cn</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Welcome",
}
</script>

<style scoped>
.welcome-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: #a7a8bd;*/
  /*background: linear-gradient(#8f90b1,#a7a8bd);*/
  /*background-image: -webkit-radial-gradient(#8f90b1,#a7a8bd);*/
  /*background-image: radial-gradient(#8f90b1,#a7a8bd);*/
  /*background-image: radial-gradient(#7d7ea5,#a7a8bd);*/
  background-image: radial-gradient(#8fb2c9, #b2c8d9);
  width: 100%;
  height: 820px;
  position: relative;
}

.welcome-img > img {
  width: 100px;
}

.welcome-tip {
  margin-top: 20px;
  text-align: center;
  color: whitesmoke;
}

.bottom {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.bottom a {
  color: #589ecb;
}

* {
  margin: 0;
  padding: 0;
}

#water {
  position: absolute;
  width: 300px;
  height: 300px;
  /*border-radius: 43% 57% 45% 55% / 54% 59% 41% 46%;*/
  border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  box-shadow: inset 10px 20px 30px rgba(0, 0, 0, 0.5),
  10px 10px 20px rgba(0, 0, 0, 0.3),
  15px 15px 30px rgba(0, 0, 0, 0.05),
  inset -10px -10px 15px rgba(255, 255, 255, 0.8);
  animation: action 3s linear infinite alternate;
}

#water:hover {
  border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  animation: actionHover 3s linear 1 alternate;
}

#water::after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50px;
  left: 30%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 59% 41% 63% 37% / 54% 57% 43% 46%;
  animation: action1 3s linear infinite alternate;
}

#water::before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  top: 66px;
  left: 26%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 70% 30% 47% 53% / 52% 58% 42% 48%;
  animation: action2 3s linear infinite alternate;
}

@keyframes action {
  25% {
    border-radius: 43% 57% 42% 58% / 54% 52% 48% 46%;
  }
  50% {
    border-radius: 40% 60% 35% 65% / 61% 45% 55% 39%;
  }
  100% {
    border-radius: 58% 42% 47% 53% / 59% 52% 48% 41%;
  }
}

@keyframes actionHover {
  25% {
    border-radius: 58% 42% 47% 53% / 59% 52% 48% 41%;
  }
  50% {
    border-radius: 40% 60% 35% 65% / 61% 45% 55% 39%;
  }
  100% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
}

@keyframes action1 {
  25% {
    top: 49px;
    left: 29.5%;
  }
  50% {
    top: 50px;
    left: 30%;
  }
  100% {
    top: 51px;
    left: 30.5%;
  }
}

@keyframes action2 {
  25% {
    top: 65px;
    left: 26.5%;
  }
  50% {
    top: 66px;
    left: 26%;
  }
  100% {
    top: 67px;
    left: 25.5%;
  }
}
</style>