<template>
  <!-- 废弃 -->
  <div class="product-detail">
    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">基本信息</div>
      <div class="detail-form detail_img-form">
        <div class="form-item">
          <span class="form-label">商品类型：</span>
          <span class="form-value">{{ goods.goodsType }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">商品状态：</span>
          <span class="form-value">{{ goods.goodsState }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">商品名称：</span>
          <span class="form-value">{{ goods.cnName }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">商品编号：</span>
          <span class="form-value">{{ goods.goodsCode }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">商品类目：</span>
          <span class="form-value">{{ goods.categoryName }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">商品品牌：</span>
          <span class="form-value">{{ goods.brandName }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">上架时间：</span>
          <span class="form-value">{{ goods.effectiveTime }} 至 {{ goods.failureTime }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">上架平台：</span>
          <span class="form-value">{{ goods.allowOrderTypeStr }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">缩略图：</span>
          <div class="image-list" v-if="goods.pictureUrl">
            <div class="image-item">
              <el-image
                  style="width: 82px; height: 82px"
                  :src="goods.pictureUrl"
                  :preview-src-list="[goods.pictureUrl]"
              ></el-image>
            </div>
          </div>
          <span class="form-value" v-else>无</span>
        </div>
      </div>
    </div>
    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">价格信息</div>
      <div class="detail-form">
        <div class="form-item">
          <span class="form-label">零售价：</span>
          <span class="form-value">￥{{ goods.initPrice || '无' }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">底价：</span>
          <span class="form-value">￥{{ goods.basePrice || '无' }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">划线价：</span>
          <span class="form-value">￥{{ goods.markingPrice || '无' }}</span>
        </div>
      </div>
    </div>

    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">销售信息</div>
      <div class="detail-form">
        <div class="form-item">
          <span class="form-label">实际销量：</span>
          <span class="form-value">￥{{ goods.goodsInfo.actualSale || 0 }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">虚拟销量：</span>
          <span class="form-value">￥{{ goods.goodsInfo.virtualSale || 0 }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">剩余可售数量：</span>
          <span class="form-value">￥{{ goods.goodsInfo.availableNum || 0 }}</span>
        </div>
      </div>
    </div>

    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">快递信息</div>
      <div class="detail-form">
        <div class="form-item">
          <span class="form-label">运费模版：</span>
          <span class="form-value">{{ goods.goodsInfo.transportDescribe }}</span>
        </div>
        <div class="form-item">
          <span class="form-label">重量：</span>
          <span class="form-value">{{ goods.goodsInfo.weight }}公斤</span>
        </div>
      </div>
    </div>

    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">分享信息</div>
      <div class="detail-form detail_img-form">
        <div class="form-item">
          <span class="form-label">分享图：</span>
          <div class="image-list" v-if="goods.goodsInfo.sharePictureUrl">
            <div class="image-item">
              <el-image
                  style="width: 82px; height: 82px"
                  :src="goods.goodsInfo.sharePictureUrl"
                  :preview-src-list="[goods.goodsInfo.sharePictureUrl]"
              ></el-image>
            </div>
          </div>
          <span class="form-value" v-else>无</span>
        </div>
        <div class="form-item">
          <span class="form-label">分享图：</span>
          <div class="image-list" v-if="goods.goodsInfo.shareMomentsPictureUrl">
            <div class="image-item">
              <el-image
                  style="width: 82px; height: 82px"
                  :src="goods.goodsInfo.shareMomentsPictureUrl"
                  :preview-src-list="[goods.goodsInfo.shareMomentsPictureUrl]"
              ></el-image>
            </div>
          </div>
          <span class="form-value" v-else>无</span>
        </div>
        <div class="form-item">
          <span class="form-label">分享描述：</span>
          <span class="form-value">{{ goods.goodsInfo.goodsShareDescribe }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PimGoodsService from "API/pim/PimGoodsService";
import PimBrandService from "API/pim/PimBrandService";
import PimCategoryService from "API/pim/PimCategoryService";
import tools from "COMJS/tools";

export default {
  data() {
    return {
      goods: {
        goodsInfo: {}
      },
    };
  },
  mounted() {
    this.getGoodsDetail();
  },
  methods: {
    getGoodsDetail() {
      PimGoodsService.getGoodsDetail(this.$route.params.id).then(data => {
        data.goodsState = (data.goodsState == 'normal') ? '正常' : '售罄';
        data.effectiveTime = data.effectiveTime
            ? tools.formatDate(
                new Date(data.effectiveTime),
                "yyyy-MM-dd hh:mm:ss"
            )
            : "无";
        data.failureTime = data.failureTime
            ? tools.formatDate(
                new Date(data.failureTime),
                "yyyy-MM-dd hh:mm:ss"
            )
            : "无";
        let _allowOrderType = "";
        if (data.allowOrderType) {
          if (data.allowOrderType.indexOf('wmp') > -1) {
            //this.platformShelfList.push("小程序零售下单")
            _allowOrderType += "小程序零售下单，";
          }
          if (data.allowOrderType.indexOf('import') > -1) {
            _allowOrderType += "后台批量下单，";
          }
          if (data.allowOrderType.indexOf('sample') > -1) {
            _allowOrderType += "样品下单，";
          }
          if(_allowOrderType.length>0){
            _allowOrderType = _allowOrderType.substr(0,_allowOrderType.length-1)
          }
        }
        data.allowOrderTypeStr = _allowOrderType;
        this.goods = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
video {
  width: 82px;
  height: 82px;
}

.product-detail {
  .detail-item {
    margin-bottom: 40px;

    .detail-item_title {
      line-height: 60px;
      font-weight: bold;
      font-size: 18px;
      padding-left: 20px;
      background-color: #f6f9fc;
    }

    .detail-form {
      font-size: 14px;
      line-height: 20px;
      padding: 30px 20px 20px 0;

      .form-item {
        margin-bottom: 14px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        .form-label {
          display: inline-block;
          text-align: right;
          width: 100px;
        }

        .form-value {
          color: #666;
        }
      }
    }

    .detail_img-form {
      .form-item {
        margin-bottom: 30px;

        .form-label {
          display: inline-block;
          margin-right: 10px;
          margin-top: 8px;
        }

        .image-list {
          display: inline-block;
          vertical-align: top;

          .image-item {
            display: inline-block;
            margin-right: 10px;

            img {
              width: 82px;
              height: 82px;
              object-fit: fill;
            }
          }
        }
      }
    }
  }

  table {
    width: 100%;

    .th-tr {
      font-size: 18px;
      background-color: #f6f9fc;
      font-weight: bold;

      th {
        color: #8898aa;

        &:first-of-type {
          color: #333;
        }
      }
    }

    tr {
      display: block;
      font-size: 16px;
      line-height: 60px;
      padding-left: 20px;
      text-align: left;
      border-bottom: 1px solid #e9ecef;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        display: inline-block;
      }
    }

    .table-col-1 {
      width: 175px;
    }

    .table-col-2 {
      width: 400px;
    }
  }
}
</style>