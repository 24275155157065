import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import Element from 'element-ui'
import i18n from "./i18n/i18n"
import WsBase from "./components/index"
import Print from 'vue-print-nb'
import htmlToPdf from 'ASSETS/js/htmlToPdf'//生产pdf
import XLSX from 'xlsx'
import moment from 'moment' //时间戳转换
import VueDragscroll from 'vue-dragscroll'
import myUtils from "./mixin/myUtilsOnVue";

import 'ASSETS/css/common/element-variables.scss'
import 'ASSETS/css/common/ws-element.scss'
import 'ASSETS/css/common/ws-app.scss'
import "ASSETS/css/font-awesome/css/font-awesome.css";
import "ASSETS/css/font/CCSFont.css";
//wanghb20230504
import "ASSETS/css/table-common.css";
import "ASSETS/css/form-common.css";
import "ASSETS/css/dialog-common.css";
import "ASSETS/css/common.css";

window.TOKEN_KEY = 'wdomstid';
window.PPUSER = 'PPUser';
Vue.config.productionTip = false
Vue.use(Element, { size: 'small' });
Vue.use(WsBase)
Vue.use(VueDragscroll)

//mixin
Vue.mixin(myUtils);

Vue.filter('formatDate', function(dates, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dates).format(pattern)
})

//clickOutside
//不知道干啥的
Vue.directive('clickOutside', {
  bind(el, binding, vnode) {
    function clickHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = clickHandler;
    document.addEventListener("click", clickHandler);
  },
  update() { },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  }
})

/**
 * 检查是否是从商品编辑页过来的，并询问
 */
Vue.prototype.checkFromProductEditorAskBack = function (){
  let StepsData = this.$store.state.ProductStepsData;
  if (StepsData.Back) {
    this.$confirm(`是否返回商品页继续之前操作？`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.$router.push({
        path: "/pim/AddGoods"
      });
    });
  }
}
/**
 * 询问是否取消并返回上个页面
 */
Vue.prototype.cancelAskBack = function() {
  this.$confirm(`取消并返回上个页面？`, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    this.$router.go(-1);
  });
}

new Vue({
  router,
  store,
  i18n,
  Print,
  htmlToPdf,
  XLSX,
  render: h => h(App)
}).$mount('#app')
