import axiosHttp from "../axiosHttp";

export default {
    queryCustomerList(param){
        return axiosHttp.POST("/customer/queryCustomerList", param);
    },
    queryAddressList(param){
        return axiosHttp.POST("/customer/queryAddressList", param);
    },
    queryAddressPage(param){
        return axiosHttp.POST("/customer/queryAddressPage", param);
    },
    createNewAddress(param){
        return axiosHttp.POST("/customer/createNewAddress", param);
    },
    editCustomerAddress(param){
        return axiosHttp.POST("/customer/editCustomerAddress", param);
    },
    delCustomerAddress(param){
        return axiosHttp.POST("/customer/delCustomerAddress", param);
    },
    exportExcelCheckRows(list){
        return axiosHttp.POST("/customer/exportExcelCheckRows", list);
    },
    queryCustomerRelaData(param){
        return axiosHttp.POST("/customer/queryCustomerRelaData", param);
    },
    createNewRela(param){
        return axiosHttp.POST("/customer/createNewRela", param);
    },
    delCustomerRela(param){
        return axiosHttp.POST("/customer/delCustomerRela", param);
    }
};
