import axiosHttp from "../axiosHttp";

export default {
    //查询提现page
    findWithdrawCashLogPage(pageInfo, searchConfig) {
        let url = "/dimWithdrawCash/findWithdrawCashLogPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url);
    },
    //导出提现List
    exportWithdrawCashExcel(searchConfig){
        let url = "/dimWithdrawCash/exportWithdrawCashExcel?";
        url +=  searchConfig.buildQueryString();
        return axiosHttp.GET(url);
    },
    //微信支付转账
    doWechatCashById(id){
        return axiosHttp.POST('/dimWithdrawCash/doWechatCashById', id)
    },
    //批量支付标志
    updateHandleState(idList){
        return axiosHttp.POST('/dimWithdrawCash/updateHandleState', idList)
    },
    //
    exportExcel4WechatMerchantTransfer(searchConfig){
        let url = "/dimWithdrawCash/exportExcel4WechatMerchantTransfer?";
        url +=  searchConfig.buildQueryString();
        return axiosHttp.GET(url);
    },
}
