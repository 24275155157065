<template>
  <div>
    <el-dialog title="新增预留库存" :visible.sync="dialogFormVisible" width="33vw">
      <el-form :model="entity" v-loading="loading" v-if="dialogFormVisible">
        <el-form-item label="账户：" :label-width="formLabelWidth" required>
          <el-input :disabled="!userEditFlag" v-model.trim="entity.userAccount" maxlength="32"
                    placeholder="请输入账户" @blur="blurEvent(entity.userAccount)"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" :label-width="formLabelWidth">
          <el-input :disabled="true" v-model="entity.userName" maxlength="32" placeholder="输入账户自动填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="商品编码：" :label-width="formLabelWidth" required>
          <el-input v-model.trim="entity.goodsCode" maxlength="32"
                    @focus="$refs.selectGoodsDialog.dialogVisible=true"
                    placeholder="选择商品"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：" :label-width="formLabelWidth">
          <el-input :disabled="true" v-model="entity.goodsName" maxlength="32" placeholder="自动填写名称"></el-input>
        </el-form-item>
        <el-form-item label="预留数量：" :label-width="formLabelWidth" required>
          <el-input v-model="entity.goodsReserveNum"
                    @keyup.native="keyupEvent4PositiveInteger($event)"></el-input>
        </el-form-item>
        <el-form-item label="锁定库存：" :label-width="formLabelWidth">
          <el-switch v-model="entity.lockReserveOnWmsFlag"></el-switch>
        </el-form-item>
        <el-form-item label="阻止下单：" :label-width="formLabelWidth">
          <el-switch v-model="entity.rejectUseUpFlag"></el-switch>
        </el-form-item>
        <el-form-item label="特殊价格：" :label-width="formLabelWidth">
          <el-switch v-model="entity.useReservePriceFlag"></el-switch>
        </el-form-item>
        <el-form-item label="" :label-width="formLabelWidth" v-if="entity.useReservePriceFlag">
          <el-input v-model="entity.goodsReservePrice"
                    @keyup.native="keyupEvent4PositiveDecimal($event)"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hide">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>

    <select-goods-popup ref="selectGoodsDialog"
                        :selection="'single'"
                        @submit="selectConfirm"></select-goods-popup>
  </div>
</template>
<script>
import userInfoService from "API/bim/userInfoService.js";
import inputKeyEvent from "COMJS/inputKeyEvent";
import pimReserveService from "API/pim/PimReserveService"
import tools from "COMJS/tools";
import BigNumber from "COMJS/bignumber.min"
import SelectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";

export default {
  props: {
    userId: String,
  },
  components: {
    SelectGoodsPopup,
  },
  computed: {
    BigNumber() {
      return BigNumber
    }
  },
  data() {
    return {
      editMode:true,
      entity: {
        userId: "",
        userName: "",
        userAccount: "",
        goodsId: "",
        goodsCode: "",
        goodsName: "",
        goodsReserveNum: 0,
        operateType: "",//add,sub
        operateReserveNum: 0,
        lockReserveOnWmsFlag: false,
        rejectUseUpFlag: false,
        useReservePriceFlag: false,
        goodsReservePrice: 0,
      },
      dialogFormVisible: false,
      userEditFlag: true,
      formLabelWidth: "100px",
      loading: false,
    };
  },
  watch:{
    dialogFormVisible(){
      tools.clearObj(this.entity)
      if(!this.dialogFormVisible){
        return;
      }
      if (this.userId) {
        //console.log("reserveCreatePop this.userId",this.userId)
        this.userEditFlag = false;
        this.getUserDetail();
      }else{
        this.userEditFlag = true;
      }
    }
  },
  methods: {
    show(){
      this.dialogFormVisible = true
    },
    hide(){
      this.dialogFormVisible = false
    },
    keyupEvent4PositiveInteger: inputKeyEvent.keyupEvent4PositiveInteger,
    keyupEvent4PositiveDecimal: inputKeyEvent.keyupEvent4PositiveDecimal,
    selectConfirm(list) {
      if (list && list.length > 0) {
        let item = list[0]
        this.$set(this.entity, "goodsId", item.goodsId)
        this.$set(this.entity, "goodsCode", item.goodsCode)
        this.$set(this.entity, "goodsName", item.cnName)
      }
    },
    blurEvent(account) {
      if (!account) {
        return this.$message({
          message: "请输入账户",
          type: "warning"
        });
      } else {
        let params = {
          userAccount: account
        }
        userInfoService.getUser(params).then(data => {
          if (data) {
            this.entity.userId = data.userId;
            this.entity.userName = data.fullName ? data.fullName : decodeURI(data.nickName)
          } else {
            return this.$message({
              message: "未找到此账号的相关信息，请核对后重试",
              type: "warning"
            });
          }
        })
      }
    },
    getUserDetail() {
      let params = {
        userId: this.userId
      }
      userInfoService.getUser(params).then(data => {
        this.$set(this.entity, "userId", data.userId)
        this.$set(this.entity, "userAccount", data.userAccount)
        this.$set(this.entity, "userName", data.fullName ? data.fullName : decodeURI(data.nickName))
      });
    },

    submitForm() {
      if (this.entity.userAccount == "") {
        return this.$message({
          message: "请填写账户",
          type: "warning"
        });
      }
      if (this.entity.userName == "") {
        return this.$message({
          message: "请填写正确的账户",
          type: "warning"
        });
      }

      if (this.entity.goodsReserveNum <= 0) {
        return this.$message({
          message: "请填写正确的数量",
          type: "warning"
        });
      }

      let params = {
        ...this.entity,
        lockReserveOnWms: this.entity.lockReserveOnWmsFlag?'1':'0',
        rejectUseUp: this.entity.rejectUseUpFlag?'1':'0',
        useReservePrice: this.entity.useReservePriceFlag?'1':'0'
      };
      this.loading = true;
      pimReserveService.createPimReserve(params).then(res => {
        this.loading = false;
        this.$emit("updateTable");
        this.hide();
      }).catch(e=>{
        this.loading = false;
        //this.hide()
      });

    },
  }
};
</script>

<style lang="scss">
.upload-img-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: $themeColor;
  border: 1px solid #dcdee0;
  border-radius: 0px;
}

.xrequired:before {
  content: '* ';
  color: red;
}
</style>
