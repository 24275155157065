import axiosHttp from "../axiosHttp";

export default {
  /*queryWhmPages(params){
        return axiosHttp.GET('/whmSku/query',params)
    },*/
  queryWhmPages(pageInfo, searchConfig) {
    //库品分页信息
    // console.log(pageInfo)
    // console.log(searchConfig)
    let url = "/whmSku/query?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveWhm(params) {
    //保存库品信息
    return axiosHttp.POST("/whmSku/save", params, false);
  },
  detailWhm(id) {
    //获取库品详情
    return axiosHttp.GET(`/whmSku/findById/${id}`);
  },
  queryCategoryWhm(params) {
    //获取库品分类
    return axiosHttp.POST("/whmTingType/queryToHierarchy", params, false);
  },
  updStateWhm(params) {
    //是否启用该库品
    return axiosHttp.POST("/whmSku/updEnable", params, false);
  },
  queryUnitPages(searchConfig) {
    //选择库品单位
    return axiosHttp.GET(`/whmSpecUnit/queryToUnit/${searchConfig}`);
  },
  querySkuspecPages(searchConfig) {
    //库品规格值
    return axiosHttp.GET(`/whmSpecUnit/queryToSpec/${searchConfig}`);
  },
  querySkuspecnamePages(searchConfig) {
    //库品规格名
    return axiosHttp.GET(`/whmSpecUnit/queryToSpecName/${searchConfig}`);
  },
  queryWhmInfo(skuId) {
    //库品规格名
    return axiosHttp.GET(`/whmSku/findById/${skuId}`);
  },
  queryFisrtWhm() {
    //获取库品分类
    return axiosHttp.GET("/whmTingType/queryByStair");
  },
  getChildrenCategory(parentId) {
    //通过父级获取子集信息
    return axiosHttp.GET(`/whmTingType/queryByParent/${parentId}`);
  },
  getCategoryDetail(id) {
    //查询分类的详情
    return axiosHttp.GET(`/whmTingType/findById/${id}`);
  },
  saveCategory(params) {
    //保存分类信息
    return axiosHttp.POST("/whmTingType/save", params, false);
  },
  deleteCategory(id) {
    //删除分类信息
    return axiosHttp.GET(`/whmTingType/delById/${id}`);
  },
  queryUnitSpecPages(pageInfo, searchConfig) {
    //单位、规格分页信息
    let url = "/whmSpecUnit/queryAtPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveUnitSpec(params) {
    //保存、修改 单位、规格信息
    return axiosHttp.POST("/whmSpecUnit/save", params, false);
  },
  updateUnitEnable(params) {
    return axiosHttp.POST("/whmSpecUnit/updByEnabled", params, false);
  },
  whmSupplier(pageInfo, searchConfig) {
    //供应商列表
    let url = "/whmSupplier/queryAtPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  updateSupplierEnable(params) {
    return axiosHttp.POST("/whmSupplier/updByEnabled", params, false);
  },
  //whmSupplier/save
  saveSupplier(params) {
    //保存供应商信息
    return axiosHttp.POST("/whmSupplier/save", params, false);
  },
  //whmSupplier/findById
  detailSupplier(id) {
    //删除分类信息
    return axiosHttp.GET(`/whmSupplier/findById/${id}`);
  },
  whmReceive(pageInfo, searchConfig) {
    //分页查询领用人列表
    let url = "/whmReceive/queryAtPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  //whmReceive/save
  saveReceive(params) {
    //新增领用人信息
    return axiosHttp.POST("/whmReceive/save", params, false);
  },
  //whmReceive/updToEnabled
  updateReceiveEnable(params) {
    return axiosHttp.POST("/whmReceive/updToEnabled", params, false);
  },
  //whmOutbound/logistics/page 物流出库单列表
  queryLogisticsPages(pageInfo, searchConfig) {
    let url = "/whmOutbound/logistics/page?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  //whmOutbound/logistics/transportDetail
  detailLogistics(searchConfig) {
    //return axiosHttp.GET(`/whmOutbound/logistics/detail/${searchConfig}`);
    return axiosHttp.GET(
      `/whmOutbound/logistics/transportDetail/${searchConfig}`
    );
  },

  // 查询默认发货方
  findDefault () {
    return axiosHttp.POST("/whmOutboundSenderInfo/findDefault");
  },

  //whmOutbound/importTransportCode导入运单号
  importTransportCode(params) {
    return axiosHttp.POST("/whmOutbound/importTransportCode", params, false);
  },
  //whmOutbound/importTransport/log/page
  queryImportTransportList(pageInfo, searchConfig) {
    let url = "/whmOutbound/importTransport/log/page?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  ///logisticsOrder/listOrderPage 物流订单分页接口
  queryLogisticsOrder(params) {
    return axiosHttp.POST("/logisticsOrder/listOrderPage", params, false);
  },
  //whmInventory/queryBySkuToBatch 查询sku的批次集
  queryBySkuToBatch(searchConfig) {
    return axiosHttp.GET(`/whmInventory/queryBySkuToBatch/${searchConfig}`);
  },
  //logisticsOrder/agentCreateLogisticsOrder 下单接口
  agentCreateLogisticsOrder(params) {
    return axiosHttp.POST(
      "/logisticsOrder/agentCreateLogisticsOrder",
      params,
      false
    );
  },
  // pageOrderGoodsStore(params){
  //     return axiosHttp.POST('/logisticsOrder/pageOrderGoodsStore', params, false);
  // },
  pageOrderGoodsStore(pageInfo, searchConfig) {
    let url = "/logisticsOrder/pageOrderGoodsStore?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveOrderGoodsStore(params) {
    return axiosHttp.POST("/logisticsOrder/logisticsSendOut", params, false);
  },
  //wwhmOutboundSenderInfo/page //发货信息列表
  pageOutboundList(params) {
    return axiosHttp.POST("/whmOutboundSenderInfo/page", params, false);
  },
  //modifySenderInfo/save //保存发货信息
  saveOutbound(params) {
    return axiosHttp.POST("/whmOutboundSenderInfo/save", params, false);
  },
  //whmOutboundSenderInfo/modifySenderInfo //编辑发货信息
  editOutbound(params) {
    return axiosHttp.POST(
      "/whmOutboundSenderInfo/modifySenderInfo",
      params,
      false
    );
  },
  //whmInventoryReserved/queryAtPage 查询库品预留接口
  queryReservedList(pageInfo, searchConfig) {
    let url = "/whmInventoryReserved/queryAtPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  //物流出库单详情--商品列表 whmOutbound/logistics/goods/page
  queryLogisticsDetail(pageInfo, searchConfig) {
    //let url = "/whmOutbound/logistics/detail/page?"
    let url = "/whmOutbound/logistics/goods/page?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  //whmOutbound/logistics/deliverGoods //物流出库单发货
  outDeliverGoods(params) {
    return axiosHttp.POST("/whmOutbound/logistics/deliverGoods", params, false);
  },
  //whmOutbound/logistics/download下载pdf接口
  downloadLogistics(searchConfig) {
    return axiosHttp.GET(`/whmOutbound/logistics/${searchConfig}`);
  },
  //whmOutbound/logistics/receiverGoods //物流收货
  outReceiverGoods(params) {
    return axiosHttp.POST(
      "/whmOutbound/logistics/receiverGoods",
      params,
      false
    );
  },
  //whmInventoryReserved/querySkuReservedInfo 预留统计列表
  querySkuReservedInfo(params) {
    return axiosHttp.POST(
      "/whmInventoryReserved/querySkuReservedInfo",
      params,
      false
    );
  },
  //whmInventoryReserved/exportInvReserveds 库品预留数据导出Excel
  exportInvReserveds(params) {
    return axiosHttp.POST(
      "/whmInventoryReserved/exportInvReserveds",
      params,
      false
    );
  },
  //whmOutbound/logistics/receiverGoods/evidence
  getEvidenceImg(searchConfig) {
    return axiosHttp.GET(
      `/whmOutbound/logistics/receiverGoods/evidence/${searchConfig}`
    );
  },
  //whmOutbound/likelogistics/transport/modify 修改物流出库单发货详情
  editTransport(params) {
    return axiosHttp.POST(
      "/whmOutbound/logistics/transport/modify",
      params,
      false
    );
  },
  // 关联商品列表
  queryGoodsBySkuId(pageInfo, searchConfig) {
    let url = `/whmGoodsToSku/queryGoodsBySkuId?`;
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  //一键下架
  goodsModifyOffShelf(params) {
    return axiosHttp.POST(
      "/pimGoods/goodsModifyOffShelf",
      params,
      false
    );
  },
  //一键上架
  goodsModifyOnShelf(params) {
    return axiosHttp.POST(
      "/pimGoods/goodsModifyOnShelf",
      params,
      false
    );
  },
  //商品改状态为售罄
  goodsModifySellOut(params) {
    return axiosHttp.POST(
      "/pimGoods/goodsModifySellOut",
      params,
      false
    );
  },
  //商品改状态为可售
  goodsModifyOnSale(params) {
    return axiosHttp.POST(
      "/pimGoods/goodsModifyOnSale",
      params,
      false
    );
  },
};
