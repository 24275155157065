<script src="../../../../vue.config.js"></script>
<template>
    <div>
        <el-form ref="tableData" :model="tableData" fit="true" class="filter-form">
            <div class="filter_left">
                <div class="form-row">
                    <el-form-item prop="campaignType">
                        <span class="form-label">活动类型：</span>
                        <el-select v-model="tableData.campaignType" placeholder="请选择" @change="selectGet">
                            <el-option :disabled="seeDetail" v-for="item in ActivityType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="form-row">
                    <el-form-item class="form-item" prop="campaignName">
                        <span class="form-label">活动名称：</span>
                        <el-input placeholder="活动名称" size="mini" :disabled="seeDetail" v-model="tableData.campaignName"></el-input>
                    </el-form-item>
                </div>
                <div class="form-row" v-if="tableData.campaignType == 'limit'">
                    <span class="form-label">活动描述：</span>
                    <el-form-item class="form-item">
                        <el-input maxlength="15" :disabled="seeDetail" v-model="tableData.remark" type="textarea"></el-input>
                    </el-form-item>
                </div>
                <div class="form-row">
                    <el-form-item class="form-item">
                        <span class="form-label">活动时间：</span>
                        <el-date-picker :disabled="seeDetail" v-model="TimeArr" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="form-row">
                    <el-form-item class="form-item" prop="label">
                        <span class="form-label">活动标签：</span>
                        <el-input placeholder="活动标签" maxlength="6" show-word-limit :disabled="seeDetail" size="mini" v-model="tableData.label"></el-input>
                    </el-form-item>
                </div>
                <div class="form-row" v-if="tableData.campaignType == 'reduction'">
                    <el-form-item prop="couponBearer">
                        <span class="form-label">优惠承担：</span>
                        <el-select v-model="tableData.couponBearer" placeholder="请选择">
                            <el-option :disabled="seeDetail" v-for="item in couponBearer" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="form-row" v-if="tableData.campaignType == 'presell'">
                    <el-form-item prop="presellTime">
                        <span class="form-label">发货时间：</span>
                        <el-date-picker :disabled="seeDetail" v-model="tableData.presellTime" type="datetime" placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="form-row">
                    <el-form-item class="form-item">
                        <span class="form-label">活动商品：</span>
                        <el-tag color="rgba(255, 255, 255, 1)" style="margin-left: 5px" v-for="tag in selected" :key="tag" closable type="info" :disable-transitions="true" @close="handleClose(tag)"
                            effect="plain">
                            {{ tag }}
                        </el-tag>
                    </el-form-item>
                    <el-button :disabled="seeDetail" size="mini" plain type="primary" @click="$refs.selectGoodsPopup.dialogVisible = true">添加商品
                    </el-button>
                </div>
                <div class="form-row" v-if="tableData.campaignType == 'limit'">
                    <el-form-item class="form-item" prop="label">
                        <span class="form-label">总数限购：</span>
                        <el-input placeholder="总数限购" :disabled="seeDetail" size="mini" v-model="tableData.limitCount" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-form-item>
                </div>
                <div class="form-row" v-if="tableData.campaignType == 'limit'">
                    <el-form-item class="form-item" prop="label">
                        <span class="form-label"> </span>
                        <span class="tips">如选择限购多个商品，限购数量须小于所选商品中实际可售数量最小的商品数。</span>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <template v-if="tableData.campaignType == 'reduction'">
            <div class="box-shadow-unify">
                <div class="form-title">
                    <p>活动详情</p>
                    <el-button @click="open" type="primary" :disabled="seeDetail">添加优惠</el-button>
                </div>
            </div>
            <el-table border style="font-size: 12px" :data="tableData.couponList" class="box-shadow-unify">
                <el-table-column align="center" prop="title" label="优惠名称"></el-table-column>
                <el-table-column align="center" prop="note" label="优惠描述"></el-table-column>
                <el-table-column align="center" prop="couponType" label="优惠类型">
                    <template slot-scope="scope">
                        <p v-if="
                tableData.couponList[scope.$index].couponType ==
                  'full_reduction_coupon'
              ">
                            现金券
                        </p>
                        <p v-else>满减</p>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="优惠金额">
                    <template slot-scope="scope">
                        <p>
                            <span>满{{ scope.row.couponNorm1 }}</span><span>减{{ scope.row.couponAmount1 }}</span>
                        </p>
                        <p v-if="
                tableData.couponList[scope.$index].couponNorm2 &&
                  tableData.couponList[scope.$index].couponAmount2
              ">
                            <span>满{{ scope.row.couponNorm2 }}</span><span>减{{ scope.row.couponAmount2 }}</span>
                        </p>
                        <p v-if="
                tableData.couponList[scope.$index].couponNorm3 &&
                  tableData.couponList[scope.$index].couponAmount3
              ">
                            <span>满{{ scope.row.couponNorm3 }}</span><span>减{{ scope.row.couponAmount3 }}</span>
                        </p>
                        <p v-if="
                tableData.couponList[scope.$index].couponNorm4 &&
                  tableData.couponList[scope.$index].couponAmount4
              ">
                            <span>满{{ scope.row.couponNorm4 }}</span><span>减{{ scope.row.couponAmount4 }}</span>
                        </p>
                        <p v-if="
                tableData.couponList[scope.$index].couponNorm5 &&
                  tableData.couponList[scope.$index].couponAmount5
              ">
                            <span>满{{ scope.row.couponNorm5 }}</span><span>减{{ scope.row.couponAmount5 }}</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="isDistributionShare" label="分发规则">
                    <template slot-scope="scope">
                        <p v-if="
                tableData.couponList[scope.$index].isDistributionShare == '1'
              ">
                            会员分享
                        </p>
                        <p v-else>自动发放</p>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip align="center" prop="eventName" label="可见时间">
                    <template slot-scope="scope">
                        <span>{{
              Number(scope.row.beginTime) | formatDate("YYYY-MM-DD HH:mm:ss")
            }}</span>~<span>{{
              Number(scope.row.endTime) | formatDate("YYYY-MM-DD HH:mm:ss")
            }}</span></template>
                </el-table-column>
                <el-table-column v-if="!seeDetail" align="center" prop="eventName" label="操作">
                    <template slot-scope="scope">
                        <a href="javascript: void(0);" @click="goDetail(scope.$index)" title="编辑">
                            <i style="color: rgba(70, 141, 234, 1)" class="el-icon-edit"></i> </a><a href="javascript: void(0);" @click="goDetele(scope.$index)" title="删除">
                            <i style="color: rgba(70, 141, 234, 1); margin-left: 10px" class="el-icon-delete-solid"></i> </a></template>
                </el-table-column>
            </el-table>
        </template>
        <template v-if="tableData.campaignType == 'limit'">
            <div class="box-shadow-unify">
                <div class="form-title">
                    <p>ID限购</p>
                    <el-button type="primary" :disabled="seeDetail" @click="AddRules">添加规则</el-button>
                </div>
            </div>
            <el-table border style="font-size: 12px" :data="tableData.campaignLimitList" class="box-shadow-unify">
                <el-table-column align="center" label="限购频率">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.limitTimeFrame" placeholder="请选择" :disabled="seeDetail">
                            <el-option v-for="item in limitTimeFrame" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="限购数量">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.limitNum" placeholder="限购数量" :disabled="seeDetail" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="限购角色" width="700px">
                    <template slot-scope="scope">
                        <el-radio :disabled="seeDetail" v-for="(item, index) in userList" :key="index" v-model="scope.row.limitInfo" :label="item.value">{{ item.label }}</el-radio>
                        <el-input :disabled="seeDetail" style="width:100px" v-if="scope.row.limitInfo == 'appoint'" v-model="scope.row.userId" @focus="openDialog(scope.$index)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <i @click="rulesDetele(scope.$index)" style="color: rgba(70, 141, 234, 1); margin-left: 10px" class="el-icon-delete-solid"></i><a href="javascript: void(0);" title="删除"></a>
                    </template>
                </el-table-column>
            </el-table>
            <div class="notice">
                注意事项：<br>
                1.总数限购、ID限购可选填一项或两项都填；<br>
                2.同一活动内，同一商品如总数限购与ID限购数量有冲突，限购总数优先于限购账号优先于限购角色；<br>
                3.不同活动内，同一商品在不同活动中限购数量有冲突，以限购数量低的为准。<br>
            </div>
        </template>
        <chooseUserPop ref="openDialog1" @UserData="getUserData"></chooseUserPop>
        <select-goods-popup ref="selectGoodsPopup" @submit="selectGoods"></select-goods-popup>
        <div class="bottomBtn">
            <el-button type="primary" :disabled="seeDetail || !tableData.campaignType" @click.native="preservation">保存</el-button>
            <el-button @click="cancel">取消</el-button>
        </div>
        <activity-edit-popup @couponListObj="couponListObjValue" :show.sync="show" ref="child" :passInData="this.tableData"></activity-edit-popup>
    </div>
</template>

<script>
let moment = require("moment");
import selectGoodsPopup from "../../pim/product/spu/selectGoodsPopup";
import BimActivity from "@/api/bim/BimActivity";
import activityEditPopup from "./activityEditPopup.vue";
// import DimAccountDetailsService from "API/dim/DimAccountDetailsService.js";
import chooseUserPop from "./chooseUserPop.vue";
export default {
    data() {
        return {
            seeDetail: false,
            visible: false,
            show: false,
            selected: [],
            TimeArr: [],
            tableData: {
                campaignId: "",
                campaignName: "",
                campaignType: "",
                couponBearer: "",
                beginTime: "",
                endTime: "",
                label: "",
                remark: "",
                presellTime: "",
                couponList: [],
                campaignCouponGoodsRelaList: [],
                campaignLimitList: [],
            },
            ActivityType: [
                {
                    label: "优惠活动",
                    value: "reduction",
                },
                {
                    label: "预售活动",
                    value: "presell",
                },
                {
                    label: "限购活动",
                    value: "limit",
                },
            ],
            couponBearer: [
                {
                    label: "仅会员",
                    value: "1",
                },
                {
                    label: "会员+公司",
                    value: "2",
                },
            ],
            limitTimeFrame: [
                {
                    label: "每自然年",
                    value: "year",
                },
                {
                    label: "每自然季度",
                    value: "quarter",
                },
                {
                    label: "每自然月",
                    value: "month",
                },
                {
                    label: "每自然周",
                    value: "week",
                },
                {
                    label: "每自然日",
                    value: "day",
                },
            ],
            userList: [
                {
                    label: "金星会员",
                    value: "distributor_third",
                },
                {
                    label: "银星会员",
                    value: "distributor_second",
                },
                {
                    label: "普标会员",
                    value: "distributor_first",
                },
                {
                    label: "普通用户",
                    value: "consumer",
                },
                {
                    label: "指定用户",
                    value: "appoint",
                },
            ],
        };
    },
    components: {
        selectGoodsPopup,
        activityEditPopup,
        chooseUserPop,
    },
    methods: {
        openDialog(index) {
            this.$refs.openDialog1.$emit("openDialog", index);
        },
        getUserData(data) {
            console.log(data);
            this.tableData.campaignLimitList[data.index].userId =
                data.Useritem.userId;
        },
        AddRules() {
            let params = {
                limitTimeFrame: "",
                limitNum: "",
                limitInfo: "",
                limitType: "",
                userId: "",
            };
            console.log(this.tableData);
            this.tableData.campaignLimitList.push(params);
        },
        open() {
            this.index = -1;
            this.show = true;
            this.$refs.child.emptyFrom();
        },
        selectGoods(selected) {
            let params = [];
            for (let i in selected) {
                params.push(selected[i].goodsId);
            }
            BimActivity.skuInvMatrixingGoodsInv(params).then((data) => {
                let tableData = this.tableData;
                let selectedList = this.selected;
                for (let i in selected) {
                    for (let j in data) {
                        if (selected[i].goodsId == data[j].goodsId) {
                            selected[i].goodsNum = data[j].goodsNum;
                        }
                    }
                    if (this.tableData.campaignType == "limit") {
                        selectedList.push(
                            selected[i].goodsCode +
                                "可售(" +
                                (selected[i].goodsNum
                                    ? selected[i].goodsNum
                                    : "0") +
                                ")"
                        );
                    } else {
                        selectedList.push(selected[i].goodsCode);
                    }

                    let Obj = {
                        goodsId: selected[i].goodsId,
                    };
                    tableData.campaignCouponGoodsRelaList.push(Obj);
                }
                //数组去重
                this.selected = [...new Set(selectedList)];

                let obj = {};
                this.tableData.campaignCouponGoodsRelaList =
                    this.tableData.campaignCouponGoodsRelaList.reduce(
                        (cur, next) => {
                            obj[next.goodsId]
                                ? ""
                                : (obj[next.goodsId] = true && cur.push(next));
                            return cur;
                        },
                        []
                    );
            });
        },

        // 多选栏中的删除
        handleClose(tag) {
            const item = this.selected.indexOf(tag);
            this.selected.splice(item, 1);
            this.tableData.campaignCouponGoodsRelaList.splice(item, 1);
            // console.log(this.selected);
        },
        //保存
        preservation() {
            let that = this;
            if (this.tableData.campaignType == "") {
                return this.$message({
                    message: "请选择活动类型",
                    type: "warning",
                });
            }
            if (this.tableData.campaignName == "") {
                return this.$message({
                    message: "请输入名称",
                    type: "warning",
                });
            }
            if (this.tableData.campaignType == "limit") {
                if (this.tableData.remark == "") {
                    return this.$message({
                        message: "请输入描述",
                        type: "warning",
                    });
                }
            }
            if (this.TimeArr == "") {
                return this.$message({
                    message: "请选择活动时间",
                    type: "warning",
                });
            }
            if (this.tableData.label == "") {
                return this.$message({
                    message: "请输入活动标签",
                    type: "warning",
                });
            }

            if (this.tableData.campaignType == "reduction") {
                if (this.tableData.couponBearer == "") {
                    return this.$message({
                        message: "请选择优惠承担",
                        type: "warning",
                    });
                }
            }

            if (this.tableData.campaignType == "presell") {
                if (this.tableData.presellTime == "") {
                    return this.$message({
                        message: "请选择发货时间",
                        type: "warning",
                    });
                }
            }
            if (this.tableData.campaignCouponGoodsRelaList == "") {
                return this.$message({
                    message: "请选择活动商品",
                    type: "warning",
                });
            }

            if (this.tableData.campaignType == "limit") {
                if (this.tableData.limitCount == "") {
                    if (this.tableData.campaignLimitList.length < 1) {
                        return this.$message({
                            message: "总数限购或者必须选填其中一项",
                            type: "warning",
                        });
                    }
                }
            }
            if (this.tableData.campaignType == "limit") {
                if (this.tableData.campaignLimitList.length > 0) {
                    for (let i in this.tableData.campaignLimitList) {
                        if (
                            this.tableData.campaignLimitList[i]
                                .limitTimeFrame == ""
                        ) {
                            return this.$message({
                                message: "限购频率填写不完整",
                                type: "warning",
                            });
                        }
                        if (
                            this.tableData.campaignLimitList[i].limitNum == ""
                        ) {
                            return this.$message({
                                message: "限购数量填写不完整",
                                type: "warning",
                            });
                        }
                        if (
                            this.tableData.campaignLimitList[i]
                                .limitTimeFrame == ""
                        ) {
                            return this.$message({
                                message: "限购角色填写不完整",
                                type: "warning",
                            });
                        }
                    }
                    for (
                        let i = 1;
                        i < this.tableData.campaignLimitList.length;
                        i++
                    ) {
                        if (
                            this.tableData.campaignLimitList[0].limitInfo +
                                "_" +
                                this.tableData.campaignLimitList[0]
                                    .limitTimeFrame ==
                            this.tableData.campaignLimitList[i].limitInfo +
                                "_" +
                                this.tableData.campaignLimitList[i]
                                    .limitTimeFrame
                        ) {
                            return this.$message({
                                message: "有相同的限购规则，请确认后重试！",
                                type: "warning",
                            });
                        }
                    }
                }
            }
            this.tableData.beginTime = moment(this.TimeArr[0]).valueOf();
            this.tableData.endTime = moment(this.TimeArr[1]).valueOf();
            if (this.tableData.campaignType == "limit") {
                for (let i in this.tableData.campaignLimitList) {
                    if (
                        this.tableData.campaignLimitList[i].limitInfo ==
                        "appoint"
                    ) {
                        this.tableData.campaignLimitList[i].limitInfo =
                            this.tableData.campaignLimitList[i].userId;
                        this.tableData.campaignLimitList[i].limitType = 2;
                    } else {
                        this.tableData.campaignLimitList[i].limitType = 1;
                    }
                }
            }
            let params = this.tableData;
            console.log(params);

            BimActivity.save(params)
                .then((res) => {
                    this.$message({ type: "success", message: "保存成功" });

                    setTimeout(function () {
                        that.$router.push({
                            path: "/bim/CommodityActivities",
                        });
                    }, 1000);
                })
                .catch((err) => {
                    this.errorMessage = err.response.data.msg;
                });
        },

        cancel() {
            this.$router.push({
                path: "/bim/CommodityActivities",
            });
        },
        couponListObjValue(e) {
            console.log(e);
            let Obj = e;
            Obj.mayReceiveNum = "1";
            Obj.mayUseNum = "1";
            if (this.index >= 0) {
                this.tableData.couponList[this.index] = Obj;
                this.show = false;
                this.tableData.couponList = this.tableData.couponList.filter(
                    (item) => item
                );
            } else {
                console.log(Obj);
                Obj.couponId = "";
                console.log(this.tableData);
                this.tableData.couponList.push(Obj);
                this.show = false;
            }
        },
        goDetail(index) {
            this.index = index;
            console.log(index);
            this.$refs.child.editcouponList(index);
            this.show = true;
        },
        goDetele(index) {
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let couponId = this.tableData.couponList[index].couponId;
                if (couponId) {
                    BimActivity.delById(couponId).then((data) => {
                        this.tableData.couponList.splice(index, 1);
                        this.$message({ type: "success", message: "删除成功" });
                    });
                } else {
                    this.tableData.couponList.splice(index, 1);
                }
            });
        },

        rulesDetele(index) {
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let campaignLimitId =
                    this.tableData.campaignLimitList[index].campaignLimitId;
                if (campaignLimitId) {
                    BimActivity.delByLimitId(campaignLimitId).then((data) => {
                        this.tableData.campaignLimitList.splice(index, 1);
                        this.$message({ type: "success", message: "删除成功" });
                    });
                } else {
                    this.tableData.campaignLimitList.splice(index, 1);
                }
            });
        },

        selectGet(e) {
            if (e == "presell") {
                let table = this.tableData;
                table.couponList = [];
                table.couponBearer = "";
            }
        },
    },
    activated() {
        let id = this.$route.query.id;
        if (this.$route.query.seeDetail) {
            this.seeDetail = true;
        } else {
            this.seeDetail = false;
        }
        if (id) {
            BimActivity.findById(id).then((data) => {
                console.log(data);
                if (data.couponList == null) {
                    data.couponList = [];
                }
                if (data.campaignType == "limit") {
                    for (let i in data.campaignLimitList) {
                        if (data.campaignLimitList[i].limitType == "2") {
                            data.campaignLimitList[i].userId =
                                data.campaignLimitList[i].limitInfo;
                            data.campaignLimitList[i].limitInfo = "appoint";
                        }
                    }
                    if (!data.campaignLimitList) {
                        data.campaignLimitList = [];
                    }
                }
                this.TimeArr = [
                    moment(data.beginTime).format("YYYY-MM-DD HH:mm:ss"),
                    moment(data.endTime).format("YYYY-MM-DD HH:mm:ss"),
                ];
                let Arr = [];
                let params = [];
                for (let i in data.campaignCouponGoodsRelaList) {
                    let Obj = {
                        goodsId: data.campaignCouponGoodsRelaList[i].goodsId,
                    };
                    params.push(data.campaignCouponGoodsRelaList[i].goodsId);
                    Arr.push(Obj);
                }
                this.tableData.campaignCouponGoodsRelaList = Arr;
                BimActivity.skuInvMatrixingGoodsInv(params).then((res) => {
                    let CodeArr = [];
                    for (let j in res) {
                        for (let i in data.campaignCouponGoodsRelaList) {
                            if (
                                data.campaignCouponGoodsRelaList[i].goodsId ==
                                res[j].goodsId
                            ) {
                                if (data.campaignType == "limit") {
                                    CodeArr.push(
                                        data.campaignCouponGoodsRelaList[i]
                                            .goodsCode +
                                            "可售（" +
                                            (res[j].goodsNum
                                                ? res[j].goodsNum
                                                : "0") +
                                            ")"
                                    );
                                } else {
                                    CodeArr.push(
                                        data.campaignCouponGoodsRelaList[i]
                                            .goodsCode
                                    );
                                }
                            }
                        }
                    }

                    this.selected = CodeArr;
                });
                this.tableData = data;
            });
        }
        if (localStorage.getItem("newlyBuild")) {
            (this.tableData = {
                campaignId: "",
                campaignName: "",
                beginTime: "",
                endTime: "",
                label: "",
                presellTime: "",
                couponList: [],
                campaignCouponGoodsRelaList: [],
                campaignLimitList: [],
            }),
                (this.TimeArr = []),
                (this.selected = []);
            localStorage.removeItem("newlyBuild");
        }
    },
};
</script>

<style scoped>
.form-title {
    width: 100%;
    height: 60px;
    font-size: 16px;
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: 26px;
    margin-right: 30px;
    /* display: inline-block; */
}

.form-title > button {
    margin-left: 30px;
}

.fullMinus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.titleRow {
    height: 60px;
    background-color: rgba(246, 249, 252, 1);
    font-size: 14px;
    color: rgba(136, 152, 170, 1);
}

.contentRow {
    height: 90px;
    color: rgba(102, 102, 102, 1);
}

.bottomBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.tips {
    position: relative;
    bottom: 30px;
    left: 70px;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
}

.notice {
    margin: 50px 0px;
    width: 800px;
    white-space: pre-wrap;
    line-height: 30px;
}
</style>
