import axiosHttp from "../axiosHttp";

export default {
    //统计
    getInvoiceStatistics(){
        return axiosHttp.GET('/somInvoice/getInvoiceStatistics',null);
    },
    // 发票分页查询
    queryInvoicePages(pageInfo) {
        return axiosHttp.POST('/somInvoice/pageQueryInvoice',pageInfo,false);
    },
    //处理
    confirmHandle(invoice){
        return axiosHttp.POST('/somInvoice/confirmHandle',invoice,false);
    },
    cancelHandle(invoice){
        return axiosHttp.POST('/somInvoice/cancelHandle',invoice,false);
    },
    //导出
    exportSomInvoice(param) {
        return axiosHttp.POST('/somInvoice/exportInvoice',param,false);
    },
    queryDetail(data) {
        return axiosHttp.POST('somInvoice/queryDetail',data,false)
    },
    listOrderPageThisInvoice(data) {
        return axiosHttp.POST('somInvoice/listOrderPageThisInvoice',data,false)
    },

    getInvoiceSizeAndAmount(param){
        return axiosHttp.POST('/somInvoice/getInvoiceSizeAndAmount',param,false);
    },
    createSomInvoice(data){
        return axiosHttp.POST('/somInvoice/createSomInvoice',data)
    },
    getGiroInfoByOrderIdList(orderIdList) {
        let param = {
            orderIdList:orderIdList
        }
        return axiosHttp.POST('/somInvoice/getGiroInfoByOrderIdList',param)
    },
}