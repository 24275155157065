<template>
  <el-dialog title="用户角色管理" :visible.sync="show" width="50%">
    <div v-if="!targetUserId" style="margin-bottom: 20px;width: 250px;">
      <el-input placeholder="通过账号搜索" v-model="targetUserAccount">
        <el-button slot="append" icon="el-icon-search" @click="queryTargetUser"></el-button>
      </el-input>
    </div>
    <div>
      <el-descriptions title="用户信息">
        <el-descriptions-item label="姓名">{{ targetUserInfo.fullName || '' }}</el-descriptions-item>
        <el-descriptions-item label="账号">{{ targetUserInfo.userAccount }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-if="!(!targetUserInfo.userId)">
      <el-divider><i class="el-icon-lock"></i></el-divider>
      <div>
        <el-checkbox-group v-model="sysLockRoleListCheck">
          <el-checkbox
              :label="item.roleCode" disabled
              v-for="(item,idx) in sysLockRoleList" :key="idx">{{ item.roleName }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div v-show="targetUserInfo.roleCodes.indexOf('agent')>-1">
        <el-divider><i class="el-icon-unlock"></i></el-divider>
        <div>
          <el-checkbox-group v-model="sysUnLockRoleListCheck">
            <el-checkbox :label="item.roleCode" v-for="(item,idx) in sysUnLockRoleList" :key="idx">{{ item.roleName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!--v-show="targetUserInfo.roleCodes.indexOf('shop_admin')>-1"-->
      <div v-show="targetUserInfo.roleCodes.indexOf('agent')<0
        && targetUserInfo.roleCodes.indexOf('distributor')<0">
        <el-divider><i class="el-icon-unlock"></i></el-divider>
        <div>
          <el-checkbox-group v-model="customRoleListCheck">
            <el-checkbox :label="item.roleCode" v-for="(item,idx) in customRoleList" :key="idx">{{ item.roleName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div style="width: 100%;text-align: center;margin-top: 40px;">
        <el-button type="primary" @click="saveAuth">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import userInfoService from "API/bim/userInfoService";
import bimRoleService from "API/ba/BimRoleService";

export default {
  name: "userRoleManager",
  data() {
    return {
      show: false,
      //
      targetUserId:'',
      targetUserAccount: '',
      targetUserInfo: {},
      //
      customRoleList:[],
      customRoleListCheck:[],
      sysLockRoleList:[],
      sysLockRoleListCheck:[],
      sysUnLockRoleList:[],
      sysUnLockRoleListCheck:[],
    }
  },
  mounted() {
    this.queryRoleList();
    this.$on("openDialog", (targetUserId) => {
      this.show = true;
      this.targetUserId = targetUserId;
      this.queryTargetUser()
    });
  },
  methods: {
    queryTargetUser() {
      this.targetUserInfo = {}
      this.sysLockRoleListCheck = []
      this.sysUnLockRoleListCheck = []
      this.customRoleListCheck = []
      //取新的
      if(!this.targetUserId && !this.targetUserAccount){
        return;
      }
      let param = {
        userId: !this.targetUserId ? '' : this.targetUserId,
        userAccount: !this.targetUserAccount ? '' : this.targetUserAccount
      }
      userInfoService.getUser(param).then(res => {
        if(res){
          this.targetUserInfo = res;
          let arr = res.roleCodes.split(",");
          let customCheck = []
          let sysLockCheck = []
          let sysUnLockCheck = []
          for (let arrElement of arr) {
            for (let customRoleListElement of this.customRoleList) {
              if(arrElement==customRoleListElement.roleCode){
                customCheck.push(arrElement);
                break;
              }
            }
            for (let sysRoleListElement of this.sysLockRoleList) {
              if(arrElement==sysRoleListElement.roleCode){
                sysLockCheck.push(arrElement);
                break;
              }
            }
            for (let sysRoleListElement of this.sysUnLockRoleList) {
              if(arrElement==sysRoleListElement.roleCode){
                sysUnLockCheck.push(arrElement);
                break;
              }
            }
          }
          this.customRoleListCheck = customCheck
          this.sysLockRoleListCheck = sysLockCheck
          this.sysUnLockRoleListCheck = sysUnLockCheck
        }else{
          this.$message.error("未找到目标用户");
        }
      })
    },
    queryRoleList(){
      this.customRoleList = []
      this.sysLockRoleList = []
      this.sysUnLockRoleList = []
      bimRoleService.getAllRoleList().then(res=>{
        this.sysLockRoleList = res.filter(e=>e.roleSystem=='1' && e.roleLock=='1')
        this.sysUnLockRoleList = res.filter(e=>e.roleSystem=='1' && e.roleLock=='0')
        this.customRoleList = res.filter(e=>e.roleSystem=='0')
      })
    },
    saveAuth(){
      if(!this.targetUserInfo.userId){
        return;
      }
      let roleCodes = ""
      if(this.sysLockRoleListCheck.length>0){
        roleCodes += this.sysLockRoleListCheck.join(',') + ","
      }
      if(this.sysUnLockRoleListCheck.length>0){
        roleCodes += this.sysUnLockRoleListCheck.join(',') + ","
      }
      if(this.customRoleListCheck.length>0){
        roleCodes += this.customRoleListCheck.join(',') + ","
      }
      if(roleCodes.length>0){
        roleCodes = roleCodes.substr(0,roleCodes.length-1);
      }

      let param = {
        userId: this.targetUserInfo.userId,
        roleCodes: roleCodes
      }
      userInfoService.grantRole2User(param).then(()=>{
        this.$message.success("保存成功");
        this.show = false;
        this.$emit("loadTableData");
      })
    }
  }
}
</script>

<style scoped>

</style>