<template>
  <div class="whm-add">
    <div class="step-title">出库单详情</div>
    <el-form :model="whmInfo" label-width="140px" ref="whmInfoForm" class="step-form">
      <el-form-item label="包裹号:">
        {{ whmInfo.packageCode }}
      </el-form-item>
      <el-form-item label="订单号:">
        {{ whmInfo.operationCode }}
      </el-form-item>
      <el-form-item label="库品信息:">
        {{ whmInfo.packageContent }}
      </el-form-item>
      <el-form-item label="收件人姓名:">
        {{ whmInfo.receiverName }}
      </el-form-item>
      <el-form-item label="收件人手机:">
        {{ whmInfo.receivingPhone }}{{ whmInfo.receivingPhoneTransfer ? ' (转：' + whmInfo.receivingPhoneTransfer + ')' : '' }}
      </el-form-item>
      <el-form-item label="收件地址:">
        {{ whmInfo.receivingAddress }}
      </el-form-item>
      <el-form-item label="快递公司:">
        <div v-if="editShow==false">{{ whmInfo.transportCompany }}</div>
        <div v-else>
          <el-select v-model="whmInfo.transportCompany" clearable placeholder="选择来源">
            <el-option v-for="item in companySelect" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="运单号:">
        <div v-if="editShow==false">{{ whmInfo.transportCode }}</div>
        <div v-else>
          <el-input v-if="whmInfo.transportCode!=null" clearable style="width:190px;"
                    v-model="whmInfo.transportCode"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="同订单包裹:">
        <div v-for="(item, index) in whmInfo.sameOrderPackageList" :key="item + index">
          {{ item }}
        </div>
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button type="primary" v-if="editShow==true" @click="preservation">保存</el-button>
      <el-button @click="backPre">返回</el-button>
    </div>
  </div>
</template>

<script>
import boundQuery from "API/whm/boundQuery";
import BimEnumService from "API/bim/BimEnumService";

export default {
  name: "outDetail",
  data() {
    return {
      //searchInfo: {},
      editShow: false,
      companySelect: [],
      whmInfo: {
        transportCode: "", //运单号
        packageCode: "", //包裹号
        operationCode: "", // 订单号
        packageContent: "", //库品信息
        receiverName: "", //收件人姓名
        receivingPhone: "", //收件人手机
        receivingPhoneTransfer: "", //收件人手机
        receivingAddress: "", // 收件地址
        transportCompany: "", //快递公司
        sameOrderPackageList: [], //同订单包裹
      },
    };
  },
  mounted() {
    this.getGoodsDetail();
    if (this.$route.query.editShow) {
      this.editShow = true;
    }
    this.whmInfo.transportCode = this.$route.query.transportCode;
    BimEnumService.getEnum("TRANSPORT_COMPANY").then((data) => {
      this.companySelect = data.map((e) => {
        return {
          value: e.value,
          label: e.name,
        };
      });
    });
  },
  methods: {
    backPre() {
      //返回上一页
      this.$router.push("/whm/outList");
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      boundQuery.detailOut(this.$route.params.id).then((data) => {
        //console.log(data)
        let whmInfo = this.whmInfo;
        (whmInfo.outboundDocumentsId = data.outboundDocumentsId),
            (whmInfo.packageCode = data.packageCode), //包裹号
            (whmInfo.operationCode = data.operationCode), // 订单号
            (whmInfo.packageContent = data.packageContent), //库品信息
            (whmInfo.receiverName = data.receiverName), //收件人姓名
            (whmInfo.receivingPhone = data.receivingPhone), //收件人手机
            (whmInfo.receivingPhoneTransfer = data.receivingPhoneTransfer), //收件人手机
            (whmInfo.receivingAddress = data.receivingAddress), // 收件地址
            (whmInfo.transportCompany = data.transportCompany), //快递公司
            (whmInfo.sameOrderPackageList =
                data.sameOrderPackageList &&
                data.sameOrderPackageList.length > 0
                    ? data.sameOrderPackageList
                    : ""), //同订单包裹
            loading.close();
      });
    },
    preservation() {
      if (
          this.whmInfo.transportCompany != "" &&
          this.whmInfo.transportCode != ""
      ) {
        let params = {
          outboundDocumentsId: this.whmInfo.outboundDocumentsId,
          transportCompany: this.whmInfo.transportCompany,
          transportCode: this.whmInfo.transportCode,
        };
        boundQuery.saveTransport(params).then((data) => {
          this.$message({type: "success", message: "保存成功"});
          this.$router.push({
            path: "/whm/outList",
          });
        });
      } else {
        this.$message({
          message: "快递公司与运单号都不能为空！",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
.step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
}

.whm-add {
  padding: 20px 0 60px 0;
  background: #fff;
}

.steps-btn-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.steps-btn-wrapper button {
  width: 80px;
}
</style>