<template>
  <div class="message-box">
    <div class="message-tab-wrap" v-if="categoryInfo.length > 0">
      <div class="message-tab">
        <div                    
          class="message-tab-list"
          v-for="(item,index) in categoryInfo"
          :key="index"
          :class="{ active: idx == index }"
          @click="tabTap(index)"
        >
          <span class="message-txt" v-if="item.categoryName" :class="{ pdr15: item.count > 0 }">{{item.categoryName}}</span>
          <span class="message-num" v-if="item.count > 0">{{item.count}}</span>
        </div>        
      </div>
    </div>
    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      class="inv-table"
    >
      <el-table-column
          prop="mailDate"
          label="消息时间"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="mes"
        label="消息标题"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{scope.row.mes}}
          <img src="../../assets/images/new.png" class="icon-new" alt="" v-if="scope.row.readFlag == '0'">
        </template>
      </el-table-column>
      <el-table-column
        prop="con"
        label="消息内容"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import systemMessage from "API/bim/systemMessage";
import tools from "COMJS/tools";

export default {
  name: "",
  data() {
    return {
      idx: 0,
      categoryInfo: '',
      tableData: [],
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.getUserNoReadByType();
  },
  methods: {
    getUserNoReadByType(){      
      systemMessage.countUserNoReadByType().then((data) => {        
        if(data[0] != null){
          this.categoryInfo = data
        }
        if(data){
          this.loadTableData()
        }
      });
    },
    loadTableData() {
      //console.log(this.categoryInfo[this.idx])
      if(this.categoryInfo && this.categoryInfo[this.idx] != null){
        this.$store.commit("setCount", this.categoryInfo[this.idx].count);
        let params = {
          pageNo: this.pageInfo.pageNo,
          pageSize: this.pageInfo.pageSize,
          mailCategoryType: this.categoryInfo ? this.categoryInfo[this.idx].categoryType : ""
        }      
        systemMessage.queryUserMainAtPage(params).then((data) => {
          data.list = data.list.map((e) => {
            //console.log(e)
            return {
              mailDate: tools.formatTime(e.mailDate,'yyyy-MM-dd hh:mm:ss'),
              mes: e.mailTitle,
              con: e.mailContent,
              readFlag: e.readFlag,
            };
          });
          this.querySuccess(data);
          this.markMesRead()
        });
      }
    },
    tabTap(num) {
      this.idx = num;
      this.loadTableData()
    },
    markMesRead(){
      let params = {
        mailCategoryType: this.categoryInfo ? this.categoryInfo[this.idx].categoryType : ""
      }
      if(this.categoryInfo && this.categoryInfo[this.idx].categoryType){
        systemMessage.markAllAsRead(params).then((data) => {
          //console.log(data)
        });
      }
    },
  },
};
</script>

<style>
.message-tab-wrap {
  position: relative;
  padding-top: 10px;
  height: 82px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
}
.message-tab {
  margin: 0 20px 0 20px;
  display: flex;
  border-bottom: 1px solid #eee;
}
.message-tab-list {
  padding: 0 15px 0 15px;
  position: relative;
  text-align: center;
  line-height: 38px;
  background: #f6f9fc;
  border: 1px solid #dcdee0;
  font-size: 14px;
  color: #666;
  border-right: 0;
  cursor: pointer;
  border-bottom: 0;
}
.message-tab-list .message-num {
  margin: 2px 0 0 -11px;
  position: absolute;
  min-width: 20px;
  height: 20px;
  text-align: center;
  line-height: 19px;
  background: #ff6060;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
}
.message-tab-list.active {
  position: relative;
  border-bottom: 1px solid #fff;
  background: #fff;  
}
.message-tab-list.active::before{ position: absolute; content: ''; width: 100%; height: 3px; background: #fff; left: 0; bottom: -2px;}
.message-tab-list:last-child {
  border-right: 1px solid #dcdee0;
}
.pdr15{ padding-right: 15px;}
.icon-new{ width: 28px;}
</style>