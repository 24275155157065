<template>
  <div id="taskEdit">
    <div class="taskEdit-title">项目名称：{{ projectTitle }}</div>
    <div class="addTask">
      <el-button type="primary" @click="addTask">添加任务</el-button>
    </div>
    <div class="taskEdit-content">
      <ws-page-table :data="tableData" ref="pageTable" height="590" @pageChange="loadTableData">
        <el-table-column label="任务名" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-input style="width:70%" :disabled='scope.row.taskState!="waiting"' v-model="scope.row.title" placeholder="任务名"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="任务描述" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-input style="width:80%" :disabled='scope.row.taskState!="waiting"' v-model="scope.row.description" placeholder="描述"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="执行人" prop="title" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-autocomplete :disabled='scope.row.taskState!="waiting"' v-model="scope.row.leaderAccount" @input="selectAccount(scope.$index)" :fetch-suggestions="search" @select="selectGroup" @focus="groupListMe" placeholder="请输入负责人账号">
            </el-autocomplete>
          </template>
        </el-table-column>
        <el-table-column label="任务状态" prop="title" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-select :disabled='scope.row.taskState=="suspend"' v-model="scope.row.taskState" placeholder="请选择">
              <el-option v-for="item in options" :disabled='item.value=="suspend"' :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="执行时间" prop="title" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-date-picker :disabled='scope.row.taskState!="waiting"' style="width:80%" v-model="scope.row.TimeArr" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <div class="btn">
      <el-button type="danger" @click="cancel">取消</el-button>
      <el-button type="primary" @click="saveTask">保存</el-button>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
import PmBrandService from "API/pm/PmBrandService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
export default {
  data() {
    return {
      tableData: [{}],
      value: "",
      userId: "",
      projectTitle: "",
      thisSearchData: [],
      options: [
        {
          value: "complete",
          label: "已完成",
        },
        {
          value: "waiting",
          label: "未完成",
        },
        {
          value: "suspend",
          label: "已终止",
        },
        // {
        //   value: "notStart",
        //   label: "未开始",
        // },
      ],
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      let params = {
        projectId: this.$route.query.projectId,
        queryTaskState:'noParse'
      };
      this.projectTitle = this.$route.query.title;
      this.projectId = this.$route.query.projectId;
      PmBrandService.queryByInfoTask(params).then((data) => {
        data.forEach((val, i) => {
          data[i].TimeArr = [data[i].beginTime, data[i].endTime];
          data[i].projectId = this.projectId;
        });
        this.tableData = data;
      });
    },
    selectAccount(index) {
      let len = this.thisSearchData.length;
      this.autocompleteIndex = index;
      let arr = [];
      for (let i = 0; i < len; i++) {
        //根据输入框中的值进行模糊匹配
        if (
          this.thisSearchData[i].account.indexOf(
            this.tableData[index].leaderAccount
          ) >= 0
        ) {
          arr.push({
            value: this.thisSearchData[i].account,
          });
        }
      }
      this.possibleData = arr;
    },
    groupListMe() {
      console.log("groupListMe");
      PmBrandService.listUserInRole("shop_admin")
        .then((data) => {
          this.thisSearchData = [];
          this.possibleData = [];
          this.thisSearchData = data;
          for (let item of this.thisSearchData) {
            this.possibleData.push({
              value: item.account,
            });
          }
          console.log(this.possibleData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    search(queryString, cb) {
      console.log("search");
      var possibleData = this.possibleData;
      cb(possibleData);
    },
    selectGroup(val) {
      console.log(val);
      for (let i in this.thisSearchData) {
        if (val.value == this.thisSearchData[i].account) {
          console.log(this.thisSearchData[i]);
          this.tableData[this.autocompleteIndex].leaderAccount =
            this.thisSearchData[i].account;
          this.tableData[this.autocompleteIndex].leaderId =
            this.thisSearchData[i].userId;
          this.tableData[this.autocompleteIndex].leaderName =
            this.thisSearchData[i].userName;
        }
      }
      this.value = val.value;
    },
    addTask() {
      let params = {
        projectTaskId: "",
        title: "",
        description: "",
        leaderId: "",
        beginTime: "",
        endTime: "",
        TimeArr: null,
        projectId: this.projectId,
      };
      this.tableData.push(params);
    },
    cancel() {
      this.$router.go(-1);
    },
    saveTask() {
      let tableData = this.tableData;
      for (let i in tableData) {
        if (tableData[i].title == "") {
          return this.$message({
            message: "任务名未填写完整",
            type: "warning",
          });
        }
        if (tableData[i].TimeArr == null) {
          return this.$message({
            message: "执行时间未填写完整",
            type: "warning",
          });
        }
        tableData[i].beginTime = moment(tableData[i].TimeArr[0]).valueOf();
        tableData[i].endTime = moment(tableData[i].TimeArr[1]).valueOf();
      }
      let params = tableData.map((e) => {
        return {
          projectTaskId: e.projectTaskId ? e.projectTaskId : "",
          title: e.title,
          remark: e.remark,
          description: e.description,
          leaderId: e.leaderId,
          beginTime: e.beginTime,
          endTime: e.endTime,
          projectId: e.projectId,
          taskState:e.taskState
        };
      });
      PmBrandService.saveBatch(params).then((data) => {
        console.log(data);
        this.$message({ type: "success", message: "保存成功" });
        this.$router.push({
          path: "/pm/ProjectList",
        });
      });
    },
  },
};
</script>

<style scoped>
#taskEdit {
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 27px 32px;
}
.taskEdit-title {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
}
.taskEdit-content {
  width: 100%;
  height: 100%;
}
.btn {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.addTask {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>
