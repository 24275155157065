<template>
  <div>
    <div class="filter-form">
      <el-select size="small" v-model="queryInfo.authState">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <div class="filter_left" style="margin-left: 10px">
        <div class="search-wrapper">

          <span class="search-input">
            <el-input size="small" v-model="queryInfo.name" placeholder="请输入会员姓名、昵称、手机号码"></el-input>
          </span>

          <el-button class="search-btn" size="mini" type="primary" @click="searchChange">搜索</el-button>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div style="flex: 1;">
        <level-tree-copy
            :isshope="false"
            :tree="firstTreeData"
            :canSee="true"
            @seeDetail="toDetail"
            firstTreeName="金星"
            @changeNode="getTreeNode">
        </level-tree-copy>
      </div>
      <div style="flex: 1;">
        <level-tree-copy
            :isshope="false"
            ref="firstTree1"
            :tree="secondTreeData"
            :canSee="true"
            @seeDetail="toDetail"
            firstTreeName="银星"
            @changeNode="getTreeNodeG1">
        </level-tree-copy>
      </div>
      <div style="flex: 1;">
        <level-tree-copy
            ref="firstTree2"
            :isshope="false"
            :tree="thirdTreeData"
            :canSee="true"
            @seeDetail="toDetail"
            firstTreeName="普标">
        </level-tree-copy>
      </div>
    </div>

  </div>
</template>

<script>
import levelTreeCopy from "COMPS/widgets/levelTreeCopy"
import BimMemberService from "API/bim/BimMemberService"

export default {
  data() {
    return {
      queryInfo: {
        name: "",
        authState:""
      },
      options: [{
        value: '',
        label: '所有会员'
      },{
        value: 'normal',
        label: '正常会员'
      }, {
        value: 'freeze',
        label: '冻结会员'
      }, {
        value: 'expires',
        label: '过期会员'
      }, {
        value: 'cancel_process',
        label: '注销中会员'
      }, {
        value: 'cancel',
        label: '已注销会员'
      }],
      currentNode: {},
      searchResultList: [],

      firstTreeData: [],
      secondTreeData: [],
      thirdTreeData: [],
      selectedFirstTree: {},
      selectedSecondTree: {},
      selectedThirdTree: {}
    };
  },
  components: {
    levelTreeCopy
  },
  mounted() {
    this.getTree();
  },
  methods: {
    getTree() {
      BimMemberService.queryMemberList().then(data => {
        this.firstTreeData = data.map(e => {
          let tel = e.tel;
          e.id = e.userId,
          e.name = e.companyAccountName ? e.companyAccountName + ' | ' + tel : e.fullName ? e.fullName : decodeURI(e.nickName) + ' | ' + tel;
          return e;
        });
        this.secondTreeData = [];
        this.thirdTreeData = [];
      });
    },
    getTreeNode(node) {
      this.$refs["firstTree1"].$refs["firstTree"].selectNode({});
      this.$refs["firstTree2"].$refs["firstTree"].selectNode({});
      let userId = node.userId;
      BimMemberService.getChildrenList(userId).then(data => {
        this.secondTreeData = [];
        this.thirdTreeData = [];


        if (data.g1) {      //高一有值的话
          this.secondTreeData = data.g1.map(e => {
            let tel = e.tel;
            e.id = e.userId,
                e.name = e.fullName ? e.fullName + ' | ' + tel : decodeURI(e.nickName) + ' | ' + tel;
            return e;

          });
        }
        if (data.g0) {    //初级有值的话
          this.thirdTreeData = data.g0.map(e => {
            let tel = e.tel;
            e.id = e.userId,
                e.name = e.fullName ? e.fullName + ' | ' + tel : decodeURI(e.nickName) + ' | ' + tel;
            return e;
          });
        }
      })
    },

    getTreeNodeG1(node) {
      this.$refs["firstTree2"].$refs["firstTree"].selectNode({});
      let userId = node.id;
      this.thirdTreeData = [];
      BimMemberService.getChildrenList(userId).then(data => {
        if (data.g0) {
          this.thirdTreeData = [];//初级有值的话
          this.selectedThirdTree = {};
          this.thirdTreeData = data.g0.map(e => {
            let tel = e.tel;
            e.id = e.userId,
                e.name = e.fullName ? e.fullName + ' | ' + tel : decodeURI(e.nickName) + ' | ' + tel;
            return e;
            this.selectedThirdTree = {};
          });
        }
      })
    },


    searchChange() {
      /*if (!this.queryInfo.name || this.queryInfo.name=='') {
        this.getTree();
        return;
      }*/
      let param = {
        keyWord: this.queryInfo.name,
        userState: this.queryInfo.authState
      };
      this.firstTreeData = [];
      this.secondTreeData = [];
      this.thirdTreeData = [];
      BimMemberService.queryUserByCondition(param).then(data => {
        //console.log(data);
        if (!data) {
          return this.$message({
            message: "未查询到相关数据",
            type: "warning"
          });
        }
        if (data.g2) {
          this.firstTreeData = data.g2.map(e => {
            let tel = e.tel;
            e.id = e.userId,
                e.name = e.fullName ? e.fullName + ' | ' + tel : decodeURI(e.nickName) + ' | ' + tel;
            return e;

          })
        }
        if (data.g1) {
          this.secondTreeData = data.g1.map(e => {
            let tel = e.tel;
            e.id = e.userId,
                e.name = e.fullName ? e.fullName + ' | ' + tel : decodeURI(e.nickName) + ' | ' + tel;
            return e;
          })
        }
        if (data.g0) {
          this.thirdTreeData = data.g0.map(e => {
            let tel = e.tel;
            e.id = e.userId,
                e.name = e.fullName ? e.fullName + ' | ' + tel : decodeURI(e.nickName) + ' | ' + tel;
            return e;
          })
        }
      })
    },

    toDetail(node) {
      this.$router.push(`/bim/memberDetail/${node.id}`)
    }
  }
};
</script>
<style lang="scss" scoped>
.filter-form .filter_left .search-wrapper {
  margin-left: 0;
}
</style>