import axiosHttp from "../axiosHttp"

export default {
    getPoint(userId) {
        return axiosHttp.GET(`/bimUser/getPoint/${userId}`)
    },
    getUser(params) {
        return axiosHttp.POST('/bimUser/getUserByUserParam/',params,false)
    },
    getUserInfo(userId) {
        return axiosHttp.GET('/bimUser/getUserInfo?userId='+userId)
    },
    //bimUserMail/countUserNoReadByAll //获取头部message数量
    countUserNoReadByAll() {
        return axiosHttp.POST('/bimUserMail/countUserNoReadByAll')
    },
    grantRole2User(param) {
        return axiosHttp.POST('/bimUser/grantRole2User/',param)
    }
}