<template>
  <div>
    <el-form label-width="120px" :inline="true">
      <el-form-item>
        <el-button type="primary" @click="openAddDialog">新增一级菜单</el-button>
      </el-form-item>
    </el-form>
    <div style="color: red;margin-bottom: 15px;">
      tips:同父节点下可拖动调整顺序
    </div>
    <div style="display: flex;">
      <div class="custom-tree-container">
        <div class="block">
          <!--show-checkbox,-->
          <el-tree
              :data="data"
              node-key="menuId"
              :props="props"
              default-expand-all
              @node-click="nodeClick"
              draggable
              @node-drop="handleDrop"
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              :expand-on-click-node="false">
          </el-tree>
        </div>
      </div>
      <div class="custom-tree-container-right">
        <div class="menu-info-div">
          <el-descriptions title="菜单信息" direction="vertical" :column="3" border>
            <el-descriptions-item label="菜单名">{{ selectedNode.menuName }}</el-descriptions-item>
            <el-descriptions-item label="URL">{{ selectedNode.menuUrl }}</el-descriptions-item>
          </el-descriptions>
          <el-row style="margin-top: 20px;text-align: right;">
            <el-button type="success" icon="el-icon-plus" circle
                       :disabled="!selectedNode.menuId"
                       @click="append(selectedNode)"></el-button>
            <el-button type="primary" icon="el-icon-edit" circle
                       :disabled="!selectedNode.menuId"
                       @click="edit({}, selectedNode)"></el-button>
            <el-button type="danger" icon="el-icon-delete" circle
                       :disabled="!selectedNode.menuId || selectedNode.subMenus.length>0"
                       @click="remove({}, selectedNode)"></el-button>
          </el-row>
        </div>
      </div>
    </div>

    <!--新增菜单-->
    <el-dialog :title="currentMenu.menuId ? '编辑' : '新增'" :visible.sync="addDialogVisible"
               @close="addDialogVisible = false" width="600px">
      <el-form label-width="140px">
        <el-form-item required label="上级菜单：" v-if="currentMenu.parentName">
          <el-input v-model="currentMenu.parentName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item required label="名称：">
          <el-input v-model="currentMenu.menuName"></el-input>
        </el-form-item>
        <el-form-item label="url：">
          <el-input v-model="currentMenu.menuUrl"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import bimMenuService from 'API/ba/BimMenuService'
import Objects from "COMJS/Objects";

export default {
  name: "menuManager",
  data() {
    let data = [];
    return {
      data: JSON.parse(JSON.stringify(data)),
      props: {
        label: 'menuName',
        children: 'subMenus'
      },
      addDialogVisible: false,
      currentMenu: {},

      //selectedNode
      selectedNode: {},
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const _this = this
      bimMenuService.getMenuAllTree('0').then(data => {
        _this.data = data
      })
      this.selectedNode = {}
    },
    append(data) {
      //console.log(data)
      this.openAddDialog(data)
    },
    openAddDialog(data) {
      if (data.menuId) {
        //添加下级节点
        this.currentMenu = {};
        this.currentMenu.parentId = data.menuId;
        this.currentMenu.parentName = data.menuName;
      } else {
        //增加一级节点
        this.currentMenu = {};
      }
      this.addDialogVisible = true;
    },
    addConfirm() {
      if (this.currentMenu.menuName == null
          || this.currentMenu.menuName.length == 0) {
        return this.$message({
          message: "请填写名称",
          type: "warning"
        });
      }
      const _this = this
      this.currentMenu.appType = '0'
      bimMenuService.saveOrUpdateMenu(this.currentMenu).then(data => {
        _this.loadData();
        //提示，关闭弹窗
        _this.$message({type: "success", message: "添加成功!"});
        _this.addDialogVisible = false;
      })

    },
    remove(node, data) {
      if (data.subMenus && data.subMenus.length > 0) {
        this.$message({type: "error", message: "只能删除叶子节点!"});
        return;
      }
      /*const parent = node.parent;
      const children = parent.data.subMenus || parent.data;
      const index = children.findIndex(d => d.menuId === data.menuId);
      children.splice(index, 1);*/
      const _this = this
      this.$confirm('此操作将删除菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        bimMenuService.deleteMenu(data.menuId).then(data => {
          _this.loadData();
        })
      });
    },
    edit(node, data) {
      let parentName = ''
      if (node && node.parent && node.parent.data) {
        parentName = node.parent.data.menuName
      }
      let editData = Objects.deepCopy({}, data)
      this.currentMenu = editData
      this.currentMenu.parentName = parentName;
      this.addDialogVisible = true;
    },
    nodeClick(data) {
      //console.log(data)
      this.selectedNode = data;
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      //console.log('tree drop: ',draggingNode.label, dropNode.label, dropType);
      if (dropNode && dropType != 'none') {
        let dropTypeStr = dropType == 'before' ? '之前' : dropType == 'after' ? '之后' : '之中';
        this.$confirm('将[' + draggingNode.label + ']拖动至[' + dropNode.label + ']' + dropTypeStr + ', 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //console.log(draggingNode.data.menuId,dropNode.data.menuId,dropType)
          bimMenuService.moveMenuItem(draggingNode.data.menuId, dropNode.data.menuId, dropType).then(data => {
            this.loadData();
          })
        }).catch(() => {
          this.loadData();
        });
      }
    },
    //释放允许
    allowDrop(draggingNode, dropNode, type) {
      //'prev'、'inner' 和 'next'
      if (draggingNode.data.parentId == dropNode.data.parentId) {
        //同parentId之间拖动顺序
        //return type != 'inner';
        return true;
      } else if (draggingNode.data.parentId != dropNode.data.parentId) {
        //不同parentId之间，即拖动至其他节点内
        return type == 'inner';
      } else {
        return false;
      }
    },
    //拖动允许
    allowDrag(draggingNode) {
      return true
    }
  }
};
</script>

<style>
.custom-tree-container {
  width: 600px;
}

.custom-tree-container-right {

}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.menu-info-div {
  width: 600px;
  margin-left: 50px;
  padding: 20px;
  line-height: 40px;
  position: absolute;
  background-color: #e4e8ec;
}
</style>