<template>
  <div>
    <div style="display:flex; justify-content:flex-end;">
      <el-button type="primary" @click="addFare">新增</el-button>
    </div>
    <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" @pageChange="loadTableData"
                   :showIndex="true" :isShadow="false">
      <el-table-column prop="expressCompany" label="物流公司" :formatter="expressCompanyFormatter" width="150"></el-table-column>
      <el-table-column prop="calcMode" label="计价方式" :formatter="calcModeFormatter" width="150"></el-table-column>
      <el-table-column prop="excludeGoodsCode" label="排除商品" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="orderCode" label="编辑" width="190">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" @click="editBtn(0, scope.row.logisticsCostId)" title="查看">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" @click="editBtn(1, scope.row.logisticsCostId)" title="编辑">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <el-popconfirm style="margin-left:30%;" title="确定删除吗？" @confirm="delBtn(scope.row.logisticsCostId)">
              <el-button>删除</el-button>
              <a slot="reference" href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid"></i>
              </a>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import Objects from "COMJS/Objects"
import BimEnumService from "API/bim/BimEnumService";
import {
  getPage,
  getDetail,
  saveLogisticsCost,
  delLogisticsCost,
} from "@/api/express/LogisticsCostService.js";

export default {
  data() {
    return {
      tableData: [],
      comOptions: []
    };
  },
  mounted() {
    this.getCompanyEnum();
  },
  methods: {
    getCompanyEnum() {
      BimEnumService.getEnum("TRANSPORT_COMPANY").then(data => {
        this.comOptions = data.map(e => {
          return {
            value: e.value,
            label: e.name
          };
        });
      });
    },
    async loadTableData() {
      const data = await getPage(this.pageInfo);
      this.tableData = data.list;
      this.querySuccess(data);
    },
    // 模板查看，修改
    editBtn(i, id) {
      let edit = 0
      if (i == 0) {
        //"查看模板"
      } else if (i == 1) {
        //"编辑模板"
        edit = 1
      }
      this.$router.push("/whm/logistics/detail/"+id+"?edit="+edit);
    },
    //删除模板
    async delBtn(id) {
      await delLogisticsCost(id);
      this.loadTableData();
    },


    expressCompanyFormatter(row){
      let expressCompany = row.expressCompany;
      let v = "未定义";
      if (expressCompany) {
        let typeEnum = this.comOptions.find(o => o.value === expressCompany);
        if (Objects.isNotEmpty(typeEnum)) {
          v = typeEnum.label;
        }
      }
      return v;
    },
    calcModeFormatter(row){
      if(row.calcMode){
        return row.calcMode == 'weight' ? '计重' : '计件'
      }
      return ''
    },

    addFare(){
      this.$router.push("/whm/logistics/detail");
    }

  },
  mixins: [tableMixins],
};
</script>

<style scoped>
</style>
