<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-button size="mini" type="primary" @click="addProduct">新增商品</el-button>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">商品名称：</span>
            <el-input size="mini" v-model="searchInfo.cnName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品编号：</span>
            <el-input size="mini" v-model="searchInfo.goodsCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品状态：</span>
            <el-select v-model="searchInfo.goodsState"
                       clearable
                       placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-dropdown split-button type="primary" @click="searchData">
            搜索
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="exportData">导出EXCEL</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!--
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
          -->
        </div>
      </div>
      <div class="filter_right">
        <el-button size="mini" type="warning" @click="refreshCensus">
          刷新销量和可售统计
        </el-button>
        <el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{logType:'goods'}})">
          操作日志
        </el-button>
      </div>
    </div>
    <el-tabs
        v-model="searchInfo.goodsType" type="card" @tab-click="loadTableData"
    >
      <el-tab-pane label="普通商品" name="common"></el-tab-pane>
      <el-tab-pane label="特殊商品" name="year_card,recruit">
        <span style="color: red;margin-bottom: 10px;">特殊商品包括奶卡商品、招商商品，需要特殊权限才可编辑。</span>
      </el-tab-pane>
    </el-tabs>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="goodsName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="goodsCode" label="商品编码" :show-overflow-tooltip="true"></el-table-column>
      <!--
      <el-table-column label="缩略图">
        <template slot-scope="scope">
          <div class="table-img">
            <img :src="scope.row.goodsCover" alt/>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="effectiveStr" label="上架状态"></el-table-column>
      <el-table-column prop="goodsStateStr" label="商品状态"></el-table-column>
      -->
      <el-table-column prop="totalStateStr" label="状态"></el-table-column>
      <el-table-column prop="price" label="非活动零售价（元）"></el-table-column>
      <el-table-column prop="actualSale" label="实际销量"></el-table-column>
      <el-table-column prop="availableNum" label="剩余可售数量"></el-table-column>
      <!--
      <el-table-column prop="markingPrice" label="划线价（元）"></el-table-column>
      -->
      <el-table-column label="操作" width="250" v-if="searchInfo.goodsType=='common' || isTest">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
                href="javascript: void(0);"
                title="编辑"
                @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
            <a href="javascript: void(0);" title="下架">
              <i class="CCSFont icon-table_3" @click="deleteGoods(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="复制">
              <i class="el-icon-document-copy" @click="copyGoods(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="复制商品" :visible.sync="copyGoodsDialogShow" width="500px">
      <div style="line-height: 28px;">本操作会创建一个新商品，并复制原商品的所有属性，但有以下注意点：</div>
      <div style="line-height: 28px;">1、不会复制原商品的经销商价，如果要支持经销商下单，需要到【价格活动】设置</div>
      <div style="line-height: 28px;">2、不会复制原商品的推荐商品数据</div>
      <div style="line-height: 28px;">3、复制出来的新商品请修改商品编码</div>
      <div style="line-height: 28px;">4、复制出来的新商品默认下架、默认不展示到小程序</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyGoodsDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="copyGoodsConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import PimGoodsService from "API/pim/PimGoodsService";
import IoProgressService from "API/IoProgressService";

export default {
  name: "productList",
  computed: {
    isTest: function () {
      if (this.$route.params.test === 'test') {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      searchInfo: {
        cnName: "",
        goodsCode: "",
        goodsState: "",
        goodsType: "common",
      },
      items: {
        cnName: {filter: "like", dataType: "str"},
        goodsCode: {filter: "like", dataType: "str"},
        goodsState: {filter: "eq", dataType: "str"},
        goodsType: {filter: "in"},
      },
      sorts: {
        goodsCode: {order: "asc"}
      },
      options: [{
        value: '正常',
        label: '正常'
      }, {
        value: '售罄',
        label: '售罄'
      }, {
        value: '下架',
        label: '下架'
      }],
      value: '',
      copyGoodsDialogShow: false,
      copyGoodsId: '',
    };
  },
  mixins: [pageTableMixin],

  methods: {
    exportData(){
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let param = {
        cnNameLike: this.searchInfo.cnName,
        goodsCodeLike: this.searchInfo.goodsCode,
        goodsTypeIn: this.searchInfo.goodsType,
        sortField:"goodsCode,asc",
      }
      if (this.searchInfo.goodsState == '正常') {
        param.goodsState = "normal";
        param.isEffective = "1";
      } else if (this.searchInfo.goodsState == '售罄') {
        param.goodsState = "sell_out";
        param.isEffective = "1";
      } else if (this.searchInfo.goodsState == '下架') {
        param.isEffective = "0";
      }
      PimGoodsService
          .exportPimGoodsExcel(param)
          .then((data) => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
    },
    refreshCensus() {
      this.$refs.pageTable.tableLoading = true;
      let that = this
      let timer = setTimeout(function () {
        that.$message.warning("统计数据需要花费较多时间，请刷新查看!")
        that.loadTableData();
      }, 10000)
      PimGoodsService.calculateSaleCountAndAvailableNum().then(data => {
        this.$message.success("统计数据已更新!")
        clearTimeout(timer)
        this.loadTableData();
      })
    },
    loadTableData() {
      let param = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        cnNameLike: this.searchInfo.cnName,
        goodsCodeLike: this.searchInfo.goodsCode,
        goodsTypeIn: this.searchInfo.goodsType,
        flagWithI: "1",
        sortField:"goodsCode,asc",
      }
      if (this.searchInfo.goodsState == '正常') {
        param.goodsState = "normal";
        param.isEffective = "1";
      } else if (this.searchInfo.goodsState == '售罄') {
        param.goodsState = "sell_out";
        param.isEffective = "1";
      } else if (this.searchInfo.goodsState == '下架') {
        param.isEffective = "0";
      }
      PimGoodsService.queryGoodsPageByParam(param).then(
          data => {
            data.list = data.list.map(e => {
              if (!e.goodsInfo) {
                e.goodsInfo = {};
              }
              return {
                id: e.goodsId,
                goodsName: e.cnName,
                goodsCode: e.goodsCode,
                //goodsCategory: e.categoryName,
                goodsCover: e.pictureUrl,
                price: e.initPrice,
                markingPrice: e.markingPrice,
                effectiveStr: this.translateEffective(e),
                goodsStateStr: this.translateState(e),
                totalStateStr: this.translateTotalState(e),
                actualSale: e.goodsInfo.actualSale || 0,
                availableNum: e.goodsInfo.availableNum || 0
              };
            });
            this.querySuccess(data);
          }
      );
    },
    addProduct() {
      this.$store.commit('CLEAR_PRODUCT')
      //this.$router.push("/pim/productAdd_step_1");
      //this.$router.push("/pim/productEdit");
      this.$router.push({
        path: "/pim/AddGoods",
        query: {newGoods: true}
      });
    },
    toDetail(row) {
      this.$store.commit('CLEAR_PRODUCT')
      // this.$router.push(`/pim/productDetail/${row.id}`)
      this.$router.push({
        path: '/pim/AddGoods',
        query: {
          goodsId: row.id,
          pageNo: 1,
          toView: true
        }
      })
    },
    toEdit(row) {
      this.$store.commit('CLEAR_PRODUCT')
      //this.$router.push(`/pim/productAdd_step_1/${row.id}`)
      //this.$router.push(`/pim/productEdit/${row.id}`)
      this.$router.push({
        path: '/pim/AddGoods',
        query: {
          goodsId: row.id,
          pageNo: 1
        }
      })
    },
    deleteGoods(row) {
      this.$confirm("是否下架该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            PimGoodsService.withdrawGoods(row.id).then(() => {
              this.loadTableData();
            });
          })
          .catch(() => {
          });
    },
    copyGoods(row) {
      this.copyGoodsDialogShow = true;
      this.copyGoodsId = row.id;
    },
    copyGoodsConfirm() {
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      let param = {
        goodsId: this.copyGoodsId
      }
      PimGoodsService.copyFromExistPimGoods(param).then((data) => {
        this.$message.success("保存成功");
        this.copyGoodsDialogShow = false;
        loading.close()
        let that = this
        setTimeout(function () {
          that.$store.commit('CLEAR_PRODUCT')
          that.$router.push({
            path: '/pim/AddGoods',
            query: {
              goodsId: data.goodsId,
              pageNo: 1
            }
          })
        }, 100);
      }).catch(err => {
        loading.close()
      });
    },
    translateEffective(e) {
      var tail = '上架';
      if (e.effectiveTime > Date.now()) {
        tail = '预售';
      } else if (e.failureTime < Date.now()) {
        tail = '下架';
      }
      return tail;
    },
    translateState(e) {
      var tail = '正常';
      if (e.goodsState == 'normal') {
        tail = '正常';
      } else if (e.goodsState == 'sell_out') {
        tail = '售罄';
      }
      return tail;
    },
    translateTotalState(e) {
      //let minTimestamp = new Date('1971-01-01 00:00:00').getTime()
      //let maxTimestamp = new Date('2038-01-01 00:00:00').getTime()
      let nowTimestamp = new Date().getTime()
      if (e.effectiveTime > nowTimestamp || e.failureTime < nowTimestamp) {
        return '下架';
      }
      if (e.goodsState == 'normal') {
        return '正常';
      } else if (e.goodsState == 'sell_out') {
        return '售罄';
      }
    },
  }
};
</script>
