<template>
  <el-dialog title="运输轨迹" :visible.sync="show" width="50%" top="10vh">
    <div style="position: relative;">
      <el-descriptions style="margin-top: 10px;"
                       :column="2"
                       size="mini" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-shopping-bag-2"></i>
            承运方
          </template>
          {{ scmContract.shipperName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-shopping-bag-2"></i>
            提单号
          </template>
          {{ scmContract.billLadingNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-shopping-bag-2"></i>
            离港时间
          </template>
          {{ tools.formatTime(scmContract.departPortTime) }}
        </el-descriptions-item>
        <el-descriptions-item v-if="scmContract.transportState=='1'">
          <template slot="label">
            <i class="el-icon-shopping-bag-2"></i>
            预计到港时间
          </template>
          {{ tools.formatTime(scmContract.expectPortTime) }}
        </el-descriptions-item>
        <el-descriptions-item v-else>
          <template slot="label">
            <i class="el-icon-shopping-bag-2"></i>
            到港时间
          </template>
          {{ tools.formatTime(scmContract.arrivalPortTime) }}
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="text" @click="goShipperHome"
                 style="position: absolute;right:0px;">
        官网查看运输详情
        <i class="el-icon-arrow-right"></i>
      </el-button>
      <div style="margin-top:40px;width: 100%;height: 600px;overflow-y: scroll;">
        <div class="trail-detail-item"
             v-for="(item,idx) in shipTrailList" :key="idx">
          <i></i>
          <div>
            <div class="trail-detail-item-line">
              <div>【时间】{{ item.transportTime }}</div>
              <div>【动态】{{ item.latestState }}</div>
              <div>【运输方式】{{ item.transportationMode }}</div>
            </div>
            <div>
              <div>【位置】{{ item.location }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </el-dialog>
</template>

<script>
import PurContractService from "API/pur/PurContractService";
import tools from "COMJS/tools";

export default {
  name: "ShipTrailPop",
  data() {
    return {
      show: false,
      scmContract: {},
      shipTrailList: [],
      shipperList:[]
    }
  },
  computed: {
    tools() {
      return tools;
    }
  },
  mounted() {
    this.$on("openDialog", (item) => {
      this.show = true;
      this.scmContract = item;
      this.shipTrailList = [];
      this.queryShipTrailList()
    });
    this.queryShipper();
  },
  methods: {
    queryShipTrailList() {
      let param = {
        contractId: this.scmContract.contractId
      }
      PurContractService.queryShipTrailList(param).then(res => {
        this.shipTrailList = res;
        console.log(this.shipTrailList)
      })
    },
    queryShipper(){
      PurContractService.listShipper().then(res => {
        this.shipperList = res;
      })
    },
    goShipperHome() {
      let shipperId = this.scmContract.shipperId
      if(!shipperId || this.shipperList.length==0){
        return;
      }
      try{
        let shipper = this.shipperList.filter(e=>e.shipperId==shipperId)[0]
        if(shipper && shipper.websiteUrl){
          window.open(shipper.websiteUrl, '_blank');
        }
      }catch(e){}
    }
  }
}
</script>

<style scoped>
.trail-detail-item {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.trail-detail-item > i {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #dcdee0;
  border-radius: 50%;
  margin-right: 10px;
}

.trail-detail-item-line {
  margin-bottom: 5px;
  display: flex;
}

.trail-detail-item-line > div {
  width: 250px;
  white-space: nowrap;
  overflow-x: visible;
}
</style>