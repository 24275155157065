<template>
  <div class="logistics-add">
    <el-form :inline="true" class="rec-inline">
      <el-button type="primary" @click="toEdit('no')">添加商品</el-button>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column
        prop="goodsCode"
        label="商品编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cnName"
        label="商品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="goodsBarCode"
        label="商品条形码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="showNum"
        label="商品数量"
      ></el-table-column>
      <el-table-column
        prop="initPrice"
        label="商品金额"
      ></el-table-column>
      <el-table-column
        prop="skuBatchNumData"
        label="库品信息"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <!--
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
            -->
            <a
              slot="reference"
              href="javascript: void(0);"
              title="删除"
              @click="deleteRow(scope.$index)"
            >
              <i class="el-icon-delete-solid"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-form :model="whmInfo" ref="deaInfoForm" class="step-form">
      <el-form-item label="下单备注：">
        <el-input v-model="whmInfo.remark"></el-input>
      </el-form-item>
      <el-form-item label="总金额："> ¥{{ total }} </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="create">确认下单</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  inject: ['reload'],
  data() {
    return {
      value: [],
      options: [],
      whmInfo: {},
      tableData: [],
      totalAmount: 0,
    };
  },  
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
  },
  computed: {
    total: function() {
       return this.totalAmount.toFixed(2)
    }
  },
  methods: {
    deleteRow(idx) {
      this.tableData.splice(idx, 1);
      this.$store.commit("saveSkuAddInfo", this.tableData);
      this.loadTableData();
    },
    toEdit(row) {
      //console.log(row)
      if (row != "no") {        
        this.$router.push({path:"/som/logAddGoods", query:row });
      } else {
        this.$router.push("/som/logAddGoods");
      }
    },
    loadTableData() {      
      if (this.$store.state.skuInfo) {
        this.tableData = this.$store.state.skuInfo;
        //console.log(this.tableData)
        this.pageInfo.total = this.tableData.length
        for(let i in this.tableData){
          this.totalAmount += (this.tableData[i].showNum-0) * (this.tableData[i].initPrice-0)
        }
        //console.log(this.totalAmount)
      }
    },
    create() {
      let goodsList = [];      
      goodsList = this.tableData.map((e) => {
        return {
          goodsId: e.goodsId, //商品id
          num: e.showNum, //商品数量
          skuBatchNumData: e.skuBatchNumData, //批次信息
        };
      });
      let params = {
        goodsDataList: goodsList,
        remark: this.whmInfo.remark,
      };
      WhmList.agentCreateLogisticsOrder(params).then((data) => {
        if(data){
          this.$message({
            message: "下单成功",
            type: "success",
          });
        }
        this.tableData = []
        this.$store.commit("saveSkuAddInfo", this.tableList);
        this.$router.push("/som/logisticsOrder");
        this.reload()
      }).catch(() => {
        this.reload()
      });;
    },
  },
};
</script>
<style>
.logistics-add .rec-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.logistics-add .step-form {
  padding: 0 0 60px 20px;
  margin-top: 30px;
}
.logistics-add .btn-wrap {
  padding: 30px 0 0 0;
  display: flex;
  justify-content: center;
}
.logistics-add .goods-dialog-cont {
  height: 360px;
  overflow-y: scroll;
}
.logistics-add .icon-operation a {
  margin-right: 20px;
}
</style>