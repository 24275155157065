<template>
  <div>
    <el-dialog title="修改特殊价格" :visible.sync="dialogFormVisible" width="33vw">
      <el-form :model="entity" v-loading="loading" v-if="dialogFormVisible">
        <el-form-item label="特殊价格：" :label-width="formLabelWidth">
          <el-switch v-model="entity.useReservePriceFlag"></el-switch>
        </el-form-item>
        <el-form-item label="" :label-width="formLabelWidth" v-if="entity.useReservePriceFlag">
          <el-input v-model="entity.goodsReservePrice"
                    @keyup.native="keyupEvent4PositiveDecimal($event)"></el-input>
        </el-form-item>
      </el-form>
      <div class="mt15 ml15" style="color: #ee5856">
        <div>参考</div>
        <div class="mt5">
          <span>零售价：{{priceInfo.initPrice}}</span>
          <span class="ml15">经销商价：{{priceInfo.eventPrice}}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hide">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import inputKeyEvent from "COMJS/inputKeyEvent";
import pimReserveService from "API/pim/PimReserveService"
import pimGoodsService from "API/pim/PimGoodsService"
import tools from "COMJS/tools";
import BigNumber from "COMJS/bignumber.min"

export default {
  props: {
    reserveId: String,
    operateType: String
  },
  computed: {
    BigNumber() {
      return BigNumber
    }
  },
  data() {
    return {
      editMode: true,
      entity: {
        reserveId: "",
        useReservePriceFlag: false,
        useReservePrice: "0",
        goodsReservePrice: 0
      },
      dialogFormVisible: false,
      formLabelWidth: "100px",
      loading: false,
      priceInfo:{
        initPrice:0,
        eventPrice:0,
      },
    };
  },
  watch: {
    dialogFormVisible() {
      tools.clearObj(this.entity)
      if (!this.dialogFormVisible) {
        return;
      }
      if (this.reserveId) {
        this.getDetail();
      }
    }
  },
  methods: {
    show() {
      this.dialogFormVisible = true
    },
    hide() {
      this.dialogFormVisible = false
    },
    keyupEvent4PositiveDecimal: inputKeyEvent.keyupEvent4PositiveDecimal,
    getDetail() {
      if (!this.reserveId) {
        return
      }
      let param = {
        reserveId: this.reserveId,
      }
      pimReserveService.queryByParam(param).then(data => {
        if (!data || !data.reserveId) {
          //没有记录
          return;
        }
        this.$set(this.entity, "reserveId", data.reserveId)
        this.$set(this.entity, "useReservePriceFlag", data.useReservePrice == '1')
        this.$set(this.entity, "useReservePrice", data.useReservePrice)
        this.$set(this.entity, "goodsReservePrice", data.goodsReservePrice)
        this.loadAllPrice(data.goodsId,data.userId)
      })
    },
    loadAllPrice(goodsId,userId){
      let param = {
        goodsId:goodsId,
        userId:userId
      }
      pimGoodsService.queryAllPrice(param).then(res=>{
        this.priceInfo = res;
      })
    },
    submitForm() {
      let params = {
        reserveId: this.reserveId,
        useReservePrice: this.entity.useReservePriceFlag ? '1' : '0',
        goodsReservePrice: this.entity.goodsReservePrice
      };
      this.loading = true;
      pimReserveService.modifyReservePriceProperty(params).then(res => {
        this.loading = false;
        this.$emit("updateTable");
      });
      this.hide();
    },
  }
};
</script>

<style lang="scss">
.upload-img-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: $themeColor;
  border: 1px solid #dcdee0;
  border-radius: 0px;
}

.xrequired:before {
  content: '* ';
  color: red;
}
</style>
