export default {
    // 商品设置各个值对应的后端key
    setting: {
        'G2': 'G2_DISCOUNT_PROPORTION', // 高一
        'G1': 'G1_DISCOUNT_PROPORTION', // 高二
        'G0': 'G0_DISCOUNT_PROPORTION', // 初级
        'EGALITARIAN_PROPORTION': 'EGALITARIAN_PROPORTION', // 教育奖励比例
        'GRANT_AFTER_CLOSING_DAY': 'GRANT_AFTER_CLOSING_DAY', // 结算后几天发出
        'SHARE_LINK_EFFECTIVE_TIME': 'SHARE_LINK_EFFECTIVE_TIME' // 链接分享有效时长
    },
    // 会员梯度
    member: {
        'CLASSA': '金星',
        'CLASSB': '银星',
        'CLASSC': '普标',
        'CLASSD': '平民'
    },
    // 订单来源
    orderType: {
        'xcx': '小程序',
        'import': '批量导入',
        'web_adm': '客服下单',
        'wmp': '小程序零售单',
        'subscribe': '奶卡订阅',
        'web_agent':'物流订单',
        'sample':'样品单'
    },
    // 支付方式
    payWay: {
        'adm_no_pay': '管理员下单免支付',
        'subscribe_no_pay': '订阅订单免支付',
        'alipay': '支付宝',
        'wxpay': '微信',
        'balance': '余额付款',
        'giro': '线下转账',
        'wxpay_plural': '微信多笔支付',
        'credit': '经销商信用支付',
        'deposit': '经销商储值支付',
        'wxpay+balance': '余额、微信混合支付',
    }
}