import axiosHttp from "../axiosHttp";

//价格活动分页查询
export const priceEventList = (pageInfo,searchConfig) => {
    let url = 'pimEvent/listPimEvent?'
    if (pageInfo) {
        url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url)
}

//活动详情查询
export const queryEventDetail = data => {
    return axiosHttp.POST('pimEvent/queryPimEventDetails' ,data,false)
}

//修改
export const editEventDetail = data => {
    return axiosHttp.POST('pimEvent/saveOrEditPimEvent', data)
}

export const changeEventBeginEndTime = data => {
    return axiosHttp.POST('pimEvent/changeEventBeginEndTime', data)
}

//删除
export const delEventDetail = id => {
    return axiosHttp.DELETE('pimEvent/changeEventState', {
        delIds: id
    })
}

/* 活动商品查询 */
export const fetchEventGoods = data => {
    return axiosHttp.POST('pimEventGoods/listPimEventGoodsByPage', data,false)
}

/* 活动商品添加 */
export const addEventGoods = data => {
    return axiosHttp.POST('pimEventGoods/createRelaPimEvenAndPimGoods', data)
}

export const delEventGoods = id => {
    return axiosHttp.DELETE('pimEventGoods/changeEventState', {
        "delIds": id
    })
}

export const editEventGoods = data=>{
    return axiosHttp.POST('pimEventGoods/saveOrUpdatePimEventGoods',data)
}
