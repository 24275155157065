<template>
    <div>
        <div>
            <el-form class="filter-form" @submit.prevent="handleSubmit">
                <div class="filter_left">
                    <div class="form-row">
                        <el-form-item class="form-item">
                            <span class="form-label">活动名称：</span>
                            <el-input placeholder="活动名称" size="mini" v-model="searchInfo.campaignName"></el-input>
                        </el-form-item>
                        <el-form-item class="form-item">
                            <span class="form-label">活动类型：</span>
                            <el-select v-model="searchInfo.campaignType" placeholder="请选择">
                                <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="form-item">
                            <span class="form-label">活动商品：</span>
                            <el-input placeholder="活动商品" size="mini" v-model="searchInfo.goodsKeyWord"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form-row">
                        <el-form-item class="form-item">
                            <span class="form-label">活动时间：</span>
                            <el-date-picker v-model="searchInfo.orderTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-button size="mini" type="primary" @click="searchData">搜索
                        </el-button>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="box-shadow-unify">
            <div class="form-title">
                <el-button @click="addActivity()" type="primary">添加优惠</el-button>
            </div>
        </div>
        <ws-page-table border :data="tableData" :pageInfo="pageInfo" ref="pageTable" @pageChange="loadTableData" :pageable="true" :showIndex="true">
            <el-table-column align="center" prop="campaignType" label="活动类型" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{scope.row.campaignType=='reduction'?'优惠活动':scope.row.campaignType=='presell'?'预售活动':'限购活动'}}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="campaignName" label="活动名称" :show-overflow-tooltip="true"></el-table-column>

            <el-table-column align="center" prop="beginTime" label="活动时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ Number(scope.row.beginTime) | formatDate('YYYY-MM-DD HH:mm:ss')}}</span>~<span>{{ Number(scope.row.endTime) | formatDate('YYYY-MM-DD HH:mm:ss') }}</span></template>
            </el-table-column>
            <el-table-column align="center" prop="weight" label="活动商品" :show-overflow-tooltip="true"><template slot-scope="scope">
                    <!--
                    <span v-for="(item, index) in tagList[scope.$index]" :key="index">{{ item }}、</span>
                    -->
                    <span v-for="(item, index) in scope.row.campaignCouponGoodsRelaList" :key="index">{{ item.goodsCode }}、</span>
                </template></el-table-column>
            <el-table-column align="center" prop="label" label="活动标签" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="icon-operation">
                        <a href="javascript: void(0);" title="查看">
                            <i class="CCSFont icon-table_2" @click="seeDetail(scope.row.campaignId)"></i>
                        </a>
                        <a href="javascript: void(0);" @click="goDetail(scope.row.campaignId)" title="编辑">
                            <i class="CCSFont icon-table_1"></i>
                        </a>
                        <!-- <div @click="goDetele(scope.$index)">
              <i class="el-icon-s-goods"></i>
            </div> -->
                    </div>
                </template>
            </el-table-column>
        </ws-page-table>
    </div>
</template>

<script>
import BimActivity from "@/api/bim/BimActivity";
import pageTableMixin from "@/mixin/pageTableMixin.js";
export default {
    name: "Activity",
    data() {
        return {
            tagList: [],
            sourceList: [
                {
                    value: "reduction",
                    label: "优惠活动",
                },
                {
                    value: "presell",
                    label: "预售活动",
                },
                {
                    value: "limit",
                    label: "限购活动",
                },
            ],
            searchInfo: {
                campaignName: "",
                campaignType: "",
                goodsKeyWord: "",
                orderTime: "",
            },
            tableData: [],
        };
    },
    mixins: [pageTableMixin],
    activated() {
        this.loadTableData();
    },
    methods: {
        loadTableData() {
            this.$refs.pageTable.tableLoading = true;
            this.pageInfo.flagWithC = "1";
            this.pageInfo.flagWithG = "1";
            this.pageInfo.campaignName = this.searchInfo.campaignName;
            this.pageInfo.campaignType = this.searchInfo.campaignType;
            this.pageInfo.goodsKeyWord = this.searchInfo.goodsKeyWord;
            this.pageInfo.beginTime = this.searchInfo.orderTime[0];
            this.pageInfo.endTime = this.searchInfo.orderTime[1];
            console.log(this.pageInfo);

            BimActivity.queryByInfoAtPage(
                this.pageInfo,
                this.searchConfig
            ).then((data) => {
                console.log(data);
                for (let i in data.list) {
                    let Arr = [];
                    for (let j in data.list[i].campaignCouponGoodsRelaList) {
                        Arr.push(
                            data.list[i].campaignCouponGoodsRelaList[j]
                                .goodsCode
                        );
                    }
                    this.tagList.push(Arr);
                }

                // let list = this.tableData;
                // for (let i in list) {
                //     let tagList = [];
                //     for (let item in list[i].campaignCouponGoodsRelaList) {
                //         tagList.push(
                //             list[i].campaignCouponGoodsRelaList[item].goodsCode
                //         );
                //     }
                //     this.tagList.push(tagList);
                // }
                this.querySuccess(data);
                console.log(this.tagList[0]);
            });
        },
        addActivity() {
            this.$router.push({
                path: "/activityEdit",
            });
            let newlyBuild = true;
            localStorage.setItem("newlyBuild", newlyBuild);
        },
        goDetail(id) {
            console.log(id);
            this.$router.push({
                path: "/activityEdit",
                query: {
                    id: id,
                },
            });
        },
        seeDetail(id) {
            this.$router.push({
                path: "/activityEdit",
                query: {
                    id: id,
                    seeDetail: true,
                },
            });
        },
        deleteBrand(id) {
            console.log(id);
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    BimActivity.delByAcitivity(id).then((data) => {
                        this.$message({ type: "success", message: "删除成功" });
                        //this.tableData = Object.assign([], this.tableData);
                        this.loadTableData();
                    });
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.form-title {
    width: 100%;
    height: 60px;
    font-size: 16px;
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: 26px;
    margin-right: 30px;
    /* display: inline-block; */
}
</style>
