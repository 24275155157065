<template>
  <div class="order-list">
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">订单号：</span>
            <el-input size="mini" v-model="searchInfo.orderCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">导入批次：</span>
            <el-input size="mini" v-model="searchInfo.batchCode"></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">收件人：</span>
            <el-input size="mini" v-model="searchInfo.recipientName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件手机：</span>
            <el-input
                size="mini"
                v-model="searchInfo.recipientPhoneNum"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件地址（模糊）：</span>
            <el-input
                size="mini"
                v-model="searchInfo.recipientFullAddress"
            ></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">运单号：</span>
            <el-input
                size="mini"
                v-model="searchInfo.packageTransportNo"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">平台单号：</span>
            <el-input
                size="mini"
                v-model="searchInfo.thirdPartyOrderCode"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品编码：</span>
            <el-input size="mini" v-model="searchInfo.goodsCode"></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">下单时间：</span>
            <el-date-picker
                v-model="filterTimes"
                @change="changePickerDate"
                size="mini"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
            <span
                class="filter-time-block"
                @click="filterDate('today')"
                :class="{ 'is-active': filterDateTimer == 'today' }"
            >今</span
            >
            <span
                class="filter-time-block"
                @click="filterDate('yesterday')"
                :class="{ 'is-active': filterDateTimer == 'yesterday' }"
            >昨</span
            >
            <span
                class="filter-time-block"
                @click="filterDate(7)"
                :class="{ 'is-active': filterDateTimer == 7 }"
            >近7天</span
            >
            <span
                class="filter-time-block"
                @click="filterDate(30)"
                :class="{ 'is-active': filterDateTimer == 30 }"
            >近30天</span
            >
          </div>
        </div>
        <div class="form-row">
          <el-button
              size="mini"
              type="primary"
              style="margin-right: 10px"
              @click="searchData"
          >搜索
          </el-button>
          <el-button
              size="mini"
              type="primary"
              @click="exportOrder"
          >导出包裹
          </el-button>
        </div>
      </div>
    </div>
    <div class="order-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs
            v-model="searchInfo.orderState"
            type="card"
            @tab-click="changeTable"
        >
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="待支付" name="unPaid"></el-tab-pane>
          <el-tab-pane label="待发货" name="unShipped"></el-tab-pane>
          <el-tab-pane label="待收货" name="unReceived"></el-tab-pane>
          <el-tab-pane label="已完成" name="finished"></el-tab-pane>
          <el-tab-pane label="已关闭" name="closed"></el-tab-pane>
          <el-tab-pane label="审核中" name="audit"></el-tab-pane>
          <el-tab-pane label="延迟发货" name="unShippedDelay"></el-tab-pane>
          <el-tab-pane label="售后中" name="afterSale"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          :selection="null"
          :isShadow="false"
      >
        <el-table-column prop="orderCode" label="订单号" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="status" label="订单状态" width="180"></el-table-column>
        <el-table-column prop="price" label="订单价格（元）" width="180"></el-table-column>
        <!--
        <el-table-column prop="source" label="订单来源"></el-table-column>
        -->
        <el-table-column prop="createTime" label="创建时间" width="180"
                         v-if="searchInfo.orderState != 'unShippedDelay'"></el-table-column>
        <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true"
                         v-if="searchInfo.orderState != 'unShippedDelay'"></el-table-column>
        <el-table-column prop="sysRemark" label="延迟原因" :show-overflow-tooltip="true"
                         v-if="searchInfo.orderState == 'unShippedDelay'"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="编辑">
                <i
                    class="CCSFont icon-table_1"
                    @click="$router.push(`/som/orderEditD/${scope.row.orderId}`)"
                ></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
  </div>
</template>

<script>
import tools from "COMJS/tools";
//import Objects from "COMJS/Objects";
import Enum from "ASSETS/js/enum";
import translate from "ASSETS/js/translate";
import chargeFormat from "COMJS/chargeFormat";
import orderService from "API/som/orderService";
import orderOperateService from "API/som/orderOperate";
import orderShipService from "API/som/orderShipService";
import orderExportService from "API/som/orderExportService";
import pageTableMixin from "@/mixin/pageTableMixin";
import IoProgressService from "API/IoProgressService";

export default {
  name: "orderList",
  data() {
    return {
      searchInfo: {
        orderCode: "",
        batchCode: "",
        orderType: "import",
        createTimeBeginDate: "",
        createTimeEndDate: "",
        orderState: "all",
        recipientName: "",
        recipientPhoneNum: "",
        recipientFullAddress: "",
        thirdPartyOrderCode: "",
        goodsCode: "",
        packageTransportNo: "",
      },
      filterTimes: [],
      filterDateTimer: "", // 快捷选择时间类型
      sourceList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "wmp",
          label: "小程序",
        },
        {
          value: "import",
          label: "批量导入",
        },
      ],
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      orderService.queryOrderPages(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          e.createTime = tools.formatDate(
              new Date(e.createTime),
              "yyyy-MM-dd hh:mm:ss"
          );
          // e.source = translate.source(e.orderType);
          e.source = Enum.orderType[e.orderType];
          e.status = translate.orderState(e);
          e.orderCode = e.orderCode || "无";
          e.price = e.totalAmount; // chargeFormat.thousandsOf()
          e.sysRemark = e.sysRemark;
          return e;
        });
        this.querySuccess(data);
      });
    },
    changeTable(event) {
      this.searchInfo.orderState = event.name;
      this.loadTableData();
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.createTimeBeginDate = "";
        this.searchInfo.createTimeEndDate = "";
      } else {
        this.searchInfo.createTimeBeginDate = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.searchInfo.createTimeEndDate = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
    filterDate(timer) {
      this.filterDateTimer = timer;
      let _time = tools.filterDateMethod(timer);
      this.$set(this.filterTimes, 0, _time.startTime);
      this.$set(this.filterTimes, 1, _time.endTime);
      this.searchInfo.createTimeBeginDate = _time.startTime;
      this.searchInfo.createTimeEndDate = _time.endTime;
    },
    exportOrder() {
      if(this.searchInfo.orderState!='all'){
        this.searchInfo.orderState = 'all'
        this.loadTableData()
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      orderExportService
          .exportPackageForDistributor(this.queryParams)
          .then((data) => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
    },
    async deleteOrder() {
      if (this.pageInfo.total == 0) {
        this.$message.error("没有订单!");
        return;
      }
      var that = this;
      this.$confirm(
          "此操作将永久关闭" + this.pageInfo.total + "条订单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            orderOperateService.cancelOrderList(that.queryParams).then((data) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadTableData();
            });
          })
          .catch(() => {
          });
    },
  },
  mounted() {
    //console.log('vue mounted',this.$route)
  },
  beforeMount() {
    //页面刷新
    if (this.$route.query.orderState) {
      this.searchInfo.orderState = this.$route.query.orderState;
    }
  },
  activated() {
    //页签切换
    if (this.$route.query.orderState) {
      this.searchInfo.orderState = this.$route.query.orderState;
      this.loadTableData();
    }
  }
};
</script>

<style lang="scss">
.order-list {
  .order-table {
    .tabs-wrapper {
      padding: 10px 20px;

      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;

        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }

    background-color: #fff;
  }
}

.order-list .el-dialog__body .el-input {
  width: 100% !important;
}
</style>
