<template>
  <div class="whm-list-wrap">
    <el-form :inline="true" class="btn-wrap">
      <el-form-item>
        <el-button type="primary" @click="syncMallGoods"
          >同步商场商品</el-button
        >
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="商品名称:">
        <el-input v-model="searchInfo.goodsName" />
      </el-form-item>
      <el-form-item label="商品编码:">
        <el-input v-model="searchInfo.goodsCode" />
      </el-form-item>
      <el-form-item label="关联库品状态:">
        <!-- <el-input v-model="searchInfo.thingTypeName" /> -->
        <el-select v-model="searchInfo.isJoin" placeholder="请选择">
          <el-option
            v-for="item in relateStateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column
        prop="goodsCode"
        label="商品编码"
        :show-overflow-tooltip="true"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="goodsName"
        label="商品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="skuNum" label="关联库品"></el-table-column>
      <el-table-column prop="isJoin" label="关联库品状态" width="100">
        <template slot-scope="scope">
          {{ scope.row.isJoin === "1" ? "已关联" : "未关联" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import relateQuery from "API/whm/relateQuery";
import pageTableMixin from "@/mixin/pageTableMixin.js";
export default {
  name: "productRelate",
  data() {
    return {
      searchInfo: {
        goodsCode: "",
        goodsName: "",
        isJoin: "",
      },
      relateStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "已关联",
        },
        {
          value: "0",
          label: "未关联",
        },
      ],
    };
  },
  mixins: [pageTableMixin],

  methods: {
    loadTableData() {
      relateQuery
        .queryProductRelatePages(this.pageInfo, this.searchConfig)
        .then((data) => {
          data.list = data.list.map((e) => {
            if (!e.goodsInfo) {
              e.goodsInfo = {};
            }
            return {
              id: e.goodsId,
              goodsName: e.goodsName,
              goodsCode: e.goodsCode,
              isJoin: e.isJoin,
              skuNum: e.skuNum,
            };
          });
          this.querySuccess(data);
        });
      sessionStorage.clear();
    },
    toEdit(row) {
      //this.$router.push(`/pim/productAdd_step_1/${row.id}`)
      sessionStorage.setItem("page", this.pageInfo.pageNo);
      this.$router.push(`/whm/productRelateEdit/${row.id}`);
    },
    syncMallGoods() {
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      var object = new Object();
      relateQuery.syncMallGoods(object).then((res) => {
        if (res.msg == "OK") {
          this.$message.success("商品同步成功！");
          this.loadTableData();
        }
        loading.close();
      });
    },
  },
};
</script>

<style>
.whm-list-wrap .btn-wrap {
  padding: 20px 0 0 20px;
  background: #fff;
}
.whm-list-wrap .demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.whm-list-wrap .el-form--inline .el-form-item {
  margin-right: 15px;
  margin-bottom: 0;
}
.whm-list-wrap .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}
.whm-list-wrap .ws-page-table .icon-operation a {
  margin-right: 20px;
}
</style>