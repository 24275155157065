import axiosHttp from "../axiosHttp";

export default {    
    queryOutboundPage(pageInfo,searchConfig){ //出库列表
        let url = "/whmOutbound/page?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    //whmSkuGroup/save 库品组合新增
    saveSkuGroup(params){
        return axiosHttp.POST('/whmSkuGroup/save', params, false);
    }, 
    detailOut(outboundDocumentsId){ // 出库详情
        return axiosHttp.GET(`/whmOutbound/detail/${outboundDocumentsId}`);
    },
    //whmReceive/queryByName
    queryReceivePages(searchConfig){//领用人列表
        return axiosHttp.GET(`/whmReceive/queryByName/${searchConfig}`);
    },
    //whmInventory/drawInv 添加领用
    saveReceive(params){
        return axiosHttp.POST('/whmInventory/drawInv', params, false);
    }, 
    //分页查询领用信息
    queryByReceiveList(params){
        return axiosHttp.POST('/whmInventoryRecord/queryByReceive', params, false);
    },
    exportOrderPackage(pageInfo,searchConfig){ //下载---1、导出日志
        let url = "/whmOutbound/exportOrder?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    // 订单导出分页查询
    // queryRecordPages(params) {
    //     return axiosHttp.POST('/whmOutbound/listExportLog', params, false);
    // }, 
    // queryRecordPages(pageInfo,searchConfig){ //出库列表
    //     let url = "/whmOutbound/listExportLog?"
    //     if (pageInfo) {
    //         url = url + pageInfo.buildQueryString();
    //     }
    //     url += "&" + searchConfig.buildQueryString();
    //     return axiosHttp.GET(url)
    // },
    queryRecordPages(params) {
        return axiosHttp.POST('/whmOutbound/listExportLog', params, false);
    },
    saveTransport(params) {
        return axiosHttp.POST('/whmOutbound/saveTransport', params, false);
    },
    splitOutbound(params) {
        return axiosHttp.POST('/whmOutbound/splitOutbound', params, false);
    },
    mergeOutbound(params) {
        return axiosHttp.POST('/whmOutbound/mergeOutbound', params, false);
    },
    expressToLogistics(outboundId){
        return axiosHttp.GET(`/whmOutbound/expressToLogistics/${outboundId}`);
    },
}