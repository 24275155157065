<template>
  <div class="ws-time-line">
    <div class="ws-time-line-item" v-for="(item, index) in timeLineList" :key="index">
      <span class="time">{{item.time}}</span>
      <span class="line"></span>
      <span class="events">{{item.events}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "wsTimeLine",
  props: {
    /**
     * @param {Object} timeLine timeLineList[0]
     * @param {String} timeLine.id 日志id
     * @param {String} timeLine.time 时间
     * @param {String} timeLine.events 事件
     */
    timeLineList: Array
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "~ASSETS/css/common/tool.scss";

.ws-time-line {
  .ws-time-line-item {
    display: flex;
    font-size: 14px;
    margin-bottom: 26px;
    color: #666666;
    .time{
      width: 110px;
    }
    > span {
      display: inline-block;
      vertical-align: middle;
    }
    &:last-of-type {
      .line:after {
        display: none;
      }
    }
    .line {
      position: relative;
      top: 1px;
      flex-shrink: 0;
      width: 9px;
      height: 9px;
      margin-left: 16px;
      margin-right: 12px;
      background-color: #dcdee0;
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        bottom: -26px;
        left: 50%;
        transform: translateX(-50%);
        height: 26px;
        width: 1px;
        background-color: #dcdee0;
      }
    }
    .events {
      flex: 1;
      @include Mult-line(1);
    }
  }
}
</style>