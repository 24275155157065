import { render, staticRenderFns } from "./whmSpec.vue?vue&type=template&id=08e2e973&scoped=true"
import script from "./whmSpec.vue?vue&type=script&lang=js"
export * from "./whmSpec.vue?vue&type=script&lang=js"
import style0 from "./whmSpec.vue?vue&type=style&index=0&id=08e2e973&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e2e973",
  null
  
)

export default component.exports