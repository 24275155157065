<template>
    <div class="product-add">
        <el-form :model="spuInfo" label-width="110px" ref="spuInfoForm" class="step-form">
            <div class="step-wrapper base-from box-shadow-unify">
                <div class="step-title">基本信息</div>
                <el-form-item required label="聚合名称：">
                    <el-input v-model="spuInfo.spuName" maxlength="20" show-word-limit></el-input>
                    <div class="tips">用于消费端展示，建议20字以内</div>
                </el-form-item>
                <el-form-item label="聚合规格：">
                    <el-input v-model="spuInfo.spuSpec" maxlength="10" show-word-limit></el-input>
                </el-form-item>

                <el-form-item required label="零售价：">
                    <el-input v-model="spuInfo.spuPrice" maxlength="11" @keyup.native="keyupEvent($event,spuInfo.spuPrice)">
                        <template slot="prepend">￥</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="划线价：">
                    <el-input v-model="spuInfo.spuMarkingPrice" maxlength="14" @keyup.native="keyupEvent($event,spuInfo.spuMarkingPrice)">
                        <template slot="prepend">￥</template>
                    </el-input>
                    <div class="tips">
                        划线价用于商品列表的价格展示，不填写默认不展示。
                        <span class="tips-btn" @click="$refs.priceExamplePopup.dialogVisible = true">查看示例</span>
                    </div>
                </el-form-item>
            </div>
            <div class="step-wrapper base-from box-shadow-unify">
                <div class="step-title">图片信息</div>
                <el-form-item required label="缩略图：">
                    <div>
                        <draggable-upload :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()" :requestHeaders="requestHeaders" v-model="thumbnailImgs"></draggable-upload>
                    </div>
                    <div class="tips">
                        用于展示商品规格
                    </div>
                </el-form-item>
                <el-form-item required label="轮播图：">
                    <div>
                        <draggable-upload :width="82" :height="82" :limit="10" :uploadUrl="uploadUrl()" :requestHeaders="requestHeaders" v-model="rotationImgs"></draggable-upload>
                    </div>
                    <div class="tips">你可以拖拽图片调整顺序，最多上传10张</div>
                </el-form-item>
                <el-form-item required label="详情图：">
                    <div>
                        <draggable-upload :width="82" :height="82" :limit="50" :uploadUrl="uploadUrl()" :requestHeaders="requestHeaders" v-model="detailImgs"></draggable-upload>
                    </div>
                    <div class="tips">你可以拖拽图片调整顺序，最多上传50张</div>
                </el-form-item>
            </div>

            <div class="step-wrapper base-from">
                <el-button type="primary" @click="$refs.selectGoodsPopup.dialogVisible = true">关联商品</el-button>
                <ws-page-table :data="spuInfo.relaList" style="margin-top:10px;width:100%;" :pageable="false" :showIndex="true">
                    <el-table-column prop="goodsName" label="商品名称"></el-table-column>
                    <el-table-column prop="goodsCode" label="商品编号"></el-table-column>
                    <el-table-column label="商品规格">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.skuSpec" maxlength="8" show-word-limit></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="关联模式">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.relaMode" placeholder="请选择">
                                <el-option v-for="item in relaMode" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <div class="icon-operation">
                                <i class="el-icon-upload2" @click="upOne(scope.$index)"></i>
                                <i class="el-icon-download" @click="downOne(scope.$index)"></i>
                                <i class="el-icon-circle-close" @click="deleteOne(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                </ws-page-table>
            </div>

            <div class="step-wrapper base-from">
                <el-button type="primary" @click="selectSpuTag">添加标签</el-button>
                <ws-page-table :data="spuInfo.tagList" ref="tagListTable" style="margin-top:10px;width:100%;" :pageable="false" :showIndex="true">
                    <el-table-column label="标签信息">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.tagContent" maxlength="8" show-word-limit></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.remark" maxlength="100" show-word-limit></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="是否启用">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.enabled"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="有效时间">
                        <template slot-scope="scope">
                            <el-date-picker @change="SetTime" v-model="scope.row.timeIn" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="90">
                        <template slot-scope="scope01">
                            <div class="icon-operation">
                                <i class="el-icon-circle-close" @click="deleteOne01(scope01.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                </ws-page-table>
            </div>

            <div class="step-wrapper base-from box-shadow-unify">
                <div class="step-title">细节展示</div>
                <el-form-item label="展示销量：">
                    <el-switch v-model="spuInfo.mallShowSale" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item label="位置1：">
                    <el-input v-model="position1.content" maxlength="5" show-word-limit></el-input>
                    <div class="tips">
                        用于商品详情的展示，不填写默认不展示。下同。
                        <span class="tips-btn" @click="$refs.displayExamplePopup.dialogVisible = true">查看示例</span>
                    </div>
                </el-form-item>
                <el-form-item label="位置2：">
                    <el-input v-model="position2.content" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="位置3：">
                    <el-input v-model="position3.content" maxlength="30" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="位置4：">
                    <el-button type="primary" @click="addPosition4">添加</el-button>
                    <ws-page-table :data="position4List" ref="position4ListTable" style="width: 50%;" :pageable="false" :showIndex="true">
                        <el-table-column label="内容">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.content" maxlength="50" show-word-limit></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="90">
                            <template slot-scope="scope">
                                <div class="icon-operation">
                                    <i class="el-icon-circle-close" @click="deleteFromPosition4List(scope.$index)"></i>
                                </div>
                            </template>
                        </el-table-column>
                    </ws-page-table>
                </el-form-item>
                <el-form-item label="位置5：">
                    <el-button type="primary" @click="addPosition5">添加</el-button>
                    <ws-page-table :data="position5List" ref="position5ListTable" style="width: 50%;" :pageable="false" :showIndex="true">
                        <el-table-column label="内容">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.content" maxlength="50" show-word-limit></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="90">
                            <template slot-scope="scope">
                                <div class="icon-operation">
                                    <i class="el-icon-circle-close" @click="deleteFromPosition5List(scope.$index)"></i>
                                </div>
                            </template>
                        </el-table-column>
                    </ws-page-table>
                </el-form-item>
            </div>
        </el-form>

        <div class="steps-btn-wrapper">
            <el-button type="primary" @click="saveSpu">保存</el-button>
        </div>

        <example-popup ref="displayExamplePopup" title="查看展示示例" tips="商品在APP及小程序的详情展示示例：" :imgUrl="require('ASSETS/images/goods_display_example.png')"></example-popup>
        <example-popup ref="priceExamplePopup" title="查看划线价示例" tips="划线价在商品详情中显示示例：" :imgUrl="require('ASSETS/images/price-example.png')"></example-popup>
        <select-goods-popup ref="selectGoodsPopup" @submit="selectGoods"></select-goods-popup>
    </div>
</template>

<script>
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import pimGoodsSpuService from "API/pim/PimGoodsSpuService";
import examplePopup from "COMPS/widgets/examplePopup";
import selectGoodsPopup from "./selectGoodsPopup";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools.js";
import Objects from "COMJS/Objects";
import eventBus from "../../../../js/eventBus";

export default {
    name: "spuEdit",
    data() {
        return {
            spuInfo: {
                spuId: "",
                shopId: "",
                spuName: "",
                spuSpec: "",
                spuPicUrl: "",
                spuPrice: "",
                spuMarkingPrice: "",
                //spuSaleCount: 0,
                mallShowSale: false, //'0'
                isEnable: "",
                createTime: "",
                updateTime: "",
                state: "",
                photoAlbumList: [],
                relaList: [],
                exhibitionList: [],
                tagList: [],
            },
            position1: {},
            position2: {},
            position3: {},
            position4List: [],
            position5List: [],

            //position6: {},
            requestHeaders: {
                Authorization: userModule.token,
            },
            thumbnailImgs: [],
            rotationImgs: [], // 轮播
            //videos: [], // 视频
            detailImgs: [], // 详情
            //videoUrl: "",
            shareImgs: [],
            shareMomentsImgs: [],
            spuStateList: [
                {
                    id: "normal",
                    name: "正常",
                },
                {
                    id: "sell_out",
                    name: "售罄",
                },
            ],
            editorConfig: {
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "50%",
                // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
                // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
                serverUrl: "/static/UEditor/php/controller.php",
                UEDITOR_HOME_URL: "/plugin/UEditor/",
            },
            relaMode: [
                {
                    label: "直联商品",
                    value: "direct",
                },
                {
                    label: "定制商品",
                    value: "custom_made",
                },
            ],
        };
    },
    components: {
        examplePopup,
        selectGoodsPopup,
        draggableUpload,
    },
    mounted() {
        this.getGoodsSpuDetail();
    },
    beforeDestroy() {},
    methods: {
        SetTime(e) {
            this.spuInfo.tagList.beginTime = e[0];
            this.spuInfo.tagList.endTime = e[1];
        },
        getGoodsSpuDetail() {
            if (Objects.isEmpty(this.$route.params.id)) {
                return;
            }
            let that = this;
            pimGoodsSpuService
                .getGoodsSpuDetail(this.$route.params.id)
                .then((data) => {
                    if (Objects.isEmpty(data)) {
                        return;
                    }
                    that.spuInfo = data;
                    that.spuInfo.mallShowSale =
                        that.spuInfo.mallShowSale == "1" ? true : false;
                    //console.log(that.spuInfo);
                    if (that.spuInfo.relaList) {
                        that.spuInfo.relaList = that.spuInfo.relaList.map(
                            (e, i) => {
                                if (!e.pimGoods) {
                                    e.pimGoods = {};
                                }
                                return {
                                    goodsId: e.goodsId,
                                    goodsName: e.pimGoods.cnName,
                                    goodsCode: e.pimGoods.goodsCode,
                                    picUrl: e.pimGoods.pictureUrl,
                                    skuSpec: e.skuSpec,
                                    relaMode: e.relaMode,
                                };
                            }
                        );
                    }
                    if (that.spuInfo.exhibitionList) {
                        let list1 = that.spuInfo.exhibitionList.filter(
                            (e) => e.position === "position1"
                        );
                        if (list1.length > 0) {
                            that.position1 = list1[0];
                        }
                        let list2 = that.spuInfo.exhibitionList.filter(
                            (e) => e.position === "position2"
                        );
                        if (list2.length > 0) {
                            that.position2 = list2[0];
                        }
                        let list3 = that.spuInfo.exhibitionList.filter(
                            (e) => e.position === "position3"
                        );
                        if (list3.length > 0) {
                            that.position3 = list3[0];
                        }
                        let list4 = that.spuInfo.exhibitionList.filter(
                            (e) => e.position === "position4"
                        );
                        if (list4.length > 0) {
                            that.position4List = list4;
                        }
                        let list5 = that.spuInfo.exhibitionList.filter(
                            (e) => e.position === "position5"
                        );
                        if (list5.length > 0) {
                            that.position5List = list5;
                        }
                        let list6 = that.spuInfo.exhibitionList.filter(
                            (e) => e.position === "position6"
                        );
                        if (list6.length > 0) {
                            that.position6 = list6[0];
                        }
                        //data.photoAlbumList.sort(this.compare("position"));
                        that.thumbnailImgs = data.spuPicUrl
                            ? [
                                  {
                                      response: data.spuPicUrl,
                                      url: data.spuPicUrl,
                                  },
                              ]
                            : [];
                        this.shareImgs = data.spuSharePicUrl
                            ? [
                                  {
                                      response: data.spuSharePicUrl,
                                      url: data.spuSharePicUrl,
                                  },
                              ]
                            : [];
                        this.shareMomentsImgs = data.spuShareMomentsPicUrl
                            ? [
                                  {
                                      response: data.spuShareMomentsPicUrl,
                                      url: data.spuShareMomentsPicUrl,
                                  },
                              ]
                            : [];
                        that.rotationImgs = that.spuInfo.photoAlbumList
                            .filter((e) => e.photoType === "sow")
                            .map((e) => {
                                return {
                                    response: e.photoUrl,
                                    url: e.photoUrl,
                                    name: e.photoName,
                                    position: e.position,
                                };
                            });
                        that.detailImgs = that.spuInfo.photoAlbumList
                            .filter((e) => e.photoType === "detail")
                            .map((e) => {
                                return {
                                    response: e.photoUrl,
                                    url: e.photoUrl,
                                    name: e.photoName,
                                    position: e.position,
                                };
                            });
                    }
                    if (that.spuInfo.tagList) {
                        for (var ele of that.spuInfo.tagList) {
                            ele.enabled = ele.enabled == "1" ? true : false;
                            ele.timeIn = [ele.beginTime, ele.endTime];
                        }
                    }
                });
        },
        uploadUrl() {
            let url = IoProgressService.getUploadUrl("pimGoods");
            return url;
        },
        // 视频
        uploadVideoSuccess(fileList) {
            this.videos = fileList;
        },
        validation() {
            if (!this.spuInfo.spuName) {
                return this.$message({
                    message: "请输入聚合名称",
                    type: "warning",
                });
            }
            if (!this.spuInfo.spuPrice) {
                return this.$message({
                    message: "请输入零售价",
                    type: "warning",
                });
            }
            //判空
            if (!this.thumbnailImgs.length) {
                return this.$message({
                    message: "请添加缩略图",
                    type: "warning",
                });
            }
            if (!this.rotationImgs.length) {
                return this.$message({
                    message: "请添加轮播图",
                    type: "warning",
                });
            }
            if (!this.detailImgs.length) {
                return this.$message({
                    message: "请添加详情图",
                    type: "warning",
                });
            }
            return 1;
        },
        saveSpu() {
            if (this.validation() != 1) {
                return;
            }
            //图片
            let _photoAlbums = [];
            let _photoAlbums_1 = this.rotationImgs.map((e, i) => {
                return {
                    photoUrl: e.response,
                    name: e.name,
                    state: 1,
                    photoType: "sow",
                    position: i + 1,
                };
            });
            let _photoAlbums_2 = this.detailImgs.map((e, i) => {
                return {
                    photoUrl: e.response,
                    name: e.name,
                    state: 1,
                    photoType: "detail",
                    position: i + 1,
                };
            });
            _photoAlbums = _photoAlbums_1.concat(_photoAlbums_2);

            //展示内容
            let allPositionList = [];
            if (this.position1 && this.position1.content) {
                this.position1.position = "position1";
                this.position1.contentType = "text";
                allPositionList.push(this.position1);
            }
            if (this.position2 && this.position2.content) {
                this.position2.position = "position2";
                this.position2.contentType = "text";
                allPositionList.push(this.position2);
            }
            if (this.position3 && this.position3.content) {
                this.position3.position = "position3";
                this.position3.contentType = "text";
                allPositionList.push(this.position3);
            }
            if (this.position4List && this.position4List.length > 0) {
                for (var i in this.position4List) {
                    this.position4List[i].position = "position4";
                    this.position4List[i].contentType = "text";
                    this.position4List[i].dispNo = i;
                    allPositionList.push(this.position4List[i]);
                }
            }
            if (this.position5List && this.position5List.length > 0) {
                for (var i in this.position5List) {
                    this.position5List[i].position = "position5";
                    this.position5List[i].contentType = "text";
                    this.position5List[i].dispNo = i;
                    allPositionList.push(this.position5List[i]);
                }
            }
            if (this.position6 && this.position6.content) {
                this.position6.position = "position6";
                this.position6.contentType = "rich_text";
                allPositionList.push(this.position6);
            }
            //完整
            this.spuInfo.exhibitionList = allPositionList;
            this.spuInfo.photoAlbumList = _photoAlbums;
            this.spuInfo.spuPicUrl = this.thumbnailImgs.length
                ? this.thumbnailImgs[0].response
                : "";
            this.spuInfo.spuSharePicUrl = this.shareImgs.length
                ? this.shareImgs[0].response
                : "";
            this.spuInfo.spuShareMomentsPicUrl = this.shareMomentsImgs.length
                ? this.shareMomentsImgs[0].response
                : "";
            this.spuInfo.mallShowSale = this.spuInfo.mallShowSale ? "1" : "0";

            //商品关联模
            if (this.spuInfo.relaList) {
                //注意 for in 结果没有改动!!
                for (var ele of this.spuInfo.relaList) {
                    if (!ele.relaMode) {
                        //console.dir(this.spuInfo);
                        return this.$message({
                            message: "聚合商品的商品关联模式有空的",
                            type: "error",
                        });
                    }
                }
            }
            //标签启用：把true、false转换成1、0，否则后端会报异常
            if (this.spuInfo.tagList) {
                for (var ele of this.spuInfo.tagList) {
                    ele.enabled = ele.enabled == true ? "1" : "0";
                    ele.beginTime = ele.timeIn[0];
                    ele.endTime = ele.timeIn[1];
                }
            }
            const loading = this.$loading({
                lock: true,
                text: "保存中...",
            });
            pimGoodsSpuService
                .savePimGoodsSpu(this.spuInfo)
                .then((data) => {
                    loading.close();
                    let StepsData = this.$store.state.ProductStepsData;
                    if (StepsData.Back) {
                        this.$confirm(`是否返回商品页继续之前操作？`, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        }).then(() => {
                            this.$router.push({
                                path: "/pim/AddGoods"
                            });
                        });
                    } else {
                        this.$router.go(-1);
                    }
                    eventBus.$emit("refresh");
                })
                .catch(() => {
                    loading.close();
                });
        },
        beforeImgUpload(file) {
            let isLtM = file.size / 1024 / 1024 < 2;
            if (!isLtM) {
                this.$message.error("品牌图标大小不能超过2M");
                return;
            }
        },
        deleteOne01(index) {
            console.error("这里是:~~deleteOne01!!!!");
            this.spuInfo.tagList.splice(index, 1);
        },
        deleteOne(index) {
            this.spuInfo.relaList.splice(index, 1);
        },
        upOne(i) {
            if (i == 0) {
                this.$message("已经在最上面了");
                return;
            }
            let item = this.spuInfo.relaList[i];
            this.$set(this.spuInfo.relaList, i, this.spuInfo.relaList[i - 1]);
            this.$set(this.spuInfo.relaList, i - 1, item);
        },
        downOne(i) {
            if (i == this.spuInfo.relaList.length - 1) {
                this.$message("已经在最下面了");
                return;
            }
            let item = this.spuInfo.relaList[i];
            this.$set(this.spuInfo.relaList, i, this.spuInfo.relaList[i + 1]);
            this.$set(this.spuInfo.relaList, i + 1, item);
        },
        addPosition4() {
            this.position4List.push({});
        },
        addPosition5() {
            this.position5List.push({});
        },
        selectGoods(selected) {
            if (selected && selected.length) {
                //selected.length.map()
                let newRelaList = selected.map((e, i) => {
                    return {
                        goodsId: e.goodsId,
                        goodsName: e.cnName,
                        goodsCode: e.goodsCode,
                        picUrl: e.pictureUrl,
                    };
                });
                for (let idx in newRelaList) {
                    this.spuInfo.relaList.push(newRelaList[idx]);
                }
            }
        },
        selectSpuTag() {
            //debugger;
            //SPU标签信息
            if (!this.spuInfo.tagList) {
                this.spuInfo.tagList = [];
            }
            this.spuInfo.tagList.push({});
        },
        keyupEvent(e, input) {
            e.target.value = e.target.value.replace(/[^\d.]/g, "");
            e.target.value = e.target.value.replace(/\.{2,}/g, ".");
            e.target.value = e.target.value.replace(/^\./g, "0.");
            e.target.value = e.target.value.replace(
                /^\d*\.\d*\./g,
                e.target.value.substring(0, e.target.value.length - 1)
            );
            e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
            e.target.value = e.target.value.replace(
                /^(\d+)\.(\d\d).*$/,
                "$1.$2"
            );
            this.input = e.target.value;
        },
        deleteFromPosition4List(index) {
            this.position4List.splice(index, 1);
        },
        deleteFromPosition5List(index) {
            this.position5List.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/components/productAdd.scss";

.product-add /deep/ .el-dialog {
    .el-dialog__header {
        display: none;
    }

    .el-dialog__footer {
        border-top: none;
    }

    .el-dialog__body {
        padding: 0;
    }
}

.el-inline-radio /deep/ .el-radio {
    display: inline-block;
}

.edit-icon {
    font-size: 25px;
}

.edit-icon i {
    cursor: pointer;
    font-size: 25px;
}
</style>