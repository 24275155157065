<template>
  <div>
    <div>
      <el-button type="primary" @click="createDialog">新增记录</el-button>
    </div>
    <div class="mt15">
      <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                     @pageChange="loadTableData">
        <el-table-column prop="factoryName" label="工厂名称"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <div class="operate-column">
              <a @click="editDialog(scope.row)" title="编辑">
                <i class="CCSFont icon-table_1 operate-btn"></i>
              </a>
              <a @click="removeDialog(scope.row)" title="删除">
                <i class="el-icon-delete-solid operate-btn"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <!---->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <el-form :model="dialogData" :rules="dialogRules" ref="dialogForm" label-width="110px" v-loading="dialogLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="24">
            <el-form-item label="工厂名称" prop="factoryName">
              <el-input v-model="dialogData.factoryName" :maxlength="32"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dialogData.remark" :maxlength="64" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeDialog">关 闭</el-button>
        <el-button type="primary" @click.native="submitDialog">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import purFactoryService from "API/pur/PurFactoryService";

export default {
  name: "InfoManager4FactoryList",
  mixins: [tableMixins],
  data() {
    return {
      dialogTitle: '新增',
      dialogVisible: false,
      dialogLoading: false,
      dialogData: {},
      dialogRules: {
        factoryName: [
          {required: true, message: '请输入工厂名称'},
          {max: 32, message: '长度不能超过32个字符', trigger: 'blur'},
        ]
      }
    }
  },
  mounted() {

  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      purFactoryService.queryPage(this.pageInfo, this.searchConfig).then(res => {
        this.querySuccess(res);
      }).finally(() => {
        this.$refs.pageTable.tableLoading = false;
      })
    },
    createDialog() {
      this.dialogData = {}
      try{
        this.$refs.dialogForm.resetFields();
      }catch(e){}
      this.dialogTitle = '新增'
      this.dialogVisible = true
    },
    editDialog(row) {
      this.dialogData = {
        ...row
      }
      this.dialogTitle = '编辑'
      this.dialogVisible = true
    },
    removeDialog(row) {
      let id = row.factoryId
      this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //删除
        purFactoryService.removeById(id).then(res => {
          this.loadTableData();
        })
      })
    },
    closeDialog() {
      this.dialogVisible = false
    },
    submitDialog() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          purFactoryService.save(this.dialogData).then(res=>{
            this.$message.success("保存成功")
            this.dialogVisible = false
            this.loadTableData()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.operate-column > a {
  margin-right: 20px;
  cursor: pointer;
  color: #00a0e9;
}
</style>