<template>
  <el-dialog title="支付账单" :visible.sync="dialogVisible" width="800px">
    <div v-if="payChannel=='none'">
      <p style="margin-top: 10px;font-size: 14px;">请选择付款方式：</p>
      <div class="none-div-class">
        <div class="none-choose-bord" @click="choosePayChannel('wxpay')">
          <img
              :src="require('ASSETS/images/payment/wxpay.png')"
              height="40px;"
          />
          <p style="margin-top: 5px;font-size: 14px;">微信支付</p>
        </div>
        <div class="none-choose-bord" @click="choosePayChannel('alipay')">
          <img
              :src="require('ASSETS/images/payment/zhifubao.png')"
              height="40px;"
          />
          <p style="margin-top: 5px;font-size: 14px;">支付宝转账</p>
        </div>
        <div class="none-choose-bord" @click="choosePayChannel('giro')">
          <img
              :src="require('ASSETS/images/payment/giro.png')"
              height="40px;"
          />
          <p style="margin-top: 5px;font-size: 14px;">银行转账</p>
        </div>
      </div>
    </div>
    <div v-show="payChannel=='wxpay'">
      <div>
        <div style="display: flex; padding-left: 80px">
          <div>
            <div style="text-align: center;margin-top: 30px;font-size: 20px;">
              支付金额：{{ billPaymentAmount }}元
            </div>
            <div
                id="qrCodeDom_rechargePop"
                style="width: 146px; height: 146px; margin:100px auto;text-align: center;line-height: 146px;"
            />
            <div style="display: flex; margin-top: 10px">
              <img
                  width="34.74px"
                  height="34.74px"
                  :src="require('ASSETS/images/payment/payment_tip2.png')"
                  alt=""
              />
              <div style="margin-left: 3.7px; line-height: 21px">
                请使用<span style="color: #5cdd61">微信</span>
                扫一扫扫描二维码支付,暂不支持截图识别。
              </div>
            </div>
          </div>
          <img
              :src="require('ASSETS/images/payment/pc_icon_phone-bg.png')"
              width="392px;"
              style="margin-left: 40px"
          />
        </div>
        <div slot="footer">
          <div
              style="
              display: flex;
              justify-content: center;
              width: 100%;
              padding-top: 10px;
            "
          >
            <el-button @click="payChannel = 'none'">返回</el-button>
            <el-button type="primary" @click="confirmWxpay">确认支付完成</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payChannel=='giro' || payChannel=='alipay'">
      <div
          style="
            margin-top: 20px;
            border: 1px solid rgba(218, 218, 218, 1);
            padding: 10px;
          "
      >
        <div style="display: flex" v-if="payChannel == 'giro'">
          <h3 style="margin-top: 9px" class="ws-title3">转入账户</h3>
          <el-form ref="form" :model="companyBank" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="户名">
                  <el-input
                      v-model="companyBank.accountName"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="账号">
                  <el-input
                      v-model="companyBank.accountNo"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开户行">
                  <el-input
                      v-model="companyBank.bankName"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="display: flex" v-if="payChannel == 'alipay'">
          <h3 style="margin-top: 9px" class="ws-title3">转入支付宝</h3>
          <el-form ref="form" :model="alipayAccount" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="名称">
                  <el-input
                      v-model="alipayAccount.accountName"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="账号">
                  <el-input
                      v-model="alipayAccount.accountNo"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div style="display: flex" v-if="payChannel == 'giro'">
          <h3 style="margin-top: 9px" class="ws-title3">我的账户</h3>
          <el-form ref="form" :model="myBank" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="名称">
                  <el-input v-model="myBank.accountName" style="width: 400px !important;"></el-input>
                  <div style="color: #99a9bf;font-size: 12px;">
                    如果订单需要开具发票，只能以此账户名称为抬头。
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="账号">
                  <el-input v-model="myBank.accountNo" style="width: 400px !important;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开户行">
                  <el-input v-model="myBank.bankName" style="width: 400px !important;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="display: flex" v-if="payChannel == 'alipay'">
          <h3 style="margin-top: 9px" class="ws-title3">我的支付宝</h3>
          <el-form ref="form" :model="myAlipayAccount" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="名称">
                  <el-input v-model="myAlipayAccount.accountName" style="width: 400px !important;"></el-input>
                  <div style="color: #99a9bf;font-size: 12px;">
                    如果订单需要开具发票，只能以此支付宝名称为抬头。
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="账号">
                  <el-input v-model="myAlipayAccount.accountNo" style="width: 400px !important;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div style="display: flex">
          <h3 style="margin-top: 9px;" class="ws-title3">支付金额</h3>
          <el-form ref="form" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="金额">
                  <el-input v-model="billPaymentAmount" disabled
                            maxlength="14">
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-alert title="截图需体现付款账户姓名、账号、金额及收款账户" type="warning" v-if="payChannel == 'giro'"></el-alert>
          <el-alert title="支付宝转账详情截图" type="warning" v-if="payChannel == 'alipay'"></el-alert>
          <div style="display: flex; margin-top: 20px">
            <h3 class="ws-title3">上传截图凭证</h3>
            <draggable-upload
                :width="82"
                :height="82"
                :limit="2"
                :fileSize="2048 * 1024"
                fileSizeText="2M"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="uploadImgs"
                style="margin-left: 10px"
            ></draggable-upload>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div style="display: flex;justify-content: center;width: 100%;padding-top: 10px;">
          <el-button @click="payChannel = 'none'">返回</el-button>
          <el-button type="primary" @click="doSubmit">提交</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import IoProgressService from "API/IoProgressService";
import Objects from "COMJS/Objects";
import dealerService from "API/dea/dealerService";
import orderPaymentService from "API/som/orderPaymentService";
import QRcode from "qrcodejs2";

export default {
  name: "rechargePop",
  components: {
    draggableUpload,
  },
  data() {
    return {
      dialogVisible: false,
      billId: '',
      billPaymentAmount: 0.00,
      payChannel: 'none',
      //wxpay
      outTradeNo:'e968f0ed315749e394ccf1ab26b2dd3f',
      //giro
      companyBank: {
        accountName: "慧之乳业（青岛）有限公司",
        accountNo: "38010101040045365",
        bankName: "中国农业银行股份有限公司青岛市分行营业部",
      },
      myBank: {},
      //alipay
      alipayAccount:{
        accountName: "慧之乳业（青岛）有限公司",
        accountNo: "weizite@ciit.tech",
      },
      myAlipayAccount:{},
      //
      uploadImgs: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
    }
  },
  methods: {
    show(param) {
      //console.log(param)
      this.billPaymentAmount = param.billPaymentAmount
      this.billId = param.billId
      this.payChannel = 'none';
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    doSubmit() {
      if(this.payChannel!='giro' && this.payChannel!='alipay'){
        //不是银行转账，也不是支付宝转账，不应该走这
        return;
      }
      let myAccountInfo = {}
      if(this.payChannel=='giro'){
        if (
            Objects.isEmpty(this.myBank.accountName) ||
            Objects.isEmpty(this.myBank.accountNo) ||
            Objects.isEmpty(this.myBank.bankName)
        ) {
          this.$message.error("请填写您的银行账户信息");
          return;
        }
        myAccountInfo = {
          ...this.myBank
        }
      }
      if(this.payChannel=='alipay'){
        if (
            Objects.isEmpty(this.myAlipayAccount.accountName) ||
            Objects.isEmpty(this.myAlipayAccount.accountNo)
        ) {
          this.$message.error("请填写您的支付宝信息");
          return;
        }
        myAccountInfo = {
          ...this.myAlipayAccount,
          bankName: "支付宝",
        }
      }
      if (this.uploadImgs.length == 0) {
        this.$message.error("请上传截图凭证");
        return;
      }
      let picsUrl = []; //获取图片地址
      for (let i in this.uploadImgs) {
        picsUrl.push(this.uploadImgs[i].response);
      }
      let picStr = picsUrl.join(",");
      //准备
      let params = {
        ...myAccountInfo,
        payChannel:this.payChannel,
        billId: this.billId,
        paymentVoucher: picStr,
      }
      dealerService.repaymentBill(params).then((data) => {
        if (data.code == 'success') {
          this.$message({
            type: "success",
            message: "提交成功，等待审核！",
          });
          this.dialogVisible = false;
          this.$emit("reload");
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    choosePayChannel(channel) {
      this.payChannel = channel;
      if(channel=='wxpay'){
        this.buildWxpayQR()
      }
    },
    buildWxpayQR() {
      document.getElementById("qrCodeDom_rechargePop").innerHTML = "二维码加载中...";
      let param = {
        billId:this.billId,
        payChannel: 'wxpay'
      }
      dealerService.repaymentBill(param).then(data => {
        //console.log(data)
        if (data.code == 'success' && data.payData) {
          this.setPayData(data.payData);
        } else {
          this.$message.error(data.msg);
        }
      })
    },
    setPayData(payData) {
      let codeUrl = payData["code_url"];
      this.outTradeNo = payData["outTradeNo"];
      document.getElementById("qrCodeDom_rechargePop").innerHTML = "";
      let codeImg = new QRcode("qrCodeDom_rechargePop", {
        width: 146,
        height: 146, // 高度
        text: codeUrl, // 二维码内容
        image: "",
      });
    },

    confirmWxpay(){
      let loading = this.$loading({
        lock: true,
        text: "支付结果确认中...",
      });
      let that = this
      //3s后检查结果
      setTimeout(function () {
        dealerService
            .checkPayState(that.outTradeNo)
            .then((data) => {
              if (data) {
                if (data["result"] == "success") {
                  that.$message({type: "success", message: "支付成功！"});
                  that.dialogVisible = false;
                  that.$emit("reload");
                } else {
                  //支付检查失败，提示
                  that.$message.error(data["msg"]);
                  that.dialogVisible = false;
                  that.$emit("reload");
                }
              }
              loading.close();
            })
            .catch((data) => {
              that.$message.error("支付失败");
              loading.close();
            });
      }, 3000);
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("fa_bill");
      return url;
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, '');
      e.target.value = e.target.value.replace(/\.{2,}/g, '.');
      e.target.value = e.target.value.replace(/^\./g, '0.');
      e.target.value = e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0, e.target.value.length - 1));
      e.target.value = e.target.value.replace(/^0[^\.]+/g, '0')
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
      this.input = e.target.value
      //console.log(this.input)
    },
  }
}
</script>

<style scoped>
.ws-title3 {
  font-weight: bold;
  width: 100px;
}

.none-div-class {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 400px;
}

.none-choose-bord {
  border: 1px solid #8a8585;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 1px 1px #8a8585;
}

.wxpay-div-one {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
}

.wxpay-div-one-item {
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #333333;
  margin-right: 20px;
  margin-bottom: 20px;
}

.wxpay-div-two {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.wxpay-div-two > * {
  margin-right: 10px;
}
</style>