<template>
  <div id="ProjectList">
    <div class="ProjectList-left">
      <div class="header">
        <el-popover placement="bottom" width="300" v-model="visible" trigger="click">
          <div>
            <el-input placeholder="项目名称" v-model="title" style="margin:10px 0px"></el-input>
            <el-autocomplete style="display: block;" v-model="roleCode" :fetch-suggestions="search" @select="selectGroup" @focus="groupListMe" placeholder="请输入负责人账号">
            </el-autocomplete>
          </div>
          <div class="AddProject">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="ProjectSave">确认</el-button>
          </div>
          <el-button v-if="projectText == '已关闭'" type="primary" slot="reference">新增项目</el-button>
        </el-popover>
        <i style="font-size:25px;margin:0px 26px" class="el-icon-warning-outline"></i>
        <p style="font-size:14px;color:rgba(102, 102, 102, 1);width:350px;line-height:20px">
          当前页面共{{
            ProjectList.length
          }}个项目，<br />上下滑动可查看项目流，抓取左右滑动可查看任务流
        </p>
        <el-button type="primary" @click="switchState" style="margin-left:65px">查看{{ projectText }}项目</el-button>
        <el-button type="primary" @click="goProjectBoard">查看日历看板</el-button>
        <el-input style="width:200px;margin:0px 20px 0px 80px" v-model="searchTitle" prefix-icon="el-icon-search"></el-input>
        <el-button type="primary" @click="searchProject">查询</el-button>
      </div>
      <div class="Project" v-for="(item, index) in ProjectList" :key="index">
        <div class="Project-item-header">
          <div>{{ item.title }}</div>
          <el-button type="primary" size="mini" style="margin:0px 12px" @click="editProject(index)">编辑项目</el-button>
          <el-button type="primary" :disabled='ProjectList[index].projectState=="close"' size="mini" @click="batchEdit(index)">批量编辑任务</el-button>
        </div>
        <div class="Project-item" v-dragscroll.x>
          <div :class="items.checked ? 'Project-slider-checked' : 'Project-slider'" v-for="(items, sonIndex) in item.childrenList" :key="sonIndex">
            <div class="slider-header">
              <el-checkbox v-model="items.checked" :disabled="items.checked?true:ProjectList[index].projectState=='close'?true:false" @change="editTaskState(index, sonIndex)">{{ items.title }}</el-checkbox>
            </div>
            <div class="slider-operation">
              <el-tag :type="items.taskState == 'complete' ? 'success' : 'info'">
                {{
                  items.taskState == "complete"
                    ? "已完成"
                    : items.taskState == "waiting"
                    ? "进行中"
                    : items.taskState == "suspend"
                    ? "已终止"
                    : items.taskState == "overdue"
                    ?'已逾期'
                    : items.taskState == "notStart"
                    ? '未开始'
                    : items.taskState
                }}</el-tag>
              <el-tag type="" style="margin:0px 10px" effect="plain">{{ items.beginTime }}-{{ items.endTime }}</el-tag>
              <i style="cursor: pointer;" @click="openTaskDetails(index, sonIndex)" class="el-icon-edit-outline slider-edit"></i>
            </div>
          </div>
          <div class="addTask" @click="AddTask(index)">
            <div>+</div>
            <div>新建任务</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ProjectList-right">
      <div class="ProjectList-right-title">动态日志</div>
      <div class="scroll">
        <div class="queryTaskLog" v-for="(item, index) in TaskLogList" :key="index">
          <div class="queryTaskLog-top">
            <div class="queryTaskLog-user">
              {{ item.operaterName }} {{ item.action }}
            </div>
            <div class="queryTaskLog-time">{{ item.operateTime }}</div>
          </div>
          <div class="queryTaskLog-bottom">
            <div class="queryTaskLog-line"></div>
            <div v-if="item.fileFlag == null" class="queryTaskLog-acitivty-null">
              {{ item.content }}
            </div>
            <div v-if="item.fileFlag == 'img'" class="queryTaskLog-acitivty-null">
              <draggableUpload :imgDel="true" :limit="0" v-model="item.fileUrlList" :editImgName="true" :uploadUrl="imgUploadUrl()" :requestHeaders="requestHeaders" />
            </div>
            <div v-if="item.fileFlag == 'file'" class="queryTaskLog-acitivty-file">
              <div class="fileList-log" v-for="(items, sonIndex) in item.fileUrlList" :key="sonIndex">
                <div class="fileList-left">
                  <div class="fileList-img">
                    <img :src="items.fileImg" />
                  </div>
                  <div class="fileList-title">
                    <div>{{ items.fileName }}</div>
                    <div>{{ items.fileSize }}</div>
                  </div>
                </div>
                <div class="fileList-operation">
                  <i class="el-icon-download" style="cursor: pointer;" @click="download(index, sonIndex)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <taskPop ref="openTaskPop" @refreshData="
        projectText == '已关闭' ? getProjectList() : getProjectList('close')
      "></taskPop>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <div class="content">
        <div class="header">
          <div class="header-left">
            <img src="../../../assets/images/title_Icon.png" />
            <p>标题</p>
            <el-input style="width:70%" v-model="projectItem.title"></el-input>
          </div>
          <div class="header-right">
            <i @click="dialogVisible = false" class="el-icon-close" style="font-size:20px;cursor: pointer;"></i>
          </div>
        </div>
        <div class="label-item">
          <div class="item-title">
            <img src="../../../assets/images/proState_icon.png" />
            <div>
              项目状态
            </div>
          </div>
          <div class="item-dispose">
            <el-switch v-model="projectItem.projectState" active-text="开启" inactive-text="关闭">
            </el-switch>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveProject">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const moment = require("moment");
import axiosHttp from "API/axiosHttp";
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import PmBrandService from "API/pm/PmBrandService";
import taskPop from "./taskPop.vue";
import { formatFileSize } from "@/js/formatFileSize";
import IoProgressService from "API/IoProgressService";
export default {
  data() {
    return {
      // checked: false,
      dialogVisible: false,
      visible: false,
      ProjectList: [],
      TaskLogList: [],
      title: "",
      roleCode: "",
      searchTitle: "",
      Useritem: {},
      thisSearchData: [],
      projectItem: {
        projectState: false,
        title: "",
      },
      requestHeaders: {
        Authorization: userModule.token,
      },
      fileData: {
        module: "pm_task_file",
      },
      projectText: "已关闭",
    };
  },
  mounted() {
    this.getProjectList();
  },
  components: {
    taskPop,
    draggableUpload,
  },
  watch: {
    roleCode: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.possibleData = []; //这是定义好的用于存放下拉提醒框中数据的数组
        let len = this.thisSearchData.length; //SearchData
        let arr = [];
        for (let i = 0; i < len; i++) {
          //根据输入框中的值进行模糊匹配
          if (this.thisSearchData[i].account.indexOf(this.roleCode) >= 0) {
            arr.push({
              value: this.thisSearchData[i].account,
            });
          }
        }
        this.possibleData = arr;
      },
    },
  },
  methods: {
    switchState() {
      if (this.projectText == "已关闭") {
        this.getProjectList("close");
        this.projectText = "未完成";
      } else {
        this.getProjectList();
        this.projectText = "已关闭";
      }
    },
    batchEdit(index) {
      let projectId = this.ProjectList[index].projectId;
      console.log(this.ProjectList[index]);
      let title = this.ProjectList[index].title;
      this.$router.push({
        path: "/pm/taskEdit",
        query: {
          projectId: projectId,
          title: title,
        },
      });
    },
    editTaskState(index, sonIndex) {
      let projectTaskId =
        this.ProjectList[index].childrenList[sonIndex].projectTaskId;
      let params = {
        projectTaskId: projectTaskId,
        taskState: "complete",
      };
      PmBrandService.setTaskState(params).then((data) => {
        this.$message({ type: "success", message: "操作成功" });
        this.getProjectList(this.projectText == "已关闭" ? "waiting" : "close");
      });
    },
    goProjectBoard() {
      this.$router.push({
        path: "/pm/ProjectBoard",
      });
    },
    search(queryString, cb) {
      console.log("search");
      var possibleData = this.possibleData;
      cb(possibleData);
    },
    saveProject() {
      let params = {
        projectId: this.projectItem.projectId,
        projectState: this.projectItem.projectState ? "waiting" : "close",
      };
      let param = {
        title: this.projectItem.title,
        projectId: this.projectItem.projectId,
        projectState: this.projectItem.projectState ? "waiting" : "close",
      };
      PmBrandService.ProjectSave(param).then((data) => {
        this.$message({ type: "success", message: "保存成功" });
        console.log(this.projectText);
        this.getProjectList(this.projectText == "已关闭" ? "waiting" : "close");
        this.dialogVisible = false;
      });
    },
    selectGroup(val) {
      for (let i in this.thisSearchData) {
        if (val.value == this.thisSearchData[i].account) {
          this.Useritem = this.thisSearchData[i];
        }
      }
      this.roleCode = val.value;
    },
    groupListMe() {
      PmBrandService.listUserInRole("shop_admin")
        .then((data) => {
          this.thisSearchData = [];
          this.possibleData = [];
          this.thisSearchData = data;
          for (let item of this.thisSearchData) {
            this.possibleData.push({
              value: item.account,
            });
          }
          console.log(this.possibleData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProjectList(projectState) {
      let paeams = {
        projectState: projectState ? projectState : "waiting",
      };
      PmBrandService.queryPmProjectAll(paeams).then((data) => {
        this.dataTreating(data);
        this.getProjectLog(data);
      });
    },
    getProjectLog(data) {
      let arr = [];
      for (let i in data) {
        arr.push(data[i].projectId);
      }
      if (arr.length > 0) {
        PmBrandService.queryProjectLog(arr).then((data) => {
          console.log(data);
          data =
            data != null
              ? data.map((e) => {
                  return {
                    action: e.action,
                    content: e.content,
                    fileFlag: e.fileFlag,
                    operateTime: moment(e.operateTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    operaterId: e.operaterId,
                    operaterName: e.operaterName,
                    projectId: e.projectId,
                    projectLogId: e.projectLogId,
                    projectTaskId: e.projectTaskId,
                    state: e.state,
                    fileUrlList:
                      e.fileUrlList != null
                        ? e.fileFlag == "file"
                          ? e.fileUrlList.map((z) => {
                              return {
                                fileName: z.fileName,
                                fileUrl: z.fileUrl,
                                fileSize: formatFileSize(z.fileSize),
                                fileImg: this.imgIconDispose(z.fileUrl),
                              };
                            })
                          : e.fileFlag == "img"
                          ? e.fileUrlList.map((z) => {
                              return {
                                response: z.fileUrl,
                                name: z.fileName,
                              };
                            })
                          : []
                        : [],
                  };
                })
              : [];
          data.reverse(); //数组倒序
          this.TaskLogList = data;
        });
      }
    },
    searchProject() {
      if (this.searchTitle != "") {
        let params = {
          title: this.searchTitle,
        };
        PmBrandService.queryByInfo(params).then((data) => {
          //projectState
          // console.log(data);
          let projectText = this.projectText == "已关闭" ? "waiting" : "close";
          data = data.filter((item) => item.projectState == projectText);
          this.dataTreating(data);
        });
      } else {
        this.getProjectList(this.projectText == "已关闭" ? "waiting" : "close");
      }
    },
    dataTreating(data) {
      let params = {
        pmProjectList: [],
      };
      for (let i in data) {
        data[i].childrenList = [];
        let param = {
          projectId: data[i].projectId,
        };
        params.pmProjectList.push(param);
      }
      PmBrandService.queryTaskAllByProjectIds(params).then((res) => {
        for (let i in res) {
          res[i].beginTime = moment(res[i].beginTime).format("MM月DD日");
          res[i].endTime = moment(res[i].endTime).format("MM月DD日");
          res[i].checked =
            res[i].taskState == "complete" || res[i].taskState == "suspend"
              ? true
              : false;
          for (let j in data) {
            if (res[i].projectId == data[j].projectId) {
              data[j].childrenList.push(res[i]);
            }
          }
        }
        this.ProjectList = data;
      });
    },
    download(index, sonIndex) {
      let module = this.fileData.module;
      console.log(index, sonIndex);
      if (sonIndex != -1) {
        let row = this.TaskLogList[index].fileUrlList[sonIndex];
        if (row.fileUrl != null) {
          let fileName = row.fileUrl.split(this.fileData.module + "/")[1];
          IoProgressService.download(module, fileName, false);
        }
      } else {
        let row = this.fileList[index];
        if (row.fileUrl != null) {
          let fileName = row.fileUrl.split(this.fileData.module + "/")[1];
          IoProgressService.download(module, fileName, false);
        }
      }
    },
    editProject(index) {
      this.dialogVisible = true;
      this.projectItem.projectState =
        this.ProjectList[index].projectState == "waiting" ? true : false;
      this.projectItem.projectId = this.ProjectList[index].projectId;
      this.projectItem.title = this.ProjectList[index].title;
    },
    ProjectSave() {
      if (this.title == "") {
        return this.$message({
          message: "标题名称不能为空",
          type: "warning",
        });
      }
      let params = {
        projectId: "",
        title: this.title,
        leaderId: this.Useritem.userId,
      };
      PmBrandService.ProjectSave(params).then((data) => {
        this.visible = false;
        this.$message({ type: "success", message: "保存成功" });
        this.getProjectList();
      });
    },
    AddTask(index) {
      let projectId = this.ProjectList[index].projectId;
      let data = {
        projectId: projectId,
      };
      this.$refs.openTaskPop.$emit("openTaskPop", data);
    },
    openTaskDetails(index, sonIndex) {
      let data = {
        projectTaskId:
          this.ProjectList[index].childrenList[sonIndex].projectTaskId,
        projectState: this.ProjectList[index].projectState,
      };
      this.$refs.openTaskPop.$emit("openTaskPop", data);
    },
    imgUploadUrl() {
      let url = `${axiosHttp.baseURL}/ioProgress/upload`;
      url = url + "?module=" + this.fileData.module;
      return url;
    },
    imgIconDispose(fileUrl) {
      if (fileUrl != null) {
        console.log(fileUrl);
        fileUrl = fileUrl.split(this.fileData.module)[1];
        console.log(fileUrl);
        return fileUrl == undefined || fileUrl == ""
          ? require("ASSETS/images/imgIcon.png")
          : fileUrl.split(".")[1].indexOf("png") != -1
          ? require("ASSETS/images/imgIcon.png")
          : fileUrl.split(".")[1].indexOf("jpg") != -1
          ? require("ASSETS/images/imgIcon.png")
          : fileUrl.split(".")[1].indexOf("pdf") != -1
          ? require("ASSETS/images/pdfIcon.png")
          : fileUrl.split(".")[1].indexOf("xls") != -1
          ? require("ASSETS/images/xmlIcon.png")
          : fileUrl.split(".")[1].indexOf("falsh") != -1
          ? require("ASSETS/images/falshIcon.png")
          : fileUrl.split(".")[1].indexOf("html") != -1
          ? require("ASSETS/images/htmlIcon.png")
          : fileUrl.split(".")[1].indexOf("doc") != -1
          ? require("ASSETS/images/wordIcon.png")
          : require("ASSETS/images/imgIcon.png");
      }
    },
  },
};
</script>

<style scoped>
#ProjectList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.ProjectList-left {
  width: 74%;
}
.ProjectList-right {
  width: 25%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 15px;
  background: rgba(255, 255, 255, 1);
  height: 100vh;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}
.ProjectList-right-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-bottom: 20px;
}
.scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 16px;
  background: rgba(255, 255, 255, 1);
}
.header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Project {
  height: 136px;
  width: 97.4%;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 16px;
  margin-top: 10px;
}
.Project-item {
  width: 100%;
  height: 96px;
  border-radius: 3px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  overflow: hidden;
  margin-top: 10px;
}

.Project-item-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Project-item-header > div:nth-child(1) {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  margin-left: 5px;
}

.Project-slider {
  flex-shrink: 0;
  /* width: 272px; */
  height: 86px;
  margin-right: 10px;
  margin-left: 4px;
  padding-right: 12px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}
.Project-slider-checked {
  flex-shrink: 0;
  /* width: 272px; */
  height: 86px;
  margin-right: 10px;
  margin-left: 4px;
  padding-right: 12px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  opacity: 0.6;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#ProjectList >>> .el-checkbox__label {
  color: rgba(102, 102, 102, 1);
}
#ProjectList >>> .el-checkbox__inner {
  /* background: white; */
  border: 1px solid rgba(153, 153, 153, 1);
}
.slider-operation {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.slider-header {
  margin: 10px 17px;
}
.slider-edit {
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
}
.addTask {
  flex-shrink: 0;
  width: 100px;
  height: 86px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 5px;
  cursor: pointer;
}
.addTask > div:nth-child(1) {
  color: rgba(196, 196, 196, 1);
  font-size: 25px;
}
.addTask > div:nth-child(2) {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  margin-top: 6px;
}
.AddProject {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  height: 100%;
  border-bottom: 1px solid rgba(166, 166, 166, 1);
  padding: 17px 11px 14px 10px;
}
.header-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.header-left > img {
  width: 14px;
  height: 14px;
}
.header-left > p {
  width: 40px;
  margin: 0px 10px;
}
#ProjectList >>> .el-dialog__header {
  border: none;
  display: none;
}
#ProjectList >>> .el-dialog__body {
  padding: 0;
}
.label-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  /* height: 60px; */
}
.item-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  width: 30%;
}
.item-title > img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.item-dispose {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 200px;
  height: 100%;
  /* margin-left: 20px; */
}

.queryTaskLog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0px;
  width: 100%;
  height: 100%;
}
.queryTaskLog-top {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
.queryTaskLog-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.queryTaskLog-line {
  width: 4px;
  height: 13px;
  background: rgba(196, 196, 196, 1);
}
.queryTaskLog-acitivty-null {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  margin-left: 5px;
}
.queryTaskLog-acitivty-file {
  margin-left: 5px;
}
.fileList-log {
  width: 50%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background: rgba(245, 245, 245, 1);
  margin-bottom: 20px;
}
.fileList-left {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.fileList-img > img {
  width: 20px;
  height: 25px;
}
.fileList-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 17px;
}

.fileList-title > div {
  color: rgba(128, 128, 128, 1);
  font-size: 12px;
  margin: 2px 0px;
}
.fileList-title > div:nth-child(1) {
  -webkit-box-orient: vertical;
  word-break: break-all;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
}
.fileList-operation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.fileList-operation > i {
  margin-left: 24px;
}
</style>
