<template>
  <div>
    <div id="ProjectBoard">
      <div class="Project-content">
        <div class="legend">
          <div style="font-size:16px;font-weight:600">图例</div>
          <div class="legend-tag">
            <div class="tag-item" v-for="(item,index) in tagList" :key="index">
              <span class="tag-state" :style="'background:'+ item.color"></span>
              <span class="tag-title">{{ item.label }}</span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="itemSet-title">
            <div style="font-size:16px;font-weight:600;">当前库存</div>
            <el-button type="text" @click="entryStockDialogShow">申请
            </el-button>
            <el-button type="text" @click="gotoRecordList" style="margin-left: auto;">
              全部记录<i class="el-icon-arrow-right"></i>
            </el-button>
          </div>
          <div class="item-Collapse">
            <div v-for="(item,index) of stockInfoList">
              <div>
                {{ item.goodsName }} 剩余 {{ item.stockNum }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar">
        <full-calendar :config="config" :events="events" ref="calendar"></full-calendar>
      </div>
    </div>
    <singleItemStockDialog ref="singleItemStockDialog" @submit='freshPage'></singleItemStockDialog>
  </div>
</template>


<script>
import tools from "COMJS/tools";

const moment = require("moment");
import {FullCalendar} from "vue-full-calendar";
import "fullcalendar/dist/fullcalendar.css";
import singleItemStockDialog from "./SingleItemStockDialog";
//import pageTableMixin from "@/mixin/pageTableMixin.js";
import milkCardService from "API/fm/FreshMilkService";
import milkStockService from "API/fm/FreshMilkStockService";
import milkOrderService from "API/fm/MilkOrderService";

export default {
  components: {FullCalendar, singleItemStockDialog},
  //mixins: [pageTableMixin],
  data() {
    return {
      //
      tagList: [
        {
          label: "入库",
          color: "rgba(214, 255, 219, 1)",
        },
        {
          label: "出库",
          color: "rgba(255, 209, 209, 1)",
        },
        {
          label: "预定",
          color: "rgb(250,237,142,1)"
        }
      ],
      //库存信息
      stockInfoList: [],
      //
      selectedDateInfo: {},
      selectDate: "2021-10", //日期选择器选中的月份
      //
      events: [],
      //日历配置
      config: {
        firstDay: "1", //以周日为每周的第一天
        buttonText: {
          today: "今天",
          month: "月",
          week: "周",
          day: "日",
        },
        header: {
          left: "myCustomButton",
          center: "prev, title, next",
          right: '',
        },
        theme: false, //是否允许使用jquery的ui主题
        height: "auto",
        slotLabelFormat: "H:mm", // axisFormat 'H(:mm)'
        //slotLabelInterval:1,
        views: {
          month: {
            titleFormat: "YYYY年MMM",
          },
          day: {
            titleFormat: "YYYY年MMMDD日 dddd",
          },
        },
        dayNames: ["日", "一", "二", "三", "四", "五", "六"],
        dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
        slotDuration: "00:30:00",
        minTime: "00:00",
        maxTime: "24:00",
        locale: "zh-cn",
        editable: false, //是否允许修改事件
        selectable: false, //是否允许用户单击或者拖拽日历中的天和时间隙
        eventLimit: false, // 限制一天中显示的事件数，默认false
        allDaySlot: true, //是否显示allDay
        displayEventEnd: false, //是否显示结束时间
        allDayText: "全天",
        navLinks: false, //允许天/周名称是否可点击
        defaultView: "month", //显示默认视图
        changeMonth: this.changeMonth,
        eventClick: this.eventClick, //点击事件
        dayClick: this.dayClick, //点击日程表上面某一天
        //eventRender: this.eventRender,
        contentHeight: 750,
        customButtons: {
          myCustomButton: {
            text: "日历", // 按钮的展示文本
            click: "",
          },
        },
        eventRender: function (event, element) {
          element.html(event.title);
        },
      },
    };
  },
  mounted() {
    this.loadStockInfo()
    this.freshPage()
  },
  methods: {
    loadStockInfo() {
      let param = {}
      milkStockService.querySingleItemList(param).then(res => {
        this.stockInfoList = res;
      })
    },
    freshPage() {
      this.events = []
      this.loadStockIn()
      this.loadOrderOut()
      this.loadMilkCardStatistics()
      let today = moment(new Date()).format("YYYY-MM-DD")
      this.showInfoThisDay(today)
    },
    cellStyle() {
      return {
        "font-size": "14px",
        height: "40px",
        padding: "0",
      };
    },
    //加载订单出库
    loadOrderOut() {
      let param = {}
      milkOrderService.getMilkOrderStatistics(param).then(res => {
        this.putInReservationList(res, 'out')
      })
    },
    //加载入库
    loadStockIn() {
      let param = {
        flagWithI: '1'
      }
      milkStockService.queryRecordList(param).then(res => {
        let addList = res.filter(e=>e.stockModifyNum>0)
        if(addList && addList.length>0){
          let arr = addList.map(e => {
            return {
              title: e.goodsName,
              count: e.stockModifyNum,
              date: e.actualEntryDate ? e.actualEntryDate : e.expectedEntryDate,
              state: e.stockModifyEntry
            }
          })
          this.putInReservationList(arr, 'in')
        }
        let reduceList = res.filter(e=>e.stockModifyNum<0)
        if(reduceList && reduceList.length>0){
          let arr = reduceList.map(e => {
            return {
              title: e.goodsName,
              count: e.stockModifyNum,
              date: e.actualEntryDate ? e.actualEntryDate : e.expectedEntryDate,
              state: e.stockModifyEntry
            }
          })
          this.putInReservationList(arr, 'out')
        }
      })
    },
    //加载奶卡预计出库
    loadMilkCardStatistics() {
      milkCardService.getMilkCardRecordStatistics({}).then(res => {
        this.putInReservationList(res, 'pre')
      })
    },
    putInReservationList(arrayData, type) {
      if (arrayData) {
        let newArr = this.events;
        arrayData.forEach((item) => {
          newArr.push({
            start: this.dealWithTime(
                moment(item.date).format("YYYY-MM-DD")
            ),
            end: this.addDate(
                this.dealWithTime(moment(item.date).format("YYYY-MM-DD")),
                1
            ),
            type: type,
            color:
                type == "in" ? "rgba(214, 255, 219, 1)"
                    : type == "out" ? "rgba(255, 209, 209, 1)"
                    : type == "pre" ? "rgb(250,237,142)"
                        : "",
            //id: item.id,
            name: item.title,
            count: Math.abs(item.count),
            title: this.getTitle(item, type),
          });
        });
        //console.log(newArr);
        this.events = newArr;
      }
    },
    getTitle(item, type) {
      let title = ''
      if (type == 'in') {
        title += '<span class="label_1"></span>';
      } else if (type == 'out') {
        title += '<span class="label"></span>';
      } else {
        title += '<span class="label"></span>';
      }
      title += item.title + '&nbsp;' + Math.abs(item.count);
      if (item.state && item.state == '0') {
        title += '&nbsp;(待确认)';
      }
      return title
    },
    getShowTime(beginDate, endDate) {
      this.form.startDate = this.dealWithTime(beginDate);
      this.form.startTime = this.getHoursMin(beginDate);
      this.form.endDate = this.dealWithTime(endDate);
      this.form.endTime = this.getHoursMin(endDate);
    },
    // 获取时分时间
    getHoursMin(value) {
      return value.substring(11, 16);
    },
    // 处理会议时间格式
    dealWithTime(date) {
      let newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)[0];
      return newDate;
    },
    // 日期加1天
    addDate(date, days) {
      var d = new Date(date);
      d.setDate(d.getDate() + days);
      var mon =
          d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let endD = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      return `${d.getFullYear()}-${mon}-${endD}`;
    },
    changeMonth() {
      console.log("changeMonth")
    },
    //changeDate() {
    //this.$refs.calendar.fireMethod("gotoDate", this.selectDate);
    //},
    // 点击事件
    eventClick(event, jsEvent, pos) {
      // 点击条条，还不知道要干什么
      this.dayClick(event.start, jsEvent)
    },
    // 点击当天
    dayClick(day, jsEvent) {
      //console.log(day)
      let date = tools.formatTime(day._i)
      this.showInfoThisDay(date)
    },
    showInfoThisDay(date) {
      let info = {
        date: date
      }
      if (this.events && this.events.length > 0) {
        let outList = this.events.filter(e => e.start == date && e.type == 'out');
        info.outList = outList;
        let inList = this.events.filter(e => e.start == date && e.type == 'in');
        info.inList = inList;
      }
      this.selectedDateInfo = info
    },
    /*eventRender: function (event, element) {
      element.find(".fc-title").attr("title", event.title);
    },*/
    gotoRecordList() {
      this.$router.push(`/fm/freshMilkRecordList`);
    },
    entryStockDialogShow() {
      this.$refs.singleItemStockDialog.show()
    },
  },
};
</script>

<style scoped>
#ProjectBoard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.Project-content {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2px;
}

.calendar {
  background: rgba(255, 255, 255, 1);
  height: 100%;
  margin-left: 1%;
  width: 74%;
  padding-top: 20px;
  border-radius: 2px;
}

.calendar >>> .fc-day-header {
  border: none;
  text-align: left;
  line-height: 40px;
  height: 40px;
  position: relative;
  left: 5.57px;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
}

.calendar >>> .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
  margin: 6px;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
}

.calendar >>> .fc-center > button {
  height: 18px;
  background: none;
  border: none;
  box-shadow: none;
}

.calendar >>> .fc-center > button > span {
  font-size: 14px;
}

.calendar >>> .fc-today {
  background: none;
}

.calendar >>> .fc-center > h2 {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 5px;
}

.calendar >>> .fc-left > button {
  background: none;
  border: none;
  box-shadow: none;
  cursor: auto;
  font-weight: 600;
}

.calendar >>> .fc-event {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /*cursor: pointer;*/
  color: rgba(102, 102, 102, 1);
}

.calendar >>> .label {
  width: 10px;
  height: 10px;
  background: rgba(255, 141, 26, 1);
  display: block;
  margin: 0px 5px;
  border-radius: 50%;
}

.calendar >>> .label_1 {
  width: 10px;
  height: 10px;
  background: #1AAFFF;
  display: block;
  margin: 0px 5px;
  border-radius: 50%;
}

.calendar >>> .fc-draggable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  width: 92%;
  height: 100%;
  border-radius: 2px;
  background: #ffffff;
}

.legend-tag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.tag-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 50px;
}

.tag-state {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.tag-title {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin-left: 8px;
}

.itemSet {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

/*#ProjectBoard >>> .el-table tr {*/
/*  background-color: #ffffff !important;*/
/*  box-shadow: none;*/
/*}*/
.itemSet-title {
  width: 90%;
  height: 30px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
}

.itemSet-title > button {
  margin-left: 10px;
}

.item {
  margin-top: 10px;
  width: 102%;
  border-radius: 2px;
}

.item-Collapse {
  width: 98%;
  padding: 8px 16px;
  height: 450px;
  overflow: auto;
}

/*#ProjectBoard >>> .expand .el-table__row {*/
/*  cursor: pointer;*/
/*}*/
/*#ProjectBoard >>> .item-Collapse .el-table__row {*/
/*  cursor: pointer;*/
/*}*/
</style>