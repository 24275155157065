<template>
  <el-dialog title="新增通知" :visible.sync="dialogFormVisible">
    <el-form :model="form" v-loading="loading">
      <el-form-item label="发送时间：" :label-width="formLabelWidth">
        <span>是否选择默认时间</span>
        <el-switch style="margin-left: 10px" active-color="#13ce66" v-model="isDefault"></el-switch>
        <div class="block">
          <el-date-picker
                  :disabled="isDefault"
                  v-model="form.sendTime"
                  @change="changePickerDate"
                  type="datetime"
                  placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="发送账号：" :label-width="formLabelWidth">
        <span>是否指定会员发送</span>
        <el-switch style="margin-left: 10px" active-color="#13ce66" v-model="isSelectUser"></el-switch>
        <el-autocomplete
                style="display: block; width: 170px"
                v-if="isSelectUser"
                v-model="form.recipient"
                size="mini"
                :fetch-suggestions="search"
                @select="selectGroup"
                @focus="groupListMe"
                placeholder="请输入会员账号"
        ></el-autocomplete>
        <el-select v-model="form.recipient" placeholder="请选择发送人" style="display: block; width: 170px"
                   v-if="!isSelectUser">
          <el-option
                  v-for="item in userList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="通知类型:">
        <el-select v-model="form.noticeType" placeholder="请选择通知类型">
          <el-option
                  v-for="item in sourceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通知标题：" :label-width="formLabelWidth">
        <el-input style="width: 172px;" v-model="form.noticeTitle"></el-input>
      </el-form-item>
      <el-form-item label="通知内容：" :label-width="formLabelWidth">
        <el-input type="textarea" :rows="4" v-model="form.noticeContent"></el-input>
      </el-form-item>
      <el-form-item>
        <div>
          <draggable-upload
                  :width="82"
                  :height="82"
                  :limit="3"
                  :uploadUrl="uploadUrl()"
                  :requestHeaders="requestHeaders"
                  v-model="imgList"
          ></draggable-upload>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>


<script>
import BimUserNoticeService from "../../../api/bim/BimUserNoticeService";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";
import DimAccountDetailsService from "API/dim/DimAccountDetailsService.js";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";

export default {
    components: {
        draggableUpload
    },
    data() {
        return {
            sourceList: [
                {
                    value: "system_notice",
                    label: "系统通知"
                },
                {
                    value: "order_notice",
                    label: "订单通知"
                },
                {
                    value: "audit_notice",
                    label: "审核通知"
                },
                {
                    value: "activity_notice",
                    label: "活动通知"
                }
            ],
            memberList: [
                {
                    value: "distributor_all",
                    label: "全部会员"
                },
                {
                    value: "distributor_third",
                    label: "全部金星"
                },
                {
                    value: "distributor_second",
                    label: "全部银星"
                },
                {
                    value: "distributor_first",
                    label: "全部普标"
                }
            ],
            isSelectUser: true,
            isDefault: true,
            userList: [],
            userAccountList: [],
            form: {
                sendTime: "",
                recipient: "",
                noticeType: "",
                noticeTitle: "",
                noticeContent: "",
            },
            possibleData: [],
            searchData: [],
            dialogFormVisible: false,
            formLabelWidth: "100px",
            loading: false,
            requestHeaders: {
                Authorization: userModule.token,
            },
            imgList:[],
        };
    },
    mounted() {
    },
    watch: {
        dialogFormVisible(value) {
            this.resetForm();
            this.isDefault = true;
        },
        isSelectUser: function (newVal) {
            if (newVal) {
                this.form.recipient = "";
            } else {
                this.form.recipient = "";
                this.userList = this.memberList
            }
        },
        isDefault: function (oldVal) {
            if (oldVal) {
                this.form.sendTime = "";
            }
        },
        'form.recipient': {
            deep: true,
            handler: function (newVal, oldVal) {
                this.possibleData = [];//这是定义好的用于存放下拉提醒框中数据的数组
                var len = this.searchData.length;//SearchData
                var arr = [];
                for (var i = 0; i < len; i++) {//根据输入框中的值进行模糊匹配
                    if (this.searchData[i].indexOf(this.form.recipient) >= 0) {
                        arr.push({
                            value: this.searchData[i]
                        })
                    }
                }
                this.possibleData = arr
            }
        },
    },
    methods: {
        uploadUrl() {
            let url = IoProgressService.getUploadUrl("noticeImg");
            return url;
        },
        groupListMe() {
            DimAccountDetailsService.getAllAccount().then(data => {
                this.searchData = []
                this.possibleData = []
                this.searchData = data;
                for (let item of this.searchData) {
                    this.possibleData.push({
                        value: item
                    })
                }
                console.log(this.possibleData)
            })
                .catch(err => {
                    console.log(err)
                })
        },
        search(queryString, cb) {
            var possibleData = this.possibleData;
            cb(possibleData);
        },
        selectGroup(val) {
            this.form.recipient = val.value
        },
        submitForm() {
            var flag = false;
            if (!this.isDefault && this.form.sendTime == "") {
                return this.$message({
                    message: "请选择发送时间",
                    type: "warning"
                });
                flag = true;
            }
            if (this.form.recipient == "") {
                return this.$message({
                    message: "请选择发送账号",
                    type: "warning"
                });
                flag = true;
            }
            if (this.form.noticeType == "") {
                return this.$message({
                    message: "请选择通知类型",
                    type: "warning"
                });
                flag = true;
            }
            if (this.form.noticeTitle == "") {
                return this.$message({
                    message: "请输入通知标题",
                    type: "warning"
                });
                flag = true;
            }
            if (this.form.noticeContent == "") {
                return this.$message({
                    message: "请输入通知内容",
                    type: "warning"
                });
                flag = true;
            }
            if (flag) {
                open()
            }
            let _picList = [];
            if(this.imgList.length>0){
                _picList = this.imgList.map((e, i) => {
                    return {
                        picUrl: e.response
                    };
                });
            }
            let params = {
                isDefault: this.isDefault,
                sendTime: this.form.sendTime,
                recipient: this.form.recipient,
                noticeType: this.form.noticeType,
                noticeContent: this.form.noticeContent,
                noticeTitle: this.form.noticeTitle,
                picList: _picList
            };
            this.loading = true;
            BimUserNoticeService.saveOrUpdateAndSendNotice(params).then(res => {
                this.loading = false;
                this.$emit("updateTable");
            });
            this.dialogFormVisible = false;
        },

        changePickerDate(e) {
            if (e === null) {
            } else {
                const day15 = 15 * 24 * 3600 * 1000
                if (e < Date.now()) {
                    return this.$message({
                        message: "定时发送通知时间需大于当前时间",
                        type: "warning"
                    });
                }
                if (e - Date.now() > day15) {
                    return this.$message({
                        message: "定时发送通知时间不能超过15天",
                        type: "warning"
                    });
                }
                this.form.sendTime = e;
            }

        },

        open() {
            this.$alert('请重新输入', '输入信息有误', {
                confirmButtonText: '确定',
                callback: action => {
                    return false;
                }
            });
        },
        // 重置表单
        resetForm() {
            tools.clearObj(this.form);
            this.imgList = [];
        },


    }
};
</script>

<style lang="scss">

</style>