<template>
  <div>
    <div class="account-detail">
      <div class="detail-item box-shadow-unify">
        <div class="detail-item_title">账户明细</div>
        <div class="filter-form" style="margin-bottom: 0px;padding-bottom: 25px">
          <div>
            <!--
            <el-select v-if="isAdmin" v-model="value" placeholder="通过会员账号搜索">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            -->
            <el-autocomplete
                v-if="isAdmin"
                    style="display: block; width: 170px"
                    v-model="value"
                    size="mini"
                    :fetch-suggestions="search"
                    @select="selectGroup"
                    @focus="groupListMe"
                    placeholder="请输入会员账号"
            ></el-autocomplete>
          </div>
          <div style="margin-left: 8px; margin-top: 6px">
            <span v-if="isDistributor">会员账号：{{value}}</span>
          </div>
          <div class="block" style="margin-left: 30px">
            <span style="margin-left: 8px" class="demonstration">下单时间:</span>
            <el-date-picker
                    style="margin-left: 18px"
                    v-model="valueData"
                    @change="changePickerDate"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
          </div>

        </div>
        <div style="margin-left: 18px ;padding-bottom: 15px">
          <el-button :disabled="tooBig" type="primary" size="mini" @click="checkData">导出记录</el-button>
        </div>
        <!--
        <div style="padding-bottom: 20px;margin-left: 18px">
          <span style="">导出说明：导出内容包含时间段内会员本人订单详情、会员推广订单详情、会员团队订单摘要及其相关余额、推广服务费、团队服务费变动。</span>
        </div>
        -->
      </div>

      <div class="detail-item box-shadow-unify">
        <div class="detail-item_title">导出记录</div>
        <ws-page-table
                :data="tableData"
                ref="pageTable"
                :pageable="true"
                :pageInfo="pageInfo"
                @pageChange="loadTableData"
                :showIndex="true"
        >
          <el-table-column prop="exportTime" label="导出时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{scope.row.exportTime ? format(new Date(scope.row.exportTime)) : '无'}}
            </template>
          </el-table-column>
          <el-table-column prop="userAccount" label="会员账号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="createTime" label="时间范围" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{format(new Date(scope.row.beginTime)) + "~" + format(new Date(scope.row.endTime))}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="下载" @click="downloadExcel(scope.row.downloadUrl)">
                  <i class="icon-download"></i>
                </a>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>
    <el-dialog title="账户明细" :visible.sync="isShowPopop" width="560px">
      <div style="color: #FBC14E;">提醒：</div>
      <div>
        <br/>
        <p>点击确定即导出{{exportTimeBeginDate + "~" + exportTimeEndDate}}期间账户明细；</p>
        <br/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="exportRecord">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import DimAccountDetailsService from "API/dim/DimAccountDetailsService.js";
import somOrderExportRecord from "API/som/orderExportRecordService";
import userModule from "@/module/userModule";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService";

export default {
    data() {
        return {
            info: {},
            options: [],
            authorizationId: "",
            valueData: [],
            value: "",
            isShowPopop: false,
            exportTimeBeginDate: "",
            exportTimeEndDate: "",
            isAdmin: false,
            isDistributor: false,
            tooBig: false,
            possibleData:[],
            thisSearchData:[]
        }
    },
    mounted() {
        this.getAllUserAccount()
        this.loadTableData();
        const loginUser = JSON.parse(userModule.PPUser);
        if (loginUser && loginUser.role) {
            if (loginUser.role.indexOf("shop_admin") > -1) {
                //this.appMenus = this.adminMenus;
                this.isAdmin = true;
            } else {
                this.isDistributor = true;
            }
        }
    },
    watch: {
        'value': {
            deep: true,
            handler: function (newVal, oldVal) {
                this.possibleData = [];//这是定义好的用于存放下拉提醒框中数据的数组
                var len = this.thisSearchData.length;//SearchData
                var arr = [];
                for (var i = 0; i < len; i++) {//根据输入框中的值进行模糊匹配
                    if (this.thisSearchData[i].indexOf(this.value) >= 0) {
                        arr.push({
                            value: this.thisSearchData[i]
                        })
                    }
                }
                this.possibleData = arr
            }
        },
    },
    mixins: [pageTableMixin],
    methods: {
        groupListMe() {
            console.log("groupListMe")
            DimAccountDetailsService.getAllAccount().then(data => {
                this.thisSearchData = []
                this.possibleData = []
                this.thisSearchData = data;
                for (let item of this.thisSearchData) {
                    this.possibleData.push({
                        value: item
                    })
                }
                console.log(this.possibleData)
            }).catch(err => {
                console.log(err)
            })
        },
        search(queryString, cb) {
            console.log("search")
            var possibleData = this.possibleData;
            cb(possibleData);
        },
        selectGroup(val) {
            console.log("selectGroup")
            this.value = val.value
        },
        loadTableData() {
            this.$refs.pageTable.tableLoading = true;
            this.searchInfo.exportType = 'account_details'
            const loginUser = JSON.parse(userModule.PPUser);
            this.searchInfo.operateUserId = loginUser.userId;
            somOrderExportRecord.queryRecordPages(this.queryParams).then(
                data => {
                    this.querySuccess(data);
                }
            );
        },
        changePickerDate(e) {
            if (e === null) {
                this.exportTimeBeginDate = "";
                this.exportTimeEndDate = "";
            } else {
                const day30 = (30 - 1) * 24 * 3600 * 1000
                /*if (e[1] - e[0] > day30) {
                    this.tooBig = true;
                    return this.$message({
                        message: "查询范围只支持三十天内",
                        type: "warning"
                    });
                } else {
                    this.tooBig = false;
                }*/
                this.exportTimeBeginDate = tools.formatDate(
                    new Date(e[0]),
                    "yyyy-MM-dd"
                );
                this.exportTimeEndDate = tools.formatDate(
                    new Date(e[1]),
                    "yyyy-MM-dd"
                );
            }

        },
        format(date) {
            return tools.formatDate(date, "yyyy-MM-dd hh:mm:ss")
        },
        checkData() {
            if (this.value != '' && this.exportTimeBeginDate != "" && this.exportTimeEndDate != '') {
                this.isShowPopop = true;
            } else {
                return this.$message({
                    message: "请选择查询账号或查询范围",
                    type: "warning"
                });
            }
        },
        exportRecord() {
            const loginUser = JSON.parse(userModule.PPUser);
            this.$alert('账户明细导出中，预计10分钟内完成', '账户明细', {
                confirmButtonText: '确定',
                callback: action => {
                    let param = {
                        userAccount: this.value,
                        operateUserId: loginUser.userId,
                        beginTime: this.exportTimeBeginDate,
                        endTime: this.exportTimeEndDate,
                    }
                    this.isShowPopop = false
                    DimAccountDetailsService.exportAccountDetails(param).then(data => {
                        this.loadTableData();
                    })

                }
            });

        },
        getAllUserAccount() {
            DimAccountDetailsService.getAllAccount().then(data => {
                if (this.isAdmin) {
                    this.options = data.map(e => {
                        return {
                            value: e,
                            label: e
                        }
                    })
                }
                if (this.isDistributor) {
                    this.value = data[0];
                }

            })
        },
        downloadExcel(url) {
            let loading = this.$loading({
                lock: true,
                text: "Loading..."
            });
            let data = url.split(',');
            let fileName = data[1];
            let module = data[0];
            IoProgressService.download(module, fileName, false);
            setTimeout(() => {
                loading.close();
            }, 1000);
        }

    }
}
</script>

<style scoped lang="scss">
  .icon-download {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background-image: url("~ASSETS/images/download.png");
    background-size: cover;
  }

  .account-detail {
    .detail-item {
      margin-bottom: 40px;
      .detail-item_title {
        line-height: 60px;
        font-weight: bold;
        font-size: 18px;
        padding-left: 20px;
        background-color: #f6f9fc;
      }

      .detail-item_title2 {
        line-height: 60px;
        font-weight: bold;
        font-size: 18px;
        padding-left: 20px;
        background-color: #f6f9fc;
      }

      .detail-form {
        font-size: 14px;
        line-height: 20px;
        padding: 30px 20px 20px 0;

        .form-item {
          margin-bottom: 14px;

          &:last-of-type {
            margin-bottom: 0px;
          }

          .form-label {
            display: inline-block;
            width: 150px;
          }

          .form-label1 {
            display: inline-block;
            text-align: right;
            width: 150px;
            font-weight: bold;
            font-size: 18px;
            padding-bottom: 10px;
          }

          .form-value {
            color: #666;
          }
        }
      }

      .detail_img-form {
        .form-item {
          margin-bottom: 30px;

          .form-label {
            margin-left: 30px;
            display: inline-block;
            margin-right: 10px;
            margin-top: 8px;
          }

          .image-list {
            display: inline-block;
            vertical-align: top;

            .image-item {
              display: inline-block;
              margin-right: 10px;

              img {
                width: 82px;
                height: 82px;
                object-fit: fill;
              }
            }
          }
        }
      }
    }

    table {
      width: 100%;

      .th-tr {
        font-size: 18px;
        background-color: #f6f9fc;
        font-weight: bold;

        th {
          color: #8898aa;

          &:first-of-type {
            color: #333;
          }
        }
      }

      tr {
        display: block;
        font-size: 16px;
        line-height: 60px;
        padding-left: 20px;
        text-align: left;
        border-bottom: 1px solid #e9ecef;

        &:last-of-type {
          border-bottom: none;
        }

        td {
          display: inline-block;
        }
      }

      .table-col-1 {
        width: 175px;
      }

      .table-col-2 {
        width: 400px;
      }
    }
  }
</style>