import axiosHttp from "../axiosHttp";

export default {

    doPayWithQuery(params){
        let url = '/payment/doPayWithQuery';
        return axiosHttp.POST(url,params,false);
    },

    checkPayState(outTradeNo){
        let url = '/payment/checkResult';
        const params={
            "outTradeNo":outTradeNo
        };
        return axiosHttp.POST(url,params,false);
    },
    doGiro(paymentParam){
        let url = '/payment/doGiro';
        return axiosHttp.POST(url,paymentParam,false);
    },
    doBalanceDeduct(params){
        let url = '/payment/doBalanceDeduct';
        return axiosHttp.POST(url,params,false);
    },
    getPrePayData(params){
        return axiosHttp.POST("/payment/getPrePayData",params,false)
    },
    getWalletInfo(){
        return axiosHttp.GET("/payment/getWalletInfo",false)
    },
    doAdmNoPay(params){
        let url = '/payment/doAdmNoPay';
        return axiosHttp.POST(url,params,false);
    },
    doCreditPay(paymentParam){
        let url = '/payment/doCreditPay';
        return axiosHttp.POST(url,paymentParam,false);
    },
    doDepositPay(paymentParam){
        let url = '/payment/doDepositPay';
        return axiosHttp.POST(url,paymentParam,false);
    },
    getAllGiroAccount(){
        let url = '/payment/getAllGiroAccount';
        return axiosHttp.POST(url,{},false);
    }
}