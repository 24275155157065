<template>
  <div>
    <el-form label-width="120px" :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="addSupplier">新增供应商</el-button>
      </el-form-item>
      <el-form-item label="供应商名称:">
        <el-input v-model="searchInfo.supplierName" />
      </el-form-item>      
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="supplierName"
        label="供应商名称"
        :show-overflow-tooltip="true"
        width="300px"
      ></el-table-column>

      <el-table-column
        prop="contactName"
        label="联系人"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="mobile"
        label="联系电话"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column label="是否启用">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.enabled"
            @click.native="toggleEnable(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="showDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  name: "whmSupplier",
  data() {
    return {
      value: [],
      searchInfo: {
        supplierName: "",
      },
      items: {
        name: { filter: "like" },
      },
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: { 
    addSupplier() {
      this.$router.push('/whm/whmSupEdit');
    },
    toEdit(row){
      //console.log(row.id)
      this.$router.push(`/whm/whmSupEdit/${row.id}`);
    },
    showDetail(row){
      this.$router.push(`/whm/whmSupDetail/${row.id}`);
    },
    loadTableData() {
      //console.log("test")
      this.$refs.pageTable.tableLoading = true;
      WhmList.whmSupplier(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.supplierId,
            supplierName: e.supplierName,
            contactName: e.contactName,
            mobile: e.mobile,
            enabled: e.enabled == 1 ? true : false,
          };
        });
        this.querySuccess(data);
      });
    },
    //切换列表启用状态
    toggleEnable(row) { 
      const that = this;
      this.$confirm(`是否切换供应商'${row.supplierName}'的启用状态？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.enabled = !row.enabled == true ? 1 : 0; // 切换状态
          row.id = row.id;
          WhmList.updateSupplierEnable(row).then((res) => {
            that.loadTableData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.el-form--inline .el-form-item {
  margin-bottom: 0;
}
.header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}
.ws-page-table .icon-operation a {
  margin-right: 20px;
}

</style>