<template>
  <div class="route_tab-bar">
    <div class="tab-bar_list">
      <div
        class="tab-bar_item"
        :class="{'active': item.isCurrentPage}"
        v-for="(item, index) in tabList"
        @click="changeTab(item)"
        :key="item.index"
      >
        <div class="tab-bar_item_content" :class="{'vertical-line': hasActive(index, item)}">
          <span class="tab-name">{{item.name}}</span>
          <i class="el-icon-close" @click.stop="closeTab(item, index)" v-if="item.path != '/'"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import sysUtil from "@/constant/SysUtil";

export default {
  data() {
    return {
      tabList: [],
      homePage:{name:'欢迎',path:'/'},
    };
  },
  mounted() {
    if (!this.tabList.length && !this.$route.meta.notTab) {
      // 导航条固定tab
      let fixedTab = {
        name: this.homePage.name,
        path: this.homePage.path,
        isCurrentPage: false
      };
      //if ((this.$route.name == this.homePage.name)) {
      if ((this.$route.path == this.homePage.path)) {
        fixedTab.isCurrentPage = true;
        this.tabList.push(fixedTab);
      } else {
        this.tabList.push(fixedTab);
        this.tabList.push({
          name: this.$route.name,
          //path: this.$route.path,
          path: this.$route.fullPath,
          isCurrentPage: true
        });
      }
    } else {
      this.$router.push(this.homePage.path);
    }
  },
  watch: {
    $route: {
      handler: function(to, from) {
        if (to.meta.notTab) {
          this.clearTabActive();
          return;
        }
        this.clearTabActive();
        if (
          !this.tabList.filter(e => {
            return e.path === to.fullPath || e.name === to.name;
          }).length
        ) {
          let params = {
            name: to.name,
            path: to.fullPath,
            isCurrentPage: true
          };
          this.tabList.push(params);
        } else {
          this.tabList.forEach(e => {
            if (e.path === to.path || e.name === to.name) {
              e.isCurrentPage = true;
              // 地址id不同时，新id覆盖旧id
              if (e.path != to.fullPath) {
                e.path = to.fullPath;
              }
            }
          });
        }
      },
      deep: true
    }
  },
  methods: {
    // 清除tab当前页active样式
    clearTabActive() {
      this.tabList.forEach(e => {
        e.isCurrentPage = false;
      });
    },
    hasActive(index, item) {
      let tabs = this.tabList
      if (index == tabs.length - 1) {
        return (item.isCurrentPage || tabs.length > 6) ? false : true;
        return false;
      } else if (item.isCurrentPage) {
        return false;
      } else {
        return !tabs[index + 1].isCurrentPage;
      }
    },
    // 切换页面
    changeTab(item) {
      if (item.isCurrentPage == true) {
        return;
      }
      this.clearTabActive();
      item.isCurrentPage = true;
      this.$router.push(item.path);
    },
    // 关闭页面
    closeTab(item, index) {
      let list = this.tabList;
      if (list.length === 1) {
        // 当前route为home时，不跳转，非home时，关闭并跳转至home
        if (this.$route.path != this.homePage.path) {
          list.splice(index, 1);
        }
        this.$router.push(this.homePage.path);
      } else {
        if (item.path === this.$route.fullPath) {
          let toIndex;
          let toPath;
          if (index === list.length - 1 && index >= 1) {
            // 显示左边route
            toIndex = index - 1;
          } else {
            // 显示右边route
            toIndex = index + 1;
          }
          list[toIndex].isCurrentPage = true;
          toPath = list[toIndex].path;
          this.$router.push(toPath);
        }
        list.splice(index, 1);
      }
      this.tabList = list;
    }
  }
};
</script>

<style lang="scss">
@import "~ASSETS/css/common/tool.scss";

.route_tab-bar {
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: 40px;
  .tab-bar_list {
    display: flex;
    border: 1px solid $borderColor;
    border-top: none;
    border-radius: 0px 0px 2px 2px;
    background-color: $borderColor;
    .tab-bar_item {
      cursor: pointer;
      flex-grow: 0;
      flex-shrink: 0;
      flex: 1;
      padding: 6px 0 6px 20px;
      box-sizing: border-box;
      max-width: 240px;
      font-size: 13px;
      &.active {
        background-color: #fff;
        border-radius: 0px 0px 2px 2px;
      }
      .tab-bar_item_content {
        display: flex;
        line-height: 18px;
        height: 18px;
        &.vertical-line {
          border-right: 1px solid #7b848d;
        }
        .tab-name {
          flex: 1;
          @include Mult-line(1);
        }
        > i {
          padding: 0 10px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>