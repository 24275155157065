<template>
  <div>
    <div>
      <el-button type="primary" @click="createDialog">新增记录</el-button>
    </div>
    <div class="mt15">
      <el-tabs v-model="selectedFactoryId" type="card" @tab-click="loadTableData">
        <el-tab-pane :label="item.factoryName" :name="item.factoryId"
                     v-for="item in factoryList" :key="item.factoryId"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="mt15">
      <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                     @pageChange="loadTableData">
        <el-table-column prop="productName" label="产品名称"></el-table-column>
        <el-table-column prop="productNum" label="数量"></el-table-column>
        <el-table-column prop="createDate" label="记录时间">
          <template slot-scope="scope">
            {{
              scope.row.createDate != null
                  ? format(scope.row.createDate)
                  : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <div class="operate-column">
              <a @click="editDialog(scope.row)" title="编辑">
                <i class="CCSFont icon-table_1 operate-btn"></i>
              </a>
              <a @click="removeDialog(scope.row)" title="删除">
                <i class="el-icon-delete-solid operate-btn"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <!---->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <el-form :model="dialogData" :rules="dialogRules" ref="dialogForm" label-width="110px" v-loading="dialogLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="工厂" prop="factoryId">
              <el-select v-model="dialogData.factoryId" placeholder="工厂" style="width: 100%;">
                <el-option
                    v-for="item in factoryList"
                    :key="item.factoryId"
                    :label="item.factoryName"
                    :value="item.factoryId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="dialogData.productName" :maxlength="32" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="批次号" prop="batchCode">
              <el-input v-model="dialogData.batchCode" :maxlength="32" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="数量" prop="productNum">
              <el-input v-model.number="dialogData.productNum" :maxlength="8" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeDialog">关 闭</el-button>
        <el-button type="primary" @click.native="submitDialog">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import purFactoryService from "API/pur/PurFactoryService";
import purFactoryProductionService from "API/pur/PurFactoryProductionService";
import tools from "COMJS/tools";

export default {
  name: "FactoryProductionManager",
  mixins: [tableMixins],
  data() {
    return {
      dialogTitle: '新增',
      dialogVisible: false,
      dialogLoading: false,
      dialogData: {},
      dialogRules: {
        factoryId: [
          {required: true, message: '请选择工厂'},
        ],
        productName: [
          {required: true, message: '请输入产品名称'},
          {max: 32, message: '长度不能超过32个字符', trigger: 'blur'},
        ],
        batchCode: [
          {required: true, message: '请输入批次号'},
          {max: 32, message: '长度不能超过32个字符', trigger: 'blur'},
        ],
        productNum: [
          {required: true, message: '请填写数量'},
          {type: 'number', message: '输入内容不是有效的数字', trigger: 'blur'}
        ]
      },
      //
      searchInfo: {factoryId: ''},
      items: {
        factoryId: {filter: "EQ"}
      },
      selectedFactoryId: '',
      factoryList: []
    }
  },
  mounted() {
    purFactoryService.queryList({}).then(res => {
      this.factoryList = res
      if (res != null && res.length > 0) {
        this.selectedFactoryId = res[0].factoryId
        this.loadTableData()
      }
    })
  },
  methods: {
    loadTableData() {
      if (this.selectedFactoryId == null
          || this.selectedFactoryId == ''
          || this.selectedFactoryId == 0) {
        return;
      }
      this.searchInfo.factoryId = this.selectedFactoryId
      this.$refs.pageTable.tableLoading = true;
      purFactoryProductionService.queryPage(this.pageInfo, this.searchConfig).then(res => {
        this.querySuccess(res);
      }).finally(() => {
        this.$refs.pageTable.tableLoading = false;
      })
    },
    createDialog() {
      this.dialogData = {}
      try {
        this.$refs.dialogForm.resetFields();
      } catch (e) {
      }
      this.dialogTitle = '新增'
      this.dialogVisible = true
    },
    editDialog(row) {
      this.dialogData = {
        ...row
      }
      this.dialogTitle = '编辑'
      this.dialogVisible = true
    },
    removeDialog(row) {
      let id = row.productionRecordId
      this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //删除
        purFactoryProductionService.removeById(id).then(res => {
          this.loadTableData();
        })
      })
    },
    closeDialog() {
      this.dialogVisible = false
    },
    submitDialog() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          purFactoryProductionService.save(this.dialogData).then(res => {
            this.$message.success("保存成功")
            this.dialogVisible = false
            this.loadTableData()
          })
        }
      })
    },
    format(time) {
      return tools.formatTime(time, "yyyy-MM-dd");
    },
  }
}
</script>

<style scoped>
.operate-column > a {
  margin-right: 20px;
  cursor: pointer;
  color: #00a0e9;
}
</style>