<template>
  <div class="ws-index">
    <ws-header></ws-header>
    <div class="ws-main">
      <ws-sidebar></ws-sidebar>
      <div class="ws-content">
        <route-tabbar></route-tabbar>
        <div class="router-view-wrapper">
          <div class="cover-all" v-if="account_s2ep"></div>
          <keep-alive>
            <router-view v-if='!$route.meta.noKeepAlive' :key="$route.path + $route.query.t + nowDate"></router-view>
          </keep-alive>
          <router-view v-if='$route.meta.noKeepAlive' :key="$route.path + $route.query.t + nowDate"></router-view>
        </div>
      </div>
    </div>

    <overall-dialog />
  </div>
</template>

<script>
import header from "./header";
import sidebar from "./sidebar";
import overallDialog from "./OverallDialog";
import eventBus from "COMJS/eventBus";
import routeTabbar from "COMPS/widgets/routeTabbar";

export default {
  components: {
    "ws-header": header,
    "ws-sidebar": sidebar,
    routeTabbar,
    overallDialog
  },
  computed: {
    account_s2ep: function () {
      return this._isS2ep()
    },
  },
  data() {
    return {
      nowDate: 0
    };
  },
  mounted() {
    //this.test()
    eventBus.$on("refreshRoute", () => {
      this.getNowDate();
    });
  },
  methods: {
    //test(){console.log("22222")},    
    getNowDate() {
      this.nowDate = new Date().getTime();
    }
  }
};
</script>

<style lang="scss">
.ws-index {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .ws-header {
    flex: 0 0 1;
  }
  .ws-main {
    flex: 1;
    display: flex;
    overflow: hidden;
    .ws-sidebar {
      flex: 0 0 1;
    }
    .ws-content {
      flex: 1;
      overflow: auto;
      padding: 0 40px 40px 40px;
      min-width: 940px;
      box-sizing: border-box;
      background-color: #fcfcfc;
    }
  }
}
.cover-all{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #d3d3d3;
  opacity:0.5;
  z-index: 999;
}
</style>