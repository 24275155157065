import axiosHttp from "../axiosHttp";

export default {
    queryPage(param){
        let url = "/purMaterialProcess/queryPageByInfo"
        return axiosHttp.POST(url,param)
    },
    queryList(param){
        let url = "/purMaterialProcess/queryList"
        return axiosHttp.POST(url,param)
    },
    removeById(id){
        let param = {
            materialProcessId : id
        }
        let url = `/purMaterialProcess/remove`
        return axiosHttp.POST(url,param)
    },
    queryAllFamily(id){
        let param = {
            materialProcessId : id
        }
        let url = `/purMaterialProcess/queryAllFamily`
        return axiosHttp.POST(url,param)
    },
    createStep0Process(param){
        let url = "/purMaterialProcess/createStep0Process"
        return axiosHttp.POST(url,param)
    },
    createStep1Process(param){
        let url = `/purMaterialProcess/createStep1Process`
        return axiosHttp.POST(url,param)
    },
    createStep2Process(param){
        let url = `/purMaterialProcess/createStep2Process`
        return axiosHttp.POST(url,param)
    },
    createStep3Process(param){
        let url = `/purMaterialProcess/createStep3Process`
        return axiosHttp.POST(url,param)
    },
    ListMaterialStockOnGroup(param){
        let url = `/purMaterialProcess/listMaterialStockOnGroup`
        return axiosHttp.POST(url,param)
    }
}