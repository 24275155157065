<template>
  <div class="dea-edit">
    <div class="step-title">经销商详情</div>
    <el-form
      :model="whmInfo"
      ref="deaInfoForm"
      class="step-form"
    >
      <el-form-item label="基本信息" class="lab-wid"> &nbsp; </el-form-item>
      <el-form-item label="经销商账号:" class="lab-wid">
        {{ whmInfo.dealerAccount }}
      </el-form-item>
      <el-form-item label="纳税类型:" class="lab-wid">
        {{ whmInfo.typeValue == "1" ? "个人" : whmInfo.typeValue == "2" ? "一般纳税人" : whmInfo.typeValue == "3" ? "小额纳税人" : ""}}
      </el-form-item>

      <el-form-item label="经销商名称:" class="lab-wid">
        {{ whmInfo.dealerName }}
      </el-form-item>
      <el-form-item label="联系人:" class="lab-wid">
        {{whmInfo.contactName}}
      </el-form-item>
      <el-form-item label="联系方式:" class="lab-wid">
        {{whmInfo.contactMobile}}
      </el-form-item>
      <el-form-item
        label="公司税号:"
        class="lab-wid"
        v-show="whmInfo.typeValue == '2' || whmInfo.typeValue == '3'"
      >
        {{whmInfo.einNumber}}
      </el-form-item>      
      <el-form-item
        label="营业执照、其他材料（最多五张）："
        class="up-img"
        v-show="whmInfo.typeValue != '1'"
      >
        <el-image 
          v-for="(item,index) in photoIds"
          :key="index"
          style="width: 100px; height: 100px"
          :src="photoIds[index]" 
          :preview-src-list="photoIds"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="财务信息" class="lab-wid"> &nbsp; </el-form-item>
      <el-form-item label="拿货折扣:" class="lab-wid">
        {{whmInfo.disValue}}
      </el-form-item>
      <!--accountType-->
      <el-form-item label="账号类型:" class="lab-wid">
        {{whmInfo.accTypeValue == 'agent_credit' ? "信用账户"
          : whmInfo.accTypeValue == 'agent_deposit' ? "预存账户"
              : whmInfo.accTypeValue == 'agent_cash' ? "现结账户": ""}}
      </el-form-item>
      <el-form-item
        label="信用额度:"
        class="lab-wid"
        v-show="whmInfo.accTypeValue == 'agent_credit'"
      >
        {{ whmInfo.lineOfCredit }}
      </el-form-item>
      <el-form-item
        label="剩余余额:"
        class="lab-wid"
        v-show="whmInfo.accTypeValue == 'agent_deposit'"
      >
        {{whmInfo.balanceNum}}
      </el-form-item>
      <el-form-item label="结算类型:" class="lab-wid" v-show="whmInfo.accTypeValue == 'agent_credit'">
        {{whmInfo.accValue == "1" ? "月结账户" : whmInfo.accValue == "2" ? "半月结账户": "周结账户"}}
      </el-form-item>
      <el-form-item label="结算时限:" class="lab-wid" v-show="whmInfo.accTypeValue == 'agent_credit'">
        {{whmInfo.validTime}}
        <span class="ml15">天</span>
      </el-form-item>

      <el-form-item label="渠道商：" class="lab-wid">
        <el-tag
            v-for="(tag,idx) in channelNames_list"
            :key="idx"
            :type="'info'">
          {{ tag }}
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">返回</el-button>
    </div>

  </div>
</template>

<script>
import dealerService from "API/dea/dealerService";
//import SysConstant from "@/constant/SysConstant";

export default {
  name: "whmEdit",
  data() {
    return {    
      photoIds:[],           
      whmInfo: {
        accValue: "",
        typeValue: "",
        disValue: "",
        dealerAccount: "", //经销商账号
        password: "", //经销商密码
        dealerType: "", //纳税类型
        dealerName: "", //经销商名称
        contactName: "", //联系人
        contactMobile: "", //联系方式
        einNumber: "", //公司税号
        companyRelateImgs: "", //材料图
        accountType: "", //账号类型
        lineOfCredit: "", //信用额度
        balanceNum: "", //剩余额度
        settlementType: "", //结算类型
        validTime: "", //结算时限
        lineOfCredit: "",//累计充值余额
        alsoLineOfCredit: "",//可用余额
        balanceNum: "",//余额
      },
      channelNames_list:[]
    };
  },
  mounted() {
    //console.log(this.$route.params.id)
    if(this.$route.params.id){
      this.getAgent()      
    }
  },
  methods: {
    backPre(){//返回上一页
      this.$router.push("/dea/dealerList");
    }, 
    getAgent() {
      dealerService
        .listForAgent()
        .then((res) => {
          this.buyDiscount = res.map((e) => {
            //console.log(e)
            return {
              disValue: e.eventId,
              label: e.eventName,
            };
          });
          this.getGoodsDetail()()
        })
        .catch(() => {});
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      dealerService.queryfaDealerInfo(this.$route.params.id).then((data) => {
        //console.log(data)
        let whmInfo = this.whmInfo;
        whmInfo.dealerAccount = data.dealerAccount; //经销商账号
        whmInfo.password = data.password; //经销商密码
        whmInfo.typeValue = data.dealerType.toString();
        if (whmInfo.typeValue != "1") {
          this.companyValue = data.dealerType.toString();
        }
        whmInfo.dealerName = data.dealerName; //经销商名称
        whmInfo.contactName = data.contactName; //联系人
        whmInfo.contactMobile = data.contactMobile; //联系方式
        whmInfo.einNumber = data.einNumber; //公司税号
        //拿货折扣
        if(data.buyDiscount){
          for(let i in this.buyDiscount){
            if(this.buyDiscount[i].disValue == data.buyDiscount){
              whmInfo.disValue = this.buyDiscount[i].label              
            }
          }  
        }      
        this.photoIds = data.companyRelateImgs.split(","),
        whmInfo.accTypeValue = data.accountType;
        whmInfo.lineOfCredit = data.lineOfCredit; //信用额度、累计充值余额
        whmInfo.balanceNum = data.balanceNum; //余额
        //whmInfo.settlementType = data.settlementType//结算类型
        whmInfo.accValue = data.settlementType;
        whmInfo.validTime = data.validTime; //结算时限
        whmInfo.alsoLineOfCredit = data.alsoLineOfCredit;//可用余额
        this.channelNames_list = data.channelNames;
        loading.close();
      });
    },

  },
};
</script>

<style lang="scss">
.el-image{margin-right: 10px;}
.dea-edit{ background: #fff;}
.dea-edit .step-title{ padding:0 0 0 20px; height: 60px; line-height: 60px; background: #F6F9FC;}
.dea-edit .step-form{ padding:30px 0 0 0;}
.dea-edit .steps-btn-wrapper{ padding:30px 0 40px 0; display: flex; justify-content: center;}
</style>