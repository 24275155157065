<template>
  <div class="logistics-wrap">
    <el-button type="primary" @click="printOut">打印</el-button>
    <div id="subOutputRank" class="print-me">
        <div class="print-head">
          <div>感谢您选购渥康官方商品！</div>
          <img src="../../../assets/images/wdom2.png" alt="">
        </div>
        <div class="print-con">
          <div class="print-tit">
            渥康产品发货-承运单
          </div>
          <div class="print-lit-tit">出库单号：XXXXXXXXXXXXXXX</div>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table">
            <tr>
              <td>发货方：丽丽亚</td>
              <!--发货时间：是填写的-->
              <td>发货时间：</td>
              <td rowspan="3">发货签名：</td>
            </tr>
            <tr>
              <td>发货人：莉莉安</td>
              <td>发货方电话：18350231299</td>
            </tr>
            <tr>
              <td colspan="2">发货方地址：龙湖</td>              
            </tr>

            <tr>
              <td>收货方：丽丽亚</td>
              <!--收货时间：是填写的-->
              <td>收货时间：</td>
              <td rowspan="3">收货签名：</td>
            </tr>
            <tr>
              <td>收货人：莉莉安</td>
              <td>收货方电话：18350231299</td>
            </tr>
            <tr>
              <td colspan="2">收货方地址：龙湖</td>              
            </tr>

            <tr>
              <td>承运单号：9898878799</td>
              <td>承运时间：：2021 03 09</td>
              <td rowspan="3">承运签名：</td>
            </tr>
            <tr>
              <td>承运人：莉莉安</td>
              <td>承运人电话：18350231299</td>
            </tr>
            <tr>
              <td colspan="2">承运车辆：五菱宏光</td>              
            </tr>
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>
          
          <div class="print-remark">订单备注：XXXXXXXXXXXXXXX</div>
          <div class="p1 mt20">送货方式：送货上门/自提</div>
          <div class="p1">运费金额：¥2,222(以实际称重为准)</div>
          <div class="p1">运费付款：到付/寄付</div>

          <div class="p2 mt20">1.以上签名处等同于公司印鉴，签名后有同样法律效力。</div>
          <div class="p2">2.收货签名视同于对产品数量无异议，如有产品破损，请在备注处标记品名及数量。</div>
          <div class="p2">3.本单据一式三联，由发货方发起，收货方终止，不得涂改，真实数据均具有法律效力。</div>

        </div>
    </div>    
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import boundQuery from "API/whm/boundQuery";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default {
  name: "receiveList",
  data() {
    return {
      teacherName:"测试pdf"
    };
  },
  mixins: [pageTableMixin],
  directives: {
    print   
  },
  mounted() {
    //this.loadTableData()
    //this.getGoodsDetail();
  },
  methods: {
    printOut() {
      var title = this.teacherName;      
      var shareContent = document.querySelector("#subOutputRank"); //需要截图的包裹的（原生的）DOM 对象
      var width = shareContent.offsetWidth; //获取dom 宽度
      var height = shareContent.offsetHeight; //获取dom 高度
      var canvas = document.createElement("canvas"); //创建一个canvas节点
      var scale = 2; //定义任意放大倍数 支持小数
      canvas.width = width * scale; //定义canvas 宽度 * 缩放，在此我是把canvas放大了2倍
      canvas.height = height * scale; //定义canvas高度 *缩放
      canvas.getContext("2d").scale(scale, scale); //获取context,设置scale
      html2Canvas(document.querySelector("#subOutputRank"), {
        allowTaint: true
      }).then(function(canvas) {
        var context = canvas.getContext("2d");
        // 【重要】关闭抗锯齿
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        
        var imgData = canvas.toDataURL("image/jpeg", 1.0); //转化成base64格式,可上网了解此格式
        var img = new Image();
        img.src = imgData;
        
        img.onload = function() {
          img.width = img.width / 2; //因为在上面放大了2倍，生成image之后要/2
          img.height = img.height / 2;
          // console.log(img.height > 840)
          // return
          img.style.transform = "scale(0.5)";
          if (this.width > this.height) {
            //此可以根据打印的大小进行自动调节
            var doc = new JsPDF("l", "mm", [
              this.width * 0.555,
              this.height * 0.555
            ]);
          } else {
            // imgData.addPage()
            var doc = new JsPDF("p", "mm", [ 
              this.width * 0.555,
              this.height * 0.555
            ]);
          }
          doc.addImage(
            imgData,
            "jpeg",
            10,
            0,
            this.width * 0.505,
            this.height * 0.545
          );
          doc.save(title + ".pdf");
        };
      });
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailLogistics(this.$route.params.id).then((data) => {
        //console.log(data)
        this.whmInfo = data
        loading.close();
      });
    },
    loadTableData() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,        
      };
      boundQuery.queryByReceiveList(params).then((data) => {
        data.list = data.list.map((e) => {
          return {
            skuCode: e.skuCode,//库品编号
            skuName: e.skuName,//库品名称
            receiveName: e.receiveName,//领用人
            createUser: e.createUser,//操作人
            operNum: e.operNum,//领用数量
            createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss"),//操作时间
          };
        });
        this.querySuccess(data);
      });      
    },   
  },
};
</script>
<style>
.print-me{ margin-top: 40px; width: 210mm; box-sizing: border-box; padding:25px; background: #fff;}
.print-head{ display: flex; justify-content: space-between; align-items: center; font-size: 12px; padding-bottom: 6px; border-bottom: 1px solid #000;}
.print-head img{ width: 33px;}
.print-tit{ margin-top: 12px; font-size: 18px; text-align: center; color: #333;}
.print-lit-tit{ margin-top: 20px; font-size: 12px;}
.print-table{ margin-top: 14px; width: 100%; border-top: 1px solid #000; border-left: 1px solid #000; font-size: 10px;}
.print-table td,.print-table th{ padding:4px 4px 4px 6px; box-sizing: border-box; border-right: 1px solid #000; border-bottom: 1px solid #000;}
.fs14{ font-size: 10px;}
.print-remark{ margin-top: 30px; font-size: 12px;}
.p1{ padding:6px 0; font-size: 12px;}
.mt20{ margin-top: 10px;}
.p2{ padding:6px 0; font-size: 10px;}
</style>