import { render, staticRenderFns } from "./PurList.vue?vue&type=template&id=4f16adae&scoped=true"
import script from "./PurList.vue?vue&type=script&lang=js"
export * from "./PurList.vue?vue&type=script&lang=js"
import style0 from "./PurList.vue?vue&type=style&index=0&id=4f16adae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f16adae",
  null
  
)

export default component.exports