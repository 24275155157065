<template>
  <div>
  <el-dialog title="查看枚举类型" :visible.sync="dialogVisible" width="700px" v-loading="loading">
    <table class="ws-gridtable ws-3cloumn">
      <tr>
        <th align="center">枚举类型名称</th>
        <td>{{this.typeName2}}</td>
        <th align="center">枚举类型</th>
        <td>{{this.type2}}</td>
      </tr>
    </table>
    <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
            height="250"
    >
      <el-table-column prop="name" label="枚举名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="value" label="枚举值" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="code" label="编号"  :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true">
        <!--<template slot-scoped="scope">-->
          <!--{{scope.row}}-->
        <!--</template>-->
      </el-table-column>
    </ws-page-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
    import BimEnumerationInfo from "API/bim/BimEnumerationInfo";
    import pageTableMixin from "@/mixin/pageTableMixin";


    export default {
        props: {
            type2: String,
            typeName2: String
        },
        watch: {
            dialogVisible() {
                if (this.type2) {
                    this.loadTableData();
                }
            }
        },
        data() {
            return {
                dialogVisible: false,
                loading: false,
            };
        },
        mixins: [pageTableMixin],
        methods: {
            loadTableData() {
                this.loading = true;
                BimEnumerationInfo.getEnumList(this.pageInfo,this.type2).then(data => {
                this.querySuccess(data);
                // this.loading = false;
            });
            }
        }
    };
</script>

<style scoped>
  .el-form /deep/ .el-form-item__label {
    font-size: 16px;
  }
  .el-form /deep/ .el-form-item__content {
    font-size: 16px;
  }

  .brand-icon {
    display: inline-block;
    width: 82px;
    height: 82px;
    object-fit: fill;
  }
</style>
