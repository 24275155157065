<template>
  <div class="order-list">
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">订单编号：</span>
            <el-input size="mini" v-model="searchInfo.orderCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">订单来源：</span>
            <el-select v-model="searchInfo.orderType" placeholder="请选择">
              <el-option
                  v-for="item in sourceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">导入批次：</span>
            <el-input size="mini" v-model="searchInfo.batchCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">会员或经销商（账号）：</span>
            <el-input
                size="mini"
                v-model="searchInfo.distributorOrAgentAccount"
            ></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">收件姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件手机：</span>
            <el-input
                size="mini"
                v-model="searchInfo.recipientPhoneNum"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件地址（模糊）：</span>
            <el-input
                size="mini"
                v-model="searchInfo.recipientFullAddress"
            ></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">快递单号：</span>
            <el-input
                size="mini"
                v-model="searchInfo.packageTransportNo"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">平台单号：</span>
            <el-input
                size="mini"
                v-model="searchInfo.thirdPartyOrderCode"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品编码：</span>
            <el-input size="mini" v-model="searchInfo.goodsCode"></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">下单时间：</span>
            <el-date-picker
                v-model="filterTimes"
                @change="changePickerDate"
                size="mini"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
            <span
                class="filter-time-block"
                @click="filterDate('today')"
                :class="{ 'is-active': filterDateTimer == 'today' }"
            >今</span
            >
            <span
                class="filter-time-block"
                @click="filterDate('yesterday')"
                :class="{ 'is-active': filterDateTimer == 'yesterday' }"
            >昨</span
            >
            <span
                class="filter-time-block"
                @click="filterDate(7)"
                :class="{ 'is-active': filterDateTimer == 7 }"
            >近7天</span
            >
            <span
                class="filter-time-block"
                @click="filterDate(30)"
                :class="{ 'is-active': filterDateTimer == 30 }"
            >近30天</span
            >
          </div>
        </div>
        <div class="form-row">
          <el-button
              size="mini"
              type="primary"
              style="margin-right: 10px"
              @click="searchData"
          >搜索
          </el-button>
          <el-dropdown
              size="mini" split-button
              type="primary" @click="exportOrder" @command="exportPackage">
            导出
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="package">导出包裹</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
              size="mini"
              type="primary"
              style="margin-left: 10px"
              @click="orderHandleComplete"
              v-if="isTest"
          >手动完成订单
          </el-button>
        </div>
      </div>
      <div class="filter_right">
        <!--
        <el-button
            size="mini"
            type="primary"
            @click="$router.push({ path: '/som/orderExportRecord' })"
        >订单导出记录
        </el-button>
        -->
        <el-button
            size="mini"
            type="primary"
            @click="
            $router.push({
              path: '/ba/systemLog',
              query: { logType: 'order' },
            })
          "
        >操作日志
        </el-button>
      </div>
    </div>
    <div class="order-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs
            v-model="searchInfo.orderState"
            type="card"
            @tab-click="changeTable"
        >
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="待支付" name="unPaid"></el-tab-pane>
          <el-tab-pane label="待发货" name="unShipped"></el-tab-pane>
          <el-tab-pane label="待收货" name="unReceived"></el-tab-pane>
          <el-tab-pane label="已完成" name="finished"></el-tab-pane>
          <el-tab-pane label="已关闭" name="closed"></el-tab-pane>
          <el-tab-pane label="审核中" name="audit"></el-tab-pane>
          <el-tab-pane label="异常" name="abnormal"></el-tab-pane>
        </el-tabs>
        <el-button
            size="mini"
            type="primary"
            @click="deleteOrder"
            v-if="searchInfo.orderState == 'unPaid'"
        >取消全部{{ this.pageInfo.total }}个订单
        </el-button>
      </div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          :isShadow="false"
      >
        <el-table-column prop="orderCode" label="订单号"></el-table-column>
        <el-table-column prop="status" label="订单状态"></el-table-column>
        <el-table-column prop="shipStatus" label="发货状态" v-if="searchInfo.orderState=='unShipped'"></el-table-column>
        <el-table-column prop="price" label="订单价格（元）"></el-table-column>
        <el-table-column prop="source" label="订单来源"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="查看">
                <i
                    class="CCSFont icon-table_2"
                    @click="$router.push(`/som/orderDetail/${scope.row.orderId}`)"
                ></i>
              </a>
              <a href="javascript: void(0);" title="编辑">
                <i
                    class="CCSFont icon-table_1"
                    @click="$router.push(`/som/orderEdit/${scope.row.orderId}`)"
                ></i>
              </a>
              <!-- <el-tooltip content="编辑" placement="top">
                              <i class="CCSFont icon-table_1"></i>
                            </el-tooltip>-->
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <!--弹窗-->
    <el-dialog title="导出发货包裹" :visible.sync="isShowPopop" width="560px">
      <div style="color: #fbc14e">提醒：</div>
      <div>
        <br/>
        <p>1、点击确定即导出包裹信息excel；</p>
        <br/>
        <p>2、邮政小包、德邦快递、顺丰快递分别为三个sheet；</p>
        <br/>
        <p>3、点击确定即自动将订单转为已发货，请尽快导入运单号信息；</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="exportOrder">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import Enum from "ASSETS/js/enum";
import translate from "ASSETS/js/translate";
//import chargeFormat from "COMJS/chargeFormat";
import orderService from "API/som/orderService";
import orderOperateService from "API/som/orderOperate";
import orderExportService from "API/som/orderExportService";
import pageTableMixin from "@/mixin/pageTableMixin";
import IoProgressService from "API/IoProgressService";
//import userModule from "@/module/userModule";

export default {
  name: "orderList",
  data() {
    return {
      searchInfo: {
        orderCode: "",
        batchCode: "",
        distributorOrAgentAccount: "",
        orderType: "",
        createTimeBeginDate: "",
        createTimeEndDate: "",
        orderState: "all",
        recipientName: "",
        recipientPhoneNum: "",
        recipientFullAddress: "",
        thirdPartyOrderCode: "",
        goodsCode: "",
        packageTransportNo: "",
      },
      filterTimes: [],
      filterDateTimer: "", // 快捷选择时间类型
      sourceList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "wmp",
          label: "小程序",
        },
        {
          value: "import",
          label: "批量导入",
        },
        {
          value: "adm",
          label: "客服下单",
        },
      ],
      isShowPopop: false,
    };
  },
  mixins: [pageTableMixin],
  computed:{
    isTest: function () {
      if (this.$route.params.test === "test") {
        return true;
      }
      return false;
    },
  },
  mounted() {

  },
  beforeMount() {
    //页面刷新
    if (this.$route.query.orderState) {
      this.searchInfo.orderState = this.$route.query.orderState;
    }
  },
  activated() {
    //页签切换
    if (this.$route.query.orderState) {
      this.searchInfo.orderState = this.$route.query.orderState;
      this.loadTableData();
    }
  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      orderService.queryOrderPages(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          e.createTime = tools.formatDate(
              new Date(e.createTime),
              "yyyy-MM-dd hh:mm:ss"
          );
          // e.source = translate.source(e.orderType);
          e.source = Enum.orderType[e.orderType];
          e.status = translate.orderState(e);
          e.shipStatus =
              e.thirdPartyShipCode == 'JKY' ? '已转吉客云' :
                  e.transportState == '0' ? '未转出库' :
                      e.transportState == '5' ? '已转出库' :
                          e.transportState == '1' ? '已发货' :
                              e.transportState == '2' ? '已收货' : '';
          e.orderCode = e.orderCode || "无";
          e.price = e.totalAmount.toFixed(2); // chargeFormat.thousandsOf()
          return e;
        });
        this.querySuccess(data);
      });
    },
    changeTable(event) {
      this.searchInfo.orderState = event.name;
      this.pageInfo.pageNo = 1;
      this.loadTableData();
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.createTimeBeginDate = "";
        this.searchInfo.createTimeEndDate = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.createTimeBeginDate = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.searchInfo.createTimeEndDate = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
    async deleteOrder() {
      if (this.pageInfo.total == 0) {
        this.$message.error("没有订单!");
        return;
      }
      var that = this;
      this.$confirm(
          "此操作将永久关闭" + this.pageInfo.total + "条订单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            orderOperateService.cancelOrderList(that.queryParams).then((data) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadTableData();
            });
          })
          .catch(() => {
          });
    },
    filterDate(timer) {
      this.filterDateTimer = timer;
      let _time = tools.filterDateMethod(timer);
      if (!this.filterTimes) {
        //鼠标点击x号的时候 filterTimes为null  先初始化为数组，然后吧值填进去
        this.filterTimes = [];
        this.filterTimes.push(_time.startTime, _time.endTime);
      } else {
        this.$set(this.filterTimes, 0, _time.startTime);
        this.$set(this.filterTimes, 1, _time.endTime);
      }
      this.searchInfo.createTimeBeginDate = _time.startTime;
      this.searchInfo.createTimeEndDate = _time.endTime;
    },
    orderHandleComplete(){
      let that = this;
      this.$confirm("确定强制完成这些订单（只有待收货状态订单会有效果）?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let loading = that.$loading({
          lock: true,
          text: "Loading...",
        });
        orderService
            .orderCompulsoryComplete(this.queryParams)
            .then((data) => {
              loading.close();
              that.loadTableData();
            })
            .catch(() => {
              loading.close();
            });
      }).catch(() => {});
    },
    exportPackage(){
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let that = this;
      orderExportService
          .exportPackageForAdmin(this.queryParams)
          .then((data) => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
            that.isShowPopop = false;
          })
          .catch(() => {
            loading.close();
          });
    },
    exportOrder() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let that = this;
      orderExportService
          .exportOrderForAdmin(this.queryParams)
          .then((data) => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
            that.isShowPopop = false;
          })
          .catch(() => {
            loading.close();
          });
    },
  },
};
</script>

<style lang="scss">
.el-table .cell {
  white-space: nowrap;
}

.ws-page-table .icon-operation a {
  margin-right: 10px;
}

.order-list {
  .order-table {
    .tabs-wrapper {
      padding: 10px 20px;

      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;

        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }

    background-color: #fff;
  }
}
</style>