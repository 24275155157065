<template>
  <div>
    <el-dialog title="创建采购单" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <el-form :model="dialogData" :rules="dialogRules" ref="dialogForm"
               label-width="110px" v-loading="dialogLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="选择物料" prop="materialId">
              <el-select v-model="dialogData.materialId" placeholder="物料" style="width: 100%;">
                <el-option
                    v-for="item in materialList"
                    :key="item.materialId"
                    :label="item.materialName"
                    :value="item.materialId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择供应商" prop="materialSupplierId">
              <el-select v-model="dialogData.materialSupplierId" placeholder="供应商" style="width: 100%;">
                <el-option
                    v-for="item in materialSupplierList"
                    :key="item.materialSupplierId"
                    :label="item.materialSupplierName"
                    :value="item.materialSupplierId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="批次号" prop="batchCode">
              <el-input v-model="dialogData.batchCode" :maxlength="64" placeholder="批次号" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购数量" prop="processNum">

              <el-input v-model.number="dialogData.processNum"
                        placeholder="数量" :maxlength="8" style="width: 100%;"></el-input>

              <!--
              <el-input-number v-model="dialogData.processNum"
                               placeholder="数量" style="width: 100%;" :step="1000"></el-input-number>
                               -->
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="hide">关 闭</el-button>
        <el-button type="primary" @click.native="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import materialService from "API/pur/PurMaterialService"
import materialSupplierService from "API/pur/PurMaterialSupplierService"
import materialProcessService from "API/pur/PurMaterialProcessService"

export default {
  name: "Step0CreateDialog",
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      dialogData: {},
      dialogRules: {
        materialId: [
          {required: true, message: '请选择采购物料'}
        ],
        materialSupplierId: [
          {required: true, message: '请选择供应商'}
        ],
        batchCode: [
          {required: true, message: '请填写批次号'}
        ],
        processNum: [
          {required: true, message: '请填写采购数量'},
          {type: 'number', message: '输入内容不是有效的数字', trigger: 'blur' }
        ]
      },
      //
      materialList: [],
      materialSupplierList:[],
    }
  },
  mounted() {
    this.queryDataList()
  },
  methods: {
    show() {
      this.clear()
      this.dialogVisible = true
    },
    hide() {
      this.clear()
      this.dialogVisible = false
    },
    clear(){
      this.dialogData = {}
      try{
        this.$refs.dialogForm.resetFields();
      }catch(e){}
    },
    save() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          let param = {
            ...this.dialogData
          }
          this.dialogLoading = true;
          materialProcessService.createStep0Process(param).then(res=>{
            this.$message.success("保存成功")
            this.$emit("reloadAfterSave");
            this.hide()
          }).finally(()=>{
            this.dialogLoading = false;
          })
        }
      })
    },
    queryDataList(){
      materialService.queryList({}).then(res=>{
        this.materialList = res;
      })
      materialSupplierService.queryList({}).then(res=>{
        this.materialSupplierList = res;
      })

    }
  }
}
</script>

<style scoped>

</style>