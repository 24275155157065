<template>
  <div>
    <div style="display: flex;align-items: end;">
      <div style="font-size: 24px;font-weight: bold;">
        {{isCreditDealer && !creditChangeSite?'信用账户':'余额账户'}}
      </div>
      <el-link type="warning"
               v-if="isCreditDealer"
               style="margin-left: 20px;"
               icon="el-icon-open"
               @click="creditSiteChange">
        {{creditChangeSite?'切换至信用账户':'切换至余额账户'}}
      </el-link>
    </div>
    <div>
      <el-form :inline="true" class="bill-table">
        <table cellspacing="0" cellpadding="0">
          <tr v-if="!isCreditDealer || creditChangeSite">
            <td>
              <el-form-item label="账户余额:">
                ¥{{ headerInfo.balanceNum }}
              </el-form-item>
              <el-button type="text" @click="withdrawalCashApply"
                         style="font-size: 12px;text-decoration:underline;">提现</el-button>
            </td>
          </tr>
          <tr v-if="isCreditDealer && !creditChangeSite">
            <td>
              <el-form-item label="剩余额度:">
                ¥{{ headerInfo.alsoLineOfCredit }}（总额度¥{{ headerInfo.lineOfCredit }}）
              </el-form-item>
            </td>
            <td>
              <el-form-item label="结余额度:">
                ¥{{ headerInfo.refundBalance }}
                <el-tooltip effect="dark" content="结余额度为上一期账单结余金额，一般因已结算订单退款而产生，生成下期账单后自动抵扣账单金额" placement="top">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </el-form-item>
            </td>
          </tr>
          <tr v-if="isCreditDealer && !creditChangeSite">
            <td>
              <el-form-item label="待结算额度:">
                ¥{{ headerInfo.stayClose }}
                <el-tooltip effect="dark" content="待结算额度为已生成账单的账单金额" placement="top">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </el-form-item>
            </td>
            <td>
              <el-form-item label="待入账额度:">
                ¥{{ headerInfo.stayEnter }}
                <el-tooltip effect="dark" content="待入账额度为已下单、未生成账单的账单金额" placement="top">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </el-form-item>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </el-form>
    </div>
    <div>
      <div>
        <el-form :inline="true" class="demo-form-inline">
          <table cellspacing="0" cellpadding="0">
            <tr v-if="isCreditDealer && !creditChangeSite">
              <td>
                <el-form-item label="账单范围:">
                  <el-select v-model="searchInfo.billId" placeholder="请选择" clearable style="width: 350px;">
                    <el-option
                        v-for="item in billList"
                        :key="item.billId"
                        :label="item.orderOnTime"
                        :value="item.billId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>
                <el-form-item label="时间范围:">
                  <el-date-picker
                      v-model="searchInfo.createTime"
                      size="mini"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="订单编号:">
                  <el-input v-model="searchInfo.orderCode" clearable/>
                </el-form-item>
                <el-form-item label="导入批次号:">
                  <el-input v-model="searchInfo.batchCode" clearable/>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="loadTableData">搜索</el-button>
                </el-form-item>
              </td>
            </tr>
          </table>
        </el-form>
        <el-tabs v-model="activeName" type="card" @tab-click="activeSwitch">
          <el-tab-pane label="全部" name="全部"></el-tab-pane>
          <el-tab-pane label="支出" name="支出"></el-tab-pane>
          <el-tab-pane label="退款" name="退款"></el-tab-pane>
          <el-tab-pane label="充值" name="充值"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
      >
        <el-table-column min-width="150" prop="createTime" label="时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column min-width="150" prop="operatorType" label="操作">
          <template slot-scope="scope">
            {{
              scope.row.type == '0' ? '新建账户' :
                  scope.row.type == '1' ? '额度提升' :
                      scope.row.type == '8' ? '额度降低' :
                          scope.row.type == '2' ? '客服充值' :
                              scope.row.type == '3' ? '客服扣款' :
                                  scope.row.type == '9' ? '转账充值' :
                                      scope.row.type == '10' ? '微信充值' :
                                          scope.row.type == '4' ? '支付订单' :
                                              scope.row.type == '5' ? '支付订单' :
                                                  scope.row.type == '6' ? '订单退款' :
                                                      scope.row.type == '7' ? '订单退款' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="alterBalance" label="金额">
          <template slot-scope="scope">
            <div
                :style="{'color': (scope.row.type == '0' || scope.row.type == '1' || scope.row.type == '2' || scope.row.type == '6' || scope.row.type == '7' || scope.row.type == '9' || scope.row.type == '10')?'green':'red'}">
              {{
                (scope.row.type == '0' || scope.row.type == '1' || scope.row.type == '2' || scope.row.type == '6' || scope.row.type == '7' || scope.row.type == '9' || scope.row.type == '10') ? '+' : '-'
              }}
              ¥{{ scope.row.operNum ? chargeFormat.thousandsOf(scope.row.operNum) : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="balance" label="操作后余额" v-if="!isCreditDealer || creditChangeSite">
          <template slot-scope="scope">
            ¥{{
              scope.row.newBalanceNum ? chargeFormat.thousandsOf(scope.row.newBalanceNum) : '0.00'
            }}
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="balance" label="操作后额度" v-if="isCreditDealer && !creditChangeSite">
          <template slot-scope="scope">
            ¥{{
              scope.row.newAlsoLineOfCredit ? chargeFormat.thousandsOf(scope.row.newAlsoLineOfCredit) : '0.00'
            }}
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="orderCode" label="订单编号"/>
        <el-table-column min-width="150" prop="batchCode" label="导入批次号"/>
        <!--        <el-table-column min-width="170" prop="remark" label="备注"/>-->
      </ws-page-table>
    </div>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerService from "API/dea/dealerService";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import chargeFormat from "COMJS/chargeFormat.js";
import userModule from "@/module/userModule";
//import sysConstant from "@/constant/SysConstant";
import sysUtil from "@/constant/SysUtil";

export default {
  name: "dealerAccountDetail",
  components: {
    draggableUpload,
  },
  mixins: [pageTableMixin],
  computed: {
    chargeFormat() {
      return chargeFormat;
    },
    isCreditDealer() {
      return sysUtil.isAgentCredit(JSON.parse(userModule.PPUser).role);
    }
  },
  data() {
    return {
      creditChangeSite: false,
      headerInfo: {
        balanceNum: "",
        lineOfCredit: "",
        alsoLineOfCredit: "",
        refundBalance: "",
        stayClose: "",
        stayEnter: "",
      },
      billList: [],
      activeName: "全部",
      searchInfo: {
        bimUserId: JSON.parse(userModule.PPUser).userId,
        createTime: [],
        type: "",
        auditState: "success,skip"
      },
      items: {
        bimUserId: {filter: "eq"},
        createTime: {filter: "between"},
        type: {filter: "in"},
        auditState: {filter: "in"},
      },
    }
  },
  mounted() {
    this.getDealerInfo();
    this.queryBill();
  },
  methods: {
    getDealerInfo() {
      dealerService.findDealerLinesInfo(JSON.parse(userModule.PPUser).userId).then((data) => {
        this.headerInfo.balanceNum = (data.balanceNum - 0).toFixed(2)
        this.headerInfo.canUseMoney = (data.alsoLineOfCredit + data.refundBalance).toFixed(2)
        this.headerInfo.lineOfCredit = (data.lineOfCredit - 0).toFixed(2)
        this.headerInfo.alsoLineOfCredit = (data.alsoLineOfCredit - 0).toFixed(2)
        this.headerInfo.stayClose = (data.stayClose - 0).toFixed(2)
        this.headerInfo.stayEnter = (data.stayEnter - 0).toFixed(2)
        this.headerInfo.refundBalance = (data.refundBalance - 0).toFixed(2)
      });
    },
    queryBill() {
      let param = {
        bimUserId: JSON.parse(userModule.PPUser).userId
      }
      dealerService.queryBillByInfo(param).then(data => {
        this.billList = data;
        //let item = {'billId':'unpublished_bill','orderOnTime':'未出账单'}
        //this.billList.splice(0,0,item)
      })
    },
    activeSwitch() {
      this.loadTableData();
    },
    creditSiteChange(){
      this.creditChangeSite=!this.creditChangeSite
      this.loadTableData();
    },
    loadTableData() {
      if (this.activeName == "全部") {
        this.searchInfo.type = (this.isCreditDealer && !this.creditChangeSite)
            ?"0,1,8,4,6":"0,2,3,9,10,5,7";//""
      } else if (this.activeName == "支出") {
        this.searchInfo.type = (this.isCreditDealer && !this.creditChangeSite)
            ?"4":"5";//"4,5"
      } else if (this.activeName == "退款") {
        this.searchInfo.type = (this.isCreditDealer && !this.creditChangeSite)
            ?"6":"7";//"6,7"
      } else if (this.activeName == "充值") {
        this.searchInfo.type = (this.isCreditDealer && !this.creditChangeSite)
            ?"0,1,8,":"0,2,3,9,10";//"0,1,8,2,3,9,10"
      }
      this.$refs.pageTable.tableLoading = true;
      dealerService
          .queryDealerLog(this.pageInfo, this.searchConfig)
          .then((data) => {
            data.list = data.list.map((e) => {
              //console.log(e)
              return {
                ...e,
                createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss")
              };
            });
            this.querySuccess(data);
          });
    },
    withdrawalCashApply(){
      this.$router.push({ path: "/dea/dealerWithdrawList" });
    },
  }
}
</script>

<style scoped>
.icon-tip {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("~ASSETS/images/tip.png");
  background-size: cover;
}

.bill-table {
  padding: 20px 0px 20px 0px;
}

.bill-table td {
  padding: 10px 100px 10px 0;
}

.bill-table /deep/ .el-form-item__content {
  color: #d04343;
}

.icon-operation a {
  margin-right: 20px;
}
</style>