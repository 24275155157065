<template>
  <div>
    <el-tabs tab-position="left">
      <el-tab-pane label="物料列表">
        <material-list></material-list>
      </el-tab-pane>
      <el-tab-pane label="供应商列表">
        <material-supplier-list></material-supplier-list>
      </el-tab-pane>
      <el-tab-pane label="新西兰仓库列表">
        <warehouse-list></warehouse-list>
      </el-tab-pane>
      <el-tab-pane label="新西兰工厂列表">
        <factory-list></factory-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import materialList from './infoManagerList/InfoManager4MaterialList'
import materialSupplierList from './infoManagerList/InfoManager4MaterialSupplierList'
import factoryList from './infoManagerList/InfoManager4FactoryList'
import warehouseList from './infoManagerList/InfoManager4WarehouseList'

export default {
  name: "MaterialInfoManager",
  components: {
    materialList: materialList,
    materialSupplierList:materialSupplierList,
    factoryList:factoryList,
    warehouseList:warehouseList,
  }
}
</script>

<style scoped>
</style>