<template>
  <div>
    <el-dialog :title="modalTitle" :visible.sync="dialogVisible" width="80%">
      <div style="padding: 20px">
        <div style="
            display: flex;
            flex-warp: warp;
            padding: 15px;
            border: 1px solid;
          ">
          <div>订单号：{{ currentData.order.orderCode }}</div>
          <div class="cs-ml">
            下单时间：{{
              currentData.order.createTime
                  ? formart(new Date(currentData.order.createTime), "yyyy-MM-dd hh:mm:ss")
                  : ""
            }}
          </div>
          <div class="cs-ml">
            会员或经销商：{{ currentData.order.userAccount || '' }}
          </div>
          <div class="cs-ml">
            订单来源：{{ Enum.orderType[currentData.order.orderType] }}
          </div>
          <div class="cs-ml">
            平台单号：{{ currentData.order.thirdPartyOrderCode }}
          </div>
        </div>
        <div>
          <table class="address-info">
            <td>
              <tr class="th">
                收件人信息
              </tr>
              <tr>收货人：{{ currentData.order.recipientName }}</tr>
              <tr>联系电话：{{ currentData.order.recipientPhoneNum }}</tr>
              <tr>收货地址：{{ currentData.order.recipientFullAddress }}</tr>
            </td>
            <td>
              <tr class="th">配送信息</tr>
              <tr v-for="(item, index) in packageList" :key="index"
                  v-html="translateTransport(item,index)"></tr>
            </td>
            <td>
              <tr class="th">金额信息</tr>
              <tr>总金额：{{ currentData.order.totalAmount }}</tr>
              <tr>商品金额：{{ currentData.order.goodsPaymentAmount }}</tr>
              <tr>运费：{{ currentData.order.transportAmount }}</tr>
            </td>
            <td>
              <tr class="th">付款信息</tr>
              <tr>付款方式：{{
                  currentData.order.payChannel
                      ? Enum.payWay[currentData.order.payChannel]
                      : '未付款'
                }}</tr>
              <tr>付款时间：{{
                  currentData.order.payTime
                      ? formart(new Date(currentData.order.payTime), "yyyy-MM-dd hh:mm:ss")
                      : '未付款'
                }}
              </tr>
            </td>
          </table>
        </div>
        <div style="display:flex;padding:15px 0;border:1px solid #999999;margin-top: 20px;">
          <div class="modal-flex">商品信息</div>
          <div class="modal-flex">数量</div>
          <div class="modal-flex">单价</div>
          <div class="modal-flex">总价</div>
          <div class="modal-flex">退货件数</div>
        </div>
        <div style="margin-top:10px;" v-for="(item, i) in goodsList" :key="i">
          <div style="display:flex;">
            <div class="modal-flex">
              <el-image style="width: 100px; height: 100px;"
                        :src="item.goodsPictureUrl" fit="cover"></el-image>
              <div style="margin-left:10px;width: 150px;">
                {{ item.goodsName }}
              </div>
            </div>
            <div class="modal-flex">x{{ item.num }}</div>
            <div class="modal-flex">
              ￥{{ item.paymentPrice.toFixed(2) }}
            </div>
            <div class="modal-flex">
              ￥{{ (item.paymentPrice * item.num).toFixed(2) }}
            </div>
            <div class="modal-flex">{{ item.num }}</div>
          </div>
        </div>

        <div style="margin-top: 20px;">
        <el-row class="row-item">
          <el-col :span="15">
            <el-col :span="2"> 订单状态： </el-col>
            <el-col :span="11" justify="start">
              {{ currentData.status }}
            </el-col>
          </el-col>
          <el-col :span="9" align="end">售后金额：￥{{
              currentData.order.totalAmount
                ? currentData.order.totalAmount.toFixed(2)
                : 0
            }}</el-col>
        </el-row>
        <el-row class="row-item">
          <el-col :span="15">
            <el-col :span="2"> 售后原因： </el-col>
            <el-col :span="11" justify="start">
              {{ currentData.after.reason }}
            </el-col>
          </el-col>
          <el-col :span="9" align="end">客服确认退款：￥{{
              currentData.after.handleTotalAmount
                ? Number(currentData.after.handleTotalAmount).toFixed(2)
                : 0
            }}</el-col>
        </el-row>
        <el-row class="row-item">
          <el-col :span="15">
            <el-col :span="2"> 售后拍照： </el-col>
            <el-col :span="11" justify="start">
              <el-image v-for="(item, index) in srcList" :key="index"
                        :fit="'cover'"
                        style="width:100px;height: 100px; margin-right: 10px"
                        :src="srcList[index]" :preview-src-list="srcList"></el-image>
            </el-col>
          </el-col>
          <el-col :span="9" align="end">客服备注：{{ currentData.after.remark }}</el-col>
        </el-row>
        <el-row class="row-item" justify="space-between">
          <el-col :span="15">
            <el-col :span="2"> 退款建议： </el-col>
            <el-col :span="12" justify="start">
              {{
                currentData.status == "已完成" &&
                currentData.after.backTransportNo
                  ? "客户退货已发出，仓库确认收货即可退款。"
                  : currentData.status == "已完成" &&
                    currentData.after.handleState == 'wait_return'
                  ? "等待客户上传运单号"
                  : currentData.status == "已完成" &&
                    currentData.after.handleState == "wait_refund"
                  ? "不退货仅退款，可立即操作退款"
                  : currentData.status == "待收货"
                  ? "快递拦截成功，仓库确认收货即可退款。"
                  : ""
              }}
            </el-col>
          </el-col>
          <!-- <el-col :span="9" align='end'>售后金额：￥{{currentData.order.totalAmount?currentData.order.totalAmount.toFixed(2):0}}</el-col> -->
        </el-row>
        <el-row class="row-item">
          <el-col :span="15">
          <el-col :span="2">快递公司：</el-col>
          <el-col :span="21"  justify="start">{{ currentData.after.backTransportCompany}}</el-col>
          </el-col>
        </el-row>
        <el-row class="row-item">
          <el-col :span="15">
            <el-col :span="2"> 退货运单： </el-col>
            <el-col :span="11" justify="start">
              {{
                currentData.after.backTransportNo
                  ? currentData.after.backTransportNo
                  : "暂未上传"
              }}
            </el-col>
          </el-col>
        </el-row>

        </div>
      </div>
      <span v-if="!isSee" slot="footer" class="dialog-footer">
        <div>
          <el-button type="success" @click="openConfirmTheRefundPop">同意退款</el-button>
          <el-button type="danger" @click="openReturnInformationPop">拒绝退款</el-button>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
    <confirmTheRefundPop ref="confirmTheRefund" @auditOk="auditOk"></confirmTheRefundPop>
    <refusedToARefundPop ref="refusedToARefund" @auditOk="auditOk"></refusedToARefundPop>
  </div>
</template>

<script>
import Enum from "ASSETS/js/enum";
import confirmTheRefundPop from "./confirmTheRefundPop.vue";
import refusedToARefundPop from "./refusedToARefundPop.vue";
import translate from "ASSETS/js/translate";
import {
  queryAuditsByIdCommon,
} from "API/som/checkService.js";
import tools from "COMJS/tools";
import Objects from "COMJS/Objects";

export default {
  components: {
    confirmTheRefundPop,
    refusedToARefundPop,
  },
  computed: {
    Enum() {
      return Enum;
    }
  },
  data() {
    return {
      businessAuditLogId: "",
      modalTitle: "查看",
      dialogVisible: false,
      currentData: { order: {}, after: {} },
      goodsList:[],
      packageList: [], //订单运单包裹
      srcList: [],
      isSee: false,
    };
  },
  watch: {
    /*currentData: {
      handler(newVal, oldVal) {
        if (newVal.totalAmount < newVal.handleReduceAmount) {
          this.currentData.handleReduceAmount = this.currentData.totalAmount;
        }
        if (newVal.handleReduceAmount) {
          if (
            newVal.handleReduceAmount.match("-") ||
            newVal.handleReduceAmount.match("+")
          ) {
            this.currentData.handleReduceAmount = 0;
          }
        } else {
          this.currentData.handleReduceAmount = 0;
        }
      },
      deep: true,
    },*/
  },
  methods: {
    translateTransport(item, index) {
      let transportNo = Objects.isEmpty(item.transportNo)
          ? "无"
          : item.transportNo;
      let pack = "包裹" + (index + 1);
      return pack + "：" + transportNo + "（" + item.transportCompany + "）";
    },
    formart: tools.formatDate,
    auditOk() {
      this.dialogVisible = false;
      this.$emit("auditOk", "");
    },
    show(businessAuditLogId, auditFlag) {
      this.businessAuditLogId = businessAuditLogId;
      this.dialogVisible = true;
      this.isSee = !auditFlag;
      this.modalTitle = this.isSee ? "查看" : "审核";
      let params = {
        auditConfigurationCode: "S002",
        businessAuditLogId: businessAuditLogId,
      };
      queryAuditsByIdCommon(params).then((data) => {
        data.status = translate.orderState(data.order);
        //data.order.afterRemark = data.order.afterRemark.split("；");
        this.currentData = data;
        this.goodsList = []
        this.goodsList = data.order.orderGoodsList;
        this.packageList = [];
        this.packageList = data.order.orderPackageList;
        this.srcList = data.after.imgs ? data.after.imgs.split(",") : [];
      });
    },
    openReturnInformationPop() {
      let data = {
        currentData: this.currentData.order,
        businessAuditLogId: this.businessAuditLogId,
      };
      this.$refs.refusedToARefund.dialogVisible = true;
      this.$refs.refusedToARefund.popType = "refund";
      this.$refs.refusedToARefund.getcurrentData(data);
    },
    openConfirmTheRefundPop() {
      let data = {
        currentData: this.currentData,
        businessAuditLogId: this.businessAuditLogId,
      };
      this.$refs.confirmTheRefund.dialogVisible = true;
      this.$refs.confirmTheRefund.getcurrentData(data);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-flex {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

.cs-ml {
  margin-left: 40px;
}

.row-item {
  padding: 1% 0;
}

.address-info {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  background-color: #f6f9fc;
  color: #666;

  .th {
    font-weight: bold;
    color: #333;
  }

  td {
    flex-basis: auto;
  }
}
</style>
