<template>
    <div id="relevanceGoodsPop">
        <el-dialog title="关联商品" width="80%" :visible="dialogTableVisible" :before-close="dialogClose">
            <div class="operation">
                <el-button type="primary" @click="opendialogVisible(1)">上架</el-button>
                <el-button type="primary" @click="opendialogVisible(2)">下架</el-button>
                <el-button type="primary" @click="opendialogVisible(3)">售罄</el-button>
                <el-button type="primary" @click="opendialogVisible(4)">可售</el-button>

            </div>
            <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" @pageChange="loadTableData">
                <el-table-column fixed type="selection" width="50"></el-table-column>
                <el-table-column prop="goodsName" label="关联商品" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="salesState" label="上架状态" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.shelvesState=='1'?'上架':'下架'}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="shelvesState" label="销售状态" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.salesState=='normal'?'可售':scope.row.salesState}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="composition" label="库品组成" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="shelvesTime" label="上架时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="undercarriageTime" label="下架时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="operation" label="操作" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div :style="'color: rgba(24, 144, 255, 1);cursor: pointer;'" @click="toDetail(scope.row)">
                            {{ scope.row.operation }}
                        </div>
                    </template>
                </el-table-column>
            </ws-page-table>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span class="titleDia" v-if="dialogstate">已选商品中包含正在生效或未生效的定时商品<span v-for="(item,index) in selectedName" :key="index"> {{item}}、</span>操作后定时信息将会失效，请问是否继续变动选中商品状态为{{stateText}}？</span>
            <span class="titleDia" v-else>是否变动当前库品全部关联商品状态为{{stateText}}？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="operation">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
let moment = require("moment");
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
export default {
    props: {
        dialogTableVisible: Boolean,
        skuid: String,
    },
    data() {
        return {
            stateText: "",
            dialogstate: true,
            dialogVisible: false,
            searchInfo: {
                skuNum: "",
            },
            items: {
                skuNum: { filter: "like" },
            },
            selectedName: [],
        };
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData(skuid) {
            // console.log(this.pageInfo,this.searchConfig);
            if (skuid) {
                this.searchInfo.skuNum = skuid;
                WhmList.queryGoodsBySkuId(
                    this.pageInfo,
                    this.searchConfig
                ).then((data) => {
                    console.log(data);
                    this.querySuccess(data);
                    this.tableData = data.list.map((e) => {
                        return {
                            goodsId: e.goodsId,
                            goodsName: e.goodsName,
                            shelvesState: e.isShelves,
                            salesState: e.isSales,
                            composition: e.skuToNUm,
                            shelvesTime:
                                !e.beginTime?"-":
                                e.beginTime == 31507200000
                                    ? "-"
                                    : moment(e.beginTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      ),
                            undercarriageTime:
                                !e.endTime?"-":
                                e.endTime == 2145888000000
                                    ? "-"
                                    : moment(e.endTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      ),
                            operation: "编辑商品详情",
                            // timing:e.beginTime==31507200000?true:e.endTime==2145888000000?true:false
                        };
                    });
                });
            }
        },
        dialogClose() {
            this.$emit("update:vasible", false);
        },
        opendialogVisible(state) {
            this.state = state;
            if (state == 1) {
                this.stateText = "上架";
            } else if (state == 2) {
                this.stateText = "下架";
            } else if (state == 3) {
                this.stateText = "售罄";
            } else if (state == 4) {
                this.stateText = "可售";
            }
            let selected = this.$refs.pageTable.getSelectedData();

            //console.log(this.stateText,);
            if (selected && selected.length > 0) {
                let nowTime = new Date();
                let dialogstateTemp = false;
                for (const iterator of selected) {
                    if(iterator.undercarriageTime == '-' 
                        || nowTime<new Date(iterator.undercarriageTime)){
                           dialogstateTemp = true;
                           break
                    }
                }
                this.dialogstate = dialogstateTemp;
                this.dialogVisible = true;
                
                /*
                let year = newDate.getFullYear();
                this.dialogVisible = true;
                let status1 = selected.some((item) => item.shelvesTime != "-");
                let status2 = selected.some(
                    (item) => item.undercarriageTime == "-"
                );
                // console.log(selected);
                console.log(status1, status2);
                if (status1 || status2) {
                    this.dialogstate = true;
                } else {
                    this.dialogstate = false;
                }*/
                for (let i in selected) {
                    this.selectedName.push(selected[i].goodsName);
                }
            } else {
                this.$message({ type: "warning", message: "未选中商品" });
            }
        },
        operation() {
            let state = this.state;
            let selected = this.$refs.pageTable.getSelectedData();
            let arr = [];
            for (let i in selected) {
                arr.push(selected[i].goodsId);
            }
            if (arr && arr.length > 0) {
                if (state == 1) {
                    WhmList.goodsModifyOnShelf(arr).then((res) => {
                        this.$message({ type: "success", message: "操作成功" });
                        this.loadTableData(this.searchInfo.skuNum);
                    });
                }
                if (state == 2) {
                    WhmList.goodsModifyOffShelf(arr).then((res) => {
                        this.$message({ type: "success", message: "操作成功" });
                        this.loadTableData(this.searchInfo.skuNum);
                    });
                }
                if (state == 3) {
                    WhmList.goodsModifySellOut(arr).then((res) => {
                        this.$message({ type: "success", message: "操作成功" });
                        this.loadTableData(this.searchInfo.skuNum);
                    });
                }
                if (state == 4) {
                    WhmList.goodsModifyOnSale(arr).then((res) => {
                        this.$message({ type: "success", message: "操作成功" });
                        this.loadTableData(this.searchInfo.skuNum);
                    });
                }
                this.dialogVisible = false;
            }
        },
        toDetail(row) {
            if(row.salesState=='已失效'){
              return this.$message({ type: "warning", message: "已失效商品不可编辑" });
            }
            this.$store.commit("CLEAR_PRODUCT");
            this.$router.push(`/pim/productEdit/${row.goodsId}`);
        },
    },
};
</script>

<style scoped>
.operation {
    margin: 10px 0px;
}
#relevanceGoodsPop >>> .dialog-footer {
    display: flex;
    justify-content: center;
}
#relevanceGoodsPop >>> .titleDia {
    font-size: 16px;
    line-height: 26px;
}
</style>