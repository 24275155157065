import axiosHttp from "../axiosHttp";

export default {
    //baseUrl: '/bimEnum/',
    // 首页加载高二会员信息
    queryMemberList() {
        let url = "/bimMember/queryDistributorList?";
        return axiosHttp.GET(url);
    },
    //获取上级会员关联的下级会员
    getChildrenList(userId){
        return axiosHttp.GET(`/bimMember/queryDistributorListById/${userId}`)
    },
    //条件搜索会员信息
    queryUserByCondition(userParam){
        let url = "/bimMember/queryUserByCondition?";
        return axiosHttp.POST(url, userParam,false)
    },
    //详情
    queryDetails(userId){
        return axiosHttp.GET(`/bimMember/queryDetails/${userId}`)
    },
    //用户冻结操作
    lockAccount(userId){
        return axiosHttp.GET(`/dimDistributorAccount/lockAccount/${userId}`)
    },
    //用户解结操作
    unlockAccount(userId){
        return axiosHttp.GET(`/dimDistributorAccount/unlockAccount/${userId}`)
    }

}
