import axiosHttp from "../axiosHttp";

//经销商的干活
export default {
    queryDealerPages(pageInfo,searchConfig){
        let url = "/faDealerInfo/queryAtPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryByInfo(){ //查询经销商列表，不分页
        return axiosHttp.POST('/faDealerInfo/queryByInfo',{});
    },
    //faDealerInfo/save
    saveDealer(params){ //保存经销商信息
        return axiosHttp.POST('/faDealerInfo/save',params,false);
    },
    //faDealerInfo/listForAgent
    listForAgent(){ //查询经销商折扣集（用于下拉选）
        return axiosHttp.POST('/faDealerInfo/listForAgent');
    },
    //faDealerInfo/findById/
    queryfaDealerInfo(searchConfig) {//根据经销商ID查询详情
        return axiosHttp.GET(`/faDealerInfo/findById/${searchConfig}`);
    },
    //faDealerInfo/findById/
    queryUserDealerInfo(searchConfig) {//根据经销商ID查询详情
        return axiosHttp.GET(`/faDealerInfo/findByUserId/${searchConfig}`);
    },
    //faDealerInfo/updLineOfCredit 修改经销商信用额度
    updLineOfCredit(params){
        return axiosHttp.POST('/faDealerInfo/updLineOfCredit',params,false);
    },
    //faDealerInfo/updBalanceNum 修改经销商余额
    updBalanceNum(params){
        return axiosHttp.POST('/faDealerInfo/updBalanceNum',params,false);
    },
    //faBillInfo/queryBillBySystem 查询经销商账单
    queryBillBySystem(params){
        return axiosHttp.POST('/faBillInfo/queryBillBySystem',params,false);
    },
    //faBillInfo/findById
    getFaBillInfo(id){//根据账单ID查询账单信息
        return axiosHttp.GET(`/faBillInfo/findById/${id}`);
    },
    editFaBill(param){//根据账单ID查询账单信息
        return axiosHttp.POST(`/faBillInfo/editBill`,param);
    },
    //billAudit/auditFlowHandle账单审核
    //统一用新接口：baAudit/auditHandleNew，wanghb20221019
    auditFlowHandle(params){
        return axiosHttp.POST('/baAudit/auditHandleNew',params,false);
    },
    //faDealerInfo/findDealerLinesInfo
    findDealerLinesInfo(id){//查询经销商额度账单详情
        return axiosHttp.GET(`/faDealerInfo/findDealerLinesInfo/${id}`);
    },
    //faBillInfo/repaymentBill
    repaymentBill(params){
        return axiosHttp.POST('/faBillInfo/repaymentBill',params,false);
    },
    //faBillInfo/queryByBillToOrder //下载excel  
    queryByBillToOrder(searchConfig){
        return axiosHttp.GET(`/faBillInfo/queryByBillToOrder/${searchConfig}`);
    },
    queryBillByInfo(param){
        return axiosHttp.POST(`/faBillInfo/queryByInfo`,param);
    },
    rechargeDeposit(param){
        return axiosHttp.POST(`/faDealerInfo/rechargeDeposit`,param);
    },
    depositWithdrawApply(param){
        return axiosHttp.POST(`/faDealerInfo/depositWithdrawApply`,param);
    },
    listCreditDealer(){
        return axiosHttp.POST(`/faDealerInfo/listCreditDealer`, {});
    },
    appointDealerGenerateBill(param){
        return axiosHttp.POST(`/faDealerInfo/appointDealerGenerateBill`,param);
    },
    //faDealerLog/queryAtPage
    queryDealerLog(pageInfo,searchConfig){
        let url = "/faDealerLog/queryAtPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    checkPayState(outTradeNo){
        let url = '/faDealerLog/checkPayState';
        const params={
            "outTradeNo":outTradeNo
        };
        return axiosHttp.POST(url,params,false);
    },
    getLastPaymentBillTimeOnContinue(){
        let url = '/faBillInfo/getLastPaymentBillTimeOnContinue';
        return axiosHttp.GET(url);
    },
}