<template>
  <div class="example-popup">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="554px"
    >
      <div class="example-content">
        <p class="tips">{{tips}}</p>
        <img :src="imgUrl" alt />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    tips: String,
    imgUrl: String
  },
  data() {
    return {
      dialogVisible: false
    };
  }
};
</script>

<style scoped lang="scss">
.example-popup /deep/ {
  .el-dialog__header {
    display: block !important;
  }
  .el-dialog__footer {
    text-align: right;
  }
}
.example-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tips {
    color: #666;
    font-size: 14px;
    align-self: flex-start;
  }
  > img {
    width: 500px;
    margin-top: 14px;
  }
}
</style>