<template>
    <el-dialog title="微信待支付信息" :visible="dialogVisible" v-loading="showLoading" element-loading-text="拼命加载中..."
               top="5%" @close="closeDialog" :close-on-click-modal="false" width="900px">
        <div>
            <table class="ws-gridtable ws-3cloumn">
                <tr v-for="row in rowTable">
                    <template v-for="col in row">
                        <th>{{col.title}}</th>
                        <template v-if="col.type=='img'&&col.text!=''"><td ><a :href="col.text" target="_blank"><img :src="col.text"  class="photoSize"/></a></td></template>
                        <template  v-else><td>{{col.text}}</td></template>
                    </template>
                </tr>
            </table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click.native="payByWechat">微信支付</el-button>
            <el-button @click.native="closeDialog">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>

    import tools from "COMJS/tools";
    import dimWithdrawCashService from "API/dim/DimWithdrawCashService";

    export default {
        name: "WithdrawCashPayByWechatView",
        props:{
          enumMap:{},
        },
        data(){
            return{
                rowTable:{},
                withdrawCashInfo:{},
                dialogVisible: false,
                showLoading: false
            }
        },
        beforeCreate(){

        },
        mounted(){

        },
        methods:{
            display: function (withdrawCash) {
                this.dialogVisible = true;
                this.withdrawCashInfo = withdrawCash;
                this.createTdTable();
            },
            //关闭弹窗
            closeDialog: function () {
                this.dialogVisible = false;
                this.withdrawCashInfo = {};
            },
            createTdTable(){
                let rows=[];
                let cols=[];
                let colCount=3;
                cols.push({title:'提现编码',text:this.withdrawCashInfo.withdrawCashCode});
                cols.push({title:'提现类型',text:this.withdrawCashTypeFormatter(this.withdrawCashInfo.withdrawCashType)});
                cols.push({title:'申请提现金额',text:this.withdrawCashInfo.withdrawCashAmount});
                cols.push({title:'申请者账号',text:this.withdrawCashInfo.distributorAccount});
                cols.push({title:'申请者姓名',text:this.withdrawCashInfo.distributorName});
                cols.push({title:'申请提现时间',text:this.datetimeFormatter(this.withdrawCashInfo.applyTime, "datetime")});
                cols.push({title:'提现方式',text:this.withdrawCashWayFormatter(this.withdrawCashInfo.withdrawCashWay)});

                if('wechat'==this.withdrawCashInfo.withdrawCashWay){
                    cols.push({title:'APPID',text:this.withdrawCashInfo.appId});
                    cols.push({title:'OPENID',text:this.withdrawCashInfo.openId});
                }
                for(let i=0;i<cols.length;i++){
                    if(i%colCount==0){
                        rows.push([]);
                    }
                    rows[rows.length-1].push(cols[i]);
                }
                this.rowTable = rows;
            },
            payByWechat(){
                let that = this;
                let withdrawCash = that.withdrawCashInfo;
                this.showLoading = true;
                let id = {'dimWithdrawCashId':withdrawCash.dimWithdrawCashId};
                console.log(id);
                dimWithdrawCashService.doWechatCashById(id).then((data)=>{
                    console.log(data);
                    // 返回异常则显示异常信息.正常则变更支付状态
                    if('fail'==data.return_code){
                        that.$alert(data.return_msg, '系统提示');
                        // 加载遮罩去除
                        this.showLoading = false;
                        return;
                    }

                    let id = withdrawCash.dimWithdrawCashId;
                    let idList = [id];
                    dimWithdrawCashService.updateHandleState(idList).then(()=>{
                        that.closeDialog();
                        // 重新加载微信待支付列表
                        this.$emit('payDone');
                        // 加载遮罩去除
                        this.showLoading = false;
                    }).catch(err=>{
                        // 加载遮罩去除
                        this.showLoading = false;
                    });
                }).catch(err=>{
                    //todo 加载遮罩去除
                    this.showLoading = false;
                });
            },

            //提现方式格式化
            withdrawCashWayFormatter(withdrawCashWay){
                let v = '-';
                if(withdrawCashWay){
                    v = withdrawCashWay;
                    if('wechat'==withdrawCashWay){
                        v = '微信';
                    }
                    if('bank'==withdrawCashWay){
                        v = '银行';
                    }
                }
                return v;
            },
            //提现类型类型格式化
            withdrawCashTypeFormatter(withdrawCashType){
                let v = '-';
                if(withdrawCashType){
                    v = withdrawCashType;
                    if(withdrawCashType){
                        v = withdrawCashType;
                        if('bondCash'==withdrawCashType){
                            v = '余额提现';
                        }
                        if('profitCash'==withdrawCashType){
                            v = '服务费提现';
                        }
                    }
                }
                return v;
            },
            datetimeFormatter(date){
                let v = "";
                if (date) {
                    v = tools.formatDate(new Date(date), "yyyy-MM-dd hh:mm:ss");
                }
                return v;
            },
        }
        ,watch:{
            withdrawCashInfo:{
                immediate:true,
                handler(val,oldVal) {
                    this.createTdTable();
                }
            }
        }

    }
</script>

<style scoped>
</style>
