import { render, staticRenderFns } from "./receiveList.vue?vue&type=template&id=a3a89fd4"
import script from "./receiveList.vue?vue&type=script&lang=js"
export * from "./receiveList.vue?vue&type=script&lang=js"
import style0 from "./receiveList.vue?vue&type=style&index=0&id=a3a89fd4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports