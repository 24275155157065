<template>
  <div>

    <div>
      <span style="font-size: 18px;" v-if="goodsPageInfo.eventName">活动名称：</span>
      <span style="font-size: 18px;">{{ goodsPageInfo.eventName }}</span><br><br>
      <el-button type="primary" @click="addGoods">新增商品</el-button>
    </div>
    <div class="info">
      <div>商品名称、商品编码：</div>
      <div>
        <el-input v-model="goodsPageInfo.keyWorld" placeholder="商品名称、商品编码" type="text" size="small"></el-input>
      </div>
      <div>
        <el-button type="primary" @click="getEventGoodsList">搜索</el-button>
      </div>
    </div>
    <el-table :data="eventGoodsList" border>
      <el-table-column label="商品信息" min-width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div style="display: flex;">
            <img width="50px" height="50px" :src="scope.row.pictureUrl">
            <span style="margin-left: 10px;">{{ scope.row.cnName }}</span>
            <span style="margin-left: 10px;">{{ scope.row.goodsCode }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="原价" prop="initPrice" width="150"></el-table-column>
      <el-table-column label="活动价" prop="l0CostPrice" width="150"/>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <div class="icon-operation" style="display: flex;justify-content: space-around;">
            <a href="javascript: void(0);" style="margin-right: 0px !important;" @click="goDetail(scope.row)"
               title="编辑">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <el-popconfirm style="margin-left:0;" title="确定删除吗？" @confirm="delPriceEventGoods(scope.row.eventGoodsId)">
              <el-button>删除</el-button>
              <a slot="reference" href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid"></i>
              </a>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination background layout="prev, pager, next" :total="goodsPageInfo.total" @current-change="changePage">
    </el-pagination>

    <select-goods-popup ref="selectGoodsPopup" @submit="commitChooseGoods"></select-goods-popup>

    <el-dialog :visible.sync="checkGoodsDialogVisible"
               title="编辑商品价格活动" width="60%"
               @close="closeCheck">
      <el-table :data="checkGoodsList" border style="margin-top: 20px;">
        <el-table-column label="商品信息" min-width="200" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div style="display: flex;">
              <img width="50px" height="50px" :src="scope.row.pictureUrl">
              <span style="margin-left: 10px;">{{ scope.row.cnName }}</span>
              <span style="margin-left: 10px;">{{ scope.row.goodsCode }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="原价" prop="initPrice" width="150"></el-table-column>
        <el-table-column label="活动价" width="150">
          <template slot-scope="scope">
            <el-input maxlength="8"
                      @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l0CostPrice)"
                      v-model="checkGoodsList[scope.$index].l0CostPrice"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <i class="el-icon-circle-close" style="font-size: 25px; cursor: pointer;"
               @click="delChooseGoods(scope.$index)"></i>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCheck">取 消</el-button>
        <el-button type="primary" @click="commitCheckGoods">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import selectGoodsPopup from "../product/spu/selectGoodsPopup"

import {
  fetchEventGoods,
  addEventGoods,
  delEventGoods,
  editEventGoods
} from '@/api/pim/PimPriceEvent.js'

export default {
  components: {
    selectGoodsPopup,
  },
  data() {
    return {
      eventGoodsList: [],
      goodsPageInfo: {
        pageSize: 10,
        pageNo: 1,
        eventId: "",
        total: 0,
        keyWorld: "",
      },
      checkGoodsList: [],
      checkGoodsDialogVisible: false,
      createNewFlag: false,
    }
  },
  methods: {
    async getEventGoodsList() {
      let data = await fetchEventGoods(this.goodsPageInfo)
      this.eventGoodsList = data.list
      this.goodsPageInfo.total = data.total
    },
    changePage(e) {
      this.goodsPageInfo.pageNo = e
      this.getEventGoodsList();
    },
    async delPriceEventGoods(id) {
      await delEventGoods(id)
      await this.getEventGoodsList()
    },
    goDetail(item) {
      this.checkGoodsList = []
      this.checkGoodsList.push(item)
      this.checkGoodsDialogVisible = true
      this.createNewFlag = false
    },

    addGoods() {
      this.$refs.selectGoodsPopup.dialogVisible = true;
    },
    commitChooseGoods(arr) {
      let addItems = arr.map(item => {
        return {
          goodsId: item.goodsId,
          cnName: item.cnName,
          goodsCode: item.goodsCode,
          pictureUrl: item.pictureUrl,
          initPrice: item.initPrice
        }
      })
      this.checkGoodsList = []
      this.checkGoodsList = addItems
      this.checkGoodsDialogVisible = true
      this.createNewFlag = true
    },

    async commitCheckGoods() {
      if(this.checkGoodsList.length>0){
        this.checkGoodsList.map(item => {
          item.l0Visible = 1
          return item
        })
        if (this.createNewFlag) {
          let params = {
            eventId: this.goodsPageInfo.eventId,
            eventGoodsList: this.checkGoodsList,
          }
          await addEventGoods(params)
          await this.getEventGoodsList()
        } else {
          let item = this.checkGoodsList[0]
          await editEventGoods(item)
          await this.getEventGoodsList()
        }
      }
      this.checkGoodsDialogVisible = false
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
          /^\d*\.\d*\./g,
          e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      this.input = e.target.value;
    },
    delChooseGoods(i) {
      this.checkGoodsList.splice(i, 1)
    },

    closeCheck() {
      this.checkGoodsDialogVisible = false
      this.createNewFlag = false
      this.checkGoodsList = []
    },
  },

  mounted() {
    this.goodsPageInfo.eventId = this.$route.query.id
    this.goodsPageInfo.eventName = this.$route.query.eventName
    if (this.goodsPageInfo.eventId) {
      this.getEventGoodsList()
    }
  },
}
</script>

<style scoped>
.icon-operation i {
  color: #1B73E8;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
}

.info > div:nth-child(2) {
  width: 200px;
}

.info > div:nth-child(3) {
  margin-left: 10px;
}
</style>
