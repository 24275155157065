<template>
    <div>
        <div class="account-detail">
            <div class="detail-item box-shadow-unify" style="padding-left: 10px;padding-top: 10px">
            <el-form label-width="80" inline>
                <el-form-item class="form-label " label="通知类型:">
                <el-select v-model="searchInfo.noticeType" placeholder="请选择通知类型">
                    <el-option
                            v-for="item in sourceList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    ></el-option>
                </el-select>
                </el-form-item>
                <el-form-item class="form-label right" label="通知内容:">
                    <el-input style="width: 172px" v-model="searchInfo.noticeTitleOrContent" placeholder="请输入通知标题或内容" />
                </el-form-item>
                <div>
                    <el-form-item  class="form-label" label="会员账号:">
                        <el-input  style="width: 173px" v-model="searchInfo.userAccount" placeholder="请输入会员账号" />
                    </el-form-item>
                    <el-form-item class="form-label right " label="通知状态:">
                    <el-select v-model="searchInfo.noticeState" placeholder="请选择通知状态">
                        <el-option
                                v-for="item in stateList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>
                    </el-form-item>
                </div>
                <div style="padding-bottom: 10px">
                    <el-button type="primary" size="mini" @click="reSetPage">搜索</el-button>
                </div>

            </el-form>
            </div>
        </div>
        <div class="detail-item box-shadow-unify">
            <div style="padding-top: 15px; margin-left: 10px;padding-bottom: 5px">
                <el-button size="mini" type="primary" @click="showAdd">新增通知</el-button>
            </div>
        <ws-page-table  :data="tableData"
                        ref="pageTable"
                        :pageable="true"
                        :pageInfo="pageInfo"
                        @pageChange="loadTableData"
                        :showIndex="true"
                        :isShadow="false">
            <el-table-column prop="sendTime" label="通知时间"></el-table-column>

            <el-table-column prop="noticeType" label="通知类型"></el-table-column>
            <el-table-column prop="noticeTitle" label="通知标题" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="noticeContent" label="通知内容" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="recipient" label="会员账号" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="noticeState" label="通知状态"></el-table-column>
            <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                    <div class="icon-operation">
                        <a href="javascript: void(0);" title="查看" @click="toDetail(scope.row.noticeId)">
                            <i  class="CCSFont icon-table_2"></i>
                        </a>
                        <a href="javascript: void(0);" title="撤销" @click="revocation(scope.row)">
                            <i style="font-size: 17px; font-weight:bold;" class="el-icon-refresh-left"></i>
                        </a>
                    </div>
                </template>
            </el-table-column>
        </ws-page-table>
        <notice-add-popup ref="noticeAddPopup" @updateTable="loadTableData"></notice-add-popup>
        <notice-details-popup ref="noticeDetailsPopup" :noticeId="currentId"></notice-details-popup>
        </div>
    </div>
</template>

<script>
    import pageTableMixin from "@/mixin/pageTableMixin";
    import BimUserNoticeService from "../../../api/bim/BimUserNoticeService";
    import tools from "COMJS/tools";
    import translate from "ASSETS/js/translate";
    import noticeAddPopup from "../../bim/notice/noticeAddPopup";
    import noticeDetailsPopup from "../../bim/notice/noticeDetailsPopup";

    export default {
        data() {
            return {
                currentId: "",
                tableData: [],
                searchInfo:{
                    noticeState:"",
                    userAccount:"",
                    noticeTitleOrContent:"",
                    noticeType:"",
                },
                sourceList: [
                    {
                        value: "",
                        label: "全部通知"
                    },
                    {
                        value: "system_notice",
                        label: "系统通知"
                    },
                    {
                        value: "order_notice",
                        label: "订单通知"
                    },
                    {
                        value: "audit_notice",
                        label: "审核通知"
                    },
                    {
                        value: "activity_notice",
                        label: "活动通知"
                    }
                ],
                stateList: [
                    {
                        value: "",
                        label: "全部状态"
                    },
                    {
                        value: "0",
                        label: "未发送"
                    },
                    {
                        value: "1",
                        label: "已发送"
                    },
                    {
                        value: "2",
                        label: "已撤销"
                    }
                ],
            }
        },
        components: {
            noticeAddPopup,
            noticeDetailsPopup
        },
        methods: {
            loadTableData() {
                this.$refs.pageTable.tableLoading = true;
                BimUserNoticeService.queryPageList(this.queryParams).then(data => {
                    data.list = data.list.map(e => {
                        e.sendTime = tools.formatDate(
                            new Date(e.sendTime),
                            "yyyy-MM-dd hh:mm:ss"
                        );
                        e.noticeState = translate.getNoticeState(e);
                        e.noticeType = translate.getNoticeType(e);
                        e.recipient = translate.getRecipient(e);
                        return e;
                    });
                    this.querySuccess(data);
                });
            },
            toDetail(id){
                this.currentId = id;
                this.$refs.noticeDetailsPopup.dialogVisible = true;
            },
            showAdd() {
                this.currentId = "";
                this.$refs.noticeAddPopup.dialogFormVisible = true;
            },
            revocation(row){
                if(!(row.noticeState=="未发送"&&row.isDefaultTime=="0")){
                    return this.$message({
                        message: "只有未发送的定时通知允许被撤销",
                        type: "warning"
                    });
                    return;
                }
                this.$confirm('此操作将撤销通知, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    BimUserNoticeService.revokeNotice(row.noticeId).then(res => {
                        this.loadTableData();
                    });
                    this.$message({
                        type: 'success',
                        message: '撤销成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '撤销取消'
                    });
                });
            },
            reSetPage(){
                this.pageInfo.pageNo=1;
                this.loadTableData();
            }
        },
        mixins: [
            pageTableMixin
        ]
    }
</script>


<style lang="scss">
    .account-detail {
        .detail-item {
            margin-bottom: 40px;
            .detail-item_title {
                line-height: 60px;
                font-weight: bold;
                font-size: 18px;
                padding-left: 20px;
                background-color: #f6f9fc;
            }
            .detail-form {
                font-size: 14px;
                line-height: 20px;
                padding: 30px 20px 20px 0;
                .form-item {
                    margin-bottom: 14px;
                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                    .form-label {
                        display: inline-block;
                        text-align: right;
                        width: 150px;
                    }
                    .form-value {
                        display: inline-block;
                        color: #666;
                        width: 100px;
                    }
                }
            }
            .right{
                margin-left: 50px;
            }
        }
    }
</style>