<template>
    <div id="addItemSetPop">
        <el-dialog title="" :showClose="false" :visible.sync="dialogVisible" width="30%">
            <div class="content">
                <div class="header">
                    <div class="header-left">
                        <img src="../../../assets/images/title_Icon.png">
                        <p>标题</p>
                        <el-input style="width:70%" v-model="title"></el-input>
                    </div>
                    <div class="header-right">
                        <i @click="dialogVisible = false" class="el-icon-close" style="font-size:20px;cursor: pointer;"></i>
                    </div>
                </div>
                <div class="list">
                    <div class="list-header">
                        <img src="../../../assets/images/guanlian_icon.png">
                        <p>关联项目</p>
                        <el-button type="primary" @click="addProject">添加项目</el-button>
                    </div>
                    <div class="list-project">
                        <ws-page-table :stripe='true' style="width:100%" height="300" :data="tableData" :pageInfo="pageInfo" ref="pageTable" @pageChange="loadTableData">
                            <el-table-column label="关联的项目" :show-overflow-tooltip="true">
                                <template slot="header">
                                    <span style="font-size:12px;color:rgba(153, 153, 153, 1)">关联的项目</span>
                                </template>
                                <template slot-scope="scope">
                                    <span style="font-size:12px;color:rgba(102, 102, 102, 1)">{{scope.row.title}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :show-overflow-tooltip="true">
                                <template slot-scope="scope">
                                    <span style="font-size:12px;color:rgba(102, 102, 102, 1)">{{scope.row.beginTime}} - {{scope.row.endTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <span @click="delItem(scope.$index)" style="font-size:12px;color:rgba(247, 82, 82, 1);cursor: pointer;">取消关联</span>
                                </template>
                            </el-table-column>
                        </ws-page-table>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveItem">保存</el-button>
            </span>
        </el-dialog>
        <relatedProjectsPop ref="openDialog" @selected='selectData'></relatedProjectsPop>
    </div>
</template>

<script>
import PmBrandService from "API/pm/PmBrandService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import relatedProjectsPop from "./relatedProjectsPop.vue";
export default {
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            title: "",
        };
    },
    components: {
        relatedProjectsPop,
    },
    mounted() {
        this.Dialog();
        //:before-close="handleClose"
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {},
        Dialog() {
            this.$on("openDialog", (data) => {
                console.log(data);
                this.dialogVisible = data.dialogVisible;
                if (data.projectData) {
                    this.title = data.projectData.title;
                    this.projectData = data.projectData;
                    this.tableData = data.projectData.pmProjectList;
                } else {
                    this.title = "";
                    this.projectData = [];
                    this.tableData = [];
                }
            });
        },
        addProject() {
            this.$refs.openDialog.$emit("openDialog", true);
        },
        selectData(selectData) {
            console.log(selectData);
            if (selectData) {
                for (let i in selectData) {
                    if (
                        this.tableData.findIndex(
                            (item) => item.projectId == selectData[i].projectId
                        ) == -1
                    ) {
                        this.tableData.push(selectData[i]);
                    }
                }
            }
        },
        saveItem() {
            let projectData = this.projectData;
            let tableData = this.tableData;
            let projectGroupRelas = [];
            for (let i in tableData) {
                let params = {
                    projectGroupRelaId: tableData[i].projectGroupRelaId
                        ? tableData[i].projectGroupRelaId
                        : "",
                    projectId: tableData[i].projectId ? tableData[i].projectId : "",
                };
                projectGroupRelas.push(params);
            }
            let params = {
                projectGroupId: projectData.projectGroupId ? projectData.projectGroupId : "",
                title: this.title,
                projectGroupRelas: projectGroupRelas,
            };
            PmBrandService.save(params).then((data) => {
                this.dialogVisible=false;
                this.$message({ type: "success", message: "保存成功" });
                this.$emit('success',true)
            });
        },
        delItem(index){
            this.$confirm(`是否取消关联?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.tableData.splice(index,1);
            })
            
        }
    },
};
</script>

<style scoped>
#addItemSetPop >>> .el-dialog__header {
    border: none;
    display: none;
}
#addItemSetPop >>> .el-dialog__body {
    padding: 0;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    height: 100%;
    border-bottom: 1px solid rgba(166, 166, 166, 1);
    padding: 17px 11px 14px 10px;
}
.header-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.header-left > img {
    width: 14px;
    height: 14px;
}
.header-left > p {
    width: 40px;
    margin: 0px 10px;
}
.list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 18px 26px 24px 16px;
    width: 94%;
    height: 100%;
}

.list-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.list-header > img {
    width: 12px;
    height: 10.4px;
}
.list-header > p {
    margin: 0px 10px;
}
.list-project {
    width: 100%;
    height: 100%;
    padding-top: 20px;
}
</style>