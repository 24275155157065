<template>
  <el-dialog title="查看通知" :visible.sync="dialogVisible" width="700px" v-loading="loading">
    <el-form label-width="120px">
      <el-form-item label="发送时间：">
        <span>{{info.sendTime}}</span>
      </el-form-item>
      <el-form-item label="发送账号：">
        <span>{{info.recipient}}</span>
      </el-form-item>
      <el-form-item label="通知类型：">
        <span>{{info.noticeType}}</span>
      </el-form-item>
      <el-form-item label="通知标题">
        <span>{{info.noticeTitle}}</span>
      </el-form-item>
      <el-form-item label="通知内容：">
        <span>{{info.noticeContent}}</span>
      </el-form-item>
      <el-form-item label="发送状态：">
        <span>{{info.noticeState}}</span>
      </el-form-item>
      <el-form-item>
        <div class="image-list">
          <div class="image-item" v-for="(item, index) in imgList" :key="index">
            <!-- <img :src="item" alt /> -->
            <el-image
                    style="width: 82px; height: 82px"
                    :src="item"
                    :preview-src-list="imgList"
            ></el-image>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <!--
      <el-button type="primary" v-if="info.noticeState=='0'" @click="sendNotice">发 送</el-button>
      -->
    </div>
  </el-dialog>
</template>

<script>
import BimUserNoticeService from "../../../api/bim/BimUserNoticeService";
import tools from "COMJS/tools";
import translate from "ASSETS/js/translate";

export default {
    props: {
        noticeId: String
    },
    watch: {
        dialogVisible() {
            if (this.noticeId && this.dialogVisible) {
                this.getNoticeDetails();
            }
        }
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            info: {},
            imgList: [],
        };
    },
    methods: {
        getNoticeDetails() {
            this.info = {};
            this.loading = true;
            BimUserNoticeService.getNoticeDetails(this.noticeId).then(data => {
                data.sendTime = tools.formatDate(
                    new Date(data.sendTime),
                    "yyyy-MM-dd hh:mm:ss"
                );
                data.noticeState = translate.getNoticeState(data);
                data.noticeType = translate.getNoticeType(data);
                data.recipient = translate.getRecipient(data);
                this.info = data;
                this.imgList = data.picList.map(e => {
                    return e.picUrl;
                });

                this.loading = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
  .el-form /deep/ .el-form-item__label {
    font-size: 16px;
  }

  .el-form /deep/ .el-form-item__content {
    font-size: 16px;
  }

  .image-list {
    display: inline-block;
    vertical-align: top;

    .image-item {
      display: inline-block;
      margin-right: 10px;
      img {
        width: 82px;
        height: 82px;
        object-fit: fill;
      }
    }
  }

</style>