import axiosHttp from "../axiosHttp"

export default {
  //保存/修改  活动
  save(params) {
    return axiosHttp.POST('/campaignInfo/save', params)
  },
  findById(id) {
    return axiosHttp.GET(`/campaignInfo/findById/${id}`)
  },
  queryByInfoAtPage(params) {
    return axiosHttp.POST('/campaignInfo/queryByInfoAtPage', params)
  },

  // 删除活动
  delByAcitivity(id) {
    return axiosHttp.GET(`/campaignInfo/delById/${id}`)
  },
  // 根据优惠券ID删除优惠券
  delById(id) {
    return axiosHttp.GET(`/campaignCoupon/delById/${id}`)
  },
  delByLimitId(id) {
    return axiosHttp.GET(`/campaignLimit/delByLimitId/${id}`)
  },
  listUserInDistributor() {
    return axiosHttp.GET(`bimUser/listUserInDistributor`)
  },
  skuInvMatrixingGoodsInv(params) {
    return axiosHttp.POST('/whmInventory/skuInvMatrixingGoodsInv', params)
  },
  
}