<template>
  <div>
    <div style="padding: 20px; background-color: white;">
      <div>
        <el-button size="primary" type="primary" @click="showAdd">新增</el-button>
      </div>
    </div>
    <ws-page-table :data="tableData" ref="pageTable" :pageable="false"
                   :pageInfo="pageInfo" @pageChange="loadTableData"
                   :showIndex="false" :isShadow="false">
      <el-table-column prop="userAccount" label="账号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="userName" label="姓名" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="role" label="角色" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="recordCount" label="预留商品数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="orderCode" label="操作" width="100px">
        <template slot-scope="scope">
          <div class="icon-operation" style="display: flex;justify-content: space-between;">
            <a href="javascript: void(0);" title="查看" style="margin-right: 0px !important;">
              <i class="CCSFont icon-table_2" @click="seeDetail(scope.row.userId)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <reserve-create-popup ref="reserveCreatePopup"
                             @updateTable="loadTableData"
                             :reserveId="''" :appointOperateType="'create'"></reserve-create-popup>
  </div>
</template>

<script>
import pimReserveService from "API/pim/PimReserveService"
import tableMixins from "@/mixin/pageTableMixin.js";
import reserveCreatePopup from "./reserveCreatePopup"
import sysUtil from "../../../constant/SysUtil"
export default {
  name: "reservePrice",
  mixins: [tableMixins],
  components: {
    reserveCreatePopup,
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    loadTableData() {
      let param = {
        ...this.pageInfo,
        userAccount: ''
      }
      pimReserveService.queryPage(param).then(data => {
        //console.log(data);
        data.list = data.list.map(e=>{
          return {
            ...e,
            role:this.translateRole(e.userRole)
          }
        })
        this.querySuccess(data)
      })
    },
    showAdd() {
      this.$refs.reserveCreatePopup.dialogFormVisible = true;
    },
    seeDetail(userId) {
      this.$router.push(`/pim/reservePriceDetail/${userId}`);
    },
    translateRole(role){
      if(sysUtil.isDistributor(role)){
        return "会员";
      }else if(sysUtil.isAgentDeposit(role) || sysUtil.isAgentCredit(role)){
        return "经销商";
      }else{
        return "未知"
      }
    }
  }
}
</script>

<style scoped>

</style>