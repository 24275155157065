<template>
  <div id="refusedToARefundPop">
    <el-dialog :title="popType=='audit'?'审核驳回':'拒绝退款'" :visible.sync="dialogVisible" width="30%">
      <el-input type="textarea" :rows="8" :placeholder="popType=='audit'?'审核原因':'拒绝原因'" v-model="auditRemark">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitReturn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import { auditHandle } from "API/som/checkService.js";
export default {
  data() {
    return {
      dialogVisible: false,
      auditRemark: "",
      currentData: {
        order: {},
      },
      businessAuditLogId: "",
      businessId: "",
      popType: "audit",
    };
  },
  methods: {
    getcurrentData(data) {
      console.log(data);
      this.currentData = data.currentData;
      this.businessAuditLogId = data.businessAuditLogId;
    },
    commitReturn() {
      if (!this.auditRemark) {
        return this.$message.warning(this.popType=='audit'?"请填写审核原因":'请填写拒绝原因');
      }
      let loading = this.$loading({
        lock: true,
        text: "提交中...",
      });
      try {
        let params = {
          userId: JSON.parse(userModule.PPUser).userId,
          businessAuditLogId: this.businessAuditLogId,
          auditResult: "fail",
          auditRemark: this.auditRemark,
        };
        auditHandle(params).then((data) => {
          loading.close();
          this.$emit("auditOk", "");
          this.dialogVisible = false;
          Object.assign(this.$data,this.$options.data())
        });
      } catch (error) {
        loading.close();
        this.$emit("auditOk", "");
        this.dialogVisible = false;
        Object.assign(this.$data,this.$options.data())
      }
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>