<template>
  <div class="product-add">
    <steps :active="0" :steps="steps"></steps>
    <el-form :model="productInfo" label-width="130px" ref="productInfoForm" class="step-form">
      <div class="step-wrapper base-from box-shadow-unify">
        <div class="step-title">基本信息</div>
        <el-form-item required label="商品名称：">
          <el-input v-model="productInfo.cnName" maxlength="100"></el-input>
          <!-- <el-input v-model="productInfo.topName" style="width: 150px;margin-right: 5px;"></el-input>
          <el-input v-model="productInfo.bottomName" style="width: 150px"></el-input>-->
          <div class="tips">用于消费端展示，最多100字</div>
        </el-form-item>
        <el-form-item label="库品名称：">
          <el-input v-model="productInfo.inventoryName"></el-input>
        </el-form-item>
        <el-form-item label="商品条码：">
          <el-input v-model="productInfo.goodsBarCode"></el-input>
        </el-form-item>
        <el-form-item label="库品条码：">
          <el-input v-model="productInfo.inventoryBarCode"></el-input>
        </el-form-item>
        <el-form-item required label="商品编号：" >
          <el-input  v-model="productInfo.goodsCode"  @blur="checkReapt(productInfo.goodsCode)"></el-input>
        </el-form-item>
        <el-form-item label="库品编号：">
          <el-input v-model="productInfo.inventoryCode"></el-input>
        </el-form-item>
        <el-form-item required label="商品类目：">
          <div v-if="!selectedCategory.selectedFirstCategory"
            class="select-btn"
            @click="$refs.selectCategoryPopup.dialogVisible = true"
          >请选择商品分类</div>
          <template v-else>
            <span>{{selectedCategory.selectedFirstCategory.nodeName || ''}}&nbsp;{{selectedCategory.selectedSecondCategory.nodeName || ''}}&nbsp;{{selectedCategory.selectedThirdCategory.nodeName || ''}}</span>
            <span
              class="tips-btn"
              style="dispaly: inline-block;margin-left: 15px;"
              @click="$refs.selectCategoryPopup.dialogVisible = true"
            >重选类目</span>
          </template>
        </el-form-item>
        <el-form-item required label="商品品牌：">
          <div
            class="select-btn"
            v-if="!selectedBrand.brandId"
            @click="$refs.selectBrandPopup.dialogVisible = true"
          >请选择商品品牌</div>
          <template v-else>
            <span>{{selectedBrand.brandName}}</span>
            <span
              class="tips-btn"
              style="dispaly: inline-block;margin-left: 15px;"
              @click="$refs.selectBrandPopup.dialogVisible = true"
            >重选品牌</span>
          </template>
        </el-form-item>
        <el-form-item label="分享描述：">
          <el-input v-model="productInfo.goodsShareDescribe"></el-input>
          <div class="tips">
            将商品分享到微信时，默认展示该标题，最多30字。未填写默认展示商品名称。
            <span
              class="tips-btn"
              @click="$refs.shareExamplePopup.dialogVisible = true"
            >查看示例</span>
          </div>
        </el-form-item>
      </div>

      <div class="step-wrapper">
        <div class="step-title">价格信息</div>
        <el-form-item required label="零售价：">
          <el-input  v-model="productInfo.retailPrice" @keyup.native='keyupEvent($event,productInfo.retailPrice)' maxlength="14">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item required label="底价：">
          <el-input  v-model="productInfo.floorPrice" @keyup.native="keyupEvent($event,productInfo.floorPrice)"
                    maxlength="14">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="划线价：">
          <el-input  v-model="productInfo.oldPrice" @keyup.native="keyupEvent($event,productInfo.oldPrice)"
                    maxlength="14">
            <template slot="prepend">￥</template>
          </el-input>
          <div class="tips">
            划线价用于商品详情的价格展示，不填写默认不展示。
            <span
              class="tips-btn"
              @click="$refs.priceExamplePopup.dialogVisible = true"
            >查看示例</span>
          </div>
        </el-form-item>
      </div>

      <div class="step-wrapper el-inline-radio">
        <div class="step-title">库存信息</div>
        <el-form-item required label="实际库存：">
          <el-input
              :min="0"
              @keyup.native="blurText($event)"
              v-model.number="productInfo.stockNum"
              maxlength="10"
          ></el-input>
          <div>
            <el-checkbox v-model="productInfo.isShowStockNum">商品详情是否显示库存数量</el-checkbox>
          </div>
          <div class="tips">买家看到的商品剩余库存位实时数据；库存为0时，商品自动变为「已售罄」状态</div>
        </el-form-item>
        <el-form-item label="预警库存：">
          <div>
            <el-radio v-model="productInfo.warningType" label="1">设置具体数值</el-radio>
            <el-input
              v-model="productInfo.warningValue"
              :min="0"
              @keyup.native="blurText($event)"
              maxlength="11"
              v-if="productInfo.warningType == 1"
              class="span_line_input"
            ></el-input>
            <el-input :disabled="true" v-else></el-input>
          </div>
          <div style="margin-top: 10px;">
            <el-radio v-model="productInfo.warningType" label="2">设置比例</el-radio>
            <template v-if="productInfo.warningType == 2">
              <el-input
                @keyup.native="keyupEvent($event,productInfo.warningPercentage)"
                maxlength="5"
                v-model="productInfo.warningPercentage"
                style="width: 60px;"
                class="span_line_input"
              ></el-input>%
            </template>
            <el-input :disabled="true" v-else></el-input>
          </div>
        </el-form-item>
        <el-form-item label="入库规格：">
          <el-input v-model="productInfo.enterSpec"></el-input>
        </el-form-item>
        <el-form-item label="出库规格：">
          <el-input v-model="productInfo.outSpec"></el-input>
        </el-form-item>
      </div>
      <div class="step-wrapper">
        <div class="step-title">快递信息</div>
        <el-form-item required label="配送方式：">
          <span>快递发货</span>
        </el-form-item>
        <el-form-item required label="运费模版：">
          <el-select v-model="productInfo.transportId" placeholder="运费模版" style="width: 160px;">
            <el-option
                    v-for="item in transportTemplateList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
            ></el-option>
          </el-select>
          <!--
          <el-input v-model="productInfo.freight">
            <template slot="prepend">￥</template>
          </el-input>
          -->
          <!-- <el-input v-model="productInfo.freight"></el-input> -->
          <!--
          <div class="tips">请填写统一运费，包邮写0。多件商品同时结算，将取最大运费</div>
          -->
        </el-form-item>
      </div>
      <div class="step-wrapper">
        <div class="step-title">其他信息</div>
        <el-form-item required label="上架时间：">
          <!-- TODO -->
          <el-radio-group v-model="productInfo.upperShelfType">
            <el-radio :label="1">立即上架</el-radio>
            <div class="el-inline-radio">
              <el-radio :label="2">自定义上架时间</el-radio>
              <el-date-picker
                style="width: 200px;"
                v-model="productInfo.upperShelfTime"
                type="datetime"
                placeholder="选择日期时间"
              ></el-date-picker>
            </div>
            <el-radio :label="3">暂不售卖，放入仓库</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item required label="月销量：">
          <el-input
              v-model="productInfo.monthlySalesVolume"
              :min="0"
              @keyup.native="blurText($event)"
              maxlength="10"></el-input>
          <div class="tips">可设置商品初始月销量，上架后生效</div>
        </el-form-item>
      </div>
    </el-form>
    <div class="steps-btn-wrapper">
      <el-button type="primary" @click="nextStep">完成，下一步</el-button>
    </div>
    <select-category-popup ref="selectCategoryPopup" @submit="selectCategory"></select-category-popup>
    <select-brand-popup ref="selectBrandPopup" @submit="selectBrand"></select-brand-popup>
    <example-popup
      ref="shareExamplePopup"
      title="查看分享示例"
      tips="将商品再微信分享给朋友时，该处填写的内容会展示在商品名称下面"
      :imgUrl="require('ASSETS/images/share-example.png')"
    ></example-popup>
    <example-popup
      ref="priceExamplePopup"
      title="查看划线价示例"
      tips="划线价在商品详情中显示示例："
      :imgUrl="require('ASSETS/images/price-example.png')"
    ></example-popup>
  </div>
</template>

<script>
import PimBrandService from "API/pim/PimBrandService";
import PimCategoryService from "API/pim/PimCategoryService";
import SearchConfig from "COMJS/query/SearchConfig";
import selectCategoryPopup from "./selectCategoryPopup";
import examplePopup from "COMPS/widgets/examplePopup";
import selectBrandPopup from "./selectBrandPopup";
import PimGoodsService from "API/pim/PimGoodsService";
import PimTransportService from "API/pim/PimTransportService";
import tools from "COMJS/tools.js";
import steps from "COMPS/widgets/steps";

export default {
  name: "productAdd",
  data() {
    return {
      steps: [
        {
          name: "添加信息"
        },
        {
          name: "添加属性"
        },
        {
          name: "添加图片"
        }
      ],
      productInfo: {
        shopId: "",
        categoryId: "",
        cnName: "", // 商品名称*3
        // topName: "",
        // bottomName: "",
        inventoryName: "", // 库品名称
        goodsBarCode: "", // 商品条码
        inventoryBarCode: "", // 库品条码
        goodsCode: "", // 商品编号
        inventoryCode: "", // 库品编号
        brandId: "", // 品牌id
        goodsShareDescribe: "", // 分享描述
        retailPrice: "", // 零售价
        floorPrice: "", // 底价
        oldPrice: "", // 划线价
        stockNum: "", // 实际库存
        isShowStockNum: true, // 是否显示库存数量
        warningValue: "", // 预警值
        warningPercentage: "", // 预警百分比
        warningType: "1", // 预警方式  1：数字，2百分比
        enterSpec: "", // 入库规格
        outSpec: "", // 出库规格
        monthlySalesVolume: "", // 月销量
        upperShelfType: 2, // 上架类型
        upperShelfTime: new Date(), // 上架时间
        freight: "", // 快递费
        transportId: "" //运费模版
      },
      selectedBrand: {
        brandId: "",
        brandName: ""
      },
      selectedCategory: {},
      transportTemplateList:[]
    };
  },
  components: {
    selectCategoryPopup,
    examplePopup,
    selectBrandPopup,
    steps
  },
  mounted() {
    this.getTransportTemplateList();
    this.addGoOn();
    // if (this.$route.params.id) {
    //   this.getGoodsDetail();
    // } else {
    //   this.addGoOn();
    // }
  },
  beforeDestroy() {
    this.productInfo.selectedBrand = this.selectedBrand;
    this.productInfo.selectedCategory = this.selectedCategory;
    this.$store.commit("saveProductAddInfo", this.productInfo);
  },
  computed: {
    brandSearchConfig() {
      return SearchConfig.createNew({
        searchForm: {
          brandName: this.selectedBrand.brandName
        }
      });
    }
  },
  methods: {
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中..."
      });
      PimGoodsService.getGoodsDetail(this.$route.params.id).then(data => {
        this.productInfo.shopId = data.shopId;
        this.productInfo.goodsType = data.goodsType;
        this.productInfo.categoryId = data.categoryId;
        this.productInfo.cnName = data.cnName;
        // this.productInfo.topName = data.goodsInfo.topName;
        // this.productInfo.bottomName = data.goodsInfo.bottomName;
        this.productInfo.inventoryName = data.goodsInfo.inventoryName;
        this.productInfo.goodsBarCode = data.goodsInfo.goodsBarCode;
        this.productInfo.inventoryBarCode = data.goodsInfo.inventoryBarCode;
        this.productInfo.goodsCode = data.goodsCode;
        this.productInfo.inventoryCode = data.goodsInfo.inventoryCode;
        this.productInfo.brandId = data.brandId;
        this.productInfo.goodsShareDescribe = data.goodsInfo.goodsShareDescribe;
        this.productInfo.retailPrice = data.initPrice;
        this.productInfo.old_retailPrice = data.initPrice; // 用于比对价格
        this.productInfo.floorPrice = data.basePrice;
        this.productInfo.oldPrice = data.markingPrice;
        this.productInfo.stockNum = data.goodsSku.skuNum;
        this.productInfo.isShowStockNum = data.goodsSku.showSku == 1 ? true : false;
        this.productInfo.warningValue = data.goodsSku.warningInventory;
        this.productInfo.warningPercentage = data.goodsSku.warningInventoryPer;
        this.productInfo.warningType = data.goodsSku.warningWay;
        this.productInfo.enterSpec = data.goodsSku.inboundStandards;
        this.productInfo.outSpec = data.goodsSku.outboundStandards;
        this.productInfo.monthlySalesVolume = parseInt(data.goodsSku.salePerMonth) || "";
        if (!data.effectiveTime) {
          this.productInfo.upperShelfType = 3;
          this.productInfo.old_upperShelfType = 3; // 用于比对上下架
        } else if (data.isEffective == 1) {
          this.productInfo.upperShelfType = 1;
          this.productInfo.old_upperShelfType = 1;
        } else {
          this.productInfo.upperShelfType = 2;
          this.productInfo.old_upperShelfType = 2;
        }
        this.productInfo.upperShelfTime = data.effectiveTime
          ? new Date(data.effectiveTime)
          : "";
        this.productInfo.transportId = data.goodsInfo.transportId;

        let _attrList = data.attributeList.map(e => {
          return {
            name: e.cnName,
            content: e.attrValue,
            isEnable: e.isUsable == 1 ? true : false,
            state: e.state,
            id: e.attributeId
          };
        });
        _attrList.sort(this.compare("dispNo"));
        let productImgs = {
          rotationImgs: [],
          videos: [],
          detailImgs: []
        };
        data.photoAlbums.sort(this.compare("position"));
        data.photoAlbums.forEach(e => {
          if (e.photoType == "sow") {
            productImgs.rotationImgs.push({
              response: e.photoUrl,
              url: e.photoUrl,
              name: e.photoName,
              position: e.position
            });
          }
          if (e.photoType == "detail") {
            productImgs.detailImgs.push({
              response: e.photoUrl,
              url: e.photoUrl,
              name: e.photoName,
              position: e.position
            });
          }
        });
        productImgs.shareImgs = data.goodsInfo.sharePictureUrl
          ? [
              {
                response: data.goodsInfo.sharePictureUrl,
                url: data.goodsInfo.sharePictureUrl
              }
            ]
          : [];
        productImgs.videos = data.videos.map(e => {
          return {
            response: e.videoUrl,
            url: e.videoUrl,
            name: ""
          };
        });
        if (data.brandId) {
            this.selectedBrand.brandId = data.brandId;
            this.selectedBrand.brandName = data.brandName;
        }
        if (data.categoryId) {
            this.productInfo.categoryId = data.categoryId;
            let selectedFirstCategory = {
                id: data.categoryId,
                nodeName: data.categoryName
            };
            this.selectedCategory = {
                selectedFirstCategory:selectedFirstCategory,
                selectedSecondCategory: {},
                selectedThirdCategory: {}
            };
        }
        this.$store.commit("saveProductAttrList", _attrList);
        this.$store.commit("saveProductAddInfo", this.productInfo);
        this.$store.commit("saveProductImgs", productImgs);
        loading.close();
      });
    },
    compare(property) {
      return function(obj1, obj2) {
        var value1 = obj1[property];
        var value2 = obj2[property];
        return value1 - value2; // 升序
      };
    },
    keyupEvent(e,input){
        e.target.value=e.target.value.replace(/[^\d.]/g, '');
        e.target.value=e.target.value.replace(/\.{2,}/g, '.');
        e.target.value=e.target.value.replace(/^\./g, '0.');
        e.target.value=e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0,e.target.value.length-1));
        e.target.value=e.target.value.replace(/^0[^\.]+/g, '0')
        e.target.value=e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
        this.input=e.target.value
        console.log(this.input)
    },

     blurText(e){
         let boolean = new RegExp("^[1-9][0-9]*$").test(e.target.value)
         if(!boolean){
             if(e.target.value.length==1){
                 e.target.value='';
             }else{
                 e.target.value = e.target.value.substr(0,e.target.value.length-1)
             }
         }
     },
    checkReapt(param){
        let params = {
            goodsId:this.$route.params.id,
            goodsCode:param
        }
        console.log(params)
        if(params.goodsCode!='') {
            PimGoodsService.queryGoodsCodeIsReapt(params).then(data => {
                console.log(data);
                if (data.code == 200) {
                    this.$alert('商品编号不允许重复,请重新输入', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'warning',
                                message:'商品编号不允许重复，请重新输入'
                            })
                        }
                    })
                }else{
                    this.$message({
                        type: 'success',
                        message:'商品编号可以使用'
                    })
                }
            });
        }
    },
      //输入正整数的方法
    // proving() {
    //     this.formData.bankName = this.formData.bankName.replace(/[^\.\d]/g,'');
    //     this.formData.bankName = this.formData.bankName.replace('.','');
    // },

    nextStep() {
      if (!this.productInfo.cnName) {
        return this.$message({
          message: "请填写商品名称",
          type: "warning"
        });
      }
      if (!this.productInfo.goodsCode) {
        return this.$message({
          message: "请填写商品编号",
          type: "warning"
        });
      }
      if (!this.productInfo.categoryId) {
        return this.$message({
          message: "请填写商品类目",
          type: "warning"
        });
      }
      if (!this.productInfo.brandId) {
        return this.$message({
          message: "请填写商品品牌",
          type: "warning"
        });
      }
      if (!this.productInfo.stockNum) {
        return this.$message({
          message: "请填写实际库存",
          type: "warning"
        });
      }
      if (this.productInfo.transportId === "") {
        return this.$message({
          message: "请运费模版",
          type: "warning"
        });
      }
      if (!this.productInfo.upperShelfType) {
        return this.$message({
          message: "请填写上架时间",
          type: "warning"
        });
      }
      if (!this.productInfo.monthlySalesVolume) {
        return this.$message({
          message: "请填写月销量",
          type: "warning"
        });
      }
      if (this.$route.params.id) {
        this.$router.push(`/pim/productAdd_step_2/${this.$route.params.id}`);
      } else {
        this.$router.push("/pim/productAdd_step_2");
      }
    },
    // 继续填写
    addGoOn() {
      let info = this.$store.state.productAddInfo;
      let hasInfo = false;
      for (let k in info) {
        if (info[k]) {
          hasInfo = true;
          break;
        }
      }
      if (!hasInfo) {
        if (this.$route.params.id) {
          return this.getGoodsDetail();
        }
        return ''
      }
      this.productInfo = info;
      this.selectedBrand = info.selectedBrand;
      this.selectedCategory = info.selectedCategory;
    },
    selectTransportTemplate(params) {
        this.productInfo.transportId = params.value;
    },
    selectCategory(params) {
      this.productInfo.categoryId =
        params.selectedThirdCategory.id ||
        params.selectedSecondCategory.id ||
        params.selectedFirstCategory.id;
      this.selectedCategory = params;
    },
    selectBrand(params) {
      this.selectedBrand = params;
      this.productInfo.brandId = params.brandId;
      this.productInfo.brandName = params.brandName;
    },
      getTransportTemplateList(){
          PimTransportService.getAllList().then(data => {
              this.transportTemplateList = data.map(e => {
                  e.id = e.transportId;
                  e.name = e.transportDescribe;
                  return e;
              });
          });
      }
  }
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/components/productAdd.scss";
.product-add /deep/ .el-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    border-top: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.el-inline-radio /deep/ .el-radio {
  display: inline-block;
}
.step-wrapper{ padding-left: 10px;}
</style>