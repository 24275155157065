<template>
  <div class="logistics-detail"> 
    <el-form :inline="true" class="rec-inline">
      物流详情     
    </el-form>    

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column prop="goodsCode" label="商品编码" :show-overflow-tooltip="true" ></el-table-column>
      <el-table-column  prop="goodsName" label="商品名称" :show-overflow-tooltip="true" ></el-table-column>
      <el-table-column prop="goodsBarCode" label="商品条形码" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="num" label="商品数量"></el-table-column>
      <el-table-column prop="costPrice" label="商品金额"></el-table-column>
      <el-table-column prop="skuBatchNumData" label="批次选择"></el-table-column>      
    </ws-page-table>

    <el-form :model="whmInfo" ref="deaInfoForm" class="step-form">
      <el-form-item label="订单备注：">
        {{whmInfo.remark}}
      </el-form-item>
      <el-form-item label="总金额：">
        ¥{{whmInfo.totalAmount}}
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">返回</el-button>
    </div>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import orderService from "API/som/orderService";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],  
      whmInfo: {}, 
      searchInfo:{
        orderId: ""
      },     
    };    
  },
  mixins: [pageTableMixin],
  mounted(){
    //this.getOrderDetail()
  },
  methods: {
    backPre(){//返回上一页
      this.$router.push("/som/logisticsOrder");
    },
    loadTableData() {
      orderService.getOrderDetail(this.$route.params.id).then(data => {
        this.whmInfo = data
        data.list = data.orderGoodsList.map((e) => {
          return {
            costPrice: e.costPrice,
            goodsName: e.goodsName,
            goodsCode: e.goodsCode,
            num: e.num,
            skuBatchNumData: e.skuBatchNumData,
            goodsBarCode: e.goodsBarCode || "",
          };
        });
        data.total = data.orderGoodsList.length
        this.querySuccess(data);
      });
    },
  },
};
</script>
<style>
.logistics-detail .rec-inline { margin-bottom: 20px;padding: 20px;background: #fff;}
.logistics-detail .step-form{ padding-bottom: 60px; margin-top: 30px;}
.logistics-detail .steps-btn-wrapper { margin-top: 80px; display: flex; justify-content: center;}
</style>