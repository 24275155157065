<template>
  <el-dialog title="改价" :visible.sync="dialogVisible" width="720px" v-loading="loading">
    <div class="tips-wraning">
      <i class="el-icon-warning"></i>
      <span>只有订单未付款时才支持改价，改价后请联系买家刷新订单核实订单金额后再支付</span>
    </div>
    <!--  -->
    <el-table
      :data="orderInfo.orderGoodsList"
      :span-method="objectSpanMethod"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column prop="goodsName" label="商品" width="90"></el-table-column>
      <el-table-column prop="costPrice" label="单价（元）" width="90"></el-table-column>
      <el-table-column prop="num" label="数量" width="90"></el-table-column>
      <el-table-column label="小计（元）" width="90">
        <template
          slot-scope="scope"
        >{{chargeFormat.thousandsOf(scope.row.costPrice * scope.row.num)}}</template>
      </el-table-column>
      <el-table-column>
        <template slot="header" slot-scope="scope">
          <span class="diy-th">涨价或者减价</span>
        </template>
        <template slot-scope="scope">
          <div class="col-all">
            <el-input oninput="value=value.replace(/[^-\d.]/g,’’)" v-model="priceDiffValue"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="运费（元）">
        <template slot-scope="scope">
          <div class="col-all">
            <el-input
              v-model="orderInfo.transportAmount"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>
            <div class="col-btn" @click="freeFreight">直接免运费</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header" slot-scope="scope">
          <span class="diy-th">实付金额</span>
        </template>
        <template slot-scope="scope">
          <div class="col-all">
            <span style="color: #BE0000;">{{totalAmount}}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="changeAmount">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import chargeFormat from "COMJS/chargeFormat.js";
import orderOperate from "API/som/orderOperate";
import _ from "lodash";

export default {
  props: {
    info: Object
  },
  data() {
    return {
      orderInfo: {},
      loading: false,
      dialogVisible: false,
      priceDiffValue: 0,
      goodsAmount: 0
    };
  },
  watch: {
    info: {
      handler(info) {
        this.goodsAmount = info.totalAmount - info.transportAmount;
        this.orderInfo = _.cloneDeepWith(info);
      },
      deep: true
    }
  },
  computed: {
    chargeFormat() {
      return chargeFormat;
    },
    totalAmount() {
      return this.chargeFormat.thousandsOf(
        Number(this.goodsAmount) +
          Number(this.orderInfo.transportAmount) +
          Number(this.priceDiffValue)
      );
    }
  },
  methods: {
    changeAmount() {
      if (
        isNaN(Number(this.orderInfo.transportAmount)) ||
        isNaN(Number(this.priceDiffValue))
      ) {
        return this.$message({
          message: "请填写正确的价格",
          type: "warning"
        });
      }
      if (this.orderInfo.transportAmount === "") {
        return this.$message({
          message: "请填写运费",
          type: "warning"
        });
      }
      let params = {
        orderId: this.orderInfo.orderId,
        changeTotalAmount: this.totalAmount,
        remark: ""
      };
      this.loading = true;
      orderOperate.orderChangeAmount(params).then(res => {
        this.dialogVisible = false;
        this.loading = false;
        this.$emit("changeAmount");
      });
    },
    freeFreight() {
      this.orderInfo.transportAmount = 0;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 3) {
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.el-table /deep/ {
  th {
    padding: 0;
    font-weight: normal;
    line-height: 36px;
    height: 36px;
    font-size: 14px;
    color: #8898aa;
    .cell {
      line-height: 36px;
      height: 36px;
    }
  }
  .cell {
    font-size: 14px;
  }
  .el-table__row td:nth-of-type(n + 5) {
    vertical-align: top;
    border-left: 1px solid #ebeef5;
    text-align: center;
  }
}
.col-btn {
  cursor: pointer;
  color: $themeColor;
}
.diy-th {
  color: #1b73e8;
  font-size: 14px;
}
.el-dialog__footer {
  border-top: 1px none;
  text-align: right;
}
.tips-wraning {
  padding-left: 20px;
  border: 1px solid #f1924e;
  line-height: 46px;
  background-color: #fff5ed;
  color: #666;
  margin-bottom: 15px;
  .el-icon-warning {
    display: inline-block;
    margin-right: 10px;
    color: #ed6a0d;
  }
}
.col-all {
  display: inline-block;
}
</style>