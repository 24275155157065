<template>
  <div id="app">
    <router-view v-if="isReload" />
  </div>
</template>

<script>
import userModule from "./module/userModule";

export default {
  name: "app",
  //ly add
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isReload: true
    }
  },
  methods: {
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  },

  created() {
    let PPUser = {
      userType: "staff",
      userId: "0df8749dab2343c7b14f2b5ad657f585",
      userCode: "daddy",
      account: "daddy",
      lastName: null,
      firstName: null,
      lastNamePinyin: null,
      firstNamePinyin: null,
      name: "系统管理员",
      namePinying: null,
      telAreaCode: null,
      tel: "",
      nickName: null,
      headImageUrl: null,
      wechatId: null,
      bindWechatUserId: null,
      bindWechatUserNickName: null,
      qqNum: null,
      country: null,
      province: null,
      city: null,
      area: null,
      street: null,
      address: null,
      certificateNo: null,
      frontPhotoId: null,
      contraryPhotoId: null,
      level: null,
      isChild: null,
      parentId: null,
      sex: "0",
      birthdate: 1536249600000,
      education: "",
      email: "",
      state: "normal",
      authCode: null,
      authTime: null,
      authTerm: null,
      token:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODI2MzA5NzkzNzQsInBheWxvYWQiOiJ7XCJ1c2VyVHlwZVwiOlwic3RhZmZcIixcInVzZXJJZFwiOlwiMGRmODc0OWRhYjIzNDNjN2IxNGYyYjVhZDY1N2Y1ODVcIixcInVzZXJDb2RlXCI6XCJkYWRkeVwiLFwiYWNjb3VudFwiOlwiZGFkZHlcIixcImxhc3ROYW1lXCI6bnVsbCxcImZpcnN0TmFtZVwiOm51bGwsXCJsYXN0TmFtZVBpbnlpblwiOm51bGwsXCJmaXJzdE5hbWVQaW55aW5cIjpudWxsLFwibmFtZVwiOlwi57O757uf566h55CG5ZGYXCIsXCJuYW1lUGlueWluZ1wiOm51bGwsXCJ0ZWxBcmVhQ29kZVwiOm51bGwsXCJ0ZWxcIjpcIlwiLFwibmlja05hbWVcIjpudWxsLFwiaGVhZEltYWdlVXJsXCI6bnVsbCxcIndlY2hhdElkXCI6bnVsbCxcImJpbmRXZWNoYXRVc2VySWRcIjpudWxsLFwiYmluZFdlY2hhdFVzZXJOaWNrTmFtZVwiOm51bGwsXCJxcU51bVwiOm51bGwsXCJjb3VudHJ5XCI6bnVsbCxcInByb3ZpbmNlXCI6bnVsbCxcImNpdHlcIjpudWxsLFwiYXJlYVwiOm51bGwsXCJzdHJlZXRcIjpudWxsLFwiYWRkcmVzc1wiOm51bGwsXCJjZXJ0aWZpY2F0ZU5vXCI6bnVsbCxcImZyb250UGhvdG9JZFwiOm51bGwsXCJjb250cmFyeVBob3RvSWRcIjpudWxsLFwibGV2ZWxcIjpudWxsLFwiaXNDaGlsZFwiOm51bGwsXCJwYXJlbnRJZFwiOm51bGwsXCJzZXhcIjpcIjBcIixcImJpcnRoZGF0ZVwiOjE1MzYyNDk2MDAwMDAsXCJlZHVjYXRpb25cIjpcIlwiLFwiZW1haWxcIjpcIlwiLFwic3RhdGVcIjpcIm5vcm1hbFwiLFwiYXV0aENvZGVcIjpudWxsLFwiYXV0aFRpbWVcIjpudWxsLFwiYXV0aFRlcm1cIjpudWxsLFwidG9rZW5cIjpudWxsLFwid3hPcGVuSWRcIjpudWxsLFwid3hBZ2VuY3lJZFwiOm51bGwsXCJ3eEFnZW5jeUNvZGVcIjpudWxsLFwid3hBZ2VuY3lUeXBlXCI6bnVsbCxcInd4QWdlbmN5TGV2ZWxcIjpudWxsLFwiYnVzaW5lc3NDb250cm9sTWFwXCI6bnVsbH0ifQ.FMoGMolgc1nc6kUIFJ8VIr1VEhcCMAIg2-zWqso41ZM",
      wxOpenId: null,
      wxAgencyId: null,
      wxAgencyCode: null,
      wxAgencyType: null,
      wxAgencyLevel: null,
      businessControlMap: null,
    };

    // userModule.setSessionToken(PPUser.token);
    // userModule.setPPUser(JSON.stringify(PPUser));
  },
};
</script>


<style>
 @import url("~ASSETS/css/resetCss.css");
</style>

