<template>
  <el-dialog title="选择商品" :visible.sync="dialogVisible" width="1000px" top="5vh">
    <div class="select-content">
      <div class="input-search">
        <el-input v-model="searchInfo.cnNameLike" placeholder="请输入商品名称"></el-input>
        <el-input v-model="searchInfo.goodsCodeLike" placeholder="请输入商品编码"></el-input>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </div>
      <div class="search-result">
        <ws-page-table ref="pageTable" :data="tableData" :pageable="true"
                       :pageInfo="pageInfo" @pageChange="loadTableData"
                       :showIndex="false"
                       :cellClickSelect="false"
                       :key="pageDataChange"
                       :selection="selection">
          <el-table-column prop="cnName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="goodsCode" label="商品编号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="selectedNum" label="数量" v-if="showSelectedNum">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.selectedNum"
                               :disabled="!scope.isSelected"
                               :min="1" :max="10"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <div class="table-img">
                <img :src="scope.row.pictureUrl" alt/>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import pimGoodsService from "API/pim/PimGoodsService";

export default {
  props: {
    //选择模式:multi,single(多选，单选)
    selection: {type: String, default: 'multi'},
    goodsOrderType: {type: String, default: ''},
    showSelectedNum: {type: Boolean, default: false},
  },
  data() {
    return {
      dialogVisible: false,
      dataList: [],
      searchInfo: {
        cnNameLike: "",
        goodsCodeLike: "",
      },
      pageDataChange: false,
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: {
    submit() {
      let selected = this.$refs.pageTable.getSelectedData();
      //console.log(selected);
      this.$emit("submit", selected);
      this.dialogVisible = false;
      this.$refs.pageTable.clearSelection();
    },
    loadTableData() {
      if (this.$refs.pageTable) {
        this.$refs.pageTable.tableLoading = true;
      }
      let param = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        sortField: "goodsCode,asc",
        orderType: this.goodsOrderType,
        ...this.searchInfo
      }
      pimGoodsService
          .queryGoodsPageByParam(param)
          .then((data) => {
            this.querySuccess(data);
            this.pageDataChange = !this.pageDataChange;
          });
    },
  },
};
</script>

<style scoped lang="scss">
.select-content {
  .input-search {
    padding: 20px;
    border-bottom: 1px solid $borderColor;

    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }

  .search-result {
    .search-result-item {
      cursor: pointer;
      line-height: 50px;
      padding: 0 20px;
      color: #666;
      border-bottom: 1px solid $borderColor;

      &:hover {
        background-color: rgba(51, 51, 51, 0.1);
      }
    }
  }
}
</style>