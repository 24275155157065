const frame = { template: "<router-view></router-view>" };

export default {
  path: "pm",
  component: frame,
  children: [
    {
      name: "日程看板",
      path: "ProjectBoard",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pm/pm/ProjectBoard.vue"),
    },
    {
      name: "项目明细",
      path: "ProjectList",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pm/pm/ProjectList.vue"),
    },
    {
      name: "批量任务编辑",
      path: "taskEdit",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pm/pm/taskEdit.vue"),
    },
  ],
};
