<template>
  <el-dialog
    :title="categoryId ? '编辑分类': '添加分类'"
    :visible.sync="dialogFormVisible"
    @close="resetForm"
  >
    <el-form :model="form" v-loading="loading">
      <el-form-item required label="分类名称：" :label-width="formLabelWidth">
        <el-input v-model="form.name" maxlength="16" show-word-limit></el-input>
      </el-form-item>
      <el-form-item required label="分类级别：" :label-width="formLabelWidth">
        <el-select v-model="form.selectedLevel" placeholder="级别" style="width: 90px;">
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="form.selectedFirstCategoryId"
          v-if="form.selectedLevel >= 2"
          placeholder="请选择一级分类"
          @change="getSecondCategory"
        >
          <el-option
            v-for="item in firstCategorys"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="form.selectedSecondCategoryId"
          v-if="form.selectedLevel == 3"
          placeholder="请选择二级分类"
        >
          <el-option
            v-for="item in secondCategorys"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item required label="分类图标：" :label-width="formLabelWidth">
        <el-upload
          ref="upload"
          :file-list="fileList"
          :class="{limit: form.iconUrl}"
          accept="image/*"
          :action="uploadUrl()"
          :headers="requestHeaders"
          :on-success="uploadSuccess"
          :on-remove="handleRemove"
          list-type="picture-card"
          :before-upload="beforeUpload"
        >+添加图片</el-upload>

      </el-form-item>
      <el-form-item label="分类编号：" :label-width="formLabelWidth">
        <el-input v-model="form.No" maxlength="16" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="是否启用：" :label-width="formLabelWidth">
        <el-switch v-model="form.isEnable"></el-switch>
      </el-form-item>
      <el-form-item label="备注：" :label-width="formLabelWidth">
        <el-input v-model="form.remarks" maxlength="128" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>


<script>
import PimCategoryService from "API/pim/PimCategoryService.js";
import IoProgressService from "API/IoProgressService.js";
import userModule from "@/module/userModule";
import tools from "COMJS/tools";

export default {
  props: {
    categoryId: String
  },
  data() {
    return {
      form: {
        name: "",
        iconUrl: "",
        No: "",
        isEnable: false,
        selectedLevel: "",
        remarks: "",
        selectedFirstCategoryId: "",
        selectedSecondCategoryId: ""
      },
      categoryInfo: {},
      levelList: [
        {
          name: "一级",
          value: "1"
        },
        {
          name: "二级",
          value: "2"
        },
        {
          name: "三级",
          value: "3"
        }
      ],
      firstCategorys:[],
      secondCategorys:[],
      requestHeaders:{
        Authorization: userModule.token
      },
      loading: false,
      dialogFormVisible: false,
      formLabelWidth: "100px",
      fileList: []
    };
  },
  mounted() {
    this.getFirstCategory();
  },
  watch: {
    dialogFormVisible() {
      if (this.categoryId) {
        this.getCategoryDetail();
      } else {
        this.resetForm();
      }
    }
  },
  methods: {
    submitForm() {
      if (this.form.name == "") {
        return this.$message({
          message: "请填写分类名称",
          type: "warning"
        });
      }
      if (!this.form.selectedLevel) {
        return this.$message({
          message: "请选择分类级别",
          type: "warning"
        });
      }
      if (this.form.selectedLevel >= 2 && !this.form.selectedFirstCategoryId) {
        return this.$message({
          message: "请选择一级分类",
          type: "warning"
        });
      }
      if (this.form.selectedLevel == 3 && !this.form.selectedSecondCategoryId) {
        return this.$message({
          message: "请选择二级分类",
          type: "warning"
        });
      }
      if (this.form.iconUrl == "") {
        return this.$message({
          message: "请上传分类图标",
          type: "warning"
        });
      }
      let _parentId;
      let _level = this.form.selectedLevel;
      if (_level == 1) {
        _parentId = "0";
      } else if (_level == 2) {
        _parentId = this.form.selectedFirstCategoryId;
      } else if (_level == 3) {
        _parentId = this.form.selectedSecondCategoryId;
      }
      let params = {
        parentCategoryId: _parentId,
        categoryName: this.form.name,
        level: this.form.selectedLevel,
        categoryCode: this.form.No,
        visible: this.form.isEnable ? 1 : 0,
        categoryIconUrl: this.form.iconUrl,
        categoryType: 1,
        state: 1,
        remark: this.form.remarks
      };
      this.loading = true;
      let promise = null;
      if (this.categoryId) {
        params.categoryId = this.categoryId;
        promise = PimCategoryService.updateCategory(params);
      } else {
        promise = PimCategoryService.saveCategory(params);
      }

      promise.then(res => {
        this.dialogFormVisible = false;
        this.loading = false;
        this.getFirstCategory();
        this.$emit("updateCategory");
      });
    },
    // 详情
    getCategoryDetail() {
      this.loading = true;
      PimCategoryService.getCategoryDetail(this.categoryId).then(async data => {
        this.categoryInfo = data
        this.form.name = data.categoryName;
        this.form.iconUrl = data.categoryIconUrl;
        if (data.categoryIconUrl) {
          this.fileList = [
            {
              name: "",
              url: data.categoryIconUrl
            }
          ];
        }
        this.form.No = data.categoryCode;
        this.form.isEnable = data.visible == 1;
        this.form.selectedLevel = String(data.level);
        this.form.remarks = data.remark
        this.writeElSelect()
        this.loading = false;
      });
    },
    // 一级分类
    getFirstCategory() {
      PimCategoryService.getTopCategory().then(data => {
        this.firstCategorys = data.map(e => {
          e.id = e.categoryId;
          e.name = e.categoryName;
          return e;
        });
      });
    },
    // 二级分类
    getSecondCategory(id) {

      return PimCategoryService.getChildrenCategory(id).then(data => {
        this.secondCategorys = data.map(e => {
          e.id = e.categoryId;
          e.name = e.categoryName;
          return e;
        });
      });
    },
    // 写入父级id
    async writeElSelect() {
      let data = this.categoryInfo

      if (data.level == "2") {
        this.$set(this.form, "selectedFirstCategoryId", data.parentCategoryId)
      }
      // 分类详情只有父级id，暂时这样写
      if (data.level == "3") {
        this.loading = true;
        let secondCategory = await PimCategoryService.getCategoryDetail(data.parentCategoryId) // 二级分类详情
        let secondCategorys = await this.getSecondCategory(secondCategory.parentCategoryId)
        this.$set(this.form, "selectedFirstCategoryId", secondCategory.parentCategoryId)
        this.$set(this.form, "selectedSecondCategoryId", secondCategory.categoryId)
        this.loading = false;
      }
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("category");
      return url;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    uploadSuccess(url) {
      this.form.iconUrl = url;
    },
    handleRemove() {
      this.form.iconUrl = "";
    },
    // 重置表单
    resetForm() {
      this.secondCategorys=[];
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
      tools.clearObj(this.form);
    }
  }
};
</script>

<style lang="scss">
.el-select {
  margin-right: 20px;
}
.el-upload { //这是上传图片父容器。
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
