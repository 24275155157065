<template>
  <div>
    <div>
      <el-button type="primary" @click="showCreateDialog">新增授权</el-button>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="被授权方名称:">
        <el-input v-model="searchInfo.dealerName"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column prop="dealerName" label="名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="dealerBusinessNo" label="税号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="dealerPlatform" label="授权平台" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="storeName" label="店铺名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="授权期限" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.authBegin) }} - {{ dateFormat(scope.row.authEnd) }}
        </template>
      </el-table-column>
      <el-table-column prop="contactName" label="扫描件">
        <template slot-scope="scope">
          <el-image
              v-if="scope.row.scanUrl"
              fit="contain"
              style="height: 100px"
              :src="scope.row.scanUrl">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="enabled" label="授权状态" width="100">
        <template slot-scope="scope">
          {{
            scope.row.authState == 'freeze' ? '冻结'
                : scope.row.authState == 'expire' ? '过期'
                : scope.row.authState == 'normal' ? '正常' : ''
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="编辑"
                @click="showEditDialog(scope.row)"
            >
              <i class="el-icon-edit"></i>
            </a>
            <a
                href="javascript: void(0);"
                title="下载二维码"
                @click="qrcodeDownload(scope.row)"
            >
              <i class="el-icon-download"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <!--  新增或编辑弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="560px">
      <el-form label-width="80px">
        <!--        <el-form-item label="直播平台" required>
                  <el-select v-model="anchorEditData.livePlatformCode"
                             :disabled="anchorEditData.anchorId!=null"
                             placeholder="请选择">
                    <el-option
                        v-for="item in livePlatformList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>-->
        <el-form-item label="名称" required>
          <el-input v-model="dialogData.dealerName"
                    :disabled="dialogData.dealerAuthId!=null"
                    placeholder="名称"></el-input>
          <!---->
          <p style="color: #8d8d8d;">* 公司或个人名称</p>
        </el-form-item>
        <el-form-item label="注册号" required>
          <el-input v-model="dialogData.dealerBusinessNo"
                    :disabled="dialogData.dealerAuthId!=null"
                    placeholder="注册号"></el-input>
          <p style="color: #8d8d8d;">* 统一社会信用代码（法人机构统一赋码）或个人身份证</p>
        </el-form-item>
        <el-form-item label="授权平台" required>
          <el-input v-model="dialogData.dealerPlatform"
                    placeholder="授权平台"></el-input>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="dialogData.storeName"
                    placeholder="店铺名称"></el-input>
        </el-form-item>
        <el-form-item label="店铺地址">
          <el-input v-model="dialogData.storeAddress"
                    placeholder="店铺地址"></el-input>
        </el-form-item>
        <el-form-item label="授权期限" required>
          <el-date-picker
              v-model="dialogDataBeginEnd"
              :editable="false"
              @change="changePickerDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="上传扫描件">
          <div style="display: flex;align-items: end;">
            <draggable-upload
                ref="screenshotUpload"
                :imgDel="true"
                :width="82"
                :limit="1"
                :fileSize="2048 * 1024"
                fileSizeText="2M"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="dialogDataScanUrlArr"
            ></draggable-upload>
            <span class="tips-btn" @click="$refs.examplePopup.dialogVisible = true">查看示例</span>
          </div>
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 认</el-button>
      </div>
    </el-dialog>
    <example-popup
        ref="examplePopup"
        title="查看文件示例"
        tips="请确保图片清晰，四角齐全"
        :imgUrl="require('ASSETS/images/example/auth-example-1.png')"
    ></example-popup>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerAuthService from "API/dea/dealerAuthService";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService";
import userModule from "@/module/userModule";
import examplePopup from "COMPS/widgets/examplePopup";
import draggableUpload from "COMPS/base/draggableUpload";
import QRCodeUtils from "COMJS/QRCodeUtils";
import fileUtils from "COMJS/FileUtils";

export default {
  name: "dealerAuthList",
  components: {examplePopup, draggableUpload},
  data() {
    return {
      dialogTitle: "新增授权",
      dialogVisible: false,
      dialogData: {},
      dialogDataBeginEnd: [],
      dialogDataScanUrlArr: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
      //
      searchInfo: {
        dealerName: "",
      },
      items: {
        dealerName: {filter: "like"},
      },
    };
  },
  mixins: [pageTableMixin],
  mounted() {

  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      dealerAuthService.queryPages(this.pageInfo, this.searchConfig).then(res => {
        this.querySuccess(res);
        this.$refs.pageTable.tableLoading = false;
      })
    },
    showCreateDialog() {
      this.dialogTitle = "新增授权"
      this.dialogData = {}
      this.dialogDataBeginEnd = []
      this.dialogDataScanUrlArr = []
      this.dialogVisible = true
    },
    showEditDialog(row) {
      this.dialogTitle = "编辑授权"
      this.dialogData = {
        ...row
      }
      this.dialogDataBeginEnd = [this.dialogData.authBegin, this.dialogData.authEnd]
      if (this.dialogData.scanUrl) {
        this.dialogDataScanUrlArr = [{'response':this.dialogData.scanUrl}]
      } else {
        this.dialogDataScanUrlArr = []
      }
      this.dialogVisible = true;
    },
    dialogConfirm() {
      let param = {
        ...this.dialogData,
        'authState':'normal',
        scanUrl: this.dialogDataScanUrlArr.length > 0 ? this.dialogDataScanUrlArr[0].response : null
      }
      dealerAuthService.save(param).then(res => {
        this.$message.success("保存成功");
        this.dialogData = {}
        this.dialogDataBeginEnd = []
        this.dialogDataScanUrlArr = []
        this.dialogVisible = false;
        this.loadTableData()
      })
    },
    async qrcodeDownload(row){
      let id = row.dealerAuthId
      let url = `https://www.wdomilk.com.cn/#/auth/${id}`
      let base64 = await QRCodeUtils.generateQR(url)
      //console.log(base64)
      fileUtils.downloadFileByBase64(base64,"授权二维码_"+row.dealerName)
    },
    dateFormat(time) {
      return tools.formatTime(time, 'yyyy/MM/dd')
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("fa_dealer");//fa_dealer图片文件夹
      return url;
    },
    changePickerDate(e) {
      if (e === null) {
        this.dialogData.authBegin = "";
        this.dialogData.authEnd = "";
      } else {
        //this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.dialogData.authBegin = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.dialogData.authEnd = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
  }
}
</script>

<style scoped>
.demo-form-inline {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
}

.tips-btn {
  margin-left: 10px;
  cursor: pointer;
  color: #1B73E8;
}
</style>