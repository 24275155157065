<template>
  <div>
    <el-button type="primary" @click="editEvent()">新增价格活动</el-button>
    <div style="margin-top: 20px;">
      <!--pane-->
      <el-tabs
          v-model="searchInfo.eventSystem"
          type="card"
          @tab-click="loadTableData">
        <el-tab-pane label="经销商" name="agent"></el-tab-pane>
        <el-tab-pane label="会员" name="distributor"></el-tab-pane>
        <el-tab-pane label="特殊" name="special"></el-tab-pane>
      </el-tabs>
      <!--table-->
      <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                     @pageChange="loadTableData"
                     :tableRowClassName="tableRowClassName"
                     :cellStyle="cellStyleFunc"
                     :showIndex="true" :isShadow="false">
        <el-table-column prop="eventName" label="活动名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="beginTime" label="开始时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="endTime" label="结束时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="weight" label="权重" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="eventSystemName" label="体系" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="icon-operation" style="display: flex;justify-content: space-between;">
              <a href="javascript: void(0);" style="margin-right: 0px !important;" @click="goDetail(scope.row)"
                 title="编辑">
                <i class="CCSFont icon-table_1"></i>
              </a>
              <div @click="toEventShop(scope.row)">
                <i class="el-icon-s-goods"></i>
              </div>
              <el-popconfirm style="margin-left:0;" title="确定删除吗？" @confirm="delPriceEvent(scope.row.eventId)">
                <el-button>删除</el-button>
                <a slot="reference" href="javascript: void(0);" title="删除">
                  <i class="el-icon-delete-solid"></i>
                </a>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <el-dialog title="价格活动编辑" :visible.sync="dialogVisible">
      <el-form :model="dialogData" label-width="100px">
        <el-form-item label="活动名称：">
          <el-input type="text"
                    v-model="dialogData.eventName"/>
        </el-form-item>
        <el-form-item label="开始结束时间：">
          <el-date-picker
              v-model="dialogDataTimeArr"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="权重：">
          <el-input-number maxlength="8"
                           v-model="dialogData.weight"/>
        </el-form-item>
        <el-form-item label="体系：">
          <el-select v-model="dialogData.eventSystem"
                     placeholder="用户体系">
            <el-option
                v-for="item in eventSystemList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定角色：" v-if="dialogData.eventSystem=='distributor'">
          <el-radio-group v-model="dialogData.selectedUsers">
            <div style="display: flex">
              <el-radio label="all">所有人</el-radio>
              <el-radio label="customize">指定用户</el-radio>
              <el-link type="primary" @click="userDialogVisible = true"
                       v-if="dialogData.selectedUsers =='customize'">选择金星会员
              </el-link>
            </div>
          </el-radio-group>
        </el-form-item>
        <div style="width:300px;margin-left: 100px;"
             v-if="dialogData.eventSystem=='distributor' && dialogData.selectedUsers =='customize'">
          <el-table :data="dialogData.eventDistributorList">
            <el-table-column label="序号" width="50">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column label="会员信息" prop="userName" width="200">
              <template slot-scope="scope">
                {{ decodeURI(scope.row.userName) + "（" + scope.row.tel + "）" }}
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="50">
              <template slot-scope="scope">
                <div style="display: flex;justify-content: space-between;" class="edit-icon">
                  <i @click="delUserFromEventDistributorList(scope.$index)" style="cursor: pointer;"
                     class="el-icon-circle-close"></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <div slot="footer" style="text-align: center;">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="saveDialogData">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择金星会员" :visible.sync="userDialogVisible" width="40%">
      <div>
        <el-checkbox-group v-model="checkUserList">
          <div v-for="item in users" :key="item.userId">
            <el-checkbox :label="item">{{ (item.fullName || '--') + "（" + item.tel + "）" }}</el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="userDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitChooseUsers">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import draggableUpload from "COMPS/base/draggableUpload";
import BimMemberService from "API/bim/BimMemberService"
import {
  queryEventDetail,
  priceEventList,
  delEventDetail,
  editEventDetail
} from '@/api/pim/PimPriceEvent.js'
import tools from "COMJS/tools";

export default {
  components: {
    draggableUpload
  },
  data() {
    return {
      //activeName: "Applets",
      searchInfo: {eventSystem: 'agent'},
      items: {eventSystem: {filter: "EQ"}},

      dialogVisible: false,
      dialogData: {},
      dialogDataTimeArr: [],
      eventSystemList: [
        {
          value: "distributor",
          label: "会员"
        },
        {
          value: "agent",
          label: "经销商"
        }
      ],
      userDialogVisible: false,
      users: [],
      checkUserList: [],
    };
  },
  mounted() {
    BimMemberService.queryMemberList().then(data => {
      this.users = data
    });
  },
  methods: {
    async loadTableData() {
      const data = await priceEventList(this.pageInfo, this.searchConfig)
      data.list = data.list.map(e => {
        e.outOfBeginTime = e.beginTime > new Date() ? '1' : '0';
        e.outOfEndTime = e.endTime < new Date() ? '1' : '0';
        e.beginTime = tools.formatDate(
            new Date(e.beginTime),
            "yyyy-MM-dd hh:mm:ss"
        );
        e.endTime = tools.formatDate(
            new Date(e.endTime),
            "yyyy-MM-dd hh:mm:ss"
        );
        e.eventSystemName = e.eventSystem == 'agent' ? '经销商' :
            e.eventSystem == 'distributor' ? '会员' : '特殊';
        return e;
      });
      //this.tableData = data.list
      this.querySuccess(data)
    },
    editEvent() {
      this.dialogData = {weight: 50};
      this.dialogDataTimeArr = []
      this.dialogVisible = true;
    },
    async goDetail(data) {
      let params = {
        pageSize: 10,
        pageNo: 1,
        eventId: data.eventId
      }
      this.dialogData = await queryEventDetail(params)
      //this.dialogData = data;
      this.dialogDataTimeArr = [data.beginTime, data.endTime]
      this.dialogVisible = true;
    },
    commitChooseUsers() {
      //console.log(this.checkUserList)
      let list = this.checkUserList.map(item => {
        return {
          distributorId: item.userId,
          userName: item.fullName,
          tel: item.tel,
        }
      })
      if (!this.dialogData.eventDistributorList) {
        this.dialogData.eventDistributorList = []
      }
      this.dialogData.eventDistributorList = this.dialogData.eventDistributorList.concat(list)
      this.userDialogVisible = false
      this.checkUserList = []
    },
    delUserFromEventDistributorList(i) {
      this.dialogData.eventDistributorList.splice(i, 1)
    },
    saveDialogData() {
      if(this.dialogData.eventSystem=='distributor'
          && this.dialogData.selectedUsers =='all'){
        this.dialogData.eventDistributorList = [{distributorId: 'all', userName: '所有人'}]
      }
      this.dialogData.beginTime = new Date(this.dialogDataTimeArr[0])
      this.dialogData.endTime = new Date(this.dialogDataTimeArr[1])
      editEventDetail(this.dialogData).then(data => {
        this.$message.success(`操作成功！`);
        this.loadTableData();
        this.dialogVisible = false;
      })
    },
    async delPriceEvent(id) {
      await delEventDetail(id)
      this.loadTableData()
    },
    toEventShop(row) {
      let id = row.eventId
      let eventSystem = row.eventSystem
      let eventName = row.eventName
      if (eventSystem == 'distributor') {
        this.$router.push({
          path: "eventShop?id=" + id + "&eventName=" + eventName
        })
      } else {
        this.$router.push({
          path: "eventShop4A?id=" + id + "&eventName=" + eventName
        })
      }
    },
    tableRowClassName({row, rowIndex}) {
      if (row.outOfBeginTime == '1' || row.outOfEndTime == '1') {
        return 'warning-row';
      }
      return '';
    },
    cellStyleFunc(column) {
      if (column.columnIndex === 2 && column.row.outOfBeginTime == '1') {
        return {"color": "#ba0606"}
      }
      if (column.columnIndex === 3 && column.row.outOfEndTime == '1') {
        return {"color": "#ba0606"}
      }
    },
  },
  mixins: [tableMixins],
};
</script>

<style></style>
