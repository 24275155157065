<template>
  <div>
    <div style="padding: 20px; background-color: white;">
      <el-form label-width="80" inline>
        <el-row>
          <el-form-item label="收件人姓名:">
            <el-input v-model="searchInfo.recipientName"/>
          </el-form-item>
          <el-form-item label="收件人手机号:">
            <el-input onkeyup="value=value.replace(/[^\d]/g,'');" v-model="searchInfo.recipientPhoneNum"/>
          </el-form-item>
          <el-form-item label="会员账号:">
            <el-input v-model="searchInfo.userAccount"/>
          </el-form-item>
          <el-form-item label="奶卡订单号:">
            <el-input v-model="searchInfo.orderCode"/>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item>
            <el-button size="small" plain type="primary" @click="$refs.selectGoodsPopup.dialogVisible = true">+商品搜索
            </el-button>
          </el-form-item>
          <el-form-item class="form-item" v-if="selected && selected.length">
            <span class="form-label">订阅商品：</span>
            <el-tag color="rgba(255, 255, 255, 1)" style="margin-left: 5px" v-for="tag in selected" :key="tag" closable
                    type="info" :disable-transitions="true" @close="handleClose(tag)"
                    effect="plain">
              {{ tag }}
            </el-tag>
          </el-form-item>
        </el-row>

      </el-form>
      <div style="display: flex;justify-content: end;">
        <el-button type="primary" @click="reSetPage">搜索</el-button>
      </div>
    </div>
    <div>
      <el-tabs
          v-model="searchInfo.type"
          type="card"
          @tab-click="reSetPage"
      >
        <el-tab-pane label="所有奶卡" name="all"></el-tab-pane>
        <el-tab-pane label="异常奶卡" name="abnormal"></el-tab-pane>
      </el-tabs>
      <ws-page-table style="margin-top: 30px;" :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                     @pageChange="loadTableData" :showIndex="true" :isShadow="false">
        <!--
        <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ formatDate(new Date(scope.row.createTime), "yyyy-MM-dd hh:mm") }}
          </template>
        </el-table-column>
        -->
        <el-table-column prop="orderCode" label="奶卡订单号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="mainFlag" label="奶卡状态">
          <template slot-scope="scope">
            {{ scope.row.packageState == 'ongoing' ? '持续中'
              : scope.row.packageState == 'finish' ? '已完成'
                  : scope.row.packageState == 'stop' ? '已暂停'
                      : scope.row.packageState == 'cancel' ? '已取消'
                          : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="mainFlag" label="奶卡类型">
          <template slot-scope="scope">
            {{scope.row.mainFlag=='1'? '礼包奶卡':'商品奶卡'}}
          </template>
        </el-table-column>
        <el-table-column prop="intermediatorName" label="所属会员">
          <template slot-scope="scope">
            {{
              scope.row.intermediatorName
                  ? decodeURI(scope.row.intermediatorName)
                  : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="usedCount" label="发货期数">
          <template slot-scope="scope"> {{ scope.row.usedCount }} / {{ scope.row.totalCount }}</template>
        </el-table-column>
        <el-table-column prop="recipientName" label="收件人" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="recipientPhoneNum" label="收件人号码" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="remark" label="最近一次修改" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ formatDate(new Date(scope.row.updateTime), "yyyy-MM-dd hh:mm") }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="icon-operation" @click="toDetail(scope.row.virtualPackageId)">
              <a href="javascript: void(0);" title="查看">
                <i class="CCSFont icon-table_2"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <select-goods-popup ref="selectGoodsPopup" @submit="selectGoods"></select-goods-popup>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import selectGoodsPopup from "../../pim/product/spu/selectGoodsPopup";
import {fetchList} from "../../../api/som/miklCard.js";
import tools from "COMJS/tools";

export default {
  data() {
    return {
      show: false,
      selected: [],
      goodsIdList: [],
      searchInfo:{
        abnormalState:'',
        type:'all'
      },
      tableData: [],
    };
  },
  components: {
    selectGoodsPopup,
  },
  methods: {
    formatDate: tools.formatDate,
    toDetail(id) {
      this.$router.push({
        path: "milkCardDetail/" + id,
      });
    },
    reSetPage() {
      this.pageInfo.pageNo = 1;
      if (this.goodsIdList && this.goodsIdList.length > 0) {
        this.searchInfo.goodsIdList = this.goodsIdList;
      }
      this.loadTableData();
    },
    async loadTableData() {
      //console.log(this.queryParams)
      this.searchInfo.abnormalState = this.searchInfo.type=='abnormal'?'1':''
      const data = await fetchList(this.queryParams);
      //console.log(data);
      this.tableData = data.list;
      this.querySuccess(data);
    },
    selectGoods(selected) {
      //console.log(selected);
      let selectedList = this.selected;
      for (let i in selected) {
        selectedList.push(selected[i].goodsCode);
        this.goodsIdList.push(selected[i].goodsId);
      }

      //数组去重
      this.selected = [...new Set(selectedList)];

      let obj = {};
      this.goodsIdList =
          this.goodsIdList.reduce((cur, next) => {
            obj[next]
                ? ""
                : (obj[next] = true && cur.push(next));
            return cur;
          }, []);
    },
    // 多选栏中的删除
    handleClose(tag) {
      const item = this.selected.indexOf(tag);
      this.selected.splice(item, 1);
      this.goodsIdList.splice(item, 1);
    },
  },
  mixins: [pageTableMixin],
};
</script>

<style scoped></style>
