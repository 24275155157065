<template>
  <div>
    <div style="position:relative;">
      <div v-if="isSee" style="width:100%;height:98%;position:absolute;z-index:3;"></div>
      <div style="margin-top:20px;display: flex;">
        <div style="display: flex;align-items: center;">
          <div>快递公司：</div>
          <el-select placeholder="快递公司" v-model="detail.expressCompany">
            <el-option v-for="item in comOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div style="display: flex;align-items: center;margin-left: 20px;">
          <div>计价方式：</div>
          <el-select placeholder="计价方式" v-model="detail.calcMode">
            <el-option v-for="item in calcModeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <div style="margin-top:20px;display: flex;align-items: center;">
        <div>排除商品：</div>
        <div style="display:flex;flex-wrap:wrap;">

          <el-tag v-for="(tag,idx) in detail.excludeGoodsCodeList" :key="tag" closable type="warning" style="margin:5px;" @close="delExcludeGoods(idx)">{{ tag }}
          </el-tag>
          <el-input class="input-new-tag" v-if="tagInputVisible" v-model="tagInputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
          </el-input>
          <div v-else style="display: flex;align-items: center;">
            <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;" @click="showInput"></i>
          </div>
        </div>
      </div>
      <div style="margin-top:20px;">
        <div>
          停发地区：
          <i class="el-icon-edit" style="font-size:20px; cursor:pointer;" @click="outRangeAreaEdit"></i>
        </div>
        <div style="margin-top:10px;display:flex;flex-wrap:wrap;">
          <el-tag v-for="(tag,idx) in outRangeList" :key="tag" type="info" style="margin:5px;">{{ tag }}
          </el-tag>
        </div>
      </div>

      <div style="margin-top:20px;">
        <el-table border :data="detail.ruleList" style="width: 100%" class="d-tab">
          <el-table-column label="地区" width="200px;">
            <template slot="header" slot-scope="scope">
              <div>
                地区
                <i class="el-icon-circle-plus" style="font-size:25px; cursor:pointer; float: right;" @click="addModel"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <!-- <areaCascader /> -->
              <div style="display:flex;justify-content:space-between;">
                <div style="display:flex;flex-wrap:wrap;">
                  <div v-for="(tag,ai) in detail.ruleList[scope.$index].transportAreaList" :key="tag">
                    <el-tag closable type="danger" style=" margin:5px;" @close="delAddr(scope.$index,ai)">{{ tag }}
                    </el-tag>
                  </div>
                </div>
                <div>
                  <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;" @click="chooseAddr(scope.$index)"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="重量区间1">
            <template slot-scope="scope">
              <div style="display:flex; justify-content:center;">
                <div>0 -</div>
                <div style="width:50px;margin:0 5px;">
                  <el-input maxlength="8" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].rangeOne)" v-model=" detail.ruleList[scope.$index].rangeOne" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" type="text" />
                </div>
                <div>kg</div>
                <div style="width:50px;margin:0 5px 0 20px;">
                  <el-input type="text" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].rangeOneCost)" maxlength="8" v-model="detail.ruleList[scope.$index].rangeOneCost" />
                </div>
                <div>元</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="重量区间2">
            <template slot-scope="scope">
              <div style="display:flex; justify-content:center;" v-if="detail.ruleList[scope.$index].rangeOne>0">
                <div>{{ detail.ruleList[scope.$index].rangeOne }} -</div>
                <div style="width:50px;margin:0 5px;">
                  <el-input maxlength="8" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].rangeTwo)" v-model="detail.ruleList[scope.$index].rangeTwo" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" type="text" />
                </div>
                <div>kg</div>
                <div style="width:50px;margin:0 5px 0 20px;">
                  <el-input type="text" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].rangeTwoCost)" maxlength="8" v-model="detail.ruleList[scope.$index].rangeTwoCost" />
                </div>
                <div>元</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="重量区间3">
            <template slot-scope="scope">
              <div style="display:flex; justify-content:center;" v-if="detail.ruleList[scope.$index].rangeTwo>0">
                <div>{{ detail.ruleList[scope.$index].rangeTwo }} -</div>
                <div style="width:50px;margin:0 5px;">
                  <el-input maxlength="8" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].rangeThree)" v-model=" detail.ruleList[scope.$index].rangeThree" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" type="text" />
                </div>
                <div>kg</div>
                <div style="width:50px;margin:0 5px 0 20px;">
                  <el-input type="text" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].rangeThreeCost)" maxlength="8" v-model="detail.ruleList[scope.$index].rangeThreeCost" />
                </div>
                <div>元</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="区间外续重">
            <template slot-scope="scope">
              <div style="display:flex; justify-content:center;">
                <div>每续</div>
                <div style="width:50px;margin:0 5px;">
                  <el-input maxlength="8" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].followOne)" v-model=" detail.ruleList[scope.$index].followOne" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" type="text" />
                </div>
                <div>kg</div>
                <div style="width:50px;margin:0 5px 0 20px;">
                  <el-input type="text" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].followOneCost)" maxlength="8" v-model="detail.ruleList[scope.$index].followOneCost" />
                </div>
                <div>元</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单包裹限制" width="120px">
            <template slot-scope="scope">
              <div style="display:flex; justify-content:center;">
                <div style="width:50px;margin:0 5px;">
                  <el-input maxlength="8" type="text" @keyup.native="keyupEvent($event,detail.ruleList[scope.$index].singleLimit)" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" v-model="detail.ruleList[scope.$index].singleLimit" />
                </div>
                <div>kg</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="删除" width="120px">
            <template slot-scope="scope">
              <div style="display:flex;justify-content:center;align-items:center;">
                <el-popconfirm title="确定删除吗？" @confirm="delModelItem(scope.$index)">
                  <el-button>删除</el-button>
                  <a slot="reference" href="javascript: void(0);" title="删除">
                    <i class="el-icon-delete-solid"></i>
                  </a>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="display: flex;justify-content: center;margin-top: 20px;">
      <el-button v-if="!isSee" @click="goBack">取 消</el-button>
      <el-button type="primary" @click="commitEdit">确 定</el-button>
    </div>

    <el-dialog title="选中地区" :visible.sync="addrModal" width="50%">
      <div>
        <area-cascader v-model="commitAddr" />
        <el-button type="primary" @click="areaSelect">确认选择</el-button>
        <div>
          <el-tag closable type="danger" v-for="(item,i) in selectedArea" :key="i" style=" margin:5px;" @close="delAddrList(i)">{{ item }}
          </el-tag>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addrModal = false">取 消</el-button>
        <el-button type="primary" @click="commitChooseAddr">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="停发地区设置" :visible.sync="outRangeModal" width="50%">
      <div>
        <div>
          <span>方式1：</span>
          <area-cascader v-model="commitAddr" />
          <el-button type="primary" @click="addToOutRangeList">确认添加</el-button>
        </div>
        <div style="margin-top: 10px;display: flex;align-items: center;">
          <span>方式2：</span>
          <el-upload  action="/" :http-request="importExcel" list-type="text" :show-file-list="false" >
            <el-button size="small"  type="primary">导入excel数据</el-button>
          </el-upload>
        </div>
        <div style="margin-top: 20px;">
          <el-button type="danger" @click="removeAllOutRange">清空</el-button>
        </div>
        <div style="margin-top: 10px;">
          <el-tag v-for="(tag,idx) in outRangeListEdit" :key="tag" closable type="info" style="margin:5px;" @close="delFromOutRangeList(idx)">{{ tag }}
          </el-tag>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outRangeModal = false">取 消</el-button>
        <el-button type="primary" @click="saveOutRangeListEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Objects from "COMJS/Objects"
import XLSX from 'xlsx'
import BimEnumService from "API/bim/BimEnumService";
import {
  getPage,
  getDetail,
  saveLogisticsCost,
  delLogisticsCost,
} from "@/api/express/LogisticsCostService.js";
import areaCascader from "COMPS/base/areaCascader.vue";

export default {
  components: {
    areaCascader,
  },
  data() {
    return {
      detail: {
        excludeGoodsCodeList: [],
        ruleList: [],
        outRangeRuleList:[]
      },
      outRangeList:[],
      outRangeListEdit:[],
      comOptions: [],
      calcModeOptions: [
        /*{
          label: "计件",
          value: "piece",
        },*/
        {
          label: "计重",
          value: "weight",
        },
      ],
      tagInputVisible: false,
      tagInputValue: "",
      addrModal: false,
      outRangeModal: false,
      currentAddr: 0,
      commitAddr: {},
      isSee: false,
      selectedArea: [],
    };
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      //编辑或查看
      getDetail(id).then((data) => {
        if (data.excludeGoodsCode) {
          data.excludeGoodsCodeList = data.excludeGoodsCode.split(",");
        } else {
          data.excludeGoodsCodeList = [];
        }
        data.ruleList.map((item) => {
          item.transportAreaList = item.transportArea.split(";");
          let len = item.transportAreaList.length;
          if (item.transportAreaList[len - 1] == "") {
            item.transportAreaList.pop();
          }
          return item;
        });
        //其中停发地区
        data.outRangeRuleList = data.ruleList.filter(a=>a.singleLimit==0)
        for (let item of data.outRangeRuleList) {
          for (let one of item.transportAreaList) {
            this.outRangeList.push(one)
          }
        }
        //console.log(this.outRangeList);
        this.outRangeList = this.outRangeList.sort(function (str1, str2) {
          return str1.localeCompare(str2, 'zh');
        });
        //console.log(this.outRangeList);
        //其中可发的地区
        data.ruleList = data.ruleList.filter(a=>a.singleLimit>0)
        this.detail = data;
      });
      this.isSee = this.$route.query.edit == 1 ? false : true;
    }
    this.getCompanyEnum();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    getCompanyEnum() {
      BimEnumService.getEnum("TRANSPORT_COMPANY").then((data) => {
        this.comOptions = data.map((e) => {
          return {
            value: e.value,
            label: e.name,
          };
        });
      });
    },

    //
    addModel() {
      this.detail.ruleList.push({
        transportAreaList: [],
      });
    },
    // 打开地区选中
    chooseAddr(i) {
      this.currentAddr = i;
      //必须要这样，子组件需要优化
      this.commitAddr = {
        province: "",
        city: "",
        area: "",
      };
      this.selectedArea = [];
      this.addrModal = true;
    },
    areaSelect() {
      //let province, city, area;
      let item;
      if (this.commitAddr.province) {
        item = this.commitAddr.province;
      }
      if (this.commitAddr.city) {
        item = item + "," + this.commitAddr.city;
      }
      if (this.commitAddr.area) {
        item = item + "," + this.commitAddr.area;
      }
      this.selectedArea.push(item);
    },
    commitChooseAddr() {
      let that = this;
      this.selectedArea.forEach((e) => {
        setTimeout(function () {
          let isExist = false;
          that.detail.ruleList.forEach((z, index) => {
            that.detail.ruleList[index].transportAreaList.forEach((element) => {
              if (element == e) {
                that.$message({
                  message:
                    "地区" +
                    e +
                    "在本模板中已有规则，设置失败。请删除其它规则后重试。",
                  type: "warning",
                });
                isExist = true;
                return;
              }
            });
          });
          if (!isExist) {
            that.detail.ruleList[that.currentAddr].transportAreaList.push(e);
            that.addrModal = false;
          }
        }, 100);
      });
      this.addrModal = false;
      this.selectedArea = [];
      this.commitAddr = {};
    },

    delAddrList(i) {
      this.selectedArea.splice(i, 1);
    },
    delAddr(i, j) {
      this.detail.ruleList[i].transportAreaList.splice(j, 1);
    },
    delExcludeGoods(i) {
      this.detail.excludeGoodsCodeList.splice(i, 1);
    },
    delModelItem(i) {
      this.detail.ruleList.splice(i, 1);
    },
    async commitEdit() {
      if (this.isSee) {
        this.goBack();
        return;
      }
      let modal = this.detail;
      if (!modal.expressCompany) {
        this.$message({
          message: "请选择快递公司",
          type: "warning",
        });
        return;
      }
      if (!modal.calcMode) {
        this.$message({
          message: "请选择计价方式",
          type: "warning",
        });
        return;
      }
      if (!modal.ruleList || modal.ruleList.length == 0) {
        this.$message({
          message: "至少需要一条规则",
          type: "warning",
        });
        return;
      }
      modal.ruleList.forEach(a=>{
        if(a.singleLimit<=0){
          this.$message({
            message: "单包裹重量必需大于0",
            type: "warning",
          });
          return;
        }
      })
      //把停发地区加进去
      for (let outRangeRuleListElement of modal.outRangeRuleList) {
        modal.ruleList.push(outRangeRuleListElement)
      }
      modal.ruleList = modal.ruleList.map((item) => {
        item.transportArea = item.transportAreaList.join(";");
        item.transportArea = item.transportArea + ";";
        return item;
      });
      modal.excludeGoodsCode = modal.excludeGoodsCodeList.join(",");
      let loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      await saveLogisticsCost(modal);
      loading.close();
      this.goBack();
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
        /^\d*\.\d*\./g,
        e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      this.input = e.target.value;
      //console.log(this.input);
    },
    showInput() {
      this.tagInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let tagInputValue = this.tagInputValue;
      if (tagInputValue) {
        this.detail.excludeGoodsCodeList.push(tagInputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = "";
    },
    //==========不配送地区编辑=============
    outRangeAreaEdit(){
      this.commitAddr = {
        province: "",
        city: "",
        area: "",
      };
      let newArray = []
      this.outRangeListEdit = Objects.deepCopy(newArray,this.outRangeList);
      this.outRangeModal = true;
    },
    removeAllOutRange(){
      this.outRangeListEdit = [];
    },
    delFromOutRangeList(idx){
      this.outRangeListEdit.splice(idx, 1);
    },
    addToOutRangeList(idx){
      //this.outRangeListEdit.splice(idx, 1);
      let item;
      if (this.commitAddr.province) {
        item = this.commitAddr.province;
      }
      if (this.commitAddr.city) {
        item = item + "," + this.commitAddr.city;
      }
      if (this.commitAddr.area) {
        item = item + "," + this.commitAddr.area;
      }
      if(this.outRangeListEdit.filter(a=>a===item).length>0){
        this.$message({
          message: "该地区已在不配送地区列表内，无需重复添加",
          type: "warning",
        });
        return
      }
      this.outRangeListEdit.push(item);
      this.outRangeListEdit = this.outRangeListEdit.sort(function (str1, str2) {
        return str1.localeCompare(str2, 'zh');
      });
    },
    importExcel (content) {
      const file = content.file
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split('.')[1]
      const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types)
      if (!fileType) {
        this.$message('格式错误！请重新选择')
        return
      }
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          let xlsxJson = tabJson
          let fileList = xlsxJson[0].sheet
          //console.log(fileList)
          if(fileList.length==0){
            this.$message('无停发区域内容')
            return
          }
          let province = '省级区划'
          let city = '地级市(省直)'
          let county = '县区'
          if(!fileList[0][province] || !fileList[0][city] || !fileList[0][county]){
            this.$message('停发区域内容格式不正确')
            return
          }
          for (let item of fileList) {
            let one
            if (item[province]) {
              one = item[province]
              if(one.indexOf('市')==one.length-1){
                //最后一个字是市，是直辖市，按照系统的规则需要去掉'市'
                one = one.substr(0,one.length-1)
              }
            }else{
              break
            }
            if (item[city] && item[city] != '全境') {
              one = one+","+item[city]
            }
            if (item[county] && item[county] != '全境') {
              one = one+","+item[county]
            }
            if(this.outRangeListEdit.filter(a=>a==one).length==0){
              this.outRangeListEdit.push(one)
            }
          }
          this.outRangeListEdit = this.outRangeListEdit.sort(function (str1, str2) {
            return str1.localeCompare(str2, 'zh');
          });
        }
      })
    },
    file2Xce (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          const result = []
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
          })
          resolve(result)
        }
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file) // 传统input方法
      })
    },
    saveOutRangeListEdit(){
      this.outRangeList = this.outRangeListEdit
      //50个一组,防止字段太长。数据库2048个字节，应该能放下。
      let result = [];
      for(let i=0;i<this.outRangeList.length;i+=50){
        result.push(this.outRangeList.slice(i,i+50));
      }
      this.detail.outRangeRuleList = []
      for (let resultElement of result) {
        let newOne = {"transportAreaList": [],"singleLimit":0}
        newOne.transportAreaList = resultElement
        this.detail.outRangeRuleList.push(newOne)
      }
      //console.log(this.detail)
      this.outRangeModal = false
    },
  },
};
</script>

<style scoped>
.d-tab div {
  white-space: nowrap;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
