<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="showAdd">添加品牌</el-button>
        <span class="search-wrapper">
          <el-input size="mini" v-model="searchInfo.brandName" placeholder="请输入品牌名称"></el-input>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </span>
      </div>
      <div class="filter_right">
        <el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{logType:'brand'}})">操作日志</el-button>
      </div>
    </div>
    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column prop="brandCode" label="品牌编号"></el-table-column>
      <el-table-column prop="brandName" label="品牌名称"></el-table-column>
      <el-table-column prop="brandShortName" label="品牌简称"></el-table-column>
      <el-table-column label="是否启用">
        <template slot-scope="scope">
          <el-switch :value="scope.row.isEnable" @click.native="toggleEnable(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTimeStr" label="创建时间"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="showDetail(scope.row.brandId)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" title="编辑" @click="showEditBrand(scope.row.brandId)">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <a href="javascript: void(0);" title="删除">
              <i class="el-icon-delete-solid" @click="deleteBrand(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <brand-add-popup ref="brandAddPopup" @updateTable="loadTableData" :brandId="currentId"></brand-add-popup>
    <brand-detail-popup ref="brandDetailPopup" :brandId="currentId"></brand-detail-popup>
  </div>
</template>

<script>
import brandAddPopup from "./brandAddPopup.vue";
import PimBrandService from "API/pim/PimBrandService";
import pageTableMixin from "@/mixin/pageTableMixin";
import tools from "COMJS/tools";
import brandDetailPopup from "./brandDetailPopup";

export default {
  name: "stockList",
  data() {
    return {
      searchInfo: {
        brandName: ""
      },
      currentId: ""
    };
  },
  mixins: [pageTableMixin],
  components: {
    brandAddPopup,
    brandDetailPopup
  },
  mounted() {},
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      PimBrandService.queryBrandPages(this.pageInfo, this.searchConfig).then(
        data => {
          data.list = data.list.map(e => {
            e.createTimeStr = tools.formatDate(
              new Date(e.createTime),
              "yyyy-MM-dd hh:mm:ss"
            );
            e.isEnable = e.visible == 1;
            e.brandNo = e.brandCode;
            return e;
          });
          this.querySuccess(data);
        }
      );
    },
    filterProduct(item, index) {},
    addProduct() {
      this.$router.push("/pim/productAdd");
    },
    deleteBrand(row) {
      this.$confirm(`是否删除"${row.brandName}"品牌?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          PimBrandService.deleteBrand(row.brandId).then(() => {
            this.loadTableData();
          });
        })
        .catch(() => {});
    },
    showAdd() {
      this.currentId = "";
      this.$refs.brandAddPopup.dialogFormVisible = true;
    },
    showDetail(id) {
      this.currentId = id;
      this.$refs.brandDetailPopup.dialogVisible = true;
    },
    showEditBrand(id) {
      this.currentId = id;
      this.$refs.brandAddPopup.dialogFormVisible = true;
    },
    toggleEnable(row) {
      this.$confirm(`是否切换品牌'${row.brandName}'的启用状态？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          row.visible = row.isEnable ? 0 : 1 // 切换状态 
          PimBrandService.saveBarnd(row).then(res => {
            this.loadTableData();
          });
        })
        .catch(() => {});
    }
  },
  computed: {}
};
</script>
