import axiosHttp from "../axiosHttp";

export default {
  // 发货批次分页查询
  queryOrderPages(params) {
    return axiosHttp.POST('/somOrderShip/queryPage', params, false);
  },
  // 订单导出
  exportOrderPackage(params) {
    return axiosHttp.POST('/somOrderShip/exportOrder', params);
  },
  // 导入运单号前先检查
  shipImportCheckData(fileName) {
    return axiosHttp.POST('/somOrderShip/shipImportCheckData', {
      fileName,
      remark: ""
    }, false)
  },
  // 导入运单号
  importOrderNO(fileName) {
    return axiosHttp.POST('/somOrderShip/importChinaPostList', {
      fileName,
      remark: ""
    })
  },
  exportShipTemplate() {
    return axiosHttp.POST('/somOrderShip/exportShipTemplate');
  },
}