<template>
  <div>
    <div class="step-list">
      <div class="step-item" :class="{'is-active': active >= index}" v-for="(item, index) in steps">
        <div class="step-line">{{index+1}}</div>
        <div class="setp-title">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: function() {
        return [];
      }
    },
    active: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped lang="scss">
.step-list {
  width: 100%;
  align-items: center;
  margin-bottom: 40px;

  .step-item {
    display: inline-block;
    width: 300px;
    text-align: center;
    margin-right: 50px;

    &:last-of-type {
      margin-right: 0px;
    }

    &.is-active {
      .setp-title {
        color: #1b73e8;
      }

      .step-line {
        background-color: #1b73e8;

        &::before {
          background-color: #1b73e8;
        }
      }
    }

    .step-title {
      color: #666;
    }

    .step-line {
      position: relative;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 50%;
      color: #fff;
      background-color: #999;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 6px;
        background-color: #999;
        z-index: -1;
      }
    }
  }
}
</style>