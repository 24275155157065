<template>
  <div class="manual-order">
    <div class="goods-info box-shadow-unify">
      <div class="box-title">
        商品信息
        <el-button type="primary" @click="addGoods">新增商品</el-button>
      </div>
      <ws-page-table :data="goodsList" ref="pageTable" :pageable="false" :showIndex="true">
        <el-table-column label="商品名称">
          <template slot-scope="scope">
            <div class="search-input">
              <el-input
                v-model="scope.row.name"
                @input="inputGoods(scope.$index)"
                @click.native="focusGoods(scope.$index)"
                maxlength="100"
              ></el-input>
              <div
                class="search-result"
                v-click-outside="closeResult"
                v-if="goodsSearchResultList.length && currentIndex == scope.$index"
              >
                <div
                  class="result-item"
                  v-for="(item, index) in goodsSearchResultList"
                  :key="index"
                  @click="selectResult(scope.$index, item)"
                >
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="下单数量">
          <template slot-scope="scope">
            <el-input
              style="width: 120px;"
              :disabled="!scope.row.id"
              maxlength="11"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              placeholder="请先选择商品"
              v-model="scope.row.num"
              @input="computedTotalPrice(scope.row, scope.$index)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="商品单价" width="150px">
          <template slot-scope="scope">
            <span>{{scope.row.goodsPrice ? '￥' + chargeFormat.thousandsOf(scope.row.goodsPrice) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品总价" width="150px">
          <template slot-scope="scope">
            <span>{{scope.row.goodsTotalPrice ? '￥'+scope.row.goodsTotalPrice: ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid" @click="deleteGoods(scope.$index)"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <div class="addressee-info box-shadow-unify">
      <div class="box-title">收件人信息</div>
      <el-form label-width="130px">
        <el-form-item label="收货人：">
          <el-input v-model="addresseeInfo.name" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input v-model="addresseeInfo.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="收货地址：">
          <area-cascader v-model="addresseeInfo.address"></area-cascader>
          <el-input v-model="addresseeInfo.dtailedAddress" placeholder="请填写详细地址"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="price-info box-shadow-unify">
      <div class="box-title">价格信息</div>
      <el-form label-width="130px">
        <el-form-item label="商品总价：">
          <span>￥{{thousandsOfAllGoodsTotalPrice || 0}}&nbsp;</span>
        </el-form-item>
        <!-- <el-form-item label="运费：">
          <el-input size="mini" v-model="freight" style="width: 90px;"></el-input>&nbsp;元
        </el-form-item>-->
        <el-form-item label="订单总价：">
          <span style="color: #E60000;font-size: 14px;">￥{{orderTotalPrice || 0}}&nbsp;</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" @click="checkOrder">下单</el-button>
    </div>
    <el-dialog title="下单" :visible.sync="checkOrderPopup" width="560px">
      <div class="order-form">
        <el-table :data="goodsList" border>
          <el-table-column label="商品名称" prop="name" show-overflow-tooltip width="150px" >
            <template slot-scope="scope">
              <div style="overflow: hidden;width: 145px;">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="下单数量" prop="num" show-overflow-tooltip width="50px"></el-table-column>
          <el-table-column label="商品单价" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.goodsPrice ? '￥' + chargeFormat.thousandsOf(scope.row.goodsPrice) : ''}}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品总价" prop="goodsTotalPrice" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="form-item">
          <div class="form-label">收件人姓名：</div>
          <div class="form-value">{{addresseeInfo.name}}</div>
        </div>
        <div class="form-item">
          <div class="form-label">收件人电话：</div>
          <div class="form-value">{{addresseeInfo.phone}}</div>
        </div>
        <div class="form-item">
          <div class="form-label">收件人地址：</div>
          <div class="form-value">{{detailAddress}}</div>
        </div>
        <div class="form-item">
          <div class="form-label">商品总价：</div>
          <div class="form-value">￥{{thousandsOfAllGoodsTotalPrice || 0}}</div>
        </div>
        <div class="form-item">
          <div class="form-label">运费：</div>
          <div class="form-value">￥{{freight || 0}}</div>
        </div>
        <div class="form-item">
          <div class="form-label">订单总价：</div>
          <div class="form-value">￥{{orderTotalPrice || 0}}</div>
        </div>
        <div class="form-item">
          <div class="form-label">下单个数：</div>
          <div class="form-value">
            <el-input size="mini" v-model="createNumber"></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkOrderPopup = false">取 消</el-button>
        <el-button type="primary" @click="createOrder">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import chargeFormat from "COMJS/chargeFormat.js";
import areaCascader from "COMPS/base/areaCascader.vue";
import orderService from "API/som/orderService";
import orderOperate from "API/som/orderOperate";
import PimGoodsService from "API/pim/PimGoodsService";
import tools from "COMJS/tools.js";
import validator from "COMJS/validator";

export default {
  name: "manua-order",
  data() {
    return {
      goodsList: [
        {
          name: "",
          num: "",
          goodsPrice: "",
          goodsTotalPrice: ""
        }
      ],
      goodsSearchResultList: [],
      currentIndex: -1, // 当前操作的商品标识
      addresseeInfo: {
        name: "",
        phone: "",
        address: {
          province: "",
          city: "",
          area: ""
        },
        dtailedAddress: ""
      },
      freight: "",
      priceInfo: {},
      allGoodsTotalPrice: null,
      checkOrderPopup: false,
      createNumber:1,
    };
  },
  components: { areaCascader },
  computed: {
    detailAddress() {
      return (
        this.addresseeInfo.address.province +
        this.addresseeInfo.address.city +
        this.addresseeInfo.address.area +
        this.addresseeInfo.dtailedAddress
      );
    },
    thousandsOfAllGoodsTotalPrice() {
      return chargeFormat.thousandsOf(this.allGoodsTotalPrice);
    },
    orderTotalPrice() {
      return chargeFormat.thousandsOf(
        Number(this.allGoodsTotalPrice) + Number(this.freight)
      );
    },
    chargeFormat() {
      return chargeFormat;
    }
  },
  methods: {
    // 计算商品总价
    computedAllGoodsTotalPrice() {
      let _totalPrice = 0;
      this.goodsList.forEach(e => {
        if (e.goodsTotalPrice) {
          let tp = Number(e.goodsPrice) * Number(e.num);
          _totalPrice += tp;
        }
      });
      this.allGoodsTotalPrice = _totalPrice;
    },
    // 新增
    addGoods() {
      let goodsInfo = {
        name: "",
        num: "",
        goodsPrice: ""
      };
      this.goodsList.push(goodsInfo);
    },
    // 计算单个商品总价
    computedTotalPrice(row, index) {
      if (!row.goodsPrice || isNaN(Number(row.num)) || Number(row.num) < 1) {
        return "";
      }
      this.goodsList[index].goodsTotalPrice = chargeFormat.thousandsOf(
        Number(row.goodsPrice) * Number(row.num)
      );
      this.computedAllGoodsTotalPrice();
    },
    // 删除
    deleteGoods(index) {
      if (this.goodsList.length == 1) return;
      this.goodsList.splice(index, 1);
      this.computedAllGoodsTotalPrice();
    },
    // 商品输入
    inputGoods: tools.debounce(function(index) {
      this.currentIndex = index;
      this.goodsList[index].id = "";
      this.goodsList[index].num = "";
      this.goodsList[index].goodsPrice = "";
      this.goodsList[index].goodsTotalPrice = "";
      this.computedAllGoodsTotalPrice();
      this.goodsSearchChange();
    }, 100),
    // 商品输入框聚焦
    focusGoods(index) {
      this.currentIndex = index;
      this.goodsSearchChange();
    },
    // 查询商品
    goodsSearchChange() {
      PimGoodsService.queryAllGoods({
        cnName: this.goodsList[this.currentIndex].name
      }).then(data => {
        let goodsNameList = this.goodsList.map(e => e.name);
        // 过滤已填写商品
        let _result = data.map(e => {
          if (!goodsNameList.includes(e.cnName)) {
            return {
              name: e.cnName,
              id: e.goodsId,
              goodsPrice: e.initPrice
            };
          }
        });
        this.goodsSearchResultList = _result.filter(e => e);
      });
    },
    // 选择搜索结果
    selectResult(index, selectedGoods) {
      let goods = {
        name: selectedGoods.name,
        goodsPrice: selectedGoods.goodsPrice,
        id: selectedGoods.id,
        num: 1
      };
      this.$set(this.goodsList, index, goods);
      this.computedTotalPrice(goods, index);
      this.closeResult();
    },
    // 关闭搜索结果
    closeResult() {
      this.goodsSearchResultList = [];
      this.currentIndex = -1;
    },
    // 下单
    createOrder() {
      let list = this.goodsList;
      let params = {
        province: this.addresseeInfo.address.province,
        city: this.addresseeInfo.address.city,
        county: this.addresseeInfo.address.area,
        addressDetail: this.addresseeInfo.dtailedAddress,
        goodsInfoList: list.map(e => {
          return `${e.id}*${e.num}`;
        }),
        name: this.addresseeInfo.name,
        phoneNum: this.addresseeInfo.phone
      };
      let loading = this.$loading({
        lock: true,
        text: "下单中..."
      });
      var that = this;

      for (let i = 0; i < this.createNumber; i++) {
        orderService.createOrder(params).then(res => {
          //loading.close();
          //that.checkOrderPopup = false;
        }).catch(()=>{
          //loading.close();
        });
      }
      let timer = setTimeout(function () {
        loading.close();
        that.checkOrderPopup = false;
      }, 3000)
    },
    checkOrder() {
      let list = this.goodsList;
      for (let i in list) {
        if (!list[i].id && !list[i].name) {
          return this.$message({
            message: "请填写完整商品信息",
            type: "warning"
          });
        }
      }
      let obj = this.addresseeInfo;
      if (!obj.name || !obj.phone || !obj.address.area || !obj.dtailedAddress) {
        return this.$message({
          message: "请填写完整收件人信息",
          type: "warning"
        });
      }
      if (!validator.mobile(obj.phone)) {
        return this.$message({
          message: "手机号格式不正确",
          type: "warning"
        });
      }
      let params = {
        province: this.addresseeInfo.address.province,
        city: this.addresseeInfo.address.city,
        county: this.addresseeInfo.address.area,
        addressDetail: this.addresseeInfo.dtailedAddress,
        goodsInfoList: list.map(e => {
          return `${e.id}*${e.num}`;
        }),
        name: this.addresseeInfo.name,
        phoneNum: this.addresseeInfo.phone
      };
      let loading = this.$loading({
        lock: true,
        text: "加载中..."
      });
      orderService.calcFreight(params).then(data => {
        if (Number(data.price) < 0) {
          return this.$alert(data.msg, "提示", {
            confirmButtonText: "确定",
            type: "warning",
            callback: action => {
              loading.close();
            }
          });
        }
        this.freight = data.price;
        loading.close();
        this.checkOrderPopup = true;
        this.createNumber = 1;
      }).catch(()=>{
          loading.close();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.manual-order /deep/ {
  .el-table {
    overflow: visible;
  }
  .el-table .cell {
    overflow: visible;
    white-space: nowrap;
  }
  .el-table__body-wrapper {
    overflow: visible;
  }
  .el-table__body-wrapper .cell {
    overflow: visible;
  }
  > div {
    margin-bottom: 40px;
  }
  .box-title {
    border-bottom: 1px solid #e9ecef;
    .el-button {
      margin-left: 40px;
    }
  }
  .el-form {
    padding: 15px 0;
  }
  .goods-info {
    .ws-page-table {
      padding-bottom: 15px;
    }
  }
  .bottom-btn {
    text-align: center;
    .el-button {
      padding: 9px 35px;
    }
  }
  .order-form {
    /deep/ .el-table {
      margin-bottom: 15px;
      th,
      td {
        padding: 0;
        font-weight: normal;
        line-height: 36px;
        height: 36px;
        font-size: 14px;
        color: #8898aa;
        .cell {
          line-height: 36px;
          height: 36px;
        }
      }
      .cell {
        font-size: 14px;
      }
    }
    .form-item {
      display: flex;
      width: 100%;
      line-height: 24px;
      .form-label {
        width: 100px;
        text-align: right;
      }
      .form-value {
        flex: 1;
      }
    }
  }
}
</style>