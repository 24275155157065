<template>
  <div class="manual-order">
    <div class="box-shadow-unify">
      <div class="box-title">基础信息</div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <el-form label-width="130px">
          <el-form-item required label="记录时间：">
            <el-date-picker
                v-model="chooseCreateDate"
                size="mini"
                type="date"
                placeholder="记录时间"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="box-shadow-unify">
      <div class="box-title">商品信息</div>
      <ws-page-table :data="goodsList" ref="pageTable" :pageable="false" :showIndex="true">
        <el-table-column label="商品名称">
          <template slot-scope="scope">
            <div class="search-input">
              <el-input v-model="scope.row.name" disabled></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope">
            <el-input
                onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请填写数量" v-model="scope.row.num"
                maxLength="8"
                @input="computedTotalPrice(scope.row, scope.$index)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="批次">
          <template slot-scope="scope">
            <el-input placeholder="请填写批次号" v-model="scope.row.batch"
                      maxLength="32"
                      v-if="scope.$index==0">
              <template slot="prepend">D.B.</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="商品单价" width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.goodsPrice ? '￥' + chargeFormat.thousandsOf(scope.row.goodsPrice) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品总价" width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.goodsTotalPrice ? '￥' + scope.row.goodsTotalPrice : '' }}</span>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <div class="box-shadow-unify">
      <div class="box-title">收件信息</div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <el-form label-width="130px">
          <el-form-item label="收货人：">
            <el-input v-model="addresseeInfo.name"
                      @input="searchAddressFromAll"
                      maxlength="10" placeholder="姓名" class="mr15">
              <i class="el-icon-search el-input__icon" slot="suffix"></i>
            </el-input>
            <el-input v-model="addresseeInfo.phone"
                      @input="searchAddressFromAll"
                      maxlength="11" placeholder="手机号码" class="mr15">
              <i class="el-icon-search el-input__icon" slot="suffix"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="收货地址：">
            <area-cascader v-model="addresseeInfo.address"></area-cascader>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="addresseeInfo.detailAddress"
                      placeholder="详细地址" class="el-input-long"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="addresseeInfo.remark"
                      placeholder="地址的备注信息，如真实姓名等" class="el-input-long"></el-input>
          </el-form-item>
        </el-form>

        <div style="margin: 15px 15px 15px auto;display: flex;align-items: center;border-left: 1px dashed #d3d3d3;">
          <el-button icon="el-icon-back"
                     type="primary" plain
                     style="height: 60px;margin-right: 15px;margin-left: 15px;"
                     @click="addresseeInfoMixInfoCheck">识别
          </el-button>
          <el-input
              type="textarea" :rows="5"
              v-model="addresseeInfo.mixStr"
              placeholder="智能识别"
              class="el-input-long">
          </el-input>
        </div>
      </div>
    </div>

    <div class="box-shadow-unify">
      <div class="box-title">收件信息匹配</div>
      <div style="display: flex;justify-content: space-between;">
        <div class="mb20" style="width: 50%">
          <div v-for="(item,idx) in searchAddressList" :key="idx" class="ml15 mt15">
            <el-radio v-model="selectedAddressId" border
                      :label="item.customerAddressId"
                      @input="selectFromSearchList">
              {{ item.recipientName }}，{{ item.recipientPhoneNum }}，{{ item.recipientProvince }}
              {{ item.recipientCity }} {{ item.recipientCounty }} {{ item.recipientAddressDetail }}
              <span v-if="item.remark">（{{ item.remark }}）</span>
            </el-radio>
          </div>
          <div class="ml15 mt15">
            <el-radio v-model="selectedAddressId" border
                      label="newCustomerAddress"
                      @input="selectFromSearchList">
              做为新地址保存
            </el-radio>
          </div>
        </div>
        <div style="width: 25%;border-left:  1px dashed #d3d3d3;border-right:  1px dashed #d3d3d3;">
          <el-form label-width="130px" :inline-message="true">
            <el-form-item label="客户" :error="targetCustomerIsNew?'* 新客户':''">
              <el-autocomplete
                  :disabled="selectedAddressId!='newCustomerAddress'"
                  popper-class="my-autocomplete"
                  v-model="targetCustomer.customerName"
                  :fetch-suggestions="querySearchCustomer"
                  placeholder="请输入内容"
                  @select="handleSelectCustomer">
                <i class="el-icon-search el-input__icon" slot="suffix"></i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.customerName }}</div>
                  <span class="addr">{{ item.address }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="单位">
              <el-input v-model="targetCustomer.customerWorkUnit" :disabled="!targetCustomerIsNew"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="targetCustomer.customerRemark" :disabled="!targetCustomerIsNew"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="width: 25%">
          <div class="ml15 mt15">
            <div style="height: 100px;">
              <p class="mb15">本地址近期订单</p>
              <el-row v-if="ordersInThisAddress.length>0" style="font-size: 12px;margin-bottom: 5px;">
                <el-col :span="8">时间</el-col>
                <el-col :span="8">商品</el-col>
                <el-col :span="8">批次</el-col>
              </el-row>
              <el-row v-for="(item,idx) in ordersInThisAddress" :key="idx" style="font-size: 12px;margin-bottom: 5px;">
                <el-col :span="8">{{ tools.formatTime(item.recordDate) }}</el-col>
                <el-col :span="8">
                  鲜奶两瓶装{{ item.milkCount }}份
                  <span v-if="item.giftBoxCount>0">礼盒{{ item.giftBoxCount }}份</span>
                </el-col>
                <el-col :span="8">
                  {{ item.milkBatchCode }}
                </el-col>
              </el-row>
            </div>
            <div style="height: 100px;">
              <p class="mb15">客户近期订单</p>
              <el-row v-if="ordersInThisCustomer.length>0" style="font-size: 12px;margin-bottom: 5px;">
                <el-col :span="8">时间</el-col>
                <el-col :span="8">商品</el-col>
                <el-col :span="8">批次</el-col>
              </el-row>
              <el-row v-for="(item,idx) in ordersInThisCustomer" :key="idx" style="font-size: 12px;margin-bottom: 5px;">
                <el-col :span="8">{{ tools.formatTime(item.recordDate) }}</el-col>
                <el-col :span="8">
                  鲜奶两瓶装{{ item.milkCount }}份
                  <span v-if="item.giftBoxCount>0">礼盒{{ item.giftBoxCount }}份</span>
                </el-col>
                <el-col :span="8">
                  {{ item.milkBatchCode }}
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-btn">
      <el-button type="primary" @click="createOrderReady">下单</el-button>
      <p class="mt15">（记录时间：{{tools.formatTime(chooseCreateDate)}}）</p>
    </div>

  </div>
</template>

<script>
import chargeFormat from "COMJS/chargeFormat.js";
import areaCascader from "COMPS/base/areaCascader.vue";
import bimAreaService from "API/bim/BimAreaService";
import customerService from "API/usr/CustomerService";
import milkOrderService from "API/fm/MilkOrderService";
import tools from "COMJS/tools";
import Set from "COMJS/Set";

export default {
  name: "manua-order",
  data() {
    return {
      chooseCreateDate: new Date(),
      goodsList: [
        {
          name: "鲜奶两瓶装",
          num: "1",
          goodsPrice: "140",
          goodsTotalPrice: "140"
        },
        {
          name: "鲜奶礼盒",
          num: "0",
          goodsPrice: "40",
          goodsTotalPrice: "0"
        }
      ],
      goodsInfo: {milkCount: 1, giftBoxCount: 0},
      addresseeInfo: {
        name: "",
        phone: "",
        address: {
          province: "",
          city: "",
          area: ""
        },
        detailAddress: "",
        mixStr: ""
      },
      freight: "",
      allGoodsTotalPrice: null,
      checkOrderPopup: false,
      //地址匹配
      allAddressList: [],
      searchAddressList: [],
      selectedAddressId: '',
      //客户匹配
      allCustomerList: [],
      targetCustomerIsNew: false,
      targetCustomer: {},
      //订单查找
      ordersInThisCustomer: [],
      ordersInThisAddress: [],
    };
  },
  components: {areaCascader},
  computed: {
    tools() {
      return tools;
    },
    /*detailAddress() {
      return (
          this.addresseeInfo.address.province +
          this.addresseeInfo.address.city +
          this.addresseeInfo.address.area +
          this.addresseeInfo.detailAddress
      );
    },
    thousandsOfAllGoodsTotalPrice() {
      return chargeFormat.thousandsOf(this.allGoodsTotalPrice);
    },
    orderTotalPrice() {
      return chargeFormat.thousandsOf(
          Number(this.allGoodsTotalPrice) + Number(this.freight)
      );
    },*/
    chargeFormat() {
      return chargeFormat;
    }
  },
  mounted() {
    this.listAllCustomerAddress()
    this.listAllCustomer()
  },
  methods: {
    // 计算商品总价
    computedAllGoodsTotalPrice() {
      let _totalPrice = 0;
      this.goodsList.forEach(e => {
        if (e.goodsTotalPrice) {
          let tp = Number(e.goodsPrice) * Number(e.num);
          _totalPrice += tp;
        }
      });
      this.allGoodsTotalPrice = _totalPrice;
    },
    // 计算单个商品总价
    computedTotalPrice(row, index) {
      if (!row.goodsPrice || isNaN(Number(row.num)) || Number(row.num) < 0) {
        return "";
      }
      this.goodsList[index].goodsTotalPrice = chargeFormat.thousandsOf(
          Number(row.goodsPrice) * Number(row.num)
      );
      this.computedAllGoodsTotalPrice();
    },
    //地址智能识别
    addresseeInfoMixInfoCheck() {
      let mixInfo = this.addresseeInfo.mixStr
      if (!mixInfo || mixInfo == '') {
        return;
      }
      let param = {
        info: mixInfo
      }
      bimAreaService.getAddrAnalysisResult(param).then(res => {
        if (res) {
          this.addresseeInfo = {
            name: res.name,
            phone: res.tel,
            address: {
              province: res.province,
              city: res.city,
              area: res.region,
            },
            detailAddress: res.addrStr,
            mixStr: mixInfo
          }
          this.searchAddressFromAll();
        }
      })
    },
    //搜索地址
    listAllCustomerAddress() {
      customerService.queryAddressList({}).then(res => {
        if (res && res.length > 0) {
          this.allAddressList = res;
          this.searchAddressFromAll();
        }
      })
    },
    searchAddressFromAll() {
      if (!this.allAddressList || this.allAddressList.length == 0) {
        return;
      }
      let addSet = {};
      //let list = []
      let name = this.addresseeInfo.name
      let phone = this.addresseeInfo.phone
      if (name || phone) {
        let allList = this.allAddressList
        if (name) {
          let temp = allList.filter(e => e.recipientName.indexOf(name) == 0)
          for (let item of temp) {
            addSet[item.customerAddressId]=item;
          }
        }
        if (phone) {
          let temp = allList.filter(e => e.recipientPhoneNum.indexOf(phone) == 0)
          for (let item of temp) {
            addSet[item.customerAddressId]=item;
          }
        }
      }
      let list = Object.values(addSet);
      //console.log(list)
      this.searchAddressList = list;
      if (list.length == 1) {
        let item = list[0]
        if (item.recipientName == name && item.recipientPhoneNum == phone
            && item.recipientProvince == this.addresseeInfo.address.province
            && item.recipientCity == this.addresseeInfo.address.city
            && item.recipientCounty == this.addresseeInfo.address.area
            && item.recipientPhoneNum == this.addresseeInfo.detailAddress) {
          //只有一个结果，且姓名、电话、地址都一样，自动选中
          this.selectFromSearchList(item.customerAddressId)
        }
      } else if (list.length == 0) {
        this.selectFromSearchList('newCustomerAddress')
      }
    },
    selectFromSearchList(id) {
      this.selectedAddressId = id;
      this.listMilkOrderInThisAddress();
      if (this.selectedAddressId == 'newCustomerAddress') {
        //做为新地址保存
        this.targetCustomer = {}
        this.listMilkOrderInThisCustomer();
        return;
      }
      let item = this.allAddressList.filter(e => e.customerAddressId == id)[0]
      this.addresseeInfo = {
        name: item.recipientName,
        phone: item.recipientPhoneNum,
        address: {
          province: item.recipientProvince,
          city: item.recipientCity,
          area: item.recipientCounty,
        },
        detailAddress: item.recipientAddressDetail,
        remark: item.remark
      }
      let customer = this.allCustomerList.filter(e => e.customerId == item.customerId)[0]
      this.targetCustomer = {
        customerId: customer.customerId,
        customerName: customer.customerName,
        customerWorkUnit: customer.customerWorkUnit,
        customerRemark: customer.customerRemark,
      }
      this.listMilkOrderInThisCustomer();
      this.targetCustomerIsNew = false;
    },
    //搜索客户
    listAllCustomer() {
      customerService.queryCustomerList({}).then(res => {
        this.allCustomerList = res;
      })
    },
    querySearchCustomer(queryString, cb) {
      let customerList = this.allCustomerList;
      let results = queryString ? customerList.filter(this.createFilterCustomer(queryString)) : customerList;
      if (results.length == 0) {
        this.targetCustomerIsNew = true;
        this.targetCustomer.customerId = "newCustomer";
        this.listMilkOrderInThisCustomer()
      } else {
        this.targetCustomerIsNew = false;
        this.targetCustomer.customerId = "";
      }
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilterCustomer(queryString) {
      return (item) => {
        return (item.customerName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelectCustomer(item) {
      this.targetCustomer = {
        ...item
      };
      this.listMilkOrderInThisCustomer()
    },
    //查询订单
    listMilkOrderInThisCustomer() {
      this.ordersInThisCustomer = []
      if (this.targetCustomer.customerId == 'newCustomer') {
        return;
      }
      if (!this.targetCustomer.customerName) {
        return;
      }
      let param = {
        customerId: this.targetCustomer.customerId
      }
      milkOrderService.listMilkOrder(param).then(res => {
        if (res) {
          if (res.length > 3) {
            this.ordersInThisCustomer = res.slice(0, 3)
          } else {
            this.ordersInThisCustomer = res
          }
        }
      })
    },
    listMilkOrderInThisAddress() {
      this.ordersInThisAddress = []
      if (this.selectedAddressId == 'newCustomerAddress') {
        return;
      }
      let param = {
        customerAddressId: this.selectedAddressId
      }
      milkOrderService.listMilkOrder(param).then(res => {
        if (res) {
          if (res.length > 3) {
            this.ordersInThisAddress = res.slice(0, 3)
          } else {
            this.ordersInThisAddress = res
          }
        }
      })
    },
    // 下单
    checkData() {
      if (!this.chooseCreateDate) {
        this.$message({
          message: "请选择记录日期",
          type: "warning"
        });
        return false;
      }
      if (!this.addresseeInfo || !this.addresseeInfo.name
          || !this.addresseeInfo.phone || !this.addresseeInfo.detailAddress
          || !this.addresseeInfo.address || !this.addresseeInfo.address.province
          || !this.addresseeInfo.address.city || !this.addresseeInfo.address.area) {
        //收货信息不完整
        this.$message({
          message: "收货信息不完整",
          type: "warning"
        });
        return false;
      }
      if (!this.selectedAddressId) {
        this.$message({
          message: "请选择匹配的收货信息或选择保存新地址",
          type: "warning"
        });
        return false;
      }
      if (!this.targetCustomer || !this.targetCustomer.customerId || !this.targetCustomer.customerName) {
        this.$message({
          message: "客户信息不完整",
          type: "warning"
        });
        return false;
      }
      return true;
    },
    createOrderReady() {
      console.log(this.chooseCreateDate)
      //检查
      if (!this.checkData()) {
        return;
      }
      //确认客户，如果新的要去新建
      let targetCustomerAddressId;
      let targetCustomerId;
      if (this.selectedAddressId == 'newCustomerAddress') {
        this.saveNewCustomerAddress();
      } else {
        targetCustomerAddressId = this.selectedAddressId;
        targetCustomerId = this.targetCustomer.customerId;
        this.createOrder(targetCustomerId, targetCustomerAddressId);
      }
    },
    saveNewCustomerAddress() {
      let param = {
        recipientName: this.addresseeInfo.name,
        recipientPhoneNum: this.addresseeInfo.phone,
        recipientProvince: this.addresseeInfo.address.province,
        recipientCity: this.addresseeInfo.address.city,
        recipientCounty: this.addresseeInfo.address.area,
        recipientAddressDetail: this.addresseeInfo.detailAddress,
        remark: this.addresseeInfo.remark,
        recordDate: this.chooseCreateDate,
        customerId: '',
        customerName: '',
        customerRemark: '',
        customerWorkUnit: ''
      }
      let refreshAllCustomerList = false;
      if (this.targetCustomer.customerId == 'newCustomer') {
        //新创建客户
        param.customerName = this.targetCustomer.customerName
        param.customerRemark = this.targetCustomer.customerRemark
        param.customerWorkUnit = this.targetCustomer.customerWorkUnit
        //有新建客户，保存完要this.listAllCustomer()
        refreshAllCustomerList = true;
      } else {
        param.customerId = this.targetCustomer.customerId;
      }
      customerService.createNewAddress(param).then(res => {
        //this.$message({message: "保存成功", type: "success"});
        if (res) {
          //刷新，加入新建的地址和客户
          this.listAllCustomerAddress();
          if (refreshAllCustomerList) {
            this.listAllCustomer()
          }
          this.selectedAddressId = res.customerAddressId;
          this.targetCustomer.customerId = res.customerId;
          this.targetCustomerIsNew = false;
          this.createOrder(res.customerId, res.customerAddressId)
        }
      })
    },
    createOrder(targetCustomerId, targetCustomerAddressId) {
      let param = {
        customerId: targetCustomerId,
        customerAddressId: targetCustomerAddressId,
        recipientName: this.addresseeInfo.name,
        recipientPhoneNum: this.addresseeInfo.phone,
        recipientProvince: this.addresseeInfo.address.province,
        recipientCity: this.addresseeInfo.address.city,
        recipientCounty: this.addresseeInfo.address.area,
        recipientAddressDetail: this.addresseeInfo.detailAddress,
        milkCount: this.goodsList[0].num,
        giftBoxCount: this.goodsList[1].num,
        giftCardCount: 0,
        milkBatchCode: (this.goodsList[0].batch && this.goodsList[0].batch.length > 0)
            ? ('D.B.' + this.goodsList[0].batch) : '',
        recordDate: this.chooseCreateDate,
      }
      let loading = this.$loading({
        lock: true,
        text: "下单中..."
      });
      let that = this;
      milkOrderService.createCustomerOrder(param).then(res => {
        this.$message({message: "下单成功", type: "success"});
        loading.close();
        //that.checkOrderPopup = false;

        this.listMilkOrderInThisCustomer();
        this.listMilkOrderInThisAddress();
      }).catch(() => {
        loading.close();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.manual-order /deep/ {
  .el-input {
    width: 200px !important;
  }

  .el-input-long {
    width: 630px !important;
  }

  .el-table {
    overflow: visible;
  }

  .el-table .cell {
    overflow: visible;
    white-space: nowrap;
  }

  .el-table__body-wrapper {
    overflow: visible;
  }

  .el-table__body-wrapper .cell {
    overflow: visible;
  }

  > div {
    margin-bottom: 40px;
  }

  .box-title {
    border-bottom: 1px solid #e9ecef;

    .el-button {
      margin-left: 40px;
    }
  }

  .el-form {
    padding: 15px 0;
  }

  .bottom-btn {
    text-align: center;

    .el-button {
      padding: 9px 35px;
    }
  }

  .order-form {
    /deep/ .el-table {
      margin-bottom: 15px;

      th,
      td {
        padding: 0;
        font-weight: normal;
        line-height: 36px;
        height: 36px;
        font-size: 14px;
        color: #8898aa;

        .cell {
          line-height: 36px;
          height: 36px;
        }
      }

      .cell {
        font-size: 14px;
      }
    }

    .form-item {
      display: flex;
      width: 100%;
      line-height: 24px;

      .form-label {
        width: 100px;
        text-align: right;
      }

      .form-value {
        flex: 1;
      }
    }
  }
}

.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>