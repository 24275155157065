const frame = {template: '<router-view></router-view>'}

export default {
    path: 'ls',
    component: frame,
    children: [
        {
            name: '机构管理',
            path: 'LsAnchorManager',
            component: resolve => import('PAGES/ls/LsAnchorManager.vue')
        },
        {
            name: '直播样品',
            path: 'lsAnchorList',
            component: resolve => import('PAGES/ls/LsAnchorList.vue')
        },
        {
            name: '直播样品管理',
            path: 'lsAnchorList4Adm',
            component: resolve => import('PAGES/ls/LsAnchorList4Adm.vue')
        },
        {
            name: '节日礼单',
            path: 'lsStaffList',
            component: resolve => import('PAGES/ls/LsStaffList.vue')
        },
        {
            name: '礼单详情',
            path: 'lsStaffDetail/:staffId',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/ls/LsStaffDetail.vue')
        }
    ]
}