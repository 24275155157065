<template>
  <div>
    <div style="margin-bottom: 15px">
      <el-button :disabled="isActivity" type="primary" @click="batchGiro('batch')">批量退款</el-button>
      <el-button :disabled="isActivity" type="primary" @click="batchGiro('all')">全部退款</el-button>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="待退款" name="2"></el-tab-pane>
      <el-tab-pane label="已退款" name="3"></el-tab-pane>
    </el-tabs>
    <el-button
        size="mini"
        @click="isShow = true"
        v-show="this.activeName == '1' || this.activeName=='2'"
    >{{ buttonText }}
    </el-button>
    <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                   @pageChange="loadTableData" :isShadow="false">
      <el-table-column
          type="selection"
          width="55"></el-table-column>
      <el-table-column prop="userAccount" label="会员账号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="packageType" label="申请时间" :show-overflow-tooltip="true">
        <template slot-scope="scope" :show-overflow-tooltip="true">
          {{ formatDate(new Date(scope.row.createTime), "yyyy-MM-dd hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column prop="intermediatorName" label="订单号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.somOrder.orderCode }}
        </template>
      </el-table-column>
      <el-table-column prop="totalCount" label="订单金额">
        <template slot-scope="scope" :show-overflow-tooltip="true">
          {{ scope.row.somOrder.totalAmount }}
        </template>
      </el-table-column>
      <el-table-column prop="refundAmount" label="退款金额" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="refundState" label="退款状态" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="paymentName" label="退款户名" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="bankAccount" label="付款账号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="depositBank" label="付款支行" :show-overflow-tooltip="true"></el-table-column>
      <!-- <el-table-column label="操作" width="120">
          <template slot-scope="scope">
              <div class="icon-operation" @click="toDetail(scope.row.virtualPackageId)">
                  <a href="javascript: void(0);" title="查看">
                      <i class="CCSFont icon-table_2"></i>
                  </a>
              </div>
          </template>
      </el-table-column> -->
    </ws-page-table>
    <el-dialog title="导出退款记录" :visible.sync="isShow" width="560px">
      <div style="color: #FBC14E;">提醒：</div>
      <div>
        <br/>
        <p>点击确定即{{ buttonText }}excel；</p>
        <br/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="exportRecord">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="type=='all' ? '全部退款': '批量退款'" :visible.sync="isShowPopop" width="560px">
      <div style="color: #FBC14E; font-size:14px;">提醒：</div>
      <div style="justify-content:space-between;font-size:14px;">
        <br/>
        <p>共选择{{ this.size }}个待退款记录；</p>
        <br/>
        <p>确认退款前请仔细核对退款数量及信息;</p>
        <br/>
        <p>点击确定进行退款操作；</p>
        <br/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="confirmRefund(ids)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import tools from "COMJS/tools";
import {fetchData, exportGiroRecord, batchRefund} from '../../../api/som/reimburse.js'
import IoProgressService from "API/IoProgressService";

export default {
  // props: {
  //     type: String
  // },
  data() {
    return {
      activeName: "1",
      isShowPopop: false,
      isShow: false,
      buttonText: "导出全部退款记录",
      type: "",
      ids: [],
      isActivity: true,
      size: "",
      searchInfo: {
        refundState: '',
      },
      items: {
        refundState: {filter: "eq"},
      }
    }
  },
  mounted() {
  },
  methods: {
    formatDate: tools.formatDate,
    async loadTableData() {
      if (this.activeName == '1') {
        this.searchInfo.refundState = '';
      } else if (this.activeName == '2') {
        this.searchInfo.refundState = '0';
      } else if (this.activeName == '3') {
        this.searchInfo.refundState = '1';
      }
      const data = await fetchData(this.pageInfo, this.searchConfig)
      this.tableData = data.list.map(e => {
        e.refundState = (e.refundState == 0 ? '待退款' : '已退款')
        e.somOrder.goodsPaymentAmount = e.somOrder.goodsPaymentAmount.toFixed(2)
        return e
      })
      this.querySuccess(data)
    },
    exportRecord() {
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      var that = this;
      let param = {};
      if (this.activeName == '2') {
        param = {
          refundState: "0"
        }
      }
      exportGiroRecord(param).then(data => {
        let fileName = data.fileName;
        let module = data.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
        that.isShow = false
        that.loadTableData();
      }).catch(() => {
        loading.close();
      });
    },
    batchGiro(flag) {
      let that = this;
      this.type = flag;
      let rows;
      if (flag == "batch") {

        rows = this.$refs.pageTable.getCheckedData();
        if (rows.length < 1) {
          that.$alert('请选择记录！', '系统提示');
          return;
        }
      } else if (flag == "all") {
        rows = this.tableData;
        if (rows.length < 1) {
          that.$alert('暂无记录！', '系统提示');
          return;
        }
      }

      let ids = rows.map(e => {
        return e.giroRecordId;
      });
      this.isShowPopop = true;
      this.size = rows.length;
      this.ids = ids;
    },
    confirmRefund(ids) {
      batchRefund(ids).then(data => {
        this.isShowPopop = false;
        this.activeName = '3'
        this.loadTableData();
      })
    },
    handleClick() {
      this.pageInfo.pageNo = 1
      if (this.activeName == '1') {
        this.isActivity = true
        this.buttonText = '导出全部退款记录'
      } else if (this.activeName == '2') {
        this.buttonText = '导出待退款记录'
        this.isActivity = false
      } else if (this.activeName == '3') {
        this.isActivity = true
      }
      this.loadTableData();
    },
  },
  mixins: [
    pageTableMixin
  ]
}
</script>

<style scoped>
</style>
