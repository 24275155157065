<template>
  <div class="content">
    <div class="wap-content" v-if="_isMobile()">
      请在电脑端操作本功能
    </div>
    <div class="web-content" v-else>
      菜单有误请联系技术人员
    </div>
  </div>
</template>

<script>
export default {
  name: "empty"
}
</script>

<style scoped>
.content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wap-content{
  margin-top: 40vh;
}
.web-content{
  margin-top: 200px;
}
</style>