<template>
  <div>
    <el-dialog
        title="经销商账号冻结提醒"
        :visible.sync="dealerFreezeDialogVisible"
        width="700px"
        top="20vh"
        @close="dealerFreezeDialogVisible=false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <div style="margin-left: 20px;font-size: 14px;line-height: 30px;">
        <div>您好，</div>
        <div>此账号已被冻结。</div>
        <div v-if="isAgentCredit">可能是由于账单超时，您可通过菜单"信用账单"前往查看详情。</div>
        <div>如有疑问，请咨询客服人员。</div>
      </div>
      <div style="text-align: center;margin-top: 30px;">
        <el-button @click="dealerFreezeDialogVisible=false" type="primary" style="margin-right: 10px;">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import sysUtil from "@/constant/SysUtil";
export default {
name: "OverallDialog",
  computed:{
    isDealer() {
      return sysUtil.isAgentCredit(JSON.parse(userModule.PPUser).role) || sysUtil.isAgentDeposit(JSON.parse(userModule.PPUser).role);
    },
    isAgentCredit() {
      return sysUtil.isAgentCredit(JSON.parse(userModule.PPUser).role);
    },
    isDistributor() {
      return sysUtil.isDistributor(JSON.parse(userModule.PPUser).role);
    },
    isFreeze(){
      return JSON.parse(userModule.PPUser).authState=='freeze';
    }
  },
  data() {
    return {
      dealerFreezeDialogVisible:false
    }
  },
  mounted() {
    this.dealerFreezeDialogVisible = this.isDealer && this.isFreeze;
  }
}
</script>

<style scoped>

</style>