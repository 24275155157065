import axiosHttp from "../axiosHttp";

export default {
  // 订单分页查询
  queryOrderPages(params) {
    return axiosHttp.POST('/somOrder/listOrderPage', params, false);
  },
  queryShipmentOrderPages(params) {
    return axiosHttp.POST('/somOrder/getShipmentPageList', params, false);
  },
  // 获取订单详情
  getOrderDetail(orderId) {
    return axiosHttp.GET(`/somOrder/queryDetails/${orderId}`);
  },
  stateBackToUnShipped(orderId,returnStock){
    let param={
      orderIdList:[orderId],
      returnStock:returnStock
    }
    return axiosHttp.POST(`/somOrder/stateBackToUnShipped`,param);
  },

  changeOrderUnShipped(orderIds){
    return axiosHttp.POST('/somOrder/changeOrderUnShipped',orderIds);
  },
  /**
   * 下单
   * @param {*} OrderCreateParam 
   * @param {String} OrderCreateParam.addressDetail  修改地址-详细地址
   * @param {String} OrderCreateParam.province  收件地址-省
   * @param {String} OrderCreateParam.city   修改地址-市
   * @param {String} OrderCreateParam.county   修改地址-区县
   * @param {String} OrderCreateParam.goodsInfoList  商品信息，List，商品id*数量  ,
   * @param {String} OrderCreateParam.name  收件人姓名
   * @param {String} OrderCreateParam.phoneNum  收件人电话
   * @returns
   */
  createOrder(OrderCreateParam) {
    return axiosHttp.POST('/somOrder/admCreateOrder', OrderCreateParam);
  },
  // 计算运费
  calcFreight(OrderCreateParam) {
    return axiosHttp.POST('/somOrder/calcFreight', OrderCreateParam, false)
  },
  // 订单转出库单
  orderToWh(params) {
    return axiosHttp.POST('/somOrder/orderToWh', params);
  },
  orderCompulsoryComplete(param){
    return axiosHttp.POST('/somOrder/compulsoryComplete', param);
  }
}