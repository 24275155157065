<template>
  <el-dialog
    :title="categoryId ? '编辑分类': '添加分类'"
    :visible.sync="dialogFormVisible"
    @close="resetForm"
  >
    <el-form :model="form" v-loading="loading" label-width="140px">
      <el-form-item required label="分类名称：">
        <el-input v-model="form.name" maxlength="16" show-word-limit></el-input>
      </el-form-item>
      <el-form-item required label="分类级别：">
        <el-select v-model="form.selectedLevel" placeholder="级别" style="width: 200px;">
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="form.selectedFirstCategoryId"
          v-if="form.selectedLevel >= 2"
          placeholder="请选择一级分类"
          @change="getSecondCategory"
        >
          <el-option
            v-for="item in firstCategorys"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="form.selectedSecondCategoryId"
          v-if="form.selectedLevel == 3"
          placeholder="请选择二级分类"
        >
          <el-option
            v-for="item in secondCategorys"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用：">
        <el-switch v-model="form.enabled"></el-switch>
      </el-form-item>      
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import PimCategoryService from "API/pim/PimCategoryService.js";
import IoProgressService from "API/IoProgressService.js";
import userModule from "@/module/userModule";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";

export default {
  props: {
    categoryId: String
  },
  data() {
    return {
      form: {
        name: "",
        enabled: true,
        selectedLevel: "",
        remarks: "",
        selectedFirstCategoryId: "",
        selectedSecondCategoryId: ""
      },
      categoryInfo: {},
      levelList: [
        {
          name: "一级",
          value: "1"
        },
        {
          name: "二级",
          value: "2"
        },
        {
          name: "三级",
          value: "3"
        }
      ],
      firstCategorys:[],
      secondCategorys:[],
      loading: false,
      dialogFormVisible: false,
      formLabelWidth: "140px",
      fileList: []
    };
  },
  mounted() {
    this.getFirstCategory();
  },
  watch: {
    dialogFormVisible() {
      if (this.categoryId) {
        this.getCategoryDetail();
      } else {
        this.resetForm();
      }
    }
  },
  methods: {
    submitForm() {
      //console.log("222")
      if (this.form.name == "") {
        return this.$message({
          message: "请填写分类名称",
          type: "warning"
        });
      }
      if (!this.form.selectedLevel) {
        return this.$message({
          message: "请选择分类级别",
          type: "warning"
        });
      }
      if (this.form.selectedLevel >= 2 && !this.form.selectedFirstCategoryId) {
        return this.$message({
          message: "请选择一级分类",
          type: "warning"
        });
      }
      if (this.form.selectedLevel == 3 && !this.form.selectedSecondCategoryId) {
        return this.$message({
          message: "请选择二级分类",
          type: "warning"
        });
      }
      let _parentId;
      let _level = this.form.selectedLevel;
      if (_level == 1) {
        _parentId = "";
      } else if (_level == 2) {
        _parentId = this.form.selectedFirstCategoryId;
      } else if (_level == 3) {
        _parentId = this.form.selectedSecondCategoryId;
      }
      let params = {
        parentId: _parentId,
        thingTypeName: this.form.name,
        level: this.form.selectedLevel,
        enabled: this.form.enabled ? 1 : 0,
      };
      this.loading = true;
      let promise = null;
      if (this.categoryId) {
        params.thingTypeId = this.categoryId;
        promise = WhmList.saveCategory(params);
      } else {
        promise = WhmList.saveCategory(params);
      }

      promise.then(res => {
        this.dialogFormVisible = false;
        this.loading = false;
        this.getFirstCategory();
        this.$emit("updateCategory");
      });
    },
    // 详情
    getCategoryDetail() {
      this.loading = true;
      WhmList.getCategoryDetail(this.categoryId).then(async data => {
        this.categoryInfo = data
        this.form.name = data.thingTypeName;
        this.form.enabled = data.enabled == 1 ? true : false;
        this.form.selectedLevel = String(data.level);
        this.writeElSelect()
        this.loading = false;
      });
    },
    // 一级分类
    getFirstCategory() {
      WhmList.queryFisrtWhm().then(data => {
        this.firstCategorys = data.map(e => {
          e.id = e.thingTypeId;
          e.name = e.thingTypeName;
          return e;
        });
      });
    },
    // 二级分类
    getSecondCategory(id) {     
      return WhmList.getChildrenCategory(id).then(data => {
        // if(data.length == 0){
        //   console.log("999")
        // }
        this.secondCategorys = data.map(e => {
          e.id = e.thingTypeId;
          e.name = e.thingTypeName;
          return e;
        });
      });
    },
    // 写入父级id
    async writeElSelect() {
      let data = this.categoryInfo
      if (data.level == "2") {
        this.$set(this.form, "selectedFirstCategoryId", data.parentId)
      }
      // 分类详情只有父级id，暂时这样写
      if (data.level == "3") {
        this.loading = true;
        let secondCategory = await WhmList.getCategoryDetail(data.parentId) // 二级分类详情
        let secondCategorys = await this.getSecondCategory(secondCategory.parentId)
        //this.$set(this.form, "selectedFirstCategoryId", secondCategory.parentCategoryId)
        this.$set(this.form, "selectedFirstCategoryId", secondCategory.parentId)
        this.$set(this.form, "selectedSecondCategoryId", secondCategory.thingTypeId)
        this.loading = false;
      }
    },
    // 重置表单
    resetForm() {
      this.secondCategorys=[];
      tools.clearObj(this.form);
      this.form.enabled = true;//设置默认的开关值 值被清空了所以需要重新设置
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  margin-right: 20px;
}
.el-upload { //这是上传图片父容器。
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
