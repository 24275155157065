<template>
    <div>
      <div>
      <el-button size="mini" type="primary" @click="showAdd(authId)">新增</el-button>
      </div>
    <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
    >
      <el-table-column prop="beginTime" label="开启时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{datetimeFormatter(scope.row.beginTime)}}~{{datetimeFormatter(scope.row.endTime)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="编辑" @click="showEdit(scope.row.authorizationId)">
            <i class="CCSFont icon-table_1"></i>
            </a>
            <a href="javascript: void(0);" title="删除">
              <i class="el-icon-delete-solid" @click="deleteAuthority(scope.row.authorizationId)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <authorization-add-popup ref="authorizationAddPopup" @updateTable="loadTableData" :authorizationId="authorizationId" :authId="authId"></authorization-add-popup>
    </div>
</template>

<script>
  import Paging from "../../../js/query/Paging";
  import BimUserAuthorization from "API/bim/BimUserAuthorizationService.js";
  import pageTableMixin from "@/mixin/pageTableMixin";
  import authorizationAddPopup from "./authorizationAddPopup.vue";
  import tools from "COMJS/tools";



  export default {
    data() {
      return {
        searchInfo: {
          pageSize: 10,
          pageNo: 1,
          distributorId:this.$route.params.id,
        },
        authorizationId:"",
        authId:"",
      };

    },
    mixins: [pageTableMixin],
    components: {
      authorizationAddPopup,
    },
    mounted() {},
    methods: {
      loadTableData() {
        this.$refs.pageTable.tableLoading = true;
        BimUserAuthorization
                .getAuthorizationDetail(this.queryParams)
                .then(data => {
                  this.querySuccess(data);
                  if(data.list){
                    this.authId = data.list[0].authorizationId;
                  }
                });
      },
      showAdd(id) {
        this.authorizationId = "";
        this.authId = id
        this.$refs.authorizationAddPopup.dialogFormVisible = true;
      },
      datetimeFormatter(date) {
        let v = "";
        if (date) {
          v = tools.formatDate(new Date(date), "yyyy-MM-dd hh:mm:ss");
        }
        return v;
      },
      showEdit(id) {
        this.authorizationId = id;
        this.$refs.authorizationAddPopup.dialogFormVisible = true;
      },
      deleteAuthority(id) {
        this.$confirm(`是否删除`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          BimUserAuthorization.deleteAuthority(id).then(() => {
            this.loadTableData();
          });
        })
            .catch(() => {
            });
      },
    }
  };
</script>
