<template>
  <div id="auditTurnDownPop">
    <el-dialog title="审核驳回" :visible.sync="dialogVisible" width="30%">
      <el-row class="row-item">
        <el-col :span="24">
          <el-radio v-model="radio" label="1">订单已签收</el-radio>
        </el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="24">
          <el-radio v-model="radio" label="2">拦截失败</el-radio>
        </el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="4">
          <el-radio v-model="radio" label="3">其他原因</el-radio>
        </el-col>
        <el-col :span="10">
          <el-input v-model="auditRemark" :disabled='radio!="3"' placeholder="填写原因"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitReturn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import {auditHandle} from "API/som/checkService.js";

export default {
  data() {
    return {
      dialogVisible: false,
      radio: "1",
      auditRemark: "",
      currentData: {
        order: {},
      },
      businessAuditLogId: "",
    };
  },
  methods: {
    getcurrentData(data) {
      this.currentData = data.currentData;
      this.businessAuditLogId = data.businessAuditLogId;
    },
    commitReturn() {
      if (this.radio == "1") {
        this.auditRemark = "订单已签收";
      }
      if (this.radio == "2") {
        this.auditRemark = "拦截失败";
      }
      if (this.radio == "3") {
        if (!this.auditRemark) {
          return this.$message.warning("请填写其他原因");
        }
      }
      let loading = this.$loading({
        lock: true,
        text: "提交中...",
      });
      try {
        let params = {
          userId: JSON.parse(userModule.PPUser).userId,
          businessAuditLogId: this.businessAuditLogId,
          auditResult: "fail",
          auditRemark: this.auditRemark,
        }
        auditHandle(params).then((data) => {
          loading.close();
          this.$emit("auditOk", "");
          this.dialogVisible = false;
          Object.assign(this.$data, this.$options.data())
        });
      } catch (error) {
        loading.close();
        this.$emit("auditOk", "");
        this.dialogVisible = false;
        Object.assign(this.$data, this.$options.data())
      }
    },
  },
};
</script>

<style scoped>
.row-item {
  padding: 3% 0;
}

.el-input {
  position: relative;
  bottom: 10px;
}

.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>