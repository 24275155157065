<template>
  <div class="rec-edit">
    <div class="step-title">领用出库</div>
    <el-form
      :model="whmInfo"
      label-width="140px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item label="库品编码:">
        <div
          class="select-btn"
          v-if="!currentRow.skuId"
          @click="openskuCodeDialog"
        >
          请选择库品编码
        </div>
        <template v-else>
          <span>{{ currentRow.skuCode }}</span>
          <span
            class="tips-btn"
            style="dispaly: inline-block; margin-left: 15px"
            @click="openskuCodeDialog"
            >重选库品编码</span
          >
        </template>
      </el-form-item>
      <el-form-item label="库品名称:">
        <el-input disabled v-model="currentRow.skuName" placeholder="请先选择库品编码"></el-input>
      </el-form-item>
      <el-form-item label="库品规格:">
        <el-input disabled v-model="currentRow.skuSpec" placeholder="请先选择库品编码"></el-input>
      </el-form-item>
      <el-form-item label="库品单位:">
        <el-input disabled v-model="currentRow.skuUnit" placeholder="请先选择库品编码"></el-input>
      </el-form-item>

      <el-form-item label="领用人:">
        <div
          class="select-btn"
          v-if="!whmInfo.drawUser"
          @click="$refs.selectReceivePopup.dialogVisible = true"
        >
          请选择领用人
        </div>
        <template v-else>
          <span>{{ whmInfo.receiveName }}</span>
          <span
            class="tips-btn"
            style="dispaly: inline-block; margin-left: 15px"
            @click="$refs.selectReceivePopup.dialogVisible = true"
            >重选领用人</span
          >
        </template>
      </el-form-item>
      <el-form-item label="领用数量:">
        <el-input type="number" v-model="whmInfo.num"></el-input>
      </el-form-item>

    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">取消</el-button>
      <el-button type="primary" @click="createWhm">保存</el-button>
    </div>

    <select-receive-popup ref="selectReceivePopup" @submit="selectReceive"></select-receive-popup>

    <el-dialog :visible.sync="skuCodeDialog" width="50%" class="table-wrap">
      <el-form label-width="160px" class="input-txt-btn">
        <el-input v-model="searchInfo.skuName"></el-input>
        <el-button type="primary" @click="loadTableData">搜索</el-button>
      </el-form>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @current-change="handleCurrentChange"
        @pageChange="loadTableData"
        :selection="'single'"
      >
        <el-table-column
          prop="skuName"
          label="库品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuCode"
          label="库品编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <div class="btn-blue" @click="skucodeConfirm">确定</div>
        <div class="btn-gray" @click="closeSkucode">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import selectReceivePopup from "./selectReceivePopup"; //选择库品规格弹窗
import userModule from "@/module/userModule";
import WhmList from "API/whm/WhmList";
import boundQuery from "API/whm/boundQuery";

export default {
  name: "whmEdit",
  data() {
    return {
      //thumbnailImgs: [], //上传图片的字段
      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        skuId: "",
        drawUser: "", //供应商id
        receiveName: "",//供应商名称
        num: 1,
      },
      selectedreceive: {//搜索字段
        specReceiveId: "",
        receiveName: ""
      },
      skuCodeDialog: false, //库品编码弹窗开关
      searchInfo: {
        skuCode: "",
        skuName: "",
        type: 0,
        enabled: 1,
      },
      items: {
        skuCode: { filter: "like" },
        skuName: { filter: "like" },
      },
      currentRow: {
        skuId: "",
        skuCode: "", //库品编码
        skuName: "", // 库品名称
        skuSpec: "",
        skuUnit: "",
      },
    };
  },
  mixins: [pageTableMixin],
  components: {
    selectReceivePopup,
  },
  mounted() {
    this.loadTableData();
  },
  computed: {
    receiveSearchConfig() {
      return SearchConfig.createNew({
        searchForm: {
          receiveName: this.selectedReceive.receiveName
        }
      });
    }
  },
  methods: {
    closeSkucode(){
      this.skuCodeDialog = false
    },
    skucodeConfirm(){
      this.skuCodeDialog = false
    },
    skucodeCancel(){
      this.skuCodeDialog = false
      this.currentRow = {
        skuCode: "", //库品编码
        skuName: "", // 库品名称
      }
    },
    handleCurrentChange(val) {
      if (val) {
        //console.log(val)
        this.currentRow = val        
      }
    },
    openskuCodeDialog() {
      this.skuCodeDialog = true;
    },
    // 查询库品列表
    loadTableData() {
      //this.$refs.pageTable.tableLoading = true;
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            skuId: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            skuUnitName:  e.skuUnit ? e.skuUnit.name : "",
            // thingTypeName: e.level ? e.level.thingTypeName : "", //分类
            // skuSpecName: e.skuSpecName ? e.skuSpecName.name : "", //规格名
            skuSpecName: e.skuSpecName ? e.skuSpecName.name : "", //规格名
            skuSpec: e.skuSpec ? e.skuSpec.name : "",//规格值
            skuUnit: e.skuUnit ? e.skuUnit.name : "", //规格单位
            // shelfLife: e.shelfLife, //保质期
            // actualNum: e.actualNum, //当前库存
            // warningNum: e.warningNum,
            // soldOutNum: e.soldOutNum,
          };
        });
        this.querySuccess(data);
      });
    },    
    selectReceive(params) {
      //console.log(params)
      this.selectedReceive = params;
      this.whmInfo.drawUser = params.receiveId;
      this.whmInfo.receiveName = params.receiveName;
    },
    backPre() {
      //返回上一页
      this.$router.push("/whm/invList");
    },
    createWhm() {
      const that = this;
      let whmInfo = this.whmInfo;
      whmInfo.skuId = this.currentRow.skuId//库品id
      //drawUser
      //num
      if(!this.whmInfo.skuId){
        return this.$message({
          message: "请选择库品",
          type: "warning",
        });
      }
      if(!this.whmInfo.drawUser){
        return this.$message({
          message: "请选择领用人",
          type: "warning",
        });
      }
      if(!this.whmInfo.num || this.whmInfo.num <= 0){
        return this.$message({
          message: "请添加领用数量",
          type: "warning",
        });
      }
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      boundQuery.saveReceive(whmInfo)
        .then((res) => {
          if (res.msg == "OK") {
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
          setTimeout(function () {
            that.$router.push("/whm/receiveList");
          }, 300);          
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss">
.rec-edit .el-form-item .el-form-item__content > .el-input,
.rec-edit .el-input--small .el-input__inner {
  width: 200px;
}
.rec-edit .shelf-life.el-input--small {
  width: 200px;
}
.rec-edit .search-result{ height: 360px; overflow-y: scroll;}
.rec-edit .step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
  color: #333;
}
.rec-edit .select-btn {
  width: 200px;
  text-align: center;
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}
.rec-edit .whm-sku {
  padding: 20px;
  display: inline-block;
  border: 1px solid #ddd;
}
.rec-edit .whm-sku .select-btn {
  margin-top: 10px;
}
.rec-edit .select-btn-wrap {
  padding: 12px;
}
.rec-edit .steps-btn-wrapper {
  margin-top: 80px;
  padding-left: 105px;
  display: flex;
}
.rec-edit .tips-btn {
  cursor: pointer;
  color: #1b73e8;
}
.rec-edit .input-txt-btn {
  margin-bottom: 20px;
  display: flex;
}
.rec-edit .input-txt-btn .el-input {
  width: 160px;
}
.rec-edit .table-wrap .el-dialog__header {
  border-bottom: 0;
}
.rec-edit .table-wrap .el-table .cell {
  line-height: 42px;
}
.rec-edit .table-wrap .el-input--small .el-input__inner {
  width: 40px;
}
.rec-edit .input-txt-btn .el-input--small .el-input__inner {
  width: 150px;
}
.rec-edit .btn-blue-wrap {
  display: flex;
  justify-content: flex-end;
}
.rec-edit .btn-blue,.rec-edit .btn-gray {
  margin-top: 20px;
  width: 80px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  background: #1b73e8;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.rec-edit .btn-gray{ margin-left: 10px; width: 78px; height: 34px; border:1px solid #ddd; color: #999; background: #fff;}
</style>