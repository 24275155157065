<template>
  <div>
    <el-form label-width="110px" :model="eventDetail" label-position="left">
      <el-form-item required label="展示平台" prop="tradeType">
        <el-radio-group v-model="eventDetail.tradeType" :disabled="eventId">
          <el-radio label="wmp" border>小程序</el-radio>
          <el-radio label="app" border>app</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item required label="标题" prop="title">
        <el-input v-model="eventDetail.title"/>
      </el-form-item>
      <el-form-item required label="展示" prop="viewEnable">
        <el-switch
            v-model="eventDetail.viewEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item label="icon">
        <div style="display:flex;">
          <div>
            <div style="margin-bottom:10px;">选中前</div>
            <draggableUpload :width="50" :height="50" :limit="1" v-model="iconList1" :uploadUrl="uploadUrl()"
                             :requestHeaders="requestHeaders"/>
          </div>
          <div style="margin-left:20px;">
            <div style="margin-bottom:10px;">选中后</div>
            <draggableUpload :width="50" :height="50" :limit="1" v-model="iconList2" :uploadUrl="uploadUrl()"
                             :requestHeaders="requestHeaders"/>
          </div>
        </div>
        <div class="tips-self">用于小程序商城展示，建议长宽比1：1</div>
      </el-form-item>
      <el-form-item required label="指定角色">
        <el-radio-group v-model="selectRoleType">
          <div style="display:flex;">
            <el-radio label="all" border>所有角色</el-radio>
            <div style="height: 20px;">
              <el-radio label="customize" border>指定的角色</el-radio>
              <div style="margin-top: 10px;">
                <el-checkbox-group v-model="eventDetail.relaRoleCodeList" v-if="selectRoleType == 'customize'">
                  <el-checkbox v-for="item in roleList" :key="item.enumId" :label="item.code">{{
                      item.name
                    }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

          </div>
        </el-radio-group>
      </el-form-item>
      <div style="margin-top: 20px;align-items: center;">
        <span>关联商品</span>
        <el-link type="primary" style="margin-left: 20px;" @click="$refs.selectGoodsPopup.dialogVisible = true">选择
        </el-link>
      </div>
      <div style="width: 750px;margin-top: 20px;margin-bottom: 20px;">
        <ws-page-table :data="eventDetail.relaList">
          <el-table-column prop="goodsName" label="商品名称" width="300"></el-table-column>
          <el-table-column prop="goodsCode" label="商品编码" width="100"></el-table-column>
          <el-table-column label="缩略图" width="100">
            <template slot-scope="scope">
              <div class="table-img">
                <img :src="scope.row.goodsPicUrl" alt/>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" width="100">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: space-between;" class="edit-icon">
                <i class="el-icon-upload2" @click="upGoods(scope.$index)"></i>
                <i class="el-icon-download" @click="downGoods(scope.$index)"></i>
                <i class="el-icon-circle-close" @click="delChooseGoods(scope.$index)"></i>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
      <div style="text-align: right;">
        <el-button type="primary" size="medium" @click="commitEdit">确 定</el-button>
      </div>
    </el-form>

    <select-goods-popup ref="selectGoodsPopup" @submit="selectGoods"></select-goods-popup>

  </div>
</template>

<script>
import draggableUpload from "COMPS/base/draggableUpload";
import BimEnumerationInfo from "API/bim/BimEnumerationInfo";
import IoProgressService from "API/IoProgressService.js";
import userModule from "@/module/userModule";
import eventBus from "../../../js/eventBus.js";
import {showEventDetail, editShowEvent} from "@/api/pim/PimShowEvent.js";
import WsPageTable from "COMPS/widgets/pageTable";
import SelectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";

export default {
  components: {
    SelectGoodsPopup,
    WsPageTable,
    draggableUpload,
  },
  data() {
    return {
      eventId: "",
      roleList: [],
      eventDetail: {
        relaList: [],
        relaRoleList: [],
        relaRoleCodeList: [],
      },
      selectRoleType: "all",
      iconList1: [],
      iconList2: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
    };
  },
  methods: {
    getEventDetail() {
      showEventDetail(this.eventId).then((data) => {
        if (
            data.relaRoleList.length == 1 &&
            data.relaRoleList[0].roleCode == "all"
        ) {
          this.selectRoleType = "all";
        } else {
          this.selectRoleType = "customize";
        }
        data.relaRoleCodeList = data.relaRoleList.map((item) => {
          return item.roleCode;
        });
        //图
        if (data.iconUrl) {
          this.iconList1 = [{response: data.iconUrl}];
        }
        if (data.iconUrlSelected) {
          this.iconList2 = [{response: data.iconUrlSelected}];
        }
        this.eventDetail = data;
        // console.log(this.eventDetail.relaList);
      });
    },
    selectGoods(selected) {
      let addItems = selected.map((item) => {
        return {
          goodsId: item.goodsId,
          goodsName: item.cnName,
          goodsCode: item.goodsCode,
          goodsPicUrl: item.pictureUrl,
        };
      });
      this.eventDetail.relaList = this.eventDetail.relaList.concat(addItems);
      let res = [];
      this.eventDetail.relaList.forEach((item) => {
        let isExist = false;
        res.forEach((item2) => {
          if (item.goodsId == item2.goodsId) {
            isExist = true;
          }
        });
        if (!isExist) {
          res.push(item);
        }
      });
      this.eventDetail.relaList = res;
    },

    queryRoles() {
      BimEnumerationInfo.listByType("ROLE_CODE").then((data) => {
        console.log(data);
        this.roleList = data;
      });
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    delChooseGoods(i) {
      this.eventDetail.relaList.splice(i, 1);
    },
    upGoods(i) {
      if (i == 0) {
        this.$message("已经在第一个了");
        return;
      }
      let lastItem = this.eventDetail.relaList[i - 1];
      this.$set(this.eventDetail.relaList, i - 1, this.eventDetail.relaList[i]);
      this.$set(this.eventDetail.relaList, i, lastItem);
    },
    downGoods(i) {
      if (i == this.eventDetail.relaList.length - 1) {
        this.$message("已经在最后一个了");
        return;
      }
      let lastNo = this.eventDetail.relaList[i + 1].dispNo;
      this.eventDetail.relaList[i + 1].dispNo =
          this.eventDetail.relaList[i].dispNo;
      this.eventDetail.relaList[i].dispNo = lastNo;
      let lastItem = this.eventDetail.relaList[i + 1];
      this.$set(this.eventDetail.relaList, i + 1, this.eventDetail.relaList[i]);
      // this.eventDetail.relaList[i - 1] = this.eventDetail.relaList[i]
      // this.eventDetail.relaList[i] = lastItem
      this.$set(this.eventDetail.relaList, i, lastItem);
    },

    async commitEdit() {
      if (!this.eventDetail.tradeType) {
        return this.$message({
          message: "请选择展示平台",
          type: "warning",
        });
      }
      if (!this.eventDetail.title) {
        return this.$message({
          message: "请输入标题",
          type: "warning",
        });
      }
      if (!this.selectRoleType) {
        return this.$message({
          message: "请选择指定角色",
          type: "warning",
        });
      }
      if (this.selectRoleType == "all") {
        this.eventDetail.relaRoleList = [
          {
            roleCode: "all",
            roleName: "全部角色",
          },
        ];
      } else {
        this.eventDetail.relaRoleList = this.eventDetail.relaRoleCodeList.map(
            (item) => {
              return {roleCode: item};
            }
        );
        this.eventDetail.relaRoleList = this.eventDetail.relaRoleList.filter(a => a.roleCode != 'all')
      }
      //   if (this.eventDetail.relaList.length == 0) {
      //     return this.$message({
      //       message: "请至少关联一个商品聚合",
      //       type: "warning",
      //     });
      //   }
      if (this.iconList1.length > 0) {
        this.eventDetail.iconUrl = this.iconList1[0].response;
      }
      if (this.iconList2.length > 0) {
        this.eventDetail.iconUrlSelected = this.iconList2[0].response;
      }
      //固定
      this.eventDetail.displayMode = "goods";
      this.eventDetail.type = "mall";
      //console.log(this.eventDetail)
      await editShowEvent(this.eventDetail);
      this.$message.success("保存成功");
      eventBus.$emit("refresh");

      this.checkFromProductEditorAskBack();

      this.$router.push({
        path: "showEvent",
      });
    },
  },
  async mounted() {
    await this.queryRoles();
    this.eventId = this.$route.query.id;
    if (this.eventId) {
      await this.getEventDetail();
    }
    let tradeType = this.$route.query.tradeType;
    let temp = {
      tradeType: tradeType,
      relaList: [],
      relaRoleList: [],
      relaRoleCodeList: [],
    }
    this.eventDetail = temp
  },
};
</script>

<style>
.edit-icon {
  font-size: 25px;
}

.edit-icon i {
  cursor: pointer;
  font-size: 25px;
}

.tips-self {
  margin-top: 4px;
  font-size: 12px;
  color: #999;
  line-height: 20px;
}
</style>
