import axiosHttp from "../axiosHttp";

export default {
    // 获取全部分类树
    getAllCategoryTree() {
        return axiosHttp.GET('/pimCategory/queryAllCategoryTree/1')
    },
    // 获取顶级分类
    getTopCategory() {
        return axiosHttp.GET('/pimCategory/queryTopCategories')
    },
    // 查询次级分类
    getChildrenCategory(parentId) {
        return axiosHttp.GET(`/pimCategory/queryByParentId/${parentId}`)
    },
    // 保存分类
    /**
     * @param {Object} Pimcategory
     * 
     * @param {*} Pimcategory.categoryId	主键
     * @param {*} Pimcategory.parentCategoryId	上级id
     * @param {*} Pimcategory.level	等级
     * @param {*} Pimcategory.categoryCode	分类编码
     * @param {*} Pimcategory.levelCode	等级编码
     * @param {*} Pimcategory.categoryName	分类名称
     * @param {*} Pimcategory.categoryType	上级人员id
     * @param {*} Pimcategory.categoryIconUrl	图标
     * @param {*} Pimcategory.createTime	创建时间
     * @param {*} Pimcategory.updateTime	修改时间
     * @param {*} Pimcategory.state	状态
     * @param {*} Pimcategory.color	颜色
     * @param {*} Pimcategory.visible	是否显示
     * @param {*} Pimcategory.remark	备注
     * @param {*} Pimcategory.dispNo	序号
     * @returns
     */
    saveCategory(Pimcategory) {
        return axiosHttp.POST('/pimCategory', Pimcategory)
    },
    updateCategory(Pimcategory) {
        return axiosHttp.PUT('/pimCategory', Pimcategory)
    },
    deleteCategory(categoryId) {
        return axiosHttp.POST('/pimCategory/removePimCategory', {
            categoryId,
        })
    },
    // 获取商品详情
    getCategoryDetail(categoryId) {
        return axiosHttp.GET(`/pimCategory/${categoryId}`)
    },
    // 模糊查询
    queryCategory(params) {
        return axiosHttp.POST('/pimCategory/queryOneNode', params, false)
    }
}