<template>
  <el-dialog :title="authorizationId ? '编辑会员权限': '添加会员权限'" :visible.sync="dialogFormVisible" width="50vw">
    <el-form :model="entity" v-loading="loading" v-if="dialogFormVisible">
      <el-form-item v-show="edit" label="会员账户：" :label-width="formLabelWidth">
        <el-input  :disabled="operateType" v-model.trim="entity.distributorAccount" maxlength="32" placeholder="请输入会员账户" @blur="blurEvent(entity.distributorAccount)"></el-input>
      </el-form-item>
      <el-form-item  v-show="edit" label="业务类型：" :label-width="formLabelWidth">
        <el-select  :disabled="operateType" v-model="entity.businessValue" placeholder="请选择关联业务">
          <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="edit" label="会员姓名："  :label-width="formLabelWidth">
        <el-input  :disabled="true" v-model="entity.userName" maxlength="32" placeholder="输入账户自动填写会员姓名" ></el-input>
      </el-form-item>
      <el-form-item v-show="edit" label="账户类型："  :label-width="formLabelWidth">
        <el-input  :disabled="true" v-model="entity.accountType"   maxlength="32"  placeholder="输入账户自动填写账户类型"></el-input>
      </el-form-item>
      <div class="block">
        <span style="margin-left: 8px" class="demonstration">有效时间范围:</span>
        <el-date-picker
                style="margin-left: 18px"
                v-model="valueData"
                @change="changePickerDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  const moment = require("moment");
  import userInfoService from "API/bim/userInfoService.js";
  import BimUserAuthorization from "API/bim/BimUserAuthorizationService.js";
  import tools from "COMJS/tools";
  import userModule from "@/module/userModule";

  export default {
    props: {
      authorizationId: String,
      authId: String
    },
    data() {
      return {
        entity:{
          userId:"",
          userName:"",
          accountType:"",
          distributorAccount:"",
          beginTime:"",
          endTime:"",
          businessValue:"",
        },
        operateType:false,
        type:"",
        edit:true,
        valueData:[],
        options: [{
          value: 'batch_import',
          label: '批量导入'
        }],

        dialogFormVisible: false,
        formLabelWidth: "100px",
        loading: false,
        flag : true,
      };
    },
    watch: {
      dialogFormVisible() {
        if (this.authorizationId) {
          this.edit=false;
          this.type='editing';
          this.getDetail(this.authorizationId,this.type);
        } else {
          //展示，但是不能点击
          this.operateType=true;
          this.edit=true;
          if(this.authId){
            this.type="adding"
            this.getDetail(this.authId,this.type);
          }else if(!this.authorizationId&&!this.authId){
            this.operateType=false;
            this.resetForm();
          }
        }
      }
    },
    methods: {
      blurEvent(account){
        if(!account){
          return this.$message({
            message: "请输入会员账户",
            type: "warning"
          });
        }else{
          let params = {
            userAccount: account
          }
          userInfoService.getUser(params).then(data =>{
            if(data){
              this.entity.userName =  data.bimUserInfo.fullName? data.bimUserInfo.fullName:decodeURI(data.bimUserInfo.nickName)
              this.entity.accountType = data.bimUserInfo.companyAccountName? "公司账号":"个人账号";
              this.entity.userId = data.userId;
              console.log(data)
            }else{
              return this.$message({
                message: "未找到此账号的相关信息，请核对后重试",
                type: "warning"
              });
            }
          })
        }
      },
      changePickerDate(e) {
        if (e === null) {
          this.entity.beginTime = "";
          this.entity.endTime = "";
        } else {
          this.entity.beginTime = tools.formatDate(
                  new Date(e[0]),
                  "yyyy-MM-dd hh:mm:ss"
          );
          this.entity.endTime = tools.formatDate(
                  new Date(e[1]),
                  "yyyy-MM-dd hh:mm:ss"
          );
        }
      },
      getDetail(authorizationId,type){
        BimUserAuthorization.queryAuthorityById(authorizationId).then(data =>{
          if(type=="editing"){
            console.log('编辑');
            console.log(data);
            
            this.$set(this.valueData,0,data.beginTime)
            this.$set(this.valueData,1,data.endTime)
            this.$set(this.entity,"userId",data.distributorId)
            this.$set(this.entity,"userName",data.distributorName)
            this.$set(this.entity,"accountType",data.accountType=='company'?"公司账号":"个人账号")
            this.$set(this.entity,"distributorAccount",data.distributorAccount)
            this.$set(this.entity,"businessValue",data.bizCode)
            // this.valueData=new Array();
          }else if(type='adding'){
             console.log('新增');
            this.$set(this.valueData,0,data.beginTime)
            this.$set(this.valueData,1,data.endTime)
            this.$set(this.entity,"userId",data.distributorId)
            this.$set(this.entity,"userName",data.distributorName)
            this.$set(this.entity,"accountType",data.accountType=='company'?"公司账号":"个人账号")
            this.$set(this.entity,"distributorAccount",data.distributorAccount)
            this.$set(this.entity,"businessValue",data.bizCode)
            this.valueData=new Array();
          }
        })
      },

      submitForm() {
        let flag =true;
        if(this.edit){ //判断是新增还是编辑
          if (this.entity.businessValue == "") {
            flag=false
            return this.$message({
              message: "请选择业务类型",
              type: "warning"
            });
          }
          if (this.entity.distributorAccount == "") {
            flag=false
            return this.$message({
              message: "请填写会员账户",
              type: "warning"
            });
          }
          if (this.entity.userName == "") {
            flag=false
            return this.$message({
              message: "请填写正确的会员账户",
              type: "warning"
            });
          }
          if (this.entity.accountType == "") {
            flag=false
            return this.$message({
              message: "请填写正确的会员账户",
              type: "warning"
            });
          }
        }
        if(this.valueData&&this.valueData.length==0){
          flag=false
          return this.$message({
            message: "请选择有效期范围",
            type: "warning"
          });
        }
        if(flag){
          let params = {
            authorizationId:this.authorizationId,
            bizCode: this.entity.businessValue,
            distributorId: this.entity.userId,
            distributorName: this.entity.userName,
            distributorAccount: this.entity.distributorAccount,
            accountType:this.entity.accountType==="公司账号"? "company":"personal",
            beginTime:moment(this.valueData[0]).format('YYYY-MM-DD HH:mm:ss'),
            endTime:moment(this.valueData[1]).format('YYYY-MM-DD HH:mm:ss')
          };
          console.log(this.valueData[0],this.valueData[1]);
          
          console.log(params)
          this.loading = true;
          if (this.authorizationId) {
            params.authorizationId = this.authorizationId;
          }
          BimUserAuthorization.createAuthorization(params).then(res => {
            this.loading = false;
            this.$emit("updateTable");
          });
          this.dialogFormVisible = false;
        }else{
          return this.$message({
            message: "会员信息不正确，请核对后重试",
            type: "warning"
          });
        }
      },
      // 重置表单
      resetForm() {
        tools.clearObj(this.form);
      },
    }
  };
</script>

<style lang="scss">
  .upload-img-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
    color: $themeColor;
    border: 1px solid #dcdee0;
    border-radius: 0px;
  }
  .xrequired:before {
    content: '* ';
    color: red;
  }
</style>
