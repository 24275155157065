import axiosHttp from "../axiosHttp"

export default {
    queryPageBySearchFilter(pageInfo,searchConfig) {
        let url = "/dimDistributionLog/queryPageBySearchFilter?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url);
    },
    // 保存设置
    saveModifyLog(params) {
        return axiosHttp.POST('/dimDistributionLog/insertLog', params)
    }
}
