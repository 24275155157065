// import { resolve } from 'core-js/fn/promise'

const frame = {template: '<router-view></router-view>'}

export default {
    path: 'pim',
    component: frame,
    children: [
        {
            name: '商品列表',
            path: 'productList/:test?',
            component: resolve => import('PAGES/pim/product/productList.vue')
        },
        {
            name: '库品列表',
            path: 'stockList',
            component: resolve => import('PAGES/pim/warehouse/stockList.vue')
        }, {
            name: '分类列表',
            path: 'productCategoryList',
            component: resolve => import('PAGES/pim/category/productCategoryList.vue')
        }, {
            name: '新增商品',
            path: 'productAdd_step_1/:id?',
            // meta: { notTab: true }, // $route.matched
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/addProductStep/fillInProductInfo.vue')
        }, {
            name: '添加属性',
            path: 'productAdd_step_2/:id?',
            meta: {notTab: true, noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/addProductStep/addProductAttr.vue')
        }, {
            name: '添加图片',
            path: 'productAdd_step_3/:id?',
            meta: {notTab: true, noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/addProductStep/addProductImg.vue')
        }, {
            name: '商品详情',
            path: 'productDetail/:id',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/productDetail.vue')
        },
        {
            name: '商品编辑',
            path: 'productEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/productEdit.vue')
        },
        {
            name: 'SPU列表',
            path: 'spuList',
            component: resolve => import('PAGES/pim/product/spu/spuList.vue')
        },
        {
            name: '新增SPU',
            path: 'spuEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/spu/spuEditor.vue')
        },
        {
            name: 'SPU详情',
            path: 'spuDetail/:id',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/spu/spuDetail.vue')
        },
        {
            name: '品牌列表',
            path: 'brandList',
            component: resolve => import('PAGES/pim/brand/brandList.vue')
        }, {
            name: "运费模板",
            path: "fareModel",
            component: resolve => import('PAGES/pim/fareModel/fareModel.vue')
        }, {
            name: "展示活动",
            path: "showEvent",
            component: resolve => import('PAGES/pim/showEvent/showEvent.vue')
        },{
            name:"活动编辑",
            path:"editShowEvent",
            meta:{noKeepAlive:true },
            component: resolve => import('PAGES/pim/showEvent/editShowEvent.vue')
        },{
            name:"价格活动",
            path:"priceEvent",
            component: resolve => import('PAGES/pim/priceEvent/priceEvent.vue')
        },{
            name:"编辑价格活动",
            path:"editPriceEvent",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/priceEvent/editPriceEvent.vue')
        },{
            name:"价格活动商品管理",
            path:"eventShop",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/priceEvent/eventShop.vue')
        },{
            name:"价格活动商品管理",
            path:"eventShop4A",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/priceEvent/eventShop4A.vue')
        },{
            name:"推荐商品列表",
            path:"RecommendList",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/Recommend/RecommendList.vue')
        },{
            name:"推荐商品详情",
            path:"RecommendEdit",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/product/Recommend/RecommendEdit.vue')
        },{
            name:"商城设置",
            path:"mallList",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/mallSetting/mallList.vue')
        },{
            name:"商品新增",
            path:"AddGoods",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/mallSetting/AddGoods.vue')
        },{
            name:"商品预留",
            path:"reservePrice",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/reserve/reservePrice.vue')
        },{
            name:"商品预留详情",
            path:"reservePriceDetail/:userId?",
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/pim/reserve/reservePriceDetail.vue')
        },{
            name: '商品标签管理',
            path: 'tagManager',
            component: resolve => import('PAGES/pim/tag/MerchandiseTagManager.vue')
        }
    ]
}