<template>
  <div>
    <el-dialog
        title="重要提醒"
        :visible.sync="temporaryDialogVisible"
        width="700px"
        top="20vh"
        @close="temporaryDialogVisible=false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <div style="text-align: center;">
        <img src="../../../assets/images/notice/notice1.png" style="width: 600px;"/>
      </div>
      <div style="text-align: right;margin-top: 30px;">
        <el-button @click="temporaryDialogVisible=false" type="primary" style="margin-right: 10px;">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
name: "TemporyDialog",
  data() {
    return {
      temporaryDialogVisible:false
    }
  },
}
</script>

<style scoped>

</style>