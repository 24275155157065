// * desc: 下载参数入口
// * @param base64  ：返回数据的blob对象或链接
// * @param fileName  ：下载后文件名标记
function downloadFileByBase64(base64, fileName) {
    var myBlob = dataURLtoBlob(base64)
    var myUrl = URL.createObjectURL(myBlob)
    downloadFile(myUrl, fileName)
}

// * desc: 下载方法
// * @param url  ：返回数据的blob对象或链接
// * @param fileName  ：下载后文件名标记
function downloadFile(url, name = "What's the fuvk") {
    console.log('url==', url)
    var a = document.createElement("a")
    a.setAttribute("href", url)
    a.setAttribute("download", name)
    a.setAttribute("target", "_blank")
    let clickEvent = document.createEvent("MouseEvents")
    clickEvent.initEvent("click", true, true)
    a.dispatchEvent(clickEvent)
}

//将完整的base64转换为blob
function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type: mime})
}


export default {
    downloadFileByBase64
}
