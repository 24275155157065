import axiosHttp from "../axiosHttp";

export default {
  // 品牌分页查询
  queryBrandPages(pageInfo, searchConfig) {
    let url = "/pimPlatformBrand/queryPimPlatformBrand?"
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  /**
   * 保存品牌
   * @param {Object} PimPlatformBrand
   *
   * @param {*} PimPlatformBrand.brandId	主键
   * @param {*} PimPlatformBrand.brandName	品牌名
   * @param {*} PimPlatformBrand.brandFirstLetter	品牌首字母
   * @param {*} PimPlatformBrand.logoUrl	品牌图片
   * @param {*} PimPlatformBrand.areaPicUrl	品牌区域图
   * @param {*} PimPlatformBrand.brandStory	品牌故事
   * @param {*} PimPlatformBrand.dispNo	序号
   * @param {*} PimPlatformBrand.visible	是否启用
   * @param {*} PimPlatformBrand.createTime	创建时间
   * @param {*} PimPlatformBrand.updateTime	修改时间
   * @param {*} PimPlatformBrand.state	状态
   * @param {*} PimPlatformBrand.manufacturer	厂商
   * @param {*} PimPlatformBrand.remark	备注
   * @param {*} PimPlatformBrand.brandShortName	品牌简称
   * @param {*} PimPlatformBrand.brandCode	品牌编号
   * @returns
   */
  saveBarnd(PimPlatformBrand) {
    PimPlatformBrand.state = 1
    return axiosHttp.POST('/pimPlatformBrand', PimPlatformBrand)
  },
  // 获取品牌详情
  getBrandDetail(brandId) {
    return axiosHttp.GET(`/pimPlatformBrand/fetchAll/${brandId}`)
  },
  // 删除品牌
  deleteBrand(brandIds) {
    return axiosHttp.DELETE(`/pimPlatformBrand`, {
      delIds: brandIds
    })
  }
}