<template>
  <el-dialog title="入库申请" :visible.sync="stockDialogVisible" width="500px">
    <el-form label-width="120px">
      <el-form-item label="入库货品：" required>
        <div class="form-item-content form-width-250">
          <el-select v-model="entryStockData.fmSingleItemId" placeholder="请选择货品">
            <el-option
                v-for="item in skuList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="入库数量：" required>
        <div class="form-item-content form-width-250">
          <el-input v-model="entryStockData.stockModifyNum" placeholder="请填入数量"
                    onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="生产日期：" required>
        <div class="form-item-content form-width-250">
          <el-date-picker
              v-model="entryStockData.productDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="过期日期：" required>
        <div class="form-item-content form-width-250">
          <el-date-picker
              v-model="entryStockData.expireDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="批次号：" required>
        <div class="form-item-content form-width-250">
          <el-input v-model="entryStockData.batchCode" placeholder="请填写批次号"
                    maxLength="32"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="预计入库时间：" required>
        <div class="form-item-content form-width-250">
          <el-date-picker
              v-model="entryStockData.expectedEntryDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <div style="display: flex;align-items: center;">
        <el-checkbox v-model="skipAuditFlag">跳过旺店通确认环节</el-checkbox>
        <div style="margin-left: auto;">
          <el-button @click="stockDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="entryStockDialogSubmit">确 定</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import milkStockService from "API/fm/FreshMilkStockService";

export default {
  name: "SingleItemStockDialog",
  data() {
    return {
      stockDialogVisible: false,
      skuList: [
        /*{label: 'Old渥康鲜奶', value: 'ole1'},
        {label: '鲜奶铁盒', value: 'ole_lihe'}*/
      ],
      entryStockData: {},
      skipAuditFlag: false,
    }
  },
  mounted() {
    milkStockService.querySingleItemList({}).then(res => {
      this.skuList = res.map(e => {
        return {
          label: e.goodsName,
          value: e.fmSingleItemId
        }
      })
    })
  },
  methods: {
    show() {
      this.entryStockData = {}
      this.stockDialogVisible = true
    },
    entryStockDialogSubmit() {
      if (!this.entryStockData.fmSingleItemId) {
        return this.$message({
          message: "请选择入库货品",
          type: "warning"
        });
      }
      if (!this.entryStockData.stockModifyNum) {
        return this.$message({
          message: "请填写入库数量",
          type: "warning"
        });
      }
      if (!this.entryStockData.productDate) {
        return this.$message({
          message: "请选择生产日期",
          type: "warning"
        });
      }
      if (!this.entryStockData.expireDate) {
        return this.$message({
          message: "请选择过期日期",
          type: "warning"
        });
      }
      if (!this.entryStockData.batchCode) {
        return this.$message({
          message: "请填写批次号",
          type: "warning"
        });
      }
      if (!this.entryStockData.expectedEntryDate) {
        return this.$message({
          message: "请选择预计入库时间",
          type: "warning"
        });
      }
      let param = {
        ...this.entryStockData,
        skipAudit: this.skipAuditFlag ? "0" : "1",
        stockModifyType: 'CGRK'
      }
      milkStockService.entryStockApply(param).then(res => {
        this.$message.success("操作成功");
        this.stockDialogVisible = false;
        this.$emit("submit", "");
      })
    },
  }
}
</script>

<style scoped>

</style>