import axiosHttp from "../axiosHttp";

export const getPage = (pageInfo) => {
    return axiosHttp.GET('/logisticsCost/pageLogisticsCost?'+ pageInfo.buildQueryString())
}

export const getDetail = id => {
    return axiosHttp.GET('logisticsCost/queryDetails/' + id)
}
export const saveLogisticsCost = data => {
    return axiosHttp.POST('logisticsCost/saveLogisticsCost', data)
}

export const delLogisticsCost= id=>{
    return axiosHttp.DELETE('/logisticsCost/removeLogisticsCost',{delIds:id})
}