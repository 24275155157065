<template>
  <div>

    <div>
      <span style="font-size: 18px;" v-if="goodsPageInfo.eventName">活动名称：</span>
      <span style="font-size: 18px;">{{ goodsPageInfo.eventName }}</span><br><br>
      <el-button type="primary" @click="addGoods">新增商品</el-button>
    </div>
    <div class="info">
      <div>商品名称、商品编码：</div>
      <div>
        <el-input v-model="goodsPageInfo.keyWorld" placeholder="商品名称、商品编码" type="text" size="small"></el-input>
      </div>
      <div>
        <el-button type="primary" @click="getGoods">搜索</el-button>
      </div>
    </div>
    <el-table :data="eventGoodsList" border>
      <el-table-column label="商品信息" min-width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div style="display: flex;">
            <img width="50px" height="50px" :src="scope.row.pictureUrl">
            <span style="margin-left: 10px;">{{ scope.row.cnName }}</span>
            <span style="margin-left: 10px;">{{ scope.row.goodsCode }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="原价" prop="initPrice" width="100"></el-table-column>
      <el-table-column label="联盟业绩" width="150">
        <template slot-scope="scope">
          <div style="display: flex;">
            <span style="white-space: nowrap;">比例：{{ eventGoodsList[scope.$index].achievementPer }}</span>
          </div>
          <div style="display: flex;">
            <span>值：{{ eventGoodsList[scope.$index].achievement }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="伙伴服务费比例" width="150">
        <template slot-scope="scope">
          <div style="display: flex;">
            <span style="white-space: nowrap;">比例：{{ eventGoodsList[scope.$index].egalitarianPer }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="普通会员" width="150">
        <template slot-scope="scope">
          <div style="display: flex;">
            <span style="white-space: nowrap;">比例：{{ eventGoodsList[scope.$index].l0CostPricePer }}</span>
          </div>
          <div style="display: flex;">
            <span>值：{{ eventGoodsList[scope.$index].l0CostPrice }}</span>
          </div>
          <div>
            <el-switch :disabled="true" v-model="eventGoodsList[scope.$index].l0Visible" active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="普标会员" width="150">
        <template slot-scope="scope">
          <div style="display: flex;">
            <span style="white-space: nowrap;">比例：{{ eventGoodsList[scope.$index].l1CostPricePer }}</span>
          </div>
          <div style="display: flex;">
            <span>值：{{ eventGoodsList[scope.$index].l1CostPrice }}</span>
          </div>
          <div>
            <el-switch :disabled="true" v-model="eventGoodsList[scope.$index].l1Visible" active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="银星会员" width="150">
        <template slot-scope="scope">
          <div style="display: flex;">
            <span style="white-space: nowrap;">比例：{{ eventGoodsList[scope.$index].l2CostPricePer }}</span>
          </div>
          <div style="display: flex;">
            <span>值：{{ eventGoodsList[scope.$index].l2CostPrice }}</span>
          </div>
          <div>
            <el-switch :disabled="true" v-model="eventGoodsList[scope.$index].l2Visible" active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="金星会员" width="150">
        <template slot-scope="scope">
          <div style="display: flex;">
            <span style="white-space: nowrap;">比例：{{ eventGoodsList[scope.$index].l3CostPricePer }}</span>
          </div>
          <div style="display: flex;">
            <span>值：{{ eventGoodsList[scope.$index].l3CostPrice }}</span>
          </div>
          <div>
            <el-switch :disabled="true" v-model="eventGoodsList[scope.$index].l3Visible" active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <div class="icon-operation" style="display: flex;justify-content: space-between;">
            <a href="javascript: void(0);" style="margin-right: 0px !important;" @click="goDetail(scope.row)"
               title="编辑">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <el-popconfirm style="margin-left:0;" title="确定删除吗？" @confirm="delPriceEventGoods(scope.row.eventGoodsId)">
              <el-button>删除</el-button>
              <a slot="reference" href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid"></i>
              </a>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="goodsPageInfo.total" @current-change="changePage">
    </el-pagination>

    <select-goods-popup ref="selectGoodsPopup" @submit="commitChooseGoods"></select-goods-popup>

    <el-dialog title="新增活动商品" :visible.sync="checkGoodsDialogVisible" width="80%" @close="closeCheck">
      <el-table :data="checkGoodsList" border>
        <el-table-column label="商品信息" min-width="200" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div style="display: flex;">
              <img width="50px" height="50px" :src="scope.row.pictureUrl">
              <span style="margin-left: 10px;">{{ scope.row.cnName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="原价" prop="initPrice" width="100"></el-table-column>
        <el-table-column label="联盟业绩" width="150">
          <template slot-scope="scope">
            <div style="display: flex;">
              <span style="white-space: nowrap;">比例：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].achievementPer)"
                        v-model="checkGoodsList[scope.$index].achievementPer"></el-input>
            </div>
            <div style="display: flex;">
              <span>值：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].achievement)"
                        v-model="checkGoodsList[scope.$index].achievement"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="伙伴服务费比例" width="150">
          <template slot-scope="scope">
            <div style="display: flex;">
              <span style="white-space: nowrap;">比例：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].egalitarianPer)"
                        v-model="checkGoodsList[scope.$index].egalitarianPer"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="普通会员" width="150">
          <template slot-scope="scope">
            <div style="display: flex;">
              <span style="white-space: nowrap;">比例：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l0CostPricePer)"
                        v-model="checkGoodsList[scope.$index].l0CostPricePer"></el-input>
            </div>
            <div style="display: flex;">
              <span>值：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l0CostPrice)"
                        v-model="checkGoodsList[scope.$index].l0CostPrice"></el-input>
            </div>
            <div>
              <el-switch v-model="checkGoodsList[scope.$index].l0Visible" active-color="#13ce66"
                         inactive-color="#ff4949">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="普标会员" width="150">
          <template slot-scope="scope">
            <div style="display: flex;">
              <span style="white-space: nowrap;">比例：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l1CostPricePer)"
                        v-model="checkGoodsList[scope.$index].l1CostPricePer"></el-input>
            </div>
            <div style="display: flex;">
              <span>值：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l1CostPrice)"
                        v-model="checkGoodsList[scope.$index].l1CostPrice"></el-input>
            </div>
            <div>
              <el-switch v-model="checkGoodsList[scope.$index].l1Visible" active-color="#13ce66"
                         inactive-color="#ff4949">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="银星会员" width="150">
          <template slot-scope="scope">
            <div style="display: flex;">
              <span style="white-space: nowrap;">比例：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l2CostPricePer)"
                        v-model="checkGoodsList[scope.$index].l2CostPricePer"></el-input>
            </div>
            <div style="display: flex;">
              <span>值：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l2CostPrice)"
                        v-model="checkGoodsList[scope.$index].l2CostPrice"></el-input>
            </div>
            <div>
              <el-switch v-model="checkGoodsList[scope.$index].l2Visible" active-color="#13ce66"
                         inactive-color="#ff4949">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="金星会员" width="150">
          <template slot-scope="scope">
            <div style="display: flex;">
              <span style="white-space: nowrap;">比例：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l3CostPricePer)"
                        v-model="checkGoodsList[scope.$index].l3CostPricePer"></el-input>
            </div>
            <div style="display: flex;">
              <span>值：</span>
              <el-input maxlength="8" @keyup.native="keyupEvent($event,checkGoodsList[scope.$index].l3CostPrice)"
                        v-model="checkGoodsList[scope.$index].l3CostPrice"></el-input>
            </div>
            <div>
              <el-switch v-model="checkGoodsList[scope.$index].l3Visible" active-color="#13ce66"
                         inactive-color="#ff4949">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="!unEdit" label="操作" width="150" align="center">
          <template slot-scope="scope">
            <i class="el-icon-circle-close" style="font-size: 25px; cursor: pointer;"
               @click="delChooseGoods(scope.$index)"></i>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="checkGoodsDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitCheckGoods">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import selectGoodsPopup from "../product/spu/selectGoodsPopup"
import pageTableMixin from "@/mixin/pageTableMixin.js";
import PimGoodsService from "API/pim/PimGoodsService";
import eventBus from '../../../js/eventBus.js'
import {
  queryEventDetail,
  editEventDetail,
  fetchEventGoods,
  addEventGoods,
  delEventGoods,
  editEventGoods
} from '@/api/pim/PimPriceEvent.js'

export default {
  components: {
    selectGoodsPopup,
  },
  data() {
    return {
      goodsDialogVisible: false,
      eventGoodsList: [],
      goodsPageInfo: {
        pageSize: 10,
        pageNo: 1,
        eventId: "",
        total: 0,
        eventName: "",
      },
      checkGoodsList: [],
      checkGoodsDialogVisible: false,
      unEdit: false,
    }
  },
  methods: {
    async getGoods() {
      let data = await fetchEventGoods(this.goodsPageInfo)
      this.eventGoodsList = data.list
      this.eventGoodsList.map(item => {
        if (item.l0Visible == 1) {
          item.l0Visible = true
        } else {
          item.l0Visible = false
        }
        if (item.l1Visible == 1) {
          item.l1Visible = true
        } else {
          item.l1Visible = false
        }
        if (item.l2Visible == 1) {
          item.l2Visible = true
        } else {
          item.l2Visible = false
        }
        if (item.l3Visible == 1) {
          item.l3Visible = true
        } else {
          item.l3Visible = false
        }
        return item
      })
      this.goodsPageInfo.total = data.total
    },

    translateTotalState(e) {
      //let minTimestamp = new Date('1971-01-01 00:00:00').getTime()
      //let maxTimestamp = new Date('2038-01-01 00:00:00').getTime()
      let nowTimestamp = new Date().getTime()
      if (e.effectiveTime > nowTimestamp || e.failureTime < nowTimestamp) {
        return '下架';
      }
      if (e.goodsState == 'normal') {
        return '正常';
      } else if (e.goodsState == 'sell_out') {
        return '售罄';
      }
    },
    commitChooseGoods(list) {
      let addItems = list.map(item => {
        // this.pimActivityGoodsRelaDispNo += 1
        return {
          goodsId: item.goodsId,
          cnName: item.cnName,
          goodsCode: item.goodsCode,
          pictureUrl: item.pictureUrl,
          initPrice: item.initPrice
        }
      })
      this.checkGoodsList = []
      this.checkGoodsList = addItems
      this.checkGoodsDialogVisible = true
      //this.createNewFlag = true
    },
    changePage(e) {
      this.goodsPageInfo.pageNo = e
      this.getGoods();
    },
    seeDetail() {
    },
    addGoods() {
      //this.goodsDialogVisible = true
      this.$refs.selectGoodsPopup.dialogVisible = true;
    },
    async commitCheckGoods() {
      this.checkGoodsList.map(item => {
        if (item.l0Visible) {
          item.l0Visible = 1
        } else {
          item.l0Visible = 0
        }
        if (item.l1Visible) {
          item.l1Visible = 1
        } else {
          item.l1Visible = 0
        }
        if (item.l2Visible) {
          item.l2Visible = 1
        } else {
          item.l2Visible = 0
        }
        if (item.l3Visible) {
          item.l3Visible = 1
        } else {
          item.l3Visible = 0
        }
        return item
      })
      if (!this.unEdit) {
        let params = {
          eventId: this.goodsPageInfo.eventId,
          eventGoodsList: this.checkGoodsList,
        }
        await addEventGoods(params)
        this.getGoods()
      } else {
        let item = this.checkGoodsList[0]
        await editEventGoods(item)
        this.getGoods()
      }
      this.checkGoodsDialogVisible = false
      this.goodsDialogVisible = false
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
          /^\d*\.\d*\./g,
          e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      this.input = e.target.value;
    },
    delChooseGoods(i) {
      this.checkGoodsList.splice(i, 1)
    },
    async delPriceEventGoods(id) {
      await delEventGoods(id)
      this.getGoods()
    },
    goDetail(item) {
      this.checkGoodsList = []
      this.checkGoodsList.push(item)
      this.checkGoodsDialogVisible = true
      this.unEdit = true
    },
    closeCheck() {
      this.unEdit = false
    },
  },
  mounted() {
    this.goodsPageInfo.eventId = this.$route.query.id
    this.goodsPageInfo.eventName = this.$route.query.eventName
    if (this.goodsPageInfo.eventId) {
      this.getGoods()
    }
  },
  mixins: [pageTableMixin],
}
</script>

<style scoped>
.icon-operation i {
  color: #1B73E8;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
}

.info > div:nth-child(2) {
  width: 200px;
}

.info > div:nth-child(3) {
  margin-left: 10px;
}
</style>
