<template>
  <div>
    <div>
      <el-descriptions title="用户信息">
        <el-descriptions-item label="名称">{{ userInfo.fullName }}</el-descriptions-item>
        <el-descriptions-item label="账号">{{ userInfo.userAccount }}</el-descriptions-item>
        <el-descriptions-item label="类型">{{ userInfo.accountType }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="mt15" style="display: flex;" v-if="adminFlag">
      <el-button type="primary" @click="showAdd" v-if="!editMode">新增</el-button>
      <div style="width: 100%"></div>
      <el-button type="warning" @click="editMode=true" v-if="!editMode">开启编辑</el-button>
      <el-button type="danger" @click="editMode=false" v-if="editMode">关闭编辑</el-button>
    </div>
    <div class="mt15">
      <el-table :data="tableData" v-loading="tableLoading">
        <el-table-column prop="goodsName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="goodsCode" label="商品编码" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="预留数量" prop="goodsReserveNum" width="200">
          <template slot-scope="scope">
            <el-input style="width: 150px;margin-left:auto;"
                      v-model="scope.row.goodsReserveNum" disabled>
              <el-button slot="prepend"
                         v-if="adminFlag && editMode"
                         @click="addOrSubReserveNum(scope.row.reserveId,'sub')"
                         icon="el-icon-minus"></el-button>
              <el-button slot="append"
                         v-if="adminFlag && editMode"
                         @click="addOrSubReserveNum(scope.row.reserveId,'add')"
                         icon="el-icon-plus"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope" slot="header" width="200">
            <el-tooltip effect="dark" content="在数量范围内下单，使用特殊价格结算，范围外恢复原价。" placement="top">
              <span>特殊价格(?)</span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div>
              {{ scope.row.useReservePriceFlag?scope.row.goodsReservePrice:'不启用' }}
              <template class="icon-operation" v-if="editMode">
                <a href="javascript: void(0);" title="编辑" class="ml15">
                  <i class="CCSFont icon-table_1" @click="openEditPriceDialog(scope.row)"></i>
                </a>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="锁定库存" width="200">
          <template slot-scope="scope" slot="header">
            <el-tooltip effect="dark" content="为用户预留相关库品的库存，保证用户有库存下单。" placement="top">
              <span>锁定库存(?)</span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div>
              <el-switch v-model="scope.row.lockReserveOnWmsFlag"
                         @change="modifyLock(scope.$index)"
                         :disabled="!editMode"></el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="阻止下单" width="200">
          <template slot-scope="scope" slot="header" v-if="adminFlag">
            <el-tooltip effect="dark" content="当数量消耗完时，阻止用户下单，显示为【已售罄】。" placement="top">
              <span>阻止下单(?)</span>
            </el-tooltip>
          </template>
          <template slot-scope="scope" v-if="adminFlag">
            <div>
              <el-switch v-model="scope.row.rejectUseUpFlag" @change="modifyReject(scope.$index)"
                         :disabled="!editMode"></el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderCode" label="操作" width="100px" v-if="adminFlag">
          <template slot-scope="scope" v-if="adminFlag && editMode">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="删除" class="ml15">
                <i class="el-icon-delete-solid" @click="deleteItem(scope.row.reserveId)"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <reserve-create-popup ref="reserveCreatePopup"
                             @updateTable="loadTableData"
                             :userId="editUserId"></reserve-create-popup>
    <reserve-num-add-popup ref="reserveNumAddPopup"
                           @updateTable="loadTableData"
                           :reserveId="editReserveId" :appointOperateType="appointOperateType"></reserve-num-add-popup>
    <reserve-price-modify-popup ref="reservePriceModifyPopup"
                                @updateTable="loadTableData"
                                :reserveId="editReserveId"></reserve-price-modify-popup>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import userInfoService from "API/bim/userInfoService.js";
import pimReserveService from "API/pim/PimReserveService"
import reserveCreatePopup from "./reserveCreatePopup";
import reserveNumAddPopup from "./reserveNumAddPopup";
import reservePriceModifyPopup from "./reservePriceModifyPopup";

export default {
  name: "reservePriceDetail",
  components: {
    reserveCreatePopup,
    reserveNumAddPopup,
    reservePriceModifyPopup
  },
  data() {
    return {
      userId: '',
      userInfo: {},
      tableData: [],
      //
      editReserveId: '',
      editUserId: '',
      appointOperateType: '',
      //
      adminFlag: false,
      editMode: false,
      tableLoading:false,
    }
  },
  mounted() {
    const loginUser = JSON.parse(userModule.PPUser);
    if (loginUser.role.indexOf("admin") > -1) {
      this.adminFlag = true;
    } else {
      this.adminFlag = false;
    }
    this.userId = loginUser.userId
    if (this.$route.params.userId) {
      this.userId = this.$route.params.userId;
    }
    this.getUserDetail();
    this.loadTableData()
  },
  methods: {
    addOrSubReserveNum(id, opr) {
      this.editReserveId = id;
      this.appointOperateType = opr;
      this.$refs.reserveNumAddPopup.show();
    },
    openEditPriceDialog(row){
      this.editReserveId = row.reserveId;
      this.$refs.reservePriceModifyPopup.show();
    },
    showAdd() {
      this.editUserId = this.userId;
      this.$refs.reserveCreatePopup.show()
    },
    modifyLock(idx) {
      let item = this.tableData[idx]
      if (item) {
        let param = {
          reserveId: item.reserveId,
          lockReserveOnWms: item.lockReserveOnWms == '1' ? '0' : '1'
        }
        this.tableLoading = true;
        pimReserveService.modifyLockProperty(param).then(res=>{
          this.loadTableData();
        }).catch(e=>{
          this.loadTableData();
        })
      }
    },
    modifyReject(idx) {
      let item = this.tableData[idx]
      if (item) {
        let param = {
          reserveId: item.reserveId,
          rejectUseUp: item.rejectUseUp == '1' ? '0' : '1'
        }
        this.tableLoading = true;
        pimReserveService.modifyRejectProperty(param).then(res=>{
          this.loadTableData();
        }).catch(e=>{
          this.loadTableData();
        })
      }
    },
    deleteItem(reserveId) {
      this.$confirm(`确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let param = {
          reserveId: reserveId
        }
        this.tableLoading = true;
        pimReserveService.deletePimReserve(param).then(() => {
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    getUserDetail() {
      let params = {
        userId: this.userId
      }
      userInfoService.getUser(params).then(data => {
        if (data) {
          this.userInfo = data;
          if (data.roleCodes.indexOf("distributor_") > -1) {
            this.userInfo.accountType = "会员";
          } else if (data.roleCodes.indexOf("agent_") > -1) {
            this.userInfo.accountType = "经销商";
          }
        }
      })
    },
    loadTableData() {
      this.tableLoading = true;
      pimReserveService.queryDetail(this.userId).then(data => {
        //console.log(data)
        data = data.map(e => {
          return {
            ...e,
            lockReserveOnWmsFlag: e.lockReserveOnWms == '1',
            rejectUseUpFlag: e.rejectUseUp == '1',
            useReservePriceFlag: e.useReservePrice == '1',
          }
        })
        this.tableData = data
        this.tableLoading = false;
      })
    },
  }
}
</script>

<style scoped>
.card-parent {
  display: flex;
  flex-wrap: wrap;
}

.box-card {
  width: 720px;
  margin-top: 10px;
  margin-right: 10px;
}
</style>