<template>
  <div>
    <steps :active="0" :steps="steps"></steps>
    <div class="main">
      <el-form label-width="110px">
        <div class="box-shadow-unify base-from step-wrapper info-item">
          <div class="info-subtitle">有效时间</div>
          <div class="info-content">
            <el-form-item label="商品名称：" required>
              <el-input v-model="info.cnName" maxlength="100"></el-input>
              <div class="tips">用于消费端展示，最多100字</div>
            </el-form-item>
            <el-form-item label="零售价：" required>
              <el-input v-model="info.retailPrice" style="width: 150px;">
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
            <el-form-item label="分享描述：">
              <el-input v-model="info.goodsShareDescribe"></el-input>
              <div class="tips">
                将商品分享到微信时，默认展示该标题，最多30字。未填写默认展示商品名称。
                <span
                  class="tips-btn"
                  @click="$refs.shareExamplePopup.dialogVisible = true"
                >查看示例</span>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="box-shadow-unify info-item attr-table">
          <div class="info-subtitle">
            <span>属性信息</span>
            <el-button type="primary" @click="addAttr">添加属性</el-button>
          </div>
          <ws-page-table
            :data="info.attrList"
            ref="pageTable"
            :isShadow="false"
            :pageable="false"
            :showIndex="true"
          >
            <el-table-column label="属性名称">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="属性内容 ">
              <template slot-scope="scope">
                <el-input v-model="scope.row.content"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="是否启用">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.isEnable"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="icon-operation">
                  <i class="el-icon-delete-solid" @click="deleteAttr(scope.$index)"></i>
                </div>
              </template>
            </el-table-column>
          </ws-page-table>
        </div>
        <div class="step-wrapper box-shadow-unify">
          <div class="step-title">图片信息</div>
          <el-form label-width="110px">
            <el-form-item required label="轮播图：">
              <div>
                <draggable-upload
                  :width="82"
                  :height="82"
                  :limitRatio="true"
                  :fileWidth="800"
                  :fileHeight="1000"
                  :uploadUrl="uploadUrl()"
                  :requestHeaders="requestHeaders"
                  v-model="info.rotationImgs"
                ></draggable-upload>
              </div>
              <div class="tips">建议尺寸：800*1000像素，你可以拖拽图片调整顺序，最多上传10张</div>
            </el-form-item>
            <el-form-item label="商品视频：">
              <div>
                <video-upload
                  :url="info.videoUrl"
                  :uploadUrl="uploadUrl()"
                  :requestHeaders="requestHeaders"
                  @deleteSuccess="deleteVideo"
                  @uploadSuccess="uploadVideoSuccess"
                ></video-upload>
              </div>
              <div class="tips">建议时长9-30秒，建议视频宽高比16:9.上传后展示在轮播首位，最多1个</div>
            </el-form-item>
            <el-form-item required label="详情图：">
              <div>
                <draggable-upload
                  :width="82"
                  :height="82"
                  :limitRatio="true"
                  :fileWidth="750"
                  :fileHeight="900"
                  :uploadUrl="uploadUrl()"
                  :requestHeaders="requestHeaders"
                  v-model="info.detailImgs"
                ></draggable-upload>
              </div>
              <div class="tips">建议尺寸：750*900像素，你可以拖拽图片调整顺序</div>
            </el-form-item>
            <el-form-item label="分享图：">
              <div>
                <draggable-upload
                  :width="82"
                  :height="82"
                  :limit="1"
                  :limitRatio="true"
                  :fileWidth="297"
                  :fileHeight="230"
                  :fileSize="128*1024"
                  fileSizeText="128k"
                  :uploadUrl="uploadUrl()"
                  :requestHeaders="requestHeaders"
                  v-model="info.shareImgs"
                ></draggable-upload>
              </div>
              <div class="tips">建议尺寸：297*230像素，你可以拖拽图片调整顺序</div>
            </el-form-item>
          </el-form>
        </div>
      </el-form>
    </div>
    <div class="step-btn">
      <el-button @click="prevStep">上一步</el-button>
      <el-button type="primary" @click="nextStep">下一步</el-button>
    </div>
    <example-popup
      ref="shareExamplePopup"
      title="查看分享示例"
      tips="将商品再微信分享给朋友时，该处填写的内容会展示在商品名称下面"
      :imgUrl="require('ASSETS/images/share-example.png')"
    ></example-popup>
  </div>
</template>

<script>
import steps from "COMPS/widgets/steps";
import examplePopup from "COMPS/widgets/examplePopup";
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import PimGoodsService from "API/pim/PimGoodsService";
import videoUpload from "COMPS/base/videoUpload";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";

export default {
  data() {
    return {
      steps: [
        {
          name: "关联商品"
        },
        {
          name: "基本信息"
        },
        {
          name: "设置"
        }
      ],
      requestHeaders: {
        Authorization: userModule.token
      },
      info: {
        cnName: "",
        retailPrice: "",
        goodsShareDescribe: "",
        attrList: [
          {
            name: "",
            content: "",
            isEnable: false,
            state: 1
          }
        ],
        rotationImgs: [], // 轮播
        detailImgs: [], // 详情
        videoUrl: "", // 视频
        shareImgs: [] // 分享图
      }
    };
  },
  components: { steps, examplePopup, videoUpload, draggableUpload },
  mounted() {
    if (JSON.stringify(this.$store.state.virtualGoods.goodsInfo) != "{}") {
      this.info = this.$store.state.virtualGoods.goodsInfo;
    }
  },
  beforeDestroy() {
    this.$store.commit("saveVirtualGoods_goodsInfo", this.info);
  },
  methods: {
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    nextStep() {
      this.$router.push("/dim/virtualGoodsSetting");
      if (!this.info.cnName) {
        return this.$message({
          message: "请填写商品名称",
          type: "warning"
        });
      }
      if (!this.info.retailPrice) {
        return this.$message({
          message: "请填写零售价",
          type: "warning"
        });
      }
      if (this.info.attrList.filter(e => !e.name || !e.content).length) {
        return this.$message({
          message: "请填写完整商品属性",
          type: "warning"
        });
      }
      if (!this.info.rotationImgs.length) {
        return this.$message({
          message: "请添加轮播图",
          type: "warning"
        });
      }
      if (!this.info.detailImgs.length) {
        return this.$message({
          message: "请添加详情图",
          type: "warning"
        });
      }
    },
    prevStep() {
      this.$router.push("/dim/addVirtualGoods");
    },
    addAttr() {
      this.info.attrList.push({
        name: "",
        content: "",
        isEnable: false,
        state: 1
      });
    },
    deleteAttr(index) {
      if (this.attrList.length == 1) return;
      this.attrList.splice(index, 1);
    },
    uploadVideoSuccess() {},
    deleteVideo() {
      this.videos = [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/components/productAdd.scss";

.step-btn {
  width: 100%;
  margin-top: 40px;
  text-align: center;
  .el-button {
    width: 110px;
    margin: 0 15px;
  }
}
.info-item {
  margin-bottom: 40px;
  .info-subtitle {
    padding: 0 20px;
    line-height: 60px;
    font-size: 18px;
    font-weight: bold;
    background-color: #f6f9fc;
  }
  .info-content {
    padding-top: 20px;
  }
}

.attr-table {
  .info-subtitle {
    background-color: #fff;
    .el-button {
      margin-left: 15px;
    }
  }
  .el-input {
    width: 200px;
  }
}
</style>

