<template>
  <div>
    <div class="goods-table box-shadow-unify">
      <div class="tabs-wrapper">
        <div class="tools-wrapper">
          <el-button type="primary" @click="addDialogVisible = true">添加商品</el-button>
        </div>
        <el-tabs v-model="tabsActiveName" type="card" @tab-click="changeTable">
          <el-tab-pane label="零售商品" name="first"></el-tab-pane>
          <el-tab-pane label="招商商品" name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :isShadow="false"
      >
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="retailPrice" label="零售价"></el-table-column>
        <el-table-column prop="type" label="商品类型"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="编辑">
                <i class="CCSFont icon-table_1"></i>
              </a>
              <a href="javascript: void(0);" title="查看">
                <i class="CCSFont icon-table_2"></i>
              </a>
              <a href="javascript: void(0);" title="下架" @click="offShelfGoods(scope.row)">
                <i class="CCSFont icon-table_3"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <el-dialog title="选择添加的商品类型" :visible.sync="addDialogVisible" width="500px">
      <div class="goods-type-group">
        <div class="goods-type" :class="{'active': goodsType === 1}" @click="goodsType = 1">添加实体商品</div>
        <div class="goods-type" :class="{'active': goodsType === 2}" @click="goodsType = 2">添加虚拟商品</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toNextStep">下一步</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import DimGoodsService from "API/dim/DimGoodsService";

export default {
  data() {
    return {
      tabsActiveName: "first",
      addDialogVisible: false,
      goodsType: 1
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      let data = {
        list: [
          {
            name: "商品名称",
            retailPrice: "25.00",
            type: "虚拟商品"
          }
        ],
        total: 1
      };
      this.querySuccess(data);
    },
    changeTable() {
      this.clearPage();
      this.loadTableData();
    },
    offShelfGoods(row) {
      this.$confirm("是否下架该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {})
        .catch(() => {});
    },
    toNextStep() {
      if (this.goodsType === 1) {
        this.$store.commit("CLEAR_PHYSICALGOODS");
        this.$router.push("/dim/addPhysicalGoods");
      }
      if (this.goodsType === 2) {
        this.$store.commit("CLEAR_VIRTUALGOODS");
        this.$router.push("/dim/addVirtualGoods");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.goods-table {
  background-color: #fff;
  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }
  .tabs-wrapper {
    padding: 20px;
    .tools-wrapper {
      margin-bottom: 15px;
    }
  }
}
/deep/ .el-dialog {
  .el-dialog__header {
    border-bottom: none;
    padding-left: 40px;
  }
  .dialog-footer {
    text-align: center;
  }
  .el-dialog__body {
    padding: 40px;
    padding-top: 20px;
  }
  .goods-type-group {
    .goods-type {
      cursor: pointer;
      text-align: center;
      line-height: 120px;
      border: 1px solid #707070;
      color: #666;
      font-size: 18px;
      border-radius: 2px;
      &:first-of-type {
        margin-bottom: 30px;
      }
      &.active {
        border: 1px solid $themeColor;
        color: $themeColor;
      }
    }
  }
}
</style>