<template>
  <div>
    <div style="display: flex;margin-bottom: 20px;height: 50px;align-items: center;">
      <div style="line-height: 50px;">
        账户余额: ¥{{ headerInfo.balanceNum }}
      </div>
      <div style="margin-left: auto;">
        <el-button type="primary" @click="showRechargeDeposit">充值</el-button>
      </div>
    </div>
    <div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
      >
        <el-table-column min-width="150" prop="createTime" label="时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column min-width="150" prop="type" label="渠道">
          <template slot-scope="scope">
            {{
              scope.row.type == '9' ? '线下转账' :
                  scope.row.type == '10' ? '微信支付' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="auditState" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.auditState == 'success' ? '充值成功' :
                  scope.row.auditState == 'fail' ? '充值失败' :
                      scope.row.auditState == 'awaiting' ? '等待审核' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="alterBalance" label="充值金额">
          <template slot-scope="scope">
            <div
                :style="{'color': scope.row.auditState == 'success'?'green':scope.row.auditState == 'fail'?'':'red'}">
              {{ (scope.row.auditState == 'success') ? '+' : '' }}
              ¥{{ scope.row.operNum ? chargeFormat.thousandsOf(scope.row.operNum) : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" label="操作">
          <template slot-scope="scope">
            <a
                href="javascript: void(0);"
                @click="showAuditDialog(scope.row)"
                class="blue-txt"
                title="查看"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <el-dialog title="充值审核" :visible.sync="showDialog" width="500px">
      <div class="dea-txt dea-tit">充值金额：¥{{ rechargeObj.rechargeAmount }}</div>
      <div v-if="rechargeObj.payChannel=='wxpay'">
        <div class="dea-txt dea-tit">微信支付信息</div>
        <div class="dea-txt">支付时间：{{ rechargeObj.payTimeStr }}</div>
        <div class="dea-txt">交易单号：{{ rechargeObj.payNo }}</div>
        <div class="dea-txt dea-tit">
          支付结果：{{ dealerLog.auditState == 'success' ? '成功' : dealerLog.auditState == 'fail' ? '失败' : '待验证' }}
        </div>
        <div class="dea-txt">备注：{{ dealerLog.remark }}</div>
      </div>
      <div v-else>
        <div class="dea-txt dea-tit">账户转账支付信息</div>
        <div class="dea-txt">
          付款人/付款公司名称：{{ rechargeObj.accountName }}
        </div>
        <div class="dea-txt">开户银行账号：{{ rechargeObj.accountNo }}</div>
        <div class="dea-txt">开户银行名称：{{ rechargeObj.bankName }}</div>
        <div class="dea-txt">
          <span class="sp1">转账凭证：</span>
          <el-image
              v-for="(item, index) in rechargeObj.rechargeVoucherList"
              :key="index"
              style="width: 100px; height: 100px"
              :src="rechargeObj.rechargeVoucherList[index]"
              :preview-src-list="rechargeObj.rechargeVoucherList"
              :zIndex="3000"
          >
          </el-image>
        </div>
        <div class="dea-txt dea-tit">
          审核结果：{{ dealerLog.auditState == 'success' ? '通过' : dealerLog.auditState == 'fail' ? '驳回' : '待审核' }}
        </div>
        <div class="dea-txt">审核备注：{{ dealerLog.remark }}</div>
      </div>
      <div class="dea-btn-wrap">
        <el-button type="primary" @click="showDialog=false;">关闭</el-button>
        <el-button type="danger" @click="checkPaymentStateAgain"
                   style="margin-left: 20px;" v-if="dealerLog.auditState != 'success'">
          再次验证支付结果</el-button>
      </div>
    </el-dialog>
    <recharge-pop @reloadAfterRecharge="reloadPageInfo()" ref="rechargeDeposit"></recharge-pop>
    <temporary-dialog />
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import rechargePop from "./rechargePop";
import dealerService from "API/dea/dealerService";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import chargeFormat from "COMJS/chargeFormat.js";
import userModule from "@/module/userModule";
import temporaryDialog from "PAGES/som/order/TemporaryDialog";
//import sysUtil from "@/constant/SysUtil";

export default {
  name: "dealerRechargeList",
  components: {
    draggableUpload,
    rechargePop,
    temporaryDialog
  },
  mixins: [pageTableMixin],
  computed: {
    chargeFormat() {
      return chargeFormat;
    }
  },
  data() {
    return {
      //isCreditDealer: true,
      headerInfo: {
        balanceNum: "",
        lineOfCredit: "",
        alsoLineOfCredit: "",
        refundBalance: "",
        stayClose: "",
        stayEnter: "",
      },
      showDialog: false,
      dealerLog: {},
      rechargeObj: {},
      searchInfo: {
        bimUserId: JSON.parse(userModule.PPUser).userId,
        createTime: [],
        type: "9,10",
        auditState: "success,fail,awaiting"
      },
      items: {
        bimUserId: {filter: "eq"},
        createTime: {filter: "between"},
        type: {filter: "in"},
        auditState: {filter: "in"},
      },
    }
  },
  mounted() {
    //this.isCreditDealer = sysUtil.isAgentCredit(JSON.parse(userModule.PPUser).role);
    this.getDealerInfo();
  },
  methods: {
    reloadPageInfo(){
      this.getDealerInfo();
      this.loadTableData();
    },
    getDealerInfo() {
      dealerService.findDealerLinesInfo(JSON.parse(userModule.PPUser).userId).then((data) => {
        this.headerInfo.balanceNum = (data.balanceNum - 0).toFixed(2)
        /*
        this.headerInfo.canUseMoney = (data.alsoLineOfCredit + data.refundBalance).toFixed(2)
        this.headerInfo.lineOfCredit = (data.lineOfCredit - 0).toFixed(2)
        this.headerInfo.alsoLineOfCredit = (data.alsoLineOfCredit - 0).toFixed(2)
        this.headerInfo.stayClose = (data.stayClose - 0).toFixed(2)
        this.headerInfo.stayEnter = (data.stayEnter - 0).toFixed(2)
        this.headerInfo.refundBalance = (data.refundBalance - 0).toFixed(2)
        */
      });
    },
    checkPaymentStateAgain(row){
      let loading = this.$loading({
        lock: true,
        text: "支付结果确认中...",
      });
      let outTradeNo = this.dealerLog.dealerLogId
      let that = this
      dealerService
          .checkPayState(outTradeNo)
          .then((data) => {
            if (data) {
              if (data["result"] == "success") {
                that.$message({type: "success", message: "支付成功！"});
                that.showDialog = false;
                that.reloadPageInfo();
              } else {
                //支付检查失败，提示
                that.$message.error(data["msg"]);
                //that.showDialog = false;
              }
            }
            loading.close();
          })
          .catch((data) => {
            that.$message.error("支付失败");
            loading.close();
          });

    },
    showAuditDialog(row) {
      this.dealerLog = row
      let content = row.content
      if (content) {
        this.rechargeObj = JSON.parse(content)
        this.rechargeObj.rechargeVoucherList = this.rechargeObj.rechargeVoucher
            ? this.rechargeObj.rechargeVoucher.split(",") : [];
      } else {
        this.rechargeObj = {}
      }
      //console.log(this.dealerLog)
      this.showDialog = true

    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      dealerService
          .queryDealerLog(this.pageInfo, this.searchConfig)
          .then((data) => {
            data.list = data.list.map((e) => {
              //console.log(e)
              return {
                ...e,
                createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss")
              };
            });
            this.querySuccess(data);
          });
    },
    showRechargeDeposit() {
      this.$refs.rechargeDeposit.show({'model': 'rechargeAndPay'});
    }
  }
}
</script>

<style scoped>
.dea-txt {
  padding: 9px 0;
  display: flex;
  color: #666;
}

.dea-tit {
  font-weight: bold;
}

.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-txt .sp2 {
  margin-left: 50px;
}

.dea-btn-wrap {
  text-align: center;
}

.bill-table {
  padding: 20px 0 20px 20px;
}

.bill-table td {
  padding: 10px 100px 10px 0;
}
</style>