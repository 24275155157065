<template>
  <div class="uploadWrapper">
    <vuedraggable class="vue-draggable" :class="{ single: isSingle, maxHidden: isMaxHidden }" v-model="imgList" tag="ul"
                  draggable=".draggable-item" @start="onDragStart" @end="onDragEnd">
      <!-- 拖拽元素 -->
      <li v-for="(item, index) in imgList" :key="item + index" class="draggable-item"
          :style="{ width: width + 'px', height: height + 'px' }">
        <el-image :src="item.response" :preview-src-list="[item.response]"
                  :style="{ width: width + 'px', height: height + 'px' }"
                  fit="contain"></el-image>
        <div v-if="imgDel" class="shadow" @click="onRemoveHandler(index)">
          <i class="el-icon-delete"></i>
        </div>
        <div class="img-name" v-if="editImgName">
          <el-input v-model="item.name"></el-input>
        </div>
      </li>
      <!-- 上传按钮 -->
      <el-upload slot="footer" ref="uploadRef" class="uploadBox" :style="{ width: width + 'px', height: height + 'px' }"
                 :action="uploadUrl" :headers="requestHeaders" accept="image/*"
                 :show-file-list="false" :multiple="!isSingle" :limit="limit" :before-upload="beforeUpload"
                 :on-success="onSuccessUpload" :on-error="onErrorUpload" :on-exceed="onExceed">
        <i class="el-icon-plus uploadIcon">
          <span class="uploading" v-show="isUploading">正在上传...</span>
          <span v-if="!isUploading && limit && limit!==99 && !isSingle" class="limitTxt">最多{{ limit }}张</span>
        </i>
      </el-upload>
    </vuedraggable>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";

export default {
  name: "ImgUpload",

  props: {
    /**
     * 图片数据(图片url组成的数组) 通过v-model传递
     * @param {object} img
     * @param {String} img.url
     * @param {String} img.name
     * @param {String} img.response el-upload返回的地址
     * @return [img]
     */
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    uploadUrl: String,
    requestHeaders: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // 限制上传的图片数量
    limit: {
      type: Number,
      default: 99,
    },
    //是否需要名称
    editImgName: {
      type: Boolean,
      default: false,
    },
    //是否需要删除按钮
    imgDel: {
      type: Boolean,
      default: true,
    },
    // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
    isSingle: {
      type: Boolean,
      default: false,
    },
    // 图片显示的宽度(px)
    width: {
      type: Number,
      default: 100,
    },
    // 图片显示的高度(px)
    height: {
      type: Number,
      default: 100,
    },
    limitRatio: {
      type: Boolean,
      default: false,
    },
    fileWidth: Number,
    fileHeight: Number,
    fileSize: {
      type: Number,
      default: 1024 * 1024 * 2,
    },
    fileSizeText: {
      type: String,
      default: "2M",
    },
  },

  data() {
    return {
      isUploading: false, // 正在上传状态
      isFirstMount: true, // 控制防止重复回显
    };
  },
  components: {vuedraggable},
  computed: {
    // 图片数组数据
    imgList: {
      get() {
        return this.value;
      },
      set(val) {
        if (val.length < this.imgList.length) {
          // 判断是删除图片时同步el-upload数据
          this.syncElUpload(val);
        }
        // 同步v-model
        this.$emit("input", val);
      },
    },
    // 控制达到最大限制时隐藏上传按钮
    isMaxHidden() {
      return this.imgList.length >= this.limit;
    },
  },

  watch: {
    value: {
      handler(val) {
        if (this.isFirstMount && this.value.length > 0) {
          this.syncElUpload();
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.value.length > 0) {
      this.syncElUpload();
    }
  },

  methods: {
    // 同步el-upload数据
    syncElUpload(val) {
      let imgList = val || this.imgList;
      //console.log(imgList);
      this.$refs.uploadRef.uploadFiles = imgList.map((v, i) => {
        return {
          name: v.name,
          url: v.response,
          response: v.response,
          status: 1,
        };
      });
      this.isFirstMount = false;
    },
    // 上传图片之前
    beforeUpload(file) {
      let that = this;
      this.isUploading = true;
      let isLtM = this.fileSize > file.size;
      if (!isLtM) {
        this.$message.warning(`图片大小不能超过${this.fileSizeText}`);
        this.isUploading = false;
        return false;
      }
      if (this.limitRatio) {
        const isSize = new Promise(function (resolve, reject) {
          let width = that.fileWidth;
          let height = that.fileHeight;
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
          img.onload = function () {
            let valid = img.width / img.height === width / height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(
            () => {
              return file;
            },
            () => {
              that.$message.error(
                  `图片尺寸限制为${that.fileWidth} x ${that.fileHeight}`
              );
              return Promise.reject();
            }
        );
        this.isUploading = false;
        return isLtM && isSize;
      } else {
        return isLtM;
      }
    },
    // 上传完单张图片
    onSuccessUpload(res, file, fileList) {
      setTimeout(() => {
        this.imgList.push(file);
        this.syncElUpload(this.imgList);
        this.isUploading = false;
      }, 500);
    },
    onErrorUpload(res) {
      this.$message({
        message: "图片上传失败",
        type: "error",
      });
      this.isUploading = false;
    },
    // 移除单张图片
    onRemoveHandler(index) {
      this.$confirm("确定删除该图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.imgList = this.imgList.filter((v, i) => {
              return i !== index;
            });
          })
          .catch(() => {
          });
    },
    // 超限
    onExceed() {
      this.$refs.uploadRef.abort(); // 取消剩余接口请求
      this.syncElUpload();
      this.$message({
        type: "warning",
        message: `图片超限，最多可上传${this.limit}张图片`,
      });
      console.log("onExceed", this.value)
    },
    onDragStart(e) {
      e.target.classList.add("hideShadow");
    },
    onDragEnd(e) {
      e.target.classList.remove("hideShadow");
    },
    clearFiles() {
      //console.log("clearFiles")
      this.$refs.uploadRef.clearFiles();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload {
  width: 100%;
  height: 100%;
}

// 上传按钮
.uploadIcon {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /*border: 1px dashed #c0ccda;*/
  background-color: #fbfdff;
  border-radius: 6px;
  font-size: 20px;
  color: #999;

  .limitTxt,
  .uploading {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}

// 拖拽
.vue-draggable {
  display: flex;
  flex-wrap: wrap;

  .draggable-item {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;
    }

    .shadow {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      padding: 2px;
      cursor: pointer;
    }

    .img-name {
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
      transition: opacity 0.3s;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    &:hover {
      .shadow {
        opacity: 1;
      }
    }
  }

  &.hideShadow {
    .shadow {
      display: none;
    }
  }

  &.single {
    overflow: hidden;
    position: relative;

    .draggable-item {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &.maxHidden {
    .uploadBox {
      display: none;
    }
  }
}

// el-image
.el-image-viewer__wrapper {
  .el-image-viewer__mask {
    opacity: 0.8;
  }

  .el-icon-circle-close {
    color: #fff;
  }
}

.img-name /deep/ .el-input__inner {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  opacity: 0.6;
}
</style>
