<template>
  <el-dialog :title="'首金邀请'" :visible.sync="dialogFormVisible">
    <el-form :model="form" v-loading="loading" ref="form" :rules="rules" v-if="dialogFormVisible">
      <el-form-item  label="会员名称：" prop="memberName" :label-width="formLabelWidth" >
        <el-input  v-model.trim="form.memberName"  maxlength="16" show-word-limit clearable></el-input>
      </el-form-item>
      <el-form-item  label="会员电话：" prop="memberTel" :label-width="formLabelWidth">
        <el-input  v-model.trim="form.memberTel" maxlength="20" show-word-limit clearable></el-input>
      </el-form-item>
      <!--<el-form-item  label="审核通过后," prop="timeSpan" :label-width="formLabelWidth">-->
        <!--<el-input v-model.trim="form.timeSpan" maxlength="10"  style ="width:50px"></el-input>-->
        <!--<span>-->
          <!--小时内有效-->
        <!--</span>-->
      <!--</el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
    import tools from "COMJS/tools";
    import userModule from "@/module/userModule";
    import BimInviteService from "API/bim/BimInviteService";


    export default {
        data() {
            var validateTel = (rule, value, callback) => {
                if(!(/^1[3456789]\d{9}$/.test(value))){
                    callback(new Error("电话号码格式有误，请重新输入"))
                }else{
                    callback();
                }
            };
            var validateTimeSpan = (rule, value, callback) => {
                if(!(/^[0-9]*[1-9][0-9]*$/.test(value))){
                    callback(new Error("时长只支持正整数，请重新输入"))
                }else{
                    callback();
                }
            };
            return {
                form: {
                    memberName: "",
                    memberTel: "",
                    timeSpan: ""
                },
                requestHeaders: {
                    Authorization: userModule.token
                },
                dialogFormVisible: false,
                formLabelWidth: "100px",
                labelWidth:"30px",
                loading: false,
                flag:true,
            rules: {
                    memberName: [
                        { required: true, message:"请输入会员名称", trigger: 'blur'}

                    ],
                    memberTel: [
                        { required: true, message:"请输入会员电话", trigger: 'blur'},
                        { validator: validateTel, trigger: 'blur' }

                    ],
                    timeSpan: [
                        { required: true, message:"请输入有效时长", trigger: 'blur' },
                        {validator: validateTimeSpan, trigger: 'blur' }
                    ]
                }
            };
            },
        watch: {
            dialogFormVisible() {
                this.resetForm();
            }
        },
        methods: {

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            memberName: this.form.memberName,
                            memberTel: this.form.memberTel,
                            timeSpan: this.form.timeSpan
                        };
                        this.loading = true;
                        BimInviteService.saveInvite(params).then(res => {
                            this.loading = false;
                        this.$emit("updateTable");
                    });
                        this.dialogFormVisible = false;
                        this.loading = false;

                    }else{
                    return false;
                }
                });

            },
            // 重置表单
            resetForm() {
                tools.clearObj(this.form);
            }
        }
    };
</script>

<style lang="scss">
  .upload-img-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
    color: $themeColor;
    border: 1px solid #dcdee0;
    border-radius: 0px;
  }
  .xrequired:before {
   content: '* ';
   color: red;
  }
</style>
