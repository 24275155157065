<template>
  <div class="whm-list-wrap">
    <el-form :inline="true" class="btn-wrap">
      <el-form-item>
        <el-button type="primary" @click="addSku">新增库品</el-button>
        <el-button type="primary" @click="addGroup">新增组合库品</el-button>
      </el-form-item>
    </el-form>  
    <el-form :inline="true" class="demo-form-inline" :class="{active:idx == 0}">
      <el-form-item label="库品编码:">
        <el-input v-model="searchInfo.skuCode" />
      </el-form-item>
      <el-form-item label="库品名称:">
        <el-input v-model="searchInfo.skuName" />
      </el-form-item>
      <el-form-item label="库品分类:">
        <!-- <el-input v-model="searchInfo.thingTypeName" /> -->
        <el-cascader
          v-model="value"
          :options="options"
          :props="{ checkStrictly: true }"
          @change="handleChange"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="demo-form-inline" :class="{active:idx == 1}">        
      <el-form-item label="组合编码:">
        <el-input v-model="searchInfo.skuGroupCode" />
      </el-form-item>
      <el-form-item label="组合名称:">
        <el-input v-model="searchInfo.groupName" />
      </el-form-item>
      <el-form-item label="库品编码:">
        <el-input v-model="searchInfo.skuCode" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchGroupData">搜索</el-button>
      </el-form-item>
    </el-form>

    <div class="inv-tab">
      <div class="inv-tab-box">
        <div class="inv-tab-list" @click="onTabClick(0)" :class="{active:idx == 0}">库品</div>
        <div class="inv-tab-list" @click="onTabClick(1)" :class="{active:idx == 1}">组合库品</div>
      </div>
    </div>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
      v-show="idx == 0"
    >
      <el-table-column
        prop="skuCode"
        label="库品编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="skuName"
        label="库品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="thingTypeName"
        label="库品分类"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="warningNum" label="库品预警值"></el-table-column>
      <el-table-column prop="soldOutNum" label="库品售罄值"></el-table-column>      
      <el-table-column label="是否启用">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.enabled"
            @click.native="toggleEnable(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadGroupTableData"
      v-show="idx == 1"
    >
      <el-table-column
        prop="skuGroupCode"
        label="组合编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="groupName"
        label="组合名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="skuNumJoin"
        label="组合内容"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="createUser" label="操作员" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="createTime" label="操作时间" :show-overflow-tooltip="true"></el-table-column>     
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="toGroupDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toGroupEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>          
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import invQuery from "API/whm/invQuery";
import tools from "COMJS/tools";

export default {
  name: "whmList",
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        skuCode: "",
        skuName: "",
        thingTypeId: "",
        skuGroupCode: "",
        groupName: "",
      },
      items: {
        skuCode: { filter: "like" },
        skuName: { filter: "like" },
        thingTypeId: { filter: "like" },
        skuGroupCode: { filter: "like" },
        groupName: { filter: "like" },
      },
      thingTypeName:'',//分类模糊查询的字段
      idx:0,
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    if(this.idx == 0){
      this.searchInfo.skuGroupCode = ""
      this.searchInfo.groupName = ""
      this.pageInfo.pageNo = 1;
      this.loadTableData()
    }else if(this.idx == 1){
      this.searchInfo.skuName = ""
      this.searchInfo.thingTypeId = ""
      this.pageInfo.pageNo = 1;
      this.loadGroupTableData()
    }
    this.getAllCategory();
  },
  methods: {
    // invGroupDetail(row){
    //   console.log(row)
    // },
    searchGroupData(){
      this.pageInfo.pageNo = 1
      this.loadGroupTableData()
    },
    onTabClick(idx){
      if(!this.$refs.pageTable.tableLoading){//加载完才能切换tab
        if(idx == 0){
          this.searchInfo.skuCode = ""
          this.searchInfo.skuGroupCode = ""
          this.searchInfo.groupName = ""
          this.pageInfo.pageNo = 1;
          //this.$set(this.pageInfo,'pageNo',1);
          this.loadTableData()
        }else if(idx == 1){
          this.searchInfo.skuCode = ""
          this.searchInfo.skuName = ""
          this.searchInfo.thingTypeId = ""
          this.pageInfo.pageNo = 1;
          //this.$set(this.pageInfo,'pageNo',1);
          this.loadGroupTableData()
        }
        this.idx = idx
      }
    },
    changeTree(val) {
      let arr = [];
      if (val) {
        val.forEach(item => {
          let obj = {};
          obj.value = item.thingTypeId;
          obj.label = item.nodeName;
          if (item.childs) {
            obj.children = this.changeTree(item.childs);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    getAllCategory() {
      const that = this
      WhmList.queryCategoryWhm({
        thingTypeName: this.thingTypeName
      }).then((data) => {
        this.options = that.changeTree(data)
      });
    },
    handleChange(val) {  
      if(val.length == 0){
        //console.log("触发了清空")
        this.searchInfo.thingTypeId = ""
        this.loadTableData()
      }
      this.searchInfo.thingTypeId = val[val.length - 1]
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.type = 0
      if(!this.searchInfo.thingTypeId){
        this.searchInfo.thingTypeId = ""
      }
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            thingTypeName: e.level ? e.level.thingTypeName : "",
            warningNum: e.stock.warningNum,
            soldOutNum: e.stock.sellOutNum,
            //reservedNum: e.reservedNum,
            enabled: e.enabled == 1 ? true : false,
          };
        });
        this.querySuccess(data);
      });
    },
    loadGroupTableData(){
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.type = ""
      invQuery.querySkuGroupPage(this.pageInfo, this.searchConfig).then((data) => {  
        //console.log(data)
        if(!data.list){
          data.list = []
        }
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            skuGroupId: e.skuGroupId,
            skuGroupCode: e.skuGroupCode,//组合编码
            groupName: e.groupName,//组合名称
            skuNumJoin: e.skuNumJoin,//组合内容
            createUser: e.createUser,//操作员
            createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss"),//操作时间
          };
        });
        this.querySuccess(data);
      });
    },
    addSku() {
      //this.$store.commit('CLEAR_PRODUCT')
      this.$router.push("/whm/whmEdit");
    },
    toDetail(row) {
      //this.$store.commit('CLEAR_PRODUCT')
      this.$router.push(`/whm/whmDetail/${row.id}`);
    },
    addGroup() {
      //this.$store.commit('CLEAR_PRODUCT')
      this.$router.push("/whm/invGroupEdit");
    },
    toGroupEdit(row){
      this.$router.push(`/whm/invGroupEdit/${row.skuGroupId}`);
    },
    toGroupDetail(row){
      this.$router.push(`/whm/invGroupDetail/${row.skuGroupId}`);
    },
    toEdit(row) {
      //this.$store.commit('CLEAR_PRODUCT')
      this.$router.push(`/whm/whmEdit/${row.id}`);
    },
    toggleEnable(row) {
      const that = this;
      this.$confirm(`是否切换库品'${row.skuName}'的启用状态？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.enabled = !row.enabled == true ? 1 : 0; // 切换状态
          row.skuId = row.id;
          WhmList.updStateWhm(row).then((res) => {
            that.loadTableData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.whm-list-wrap .btn-wrap{ padding:20px 0 0 20px; background: #fff;}
.whm-list-wrap .demo-form-inline {margin-bottom: 20px; padding: 20px; display: none; background: #fff;}
.whm-list-wrap .el-form--inline .el-form-item {margin-right: 15px; margin-bottom: 0;}
.whm-list-wrap .header-search {margin: 20px;height: 45px;display: flex;align-items: center;background-color: white;}
.whm-list-wrap .ws-page-table .icon-operation a {margin-right: 20px;}
.whm-list-wrap .inv-tab{ padding-top: 10px; padding-bottom: 20px; height: 70px; box-sizing: border-box; box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15); background: #fff;}
.whm-list-wrap .inv-tab-box{margin:0 20px; height: 39px; display: flex; border-bottom: 1px solid #E9ECEF;}
.whm-list-wrap .inv-tab-list{ width: 90px; height: 38px; position: relative; text-align: center; line-height: 38px; border:1px solid #DCDEE0; border-bottom: 0; font-size: 14px; cursor: pointer;}
.whm-list-wrap .inv-tab-list:first-child{ border-right: 0;}
.whm-list-wrap .inv-tab-list.active:after{ position: absolute; content: ""; left: 0; bottom: -3px; width: 90px; height: 6px; background: #fff; z-index: 1;}
.whm-list-wrap .demo-form-inline.active{ display: block;}
</style>