const frame = { template: "<router-view></router-view>" };

export default {
  path: "pur",
  component: frame,
  children: [
    {
      name: "采购管理",
      path: "purList",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pur/PurList.vue"),
    },
    {
      name: "物料参数管理",
      path: "materialInfo",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pur/MaterialInfoManager.vue"),
    },
    {
      name: "物料采购流程",
      path: "materialProcess",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pur/MaterialProcessManager.vue"),
    },
    {
      name: "物料采购详情",
      path: "materialDetail/:id?",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pur/MaterialProcessDetail.vue"),
    },
    {
      name: "物料库存管理",
      path: "materialStock",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pur/MaterialStockManager.vue"),
    },
    {
      name: "工厂生产管理",
      path: "factoryProduction",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/pur/FactoryProductionManager.vue"),
    },
  ],
};
