import axiosHttp from "../axiosHttp";

export default {
    queryByParam(param){
        return axiosHttp.POST("/freshMilkCard/queryByParam", param);
    },
    pageByParam(param) {
        return axiosHttp.POST("/freshMilkCard/pageByParam", param);
    },
    pageMilkCardRecordByParam(param){
        return axiosHttp.POST("/freshMilkCard/pageMilkCardRecordByParam", param);
    },

    //统计奶卡记录
    getMilkCardRecordStatistics(param){
        return axiosHttp.POST("/freshMilkCard/getMilkCardRecordStatistics", param);
    },
};
