<template>
  <div class="order-pay">
    <el-dialog title="支付" :visible.sync="dialogVisible" width="660px">
      <div style="padding-top: 32px">
        <div
            style="display: flex; justify-content: space-between; font-size: 14px"
        >
          <div>共{{ this.paymentInfo.orderCount }}个订单，请选择支付方式</div>
          <div>
            订单金额：<span
              style="
                color: #1b73e8;
                font-weight: bold;
                font-family: San Francisco Display;
                font-size: 24px;
              "
          >¥{{ this.paymentInfo.orderAmount.toFixed(2) }}</span
          >
          </div>
        </div>
        <el-radio-group
            v-model="choosePayway"
            @change="changePayway"
            style="margin-top: 27px"
        >
          <el-radio v-for="item in paywayList" :key="item.value"
                    :label="item.value" border>{{ item.label }}
          </el-radio>
          ><!--现结经销商或会员-->
        </el-radio-group>
        <div
            style="
            margin-top: 20px;
            border: 1px solid rgba(218, 218, 218, 1);
            padding: 10px;
          "
            v-show="choosePayway == 'credit'"
        >
          <div
              style="
              font-size: 14px;
              wdith: 100%;
              align-items: center;
              padding: 0 16px;
              height: 60px;
              border: 2px solid rgba(70, 141, 234, 1);
              display: flex;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <img
                  :src="require('ASSETS/images/payment/pay-icon.png')"
                  width="24px;height:27px;"
                  alt=""
              />
              <div style="margin-left: 10px">
                额度 {{ creditLimit.toFixed(2) }} 元
              </div>
            </div>
            <div>
              支付
              <span style="color: #468dea; font-size: 18px">
                {{ paymentInfo.orderAmount.toFixed(2) }}
              </span>
              元
            </div>
          </div>
          <div
              v-if="paymentInfo.orderAmount > creditLimit"
              style="font-size: 12px; color: red; margin-top: 5px"
          >
            额度不足，无法支付
          </div>
        </div>
        <div
            style="
            margin-top: 20px;
            border: 1px solid rgba(218, 218, 218, 1);
            padding: 10px;
          "
            v-show="choosePayway == 'deposit'"
        >
          <div v-if="isAgentCredit" style="margin-bottom: 10px;color: red;">
            *请注意，余额支付和信用支付分属两个账户，资金不互通
          </div>
          <div
              style="
              font-size: 14px;
              wdith: 100%;
              align-items: center;
              padding: 0 16px;
              height: 60px;
              border: 2px solid rgba(70, 141, 234, 1);
              display: flex;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <img
                  :src="require('ASSETS/images/payment/pay-icon.png')"
                  width="24px;height:27px;"
                  alt=""
              />
              <div style="margin-left: 10px">
                余额 {{ depositAmount.toFixed(2) }} 元
              </div>
            </div>
            <div>
              支付
              <span style="color: #468dea; font-size: 18px">
                {{ paymentInfo.orderAmount.toFixed(2) }}
              </span>
              元
            </div>
          </div>
          <div
              v-if="paymentInfo.orderAmount > depositAmount"
              style="font-size: 12px; color: red; margin-top: 5px"
          >
            余额不足，无法支付
          </div>
        </div>
        <div
            style="text-align: center; border: 1px solid rgba(218, 218, 218, 1)"
            v-show="choosePayway == 'wxpay'"
        >
          <div style="display: flex; padding-left: 80px">
            <div id="leftDom">
              <!-- <img
                :src="require('ASSETS/images/payment/WePayLogo.png')"
                height="40px;"
              /> -->
              <div
                  id="qrCodeDom"
                  style="width: 146px; height: 146px; margin-top: 100px; margin-left: 15px;"
              />
              <div style="display: flex; margin-top: 10px">
                <img
                    width="34.74px"
                    height="34.74px"
                    :src="require('ASSETS/images/payment/payment_tip2.png')"
                    alt=""
                />
                <div style="margin-left: 3.7px; line-height: 21px">
                  请使用<span style="color: #5cdd61">微信</span
                >扫一扫扫描二维码支付,暂不支持截图识别。
                </div>
              </div>
              <!-- <img
                :src="require('ASSETS/images/payment/payment_tip.png')"
                width="256"
                style="margin-top: 10px"
              /> -->
            </div>
            <img
                :src="require('ASSETS/images/payment/pc_icon_phone-bg.png')"
                width="392px;"
                style="margin-left: 40px"
            />
          </div>
        </div>
        <div
            style="
            margin-top: 20px;
            border: 1px solid rgba(218, 218, 218, 1);
            padding: 10px;
          "
            v-show="choosePayway == 'balance'"
        >
          <div
              style="
              font-size: 14px;
              wdith: 100%;
              align-items: center;
              padding: 0 16px;
              height: 60px;
              border: 2px solid rgba(70, 141, 234, 1);
              display: flex;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <img
                  :src="require('ASSETS/images/payment/pay-icon.png')"
                  width="24px;height:27px;"
                  alt=""
              />
              <div style="margin-left: 10px">
                余额 {{ balance.toFixed(2) }} 元
              </div>
            </div>
            <div>
              支付
              <span style="color: #468dea; font-size: 18px">{{
                  paymentInfo.orderAmount.toFixed(2)
                }}</span>
              元
            </div>
          </div>
          <div
              v-if="paymentInfo.orderAmount > balance"
              style="font-size: 12px; color: red; margin-top: 5px"
          >
            余额不足，无法支付
          </div>
        </div>
        <div
            style="
            margin-top: 20px;
            border: 1px solid rgba(218, 218, 218, 1);
            padding: 10px;
          "
            v-show="choosePayway == 'giro'"
        >
          <div style="display: flex">
            <h3 style="margin-top: 9px" class="ws-title3">目标账户</h3>
            <el-form ref="form" :model="companyBank" label-width="80px">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="户名">
                    <el-input
                        v-model="companyBank.accountName"
                        :disabled="true"
                        style="width: 400px !important;"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="账号">
                    <el-input
                        v-model="companyBank.accountNo"
                        :disabled="true"
                        style="width: 400px !important;"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="开户行">
                    <el-input
                        v-model="companyBank.bankName"
                        :disabled="true"
                        style="width: 400px !important;"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>

          <div style="display: flex">
            <h3 style="margin-top: 9px" class="ws-title3">我的账户</h3>
            <el-form ref="form" :model="myBank" label-width="80px">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="户名">
                    <el-input v-model="myBank.accountName" style="width: 400px !important;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="账号">
                    <el-input v-model="myBank.accountNo" style="width: 400px !important;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="开户行">
                    <el-input v-model="myBank.bankName" style="width: 400px !important;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div>
            <el-alert
                title="凭证需体现付款账户姓名、账号、金额及收款账户"
                type="warning"
            ></el-alert>
            <div style="display: flex; margin-top: 20px">
              <h3 class="ws-title3">上传支付凭证</h3>
              <draggable-upload
                  :width="82"
                  :height="82"
                  :limit="1"
                  :fileSize="2048 * 1024"
                  fileSizeText="2M"
                  :uploadUrl="uploadUrl()"
                  :requestHeaders="requestHeaders"
                  v-model="payOrdersImgs"
                  style="margin-left: 10px"
              ></draggable-upload>
            </div>
          </div>
          <div style="display: flex">
            <h3 style="margin-top: 9px" class="ws-title3">备注</h3>
            <el-form ref="form1" label-width="80px">
              <el-row>
                <el-col :span="24">
                  <el-form-item>
                    <el-input v-model="giroRemark"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <div
              style="
              display: flex;
              justify-content: center;
              width: 100%;
              padding-top: 31px;
            "
          >
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button v-if="paymentInfo.orderAmount > depositAmount && choosePayway == 'deposit'"
                       type="primary"
                       @click="closeAndRechargeDeposit">
              充值并支付
            </el-button>
            <el-button v-else-if="paymentInfo.orderAmount > balance && choosePayway == 'balance'"
                       style="background:gray;borderColor:gray"
                       type="primary"
                       disabled="true">
              确认付款
            </el-button>
            <el-button v-else
                       type="primary"
                       @click="doPayConfirm"
            >{{ commitText }}
            </el-button>

          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="延迟发货提醒" width="560px" :visible.sync="delayDialogVisible" @close="delayDialogVisible=false">
      <el-form>
        <span style="color:red;">提示：当前选中的订单存在延迟发货的情况，可能是由于地区快递原因或预售活动原因，请确认。</span>
        <el-form-item label="延迟发货订单数">
          <span>{{ paymentInfo.delayShipCount }}</span>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="confirmDelay">知道了</el-button>
        <el-button type="primary" @click="gotoOrderListD">查看详情</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import userModule from "@/module/userModule";
import tools from "COMJS/tools";
import Objects from "COMJS/Objects";
import orderPaymentService from "API/som/orderPaymentService";
import draggableUpload from "COMPS/base/draggableUpload";
import IoProgressService from "API/IoProgressService";
import QRcode from "qrcodejs2";

//import sysConstant from "@/constant/SysConstant";

export default {
  components: {
    draggableUpload,
  },
  data() {
    return {
      delayDialogVisible: false,
      dialogVisible: false,
      requestHeaders: {
        Authorization: userModule.token,
      },
      loginUser: JSON.parse(userModule.PPUser),
      outTradeNo: "",
      orderPaymentParam: {},
      paymentInfo: {orderCount: 0, orderAmount: 0, delayShipCount: 0},
      paywayList: [],
      choosePayway: "",
      companyBank: {accountName: "", accountNo: "", bankName: ""},
      myBank: {accountName: "", accountNo: "", bankName: ""},
      payOrdersImgs: [],
      giroRemark: "",
      balance: 0,
      creditLimit: 0,
      depositAmount: 0,
      commitText: "确认",
      //todo 为什么这个文件内无法用SysConstant
      /*agentCreditCode : 'agent_credit',
      agentDepositCode : 'agent_deposit',
      agentCashCode : 'agent_cash',*/
      agentCreditCode : "agent_credit",
      agentDepositCode : "agent_deposit",
      agentCashCode : "agent_cash",
    };
  },
  computed:{
    isAgentCredit(){
      return this.loginUser.role.indexOf(this.agentCreditCode) > -1
    }
  },
  methods: {
    confirmDelay() {
      this.delayDialogVisible = false
      //支付弹窗
      this.dialogVisible = true;
    },
    gotoOrderListD() {
      this.$router.push({
        path: "/som/orderListD",
        query: {orderState: "unShippedDelay"},
      })
      this.delayDialogVisible = false
    },
    async openPayPopup(orderPaymentParam) {
      //console.log(this.loginUser)
      //按导入批次支付，需要参数：batchCodeList
      //按订单支付，需要参数：orderIdList
      this.orderPaymentParam = orderPaymentParam;
      this.paymentInfo = await orderPaymentService.getPrePayData(
          this.orderPaymentParam
      );
      const walletInfo = await orderPaymentService.getWalletInfo();
      this.balance = walletInfo.balance;
      this.creditLimit = walletInfo.creditLimit;
      this.depositAmount = walletInfo.depositAmount;
      if (this.paymentInfo.delayShipCount > 0) {
        //有需要延期发货的订单，弹窗告诉他
        this.delayDialogVisible = true
      } else {
        //正常发货，支付弹窗
        this.dialogVisible = true;
      }

      this.paywayList = [];
      if (this.loginUser.role.indexOf('distributor') > -1) {
        //会员
        this.paywayList.push({value: 'balance', label: '余额抵扣'})
        this.paywayList.push({value: 'wxpay', label: '微信支付'})
        this.paywayList.push({value: 'giro', label: '线下转账'})
      }
      if (this.loginUser.role.indexOf(this.agentCashCode) > -1) {
        //现结经销商，以后少用
        this.paywayList.push({value: 'wxpay', label: '微信支付'})
        this.paywayList.push({value: 'giro', label: '线下转账'})
      }
      if (this.loginUser.role.indexOf(this.agentCreditCode) > -1) {
        //信用经销商，需要给他们现金支付的渠道，当信用不足时
        this.paywayList.push({value: 'credit', label: '信用支付'})
        this.paywayList.push({value: 'deposit', label: '余额支付'})
      }
      if (this.loginUser.role.indexOf(this.agentDepositCode) > -1) {
        //预存经销商，最好是这种类型
        //可以马上存马上用，已经取代了现结经销商
        this.paywayList.push({value: 'deposit', label: '余额支付'})
      }
      if (this.paywayList.length == 1) {
        this.choosePayway = this.paywayList[0].value;
      }

    },
    changePayway(e) {
      //console.log(this.choosePayway);
      //console.log(e);
      if (this.choosePayway == "wxpay") {
        this.commitText = "已完成支付";
        document.getElementById("qrCodeDom").innerHTML = "二维码加载中...";
        var that = this;
        orderPaymentService
            .doPayWithQuery(this.orderPaymentParam)
            .then((data) => {
              //console.log(data);
              if (data && data["needPay"] == "1") {
                //console.log(data);
                let payData = data["payData"];
                that.payData = payData;
                that.setPayData(payData);
              } else {
                //不需要支付，提示
                that.$message.error(data["msg"]);
              }
            })
            .catch((data) => console.log(data));
      } else if (this.choosePayway == "giro") {
        this.commitText = "确认付款";
        this.companyBank = {
          accountName: "慧之乳业（青岛）有限公司",
          accountNo: "38010101040045365",
          bankName: "中国农业银行股份有限公司青岛市分行营业部",
        };
      } else {
        this.commitText = "确认付款";
      }
    },
    setPayData(payData) {
      let codeUrl = payData["code_url"];
      this.outTradeNo = payData["outTradeNo"];
      document.getElementById("qrCodeDom").innerHTML = "";
      let codeImg = new QRcode("qrCodeDom", {
        width: 146,
        height: 146, // 高度
        text: codeUrl, // 二维码内容
        image: "",
        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'
        // foreground: '#ff0'
      });
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("payOrders");
      return url;
    },
    doPayConfirm() {
      let that = this;
      if (!this.choosePayway) {
        that.$message.error("请选择支付方式");
        return;
      }
      if (this.choosePayway == "wxpay") {
        let loading = this.$loading({
          lock: true,
          text: "支付结果确认中...",
        });
        setTimeout(function () {
          orderPaymentService
              .checkPayState(that.outTradeNo)
              .then((data) => {
                //console.log(data);
                if (data) {
                  if (data["result"] == "success") {
                    //console.log(data);
                    that.$message({type: "success", message: "支付成功！"});
                    that.$emit("reloadAfterPay");
                  } else {
                    //不需要支付，提示
                    // that.$message.error(data["msg"]);
                    that.$emit("reloadAfterPay");
                    that.$message.error("支付失败,请稍后再试");
                  }
                }
                loading.close();
              })
              .catch((data) => {
                that.$message.error("支付失败");
                loading.close();
              });
        }, 3000);
      } else if (this.choosePayway == "balance") {
        if (this.paymentInfo.orderAmount > this.balance) {
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "支付结果确认中...",
        });
        orderPaymentService
            .doBalanceDeduct(this.orderPaymentParam)
            .then((data) => {
              if (data && data["code"] == "success") {
                //that.$message.error('余额抵扣成功!');
                that.$message({type: "success", message: "余额抵扣成功！"});
                that.$emit("reloadAfterPay");
              } else {
                //不需要支付，提示
                that.$emit("reloadAfterPay");
                that.$message.error(data["msg"]);
              }
              loading.close();
            })
            .catch((data) => {
              //console.log(data);
              loading.close();
            });
      } else if (this.choosePayway == "giro") {
        const payOrdersImg = this.payOrdersImgs.length
            ? this.payOrdersImgs[0].response
            : "";
        //const imgs = [payOrdersImg];
        if (payOrdersImg == "") {
          this.$message.error("请上传支付凭证");
          return;
        }
        if (
            Objects.isEmpty(this.myBank.accountName) ||
            Objects.isEmpty(this.myBank.accountNo) ||
            Objects.isEmpty(this.myBank.bankName)
        ) {
          this.$message.error("请填写账户信息");
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "审核提交中...",
        });
        this.orderPaymentParam.giroPicUrlList = [payOrdersImg];
        this.orderPaymentParam.giroRemark = this.giroRemark;
        this.orderPaymentParam.giroAccountName = this.myBank.accountName;
        this.orderPaymentParam.giroAccountNo = this.myBank.accountNo;
        this.orderPaymentParam.giroBankName = this.myBank.bankName;
        orderPaymentService
            .doGiro(this.orderPaymentParam)
            .then((data) => {
              if (data && data["code"] == "success") {
                //that.$message.error('提交成功，等待审核!');
                that.$message({
                  type: "success",
                  message: "提交成功，等待审核！",
                });
                that.$emit("reloadAfterPay");
              } else {
                //不需要支付，提示
                that.$emit("reloadAfterPay");
                that.$message.error(data["msg"]);
              }
              loading.close();
            })
            .catch((data) => {
              //console.log(data);
              loading.close();
            });
      } else if (this.choosePayway == "credit") {//信用支付
        if (this.paymentInfo.orderAmount > this.creditLimit) {
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "支付结果确认中...",
        });
        orderPaymentService
            .doCreditPay(this.orderPaymentParam)
            .then((data) => {
              if (data && data["code"] == "success") {
                that.$message({type: "success", message: "支付成功！"});
                that.$emit("reloadAfterPay");
              } else {
                //不需要支付，提示
                that.$emit("reloadAfterPay");
                that.$message.error(data["msg"]);
              }
              loading.close();
            })
            .catch((data) => {
              //console.log(data);
              loading.close();
            });
      } else if (this.choosePayway == "deposit") {
        if (this.paymentInfo.orderAmount > this.depositAmount) {
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "支付结果确认中...",
        });
        orderPaymentService
            .doDepositPay(this.orderPaymentParam)
            .then((data) => {
              if (data && data["code"] == "success") {
                that.$message({type: "success", message: "支付成功！"});
                that.$emit("reloadAfterPay");
              } else {
                //不需要支付，提示
                that.$emit("reloadAfterPay");
                that.$message.error(data["msg"]);
              }
              loading.close();
            })
            .catch((data) => {
              //console.log(data);
              loading.close();
            });
      }

      this.dialogVisible = false;
    },
    closeAndRechargeDeposit(){
      this.dialogVisible = false;
      this.$emit("showRechargeDeposit");
    }
  },
};
</script>

<style lang="scss">
.order-list {
  .order-table {
    .tabs-wrapper {
      padding: 10px 20px;

      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;

        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }

    background-color: #fff;
  }
}

.order-pay .el-dialog__body .el-input {
  width: 100% !important;
}
</style>