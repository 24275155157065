<template>
  <div class="invlist-box">
    <div class="bill-manage-head">
      <!--
      <el-form :inline="true" class="demo-form-inline1">
        <el-form-item label="生成时间：">
          <el-date-picker
              v-model="timeArr"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchData">搜索</el-button>
        </el-form-item>
      </el-form>
      -->
      <el-form :inline="true" class="bill-table">
        <table cellspacing="0" cellpadding="0">
          <tr>
            <!--            <td>
                          <el-form-item label="可用额度:">
                            ¥{{headerInfo.canUseMoney}}
                            <el-tooltip effect="dark" content="可用额度=剩余额度+结余额度" placement="top">
                              <i class="icon-tip"></i>
                            </el-tooltip>
                          </el-form-item>
                        </td>-->
            <td>
              <el-form-item label="剩余额度:">
                ¥{{ headerInfo.alsoLineOfCredit }}（总额度¥{{ headerInfo.lineOfCredit }}）
              </el-form-item>
            </td>
            <td>
              <el-form-item label="结余额度:">
                ¥{{ headerInfo.refundBalance }}
                <el-tooltip effect="dark" content="结余额度为上一期账单结余金额，一般因已结算订单退款而产生，生成下期账单后自动抵扣账单金额" placement="top">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              <el-form-item label="待结算额度:">
                ¥{{ headerInfo.stayClose }}
                <el-tooltip effect="dark" content="待结算额度为已生成账单的账单金额" placement="top">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </el-form-item>
            </td>
            <td>
              <el-form-item label="待入账额度:">
                ¥{{ headerInfo.stayEnter }}
                <el-tooltip effect="dark" content="待入账额度为已下单、未生成账单的账单金额" placement="top">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </el-form-item>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </el-form>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column prop="createTime" label="生成时间" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="orderOnTime" label="订单时间" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="dealerAccount" label="经销商账号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="auditState" label="结算状态" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="billAmount" label="订单金额" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="refundAmount" label="退款金额" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="lastBalance" label="上期结余" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="payAmount" label="账单金额" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="endTime" label="最后支付时间" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                @click="showDialog(scope.row,'pay')"
                class="blue-txt"
                title="支付"
                v-show="scope.row.auditState == '待申请' || scope.row.auditState == '审核失败' || scope.row.auditState == '账单超时'"
            >
              <i class="icon-pay"></i>
            </a>
            <a
                href="javascript: void(0);"
                @click="showDialog(scope.row, 'info')"
                class="blue-txt"
                title="查看"
                v-show="scope.row.auditState == '待审核' || scope.row.auditState == '审核失败' || scope.row.auditState == '审核通过'"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
                href="javascript: void(0);"
                @click="exportList(scope.row)"
                class="blue-txt"
                title="下载账单"
            >
              <i class="icon-download"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <bill-repayment-pop
        ref="billRepaymentPop" @reload="loadTableData()" />

    <el-dialog title="账单审核结果" :visible.sync="creditViewDialog" width="500px">
      <div style="display: flex;">
        <div class="dea-txt mr20">订单金额：¥{{ billtInfo.billAmount }}</div>
        <div class="dea-txt mr20">退款金额：¥{{ billtInfo.refundAmount }}</div>
        <div class="dea-txt mr20">上期结余：¥{{ billtInfo.lastBalance }}</div>
        <div class="dea-txt mr20" v-if="billtInfo.adminModifyAmount!=0">减免金额：¥{{ billtInfo.adminModifyAmount }}</div>
      </div>
      <div class="dea-txt">账单金额：¥{{ billtInfo.payAmount }}</div>
      <div class="draw-line"></div>
      <div class="dea-txt dea-title">支付信息</div>
      <template v-if="billtInfo.paymentInfo=='wxpay'">
        <div class="dea-txt">支付方式：微信支付</div>
        <div class="dea-txt">微信交易单号：{{billtInfo.openBankNumber}}</div>
      </template>
      <template v-if="billtInfo.paymentInfo=='alipay'">
        <div class="dea-txt">支付方式：支付宝转账</div>
        <div class="dea-txt">付款支付宝名称：{{ billtInfo.paymentMan }}</div>
        <div class="dea-txt">付款支付宝账号：{{ billtInfo.openBankNumber }}</div>
      </template>
      <template v-if="!billtInfo.paymentInfo || billtInfo.paymentInfo=='giro'">
        <div class="dea-txt">支付方式：银行转账</div>
        <div class="dea-txt">付款账户名称：{{ billtInfo.paymentMan }}</div>
        <div class="dea-txt">开户银行账号：{{ billtInfo.openBankNumber }}</div>
        <div class="dea-txt">开户银行名称：{{ billtInfo.openBankName }}</div>
      </template>
      <div class="dea-txt" v-if="billtInfo.paymentInfo!='wxpay'">
        <span class="sp1">转账凭证：</span>
        <el-image
            v-for="(item, index) in billtInfo.transferImgs"
            :key="index"
            style="width: 100px; height: 100px;margin-right: 10px;"
            :src="billtInfo.transferImgs[index]"
            :preview-src-list="billtInfo.transferImgs"
            :zIndex="3000"
        >
        </el-image>
      </div>
      <div class="draw-line"></div>
      <div class="dea-txt">
        支付结果：{{
          billtInfo.auditState == '0' ? '待支付' : billtInfo.auditState == '1' ? '已提交待审核' : billtInfo.auditState == '2' ? '支付成功' : billtInfo.auditState == '3' ? '审核失败' : billtInfo.auditState == '4' ? '账单超时' : ''
        }}
      </div>
      <div class="dea-txt" v-if="billtInfo.auditState == '3'">审核原因：{{ billtInfo.remark }}</div>
    </el-dialog>

    <temporary-dialog />
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerService from "API/dea/dealerService";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";
import temporaryDialog from "PAGES/som/order/TemporaryDialog";
import billRepaymentPop from "./billRepaymentPop";

export default {
  name: "dealerList",
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        openTime: "",
        endTime: "",
      },
      items: {
        openTime: {filter: "EQ"},
        endTime: {filter: "EQ"},
      },
      timeArr: [],
      creditViewDialog: false,
      requestHeaders: {
        Authorization: userModule.token,
      },
      headerInfo: {
        lineOfCredit: "",
        alsoLineOfCredit: "",
        refundBalance: "",
        stayClose: "",
        stayEnter: "",
      },
      billtInfo: {
        paymentMan: "",
        openBankName: "",
        openBankNumber: "",
        transferImgs: [],
      },
      curList: {},
    };
  },
  components: {
    draggableUpload,
    temporaryDialog,
    billRepaymentPop
  },
  mixins: [pageTableMixin],
  mounted() {
    //console.log(JSON.parse(userModule.PPUser))
    //this.loadTableData()    
    this.getUserId()
  },
  methods: {
    exportList(row) {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      dealerService
          .queryByBillToOrder(row.billId)
          .then((data) => {
            //console.log(data)
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
            this.loadTableData();
          })
          .catch(() => {
            loading.close();
          });
    },
    showDialog(row, state) {
      this.curList = row
      if (state == 'pay') {
        this.billtInfo = {transferImgs:[]}
        this.$refs.billRepaymentPop.show({billPaymentAmount:row.payAmount,billId:row.billId})
        return;
      }
      //this.auditLogId = row.auditLogId
      dealerService.getFaBillInfo(row.billId).then((data) => {
        data.transferImgs = data.transferImgs ? data.transferImgs.split(",") : [];
        this.billtInfo = data
        this.creditViewDialog = true
      });
    },
    getUserId() {
      dealerService.findDealerLinesInfo(JSON.parse(userModule.PPUser).userId).then((data) => {
        this.headerInfo.canUseMoney = (data.alsoLineOfCredit + data.refundBalance).toFixed(2)
        this.headerInfo.lineOfCredit = (data.lineOfCredit - 0).toFixed(2)
        this.headerInfo.alsoLineOfCredit = (data.alsoLineOfCredit - 0).toFixed(2)
        this.headerInfo.stayClose = (data.stayClose - 0).toFixed(2)
        this.headerInfo.stayEnter = (data.stayEnter - 0).toFixed(2)
        this.headerInfo.refundBalance = (data.refundBalance - 0).toFixed(2)
      });
    },
    loadTableData() {
      this.searchInfo.openTime = this.timeArr && this.timeArr[0] ? tools.formatDate(new Date(this.timeArr[0]), "yyyy-MM-dd") : ""
      this.searchInfo.endTime = this.timeArr && this.timeArr[1] ? tools.formatDate(new Date(this.timeArr[1]), "yyyy-MM-dd") : ""
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.dealerAccount = JSON.parse(userModule.PPUser).userAccount
      dealerService.queryBillBySystem(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            billId: e.billId,
            createTime: e.createTime ? tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss") : "",
            orderOnTime: e.orderOnTime,
            dealerAccount: e.dealerAccount,
            auditState: e.auditState == 0 ? "待申请" : e.auditState == 1 ? "待审核" : e.auditState == 2 ? "审核通过" : e.auditState == 3 ? "审核失败" : e.auditState == 4 ? "账单超时" : "",
            billAmount: e.billAmount,
            payAmount: e.payAmount,
            lastBalance: e.lastBalance,
            thisBalance: e.thisBalance,
            endTime: e.endTime ? tools.formatDate(new Date(e.endTime), "yyyy-MM-dd hh:mm:ss") : "",
            refundAmount: e.refundAmount,
            remark: e.remark,
          };
        });
        this.querySuccess(data);
      });
    },
    toEdit(row) {
      this.$router.push(`/dea/dealerEdit/${row.dealerId}`);
    },
  },
};
</script>
<style>
.icon-tip {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("~ASSETS/images/tip.png");
  background-size: cover;
}

.bill-manage-head {
  margin-bottom: 20px;
  background: #fff;
}

.reason .el-input__inner {
  width: 200px;
}

.invlist-box .demo-form-inline, .invlist-box .demo-form-inline1 {
  padding: 20px 20px 0 20px;
}

.invlist-box .demo-form-inline1 {
  padding-bottom: 0;
}

/* .invlist-box .demo-form-inline .el-form-item{ min-width: 200px; margin-right: 60px;} */
.invlist-box .el-form--inline .el-form-item {
  margin-left: 10px;
  margin-bottom: 0;
}

.invlist-box .demo-form-inline2 {
  padding-bottom: 20px;
}

.invlist-box .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.invlist-box .ws-page-table .icon-operation a {
  margin-right: 20px;
}
.draw-line{
  border-bottom: 1px solid #666;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dea-tit{ margin-top: 15px;}
.mr20{margin-right: 20px;}
.dea-txt {
  padding: 9px 0;
  display: flex;
  align-items: center;
  color: #666;
}
.dea-title{
  font-size: 20px;
  margin-top: 10px;
}
.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-txt .sp2 {
  margin-left: 50px;
}

.dea-btn-wrap {
  text-align: center;
}

.icon-download {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/download.png");
  background-size: cover;
}

.icon-pay {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 16px;
  background-image: url("~ASSETS/images/pay.png");
  background-size: cover;
}

.dea-txt.red-txt {
  color: #FF6060;
  font-size: 12px;
}

.bill-table {
  padding: 20px 0 20px 20px;
}

.bill-table td {
  padding: 10px 100px 10px 0;
}
</style>