<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-button size="mini" type="primary" @click="toAdd">新增聚合</el-button>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">聚合名称：</span>
            <el-input size="mini" v-model="searchInfo.spuName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">展示平台：</span>
            <el-select v-model="searchInfo.showPlatform" placeholder="请选择">
              <el-option
                  v-for="item in platformList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
      <!--
      <div class="filter_right">
        <el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{logType:'goods'}})">操作日志</el-button>
      </div>
      -->
    </div>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="spuName" label="名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="spuSpec" label="规格" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="缩略图">
        <template slot-scope="scope">
          <div class="table-img">
            <img :src="scope.row.spuPicUrl" alt/>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="spuPrice" label="零售价（元）"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
                href="javascript: void(0);"
                title="编辑"
                @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import pimGoodsSpuService from "API/pim/PimGoodsSpuService";

export default {
  name: "spuList",
  data() {
    return {
      searchInfo: {
        spuName: "",
        showPlatform: ""
      },
      platformList: [
        {label: '全部', value: ''},
        {label: '小程序', value: 'wmp'},
        {label: 'APP', value: 'app'},
        {label: '无归属', value: 'none'}]
    };
  },
  mixins: [pageTableMixin],

  methods: {
    loadTableData() {
      pimGoodsSpuService.queryPages(this.queryParams).then(
          data => {
            data.list = data.list.map(e => {
              return {
                spuId: e.spuId,
                spuName: e.spuName,
                spuSpec: e.spuSpec,
                spuPicUrl: e.spuPicUrl,
                spuPrice: e.spuPrice
              };
            });
            this.querySuccess(data);
          }
      );
    },
    toAdd() {
      this.$router.push("/pim/spuEdit");
    },
    toDetail(row) {
      this.$router.push(`/pim/spuDetail/${row.spuId}`)
    },
    toEdit(row) {
      this.$router.push(`/pim/spuEdit/${row.spuId}`)
    },
    deleteGoods(row) {
      this.$confirm("是否下架该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        pimGoodsSpuService.offTheShelf(row.id).then(() => {
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    translateState(e) {
      var tail = '正常';
      if (e.spuState == 'normal') {
        tail = '正常';
      } else if (e.spuState == 'sell_out') {
        tail = '售罄';
      }
      return tail;
    }
  }
};
</script>
