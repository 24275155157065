import axiosHttp from "../axiosHttp";

export const fetchFareList = (pageInfo) => {
    return axiosHttp.GET('/pimTransport/listPimTransport?'+ pageInfo.buildQueryString())
}

export const fareDetail = id => {
    return axiosHttp.GET('pimTransport/queryPimTransportDetails/' + id)
}
/*
    {
        "createTime": "2020-09-07T09:29:16.811Z",
        "shipCompany": "string",
        "shopId": "string",
        "state": "string",
        "transportCode": "string",
        "transportDescribe": "string",
        "transportId": "string",
        "updateTime": "2020-09-07T09:29:16.811Z"
    }
*/
export const saveFare = data => {
    return axiosHttp.POST('pimTransport/savePimTransport', data)
}

export const delFare = id=>{
    return axiosHttp.DELETE('/pimTransport/removePimTransport',{delIds:id})
}