<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">订单编号：</span>
            <el-input size="mini" v-model="searchInfo.freshMilkOrderCode" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">快递单号：</span>
            <el-input size="mini" v-model="searchInfo.transportNo" class="address-dialog-input"></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">收件姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件电话：</span>
            <el-input size="mini" v-model="searchInfo.recipientPhoneNum" class="address-dialog-input"></el-input>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
      <div class="filter_right">
      </div>
    </div>
    <div class="operate-bar">
      <div class="operate-bar-right">
      </div>
    </div>
    <el-tabs
        v-model="searchInfo.afterState"
        type="card"
        @tab-click="searchData"
    >
      <el-tab-pane label="待处理" name="1"></el-tab-pane>
      <el-tab-pane label="已处理" name="2"></el-tab-pane>
      <el-tab-pane label="已驳回" name="3"></el-tab-pane>
    </el-tabs>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="false"
        :selection="'multi'"
    >
      <el-table-column prop="freshMilkOrderCode" label="订单编号" width="180"></el-table-column>
      <el-table-column label="收件信息" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{ scope.row.recipientName }} {{ scope.row.recipientPhoneNum }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientProvince }} {{ scope.row.recipientCity }} {{ scope.row.recipientCounty }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddressDetail }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品内容" minWidth="150">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            鲜奶两瓶装 {{ scope.row.packageMilkCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.packageGiftBoxCount>0">
            礼盒 {{ scope.row.packageGiftBoxCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.packageGiftCardCount>0">
            贺卡 {{ scope.row.packageGiftCardCount }} 份
          </div>
        </template>
      </el-table-column>
      <el-table-column label="补发方式" minWidth="100">
        <template slot-scope="scope">
          <div>{{ scope.row.afterMode == '1' ? '本周补发' : '下周补发' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="售后原因" minWidth="200">
        <template slot-scope="scope">
          <div>{{ scope.row.afterReason }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="showAuditDialog(scope.row)">
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="查看售后" :visible.sync="auditDialogVisible" width="800px">
      <div class="dea-txt dea-tit">商品信息：</div>
      <div style="display: flex;">
        <div class="dea-txt mr20">有机鲜奶两瓶 {{ auditData.packageMilkCount }} 份</div>
        <div class="dea-txt mr20" v-if="auditData.packageGiftBoxCount>0">礼盒 {{ auditData.packageGiftBoxCount }} 份</div>
        <div class="dea-txt mr20" v-if="auditData.packageGiftCardCount>0">贺卡 {{ auditData.packageGiftCardCount }} 份
        </div>
      </div>
      <div class="dea-txt dea-tit">快递信息：</div>
      <div style="display: flex;">
        <div class="dea-txt mr20">快递公司：{{ auditData.transportCompany }}</div>
        <div class="dea-txt mr20">快递单号：{{ auditData.transportNo }}</div>
      </div>
      <div class="draw-line"></div>
      <div class="dea-txt dea-tit">售后信息：</div>
      <div class="dea-txt">
        补发方式：{{ auditData.afterMode == '1' ? '本周补发' : '下周补发' }}
      </div>
      <div class="dea-txt">
        售后原因：{{ auditData.afterReason }}
      </div>
      <div class="dea-txt">
        <span class="sp1">包装照片：</span>
        <el-image
            v-for="(item, index) in auditData.packageImgs"
            :key="index"
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="auditData.packageImgs"
            :zIndex="3000"
        >
        </el-image>
      </div>
      <div class="dea-txt">
        <span class="sp1">产品照片：</span>
        <el-image
            v-for="(item, index) in auditData.productImgs"
            :key="index"
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="auditData.productImgs"
            :zIndex="3000"
        >
        </el-image>
      </div>
      <div class="draw-line"></div>
      <div class="dea-txt" v-show="auditData.afterState == '1'">
        <!--hz-->
        <span class="sp1">备注：</span>
        <el-input v-model="auditData.afterResponse" class="reason"></el-input>
        <span class="ml15">(驳回必填)</span>
      </div>
      <div class="dea-txt" v-if="auditData.afterState == '3'">驳回原因：{{ auditData.afterResponse }}</div>
      <div class="dea-btn-wrap">
        <el-button type="success" @click="auditAfterOperate('accept')" v-show="auditData.afterState == '1'">通过</el-button>
        <el-button type="warning" @click="auditAfterOperate('reject')" v-show="auditData.afterState == '1'">驳回</el-button>
        <el-button type="primary" @click="auditDialogVisible=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//import areaCascader from "COMPS/base/areaCascader";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import milkOrderService from "API/fm/MilkOrderService";
//import IoProgressService from "API/IoProgressService";
import tools from "COMJS/tools";

export default {
  name: "MilkOrderList",
  mixins: [pageTableMixin],
  components: {
    //areaCascader
  },
  data() {
    return {
      auditData: {},
      auditDialogVisible: false,
      searchInfo: {
        afterState: '1'
      },
      pageInfo: {
        pageSize: 10,
      },
    }
  },
  mounted() {
    //
  },
  methods: {
    loadTableData() {
      let param = {
        ...this.searchInfo,
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize
      }
      milkOrderService.pageMilkOrderPackage(param).then(res => {
        //console.log(res)
        this.querySuccess(res);
      })
    },
    showAuditDialog(row) {
      let temp = {
        ...row
      }
      if (temp.packagePicUrl) {
        let imgList_1 = temp.packagePicUrl.split(",").map(e => {
          return e
        })
        temp.packageImgs = imgList_1
      }
      if (temp.productPicUrl) {
        let imgList_1 = temp.productPicUrl.split(",").map(e => {
          return e
        })
        temp.productImgs = imgList_1
      }
      this.auditData = temp;
      this.auditDialogVisible = true;
    },
    auditAfterOperate(mode) {
      if(mode=='reject'){
        if(!this.auditData.afterResponse){
          return this.$message.warning("请填写驳回原因");
        }
      }
      let param = {
        freshMilkOrderPackageId: this.auditData.freshMilkOrderPackageId,
        afterState: mode == 'accept' ? "2" : "3",
        afterResponse: this.auditData.afterResponse
      }
      let loading = this.$loading({
        lock: true,
        text: "审核操作中..."
      });
      milkOrderService.auditMilkOrderAfter(param).then(res => {
        loading.close()
        this.$message.success("操作成功");
        this.auditData = {}
        this.auditDialogVisible = false;
        this.loadTableData()
      })
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>
.address-dialog-frame {
  border: 1px solid #d3d3d3;
  padding: 20px;
  margin-bottom: 20px;
  /*background-color: #d3d6da;*/
}

.mt10 {
  margin-top: 10px;
}

.address-dialog-input {
  width: 200px !important;
  margin-right: 15px;
}

.address-dialog-input-long {
  width: 630px !important;
}

.border-dashed {
  border-bottom: 1px dashed #3d3d3d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
}

.tag-row .input-new-tag {
  width: 100px;
}

.draw-line {
  border-bottom: 1px solid #666;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dea-tit {
  margin-top: 15px;
}

.mr20 {
  margin-right: 20px;
}

.reason,
.reason .el-input__inner {
  width: 600px;
}

.dea-txt {
  padding: 9px 0;
  display: flex;
  align-items: center;
  color: #666;
}

.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-btn-wrap {
  text-align: center;
}
</style>