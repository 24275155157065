<template>
  <div class="invlist-box">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="toEdit">新增入库</el-button>
      </el-form-item>
      <el-form-item label="库品编码:">
        <el-input v-model="searchInfo.skuCode"/>
      </el-form-item>
      <el-form-item label="库品名称:">
        <el-input v-model="searchInfo.skuName"/>
      </el-form-item>
      <el-form-item label="库品分类:">
        <el-cascader v-model="value" :options="options" :props="{ checkStrictly: true }" @change="handleChange"
                     clearable></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" @pageChange="loadTableData">
      <el-table-column prop="skuCode" label="库品编码" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="skuName" label="库品名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="thingTypeName" label="库品分类" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="actualNum" label="总库存" :show-overflow-tooltip="true">
        <template slot-scope="scope" slot="header">
          <el-tooltip effect="dark" content="锁定库存 + 可用库存 + 预留库存" placement="top">
            <span>总库存(?)</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="lockNum" label="锁定库存" :show-overflow-tooltip="true">
        <template slot="header">
          <el-tooltip content="待支付 + 待发货">
            <span>锁定库存(?)</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="availableNum" label="可用库存" :show-overflow-tooltip="true">
        <template slot="header">
          <el-tooltip content="实际可销售库存数量，低于预警值时会发送提醒短信，低于售罄值时会下架对应商品">
            <span>可用库存(?)</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <div v-if="scope.row.reservedNum!=0" :style="'color:rgba(245, 34, 45, 1);cursor: pointer;'">
            {{ scope.row.availableNum }}
            <el-popover placement="top-start" width="200" trigger="hover"
                        :content="'可用库存('+scope.row.availableNum+') = 总库存('+scope.row.actualNum+') - 锁定库存('+scope.row.lockNum+') - 预留库存('+scope.row.reservedNum+')'">
              <i class="el-icon-warning" slot="reference"></i>
            </el-popover>
          </div>
          <div v-else>
            {{ scope.row.availableNum }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="reservedNum" label="预留库存">
        <template slot="header">
          <el-tooltip content="给经销商预留的库存，以及近三个月奶卡发货需要的库存数量">
            <span>预留库存(?)</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="associatedGoods" label="关联商品" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div :style="'color: rgba(24, 144, 255, 1);cursor: pointer;'" @click="openAssociated(scope.row)">
            {{ scope.row.associatedGoods }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="toDetail(scope.row)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" title="明细" @click="routerToList(scope.row)">
              <i class="el-icon-document"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <relevanceGoodsPop :dialogTableVisible.sync="dialogTableVisible" @update:vasible="dialogClose"
                       ref="relevanceGoodsPop"></relevanceGoodsPop>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import invQuery from "API/whm/invQuery";
import relevanceGoodsPop from "./relevanceGoodsPop.vue";

export default {
  name: "invList",
  data() {
    return {
      dialogTableVisible: false,
      value: [],
      options: [],
      searchInfo: {
        skuCode: "",
        skuName: "",
        thingTypeId: "",
      },
      items: {
        skuCode: {filter: "like"},
        skuName: {filter: "like"},
        thingTypeId: {filter: "like"},
      },
      thingTypeName: "", //分类模糊查询的字段
    };
  },
  components: {
    relevanceGoodsPop,
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
    this.getAllCategory();
  },
  methods: {
    dialogClose() {
      this.dialogTableVisible = false;
    },
    changeTree(val) {
      let arr = [];
      if (val) {
        val.forEach((item) => {
          let obj = {};
          obj.value = item.thingTypeId;
          obj.label = item.nodeName;
          if (item.childs) {
            obj.children = this.changeTree(item.childs);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    getAllCategory() {
      const that = this;
      WhmList.queryCategoryWhm({
        thingTypeName: this.thingTypeName,
      }).then((data) => {
        this.options = that.changeTree(data);
      });
    },
    handleChange(value) {
      if (value.length == 0) {
        //console.log("触发了清空")
        this.searchInfo.thingTypeId = "";
        this.loadTableData();
      }
      this.searchInfo.thingTypeId = value[value.length - 1];
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.type = 0;
      if (!this.searchInfo.thingTypeId) {
        this.searchInfo.thingTypeId = "";
      }
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then(
          (data) => {
            console.log(data);
            data.list = data.list.map((e) => {
              //console.log(e)
              return {
                id: e.skuId,
                skuCode: e.skuCode,
                skuName: e.skuName,
                thingTypeName: e.level ? e.level.thingTypeName : "",
                actualNum: e.stock.actualNum.toLocaleString(), //总库存
                lockNum: e.stock.lockNum.toLocaleString(), //锁定库存
                availableNum: e.stock.availableNum.toLocaleString(), //可用库存
                reservedNum: e.stock.reservedNum,
                associatedGoods:
                    "当前关联" + e.joinGoodsNum + "个商品",
              };
            });
            this.querySuccess(data);
          }
      );
    },
    toGroupEdit() {
      //this.$store.commit('CLEAR_PRODUCT')
      this.$router.push("/whm/invGroupEdit");
    },
    toDetail(row) {
      //this.$store.commit('CLEAR_PRODUCT')
      // this.$router.push(`/whm/invBatch/${row.id}`);
      this.$router.push({
        path: `/whm/invBatch/${row.id}`,
        query: {
          associatedGoods: row.associatedGoods,
        },
      });
    },
    toEdit(row) {
      //this.$store.commit('CLEAR_PRODUCT')
      this.$router.push(`/whm/invEdit/${row.id}`);
    },
    openAssociated(row) {
      let skuid = row.id;
      console.log(skuid);
      this.$refs.relevanceGoodsPop.loadTableData(skuid);
      this.dialogTableVisible = true;
    },
    routerToList(row){
      this.$router.push({
        path: "invAmounts?skuId=" + row.id
      })
    }
  },
};
</script>
<style scoped>
.invlist-box .demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.invlist-box .el-form--inline .el-form-item {
  margin-left: 10px;
  margin-bottom: 0;
}

.invlist-box .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.invlist-box .ws-page-table .icon-operation a {
  margin-right: 20px;
}

.operation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 75px;
}
</style>