<template>
  <div>
    <div>
      <div class="filter-form">
        <div class="filter_left">
          <div class="form-row">
            <div class="form-item">
              <span class="form-label">序列号：</span>
              <el-input size="mini" v-model="serialCode"></el-input>
            </div>
            <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="searchData">查询</el-button>
          </div>
        </div>
      </div>
      <div>
        <el-form :model="param" label-width="110px" class="step-form">
          <div class="step-wrapper">
            <el-form-item label="经销商账号：">
              {{param.dealerAccount}}
            </el-form-item>
            <el-form-item label="渠道商名称：">
              {{param.channelName}}
            </el-form-item>
            <el-form-item label="库品编码:">
              {{param.skuCode}}
            </el-form-item>
            <el-form-item label="库品名称:">
              {{param.skuName}}
            </el-form-item>
            <el-form-item label="库品批次:">
              {{param.skuBatch}}
            </el-form-item>
            <el-form-item label="库品生产日期：" prop="dealerName">
              {{param.skuProductionDate}}
            </el-form-item>
            <el-form-item label="库品供应商：" prop="dealerName">
              {{param.skuSupplier}}
            </el-form-item>
            <el-form-item label="库品保质期：" prop="dealerName">
              {{param.skuShelfLife}}
            </el-form-item>
            <el-form-item label="录入时间：" prop="dealerName">
              {{param.createTimeStr}}
            </el-form-item>
            <el-form-item label="同批次录入总数：" prop="dealerName">
              {{param.serialNum}}
            </el-form-item>
            <el-form-item label="出货价：" prop="shipmentPrice">
              {{param.shipmentPrice}}
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              {{param.remark}}
            </el-form-item>
          </div>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
import dealerSerialInfo from "API/whm/dealerSerialInfo.js";
import tools from "COMJS/tools";

export default {
  name: "serialNumOutboundSearch",
  data() {
    return {
      serialCode:'',
      param:{}
    };
  },
  methods: {
    searchData() {
      if(!this.serialCode || this.serialCode.length==0){
        return;
      }
      dealerSerialInfo.findBySerialNumber(this.serialCode).then(
          data => {
            if(data && data.dealerName){
              this.param = data;
              this.param.createTimeStr = tools.formatDate(new Date(this.param.createTime),"yyyy-MM-dd hh:mm:ss")
              console.log(this.param)
            }else{
              //没有
              this.param = {};
              this.$alert('未查询到记录！', '系统提示');
            }
          }
      );
    }
  }
};
</script>
