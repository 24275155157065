import axiosHttp from "../axiosHttp"

export default {
  // 日志列表
  queryLogs(params) {
    params.userType = 'platform'
    return axiosHttp.GET('/bimUserLog/getGroupLogByParam', params)
  },
  // 获取分组日志参数（人员、操作类型）
  getLogsPerson() {
    return axiosHttp.GET('/bimUserLog/getGroupLogParam', {
      userType: 'platform'
    })
  },
  // 发登录日志
  sendLoginLog(params) {
    return axiosHttp.POST('/bimUserLog/createLoginLog', params, false)
  }
}