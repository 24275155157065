<template>
  <div class="logistics-wrap"> 
    <el-form :inline="true" class="rec-inline1">
      <el-form-item label="经销商账号：">
        <el-input v-model="searchInfo.dealerAccount" />
      </el-form-item>
      <el-form-item label="收件人：">
        <el-input v-model="searchInfo.receiverName" />
      </el-form-item>
      <el-form-item label="收件人手机号：">
        <el-input v-model="searchInfo.receivingPhone" type="number" />
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="rec-inline">   
      <el-form-item label="账号类型：">
        <el-select v-model="searchInfo.accountType" clearable placeholder="选择类型">
          <el-option
            v-for="item in accountType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出库单状态：">
        <!-- <el-input v-model="searchInfo.skuName" /> -->
        <el-select v-model="searchInfo.completionState" clearable placeholder="选择类型">
          <el-option
            v-for="item in completionState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>    

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="importTime"
        label="导入时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="dealerAccount"
        label="经销商账号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="receiverName"
        label="收件人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="receivingPhone" :show-overflow-tooltip="true" label="收件人手机"></el-table-column>
      <el-table-column prop="outboundDocumentsCode" :show-overflow-tooltip="true" label="出库单号" width="300"></el-table-column>
      <el-table-column prop="completionState" :show-overflow-tooltip="true" label="出库单状态"></el-table-column>
      <el-table-column prop="carrierAppCode" :show-overflow-tooltip="true" label="运单号"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看发货单" @click="toDetail(scope.row)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
              v-if="scope.row.completionState == '在库'"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>           
            <a href="javascript: void(0);" title="下载发货单" @click="downloadPDF(scope.row)">
              <i class="icon-download"></i>
            </a>
            <a href="javascript: void(0);" title="物流发货" @click="openDialog(scope.row)" v-if="scope.row.completionState == '在库'">
              <i class="icon-delivery"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="物流发货" :visible.sync="deliveryDialog" width="600px">
      <div class="delivery-cont">确认发货？</div>
      <div class="btn-blue-wrap">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import tools from "COMJS/tools";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        outboundTransportId: "",
        dealerAccount: "",
        recipientName: "",
        recipientPhoneNum: "",     
        accountType: "",        
        completionState: "",
      },
      items: {
        dealerAccount: { filter: "like" },
        recipientName: { filter: "like" },
        recipientPhoneNum: { filter: "like" },
        accountType: { filter: "EQ" },
        completionState: { filter: "EQ" },
      },
      accountType:[
        {
          value: "0",
          label: "现结",
        }, 
        {
          value: "1",
          label: "信用额度",
        },
        {
          value: "2",
          label: "余额",
        },               
      ],
      completionState: [
        {
          value: "0",
          label: "在库",
        },
        {
          value: "1",
          label: " 锁定库存",
        },
        {
          value: "2",
          label: "已出库未签收",
        },
        {
          value: "3",
          label: "取消",
        },
        {
          value: "4",
          label: "已出库已签收",
        },
      ],
      deliveryDialog: false,
      curId: "",   
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
  },
  methods: {
    toEdit(row){this.$router.push(`/som/logisticsEdit/${row.outboundDocumentsId}`);},
    toDetail(row){this.$router.push(`/som/logisticsInfo/${row.outboundDocumentsId}`);},
    downloadPDF(row){
      this.$router.push(`/som/logisticsA4Pdf2/${row.outboundDocumentsId}`);
    },
    openDialog(row){      
      this.curId = row.outboundDocumentsId
      this.deliveryDialog = true
    },
    confirm(){
      this.loading = true;
      let params = {
        outboundId: this.curId
      }
      WhmList.outDeliverGoods(params).then((res) => {
        this.loading = false;
        this.curId = ""
        this.deliveryDialog = false
        this.loadTableData()
        this.$message({
          type: "success",
          message: "发货成功!",
        });        
      });
    },
    close(){this.deliveryDialog = false},
    loadTableData() {
      WhmList.queryLogisticsPages(this.pageInfo, this.searchConfig).then((data) => {
        data.list = data.list.map((e) => {
          return {
            outboundDocumentsId: e.outboundDocumentsId,
            importTime: tools.formatDate(new Date(e.importTime), "yyyy-MM-dd hh:mm:ss"),
            dealerAccount: e.dealerAccount,
            receiverName: e.receiverName,
            receivingPhone: e.receivingPhone,
            outboundDocumentsCode: e.outboundDocumentsCode,
            //completionState: e.completionState == "0" ? "在库" : e.completionState == "1" ? "锁定库存" : e.completionState == "2" ? "已出库未签收" : e.completionState == "3" ? "取消" : e.completionState == "4" ? "已出库已签收" : "",      
            completionState: e.completionState,
            carrierAppCode: e.carrierAppCode,      
          };
        });
        this.querySuccess(data);
      });      
    },   
  },
};
</script>
<style>
.logistics-wrap .rec-inline { margin-bottom: 20px;padding: 20px;background: #fff;}
.logistics-wrap .rec-inline1{ padding: 20px 0 0 20px; background: #fff;}
.logistics-wrap .el-form--inline .el-form-item { margin-right: 20px;margin-bottom: 0;}
.logistics-wrap .el-input--suffix .el-input__inner{ width: 200px;}
.icon-download,.icon-delivery {display: inline-block;vertical-align: middle;width: 16px;height: 16px;  background-size: cover;}
.icon-download{background-image: url("~ASSETS/images/download.png");}
.icon-delivery{background-image: url("~ASSETS/images/delivery.png");}
.logistics-wrap .icon-operation a{ margin-right: 15px;}
.delivery-cont{ padding:20px 0 60px 0; font-size: 16px;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>