import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import PimRoute from "./pim-route";
import BimRoute from "./bim-route";
import SomRoute from "./som-route";
import DimRoute from "./dim-route";
import WhmRoute from "./whm-route";
import DeaRoute from "./dea-route";
import reportRoute from "./report-route";
import BaRoute from "./ba-route";
import usrRoute from "./usr-route";
import livestreamRoute from './livestream-route'
import userModule from "../module/userModule";
import pmRoute from "./pm-route";
import purRoute from "./pur-route";
import fmRoute from "./fm-route"

//import ElementUI from 'element-ui'
//import 'element-ui/lib/theme-chalk/index.css'
//Vue.use(ElementUI)
//import {MessageBox} from 'element-ui';

import myUtils from "COMJS/myUtils"

/**
 * bim基础模块
 * pim企业信息模块
 * fim财务模块
 * som订单模块
 * whm仓库模块
 * dim分销模块
 * pm供应链模块
 */

const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("../pages/login.vue"),
    },
    {
        path: "/",
        name: "Index",
        component: () => import("../pages/index/index.vue"),
        meta: {requireAuth: true},
        children: [
            {
                path: "/empty",
                name: "空白",
                component: () => import("../pages/empty.vue"),
            },
            {
                path: "/",
                name: "欢迎",
                component: () => import("../pages/home/Welcome.vue"),
            },
            {
                path: "/dashboard",
                name: "概况",
                component: () => import("../pages/home/home.vue"),
            },
            BimRoute,
            PimRoute,
            SomRoute,
            DimRoute,
            WhmRoute,
            DeaRoute,
            reportRoute,
            BaRoute,
            usrRoute,
            pmRoute,
            livestreamRoute,
            purRoute,
            fmRoute
        ],
    },
];

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

const originalPush = VueRouter.prototype.push;
//console.log(originalPush)
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
    //匹配失败之后重定向
    if (to.matched.length == 0) {
        console.log('to.matched.length==0', to)
        next({path: "/empty"});
        return;
    }
    //todo 把业务去掉
    let productStepsData = router.app.$options.store.state.ProductStepsData
    if (productStepsData && productStepsData.goodsId) {
        if (to.fullPath.indexOf('/pim/AddGoods') > -1) {
            //去商品编辑页面
            if (Object.keys(to.query).length === 0) {
                //没有条件
                //let StepsData = router.app.$options.store.state.ProductStepsData
                next({
                    path: "/pim/AddGoods",
                    query: {
                        goodsId: productStepsData.goodsId,
                        pageNo: productStepsData.Step
                    },
                });
            }
        }
    }
    if (to.meta.requireAuth) {
        if (!userModule.checkToken()) {
            next({path: "/login"});
            return;
        }
    }
    /*if (myUtils._isMobile() && to.path.indexOf("/wap/") < 0) {
        let newRouter = {
            fullPath: '/wap' + to.fullPath,
            //hash:to.hash,
            //matched:to.matched,
            //meta:to.meta,
            //name: to.name,//next里面根据name的缓存，所以name一定不能带
            params: to.params,
            path: '/wap' + to.path,
            query: to.query,
        }
        //console.log('newRouter', newRouter)
        next(newRouter);
        return;
    }*/
    next();
});

if (sessionStorage.getItem("homeNoticeState") != null) {
    router.beforeEach((to, from, next) => {
        if (sessionStorage.getItem("homeNoticeState") && to.path != "/") {
            return next("/");
        }
        next();
    });
}

export default router;
