<template>
  <div>
    <steps :active="2" :steps="steps"></steps>
    <div class="step-wrapper base-from box-shadow-unify">
      <div class="step-title">图片信息</div>
      <el-form label-width="110px">
        <el-form-item required label="轮播图：">
          <div>
            <draggable-upload
              :width="82"
              :height="82"
              :uploadUrl="uploadUrl()"
              :requestHeaders="requestHeaders"
              v-model="rotationImgs"
            ></draggable-upload>
          </div>
          <div class="tips">你可以拖拽图片调整顺序，最多上传10张</div>
        </el-form-item>
        <el-form-item label="商品视频：">
          <div>
            <video-upload
              :url="videoUrl"
              :uploadUrl="uploadUrl()"
              :requestHeaders="requestHeaders"
              @deleteSuccess="deleteVideo"
              @uploadSuccess="uploadVideoSuccess"
            ></video-upload>
          </div>
          <div class="tips">上传后展示在轮播首位，最多1个</div>
        </el-form-item>
        <el-form-item required label="详情图：">
          <div>
            <!-- :limitRatio="true"
              :fileWidth="750"
            :fileHeight="900"-->
            <draggable-upload
              :width="82"
              :height="82"
              :uploadUrl="uploadUrl()"
              :requestHeaders="requestHeaders"
              v-model="detailImgs"
            ></draggable-upload>
          </div>
          <div class="tips">你可以拖拽图片调整顺序</div>
        </el-form-item>
        <el-form-item label="分享图：">
          <div>
            <!-- :limitRatio="true"
              :fileWidth="297"
            :fileHeight="230"-->
            <draggable-upload
              :width="82"
              :height="82"
              :limit="1"
              :fileSize="128 * 1024"
              fileSizeText="128k"
              :uploadUrl="uploadUrl()"
              :requestHeaders="requestHeaders"
              v-model="shareImgs"
            ></draggable-upload>
          </div>
          <div class="tips">你可以拖拽图片调整顺序</div>
        </el-form-item>
      </el-form>
    </div>
    <div class="steps-btn-wrapper">
      <el-button @click="backPreStep()">返回上一步</el-button>
      <el-button type="primary" @click="createGoods"
        >&nbsp;完&nbsp;成&nbsp;</el-button
      >
    </div>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import PimGoodsService from "API/pim/PimGoodsService";
import tools from "COMJS/tools";
import videoUpload from "COMPS/base/videoUpload";
import draggableUpload from "COMPS/base/draggableUpload";
import steps from "COMPS/widgets/steps";
import eventBus from "../../../../js/eventBus";

export default {
  data() {
    return {
      steps: [
        {
          name: "添加信息",
        },
        {
          name: "添加属性",
        },
        {
          name: "添加图片",
        },
      ],
      requestHeaders: {
        Authorization: userModule.token,
      },
      rotationImgs: [], // 轮播
      videos: [], // 视频
      detailImgs: [], // 详情
      videoUrl: "",
      shareImgs: [],
    };
  },
  components: {
    videoUpload,
    draggableUpload,
    steps,
  },
  mounted() {
    let imgs = this.$store.state.productImgs;
    this.rotationImgs = imgs.rotationImgs || [];
    this.videos = imgs.videos || [];
    this.detailImgs = imgs.detailImgs || [];
    this.shareImgs = imgs.shareImgs || [];
    if (this.videos.length) {
      this.videoUrl = this.videos[0].url;
    }
  },
  beforeDestroy() {
    let imgs = {
      rotationImgs: this.rotationImgs,
      videos: this.videos,
      detailImgs: this.detailImgs,
    };
    this.$store.commit("saveProductImgs", imgs);
  },
  methods: {
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    // 视频
    uploadVideoSuccess(fileList) {
      this.videos = fileList;
    },
    deleteVideo() {
      this.videos = [];
    },
    createGoods() {
      if (!this.rotationImgs.length) {
        return this.$message({
          message: "请添加轮播图",
          type: "warning",
        });
      }
      if (!this.detailImgs.length) {
        return this.$message({
          message: "请添加详情图",
          type: "warning",
        });
      }
      let baseInfo = this.$store.state.productAddInfo;
      let attrList = this.$store.state.productAttrList;
      let _photoAlbums = [];
      let _photoAlbums_1 = this.rotationImgs.map((e, i) => {
        return {
          photoUrl: e.response,
          name: e.name,
          state: 1,
          photoType: "sow",
          position: i + 1,
        };
      });
      let _photoAlbums_2 = this.detailImgs.map((e, i) => {
        return {
          photoUrl: e.response,
          name: e.name,
          state: 1,
          photoType: "detail",
          position: i + 1,
        };
      });
      _photoAlbums = _photoAlbums_1.concat(_photoAlbums_2);

      let PimAttr = attrList.map((e, index) => {
        return {
          cnName: e.name,
          attrValue: e.content,
          isUsable: e.isEnable ? 1 : 0,
          dispNo: index,
        };
      });
      let _effectiveTime;
      let _isEffective;
      if (baseInfo.upperShelfType == "1") {
        _isEffective = 1;
        _effectiveTime = new Date();
      } else if (baseInfo.upperShelfType == "2") {
        _isEffective = 1;
        _effectiveTime = baseInfo.upperShelfTime;
      } else if (baseInfo.upperShelfType == "3") {
        _isEffective = 2;
        _effectiveTime = "";
      }
      let PimGoods = {
        shopId: baseInfo.shopId,
        goodsType: baseInfo.goodsType,
        categoryId: baseInfo.categoryId,
        cnName: baseInfo.cnName, // 商品名
        goodsCode: baseInfo.goodsCode, // 商品编码
        goodsInfo: {
          inventoryName: baseInfo.inventoryName, // 库品名
          inventoryCode: baseInfo.inventoryCode, // 库品编码
          goodsBarCode: baseInfo.goodsBarCode, // 商品条形码
          inventoryBarCode: baseInfo.inventoryBarCode, // 库品条形码
          goodsDescribe: baseInfo.goodsDescribe, // 商品描述
          goodsShareDescribe: baseInfo.goodsShareDescribe, // 商品分享描述
          transportId: baseInfo.transportId,
          sharePictureUrl: this.shareImgs.length
            ? this.shareImgs[0].response
            : "",
        },
        brandId: baseInfo.brandId, // 品牌id
        initPrice: baseInfo.retailPrice, // 零售价
        markingPrice: baseInfo.oldPrice, // 划线价
        basePrice: baseInfo.floorPrice, // 底价
        pictureUrl: this.rotationImgs[0].response, // 图片地址
        state: 1, // 状态	1:生效，0：失效，必填
        isEffective: _isEffective, // 是否上架	1：上架，2：下架，必填
        effectiveTime: _effectiveTime, // 生效时间
        attributeList: PimAttr, // 属性列表	List<PimAttribute>
        videos: this.videos.map((e) => {
          return {
            videoUrl: e.response,
            name: e.name,
            state: 1,
          };
        }), // 视频信息	List<PimGoodsVidea>
        photoAlbums: _photoAlbums, // 照片列表	List<PimPhotoAlbum>
        //pimCategory: baseInfo.pimCategory, // 商品类型	填选择的分类对象
        goodsSku: {
          skuNum: baseInfo.stockNum, // 商品库存
          showSku: baseInfo.isShowStockNum ? 1 : 0, // 商品是否显示库存 0：不显示 1：显示
          warningInventory: baseInfo.warningValue, // 预警库存
          inboundStandards: baseInfo.enterSpec, // 入库规格
          outboundStandards: baseInfo.outSpec, // 出库规格
          warningInventoryPer: baseInfo.warningPercentage, // 预警库存百分比
          warningWay: baseInfo.warningType, // 预警方式	1：数字，2百分比
          salePerMonth: baseInfo.monthlySalesVolume, // 月销量
        },
        transportWay: 1, // 配送方式	1:快递配货，2：其他
        transportPrice: baseInfo.freight, // 配送价格
        changeLog: "",
      };
      if (this.$route.params.id) {
        PimGoods.goodsId = this.$route.params.id;
        if (baseInfo.retailPrice != baseInfo.old_retailPrice) {
          PimGoods.changeLog += "changePrice,";
        }
        if (
          baseInfo.old_upperShelfType != baseInfo.upperShelfType &&
          baseInfo.upperShelfType == 3
        ) {
          PimGoods.changeLog += "withdraw,";
        }
        if (
          baseInfo.old_upperShelfType != baseInfo.upperShelfType &&
          baseInfo.upperShelfType != 3
        ) {
          PimGoods.changeLog += "draw,";
        }
      } else {
        PimGoods.changeLog = "draw";
      }
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      PimGoodsService.saveGoods(PimGoods)
        .then((res) => {
          loading.close();
          this.$store.commit("saveProductAttrList", []);
          this.$store.commit("saveProductAddInfo", {});
          this.$store.commit("saveProductImgs", []);
          this.$router.push("/pim/productList");
          eventBus.$emit("refresh");
        })
        .catch(() => {
          loading.close();
        });
    },
    backPreStep() {
      if (this.$route.params.id) {
        this.$router.push(`/pim/productAdd_step_2/${this.$route.params.id}`);
      } else {
        this.$router.push("/pim/productAdd_step_2");
      }
    },
    beforeImgUpload(file) {
      let isLtM = file.size / 1024 / 1024 < 2;
      if (!isLtM) {
        this.$message.error("品牌图标大小不能超过2M");
        return;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~ASSETS/css/components/productAdd.scss";
</style>
