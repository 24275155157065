<template>
    <div id="relatedProjectsPop">
        <el-dialog title="" :showClose="false" :visible.sync="dialogVisible" width="30%">
            <div class="content">
                <div class="header">
                    <div class="header-left">
                        <p>关联项目</p>
                        <el-input style="width:70%;" placeholder="请输入项目名称" v-model="title" prefix-icon="el-icon-search"></el-input>
                        <el-button style="margin:0px 30px" @click="search" type="primary">搜索</el-button>
                    </div>
                    <div class="header-right">
                        <i @click="dialogVisible = false" class="el-icon-close" style="font-size:20px;cursor: pointer;"></i>
                    </div>
                </div>
                <div class="list">
                    <div class="list-project">
                        <ws-page-table :selection="'multi'" :show-header="false" :stripe='true' style="width:100%" height="300" :data="tableData" :pageInfo="pageInfo" ref="pageTable"
                            @pageChange="loadTableData">
                            <el-table-column prop="title" :show-overflow-tooltip="true">
                                <template slot-scope="scope">
                                    <span style="font-size:12px;color:rgba(102, 102, 102, 1)">{{scope.row.title}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :show-overflow-tooltip="true">
                                <template slot-scope="scope">
                                    <span style="font-size:12px;color:rgba(102, 102, 102, 1)">{{scope.row.beginTime}} - {{scope.row.endTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :show-overflow-tooltip="true"></el-table-column>
                        </ws-page-table>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="selsect">确认添加</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const moment = require("moment");
import PmBrandService from "API/pm/PmBrandService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
export default {
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            title: "",
        };
    },
    mounted() {
        this.openDialog();
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {
            let paeams = {
                projectState: "all",
            };
            PmBrandService.queryPmProjectAll(paeams).then((data) => {
                this.tableData = data.filter((e) => {
                    return {
                        beginTime: (e.beginTime = moment(e.beginTime).format(
                            "YYYY-MM-DD"
                        )),
                        endTime: (e.endTime = moment(e.endTime).format(
                            "YYYY-MM-DD"
                        )),
                    };
                });
            });
        },
        selsect() {
            let selected = this.$refs.pageTable.getSelectedData();
            this.$emit("selected", selected);
            this.dialogVisible = false;
        },
        openDialog() {
            this.$on("openDialog", (dialogVisible) => {
                this.dialogVisible = dialogVisible;
                this.loadTableData();
            });
        },
        search() {
            let params={
                title:this.title
            }
            PmBrandService.queryByInfo(params).then((data) => {
                this.tableData = data.filter((e) => {
                    return {
                        beginTime: (e.beginTime = moment(e.beginTime).format(
                            "YYYY-MM-DD"
                        )),
                        endTime: (e.endTime = moment(e.endTime).format(
                            "YYYY-MM-DD"
                        )),
                    };
                });
            });
        },
    },
};
</script>

<style scoped>
#relatedProjectsPop >>> .el-dialog__header {
    border: none;
    display: none;
}
#relatedProjectsPop >>> .el-dialog__body {
    padding: 0;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    height: 100%;
    border-bottom: 1px solid rgba(166, 166, 166, 1);
    padding: 17px 11px 14px 10px;
}
.header-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.header-left > img {
    width: 14px;
    height: 14px;
}
.header-left > p {
    width: 80px;
    margin: 0px 10px;
}
.list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 18px 26px 24px 16px;
    width: 94%;
    height: 100%;
}

.list-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.list-header > img {
    width: 12px;
    height: 10.4px;
}
.list-header > p {
    margin: 0px 10px;
}
.list-project {
    width: 100%;
    height: 100%;
    padding-top: 20px;
}
</style>