import axiosHttp from "../axiosHttp";
import tools from "COMJS/tools"

export default {
    queryAtPage(pageInfo,searchConfig){
        let url = "/dealerSerialBatch/queryAtPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    createBatch(param){
        return axiosHttp.POST('/dealerSerialBatch/createBatch',param,false)
    },
    getSerialBatchConfig(){
        return axiosHttp.GET('/dealerSerialBatch/getSerialBatchConfig')
    }
}