// 字段翻译

export default {
    // 翻译订单状态
    orderState(order) {
        if(!order){
            return "";
        }
        const payState = order.payState;
        const transportState = order.transportState;
        const orderState = order.orderState;
        const afterState = order.afterState;
        /*if (afterState == "1") {
            return "售后申请中";
        }*/
        if (orderState == "2" || orderState == "3") {
            return "已关闭";
        }
        if (orderState == "1") {
            return "已完成";
        }
        if (transportState == "2") {
            return "已收货";
        }
        if (payState == "1" && (transportState == "0"||transportState == "5")) {
            return "待发货";
        }
        if (payState == "1" && transportState == "1") {
            return "待收货";
        }
        if (payState == "0") {
            return "待支付";
        }
        if (payState == "5") {
            return "转账审核中";
        }
        if (payState == "6") {
            return "审核拒绝";
        }
    },
    orderPayChannel(payChannel) {
        if (payChannel == "wxpay") {
            return "微信支付";
        }
        if (payChannel == "wxpay_plural") {
            return "微信多笔支付";
        }
        if (payChannel == "balance") {
            return "余额支付";
        }
        if (payChannel == "giro") {
            return "转账支付";
        }
        if (payChannel == "adm_no_pay") {
            return "客服免支付";
        }
        if (payChannel == "subscribe_no_pay") {
            return "订阅免支付";
        }
        if (payChannel == "credit") {
            return "信用支付";
        }
        if (payChannel == "deposit") {
            return "余额支付";
        }
    },
    // 订单来来源 orderType
    // source(source) {
    //   switch (source) {
    //     case "xcx":
    //       return "小程序";
    //     case "import":
    //       return "批量导入";
    //     case "adm":
    //       return "客服下单";
    //     case "wmp":
    //       return "小程序零售单"
    //   }
    // },
    // // 支付方式
    // payWay(payway) {
    //   switch (payway) {
    //     case "adm_no_pay":
    //       return "管理员下单免支付";
    //     case "alipay":
    //       return "支付宝";
    //     case "wxpay":
    //       return "微信";
    //     case "balance":
    //       return "余额付款";
    //     case "giro":
    //       return "线下转账";
    //     default: "未支付"
    //   }
    // }

    // 翻译通知状态和通知类型
    getNoticeState(notice) {
        var noticeState = notice.noticeState;
        if (noticeState == "0") {
            return "未发送";
        }
        if (noticeState == "1") {
            return "已发送";
        }
        if (noticeState == "2") {
            return "已撤销"
        }
    },

    getNoticeType(notice) {
        var noticeType = notice.noticeType;
        if (noticeType == "audit_notice") {
            return "审核通知";
        }
        if (noticeType == "order_notice") {
            return "订单通知";
        }
        if (noticeType == "activity_notice") {
            return "活动通知"
        }
        if (noticeType == "system_notice") {
            return "系统通知"
        }
    },
    getRevertNoticeType(notice) {
        var noticeType = notice.noticeType;
        if (noticeType == "审核通知") {
            return "audit_notice";
        }
        if (noticeType == "订单通知") {
            return "order_notice";
        }
        if (noticeType == "活动通知") {
            return "activity_notice"
        }
        if (noticeType == "系统通知") {
            return "system_notice"
        }
    },
    getRecipient(notice) {
        var recipient = notice.recipient;
        if (recipient.indexOf("1") > -1) {
            return recipient
        }
        if (recipient == "all") {
            return "全部会员";
        }
        if (recipient == "distributor_first") {
            return "全部普标";
        }
        if (recipient == "distributor_second") {
            return "全部银星"
        }
        if (recipient == "distributor_third") {
            return "全部金星"
        }
    },
    getRevertRecipient(notice) {
        var recipient = notice.recipient;
        if (recipient.indexOf("1") > -1) {
            return recipient
        }
        if (recipient == "全部会员") {
            return "all";
        }
        if (recipient == "全部普标") {
            return "distributor_first";
        }
        if (recipient == "全部银星") {
            return "distributor_second"
        }
        if (recipient == "全部金星") {
            return "distributor_third"
        }
    }
}