<template>
    <div>
        <el-dialog title="选择规格值" :visible.sync="dialogVisible" width="500px">
            <div class="select-brand-content">
                <div class="input-search">
                    <el-input v-model="searchKey"></el-input>
                    <el-button type="primary" @click="querySkuspecList">搜索</el-button>
                    <el-button type="primary" @click="showUnit">新增规格</el-button>
                </div>
                <div class="search-result">
                    <div class="search-result-item" @click="selectSkuspec(item, index)" v-for="(item, index) in skuspecSearchResult" :key="index" :class="{ active : index == current}">
                        {{item.skuspecName}}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">下一步</el-button>
            </span>
        </el-dialog>
        <!--新增规格-->
        <el-dialog title="新增规格" :visible.sync="unitDialog" @close="closeAddDialog" width="606px">
            <el-form label-width="140px">
                <el-form-item required label="规格名称：">
                    <el-input v-model="currentInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="是否启用：">
                    <el-switch :value="currentInfo.enabled" @click.native="editEnable()"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="unitDialog = false">取消</el-button>
                <el-button type="primary" @click="addUnit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import WhmList from "API/whm/WhmList";
import SearchConfig from "COMJS/query/SearchConfig";

export default {
    data() {
        return {
            unitDialog: false,
            currentInfo: {
                name: "",
                enabled: true,
            },
            searchKey: "",
            dialogVisible: false,
            skuspecSearchResult: [],
            current: null,
            selectedSkuspec: {
                skuspecId: "",
                skuspecName: "",
            },
        };
    },
    computed: {
        SkuspecSearchConfig() {
            return SearchConfig.createNew({
                searchForm: {
                    skuspecName: this.selectedSkuspec.skuspecName,
                },
            });
        },
    },
    mounted() {
        this.querySkuspecList();
    },
    methods: {
        submit() {
            this.$emit("submit", this.selectedSkuspec);
            this.dialogVisible = false;
        },
        selectSkuspec(item, index) {
            this.selectedSkuspec = item;
            this.current = index;
        },
        // 查询品牌
        querySkuspecList() {
            let params = this.searchKey;
            if (!this.searchKey) {
                params = "all";
            }
            WhmList.querySkuspecPages(params).then((data) => {
                //console.log(data)
                this.skuspecSearchResult = data.map((e) => {
                    return {
                        skuspecId: e.specUnitId,
                        skuspecName: e.name,
                    };
                });
            });
        },
        showUnit() {
            this.unitDialog = true;
        },
        closeAddDialog() {
            this.currentInfo.id = "";
            this.currentInfo.name = "";
        },
        // 切换 编辑 的状态
        editEnable() {
            this.currentInfo.enabled = !this.currentInfo.enabled;
        },
        addUnit() {
            let params = {
                specUnitId: this.currentInfo.id,
                name: this.currentInfo.name,
                enabled: this.currentInfo.enabled ? 1 : 0,
                specUnitType: 1,
            };
            this.loading = true;
            WhmList.saveUnitSpec(params).then((res) => {
                this.loading = false;
                this.$message({
                    type: "success",
                    message: "添加规格成功!",
                });
                this.currentInfo.name = "";
                this.querySkuspecList();
                this.unitDialog = false;
                if (this.currentInfo.id) {
                    this.currentInfo.id = "";
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.select-brand-content {
    .input-search {
        padding: 20px;
        border-bottom: 1px solid $borderColor;
        .el-input {
            width: 200px;
            margin-right: 20px;
        }
    }
    .search-result {
        .search-result-item {
            cursor: pointer;
            line-height: 50px;
            padding: 0 20px;
            color: #666;
            border-bottom: 1px solid $borderColor;
            &:hover {
                background-color: rgba(51, 51, 51, 0.1);
            }
        }
    }
    .search-result-item.active,
    .search-result-item.active:hover {
        background-color: #e8f1fd;
    }
}
</style>