<template>
  <div>
    <div v-show="lodding" style="padding: 20px; background-color: white;">
      <div class="title-info">
        <span>下单时间：{{ formatDate(new Date(detail.createTime), "yyyy-MM-dd hh:mm") }}</span>
        <span>奶卡类型：{{ detail.mainFlag === "1" ? "礼包奶卡" : "商品奶卡" }}</span>
        <span>所属会员：{{ detail.intermediatorName }}</span>
        <span>最近一次修改：{{ formatDate(new Date(detail.updateTime), "yyyy-MM-dd hh:mm") }}</span>
        <span>奶卡状态：{{
            detail.packageState == 'ongoing' ? '持续中'
                : detail.packageState == 'finish' ? '已完成'
                : detail.packageState == 'stop' ? '已暂停'
                    : detail.packageState == 'cancel' ? '已取消'
                        : ''
          }}</span>
      </div>
      <div style="font-weight: bold;font-size: 17px;margin-top: 15px;">当前奶卡信息</div>
      <div class="title-info title-mtp">
        <span>收件人：{{ detail.recipientName }}</span>
        <span>收件人手机号：{{ detail.recipientPhoneNum }}</span>
        <span>收件地址：{{ detail.recipientProvince }}/{{ detail.recipientCity }}/{{
            detail.recipientCounty
          }}/{{ detail.recipientAddressDetail }}</span>
      </div>
      <div class="title-info title-mtp">
        <span>订阅商品：{{ detail.appointGoodsName }}</span>
        <span>每期商品数量：{{ detail.appointGoodsNum }}</span>
        <span>发货时间：{{ formatDate(new Date(detail.transportDate), "yyyy-MM-dd") }}</span>
        <span>剩余修改次数：{{ detail.updateMax - detail.updateCount }}</span>
        <el-button type="text" v-if="detail.packageState!='cancel'"
                   @click="modifyDialogVisible=true">调整
        </el-button>
      </div>
    </div>
    <div style="display: flex;" v-if="detail.packageState!='cancel'">
      <el-button size="mini" type="warning"
                 :disabled="detail.packageState == 'finish'" @click="handleCreateOrderPop = true">手动创建订单
      </el-button>
      <el-button size="mini" type="warning"
                 :disabled="detail.abnormalState != '1'" @click="handleAbnormalPop = true">异常转正常
      </el-button>

      <el-button size="mini" type="warning"
                 :disabled="detail.packageState == 'finish'" @click="handleChangePackageStatePop = true">
        {{ detail.packageState == 'ongoing' ? '暂停发货' : (detail.packageState == 'finish' ? '已完成' : '继续发货') }}
      </el-button>

      <el-dropdown @command="ShowHandleChangeAppointGoodsPop">
        <el-button size="mini" type="warning" style="margin-left: 10px;">
          修改订阅商品<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">修改商品</el-dropdown-item>
          <el-dropdown-item command="b">同步到待发货</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div style="width: 100%;"></div>
      <el-button size="mini" type="danger" @click="showCancelCard">取消奶卡并退款</el-button>
    </div>


    <el-table style="margin-top: 10px;" :data="tableData" ref="pageTable" :pageable="false" :pageInfo="pageInfo"
              :showIndex="false" :isShadow="false">
      <el-table-column label="发货期数" width="100">
        <template slot-scope="scope">
          <!--div>{{ detail.usedCount - scope.$index }}</div-->
          {{ scope.row.idx }}
        </template>
      </el-table-column>
      <el-table-column prop="recipientName" label="收件人" width="100" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="recipientPhoneNum" label="收件人手机号" width="120"></el-table-column>
      <el-table-column prop="source" label="订阅商品" :show-overflow-tooltip="true" :formatter="goodsFormatter">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ formatDate(new Date(scope.row.createTime), "yyyy-MM-dd hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column prop="orderState" label="订单状态" width="180" :formatter="orderStateFormatter"></el-table-column>
      <el-table-column prop="orderCode" label="订单号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="transportNo" label="运单号" :show-overflow-tooltip="true"
                       :formatter="transportNoFormatter"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="toOrderDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </el-table>


    <!--调整修改次数弹窗-->
    <el-dialog title="增加修改次数" width="560px" :visible.sync="modifyDialogVisible" @close="modifyDialogVisible=false">
      <el-form :model="detail">
        <el-form-item label="剩余修改次数">
          <p>{{ detail.updateMax - detail.updateCount }}</p>
        </el-form-item>
        <el-form-item label="增加次数">
          <el-input-number v-model="addUpdateCount"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="modifyDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="updateMaxModifyCommit">确 认</el-button>
      </div>
    </el-dialog>
    <!--调整修改次数弹窗-->
    <el-dialog title="手动创建订单" width="560px" :visible.sync="handleCreateOrderPop" @close="handleCreateOrderPop=false">
      <el-form>
        <span style="color:red;">提示：手动创建订单会使奶卡使用情况+1，不影响本月订单自动创建。</span>
        <el-form-item label="奶卡使用情况">
          <span>{{ detail.usedCount }}/12</span>
        </el-form-item>
        <el-form-item label="订单备注">
          <el-input v-model="handleCreateOrderRemark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="handleCreateOrderPop=false">取 消</el-button>
        <el-button type="primary" @click="handleCreateOrderConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="异常转正常" width="560px" :visible.sync="handleAbnormalPop" @close="handleAbnormalPop=false">
      <el-form>
        <span style="color:red;">提示：当前订单存在自动创建订单时失败的情况，可能是由于当前地址不配送或当前商品缺货导致，请确认已妥善处理。</span>
        <el-form-item label="奶卡使用情况">
          <span>{{ detail.usedCount }} / {{ detail.totalCount }}</span>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="handleAbnormalPop=false">取 消</el-button>
        <el-button type="primary" @click="handleAbnormalConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="暂停或发货" width="560px" :visible.sync="handleChangePackageStatePop"
               @close="handleChangePackageStatePop=false">
      <el-form>
        <span style="color:red;">
          {{ detail.packageState == 'ongoing' ? '是否确认暂停奶卡？' : '是否确认继续发货？' }}
        </span>

      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="handleChangePackageStatePop=false">取 消</el-button>
        <el-button type="primary" @click="handleChangePackageStateConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改订阅商品" width="560px"
               :visible.sync="handleChangeAppointGoodsPop" @close="handleChangeAppointGoodsPop=false">
      <el-form>
        <el-form-item label="订阅商品">
          <span>
            <el-input
                style="width:300px;"
                v-model="changeGoodsParam.goodsName"
                @focus="$refs.selectGoodsPopup.dialogVisible = true"
            />
          </span>
        </el-form-item>
        <el-form-item label="每期数量">
          <span>
            <el-input-number v-model="changeGoodsParam.num" :min="1"/>
          </span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="handleChangeAppointGoodsPop=false">取 消</el-button>
        <el-button type="primary" @click="updateYearCardAppointGoods">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="同步到待发货" width="560px"
               :visible.sync="syncAppointGoodsPop" @close="syncAppointGoodsPop=false">
      <div style="line-height: 20px;">
        <h2>1、本操作应在修改订阅商品后执行；</h2>
        <h2>2、如果存在待发货状态的订阅订单，会取消原包裹，以新商品重新发货；</h2>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="syncAppointGoodsPop=false">取 消</el-button>
        <el-button type="primary" @click="syncAppointGoodsToUnTransportOrder">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="取消奶卡并退款" width="560px"
               :visible.sync="cancelCardPop" @close="cancelCardPop=false">
      <div style="line-height: 24px;font-size: 14px;">
        <h2>奶卡实际支付金额：{{ cancelCardData.paymentAmount }}</h2>
        <h2>支付方式：{{
            cancelCardData.paymentChannel == 'balance' ? '余额支付' :
                cancelCardData.paymentChannel == 'wxpay' ? '微信支付' :
                    cancelCardData.paymentChannel == 'giro' ? '线下转账' :
                        cancelCardData.paymentChannel == 'wxpay_plural' ? '微信多笔支付' : ''
          }}</h2>
        <h2>奶卡期数：12</h2>
        <h2>奶卡每期单价：{{ cancelCardData.unitPrice }}</h2>

        <h2 style="margin-top: 20px;">截止{{ tools.formatDate(new Date(), "yyyy-MM-dd hh:mm:ss") }}</h2>
        <h2>已发货期数：{{ cancelCardData.transportCount }}</h2>
        <h2>
          待发货期数：{{ cancelCardData.untransportCount }}
          <el-switch v-model="cancelCardData.refund1" active-text="退款（取消待发货的订单）"></el-switch>
        </h2>
        <h2>
          未生成期数：{{ cancelCardData.ungenerateCount }}
          <el-switch v-model="cancelCardData.refund2" active-text="退款" disabled></el-switch>
        </h2>

        <h2 style="margin-top: 20px;">
          退款金额：
          {{ cancelCardData.unitPrice }}
          *
          {{
            cancelCardData.refund1 ? cancelCardData.untransportCount + cancelCardData.ungenerateCount : cancelCardData.ungenerateCount
          }}
          =
          {{
            cancelCardData.refund1 ?
                new BigNumber(cancelCardData.untransportAmount).add(cancelCardData.ungenerateAmount).toNumber() :
                new BigNumber(cancelCardData.ungenerateAmount).toNumber()
          }}</h2>
        <h2></h2>
        <div>
          退款方式：
          <el-radio-group v-model="cancelCardData.refundChannel">
            <!--            <el-radio :label="''">原路返回</el-radio>-->
            <el-radio :label="'bank'">银行卡</el-radio>
            <el-radio :label="'alipay'">支付宝</el-radio>
          </el-radio-group>
        </div>
        <div v-if="cancelCardData.refundChannel=='bank' || cancelCardData.refundChannel=='alipay'">
          <el-input style="width: 150px;margin-right: 10px;"
                    v-model="cancelCardData.refundDestBank"
                    placeholder="银行名称" :disabled="cancelCardData.refundChannel=='alipay'"/>
          <el-input style="width: 150px;margin-right: 10px;" v-model="cancelCardData.refundDestName" placeholder="姓名"/>
          <el-input style="width: 150px;margin-right: 10px;" v-model="cancelCardData.refundDestNo" placeholder="账户"/>
        </div>

        <h2 style="margin-top: 20px;color: red">提示：本操作将直接取消奶卡，并退款，不再经过审核。</h2>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="cancelCardPop=false">取 消</el-button>
        <el-button type="primary" @click="cancelCardAndRefund">确 认</el-button>
      </div>
    </el-dialog>

    <select-goods-popup ref="selectGoodsPopup" :selection="'single'" @submit="selectGoods"></select-goods-popup>
  </div>
</template>

<script>
import selectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";
import tools from "COMJS/tools";
import BigNumber from "COMJS/bignumber.min"
import {
  fetchDetail,
  modifyUpdateMax,
  manualCreateOrderFromVirtualPackage,
  makeNormal,
  changePackageState
} from 'API/som/miklCard.js'
import {
  adminUpdateYearCardAppointGoods,
  adminSyncAppointGoodsToUnTransportOrder,
  getCancelCardData,
  cancelCardAndRefund
} from 'API/som/miklCard.js'

export default {
  components: {
    selectGoodsPopup,
  },
  computed: {
    BigNumber() {
      return BigNumber
    },
    tools() {
      return tools
    }
  },
  watch: {
    'cancelCardData.refundChannel': {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal == 'alipay') {
          this.cancelCardData.refundDestBank = '支付宝';
        }
      }
    }
  },
  data() {
    return {
      id: "",
      tableData: [],
      pageInfo: {
        virtualPackageId: "",
        pageNo: 1,
        pageSize: 20
      },
      detail: {},
      addUpdateCount: 0,
      lodding: false,
      modifyDialogVisible: false,
      handleCreateOrderPop: false,
      handleCreateOrderRemark: "",
      handleAbnormalPop: false,
      handleChangePackageStatePop: false,
      //同步待发货
      syncAppointGoodsPop: false,
      //改商品
      handleChangeAppointGoodsPop: false,
      changeGoodsParam: {},
      //取消
      cancelCardPop: false,
      cancelCardData: {
        transportCount: 0, untransportCount: 0, ungenerateCount: 0,
        refund1: true, refund2: true, paymentAmount: 0, untransportAmount: 0, ungenerateAmount: 0,
        paymentChannel: '', totalCount: 12, unitPrice: 0, distributorAnormal: '',
        refundChannel: '', refundDestBank: '', refundDestName: '', refundDestNo: ''
      },
    }
  },
  methods: {
    formatDate: tools.formatDate,
    async loadTableDataForCardDetail() {
      this.detail = await fetchDetail(this.pageInfo)
      //console.log(this.detail)
      //this.tableData = this.detail.orderList
      let orderList = this.detail.orderList
      let tableDataTemp = []
      let i = 0
      for (let ele of orderList) {
        let idx = ''
        if (ele.orderState == '0' || ele.orderState == '1') {
          idx = this.detail.usedCount - (i++)
        }
        tableDataTemp.push({
          ...ele,
          idx: idx
        })
      }
      this.tableData = tableDataTemp;
      this.lodding = true;
    },
    updateMaxModifyCommit() {
      if (this.addUpdateCount <= 0) {
        return this.$message({
          message: "请输入增加次数",
          type: "warning"
        });
      }
      const param = {
        "virtualPackageId": this.detail.virtualPackageId,
        "updateMax": this.detail.updateMax + this.addUpdateCount
      }
      modifyUpdateMax(param)
      this.detail.updateMax = this.detail.updateMax + this.addUpdateCount
      this.modifyDialogVisible = false
    },
    async handleCreateOrderConfirm() {
      const param = {
        "virtualPackageId": this.detail.virtualPackageId,
        "remark": this.handleCreateOrderRemark
      }
      await manualCreateOrderFromVirtualPackage(param)
      this.handleCreateOrderPop = false
      this.loadTableDataForCardDetail()
    },
    goodsFormatter(row) {
      if (row.orderGoodsList && row.orderGoodsList.length > 0) {
        return row.orderGoodsList[0].goodsName
      }
      return ''
    },
    orderStateFormatter(row) {
      //console.log(row)
      if (row.orderState == 0) {
        return row.thirdPartyShipCode == 'JKY' ? '待发货-已转吉客云'
            : row.transportState == 0 ? '待发货-未转仓库'
                : row.transportState == 5 ? '待发货-已转仓库'
                    : row.transportState == 1 ? '待收货' : '';
      } else {
        return row.orderState == 1 ? '已完成' : row.orderState == 2 ? '失效' : '撤销'
      }
      return ''
    },
    transportNoFormatter(row) {
      if (row.orderPackageList && row.orderPackageList.length > 0) {
        return row.orderPackageList[0].transportNo
      }
      return ''
    },
    toOrderDetail(row) {
      let orderId = row.orderId
      //this.$router.push(`/som/orderDetail/${orderId}`)
      this.$router.push(`/som/orderEdit/${orderId}`)
    },
    async handleAbnormalConfirm() {
      const param = {
        "virtualPackageId": this.detail.virtualPackageId
      }
      await makeNormal(param)
      this.handleAbnormalPop = false
      this.loadTableDataForCardDetail()
    },

    async handleChangePackageStateConfirm() {
      const param = {
        "virtualPackageId": this.detail.virtualPackageId
      }
      // 执行方法
      await changePackageState(param)
      // 关闭提示窗口
      this.handleChangePackageStatePop = false
      // 刷新页面
      this.loadTableDataForCardDetail()
    },

    ShowHandleChangeAppointGoodsPop(command) {
      if (command == 'a') {
        this.handleChangeAppointGoodsPop = true;
        this.changeGoodsParam = {}
      } else if (command == 'b') {
        this.syncAppointGoodsPop = true;
      }
    },
    selectGoods(selected) {
      let param = {}
      param.goodsName = selected[0].cnName + "(" + selected[0].goodsCode + ")";
      param.goodsId = selected[0].goodsId;
      param.num = 1;
      this.changeGoodsParam = param;
    },
    async updateYearCardAppointGoods() {
      const param = {
        virtualPackageId: this.detail.virtualPackageId,
        appointGoodsId: this.changeGoodsParam.goodsId,
        appointGoodsNum: this.changeGoodsParam.num,
      }
      // 执行方法
      await adminUpdateYearCardAppointGoods(param)
      // 关闭提示窗口
      this.handleChangeAppointGoodsPop = false
      // 刷新页面
      this.loadTableDataForCardDetail()
    },
    async syncAppointGoodsToUnTransportOrder() {
      const param = {
        virtualPackageId: this.detail.virtualPackageId,
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      // 执行方法
      await adminSyncAppointGoodsToUnTransportOrder(param)
      // 关闭提示窗口
      this.syncAppointGoodsPop = false
      // 刷新页面
      this.loadTableDataForCardDetail()
      loading.close();
    },
    async showCancelCard() {
      this.cancelCardPop = true;
      const param = {
        virtualPackageId: this.detail.virtualPackageId,
      }
      let data = await getCancelCardData(param)
      data.refund1 = true;
      data.refund2 = true;
      data.refundChannel = 'bank';
      data.refundDestBank = '';
      data.refundDestName = '';
      data.refundDestNo = '';

      this.cancelCardData = data;
    },
    async cancelCardAndRefund() {
      const param = {
        virtualPackageId: this.detail.virtualPackageId,
        cancelUnTransportOrder: this.cancelCardData.refund1 ? '1' : '0',
        refundAmount: this.cancelCardData.refund1 ?
            new BigNumber(this.cancelCardData.untransportAmount).add(this.cancelCardData.ungenerateAmount).toNumber() :
            new BigNumber(this.cancelCardData.ungenerateAmount).toNumber(),
        ...this.cancelCardData
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      // 执行方法
      await cancelCardAndRefund(param)
      // 关闭提示窗口
      this.cancelCardPop = false
      // 刷新页面
      this.loadTableDataForCardDetail()
      loading.close();
    },
  },
  mounted() {
    //console.log(this.$route.params.id)
    this.pageInfo.virtualPackageId = this.$route.params.id
    this.loadTableDataForCardDetail()
  }
}
</script>

<style scoped>
.title-info span {
  margin-right: 40px;
}

.title-mtp {
  margin-top: 20px;
}
</style>
