<template>
  <div class="tree-ground">
    <level-tree-item
      ref="firstTree"
      :treeName="firstTreeName"
      :list="tree"
      :canSee="canSee"
      :canEdit="canEdit"
      :canDelete="canDelete"
      @seeDetail="seeDetail"
      @edit="edit"
      @deleteNode="deleteNode"
      @selectNode="selectFirstTree"
    ></level-tree-item>
    <level-tree-item
      v-if="isshope"
      ref="secondTree"
      :treeName="secondTreeName"
      :list="secondTreeData"
      :canSee="canSee"
      :canEdit="canEdit"
      :canDelete="canDelete"
      @seeDetail="seeDetail"
      @edit="edit"
      @deleteNode="deleteNode"
      @selectNode="selectSecondTree"
    ></level-tree-item>
    <level-tree-item
      v-if="isshope"
      ref="thirdTree"
      :treeName="thirdTreeName"
      :list="thirdTreeData"
      :canSee="canSee"
      :canEdit="canEdit"
      :canDelete="canDelete"
      :isArrow="false"
      @seeDetail="seeDetail"
      @edit="edit"
      @deleteNode="deleteNode"
      @selectNode="selectThirdTree"
    ></level-tree-item>
  </div>
</template>

<script>
import levelTreeItem from "./levelTreeItem";

export default {
  name: "productTreeList",
  props: {
      isshope:{
          type:Boolean
      },
    tree: {
      type: Array,
      default: function() {
        return [];
      }
    },
    firstTreeName: String,
    secondTreeName: String,
    thirdTreeName: String,
    canEdit: {
      type: Boolean,
      default: false
    },
    canSee: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      secondTreeData: [],
      thirdTreeData: [],
      selectedFirstTree: {},
      selectedSecondTree: {},
      selectedThirdTree: {}
    };
  },
  components: {
    levelTreeItem
  },
  mounted() {},
  methods: {
    // 定位结果 - 暴露给外部的方法，如搜索结果
    positioningResult(item) {
      this.selectedFirstTree = {};
      this.selectedSecondTree = {};
      this.selectedThirdTree = {};
      this.tree.forEach(e => {
        if (e.id == item.firstId) {
          this.selectFirstTree(e);
          this.$refs["firstTree"].selectNode(e);
        }
      });
      this.secondTreeData.forEach(e => {
        if (e.id == item.secondId) {
          this.$refs["secondTree"].selectNode(e);
          this.selectSecondTree(e);
        }
      });
      this.thirdTreeData.forEach(e => {
        if (e.id == item.thirdId) {
          this.$refs["thirdTree"].selectNode(e);
          this.selectThirdTree(e);
        }
      });
    },
    clearData() {
      this.secondTreeData = [];
      this.thirdTreeData = [];
      this.selectedFirstTree = {};
      this.selectedSecondTree = {};
      this.selectedThirdTree = {};
    },
    selectFirstTree(node) {
      if (node.id == this.selectedFirstTree.id) return;
      this.selectedSecondTree = {};
      this.selectedThirdTree = {};
      this.selectedFirstTree = node;
      this.emitEvents(node)
    },

    selectSecondTree(node) {
      if (node.id == this.selectedSecondTree.id) return;
      this.selectedThirdTree = {};
      this.selectedSecondTree = node;
      this.emitEvents(node)
    },
    selectThirdTree(node) {
      this.selectedThirdTree = node;
      this.emitEvents(node)
    },
    seeDetail(node) {
      this.$emit("seeDetail", node);
    },
    edit(node) {
        this.$emit("edit", node);
    },
    deleteNode(node) {
        this.$emit("deleteNode", node);
    },
    emitEvents(node) {
      let params = {
        selectedFirstTree: this.selectedFirstTree,
        selectedSecondTree: this.selectedSecondTree,
        selectedThirdTree: this.selectedThirdTree
      };
      this.$emit("changeAllNode", params);
      this.$emit("changeNode", node);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/common/tool.scss";
@import "~ASSETS/css/components/levelTree.scss";
</style>