<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-button size="mini" type="primary" @click="showDialogForm">生成序列</el-button>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">库品编码：</span>
            <el-input size="mini" v-model="searchInfo.skuCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">生成时间：</span>
            <el-date-picker
                style="margin-left: 18px"
                v-model="searchInfo.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
    </div>
    <div style="color: red;">
      *注意：由于Linux和windows早期版本换行符不兼容问题，如果印刷设备无法识别，需要在导出的txt文件上手动修改换行符号！
    </div>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="createTime" label="生成时间"></el-table-column>
      <el-table-column prop="num" label="生成数量"></el-table-column>
      <el-table-column prop="progressPer" label="生成进度"></el-table-column>
      <el-table-column prop="skuCode" label="库品编码"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation" v-if="scope.row.fileUrl">
            <a
                href="javascript: void(0);"
                title="下载"
                @click="downloadFile(scope.row)"
            >
              <i class="el-icon-download"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="生成序列" :visible.sync="dialogFormVisible">
      <el-form :model="serialBatchInfo" v-loading="loading">
        <el-form-item label="库品编码：">
          <el-select v-model="serialBatchInfo.skuCode" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量：">
          <el-input  v-model="serialBatchInfo.num" type="number"
                     :min="1" :max="1000000"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="serialBatchInfo.remark"></el-input>
        </el-form-item>
      </el-form>
      <div v-if="serialBatchInfo.num>10000" style="color: red;">单任务预计需要时间{{needTime}}分钟</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="createBatch">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import IoProgressService from "API/IoProgressService.js";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerSerialBatch from "API/whm/dealerSerialBatch.js";
import tools from "COMJS/tools.js";

export default {
  name: "serialNumBatch",
  data() {
    return {
      searchInfo: {
        dealerAccount: "",
        createTime:""
      },
      items:{
        createTime: { filter: "between", dataType: "date" },
      },
      options: [],
      dialogFormVisible:false,
      serialBatchInfo:{
        skuCode:'',
        num:1,
        remark:'',
      }
    };
  },
  mixins: [pageTableMixin],
  computed:{
    needTime(){
      if(this.serialBatchInfo.num>10000){
        return (this.serialBatchInfo.num/10000).toFixed(0)*1
      }else{
        return 0
      }
    }
  },
  mounted(){
    dealerSerialBatch.getSerialBatchConfig().then(data=>{
      this.options = data.map(e=>{
        return {
          value: e.skuCode,
          label: e.skuCode
        }
      })
    })
  },
  methods: {
    loadTableData() {
      dealerSerialBatch.queryAtPage(this.pageInfo, this.searchConfig).then(
          data => {
            data.list = data.list.map(e => {
              return {
                ...e,
                createTime: tools.formatDate(new Date(e.createTime),'yyyy-MM-dd hh:mm:ss'),
                progressPer:e.progressPer+"%"
              };
            });
            this.querySuccess(data);
          }
      );
    },
    showDialogForm(){
      this.serialBatchInfo={
        skuCode:'',
        num:1,
        remark:''
      }
      this.dialogFormVisible = true
    },
    createBatch(){
      if(this.serialBatchInfo.skuCode==''){
        this.$message({
          message: "请选择库品",
          type: "warning",
        });
        return
      }
      if(this.serialBatchInfo.num<1){
        this.$message({
          message: "最小数量为1，请输入正确数量",
          type: "warning",
        });
        return
      }
      if(this.serialBatchInfo.num>1000000){
        this.$message({
          message: "最大数量为1000000，请输入正确数量",
          type: "warning",
        });
        return
      }

      dealerSerialBatch.createBatch(this.serialBatchInfo).then(data=>{
        this.$message({
          message: "已提交创建，请稍等",
          type: "success",
        });
        this.dialogFormVisible = false
        this.loadTableData()
      })
    },
    downloadFile(row) {
      if(!row.fileUrl){
        return this.$message({
          message: "文件还没准备好，请稍等",
          type: "warning",
        });
      }
      IoProgressService.download("serial_batch",row.fileUrl, false);
    }
  }
};
</script>
