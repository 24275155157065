<template>
  <div class="freight-list">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="toEdit">返回上一级</el-button>
      </el-form-item>        
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column prop="skuCode" label="出库单号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="skuName" label="快递公司" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="thingTypeName" label="快递单号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="actualNum" label="物流状态" :show-overflow-tooltip="true"></el-table-column>          
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">          
          <!-- <div class="gray-btn" @click="toDetail(scope.row)">确认收货</div> -->
          <div class="blue-btn" @click="toDetail(scope.row)">确认收货</div>
          <!-- <div class="blue-bor-btn" @click="toDetail(scope.row)">收货凭证</div> -->
        </template>
      </el-table-column>
    </ws-page-table>
    
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  name: "dealerList",
  data() {
    return {
      value: [],
      options: [],
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData()    
  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.type = 0
      if(!this.searchInfo.thingTypeId){
        this.searchInfo.thingTypeId = ""
      }
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            thingTypeName: e.level ? e.level.thingTypeName : "",
            actualNum: e.actualNum.toLocaleString(),//总库存
            lockNum: e.lockNum.toLocaleString(),//锁定库存
            availableNum: e.availableNum.toLocaleString(),//可用库存
          };
        });
        this.querySuccess(data);
      });
    },
    toEdit(row) {
      this.$router.push(`/dea/dealerEdit/${row.id}`);
    },
    toDetail(row){
      this.$router.push(`/dea/dealerDetail/${row.id}`);
    },
  },
};
</script>
<style>
.freight-list .gray-btn,.freight-list .blue-bor-btn{ width: 74px; height: 28px; line-height: 28px; border-radius: 2px; cursor: pointer; font-size: 12px;}
.freight-list .gray-btn{ border:1px solid #999; text-align: center; color: #999;}
.freight-list .blue-btn{ width: 76px; height: 30px; border-radius: 2px; background: #1B73E8; text-align: center; line-height: 30px; color: #fff; cursor: pointer; font-size: 12px;}
.freight-list .blue-bor-btn{ border:1px solid #1B73E8; text-align: center; color: #1B73E8;}
</style>