<template>
  <div class="out-list">
    <el-form :inline="true" class="btn-wrap">
      <el-form-item>
        <el-button type="primary" @click="exportOrder">导出包裹</el-button>
        <el-button type="primary" @click="exportRecord">导出记录</el-button>
        <el-button type="primary" @click="toWayBill">运单导入</el-button>
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="is-check">
      <el-form-item label="导出状态:">
        <el-select v-model="searchInfo.exportDataType" placeholder="选择导出状态">
          <el-option v-for="item in exportSelect" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="运单状态:">
        <el-select v-model="searchInfo.searchTransCode" placeholder="选择运单状态">
          <el-option v-for="item in transSelect" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="快递公司:">
        <!-- <el-input v-model="searchInfo.transportCompany" /> -->
        <el-select v-model="searchInfo.transportCompany" clearable placeholder="选择来源">
          <el-option v-for="item in companySelect" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="demo-form-inline1">
      <el-form-item label="订单号:">
        <el-input v-model="searchInfo.operationCode" />
      </el-form-item>
      <el-form-item label="收件姓名:">
        <el-input v-model="searchInfo.receiverName" />
      </el-form-item>
      <el-form-item label="收件手机:">
        <el-input v-model="searchInfo.receivingPhone" />
      </el-form-item>
      <el-form-item label="会员渠道(手机号):">
        <el-input v-model="searchInfo.intermediatorUser" />
      </el-form-item>

      <el-form-item label="包裹号:" style="margin-top: 22.5px">
        <el-input v-model="searchInfo.packageCode" />
      </el-form-item>
      <el-form-item label="收件地址:" style="margin-top: 22.5px">
        <el-input v-model="searchInfo.receivingAddress" />
      </el-form-item>
      <!-- <el-form-item label="商品编码:">
  <el-input v-model="searchInfo.packageContentCode" />
</el-form-item> -->

      <!-- <el-form-item label="订单来源:">
  <el-select v-model="searchInfo.operationType" clearable placeholder="选择来源">
    <el-option
      v-for="item in operationSelect"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</el-form-item> -->

      <div class="form-row">
        <el-form-item label="商品编码:">
          <el-tooltip effect="dark" content="可同时输入多个需筛选的商品编码，编码之间用逗号隔开" placement="top">
            <i class="icon-tip"></i>
          </el-tooltip>
          <el-input v-model="goodsCodeOrigin" placeholder="请输入商品编码，可输入多个（需用逗号隔开）" style="width: 600px"></el-input>
        </el-form-item>
      </div>

      <div class="form-row">
        <div class="form-item">
          <span class="form-label">转出库单时间：</span>
          <el-date-picker v-model="searchInfo.createTime" size="mini" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </div>
      </div>

      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <!--订单编号、商品编码、下单时间、订单来源、快递公司、会员渠道(手机号)-->

    <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" @pageChange="loadTableData">
      <el-table-column prop="packageCode" label="包裹号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="operationCode" label="订单号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="packageContent" label="库品信息" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="receiverName" label="收件人姓名"></el-table-column>
      <el-table-column prop="receivingPhone" label="收件人手机">
        <template slot-scope="scope">
          <span>{{scope.row.receivingPhone}}{{scope.row.receivingPhoneTransfer?' (转:'+scope.row.receivingPhoneTransfer+')':''}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="transportCompany" label="快递公司"></el-table-column>
      <el-table-column prop="transportCode" label="运单号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="toDetail(scope.row)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" @click="goEdit(scope.row)" title="编辑">
              <i style="color: rgba(70, 141, 234, 1)" class="el-icon-edit"></i>
            </a>
            <a href="javascript: void(0);" @click="goPackage(scope.row)" title="拆合包">
              <i style="color: rgba(70, 141, 234, 1)" class="el-icon-menu"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="包裹编辑" :visible.sync="dialogVisible" width="30%">
      <div class="packageEdit-swith">
        <el-radio v-model="swithTab" label="1">更改快递信息</el-radio>
        <el-radio v-model="swithTab" label="2">手动转物流出库</el-radio>
      </div>
      <div class="packageEdit-content" v-if="swithTab=='1'">
        <div class="label">
          <div class="label-title">快递公司</div>
          <div class="label-operation">
            <el-select style="width:60%" v-model="packageEdit.transportCompany" clearable placeholder="选择来源">
              <el-option v-for="item in companySelect" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="label">
          <div class="label-title">快递单号<span style="color:red">（填写表示直接快递发货）</span></div>
          <div class="label-operation">
            <el-input style="width:60%" v-model="packageEdit.transportCode"></el-input>
          </div>
        </div>
      </div>
      <div v-if="swithTab=='2'" class="manual-content">
        <p>将快递出库转为物流出库，后续操作到“物流出库”中完成。</p>
        <p>物流出库需要管理员录入物流单号，手动完成物流</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogSave">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import boundQuery from "API/whm/boundQuery";
import IoProgressService from "API/IoProgressService";
import userModule from "@/module/userModule";
import BimEnumService from "API/bim/BimEnumService";
import dealWithInvoice from "../../som/order/dealWithInvoice.vue";

export default {
  components: { dealWithInvoice },
  name: "outList",
  data() {
    return {
      packageEdit: {
        outboundDocumentsId: "",
        transportCompany: "",
        transportCode: "",
      },
      dialogVisible: false,
      swithTab: "1",
      value: [],
      options: [],
      searchInfo: {
        operationCode: "", //订单编号
        receiverName: "", //收件人
        receivingPhone: "", //收件人手机号
        receivingAddress: "", //收货地址
        packageCode: "",
        createTime: "",
        packageContentCode: "",
        operationType: "",
        transportCompany: "",
        exportDataType: "2",
        intermediatorUser: "",
        goodsCode: "",
        searchTransCode: "2",
      },
      items: {
        operationCode: { filter: "eq" },
        receiverName: { filter: "eq" },
        receivingPhone: { filter: "eq" },
        skuGroupCode: { filter: "eq" },
        receivingAddress: { filter: "eq" },
        createTime: { filter: "between", dataType: "date" },
        exportDataType: { filter: "eq" },
        intermediatorUser: { filter: "eq" },
        searchTransCode: { filter: "eq" },
        packageCode: { filter: "eq" },
        transportCompany: { filter: "eq" },
      },

      goodsCodeOrigin: "",

      requestHeaders: {
        Authorization: userModule.token,
      },

      exportSelect: [
        {
          value: "0",
          label: "未导出",
        },
        {
          value: "1",
          label: "已导出",
        },
        {
          value: "2",
          label: "全部",
        },
      ],
      transSelect: [
        {
          value: "0",
          label: "无运单",
        },
        {
          value: "1",
          label: "已有运单",
        },
        {
          value: "2",
          label: "全部",
        },
      ],

      operationSelect: [
        {
          value: "0",
          label: "小程序",
        },
        {
          value: "1",
          label: "批量导入",
        },
        {
          value: "2",
          label: "客服下单",
        },
      ],

      companySelect: [],

      filterTimes: [],
      ischeck: true,
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //取快递公司
    BimEnumService.getEnum("TRANSPORT_COMPANY").then((data) => {
      this.companySelect = data.map((e) => {
        return {
          value: e.value,
          label: e.name,
        };
      });
    });
    //this.loadTableData();
  },
  methods: {
    checkGoodsCode() {
      if (this.goodsCodeOrigin) {
        this.searchInfo.goodsCode = this.goodsCodeOrigin.replace(/，/, ",");
      } else {
        this.searchInfo.goodsCode = this.goodsCodeOrigin;
      }
    },
    toWayBill() {
      this.$router.push("/whm/wayBill");
    },
    exportRecord() {
      //跳转到导出记录
      this.$router.push("/whm/orderExportRecord");
    },
    search() {
      this.pageInfo.pageNo = 1;
      this.loadTableData();
    },
    loadTableData() {
      //console.log(this.searchInfo.createTime)

      if (this.searchInfo.createTime === null) {
        this.searchInfo.createTime = "";
      }
      this.checkGoodsCode();
      boundQuery
        .queryOutboundPage(this.pageInfo, this.searchConfig)
        .then((data) => {
          if (data) {
            data.list = data.list.map((e) => {
              return {
                id: e.outboundDocumentsId, //出库单id
                packageCode: e.packageCode, //包裹编号
                operationCode: e.operationCode, //订单编号
                packageContent: e.packageContent, //库品信息
                receiverName: e.receiverName, //收件人
                receivingPhone: e.receivingPhone, //收件人手机号
                receivingPhoneTransfer: e.receivingPhoneTransfer,
                transportCompany: e.transportCompany, //快递公司
                transportCode: e.transportCode, //运单号
              };
            });
          } else {
            data.list = data.list.map((e) => {
              return {
                id: null, //出库单id
                packageCode: null, //包裹编号
                operationCode: null, //订单编号
                packageContent: null, //库品信息
                receiverName: null, //收件人
                receivingPhone: null, //收件人手机号
                transportCompany: null, //快递公司
                transportCode: null, //运单号
              };
            });
          }
          this.querySuccess(data);
        });
    },
    toDetail(row) {
      this.$router.push({
        path: `/whm/outDetail/${row.id}`,
        query: {
          transportCode: row.transportCode,
        },
      });
    },
    goEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.packageEdit.transportCompany = row.transportCompany;
      this.packageEdit.outboundDocumentsId = row.id;
      this.packageEdit.transportCode = row.transportCode;
      // this.$router.push({
      //   path: `/whm/outDetail/${row.id}`,
      //   query: {
      //     editShow: true,
      //     transportCode: row.transportCode,
      //   },
      // });
    },
    goPackage(row) {
      this.$router.push({
        path: `/whm/DisassemblyPackage`,
        query: {
          id: row.id,
        },
      });
    },
    exportOrder() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      const that = this;
      boundQuery
        .exportOrderPackage(this.pageInfo, this.searchConfig)
        .then((data) => {
          //console.log(data)
          let fileName = data.fileName;
          let module = data.module;
          IoProgressService.download(module, fileName, false);
          loading.close();
          that.isShowPopop = false;
          that.loadTableData();
        })
        .catch(() => {
          loading.close();
        });
    },
    dialogSave() {
      let packageEdit = this.packageEdit;
      console.log(packageEdit);
      if (this.swithTab == "1") {
        boundQuery.saveTransport(packageEdit).then((data) => {
          this.$message({ type: "success", message: "保存成功" });
          this.dialogVisible = false;
          this.loadTableData();
        });
      }
      if (this.swithTab == "2") {
        boundQuery
          .expressToLogistics(packageEdit.outboundDocumentsId)
          .then((data) => {
            this.$message({ type: "success", message: "保存成功" });
            this.dialogVisible = false;
            this.loadTableData();
          });
      }
    },
  },
};
</script>
<style lang="scss">
.out-list .btn-wrap {
  margin-bottom: 10px;
  padding: 10px 0 0 20px;
  background: #fff;
}

.out-list .demo-form-inline1 {
  background: #fff;
}

.out-list .demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.out-list .demo-form-inline1 {
  padding: 20px 0 20px 20px;
}

.out-list .el-form--inline .el-form-item {
  margin-right: 20px;
  margin-bottom: 0;
}

.out-list .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.out-list .ws-page-table .icon-operation a {
  margin-right: 20px;
}

.out-list .el-form-item {
  margin: 10px 0;
}

.out-list .ws-page-table {
  margin-top: 20px;
}

.out-list .form-row {
  margin-top: 20px;
}

.filter-time-block {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  padding: 0 6px;
  font-size: 14px;
  color: #999;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  margin-left: 10px;

  &.is-active {
    border-color: $themeColor;
    color: #fff;
    background-color: $themeColor;
  }
}

.is-check {
  padding-left: 20px;
  background: #fff;
}

.is-check .el-form-item__label {
  font-size: 12px;
}

.icon-tip {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/tip.png");
  background-size: cover;
}
.packageEdit-swith {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.packageEdit-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 80px;
  width: 100%;
}
.label-title {
  margin: 8px 0px;
}
.label-operation {
  height: 100%;
  width: 100%;
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.manual-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 160px;
  margin-top: 20px;
}
.manual-content > p {
  line-height: 20px;
}
</style>