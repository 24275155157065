<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <el-button class="search-btn" size="mini" @click="goBack">返回</el-button>
          </div>
          <div class="form-item">
            <span class="form-label">会员级别：{{distributorInfo.distributorLevel}}</span>
          </div>
          <div class="form-item">
            <span class="form-label">会员名称：{{distributorInfo.distributorName}}</span>
          </div>
          <div class="form-item">
            <span class="form-label">会员账号：{{distributorInfo.distributorAccount}}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">商品名称：</span>
            <el-input size="mini" v-model="searchInfo.goodsName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品编码：</span>
            <el-input size="mini" v-model="searchInfo.goodsCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">统计时间：</span>
            <el-date-picker
                v-model="searchInfo.timeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="goodsCode" label="商品编码"></el-table-column>
      <el-table-column prop="goodsName" label="商品名称"></el-table-column>
      <el-table-column prop="distributorNum" label="会员售量" width="160"></el-table-column>
      <el-table-column prop="distributorSales" label="会员销售额（不含运费）" width="160"></el-table-column>
      <el-table-column prop="promotionNum" label="推广售量" width="160"></el-table-column>
      <el-table-column prop="promotionSales" label="推广销售额（不含运费）" width="160"></el-table-column>
      <el-table-column prop="teamNum" label="团队售量" width="160"></el-table-column>
      <el-table-column prop="teamSales" label="团队销售额（不含运费）" width="160"></el-table-column>
      <el-table-column prop="promotionServiceFee" label="推广服务费"></el-table-column>
      <el-table-column prop="teamServiceFee" label="团队服务费"></el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dimAccountDetailService from "API/dim/DimAccountDetailsService.js";
import tools from "COMJS/tools.js";

export default {
  name: "salesStatistics",
  data() {
    return {
      distributorInfo:{
        distributorLevel:"",
        distributorName:"",
        distributorAccount:"",
      },
      searchInfo: {
        distributorId: "",
        goodsName:"",
        goodsCode:"",
        timeRange:[]
      }
    };
  },
  mixins: [pageTableMixin],
  created(){
    //console.log(this.$route.query)
    this.searchInfo.distributorId = this.$route.query.distributorId;
    this.distributorInfo = this.$route.query;
  },
  methods: {
    loadTableData() {
      dimAccountDetailService.queryDistributorGoodsJoin(this.pageInfo, this.searchInfo).then(
          data => {
            //console.log(data.list)
            data.list = data.list.map(e => {
              return {
                goodsId: e.goodsId,
                goodsCode: e.goodsCode,
                goodsName: e.goodsName,
                distributorNum: e.distributorNum,
                distributorSales: e.distributorSales,
                promotionNum: e.promotionNum,
                promotionSales: e.promotionSales,
                teamNum: e.teamNum,
                teamSales: e.teamSales,
                promotionServiceFee: e.promotionServiceFee,
                teamServiceFee: e.teamServiceFee
              };
            });
            this.querySuccess(data);
          }
      );
    },
    goBack(){
      this.$router.push('/report/distributor/salesStatistics')
    },
    translateDistributorLevel(level){
      let result;
      switch (level) {
        case 'g0':
          result = '普标';
          break;
        case 'g1':
          result = '银星';
          break;
        case 'g2':
          result = '金星';
          break;
        default:
          result = '未知';
      }
      return result;
    }
  }
};
</script>