<template>
  <div class="receive-wrap">
    <el-form :inline="true" class="rec-btn-wrap">
      <el-form-item>
        <el-button type="primary" @click="addReceive">领用出库</el-button>
      </el-form-item>
    </el-form>  
    <el-form :inline="true" class="rec-inline1">
      <el-form-item label="库品编码:">
        <el-input v-model="searchInfo.skuCode" />
      </el-form-item>
      <el-form-item label="库品名称:">
        <el-input v-model="searchInfo.skuName" />
      </el-form-item>
      <!--
      <el-form-item label="领用人:">
        <el-input v-model="searchInfo.receiveName" />
      </el-form-item>
      -->
      <el-form-item label="操作时间:">
        <el-date-picker v-model="searchInfo.startInTime" type="date" placeholder="选择日期"></el-date-picker>
        <span class="mb15">至</span>
        <el-date-picker v-model="searchInfo.endInTime" type="date" placeholder="选择日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>    
    <div style="height: 20px;"/>
    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="skuCode"
        label="库品编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="skuName"
        label="库品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <!--
      <el-table-column
        prop="receiveName"
        label="领用人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      -->
      <el-table-column prop="createUser" label="操作人"></el-table-column>
      <el-table-column prop="operNum" label="领用数量"></el-table-column>
      <el-table-column prop="createTime" label="操作时间"></el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import boundQuery from "API/whm/boundQuery";
import whmStockService from "API/whm/whmStockService";
import tools from "COMJS/tools";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        skuCode: "",//库品编码
        skuName: "",//库品名称
        purchaseBatchCode: "",//库品批次
        receiveName: "",//领用人
        startInTime: "",
        endInTime: "",
      },
      items: {
        operationCode: { filter: "like" },
        recipientName: { filter: "like" },
        recipientPhoneNum: { filter: "like" },
        skuGroupCode: { filter: "like" },
        recipientFullAddress: { filter: "like" },
        startInTime: { filter: "EQ" },
        endInTime: { filter: "EQ" }
      },      
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData()
  },
  methods: {
    addReceive(){
      this.$router.push("/whm/receiveEdit");
    },
    loadTableData() {
      this.searchInfo.openTime = this.searchInfo.startInTime ? tools.formatDate(new Date(this.searchInfo.startInTime), "yyyy-MM-dd") : ""
      this.searchInfo.endTime = this.searchInfo.endInTime ? tools.formatDate(new Date(this.searchInfo.endInTime), "yyyy-MM-dd") : ""
      whmStockService.queryReceivePage(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          return {
            skuCode: e.skuCode,//库品编号
            skuName: e.skuName,//库品名称
            receiveName: e.receiveName,//领用人
            createUser: e.createUser,//操作人
            operNum: e.operNum,//领用数量
            createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss"),//操作时间
          };
        });
        this.querySuccess(data);
      });      
    },   
  },
};
</script>
<style>
.rec-btn-wrap{ padding: 20px 0 0 20px; background: #fff;}
.rec-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.rec-inline1{ padding: 20px 0 0 20px; background: #fff;}
.rec-inline .long-inp{ width: 580px;}
.receive-wrap .el-form--inline .el-form-item {
  margin-right: 20px;
  margin-bottom: 0;
}
.receive-wrap .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}
.receive-wrap .ws-page-table .icon-operation a {
  margin-right: 20px;
}
.receive-wrap .btn-blue-wrap {
  display: flex;
  justify-content: flex-end;
}
.receive-wrap .btn-blue,.receive-wrap .btn-gray {
  margin-top: 20px;
  width: 80px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  background: #1b73e8;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.receive-wrap .btn-gray{ margin-left: 10px; width: 78px; height: 34px; border:1px solid #ddd; color: #999; background: #fff;}
.receive-wrap .table-wrap .el-dialog__header{ border-bottom: 0}
.receive-wrap .el-pagination__editor.el-input .el-input__inner{ width: 60px;}
.receive-wrap .el-pagination__jump .el-pagination__editor{ width: 80px;}
.mb15{ margin:0 15px}
</style>