<template>
  <div class="whm-add">
    <div class="step-title">库品信息</div>
    <el-form
      :model="whmInfo"
      label-width="180px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item label="库品编码:">
        {{whmInfo.skuCode}}
      </el-form-item>
      <el-form-item label="库品名称:">
        {{whmInfo.skuName}}
      </el-form-item>
      <el-form-item label="库品分类:">
        {{whmInfo.level ? whmInfo.level.thingTypeName : ''}}
      </el-form-item>
      <el-form-item label="库品单位:">
        {{whmInfo.skuUnit ? whmInfo.skuUnit.name : ''}}
      </el-form-item>
      <el-form-item label="库品规格:">
        <template>
          <el-form-item label="规格名:" label-width="78px">
            净含量
          </el-form-item>
          <el-form-item label="规格值:" label-width="78px">
            {{whmInfo.skuSpec ? whmInfo.skuSpec.name : ''}}
          </el-form-item>
        </template>
      </el-form-item>
      <el-form-item label="库存预警值:">
        {{whmInfo.warningNum}}
      </el-form-item>
      <el-form-item label="保质期:">
        {{whmInfo.shelfLife}}
      </el-form-item>
      <el-form-item label="库存售罄值:">
        {{whmInfo.soldOutNum}}
      </el-form-item>
      <el-form-item label="备注:">
        如该库品为奶卡中库品，请预留足够数量，否则随奶卡每月预留值变动，可能导致可用库存为负数。
      </el-form-item>
      <el-form-item label="库品图片:">
        <img style="width:100px; height:100px" :src="whmInfo.imageUrl">
      </el-form-item>
      <el-form-item label="是否启用:">
        {{whmInfo.enabled == 1 ? '是' : '否'}}
      </el-form-item>
      <el-form-item label="是否展示保质期至小程序:">
        {{whmInfo.isSku == 1 ? '是' : '否'}}
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">返回</el-button>
    </div>
  </div>
</template>

<script>
import WhmList from "API/whm/WhmList";

export default {
  name: "whmDetail",
  data() {
    return {
      //searchInfo: {},
      whmInfo: {
        skuCode: "", //库品编码
        skuName: "", // 库品名称
        thingTypeId: "", //库品分类id
        skuUnit: "", //库品单位
        skuSpec: "", //库品规格值
        enabled: "", // 是否启用
        isSku: "", // 是否启用
        shelfLife: "", //保质期
        warningNum: "", //预警值
        soldOutNum: "", //售罄值
        imageUrl:""//库品图片
      },    
    };
  },
  mounted() {
    this.getGoodsDetail();
  },
  methods: {
    backPre(){//返回上一页
      this.$router.push("/whm/whmList");
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailWhm(this.$route.params.id).then((data) => {
        console.log(data)
        let whmInfo = this.whmInfo
        whmInfo.skuCode = data.skuCode
        whmInfo.skuName = data.skuName
        whmInfo.level = data.level
        whmInfo.skuUnit = data.skuUnit
        whmInfo.skuSpec = data.skuSpec
        whmInfo.enabled = data.enabled
        whmInfo.isSku = data.isSku
        whmInfo.shelfLife = data.shelfLife
        whmInfo.warningNum = data.stock.warningNum
        whmInfo.soldOutNum = data.stock.sellOutNum
        whmInfo.imageUrl = data.imageUrl
        loading.close();
      });
    },
  },
};
</script>
<style scoped>
.step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
}
.whm-add{ padding:20px 0 60px 0; background: #fff;}
.steps-btn-wrapper{ margin-top: 80px; display: flex; justify-content: center;}
.steps-btn-wrapper button{ width: 80px;}
</style>