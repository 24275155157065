<template>
  <div class="logistics-add-goods">
    <div class="step-title">选择商品及数量</div>
    <el-form
        :model="whmInfo"
        label-width="140px"
        ref="whmInfoForm"
        class="step-form"
    >
      <el-form-item label="商品编号：">
        <div
            class="select-btn"
            v-if="!whmInfo.goodsCode"
            @click="goodsChoiceDialog = true"
        >
          请选择商品名称
        </div>
        <div v-else>
          <span>{{ whmInfo.goodsCode }}</span>
          <span
              class="tips-btn"
              style="dispaly: inline-block; margin-left: 15px"
              @click="goodsChoiceDialog = true"
          >重选商品名称</span
          >
        </div>
      </el-form-item>
      <el-form-item label="商品名称：">
        <el-input v-model="whmInfo.cnName" disabled></el-input>
      </el-form-item>
      <el-form-item label="商品数量：">
        <el-input
            :disabled="skuList.length == 0"
            v-model="whmInfo.showNum"
            @blur="setNumFotList()"
            type="number"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="step-title mt60">配置库品及批次</div>
    <ws-page-table :data="skuList" ref="pageTable" :pageable="false">
      <el-table-column
          prop="skuCode"
          label="库品编号"
          :show-overflow-tooltip="true"
          width="360"
      ></el-table-column>
      <el-table-column
          prop="showNum"
          label="库品数量"
          width="360"
      ></el-table-column>
      <el-table-column label="库品批次">
        <template slot-scope="scope">
          <div
              class="select-btn"
              v-if="!scope.row.batchCode"
              @click="openBatchDialog(scope.row, scope.$index)"
          >
            请选择批次
          </div>
          <div v-else>
            <span>{{ scope.row.batchCode }}</span>
            <span
                class="tips-btn"
                style="dispaly: inline-block; margin-left: 15px"
                @click="openBatchDialog(scope.row, scope.$index)"
            >重选商品批次</span
            >
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">取消</el-button>
      <el-button type="primary" @click="toAdd">保存</el-button>
    </div>

    <!--商品选择-->
    <el-dialog :visible.sync="goodsChoiceDialog" width="50%" class="table-wrap">
      <el-form label-width="160px" class="input-txt-btn">
        <el-input v-model="searchInfo.cnName"></el-input>
        <el-button type="primary" @click="loadTableData">搜索</el-button>
      </el-form>
      <ws-page-table
          :data="tableData"
          ref="goodsChoiceTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :selection="'single'"
      >
        <el-table-column
            prop="goodsCode"
            label="商品编号"
            :show-overflow-tooltip="true"
            width="220"
        ></el-table-column>
        <el-table-column
            prop="cnName"
            label="商品名称"
            :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <el-button type="primary" @click="handleCurrentChange">确定</el-button>
        <el-button @click="goodsChoiceDialog = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="batchDialog" width="560px" class="table-wrap">
      <div class="goods-dialog-head">
        <div class="v1">库品：{{ curSku.skuCode }}</div>
      </div>

      <div class="goods-dialog-cont">
        <ws-page-table
            :data="curSku.batchList"
            ref="batchTable"
            :selection="'single'"
        >
          <el-table-column
              prop="batchCode"
              label="批次"
              :show-overflow-tooltip="true"
          ></el-table-column>
        </ws-page-table>
      </div>

      <div class="btn-wrap">
        <el-button type="primary" @click="batchChange">确认保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import draggableUpload from "COMPS/base/draggableUpload";
import PimGoodsService from "API/pim/PimGoodsService";

export default {
  name: "invGroupEdit",
  data() {
    return {
      whmInfo: {},
      goodsChoiceDialog: false, //商品编码弹窗开关
      searchInfo: {
        cnName: "",
      },
      skuList: [],
      batchDialog: false,
      curIdx: 0,
      curSku: {}
    };
  },
  mixins: [pageTableMixin],
  components: {
    draggableUpload,
  },
  mounted() {},
  methods: {
    // 查询商品列表
    loadTableData() {

    },
    handleCurrentChange() {
      //console.log("handleCurrentChange")
      let selected = this.$refs.goodsChoiceTable.getSelectedData();
      if (selected && selected[0]) {
        this.whmInfo.cnName = selected[0].cnName;
        this.whmInfo.goodsCode = selected[0].goodsCode;
        this.whmInfo.goodsBarCode = selected[0].goodsBarCode;
        this.whmInfo.initPrice = selected[0].initPrice;
        this.whmInfo.goodsId = selected[0].goodsId;
        //this.whmInfo.showNum = 1;
        this.findToSkuCodeInBatchByGoodsId(selected[0].goodsId);
        this.goodsChoiceDialog = false;
      }
    },
    findToSkuCodeInBatchByGoodsId(id) {
      this.skuList = [];
      PimGoodsService.findToSkuCodeInBatchByGoodsId(id).then((data) => {
        let dataList = data;
        for (let item of dataList) {
          let skuItem = {
            skuCode: item.skuCode,
            skuNum: item.needNum,
            showNum: item.needNum,
            batchList: item.batches
          };
          this.skuList.push(skuItem)
        }
      });
    },
    openBatchDialog(row, idx) {//选择批次
      this.batchDialog = true;
      this.curIdx = idx;
      this.curSku = row;
    },
    batchChange() {
      let selected = this.$refs.batchTable.getSelectedData();
      if (selected && selected[0]) {
        this.$set(this.skuList[this.curIdx], "batchCode", selected[0].batchCode);
        this.batchDialog = false;
      }
    },
    setNumFotList() {
      //console.log(this.whmInfo.showNum)
      if (this.whmInfo.showNum <= 0 || !this.whmInfo.showNum) {
        this.whmInfo.showNum = ""
        return this.$message({
          message: "请添加商品数量",
          type: "warning",
        });
      }
      for (let i in this.skuList) {
        this.$set(this.skuList[i], "showNum", this.skuList[i].skuNum * this.whmInfo.showNum);
      }
    },
    backPre() {
      //返回上一页
      this.$router.push("/som/logisticsAdd");
    },
    toAdd() {
      if (!this.whmInfo.showNum || this.whmInfo.showNum == "") {
        return this.$message({
          message: "请添加商品数量",
          type: "warning",
        });
      }
      if(!this.skuList){
        return
      }
      for (let i in this.skuList) {
        if (!this.skuList[i].batchCode || this.skuList[i].batchCode == "") {
          return this.$message({
            message: `请选择${this.skuList[i].skuCode}对应的批次`,
            type: "warning",
          });
        }
      }
      let newBat = "";
      for (let i in this.skuList) {
        newBat += this.skuList[i].skuCode + ":" + this.skuList[i].batchCode + "*" + this.skuList[i].skuNum + "+";
      }
      newBat = newBat.substr(0, newBat.length - 1);

      let params = {
        goodsId: this.whmInfo.goodsId,
        goodsCode: this.whmInfo.goodsCode,
        cnName: this.whmInfo.cnName,
        skuNum: this.whmInfo.skuNum,
        showNum: this.whmInfo.showNum,
        initPrice: this.whmInfo.initPrice,
        goodsBarCode: this.whmInfo.goodsBarCode,
        skuBatchNumData: newBat,
      };

      let tableList = [];
      let oldTableList = this.$store.state.skuInfo;
      if (oldTableList && oldTableList.length > 0) {
        //如果state里面有值 那么先把值读出来 然后再把新的params push进去  如果选择相同的商品和相同的批次 那么把数量相加
        for (let i in oldTableList) {
          if (oldTableList[i].goodsCode == params.goodsCode
              && oldTableList[i].skuBatchNumData == params.skuBatchNumData) {
            //如果该库品已存在
            oldTableList[i].showNum = (oldTableList[i].showNum - 0) + (params.showNum - 0);
            tableList = oldTableList;
          }else{
            //没有相同的 等于新加了一条
            tableList = oldTableList.concat(params);
          }
        }
      } else {
        tableList.push(params);
      }
      this.$store.commit("saveSkuAddInfo", tableList);
      this.$router.push("/som/logisticsAdd");
    },
  },
};
</script>

<style lang="scss">
.logistics-add-goods {
  padding: 20px 0 60px 0;
  background: #fff;
}

.logistics-add-goods .el-input--small .el-input__inner {
  width: 150px;
}

.logistics-add-goods .input-txt-btn .el-input--small {
  width: 160px;
}

.logistics-add-goods .step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
  color: #333;
}

.logistics-add-goods .select-btn {
  width: 150px;
  text-align: center;
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}

.logistics-add-goods .steps-btn-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.logistics-add-goods .search-result {
  height: 360px;
  overflow-y: auto;
}

.logistics-add-goods .tips-btn {
  cursor: pointer;
  color: #1b73e8;
}

.logistics-add-goods .input-txt-btn {
  margin-bottom: 20px;
  display: flex;
}

.logistics-add-goods .input-txt-btn .el-input {
  width: 160px;
}

.logistics-add-goods .table-wrap .el-dialog__header {
  border-bottom: 0;
}

.logistics-add-goods .table-wrap .el-table .cell {
  line-height: 42px;
}

.logistics-add-goods .input-txt-btn .el-input--small .el-input__inner {
  width: 150px;
}

.logistics-add-goods .is-in-pagination .el-input__inner {
  width: 40px;
}

.logistics-add-goods .btn-blue-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.logistics-add-goods .mt60 {
  margin-top: 60px;
}

.logistics-add-goods .el-table--enable-row-hover .el-table__body tr:active > td {
  background-color: #e8f1fd;
}

.logistics-add-goods .goods-dialog-head {
  display: flex;
  align-items: center;
  height: 60px;
}

.logistics-add-goods .el-dialog__header {
  padding: 0;
  border-bottom: 0;
}

.logistics-add-goods .goods-dialog-head .v1 {
  font-size: 18px;
}

.logistics-add-goods .goods-dialog-cont {
  height: 360px;
  overflow-y: scroll;
}

.logistics-add-goods .table-wrap .el-table .cell {
  line-height: 42px;
}

.logistics-add-goods .btn-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.logistics-add-goods .icon-operation a {
  margin-right: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>