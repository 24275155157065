export default {
    keyupEvent4PositiveInteger(e, min, max) {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        if (min) {
            if (e.target.value < min) {
                e.target.value = min
            }
        }
        if (max) {
            if (e.target.value > max) {
                e.target.value = max
            }
        }
        if ((/^0+[0-9]+/g.test(e.target.value))) {
            e.target.value = e.target.value.replace(/0+/g, '');
        }
    },
    //输入完成后的验证，输入中不能用这个
    checkDecimal(e, min, max) {
        //整数或0
        /*let reg = /^([0]|([1-9][0-9]*)|(([0]\.\d{1,}|[1-9][0-9]*\.\d{1,})))$/
        if (!reg.test(e.target.value)) {
            e.target.value = ''
            return
        }*/
        let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
        if (!reg.test(e.target.value)) {
            e.target.value = ''
            return
        }
    },
    keyupEvent4PositiveDecimal(e, min, max) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')
        if (min) {
            if (e.target.value < min) {
                e.target.value = min
            }
        }
        if (max) {
            if (e.target.value > max) {
                e.target.value = max
            }
        }
        if ((/^0+[0-9]+/g.test(e.target.value))) {
            e.target.value = e.target.value.replace(/0+/g, '');
        }
    }
}
