export default {
  areaList: [],
  menus: [],
  productAddInfo: {}, // 新增商品基本信息
  saveSkuAddInfo: [], //保存库品基本信息
  setCount: 0, //消息通知数量
  productAttrList: [], // 新增商品属性列表
  productImgs: [], // 新增商品图片
  productRelateInfo: {}, //商品关联库品信息
  // 实体商品
  physicalGoods: {
    relatedProducts: [], // 关联商品
    setting: {}, // 设置
  },
  // 虚拟商品
  virtualGoods: {
    relatedProducts: [], // 关联商品
    goodsInfo: {}, // 商品基本信息
    setting: {}, // 设置
  },
  pageNo: 1,
  specifyBackUrl: "",
  ProductStepsData: {},
};
