<template>
  <div class="inv-batch">
    <div class="step-title">库品信息</div>

    <el-form :inline="true" class="demo-form-inline1 pt20">
      <el-row>
        <el-form-item label="库品编码:">
          <span v-if="skuInfo">{{ skuInfo.skuCode }}</span>
        </el-form-item>
        <el-form-item label="库品名称:">
          <span v-if="skuInfo">{{ skuInfo.skuName }}</span>
        </el-form-item>
        <el-form-item label="库存预警:">
          <span v-if="skuInfo">{{ skuInfo.stock.warningNum }}</span>
        </el-form-item>
        <el-form-item label="库存售罄:">
          <span v-if="skuInfo">{{ skuInfo.stock.sellOutNum }}</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="总库存数量:">
          <span v-if="skuInfo">{{ skuInfo.stock.actualNum }}</span>
        </el-form-item>
        <el-form-item label="锁定库存数量:">
          <span v-if="skuInfo">{{ skuInfo.stock.lockNum }}</span>
        </el-form-item>
        <el-form-item label="可用库存数量:">
          <div v-if="skuInfo.stock.reservedNum!=0">
            <span v-if="skuInfo">{{ skuInfo.stock.availableNum }}</span>
            <el-popover placement="top-start" width="200" trigger="hover"
                        :content="'可用库存('+skuInfo.stock.availableNum+') = 总库存('+skuInfo.stock.actualNum+') - 锁定库存('+skuInfo.stock.lockNum+') - 预留库存('+skuInfo.stock.reservedNum+')'">
              <i style="color:rgba(245, 34, 45, 1);cursor: pointer;margin-left:5px" class="el-icon-warning"
                 slot="reference"></i>
            </el-popover>
          </div>
          <div v-else>
            <span v-if="skuInfo">{{ skuInfo.stock.availableNum }}</span>
          </div>
        </el-form-item>
        <el-form-item label="保质期:">
          <span v-if="skuInfo">{{ skuInfo.shelfLife }}&nbsp;天</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="关联商品：">
          <template slot-scope="scope">
            <div :style="'color: rgba(24, 144, 255, 1);cursor: pointer;'" @click="openAssociated(scope.row)">
              {{ skuInfo.associatedGoods }}
            </div>
          </template>
        </el-form-item>
      </el-row>
    </el-form>

    <el-form :inline="true" class="btn-wrap">
      <el-form-item>
        <div class="step-tit2">批次信息</div>
        <!--
        <el-button type="primary" @click="openPriority">指定优先出库</el-button>
        -->
      </el-form-item>
    </el-form>

    <el-form :inline="true" class="demo-form-inline" style="padding-left:16px">
      <el-form-item label="库品批次:">
        <el-input v-model="searchInfo.purchaseBatchCode"/>
      </el-form-item>
      <el-form-item label="单据编号:">
        <el-input v-model="searchInfo.billCode"/>
      </el-form-item>
      <el-form-item label="入库时间:">
        <el-date-picker
            v-model="timeArr"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <!--
    <div class="show-cur">
      <div class="v1">当前指定：</div>
      <div class="v2">{{sortPur}}</div>
    </div>
    -->

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column
          prop="purchaseBatchCode"
          label="库品批次"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <!--
      <el-table-column prop="actualNum" label="批次总数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="lockNum" label="批次锁定数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="availableNum" label="批次可用数量" :show-overflow-tooltip="true"></el-table-column>
      -->
      <el-table-column prop="actualNum" label="批次总数量" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column prop="productionDate" label="生产日期" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="doomsday" label="保质期至" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="putInTime" width="300" label="最近一次入库" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
                href="javascript: void(0);"
                title="调整"
                @click="showRemoveBatchDialog(scope.row)"
            >
              <i class="el-icon-document-remove"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="批次数量扣减" :visible.sync="removeBatchDialogVisible" width="500px">
      <div class="remove-batch-dialog">
        <div>1、仅用于对批次数量进行扣减，增加批次数量请使用【新增入库】</div>
        <div>2、批次总数量不能小于0</div>
        <div>3、批次数量扣减会同步减少库品可用库存，可用库存数量不能小于0</div>
        <h3>库品可用库存：{{ removeBatchData.skuAvailableNum }}
          <span
              v-if="removeBatchData.removeNum>0">- {{ removeBatchData.removeNum }} = {{ new BigNumber(removeBatchData.skuAvailableNum).minus(removeBatchData.removeNum).toNumber() }}</span>
        </h3>
        <h3>批次总数量：{{ removeBatchData.batchActualNum }}
          <span
              v-if="removeBatchData.removeNum>0">- {{ removeBatchData.removeNum }} = {{ new BigNumber(removeBatchData.batchActualNum).minus(removeBatchData.removeNum).toNumber() }}</span>
        </h3>
        <el-input v-model="removeBatchData.removeNum" oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="removeBatchConfirm">确认</el-button>
      </span>
    </el-dialog>

    <relevanceGoodsPop :dialogTableVisible.sync="dialogTableVisible" @update:vasible="dialogClose"
                       ref="relevanceGoodsPop"></relevanceGoodsPop>
    <select-priority-popup ref="selectPriorityPopup" @submit="selectPriority"></select-priority-popup>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import invQuery from "API/whm/invQuery";
import selectPriorityPopup from "./selectPriorityPopup"
import tools from "COMJS/tools";
import relevanceGoodsPop from './relevanceGoodsPop.vue'
import BigNumber from "COMJS/bignumber.min"

export default {
  name: "invBatch",
  data() {
    return {
      dialogTableVisible: false,
      removeBatchDialogVisible: false,
      removeBatchData: {inventory: '', removeNum: 0},
      idx: 0,
      value: [],
      options: [],
      searchInfo: {
        purchaseBatchCode: "",//批次
        billCode: "",//单据编号
        openTime: "",
        endTime: "",
      },
      items: {
        purchaseBatchCode: {filter: "like"},
        billCode: {filter: "like"},
        openTime: {filter: "EQ"},
        endTime: {filter: "EQ"},
      },
      skuInfo: {
        stock: {}
      },
      sortPur: "",
      timeArr: [],
      //purchaseBatchCodeList:{},
      hasSelect: false
    };
  },
  computed:{
    BigNumber() {
      return BigNumber
    }
  },
  mixins: [pageTableMixin],
  components: {
    selectPriorityPopup,
    relevanceGoodsPop
  },
  mounted() {
    //this.loadTableData();
    // this.queryBySort()
  },
  methods: {
    dialogClose() {
      this.dialogTableVisible = false;
    },
    openPriority() {
      this.$refs.selectPriorityPopup.dialogVisible = true
    },
    loadTableData() {
      //console.log(this.searchInfo.putInTime)     
      this.searchInfo.openTime = this.timeArr && this.timeArr[0] ? tools.formatDate(new Date(this.timeArr[0]), "yyyy-MM-dd") : ""
      this.searchInfo.endTime = this.timeArr && this.timeArr[1] ? tools.formatDate(new Date(this.timeArr[1]), "yyyy-MM-dd") : ""
      this.searchInfo.skuId = this.$route.params.id;
      this.searchInfo.isNull = 0;
      this.$refs.pageTable.tableLoading = true;
      invQuery.querySkuAndInvPages(this.queryParams).then((data) => {
        this.skuInfo = data.sku;
        console.log(this.skuInfo);

        this.skuInfo.associatedGoods = this.$route.query.associatedGoods;
        if (!this.hasSelect) {
          this.sortPur = data.sortPur
        }
        //console.log(this.sortPur)
        data.list = data.invPage.list.map((e) => {
          return {
            id: e.skuId,
            inventoryId: e.inventoryId,
            purchaseBatchCode: e.purchaseBatchCode,
            actualNumDecimal: e.actualNum,
            actualNum: e.actualNum.toLocaleString(),
            lockNum: e.lockNum.toLocaleString(),
            availableNum: e.availableNum.toLocaleString(),
            productionDate: e.productionDate ? tools.formatDate(new Date(e.productionDate), "yyyy-MM-dd") : "",
            doomsday: tools.formatDate(new Date(e.doomsday), "yyyy-MM-dd"),
            putInTime: tools.formatDate(new Date(e.putInTime), "yyyy-MM-dd hh:mm:ss"),
          };
        });
        //console.log(data)
        data.total = data.invPage.total
        this.querySuccess(data);
      });
    },
    toDetail(row) {
      this.$router.push(`/whm/invBatchDetail/${row.inventoryId}`);
    },
    showRemoveBatchDialog(row) {
      this.removeBatchData.inventoryId = row.inventoryId;
      this.removeBatchData.batchActualNum = row.actualNumDecimal;
      this.removeBatchData.skuAvailableNum = this.skuInfo.stock.availableNum;
      this.removeBatchDialogVisible = true;
    },
    removeBatchConfirm() {
      //console.log(this.removeBatchData)
      if (this.removeBatchData.batchActualNum - this.removeBatchData.removeNum < 0
          || this.removeBatchData.skuAvailableNum - this.removeBatchData.removeNum < 0) {
        return this.$message.error("库品可用库存和批次总数量不能小于0");
      }
      let param={
        inventoryId:this.removeBatchData.inventoryId,
        openNum:this.removeBatchData.removeNum,
      }
      invQuery.removeInventory(param).then(data=>{
        this.$message.success("提交成功");
        this.removeBatchDialogVisible = false;
        this.loadTableData();
      })
    },
    selectPriority(selected) { //刷新列表
      //console.log(selected)
      this.sortPur = selected
      this.hasSelect = true
      this.loadTableData()
    },
    openAssociated() {
      let skuid = this.$route.params.id;
      //console.log(skuid);
      this.$refs.relevanceGoodsPop.loadTableData(skuid);
      this.dialogTableVisible = true;
    },
  },
};
</script>
<style>
.inv-batch .el-form-item__label {
  width: auto;
}

.inv-batch .demo-form-inline1 {
  padding: 0 0 20px 20px;
  background: #fff;
}

.inv-batch .demo-form-inline, .inv-batch .demo-form-inline.pt20 {
  padding: 20px 20px 20px 0;
  padding-top: 0;
  background: #fff;
}

.inv-batch .el-form--inline .el-form-item {
  margin-bottom: 0;
}

.inv-batch .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.inv-batch .ws-page-table .icon-operation a {
  margin-right: 20px;
}

.inv-batch .btn-wrap {
  padding: 20px 20px 30px 20px;
  border-top: 1px solid #E9ECEF;
  background: #fff;
}

.inv-batch .step-title {
  padding: 20px 0 30px 20px;
  font-size: 18px;
  color: #333;
  background: #fff;
}

.inv-batch .el-row {
  display: flex;
}

.inv-batch .el-form--inline .el-form-item {
  flex: 1
}

.inv-batch .step-tit2 {
  margin-right: 16px;
  display: inline-block;
  font-size: 18px;
}

.inv-batch .show-cur {
  padding: 33px 0 33px 20px;
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 14px;
  color: #333;
}

.inv-batch .search-result {
  height: 312px;
  overflow-y: auto;
}

.remove-batch-dialog > * {
  margin-top: 10px;
}

.remove-batch-dialog > div {
  color: red;
}

</style>