<template>
  <el-upload
    class="avatar-uploader"
    :headers="requestHeaders"
    :action="uploadUrl"
    list-type="picture-card"
    :show-file-list="false"
    :on-error="handleError"
    :on-success="handleSuccessVideo"
    :on-progress="uploadProcess"
    :before-upload="videoBeforeUpload"
    :on-remove="handleRemove"
  >
    <div class="video-wrapper" v-if="videoUrl.length > 1 && imgFlag == false">
      <video controls="controls" :src="videoUrl" class="avatar"></video>
      <div class="shadow" @click.stop="deleteVideo">
        <i class="el-icon-delete"></i>
      </div>
    </div>
    <span v-else-if="videoUrl.length < 1 && imgFlag == false">+添加视频</span>
    <el-progress
      v-if="imgFlag == true"
      type="circle"
      :percentage="percent"
      style="margin-top: 20px"
    ></el-progress>
  </el-upload>
</template>

<script>
export default {
  props: {
    url: String,
    uploadUrl: String,
    requestHeaders: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      imgFlag: false,
      videoUrl: "",
      percent: 0,
      dialogVideoUrl: "",
      dialogVisible: false,
      listObj: {}
    };
  },
  watch: {
    url() {
      this.videoUrl = this.url;
    }
  },
  methods: {
    handleRemove(file, fileList) {
      this.videoUrl = "";
    },
    handleSuccessVideo(response, file, fileList) {
      console.log(file);
      this.imgFlag = false;
      this.percent = 0;
      this.videoUrl = response;
      // file {
      // status: "success"
      // name: "QQ视频20200423090820.mp4"
      // size: 3116918
      // percentage: 100
      // uid: 1587606126067
      // raw: File
      // url: "blob:http://localhost:8085/3a08df6b-5747-4044-b369-81c76cff7edb"
      // response: "http://ciit-dev-test.oss-cn-qingdao.aliyuncs.com/pimGoods/el7jb67Zw5YjzN60.mp4"
      // }
      this.$emit("uploadSuccess", fileList);
    },
    handleError(err, file, fileList) {
      // 上传失败异常处理
      const error = JSON.parse(JSON.stringify(err));
      // console.log(err);
      // console.log(error);
      this.$message.error(error.status.toString());
      this.imgFlag = false;
      this.percent = 0;
    },
    videoBeforeUpload(file) {
      const _self = this;
      const isVideo =
        file.type === "video/mp4" ||
        file.type === "video/ogg" ||
        file.type === "video/flv" ||
        file.type === "video/avi" ||
        file.type === "video/wmv" ||
        file.type === "video/rmvb";
      const isLt30M = file.size / 1024 / 1024 < 30;
      if (!isVideo) {
        this.$message.warning("请上传正确格式的视频！");
        return false;
      } else {
        if (!isLt30M) {
          this.$message.warning("上传视频文件大小不能超过 30MB!");
          return false;
        }
      }
    },
    uploadProcess(event, file, fileList) {
      this.imgFlag = true;
      this.percent = Math.floor(event.percent);
    },
    deleteVideo() {
      this.$confirm("确定删除该视频?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$emit("deleteSuccess");
          this.videoUrl = "";
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-img-card {
  width: 80px;
  height: 80px;
  line-height: 82px;
  color: $themeColor;
  border: 1px solid #dcdee0;
  border-radius: 0px;
}
.video-wrapper {
  position: relative;
  width: 82px;
  height: 82px;
  &:hover {
    .shadow {
      display: block;
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
  .shadow {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    padding: 2px;
    cursor: pointer;
  }
}
/deep/ .el-progress {
  margin: 5px !important;
  .el-progress-circle {
    width: 70px !important;
    height: 70px !important;
  }
}
</style>