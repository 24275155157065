<template>
  <div class="order-detail box-shadow-unify">
    <div class="order-base-info" style="display: flex;justify-content: space-between">
      <div style="display: flex">
        <span>订单编号：{{ orderDetail.orderCode }}</span>
        <span>下单时间：{{ orderDetail.createTimeStr }}</span>
        <span>订单来源：{{ orderDetail.source }}</span>
        <span if="orderDetail.orderType == 'import'">平台单号：{{ orderDetail.thirdPartyOrderCode }}</span>
        <span v-if="orderDetail.achievement!=null && isDistributor">贡献成就值：{{ achievement }}</span>
        <span v-if="orderDetail.expectShippingTime!=null">发货时间：{{ orderDetail.expectShippingTime }}</span>
        <!--
                <span>运单号：{{mergeTransportInfo}}</span>
                -->
      </div>
    </div>
    <div class="order-progress">
      <div class="progress-info">
        <template v-if="orderDetail.statusStr == '待发货'">
          <div class="progress-title">等待商家发货</div>
          <div class="progress-desc">买家已付款，请尽快发货，否则买家有权申请退款。
            <div class="order-font-btn">
              <span @click="confirmToCancel" v-if="isEditing">取消订单</span>
            </div>
          </div>
          <!--
                    <el-button type="primary" size="mini" @click="isShowCompanyPopup = true">发货</el-button>
                    -->
        </template>
        <template
            v-if="orderDetail.statusStr == '待支付' || orderDetail.statusStr == '转账审核中' || orderDetail.statusStr == '审核拒绝'">
          <div class="progress-title" v-if="!isExpire">商品已拍下，等待买家付款</div>
          <div class="progress-title" v-if="isExpire">交易关闭</div>
          <div class="progress-desc" v-if="!isExpire">
            如买家未在
            <div class="count-down" style="width: 120px;text-align: center;">
              {{ toolsCountDown }}
            </div>
            内付款，订单将按照设置逾期自动关闭。
            <div class="order-font-btn">
              <span @click="confirmToCancel" v-if="isEditing">取消订单</span>
            </div>
          </div>
          <div class="progress-desc" v-if="isExpire">超时未付款</div>
        </template>
        <template v-if="orderDetail.statusStr == '待收货'">
          <div class="progress-title">等待买家收货</div>
          <div class="progress-desc">
            <div class="order-font-btn">
              <span @click="afterDialogVisible = true" v-if="isEditing">转售后处理</span>
              <div style="margin-top: 20px; color: #B94A48;">
                提示：待收货状态申请售后将为您取消整个订单，并按照商品类型收取取消费用。牛奶¥50/箱，奶豆¥10/4袋/盒，奶粉¥30/袋/盒
              </div>
            </div>
          </div>
        </template>
        <template v-if="orderDetail.statusStr == '已完成'">
          <div class="progress-title">交易已完成</div>
          <div class="order-font-btn">
            <span @click="accomplishDialogVisible = true" v-if="isEditing">转售后处理</span>
          </div>
        </template>
        <template v-if="orderDetail.statusStr == '已关闭'">
          <div class="progress-title">交易已关闭</div>
        </template>
        <template v-if="orderDetail.statusStr == '售后申请中'">
          <div class="progress-title">售后申请中</div>
        </template>
        <template
            v-if="orderDetail.statusStr == '待支付' || orderDetail.statusStr == '转账审核中' || orderDetail.statusStr == '审核拒绝'">
          <div class="remark" @click="isShowRemarksPopop = true" v-if="isEditing">备注</div>
        </template>
      </div>
      <div class="progress-step">
        <el-steps :active="stepActive" align-center finish-status="success"
                  v-if="!(orderDetail.statusStr == '待支付' && isExpire)">
          <el-step title="买家下单" :description="orderDetail.createTimeStr"></el-step>
          <el-step title="买家付款" :description="orderDetail.payTime"></el-step>
          <el-step title="商家发货" :description="orderDetail.transportTimeStr"></el-step>
          <el-step title="交易成功" :description="orderDetail.finishTimeStr "></el-step>
        </el-steps>
      </div>
    </div>
    <div class="order-tips"
         v-if="orderDetail.orderPattern=='common' && orderDetail.afterRemark!=null && (orderDetail.statusStr=='已完成' || orderDetail.statusStr=='已关闭' || orderDetail.statusStr=='待发货')">
      <div style="color: #FBC14E;">售后说明：</div>
      <div v-for="(item,index) in orderDetail.afterRemark" :key="index">
        <div style="line-height:30px; color:rgba(102, 102, 102, 1)">{{ item }}</div>
      </div>
    </div>
    <div>
      <table class="address-info">
        <td>
          <tr class="th">
            收件人信息
            <span
                v-if="orderDetail.statusStr == '待支付' || orderDetail.statusStr == '转账审核中' || orderDetail.statusStr == '待发货'"
                class="change-address" @click="$refs.changeOrderAddress.dialogVisible = true">修改</span>
          </tr>
          <tr>收货人：{{ orderDetail.recipientName }}</tr>
          <tr>联系电话：{{
              orderDetail.recipientPhoneNum
            }}{{ orderDetail.recipientPhoneNumTransfer ? ' (转:' + orderDetail.recipientPhoneNumTransfer + ')' : '' }}
          </tr>
          <tr>收货地址：{{ orderDetail.recipientFullAddress }}</tr>
        </td>
        <!--
                <td>
                    <tr class="th">配送方式</tr>
                    <tr>配送方式：快递</tr>
                </td>
                -->
        <td>
          <tr class="th">配送信息</tr>
          <tr v-for="(item, index) in orderDetail.orderPackageList" :key="index"
              v-html="translateTransport(item,index)"></tr>
        </td>
        <td>
          <tr class="th">付款信息</tr>
          <tr>实付金额：{{ orderDetail.totalAmount }}</tr>
          <tr>付款方式：{{ orderDetail.payWay || '未付款' }}</tr>
          <tr>付款时间：{{ orderDetail.payTime || '未付款' }}</tr>
        </td>
        <td>
          <!--
                    <tr class="th">买家信息</tr>
                    <tr>买家： {{orderDetail.recipientName}}</tr>
                    <tr>买家留言：{{orderDetail.remark || '无'}}</tr>
                    -->
        </td>
      </table>
    </div>
    <div class="goods-table">
      <ws-page-table :data="orderDetail.orderGoodsList" ref="pageTable" :pageable="false">
        <el-table-column label="商品">
          <template slot-scope="scope">
            <div class="table-img">
              <img :src="scope.row.goodsPictureUrl" alt/>
              <span>{{ scope.row.goodsName }}</span>
              <span>{{ scope.row.goodsCode }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="paymentPrice" label="单价"></el-table-column>
        <el-table-column prop="num" label="数量"></el-table-column>
        <el-table-column label="售后状态">
          <!-- <template slot-scope="scope">{{orderDetail.transportState == 1 ? '已发货' : '待发货'}}</template> -->
        </el-table-column>
        <el-table-column label="发货状态">
          <template slot-scope="scope">{{ orderDetail.transportState == 1 ? '已发货' : '待发货' }}</template>
        </el-table-column>
      </ws-page-table>
      <div class="goods-price-info-wrapper clearfix">
        <table class="goods-price-info">
          <tr>
            <td>商品总价：</td>
            <td>￥{{ orderDetail.totalAmount - orderDetail.transportAmount }}</td>
          </tr>
          <tr>
            <td>运费：</td>
            <td>￥{{ orderDetail.transportAmount }}</td>
          </tr>
          <tr>
            <td class="sum-price-text">实收金额：</td>
            <td class="sum-price">￥{{ orderDetail.totalAmount }}</td>
          </tr>
        </table>
      </div>
    </div>

    <el-dialog title="卖家备注" :visible.sync="isShowRemarksPopop" width="560px">
      <el-input style="margin-top: 15px;" type="textarea" :rows="4" placeholder="请输入备注" v-model="remark" maxlength="256"
                show-word-limit></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowRemarksPopop = false">取 消</el-button>
        <el-button type="primary" @click="changeRemark">确 定</el-button>
      </div>
    </el-dialog>
    <change-order-address @changeAddress="getOrderDetail()" ref="changeOrderAddress" :info="orderDetail">
    </change-order-address>
    <el-dialog title="转售后处理" :visible.sync="afterDialogVisible" width="300px">
      <el-select v-model="afterType" placeholder="请选择售后类型">
        <el-option :key="item.value" :label="item.label" :value="item.value"
                   v-for="item in reasonOfCancellationList"></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="afterDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderApplyAfter">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="转售后处理" :visible.sync="accomplishDialogVisible" width="600px">
      <el-row type="flex" align="middle" class="row-item">
        <el-col :span="3">售后原因：</el-col>
        <el-col :span="6">
          <el-select v-model="afterType" placeholder="请选择售后类型">
            <el-option :key="item.value" :label="item.label" :value="item.value"
                       v-for="item in accomplishList"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="3">售后拍照：</el-col>
        <el-col :span="21" style="color:rgba(255, 141, 26, 100)">请提供带快递单号的快递包裹照片，开箱内物商品照片，问题商品照片</el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="21" :offset="3">
          <draggable-upload :width="82" :height="82" :limit="3" :uploadUrl="uploadUrl()"
                            :requestHeaders="requestHeaders" v-model="thumbnailImgs"></draggable-upload>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="accomplishDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderApplyAfter('accomplish')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let moment = require("moment");
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import Objects from "COMJS/Objects";
import Enum from "ASSETS/js/enum";
import translate from "ASSETS/js/translate";
//import BimEnumService from "API/bim/BimEnumService";
import orderService from "API/som/orderService";
import orderOperate from "API/som/orderOperate";
import changeOrderAddress from "../order/changeOrderAddress";
//import countDown from "vue2-countdown";
import {applyAfterSale} from "@/api/som/checkService.js";

export default {
  name: "orderDetail",
  data() {
    return {
      isDistributor: true,
      orderDetail: {},
      stepActive: 1,
      cancelOrderDialogVisible: false, // 取消订单弹窗
      afterDialogVisible: false,
      isShowRemarksPopop: false,
      isShowCompanyPopup: false,
      accomplishDialogVisible: false,
      thumbnailImgs: [],
      cancelOrderReasons: [
        {
          label: "无法联系上买家",
          value: "无法联系上买家",
        },
        {
          label: "买家误拍或重拍了",
          value: "买家误拍或重拍了",
        },
        {
          label: "买家无诚意完成交易",
          value: "买家无诚意完成交易",
        },
      ],
      reasonOfCancellationList: [
        {
          label: "不想要了",
          value: "不想要了",
        },
        {
          label: "地址信息填写错误",
          value: "地址信息填写错误",
        },
        {
          label: "配送时间问题",
          value: "配送时间问题",
        },
        {
          label: "商品错选多选",
          value: "商品错选多选",
        },
      ],
      accomplishList: [
        {
          label: "商品破损、脏污",
          value: "商品破损、脏污",
        },
        {
          label: "少、错商品",
          value: "少、错商品",
        },
      ],
      cancelOrderReason: "",
      changeTotalAmount: "",
      remark: "",
      timeEnd: 0,
      toolsCountDown: '',
      isExpire: false,
      transportNo: "",
      isEditing: false,
      afterType: "",
      requestHeaders: {
        Authorization: userModule.token,
      },
    };
  },
  components: {
    changeOrderAddress,
    //countDown,
    draggableUpload,
  },
  computed: {
    mergeTransportInfo: function () {
      var info = "";
      if (this.orderDetail.orderPackageList) {
        for (let item of this.orderDetail.orderPackageList) {
          let transportNo = Objects.isEmpty(item.transportNo)
              ? "无"
              : item.transportNo;
          if (info === "") {
            info = transportNo + "(" + item.transportCompany + ")";
          } else {
            info = info + "," + transportNo + "(" + item.transportCompany + ")";
          }
        }
      }
      return info;
    },
    achievement: function () {
      return this.orderDetail.achievement.toFixed(2);
    },
  },
  mounted() {
    this.isDistributor = JSON.parse(userModule.PPUser).role.indexOf('distributor') > -1
    if (this.$route.path.includes("/som/orderEditD")) {
      this.isEditing = true;
    } else {
      this.isEditing = false;
    }
    this.getOrderDetail();
    //setInterval(this.timerToCountSample, 1000)
    tools.interval(this.timerToCountSample, 1000)
  },
  methods: {
    translateTransport(item, index) {
      let transportNo = Objects.isEmpty(item.transportNo)
          ? "无"
          : item.transportNo;
      let pack = "包裹" + (index + 1);
      return pack + "：" + transportNo + "（" + item.transportCompany + "）";
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    getOrderDetail() {
      orderService.getOrderDetail(this.$route.params.id).then((data) => {
        data.createTimeStr = data.createTime
            ? tools.formatDate(new Date(data.createTime), "yyyy-MM-dd hh:mm:ss")
            : "";
        data.payTime = data.payTime
            ? tools.formatDate(new Date(data.payTime), "yyyy-MM-dd hh:mm:ss")
            : "";
        data.transportTimeStr = data.transportTime
            ? tools.formatDate(
                new Date(data.transportTime),
                "yyyy-MM-dd hh:mm:ss"
            )
            : "";
        data.finishTimeStr = data.finishTime
            ? tools.formatDate(new Date(data.finishTime), "yyyy-MM-dd hh:mm:ss")
            : "";
        // data.source = translate.source(data.orderType);
        // data.payWay = translate.payWay(data.payChannel);
        data.source = Enum.orderType[data.orderType];
        data.payWay = Enum.payWay[data.payChannel];
        data.statusStr = translate.orderState(data);
        if (data.afterState == "1") {
          data.statusStr = "售后申请中";
        }
        this.stepActive = this.computedStepActive(data.statusStr);
        if (data.payState == "0") {
          let nowTime = new Date().getTime();
          //let now = Number(new Date());
          if (data.orderType == "import") {
            //this.timeEnd = data.createTime + data.createTime + 24 * 60 * 60 * 1000 - now;
            this.timeEnd = data.createTime + 24 * 60 * 60 * 1000
          } else if (data.orderType == "wmp") {
            //this.timeEnd = data.createTime + data.createTime + 30 * 60 * 1000 - now;
            this.timeEnd = data.createTime + 30 * 60 * 1000
          } else {
            // TODO 非导入和小程序零售单时暂未确定如何处理，先默认30分钟
            //this.timeEnd = data.createTime + data.createTime + 30 * 60 * 1000 - now;
            this.timeEnd = data.createTime + 30 * 60 * 1000
          }
          //console.log("data.createTime=" + data.createTime);
          //console.log(data.orderType, "this.timeEnd=" + this.timeEnd);
          //console.log("hor=" + (this.timeEnd - data.createTime) / (60 * 60 * 1000));
          if (nowTime > this.timeEnd) {
            this.isExpire = true;
          }
        }
        if (data.afterRemark && data.afterRemark.length > 0) {
          data.afterRemark = data.afterRemark.split("；");
        }
        //console.log(data);
        data.expectShippingTime = moment(data.expectShippingTime).format(
            "YYYY-MM-DD HH:mm:ss"
        );
        this.orderDetail = data;
      });
    },
    rowClass() {
      //表头的字体样式
      return "text-align: center;height: 20px;font-size: 10px;height: 20px";
    },
    headClass() {
      return "text-align: center; font-size: 10px;height: 20px";
    },
    computedStepActive(statusStr) {
      switch (statusStr) {
        case "待支付":
          return 1;
        case "待发货":
          return 2;
        case "待收货":
          return 3;
        case "已完成":
          return 4;
      }
    },
    cancelOrder() {
      let params = {
        orderId: this.orderDetail.orderId,
        // remark: this.afterType,
      };
      orderOperate.cancelOrder(params).then(() => {
        this.getOrderDetail();
      });
    },
    changeRemark() {
      if (this.remark === "") {
        return this.$message({
          message: "请填写备注",
          type: "warning",
        });
      }
      let params = {
        orderId: this.orderDetail.orderId,
        changeRemark: this.remark,
      };
      orderOperate.orderChangeRemark(params).then(() => {
        this.isShowRemarksPopop = false;
        this.getOrderDetail();
      });
    },
    async orderApplyAfter(e) {
      let imgs = [];
      this.thumbnailImgs.forEach((e) => {
        imgs.push(e.response);
      });

      let params = {
        orderId: this.orderDetail.orderId,
        reason: this.afterType,
        imgs: imgs ? imgs.join(",") : "",
      };
      const data = await applyAfterSale(params);
      if (data) {
        if (e == "accomplish") {
          this.accomplishDialogVisible = false;
        } else {
          this.afterDialogVisible = false;
        }
        this.$alert("订单已转至售后处理，请到联系客服处理。", "售后", {
          confirmButtonText: "确定",
          callback: (action) => {
          },
        });
        this.getOrderDetail();
      }
    },
    timerToCountSample() {
      if (this.orderDetail && this.orderDetail.createTime) {
        let nowTime = new Date().getTime()
        if(nowTime<this.timeEnd){
          let timer = tools.getCostTimeString(new Date(), new Date(this.timeEnd), 3)
          this.toolsCountDown = timer;
          this.isExpire = false;
        }else{
          this.isExpire = true;
        }
      } else {
        this.toolsCountDown = '';
      }
    },
    confirmToCancel() {
      this.$confirm("确认取消订单?")
          .then((_) => {
            this.cancelOrder();
          })
          .catch((_) => {
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/common/tool.scss";

.order-detail {
  padding: 20px;

  .order-base-info {
    margin-bottom: 20px;

    span {
      margin-right: 60px;
      font-size: 14px;
    }
  }

  .order-progress {
    display: flex;
    align-items: center;
    border: 1px solid $borderColor;
    font-size: 14px;

    .progress-info {
      flex-shrink: 1;
      flex-grow: 0;
      min-width: 450px;
      padding: 20px;
      border-right: 1px solid $borderColor;

      .progress-title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
      }

      .progress-desc {
        margin-bottom: 20px;
        color: #999;
      }

      .el-button {
        margin-bottom: 20px;
      }

      .order-font-btn {
        margin-top: 20px;
        color: $themeColor;

        > span {
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .count-down {
        display: inline-block;
        margin-right: 2px;
        color: #fc4d00;
      }

      .remark {
        cursor: pointer;
        color: $themeColor;
      }
    }

    .progress-step {
      flex: 1;
      // flex-basis: auto;
    }
  }

  .order-tips {
    display: flex;
    padding: 25px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid $borderColor;
    border-top: none;
    line-height: 20px;

    p {
      color: #666;
    }
  }

  .address-info {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    padding: 10px 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    background-color: #f6f9fc;
    color: #666;
    // tr {
    //     @include Mult-line(1);
    // }
    .th {
      font-weight: bold;
      color: #333;
    }

    td {
      flex-basis: auto;
    }

    .change-address {
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      color: $themeColor;
    }
  }

  .goods-table {
    background-color: #fff;
    padding-bottom: 10px;

    .goods-price-info-wrapper {
      text-align: right;
      margin-right: 30px;
      margin-top: 20px;

      .goods-price-info {
        float: right;
        font-size: 14px;
        line-height: 30px;
        color: #666;

        .sum-price-text {
          font-size: 16px;
          color: #333;
        }

        .sum-price {
          font-size: 16px;
          color: #e60000;
        }

        td {
          width: 120px;
        }
      }
    }

    .ws-page-table {
      box-shadow: none;
    }
  }
}

.afterSale {
  width: 200px;
}

.row-item {
  padding: 2% 0;
}
</style>