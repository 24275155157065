<template>
  <div class="batch-list">
    <div class="table-tips">
      <div class="tips-title">运单导入</div>
      <div class="tips-content">
        <div>
          <p style="font-weight: bold">运单导入规则说明</p>
          <p>
            <span>1.</span>
            <span
              >导入规范：按<a
                href="javascript: void(0);"
                style="
                  cursor: pointer;
                  color: #1ea0ff;
                  text-decoration: underline;
                "
                @click="exportShipTemplate"
                >发货模版</a
              >
              要求填写发货信息，点击【正常导入】操作导入运单，导入成功则变更订单状态为‘待收货’。注意运单号不要写成E+22科学计数法；</span
            >
          </p>
          <p>
            <span>2.</span>
            <span
              >错误修改：导入后点击【查看失败原因】，下载错误运单，对症修改，成功率更高；</span
            >
          </p>
          <p>
            <span>3.</span>
            <span
              >强制导入：当失败原因为‘地址已修改’，请将相关运单在快递公司完成地址修改后，使用【强制导入】重新导入运单。如为其他失败原因，请继续使用【正常导入】。</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-upload
            style="display: inline-block; margin-right: 28px"
            ref="upload"
            :headers="requestHeaders"
            :multiple="false"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :action="uploadUrl()"
            :limit="1"
            :on-success="onSuccess"
            :on-remove="onRemove"
          >
            <el-button type="primary" @click="whichOne(1)">正常导入</el-button>
            <el-button type="primary" @click="whichOne(2)">强制导入</el-button>
          </el-upload>
          <div class="form-item">
            <span class="form-label">操作时间：</span>
            <el-date-picker
              v-model="searchInfo.createTime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <el-button type="primary" @click="searchData" class="ml10">搜索</el-button>
          <!--<el-button type="primary" size="mini" style="margin-left: 30px" @click="showAllData">展示所有数据</el-button>-->
        </div>
      </div>
    </div>
    <div class="batch-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs v-model="tabsActiveName" type="card">
          <el-tab-pane label="表格导入" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :isShadow="false"
      >
        <el-table-column prop="createTime" label="导入时间"></el-table-column>
        <el-table-column prop="progress" label="进度"></el-table-column>
        <el-table-column prop="totalRow" label="总条数"></el-table-column>
        <el-table-column prop="checkPassRow" label="成功条数"></el-table-column>
        <el-table-column prop="failRow" label="失败条数"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="operation" v-if="Number(scope.row.failRow) > 0" @click="downloadExcel(scope.row)"
              >查看失败原因</span
            >
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService";
import orderShipService from "API/som/orderShipService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import userModule from "@/module/userModule";
import WhmList from "API/whm/WhmList";
// import SearchConfig from "COMJS/query/SearchConfig";

export default {
  name: "orderList",
  data() {
    return {
      searchInfo: {
        createTime: "",
      },
      items: {
        createTime: { filter: "between", dataType: "date" },
      },
      tabsActiveName: "first",
      fileTemplateName: "",
      requestHeaders: {
        Authorization: userModule.token,
      },
      skipCheck: false,
    };
  },
  mixins: [pageTableMixin],
  methods: {
    whichOne(num) {
      this.skipCheck = num == 1 ? false : true;
      //console.log(this.skipCheck)
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      if(this.searchInfo.createTime === null){
        this.searchInfo.createTime = ""
      }
      WhmList.queryImportTransportList(this.pageInfo, this.searchConfig).then(
        (data) => {
          data.list = data.list.map((e) => {
            return {
              createTime: tools.formatDate(
                new Date(e.createTime),
                "yyyy-MM-dd hh:mm:ss"
              ),
              progress: e.totalRow > 0 ? (((e.checkPassRow+e.failRow) / e.totalRow) * 100).toFixed(2)  + "%" : "0.00%",
              fileUrl: e.fileUrl,
              totalRow: e.totalRow,
              checkPassRow: e.checkPassRow,
              failRow: e.failRow,
            };
          });
          this.querySuccess(data);
        }
      );
    },
    showAllData() {
      this.searchInfo.beginDate = "";
      this.searchInfo.endDate = "";
      this.loadTableData();
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("whm_excel");
      return url;
    },
    /*上传文件之前的钩子*/
    beforeUpload(file) {
      let isLtM = file.size / 1024 / 1024 < 1;
      if (!isLtM) {
        this.$message.error("暂只支持上传大小为1024k以下表格！");
        return false;
      }

      if (file.type && file.type.length > 0) {
        //包含type
        let isXlsm =
          file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
          file.type === "application/vnd.ms-excel.sheet.macroenabled.12"; //xlsm
        let isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; //xlsx
        let isXls = file.type === "application/vnd.ms-excel"; //xls

        if (!(isXlsm || isXlsx || isXls)) {
          this.$message.warning(
            "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
          );
        }
        return isXlsm || isXlsx || isXls;
      } else {
        if (file.name && file.name.length > 5) {
          //文件名存在
          if (
            file.name.substring(file.name.length - 5, file.name.length) ===
              ".xlsx" ||
            file.name.substring(file.name.length - 5, file.name.length) ===
              ".xlsm" ||
            file.name.substring(file.name.length - 4, file.name.length) ===
              ".xls"
          ) {
            return true;
          } else {
            this.$message.warning(
              "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
            );
            return false;
          }
        } else if (
          file.name &&
          file.name.length > 4 &&
          file.name.substring(file.name.length - 4, file.name.length) === ".xls"
        ) {
          return true;
        } else {
          this.$message.warning(
            "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
          );
          return false;
        }
      }
    },
    onSuccess(response, file, fileList) {
      let splitList = file["response"].split("/");
      this.fileTemplateName = file["response"].split("/")[splitList.length - 1];
      const that = this;
      //console.log(this.fileTemplateName)
      let params = {
        fileUrl: this.fileTemplateName,
      };
      if(this.skipCheck){
        params.skipCheck = true
      }
      WhmList.importTransportCode(params)
        .then((data) => {
          //console.log(data);
          this.$message.warning("等待消息!");
          that.loadTableData();
          that.clearUploadForm();
        })
        .catch(() => {
          that.clearUploadForm();
          that.loadTableData();
          that.clearUploadForm();
        });
    },
    onRemove() {},
    // 清除upload
    clearUploadForm() {
      this.$refs.upload.clearFiles();
      this.fileTemplateName = null;
    },

    downloadExcel(row) {
      //console.log(row)
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let url = row.fileUrl;
      console.log(url);
      let array = new Array();
      array = url.split("/"); //以,分隔
      //console.log(array);
      let len = array.length;
      if (len == 2) {
        var module = array[len - 2];
        var fileName = array[len - 1];
      }
      console.log(module);
      console.log(fileName);
      console.log(module + fileName);
      IoProgressService.download(module, fileName, false);
      loading.close();
    },

    // 下载模板
    exportShipTemplate() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      orderShipService
        .exportShipTemplate()
        .then((data) => {
          let fileName = data.fileName;
          let module = data.module;
          IoProgressService.download(module, fileName, false);
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-list {
  .batch-table {
    .tabs-wrapper {
      padding: 10px 20px;
      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;
        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }
    background-color: #fff;
  }
}
.el-upload {
  border: none !important;
}
.ml10{ margin-left: 10px;}
</style>