<template>
  <div>
    <div>
      <div slot="toolbar">
        <el-button type="primary" @click.navite="addNode">新增</el-button>
        <el-button @click.navite="removeNode">删除</el-button>
      </div>
      <ws-page-table
          :data="tableData"
          ref="auditNodeConfigurationPageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          selection="single"
      >
        <el-table-column prop="auditVersionCode" label="审核版本编码" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="auditNodeName" label="审核节点名称" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="previousNodeName" label="上级审核节点名称" min-width="90"   show-overflow-tooltip></el-table-column>
         <el-table-column prop="nextNodeName" label="下级审核节点名称" min-width="90" show-overflow-tooltip></el-table-column>-->
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" class="ws-iconbtn" @click.native="editNode(scope.row)"><i
                class="fa fa-edit"></i></el-button>
          </template>
        </el-table-column>
      </ws-page-table>

    </div>

    <audit-node-configuration-edit :enum-map="enumMap" ref="nodeEdit"
                                   @editCompleted="loadTableData"></audit-node-configuration-edit>
  </div>
</template>

<script>
import auditNodeConfigurationEdit from './components/auditNodeConfigurationEdit';
import pageTableMixin from "@/mixin/pageTableMixin.js";
import auditFlowService from "API/ba/auditFlowService"
import Objects from "COMJS/Objects";

export default {
  name: "auditNodeConfigurationList",
  components: {
    auditNodeConfigurationEdit: auditNodeConfigurationEdit
  },
  props: {
    versionInfo: {
      type: Object,
      default: ''
    }
  },
  mixins: [pageTableMixin],
  data() {
    return {
      searchInfo: {auditVersionConfigurationId: this.versionInfo.auditVersionConfigurationId, auditNodeName: '', state: '1'},
      items: {
        auditNodeCode: {filter: "like"},
        auditVersionConfigurationId: {filter: "EQ"},
        state: {filter: "EQ"}
      },
      sorts: {
        createTime: {order: "asc"}
      },
      enumMap: {},
      tempOnePage: {},
      tempOneSearch: {},
      tempTowPage: {},
      tempTowSearch: {},
      tempThreePage: {},
      tempThreeSearch: {},
    };
  },
  mounted() {
    this.initPage();
  },
  created() {
    let that = this;

    /*eventBus.$on('searchInfoAuditConfigurationStorage', (onePageInfo, oneSearchForm, towPageInfo, towSearchForm, threePageInfo, threeSearchForm) => {
        Objects.deepCopy(that.tempOnePage, onePageInfo);
        Objects.deepCopy(that.tempOneSearch, oneSearchForm);

        Objects.deepCopy(that.tempTowPage, towPageInfo);
        Objects.deepCopy(that.tempTowSearch, towSearchForm);

        Objects.deepCopy(that.tempThreePage, threePageInfo);
        Objects.deepCopy(that.tempThreeSearch, threeSearchForm);

        if (Objects.isNotEmpty(threePageInfo)) {
            Objects.deepCopy(this.pageInfo, threePageInfo);
        }

        if (Objects.isNotEmpty(threeSearchForm)) {
            Objects.deepCopy(this.searchForm, threeSearchForm);
        }
    });*/
  },
  destroyed() {
    let that = this;
    //eventBus.$emit('searchInfoAuditConfigurationStorage', that.tempOnePage, that.tempOneSearch, that.tempTowPage, that.tempTowSearch, that.pageInfo, that.searchForm);
  },
  methods: {

    /**初始化页面*/
    initPage() {
      /*let types = "AGENCY_LEVEL,AGENCY_STATE,OPERATION_TYPE,AUDIT_STATE,AUDIT_ROLE_TYPE,YES_OR_NO";
      bimEnumService.getEnumByTypes(types).then((data) => {
          this.enumMap = data;
          this.loadTableData();
      }).catch((data) => console.log(data));*/
    },
    /**读取数据*/
    loadTableData() {
      auditFlowService.findAuditNodeConfigurationPage(this.pageInfo, this.searchConfig).then(data => {
        this.querySuccess(data);
      })
    },
    /**删除*/
    removeNode() {
      let that = this;
      let rows = this.$refs.auditNodeConfigurationPageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            that.$refs.auditNodeConfigurationPageTable.tableLoading = true;
            let ids = '';
            rows.forEach(o => {
              if (Objects.isEmpty(ids)) {
                ids = o.auditNodeConfigurationId;
              } else {
                ids += ',' + o.auditNodeConfigurationId;
              }
            });
            //维护前后节点数据
        auditFlowService.removeAuditNodeConfigurationInfoByIds(ids).then(() => {
              that.loadTableData();
              that.$refs.auditNodeConfigurationPageTable.tableLoading = false;
            }).catch(err => {
              that.$refs.auditNodeConfigurationPageTable.tableLoading = false;
            })
          }
      )
    },
    /**新增*/
    addNode() {
      //列表无数据时,为首次新增节点;存在数据时,则需选中一条记录,作为插入节点的前置节点.
      let nodeInfo = {};
      if (Objects.isEmpty(this.tableData)) {
        nodeInfo = this.versionInfo;
      } else {
        let rows = this.$refs.auditNodeConfigurationPageTable.getSelectedData();
        if (rows.length < 1) {
          this.$alert('请选中一条记录作为前置节点！', '系统提示');
          return;
        } else {
          nodeInfo.auditVersionConfigurationId = rows[0].auditVersionConfigurationId;
          nodeInfo.auditConfigurationCode = rows[0].auditConfigurationCode;
          nodeInfo.auditConfigurationName = rows[0].auditConfigurationName;
          nodeInfo.auditVersionCode = rows[0].auditVersionCode;
          nodeInfo.previousNodeId = rows[0].auditNodeConfigurationId;
          nodeInfo.previousNodeName = rows[0].auditNodeName;
          nodeInfo.nextNodeId = rows[0].nextNodeId;
          nodeInfo.nextNodeName = rows[0].nextNodeName;

        }
      }
      this.$refs.nodeEdit.display(nodeInfo, 'ADD');
    },
    /**编辑*/
    editNode(row) {
      //console.log(row)
      this.$refs.nodeEdit.display(row, 'EDIT');
    }
  }
}
</script>

<style scoped>

</style>
