<template>
  <div class="delivery-list"> 
    <el-form :inline="true" class="rec-inline">
      <div class="logistics-tit">收货信息</div>
      <div class="log-head-list">
        <div class="log-list-lef">
          <div class="tab-label">收货人：</div>
          <div class="tab-inp">
            <el-input class="inp118" v-model="whmInfo.recipientName"></el-input>
          </div>
        </div>
        <div class="log-list-rig">
          <div class="tab-label">收货方电话：</div>
          <div class="tab-inp">
            <el-input type="number" class="inp118" v-model="whmInfo.recipientPhoneNum" @blur="regPhone"></el-input>
          </div>
        </div>
      </div>
      <div class="log-head-list">
        <div class="tab-label">收货方地址：</div>
        <div class="tab-inp">
          <el-input class="inp340" v-model="whmInfo.recipientFullAddress"></el-input>
        </div>
      </div>  
      <div class="logistics-tit mt30">物流信息</div>
      <div class="log-head-list">
        <div class="tab-label">送货方式：</div>
        <el-select v-model="whmInfo.deliveryMethod" placeholder="寄付/到付">
          <el-option
            v-for="item in deliveryType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>   
      </div>
      <div class="log-head-list">
        <div class="tab-label">运费付款：</div>
        <el-select v-model="whmInfo.transportationCostMethod" placeholder="到门/自提">
          <el-option
            v-for="item in transportationCostType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> 
      </div> 
    </el-form>    

    <div class="logistics-btn-wrap">
      <el-button type="primary" @click="showSkuCodeDialog">添加商品</el-button>   
    </div> 
    <ws-page-table
      :data="deliveryList"
      ref="pageTable"
      :pageable="true"
      :pageInfo="deliveryPageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column  prop="goodsName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="goodsCode" label="商品编码" :show-overflow-tooltip="true"></el-table-column>            
      <el-table-column  prop="availableNum" label="剩余数量" :show-overflow-tooltip="true"></el-table-column>      
      <el-table-column  prop="skuBatchNumData" label="库品批次" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column  prop="skuName" label="发货数量" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-input 
            v-model="scope.row.thisTimeNum"
            maxlength="11"
            type="number"            
            >
          </el-input>
        </template>  
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              slot="reference"
              href="javascript: void(0);"
              title="删除"
              @click="deleteRow(scope.$index)"
            >
              <i class="el-icon-delete-solid"></i>
            </a>
          </div>
        </template>   
      </el-table-column>          
    </ws-page-table>

    <el-form ref="deaInfoForm" class="step-form">
      <el-form-item label="下单备注：">
        <el-input v-model="whmInfo.logisticsNote"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createDelivery">确认发货</el-button> 
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="skuCodeDialog" class="table-wrap">
      <el-form label-width="160px" class="delivery-btn">
        <el-input v-model="searchInfo.cnName"></el-input>
        <el-button type="primary" @click="loadTableData">搜索</el-button>
      </el-form>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @current-change="handleCurrentChange"
        @pageChange="loadTableData"
        :selection="'single'"
      >
        <el-table-column
          prop="createTime"
          label="下单时间"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="goodsCode"
          label="商品编号"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuBatchNumData"
          label="库品批次"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="availableNum"
          label="剩余数量"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <el-button type="primary" @click="skucodeConfirm">确定</el-button>
        <el-button @click="closeSkucode">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";
import validator from "COMJS/validator";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],  
      deliveryList: [],
      deliveryMethod: "",
      deliveryType: [
        {
          value: "0",
          label: "寄付",
        },
        {
          value: "1",
          label: "到付",
        },
      ],
      transportationCostMethod: "",
      transportationCostType: [
        {
          value: "0",
          label: "到门",
        },
        {
          value: "1",
          label: "自提",
        },
      ],        
      skuCodeDialog: false,
      deliveryPageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      receiveList: {
        goodsName: "",
        goodsCode: "",
        availableNum: "",
        skuBatchNumData: "",
      },  
      whmInfo: {},
      searchInfo: {
        userId : JSON.parse(userModule.PPUser).userId
      },
      items: {
        userId: { filter: "EQ" }
      }, 
    };    
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
  },
  methods: {
    regPhone(){            
      if (!validator.mobile(this.whmInfo.recipientPhoneNum)) {
        this.whmInfo.recipientPhoneNum = ""
        return this.$message({
          message: "请输入正确的号码",
          type: "warning",
        });
      }
    },
    // setNumFotList(row,idx){
    //   if(row.thisTimeNum > this.deliveryList[idx].availableNum){
    //     return this.$message({
    //       message: "超出剩余数量",
    //       type: "warning",
    //     });        
    //     //this.deliveryList[idx].thisTimeNum = this.deliveryList[idx].availableNum
    //   }
    // },
    showSkuCodeDialog(){
      this.skuCodeDialog = true
    },
    deleteRow(idx) {
      this.deliveryList.splice(idx, 1);
    },
    closeSkucode() {
      this.skuCodeDialog = false;
    },
    handleCurrentChange(val) {
      //console.log(val.goodsId)
      if (val) {
        //console.log(val)        
        this.receiveList = {
          orderGoodsStoreId: val.orderGoodsStoreId,
          goodsName: val.goodsName,//商品名称
          goodsCode: val.goodsCode,//商品编码
          availableNum: val.availableNum,//剩余数量
          skuBatchNumData: val.skuBatchNumData,//批次信息  
        }        
        //this.deliveryList.push(this.receiveList)        
      }
    },
    skucodeConfirm() {      
      if(this.deliveryList.length > 0){
        for(let i in this.deliveryList){
          if(this.deliveryList[i].orderGoodsStoreId == this.receiveList.orderGoodsStoreId){
            return this.$message({
              message: "该商品已在列表,请选择其他商品",
              type: "warning",
            });
          }
        }
      }
      this.deliveryList.push(this.receiveList)
      this.deliveryPageInfo.total = this.deliveryList.length
      //console.log(this.deliveryList)
      this.skuCodeDialog = false;
    },
    createDelivery(){
      if(!this.deliveryList || this.deliveryList.length <= 0){
        return this.$message({
          message: "请添加商品",
          type: "warning",
        });
      }
      for(let i in this.deliveryList){
        if(!this.deliveryList[i].thisTimeNum || this.deliveryList[i].thisTimeNum == ""){
          return this.$message({
            message: `请输入商品名称'${this.deliveryList[i].goodsName}'的发货数量`,
            type: "warning",
          });
        }
        if(this.deliveryList[i].thisTimeNum == "0"){
          return this.$message({
            message: `商品名称'${this.deliveryList[i].goodsName}'的发货数量不能为零`,
            type: "warning",
          });
        }
        if(this.deliveryList[i].thisTimeNum > this.deliveryList[i].availableNum){
          return this.$message({
            message: `商品名称'${this.deliveryList[i].goodsName}'的发货数量超出剩余数量`,
            type: "warning",
          });        
        }
      }
      let whmInfo = this.whmInfo
      if (!whmInfo.recipientName) {
        return this.$message({
          message: "请添加收货人",
          type: "warning",
        });
      }
      if (!whmInfo.recipientPhoneNum) {
        return this.$message({
          message: "请添加收货方电话",
          type: "warning",
        });
      }
      if (!whmInfo.recipientFullAddress) {
        return this.$message({
          message: "请添加发货方地址",
          type: "warning",
        });
      } 
      if (!whmInfo.deliveryMethod) {
        return this.$message({
          message: "请选择送货方式",
          type: "warning",
        });
      } 
      if (!whmInfo.transportationCostMethod) {
        return this.$message({
          message: "请选择运费付款",
          type: "warning",
        });
      }
      
      whmInfo.goodsStoreList = this.deliveryList
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });      
      WhmList.saveOrderGoodsStore(whmInfo)
        .then((res) => {    
          if(res.msg == "OK"){
            this.$message({
                message: '发货成功',
                type: 'success'
            });
            this.$router.push('/som/deliveryList');
          }
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      WhmList.pageOrderGoodsStore(this.pageInfo, this.searchConfig).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            orderGoodsStoreId: e.orderGoodsStoreId,
            createTime: tools.formatDate(new Date(e.createTime),"yyyy-MM-dd"),
            goodsName: e.goodsName,//商品名称
            goodsCode: e.goodsCode,//商品编码
            totalNum: e.totalNum,//下单总数
            availableNum: e.availableNum,//剩余数量
            skuBatchNumData: e.skuBatchNumData,//批次信息            
          };
        });
        this.querySuccess(data);
      });   
    },          
  },
};
</script>
<style>
.delivery-list .tab-label{ min-width: 160px; text-align: right;}
.delivery-list .rec-inline { margin-bottom: 20px;padding: 20px;background: #fff;}
.delivery-list .log-head-list{ margin-top: 10px; display: flex; align-items: center;}
.delivery-list .log-list-lef,.log-list-rig{ display: flex; align-items: center;}
.delivery-list .log-list-lef{ margin-right: 16px;}
.delivery-list .inp118{ width: 118px;}
.delivery-list .inp340{ width: 412px;}
.delivery-list .logistics-tit{ padding:0 0 10px 0; font-size: 18px;}
.delivery-list .mt30{ margin-top: 30px;}
.delivery-list .el-dialog__header{ padding: 0; border-bottom: 0;}
.delivery-list .btn-wrap{ padding:30px 0 0 0; display: flex; justify-content: center;}
.delivery-list .step-form{ margin-top: 30px;}
.delivery-list .logistics-btn-wrap{ padding: 15px 0 15px 20px; background: #fff;}
.delivery-list .delivery-btn { margin-bottom: 20px; display: flex;}
.delivery-list .delivery-btn .el-input--small{width: 160px;}
.delivery-list .delivery-btn .el-input__inner {width: 150px;}
.delivery-list .is-in-pagination .el-input__inner {width: 40px;}
.delivery-list .btn-blue-wrap{ margin-top: 20px;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>