import axiosHttp from "../axiosHttp";

export default {
    baseUrl: '/bimEnum/',
    // 枚举类型列表查询
    queryData(pageInfo, keyWord) {
        let url = "/bimEnum/pageGroupByParam?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&keyword=" + keyWord || ''
        return axiosHttp.GET(url);
    },

    // 查询枚举类型对象列表
    getEnumList(pageInfo, tpye) {
        let url = "/bimEnum/pageByParam?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        if(tpye==""){
            tpye="QWEUSADBB_SANDJSXCBSAIQBS10";
        }
        url += "&type=" + tpye
        return axiosHttp.GET(url);
    },
    listByType(type) {
        let url = `/bimEnum/listByType/${type}`
        return axiosHttp.GET(url);
    },
    // 保存枚举对象
    saveEnumInfo(enumInfo) {
        let url = "/bimEnum/create?"
        enumInfo.state = 1
        return axiosHttp.POST(url, enumInfo)
    },
    // 删除枚举对象
    deleteEnumInfo(enumIdS) {
        let delIds;
        if (enumIdS && enumIdS.length > 0) {
            for (let i = 0; i < enumIdS.length; i++) {
                if (delIds == null) {
                    delIds = enumIdS[i].enumId;
                } else {
                    delIds += "," + enumIdS[i].enumId;
                }
            }
            let opt = {'delIds': delIds};
            return axiosHttp.DELETE('/bimEnum/delete', opt)
        }
    },
    uniqueValidate(enumInfo){
        let url = "/bimEnum/checkUnique";
        enumInfo.state = 1;
        return axiosHttp.POST(url, enumInfo,false)
    }
}
