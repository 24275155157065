<template>
  <div class="invlist-wrap">
    <el-form label-width="120px" :inline="true" class="inventory-form">
      <el-form-item>
        <el-button type="primary" @click="addReceive">新增出入库</el-button>
        <el-button type="primary" @click="invalidTap">作废</el-button>
      </el-form-item>
    </el-form>  

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :selection="'multi'"
      :checkboxSelect="checkboxSelectFunc"
    >
      <el-table-column
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="invalidTime"
        label="异常时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="storageState"
        label="出入库状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createUserName"
        label="操作人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="toGroupDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>          
          </div>          
        </template>
      </el-table-column>
    </ws-page-table>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import abnormalQuery from "API/whm/abnormalQuery";
import tools from "COMJS/tools";

export default {
  name: "inventoryList",
  data() {
    return {
      value: [],
      options: [],    
      timeList: [],//最多三个
      choiceTime: "",
      addedTime: [],           
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData()
  },
  methods: {
    checkboxSelectFunc(row){
      if (row.storageState == "作废" || row.storageState == "已提交") {
        return false // 不禁用
     }else{
        return true // 不禁用
     }
    },
    loadTableData() {
      abnormalQuery.queryOtherInvPage(this.pageInfo, this.searchConfig).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            invalidTime: tools.formatDate(new Date(e.invalidTime), "yyyy-MM-dd hh:mm:ss"), //异常时间
            storageState: e.storageState,//出入库状态
            createUserName: e.createUserName,//操作人
            updateUserName: e.updateUserName,//更新人
            updateTime: e.updateTime,//更新时间
            checkStoreId: e.checkStoreId,//单据id
          };
        });
        this.querySuccess(data);
      });      
    },
    addReceive(){
      this.$router.push('/whm/otherEdit');
    },
    invalidTap(){
      //this.$router.push("/whm/receiveEdit");
      let selected = this.$refs.pageTable.getSelectedData();// ******************获取当前选中的*****************
      let params = []
      params = selected.map((e) => {
        return {
          checkStoreId: e.checkStoreId,
          storageState: 2,
        };
      });
      //console.log(arr)
      abnormalQuery.changeAuditState(params).then((data) => {
        //console.log(data)
        if(data.msg == "OK"){
          this.$message({
            message: '作废成功！',
            type: 'warning'
          });
          this.loadTableData()
        }
      })
    },

    toGroupDetail(row){
      row = JSON.stringify(row)
      this.$router.push(`/whm/inventoryDetail/${row}`);
    },
       
  },
};
</script>
<style>
.mt12{ margin-top: 12px;}
.inv-remid{ padding:20px 0 20px 20px; border-bottom: 1px solid #f1f1f1;}
.invlist-wrap .el-table--small th{ margin:0; padding: 0; line-height: 0; height: 0; border-bottom: 0;}
.ml30{ margin-left: 15px; font-size: 14px; color: #666;}
.inventory-form{padding-left: 20px;}
.invlist-wrap{ padding: 0 0 40px 20px;}
.invlist-wrap .el-dialog__body{ padding: 0; padding-bottom: 30px;}
.inv-remin-btn{ margin-top: 30px; display: flex; justify-content: center;}
.inv-remin-btn .b1,.inv-remin-btn .b2{ text-align: center; border-radius: 2px; font-size: 14px; cursor: pointer;}
.inv-remin-btn .b1{ width: 66px; height: 28px; line-height: 28px; color: #999; border:1px solid #999;}
.inv-remin-btn .b2{ margin-left: 20px; width: 68px; height: 30px; line-height: 30px; color: #fff; background: #1B73E8;}

</style>