<template>
  <div id="aggregation">
    <!--
    <div class="tips">
      <i class="el-icon-warning" style="color:rgba(255, 87, 51, 1);margin:0px 20px"></i>此处对聚合、展示的任何变动都将同步影响它在其他位置的显示，请谨慎操作
    </div>
    -->
    <div class="title">商品名称：{{ goodsData.cnName }}</div>
    <div class="content">
      <div class="half-content">
        <div class="half-content-title">小程序:</div>
        <div class="half-content-right">
          <div :class="item.flag=='1'?'half-content-item item-check':'half-content-item'"
               v-for="(item,idx) in activityList4wmp" :key="item.title+idx"
               @click="activityClick('wmp',idx)">
            <i class="el-icon-check free" v-if="item.flag=='1'"></i>
            <i class="el-icon-minus free" v-else></i>
            <img v-if="item.icon" :src="item.icon">
            <div>{{ item.title }}</div>
          </div>
          <div class="half-content-tail" @click="toAddActivity('wmp')">
            <i class="el-icon-plus"></i>
          </div>
        </div>

      </div>
      <div class="half-content">
        <div class="half-content-title">APP:</div>
        <div class="half-content-right">
          <div :class="item.flag=='1'?'half-content-item item-check':'half-content-item'"
               v-for="(item,idx) in activityList4app" :key="item.title+idx"
               @click="activityClick('app',idx)">
            <i class="el-icon-check free" v-if="item.flag=='1'"></i>
            <i class="el-icon-minus free" v-else></i>
            <img v-if="item.icon" :src="item.icon">
            <div>{{ item.title }}</div>
          </div>
          <div class="half-content-tail" @click="toAddActivity('app')">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="sumbit">
      <el-button @click="back">取消</el-button>
      <el-button type="primary" @click="save" :disabled='toView'>保存</el-button>
    </div>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import PimGoodsService from "API/pim/PimGoodsService";
import {showEventList, putGoodsInActivityList} from "@/api/pim/PimShowEvent.js";

export default {
  data() {
    return {
      old_activityList4wmp:[],
      old_activityList4app:[],
      activityList4wmp: [],
      activityList4app: [],
      activityList4show: [],
      searchInfo: {
        tradeType: ['wmp', 'app'],
        type: "mall",
      },
      items: {
        tradeType: {filter: "in"},
        type: {filter: "EQ"},
      },
    };
  },
  props: {
    goodsData: Object,
    toView: Boolean,
  },
  watch: {
    goodsData() {
      this.getAllAndShowActivity(this.goodsData.goodsId);
    }
  },
  components: {},
  mixins: [pageTableMixin],
  mounted() {
    //this.getAllShowActivity();
  },
  methods: {
    getAllAndShowActivity(goodsId) {
      this.activityList4wmp = []
      this.activityList4app = []
      this.activityList4show = []
      //查询
      this.pageInfo.pageSize=100;
      this.pageInfo.pageNo=1;
      showEventList(this.pageInfo, this.searchConfig).then((data) => {
        for (let idx in data.list) {
          let e = data.list[idx];
          let one = {
            activityId: e.activityId,
            title: e.title,
            icon: e.iconUrlSelected,
            active: false,
            display: '1',
            flag: 0,
          }
          if (e.tradeType == 'wmp') {
            this.activityList4wmp.push(one);
          } else {
            this.activityList4app.push(one);
          }
        }
        PimGoodsService.queryBySpuId(goodsId).then((data) => {
          this.activityList4show = data;
          for (const datum of this.activityList4show) {
            for (let ele of this.activityList4wmp) {
              if (datum.activityId == ele.activityId) {
                ele.flag = '1';
              }
            }
            for (let ele of this.activityList4app) {
              if (datum.activityId == ele.activityId) {
                ele.flag = '1';
              }
            }
          }
          this.old_activityList4wmp = JSON.parse(JSON.stringify(this.activityList4wmp));
          this.old_activityList4app = JSON.parse(JSON.stringify(this.activityList4app));
        });
      });
    },
    activityClick(mode, idx) {
      if (mode == 'wmp') {
        this.activityList4wmp[idx].flag = this.activityList4wmp[idx].flag == '1' ? '0' : '1';
      } else {
        this.activityList4app[idx].flag = this.activityList4app[idx].flag == '1' ? '0' : '1';
      }
    },
    toAddActivity(mode){
      this.$router.push(`/pim/editShowEvent?tradeType=${mode}`);
      this.$store.commit("setProductStepsData_Back", true);
    },
    checkDataChange() {
      if (
          JSON.stringify(this.old_activityList4wmp) != JSON.stringify(this.activityList4wmp) ||
          JSON.stringify(this.old_activityList4app) != JSON.stringify(this.activityList4app)
      ) {
        return true;
      }
      return false;
    },
    saveDataChange() {
      let state = this.save();
      return state;
    },

    save() {
      let activityIds = []
      for (let ele of this.activityList4wmp) {
        if(ele.flag=='1'){
          activityIds.push(ele.activityId)
        }
      }
      for (let ele of this.activityList4app) {
        if(ele.flag=='1'){
          activityIds.push(ele.activityId)
        }
      }
      //保存
      let params = {
        goodsId: this.goodsData.goodsId,
        activityIdList: activityIds,
      };
      putGoodsInActivityList(params).then((data) => {
        this.$message({type: "success", message: "保存成功"});
        this.getAllAndShowActivity(this.goodsData.goodsId)
        this.$router.push({
          path: "mallList",
        });
      });
      return 1;
    },
    back() {
      this.cancelAskBack();
    },
    loadTableData(){

    },
  },
};
</script>

<style scoped>
@import url("./malllCss.css");

.content {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}

.half-content {
  width: 100%;
  margin-bottom: 100px;
  display: flex;
}
.half-content-right{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.half-content-title {
  margin-right: 15px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.half-content-tail {
  width: 100px;
  height: 100px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(233, 236, 239, 0.5);
  cursor: pointer;
}

.item-check {
  background-color: rgba(80, 160, 243, 0.5) !important;
}

.item-check > i {
  color: #1B73E8;
}

.half-content-item {
  width: 80px;
  height: 75px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(233, 236, 239, 0.5);
  padding: 10px 10px 15px 10px;
  cursor: pointer;
}

.half-content-item > * {
  margin-bottom: 10px;
}

.half-content-item > img {
  width: 20px;
  height: 20px;
}

.half-content-item > .free {
  align-self: flex-start;
  margin-bottom: auto;
}

</style>