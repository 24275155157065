import axiosHttp from "../axiosHttp";

export default {
    //group
    queryGroupList(param) {
        return axiosHttp.POST("/lsGroup/listGroupByParam", param);
    },
    createLsGroup(param){
        return axiosHttp.POST("/lsGroup/createLsGroup", param);
    },
    //anchor
    queryAnchorList(param) {
        return axiosHttp.POST("/lsAnchor/listAnchorByParam", param);
    },
    pageAnchorByParam(param) {
        return axiosHttp.POST("/lsAnchor/pageAnchorByParam", param);
    },
    checkLsAnchorBeforeSave(param) {
        return axiosHttp.POST("/lsAnchor/checkLsAnchorBeforeSave", param);
    },
    saveLsAnchor(param) {
        return axiosHttp.POST("/lsAnchor/saveLsAnchor", param);
    },
    giveUpAnchor(editAnchorId) {
        let param={
            anchorId:editAnchorId
        }
        return axiosHttp.POST("/lsAnchor/giveUpAnchor", param);
    },
    //anchorLive
    queryLiveRecord(anchorId) {
        let param={
            anchorId:anchorId
        }
        return axiosHttp.POST("/lsAnchor/listLsAnchorLive", param);
    },
    saveLiveRecord(param) {
        return axiosHttp.POST("/lsAnchor/saveLsAnchorLive", param);
    },
    removeLiveRecord(param) {
        return axiosHttp.POST("/lsAnchor/removeLsAnchorLive", param);
    },
    //sample
    trySample(param) {
        return axiosHttp.POST("/lsAnchor/trySample", param);
    },
    querySampleRecord(param) {
        return axiosHttp.POST("/lsAnchor/listLsAnchorSample", param);
    },
    applyAnchorSample(param) {
        return axiosHttp.POST("/lsAnchor/applyAnchorSample", param);
    },
    auditAnchorSample(param) {
        return axiosHttp.POST("/lsAnchor/auditAnchorSample", param);
    },
    removeSampleRecord(param) {
        return axiosHttp.POST("/lsAnchor/removeLsAnchorSample", param);
    },
    modifyAddressById(param){
        return axiosHttp.POST("/lsAnchor/modifyAddressById", param);
    }
}