import axiosHttp from "../axiosHttp";
import tools from "COMJS/tools";

export default {
    // 订单分页查询
    statisticsGroupByGoods(searchInfo) {
        let url = "/orderStatistics/statisticsGroupByGoods"
        let param = {
            "goodsCode":searchInfo.goodsCode?searchInfo.goodsCode:null,
            "beginTime":searchInfo.timeRange.length>0?tools.formatDate(searchInfo.timeRange[0],'yyyy-MM-dd hh:mm:ss'):null,
            "endTime":searchInfo.timeRange.length>1?tools.formatDate(searchInfo.timeRange[1],'yyyy-MM-dd hh:mm:ss'):null
        }
        return axiosHttp.POST(url,param)
    }
}