<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="addSku">新增库品</el-button>
        <span class="search-wrapper">
          <el-input size="mini" v-model="searchInfo.skuName" placeholder="请输入库品名称"></el-input>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </span>
      </div>
      <div class="filter_right">
        <el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{operationType:'004'}})">操作日志</el-button>
      </div>
    </div>
    <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
    >
      <el-table-column prop="skuName" label="库品名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="skuCode" label="库品编号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="specUnit" label="规格单位"></el-table-column>
      <el-table-column label="库存">
        <template slot-scope="scope">
          <span class="icon-text">{{scope.row.skuNum || '无'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="可用库存">
        <template slot-scope="scope">
          <span class="icon-text">{{scope.row.avaibleNum || '无'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="锁定库存">
        <template slot-scope="scope">
          <span class="icon-text">{{scope.row.lockNum || '无'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                    href="javascript: void(0);"
                    title="修改库存"
                    @click="showEditPopop(scope.row, scope.$index)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="新增库品" :visible.sync="addVisibleDialog" width="560px">
      <div class="edit-form">
        <el-form label-width="140px">
          <el-form-item required label="库品名称：">
            <el-input v-model="currentInfo.skuName"></el-input>
          </el-form-item>
          <el-form-item required label="库品编号：">
            <el-input v-model="currentInfo.skuCode"></el-input>
          </el-form-item>
          <el-form-item required label="实际库存：">
            <el-input :min="0" @keyup.native="blurText($event)" v-model="currentInfo.skuNum"></el-input>
          </el-form-item>
          <el-form-item required label="规格单位：">
            <el-input v-model="currentInfo.specUnit"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisibleDialog = false">取消</el-button>
        <el-button type="primary" @click="addStock">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改库存" :visible.sync="editVisibleDialog" width="560px">
      <div class="edit-form">
        <el-form label-width="140px">
          <el-form-item required label="实际库存：">
            <el-input :min="0"  @keyup.native="blurText($event)" v-model="changeStockNum"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editVisibleDialog = false">取消</el-button>
        <el-button type="primary" @click="editStock">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
    import pageTableMixin from "@/mixin/pageTableMixin.js";
    import PimStockService from "API/pim/PimStockService";
    import _ from "lodash";

    export default {
        name: "productList",
        data() {
            return {
                searchInfo: {
                    skuName: ""
                },
                changeStockNum: "",
                editVisibleDialog: false,
                addVisibleDialog: false,
                editInfo: {
                    stockNum: ""
                },
                currentInfo: {}
            };
        },
        mixins: [pageTableMixin],
        methods: {
            loadTableData() {
                this.$refs.pageTable.tableLoading = true;
                PimStockService.queryStockPages(this.pageInfo, this.searchConfig).then(
                    data => {
                        data.list = data.list.map(e => {
                            return e;
                        });
                        this.querySuccess(data);
                    }
                );
            },
            addSku() {
                this.currentInfo = {};
                this.addVisibleDialog = true;
                //this.$router.push("/pim/productAdd");
            },
            addStock() {
                PimStockService.saveStock(this.currentInfo).then(() => {
                    this.loadTableData();
                    this.currentInfo = {};
                    this.addVisibleDialog = false;
                });
            },
            keyupEvent(e,input){
                e.target.value=e.target.value.replace(/[^\d.]/g, '');
                e.target.value=e.target.value.replace(/\.{2,}/g, '.');
                e.target.value=e.target.value.replace(/^\./g, '0.');
                e.target.value=e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0,e.target.value.length-1));
                e.target.value=e.target.value.replace(/^0[^\.]+/g, '0')
                e.target.value=e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
                this.input=e.target.value
                console.log(this.input)
            },

            blurText(e){
                let boolean = new RegExp("^[1-9][0-9]*$").test(e.target.value)
                if(!boolean){
                    if(e.target.value.length==1){
                        e.target.value='';
                    }else{
                        e.target.value = e.target.value.substr(0,e.target.value.length-1)
                    }
                }
            },
            showEditPopop(row) {
                this.changeStockNum = row.skuNum;
                this.currentInfo = _.cloneDeepWith(row);
                this.editVisibleDialog = true;
            },
            // 修改库存
            editStock() {
                if (this.changeStockNum === "" || this.changeStockNum ===undefined) {
                    return this.$message({
                        message: "请设置实际库存",
                        type: "warning"
                    });
                }

                // alert(2)
                let params = {
                    skuId: this.currentInfo.skuId,
                    skuNum: Number(this.changeStockNum),
                    changeNum: this.changeStockNum - this.currentInfo.skuNum,
                };
                PimStockService.changeStock(params).then(() => {
                    this.loadTableData();
                    this.changeStockNum = "";
                    this.editVisibleDialog = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
  .icon-text {
    display: inline-block;
    margin-right: 15px;
  }
  .icon-operation {
    display: inline-block;
  }
  .el-popover {
    padding-right: 0;
  }
  .edit-popover {
    .el-input {
      width: 80px;
    }
    .popover-edit-btn {
      > span {
        cursor: pointer;
        display: inline-block;
        width: 42px;
        line-height: 24px;
        text-align: center;
        color: $themeColor;
      }
      .save {
        border-right: 1px solid $themeColor;
      }
    }
  }
  .edit-form /deep/ {
    .el-radio {
      display: inline-block;
    }
    .el-input {
      width: 150px;
    }
  }
</style>
