<template>
  <!-- 关联商城商品 -->
  <div class="related-products">
    <div class="box-shadow-unify">
      <div class="related-header">
        <span class="related-title">关联商品</span>
        <div class="search-input">
          <el-input
            v-model="name"
            prefix-icon="el-icon-search"
            @input="inputGoods"
            @click.native="focusGoods"
            maxlength="100"
          ></el-input>
          <div
            class="search-result"
            v-click-outside="closeResult"
            v-if="goodsSearchResultList.length"
          >
            <div
              class="result-item"
              v-for="(item, index) in goodsSearchResultList"
              :key="index"
              @click="selectResult(item)"
            >
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <ws-page-table :data="goodsList" :isShadow="false" ref="pageTable" :pageable="false">
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="goodsPrice" label="商品价格（元）"></el-table-column>
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <div class="table-img" v-if="scope.row.cover">
              <img :src="scope.row.cover" alt />
            </div>
            <span v-else>无</span>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
  </div>
</template>

<script>
import PimGoodsService from "API/pim/PimGoodsService";
import tools from "COMJS/tools.js";
import chargeFormat from "COMJS/chargeFormat.js";

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    isSingle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      goodsSearchResultList: [],
      name: ""
    };
  },
  mounted() {
  },
  computed: {
    goodsList: {
      get() {
        return this.value;
      },
      set(val) {
        // 同步v-model
        this.$emit("input", val);
        return val
      }
    }
  },
  methods: {
    goodsSearchChange() {
      PimGoodsService.queryAllGoods({
        cnName: this.name
      }).then(data => {
        let goodsNameList = this.goodsList.map(e => e.name);
        // 过滤已填写商品
        let _result = data.map(e => {
          if (!goodsNameList.includes(e.cnName)) {
            return {
              name: e.cnName,
              id: e.goodsId,
              goodsPrice: chargeFormat.thousandsOf(e.initPrice),
              cover: e.pictureUrl
            };
          }
        });
        this.goodsSearchResultList = _result.filter(e => e);
      });
    },
    inputGoods: tools.debounce(function(index) {
      this.goodsSearchChange();
    }, 100),
    focusGoods() {
      this.goodsSearchChange();
    },
    closeResult() {
      this.goodsSearchResultList = [];
    },
    selectResult(item) {
      if (this.isSingle) {
        this.goodsList = [item];
      } else {
        this.goodsList.push(item);
      }
      this.closeResult();
    }
  }
};
</script>

<style scoped lang="scss">
.related-products {
  .related-header {
    padding: 16px 20px;
    background-color: #F6F9FC;
    border-bottom: 1px solid #999;
    .related-title {
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }
    .search-input {
      display: inline-block;
      .el-input {
        width: 500px;
      }
    }
  }
}
</style>