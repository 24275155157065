export default {

  //todo 把页面上的鬼值都换成这里，但是不能直接引用，研究一下

  ROLE_CODE_DISTRIBUTOR: "distributor_",

  ROLE_CODE_AGENT__CREDIT: "agent_credit",
  ROLE_CODE_AGENT__DEPOSIT: "agent_deposit",
  ROLE_CODE_AGENT__CASH: "agent_cash",

  ROLE_CODE_LIVESTREAM__GROUP: "livestream_group",
  ROLE_CODE_SCM_EDITOR: "scm_editor",
  ROLE_CODE_S2EP: "s2ep",
}