<template>
  <div class="batch-list">
    <div class="table-tips">
      <div class="tips-title">批量发货</div>
      <div class="tips-content">
        <div>
          <p style="font-weight: bold;">订单导入规则说明</p>
          <p>批量发货目前仅支持快递订单，暂不支持自提订单和同城配送方式的订单。提高操作发货的成功率：</p>
          <p>
            <span>1.</span>
            <span>按
              <a href="javascript: void(0);" style="cursor: pointer;color:#1ea0ff;text-decoration:underline;" @click="exportShipTemplate">发货模版</a>
              要求填写发货信息，注意运单号不要写成E+22科学计数法；</span>
          </p>
          <p>
            <span>2.</span>
            <span>下载【查看失败原因】，对症修改，成功率更高；</span>
          </p>
        </div>
      </div>
    </div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-upload
            style="display: inline-block;margin-right: 28px;"
            ref="upload"
            :headers="requestHeaders"
            :multiple="false"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :action="uploadUrl()"
            :limit="1"
            :on-success="onSuccess"
            :on-remove="onRemove"
          >
            <el-button type="primary" >批量发货</el-button>
          </el-upload>
          <div class="form-item">
            <span class="form-label">操作时间：</span>
            <el-date-picker
              v-model="filterTimes"
              @change="changePickerDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
          <!--<el-button type="primary" size="mini" style="margin-left: 30px" @click="showAllData">展示所有数据</el-button>-->
        </div>
      </div>
      <div class="filter_right">
        <el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{operationType:'order_ship'}})">操作日志</el-button>
      </div>
    </div>
    <div class="batch-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs v-model="tabsActiveName" type="card">
          <el-tab-pane label="表格导入" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :isShadow="false"
      >
        <el-table-column prop="batch" label="批次"></el-table-column>
        <el-table-column prop="importTime" label="导入时间"></el-table-column>
        <el-table-column prop="progress" label="进度"></el-table-column>
        <el-table-column prop="sumNum" label="总条数"></el-table-column>
        <el-table-column prop="successNum" label="成功条数"></el-table-column>
        <el-table-column prop="failNum" label="失败条数"></el-table-column>
        <el-table-column prop="remarks" label="备注"></el-table-column>
        <!-- <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <span class="operation" v-if="Number(scope.row.failNum) > 0">查看失败原因</span>
          </template>
        </el-table-column>-->
      </ws-page-table>
    </div>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService";
import orderShipService from "API/som/orderShipService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import userModule from "@/module/userModule";

export default {
  name: "orderList",
  data() {
    return {
      searchInfo: {
        beginDate: "",
        endDate: ""
      },
      filterTimes: [],
      tabsActiveName: "first",
      fileTemplateName: "",
      requestHeaders: {
        Authorization: userModule.token
      }
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      orderShipService.queryOrderPages(this.queryParams).then(data => {
        data.list = data.list.map(e => {
          return {
            id: e.orderImportBatchId,
            shopId: e.shopId,
            batch: e.batchCode,
            importTime: tools.formatDate(
              new Date(e.createTime),
              "yyyy-MM-dd hh:mm:ss"
            ),
            progress:
              ((e.successCount + e.failCount) / e.totalCount).toFixed(2) * 100 +
              "%",
            fileUrl: e.fileUrl,
            sumNum: e.totalCount,
            successNum: e.successCount,
            failNum: e.failCount,
            remarks: e.remark,
            chinaPostIOList: e.chinaPostIOList
          };
        });
        this.querySuccess(data);
      });
    },
    showAllData() {
        this.searchInfo.beginDate = "";
        this.searchInfo.endDate = "";
        this.loadTableData();
    },
    changePickerDate(e) {
      console.log(e)
      if (e === null) {
        this.searchInfo.beginDate = "";
        this.searchInfo.endDate = "";
      } else {
          this.searchInfo.beginDate = new Date(e[0]);/*tools.formatDate(
          new Date(e[0]),
          "yyyy-MM-dd hh:ss:mm"
        );*/
          this.searchInfo.endDate = new Date(e[1]);/*tools.formatDate(
          new Date(e[1]),
          "yyyy-MM-dd hh:ss:mm"
        );*/
      }
      this.loadTableData();
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("somOrderShipImport");
      return url;
    },
    /*上传文件之前的钩子*/
    beforeUpload(file) {
      let isLtM = file.size / 1024 / 1024 < 1;
      if (!isLtM) {
        this.$message.error("暂只支持上传大小为1024k以下表格！");
        return false;
      }

      if (file.type && file.type.length > 0) {
        //包含type
        let isXlsm =
          file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
          file.type === "application/vnd.ms-excel.sheet.macroenabled.12"; //xlsm
        let isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; //xlsx
        let isXls = file.type === "application/vnd.ms-excel"; //xls

        if (!(isXlsm || isXlsx || isXls)) {
          this.$message.warning(
            "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
          );
        }
        return isXlsm || isXlsx || isXls;
      } else {
        if (file.name && file.name.length > 5) {
          //文件名存在
          if (
            file.name.substring(file.name.length - 5, file.name.length) ===
              ".xlsx" ||
            file.name.substring(file.name.length - 5, file.name.length) ===
              ".xlsm" ||
            file.name.substring(file.name.length - 4, file.name.length) ===
              ".xls"
          ) {
            return true;
          } else {
            this.$message.warning(
              "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
            );
            return false;
          }
        } else if (
          file.name &&
          file.name.length > 4 &&
          file.name.substring(file.name.length - 4, file.name.length) === ".xls"
        ) {
          return true;
        } else {
          this.$message.warning(
            "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
          );
          return false;
        }
      }
    },
    onSuccess(response, file, fileList) {
      let splitList = file["response"].split("/");
      this.fileTemplateName = file["response"].split("/")[splitList.length - 1];
      var that = this;
      orderShipService.shipImportCheckData(this.fileTemplateName).then(data => {
          var msg = "本次发货包裹数量："+data.totalCount;
          if(data.failCount>0){
              msg = msg + "，其中"+ data.failCount + "个包裹已有发货记录，将被覆盖"
          }
          msg = msg + "。是否继续？"
          this.$confirm(msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              orderShipService.importOrderNO(that.fileTemplateName).then(data => {
                  console.log(data);
                  that.loadTableData();
                  that.clearUploadForm();
              }).catch(() => {
                  that.clearUploadForm();
                  that.loadTableData();
                  that.clearUploadForm();
              });
          }).catch(() => {
              that.clearUploadForm();
          });
      }).catch(() => {
          console.log(data);
          that.clearUploadForm();
          //that.loadTableData();
      });


    },
    onRemove() {},
    // 清除upload
    clearUploadForm() {
      this.$refs.upload.clearFiles();
      this.fileTemplateName = null;
    },
      // 下载模板
      exportShipTemplate() {
          let loading = this.$loading({
              lock: true,
              text: "Loading..."
          });
          orderShipService
              .exportShipTemplate()
              .then(data => {
                  let fileName = data.fileName;
                  let module = data.module;
                  IoProgressService.download(module, fileName, false);
                  loading.close();
              })
              .catch(() => {
                  loading.close();
              });
      }
  }
};
</script>

<style lang="scss" scoped>
.batch-list {
  .batch-table {
    .tabs-wrapper {
      padding: 10px 20px;
      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;
        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }
    background-color: #fff;
  }
}
  .el-upload{
    border: none !important;
  }
</style>