<template>
  <div>
    <div>
      <div class="filter-form">
        <div class="filter_left">
          <div class="form-row">
            <div class="form-item">
              <span class="form-label">经销商账号：</span>
              <el-input size="mini" v-model="dealerInfo.account" :disabled="true"></el-input>
            </div>
            <div class="form-item">
              <span class="form-label">经销商姓名：</span>
              <el-input size="mini" v-model="dealerInfo.name" :disabled="true"></el-input>
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <span class="form-label">渠道商：</span>
              <el-select v-model="searchInfo.channelName" placeholder="请选择通知类型">
                <el-option
                    v-for="item in channelNames"
                    :key="item"
                    :label="item"
                    :value="item"
                ></el-option>
              </el-select>
              <!--
              <el-input size="mini" v-model="searchInfo.channelName"></el-input>
              -->
            </div>
            <div class="form-item">
              <span class="form-label">录入时间：</span>
              <el-date-picker
                  style="margin-left: 18px"
                  v-model="searchInfo.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div class="form-item">
              <span class="form-label">库品编码：</span>
              <el-input size="mini" v-model="searchInfo.skuCode"></el-input>
            </div>
            <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="searchData">搜索
            </el-button>
          </div>
        </div>
      </div>

      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="false"
      >
        <el-table-column prop="channelName" label="渠道商"></el-table-column>
        <el-table-column prop="createTime" label="录入时间"></el-table-column>
        <el-table-column prop="skuName" label="库品名称"></el-table-column>
        <el-table-column prop="skuCode" label="库品编码"></el-table-column>
        <el-table-column prop="serialNum" label="录入数量"></el-table-column>
        <el-table-column prop="remark" label="录入备注"></el-table-column>
        <el-table-column prop="repeatNum" label="重复数量">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="showRepeatOrDeleteDialog(scope.row.dealerSerialInfoId,'0')"
                type="text"
                size="small">
              {{ scope.row.repeatNum }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="deleteNum" label="删除数量">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="showRepeatOrDeleteDialog(scope.row.dealerSerialInfoId,'1')"
                type="text"
                size="small">
              {{ scope.row.deleteNum }}
            </el-button>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <el-dialog :visible.sync="repeatOrDeleteDialogVisible" width="900px">
      <el-table
          :data="repeatOrDeleteList"
          :pageable="false"
      >
        <el-table-column
            prop="serialNumber"
            label="序列"
            width="200px"
        ></el-table-column>
        <el-table-column
            prop="serialInfo"
            label="信息"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerSerialInfo from "API/whm/dealerSerialInfo.js";
import tools from "COMJS/tools.js";
import chargeFormat from "COMJS/chargeFormat.js";
import dealerService from "API/dea/dealerService";

export default {
  name: "serialNumOutboundDetail",
  data() {
    return {
      searchInfo: {
        bimUser: '',
        channelName: '',
        createTime: '',
        skuCode: '',
      },
      items: {
        bimUser: {
          filter: "EQ",
        },
        channelName: {
          filter: "EQ",
        },
        createTime: {filter: "between", dataType: "date"},
        skuCode: {
          filter: "EQ",
        },
      },
      dealerInfo: {
        account: "",
        name: "",
      },
      channelNames: [],
      repeatOrDeleteDialogVisible: false,
      repeatOrDeleteList: [],
    };
  },
  mixins: [pageTableMixin],
  created() {
    this.searchInfo.bimUser = this.$route.params.id;
  },
  methods: {
    /*getChannelNames() {
      dealerService.queryfaDealerInfo(this.$route.params.id).then((data) => {
        this.channelNames = data.channelNames;
      })
    },*/
    loadTableData() {
      dealerSerialInfo.queryAtPage(this.pageInfo, this.searchConfig).then(
          data => {
            if (data.list.length > 0) {
              this.dealerInfo.account = data.list[0].dealerAccount;
              this.dealerInfo.name = data.list[0].dealerName;
            }
            data.list = data.list.map(e => {
              return {
                ...e,
                createTime: tools.formatDate(new Date(e.createTime), 'yyyy-MM-dd hh:mm:ss'),
                shipmentPrice: '¥' + chargeFormat.thousandsOf(e.shipmentPrice),
              };
            });
            this.querySuccess(data);
          }
      );
    },
    showRepeatOrDeleteDialog(id,type) {
      let param = {
        "pageNo": 1,
        "pageSize": 100,
        "dealerSerialInfoId": id,
        "isRepeat": type=='0'?"1":"0",
        "isDelete": type=='1'?"1":"0",
      }
      console.log(param)
      dealerSerialInfo.querySerialInStateAtPage(param).then(
          data => {
            if(data && data.list && data.list.length>0){
              this.repeatOrDeleteList = data.list;
            }else{
              this.repeatOrDeleteList = [];
            }
          }
      );
      this.repeatOrDeleteDialogVisible = true;
    }
  }
};
</script>
