<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">创建时间：</span>
            <el-date-picker
                v-model="filterTimes"
                @change="changePickerDate"
                size="mini"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <span class="form-label">入库状态：</span>
            <el-select v-model="searchInfo.stockModifyEntry"
                       clearable
                       placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="form-item">
            <span class="form-label">入库类型：</span>
            <el-select v-model="searchInfo.stockModifyType"
                       clearable
                       placeholder="请选择">
              <el-option
                  v-for="item in modifyTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
      <div class="filter_right">
        <el-button size="mini" type="primary" @click="entryStockDialogShow">新增入库</el-button>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :selection="null"
    >
      <el-table-column prop="goodsName" label="名称" minWidth="150"></el-table-column>
      <el-table-column prop="goodsCode" label="编码" width="150"></el-table-column>
      <el-table-column prop="stockModifyNum" label="变动数量" width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.stockModifyNum>0" style="color: #5cdd61;">
            {{ scope.row.stockModifyNum }}
          </div>
          <div v-else style="color: #b94a48;">
            {{ scope.row.stockModifyNum }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="batchCode" label="批次编码" width="150"></el-table-column>
      <el-table-column prop="expireDate" label="过期日期" width="150"
                       :formatter="dateFormatterShot4Table"></el-table-column>
      <el-table-column label="入库类型" width="150">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.stockModifyType == 'CGRK' ? "采购入库" :
                  scope.row.stockModifyType == 'KCPD' ? "库存盘点" : "库存异动"
            }}
          </div>
          <div v-if="scope.row.remark">
            ({{
              scope.row.remark == 'THYRK' ? "智能退货入库" :
                  scope.row.remark == 'QTCK' || scope.row.remark == 'QTRK' ? "未知出入库/WMS开的调拨单/借调单/生产单"
                      : ""
            }})
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cardState" label="状态" width="150">
        <template slot-scope="scope">
          {{ scope.row.stockModifyEntry == '1' ? '已确认' : '待确认' }}
        </template>
      </el-table-column>
      <el-table-column label="入库时间" width="180">
        <template slot-scope="scope">
          <div v-if="!scope.row.actualEntryDate">
            {{ dateFormatter(scope.row.expectedEntryDate, 'yyyy-MM-dd') }} (预计)
          </div>
          <div v-else>
            {{ dateFormatter(scope.row.actualEntryDate, 'yyyy-MM-dd') }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180"
                       :formatter="dateFormatter4Table"></el-table-column>
      <!--
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="详情"
                @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
      -->
    </ws-page-table>
    <singleItemStockDialog ref="singleItemStockDialog" @submit='loadTableData'></singleItemStockDialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import singleItemStockDialog from "./SingleItemStockDialog";
import freshMilkStockService from "API/fm/FreshMilkStockService";
import tools from "COMJS/tools";

export default {
  name: "FreshMilkRecordList",
  mixins: [pageTableMixin],
  components: {
    singleItemStockDialog
  },
  data() {
    return {
      filterTimes: [],
      searchInfo: {},
      options: [{
        value: '1',
        label: '已确认'
      }, {
        value: '0',
        label: '待确认'
      }],
      modifyTypeList: [
        {value: 'CGRK', label: '采购入库'},
        {value: 'KCPD', label: '库存盘点'},
        {value: 'KCYD', label: '库存异动'},
      ]
    }
  },
  methods: {
    loadTableData() {
      let param = {
        ...this.searchInfo,
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        flagWithI: '1'
      }
      freshMilkStockService.queryRecordPage(param).then(res => {
        this.querySuccess(res);
      })
    },
    toDetail(row) {
      /*this.$router.push({
        path: `/fm/freshMilkCardDetail/${row.milkCardId}`,
      });*/
    },
    dateFormatter4Table(row, column) {
      return this.dateFormatter(row[column.property]);
    },
    dateFormatterShot4Table(row, column) {
      return this.dateFormatter(row[column.property], "yyyy-MM-dd");
    },
    dateFormatter(date, fmt = "yyyy-MM-dd hh:mm:ss") {
      let v = "";
      if (date) {
        v = tools.formatDate(new Date(date), fmt);
      }
      return v;
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.recordDateBegin = "";
        this.searchInfo.recordDateEnd = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.recordDateBegin = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.searchInfo.recordDateEnd = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
    entryStockDialogShow() {
      this.$refs.singleItemStockDialog.show()
    },
  }
}
</script>

<style scoped>

</style>