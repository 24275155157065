<template>
  <div>
    <el-form label-width="80px" class="form">
      <div style="display: flex;">
        <div>
          <el-form-item label="申请时间：">
            <span>{{ formart(new Date(invoiceDetail.createTime), "yyyy-MM-dd hh:mm:ss") }}</span>
          </el-form-item>
          <el-form-item label="发票抬头：">
            <span>{{ invoiceDetail.name || '无' }}</span>
          </el-form-item>
          <el-form-item label="开票总额：">
            <span>{{ !invoiceDetail.invoiceAmount ? '无' : invoiceDetail.invoiceAmount.toFixed(2) }}</span>
          </el-form-item>
          <el-form-item label="接收邮箱：">
            <span>{{ invoiceDetail.email || '无' }}</span>
          </el-form-item>
        </div>
        <div style="margin-left: 20px;">
          <el-form-item label="发票类型：">
            <span>{{ invoiceDetail.invoiceType == 'company_sp' ? '企业专票' : invoiceDetail.invoiceType == 'company' ? '企业普票' : '个人' }}</span>
          </el-form-item>
          <el-form-item label="企业税号：">
            <span>{{ invoiceDetail.dutySign || '无' }}</span>
          </el-form-item>
          <el-form-item label="开票状态：">
            <span>{{ invoiceDetail.completeState == '1' ? '已开票' : '开票中' }}</span>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" @pageChange="loadTableData">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="orderCode" label="订单号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="recipientName" label="收件人姓名" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="recipientPhoneNum" label="收件人手机号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="batchCode" label="批次号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="totalAmount" label="实付金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.totalAmount.toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="goodsPaymentAmount" label="可开票金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.goodsPaymentAmount.toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="finishTime" label="完成时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ formart(new Date(scope.row.finishTime), "yyyy-MM-dd hh:mm:ss") }}
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import SomInvoiceService from "API/som/SomInvoiceService";
import tools from "COMJS/tools";

export default {
  data() {
    return {
      invoiceDetail: {},
      pageInfo: {
        invoiceId: "",
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  async mounted() {
    let data = await SomInvoiceService.queryDetail({"invoiceId": this.$route.params.id})
    this.invoiceDetail = data
  },
  methods: {
    formart: tools.formatDate,
    async loadTableData() {
      this.pageInfo.invoiceId = this.$route.params.id
      let data = await SomInvoiceService.listOrderPageThisInvoice(this.pageInfo)
      this.querySuccess(data);
    },
  },
  mixins: [tableMixins],
}
</script>

<style>
</style>
