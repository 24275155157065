<template>
  <div>
    <steps :active="1" :steps="steps"></steps>
    <div class="add-toos_bar box-shadow-unify">
      <el-button type="primary" @click="addAttr">添加属性</el-button>
    </div>
    <ws-page-table :data="attrList" ref="pageTable" :pageable="false" :showIndex="true">
      <el-table-column label="属性名称">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="属性内容 ">
        <template slot-scope="scope">
          <el-input v-model="scope.row.content"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="是否启用" width="120px">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isEnable"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <i class="el-icon-delete-solid" @click="deleteAttr(scope.$index)"></i>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <div class="steps-btn-wrapper">
      <el-button @click="backPreStep()">返回上一步</el-button>
      <el-button type="primary" @click="nextStep">完成，下一步</el-button>
    </div>
  </div>
</template>

<script>
import steps from "COMPS/widgets/steps";

export default {
  data() {
    return {
      steps: [
        {
          name: "添加信息"
        },
        {
          name: "添加属性"
        },
        {
          name: "添加图片"
        }
      ],
      attrList: [
        {
          name: "",
          content: "",
          isEnable: false
        }
      ]
    };
  },
  beforeDestroy() {
    this.$store.commit("saveProductAttrList", this.attrList);
  },
  components: {
    steps
  },
  mounted() {
    let list = this.$store.state.productAttrList;
    if (list.length > 0) {
      this.attrList = list;
    }
  },
  methods: {
    addAttr() {
      // let preAttr = this.attrList[this.attrList.length - 1];
      // if (preAttr.name == "" || preAttr.content == "") {
      //   return this.$message({
      //     message: "请填写完整属性信息",
      //     type: "warning"
      //   });
      // }
      this.attrList.push({
        name: "",
        content: "",
        isEnable: false,
        state: 1
      });
    },
    deleteAttr(index) {
      if (this.attrList.length == 1) return;
      this.attrList.splice(index, 1);
    },
    backPreStep() {
      if (this.$route.params.id) {
        this.$router.push(`/pim/productAdd_step_1/${this.$route.params.id}`);
      } else {
        this.$router.push("/pim/productAdd_step_1");
      }
    },
    nextStep() {
      for (let item of this.attrList) {
        if (item.name == "" || item.content == "") {
          return this.$message({
            message: "请填写完整属性信息",
            type: "warning"
          });
        }
      }
      if (this.$route.params.id) {
        this.$router.push(`/pim/productAdd_step_3/${this.$route.params.id}`);
      } else {
        this.$router.push("/pim/productAdd_step_3");
      }
    }
  }
};
</script>

<style lang="scss">
@import "~ASSETS/css/components/productAdd.scss";
</style>