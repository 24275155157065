import axiosHttp from "../axiosHttp";

export default {
    queryPage(pageInfo,searchConfig){
        let url = "/purFactory/queryPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryList(param){
        let url = "/purFactory/queryList"
        return axiosHttp.POST(url,param)
    },
    save(param){
        let url = "/purFactory/save"
        return axiosHttp.POST(url,param)
    },
    removeById(id){
        let param = {
            factoryId : id
        }
        let url = `/purFactory/remove`
        return axiosHttp.POST(url,param)
    },

}