<template>
  <div>
    <div class="search-input"
         v-if="searchInfo.auditConfigurationCode=='S002' || searchInfo.auditConfigurationCode=='S003'">
      <span class="search-input-span">订单号：</span>
      <el-input class="search-input-input" v-model="searchInfo.orderCode"></el-input>
      <span class="search-input-span">会员/经销商账号：</span>
      <el-input class="search-input-input" v-model="searchInfo.userAccount"></el-input>
      <el-button type="primary" @click="loadTableData">搜索</el-button>
    </div>
    <el-tabs v-model="activeName" type="card"
             @tab-click="loadTableData">
      <el-tab-pane label="待审核" name="awaiting"></el-tab-pane>
      <el-tab-pane label="已通过" name="success"></el-tab-pane>
      <el-tab-pane label="已驳回" name="fail"></el-tab-pane>
      <el-tab-pane label="全部" name="all"></el-tab-pane>
    </el-tabs>
    <ws-page-table
        v-if="header.length>0"
        :tableRowClassName="tableRowClassName"
        :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
        @pageChange="loadTableData" :showIndex="true" :isShadow="false">
      <el-table-column
          v-for="(item,idx) in header" :key="idx"
          :prop="item.prop" :label="item.label" :show-overflow-tooltip="true"/>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a v-if="activeName == 'awaiting'"
               href="javascript: void(0);" title="审核">
              <i class="el-icon-s-check" @click="seeDetail(scope.row)"></i>
            </a>
            <a v-else
               href="javascript: void(0);" title="查看">
              <i class="el-icon-view" @click="seeDetail(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog :title="modalTitle" :visible.sync="dialogVisible" width="800px">
      <div style="margin:20px;padding-bottom:5px;border-bottom:1px solid #999;">
        详情<i class="el-icon-caret-bottom"></i>
      </div>
      <div style="padding:10px;" v-if="dialogData.list1 && dialogData.list1.length>0">
        <el-row class="row-item" v-for="(item,idx) in dialogData.list1" :key="'list1'+idx">
          <el-col :span="4">
            {{ item.key }}：
          </el-col>
          <el-col :span="20">
            {{ item.type == 'money' ? chargeFormat.thousandsOf(item.value) : item.value }}
          </el-col>
        </el-row>
      </div>
      <div style="padding:10px;" v-if="dialogData.list3 && dialogData.list3.length>0">
        <el-row class="row-item">
          <el-col :span="4">
            {{ dialogData.list3Value }}：
          </el-col>
          <el-col :span="20">
            <el-image v-for="(item,index) in dialogData.list3" :key="'list3'+index"
                      style="width: 56px;margin-right:10px"
                      :src="item" :preview-src-list="dialogData.list3"></el-image>
          </el-col>
        </el-row>
      </div>

      <div style="margin:20px;padding-bottom:5px;border-bottom:1px solid #999;">
        审核<i class="el-icon-caret-bottom"></i>
      </div>
      <div v-if="dialogAuditVisible"
           style="margin:20px;font-size: 16px;">
        <h1 style="margin-bottom: 5px;">备注：</h1>
        <el-input type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  v-model="dialogData.auditRemark"/>
      </div>
      <div v-else
           style="margin:20px;font-size: 16px;">
        <h1 style="margin-bottom: 10px;">结果：{{ dialogData.auditState == 'success' ? '通过' : '驳回' }}</h1>
        <h1 style="margin-bottom: 5px;">备注：{{ dialogData.auditRemark }}</h1>
      </div>
      <span slot="footer" class="dialog-footer" v-if="dialogAuditVisible">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="dialogConfirm('fail')">驳回</el-button>
        <el-button type="primary" @click="dialogConfirm('success')">通过</el-button>
      </span>
    </el-dialog>

    <afterSalePop ref="afterSalePop" @auditOk='loadTableData'></afterSalePop>
    <refundPop ref="refundPop" @auditOk='loadTableData'></refundPop>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import chargeFormat from "COMJS/chargeFormat.js";
import tools from "COMJS/tools";
import {mapActions} from 'vuex'
import {
  listAuditsByCodeCommon,
  queryAuditsByIdCommon,
  auditFlowHandle
} from "@/api/som/checkService.js";
import afterSalePop from "PAGES/som/audit/afterSale"
import refundPop from "PAGES/som/audit/refundList"

export default {
  components: {afterSalePop,refundPop},
  data() {
    return {
      activeName: 'awaiting',
      searchInfo: {
        auditConfigurationCode: '',
        auditResult: '',
        orderCode:'',
        userAccount:''
      },
      header: [],
      businessAuditLogId: "",
      modalTitle: "审核",
      dialogData: {},
      dialogVisible: false,
      dialogAuditVisible: false,
    };
  },
  mixins: [tableMixins],
  computed: {
    chargeFormat() {
      return chargeFormat;
    }
  },
  watch: {
    '$route.query.code': {
      deep: true,
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal)
        if (newVal != oldVal) {
          this.searchInfo = {}
          this.searchInfo.auditConfigurationCode = newVal;
          this.loadTableData();
        }
      }
    }
  },
  created() {
    let code = this.$route.query.code
    if (code) {
      this.searchInfo.auditConfigurationCode = code;
    }
  },
  methods: {
    async loadTableData() {
      //默认all
      let auditResult = this.activeName;
      if (this.activeName == "all") {
        auditResult = "";
      }
      this.searchInfo.auditResult = auditResult;
      //console.log(this.queryParams)
      //查询
      listAuditsByCodeCommon(this.queryParams).then((data) => {
        this.header = data.header;
        let page = data.page;
        //console.log(page)
        this.querySuccess(page);
      });
    },
    seeDetail(row) {
      if (row.auditConfigurationCode == 'S002') {
        this.$refs.refundPop.show(row.businessAuditLogId,this.activeName == 'awaiting');
        return;
      }
      if (row.auditConfigurationCode == 'S003') {
        this.$refs.afterSalePop.show(row.businessAuditLogId,this.activeName == 'awaiting');
        return;
      }
      this.businessAuditLogId = row.businessAuditLogId;
      //activeName=='awaiting'?审核:查看
      if (this.activeName == 'awaiting') {
        this.dialogAuditVisible = true;
        this.modalTitle = "审核";
      } else {
        this.dialogAuditVisible = false;
        this.modalTitle = "查看";
      }
      this.dialogVisible = true;
      let params = {
        businessAuditLogId: row.businessAuditLogId,
      };
      queryAuditsByIdCommon(params).then((data) => {
        //console.log(data);
        this.dialogData = data;
      });

    },
    dialogConfirm(status) {
      if (this.dialogData.auditEnableMsg != 'success') {
        return this.$message.error(this.dialogData.auditEnableMsg)
      }
      if (status == 'fail') {
        //驳回
        if (!this.dialogData.auditRemark) {
          return this.$message.error("驳回请填写原因")
        }
      }
      let param = {
        businessAuditLogId: this.businessAuditLogId,
        auditResult: status,
        auditRemark: this.dialogData.auditRemark,
      }
      this.hideLockScreen();
      auditFlowHandle(param).then(data => {
        this.hideLockScreen();
        this.$message.success(`操作成功！`);
        this.loadTableData();
        this.dialogVisible = false;
      })
    },

    ...mapActions([
      'showLockScreen', 'hideLockScreen'
    ]),
    dateFormat: tools.formatDate,
    tableRowClassName({row, rowIndex}) {
      if (this.activeName == 'awaiting') {
        let t1 = new Date(row.applyTime).getTime()
        let t2 = new Date().getTime() - (2 * 24 * 3600 * 1000)//三天前
        if (t1 < t2) {
          return 'warning-row';
        }
      }
      return '';
    }
  }
};
</script>

<style scoped lang="scss">
.search-input {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.search-input-span{
  height: 30px;
  line-height: 30px;
}
.search-input-input{
  width: 200px;
  margin-right: 20px;
}


.dialog-footer {
  display: flex;
  justify-content: center;
}

.row-item {
  padding: 1% 0;
  font-size: 16px;
}

.row-item > *:nth-child(1) {
  text-align: right;
}

.row-item > *:nth-child(2) {
  text-align: left;
}
</style>
