<template>
  <div id="DisassemblyPackage">
    <div class="message">
      <div class="row">
        <div class="label">
          <div class="label-title">包裹号码：</div>
          <div class="label-content">{{PackageDetail.packageCode}}</div>
        </div>
        <div class="label">
          <div class="label-title">包裹状态：</div>
          <div class="label-content">{{PackageDetail.completionState=='0'?'待发货':PackageDetail.completionState=='2'?'已发货':''}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">
          <div class="label-title">订单号：</div>
          <div class="label-content">{{PackageDetail.operationCode}}</div>
        </div>
        <!-- <div class="label">
          <div class="label-title">订单状态：</div>
          <div class="label-content">待发货</div>
        </div> -->
        <div class="label">
          <div class="label-title">收件人手机：</div>
          <div class="label-content">{{PackageDetail.receivingPhone}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">
          <div class="label-title">收件人姓名：</div>
          <div class="label-content">{{PackageDetail.receiverName}}</div>
        </div>

      </div>
      <div class="row">
        <div class="label">
          <div class="label-title">包裹内容：</div>
          <div class="label-content">{{PackageDetail.packageContent}}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div style="margin-bottom:20px;font-size:14px;color:red">无论操作拆包还是合包，都会将涉及到包裹还原成待发货，对应订单状态也会相应改变.</div>
      <div class="select">
        <el-radio v-model="swithTab" label="1">拆包</el-radio>
        <el-radio v-model="swithTab" label="2">合包</el-radio>
        <div style="font-size:14px;color:red" v-if="swithTab=='1'">(将一个包裹拆分成多个)</div>
        <div style="font-size:14px;color:red" v-if="swithTab=='2'">(合并同一订单内的多个包裹)</div>
      </div>
      <div class="operation" v-if="swithTab=='1'">
        <div class="addPackage">
          <el-button type="primary" @click="addPackage" :disabled='PackageDetail.completionState=="2"'>添加包裹</el-button>
        </div>
        <ws-page-table :data="tableData" ref="pageTable" height="550" :pageInfo="pageInfo" @pageChange="loadTableData">
          <el-table-column label="包裹编号" prop="packageCode"></el-table-column>
          <el-table-column label="包裹内容" prop="packageContentCode"></el-table-column>
          <el-table-column label="状态" prop="state"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <a @click="Detele(scope.row,scope.$index)" title="删除">
                <i style="color: rgba(70, 141, 234, 1); margin-left: 10px;cursor: pointer;" class="el-icon-delete-solid"></i>
              </a>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
      <div class="merge" v-if="swithTab=='2'">
        <div class="merge-operation">
          <el-button type="primary" @click="dataMerge" :disabled='PackageDetail.completionState=="2"'>合并</el-button>
          <el-button @click="dataRestore" :disabled='PackageDetail.completionState=="2"'>还原</el-button>
        </div>
        <div class="merge-content">
          <ws-page-table selection='multi' style="width:100%" :data="mergeData" ref="mergeTable" height="590" :pageInfo="pageInfo" @pageChange="loadTableData">
            <el-table-column label="包裹编号" :show-overflow-tooltip="true" prop="outboundDocumentsCode"></el-table-column>
            <el-table-column label="包裹内容" :show-overflow-tooltip="true" prop="packageContent"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span>{{scope.row.completionState=='0'?'待发货':scope.row.completionState=='2'?'已发货':''}}</span>
              </template>
            </el-table-column>
          </ws-page-table>
        </div>
      </div>
      <div class="btn">
        <el-button type="primary" :disabled='PackageDetail.completionState=="2"' @click="saveAll">保 存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="clean">
      <div class="addGoods">
        <p>包裹编号：{{addOutboundDocumentsCode}}</p>
        <el-button type="primary" @click="addgoods">添加商品</el-button>
        <div class="addGoods-label" v-for='(item,index) in goodsList' :key="index">
          <el-select v-model="item.goodsCode" placeholder="请选择商品" style="width:30%" @change='selectValue($event,index)'>
            <el-option v-for="item in splitData" :key="item.goodsCode" :label="item.goodsCode" :value="item.goodsCode"></el-option>
          </el-select>
          <el-input v-model="item.num" placeholder="数量" @input="thisMaxValue(index,item.maxNum)" style="width:30%;margin:0px 30px"></el-input>
          <i class="el-icon-delete-solid" @click="delgoods(index)"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clean">取 消</el-button>
        <el-button type="primary" @click="goodsSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import boundQuery from "API/whm/boundQuery";
import pimGoodsService from "API/pim/PimGoodsService"
export default {
  data() {
    return {
      swithTab: "1",
      addOutboundDocumentsCode: "",
      PackageDetail: {},
      dialogVisible: false,
      goodsList: [],
      tableData: [],
      mergeData: [],
      splitData:[],
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      let id = this.$route.query.id;
      boundQuery.detailOut(id).then((data) => {
        let arr = this.dataTreating(data.packageContentInfo);
        this.PackageDetail = data;
        this.mergeData = data.orderToOutboundList;
        //取商品数据
        let goodsIds = arr.map(e=>e.goodsId)
        let param = {
          goodsIdList:goodsIds
        }
        pimGoodsService.queryGoodsList(param).then(res=>{
          let splitDataTemp = []
          if(res && res.length>0){
            for (const item of res) {
              let num = arr.filter(a=>a.goodsId==item.goodsId)[0].num || 0
              splitDataTemp.push({
                goodsId:item.goodsId,
                goodsCode:item.goodsCode,
                cnName:item.cnName,
                num:num
              })
            }
          }
          this.splitData = splitDataTemp;
        })
        //console.log(this.PackageDetail);
      });
    },
    dataTreating(idData) {
      let Arr = [];
      let idAndNum = idData.split("+");
      for (let i in idAndNum) {
        let params = {
          goodsId: idAndNum[i].substring(0, idAndNum[i].lastIndexOf("*")),
          num: idAndNum[i].substring(
              idAndNum[i].lastIndexOf("*") + 1,
              idAndNum[i].length
          ),
        };
        Arr.push(params);
      }
      return Arr;
    },
    addgoods() {
      let params = {
        goodsCode: "",
        goodsId: "",
        num: "",
        maxNum: "",
      };
      this.goodsList.push(params);
    },
    thisMaxValue(index, maxNum) {
      if (!/^[0-9]*$/.test(this.goodsList[index].num)) {
        this.$message.warning(`必须为数字`);
        this.goodsList[index].num = "";
        return;
      }
      if (Number(this.goodsList[index].num) > Number(maxNum)) {
        this.goodsList[index].num = maxNum;
        this.$message.warning(`包裹数量相加不能超过商品总数量！`);
        return;
      }
    },
    goodsSave() {
      if (this.goodsList.length > 0) {
        for (let i in this.goodsList) {
          if (this.goodsList[i].goodsCode == "") {
            return this.$message({
              message: "商品信息未填写完整",
              type: "warning",
            });
          }
          if (this.goodsList[i].num == "") {
            return this.$message({
              message: "商品数量未填写完整",
              type: "warning",
            });
          }
        }
        let Arr = this.dataChange(this.goodsList);
        let packageGoodsList = this.splitData;
        let packageContentCode = [];
        let packageContentInfo = [];
        for (let i in Arr) {
          for (let j in packageGoodsList) {
            if (Arr[i].goodsCode == packageGoodsList[j].goodsCode) {
              if (Number(packageGoodsList[j].num) - Number(Arr[i].num) < 0) {
                this.$message.warning(
                  "商品(" +
                    Arr[i].goodsCode +
                    ")分配的数量超过了商品总数，请重新分配"
                );
                return;
              }
              if (Number(Arr[i].num) == 0) {
                this.$message.warning(
                  "商品(" + Arr[i].goodsCode + ")数量不能为0"
                );
                return;
              }
            }
          }
          packageContentCode.push(Arr[i].goodsCode + "*" + Arr[i].num);
          packageContentInfo.push(Arr[i].goodsId + "*" + Arr[i].num);
        }
        for (let i in Arr) {
          for (let j in packageGoodsList) {
            if (Arr[i].goodsCode == packageGoodsList[j].goodsCode) {
              packageGoodsList[j].num =
                Number(packageGoodsList[j].num) - Number(Arr[i].num);
            }
          }
        }
        let params = {
          packageCode: this.addOutboundDocumentsCode,
          packageContentCode: packageContentCode.join("+"),
          packageContentInfo: packageContentInfo.join("+"),
          state: "待发货",
        };
        this.tableData.push(params);
        this.PackageDetail.sameOrderPackageList.push(
          this.addOutboundDocumentsCode
        );
        this.dialogVisible = false;
        this.goodsList = [];
      } else {
        return this.$message({
          message: "请添加商品。",
          type: "warning",
        });
      }
    },
    selectValue(event, index) {
      let packageGoodsList = this.splitData;
      for (let i in packageGoodsList) {
        if (packageGoodsList[i].goodsCode == event) {
          //console.log(packageGoodsList[i]);
          this.goodsList[index].goodsId = packageGoodsList[i].goodsId;
          this.goodsList[index].goodsCode = packageGoodsList[i].goodsCode;
          this.goodsList[index].maxNum = packageGoodsList[i].num;
        }
      }
    },
    dataChange(arr) {
      // 设置一个对象放id 一个数组放处理后的数据
      let a = {};
      let b = [];
      //  深拷贝源数据
      let arrs = JSON.parse(JSON.stringify(arr));
      // 循环遍历原始数据
      arrs.forEach((item) => {
        // 判断item.id 是否存在在 a 中 无则直接push进b
        if (!a[item.goodsId]) {
          // 直接push b
          b.push(item);
          // 将 对象塞进 a中
          a[item.goodsId] = item;
        } else {
          // a中存在 则对b 进行累加
          b.forEach((ss) => {
            if (ss.goodsId === item.goodsId) {
              ss.num = (parseFloat(ss.num) + parseFloat(item.num)).toString();
            }
          });
        }
      });
      return b;
    },
    delgoods(index) {
      this.$confirm(`是否删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.goodsList.splice(index, 1);
      });
    },
    addPackage() {
      this.dialogVisible = true;
      this.addOutboundDocumentsCode = this.packageNumDispose(
        this.PackageDetail.sameOrderPackageList,
        this.PackageDetail.operationCode
      );
    },
    packageNumDispose(sameOrderPackageList, operationCode) {
      let arr = [];
      for (let i in sameOrderPackageList) {
        arr.push(
          sameOrderPackageList[i].substring(sameOrderPackageList[i].length - 3)
        );
      }
      let max = Math.max(...arr);
      max += 1;
      max = String(max);
      max = max.length == 1 ? "00" + max : max.length == 2 ? "0" + max : max;
      return operationCode + max;
    },
    Detele(row, index) {
      this.$confirm(`是否删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let Arr = this.dataTreating(row.packageContentInfo);
        let packageGoodsList = this.splitData;
        for (let i in Arr) {
          for (let j in packageGoodsList) {
            if (Arr[i].goodsId == packageGoodsList[j].goodsId) {
              packageGoodsList[j].num += Number(Arr[i].num);
            }
          }
        }
        this.tableData.splice(index, 1);
      });
    },
    clean() {
      (this.goodsList = []), (this.dialogVisible = false);
    },
    saveAll() {
      if (this.swithTab == "1") {
        let packageGoodsList = this.splitData;
        //console.log(packageGoodsList);
        for (let i in packageGoodsList) {
          if (packageGoodsList[i].num != 0) {
            return this.$message({
              message: "包裹内还有剩余商品未进行拆包，请操作后重试",
              type: "warning",
            });
          }
        }
        //console.log(this.tableData);
        let params = {
          outboundId: this.PackageDetail.outboundDocumentsId,
          documents: this.tableData.map((e) => {
            return {
              packageCode: e.packageCode,
              packageContentCode: e.packageContentCode,
              packageContent: e.packageContentInfo,
            };
          }),
        };
        //console.log(params);
        boundQuery.splitOutbound(params).then((data) => {
          this.$message({ type: "success", message: "保存成功" });
          this.$router.go(-1);
        });
      }
      if (this.swithTab == "2") {
        let mergeData = this.mergeData;
        //console.log(mergeData);
        let Arr = [];
        for (let i in mergeData) {
          let idList = [];
          if (mergeData[i].previousData) {
            let params = {
              documentsCode: mergeData[i].outboundDocumentsCode,
              outboundIdList: this.ProcessingData(mergeData[i], idList),
            };
            Arr.push(params);
          }
        }
        //console.log(Arr);
        boundQuery.mergeOutbound(Arr).then((data) => {
          // console.log(data);
          this.swithTab = "1";
          this.$message({ type: "success", message: "保存成功" });
          this.$router.go(-1);
        });
      }
    },
    ProcessingData(data, idList = []) {
      //console.log(data);
      if (data.previousData) {
        for (let i in data.previousData) {
          if (data.previousData[i].previousData) {
            //console.log(data.previousData[i].previousData);
            this.ProcessingData(data.previousData[i].previousData, idList);
          } else {
            idList.push(data.previousData[i].outboundDocumentsId);
          }
        }
      } else {
        for (let i in data) {
          if (data[i].outboundDocumentsId) {
            idList.push(data[i].outboundDocumentsId);
          }
        }
      }
      //console.log(idList);
      return idList;
    },
    cancel() {
      this.$confirm(`确认取消拆合包的操作吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.go(-1);
      });
    },

    dataMerge() {
      let list = this.$refs.mergeTable.getCheckedData();
      if (list.length <= 1) {
        this.$message({
          message: "请选择两个或两个以上的包裹",
          type: "warning",
        });
        return;
      }
      //console.log(list);
      let mergeData = this.mergeData;
      this.addOutboundDocumentsCode = this.packageNumDispose(
        this.PackageDetail.sameOrderPackageList,
        this.PackageDetail.operationCode
      );
      let packageContent = "";
      let previousData = [];
      for (let i in list) {
        if (list[i].previousData) {
          previousData.push(list[i]);
        } else {
          let params = {
            completionState: list[i].completionState,
            outboundDocumentsCode: list[i].outboundDocumentsCode,
            packageContent: list[i].packageContent,
            outboundDocumentsId: list[i].outboundDocumentsId
              ? list[i].outboundDocumentsId
              : "",
          };
          previousData.push(params);
        }
        packageContent += list[i].packageContent + "+";
      }
      if (packageContent.length > 0) {
        packageContent = packageContent.substr(0, packageContent.length - 1);
      }
      let params = {
        completionState: 0,
        outboundDocumentsCode: this.addOutboundDocumentsCode,
        packageContent: packageContent,
        previousData: previousData,
      };
      mergeData.push(params);
      this.PackageDetail.sameOrderPackageList.push(
        this.addOutboundDocumentsCode
      );
      console.log(params);
      for (let i in previousData) {
        for (let j in mergeData) {
          if (
            previousData[i].outboundDocumentsCode ==
            mergeData[j].outboundDocumentsCode
          ) {
            mergeData.splice(j, 1);
          }
        }
      }
    },
    dataRestore() {
      let list = this.$refs.mergeTable.getCheckedData();
      let mergeData = this.mergeData;
      console.log(list);
      if (list.length == 1) {
        for (let i in list) {
          if (list[i].previousData) {
            for (let j in list[i].previousData) {
              mergeData.push(list[i].previousData[j]);
            }
          } else {
            this.$message({
              message: "当前包裹不可还原",
              type: "warning",
            });
            return;
          }
          // mergeData.push(list[i]);
          for (let j in mergeData) {
            if (
              list[i].outboundDocumentsCode ==
              mergeData[j].outboundDocumentsCode
            ) {
              mergeData.splice(j, 1);
            }
          }
        }
      } else {
        this.$message({
          message: "包裹还原只能选择单条数据",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
#DisassemblyPackage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
#DisassemblyPackage >>> .el-dialog__header {
  border: none;
  display: none;
}
#DisassemblyPackage >>> .el-dialog__body {
  padding: 0;
}
.message {
  width: 97.5%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px;
}
.row {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.label {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #333;
  font-size: 14px;
}
.label-title {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.label-content {
  width: 85%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.content {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  margin-top: 50px;
  padding: 40px;
}
.operation {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}
.addPackage {
  margin-bottom: 20px;
}
.addGoods {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}
.addGoods > p {
  font-size: 16px;
  margin-bottom: 20px;
}
.addGoods-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  width: 100%;
}
.addGoods-label > i {
  font-size: 17px;
  color: #1b73e8;
  cursor: pointer;
}
.btn {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.merge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.merge-operation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  width: 100%;
}
.merge-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>