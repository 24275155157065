<template>
  <div id="setting">
    <div class="setting-tips box-shadow-unify">
      <div class="setting-title">基本信息</div>
      <div class="setting-tips-content">
        <p>
          请按照「基础设置—商品设置」顺序完成设置，设置后即时生效。
          <el-button type="primary" @click="editing = true" v-if="!editing">开始编辑</el-button>
          <el-button type="primary" @click="cancelEdit" v-else>取消编辑</el-button>
        </p>
      </div>
    </div>
    <div class="light-color-table box-shadow-unify">
      <el-table :data="members" style="width: 100%">
        <el-table-column prop="level" label="级别"></el-table-column>
        <el-table-column prop="name" label="会员名称"></el-table-column>
        <el-table-column label="折扣比例">
          <template slot-scope="scope">
            <span class="discount short-input">
              <el-input
                :disabled="!editing"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                v-model="scope.row.discount"
              ></el-input>%
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="gift">
          <template slot="header" slot-scope="scope">
            会员礼包
            <el-popover placement="bottom" trigger="hover" content="温馨提示：会员礼包请前往“分销—商品管理”进行关联。">
              <i slot="reference" class="el-icon-question" style="cursor: pointer;color: #468DEA;"></i>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="setting-tips box-shadow-unify">
      <div class="setting-title">教育奖励</div>
      <div class="setting-tips-content">
        <p>当被邀请会员与邀请人处于同级别，且被邀请会员奶卡余额被消耗，邀请人获得被消耗余额的一部分作为教育奖励。「教育奖励」=消耗余额*x%</p>
      </div>
    </div>
    <div class="box-shadow-unify">
      <div class="single-row-box">
        <span class="label">教育奖励比例：</span>
        <span class="short-input">
          <el-input
            :disabled="!editing"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="educationAward"
          ></el-input>%
        </span>
      </div>
    </div>
    <div class="setting-tips box-shadow-unify">
      <div class="setting-title">联盟奖励</div>
      <div class="setting-tips-content">
        <p>各金星会员联盟奖励计算方式：</p>
        <p>【「团队业绩」*「联盟比例1」+（「联盟业绩2」*「联盟比例1-联盟比例2」）*n】。</p>
        <p>「团队业绩」：金星会员个人及其下级销售业绩的总和。</p>
        <p>「联盟比例1」：金星会员+被邀请的金星+被邀请的金星…..，金星会员和所有与自己相关金星会员团队业绩的总和，所对应的奖励比例。</p>
        <p>「联盟业绩2」：「联盟比例1」对应的业绩总和-「团队业绩」。</p>
        <p>「联盟比例2」：「联盟业绩2」对应的奖励比例。</p>
        <p>「n」：金星会员直接相关的的金星会员有几个，则根据不同相关金星会员的情况累积计算。</p>
      </div>
    </div>
    <div class="reward-gradient box-shadow-unify">
      <div class="box-title">
        奖励梯度
        <el-button type="primary" :class="{'gray-btn': !editing}" @click="addGradient">添加奖励梯度</el-button>
      </div>
      <ws-page-table :data="gradients" ref="pageTable" :pageable="false">
        <el-table-column label="业绩金额（元）">
          <template slot-scope="scope">
            <el-input
              :disabled="!editing"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              style="width: 200px"
              v-model="scope.row.allianceThresholdStart"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="奖励比例">
          <template slot-scope="scope">
            <el-input
              :disabled="!editing"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              style="width: 80px"
              v-model="scope.row.allianceRewardProportion"
            ></el-input>&nbsp;%
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid" @click="deleteGradient(scope.$index)"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <div class="box-shadow-unify">
      <div class="single-row-box small-row">
        <span class="label">结算周期：</span>
        <span class="short-input">1个自然月</span>
      </div>
      <div class="single-row-box small-row">
        <span class="label">发放时间：</span>
        <span class="short-input">
          结算后
          <el-input
            :disabled="!editing"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="day"
            style="margin-left: 10px;"
          ></el-input>天
        </span>
      </div>
    </div>
    <div class="setting-tips box-shadow-unify">
      <div class="setting-title">链接时效</div>
      <div class="setting-tips-content">
        <p>品牌方/会员分享会员礼包购买链接，链接有效时长为x小时。如需分享链接，请前往“分销—链接列表”进行操作。</p>
      </div>
    </div>
    <div class="box-shadow-unify">
      <div class="single-row-box small-row">
        <span class="label">分享链接有效时长</span>
        <span class="short-input">
          <el-input
            :disabled="!editing"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="shareHour"
            style="margin-left: 10px;"
          ></el-input>小时
        </span>
      </div>
    </div>
    <div class="save">
      <el-button type="primary" @click="saveSetting" v-if="editing">保存</el-button>
    </div>
  </div>
</template>
<script>
import DimSettingService from "API/dim/DimSettingService";
import _ from "lodash";
import Enum from "ASSETS/js/enum";

export default {
  data() {
    return {
      settingInfo: {},
      editing: false,
      members: [
        {
          level: "一级",
          name: "金星",
          discount: 0,
          gift: "-"
        },
        {
          level: "二级",
          name: "银星",
          discount: 0,
          gift: "-"
        },
        {
          level: "三级",
          name: "普标",
          discount: 0,
          gift: "-"
        }
      ],
      // 奖励梯度
      gradients: [],
      singleParamList: {}, // 后端返回数据，用于保存操作
      day: "", // 发放时间 天数
      shareHour: "", // 分享链接有效时长
      educationAward: "" // 教育奖励比例
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      DimSettingService.getSetting().then(data => {
        this.gradients = data.allianceRewardProportionList.map(e => {
          e.allianceRewardProportion = e.allianceRewardProportion * 100;
          return e;
        });
        this.singleParamList = data.singleParamList;
        data.singleParamList.forEach(e => {
          switch (e.paramKey) {
            case Enum.setting["GRANT_AFTER_CLOSING_DAY"]:
              this.day = e.defaultValue;
              break;
            case Enum.setting["EGALITARIAN_PROPORTION"]:
              this.educationAward = Number(e.defaultValue) * 100;
              break;
            case Enum.setting['G2']:
              this.members[0].discount = e.defaultValue * 100;
              break;
            case Enum.setting['G1']:
              this.members[1].discount = e.defaultValue * 100;
              break;
            case Enum.setting['G0']:
              this.members[2].discount = e.defaultValue * 100;
              break;
            case Enum.setting['SHARE_LINK_EFFECTIVE_TIME']:
              this.shareHour = e.defaultValue;
              break;
          }
        });
      });
    },
    cancelEdit() {
      this.editing = false
      this.getSetting()
    },
    saveSettingMethod() {
      console.log("saveSettingMethod");
      let params = {};
      // 奖励梯度转换
      params.allianceRewardProportionList = _.cloneDeepWith(this.gradients);
      params.allianceRewardProportionList = params.allianceRewardProportionList.map(
        e => {
          e.allianceRewardProportion = Number(
            (e.allianceRewardProportion / 100).toFixed(2)
          );
          return e;
        }
      );
      // 其他设置值转换
      params.singleParamList = this.singleParamList;
      params.singleParamList.forEach(e => {
        switch (e.paramKey) {
          case Enum.setting["GRANT_AFTER_CLOSING_DAY"]:
            e.defaultValue = this.day;
            break;
          case Enum.setting["EGALITARIAN_PROPORTION"]:
            e.defaultValue = (this.educationAward / 100).toFixed(2);
            break;
          case Enum.setting['G2']:
            e.defaultValue = (this.members[0].discount / 100).toFixed(2);
            break;
          case Enum.setting['G1']:
            e.defaultValue = (this.members[1].discount / 100).toFixed(2);
            break;
          case Enum.setting['G0']:
            e.defaultValue = (this.members[2].discount / 100).toFixed(2);
            break;
          case Enum.setting['SHARE_LINK_EFFECTIVE_TIME']:
            e.defaultValue = this.shareHour;
            break;
        }
      });
      let loading = this.$loading({
        lock: true,
        text: "保存中..."
      });
      DimSettingService.saveSetting(params)
        .then(() => {
          this.getSetting();
          this.editing = false
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    addGradient() {
      if (!this.editing) {
        return;
      }
      let obj = {
        allianceThresholdStart: "",
        allianceRewardProportion: ""
      };
      this.gradients.push(obj);
    },
    deleteGradient(index) {
      if (!this.editing) {
        return;
      }
      this.gradients.splice(index, 1);
    },
    saveSetting() {
      if (this.members.filter(e => e.discount === "").length) {
        return this.$message({
          message: "请填写会员折扣比例",
          type: "warning"
        });
      }
      if (
        this.gradients.filter(e => {
          return (
            e.allianceThresholdStart === "" || e.allianceRewardProportion === ""
          );
        }).length
      ) {
        return this.$message({
          message: "请填完整的奖励梯度信息",
          type: "warning"
        });
      }
      if (this.educationAward === "" || !this.educationAward) {
        return this.$message({
          message: "请填写教育比例",
          type: "warning"
        });
      }
      if (this.day === "" || !this.day) {
        return this.$message({
          message: "请填写结算天数",
          type: "warning"
        });
      }
      if (this.shareHour === "" || !this.shareHour) {
        return this.$message({
          message: "请填写分享链接有效时长",
          type: "warning"
        });
      }
      this.$confirm("是否保存该设置", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.saveSettingMethod();
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
#setting {
  > div {
    margin-bottom: 40px;
  }
  .setting-tips {
    background-color: #f6f9fc;
    padding: 18px 20px 10px 20px;
    .setting-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .setting-tips-content {
      > p {
        line-height: 22px;
        margin-bottom: 10px;
        color: #666;
        font-weight: bold;
      }
    }
  }
  .short-input {
    .el-input {
      width: 80px;
      margin-right: 10px;
    }
  }
  .single-row-box {
    padding: 0 20px;
    line-height: 60px;
  }
  .small-row {
    font-size: 14px;
  }
  .reward-gradient {
    .box-title {
      border-bottom: 1px solid #e9ecef;
      .el-button {
        margin-left: 20px;
      }
    }
  }
  .save {
    margin-top: 40px;
    text-align: center;
    .el-button {
      width: 110px;
    }
  }
  .gray-btn {
    cursor: not-allowed;
    background-color: #8898aa;
    border-color: #8898aa;
  }
}
</style>