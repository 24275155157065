<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" @click="showTips">流程说明</el-button>
        <el-button size="mini" type="primary" @click="exportCash">导出表格</el-button>
        <el-button size="mini" type="primary" @click="handleCashByBank">标记已支付</el-button>
        <span class="search-wrapper">
                    <el-input size="mini" v-model="searchInfo.distributorAccount" placeholder="请输入账号"></el-input>
                    <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
                </span>
      </div>
    </div>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        fix-height="440px"
    >
      <el-table-column fixed type="selection" width="50"></el-table-column>
      <el-table-column prop="applyTime" label="申请时间" min-width="174" :formatter="datetimeFormatter"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="withdrawCashCode" label="提现编码" min-width="160" show-overflow-tooltip></el-table-column>
      <el-table-column prop="withdrawCashType" label="提现类型" min-width="120" :formatter="cashTypeFormatter"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="withdrawCashAmount" label="申请提现金额" min-width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="distributorAccount" label="账号" min-width="130" show-overflow-tooltip></el-table-column>
      <el-table-column prop="distributorName" label="申请者姓名" min-width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="withdrawCashWay" label="提现方式" min-width="110" :formatter="cashWayFormatter"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="110" show-overflow-tooltip></el-table-column>
      <el-table-column prop="appId" label="APPID" min-width="190" show-overflow-tooltip></el-table-column>
      <el-table-column prop="openId" label="OPENID" min-width="190" show-overflow-tooltip></el-table-column>
      <el-table-column prop="handleState" label="支付状态" min-width="100" :formatter="handleStateFormatter" fixed="right"
                       show-overflow-tooltip></el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import tools from "COMJS/tools";
import dimWithdrawCashService from "API/dim/DimWithdrawCashService";
import IoProgressService from "API/IoProgressService";

export default {
  name: "unhandleCashByWechatList2",
  data() {
    return {
      searchInfo: {
        handleState: ['unhandle'],
        //applicantAccount:'',
        withdrawCashState: 'success',
        //withdrawCashType:[],
        withdrawCashWay: ['wechat'],
        //applyTime:'',
        state: '1'
      },
      items: {
        handleState: {filter: "IN"},
        //applicantAccount: {filter: "like"},
        withdrawCashState: {filter: "EQ"},
        //withdrawCashType: {filter: "IN"},
        withdrawCashWay: {filter: "IN"},
        //applyTime: {filter: "between",dataType:"date"},
        state: {filter: "EQ"},
      },
      sorts: {
        applyTime: {order: "desc"}
      },
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: {
    loadTableData() {
      // this.$set(this.pageInfo,'pageNo',this.$store.state.pageNo);
      this.$refs.pageTable.tableLoading = true;
      console.log(this.pageInfo, this.searchConfig);
      dimWithdrawCashService.findWithdrawCashLogPage(this.pageInfo, this.searchConfig).then(
          data => {
            this.querySuccess(data);
          }
      ).catch(e => {
        this.$refs.pageTable.tableLoading = false;
      });
    },
    showTips(){
      const h = this.$createElement;
      this.$msgbox({
        title: '支付流程说明',
        message: h('div', null, [
          h('p', null, '1、导出支付表格，用于在微信商户号后台导入'),
          h('p', null, '2、登录微信商户号后台，https://pay.weixin.qq.com'),
          h('p', null, '3、点击顶部菜单"交易中心"，再点击左侧菜单"商家转账到零钱"，依界面提示操作'),
          h('p', null, '4、转账完成后，返回本系统，选择记录，标记为已支付'),
          h('p', {style: 'color: red'}, '注：转账过程中，如"运营账户"余额不足，还需操作资金转入')
        ]),
        showCancelButton: false,
        confirmButtonText: '确定',
      }).then(action => {
        //confirm,cancel
      });
    },
    exportCash() {
      if (this.tableData.length == 0 || !this.tableData) {
        this.$message('暂无可导出数据')
        return
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      dimWithdrawCashService.exportExcel4WechatMerchantTransfer(this.searchConfig).then(data => {
        let fileName = data.fileName;
        let module = data.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    //处理标识记录
    handleCashByBank() {
      let that = this;
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        that.$message('请选择记录！', '系统提示');
        return;
      }
      let confuse = false;
      rows.forEach(o => {
        if (o.handleState == 'handle') {
          confuse = true;
        }
      });
      if (confuse) {
        that.$alert('选择记录中包含已处理记录,请重新选择', '系统提示');
        return;
      }

      that.$confirm('是否确定将选中记录标识为已支付?', '系统提示', {type: 'warning'}).then(() => {
            that.$refs.pageTable.tableLoading = true;
            let ids = [];
            rows.forEach(o => {
              ids.push(o.dimWithdrawCashId);
            });
            dimWithdrawCashService.updateHandleState(ids).then(() => {
              that.loadTableData();
              that.$refs.pageTable.tableLoading = false;
            }).catch(err => {
              that.$refs.pageTable.tableLoading = false;
            })
          }
      ).catch(error => {
        that.$refs.pageTable.tableLoading = false;
      });
    },
    datetimeFormatter(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd hh:mm:ss");
      }
      return v;
    },
    cashTypeFormatter(row, column) {
      let v = '-';
      if (row.withdrawCashType) {
        v = row.withdrawCashType;
        if (row.withdrawCashType) {
          v = row.withdrawCashType;
          if ('bondCash' == row.withdrawCashType) {
            v = '余额提现';
          }
          if ('profitCash' == row.withdrawCashType) {
            v = '主服务费提现';
          }
          if ('marketCash' == row.withdrawCashType) {
            v = '次服务费提现';
          }

        }
      }
      return v;
    },
    //提现方式格式化
    cashWayFormatter(row) {
      let v = '-';
      if (row.withdrawCashWay) {
        v = row.withdrawCashWay;
        if ('wechat' == row.withdrawCashWay) {
          v = '微信';
        }
        if ('bank' == row.withdrawCashWay) {
          v = '银行';
        }
      }
      return v;
    },
    handleStateFormatter(row) {
      let v = '未支付';
      if (row.handleState) {
        if ('handle' == row.handleState) {
          v = '已支付';
        }
        if ('unhandle' == row.handleState) {
          v = '未支付';
        }
      }
      return v;
    }

  }
}
</script>

<style scoped>
</style>
