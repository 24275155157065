<template>
  <div>
    <div>
      <div slot="toolbar">
        <el-button type="primary" @click.navite="addVersion">新增</el-button>
        <el-button @click.navite="removeVersion">删除</el-button>
      </div>
      <ws-page-table
          :data="tableData"
          ref="auditVersionConfigurationPageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          selection="single"
      >
        <el-table-column prop="auditConfigurationCode" label="业务审核编码" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="auditConfigurationName" label="业务审核说明" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="auditVersionCode" label="版本编码" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="inUse" label="是否启用" min-width="90" :formatter="yesOrNoFormatter"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" class="ws-iconbtn" @click.native="editVersion(scope.row)"><i
                class="fa fa-edit"></i></el-button>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <audit-version-configuration-edit :enum-map="enumMap" ref="versionEdit"
                                      @editCompleted="loadTableData"></audit-version-configuration-edit>
  </div>
</template>

<script>
import auditVersionConfigurationEdit from './components/auditVersionConfigurationEdit';
import pageTableMixin from "@/mixin/pageTableMixin.js";
import auditFlowService from "API/ba/auditFlowService"
import Objects from "COMJS/Objects";

export default {
  name: "auditVersionConfigurationList",
  components: {
    auditVersionConfigurationEdit: auditVersionConfigurationEdit
  },
  mixins: [pageTableMixin],
  props: {
    configInfo: {
      type: Object,
      default: ''
    }
  },
  data() {
    return {
      searchInfo: {auditConfigurationId: this.configInfo.auditConfigurationId, auditVersionCode: '', state: '1'},
      items: {
        auditVersionCode: {filter: "like"},
        auditConfigurationId: {filter: "EQ"},
        state: {filter: "EQ"}
      },
      sorts: {
        createTime: {order: "desc"}
      },
      enumMap: {},
      tempOnePage: {},
      tempOneSearch: {},
      tempTowPage: {},
      tempTowSearch: {}
    };
  },
  mounted() {
    this.initPage();
  },
  created() {
    let that = this;
    /*eventBus.$on('searchInfoAuditConfigurationStorage', (onePageInfo, oneSearchForm, towPageInfo, towSearchForm) => {
        Objects.deepCopy(that.tempOnePage, onePageInfo);
        Objects.deepCopy(that.tempOneSearch, oneSearchForm);

        Objects.deepCopy(that.tempTowPage, towPageInfo);
        Objects.deepCopy(that.tempTowSearch, towSearchForm);

        if (Objects.isNotEmpty(towPageInfo)) {
            Objects.deepCopy(this.pageInfo, towPageInfo);
        }

        if (Objects.isNotEmpty(towSearchForm)) {
            Objects.deepCopy(this.searchForm, towSearchForm);
        }
    });*/
  },
  destroyed() {
    let that = this;
    //eventBus.$emit('searchInfoAuditConfigurationStorage', that.tempOnePage, that.tempOneSearch, that.pageInfo, that.searchForm, null, null);
  },
  methods: {

    /**初始化页面*/
    initPage() {
      /*let types = "AGENCY_LEVEL,AGENCY_STATE,OPERATION_TYPE,AUDIT_STATE,AUDIT_ROLE_TYPE,YES_OR_NO";
      bimEnumService.getEnumByTypes(types).then((data) => {
          this.enumMap = data;
          this.loadVersionConfigurationPage();
      }).catch((data) => console.log(data));*/
    },
    /**读取数据*/
    loadTableData() {
      auditFlowService.findAuditVersionConfigurationPage(this.pageInfo, this.searchConfig).then(data => {
        this.querySuccess(data);
      })
    },
    /**删除*/
    removeVersion() {
      let that = this;
      let rows = this.$refs.auditVersionConfigurationPageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            that.$refs.auditVersionConfigurationPageTable.tableLoading = true;
            let ids = '';
            rows.forEach(o => {
              if (Objects.isEmpty(ids)) {
                ids = o.auditVersionConfigurationId;
              } else {
                ids += ',' + o.auditVersionConfigurationId;
              }
            });
            auditFlowService.removeAuditVersionConfigurationInfoByIds(ids).then(() => {
              that.loadTableData();
              that.$refs.auditVersionConfigurationPageTable.tableLoading = false;
            }).catch(err => {
              that.$refs.auditVersionConfigurationPageTable.tableLoading = false;
            })
          }
      )
    },
    /**新增*/
    addVersion() {
      this.$refs.versionEdit.display(this.configInfo, 'ADD');
    },
    /**编辑*/
    editVersion(row) {
      this.$refs.versionEdit.display(row, 'EDIT');
    },
    yesOrNoFormatter(value) {
      let v = "-";
      if (value.inUse === '1') {
        v = "是";
      } else {
        v = "否";
      }
      return v;
    }
  }
}
</script>

<style scoped>

</style>
