<template>
  <div class="logistics-wrap">
    <el-form :inline="true" class="rec-inline1">
      <el-form-item label="收件人：">
        <el-input v-model="searchInfo.receiverName" />
      </el-form-item>
      <el-form-item label="收件人手机：">
        <el-input v-model="searchInfo.receivingPhone" />
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="rec-inline">
      <el-form-item label="创建时间：">
        <el-date-picker
            v-model="searchInfo.createTime"
            size="mini"
            type="daterange"            
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>   
      </el-form-item>
      <el-form-item label="出库单状态：">
        <el-select v-model="searchInfo.completionState" clearable placeholder="选择类型">
          <el-option
            v-for="item in settlementType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="importTime"
        label="导入时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="dealerAccount"
        label="经销商账号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="receiverName"
        label="收件人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="receivingPhone"
        label="收件人手机"
      ></el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="outboundDocumentsCode"
        label="出库单号"
        width="300"
      ></el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="completionState"
        label="出库单状态"
      ></el-table-column>
      <el-table-column prop="carrierAppCode" :show-overflow-tooltip="true" label="运单号"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="物流收货"
              @click="openDialog(scope.row)"
              v-if="scope.row.completionState == '已出库未签收'"
            >
              <i class="icon-delivery"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="查看凭证"
              @click="openImgDialog(scope.row)"
              v-if="scope.row.completionState == '已出库已签收'"
            >
              <i class="icon-delivery-show"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="确认收货" :visible.sync="deliveryDialog" width="606px">
      <div class="theTit">收货凭证（非必填）</div>
      <draggable-upload
        :width="82"
        :height="82"
        :limit="3"
        :uploadUrl="uploadUrl()"
        :requestHeaders="requestHeaders"
        v-model="allPhotoId"
      ></draggable-upload>

      <div class="btn-wrap">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="addReceiverGoods">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="查看收货凭证"
      :visible.sync="deliveryImgDialog"
      width="606px"
    >
      <el-image
        v-for="(item, index) in photoIds"
        :key="index"
        style="width: 100px; height: 100px"
        :src="photoIds[index]"
        :preview-src-list="photoIds"
      >
      </el-image>
      <div class="btn-wrap">
        <el-button @click="closeImgDialog">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";

export default {
  name: "receiveList",
  data() {
    return {
      photoIds:[],
      value: [],
      options: [],
      timeArr: [],
      searchInfo: {
        skuCode: "", //库品编码
        skuName: "", //库品名称
        purchaseBatchCode: "", //库品批次
        receiveName: "", //领用人
        createTime: "",
      },
      items: {
        operationCode: { filter: "like" },
        receiverName: { filter: "like" },
        receivingPhone: { filter: "like" },
        skuGroupCode: { filter: "like" },
        recipientFullAddress: { filter: "like" },
        createTime: { filter: "between", dataType: "date" },
      },
      deliveryDialog: false,
      allPhotoId: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
      curId: "",
      deliveryImgDialog: false,
      settlementType: [
        {
          value: "0",
          label: "在库",
        },
        {
          value: "1",
          label: " 锁定库存",
        },
        {
          value: "2",
          label: "已出库未签收",
        },
        {
          value: "3",
          label: "取消",
        },
        {
          value: "4",
          label: "已出库已签收",
        },
      ],
    };
  },
  mixins: [pageTableMixin],
  components: {
    draggableUpload,
  },
  // mounted() {
  //   this.loadTableData()
  // },
  methods: {
    openImgDialog(row){
      const loading = this.$loading({
        lock: true,
        text: "请求图片中...",
      });
      WhmList.getEvidenceImg(row.outboundDocumentsId)
        .then((data) => {
          if (data) {
            this.deliveryImgDialog = true
            this.photoIds = data.fileName.split(",")
          }
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    closeImgDialog() {      
      this.deliveryImgDialog = false;
    },
    close() {
      this.deliveryDialog = false;
    },
    toDetail(row) {
      this.$router.push(`/som/deliveryDetail/${row.outboundDocumentsId}`);
    },
    addReceiverGoods() {
      let picsUrl = []; //获取图片地址
      for (let i in this.allPhotoId) {
        picsUrl.push(this.allPhotoId[i].response);
      }
      let picStr = picsUrl.join(",");
      let params = {
        outboundId: this.curId,
        evidencePicUrl: picStr,
      };
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      WhmList.outReceiverGoods(params)
        .then((res) => {
          if (res.msg == "OK") {
            this.curId = "";
            this.$message({
              message: "上传凭证成功",
              type: "success",
            });
            this.loadTableData()
            this.deliveryDialog = false;
          }
          loading.close();
        })
        .catch(() => {
          this.deliveryDialog = false;
          loading.close();
        });
    },
    openDialog(row) {
      this.curId = row.outboundDocumentsId;
      this.deliveryDialog = true;
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("whm_img");
      return url;
    },
    loadTableData() {
      if(this.searchInfo.createTime === null){
        this.searchInfo.createTime = ""
      }  
      WhmList.queryLogisticsPages(this.pageInfo, this.searchConfig).then(
        (data) => {
          data.list = data.list.map((e) => {
            return {
              outboundDocumentsId: e.outboundDocumentsId,
              importTime: tools.formatDate(
                new Date(e.importTime),
                "yyyy-MM-dd hh:mm:ss"
              ),
              dealerAccount: e.dealerAccount,
              receiverName: e.receiverName,
              receivingPhone: e.receivingPhone,
              outboundDocumentsCode: e.outboundDocumentsCode,
              completionState: e.completionState,              
              transportNo: e.transportNo,
            };
          });
          this.querySuccess(data);
        }
      );
    },
  },
};
</script>
<style>
.logistics-wrap .rec-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.logistics-wrap .rec-inline1 {
  padding: 20px 0 0 20px;
  background: #fff;
}
.logistics-wrap .el-form--inline .el-form-item {
  margin-right: 20px;
  margin-bottom: 0;
}
.logistics-wrap .el-input--suffix .el-input__inner {
  width: 200px;
}
.icon-download,
.icon-delivery,
.icon-delivery-show {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-size: cover;
}
.icon-download {
  background-image: url("~ASSETS/images/download.png");
}
.icon-delivery {
  background-image: url("~ASSETS/images/delivery.png");
}
.icon-delivery-show{background-image: url("~ASSETS/images/del-show.png");}
.logistics-wrap .icon-operation a {
  margin-right: 15px;
}
.logistics-wrap .btn-wrap {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.theTit {
  margin: 20px 0 28px 0;
}
.el-image{ margin-right: 10px;}

</style>