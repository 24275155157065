<template>
  <div>
    <el-tabs type="border-card" :closable="false" v-model="activeName" class="ws-Tab-closable">
      <el-tab-pane label="微信待支付" name="unhandleWechat">
        <unhandle-cash-by-wechat-list2 ref=""></unhandle-cash-by-wechat-list2>
      </el-tab-pane>
      <el-tab-pane label="银行卡待支付" name="unhandleBank">
        <unhandle-cash-by-bank-list ref="unhandleBank"></unhandle-cash-by-bank-list>
      </el-tab-pane>
      <el-tab-pane label="提现支付历史" name="cashLog">
        <withdraw-cash-log-list ref="cashLog"></withdraw-cash-log-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UnhandleCashByWechatList from './unhandleCashByWechatList.vue';
import UnhandleCashByWechatList2 from './unhandleCashByWechatList2.vue';
import UnhandleCashByBankList from './unhandleCashByBankList.vue';
import WithdrawCashLogList from './withdrawCashLogList.vue';

export default {
  name: "withdrawCashAuditManagement",
  components: {
    'unhandleCashByWechatList': UnhandleCashByWechatList,
    'unhandleCashByWechatList2': UnhandleCashByWechatList2,
    'unhandleCashByBankList': UnhandleCashByBankList,
    'withdrawCashLogList': WithdrawCashLogList,
  },
  data() {
    return {
      activeName: 'unhandleWechat',
    }
  },
  created() {

  },
  destroyed() {
  },
  mounted: function () {
  },
  methods: {},
  computed: {}, watch: {}
}
</script>

<style scoped>

</style>
