<template>
  <div class="whm-add">
    <div class="step-title">新增组合库品</div>
    <el-form
      :model="whmInfo"
      label-width="140px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item label="组合编码:">
        {{whmInfo.skuGroupCode}}
      </el-form-item>
      <el-form-item label="组合名称:">
        {{whmInfo.groupName}}
      </el-form-item>

      <el-form-item label="组合库品:"></el-form-item>
    </el-form>

    <ws-page-table
      :data="goodsList"
      ref="pageTable"
      :pageable="false"
      :showIndex="true"
    >
      <el-table-column label="库品名称" width="360px">
        <template slot-scope="scope">
          {{ scope.row.skuName }}
        </template>
      </el-table-column>
      <el-table-column label="库品数量">
        <template slot-scope="scope">
          {{ scope.row.skuNum }}          
        </template> 
      </el-table-column>      
    </ws-page-table>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">返回</el-button>
    </div>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import userModule from "@/module/userModule";
import invQuery from "API/whm/invQuery";

export default {
  name: "invGroupDetail",
  data() {
    return {
      //thumbnailImgs: [], //上传图片的字段
      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        skuId: "",
        skuGroupCode: "",//组合编码
        groupName: "",//组合名称
      },
      goodsList: [{
        skuId: "",
        skuCode: "",
        skuName: "",
        skuNum: "",
      }],    
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.addGoOn();
    const that = this
    if (this.$route.params.id) {
      that.loadTableData()
    }
  },
  methods: {
    loadTableData(){
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      invQuery.detailSkuGroup(this.$route.params.id).then((data) => {
        this.whmInfo = data
        this.goodsList = data.groupDetailList
        loading.close();
      });
    },
    backPre() {
      //返回上一页
      this.$router.push("/whm/whmList");
    },
  },
};
</script>

<style lang="scss" scoped>
.whm-add .el-input--small .el-input__inner {
  width: 150px;
}
.whm-add .shelf-life.el-input--small {
  width: 150px;
}
.whm-add .step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
  color: #333;
}
.whm-add .select-btn {
  width: 200px;
  text-align: center;
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}
.whm-add .whm-sku {
  padding: 20px;
  display: inline-block;
  border: 1px solid #ddd;
}
.whm-sku .select-btn {
  margin-top: 10px;
}
.whm-add .select-btn-wrap {
  padding: 12px;
}
.whm-add .select-label {
  padding-left: 12px;
  width: 188px;
  background: #f8f8f8;
}
.whm-add .c9 {
  margin-left: 12px;
  color: #999;
}
.whm-add .steps-btn-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.whm-add .search-result {
  height: 360px;
  overflow-y: auto;
}
.whm-add .input-txt-btn {
  margin-bottom: 20px;
  display: flex;
}
.whm-add .input-txt-btn .el-input {
  width: 160px;
}
.whm-add .table-wrap .el-dialog__header {
  border-bottom: 0;
}
.whm-add .table-wrap .el-table .cell {
  line-height: 42px;
}
.whm-add .table-wrap .el-input--small .el-input__inner {
  width: 40px;
}
.whm-add .input-txt-btn .el-input--small .el-input__inner {
  width: 150px;
}
.whm-add .btn-blue-wrap {
  display: flex;
  justify-content: flex-end;
}
.whm-add .btn-blue,
.whm-add .btn-gray {
  margin-top: 20px;
  width: 80px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  background: #1b73e8;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.whm-add .btn-gray {
  margin-left: 10px;
  width: 78px;
  height: 34px;
  border: 1px solid #ddd;
  color: #999;
  background: #fff;
}

</style>