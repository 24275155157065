<template>
  <div>
    <div style="position: relative;">
      <el-form label-width="120px" :inline="true">
        <el-form-item>
          <el-button type="primary" @click="openAddDialog" :disabled="searchInfo.roleSystem!='0'">新增</el-button>
        </el-form-item>
      </el-form>
      <div style="position: absolute;top: 0px;right: 0px;">
        <el-button type="text" @click="userRolePopShow">人员角色管理</el-button>
      </div>
    </div>

    <el-tabs
        v-model="searchInfo.roleSystem"
        type="card"
        @tab-click="changeTable"
    >
      <el-tab-pane label="自定义角色" name="0"></el-tab-pane>
      <el-tab-pane label="系统角色" name="1">
        <span style="color: red;">系统角色为组件自带角色，不可增删改，不可赋予，可以编辑菜单权限</span>
      </el-tab-pane>
    </el-tabs>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column prop="roleCode" label="编码"></el-table-column>
      <el-table-column prop="roleName" label="名称"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <!--
      <el-table-column prop="userList" label="用户列表"></el-table-column>
      -->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="权限">
              <i class="CCSFont icon-table_2" @click="openAuthDialog(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="人员"
               v-if="searchInfo.roleSystem=='0'">
              <i class="CCSFont icon-sidebar_7" @click="openUserDialog(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="编辑"
               v-if="searchInfo.roleSystem=='0'">
              <i class="CCSFont icon-table_1" @click="openAddDialogForEdit(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="删除"
               v-if="searchInfo.roleSystem=='0'">
              <i class="el-icon-delete-solid" @click="removeRole(scope.row.roleId)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <!--新增-->
    <el-dialog :title="currentInfo.roleId ? '编辑' : '新增'" :visible.sync="addDialogVisible"
               @close="addDialogVisible = false" width="606px">
      <el-form label-width="140px">
        <el-form-item required label="编号：">
          <el-input v-model="currentInfo.roleCode" :disabled="currentInfo.roleId!=null"></el-input>
        </el-form-item>
        <el-form-item required label="名称：">
          <el-input v-model="currentInfo.roleName"></el-input>
        </el-form-item>
        <el-form-item required label="备注：">
          <el-input v-model="currentInfo.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="菜单权限" :visible.sync="authDialogVisible"
               @close="authDialogVisible = false" width="1200px">
      <el-tabs
          v-model="authDialogTabMode"
          type="card"
      >
        <el-tab-pane label="后台菜单" name="web"></el-tab-pane>
        <el-tab-pane label="管理员APP工作台" name="admin-app"></el-tab-pane>
        <el-tab-pane label="会员APP工作台" name="distributor-app"></el-tab-pane>
      </el-tabs>
      <el-checkbox-group v-model="roleMenuAllIdList">
        <div v-if="authDialogTabMode=='web'">
          <el-row v-for="first in webMenuAllTree" :key="first.menuId">
            <el-col :span="2">
              <div style="min-height: 60px;">
                <el-checkbox :label="first.menuId" :key="first.menuId">{{ first.menuName }}</el-checkbox>
              </div>
            </el-col>
            <el-col :span="22">
              <el-row v-for="second in first.subMenus" :key="second.menuId">
                <el-col :span="3">
                  <div style="min-height: 40px;">
                    <el-checkbox :label="second.menuId" :key="second.menuId">{{ second.menuName }}</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="21">
                  <el-row type="flex" style="flex-wrap:wrap;">
                    <el-col :span="2" v-for="third in second.subMenus" :key="third.menuId">
                      <div style="min-height: 20px;">
                        <el-checkbox :label="third.menuId" :key="third.menuId">{{ third.menuName }}</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div v-if="authDialogTabMode=='admin-app'">
          <el-row v-for="first in adminAppMenuAllTree" :key="first.menuId">
            <el-col :span="3">
              <div style="min-height: 60px;">
                <el-checkbox :label="first.menuId" :key="first.menuId">{{ first.menuName }}</el-checkbox>
              </div>
            </el-col>
            <el-col :span="21">
              <el-row type="flex" style="flex-wrap:wrap;">
                <el-col :span="2" v-for="second in first.subMenus" :key="second.menuId">
                  <div style="min-height: 40px;">
                    <el-checkbox :label="second.menuId" :key="second.menuId">{{ second.menuName }}</el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div v-if="authDialogTabMode=='distributor-app'">
          <el-row v-for="first in distributorAppMenuAllTree" :key="first.menuId">
            <el-col :span="3">
              <div style="min-height: 60px;">
                <el-checkbox :label="first.menuId" :key="first.menuId">{{ first.menuName }}</el-checkbox>
              </div>
            </el-col>
            <el-col :span="21">
              <el-row type="flex" style="flex-wrap:wrap;">
                <el-col :span="2" v-for="second in first.subMenus" :key="second.menuId">
                  <div style="min-height: 40px;">
                    <el-checkbox :label="second.menuId" :key="second.menuId">{{ second.menuName }}</el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="authDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="authDialogConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="维护人员" :visible.sync="userDialogVisible"
               @close="userDialogVisible = false" width="1200px">
      <div v-loading="userDialogLoading">
        <div style="display: flex;">
          <div style="font-size: 14px;min-width: 100px;">角色内人员</div>
          <el-checkbox-group v-model="inGroupUserIdList">
            <el-checkbox
                v-for="user in inGroupUserList"
                :label="user.userId"
                :key="user.userId">
              {{ user.fullName || user.userAccount }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="display: flex;margin-top: 20px;">
          <div style="font-size: 14px;min-width: 100px;">角色外人员</div>
          <el-checkbox-group v-model="outGroupUserIdList">
            <el-checkbox v-for="user in outGroupUserList" :label="user.userId" :key="user.userId">
              {{ user.fullName || user.userAccount}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="userDialogConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <user-role-manager ref="userRolePop"></user-role-manager>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import bimMenuService from "API/ba/BimMenuService";
import bimRoleService from "API/ba/BimRoleService";
import Objects from "COMJS/Objects";
import UserRoleManager from "PAGES/ba/role/userRoleManager";

export default {
  name: "roleManager",
  components: {UserRoleManager},
  data() {
    return {
      searchInfo: {
        roleSystem: '0'
      },
      tableData: [],
      addDialogVisible: false,
      authDialogVisible: false,
      userDialogVisible: false,
      userDialogLoading: false,
      currentInfo: {},
      authDialogTabMode: 'web',
      webMenuAllTree: [],
      adminAppMenuAllTree: [],
      distributorAppMenuAllTree: [],
      roleMenuAllIdList: [],
      //相关用户
      inGroupUserIdList: [],
      inGroupUserList: [],
      outGroupUserIdList: [],
      outGroupUserList: [],
      //当前操作roleId
      currentRoleId: null,
    }
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
    this.initMenuData();
  },
  methods: {
    loadTableData() {
      bimRoleService.getPageByParam(this.pageInfo, this.searchConfig).then(
          data => {
            this.querySuccess(data)
          }
      )
    },
    initMenuData() {
      const _this = this;
      if (this.webMenuAllTree.length == 0) {
        bimMenuService.getMenuAllTree('0').then(data => {
          _this.webMenuAllTree = data;
        })
      }
      if (this.adminAppMenuAllTree.length == 0) {
        bimMenuService.getMenuAllTree('1').then(data => {
          _this.adminAppMenuAllTree = data;
        })
      }
      if (this.distributorAppMenuAllTree.length == 0) {
        bimMenuService.getMenuAllTree('2').then(data => {
          _this.distributorAppMenuAllTree = data;
        })
      }
    },
    changeTable() {
      this.tableData = [];
      this.pageInfo.pageNo = 1;
      this.loadTableData();
    },
    //======================================
    openAddDialog() {
      this.currentInfo = {}
      this.addDialogVisible = true
    },
    openAddDialogForEdit(row) {
      this.currentInfo = row
      this.addDialogVisible = true
    },
    addConfirm() {
      if (this.currentInfo.roleId) {
        bimRoleService.update(this.currentInfo).then(data => {
          this.loadTableData()
          this.addDialogVisible = false
        })
      } else {
        bimRoleService.create(this.currentInfo).then(data => {
          this.loadTableData()
          this.addDialogVisible = false
        })
      }
      //console.log("addConfirm " + this.currentInfo)
    },
    removeRole(roleId) {
      this.$confirm('即将执行删除角色操作，相关人员将失去角色权限，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            bimRoleService.delete(roleId).then(data => {
              this.loadTableData()
            })
          }
      )
    },
    //======================================
    async openAuthDialog(row) {
      this.currentRoleId = row.roleId;
      this.roleMenuAllIdList = [];
      this.authDialogVisible = true;
      let thisRoleMenuIdList = await bimRoleService.queryByRoleCode(row.roleCode)
      this.roleMenuAllIdList = thisRoleMenuIdList;
    },

    authDialogConfirm() {
      bimRoleService.saveBimRoleMenus(this.currentRoleId, this.roleMenuAllIdList).then(data => {
        this.loadTableData()
        this.authDialogVisible = false;
      })
    },
    //======================================
    async openUserDialog(row) {
      this.currentRoleId = row.roleId;
      this.userDialogVisible = true;
      this.userDialogLoading = true;
      this.inGroupUserIdList = [];
      this.inGroupUserList = [];
      this.outGroupUserIdList = [];
      this.outGroupUserList = [];
      //开始
      let inGroupIdMap = {}
      const _this = this
      let inGroupData = await bimRoleService.findUserByRoleId(this.currentRoleId)
      inGroupData.map(e => {
        inGroupIdMap[e.userId] = '1'
        _this.inGroupUserIdList.push(e.userId)
        _this.inGroupUserList.push({fullName: e.fullName,userAccount:e.userAccount, userId: e.userId})
      })
      let allAdminData = await bimRoleService.findUserByRoleCode('shop_admin')
      //console.log(allAdminData)
      allAdminData.map(e => {
        if (!inGroupIdMap[e.userId]) {
          //_this.outGroupUserIdList.push(e.userId)
          _this.outGroupUserList.push({fullName: e.fullName,userAccount:e.userAccount, userId: e.userId})
        }
      })
      this.userDialogLoading = false;
    },
    userDialogConfirm() {
      //console.log(this.inGroupUserIdList)
      //console.log(this.outGroupUserIdList)
      let ids;
      if (this.inGroupUserIdList && this.inGroupUserIdList.length > 0) {
        this.inGroupUserIdList.map(e => {
          if (Objects.isEmpty(ids)) {
            ids = e;
          } else {
            ids += ',' + e;
          }
        })
      }
      if (this.outGroupUserIdList && this.outGroupUserIdList.length > 0) {
        this.outGroupUserIdList.map(e => {
          if (Objects.isEmpty(ids)) {
            ids = e;
          } else {
            ids += ',' + e;
          }
        })
      }
      bimRoleService.addBimUserRole(this.currentRoleId, ids).then(data => {
        this.loadTableData()
        this.userDialogVisible = false;
      })
    },
    userRolePopShow(){
      this.$refs.userRolePop.$emit("openDialog",null);
    }
  }
}
</script>

<style>
.dialog-footer {
  text-align: center;
}
</style>