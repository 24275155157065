<template>
  <div>
    <div>
      <div slot="toolbar">
        <el-button type="primary" @click.navite="addConfig">新增</el-button>
        <el-button @click.navite="removeConfig">删除</el-button>
      </div>
      <ws-page-table
          :data="tableData"
          ref="auditConfigurationPageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          selection="single"
      >
        <el-table-column prop="auditConfigurationCode" label="业务审核编码" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="auditConfigurationName" label="业务审核说明" min-width="90"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" class="ws-iconbtn" @click.native="editConfig(scope.row)"><i
                class="fa fa-edit"></i></el-button>
          </template>
        </el-table-column>
      </ws-page-table>

    </div>

    <audit-configuration-edit ref="configEdit" @editCompleted="loadTableData"></audit-configuration-edit>

  </div>
</template>

<script>
import Objects from 'COMJS/Objects'
import AuditConfigurationEdit from './components/auditConfigurationEdit';
import pageTableMixin from "@/mixin/pageTableMixin.js";
import auditFlowService from "API/ba/auditFlowService"

export default {
  name: "auditConfigurationList",
  components: {
    auditConfigurationEdit: AuditConfigurationEdit
  },
  mixins: [pageTableMixin],
  data() {
    return {
      searchInfo: {state: '1'},
      items: {
        auditConfigurationName: {filter: "like"},
        state: {filter: "EQ"}
      },
      sorts: {
        auditConfigurationCode: {order: "asc"},
        createTime: {order: "desc"}
      },
      active: 0
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    /**初始化页面*/
    initPage() {
      /*let types = "AGENCY_LEVEL,AGENCY_STATE,OPERATION_TYPE,AUDIT_STATE,AUDIT_ROLE_TYPE";
      bimEnumService.getEnumByTypes(types).then((data) => {
          this.enumMap = data;
          this.loadConfigurationPage();
      }).catch((data) => console.log(data));*/
    },
    /**读取数据*/
    loadTableData() {
      auditFlowService.findAuditConfigurationPage(this.pageInfo,this.searchConfig).then(
          data=>{
            this.querySuccess(data);
          }
      )
    },
    /**删除*/
    removeConfig() {
      let that = this;
      let rows = this.$refs.auditConfigurationPageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            that.$refs.auditConfigurationPageTable.tableLoading = true;
            let ids = '';
            rows.forEach(o => {
              if (Objects.isEmpty(ids)) {
                ids = o.auditConfigurationId;
              } else {
                ids += ',' + o.auditConfigurationId;
              }
            });
            auditFlowService.removeAuditConfigurationInfoByIds(ids).then(() => {
                that.loadTableData();
                that.$refs.auditConfigurationPageTable.tableLoading = false;
            }).catch(()=>{
              that.$refs.auditConfigurationPageTable.tableLoading = false;
            })
          }
      )
    },
    /**新增*/
    addConfig() {
      this.$refs.configEdit.display(null, 'ADD');
    },
    /**编辑*/
    editConfig(row) {
      this.$refs.configEdit.display(row, 'EDIT');
    },
  }
}
</script>

<style scoped>

</style>
