<template>
  <div>
    <audio controls ref="audio_chengong" style="display: none;">
      <source src="../../../assets/audio/成功.mp3" type="audio/mp3">
    </audio>
    <audio controls ref="audio_chongfu" style="display: none;">
      <source src="../../../assets/audio/重复.mp3" type="audio/mp3">
    </audio>
    <audio controls ref="audio_qingshaodeng" style="display: none;">
      <source src="../../../assets/audio/请稍等.mp3" type="audio/mp3">
    </audio>
    <audio controls ref="audio_baocunzhong" style="display: none;">
      <source src="../../../assets/audio/成功正在自动保存.mp3" type="audio/mp3">
    </audio>
    <audio controls ref="audio_baocunchengong" style="display: none;">
      <source src="../../../assets/audio/保存成功.mp3" type="audio/mp3">
    </audio>
    <audio controls ref="audio_baocunshibai" style="display: none;">
      <source src="../../../assets/audio/保存失败.mp3" type="audio/mp3">
    </audio>
    <el-form :model="param" label-width="110px" class="step-form">
      <div class="step-wrapper">
        <div class="step-title" style="display: flex;">
          <div>当前自动保存频率：</div>
          <el-select v-model="autoSaveP" style="width: 100px;" filterable>
            <el-option
                v-for="item in autoSavePList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <div style="margin-left: 20px;">已自动保存数量：{{autoSaveNum}}</div>
        </div>
        <el-form-item required label="经销商账号：" prop="dealerAccount">
          <el-select v-model="param.dealerAccount" filterable placeholder="请选择"
                     @change="agentSelectChange">
            <el-option
                v-for="item in agentList"
                :key="item.dealerAccount"
                :label="item.dealerNameAccount"
                :value="item.dealerAccount">
            </el-option>
          </el-select>
          <el-button type="primary" plain style="margin-left: 10px;" @click="goToCreateDealer">新增经销商</el-button>
        </el-form-item>
        <el-form-item label="渠道商名称：" prop="channelName">
          <el-select v-model="param.channelName" filterable placeholder="请选择"
                     :disabled="!param.dealerAccount">
            <el-option
                v-for="item in channelNames"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" plain style="margin-left: 10px;"
                     :disabled="!param.dealerAccount" @click="goToCreateChannel">新增渠道商</el-button>
        </el-form-item>
        <el-form-item label="库品编码:">
          <div
              class="select-btn"
              v-if="!param.skuCode"
              @click="$refs.selectSkuPopup.dialogVisible = true"
          >
            请选择库品编码
          </div>
          <template v-else>
            <span>{{ param.skuCode }}</span>
            <span
                class="tips-btn"
                style="dispaly: inline-block; margin-left: 15px"
                @click="$refs.selectSkuPopup.dialogVisible = true"
            >重选库品编码</span
            >
          </template>
        </el-form-item>
        <el-form-item label="库品名称:">
          <el-input v-model="param.skuName" disabled></el-input>
        </el-form-item>

        <el-form-item label="库品批次:">
          <div
              class="select-btn"
              v-if="!param.skuBatch"
              @click="showBatchDialog"
          >
            请选择库品批次
          </div>
          <template v-else>
            <span>{{ param.skuBatch }}</span>
            <span
                class="tips-btn"
                style="dispaly: inline-block; margin-left: 15px"
                @click="$refs.selectSkuBatchPopup.dialogVisible = true"
            >重选库品批次</span
            >
          </template>
        </el-form-item>
        <el-form-item label="库品生产日期：" prop="dealerName">
          <el-input v-model="param.skuProductionDate" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="库品供应商：" prop="dealerName">
          <el-input v-model="param.skuSupplier" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="库品保质期：" prop="dealerName">
          <el-input v-model="param.skuShelfLife" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="出货价：" prop="shipmentPrice">
          <el-input v-model="param.shipmentPrice" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="param.remark" type="textarea"></el-input>
        </el-form-item>
        <div class="tips" style="color: red;">=======================开始扫描=======================</div>
        <el-form-item label="扫描或输入序列：">
          <el-input v-model="tempStr" @change="addToSet"></el-input>
          <div class="tips" style="color: red;">扫描时请确保光标在本输入框内</div>
        </el-form-item>
        <el-form-item label="已扫描序列：">
          <el-tag
              v-for="tag in numSet.keys()"
              closable
              :key="tag"
              :type="'info'"
              @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <!--
          <div class="tips" style="color: red;">扫描时请确保光标不在任何输入框内</div>
          -->
        </el-form-item>
        <el-form-item label="本次录入数量：">
          <div class="tips">{{ numSet.size }}</div>
        </el-form-item>
      </div>
    </el-form>
    <div class="steps-btn-wrapper">
      <el-button type="primary" @click="saveBind">保存</el-button>
    </div>

    <select-sku-popup ref="selectSkuPopup" @submit="selectSku"></select-sku-popup>
    <select-sku-batch-popup ref="selectSkuBatchPopup" @submit="selectBatch"></select-sku-batch-popup>

  </div>
</template>

<script>
import Set from "COMJS/Set";
import dealerService from "API/dea/dealerService.js";
import dealerSerialInfo from "API/whm/dealerSerialInfo.js";
import selectSkuPopup from "../inventory/selectSkuPopup";
import selectSkuBatchPopup from "../inventory/selectSkuBatchPopup";
import tools from "COMJS/tools";

export default {
  name: "serialNumOutboundInput",
  data() {
    return {
      tempStr: '',
      param: {
        bimUser: "",
        dealerAccount: "",
        dealerName: "",
        shipmentPrice: "",
        remark: "",
        skuCode:'',
        skuBatch:'',
        skuProductionDate:'',
        skuSupplier:'',
        skuShelfLife:''
      },
      autoSaveP:20,
      autoSaveNum:0,
      autoSaveGoing:false,
      numSet: new Set(),
      agentList: [],
      channelNames:[],
      autoSavePList:[],
    };
  },
  components: {
    selectSkuPopup,
    selectSkuBatchPopup
  },
  mounted() {
    dealerService.queryByInfo().then(
        data => {
          //dealerNameAccount
          this.agentList = data.map(e=>{
            return {
              ...e,
              "dealerNameAccount":e.dealerName+","+e.dealerAccount
            }
          });
        }
    );
    for (let i = 0; i < 101; i++) {
      this.autoSavePList.push(i)
    }
  },
  methods: {
    selectBatch(batch){
      if(batch){
        //console.log(batch)
        this.$set(this.param,'skuBatch',batch.purchaseBatchCode);
        this.$set(this.param,'skuProductionDate',tools.formatDate(
            new Date(batch.productionDate),
            "yyyy-MM-dd hh:mm:ss"
        ));
        this.$set(this.param,'skuSupplier',batch.supplierName);
        this.$set(this.param,'skuShelfLife',tools.formatDate(
            new Date(batch.doomsday),
            "yyyy-MM-dd hh:mm:ss"
        ));
      }
    },
    selectSku(sku){
      if(sku){
        //console.log(sku)
        this.$set(this.param,'skuCode',sku.skuCode);
        this.$set(this.param,'skuName',sku.skuName);
        this.$set(this.param,'skuId',sku.id);
        //console.log(this.param)
        this.$set(this.param,'skuBatch','');
        this.$set(this.param,'skuProductionDate','');
        this.$set(this.param,'skuSupplier','');
        this.$set(this.param,'skuShelfLife','');
      }
    },
    agentSelectChange(val) {
      const selectAgent = this.agentList.filter(e => val == e.dealerAccount)[0];
      console.log(selectAgent)
      this.channelNames = selectAgent.channelNames;
      this.$set(this.param,'channelName','');
      this.param.dealerId = selectAgent.dealerId;
      this.param.bimUser = selectAgent.bimUserId;
      this.param.dealerName = selectAgent.dealerName;
    },
    addToSet(value){
      //序列号不能长于36位
      /*if(value.indexOf("qj/")>-1){
        value = value.split("qj/")[1]
      }*/
      let last = value.lastIndexOf("/")
      if(last>-1){
        value = value.substr(last+1)
      }
      if(!value){
        return;
      }
      if(value.length>36){
        this.$message({
          message: "序列号不能长于36位",
          type: "warning"
        })
        return;
      }

      if(this.autoSaveGoing){
        this.$refs.audio_qingshaodeng.play();
        this.tempStr = '';
        return;
      }

      let oldSize = this.numSet.size
      this.numSet.add(value);
      this.tempStr = '';

      if(oldSize == this.numSet.size){
        //重复的语音
        this.$refs.audio_chongfu.play();
      }else{
        if(this.numSet.size>=this.autoSaveP){
        //if(this.numSet.size>=3){
          //自动保存的语音
          this.$refs.audio_baocunzhong.play();
          this.autoSaveGoing = true;
          let _this = this;
          setTimeout(function(){
            _this.param.serialNumberList = _this.numSet.keys();
            dealerSerialInfo.save(_this.param).then(data => {
              //保存成功的语音
              _this.$refs.audio_baocunchengong.play();
              _this.autoSaveNum += _this.numSet.size;
              _this.numSet = new Set();
              _this.param.serialNumberList = [];
              _this.autoSaveGoing = false;
            }).catch(e=>{
              console.log(e)
              //保存失败的语音
              _this.$refs.audio_baocunshibai.play();
              _this.autoSaveGoing = false;
            });
          }, 2000 )
        }else{
          //成功的语音
          this.$refs.audio_chengong.play();
        }
      }
    },
    handleClose(tag) {
      this.numSet.delete(tag);
    },
    saveBind() {
      if (!this.param.bimUser) {
        this.$message({
          message: "请选择绑定账号",
          type: "warning"
        })
        return;
      }
      if (this.numSet.size == 0) {
        this.$message({
          message: "请扫描录入至少一个序列号",
          type: "warning"
        })
        return;
      }
      this.param.serialNumberList = this.numSet.keys();
      //console.log(this.param);
      const _this = this
      dealerSerialInfo.save(this.param).then(data => {
        _this.$message({message: "绑定成功",type:"success"})
        _this.$router.push(`/whm/outbound/serialNumOutboundDetail/${_this.param.bimUser}`)
      });
    },

    goToCreateDealer(){
      this.$store.commit("setSpecifyBackUrl", "/whm/outbound/serialNumOutboundInput");
      this.$router.push(`/dea/dealerEdit/undefined`)
    },
    goToCreateChannel(){
      this.$store.commit("setSpecifyBackUrl", "/whm/outbound/serialNumOutboundInput");
      this.$router.push(`/dea/dealerEdit/${this.param.dealerId}`)
    },
    showBatchDialog(){
      if(!this.param.skuCode){
        this.$message({
          message: "请先选择库品",
          type: "warning"
        })
        return;
      }
      this.$refs.selectSkuBatchPopup.showDialog(this.param.skuId,this.param.skuCode)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~ASSETS/css/components/productAdd.scss";

.el-inline-radio /deep/ .el-radio {
  display: inline-block;
}

.product-add .is-in-pagination .el-input__inner {
  width: 60px;
}
</style>
