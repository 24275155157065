<template>
  <!--body-->
  <div>
    <!--operator bar-->
    <div>
      <el-button type="primary" @click="showEditDialog">创建用户</el-button>
    </div>
    <!--search bar-->
    <div class="mt15">
      <el-form label-position="right" label-width="60px" :inline="true">
        <el-form-item label="姓名：">
          <el-input v-model="searchInfo.name" placeholder="模糊搜索"/>
        </el-form-item>
        <el-form-item label="账号：">
          <el-input v-model="searchInfo.account" placeholder="模糊搜索"/>
        </el-form-item>
        <el-form-item label="角色：">
          <div style="width: 220px;">
            <el-select v-model="searchInfo.roles" multiple placeholder="请选择" style="width: 100%;">
              <el-option
                  v-for="item in roleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--main-->
    <div class="mt15">
      <!--tab-->
      <div></div>
      <!--table-->
      <div>
        <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
            :isShadow="false"
        >
          <!--el-table-column prop="userCode" label="编号"></el-table-column-->
          <el-table-column prop="userAccount" label="账号" width="160"></el-table-column>
          <!--el-table-column prop="accountPassword" label="密码"></el-table-column-->
          <el-table-column prop="fullName" label="姓名" minWidth="120"></el-table-column>
          <el-table-column prop="nickName" label="昵称" minWidth="120">
            <template slot-scope="scope" v-if="scope.row.nickName">
              {{ decodeURI(scope.row.nickName) }}
            </template>
          </el-table-column>
          <el-table-column prop="roleCodes" label="角色" minWidth="200">
            <template slot-scope="scope">
              <el-tag v-for="(item,idx) in  scope.row.roleTranslateArr" :key="idx" class="mr5">{{ item }}</el-tag>
            </template>
          </el-table-column>
          <!--el-table-column prop="roleCodes" label="角色编码" :show-overflow-tooltip="true"></el-table-column-->
          <el-table-column label="电话" width="160">
            <template slot-scope="scope">
              {{ scope.row.telAreaCode }}-{{ scope.row.tel }}
            </template>
          </el-table-column>
          <!--el-table-column prop="userState" label="状态" width="100"></el-table-column-->
          <el-table-column prop="createTime" label="创建时间" :formatter="dateFormatter4Table"
                           width="120"></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="查看">
                  <i class="CCSFont icon-table_2" @click="showEditDialog(scope.row)"></i>
                </a>
                <a href="javascript: void(0);" title="权限">
                  <i class="el-icon-key" @click="userRolePopShow(scope.row)"></i>
                </a>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>

    <!--dialog-->
    <user-role-manager ref="userRolePop" @loadTableData="loadTableData"></user-role-manager>

    <el-dialog :title="dialog4Edit?'编辑':'创建'" :model="editUser"
               :visible.sync="dialogVisible" width="700px">
      <el-form label-width="80px" label-position="right">
        <el-form-item label="编号：">
          <span v-if="dialog4Edit">{{editUser.userCode}}</span>
          <span v-else>系统生成</span>
        </el-form-item>
        <el-form-item label="账号：" required>
          <el-input v-model="editUser.userAccount" placeholder="请输入账号" :disabled="dialog4Edit"></el-input>
        </el-form-item>
        <el-form-item label="密码：" v-if="dialog4Edit">
          <el-input v-model="editUser.accountPassword"></el-input>
          <p style="color: #b94a48">* 可修改</p>
        </el-form-item>
        <el-form-item label="姓名：" required>
          <el-input v-model="editUser.fullName" placeholder="请输入姓名" :disabled="dialog4Edit"></el-input>
        </el-form-item>
        <el-form-item label="昵称：" v-if="dialog4Edit">
          <span>{{decodeURI(editUser.nickName)}}</span>
        </el-form-item>
        <el-form-item label="角色：" v-if="dialog4Edit">
          <div style="width: 420px;">
            <el-tag v-for="(item,idx) in  editUser.roleTranslateArr" class="mr5" :key="idx">{{ item }}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="电话：" required>
          <el-input placeholder="请输入手机号" v-model="editUser.tel" :disabled="dialog4Edit">
            <template slot="prepend">+{{ editUser.telAreaCode }}</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editDialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import pageTableMixin from "@/mixin/pageTableMixin";
import userService from "API/bim/userService";
import roleService from "API/ba/BimRoleService";
import UserRoleManager from "PAGES/ba/role/userRoleManager";

export default {
  name: "adminManager",
  components: {UserRoleManager},
  data() {
    return {
      roleOptions: [
        {label: '系统管理员', value: 'sys_admin'},
        {label: '店铺管理员', value: 'shop_admin'},
        {label: '会员', value: 'distributor'},
        {label: '经销商', value: 'agent'}
      ],
      roleEnum: {},
      searchInfo: {
        name: '',
        account: '',
        roles: []
      },
      editUser:{},
      dialogVisible:false,
    }
  },
  computed:{
    dialog4Edit() {
      return this.editUser.userId
    }
  },
  mixins: [pageTableMixin],
  created() {
    this.loadRoleEnum()
  },
  mounted() {

  },
  methods: {
    loadRoleEnum() {
      this.roleEnum = {}
      roleService.getAllRoleList().then(res => {
        if (res && res.length > 0) {
          for (const re of res) {
            this.roleEnum[re.roleCode] = re.roleName
          }
        }
      })
    },
    searchSubmit() {
      this.pageInfo.pageNo = 1
      this.loadTableData()
    },
    showEditDialog(row) {
      if(row && row.userId){
        this.editUser = {
          ...row
        }
      }else{
        this.editUser = {telAreaCode:'86'}
        //this.$message.error("未开放功能");
        //return;
      }
      this.dialogVisible = true;
      //this.$message.success("showEditDialog"+row.userAccount);
    },
    editDialogConfirm(){
      if(this.dialog4Edit){
        let param={
          userId:this.editUser.userId,
          accountPassword:this.editUser.accountPassword
        }
        userService.modifyPassword(param).then(res=>{
          this.$message.success("修改密码成功");
          this.dialogVisible = false;
          this.loadTableData()
        })
      }else{
        if(!this.editUser.userAccount){
          this.$message.warning("请填写用户账号");
          return;
        }
        if(!this.editUser.fullName){
          this.$message.warning("请填写用户姓名");
          return;
        }
        if(!this.editUser.tel){
          this.$message.warning("请填写用户电话");
          return;
        }
        let param={
          ...this.editUser
        }
        userService.createUser(param).then(res=>{
          this.$message.success("创建成功");
          this.dialogVisible = false;
          this.loadTableData()
        })
      }
    },
    loadTableData() {
      userService.queryUserPage(this.queryParams).then(res => {
        res.list = res.list.map(e => {
          let codes = e.roleCodes.split(",")
          let translateCodes = []
          for (const code of codes) {
            if (this.roleEnum[code]) {
              translateCodes.push(this.roleEnum[code])
            } else {
              translateCodes.push(code)
            }
          }
          return {
            ...e,
            roleArr:codes,
            roleTranslateArr: translateCodes
          }
        })
        this.querySuccess(res)
      })
    },
    userRolePopShow(row){
      this.$refs.userRolePop.$emit("openDialog",row.userId);
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>

</style>