<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-button size="mini" type="primary" @click="goToInput">录入</el-button>
          <el-button size="mini" type="primary" @click="goToSearch">查询</el-button>
          <el-button size="mini" type="danger" @click="goToRemove">删除</el-button>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">经销商账号：</span>
            <el-input size="mini" v-model="searchInfo.dealerAccount"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">录入时间：</span>
            <el-date-picker
                style="margin-left: 18px"
                v-model="searchInfo.timeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="dealerName" label="经销商名称"></el-table-column>
      <el-table-column prop="dealerAccount" label="经销商账号"></el-table-column>
      <el-table-column prop="serialNum" label="总录入数量"></el-table-column>
      <el-table-column prop="lastTime" label="最近一次录入"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="goToDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerSerialInfo from "API/whm/dealerSerialInfo.js";
import tools from "COMJS/tools.js";

export default {
  name: "serialNumOutboundList",
  data() {
    return {
      searchInfo: {
        dealerAccount: "",
        timeRange:[]
      }
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      dealerSerialInfo.queryByInfoAtPage(this.pageInfo, this.searchInfo).then(
          data => {
            data.list = data.list.map(e => {
              return {
                bimUser: e.bimUser,
                dealerName: e.dealerName,
                dealerAccount: e.dealerAccount,
                serialNum: e.serialNum,
                lastTime: tools.formatDate(new Date(e.lastTime),'yyyy-MM-dd hh:mm:ss')
              };
            });
            this.querySuccess(data);
          }
      );
    },
    goToInput() {
      this.$router.push("/whm/outbound/serialNumOutboundInput");
    },
    goToRemove() {
      this.$router.push("/whm/outbound/serialNumOutboundRemove");
    },
    goToSearch() {
      this.$router.push("/whm/outbound/serialNumOutboundSearch");
    },
    goToDetail(row) {
      this.$router.push(`/whm/outbound/serialNumOutboundDetail/${row.bimUser}`);
    }
  }
};
</script>
