<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">订单编号：</span>
            <el-input size="mini" v-model="searchInfo.freshMilkOrderCode" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">快递单号：</span>
            <el-input size="mini" v-model="searchInfo.transportNo" class="address-dialog-input"></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">收件姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件电话：</span>
            <el-input size="mini" v-model="searchInfo.recipientPhoneNum" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品批次：</span>
            <el-input size="mini" v-model="searchInfo.milkBatchCode" class="address-dialog-input">
              <template slot="prepend">D.B.</template>
            </el-input>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
      <div class="filter_right">
      </div>
    </div>
    <div class="operate-bar">
      <div class="operate-bar-right">
        <el-button size="mini" type="primary" @click="pushWdt" v-if="searchInfo.transportState=='0'">推送旺店通</el-button>
        <!--
        <el-button size="mini" type="default" @click="exportExcel">导出EXCEL</el-button>
        <el-button size="mini" type="default" v-if="searchInfo.transportState=='0' && !isWdt" @click="importTransportNo">导入运单号</el-button>
        -->
      </div>
    </div>
    <el-tabs
        v-model="searchInfo.transportState"
        type="card"
        @tab-click="searchData"
    >
      <el-tab-pane label="待发货-未推送" name="0"></el-tab-pane>
      <el-tab-pane label="待发货-已推送" name="9"></el-tab-pane>
      <el-tab-pane label="已发货" name="1"></el-tab-pane>
      <el-tab-pane label="全部" name="all"></el-tab-pane>
    </el-tabs>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="false"
        :selection="'multi'"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div v-if="props.row.packageList && props.row.packageList.length>0">
            <div v-for="(item,index) of props.row.packageList" class="table-props">
              <div>包裹{{ index + 1 }}</div>
              <div>{{ item.freshMilkOrderPackageCode }}（包裹编号）</div>
              <div>{{ item.transportCompany || '未回传' }}（快递公司）</div>
              <div>{{ item.transportNo || '未回传'}}（快递单号）</div>
            </div>
          </div>
          <div v-else class="table-props">暂无包裹信息</div>
        </template>
      </el-table-column>
      <el-table-column prop="freshMilkOrderCode" label="订单编号" width="180"></el-table-column>
      <!--
      <el-table-column prop="freshMilkOrderPackageCode" label="包裹编号" width="200"></el-table-column>
      -->
      <el-table-column label="收件信息" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{ scope.row.recipientName }} {{ scope.row.recipientPhoneNum }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddress }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品内容" minWidth="150">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            鲜奶两瓶装 {{ scope.row.milkCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.giftBoxCount>0">
            礼盒 {{ scope.row.giftBoxCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.giftCardCount>0">
            贺卡 {{ scope.row.giftCardCount }} 份
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="milkBatchCode" label="批次" minWidth="110"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="icon-operation">

          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <!--导入运单号的弹窗-->
    <el-dialog title="导入运单" :visible.sync="importTransportDialogVisible">
      <div style="display: flex;align-items: flex-end;">
        <div>请按如下格式导入运单号</div>
        <div class="ml15">
          <el-upload action="/" :http-request="importTransportExcel" list-type="text" :show-file-list="false">
            <el-button size="small" type="primary">导入运单数据</el-button>
          </el-upload>
        </div>
      </div>
      <div class="table_div mt15">
        <div>包裹编号</div>
        <div>快递公司</div>
        <div>快递单号</div>
      </div>
      <div class="table_div_body">
        <div class="table_div" v-for="(item,idx) in transportExcelArr" :key="idx">
          <div>{{ item.freshMilkOrderPackageCode }}</div>
          <div>{{ item.transportCompany }}</div>
          <div>{{ item.transportNo }}</div>
        </div>
      </div>
      <div style="font-size: 12px;color: #99a9bf;" v-if="transportExcelArr.length>18">
        可滚动查看
      </div>
      <div class="mt15">
        本次导入数量：{{ transportExcelArr.length }}，请确认。
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="importTransportNoClose">关 闭</el-button>
        <el-button @click.native="importTransportNoClear">清 除</el-button>
        <el-button type="primary" @click.native="importTransportNoConfirm">确认并导入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import milkOrderService from "API/fm/MilkOrderService";
import IoProgressService from "API/IoProgressService";
import tools from "COMJS/tools";
import XLSX from 'xlsx'

export default {
  name: "MilkOrderDelivery",
  mixins: [pageTableMixin],
  components: {
    //areaCascader
  },
  data() {
    return {
      // 是否通过wdt发货
      isWdt: false,
      //
      filterTimes: [],
      searchInfo: {
        transportState: '0'
      },
      pageInfo: {
        pageSize: 100,
      },
      importTransportDialogVisible: false,
      transportExcelArr: [],
    }
  },
  mounted() {
    //
    this.isWdt = this.$route.params.mode == 'wdt'
  },
  methods: {
    loadTableData() {
      let param = {
        ...this.searchInfo,
        transportState: this.searchInfo.transportState == 'all' ? '' : this.searchInfo.transportState,
        milkBatchCode: (this.searchInfo.milkBatchCode && this.searchInfo.milkBatchCode.length > 0)
            ? ('D.B.' + this.searchInfo.milkBatchCode) : '',
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        flagWithP: "1"
      }
      milkOrderService.pageMilkOrder(param).then(res => {
        //console.log(res)
        this.querySuccess(res);
      })
    },
    pushWdt() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录导出！', '系统提示');
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "推送中..."
      });
      milkOrderService.pushOrderToWdt(rows).then(res => {
        this.$message.success("推送完成");
        this.loadTableData();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    exportExcel() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录导出！', '系统提示');
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "导出中..."
      });
      milkOrderService.exportExcelCheckRows(rows).then(res => {
        let fileName = res.fileName;
        let module = res.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.recordDateBegin = "";
        this.searchInfo.recordDateEnd = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.recordDateBegin = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.searchInfo.recordDateEnd = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },

    //导入运单号的一些方法
    //region
    importTransportNo() {
      this.transportExcelArr = []
      this.importTransportDialogVisible = true;
    },
    importTransportNoClose() {
      this.transportExcelArr = []
      this.importTransportDialogVisible = false;
    },
    importTransportNoConfirm() {
      if (this.transportExcelArr && this.transportExcelArr.length > 0) {
        milkOrderService.importTransportDataToPackage(this.transportExcelArr).then(res => {
          let modifyCount = res.modifyCount ? res.modifyCount : 0
          this.$alert('成功导入' + modifyCount + '条数据！', '系统提示');
          this.importTransportDialogVisible = false;
          this.loadTableData()
        })
      } else {
        this.$message('无导入数据！请重新导入！')
      }
    },
    importTransportNoClear() {
      this.transportExcelArr = []
    },
    importTransportExcel(content) {
      const file = content.file
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split('.')[1]
      const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types)
      if (!fileType) {
        this.$message('格式错误！请重新选择')
        return
      }
      this.transportExcelArr = []
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          let xlsxJson = tabJson
          //console.log(xlsxJson)
          let fileList = xlsxJson[0].sheet
          //console.log(fileList)
          if (fileList.length == 0) {
            this.$message('无内容')
            return
          }
          let packageCodeF = '包裹编号'
          let transportCompanyF = '快递公司'
          let transportNoF = '快递单号'
          if (!fileList[0][packageCodeF] || !fileList[0][transportCompanyF] || !fileList[0][transportNoF]) {
            this.$message('内容格式不正确')
            return
          }
          let tempList = []
          for (let item of fileList) {
            let one = {}
            if (item[packageCodeF]) {
              one.freshMilkOrderPackageCode = item[packageCodeF]
            } else {
              break
            }
            if (item[transportCompanyF]) {
              one.transportCompany = item[transportCompanyF]
            }
            if (item[transportNoF]) {
              one.transportNo = item[transportNoF]
            }
            tempList.push(one)
          }
          this.transportExcelArr = tempList
        }
      })
    },
    file2Xce(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          const result = []
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
          })
          resolve(result)
        }
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file) // 传统input方法
      })
    },
    //endregion
  }
}
</script>

<style scoped>
.address-dialog-frame {
  border: 1px solid #d3d3d3;
  padding: 20px;
  margin-bottom: 20px;
  /*background-color: #d3d6da;*/
}

.mt10 {
  margin-top: 10px;
}

.address-dialog-input {
  width: 200px !important;
  margin-right: 15px;
}

.address-dialog-input-long {
  width: 630px !important;
}

.border-dashed {
  border-bottom: 1px dashed #3d3d3d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
}

.tag-row .input-new-tag {
  width: 100px;
}


.table_div {
  display: flex;
}

.table_div > div {
  width: 200px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #3d3d3d;
}

.table_div_body {
  height: 400px;
  overflow: scroll;
}

.table-props {
  margin-left: 100px;
  display: flex;
  height: 50px;
  line-height: 50px;
}

.table-props > div {
  margin-right: 20px;
}

</style>