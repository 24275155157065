import axiosHttp from "../axiosHttp"

export default {
  // 获取分销基本设置
  getSetting() {
    return axiosHttp.GET('/distributionSettings')
  },
  // 保存设置
  saveSetting(params) {
    return axiosHttp.PUT('/distributionSettings', params)
  }
}
