<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">会员类型：</span>
            <el-select v-model="searchInfo.distributorLevel">
              <el-option
                  v-for="item in distributorLevelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="form-item">
            <span class="form-label">会员名称：</span>
            <el-input size="mini" v-model="searchInfo.distributorName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">会员账号：</span>
            <el-input size="mini" v-model="searchInfo.distributorAccount"></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">统计时间：</span>
            <el-date-picker
                v-model="searchInfo.timeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button style="margin-left: 15px;" class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
    >
      <el-table-column prop="distributorLevel" label="会员类型"></el-table-column>
      <el-table-column prop="distributorName" label="会员名称"></el-table-column>
      <el-table-column prop="distributorAccount" label="会员账号"></el-table-column>
      <el-table-column prop="distributorSales" label="会员销售额（含运费）" width="160"></el-table-column>
      <el-table-column prop="promotionSales" label="推广销售额（含运费）" width="160"></el-table-column>
      <el-table-column prop="teamSales" label="团队销售额（含运费）" width="160"></el-table-column>
      <el-table-column prop="promotionServiceFee" label="推广服务费"></el-table-column>
      <el-table-column prop="teamServiceFee" label="团队服务费"></el-table-column>
      <el-table-column label="操作" width="60">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="查看"
                @click="goToDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dimAccountDetailService from "API/dim/DimAccountDetailsService.js";
import tools from "COMJS/tools.js";

export default {
  name: "salesStatistics",
  data() {
    return {
      searchInfo: {
        distributorLevel: "",
        distributorName:"",
        distributorAccount:"",
        timeRange:[]
      },
      distributorLevelList:[{value:'',label:'全部'},{value:'g2',label:'金星'},{value:'g1',label:'银星'},{value:'g0',label:'普标'}]
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      dimAccountDetailService.queryDistributorSales(this.pageInfo, this.searchInfo).then(
          data => {
            data.list = data.list.map(e => {
              return {
                distributorId: e.distributorId,
                distributorLevel: this.translateDistributorLevel(e.distributorLevel),
                distributorName: e.distributorName,
                distributorAccount: e.distributorAccount,
                distributorSales: e.distributorSales,
                promotionSales: e.promotionSales,
                teamSales: e.teamSales,
                promotionServiceFee: e.promotionServiceFee,
                teamServiceFee: e.teamServiceFee
              };
            });
            this.querySuccess(data);
          }
      );
    },
    goToDetail(row){
      this.$router.push({path:`/report/distributor/salesStatisticsDetail`,query:row});
    },
    translateDistributorLevel(level){
      let result;
      switch (level) {
        case 'g0':
          result = '普标';
          break;
        case 'g1':
          result = '银星';
          break;
        case 'g2':
          result = '金星';
          break;
        default:
          result = '未知';
      }
      return result;
    }
  }
};
</script>