<template>
  <div>
    <!--导入运单号的弹窗-->
    <el-dialog title="导入鲜奶订单" :visible.sync="importOrderDialogVisible">
      <div style="display: flex;align-items: flex-end;width: 100%;">
        <div>请按如下格式导入订单</div>
        <div class="ml15">
          <el-switch
              style="margin-left: 10px;"
              v-model="address4"
              active-text="四列地址"
              inactive-text="一列地址">
          </el-switch>
        </div>
        <div style="margin-left: auto;">
          <el-upload action="/" :http-request="importOrderExcel" list-type="text" :show-file-list="false">
            <el-button size="small" type="primary">选择文件</el-button>
          </el-upload>
        </div>
      </div>
      <div class="table_div mt15" style="width: 100%;">
        <div>收件人姓名</div>
        <template v-if="address4">
          <div>省</div>
          <div>市</div>
          <div>区县</div>
          <div style="width: 200px;">详细地址</div>
        </template>
        <div style="width: 560px;" v-else>收件人地址</div>
        <div>收件人手机</div>
        <div>数量 2瓶/1单</div>
      </div>
      <div class="table_div_body">
        <div class="table_div" v-for="(item,idx) in orderExcelArr" :key="idx">
          <div>{{ item.recipientName }}</div>
          <template v-if="address4">
            <div>{{ item.recipientProvince }}</div>
            <div>{{ item.recipientCity }}</div>
            <div>{{ item.recipientCounty }}</div>
            <div style="width: 200px;">{{ item.recipientAddressDetail }}</div>
          </template>
          <div style="width: 560px;" v-else>{{ item.recipientAddress }}</div>
          <div>{{ item.recipientPhoneNum }}</div>
          <div>{{ item.milkCount }}</div>
        </div>
      </div>
      <div style="font-size: 12px;color: #99a9bf;" v-if="orderExcelArr.length>18">
        可滚动查看
      </div>
      <div class="mt15" style="display: flex;">
        <div>
          本次导入数量：{{ orderExcelArr.length }}，请确认。
        </div>
        <div style="margin-left: auto;">
          <el-checkbox v-model="pushWdtAfterImport">导入后直接推送旺店通发货</el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="importClose">关 闭</el-button>
        <el-button @click.native="importClear">清 除</el-button>
        <el-button type="primary" @click.native="importConfirm">确认并导入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import milkOrderService from 'API/fm/MilkOrderService'

export default {
  name: "MilkOrderImportDialog",
  data(){
    return {
      importOrderDialogVisible:false,
      orderExcelArr:[],
      address4:false,
      pushWdtAfterImport:false,
    }
  },
  methods:{
    show() {
      this.orderExcelArr = []
      this.importOrderDialogVisible = true;
    },
    importClose() {
      this.orderExcelArr = []
      this.importOrderDialogVisible = false;
    },
    importConfirm() {
      if (this.orderExcelArr && this.orderExcelArr.length > 0) {
        let loading = this.$loading({
          lock: true,
          text: "导入中..."
        });
        milkOrderService.importOrderList(this.orderExcelArr).then(res => {
          let modifyCount = 0
          if(res.importList && res.importList.length>0){
            modifyCount = res.importList.length
          }
          loading.close();
          let msg = '成功导入' + modifyCount + '条订单'
          if(this.pushWdtAfterImport){
            milkOrderService.pushOrderToWdt(res.importList).then(data=>{
              this.$alert(msg+'，并自动推送旺店通', '系统提示');
              this.importOrderDialogVisible = false;
              this.$emit("submit");
            }).catch(error=>{
              this.$alert(msg+'，但推送旺店通失败，请另行推送！', '系统提示');
              this.importOrderDialogVisible = false;
              this.$emit("submit");
            })
          }else{
            this.$alert(msg, '系统提示');
            this.importOrderDialogVisible = false;
            this.$emit("submit");
          }
        }).catch(error => {
          loading.close();
        });
      } else {
        this.$message('无导入数据！请重新导入！')
      }
    },
    importClear() {
      this.orderExcelArr = []
    },
    importOrderExcel(content) {
      const file = content.file
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split('.')[1]
      const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types)
      if (!fileType) {
        this.$message('格式错误！请重新选择')
        return
      }
      this.orderExcelArr = []
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          let xlsxJson = tabJson
          //console.log(xlsxJson)
          let fileList = xlsxJson[0].sheet
          //console.log(fileList)
          if (fileList.length == 0) {
            this.$message('无内容')
            return
          }
          let tempList = []
          if(this.address4){
            let xm = '收件人姓名'
            let dz_1 = '省'
            let dz_2 = '市'
            let dz_3 = '区县'
            let dz_4 = '详细地址'
            let sj = '收件人手机'
            let sl = '数量 2瓶/1单'
            if (!fileList[0][xm] || !fileList[0][dz_1]
                || !fileList[0][dz_2] || !fileList[0][dz_3] || !fileList[0][dz_3]
                || !fileList[0][sj] || !fileList[0][sl]) {
              this.$message('内容格式不正确')
              return
            }
            for (let item of fileList) {
              let one = {}
              if (item[xm]) {
                one.recipientName = item[xm]
              } else {
                break
              }
              if (item[dz_1]) {
                one.recipientProvince = item[dz_1]
              }
              if (item[dz_2]) {
                one.recipientCity = item[dz_2]
              }
              if (item[dz_3]) {
                one.recipientCounty = item[dz_3]
              }
              if (item[dz_4]) {
                one.recipientAddressDetail = item[dz_4]
              }
              if (item[sj]) {
                one.recipientPhoneNum = item[sj]
              }
              if (item[sl]) {
                one.milkCount = item[sl]
              }
              tempList.push(one)
            }
          }else{
            let xm = '收件人姓名'
            let dz = '收件人地址'
            let sj = '收件人手机'
            let sl = '数量 2瓶/1单'
            if (!fileList[0][xm] || !fileList[0][dz] || !fileList[0][sj] || !fileList[0][sl]) {
              this.$message('内容格式不正确')
              return
            }
            for (let item of fileList) {
              let one = {}
              if (item[xm]) {
                one.recipientName = item[xm]
              } else {
                break
              }
              if (item[dz]) {
                one.recipientAddress = item[dz]
              }
              if (item[sj]) {
                one.recipientPhoneNum = item[sj]
              }
              if (item[sl]) {
                one.milkCount = item[sl]
              }
              tempList.push(one)
            }
          }
          this.orderExcelArr = tempList
        }
      })
    },
    file2Xce(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          const result = []
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
          })
          resolve(result)
        }
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file) // 传统input方法
      })
    },
  }
}
</script>

<style scoped>
.table_div {
  display: flex;
}

.table_div > div {
  width: 120px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #3d3d3d;
}

.table_div_body {
  height: 400px;
  overflow: scroll;
}
</style>