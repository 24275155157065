export default {
    // 金额强制保留bit位
    toFixed(num, bit) {
        if (!bit) bit = 2;
        var times = Math.pow(10, bit);
        var des = num * times + 0.5;
        des = parseInt(des, 10) / times;
        des = des + '';
        var rs = des.indexOf('.');
        if (rs < 0) {
            rs = des.length;
            des += '.';
        }
        while (des.length <= rs + bit) {
            des += '0';
        }
        return des + '';
    },
    // 金额千位分隔符，强制保留2位小数
    thousandsOf(num) {
        if (!num) return '0.00';
        var str1 = num && (num
            .toString().indexOf('.') != -1 ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
            return $1 + ",";
        }) : num.toString().replace(/(\d)(?=(\d{3})+$)/g, function($0, $1) {
            return $1 + ",";
        }));
        str1 = str1 + "";
        var str2 = this.toFixed(num, 2);

        if (str1.indexOf('.') > 0) {
            str1 = str1.substring(0, str1.indexOf('.')) + str2.substring(str2.indexOf('.'));
        } else {
            str1 = str1 + "." + str2.substring(str2.indexOf('.') + 1);
        }
        return str1;
    },
    thousandsOfInt(num) {
        if (!num) return '0';
        var str1 = num && (num
            .toString().indexOf('.') != -1 ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
            return $1 + ",";
        }) : num.toString().replace(/(\d)(?=(\d{3})+$)/g, function($0, $1) {
            return $1 + ",";
        }));
        str1 = str1 + "";
        return str1;
    }
}
