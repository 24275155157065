const frame = { template: '<router-view></router-view>' }

export default {
    path: 'dim',
    component: frame,
    children: [{
        name: '基础设置',
        path: 'setting',
        component: resolve => import('PAGES/dim/setting.vue')
    },{
        name: '分销商品列表',
        path: 'goodsList',
        component: resolve => import('PAGES/dim/goods/goodsList.vue')
    },{
        name: '添加实体商品',
        path: 'addPhysicalGoods/:id?',
        component: resolve => import('PAGES/dim/goods/physicalGoods/addPhysicalGoods.vue')
    },{
        name: '实体商品设置',
        path: 'physicalGoodsSetting/:id?',
        meta: { notTab: true },
        component: resolve => import('PAGES/dim/goods/physicalGoods/setting.vue')
    },{
        name: '添加虚拟商品',
        path: 'addVirtualGoods/:id?',
        component: resolve => import('PAGES/dim/goods/virtualGoods/addVirtualGoods.vue')
    },{
        name: '虚拟商品信息',
        path: 'fillInVirtualGoodsInfo/:id?',
        meta: { notTab: true },
        component: resolve => import('PAGES/dim/goods/virtualGoods/fillInVirtualGoodsInfo.vue')
    },{
        name: '虚拟商品设置',
        path: 'virtualGoodsSetting/:id?',
        meta: { notTab: true },
        component: resolve => import('PAGES/dim/goods/virtualGoods/setting.vue')
    },{
        name: '提现管理列表',
        path: 'withdrawCashAuditManagement',
        component: resolve => import('PAGES/dim/withdrawCash/withdrawCashAuditManagement.vue')
    }
    ]
}
