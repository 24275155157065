import axiosHttp from "../axiosHttp";

export default {
    // SomOrderImportBatchDTO {
    //   batchCode (string, optional),
    //   batchInfoIdList (Array[string], optional),
    //   columnBatchSequence (integer, optional),
    //   columnCity (integer, optional),
    //   columnCounty (integer, optional),
    //   columnDetailAddress (integer, optional),
    //   columnFullAddress (integer, optional),
    //   columnGoodsName (integer, optional),
    //   columnGoodsNum (integer, optional),
    //   columnMergeGoods (integer, optional),
    //   columnName (integer, optional),
    //   columnProvince (integer, optional),
    //   columnRemark (integer, optional),
    //   columnTel (integer, optional),
    //   columnThirdOrderNo (integer, optional),
    //   completePer (number, optional),
    //   createTime (string, optional),
    //   excelContent (Array[Inline Model 1], optional),
    //   exportCount (integer, optional),
    //   failCount (integer, optional),
    //   fileUrl (string, optional),
    //   importTimes (integer, optional),
    //   importType (string, optional),
    //   orderImportBatchId (string, optional),
    //   remark (string, optional),
    //   result (string, optional),
    //   resultMsg (string, optional),
    //   resultType (string, optional),
    //   rowBegin (integer, optional),
    //   schedule (string, optional),
    //   shopId (string, optional),
    //   state (string, optional),
    //   successCount (integer, optional),
    //   totalCount (integer, optional),
    //   updateTime (string, optional),
    //   userId (string, optional)
    //   }
    // 批次分页查询
    queryOrderPages(params) {
        return axiosHttp.POST('/somOrderImportBatch/queryPage', params, false);
    },
    // 批量导入
    importOrderByBatch(SomOrderImportBatchDTO) {
        return axiosHttp.POST("/somOrderImportBatch/importOrderByBatch", SomOrderImportBatchDTO)
    },
    // 校验excel文件
    checkOrderExcel(fileName) {
        return axiosHttp.POST("/somOrderImportBatch/importOrderPre", {
            fileName,
        })
    },
    // 修改备注
    batchChangeRemark(params) {
        return axiosHttp.POST("/somOrderImportBatch/importOrderPre", params)
    },
    // 导出失败原因
    exportOrderImportError(orderImportBatchId) {
        return axiosHttp.POST('/somOrderImportBatch/exportOrderImportError', {
            orderImportBatchId
        })
    },
    deleteBatch(orderImportBatchId){
        return axiosHttp.POST('/somOrderImportBatch/deleteBatch', {'orderImportBatchId':orderImportBatchId})
    },
    exportBatch(orderImportBatchId,batchCode){
        return axiosHttp.POST('/somOrderImportBatch/exportBatch', {'orderImportBatchId':orderImportBatchId,'batchCode':batchCode})
    },

    isHavePermission(){
        return axiosHttp.GET('/somOrderImportBatch/isAllow')
    },

    getBatchAmountDetail(orderImportBatchId){
        return axiosHttp.POST('/somOrderImportBatch/getBatchAmountDetail', {'orderImportBatchId':orderImportBatchId})
    },

    queryTransportFeeInfo(){
        return axiosHttp.POST('/somOrderImportBatch/queryTransportFeeInfo', {})
    }
}