<template>
  <div class="order-detail box-shadow-unify">
    <div class="order-base-info" style="display: flex;justify-content: space-between">
      <div style="display: flex;flex-wrap:wrap;line-height: 30px;">
        <span>订单编号：{{ orderDetail.orderCode }}</span>
        <span>下单时间：{{ orderDetail.createTimeStr }}</span>
        <span>订单来源：{{ orderDetail.source }}</span>
        <span v-if="orderDetail.orderType == 'import'">平台单号：{{ orderDetail.thirdPartyOrderCode }}</span>
        <template v-if="tel && telOrderPerson">
          <span>下单人：{{ telOrderPerson }}</span>
          <span>会员：{{ tel }}</span>
        </template>
        <template v-else-if="!tel && telOrderPerson">
          <span>经销商：{{ telOrderPerson }}</span>
        </template>
        <template v-else>

        </template>
        <span v-if="orderDetail.achievement!=null">贡献成就值：{{ achievement }}</span>
        <span v-if="orderDetail.expectShippingTime!=null">发货时间：{{ orderDetail.expectShippingTime }}</span>
        <!--
        <span>运单号：{{mergeTransportInfo}}</span>
        -->
      </div>
    </div>
    <div style="margin-bottom: 10px">
      <span v-if="orderDetail.orderType=='import'">批次号：{{ orderDetail.batchCode }}</span>
      <span style="margin-left: 50px" v-if="orderDetail.orderType=='import'">批次备注：{{
          orderDetail.batchRemark ? orderDetail.batchRemark : "无"
        }}</span>
    </div>
    <div class="order-progress">
      <div class="progress-info">
        <template v-if="orderDetail.statusStr == '待发货'">
          <div class="progress-title">等待商家发货</div>
          <div class="progress-desc">
            买家已付款，请尽快发货，否则买家有权申请退款。
            <div class="order-font-btn">
              <span @click="confirmToCancel" v-if="isEditing">取消订单</span>
              <span @click="stateBackDialogVisible = true" v-if="isEditing && orderDetail.transportState=='5'">订单回退（返回待发货-未转出库）</span>
            </div>
          </div>
          <!--
          <el-button type="primary" size="mini" @click="isShowCompanyPopup = true" v-if="isEditing">发货</el-button>
          -->
        </template>
        <template
            v-if="orderDetail.statusStr == '待支付' || orderDetail.statusStr == '转账审核中' || orderDetail.statusStr == '审核拒绝'">
          <div class="progress-title" v-if="!isExpire">商品已拍下，等待买家付款</div>
          <div class="progress-title" v-if="isExpire">交易关闭</div>
          <div class="progress-desc" v-if="!isExpire">
            如买家未在
            <div class="count-down" style="width: 120px;text-align: center;">
              {{ toolsCountDown }}
            </div>
            内付款，订单将按照设置逾期自动关闭。
            <div class="order-font-btn">
              <span @click="$refs.changeOrderPrice.dialogVisible = true" v-if="isEditing">改价</span>
              <span @click="cancelOrderDialogVisible = true" v-if="isEditing">取消订单</span>
            </div>
          </div>
          <div class="progress-desc" v-if="isExpire">超时未付款</div>
        </template>
        <template v-if="orderDetail.statusStr == '待收货'">
          <div class="progress-title">等待买家收货</div>
          <div class="progress-desc">
            <div class="order-font-btn">
              <span @click="afterDialogVisible = true" v-if="isEditing">转售后处理</span>
              <!--
              <span @click="isShowCompanyPopup = true" v-if="isEditing">改运单号</span>
              -->
              <!--完成doCompleteOrder-->
              <span @click="completeDialogVisible = true" v-if="isEditing">完成订单</span>
              <span @click="stateBackDialogVisible = true" v-if="isEditing">订单回退（返回待发货-未转出库）</span>
            </div>
          </div>
        </template>
        <template v-if="orderDetail.statusStr == '已完成'">
          <div class="progress-title">交易已完成</div>
          <div class="order-font-btn">
            <span @click="accomplishDialogVisible = true" v-if="isEditing">转售后处理</span>
          </div>
        </template>
        <template v-if="orderDetail.statusStr == '已关闭'">
          <div class="progress-title">交易已关闭</div>
        </template>
        <template v-if="orderDetail.statusStr == '售后申请中'">
          <div class="progress-title">售后申请中</div>
        </template>
        <div class="remark" @click="isShowRemarksPopop = true" v-if="isEditing">备注</div>
      </div>
      <div class="progress-step">
        <el-steps :active="stepActive" align-center finish-status="success"
                  v-if="!(orderDetail.statusStr == '待支付' && isExpire)">
          <el-step title="买家下单" :description="orderDetail.createTimeStr"></el-step>
          <el-step title="买家付款" :description="orderDetail.payTime"></el-step>
          <el-step title="商家发货" :description="orderDetail.transportTimeStr"></el-step>
          <el-step title="交易成功" :description="orderDetail.finishTimeStr "></el-step>
        </el-steps>
      </div>
    </div>
    <div class="order-tips"
         v-if="orderDetail.orderPattern=='common' && orderDetail.afterRemark!=null && (orderDetail.statusStr=='已完成' || orderDetail.statusStr=='已关闭' || orderDetail.statusStr=='待发货')">
      <div style="color: #FBC14E;">售后说明：</div>
      <div v-for="(item,index) in orderDetail.afterRemark" :key="index">
        <div style="line-height:30px; color:rgba(102, 102, 102, 1)">{{ item }}</div>
      </div>
    </div>
    <div>
      <table class="address-info">
        <td>
          <tr class="th">
            收件人信息
            <span class="change-address" v-if="updateTransportVisible"
                  @click="$refs.changeOrderAddress.dialogVisible = true">修改</span>
          </tr>
          <tr>收货人：{{ orderDetail.recipientName }}</tr>
          <tr>联系电话：{{ orderDetail.recipientPhoneNum }}{{orderDetail.recipientPhoneNumTransfer?' (转:'+orderDetail.recipientPhoneNumTransfer+')':''}}</tr>
          <tr>收货地址：{{ orderDetail.recipientFullAddress }}</tr>
        </td>
        <!--
        <td>
            <tr class="th">配送方式</tr>
            <tr>配送方式：快递</tr>
        </td>
        -->
        <td>
          <tr class="th">配送信息</tr>
          <tr v-for="(item, index) in orderDetail.orderPackageList" :key="index"
              v-html="translateTransport(item,index)"></tr>
        </td>
        <td>
          <tr class="th">付款信息</tr>
          <tr>实付金额：{{ orderDetail.totalAmount }}</tr>
          <tr>付款方式：{{ orderDetail.payWay || '未付款' }}</tr>
          <tr>付款时间：{{ orderDetail.payTime || '未付款' }}</tr>
        </td>
        <td>
          <!--
          <tr class="th">买家信息</tr>
          <tr>买家： {{ orderDetail.recipientName }}</tr>
          <tr>买家留言：{{ orderDetail.remark || '无' }}</tr>
          -->
        </td>
      </table>
    </div>
    <div class="goods-table">
      <ws-page-table :data="orderDetail.orderGoodsList" ref="pageTable" :pageable="false">
        <el-table-column label="商品">
          <template slot-scope="scope">
            <div class="table-img-text">
              <img :src="scope.row.goodsPictureUrl" alt/>
              <span>{{ scope.row.goodsName }}</span>
              <span>{{ scope.row.goodsCode }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="retailPrice" label="单价"></el-table-column>
        <el-table-column prop="num" label="数量"></el-table-column>
        <el-table-column label="售后状态">
          <!-- <template slot-scope="scope">{{orderDetail.transportState == 1 ? '已发货' : '待发货'}}</template> -->
        </el-table-column>
        <el-table-column label="发货状态">
          <template slot-scope="scope">{{ orderDetail.transportState == 1 ? '已发货' : '待发货' }}</template>
        </el-table-column>
      </ws-page-table>
      <div class="goods-price-info-wrapper clearfix">
        <table class="goods-price-info">
          <tr>
            <td>商品总价：</td>
            <td>￥{{ orderDetail.goodsRetailAmount }}</td>
          </tr>
          <tr>
            <td>运费：</td>
            <td>￥{{ orderDetail.transportAmount }}</td>
          </tr>
          <tr>
            <td>优惠金额：</td>
            <td>￥{{ preferentialAmount }}</td>
          </tr>
          <tr>
            <td class="sum-price-text">实收金额：</td>
            <td class="sum-price">￥{{ orderDetail.totalAmount }}</td>
          </tr>
        </table>
      </div>
    </div>

    <el-dialog title="取消订单" :visible.sync="cancelOrderDialogVisible" width="300px">
      <el-select v-model="cancelOrderReason" placeholder="请选择理由">
        <el-option v-for="item in cancelOrderReasons" :key="item.value" :label="item.label"
                   :value="item.value"></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelOrderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cancelOrder">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择发货公司" :visible.sync="isShowCompanyPopup" width="300px">
      <el-form label-width="80px" v-for="(item, index) in orderDetail.orderPackageList" :key="index">
        <el-form-item label="包裹编号">
          <el-input v-model="item.orderPackageCode" style="width: 180px;" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="商品内容">
          <el-input v-model="item.packageContentCode" style="width: 180px;" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="运单号">
          <el-input v-model="item.transportNo" style="width: 180px;"></el-input>
        </el-form-item>
        <el-form-item label="发货公司">
          <el-select v-model="item.transportCompany" placeholder="请选择发货公司">
            <el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowCompanyPopup = false">取 消</el-button>
        <el-button type="primary" @click="deliverGoods">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="卖家备注" :visible.sync="isShowRemarksPopop" width="560px">
      <el-input style="margin-top: 15px;" type="textarea" placeholder="请输入备注" v-model="remark" maxlength="256"
                show-word-limit autosize resize="none"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowRemarksPopop = false">取 消</el-button>
        <el-button type="primary" @click="changeRemark">确 定</el-button>
      </div>
    </el-dialog>
    <change-order-address @changeAddress="getOrderDetail()" ref="changeOrderAddress"
                          :info="orderDetail"></change-order-address>
    <change-order-price @changeAmount="getOrderDetail()" ref="changeOrderPrice"
                        :info="orderDetail"></change-order-price>
    <el-dialog title="转售后处理" :visible.sync="afterDialogVisible" width="300px">
      <el-select v-model="afterType" placeholder="请选择售后类型">
        <el-option :key="item.value" :label="item.label" :value="item.value"
                   v-for="item in reasonOfCancellationList"></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="afterDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderApplyAfter">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="转售后处理" :visible.sync="accomplishDialogVisible" width="600px">
      <el-row type="flex" align="middle" class="row-item">
        <el-col :span="3">售后原因：</el-col>
        <el-col :span="6">
          <el-select v-model="afterType" placeholder="请选择售后类型">
            <el-option :key="item.value" :label="item.label" :value="item.value"
                       v-for="item in accomplishList"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="3">售后拍照：</el-col>
        <el-col :span="21" style="color:rgba(255, 141, 26, 100)">请提供带快递单号的快递包裹照片，开箱内物商品照片，问题商品照片</el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="21" :offset="3">
          <draggable-upload :width="82" :height="82" :limit="3" :uploadUrl="uploadUrl()"
                            :requestHeaders="requestHeaders" v-model="thumbnailImgs"></draggable-upload>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="accomplishDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderApplyAfter('accomplish')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="订单完成" :visible.sync="completeDialogVisible" width="300px">
      <div style="line-height: 28px;">本操作会造成如下结果：</div>
      <div style="line-height: 28px;">1、订单状态从待收货变为已完成</div>
      <div style="line-height: 28px;">2、相关服务费会直接给到相关会员</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="completeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doCompleteOrder">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="订单回退" :visible.sync="stateBackDialogVisible" width="500px">
      <div style="line-height: 28px;">本操作会造成如下结果：</div>
      <div style="line-height: 28px;">1、订单状态返回待发货-未转出库</div>
      <div style="line-height: 28px;">2、取消相关出库单</div>
      <div style="line-height: 28px;" v-if="orderDetail.statusStr == '待发货'">
        3、如已转第三方，则取消原包裹，重新发货
      </div>
      <div style="line-height: 28px;" v-if="orderDetail.statusStr == '待收货'">
        3、不改变第三方发货平台的数据（如：吉客云）
      </div>
      <div style="line-height: 28px;" v-if="orderDetail.statusStr == '待收货'">
        4、回退库品库存，总库存增加，锁定库存增加，可用库存不变
      </div>
      <div>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="stateBackDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderStateBack">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let moment = require("moment");
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import Objects from "COMJS/Objects";
import Enum from "ASSETS/js/enum";
import translate from "ASSETS/js/translate";
import BimEnumService from "API/bim/BimEnumService";
import userInfoService from "API/bim/userInfoService.js";
import orderService from "API/som/orderService";
import orderOperate from "API/som/orderOperate";
import {applyAfterSale} from "@/api/som/checkService.js";
import changeOrderAddress from "./changeOrderAddress";
import changeOrderPrice from "./changeOrderPrice";

export default {
  name: "orderDetail",
  data() {
    return {
      orderDetail: {},
      stepActive: 1,
      cancelOrderDialogVisible: false, // 取消订单弹窗
      accomplishDialogVisible: false,
      afterDialogVisible: false,
      completeDialogVisible: false,
      stateBackDialogVisible: false,
      isShowRemarksPopop: false,
      isShowCompanyPopup: false,
      thumbnailImgs: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
      cancelOrderReasons: [
        {
          label: "无法联系上买家",
          value: "无法联系上买家",
        },
        {
          label: "买家误拍或重拍了",
          value: "买家误拍或重拍了",
        },
        {
          label: "买家无诚意完成交易",
          value: "买家无诚意完成交易",
        },
        {
          label: "已通过银行线下汇款",
          value: "已通过银行线下汇款",
        },
        {
          label: "已通过同城见面交易",
          value: "已通过同城见面交易",
        },
        {
          label: "已通过货到付款交易",
          value: "已通过货到付款交易",
        },
        {
          label: "已通过网上银行直接汇款",
          value: "已通过网上银行直接汇款",
        },
        {
          label: "已经缺货无法交易",
          value: "已经缺货无法交易",
        },
      ],
      reasonOfCancellationList: [
        {
          label: "不想要了",
          value: "不想要了",
        },
        {
          label: "地址信息填写错误",
          value: "地址信息填写错误",
        },
        {
          label: "配送时间问题",
          value: "配送时间问题",
        },
        {
          label: "商品错选多选",
          value: "商品错选多选",
        },
      ],
      accomplishList: [
        {
          label: "商品破损、脏污",
          value: "商品破损、脏污",
        },
        {
          label: "少、错商品",
          value: "少、错商品",
        },
      ],
      cancelOrderReason: "",
      afterType: "",
      changeTotalAmount: "",
      remark: "",
      timeEnd: 0,
      toolsCountDown:'',
      isExpire: false,
      companys: [],
      isEditing: false,
      tel: "",
      telOrderPerson:"",
    };
  },
  components: {
    changeOrderAddress,
    changeOrderPrice,
    draggableUpload,
  },
  computed: {
    isTest: function () {
      if (this.$route.params.test === "test") {
        return true;
      }
      return false;
    },
    preferentialAmount: function () {
      let temp =
          this.orderDetail.goodsRetailAmount -
          this.orderDetail.goodsPaymentAmount;
      return temp.toFixed(2);
    },
    achievement: function () {
      return this.orderDetail.achievement.toFixed(2);
    },
    mergeTransportInfo: function () {
      var info = "";
      if (this.orderDetail.orderPackageList) {
        for (let item of this.orderDetail.orderPackageList) {
          let transportNo = Objects.isEmpty(item.transportNo)
              ? "无"
              : item.transportNo;
          if (info === "") {
            info = transportNo + "(" + item.transportCompany + ")";
          } else {
            info = info + "," + transportNo + "(" + item.transportCompany + ")";
          }
        }
      }
      return info;
    },
    updateTransportVisible: function () {
      if (this.isEditing) {
        if (
            this.orderDetail.statusStr == "待支付" ||
            this.orderDetail.statusStr == "待发货" ||
            this.orderDetail.statusStr == "待收货"
        ) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    if (this.$route.path.includes("/som/orderEdit")) {
      this.isEditing = true;
    } else {
      this.isEditing = false;
    }
    this.getCompanyEnum();
    this.getOrderDetail();
    tools.formatDate()
    //setInterval(this.timerToCountSample, 1000)
    tools.interval(this.timerToCountSample, 1000)
  },
  methods: {
    getCompanyEnum() {
      BimEnumService.getEnum("TRANSPORT_COMPANY").then((data) => {
        this.companys = data.map((e) => {
          return {
            value: e.value,
            label: e.name,
          };
        });
      });
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    rowClass() {
      //表头的字体样式
      return "text-align: center;height: 20px;font-size: 10px;height: 20px";
    },
    headClass() {
      return "text-align: center; font-size: 10px;height: 20px";
    },
    translateTransport(item, index) {
      let transportNo = Objects.isEmpty(item.transportNo)
          ? "无"
          : item.transportNo;
      let pack = "包裹" + (index + 1);
      return pack + "：" + transportNo + "（" + item.transportCompany + "）";
    },
    getOrderDetail() {
      orderService.getOrderDetail(this.$route.params.id).then((data) => {
        if (data) {
          if (data.intermediatorUserId) {
            this.getTel(data.intermediatorUserId);
          }
          if (data.customerUserId) {
            this.getTelOrderPerson(data.customerUserId);
          }
        }
        data.createTimeStr = data.createTime
            ? tools.formatDate(new Date(data.createTime), "yyyy-MM-dd hh:mm:ss")
            : "";
        data.payTime = data.payTime
            ? tools.formatDate(new Date(data.payTime), "yyyy-MM-dd hh:mm:ss")
            : "";
        data.transportTimeStr = data.transportTime
            ? tools.formatDate(
                new Date(data.transportTime),
                "yyyy-MM-dd hh:mm:ss"
            )
            : "";
        data.finishTimeStr = data.finishTime
            ? tools.formatDate(new Date(data.finishTime), "yyyy-MM-dd hh:mm:ss")
            : "";
        // data.source = translate.source(data.orderType);
        // data.payWay = translate.payWay(data.payChannel);
        data.source = Enum.orderType[data.orderType];
        data.payWay = Enum.payWay[data.payChannel];
        data.statusStr = translate.orderState(data);
        if (data.afterState == "1") {
          data.statusStr = "售后申请中";
        }
        this.stepActive = this.computedStepActive(data.statusStr);
        //界面判断是否超时，经常因为时间问题出bug
        if (data.payState == "0") {
          let nowTime = new Date().getTime();
          if (data.orderType == "import") {
            this.timeEnd = data.createTime + 24 * 60 * 60 * 1000;
          } else if (data.orderType == "wmp") {
            this.timeEnd = data.createTime + 30 * 60 * 1000;
          } else {
            // TODO 非导入和小程序零售单时暂未确定如何处理，先默认30分钟
            this.timeEnd = data.createTime + 30 * 60 * 1000;
          }
          if (nowTime > this.timeEnd) {
            this.isExpire = true;
          }
        }
        if (data.afterRemark && data.afterRemark.length > 0) {
          data.afterRemark = data.afterRemark.split("；");
        }
        //console.log(data);
        data.expectShippingTime = moment(data.expectShippingTime).format(
            "YYYY-MM-DD HH:mm:ss"
        );
        this.orderDetail = data;
      });
    },
    computedStepActive(statusStr) {
      switch (statusStr) {
        case "待支付":
          return 1;
        case "待发货":
          return 2;
        case "待收货":
          return 3;
        case "已完成":
          return 4;
      }
    },
    cancelOrder() {
      let params = {
        orderId: this.orderDetail.orderId,
        remark: this.cancelOrderReason,
      };
      orderOperate.cancelOrder(params).then(() => {
        this.cancelOrderDialogVisible = false;
        this.getOrderDetail();
      });
    },
    doCompleteOrder() {
      let params = {
        orderId: this.orderDetail.orderId,
      };
      orderOperate.doCompleteOrder(params).then(() => {
        this.completeDialogVisible = false;
        this.getOrderDetail();
      });
    },
    orderStateBack() {
      //return this.$message.warning("暂未开放功能");
      let orderId = this.$route.params.id
      let returnStock = '1'
      orderService.stateBackToUnShipped(orderId,returnStock).then(data => {
        this.$message.success('操作成功');
        this.getOrderDetail();
        this.stateBackDialogVisible = false;
      })
    },
    async orderApplyAfter(e) {
      let imgs = [];
      this.thumbnailImgs.forEach((e) => {
        imgs.push(e.response);
      });

      let params = {
        orderId: this.orderDetail.orderId,
        reason: this.afterType,
        imgs: imgs ? imgs.join(",") : "",
      };
      const data = await applyAfterSale(params);
      console.log(data);

      if (data) {
        if (e == "accomplish") {
          this.accomplishDialogVisible = false;
        } else {
          this.afterDialogVisible = false;
        }
        this.$alert(
            "订单已转至售后处理，请到【审核-售后审核】模块处理。",
            "售后",
            {
              confirmButtonText: "确定",
              callback: (action) => {
              },
            }
        );
        this.getOrderDetail();
      }
    },
    changeRemark() {
      if (this.remark === "") {
        return this.$message({
          message: "请填写备注",
          type: "warning",
        });
      }
      let params = {
        orderId: this.orderDetail.orderId,
        changeRemark: this.remark,
      };
      orderOperate.orderChangeRemark(params).then(() => {
        this.isShowRemarksPopop = false;
        this.getOrderDetail();
      });
    },
    countDownEnd() {
      this.isExpire = true;
    },
    getTel(userId) {
      //查询中间人电话
      let params = {
        userId: userId,
      };
      userInfoService.getUser(params).then((data) => {
        if (data) {
          this.tel = data.fullName + "（" + data.tel + "）";
        }
      });
    },
    getTelOrderPerson(userId) {
      //查询中间人电话
      let params = {
        userId: userId,
      };
      userInfoService.getUser(params).then((data) => {
        if (data) {
          this.telOrderPerson = (data.fullName || decodeURI(data.nickName)) + "（" + data.tel + "）";
        }
      });
    },
    // 发货
    deliverGoods() {
      if (Objects.isEmpty(this.orderDetail.orderPackageList)) {
        return;
      }
      const packageList = [];
      for (var idx in this.orderDetail.orderPackageList) {
        var item = this.orderDetail.orderPackageList[idx];
        if (Objects.isEmpty(item.transportNo)) {
          return this.$message({
            message: "请填写运单号",
            type: "warning",
          });
        }
        if (Objects.isEmpty(item.transportCompany)) {
          return this.$message({
            message: "请选择发货公司",
            type: "warning",
          });
        }
        packageList.push({
          orderPackageId: item.orderPackageId,
          transportNo: item.transportNo,
          transportCompany: item.transportCompany,
        });
      }
      let params = {
        orderId: this.orderDetail.orderId,
        remark: "",
        changePackageTransportList: packageList,
      };
      orderOperate.orderSentOut(params).then(() => {
        this.isShowCompanyPopup = false;
        this.getOrderDetail();
      });
    },
    timerToCountSample() {
      if (this.orderDetail && this.orderDetail.createTime) {
        let nowTime = new Date().getTime()
        if(nowTime<this.timeEnd){
          let timer = tools.getCostTimeString(new Date(), new Date(this.timeEnd), 3)
          this.toolsCountDown = timer;
          this.isExpire = false;
        }else{
          this.isExpire = true;
        }
      } else {
        this.toolsCountDown = '';
      }
    },
    confirmToCancel() {
      this.$confirm("确认取消订单?")
          .then((_) => {
            this.cancelOrder();
          })
          .catch((_) => {
          });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~ASSETS/css/common/tool.scss";

.order-detail {
  padding: 20px;

  .order-base-info {
    margin-bottom: 20px;

    span {
      margin-right: 60px;
      font-size: 14px;
    }
  }

  .order-progress {
    display: flex;
    align-items: center;
    border: 1px solid $borderColor;
    font-size: 14px;

    .progress-info {
      flex-shrink: 1;
      flex-grow: 0;
      min-width: 450px;
      padding: 20px;
      border-right: 1px solid $borderColor;

      .progress-title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
      }

      .progress-desc {
        margin-bottom: 20px;
        color: #999;
      }

      .el-button {
        margin-bottom: 20px;
      }

      .order-font-btn {
        margin-top: 20px;
        color: $themeColor;

        > span {
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .count-down {
        display: inline-block;
        margin-right: 2px;
        color: #fc4d00;
      }

      .remark {
        cursor: pointer;
        color: $themeColor;
      }
    }

    .progress-step {
      flex: 1;
      // flex-basis: auto;
    }
  }

  .order-tips {
    // display: flex;
    padding: 25px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid $borderColor;
    border-top: none;
    // line-height: 20px;

    p {
      color: #666;
    }
  }

  .address-info {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    padding: 10px 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    background-color: #f6f9fc;
    color: #666;

    // tr {
    //   @include Mult-line(1);
    // }

    .th {
      font-weight: bold;
      color: #333;
    }

    td {
      flex-basis: auto;
    }

    .change-address {
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      color: $themeColor;
    }
  }

  .goods-table {
    background-color: #fff;
    padding-bottom: 10px;

    .goods-price-info-wrapper {
      text-align: right;
      margin-right: 30px;
      margin-top: 20px;

      .goods-price-info {
        float: right;
        font-size: 14px;
        line-height: 30px;
        color: #666;

        .sum-price-text {
          font-size: 16px;
          color: #333;
        }

        .sum-price {
          font-size: 16px;
          color: #e60000;
        }

        td {
          width: 120px;
        }
      }
    }

    .ws-page-table {
      box-shadow: none;
    }
  }
}

.afterSale {
  width: 200px;
}

.row-item {
  padding: 2% 0;
}
</style>
