<template>
  <div>
    <el-dialog title="添加库存" :visible.sync="dialogFormVisible" width="33vw">
      <el-form :model="entity" v-loading="loading" v-if="dialogFormVisible">
        <el-form-item label="操作：" :label-width="formLabelWidth">
          <el-radio-group v-model="entity.operateType" size="medium">
            <el-radio-button label="sub">减少</el-radio-button>
            <el-radio-button label="add">增加</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量：" :label-width="formLabelWidth">
          <el-input v-model="entity.operateReserveNum"
                    @keyup.native="keyupEvent4PositiveInteger($event)"></el-input>
          <div style="color: red;">
            <span v-if="entity.goodsReserveNum>0  && entity.operateReserveNum>0 && entity.operateType=='add'">
            {{ entity.goodsReserveNum }}(原) + {{
                entity.operateReserveNum
              }} = {{ new BigNumber(entity.goodsReserveNum).add(entity.operateReserveNum).toNumber() }}
          </span>
            <span v-if="entity.goodsReserveNum>0 && entity.operateReserveNum>0 && entity.operateType=='sub'">
            {{ entity.goodsReserveNum }}(原) - {{
                entity.operateReserveNum
              }} = {{ new BigNumber(entity.goodsReserveNum).minus(entity.operateReserveNum).toNumber() }}
          </span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hide">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import inputKeyEvent from "COMJS/inputKeyEvent";
import pimReserveService from "API/pim/PimReserveService"
import tools from "COMJS/tools";
import BigNumber from "COMJS/bignumber.min"

export default {
  props: {
    reserveId: String,
    appointOperateType: String
  },
  computed: {
    BigNumber() {
      return BigNumber
    }
  },
  data() {
    return {
      editMode:true,
      entity: {
        reserveId:"",
        goodsReserveNum: 0,
        operateType: "",//add,sub
        operateReserveNum: 0
      },
      dialogFormVisible: false,
      formLabelWidth: "100px",
      loading: false,
    };
  },
  watch:{
    dialogFormVisible(){
      tools.clearObj(this.entity)
      if(!this.dialogFormVisible){
        //关闭
        return;
      }
      this.$set(this.entity, "operateType", this.appointOperateType)
      if(this.reserveId){
        this.getDetail();
      }
    }
  },
  methods: {
    show(){
      this.dialogFormVisible = true
    },
    hide(){
      this.dialogFormVisible = false
    },
    keyupEvent4PositiveInteger: inputKeyEvent.keyupEvent4PositiveInteger,
    getDetail() {
      if (!this.reserveId) {
        return
      }
      let param = {
        reserveId: this.reserveId,
      }
      pimReserveService.queryByParam(param).then(data => {
        if (!data || !data.reserveId) {
          //没有记录
          return;
        }
        this.$set(this.entity, "reserveId", data.reserveId)
        this.$set(this.entity, "goodsReserveNum", data.goodsReserveNum)
      })
    },

    submitForm() {
      if ((this.entity.operateType != 'add' && this.entity.operateType != 'sub')
          || this.entity.operateReserveNum <= 0) {
        return this.$message({
          message: "请填写正确的操作数量",
          type: "warning"
        });
      }
      let params = {
        reserveId: this.reserveId,
        operateType: this.entity.operateType,
        operateReserveNum: this.entity.operateReserveNum
      };
      this.loading = true;
      pimReserveService.addOrSubReserveNum(params).then(res => {
        this.loading = false;
        this.$emit("updateTable");
      });
      this.hide();
    },
  }
};
</script>

<style lang="scss">
.upload-img-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: $themeColor;
  border: 1px solid #dcdee0;
  border-radius: 0px;
}

.xrequired:before {
  content: '* ';
  color: red;
}
</style>
