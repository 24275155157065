<template>
  <div id="informationDetail">
    <div class="information-title">商品标签</div>
    <div class="label">
      <div class="label-column-full">
        <el-button type="primary" @click="addTagToList">添加</el-button>
        <ws-page-table :data="newData.tagList" ref="tagListTable" style="margin-top:10px;width:100%;"
                       :pageable="false" :showIndex="true">
          <el-table-column label="标签信息" minWidth="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.tagContent" maxlength="8" show-word-limit></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注" minWidth="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark" maxlength="100" show-word-limit></el-input>
            </template>
          </el-table-column>
          <el-table-column width="100" label="是否启用">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enabled"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="有效时间" minWidth="150">
            <template slot-scope="scope">
              <el-date-picker v-model="scope.row.timeIn"
                              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetimerange" range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="90">
            <template slot-scope="scope01">
              <div class="icon-operation">
                <i class="el-icon-circle-close" @click="deleteOneTag(scope01.$index)"></i>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>
    <div class="information-title">
      <span>细节展示</span>
      <span class="a-tag" @click="$refs.displayExamplePopup.dialogVisible = true">查看示例</span>
    </div>
    <div class="label">
      <div class="label-column-none">
        <div>位置1</div>
        <el-input class="myInput" v-model="newData.position1.content"/>
        <div class="label-describe">用于商品详情的展示，不填写即不展示。</div>
      </div>
      <div class="label-column-none">
        <div>位置2</div>
        <el-input class="myInput" v-model="newData.position2.content"/>
        <div class="label-describe">用于商品详情的展示，不填写即不展示。</div>
      </div>
      <div class="label-column-none">
        <div>位置3</div>
        <el-input class="myInput" v-model="newData.position3.content"/>
        <div class="label-describe">用于商品详情的展示，不填写即不展示。</div>
      </div>
    </div>
    <div class="label">
      <div class="label-column-none">
        <div>位置4</div>
        <div style="margin-top: 10px;">
          <el-tag v-for="(tag,idx) in newData.position4List" :key="tag.content+idx" closable
                  type="warning" style="margin:5px;" @close="delPositionList(4,idx)">
            {{ tag.content }}
          </el-tag>
          <el-input class="input-new-tag" v-if="position4InputVisible" v-model="positionInputValue"
                    ref="position4Input" size="small" @keyup.enter.native="handleInputConfirm(4)" @blur="handleInputConfirm(4)">
          </el-input>
          <div v-else style="display: flex;align-items: center;">
            <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;" @click="showPositionInput(4)"></i>
          </div>
        </div>
      </div>
      <div class="label-column-none">
        <div>位置5</div>
        <div style="margin-top: 10px;">
          <el-tag v-for="(tag,idx) in newData.position5List" :key="tag.content+idx" closable
                  type="warning" style="margin:5px;" @close="delPositionList(5,idx)">
            {{ tag.content }}
          </el-tag>
          <el-input class="input-new-tag" v-if="position5InputVisible" v-model="positionInputValue"
                    ref="position5Input" size="small" @keyup.enter.native="handleInputConfirm(5)" @blur="handleInputConfirm(5)">
          </el-input>
          <div v-else style="display: flex;align-items: center;">
            <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;" @click="showPositionInput(5)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="information-title">详情图片</div>
    <div class="label">
      <div class="label-column-long">
        <div><span style="color:red">*</span> 商品详情图</div>
        <div>
          <draggable-upload class="myInput" :width="82" :height="82" :limit="50" :uploadUrl="uploadUrl()"
                            :requestHeaders="requestHeaders"
                            v-model="newData.detailImgList"></draggable-upload>
        </div>
        <div class="label-describe">
          在小程序商品详情页依次展示，你可以拖拽图片调整顺序，最多上传50张。
        </div>
      </div>
    </div>
    <div class="sumbit">
      <el-button @click="back">取消</el-button>
      <el-button type="primary" @click="save" :disabled='toView'>保存</el-button>
      <el-button type="primary" @click="save('next')" :disabled='toView'>下一步并保存</el-button>
    </div>

    <example-popup ref="displayExamplePopup" title="查看展示示例" tips="商品在APP及小程序的详情展示示例：" :imgUrl="require('ASSETS/images/goods_display_example.png')"></example-popup>
  </div>
</template>

<script>
import IoProgressService from "API/IoProgressService.js";
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import examplePopup from "COMPS/widgets/examplePopup";
import pimGoodsService from "API/pim/PimGoodsService";

export default {
  name: "informationDetail",
  props: {
    goodsData: {
      type: Object,
      default: {
        goodsInfo:{},
        imgInfo: {},
      }
    },
    toView: Boolean,
  },
  components: {
    draggableUpload,
    examplePopup
  },
  watch: {
    goodsData(){
      //console.log("informationDetail","goodsData",this.goodsData);
      //this.getGoodsDetails(this.goodsData.goodsId);
      this.getGoodsTagAndExhibitionAndDetailPhoto();
    },
  },
  data() {
    return {
      oldData:{},
      newData:{
        tagList:[],
        position1: {},
        position2: {},
        position3: {},
        position4List: [],
        position5List: [],
        detailImgList:[],
      },
      position4InputVisible: false,
      position5InputVisible: false,
      positionInputValue:'',
      requestHeaders: {
        Authorization: userModule.token,
      },
    }
  },
  methods: {
    getGoodsTagAndExhibitionAndDetailPhoto(){
      let data = this.goodsData;
      //tagList
      let tagTempList = data.tagList;
      if (tagTempList) {
        for (let ele of tagTempList) {
          ele.enabled = ele.enabled == "1" ? true : false;
          ele.timeIn = [ele.beginTime, ele.endTime];
        }
      }
      this.newData.tagList  =tagTempList;
      //exhibition
      if (data.exhibitionList) {
        let list1 = data.exhibitionList.filter(
            (e) => e.position === "position1"
        );
        if (list1.length > 0) {
          this.newData.position1 = list1[0];
        }
        let list2 = data.exhibitionList.filter(
            (e) => e.position === "position2"
        );
        if (list2.length > 0) {
          this.newData.position2 = list2[0];
        }
        let list3 = data.exhibitionList.filter(
            (e) => e.position === "position3"
        );
        if (list3.length > 0) {
          this.newData.position3 = list3[0];
        }
        let list4 = data.exhibitionList.filter(
            (e) => e.position === "position4"
        );
        if (list4.length > 0) {
          this.newData.position4List = list4;
        }
        let list5 = data.exhibitionList.filter(
            (e) => e.position === "position5"
        );
        if (list5.length > 0) {
          this.newData.position5List = list5;
        }
      }
      //detailImgList
      this.newData.detailImgList = data.detailPhotoAlbums ? data.detailPhotoAlbums.map(a=>{
        return {response: a.photoUrl}
      }) : [];

      this.oldData = JSON.parse(JSON.stringify(this.newData));
    },
    checkDataChange(){
      if (JSON.stringify(this.newData) != JSON.stringify(this.oldData)) {
        return true;
      }
      return false;
    },
    saveDataChange(){
      let state = this.save();
      return state
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    addTagToList() {
      if (!this.newData.tagList) {
        this.newData.tagList = [];
      }
      this.newData.tagList.push({});
    },
    deleteOneTag(index) {
      this.newData.tagList.splice(index, 1);
    },
    handleInputConfirm(position) {
      let inputValue = this.positionInputValue;
      if (inputValue) {
        if(position==4){
          this.newData.position4List.push({content:inputValue});
        }else if(position==5){
          this.newData.position5List.push({content:inputValue});
        }
      }
      this.position4InputVisible = false;
      this.position5InputVisible = false;
      this.positionInputValue = "";
    },
    showPositionInput(position) {
      if(position==4){
        this.position4InputVisible = true;
        this.$nextTick((_) => {
          this.$refs.position4Input.$refs.input.focus();
        });
      }else if(position==5){
        this.position5InputVisible = true;
        this.$nextTick((_) => {
          this.$refs.position5Input.$refs.input.focus();
        });
      }
    },
    delPositionList(position,idx) {
      if(position==4){
        this.newData.position4List.splice(idx, 1);
      }else if(position==5){
        this.newData.position5List.splice(idx, 1);
      }
    },
    back() {
      this.$confirm(`是否取消？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.go(-1);
      });
    },
    save(next) {
      //_detailPhotoAlbums
      let _detailPhotoAlbums = []
      if(this.newData.detailImgList && this.newData.detailImgList.length>0){
        _detailPhotoAlbums = this.newData.detailImgList.map((e, i) => {
          return {
            photoUrl: e.response,
            name: e.name,
            state: 1,
            photoType: "detail",
            position: i + 1,
          };
        });
      }
      //_tagList
      let _tagList = []
      if(this.newData.tagList && this.newData.tagList.length>0){
        _tagList = this.newData.tagList.map(ele=>{
          return{
            ...ele,
            enabled:ele.enabled == true ? "1" : "0",
            beginTime:ele.timeIn[0],
            endTime:ele.timeIn[1],
          }
        })
      }
      //_exhibitionList
      let _exhibitionList = [];
      if (this.newData.position1 && this.newData.position1.content) {
        this.newData.position1.position = "position1";
        this.newData.position1.contentType = "text";
        _exhibitionList.push(this.newData.position1);
      }
      if (this.newData.position2 && this.newData.position2.content) {
        this.newData.position2.position = "position2";
        this.newData.position2.contentType = "text";
        _exhibitionList.push(this.newData.position2);
      }
      if (this.newData.position3 && this.newData.position3.content) {
        this.newData.position3.position = "position3";
        this.newData.position3.contentType = "text";
        _exhibitionList.push(this.newData.position3);
      }
      if (this.newData.position4List && this.newData.position4List.length > 0) {
        for (let i in this.newData.position4List) {
          this.newData.position4List[i].position = "position4";
          this.newData.position4List[i].contentType = "text";
          this.newData.position4List[i].dispNo = i;
          _exhibitionList.push(this.newData.position4List[i]);
        }
      }
      if (this.newData.position5List && this.newData.position5List.length > 0) {
        for (let i in this.newData.position5List) {
          this.newData.position5List[i].position = "position5";
          this.newData.position5List[i].contentType = "text";
          this.newData.position5List[i].dispNo = i;
          _exhibitionList.push(this.newData.position5List[i]);
        }
      }

      let params = {
        goodsId: this.goodsData.goodsId ? this.goodsData.goodsId : "",
        tagList:_tagList,
        exhibitionList:_exhibitionList,
        detailPhotoAlbums: _detailPhotoAlbums,
      };

      pimGoodsService.saveGoodsTagAndExhibitionAndDetailPhoto(params).then((res) => {
        this.$message.success("保存成功");
        this.oldData = JSON.parse(JSON.stringify(this.newData));
        //this.$emit("returnGoodsData", res);
        if (next == "next") {
          this.$emit("swiStep", 3);
        }
      });
      return 1;
    },
  }
}
</script>

<style scoped>
@import url("./malllCss.css");

#informationDetail {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}
</style>