<template>
    <div>
        <div class="filter-form">
            <div class="filter_left">
                <el-button size="mini" type="primary" @click="exportCash">导出</el-button>
                <span class="search-wrapper">
                    <el-input size="mini" v-model="searchInfo.distributorAccount" placeholder="请输入账号"></el-input>
                    <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
                </span>
            </div>
        </div>
        <ws-page-table
                :data="tableData"
                ref="pageTable"
                :pageable="true"
                :pageInfo="pageInfo"
                @pageChange="loadTableData"
                :showIndex="true"
                fix-height="440px"
        >
            <el-table-column prop="applyTime" label="申请时间" min-width="174" :formatter="datetimeFormatter" show-overflow-tooltip></el-table-column>
            <el-table-column prop="withdrawCashCode" label="提现编码" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="withdrawCashType" label="提现类型" min-width="120"  :formatter="cashTypeFormatter" show-overflow-tooltip></el-table-column>
            <el-table-column prop="withdrawCashAmount" label="申请提现金额" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="distributorAccount" label="账号" min-width="130" show-overflow-tooltip></el-table-column>
            <el-table-column prop="distributorName" label="申请者姓名" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="withdrawCashWay" label="提现方式" min-width="110" :formatter="cashWayFormatter" show-overflow-tooltip></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="110" show-overflow-tooltip></el-table-column>
            <el-table-column prop="appId" label="APPID" min-width="190" show-overflow-tooltip></el-table-column>
            <el-table-column prop="openId" label="OPENID" min-width="190" show-overflow-tooltip></el-table-column>
            <el-table-column prop="handleState" label="支付状态" min-width="100" :formatter="handleStateFormatter" fixed = "right" show-overflow-tooltip></el-table-column>
        </ws-page-table>
    </div>
</template>

<script>
    import pageTableMixin from "@/mixin/pageTableMixin.js";
    import tools from "COMJS/tools";
    import Objects from "../../../js/Objects";
    import dimWithdrawCashService from "API/dim/DimWithdrawCashService";
    import IoProgressService from "API/IoProgressService";

    export default {
        name: "withdrawCashLogList",
        data() {
            return {
                searchInfo: {
                    handleState:['unhandle','handle'],
                    distributorAccount:'',
                    withdrawCashState:'success',
                    //withdrawCashType:[],
                    withdrawCashWay:[],
                    //applyTime:'',
                    state:'1'
                },
                items: {
                    handleState: {filter: "IN"},
                    distributorAccount: {filter: "like"},
                    withdrawCashState: {filter: "EQ"},
                    //withdrawCashType: {filter: "IN"},
                    withdrawCashWay: {filter: "IN"},
                    //applyTime: {filter: "between",dataType:"date"},
                    state: {filter:"EQ"},
                },
                sorts:{
                    applyTime: {order:"desc"}
                }
            };
        },
        mixins: [pageTableMixin],
        mounted() {
            this.loadTableData();
        },
        methods: {
            loadTableData() {
                this.$set(this.pageInfo,'pageNo',this.$store.state.pageNo);
                this.$refs.pageTable.tableLoading = true;
                dimWithdrawCashService.findWithdrawCashLogPage(this.pageInfo, this.searchConfig).then(
                    data => {
                        this.querySuccess(data);
                    }
                ).catch(e=>{
                    this.$refs.pageTable.tableLoading = false;
                });
            },
            exportCash(){
                let loading = this.$loading({
                    lock: true,
                    text: "Loading..."
                });
                dimWithdrawCashService.exportWithdrawCashExcel(this.searchConfig).then(data => {
                    let fileName = data.fileName;
                    let module = data.module;
                    IoProgressService.download(module, fileName, false);
                    loading.close();
                }).catch(() => {
                    loading.close();
                });
            },
            datetimeFormatter(row, column){
                let v = "";
                if (row[column.property]) {
                    v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd hh:mm:ss");
                }
                return v;
            },
            cashTypeFormatter(row, column){
                let v = '-';
                if(row.withdrawCashType){
                    v = row.withdrawCashType;
                    if(row.withdrawCashType){
                        v = row.withdrawCashType;
                        if('bondCash'==row.withdrawCashType){
                            v = '余额提现';
                        }
                        if('profitCash'==row.withdrawCashType){
                            v = '主服务费提现';
                        }
                        if('marketCash'==row.withdrawCashType){
                            v = '次服务费提现';
                        }
                    }
                }
                return v;
            },
            //提现方式格式化
            cashWayFormatter(row){
                let v = '-';
                if(row.withdrawCashWay){
                    v = row.withdrawCashWay;
                    if('wechat'==row.withdrawCashWay){
                        v = '微信';
                    }
                    if('bank'==row.withdrawCashWay){
                        v = '银行';
                    }
                }
                return v;
            },
            handleStateFormatter(row){
                let v = '未支付';
                if(row.handleState){
                    if('handle' ==row.handleState){
                        v = '已支付';
                    }
                    if('unhandle' ==row.handleState){
                        v = '未支付';
                    }
                }
                return v;
            }

        }
    }
</script>

<style scoped>

</style>
