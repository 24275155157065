<template>
  <div class="inv-edit">
    <div class="step-title">新增库品</div>
    <el-form
      :model="whmInfo"
      label-width="140px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item label="库品编码:">
        <div
          class="select-btn"
          v-if="!currentRow.id"
          @click="openskuCodeDialog"
        >
          请选择库品编码
        </div>
        <template v-else>
          <span>{{ currentRow.skuCode }}</span>
          <span
            class="tips-btn"
            style="dispaly: inline-block; margin-left: 15px"
            @click="openskuCodeDialog"
            >重选库品编码</span
          >
        </template>
      </el-form-item>
      <el-form-item label="库品名称:">
        <el-input v-model="currentRow.skuName" disabled></el-input>
      </el-form-item>
      <el-form-item label="库品分类:">
        <el-input v-model="currentRow.thingTypeName" disabled></el-input>
      </el-form-item>

      <el-form-item label="库品单位:">
        <el-input v-model="currentRow.skuUnit" disabled></el-input>
      </el-form-item>

      <el-form-item label="库品规格:">
        <div class="whm-sku">
          <el-form-item label-width="80px" label="规格名:">
            <el-input v-model="currentRow.skuSpecName" disabled></el-input>
          </el-form-item>
          <el-form-item label-width="80px" label="规格值:">
            <el-input v-model="currentRow.skuSpec" disabled></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="库品单价:">
        <el-input
          type="number"
          v-model="whmInfo.purchasePrice"
          class="shelf-life"
        >
          <template slot="prepend">￥</template>
        </el-input>
        <!-- <span class="c9 ml12">非必填</span> -->
      </el-form-item>
      <el-form-item label="保质期:">
        <el-input
          type="number"
          v-model="currentRow.shelfLife"
          class="shelf-life"
          disabled
        ></el-input>
        <span class="ml12">天</span>
      </el-form-item>
      <el-form-item label="库存批次:">
        <el-input v-model="whmInfo.purchaseBatchCode"></el-input>
      </el-form-item>

      <el-form-item label="生产日期:">
        <el-date-picker
          v-model="whmInfo.productionDate"
          type="date"
          placeholder="选择日期"
          @change="invGetTime"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <!--选择日期后自动计算-->
      <!-- <el-form-item label="保质期至:">
        <el-input v-model="whmInfo.doomsday"></el-input>
      </el-form-item> -->
      
      <el-form-item label="保质期至:">
        <el-date-picker
          v-model="whmInfo.doomsday"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="当前库存:">
        <el-input type="number" v-model="currentRow.actualNum" disabled></el-input>
      </el-form-item>

      <el-form-item label="新增数量:">
        <el-input type="number" v-model="whmInfo.availableNum"></el-input>
      </el-form-item>

      <el-form-item label="单据编号:">
        <el-input v-model="whmInfo.billCode"></el-input>
      </el-form-item>

      <el-form-item label="选择供应商:">
        <div
          class="select-btn"
          v-if="!whmInfo.supplierId"
          @click="$refs.selectSupplierPopup.dialogVisible = true"
        >
          请选择供应商
        </div>
        <template v-else>
          <span>{{ whmInfo.supplierName }}</span>
          <span
            class="tips-btn"
            style="dispaly: inline-block; margin-left: 15px"
            @click="$refs.selectSupplierPopup.dialogVisible = true"
            >重选供应商</span
          >
        </template>
      </el-form-item>

      <!---fileSizeText="128k"-->
      <el-form-item label="入库照片：">
        <draggable-upload
          :width="82"
          :height="82"
          :limit="3"
          :uploadUrl="uploadUrl()"
          :requestHeaders="requestHeaders"
          v-model="allPhotoId"
        ></draggable-upload>
      </el-form-item>

      <el-form-item label="备注:">
        <el-input v-model="whmInfo.remark"></el-input>
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">取消</el-button>
      <el-button type="primary" @click="createWhm">保存入库</el-button>
    </div>

    <select-supplier-popup ref="selectSupplierPopup" @submit="selectSupplier"></select-supplier-popup>

    <el-dialog :visible.sync="skuCodeDialog" width="50%" class="table-wrap">
      <el-form label-width="160px" class="input-txt-btn">
        <el-input v-model="searchInfo.skuName" placeholder="库品名称"></el-input>
        <el-input v-model="searchInfo.skuCode" placeholder="库品编码"></el-input>
        <el-button type="primary" @click="loadTableData">搜索</el-button>
      </el-form>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @current-change="handleCurrentChange"
        @pageChange="loadTableData"
        :selection="'single'"
      >
        <el-table-column
          prop="skuName"
          label="库品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuCode"
          label="库品编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <div class="btn-blue" @click="skucodeConfirm">确定</div>
        <div class="btn-gray" @click="closeSkucode">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import selectSupplierPopup from "./selectSupplierPopup"; //选择供应商
import userModule from "@/module/userModule";
import eventBus from "../../../js/eventBus";
import WhmList from "API/whm/WhmList";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import tools from "COMJS/tools";
import invQuery from "API/whm/invQuery";

export default {
  name: "whmEdit",
  data() {
    return {
      pickerOptions: {//时间必须小于等于今天
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      //thumbnailImgs: [], //上传图片的字段
      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        skuId: "",
        purchaseBatchCode: "", //批次
        availableNum: "", //当前库存
        supplierId: "", //供应商id
        purchasePrice: "", //单价
        productionDate: "", //生产日期
        billCode: "", //单据编号
        photoIds: "", //图片ID集（逗号隔开）
        remark: "", //备注
        doomsday: "",//保质期至
      },
      allPhotoId: [],
      selectedSupplier: {
        specSupplierId: "",
      },
      skuCodeDialog: false, //库品编码弹窗开关
      searchInfo: {
        skuCode: "",
        skuName: "",
        type: 0,
        enabled: 1,
      },
      items: {
        skuCode: { filter: "like" },
        skuName: { filter: "like" },
      },
      currentRow: {
        skuCode: "", //库品编码
        skuName: "", // 库品名称
        thingTypeName: "", //分类
        skuSpecName: "", //规格名
        skuSpec: "", //规格值
        skuUnit: "", //规格单位
        shelfLife: "", //保质期
        actualNum: "", //当前库存
        warningNum: "", //预警值
        soldOutNum: "", //售罄值
      },
    };
  },
  mixins: [pageTableMixin],
  components: {
    selectSupplierPopup,
    draggableUpload,
  },
  mounted() {
    this.loadTableData();
  },
  methods: {
    closeSkucode(){
      this.skuCodeDialog = false
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("whm_img");//whm_img图片文件夹
      return url;
    },
    invGetTime(){//获取保质期至
      let chooseTime = tools.formatDate(new Date(this.whmInfo.productionDate), "yyyy-MM-dd")
      //this.whmInfo.doomsday
      //console.log(chooseTime)
      let timestamp = new Date(chooseTime).getTime()
      //一天时间戳、一天的时间戳 等于24*60*60*1000
      //保质期:currentRow.shelfLife
      //console.log(this.currentRow.shelfLife)
      let shelfLifeTimestamp = this.currentRow.shelfLife * 24*60*60*1000
      //console.log(shelfLifeTimestamp)
      let endTime = ""
      endTime = timestamp + shelfLifeTimestamp
      let doomsday = tools.formatDate(new Date(endTime), "yyyy-MM-dd");
      this.whmInfo.doomsday = doomsday
    },
    skucodeConfirm(){
      this.skuCodeDialog = false
    },
    skucodeCancel(){
      this.skuCodeDialog = false
      this.currentRow = {
        skuCode: "", //库品编码
        skuName: "", // 库品名称
        thingTypeName: "", //分类
        skuSpecName: "", //规格名
        skuSpec: "", //规格值
        skuUnit: "", //规格单位
        shelfLife: "", //保质期
        actualNum: "", //当前库存
        warningNum: "", //预警值
        soldOutNum: "", //售罄值
      }
    },
    handleCurrentChange(val) {
      if (val) {
        //console.log(val)
        this.currentRow = val        
      }
    },
    openskuCodeDialog() {
      this.skuCodeDialog = true;
    },
    // 查询库品列表
    loadTableData() {
      //this.$refs.pageTable.tableLoading = true;
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            thingTypeName: e.level ? e.level.thingTypeName : "", //分类
            skuSpecName: e.skuSpecName ? e.skuSpecName.name : "", //规格名
            skuSpec: e.skuSpec ? e.skuSpec.name : "", //规格值
            skuUnit: e.skuUnit ? e.skuUnit.name : "", //规格单位
            shelfLife: e.shelfLife, //保质期
            actualNum: e.actualNum, //当前库存
            warningNum: e.warningNum,
            soldOutNum: e.soldOutNum,
          };
        });
        this.querySuccess(data);
      });
    },    
    selectSupplier(params) {
      this.selectedSupplier = params;
      this.whmInfo.supplierId = params.supplierId;
      this.whmInfo.supplierName = params.supplierName;
    },
    backPre() {
      //返回上一页
      this.$router.push("/whm/invList");
    },
    createWhm() {
      const that = this;
      let whmInfo = this.whmInfo;      
      whmInfo.skuId = this.currentRow.id//库品id
      //console.log(whmInfo.skuId)
      whmInfo.productionDate = tools.formatDate(new Date(this.whmInfo.productionDate), "yyyy-MM-dd")
      //console.log(whmInfo.productionDate)
      let picsUrl = [] //获取图片地址
      for(let i in this.allPhotoId){
        picsUrl.push(this.allPhotoId[i].response)
      }     
      let picStr = picsUrl.join(",")
      whmInfo.photoIds = picStr
      if(!this.whmInfo.skuId){
        return this.$message({
          message: "请选择库品编码",
          type: "warning",
        });
      }
      if (!whmInfo.purchaseBatchCode) {
        return this.$message({
          message: "请添加批次",
          type: "warning",
        });
      }      
      if (!whmInfo.availableNum || whmInfo.availableNum <= 0) {
        return this.$message({
          message: "请添加新增数量",
          type: "warning",
        });
      }
      if (whmInfo.availableNum && whmInfo.availableNum % 1 !== 0){
        return this.$message({
          message: "请填写正整数量",
          type: "warning",
        });
      }
      if (!whmInfo.purchasePrice || whmInfo.purchasePrice < 0) {
        return this.$message({
          message: "请添加库品单价",
          type: "warning",
        });
      }      
      if (!whmInfo.productionDate || whmInfo.productionDate == "NaN-aN-aN") {
        if(whmInfo.productionDate == "NaN-aN-aN"){
          whmInfo.productionDate = ""
        }
        return this.$message({
          message: "请添加生产日期",
          type: "warning",
        });
      }
      if (!whmInfo.billCode) {
        return this.$message({
          message: "请添加单据编号",
          type: "warning",
        });
      }
      if (!whmInfo.supplierId) {
        return this.$message({
          message: "请选择供应商",
          type: "warning",
        });
      }
      if (whmInfo.photoIds.length == 0) {
        return this.$message({
          message: "请添加入库照片",
          type: "warning",
        });
      }
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      invQuery.saveInventory(whmInfo)
        .then((res) => {
          //this.$router.push("/pim/productList");
          //eventBus.$emit("refresh");
          if (this.$route.params.id == "undefined") {           
            if (res.msg == "OK") {
              this.$message({
                message: "新增成功",
                type: "success",
              });
            }
            setTimeout(function () {
              that.$router.push("/whm/invList");
            }, 300);
          } else {
            if (res.msg == "OK") {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            setTimeout(function () {
              that.$router.push("/whm/invList");
            }, 300);
          }
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss">
.inv-edit .el-form-item .el-form-item__content > .el-input,
.inv-edit .el-input--small .el-input__inner {
  width: 150px;
}
.inv-edit .shelf-life.el-input--small {
  width: 150px;
}
.inv-edit .step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
  color: #333;
}
.inv-edit .select-btn {
  width: 150px;
  text-align: center;
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}
.inv-edit .whm-sku {
  padding: 20px;
  display: inline-block;
  border: 1px solid #ddd;
}
.inv-edit .whm-sku .select-btn {
  margin-top: 10px;
}
.inv-edit .select-btn-wrap {
  padding: 12px;
}
.inv-edit .select-label {
  padding-left: 12px;
  width: 188px;
  background: #f8f8f8;
}
.inv-edit .c9 {
  margin-left: 12px;
  color: #999;
}
.inv-edit .ml12 {
  margin-left: 12px;
}
.inv-edit .steps-btn-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.inv-edit .btn-next-step {
  margin: 20px auto 0 auto;
  width: 76px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background: #1b73e8;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
}
.inv-edit .search-result {
  height: 360px;
  overflow-y: auto;
}
.inv-edit .tips-btn {
  cursor: pointer;
  color: #1b73e8;
}
.inv-edit .input-txt-btn {
  margin-bottom: 20px;
  display: flex;
}
.inv-edit .input-txt-btn .el-input {
  width: 160px;
}
.inv-edit .table-wrap .el-dialog__header {
  border-bottom: 0;
}
.inv-edit .table-wrap .el-table .cell {
  line-height: 42px;
}
.inv-edit .table-wrap .el-input--small .el-input__inner {
  width: 40px;
}
.inv-edit .input-txt-btn .el-input--small .el-input__inner {
  width: 150px;
}
.inv-edit .btn-blue-wrap {
  display: flex;
  justify-content: flex-end;
}
.inv-edit .btn-blue,.inv-edit .btn-gray {
  margin-top: 20px;
  width: 80px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  background: #1b73e8;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.inv-edit .btn-gray{ margin-left: 10px; width: 78px; height: 34px; border:1px solid #ddd; color: #999; background: #fff;}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {-webkit-appearance: none !important;}
input[type="number"]{-moz-appearance: textfield;}
</style>