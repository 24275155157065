<template>
  <div class="order-list">
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">订单号：</span>
            <el-input size="mini" v-model="searchInfo.orderCode"></el-input>
          </div>
          <el-button
              size="mini"
              type="primary"
              style="margin-right: 10px"
              @click="searchData"
          >搜索
          </el-button>
        </div>
      </div>

    </div>
    <div class="order-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs
          v-model="searchInfo.afterState"
          type="card"
          @tab-click="changeTable"
        >
          <el-tab-pane label="售后中" name="processing"></el-tab-pane>
          <el-tab-pane label="全部" name="all"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :selection="null"
        :isShadow="false"
      >
        <el-table-column prop="orderAfterCode" label="售后单号" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="orderCode" label="订单号" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="status" label="售后状态" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="handleTotalAmount" label="售后价格（元）" width="180"></el-table-column>
        <el-table-column prop="createTime" label="申请时间" width="180"></el-table-column>
        <el-table-column prop="remark"  label="客服备注" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="编辑">
                <i
                  class="CCSFont icon-table_1"
                  @click="showAfterDetail(scope.row)"
                ></i>
              </a>
              <a href="javascript: void(0);" title="查看订单详情">
                <i
                    class="CCSFont icon-table_2"
                    @click="$router.push(`/som/orderEditD/${scope.row.orderId}`)"
                ></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <!-- <el-dialog :title="dialogTitle" :visible.sync="afterDialogVisible" width="600px">
      <el-input v-model="afterDetail.reason" disable></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="afterConfirm">确 定</el-button>
      </div>
    </el-dialog> -->
    <returnInformationPop ref="returnInformation" @auditOk='loadTableData'></returnInformationPop>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import { pageAfterSaleByParam } from "API/som/afterService";
import pageTableMixin from "@/mixin/pageTableMixin";
import returnInformationPop from './returnInformationPop'
import userModule from "@/module/userModule";

export default {
  name: "orderAfterListForDistributor",
  data() {
    return {
      searchInfo: {
        orderCode: "",
        afterState: "processing",
        orderCustomerUserId:JSON.parse(userModule.PPUser).userId,
      },
      dialogTitle:"售后详情",
      afterDetail:{}
    };
  },
  components:{
    returnInformationPop
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      pageAfterSaleByParam(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          e.createTime = tools.formatDate(
            new Date(e.createTime),
            "yyyy-MM-dd hh:mm:ss"
          );
          // e.source = translate.source(e.orderType);
          e.status = this.translateAfterHandleState(e);
          return e;
        });
        this.querySuccess(data);
      });
    },
    showAfterDetail(e){
      this.$refs.returnInformation.dialogVisible=true;
      this.$refs.returnInformation.getAfterDetail(e);
    },
    afterConfirm(){
      // this.afterDialogVisible = false
    },
    changeTable(event) {
      this.searchInfo.afterState = event.name;
      this.loadTableData();
    },
    translateAfterHandleState(e){
      let str
      if(e.handleState=='processing'){
        str = "售后审核中"
      }else if(e.handleState=='wait_return'){
        str = "等待退货，请填写退货信息"
      }else if(e.handleState=='wait_refund'){
        str = "等待确认退款"
      }else if(e.handleState=='finish'){
        str = "售后完成"
      } else if(e.handleState=='closed'){
        str = "售后驳回"
      }
      return str
    }


  },
  mounted() {
    //console.log('vue mounted',this.$route)
  },
  /*beforeMount(){
    //页面刷新
    if(this.$route.query.orderState){
      this.searchInfo.orderState = this.$route.query.orderState;
    }
  },
  activated(){
    //页签切换
    if(this.$route.query.orderState){
      this.searchInfo.orderState = this.$route.query.orderState;
      this.loadTableData();
    }
  }*/
};
</script>

<style lang="scss">
.order-list {
  .order-table {
    .tabs-wrapper {
      padding: 10px 20px;
      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;
        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }
    background-color: #fff;
  }
}

.order-list .el-dialog__body .el-input {
  width: 100% !important;
}
</style>
