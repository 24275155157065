import axiosHttp from "../axiosHttp";

//保存推荐商品
export const saveBatchRelation = data=>{
    return axiosHttp.POST('/pimGoodsRelation/saveBatchRelation',data)
}

//推荐商品列表
export const queryInfoAtPage = data=>{
    return axiosHttp.POST('/pimGoodsRelation/queryInfoAtPage',data)
}

  // 删除关联信息
  export const delByParentGoodsIds=Id=> {
    return axiosHttp.GET(`/pimGoodsRelation/delByParentGoodsIds/${Id}`)
  }