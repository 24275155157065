const frame = { template: '<router-view></router-view>' }

export default {
    path: 'bim',
    component: frame,
    children: [{
        name: '会员列表',
        path: 'memberList',
        component: resolve => import('PAGES/bim/member/memberList.vue')
    }, {
        name: '会员详情',
        path: 'memberDetail/:id',
        component: resolve => import('PAGES/bim/member/memberDetail.vue')
    },{
        name: '会员账户调整',
        path: 'memberAccountModify/:id',
        component: resolve => import('PAGES/bim/member/memberAccountModify.vue')
    }, {
        name: '首金邀请',
        path: 'invitedUsers',
        component: resolve => import('PAGES/bim/invite/invitedUsers.vue')
    },{
        name: '权限管理',
        path: 'authorization',
        component: resolve => import('PAGES/bim/authorization/authorization.vue')
    },{
        name: '权限详情',
        path: 'authorizationDetail/:id',
        component: resolve => import('PAGES/bim/authorization/authorizationDetailPopup.vue')
    },{
        name: '参数管理',
        path: 'paramList',
        component: resolve => import('PAGES/bim/param/paramList.vue')
    },{
        name: '枚举管理',
        path: 'enumList',
        component: resolve => import('PAGES/bim/enum/BimEnumerationMain.vue')
    },{
        name: '账户明细',
        path: 'accountDetail',
        component: resolve => import('PAGES/bim/account/accountDetail.vue')
    },{
        name: '运营管理',
        path: 'userNotice',
        component: resolve => import('PAGES/bim/notice/userNotice.vue')
    },{
      name: '活动设置',
        path: 'CommodityActivities',
        component: resolve => import('PAGES/bim/CommodityActivities/ActivityList.vue')
    },{
      name: '活动内容编辑',
        path: '/activityEdit',
        component: resolve => import('PAGES/bim/CommodityActivities/activityEdit.vue')
    },{
        name: '文件管理',
          path: 'FileDownload',
          component: resolve => import('PAGES/bim/FileDownload/FileList.vue')
      }]
}