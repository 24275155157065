<template>
  <div class="order-pay">
    <el-dialog title="支付" :visible.sync="dialogVisible" width="660px">
      <div style="padding-top:32px;">
        <div
                style="display:flex;justify-content:space-between;font-size:14px;"
        >
          <div>共{{ this.paymentInfo.orderCount }}个订单，请选择支付方式</div>
          <div>
            订单金额：<span
                  style="color:#1B73E8;font-weight:bold;font-family:San Francisco Display;font-size:24px;"
          >¥{{ this.paymentInfo.orderAmount.toFixed(2) }}</span
          >
          </div>
        </div>
        <!-- <el-radio-group
                v-model="choosePayway"
                @change="changePayway"
                style="margin-top:27px;"
        > -->
        <el-radio-group
                v-model="choosePayway"
                style="margin-top:27px;"
        >
          <el-radio :label="'adm_no_pay'" border>客服免支付</el-radio>
        </el-radio-group>
        <div style="margin-top: 20px;border:1px solid rgba(218,218,218,1); padding:10px;"
             v-show="choosePayway == 'adm_no_pay'">
          <div style="font-size:12px;color:red;margin-top:5px;">
            客服批量下单，不需要支付，点击"确认支付"，批次订单即变更为待发货状态。
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <div
                  style="display:flex;justify-content:center;width:100%;padding-top:31px;"
          >
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
                    style="background:#1B73E8;borderColor:#1B73E8;"
                    type="primary"
                    @click="doPayConfirm"
            >确认支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import tools from "COMJS/tools";
import Objects from "COMJS/Objects";
import orderPaymentService from "API/som/orderPaymentService";
import draggableUpload from "COMPS/base/draggableUpload";
import IoProgressService from "API/IoProgressService";
import QRcode from "qrcodejs2";
import _ from "lodash";

export default {
    components: {
        draggableUpload,
    },
    data() {
        return {
            dialogVisible: false,
            requestHeaders: {
                Authorization: userModule.token,
            },
            orderOperateId: "",
            orderPaymentParam: {},
            paymentInfo: {orderCount: 0, orderAmount: 0},
            choosePayway: "",
            companyBank: {accountName: "", accountNo: "", bankName: ""},
            myBank: {accountName: "", accountNo: "", bankName: ""},
            payOrdersImgs: [],
            giroRemark: "",
            //orderAmount: 0,
            blance: 0,
            commitText: "确认"
        };
    },
    methods: {
        async openPayPopup(orderPaymentParam) {
            this.orderPaymentParam = orderPaymentParam;
            this.paymentInfo = await orderPaymentService.getPrePayData(this.orderPaymentParam);
            //this.blance = (await orderPaymentService.getBalance()).balance;
            //console.log(this.paymentInfo);
            this.dialogVisible = true;
        },
        doPayConfirm() {
            var that = this;
            if (this.choosePayway == "adm_no_pay") {
                let loading = this.$loading({
                    lock: true,
                    text: "支付结果确认中..."
                });
                orderPaymentService.doAdmNoPay(this.orderPaymentParam)
                    .then((data) => {
                        if (data && data["code"] == "success") {
                            that.$message({type: "success", message: "支付成功！"});
                            that.$emit("reloadAfterPay");
                        } else {
                            //不需要支付，提示
                            that.$message.error(data["msg"]);
                        }
                        loading.close();
                    })
                    .catch((data) => {console.log(data);loading.close();});
            }
            this.dialogVisible = false;
        },

    }
};
</script>

<style lang="scss">
  .order-list {
    .order-table {
      .tabs-wrapper {
        padding: 10px 20px;
        .el-tabs__item {
          background: rgba(247, 248, 250, 1);
          border-bottom: 1px solid #dcdee0;
          &.is-active {
            background-color: #fff;
            border-bottom: none;
          }
        }
      }
      background-color: #fff;
    }
  }

  .order-pay .el-dialog__body .el-input {
    width: 100% !important;
  }
</style>