import { render, staticRenderFns } from "./productRelateEdit.vue?vue&type=template&id=049d6991&scoped=true"
import script from "./productRelateEdit.vue?vue&type=script&lang=js"
export * from "./productRelateEdit.vue?vue&type=script&lang=js"
import style0 from "./productRelateEdit.vue?vue&type=style&index=0&id=049d6991&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049d6991",
  null
  
)

export default component.exports