<template>
  <div class="ws-header">
    <div class="ws-header-lef">
      <!--
      <img :src="require('ASSETS/images/wdom.png')" class="logo" alt />
      <span class="sp1">后台管理系统</span>
      -->
      <img :src="require('ASSETS/images/login/wdom_logo_1.jpeg')" class="logo" alt />
    </div>
    <div class="ws-header-rig">
      <div class="header-mess-box" @click="toUserMess">
        <img src="../../assets/images/mess.png" class="icon-mess" alt="" />
        <span class="sp1" v-if="count > 0">{{ this.$store.state.counts || count }}</span>
      </div>
      <span v-show="showFlag" style="margin-right: 15px; font-size: 12px">
        【账户余额：¥{{ userpoint }}】
      </span>
      <span style="margin-right: 5px; font-size: 12px">{{ userAccount }}</span>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-s-custom"
        @click="loginOut"
        >退出</el-button
      >
    </div>
  </div>
</template>

<script>
import eventBus from "COMJS/eventBus";
import tools from "COMJS/tools";
import userModule from "../../module/userModule";
import userinfoService from "@/api/bim/userInfoService";
import homePageService from "@/api/bim/homePageService";

export default {
  name: "wsHeader",
  data() {
    return {
      crumBar: "概况",
      crumBarHtml: "概况",
      userAccount: this.dealTel(JSON.parse(userModule.PPUser).userAccount),
      userpoint: "",
      showFlag: false,
      count: 0,
    };
  },
  mounted() {
    this.getUserMess();
    this.getCrumBar();
    const loginUser = JSON.parse(userModule.PPUser);
    if (loginUser && loginUser.role) {
      if (loginUser.role.indexOf("distributor_") > -1) {
        this.getPoint(JSON.parse(userModule.PPUser).userId);
        this.showFlag = true;
      }else if (loginUser.role.indexOf("agent_") > -1) {
        //查看是否有要弹窗提示的
        //this.showDialog();
      }
      //各种人都有弹窗的需求了
      this.showDialog();
    }
  },
  watch: {
    $route() {
      this.getCrumBar();
    },
  },
  methods: {
    toUserMess(){
      this.$router.push("/ba/systemMessage");
    },
    getUserMess() {
      userinfoService.countUserNoReadByAll().then((data) => {
        this.count = data.count
      });
    },
    refreshRoute() {
      eventBus.$emit("refreshRoute");
    },
    /**
     * 展示页面弹窗通知，sidebar里还有一个
     */
    showDialog() {
      homePageService.homeNotice().then(async (data) => {
        if(data.length>0){
          let that = this
          for (let datum of data) {
            //有点时间差，才能一个一个出来，并且相隔一定距离，否则会叠在一起
            await tools.delay(100)
            let notify
            notify = this.$notify({
              title: datum.title,
              message: datum.message,
              duration: 0,
              customClass:'notify-custom-class',
              onClick:()=>{
                that.$router.push(datum.routerPath);
                notify.close()
              }
            });
          }
        }
      });
    },
    getPoint(userId) {
      let id = userId;
      userinfoService.getPoint(id).then((data) => {
        this.userpoint = this.num_filter(data.points);
      });
    },
    dealTel(tel) {
      tel = "" + tel;
      let tel1 = tel.substr(0, 3) + "****" + tel.substr(7);
      return tel1;
    },
    //千分位//小数点
    num_filter(val) {
      //console.log(val)
      val = val && val.toLocaleString(); // 3,000
      if (val && val.indexOf(".") == -1) {
        //ly-add -val &&
        val = val + ".00"; //3,000.00
      } else if (val && val.charAt(val.indexOf(".") == val.length - 2)) {
        //ly-add -val &&
        val = val + "0";
      }
      return val;
    },
    loginOut() {
      this.$confirm("确认退出当前账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("homeNoticeState");
          userModule.removeCookiesToken();
          userModule.removePPUser();
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出操作",
          });
        });
    },
    getCrumBar() {
      let path = this.$route.path;
      let menus = this.$store.state.menus;
      for (let o of menus) {
        if (o.url && o.url === path) {
          return (this.crumBar = o.menuName);
        }
        if (o.subMenus && o.subMenus.length > 0) {
          for (let menu of o.subMenus) {
            if (menu.url && menu.url === path) {
              this.crumBar = `${o.menuName} / ${menu.menuName}`;
              this.crumBarHtml = `<span style="color: #999999">${o.menuName} / </span><span>${menu.menuName}</span>`;
              return;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.ws-header{ padding-left: 26px; display: flex; align-items: center; justify-content: space-between; box-sizing: border-box; height: 60px; 
border-bottom: 1px solid $borderColor;}
.ws-header-lef{ display: flex; align-items: center; }
.logo{ width: 50px; margin-right: 13px; background-color: #fff; }
.ws-header .sp1{ font-size: 17px; }
.ws-header-rig{ margin-right: 42px; display: flex; align-items: center; }
.header-mess-box{ margin-right: 20px; cursor: pointer;}
.header-mess-box .sp1{ margin:-10px 0 0 -4px; position: absolute; min-width: 18px; height: 18px; text-align: center; line-height: 18px; font-size: 12px; 
color: #fff; background: #FF6060; border-radius: 9px;}
.icon-mess{ width: 18px;}
.notify-custom-class{
  cursor: pointer;
}
</style>