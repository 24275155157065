import axiosHttp from "../axiosHttp";

export const fetchData = (pageInfo,searchConfig)=> {
    let url = "/somGiroRecord/getPageList?";
    if (pageInfo) {
        url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
};
export const exportGiroRecord = data=>{
    return axiosHttp.POST('somGiroRecord/exportGiroRecord',data)
}

export const batchRefund = ids =>{
    return axiosHttp.POST('somGiroRecord/batchRefund',ids)
}

