<template>
  <div>
    <el-dialog title="查看单据" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <el-form ref="dialogForm" label-width="110px">
        <template v-if="materialProcess.processStep>=0">
          <el-divider>物料信息</el-divider>
          <el-row :span="24">
            <el-col :span="12">
              <el-form-item label="物料">
                <span>{{ materialProcess.materialName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应商">
                <span>{{ materialProcess.materialSupplierName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="12">
              <el-form-item label="批次">
                <span>{{ materialProcess.batchCode }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-if="materialProcess.processStep>=1">
          <el-divider>发货信息</el-divider>
          <el-row :span="24">
            <el-col :span="12">
              <el-form-item label="发货物流">
                <span>{{ materialProcess.shipperName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提单号">
                <span>{{ materialProcess.materialTransport.billLadingNo }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="12">
              <el-form-item label="离港时间">
                <span>{{ formatDate(materialProcess.materialTransport.departPortTime) }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预计到港时间">
                <span>{{ formatDate(materialProcess.materialTransport.expectPortTime) }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-if="materialProcess.processStep==2">
          <el-divider>收货信息</el-divider>
          <el-row :span="24">
            <el-col :span="12">
              <el-form-item label="实际到港时间" prop="arrivalPortTimeDate">
                <span>{{ formatDate(materialProcess.materialTransport.arrivalPortTime) }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入库仓库" prop="warehouseId">
                <span>{{ materialProcess.warehouseName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template>
          <el-divider>数量信息</el-divider>
          <el-row :span="24">
            <el-col :span="12">
              <el-form-item :label="processNumLabel">
                <span>{{ materialProcess.processNum }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click.native="remove" v-if="removePermission">废 弃</el-button>
        <el-button @click.native="hide">关 闭</el-button>
        <!--
        <el-button type="primary" @click.native="save">保 存</el-button>
        -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import warehouseService from "API/pur/PurWarehouseService"
import materialProcessService from "API/pur/PurMaterialProcessService"
import tools from "COMJS/tools";

export default {
  name: "StepShowDialog",
  computed: {
    removePermission() {
      if (this.materialProcess.processStep == '0') {
        //采购，只有从未发货的才能废弃
        return this.materialProcess.processNum == this.materialProcess.remainder
      } else if (this.materialProcess.processStep == '1') {
        //发货，只有未确认的才能废弃
        return this.materialProcess.confirmed != '1'
      } else if (this.materialProcess.processStep == '2') {
        //收货，都能废弃，但是后台会检查库存数量够不够扣
        return true
      } else if (this.materialProcess.processStep == '3') {
        //调用，都可以废弃
        return true
      }
      return false
    }
  },
  data() {
    return {
      dialogVisible: false,
      materialProcess: {materialTransport: {}},
      processNumLabel: '',
    }
  },
  mounted() {
    //this.queryDataList()
  },
  methods: {
    show(step) {
      this.materialProcess = step;
      this.confirmLabel()
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    },
    remove() {
      if (this.materialProcess.materialProcessId != null) {
        this.$confirm("确定废弃该单据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          //this.goodsList.splice(index, 1);
          materialProcessService.removeById(this.materialProcess.materialProcessId).then(res => {
            this.$message.success("操作成功");
            this.$emit("reload");
            this.dialogVisible = false;
          })
        }).catch(() => {
        });
      }
    },
    confirmLabel() {
      if (this.materialProcess.processStep == '0') {
        this.processNumLabel = '采购数量'
      } else if (this.materialProcess.processStep == '1') {
        this.processNumLabel = '发货数量'
      } else if (this.materialProcess.processStep == '2') {
        this.processNumLabel = '确认数量'
      } else if (this.materialProcess.processStep == '3') {
        this.processNumLabel = '调用数量'
      }
    },
    formatDate(time) {
      return tools.formatTime(time, 'yyyy-MM-dd');
    }
  }
}
</script>

<style scoped>

</style>