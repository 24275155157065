import axiosHttp from "../axiosHttp";

export default {    
    queryCheckStorePage(pageInfo,searchConfig){ //盘点列表
        let url = "/WhmSpecialInventory/checkStore/page?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryOtherInvPage(pageInfo,searchConfig){ //盘点列表
        let url = "/WhmSpecialInventory/otherInventory/page?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    // queryBatchCodeNowInv(pageInfo,searchConfig){ //查询盘点的库存批次数据
    //     let url = "/whmInventory/queryBatchCodeNowInv?"
    //     if (pageInfo) {
    //         url = url + pageInfo.buildQueryString();
    //     }
    //     url += "&" + searchConfig.buildQueryString();
    //     return axiosHttp.GET(url)
    // },
    //WhmSpecialInventory/clock/page
    clockPage() { //查询盘点提醒列表
        return axiosHttp.POST('/WhmSpecialInventory/clock/page')
    },
    //WhmSpecialInventory/clock/del/
    clockDel(checkStoreAlarmId) { //删除提醒
        return axiosHttp.GET(`/WhmSpecialInventory/clock/del/${checkStoreAlarmId}`)
    },
    //WhmSpecialInventory/clock/save
    clockSave(params){ //保存库品信息
        return axiosHttp.POST('/WhmSpecialInventory/clock/save',params,false);
    },
    //WhmSpecialInventory/changeAuditState 修改状态(公用)  出入库状态(0:待提交；1：提交，2：作废)
    changeAuditState(params){
        return axiosHttp.POST('/WhmSpecialInventory/changeStorageState',params,false);
    },
    //WhmSpecialInventory/detail
    // detailSpecialCheck(row){
    //     return axiosHttp.GET(`/WhmSpecialInventory/detail/${row}`)
    // },
    detailSpecialCheck(pageInfo,searchConfig){ //盘点列表
        let url = "/WhmSpecialInventory/detail/page?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryBatchCodeNowInv(params){ //查询盘点的库存批次数据        
        return axiosHttp.POST('/whmInventory/queryBatchCodeNowInv',params,false);
    },
    //WhmSpecialInventory/checkStore/save  新增---盘点单
    saveCkeckSore(params){
        return axiosHttp.POST('/WhmSpecialInventory/checkStore/save',params,false);
    },
    //WhmSpecialInventory/checkStore/save  新增---异常
    saveOtherInventory(params){
        return axiosHttp.POST('/WhmSpecialInventory/otherInventory/save',params,false);
    },
    //WhmSpecialInventory/inventoryModifyApply/page  库存变动申请--分页
    // queryinventoryModify(){ //获取库品分类
    //     return axiosHttp.GET('/WhmSpecialInventory/inventoryModifyApply/page');
    // },
    queryinventoryModify(pageInfo,searchConfig){
        let url = "/WhmSpecialInventory/inventoryModifyApply/page?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    //WhmSpecialInventory/importFile 上传步骤excel
    importFileInventory(params){
        return axiosHttp.POST('/WhmSpecialInventory/importFile?warehouseId=1&state=0',params,false);
    },

    //WhmSpecialInventory/exportCheckStoreTemplate 下载盘点模板---1
    exportCheckStoreTemplate() {
        return axiosHttp.POST('/WhmSpecialInventory/exportCheckStoreTemplate');
    },
}