import { render, staticRenderFns } from "./relevanceGoodsPop.vue?vue&type=template&id=1e299e48&scoped=true"
import script from "./relevanceGoodsPop.vue?vue&type=script&lang=js"
export * from "./relevanceGoodsPop.vue?vue&type=script&lang=js"
import style0 from "./relevanceGoodsPop.vue?vue&type=style&index=0&id=1e299e48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e299e48",
  null
  
)

export default component.exports