const frame = {template: '<router-view></router-view>'}

export default {
    path: 'ba',
    component: frame,
    children: [
        {
            name: '日志',
            path: 'systemLog',
            component: resolve => import('PAGES/ba/systemLog.vue')
        }, {
            name: '通知',
            path: 'systemMessage',
            component: resolve => import('PAGES/ba/systemMessage.vue')
        },
        {
            path: 'audit',
            component: frame,
            children: [
                {
                    name: '审核流管理',
                    path: 'manager',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/ba/audit/auditManager.vue')
                },
                {
                    name: '审核列表',
                    path: 'businessList',
                    meta: {noKeepAlive: true,notTab: true},
                    component: resolve => import('PAGES/ba/audit/auditBusinessList.vue')
                }
            ]
        },
        {
            path: 'task',
            component: frame,
            children: [
                {
                    name: '定时任务管理',
                    path: 'manager',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/ba/task/timedTaskManager.vue')
                }
            ]
        },
        {
            path: 'role',
            component: frame,
            children: [
                {
                    name: '角色管理',
                    path: 'manager',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/ba/role/roleManager.vue')
                },
                {
                    name: '用户角色管理',
                    path: 'give/:userId?',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/ba/role/userRoleManager.vue')
                }
            ]
        },
        {
            path: 'menu',
            component: frame,
            children: [
                {
                    name: '后台菜单管理',
                    path: 'manager',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/ba/menu/menuManager.vue')
                },
                {
                    name: 'APP工作台管理',
                    path: 'appWorkbenchManager',
                    meta: {noKeepAlive: true},
                    component: resolve => import('PAGES/ba/menu/appWorkbenchManager.vue')
                }
            ]
        }
    ]
}