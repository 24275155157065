<template>
    <div id="whmPop">
        <el-dialog title="选择内容库品" :visible.sync="dialogVisible" width="50%">
            <div>
                <span class="search-wrapper" style="display: flex">
                    <el-input size="mini" v-model="searchInfo.skuName" placeholder="请输入库品名称"></el-input>
                    <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
                </span>
                <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" selection='single' @pageChange="loadTableData" :showIndex="false">
                    <el-table-column prop="skuName" label="库品名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="skuCode" label="编码" :show-overflow-tooltip="true"></el-table-column>
                </ws-page-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="pushRelate">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import whmList from "API/whm/WhmList";
export default {
    data() {
        return {
            searchInfo: {
                skuCode: "",
                skuName: "",
            },
        };
    },
    props: {
        dialogVisible: Boolean,
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {
            whmList
                .queryWhmPages(this.pageInfo, this.searchConfig)
                .then((data) => {
                    data.list = data.list.map((e) => {
                        if (!e.goodsInfo) {
                            e.goodsInfo = {};
                        }
                        return {
                            id: e.skuId,
                            skuName: e.skuName,
                            skuCode: e.skuCode,
                        };
                    });
                    this.querySuccess(data);
                });
        },
        pushRelate() {
            let list = this.$refs.pageTable.getCheckedData();
            this.$emit('pushRelate', list);
            this.cancel();
        },
        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>