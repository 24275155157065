<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">记录时间：</span>
            <el-date-picker
                v-model="filterTimes"
                @change="changePickerDate"
                size="mini"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
            <!--
            <el-input size="mini" v-model="searchInfo.customerName" class="address-dialog-input"></el-input>
            -->
          </div>
          <div class="form-item">
            <span class="form-label">订单来源：</span>
            <el-select v-model="searchInfo.milkOrderSource"
                       placeholder="请选择订单来源"
                       clearable>
              <el-option
                  v-for="item in orderModelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--
            <el-input size="mini" v-model="searchInfo.customerName" class="address-dialog-input"></el-input>
            -->
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">收件姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件电话：</span>
            <el-input size="mini" v-model="searchInfo.recipientPhoneNum" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品批次：</span>
            <el-input size="mini" v-model="searchInfo.milkBatchCode" class="address-dialog-input">
              <template slot="prepend">D.B.</template>
            </el-input>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="operate-bar">
      <div class="operate-bar-right">
        <el-button size="mini" type="primary" @click="importOrder">批量导入订单</el-button>
        <el-button size="mini" type="default" @click="exportExcel">导出EXCEL</el-button>
        <!--
        <el-button size="mini" type="danger" @click="deleteOrderList">删除</el-button>
        -->
      </div>
    </div>
    <el-tabs v-model="searchInfo.transportState" type="card"
             @tab-click="loadTableData">
      <el-tab-pane label="待发货" name="0"></el-tab-pane>
      <el-tab-pane label="已发货" name="1"></el-tab-pane>
      <el-tab-pane label="已完成" name="2"></el-tab-pane>
      <el-tab-pane label="全部" name="all"></el-tab-pane>
    </el-tabs>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="false"
        :selection="'multi'"
    >
      <!--
      <el-table-column prop="customerName" label="客户" width="100"></el-table-column>
      <el-table-column prop="customerWorkUnit" label="单位" width="120" :show-overflow-tooltip="true"></el-table-column>
      -->
      <el-table-column prop="freshMilkOrderCode" label="订单编号" width="180"></el-table-column>
      <el-table-column label="收件信息" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{ scope.row.recipientName }} {{ scope.row.recipientPhoneNum }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddress }}
          </div>
          <!--
          <div style="line-height: 18px;">
            {{ scope.row.recipientProvince }} {{ scope.row.recipientCity }} {{ scope.row.recipientCounty }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddressDetail }}
          </div>
          -->
        </template>
      </el-table-column>
      <el-table-column label="商品内容" minWidth="150">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            鲜奶两瓶装 {{ scope.row.milkCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.giftBoxCount>0">
            礼盒 {{ scope.row.giftBoxCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.giftCardCount>0">
            贺卡 {{ scope.row.giftCardCount }} 份
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="milkBatchCode" label="批次" width="150"></el-table-column>
      <el-table-column prop="createTime" label="下单时间" width="180" :formatter="dateFormatter4Table"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <div class="icon-operation">
            <!-- -->
            <a href="javascript: void(0);" title="取消">
              <i class="el-icon-delete-solid" @click="deleteOrder(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <milk-order-import-dialog ref="milkOrderImportDialog" @submit="loadTableData"/>
  </div>
</template>

<script>
//import areaCascader from "COMPS/base/areaCascader";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import milkOrderService from "API/fm/MilkOrderService";
//import customerService from "API/usr/CustomerService";
import milkOrderImportDialog from "./MilkOrderImportDialog"
import IoProgressService from "API/IoProgressService";
import tools from "COMJS/tools";

export default {
  name: "FreshMilkOrderManager",
  mixins: [pageTableMixin],
  components: {
    //areaCascader
    milkOrderImportDialog
  },
  data() {
    return {
      filterTimes: [],
      orderModelList: [
        {label: '导入订单', value: 'import'},
        {label: '奶卡订单', value: 'milk_card'},
        {label: '客户订单', value: 'customer'},
      ],
      searchInfo: {
        milkOrderSource: ''
      },
      pageInfo: {
        pageSize: 10,
      },
    }
  },
  mounted() {
    //
  },
  methods: {
    loadTableData() {
      if (!this.searchInfo.customerName) {
        this.searchInfo.customerId = ''
      }
      let param = {
        ...this.searchInfo,
        transportState: this.searchInfo.transportState == "all" ? "" : this.searchInfo.transportState,
        milkBatchCode: (this.searchInfo.milkBatchCode && this.searchInfo.milkBatchCode.length > 0)
            ? ('D.B.' + this.searchInfo.milkBatchCode) : '',
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
      }
      milkOrderService.pageMilkOrder(param).then(res => {
        this.querySuccess(res);
      })
    },
    importOrder() {
      this.$refs.milkOrderImportDialog.show();
    },
    exportExcel() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录导出！', '系统提示');
        return;
      }
      milkOrderService.exportExcelCheckRows(rows).then(res => {
        let fileName = res.fileName;
        let module = res.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.recordDateBegin = "";
        this.searchInfo.recordDateEnd = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.recordDateBegin = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.searchInfo.recordDateEnd = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
    //删除
    deleteOrderList() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录！', '系统提示');
        return;
      }
      let ids = rows.map(e => e.freshMilkOrderId)
      let param = {
        freshMilkOrderIdList: ids
      }
      this.$confirm(`确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        milkOrderService.delMilkOrder(param).then((data) => {
          this.$message({type: "success", message: "删除成功"});
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    deleteOrder(row) {
      let param = {
        freshMilkOrderId: row.freshMilkOrderId
      }
      this.$confirm(`确定取消此订单?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        milkOrderService.delMilkOrder(param).then((data) => {
          this.$message({type: "success", message: "删除成功"});
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd hh:mm:ss");
      }
      return v;
    },
  }
}
</script>

<style scoped>
.address-dialog-frame {
  border: 1px solid #d3d3d3;
  padding: 20px;
  margin-bottom: 20px;
  /*background-color: #d3d6da;*/
}

.mt10 {
  margin-top: 10px;
}

.address-dialog-input {
  width: 200px !important;
  margin-right: 15px;
}

.address-dialog-input-long {
  width: 630px !important;
}

.border-dashed {
  border-bottom: 1px dashed #3d3d3d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
}

.tag-row .input-new-tag {
  width: 100px;
}
</style>