import axiosHttp from "../axiosHttp";

export default {
    createCustomerOrder(param){
        return axiosHttp.POST("/milkOrder/createCustomerOrder", param);
    },
    createCustomerOrderAgain(param){
        return axiosHttp.POST("/milkOrder/createCustomerOrderAgain", param);
    },
    listMilkOrder(param){
        return axiosHttp.POST("/milkOrder/listMilkOrder", param);
    },
    pageMilkOrder(param){
        return axiosHttp.POST("/milkOrder/pageMilkOrder", param);
    },
    delMilkOrder(param){
        return axiosHttp.POST("/milkOrder/delMilkOrder", param);
    },
    exportExcelCheckRows(list){
        return axiosHttp.POST("/milkOrder/exportExcelCheckRows", list);
    },
    pageMilkOrderPackage(param){
        return axiosHttp.POST("/milkOrder/pageMilkOrderPackage", param);
    },
    auditMilkOrderAfter(param){
        return axiosHttp.POST("/milkOrder/auditMilkOrderAfter", param);
    },
    exportExcel4Package(list){
        return axiosHttp.POST("/milkOrder/exportExcel4OrderPackage", list);
    },
    importTransportDataToPackage(list){
        return axiosHttp.POST("/milkOrder/importTransportDataToPackage", list);
    },
    importOrderList(list){
        return axiosHttp.POST("/milkOrder/importOrderList", list);
    },
    pushOrderToWdt(list){
        return axiosHttp.POST("/milkOrder/pushOrderToWdt", list);
    },
    getMilkOrderStatistics(param){
        return axiosHttp.POST("/milkOrder/getMilkOrderStatistics", param);
    },
};
