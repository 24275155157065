<template>
  <div id="mallList">
    <div class="mall-tab">
      <div class="classify">
        <div style="color: rgba(51, 51, 51, 1);">平台</div>
        <div :class="item.active?'tabItem-active':'tabItem'"
             v-for="(item,index) in tabList" :key="index" @click="selectTab(index)">{{ item.text }}
        </div>
      </div>
      <div class="search">
        <span class="a-tag" @click="shipTimeParamSetDialogShow=true">发货时间设置及说明</span>
      </div>
    </div>
    <div class="mall-content">

      <div class="mall-show">
        <div class="show-title">
          <div>展示</div>
          <router-link class="link" :to="{path:'showEvent'}">全部 >></router-link>
        </div>
        <div class="scroll">
          <div :class="item.active?'show-item show-item-active':'show-item'" v-for="(item,index) in ShowList"
               :key="index"
               @click="showActivityClick(item.activityId)">
            <i class="el-icon-edit" v-show="item.active" @click="editShowActivity"></i>
            <img v-if="item.icon" :src="item.icon">
            <div>{{ item.title }}</div>
            <div v-show="item.active" style="margin-left:auto;">
              <el-switch
                  @change="changeActivityViewEnable(item.activityId)"
                  v-model="item.viewEnable"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="mall-goods">
        <div class="show-title">
          <div class="title-item">商品</div>
          <router-link style="margin-left: 10px;" class="link" :to="{path:'productList'}">全部 >></router-link>
          <router-link style="margin-left: auto;" class="link" :to="{path:'AddGoods',query:{newGoods:true}}">新增
          </router-link>
        </div>
        <div class="scroll">
          <div class="aggregation-item" v-for="(item,index) in goodsList" :key="index">
            <div class="aggregation-item-content">
              <img :src="item.pictureUrl" alt="goods"/>
              <div style="width: 200px;">{{ item.cnName }}</div>
              <div style="width: 100px;">{{ item.goodsCode }}</div>
              <div style="width: 50px;">{{ item.retailPrice }}(零)</div>
              <div style="width: 50px;">{{ item.l1CostPrice }}(普)</div>
              <div style="width: 50px;">{{ item.l2CostPrice }}(银)</div>
              <div style="width: 50px;">{{ item.l3CostPrice }}(金)</div>
            </div>
            <div class="aggregation-item-operation">
              <div class="aggregation-state">{{ item.goodsState == 'normal' ? '在售' : '售罄' }}</div>
              <div class="aggregation-state">{{ item.effectiveState }}</div>
              <div class="aggregation-edit" @click="goEdit(item.goodsId)" style="cursor: pointer;">编辑</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="发货时间设置及说明" :visible.sync="shipTimeParamSetDialogShow">
      <div>
        <h1 style="line-height: 22px;">
          商城下单时展示的是<span style="color: red">最早发货时间</span>，规则如下：
        </h1>
        <h1 style="line-height: 22px;">
          以下三个规则计算出的发货时间相互竞争，<span style="color: red">取最晚的时间为最早发货时间</span>
        </h1>
        <h1 style="line-height: 22px;">
          1、如果下单时间在<span style="color: red">当天12点</span>之前，则发货时间为当天；如果在之后，则为第二天。
        </h1>
        <h1 style="line-height: 22px;">
          2、如果商品存在<span style="color: red">预售活动</span>，则发货时间为预售发货时间。
        </h1>
        <h1 style="line-height: 22px;">
          3、以下<span style="color: red">延迟天数</span>和<span style="color: red">指定日期</span>，取较晚的时间为发货时间。
        </h1>
      </div>
      <div style="margin-top: 30px;margin-bottom:10px;font-size: 16px;font-weight: bold;">
        编辑延迟发货
      </div>
      <el-form label-width="100px">
        <el-form-item label="延迟天数：">
          <el-input-number
              v-model="shipTimeParamSet.delay"
              :min="0" :max="30">
          </el-input-number>
          <span class="tip-set">下单之日起延迟多少天发货，0即为不延迟。</span>
        </el-form-item>
        <el-form-item label="指定日期：">
          <el-date-picker
              v-model="shipTimeParamSet.scheduled"
              type="date">
          </el-date-picker>
          <span class="tip-set">指定日期发货，如果是过去的时间，则无效。</span>
        </el-form-item>
        <el-form-item label="延迟说明：">
          <el-input
              v-model="shipTimeParamSet.explain"
              :maxlength="64">
          </el-input>
          <span class="tip-set">展示在小程序订单确认页，放空即为不展示说明。</span>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center;">
        <el-button @click="shipTimeParamSetDialogShow=false">取 消</el-button>
        <el-button type="primary" @click="saveShipTimeParamSet">确认修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import {showEventList, showEventDetail, changeActivityViewEnable} from "@/api/pim/PimShowEvent.js";
import paramService from "@/api/bim/BimParamService";
import tools from "COMJS/tools";
//import pimGoodsSpuService from "API/pim/PimGoodsSpuService";

export default {
  data() {
    return {
      tabList: [
        {
          text: "小程序",
          label: "wmp",
          active: true,
        },
        {
          text: "App",
          label: "app",
          active: false,
        },
      ],
      ShowList: [], //展示列表
      getAggregationList: [], //聚合列表
      goodsList: [], //商品列表
      searchInfo: {
        tradeType: "wmp",
        type: "mall",
      },
      items: {
        tradeType: {filter: "like"},
        type: {filter: "EQ"},
      },
      //发货时间设置
      shipTimeParamSetDialogShow: false,
      shipTimeParamSet: {},
      shipTimeParamData: {}
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.getShipTimeParamSet();
  },
  methods: {
    loadTableData() {
      this.pageInfo.pageSize = 100;
      this.pageInfo.pageNo = 1;
      showEventList(this.pageInfo, this.searchConfig).then((data) => {
        this.ShowList = data.list.map((e) => {
          return {
            activityId: e.activityId,
            title: e.title,
            icon: e.iconUrlSelected,
            active: false,
            viewEnable: e.viewEnable,
          };
        });
      });
    },
    showActivityClick(id) {
      let nowTimestamp = new Date().getTime();
      showEventDetail(id)
          .then((data) => {
            //console.log(data);
            this.goodsList = data.relaGoodsList.map((e) => {
              return {
                cnName: e.cnName,
                goodsCode: e.goodsCode,
                pictureUrl: (e.eventGoods && e.eventGoods.eventPictureUrl) ? e.eventGoods.eventPictureUrl : e.pictureUrl,
                goodsId: e.goodsId,
                goodsState: e.goodsState,
                effectiveState: (e.effectiveTime > nowTimestamp || e.failureTime < nowTimestamp) ? '已下架' : '已上架',
                retailPrice: (e.eventGoods && e.eventGoods.l0CostPrice) ? e.eventGoods.l0CostPrice : e.initPrice,
                l1CostPrice: (e.eventGoods && e.eventGoods.l1CostPrice) ? e.eventGoods.l1CostPrice : '--',
                l2CostPrice: (e.eventGoods && e.eventGoods.l2CostPrice) ? e.eventGoods.l2CostPrice : '--',
                l3CostPrice: (e.eventGoods && e.eventGoods.l3CostPrice) ? e.eventGoods.l3CostPrice : '--',
              };
            });
          })
          .catch(() => {
            this.goodsList = [];
          });
      this.ShowList.forEach((e) => {
        e.active = false;
        if (e.activityId == id) {
          e.active = true;
        }
      });
    },
    editShowActivity() {
      let list2 = this.ShowList.filter(e => e.active);
      if (list2.length > 0) {
        //console.log("editShowActivity", list2[0].activityId)
        this.$router.push({
          path: "editShowEvent",
          query: {
            id: list2[0].activityId
          }
        })
      }
    },
    changeActivityViewEnable() {
      let list2 = this.ShowList.filter(e => e.active);
      if (list2.length > 0) {
        //console.log("editShowActivity", list2[0].activityId)
        let id = list2[0].activityId
        changeActivityViewEnable(id).then((data) => {
          this.$message.success("修改成功");
        })
      }
    },
    selectTab(index) {
      this.tabList.forEach((e) => {
        e.active = false;
      });
      this.tabList[index].active = true;
      this.searchInfo.tradeType = this.tabList[index].label;
      this.loadTableData();
    },
    goEdit(goodsId) {
      this.$router.push({
        path: "AddGoods",
        query: {
          goodsId: goodsId,
          pageNo: 1
        },
      });
    },
    getShipTimeParamSet() {
      let keys = "ORDER_DELIVERY_DELAY,ORDER_DELIVERY_SCHEDULED,ORDER_DELIVERY_DELAY_SCHEDULED_EXPLAIN"
      paramService.listMapByParam(keys).then(data => {
        this.shipTimeParamData = data;
        let set = {
          delay: data.ORDER_DELIVERY_DELAY ? data.ORDER_DELIVERY_DELAY.defaultValue : 0,
          scheduled: data.ORDER_DELIVERY_SCHEDULED ? data.ORDER_DELIVERY_SCHEDULED.defaultValue : '',
          explain: data.ORDER_DELIVERY_DELAY_SCHEDULED_EXPLAIN ? data.ORDER_DELIVERY_DELAY_SCHEDULED_EXPLAIN.defaultValue : '',
        }
        set.scheduled = new Date(set.scheduled);
        this.shipTimeParamSet = set;
      })
    },
    saveShipTimeParamSet() {
      let param1 = {
        ...this.shipTimeParamData.ORDER_DELIVERY_DELAY,
        defaultValue: this.shipTimeParamSet.delay
            ? this.shipTimeParamSet.delay : ''
      };
      let param2 = {
        ...this.shipTimeParamData.ORDER_DELIVERY_SCHEDULED,
        defaultValue:this.shipTimeParamSet.scheduled
            ? tools.formatDate(this.shipTimeParamSet.scheduled, "yyyy-MM-dd") : ''
      };
      let param3 = {
        ...this.shipTimeParamData.ORDER_DELIVERY_DELAY_SCHEDULED_EXPLAIN,
        defaultValue: this.shipTimeParamSet.explain
            ? this.shipTimeParamSet.explain : ''
      };
      let params = [param1,param2,param3]
      //console.log(params)
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      paramService.saveList(params).then(res => {
        this.$message.success("修改成功");
        loading.close();
        this.shipTimeParamSetDialogShow = false;
      });
    },
  },
};
</script>
<style scoped>
#mallList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mall-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  height: 60px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  padding: 0px 17px;
}

.classify {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  line-height: 24px;
  /* border-bottom: 1px solid black; */
  height: 100%;
}

.tabItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80%;
  margin-left: 20px;
  cursor: pointer;
}

.tabItem-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80%;
  margin-left: 20px;
  border-bottom: 2px solid rgba(24, 144, 255, 1);
  color: rgba(24, 144, 255, 1);
  cursor: pointer;
}

.mall-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  height: 100%;
}

.show-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  margin-top: 14px;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.show-title > .link {
  font-size: 12px;
  margin-left: 16px;
  color: rgba(24, 144, 255, 1);
}

.show-item {
  height: 81px;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  cursor: pointer;
}

.show-item > * {
  margin-right: 15px;
}

.show-item > img {
  width: 20px;
  height: 20px;
}

.show-item-active {
  background: rgba(204, 230, 255, 1);
}


.aggregation-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5.5px 40px 5.5px 10px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.aggregation-item-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.aggregation-item-content > div {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  margin-left: 20px;
}

.aggregation-item-content > img {
  /*width: 48px;*/
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.aggregation-item-operation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  width: 15%;
}

.aggregation-item-operation > .link {
  color: rgba(24, 144, 255, 1);
}

.mall-show {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(51, 51, 51, 1);
  border-radius: 6px;
  height: 800px;
  flex: 0.8;
}

.scroll {
  overflow-y: scroll;
  height: 748px;
}


.mall-goods {
  flex: 4;
  /* width: 510px; */
  height: 800px;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: scroll;
  border-radius: 6px;
  margin-left: 12px;
}

.title-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 16px;
}

.title-item > .link {
  font-size: 12px;
  color: rgba(24, 144, 255, 1);
}

.aggregation-edit {
  font-size: 12px;
  margin-left: 16px;
  color: rgba(24, 144, 255, 1);
}

.a-tag {
  color: #00a2d4;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}
.tip-set{
  margin-left: 20px;
  color: #793b3b;
}
</style>