<template>
    <div class="whm-edit">
        <div class="step-title">{{hasId ? '编辑库品' : '新增库品'}}</div>
        <el-form :model="whmInfo" label-width="180px" ref="whmInfoForm" class="step-form">
            <el-form-item label="库品编码:">
                <el-input v-model="whmInfo.skuCode"></el-input>
            </el-form-item>
            <el-form-item label="库品名称:">
                <el-input v-model="whmInfo.skuName" maxlength="35"></el-input>
            </el-form-item>
            <el-form-item label="库品分类:">
                <div v-if="!selectedCategory.selectedFirstCategory" class="select-btn" @click="showTree">
                    请选择库品分类
                </div>
                <template v-else>
                    <span v-if="selectedCategory && selectedCategory.selectedFirstCategory">{{ selectedCategory.selectedFirstCategory.nodeName || "" }}&nbsp;{{
              selectedCategory.selectedSecondCategory.nodeName || ""
            }}&nbsp;{{
              selectedCategory.selectedThirdCategory.nodeName || ""
            }}</span>
                    <span class="tips-btn" @click="showTree" style="dispaly: inline-block; margin-left: 15px">重选分类</span>
                </template>
            </el-form-item>

            <el-form-item label="库品单位:">
                <div class="select-btn" v-if="!selectedUnit.unitId" @click="$refs.selectUnitPopup.dialogVisible = true">
                    请选择库品单位
                </div>
                <template v-else>
                    <span>{{ selectedUnit.unitName }}</span>
                    <span class="tips-btn" style="dispaly: inline-block; margin-left: 15px" @click="$refs.selectUnitPopup.dialogVisible = true">重选单位</span>
                </template>
            </el-form-item>

            <el-form-item label="库品规格:">
                <div class="whm-sku">
                    <div class="select-btn-wrap">
                        <div class="select-label">规格名:<span class="ml12" v-if="whmInfo.skuSpecName">{{whmInfo.skuSpecName.name}}</span></div>
                        <div class="select-btn" @click="$refs.selectSkuspecPopup.dialogVisible = true" v-if="!selectedSkuspec.skuspecId">
                            请选择规格值
                        </div>
                        <template v-else>
                            <span>{{ selectedSkuspec.skuspecName }}</span>
                            <span class="tips-btn" style="dispaly: inline-block; margin-left: 15px" @click="$refs.selectSkuspecPopup.dialogVisible = true">重选规格值</span>
                        </template>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="保质期:">
                <el-input type="number" v-model="whmInfo.shelfLife" class="shelf-life"></el-input>
                <span class="ml12">天</span>
            </el-form-item>
            <el-form-item label="库存预警值:">
                <el-input type="number" v-model="whmInfo.warningNum"></el-input>
            </el-form-item>
            <el-form-item label="库存售罄值:">
                <el-input type="number" v-model="whmInfo.soldOutNum"></el-input>
            </el-form-item>
            <el-form-item label="备注:">
                如该库品为奶卡中库品，请预留足够数量，否则随奶卡每月预留值变动，可能导致可用库存为负数。
            </el-form-item>
            <el-form-item label="库品图片:">
                <draggableUpload :limit="1" v-model="chooseImgFirst" :uploadUrl="uploadUrl()" :requestHeaders="requestHeaders" />
            </el-form-item>
            <el-form-item label="是否启用:">
                <el-switch v-model="whmInfo.enabled"></el-switch>
            </el-form-item>
            <el-form-item label="是否展示保质期至小程序:">
                <el-switch v-model="whmInfo.isSku"></el-switch>
            </el-form-item>
        </el-form>

        <div class="steps-btn-wrapper">
            <el-button @click="backPre">取消</el-button>
            <el-button type="primary" @click="createWhm">保存库品</el-button>
        </div>

        <select-unit-popup ref="selectUnitPopup" @submit="selectUnit"></select-unit-popup>
        <select-skuspec-popup ref="selectSkuspecPopup" @submit="selectSkuspec"></select-skuspec-popup>

        <el-dialog title="选择分类" :visible.sync="treeDialog" width="80%">
            <el-button type="primary" style="margin-left:20px" @click="showAdd">新增分类</el-button>
            <level-tree ref="levelTree" :tree="firstCategoryData" @changeNode="getTreeNode" firstTreeName="一级分类" secondTreeName="二级分类" thirdTreeName="三级分类" @changeAllNode="getAllNode"></level-tree>
            <div class="btn-next-step" @click="switchTree">下一步</div>
        </el-dialog>

        <category-add-popup ref="categoryAddPopup" @updateCategory="refreshCategory()"></category-add-popup>
    </div>
</template>

<script>
import categoryAddPopup from "./categoryAddPopup.vue";
import draggableUpload from "COMPS/base/draggableUpload";
import SearchConfig from "COMJS/query/SearchConfig";
import selectUnitPopup from "./selectUnitPopup"; //选择单位弹窗
import selectSkuspecPopup from "./selectSkuspecPopup"; //选择库品规格弹窗
import userModule from "@/module/userModule";
import levelTree from "COMPS/widgets/levelTree";
import WhmList from "API/whm/WhmList";
import IoProgressService from "API/IoProgressService.js";
export default {
    name: "whmEdit",
    data() {
        return {
            firstCategoryData: [],
            chooseImgFirst: [],
            requestHeaders: {
                Authorization: userModule.token,
            },
            //searchInfo: {},
            treeDialog: false, //显示隐藏tree
            whmInfo: {
                skuCode: "", //库品编码
                skuName: "", // 库品名称
                thingTypeId: "", //库品分类id
                skuUnit: {
                    specUnitId: "", //库品单位id
                },
                skuSpec: {
                    specUnitId: "", //库品规格值
                },
                skuSpecName: {
                    specUnitId: "", //库品规格名
                    name: "",
                },
                imageUrl: "",
                shelfLife: "", //保质期
                warningNum: "", //预警值
                soldOutNum: "", //售罄值
                enabled: true, // 是否启用1是启用0是禁用
                isSku: true,
            },
            selectedUnit: {
                specUnitId: "",
            },
            selectedSkuspec: {
                specSkuspecId: "",
            },
            selectedCategory: {},
            hasId: false,
        };
    },
    components: {
        categoryAddPopup,
        selectUnitPopup,
        selectSkuspecPopup,
        levelTree,
        draggableUpload,
    },
    mounted() {
        //this.addGoOn();
        //console.log(this.$route.params.id)
        this.getAllCategory();
        this.getSkusepcname();
        if (this.$route.params.id) {
            this.hasId = true;
            this.getGoodsDetail();
        }
    },
    computed: {
        unitSearchConfig() {
            return SearchConfig.createNew({
                searchForm: {
                    name: this.selectedUnit.name,
                },
            });
        },
    },
    methods: {
        getSkusepcname() {
            WhmList.querySkuspecnamePages("all").then((data) => {
                //console.log(data[0].specUnitId)
                this.whmInfo.skuSpecName.specUnitId = data[0].specUnitId;
                this.whmInfo.skuSpecName.name = data[0].name;
            });
        },
        showTree() {
            this.treeDialog = true;
        },
        selectCategory(params) {
            this.whmInfo.thingTypeId =
                params.selectedThirdCategory.id ||
                params.selectedSecondCategory.id ||
                params.selectedFirstCategory.id;
            this.selectedCategory = params;
            //console.log(this.whmInfo.thingTypeId)
        },
        switchTree() {
            //this.$emit("submit", this.submitParams);
            if (this.submitParams) {
                this.selectCategory(this.submitParams);
            }
            this.treeDialog = false;
        },
        getAllCategory() {
            WhmList.queryCategoryWhm({
                thingTypeName: this.thingTypeName,
            }).then((data) => {
                //console.log(data)
                this.firstCategoryData = data.map((e) => {
                    e.id = e.nodeId;
                    e.name = e.nodeName;
                    return e;
                });
            });
        },
        getTreeNode(node) {
            this.currentNode = node;
            //console.log(node)
        },
        getAllNode(obj) {
            //console.log(obj)
            let params = {
                selectedFirstCategory: obj.selectedFirstTree,
                selectedSecondCategory: obj.selectedSecondTree,
                selectedThirdCategory: obj.selectedThirdTree,
            };
            this.submitParams = params;
        },
        selectUnit(params) {
            //库品单位
            this.selectedUnit = params;
            this.whmInfo.skuUnit.specUnitId = params.unitId;
            this.whmInfo.unitName = params.unitName;
        },
        selectSkuspec(params) {
            //库品规格值
            this.selectedSkuspec = params;
            this.whmInfo.skuSpec.specUnitId = params.skuspecId;
            this.whmInfo.skuSpec.name = params.skuspecName;
        },
        backPre() {
            //返回上一页
            this.$router.push("/whm/whmList");
            let StepsData = this.$store.state.ProductStepsData;
            if (StepsData.Back) {
                this.$confirm(`是否返回商品页继续之前操作？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$router.push({
                        path: "/pim/AddGoods"
                    });
                });
            }
        },
        createWhm() {
            const that = this;
            let whmInfo = this.whmInfo;
            if (this.chooseImgFirst && this.chooseImgFirst.length > 0) {
                whmInfo.imageUrl = this.chooseImgFirst[0].response;
            }
            if (!whmInfo.skuCode) {
                return this.$message({
                    message: "请添加库品编码",
                    type: "warning",
                });
            }
            if (!whmInfo.skuName) {
                return this.$message({
                    message: "请添加库品名称",
                    type: "warning",
                });
            }
            if (!whmInfo.thingTypeId) {
                return this.$message({
                    message: "请选择库品分类",
                    type: "warning",
                });
            }
            if (whmInfo.skuUnit && !whmInfo.skuUnit.specUnitId) {
                return this.$message({
                    message: "选择库品单位",
                    type: "warning",
                });
            }
            if (whmInfo.skuSpec && !whmInfo.skuSpec.specUnitId) {
                return this.$message({
                    message: "请选择规格值",
                    type: "warning",
                });
            }
            if (!whmInfo.shelfLife || whmInfo.shelfLife <= 0) {
                return this.$message({
                    message: "请添加保质期",
                    type: "warning",
                });
            }
            if (!whmInfo.warningNum || whmInfo.warningNum <= 0) {
                return this.$message({
                    message: "请添加预警值",
                    type: "warning",
                });
            }
            if (!whmInfo.soldOutNum || whmInfo.soldOutNum <= 0) {
                return this.$message({
                    message: "请添加售罄值",
                    type: "warning",
                });
            }
            if (!whmInfo.imageUrl || whmInfo.imageUrl <= 0) {
                return this.$message({
                    message: "请添加库品图片",
                    type: "warning",
                });
            }
            if (this.$route.params.id) {
                whmInfo.skuId = this.$route.params.id;
            }
            whmInfo.enabled = whmInfo.enabled == true ? 1 : 0; //切换状态位数字 1开启 0关闭
            whmInfo.isSku = whmInfo.isSku == true ? 1 : 0; //切换状态位数字 1开启 0关闭
            const loading = this.$loading({
                lock: true,
                text: "保存中...",
            });
            WhmList.saveWhm(whmInfo)
                .then((res) => {
                    //this.$router.push("/pim/productList");
                    //eventBus.$emit("refresh");
                    if (this.$route.params.id) {
                        if (res.msg == "OK") {
                            this.$message({
                                message: "修改成功",
                                type: "success",
                            });
                        }
                        setTimeout(function () {
                            that.$router.push("/whm/whmList");
                        }, 300);
                    } else {
                        if (res.msg == "OK") {
                            this.$message({
                                message: "新增成功",
                                type: "success",
                            });
                        }
                        setTimeout(function () {
                            that.$router.push("/whm/whmList");
                        }, 300);
                    }
                    loading.close();
                    let StepsData = this.$store.state.ProductStepsData;
                    if (StepsData.Back) {
                        this.$confirm(`是否返回商品页继续之前操作？`, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        }).then(() => {
                            this.$router.push({
                                path: "/pim/AddGoods"
                            });
                        });
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
        getGoodsDetail() {
            let loading = this.$loading({
                lock: true,
                text: "加载中...",
            });
            WhmList.queryWhmInfo(this.$route.params.id).then((data) => {
                let whmInfo = this.whmInfo;
                let selectedUnit = this.selectedUnit;
                let selectedSkuspec = this.selectedSkuspec;
                //whmInfo.skuId
                whmInfo.skuCode = data.skuCode; //库品编码
                whmInfo.skuName = data.skuName; // 库品名称
                if (data.thingTypeId) {
                    let levId = "";
                    let levName = "";
                    if (data.level) {
                        levId = data.level.thingTypeId;
                        levName = data.level.thingTypeName;
                    }
                    whmInfo.thingTypeId = levId;
                    this.selectedCategory = {
                        selectedFirstCategory: {
                            id: levId,
                            nodeName: levName,
                        },
                        selectedSecondCategory: {},
                        selectedThirdCategory: {},
                    };
                }
                if (data.skuUnit) {
                    //库品单位
                    selectedUnit.unitId = data.skuUnit.specUnitId;
                    whmInfo.skuUnit.specUnitId = data.skuUnit.specUnitId;
                    selectedUnit.unitName = data.skuUnit.name;
                    whmInfo.skuUnit.unitName = data.skuUnit.unitName;
                }
                if (data.skuSpec) {
                    //库品规格
                    selectedSkuspec.skuspecId = data.skuSpec.specUnitId;
                    selectedSkuspec.skuspecName = data.skuSpec.name;
                    whmInfo.skuSpec.specUnitId = data.skuSpec.specUnitId;
                    whmInfo.skuSpec.name = data.skuSpec.name;
                }
                whmInfo.shelfLife = data.shelfLife; //保质期
                whmInfo.warningNum = data.stock.warningNum; //预警值
                whmInfo.soldOutNum = data.stock.sellOutNum; //售罄值
                this.chooseImgFirst = data.imageUrl
                    ? [
                          {
                              response: data.imageUrl,
                          },
                      ]
                    : []; //库品图片
                (whmInfo.enabled = data.enabled == 1 ? true : false), // 是否启用1是启用0是禁用
                    (whmInfo.isSku = data.isSku == 1 ? true : false), // 是否启用1是启用0是禁用
                    // this.$store.commit("saveProductAddInfo", this.whmInfo);
                    loading.close();
            });
        },

        compare(property) {
            return function (obj1, obj2) {
                var value1 = obj1[property];
                var value2 = obj2[property];
                return value1 - value2; // 升序
            };
        },
        commitChoose() {
            let list = this.$refs.pageTable.getCheckedData();
            var oldList = this.skuList;
            this.skuList = this.skuList.concat(list);
            let res = [];
            this.skuList.forEach((item) => {
                let isExist = false;
                res.forEach((item2) => {
                    if (item.skuId == item2.skuId) {
                        isExist = true;
                    }
                });
                if (!isExist) {
                    res.push(item);
                }
            });
            if (res.length > 4) {
                this.skuList = oldList;
                return this.$message({
                    message: "不允许添加4件以上库品",
                    type: "warning",
                });
            }
            this.skuList = res;
            this.goodsSkuDialog = false;
        },
        delChooseSku(i) {
            this.skuList.splice(i, 1);
        },
        selectTransportTemplate(params) {
            this.whmInfo.transportId = params.value;
        },
        selectGoodsStateList(params) {
            this.whmInfo.goodsState = params.value;
        },
        uploadUrl() {
            let url = IoProgressService.getUploadUrl("pimGoods");
            return url;
        },
        showAdd() {
            this.$refs.categoryAddPopup.dialogFormVisible = true;
        },
        refreshCategory() {
            this.$refs.categoryAddPopup.dialogFormVisible = false;
            this.getAllCategory();
        },
    },
};
</script>

<style lang="scss">
.whm-edit {
    padding: 20px 0 60px 0;
    background: #fff;
}
.whm-edit .el-form-item .el-form-item__content > .el-input,
.whm-edit .el-input--small .el-input__inner {
    width: 150px;
}
.whm-edit .step-title {
    padding: 0 0 40px 33px;
    font-size: 18px;
    color: #333;
}
.whm-edit .select-btn {
    width: 150px;
    text-align: center;
    line-height: 32px;
    border: 1px solid $borderColor;
    cursor: pointer;
    border-radius: 2px;
    color: #999;
}
.whm-edit .whm-sku {
    padding: 20px;
    display: inline-block;
    border: 1px solid #ddd;
}
.whm-edit .whm-sku .select-btn {
    margin-top: 10px;
}
.whm-edit .select-btn-wrap {
    padding: 12px;
}
.whm-edit .select-label {
    padding-left: 12px;
    width: 188px;
    background: #f8f8f8;
}
.whm-edit .c9 {
    margin-left: 12px;
    color: #999;
}
.whm-edit .ml12 {
    margin-left: 12px;
}
.whm-edit .one-row {
    margin-top: 10px;
}
.whm-edit .one-row,
.whm-edit .column1 {
    display: flex;
}
.whm-edit .columen-rig {
    min-width: 120px;
    color: #999;
}
.whm-edit .steps-btn-wrapper {
    margin-top: 80px;
    display: flex;
    justify-content: center;
}
.whm-edit .btn-next-step {
    margin: 20px auto 0 auto;
    width: 76px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #1b73e8;
    border-radius: 2px;
    font-size: 12px;
    cursor: pointer;
}
.whm-edit .search-result {
    height: 360px;
    overflow-y: auto;
}
.whm-edit .tips-btn {
    cursor: pointer;
    color: #1b73e8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
input[type="number"] {
    -moz-appearance: textfield;
}
</style>