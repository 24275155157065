<template>
  <div id='AddGoods'>
    <el-steps :active="stepActive" align-center style="margin-bottom:50px">
      <el-step :title="item.title" style="cursor: pointer;" v-for="(item,index) in stepData" :key="index"
               @click.native="goStep(item.id)"></el-step>
    </el-steps>
    <div v-show='stepActive==1'>
      <information @swiStep="swiStep" ref="child1" :toView='toView' :goodsData='goodsData'
                   @returnGoodsData='returnGoodsData'></information>
    </div>
    <div v-show="stepActive==2">
      <information-detail @swiStep="swiStep" ref="child2" :toView='toView' :goodsData='goodsData'
                          @refreshGoodsData='refreshGoodsData'></information-detail>
    </div>
    <div v-show="stepActive==3">
      <PriceInformation @swiStep="swiStep" ref="child3" :toView='toView' :goodsData='goodsData'></PriceInformation>
    </div>
    <div v-show="stepActive==4">
      <whm @swiStep="swiStep" ref="child4" :toView='toView' :goodsData='goodsData'></whm>
    </div>
    <div v-show="stepActive==5">
      <show-goods @swiStep="swiStep" ref="child5" :toView='toView' :goodsData='goodsData'></show-goods>
    </div>
  </div>
</template>

<script>
import information from "./information";
import informationDetail from "./informationDetail";
import PriceInformation from "./PriceInformation";
import whm from "./whm";
import showGoods from "./showGoods";
import PimGoodsService from "API/pim/PimGoodsService";

export default {
  data() {
    return {
      toView: false,
      goodsId: "",
      goodsData: {
        goodsInfo:{},
        imgInfo:{},
        allowOrderType:''
      },
      stepActive: 1,
      stepData: [
        {
          id: 1,
          title: "基本信息",
          Data: {},
        },
        {
          id: 2,
          title: "详细信息",
          Data: {},
        },
        {
          id: 3,
          title: "会员优惠信息",
          Data: {},
        },
        {
          id: 4,
          title: "库品信息",
          Data: {},
        },
        {
          id: 5,
          title: "展示信息",
          Data: {},
        },
      ],
    };
  },
  components: {
    information,
    informationDetail,
    PriceInformation,
    whm,
    showGoods,
  },
  watch: {
    stepActive() {
      //console.log("stepActive change")
      this.setProductStepsDataToStore();
    },
  },
  mounted() {
    let goodsId = this.$route.query.goodsId;
    if (goodsId) {
      this.goodsId = goodsId
      this.queryGoodsDetail(goodsId)
    }
    if (this.$route.query.newGoods) {
      //新增
      this.stepActive = 1;
      this.getNewGoodsData();
      this.removeProductStepsDataFromStore();
    }
    if (this.$route.query.pageNo) {
      this.stepActive = Number(this.$route.query.pageNo);
      this.setProductStepsDataToStore();
    }
    if (this.$route.query.toView) {
      this.toView = true;
    }
    //返回之前的页面
    /*let StepsData = this.$store.state.ProductStepsData;
    if (StepsData.Step) {
      //console.log("StepsData.Step=",StepsData.Step)
      this.stepActive = StepsData.Step;
      this.removeProductStepsDataFromStore()
      return;
    }*/
  },
  methods: {
    queryGoodsDetail(goodsId){
      PimGoodsService.getGoodsDetail(goodsId).then((data) => {
        this.getGoodsData(data);
      });
    },
    getNewGoodsData() {
      let tempData = {}
      tempData.goodsInfo = {}

      tempData.imgInfo = {}
      tempData.imgInfo.imgFirst = [];
      tempData.imgInfo.imgSecond = [];
      tempData.imgInfo.imgThird = [];
      tempData.imgInfo.imgFourth = [];

      tempData.tagList = [];
      tempData.exhibitionList = [];
      tempData.detailPhotoAlbums = [];
      tempData.allowOrderType='';
      tempData.spuGroupName=''
      this.goodsData = tempData;
    },
    getGoodsData(data) {
      let tempData = {}
      tempData.goodsId = data.goodsId;
      tempData.cnName = data.cnName;
      tempData.goodsCode = data.goodsCode;
      tempData.goodsSpec = data.goodsSpec;
      tempData.goodsState = data.goodsState;
      tempData.initPrice = data.initPrice;
      tempData.markingPrice = data.markingPrice;
      tempData.categoryId = data.categoryId;
      tempData.categoryName = data.categoryName;
      tempData.effectiveTime = data.effectiveTime;
      tempData.failureTime = data.failureTime;
      tempData.allowOrderType = data.allowOrderType;
      tempData.spuGroupName = data.spuGroupName;

      tempData.effectiveTime = data.effectiveTime;
      tempData.failureTime = data.failureTime;

      tempData.goodsInfo = {}
      tempData.goodsInfo.virtualSale = data.goodsInfo.virtualSale;
      tempData.goodsInfo.transportId = data.goodsInfo.transportId;
      tempData.goodsInfo.weight = data.goodsInfo.weight;

      tempData.imgInfo = {}
      tempData.imgInfo.imgFirst = data.pictureUrl ? [{response: data.pictureUrl}] : [];
      tempData.imgInfo.imgSecond = data.sharePictureUrl ? [{response: data.sharePictureUrl}] : [];
      tempData.imgInfo.imgThird = data.shareMomentsPictureUrl ? [{response: data.shareMomentsPictureUrl}] : [];
      tempData.imgInfo.imgFourth = data.sowPhotoAlbums ? data.sowPhotoAlbums.map(a=>{
        return {response: a.photoUrl}
      }) : [];

      tempData.tagList = data.tagList;
      tempData.exhibitionList = data.exhibitionList;
      tempData.detailPhotoAlbums = data.detailPhotoAlbums;

      this.goodsData = tempData;
    },
    setProductStepsDataToStore() {
      if(!this.goodsId){
        return;
      }
      let params = {
        goodsId: this.goodsId,
        Step: this.stepActive,
        Back: false,
      };
      this.$store.commit("setProductStepsData", params);
    },
    removeProductStepsDataFromStore() {
      this.$store.commit("setProductStepsData", {});
    },
    goStep(id) {
      let child = this.getChildByStepActive()
      let that = this
      if (child.checkDataChange()) {
        this.$confirm(`是否保存当前页修改？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let state = child.saveDataChange();
          if (state == 1) {
            that.stepActive = id;
          }
        }).catch(() => {
          that.stepActive = id;
        });
      }else{
        that.stepActive = id;
      }
    },
    swiStep(id){
      this.stepActive = id;
    },

    returnGoodsData(goodsData) {
      this.getGoodsData(goodsData)
    },
    refreshGoodsData() {
      this.queryGoodsDetail(this.goodsId)
    },
    getChildByStepActive(){
      let child
      if (this.stepActive == 1) {
        child = this.$refs.child1;
      } else if (this.stepActive == 2) {
        child = this.$refs.child2;
      } else if (this.stepActive == 3) {
        child = this.$refs.child3;
      } else if (this.stepActive == 4) {
        child = this.$refs.child4;
      } else if (this.stepActive == 5) {
        child = this.$refs.child5;
      }
      return child;
    }
  },
};
</script>
