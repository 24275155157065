<template>
  <el-dialog title="选择领用人" :visible.sync="dialogVisible" width="500px">
    <div class="select-brand-content">
      <div class="input-search">
        <el-input v-model="searchKey"></el-input>
        <el-button type="primary" @click="queryReceiveList">搜索</el-button>
      </div>
      <div class="search-result">
        <div
          class="search-result-item"
          @click="selectReceive(item, index)"
          v-for="(item, index) in receiveSearchResult"
          :key="index"
          :class="{ active : index == current}"
        >{{item.receiveName}}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">下一步</el-button>
    </span>
  </el-dialog>
</template>

<script>
import SearchConfig from "COMJS/query/SearchConfig";
import boundQuery from "API/whm/boundQuery";

export default {
  data() {
    return {
      searchKey:"",
      dialogVisible: false,
      receiveSearchResult: [],
      current: null,
      selectedReceive: {
        receiveId: "",
        receiveName: ""
      }
    };
  },
  computed: {
    receiveSearchConfig() {
      return SearchConfig.createNew({
        searchForm: {
          receiveName: this.selectedReceive.receiveName
        }
      });
    }
  },
  mounted() {
    this.queryReceiveList();
  },
  methods: {
    submit() {
      this.$emit("submit", this.selectedReceive);
      this.dialogVisible = false;
    },
    selectReceive(item, index) {
      //console.log(item)
      this.selectedReceive = item;
      this.current=index;      
    },
    // 查询供应商
    queryReceiveList() {
      let params = this.searchKey
      if(!this.searchKey){
        params = "all"
      }
      boundQuery.queryReceivePages(params).then(
        data => {          
          //console.log(data)
          this.receiveSearchResult = data.map(e => {
            return {
              receiveId: e.receiveId,
              receiveName: e.receiveName
            };
          });
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.select-brand-content {
  .input-search {
    padding: 20px;
    border-bottom: 1px solid $borderColor;
    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }
  .search-result {
    .search-result-item {
      cursor: pointer;
      line-height: 50px;
      padding: 0 20px;
      color: #666;
      border-bottom: 1px solid $borderColor;
      &:hover {
        background-color: rgba(51, 51, 51, 0.1);
      }
    }
  }
  .search-result-item.active,.search-result-item.active:hover{background-color:#E8F1FD;} 
}
</style>