<template>
  <div class="logistics-wrap"> 
    <el-form :inline="true" class="rec-inline">
      <el-button type="primary" @click="toEdit">发货</el-button>
      <el-button type="primary" @click="toHistory">发货记录</el-button>     
    </el-form>    

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column prop="createTime" label="下单时间 " :show-overflow-tooltip="true"></el-table-column>
      <el-table-column  prop="goodsName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column  prop="goodsCode" label="商品编码" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column  prop="totalNum" label="下单总数" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column  prop="availableNum" label="剩余数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column  prop="skuBatchNumData" label="批次信息" :show-overflow-tooltip="true"></el-table-column>        
    </ws-page-table>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],  
      whmInfo: {},
      searchInfo: {
        userId : JSON.parse(userModule.PPUser).userId
      },
      items: {
        userId: { filter: "EQ" }
      },       
    };    
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
  },
  methods: {
    toHistory(){
      this.$router.push("/som/deliveryOrder");
    },
    toEdit(){
      this.$router.push("/som/deliveryEdit");
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      //this.searchInfo.userId = JSON.parse(userModule.PPUser).userId
      //console.log(this.searchInfo.userId)
      WhmList.pageOrderGoodsStore(this.pageInfo, this.searchConfig).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            createTime: tools.formatDate(new Date(e.createTime),"yyyy-MM-dd"),
            goodsName: e.goodsName,
            goodsCode: e.goodsCode,
            totalNum: e.totalNum,
            availableNum: e.availableNum,
            skuBatchNumData: e.skuBatchNumData,            
          };
        });
        this.querySuccess(data);
      });   
    },   
  },
};
</script>
<style>
.logistics-wrap .rec-inline { margin-bottom: 20px;padding: 20px;background: #fff;}
</style>