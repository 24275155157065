<template>
  <div id="PriceInformation">
    <div class="title">商品名称：{{ goodsData.cnName }}</div>
    <div class="content">
      <div class="tips-column" style="line-height: 20px;">
        <div>1、以下价格设置只针对会员体系，经销商体系的价格设置，请移步【价格活动】</div>
        <div>2、「长期会员价信息」和「大促会员价信息」是针对所有会员的设置，如果要指定会员给予特殊优惠，请移步【价格活动】</div>
        <div>3、会员价信息内设置的「三图」，将在价格活动被使用时，替换基本信息内设置的「三图」</div>
      </div>
      <div class="information-title">
        <div>
          <el-checkbox v-model="priceData.openFlag" border>启用</el-checkbox>
        </div>
        <i class="el-icon-shopping-bag-1"/>
        <span>长期会员价信息</span>
      </div>
      <div style="position:relative;" :class="!priceData.openFlag?'text-color-gray':''">
        <div v-if="!priceData.openFlag" class="mask"></div>
        <div class="label">
          <div class="label-column-free">
            <div><span style="color:red">*</span> 零售价</div>
            <el-input class="myInput" v-model="priceData.l0CostPrice" placeholder="￥0.00" :disabled='toView'></el-input>
            <div class="label-describe">消费者从会员渠道下单的零售价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 普标会员</div>
            <el-input class="myInput" v-model="priceData.l1CostPrice" placeholder="￥0.00" :disabled='toView'></el-input>
            <div class="label-describe">普标拿货价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 银星会员</div>
            <el-input class="myInput" v-model="priceData.l2CostPrice" placeholder="￥0.00" :disabled='toView'></el-input>
            <div class="label-describe">银星拿货价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 金星会员</div>
            <el-input class="myInput" v-model="priceData.l3CostPrice" placeholder="￥0.00" :disabled='toView'></el-input>
            <div class="label-describe">金星拿货价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 伙伴服务费比例</div>
            <el-input class="myInput" v-model="priceData.egalitarianPerVal" placeholder="请输入伙伴服务费比例"
                      @keyup.native="keyupEvent4PositiveInteger($event,0,100)"
                      :disabled='toView'>
              <template slot="append">%</template>
            </el-input>
            <div class="label-describe">填写百分比，最小0，最大100</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 联盟业绩</div>
            <el-input class="myInput" v-model="priceData.achievement" placeholder="请输入联盟业绩"
                      :disabled='toView'></el-input>
            <div class="label-describe">填写业绩值，不填写比例</div>
          </div>
        </div>
        <div class="label">
          <div class="label-column">
            <div><span style="color:red">*</span> 商品略图</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                                :requestHeaders="requestHeaders" v-model="priceData.imgFirst"></draggable-upload>
            </div>
            <div class="label-describe">
              在小程序订单详情或选择商品规格处展示。
            </div>
          </div>
          <div class="label-column">
            <div><span style="color:red">*</span> 分享图（微信对话）</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                                :fileSize='131072' fileSizeText='128kb' :requestHeaders="requestHeaders"
                                v-model="priceData.imgSecond"></draggable-upload>
            </div>
            <div class="label-describe">
              尺寸比例5:4，小于128kb，分享到微信对话展示
            </div>
          </div>
          <div class="label-column">
            <div><span style="color:red">*</span> 分享图（朋友圈）</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                                :requestHeaders="requestHeaders" v-model="priceData.imgThird"></draggable-upload>
            </div>
            <div class="label-describe">
              分享到微信朋友圈或下载的图片展示。
            </div>
          </div>
        </div>
        <div class="label">
          <div class="label-column-long">
            <div><span style="color:red">*</span> 商品轮播图</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="10" :uploadUrl="uploadUrl()"
                                :requestHeaders="requestHeaders" v-model="priceData.imgFourth"></draggable-upload>
            </div>
            <div class="label-describe">
              在小程序商品详情页头部轮播
            </div>
          </div>
        </div>
        <div style="margin-left: 20px;">
          <span class="a-tag" @click="easyUseBase3Img(1)">使用基本信息图片</span>
        </div>
      </div>

      <div style="width: 100%;height: 50px;"></div>
      <div class="information-title">
        <div>
          <el-checkbox v-model="promotionPriceData.openFlag" border>启用</el-checkbox>
        </div>
        <i class="el-icon-present"/>
        <span>大促会员价信息</span>
        <span class="a-tag" @click="gotoSetPromotionEventDateTime">设置大促时间</span>
        <span v-if="dialogDataTimeArrShow.length>0">{{
            "(" +
            dateFormat(new Date(dialogDataTimeArrShow[0]), "yyyy-MM-dd hh:mm:ss")
            + " ~ " +
            dateFormat(new Date(dialogDataTimeArrShow[1]), "yyyy-MM-dd hh:mm:ss")
            + ")"
          }}</span>
      </div>
      <div style="position:relative;" :class="!promotionPriceData.openFlag?'text-color-gray':''">
        <div v-if="!promotionPriceData.openFlag" class="mask"></div>
        <div class="label">
          <div class="label-column-free">
            <div><span style="color:red">*</span> 零售价</div>
            <el-input class="myInput" v-model="promotionPriceData.l0CostPrice" placeholder="￥0.00"
                      :disabled='toView'></el-input>
            <div class="label-describe">消费者从会员渠道下单的零售价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 普标会员</div>
            <el-input class="myInput" v-model="promotionPriceData.l1CostPrice" placeholder="￥0.00"
                      :disabled='toView'></el-input>
            <div class="label-describe">普标拿货价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 银星会员</div>
            <el-input class="myInput" v-model="promotionPriceData.l2CostPrice" placeholder="￥0.00"
                      :disabled='toView'></el-input>
            <div class="label-describe">银星拿货价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 金星会员</div>
            <el-input class="myInput" v-model="promotionPriceData.l3CostPrice" placeholder="￥0.00"
                      :disabled='toView'></el-input>
            <div class="label-describe">金星拿货价</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 伙伴服务费比例</div>
            <el-input class="myInput" v-model="promotionPriceData.egalitarianPerVal" placeholder="请输入伙伴服务费比例"
                      @keyup.native="keyupEvent4PositiveInteger($event,0,100)"
                      :disabled='toView'>
              <template slot="append">%</template>
            </el-input>
            <div class="label-describe">填写百分比，最小0，最大100</div>
          </div>
          <div class="label-column-free">
            <div><span style="color:red">*</span> 联盟业绩</div>
            <el-input class="myInput" v-model="promotionPriceData.achievement" placeholder="请输入联盟业绩"
                      :disabled='toView'></el-input>
            <div class="label-describe">填写业绩值，不填写比例</div>
          </div>
        </div>
        <div class="label">
          <div class="label-column">
            <div><span style="color:red">*</span> 商品略图</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                                :requestHeaders="requestHeaders"
                                v-model="promotionPriceData.imgFirst"></draggable-upload>
            </div>
            <div class="label-describe">
              尺寸为750*1088px，在小程序订单详情或选择商品规格处展示。
            </div>
          </div>
          <div class="label-column">
            <div><span style="color:red">*</span> 分享图（微信对话）</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                                :fileSize='131072' fileSizeText='128kb' :requestHeaders="requestHeaders"
                                v-model="promotionPriceData.imgSecond"></draggable-upload>
            </div>
            <div class="label-describe">
              500*400，小于128kb，分享到微信对话展示
            </div>
          </div>
          <div class="label-column">
            <div><span style="color:red">*</span> 分享图（朋友圈）</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                                :requestHeaders="requestHeaders"
                                v-model="promotionPriceData.imgThird"></draggable-upload>
            </div>
            <div class="label-describe">
              750*1088px，分享到微信朋友圈或下载的图片展示。
            </div>
          </div>
        </div>
        <div class="label">
          <div class="label-column-long">
            <div><span style="color:red">*</span> 商品轮播图</div>
            <div>
              <draggable-upload class="myInput" :width="82" :height="82" :limit="10" :uploadUrl="uploadUrl()"
                                :requestHeaders="requestHeaders"
                                v-model="promotionPriceData.imgFourth"></draggable-upload>
            </div>
            <div class="label-describe">
              尺寸为750*1088px，在小程序商品详情页头部轮播
            </div>
          </div>
        </div>
        <div style="margin-left: 20px;">
          <span class="a-tag" @click="easyUseBase3Img(2)">使用基本信息图片</span>
        </div>
      </div>
      <div class="sumbit">
        <el-button @click="back">取消</el-button>
        <el-button type="primary" @click="save" :disabled='toView'>保存</el-button>
        <el-button type="primary" @click="save('next')" :disabled='toView'>下一步并保存</el-button>
      </div>
    </div>

    <el-dialog title="大促时间编辑" :visible.sync="dialogVisible">
      <el-form label-width="100px">
        <el-form-item label="开始结束时间：">
          <el-date-picker
              v-model="dialogDataTimeArr"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center;">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="saveDialogData">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import IoProgressService from "API/IoProgressService.js";
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import PimGoodsService from "API/pim/PimGoodsService";
import {
  queryEventDetail,
  changeEventBeginEndTime
} from 'API/pim/PimPriceEvent.js'
import tools from "COMJS/tools";
import inputKeyEvent from "COMJS/inputKeyEvent";
import Objects from "COMJS/Objects";
import BigNumber from "COMJS/bignumber.min"

export default {
  props: {
    goodsData: Object,
    toView: Boolean,
  },
  components: {
    draggableUpload,
  },
  data() {
    return {
      requestHeaders: {
        Authorization: userModule.token,
      },
      priceData: {
        openFlag: false,
        l0CostPrice: "",
        l1CostPrice: "",
        l2CostPrice: "",
        l3CostPrice: "",
        egalitarianPer: "",
        egalitarianPerVal: "",
        achievement: "",
        imgFirst: [],
        imgSecond: [],
        imgThird: [],
        imgFourth: [],
      },
      promotionPriceData: {
        openFlag: false,
        l0CostPrice: "",
        l1CostPrice: "",
        l2CostPrice: "",
        l3CostPrice: "",
        egalitarianPer: "",
        egalitarianPerVal:"",
        achievement: "",
        imgFirst: [],
        imgSecond: [],
        imgThird: [],
        imgFourth: [],
      },
      dialogDataTimeArrShow: [],
      dialogDataTimeArr: [],
      dialogVisible: false,
    };
  },
  computed:{
    BigNumber() {
      return BigNumber
    },
    tools(){
      return tools
    }
  },
  mounted() {
    //this.monitoring(); // 注册监听事件
  },
  watch: {
    goodsData() {
      //console.log("priceInformation","goodsData",this.goodsData);
      this.getGoodsDetails(this.goodsData.goodsId);
    },
  },
  methods: {
    keyupEvent4PositiveInteger: inputKeyEvent.keyupEvent4PositiveInteger,
    dateFormat: tools.formatDate,
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
    translatePriceData(dailyDistributorEvent) {
      //console.log("dailyDistributorEvent",dailyDistributorEvent)
      let daily
      if (dailyDistributorEvent) {
        //有对象
        daily = dailyDistributorEvent;
        if (daily.eventGoodsId && daily.state == '1') {
          //有eventGoodsId，并且state=1
          daily.openFlag = true;
        } else {
          //有可能state=2
          daily.openFlag = false;
        }
      } else {
        daily = {openFlag: false};
      }
      console.log("daily",daily)
      if(daily.egalitarianPer){
        daily.egalitarianPerVal =
            new BigNumber(daily.egalitarianPer).mul(100).toNumber();
      }
      daily.imgFirst = daily.eventPictureUrl ? [{response: daily.eventPictureUrl}] : []
      daily.imgSecond = daily.eventSharePictureUrl ? [{response: daily.eventSharePictureUrl}] : []
      daily.imgThird = daily.eventShareMomentsPictureUrl ? [{response: daily.eventShareMomentsPictureUrl}] : []
      daily.imgFourth = daily.sowPhotoAlbums ? daily.sowPhotoAlbums.map(a => {
        return {response: a.photoUrl}
      }) : [];
      this.priceData = daily;
      this.priceData_old = JSON.parse(JSON.stringify(this.priceData));
    },
    translatePromotionPriceData(promotionDistributorEvent) {
      //console.log("translatePromotionPriceData",promotionDistributorEvent)
      let promotion
      if (promotionDistributorEvent) {
        //有对象
        promotion = promotionDistributorEvent;
        if (promotion.eventGoodsId && promotion.state == '1') {
          //有eventGoodsId，并且state=1
          promotion.openFlag = true;
        } else {
          //有可能state=2
          promotion.openFlag = false;
        }
      } else {
        promotion = {openFlag: false};
      }
      console.log("promotion",promotion)
      if(promotion.egalitarianPer){
        promotion.egalitarianPerVal =
            new BigNumber(promotion.egalitarianPer).mul(100).toNumber();
      }
      promotion.imgFirst = promotion.eventPictureUrl ? [{response: promotion.eventPictureUrl}] : []
      promotion.imgSecond = promotion.eventSharePictureUrl ? [{response: promotion.eventSharePictureUrl}] : []
      promotion.imgThird = promotion.eventShareMomentsPictureUrl ? [{response: promotion.eventShareMomentsPictureUrl}] : []
      promotion.imgFourth = promotion.sowPhotoAlbums ? promotion.sowPhotoAlbums.map(a => {
        return {response: a.photoUrl}
      }) : [];
      this.promotionPriceData = promotion;
      this.promotionPriceData_old = JSON.parse(JSON.stringify(this.promotionPriceData));
    },
    getGoodsDetails(goodsId) {
      //取长期会员活动和大促会员活动
      PimGoodsService.getEventByGoods(goodsId).then((data) => {
        this.translatePriceData(data.dailyDistributorEvent)
        this.translatePromotionPriceData(data.promotionDistributorEvent);
      });
      let params = {
        pageSize: 10,
        pageNo: 1,
        eventId: '2'
      }
      queryEventDetail(params).then(data => {
        this.dialogDataTimeArrShow = [data.beginTime, data.endTime]
      })
    },
    checkDataChange() {
      if (
          JSON.stringify(this.priceData_old) != JSON.stringify(this.priceData)
          || JSON.stringify(this.promotionPriceData_old) != JSON.stringify(this.promotionPriceData)
      ) {
        return true;
      }
      return false;
    },
    saveDataChange() {
      let state = this.save();
      return state
    },

    back() {
      this.$confirm(`是否取消？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.go(-1);
      });
    },
    checkDataAvailable(data) {
      if (!data.openFlag) return true;
      if (!data.l0CostPrice) {
        this.$message.warning("零售价不能为空");
        return false;
      }
      if (!data.l1CostPrice) {
        this.$message.warning("普标会员价格不能为空");
        return false;
      }
      if (!data.l2CostPrice) {
        this.$message.warning("银星会员价格不能为空");
        return false;
      }
      if (!data.l3CostPrice) {
        this.$message.warning("金星会员价格不能为空");
        return false;
      }
      if (data.egalitarianPerVal == '') {
        this.$message.warning("伙伴服务费比例不能为空");
        return false;
      }
      /*if (data.achievement=='') {
        this.$message.warning("联盟业绩不能为空");
        return false;
      }*/
      if (!data.imgFirst.length) {
        this.$message.warning("商品略图不能为空");
        return false;
      }
      if (!data.imgSecond.length) {
        this.$message.warning("分享图（微信对话）不能为空");
        return false;
      }
      if (!data.imgThird.length) {
        this.$message.warning("分享图（朋友圈）不能为空");
        return false;
      }
      if (!data.imgFourth.length) {
        this.$message.warning("轮播图不能为空");
        return false;
      }
      return true;
    },
    async savePriceData() {
      //保存一个
      let param1 = this.priceData;
      param1.goodsId = this.goodsData.goodsId;
      param1.eventId = "1";
      if (this.priceData.openFlag) {
        param1.state = "1";
      } else {
        param1.state = "2";
      }
      if(param1.egalitarianPerVal){
        param1.egalitarianPer =
            new BigNumber(param1.egalitarianPerVal).div(100).toNumber();
      }
      if (param1.imgFirst.length > 0) {
        param1.eventPictureUrl = param1.imgFirst[0].response;
      }
      if (param1.imgFirst.length > 0) {
        param1.eventSharePictureUrl = param1.imgSecond[0].response;
      }
      if (param1.imgFirst.length > 0) {
        param1.eventShareMomentsPictureUrl = param1.imgThird[0].response;
      }
      if (param1.imgFourth.length > 0) {
        //轮播图片
        let _sowPhotoAlbums = param1.imgFourth.map((e, i) => {
          return {
            photoUrl: e.response,
            name: e.name,
            state: 1,
            photoType: "sow",
            position: i + 1,
          };
        });
        param1.sowPhotoAlbums = _sowPhotoAlbums;
      }
      let data = await PimGoodsService.saveByGoods(param1)
      if (data) {
        this.translatePriceData(data)
        return 1;
      } else {
        return 0;
      }
    },
    async savePromotionPriceData() {
      //保存一个
      let param1 = this.promotionPriceData;
      param1.goodsId = this.goodsData.goodsId;
      param1.eventId = "2";
      if (this.promotionPriceData.openFlag) {
        param1.state = "1";
      } else {
        param1.state = "2";
      }
      if(param1.egalitarianPerVal){
        param1.egalitarianPer =
            new BigNumber(param1.egalitarianPerVal).div(100).toNumber();
      }
      if (param1.imgFirst.length > 0) {
        param1.eventPictureUrl = param1.imgFirst[0].response;
      }
      if (param1.imgFirst.length > 0) {
        param1.eventSharePictureUrl = param1.imgSecond[0].response;
      }
      if (param1.imgFirst.length > 0) {
        param1.eventShareMomentsPictureUrl = param1.imgThird[0].response;
      }
      if (param1.imgFourth.length > 0) {
        //轮播图片
        let _sowPhotoAlbums = param1.imgFourth.map((e, i) => {
          return {
            photoUrl: e.response,
            name: e.name,
            state: 1,
            photoType: "sow",
            position: i + 1,
          };
        });
        param1.sowPhotoAlbums = _sowPhotoAlbums;
      }
      /*PimGoodsService.saveByGoods(param1).then((data) => {
        this.translatePromotionPriceData(data);
      });*/
      let data = await PimGoodsService.saveByGoods(param1)
      if (data) {
        this.translatePromotionPriceData(data)
        return 1;
      } else {
        return 0;
      }
    },
    async save(next) {
      if (!this.checkDataAvailable(this.priceData)) {
        return;
      }
      if (!this.checkDataAvailable(this.promotionPriceData)) {
        return;
      }
      let r1 = await this.savePriceData();
      let r2 = await this.savePromotionPriceData();
      //console.log("async save",r1, r2);
      if (r1 == 1 && r2 == 1) {
        this.$message({
          type: "success",
          message: "保存成功",
        });
        if (next == "next") {
          this.$emit("swiStep", 4);
        }
        return 1;
      } else {
        return 0;
      }
    },

    gotoSetPromotionEventDateTime() {
      //this.$store.commit("setProductStepsData_Back", true);
      //this.$router.push(`/pim/editPriceEvent?id=2`);
      this.dialogDataTimeArr = []
      Objects.deepCopy(this.dialogDataTimeArr, this.dialogDataTimeArrShow)
      this.dialogVisible = true;
    },
    saveDialogData() {
      let param = {
        eventId: '2',
        beginTime: new Date(this.dialogDataTimeArr[0]),
        endTime: new Date(this.dialogDataTimeArr[1])
      }
      changeEventBeginEndTime(param).then(data => {
        this.$message.success(`操作成功！`);
        this.dialogDataTimeArrShow = []
        Objects.deepCopy(this.dialogDataTimeArrShow, this.dialogDataTimeArr)
        this.dialogVisible = false;
      })
    },
    easyUseBase3Img(idx) {
      if (idx === 1) {
        this.priceData.imgFirst = this.goodsData.imgInfo.imgFirst;
        this.priceData.imgSecond = this.goodsData.imgInfo.imgSecond;
        this.priceData.imgThird = this.goodsData.imgInfo.imgThird;
        this.priceData.imgFourth = this.goodsData.imgInfo.imgFourth;
      } else {
        this.promotionPriceData.imgFirst = this.goodsData.imgInfo.imgFirst;
        this.promotionPriceData.imgSecond = this.goodsData.imgInfo.imgSecond;
        this.promotionPriceData.imgThird = this.goodsData.imgInfo.imgThird;
        this.promotionPriceData.imgFourth = this.goodsData.imgInfo.imgFourth;
      }
    }
  },
};
</script>
<style scoped>
@import url("./malllCss.css");

.content {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}

.icon-tip {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("~ASSETS/images/tip.png");
  background-size: cover;
}
</style>