const frame = {template: '<router-view></router-view>'}

export default {
    path: 'som',
    component: frame,
    children: [{
        name: '订单列表',
        path: 'orderList/:test?',
        component: resolve => import('PAGES/som/order/orderList.vue')
    }, {
        name: '批量发货',
        path: 'bulkShipment',
        component: resolve => import('PAGES/som/order/bulkShipment.vue')
    },
        {
            name: '发货管理',
            path: 'shipmentList/:test?',
            component: resolve => import('PAGES/som/order/shipmentList.vue')
        }, {
            name: '订单详情',
            path: 'orderDetail/:id?',
            component: resolve => import('PAGES/som/order/orderDetail.vue')
        }, {
            name: '编辑订单',
            path: 'orderEdit/:id?/:test?',
            component: resolve => import('PAGES/som/order/orderDetail.vue')
        }, {
            name: '客服下单',
            path: 'manualOrder',
            component: resolve => import('PAGES/som/order/manualOrder.vue')
        }, {
            name: '订单列表',
            path: 'orderListD',
            component: resolve => import('PAGES/som/orderForD/orderListForDistributor.vue')
        }, {
            name: '订单详情',
            path: 'orderDetailD/:id?',
            component: resolve => import('PAGES/som/orderForD/orderDetailForDistributor.vue')
        }, {
            name: '售后列表',
            path: 'orderAfterD',
            component: resolve => import('PAGES/som/orderForD/orderAfterListForDistributor.vue')
        }, {
            name: '编辑订单',
            path: 'orderEditD/:id?',
            component: resolve => import('PAGES/som/orderForD/orderDetailForDistributor.vue')
        }, {
            name: '批量下单',
            path: 'orderBatchList/:test?',
            component: resolve => import('PAGES/som/order/orderBatchList.vue')
        }, {
            name: '发票处理',
            path: "dealWithInvoice",
            component: () => import('PAGES/som/order/dealWithInvoice.vue')
        }, {
            name: '发票管理',
            path: "invoice",
            component: () => import('PAGES/som/invoice/invoice.vue')
        }, {
            name: '发票详情',
            path: "invoiceDetail/:id?",
            meta: {
                noKeepAlive: true
            },
            component: () => import('PAGES/som/invoice/invoiceDetail.vue')
        }, {
            name: '奶卡管理',
            path: "milkCard",
            component: () => import('PAGES/som/order/milkCard.vue')
        }, {
            name: '奶卡详情',
            path: "milkCardDetail/:id",
            meta: {
                noKeepAlive: true
            },
            component: () => import('PAGES/som/order/milkCardDetail.vue')
        }, {
            name: '退款处理',
            path: "reimburse",
            component: () => import('PAGES/som/order/reimburse.vue')
        }, {
            name: '订单导出记录',
            path: "orderExportRecord",
            meta: {
                noKeepAlive: true  //关闭缓存
            },
            component: () => import('PAGES/som/order/orderExportRecord.vue')
        }, {
            name: '物流单',
            path: 'logisticsOrder',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsOrder.vue')
        }, {
            name: '物流下单',
            path: 'logisticsAdd',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsAdd.vue')
        }, {
            name: '添加商品',
            path: 'logAddGoods',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logAddGoods.vue')
        }, {
            name: '物流详情',
            path: 'logisticsDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsDetail.vue')
        }, {
            name: '查看物流出库单',
            path: 'logisticsInfo/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsInfo.vue')
        }, {
            name: '物流发货',
            path: 'deliveryList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/deliveryList.vue')
        }, {
            name: '物流发货',
            path: 'deliveryEdit',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/deliveryEdit.vue')
        }, {
            name: '发货记录',
            path: 'deliveryOrder',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/deliveryOrder.vue')
        }, {
            name: '发货查看',
            path: 'deliveryDetail/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/deliveryDetail.vue')
        }, {
            name: '打印',
            path: 'logisticsA4/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsA4.vue')
        }, {
            name: '打印',
            path: 'logisticsA4Pdf/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsA4Pdf.vue')
        }, {
            name: '打印',
            path: 'logisticsA4Pdf2/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsA4Pdf2.vue')
        }, {
            name: '物流出库单',
            path: 'logisticsList',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsList.vue')
        }, {
            name: '编辑出库单',
            path: 'logisticsEdit/:id?',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/logisticsEdit.vue')
        }, {
            name: '发货信息',
            path: 'deliveryInformation',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/logistics/deliveryInformation.vue')
        },
        {
            name: '批量权限申请列表',
            path: 'importPermission',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/order/importPermission.vue')
        },
        {
            name: '批量权限申请',
            path: 'addImport',
            meta: {noKeepAlive: true},
            component: resolve => import('PAGES/som/order/addImport.vue')
        }
    ]
}