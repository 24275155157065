<template>
  <div>
    <el-form label-width="120px" :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="showUnit">新增规格</el-button>
      </el-form-item>
      <el-form-item label="规格名称:">
        <el-input v-model="searchInfo.name" />
      </el-form-item>      
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="name"
        label="规格名"
        :show-overflow-tooltip="true"
        width="260px"
      ></el-table-column>
      <el-table-column width="260px" label="是否启用">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.enabled"
            @click.native="toggleEnable(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="showDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    
    <!--新增规格-->
    <el-dialog :title="this.currentInfo.id ? '编辑规格' : '新增规格'" :visible.sync="unitDialog" @close="closeAddDialog" width="606px">
        <el-form label-width="140px">
          <el-form-item required label="规格名称：">
            <el-input v-model="currentInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="是否启用：">
            <el-switch
              :value="currentInfo.enabled"
              @click.native="editEnable()"
            ></el-switch>
          </el-form-item>          
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="unitDialog = false">取消</el-button>
          <el-button type="primary" @click="addUnit">确 定</el-button>
        </div>
    </el-dialog>

    <!--规格详情-->
    <el-dialog title="规格详情" :visible.sync="unitDetailDialog" width="606px">
        <el-form label-width="140px">
          <el-form-item label="规格名称：">
            {{unitDetail.name}}
          </el-form-item>
          <el-form-item label="是否启用：">
            {{unitDetail.enabled == true ? '是' : '否'}}
          </el-form-item>          
        </el-form>        
    </el-dialog>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  name: "whmSpec",
  data() {
    return {
      value: [],
      options: [],
      currentInfo: {
        id: "",
        name: "",
        enabled: true,
      },
      searchInfo: {
        name: "",
        specUnitType: 1,
      },
      items: {
        name: { filter: "like" },
        specUnitType: {filter: "EQ"},
      },
      specUnitType: 1, //0代表请求的是单位接口 1请求的是规格接口
      unitDialog: false, //是否显示 添加修改规格窗口
      unitDetailDialog: false, //是否显示 规格详情窗口
      unitDetail: {
        name: "",
        enabled: true,
      },
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: {
    closeAddDialog() {
      this.currentInfo.id = ""
      this.currentInfo.name = ""
    },
    //添加、编辑规格
    addUnit(){
      let params = {
        specUnitId: this.currentInfo.id,
        name: this.currentInfo.name,
        enabled: this.currentInfo.enabled ? 1 : 0,
        specUnitType: 1,
      };
      this.loading = true;
      WhmList.saveUnitSpec(params).then((res) => {
        this.loading = false;
        this.$message({
          type: "success",
          message: "添加规格成功!",
        });
        this.currentInfo.name = ""       
        this.loadTableData()
        this.unitDialog = false
        if(this.currentInfo.id){
          this.currentInfo.id = ""
        }
      });
    },
    // 切换 编辑 的状态
    editEnable() {this.currentInfo.enabled = !this.currentInfo.enabled },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      WhmList.queryUnitSpecPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.specUnitId,
            name: e.name,
            enabled: e.enabled == 1 ? true : false,
          };
        });
        this.querySuccess(data);
      });
    },
    showUnit(){ this.unitDialog = true },
    showDetail(row) {    
      this.unitDetail.name = row.name
      this.unitDetail.enabled = row.enabled
      this.unitDetailDialog = true
    },
    toEdit(row) {
      //console.log(row)
      this.currentInfo.id = row.id      
      this.currentInfo.name = row.name
      this.currentInfo.enabled = row.enabled
      this.unitDialog = true 
    },

    //切换列表启用状态
    toggleEnable(row) { 
      const that = this;
      this.$confirm(`是否切换规格'${row.name}'的启用状态？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.enabled = !row.enabled == true ? 1 : 0; // 切换状态
          row.id = row.id;
          WhmList.updateUnitEnable(row).then((res) => {
            that.loadTableData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.el-form--inline .el-form-item {
  margin-bottom: 0;
}
.header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}
.ws-page-table .icon-operation a {
  margin-right: 20px;
}
</style>