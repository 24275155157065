<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="changeType">
      <el-tab-pane label="小程序" name="wmp"></el-tab-pane>
      <el-tab-pane label="app" name="app"></el-tab-pane>
    </el-tabs>
    <div style="margin-bottom: 10px;">
      <el-button type="primary" @click="editEvent()">增加</el-button>
      <el-button style="margin-left: 20px;" @click="showSortDialog">排序</el-button>
    </div>

    <ws-page-table :data="tableData" ref="pageTable" :pageable="false"
                   :pageInfo="pageInfo" @pageChange="loadTableData"
                   :showIndex="true" :isShadow="false">
      <el-table-column prop="title" label="标题" :show-overflow-tooltip="true">
        <template slot-scope="scope" class="noel">
          <div style="display: flex;align-items: center;">
            <img v-if="scope.row.iconUrl" :src="scope.row.iconUrl" width="20px" height="20px">
            <p style="margin-left: 10px;">{{ scope.row.title }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="tradeType" label="平台">
        <template slot-scope="scope">
          {{ scope.row.tradeType == 'wmp' ? '小程序' : 'app' }}
        </template>
      </el-table-column>
      <el-table-column prop="viewEnable" label="展示">
        <template slot-scope="scope">
          <i class="el-icon-check"
             style="color: #00a0e9;font-weight: bold;font-size: 20px;"
             v-if="scope.row.viewEnable == '1'"/>
        </template>
      </el-table-column>
      <el-table-column label="展示角色" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-for="(item,i) in scope.row.relaRoleList" :key="i">{{ item.roleName }}<span
              v-if="i != scope.row.relaRoleList.length-1">/</span></span>
        </template>
      </el-table-column>
      <el-table-column prop="dispNo" label="顺序">
        <template slot-scope="scope">
          {{ scope.row.dispNo+1}}
        </template>
      </el-table-column>
      <el-table-column prop="orderCode" label="操作" width="100px">
        <template slot-scope="scope">
          <div class="icon-operation" style="display: flex;justify-content: space-between;">
            <!--
            <a href="javascript: void(0);" title="查看" style="margin-right: 0px !important;">
              <i class="CCSFont icon-table_2" @click="seeDetail(scope.row.activityId)"></i>
            </a>
            -->
            <a href="javascript: void(0);" style="margin-right: 0px !important;" @click="goDetail(scope.row.activityId)"
               title="编辑">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <el-popconfirm style="margin-left:0;" title="确定删除吗？" @confirm="delShowEvent(scope.row.activityId)">
              <el-button>删除</el-button>
              <a slot="reference" href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid"></i>
              </a>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="排序" :visible.sync="sortDialogVisible" width="250px">
      <div>
        <vuedraggable v-model="sortList" animation="300">
          <transition-group>
            <div class="item" v-for="item in sortList" :key="item.activityId">{{item.title}}</div>
          </transition-group>
        </vuedraggable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sortDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmSort">确定</el-button>
      </span>
    </el-dialog>
    <!--
    <el-dialog :title="modalTitle" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="标题">
            <span>{{ eventDetail.title }}</span>
          </el-form-item>
          <el-form-item label="平台">
            <span>{{ eventDetail.tradeType == 'wmp' ? '小程序' : 'app' }}</span>
          </el-form-item>
          <div v-if="eventDetail.iconUrl || eventDetail.iconUrlSelected"
               style="display:flex;margin-left:40px;margin-bottom:20px;">
            <div v-if="eventDetail.iconUrl">
              <div style="margin-bottom:10px;">选中前</div>
              <img width="20px" height="20px" :src="eventDetail.iconUrl">
            </div>
            <div style="margin-left:20px;" v-if="eventDetail.iconUrlSelected">
              <div style="margin-bottom:10px;">选中后</div>
              <img width="20px" height="20px" :src="eventDetail.iconUrlSelected">
            </div>
          </div>
          <el-form-item label="角色可见">
                        <span v-for="(item,i) in eventDetail.relaRoleList" :key="item.activityRoleRelaId">
                            {{ item.roleName }} <span v-if="i != eventDetail.relaRoleList.length-1">/</span>
                        </span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    -->
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import draggableUpload from "COMPS/base/draggableUpload";
import vuedraggable from "vuedraggable";
import BimEnumerationInfo from "API/bim/BimEnumerationInfo";
import {
  showEventList,
  delShowEvent,
  sortShowEvent
} from "@/api/pim/PimShowEvent.js";

export default {
  components: {
    draggableUpload,
    vuedraggable
  },
  data() {
    return {
      activeName: "wmp",
      sortList:[],
      tableData: [],
      modalTitle: "查看活动",
      dialogVisible: false,
      firstImg: "",
      secondImg: "",
      sortDialogVisible:false,
      eventDetail: {},
      enums: {},
      searchInfo: {
        tradeType: "wmp",
        type: "mall"
      },
      items: {
        tradeType: {filter: "like"},
        mall: {filter: "EQ"},
      },
    };
  },
  methods: {
    async loadTableData() {
      this.pageInfo.pageSize=100;
      this.pageInfo.pageNo=1;
      const data = await showEventList(this.pageInfo, this.searchConfig)
      this.tableData = data.list
      this.tableData.forEach((item1, i) => {
        this.tableData[i].relaRoleList.map(item => {
          for (let key in this.enums) {
            if (item.roleCode == key) {
              item.roleName = this.enums[key]
              break
            }
          }
          if (item.roleCode == 'all') {
            item.roleName = '全部'
          }
          if(!item.roleName){
            item.roleName = item.roleCode
          }
          return item
        })
      })
      this.querySuccess(data)
    },
    queryRoles() {
      BimEnumerationInfo
          .listByType('ROLE_CODE')
          .then(data => {
            //console.log(data)
            let roleList = data
            roleList.forEach(item => {
              this.enums[item.code] = item.name
            })
          });
    },
    editEvent() {
      this.$router.push({
        path: "editShowEvent"
      })
    },
    goDetail(id) {
      this.$router.push({
        path: "editShowEvent",
        query: {
          id
        }
      })
    },
    async delShowEvent(id) {
      await delShowEvent(id)
      await this.loadTableData()
    },
    changeType() {
      this.searchInfo.tradeType = this.activeName
      this.pageInfo.pageNo = 1
      this.loadTableData()
    },
    async seeDetail(id) {
      this.eventDetail = this.tableData.filter(a => a.activityId == id)[0]
      this.dialogVisible = true
    },
    showSortDialog(){
      this.sortList = this.tableData.map(a=>{
        return {
          activityId:a.activityId,
          title:a.title
        }
      })
      this.sortDialogVisible=true
    },
    async confirmSort(){
      let sortIdList = this.sortList.map(a=>{
        return a.activityId
      })
      let param = {
        sortIdList:sortIdList
      }
      await sortShowEvent(param)
      this.$message({
        message: "排序更新成功",
        type: "success",
      });
      this.sortDialogVisible = false;
      await this.loadTableData();
    }
  },
  mixins: [tableMixins],
  async mounted() {
    await this.queryRoles()
  }
};
</script>

<style>
.noel {
  text-overflow: clip !important;
}
.item {
  height: 40px;
  line-height: 40px;
  border: solid 1px #eee;
  background-color: #f1f1f1;
  text-align: center;
  margin: 5px;
}
.item:hover {
  background-color: #fdfdfd;
  cursor: move;
}
</style>
