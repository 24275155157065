import axiosHttp from "../axiosHttp";

export default {
    queryPage(pageInfo,searchConfig){
        let url = "/purMaterial/queryPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryList(param){
        let url = "/purMaterial/queryList"
        return axiosHttp.POST(url,param)
    },
    save(param){
        let url = "/purMaterial/save"
        return axiosHttp.POST(url,param)
    },
    removeById(id){
        let param = {
            materialId : id
        }
        let url = `/purMaterial/remove`
        return axiosHttp.POST(url,param)
    },

}