import axiosHttp from "../axiosHttp"

let versionNo = -1

export default {
  findAuditConfigurationPage(pageInfo,searchConfig) {
    let url = "/baAuditConfiguration/findAuditConfigurationPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveAuditConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/saveAuditConfigurationInfo`,param)
  },
  updateAuditConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/updateAuditConfigurationInfo`,param)
  },
  removeAuditConfigurationInfoByIds(ids){
    let param = {
      delIds:ids
    }
    return axiosHttp.POST(`/baAuditConfiguration/removeAuditConfigurationInfoByIds`,param)
  },


  findAuditVersionConfigurationPage(pageInfo,searchConfig) {
    let url = "/baAuditConfiguration/findAuditVersionConfigurationPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveAuditVersionConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/saveAuditVersionConfigurationInfo`,param)
  },
  updateAuditVersionConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/updateAuditVersionConfigurationInfo`,param)
  },
  removeAuditVersionConfigurationInfoByIds(ids){
    let param = {
      delIds:ids
    }
    return axiosHttp.POST(`/baAuditConfiguration/removeAuditVersionConfigurationInfoByIds`,param)
  },



  findAuditNodeConfigurationPage(pageInfo,searchConfig) {
    let url = "/baAuditConfiguration/findAuditNodeConfigurationPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveAuditNodeConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/saveAuditNodeConfigurationInfo`,param)
  },
  updateAuditNodeConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/updateAuditNodeConfigurationInfo`,param)
  },
  removeAuditNodeConfigurationInfoByIds(ids){
    let param = {
      delIds:ids
    }
    return axiosHttp.POST(`/baAuditConfiguration/removeAuditNodeConfigurationInfoByIds`,param)
  },


  findAuditNodeRoleConfigurationPage(pageInfo,searchConfig) {
    let url = "/baAuditConfiguration/findAuditNodeRoleConfigurationPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveAuditNodeRoleConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/saveAuditNodeRoleConfigurationInfo`,param)
  },
  updateAuditNodeRoleConfigurationInfo(param){
    return axiosHttp.POST(`/baAuditConfiguration/updateAuditNodeRoleConfigurationInfo`,param)
  },
  removeAuditNodeRoleConfigurationInfoByIds(ids){
    let param = {
      delIds:ids
    }
    return axiosHttp.POST(`/baAuditConfiguration/removeAuditNodeRoleConfigurationInfoByIds`,param)
  },


  findAuditNodePersonConfigurationPage(pageInfo,searchConfig) {
    let url = "/baAuditConfiguration/findAuditNodePersonConfigurationPage?";
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  saveAuditNodePersonConfigurationList(personList){
    return axiosHttp.POST(`/baAuditConfiguration/saveAuditNodePersonConfigurationList`,personList)
  },
  removeAuditNodePersonConfigurationInfoByIds(ids){
    let param = {
      delIds:ids
    }
    return axiosHttp.POST(`/baAuditConfiguration/removeAuditNodePersonConfigurationInfoByIds`,param)
  },
}