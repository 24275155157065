<template>
  <div class="order-list">
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">订单编号：</span>
            <el-input
              size="mini"
              v-model="searchInfo.orderCode"
              placeholder="请输入订单号"
            ></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">订单来源：</span>
            <el-select v-model="searchInfo.orderType" placeholder="请选择">
              <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">商品编码：</span>
            <el-input size="mini" v-model="searchInfo.goodsCode"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">会员渠道（手机号）：</span>
            <el-input
              size="mini"
              v-model="searchInfo.intermediatorUserTel"
            ></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">下单时间：</span>
            <el-date-picker
              v-model="filterTimes"
              @change="changePickerDate"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            <span
              class="filter-time-block"
              @click="filterDate('today')"
              :class="{ 'is-active': filterDateTimer == 'today' }"
              >今</span
            >
            <span
              class="filter-time-block"
              @click="filterDate('yesterday')"
              :class="{ 'is-active': filterDateTimer == 'yesterday' }"
              >昨</span
            >
            <span
              class="filter-time-block"
              @click="filterDate(7)"
              :class="{ 'is-active': filterDateTimer == 7 }"
              >近7天</span
            >
            <span
              class="filter-time-block"
              @click="filterDate(30)"
              :class="{ 'is-active': filterDateTimer == 30 }"
              >近30天</span
            >
          </div>
        </div>
        <div class="form-row">
          <el-button
            size="mini"
            type="primary"
            style="margin-right: 10px"
            @click="searchData"
            >搜索</el-button
          >
          <!--
          <el-button size="mini" @click="exportOrder">导出</el-button>
          -->
        </div>
      </div>
      <div class="filter_right">
        <el-button
          size="mini"
          type="primary"
          @click="
            $router.push({
              path: '/ba/systemLog',
              query: { logType: 'order' },
            })
          "
          >操作日志
        </el-button>
      </div>
    </div>
    <div class="order-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs
          v-model="searchInfo.orderState"
          type="card"
          @tab-click="changeTable"
        >
          <el-tab-pane label="待发货" name="unShipped"></el-tab-pane>
          <el-tab-pane label="预售单" name="preSell"></el-tab-pane>
          <el-tab-pane label="定时单" name="scheduled"></el-tab-pane>
        </el-tabs>
        <el-button
          size="mini"
          @click="getShowPopop = true"
          v-if="searchInfo.orderState != 'unShipped'"
          >转待发货
        </el-button>
        <el-button
            size="mini"
            type="primary"
            :disabled="true"
            @click="isShowPopop = false"
            v-if="searchInfo.orderState == 'unShipped'"
        >自动转仓库
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="isShowPopop = true"
          v-if="searchInfo.orderState == 'unShipped'"
          >待发货转仓库
        </el-button>
        <!--
        <el-button
            size="mini"
            @click="isShowPopop = true"
            v-if="searchInfo.orderState == 'unShipped'"
        >导出待发货包裹
        </el-button>
        <el-button size="mini" type="primary" v-if="searchInfo.orderState == 'unShipped'"
                   @click="$router.push({path:'/som/orderExportRecord'})">订单导出记录
        </el-button>
      </div>
      -->
        <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          :isShadow="false"
          :selection="'multi'"
        >
          <el-table-column prop="orderCode" :show-overflow-tooltip="true" label="订单号"></el-table-column>
          <el-table-column prop="status" label="订单状态"></el-table-column>
          <el-table-column
            prop="price"
            label="订单价格（元）"
          ></el-table-column>
          <el-table-column prop="source" label="订单来源"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="remark" :show-overflow-tooltip="true" label="备注"></el-table-column>
          <el-table-column prop="mixTime" label="发货时间"></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="查看">
                  <i
                    class="CCSFont icon-table_2"
                    @click="
                      $router.push(`/som/orderDetail/${scope.row.orderId}`)
                    "
                  ></i>
                </a>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>

    <!--弹窗-->
    <!--
    <el-dialog title="导出发货包裹" :visible.sync="isShowPopop" width="560px">
      <div style="color: #FBC14E;">提醒：</div>
      <div>
        <br/>
        <p>1、点击确定即导出包裹信息excel；</p>
        <br/>
        <p>2、邮政小包、德邦快递、顺丰快递分别为三个sheet；</p>
        <br/>
        <p>3、点击确定即自动将订单转为已发货，请尽快导入运单号信息；</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="exportOrder">确 定</el-button>
      </div>
    </el-dialog>
    -->
    <el-dialog title="待发货转仓库" :visible.sync="isShowPopop" width="560px">
      <div style="color: #fbc14e">提醒：</div>
      <div>
        <br />
        <p>1、点击确定即将待发货订单转入出库单；</p>
        <br />
        <p>2、这可能需要一些时间，请耐心等待；</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="orderToWh">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="订单转待发货" :visible.sync="getShowPopop" width="560px">
      <div style="color: #fbc14e">提醒：</div>
      <div>
        <br />
        <br />
        <p>点击确定即自动将订单转为待发货；</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="getShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="changeOrderState">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import Enum from "ASSETS/js/enum";
import translate from "ASSETS/js/translate";
import chargeFormat from "COMJS/chargeFormat";
import orderService from "API/som/orderService";
import orderOperateService from "API/som/orderOperate";
import orderShipService from "API/som/orderShipService";
import pageTableMixin from "@/mixin/pageTableMixin";
import IoProgressService from "API/IoProgressService";
import userModule from "@/module/userModule";

export default {
  name: "orderList",
  data() {
    return {
      searchInfo: {
        orderCode: "",
        batchCode: "",
        intermediatorUserTel: "",
        orderType: "",
        createTimeBeginDate: "",
        createTimeEndDate: "",
        orderState: "unShipped",
        recipientName: "",
        recipientPhoneNum: "",
        recipientFullAddress: "",
        thirdPartyOrderCode: "",
        goodsCode: "",
        packageTransportNo: "",
      },
      filterTimes: [],
      filterDateTimer: "", // 快捷选择时间类型
      sourceList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "wmp",
          label: "小程序",
        },
        {
          value: "import",
          label: "批量导入",
        },
        {
          value: "adm",
          label: "客服下单",
        },
      ],
      isShowPopop: false,
      getShowPopop: false,
      first: false,
    };
  },
  mixins: [pageTableMixin],
  computed: {
    isTest: function () {
      if (this.$route.params.test === 'test') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // if(this.$route.query.orderState){
    //     alert(this.$route.query.orderState);
    //     this.searchInfo.orderState = this.$route.query.orderState;
    //     this.loadTableData();
    // }
  },
  watch: {
    $route(newVal) {
      if (newVal.query.orderState) {
        this.searchInfo.orderState = newVal.query.orderState;
        this.loadTableData();
      }
    },
  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      orderService.queryShipmentOrderPages(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          e.createTime = tools.formatDate(
            new Date(e.createTime),
            "yyyy-MM-dd hh:mm:ss"
          );
          e.mixTime = '';
          let mixTime = e.appointShippingTime || e.expectShippingTime;
          //如果两个字段都有值，取e.appointShippingTime
          if(e.appointShippingTime){
            mixTime = e.appointShippingTime;
          }
          console.log("******"+mixTime);
          e.mixTime = tools.formatDate(
                new Date(mixTime),
                "yyyy-MM-dd"
              );
          console.log(e.mixTime);
          // e.source = translate.source(e.orderType);
          e.source = Enum.orderType[e.orderType];
          e.status = translate.orderState(e);
          e.orderCode = e.orderCode || "无";
          e.price = e.totalAmount.toFixed(2); // chargeFormat.thousandsOf()
          return e;
        });
        this.querySuccess(data);
      });
    },
    changeTable(event) {
      this.first = event.name == "unShipped" ? false : true;
      this.searchInfo.orderState = event.name;
      this.pageInfo.pageNo = 1;
      this.loadTableData();
    },
    changeOrderState() {
      var that = this;
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert("请选择记录！", "系统提示");
        return;
      }
      let changeList = rows.map((e) => {
        return e.orderId;
      });
      orderService.changeOrderUnShipped(changeList).then((data) => {
        this.getShowPopop = false;
        this.searchInfo.orderState = "unShipped";
        this.loadTableData();
      });
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.createTimeBeginDate = "";
        this.searchInfo.createTimeEndDate = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.createTimeBeginDate = tools.formatDate(
          new Date(e[0]),
          "yyyy-MM-dd"
        );
        this.searchInfo.createTimeEndDate = tools.formatDate(
          new Date(e[1]),
          "yyyy-MM-dd"
        );
      }
    },
    filterDate(timer) {
      this.filterDateTimer = timer;
      let _time = tools.filterDateMethod(timer);
      if (!this.filterTimes) {
        //鼠标点击x号的时候 filterTimes为null  先初始化为数组，然后吧值填进去
        this.filterTimes = [];
        this.filterTimes.push(_time.startTime, _time.endTime);
      } else {
        this.$set(this.filterTimes, 0, _time.startTime);
        this.$set(this.filterTimes, 1, _time.endTime);
      }
      this.searchInfo.createTimeBeginDate = _time.startTime;
      this.searchInfo.createTimeEndDate = _time.endTime;
    },
    exportOrder() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      var that = this;
      orderShipService
        .exportOrderPackage(this.queryParams)
        .then((data) => {
          let fileName = data.fileName;
          let module = data.module;
          IoProgressService.download(module, fileName, false);
          loading.close();
          that.isShowPopop = false;
          that.loadTableData();
        })
        .catch(() => {
          loading.close();
        });
    },
    orderToWh() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let that = this;
      orderService
        .orderToWh(this.queryParams)
        .then((data) => {
          loading.close();
          that.loadTableData();
          that.isShowPopop = false
        })
        .catch(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss">
.order-list {
  .order-table {
    .tabs-wrapper {
      padding: 10px 20px;

      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;

        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }

    background-color: #fff;
  }
}
</style>
