<template>
  <div class="inv-detail">
    <el-form :inline="true" class="demo-form-inline1">
      <el-row>        
        <el-form-item label="库品编码:">
          {{skuInfo.skuCode}}
        </el-form-item>
        <el-form-item label="库品名称:">
          {{skuInfo.skuName}}
        </el-form-item>
        <el-form-item label="库品批次:">
          {{skuInfo.purchaseBatchCode}}
        </el-form-item>
        <el-form-item label="供应商:">
          {{skuInfo.supplierName}}
        </el-form-item>
      </el-row>
      <el-row>        
        <el-form-item label="批次总数量:">
          {{skuInfo.actualNum}}
        </el-form-item>
        <el-form-item label="生产日期:">
          {{skuInfo.productionDate}}
        </el-form-item>
        <el-form-item label="保质期:">
          {{skuInfo.shelfLife}}&nbsp;天
        </el-form-item>    
        <el-form-item label="保质期至:">
          {{skuInfo.doomsday}}
        </el-form-item>
      </el-row>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="createTime"
        label="入库时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="operNum"
        label="入库数量"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="入库照片"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.photoIds.length>0"
            style="width: 100px; height: 100px"
            :src="scope.row.photoIds[0]" 
            :preview-src-list="scope.row.photoIds">
          </el-image>
        </template>  
      </el-table-column>
      <el-table-column prop="billCode" label="单据编号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="createUser" label="操作人" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="purchasePrice" label="库品单价" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true"></el-table-column>      
    </ws-page-table>
    
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import invQuery from "API/whm/invQuery";
import tools from "COMJS/tools";

export default {
  name: "invBatchDetail",
  data() {
    return {
      idx: 0,
      value: [],
      options: [],
      skuInfo: {},
      searchInfo: {},
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: {    
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.inventoryId = this.$route.params.id
      invQuery.querySkuAndInvRecordPage(this.queryParams).then((data) => {
        if(data.skuInfo){
          data.skuInfo.productionDate = data.skuInfo.productionDate ? tools.formatDate(new Date(data.skuInfo.productionDate), "yyyy-MM-dd") : ""
          data.skuInfo.doomsday = tools.formatDate(new Date(data.skuInfo.doomsday), "yyyy-MM-dd")
          this.skuInfo = data.skuInfo        
        }
        data.list = data.records.list.map((e) => {
          //console.log(e)
          return {
            createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss"),
            operNum: e.operationType=='5'?e.operNum:-e.operNum,
            photoIds: e.operationType=='5'?e.photoIds.split(","):[],
            billCode: e.billCode,
            purchasePrice: e.purchasePrice,
            createUser: e.createUser,            
            remark: e.remark
          };
        });
        data.total = data.records.total
        this.querySuccess(data);
      });
    },      
  },
};
</script>
<style>
.inv-detail .demo-form-inline1 {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
}
.inv-detail .el-form--inline .el-form-item {
  margin-bottom: 0;
}
.inv-detail .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}
.inv-detail .ws-page-table .icon-operation a {
  margin-right: 20px;
}
.inv-detail .btn-wrap{ margin:0 20px; padding: 20px 0 30px 18px; border-top: 1px solid #E9ECEF; background: #fff;}
.inv-detail .el-row{display: flex;}
.inv-detail .el-form-item--small{ flex: 1}
.inv-detail .el-tooltip{ padding:10px 0; display: flex; align-items: center;}
</style>