<template>
  <div>
    <div style="display:flex; justify-content:flex-end;">
      <el-button type="primary" @click="addFare">新增</el-button>
    </div>
    <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" @pageChange="loadTableData"
                   :showIndex="true" :isShadow="false">
      <el-table-column prop="transportCode" label="模板编号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="transportDescribe" label="模板描述" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="orderCode" label="编辑" :show-overflow-tooltip="true" width="140">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" @click="editBtn(0, scope.row.transportId)" title="查看">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" @click="editBtn(1, scope.row.transportId)" title="编辑">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <el-popconfirm style="margin-left:30px;" title="确定删除吗？" @confirm="delBtn(scope.row.transportId)">
              <el-button>删除</el-button>
              <a slot="reference" href="javascript: void(0);" title="删除">
                <i class="el-icon-delete-solid"></i>
              </a>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>


    <el-dialog :title="modalTitle" :visible.sync="dialogVisible" width="90%" :lock-scroll="true"
               :close-on-click-modal="false">
      <div style="position:relative;">
        <div v-if="isSee" style="width:100%;height:98%;position:absolute;z-index:3;"></div>
        <div style="width:30%;margin-top:20px;">
          <el-input type="text" placeholder="编号" v-model="modelDetail.transportCode"/>
        </div>
        <div style="width:40%; margin-top:20px;">
          <el-input type="textarea" placeholder="描述" autosize resize="none" v-model="modelDetail.transportDescribe"/>
        </div>

        <div style="margin-top: 20px;">
          <div style="font-size: 22px;">
            停发地区设置
            <i class="el-icon-circle-plus" style="font-size:25px; cursor:pointer;"
               @click="addRangeArea"></i>
          </div>
          <table style="margin-top: 20px;" v-if="outRangeList.length>0">
            <tr style="font-size: 14px;">
              <td width="50px;">编辑</td>
              <td width="100px;">原因</td>
              <td>地区</td>
            </tr>
            <tr v-for="(item,idx) in outRangeList" :key="idx">
              <td>
                <div>
                  <i class="el-icon-edit" style="font-size:20px; cursor:pointer;" @click="outRangeAreaEdit(idx)"></i>
                </div>
              </td>
              <td>
                <div>
                  {{ item.tip }}
                </div>
              </td>
              <td>
                <div style="margin-top:10px;display:flex;flex-wrap:wrap;">
                  <el-tag v-for="(tag,idx) in item.tags" :key="tag" type="info" style="margin:5px;">{{ tag }}
                  </el-tag>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div style="margin-top:20px;">
          <div style="font-size: 22px;">
            发货地区设置
            <i class="el-icon-circle-plus" style="font-size:25px; cursor:pointer;"
               @click="addModel"></i>
          </div>
          <el-table border :data="modelDetail.pimTransportCostList" style="width: 100%" class="d-tab">
            <el-table-column label="地区" minWidth="200px;">
              <!--              <template slot="header" slot-scope="scope">
                              <div>
                                地区
                                <i class="el-icon-circle-plus" style="font-size:25px; cursor:pointer; float: right;"
                                   @click="addModel"></i>
                              </div>
                            </template>-->
              <template slot-scope="scope">
                <!-- <areaCascader /> -->
                <div style="display:flex;justify-content:space-between;"
                     v-if="modelDetail.pimTransportCostList[scope.$index].transportArea[0] != 'default'">
                  <div style="display:flex;flex-wrap:wrap;">
                    <div v-for="(tag,ai) in modelDetail.pimTransportCostList[scope.$index].transportArea" :key="tag">
                      <el-tag closable type="danger" style=" margin:5px;" v-if="tag && tag!='default'"
                              @close="delAddr(scope.$index,ai)">{{ tag }}
                      </el-tag>
                    </div>
                  </div>
                  <div>
                    <i class="el-icon-circle-plus-outline" style="font-size:20px; cursor:pointer;"
                       @click="chooseAddr(scope.$index)"></i>
                  </div>
                </div>
                <div v-else style="display:flex;justify-content:left;">
                  <el-tag type="danger">默认地区</el-tag>
                </div>
              </template>
            </el-table-column>
            <!--
            <el-table-column label="快递公司" width="110">
              <template slot-scope="scope">
                <el-select placeholder="请选择" v-model="
                    modelDetail.pimTransportCostList[scope.$index].shipCompany
                  ">
                  <el-option v-for="item in comOptions" :key="item.value" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </template>
            </el-table-column>
            -->
            <el-table-column width="140">
              <template slot="header">
                <span>最早发货日期</span>
                <el-tooltip content="不填表示默认发货时间，已过去的时间不会生效">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-date-picker
                    style="width: 120px;"
                    size="mini"
                    v-model="modelDetail.pimTransportCostList[scope.$index].earliestShipDate"
                    type="date"
                    placeholder="默认发货">
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="计算方式" width="110">
              <template slot-scope="scope">
                <el-select placeholder="请选择" v-model="
                    modelDetail.pimTransportCostList[scope.$index].costType
                  ">
                  <el-option v-for="item in scaOptions" :key="item.value" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="首" width="110">
              <template slot-scope="scope" v-if="
                  modelDetail.pimTransportCostList[scope.$index].costType !=
                    'out_range'
                ">
                <div style="display:flex; justify-content:center;">
                  <div>首</div>
                  <div style="width:50px;margin:0 5px;">
                    <el-input v-if="modelDetail.pimTransportCostList[scope.$index].costType == 'piece'"
                              v-model="modelDetail.pimTransportCostList[scope.$index].firstPiece"
                              type="text" maxlength="8" onkeyup="value=value.replace(/[^\d]/g,'');"/>
                    <el-input v-else maxlength="8"
                              @keyup.native="keyupEvent($event,modelDetail.pimTransportCostList[scope.$index].firstWeight)"
                              v-model=" modelDetail.pimTransportCostList[scope.$index].firstWeight"
                              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" type="text"/>
                  </div>
                  <div>
                    {{
                      modelDetail.pimTransportCostList[scope.$index].costType ==
                      "piece"
                          ? "件"
                          : "kg"
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="首金额" width="110">
              <template slot-scope="scope" v-if="
                  modelDetail.pimTransportCostList[scope.$index].costType !=
                    'out_range'
                ">
                <div style="display:flex; justify-content:center;">
                  <div style="width:50px;margin:0 5px;">
                    <el-input type="text" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                              @keyup.native="keyupEvent($event,modelDetail.pimTransportCostList[scope.$index].firstPrice)"
                              maxlength="8" v-model="
                        modelDetail.pimTransportCostList[scope.$index]
                          .firstPrice
                      "/>
                  </div>
                  <div>元</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="续" width="110">
              <template slot-scope="scope" v-if="
                  modelDetail.pimTransportCostList[scope.$index].costType !=
                    'out_range'
                ">
                <div style="display:flex; justify-content:center;">
                  <div>续</div>
                  <div style="width:50px;margin:0 5px;">
                    <el-input v-if="modelDetail.pimTransportCostList[scope.$index].costType == 'piece'" v-model="modelDetail.pimTransportCostList[scope.$index].continuePiece
                      " type="text" maxlength="8" onkeyup="value=value.replace(/[^\d]/g,'');"/>
                    <el-input
                        @keyup.native="keyupEvent($event,modelDetail.pimTransportCostList[scope.$index].continuePiece)"
                        type="text" maxlength="8"
                        onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" v-else
                        v-model="modelDetail.pimTransportCostList[scope.$index].continueWeight"/>
                  </div>
                  <div>
                    {{
                      modelDetail.pimTransportCostList[scope.$index].costType ==
                      "piece"
                          ? "件"
                          : "kg"
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="续金额" width="110">
              <template slot-scope="scope" v-if="
                  modelDetail.pimTransportCostList[scope.$index].costType !=
                    'out_range'
                ">
                <div style="display:flex; justify-content:center;">
                  <div style="width:50px;margin:0 5px;">
                    <el-input type="text" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                              @keyup.native="keyupEvent($event,modelDetail.pimTransportCostList[scope.$index].continuePrice)"
                              maxlength="8" v-model="
                        modelDetail.pimTransportCostList[scope.$index]
                          .continuePrice
                      "/>
                  </div>
                  <div>元</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="包邮条件" width="110">
              <template slot-scope="scope" v-if="
                  modelDetail.pimTransportCostList[scope.$index].costType !=
                    'out_range'
                ">
                <div style="display:flex; justify-content:center;">
                  <div style="width:50px;margin:0 5px;">
                    <el-input v-if="modelDetail.pimTransportCostList[scope.$index].costType == 'piece'"
                              v-model=" modelDetail.pimTransportCostList[scope.$index].pieceToFree"
                              type="text" maxlength="8" onkeyup="value=value.replace(/[^\d]/g,'');"/>
                    <el-input v-else maxlength="8"
                              @keyup.native="keyupEvent($event,modelDetail.pimTransportCostList[scope.$index].weightToFree)"
                              type="text"
                              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                              v-model="modelDetail.pimTransportCostList[scope.$index].weightToFree"/>
                  </div>
                  <div>
                    {{
                      modelDetail.pimTransportCostList[scope.$index].costType ==
                      "piece"
                          ? "件"
                          : "kg"
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template slot="header">
                <span>原因<span style="color:red"> * </span></span>
                <el-tooltip content="偏远、不发、指定发货时间地区，该项必填">
                  <i class="icon-tip"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div style="display:flex; justify-content:center;">
                  <div style="width:100%;">
                    <el-input type="textarea" maxlength="100"
                              v-model="modelDetail.pimTransportCostList[scope.$index].tip"/>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="删除" width="50">
              <template slot-scope="scope">
                <div style="display:flex;justify-content:center;align-items:center;"
                     v-if="modelDetail.pimTransportCostList[scope.$index].transportArea[0]!='default'">
                  <el-popconfirm title="确定删除吗？" @confirm="delModelItem(scope.$index)">
                    <i class="el-icon-delete" style="font-size:20px;cursor:pointer;" slot="reference"></i>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button v-if="!isSee" @click="cancel">取 消</el-button>
                <el-button type="primary" @click="commitEdit">确 定</el-button>
            </span>
    </el-dialog>

    <!---->
    <el-dialog title="选中地区" :visible.sync="addrModal" width="50%">
      <div>
        <area-cascader v-model="commitAddr"/>
        <el-button type="primary" @click="addToSelectList">确认选择</el-button>
        <div>
          <el-tag closable type="danger" v-for="(item,i) in selectedArea" :key="i" style=" margin:5px;"
                  @close="delAddrList(i)">{{ item }}
          </el-tag>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="addrModal = false">取 消</el-button>
                <el-button type="primary" @click="commitChooseAddr">确 定</el-button>
            </span>
    </el-dialog>
    <!---->
    <el-dialog title="停发地区设置" :visible.sync="outRangeModal" width="50%">
      <div>
        <div style="margin-top: 10px;font-weight: bold;">
          停发原因
        </div>
        <div style="margin-top: 10px;">
          <el-input type="textarea" maxlength="100" v-model="editTargetTip"/>
        </div>
        <div style="margin-top: 10px;font-weight: bold;">
          停发地区
        </div>
        <div style="margin-top: 10px;">
          <span>方式1：</span>
          <area-cascader v-model="commitAddr"/>
          <el-button type="primary" @click="addToSelectList">确认添加</el-button>
        </div>
        <div style="margin-top: 10px;display: flex;align-items: center;">
          <span>方式2：</span>
          <el-upload action="/" :http-request="importExcel" list-type="text" :show-file-list="false">
            <el-button size="small" type="primary">导入excel数据</el-button>
          </el-upload>
        </div>
        <div style="margin-top: 20px;">
          <el-button type="danger" @click="removeAllSelectList">清空</el-button>
        </div>
        <div style="margin-top: 10px;">
          <el-tag v-for="(tag,idx) in selectedArea" :key="tag" closable type="info" style="margin:5px;"
                  @close="delFromSelectList(idx)">{{ tag }}
          </el-tag>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align: center;">
          <el-button @click="outRangeModal = false">取 消</el-button>
          <el-button type="danger" @click="delOutRangeAreaEdit">删 除</el-button>
          <el-button type="primary" @click="confirmOutRangeAreaEdit">确 定</el-button>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import Objects from "COMJS/Objects"
import XLSX from 'xlsx'
import BimEnumService from "API/bim/BimEnumService";
import {
  fetchFareList,
  fareDetail,
  saveFare,
  delFare,
} from "@/api/pim/pimFareModelService.js";
import areaCascader from "COMPS/base/areaCascader.vue";

export default {
  components: {
    areaCascader,
  },
  data() {
    return {
      tableData: [],
      modalTitle: "新增",
      dialogVisible: false,
      modelDetail: {
        pimTransportCostList: [],
      },
      comOptions: [],
      outRangeList: [],
      scaOptions: [
        {
          label: "计件",
          value: "piece",
        },
        {
          label: "计重",
          value: "weight",
        },
        /*{
          label: "超出配送范围",
          value: "out_range",
        },*/
      ],
      outRangeModal: false,
      editTargetIdx: {},
      editTargetTip: "",
      addrModal: false,
      currentAddr: 0,
      commitAddr: {},
      isSee: true,
      selectedArea: [],
    };
  },
  mounted() {
    this.getCompanyEnum();
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      let StepsData = this.$store.state.ProductStepsData;
      //console.log(StepsData);
      if (StepsData.Back) {
        this.$confirm(`是否返回商品页继续之前操作？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push({
            path: "/pim/AddGoods"
          });
        });
      }
    },
    delAddrList(i) {
      this.selectedArea.splice(i, 1);
    },
    getCompanyEnum() {
      BimEnumService.getEnum("TRANSPORT_COMPANY").then((data) => {
        this.comOptions = data.map((e) => {
          return {
            value: e.value,
            label: e.name,
          };
        });
      });
    },
    async loadTableData() {
      const data = await fetchFareList(this.pageInfo);
      this.tableData = data.list;
      this.querySuccess(data);
    },

    // 模板查看，修改
    async editBtn(i, id) {
      this.modelDetail = await fareDetail(id);
      this.modelDetail.pimTransportCostList.map((item) => {
        item.transportArea = item.transportArea.split(";");
        //console.log(item.transportArea)
        let len = item.transportArea.length;
        if (item.transportArea[len - 1] == "") {
          item.transportArea.pop();
        }
        return item;
      });
      //其中停发地区
      this.outRangeList = []
      let outRangeItems = this.modelDetail.pimTransportCostList.filter(a => a.costType == 'out_range')
      let tempMap = new Map()
      for (let item of outRangeItems) {
        let oneList = []
        if (tempMap.has(item.tip)) {
          oneList = tempMap.get(item.tip)
        }
        for (let one of item.transportArea) {
          oneList.push(one)
        }
        tempMap.set(item.tip, oneList)
      }
      //console.log(tempMap)
      let it = tempMap.entries()
      //console.log(it)
      for (let i = 0; i < tempMap.size; i++) {
        let one = it.next()
        let tip = one.value[0]
        let arr = one.value[1]
        arr = arr.sort(function (str1, str2) {
          return str1.localeCompare(str2, 'zh');
        });
        this.outRangeList.push({'tip': tip, 'tags': arr})
      }
      //console.log(this.outRangeList)

      //其中可发的地区
      this.modelDetail.pimTransportCostList = this.modelDetail.pimTransportCostList.filter(a => a.costType != 'out_range')

      if (i == 0) {
        this.modalTitle = "查看模板";
        this.isSee = true;
      } else if (i == 1) {
        this.modalTitle = "编辑模板";
        this.isSee = false;
      }
      this.dialogVisible = true;
    },
    //
    addModel() {
      this.modelDetail.pimTransportCostList.push({
        transportArea: [],
        costType: "piece",
        firstPiece: 0,
        continuePiece: 0,
        pieceLimit: 0,
        pieceToFree: null,
        firstWeight: null,
        continueWeight: null,
        weightLimit: null,
        weightToFree: null,
        firstPrice: 0,
        continuePrice: 0,
        express: null,
        shipCompany: "",
      });
      this.chooseAddr(this.modelDetail.pimTransportCostList.length - 1)
    },
    // 打开地区选中
    chooseAddr(i) {
      this.currentAddr = i;
      //必须要这样，子组件需要优化
      this.commitAddr = {
        province: "",
        city: "",
        area: "",
      };
      this.selectedArea = [];
      this.addrModal = true;
    },

    addRangeArea() {
      this.outRangeList.push({
        tip: "",
        tags: []
      });
      this.outRangeAreaEdit(this.outRangeList.length - 1)
    },
    outRangeAreaEdit(idx) {
      //必须要这样，子组件需要优化
      this.commitAddr = {
        province: "",
        city: "",
        area: "",
      };
      let item = this.outRangeList[idx]
      this.editTargetIdx = idx
      this.editTargetTip = item.tip
      let newArray = []
      this.selectedArea = Objects.deepCopy(newArray, item.tags);
      this.outRangeModal = true;
    },
    removeAllSelectList() {
      this.selectedArea = []
    },
    delFromSelectList(idx) {
      this.selectedArea.splice(idx, 1);
    },
    addToSelectList() {
      //let province, city, area;
      let item;
      if (this.commitAddr.province) {
        item = this.commitAddr.province;
      }
      if (this.commitAddr.city) {
        item = item + "," + this.commitAddr.city;
      }
      if (this.commitAddr.area) {
        item = item + "," + this.commitAddr.area;
      }
      if (this.selectedArea.filter(a => a === item).length > 0) {
        this.$message({
          message: "该地区已在列表内，无需重复添加",
          type: "warning",
        });
        return
      }
      this.selectedArea.push(item);
      this.selectedArea = this.selectedArea.sort(function (str1, str2) {
        return str1.localeCompare(str2, 'zh');
      });
    },
    importExcel(content) {
      const file = content.file
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split('.')[1]
      const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types)
      if (!fileType) {
        this.$message('格式错误！请重新选择')
        return
      }
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          let xlsxJson = tabJson
          //console.log(xlsxJson)
          let fileList = xlsxJson[0].sheet
          //console.log(fileList)
          if (fileList.length == 0) {
            this.$message('无内容')
            return
          }
          let province = '省级区划'
          let city = '地级市(省直)'
          let county = '县区'
          if (!fileList[0][province] || !fileList[0][city] || !fileList[0][county]) {
            this.$message('停发区域内容格式不正确')
            return
          }
          for (let item of fileList) {
            let one
            if (item[province]) {
              one = item[province]
              if (one.indexOf('市') == one.length - 1) {
                //最后一个字是市，是直辖市，按照系统的规则需要去掉'市'
                one = one.substr(0, one.length - 1)
              }
            } else {
              break
            }
            if (item[city] && item[city] != '全境') {
              one = one + "," + item[city]
            }
            if (item[county] && item[county] != '全境') {
              one = one + "," + item[county]
            }
            if (this.selectedArea.filter(a => a == one).length == 0) {
              this.selectedArea.push(one)
            }
          }
          this.selectedArea = this.selectedArea.sort(function (str1, str2) {
            return str1.localeCompare(str2, 'zh');
          });
        }
      })
    },
    file2Xce(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          const result = []
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
          })
          resolve(result)
        }
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file) // 传统input方法
      })
    },
    confirmOutRangeAreaEdit() {
      if (!this.editTargetTip) {
        this.$message({
          type: "warning",
          message: "停发原因必须填写！",
        });
        return
      }
      this.outRangeList[this.editTargetIdx].tip = this.editTargetTip
      this.outRangeList[this.editTargetIdx].tags = this.selectedArea
      this.outRangeModal = false;
    },
    delOutRangeAreaEdit() {
      this.outRangeList.splice(this.editTargetIdx, 1)
      this.outRangeModal = false;
    },

    delAddr(i, j) {
      this.modelDetail.pimTransportCostList[i].transportArea.splice(j, 1);
      //this.loadTableData();
    },
    //
    commitChooseAddr() {
      if(!this.selectedArea || this.selectedArea.length==0){
        this.$message({
          type: "warning",
          message: "请选择至少一个地区！",
        });
        return;
      }
      let that = this;
      this.selectedArea.forEach((e) => {
        setTimeout(function () {
          let isExist = false;
          that.modelDetail.pimTransportCostList.forEach(
              (z, index) => {
                that.modelDetail.pimTransportCostList[
                    index
                    ].transportArea.forEach((element) => {
                  if (element == e) {
                    that.$message({
                      message:
                          "地区" +
                          e +
                          "在本模板中已有规则，设置失败。请删除其它规则后重试。",
                      type: "warning",
                    });
                    isExist = true;
                    return;
                  }
                });
              }
          );
          if (!isExist) {
            that.modelDetail.pimTransportCostList[
                that.currentAddr
                ].transportArea.push(e);
            // this.modelDetail.pimTransportCostList[this.currentAddr].transportArea +=
            //   province + "," + city + ";";
            that.addrModal = false;
            that.selectedArea = [];
            that.commitAddr = {};
          }
        }, 100);
      });
      //this.addrModal = false;
      //this.selectedArea = [];
      //this.commitAddr = {};
    },
    delModelItem(i) {
      this.modelDetail.pimTransportCostList.splice(i, 1);
    },
    commitEdit() {
      if (this.isSee) {
        this.dialogVisible = false;
        return;
      }
      let modal = {}
      Objects.deepCopy(modal, this.modelDetail);
      // if(modal.pimTransportCostList.findIndex(target=>target.tip === "")==-1){
      this.dialogVisible = false;
      // }else{
      //   this.$message({
      //   type: "warning",
      //   message: "原因未填写完整",
      //   });
      //   return
      // }

      let newCostList = [];
      //50个一组,防止字段太长。数据库2048个字节，应该能放下。
      for (let cost of modal.pimTransportCostList) {
        let result = [];
        for (let i = 0; i < cost.transportArea.length; i += 50) {
          result.push(cost.transportArea.slice(i, i + 50));
        }
        if(result.length>0){
          cost.transportArea = result[0].join(";") + ";";
          newCostList.push(cost)
          for (let i = 1; i < result.length; i += 1) {
            let newCost = {}
            Objects.deepCopy(newCost, cost);
            newCost.transportCostId = null
            newCost.transportArea = result[i].join(";") + ";";
            newCostList.push(newCost)
          }
        }
      }
      for (let outRange of this.outRangeList) {
        let result = [];
        for (let i = 0; i < outRange.tags.length; i += 50) {
          result.push(outRange.tags.slice(i, i + 50));
        }
        //console.log(result)
        for (let i = 0; i < result.length; i += 1) {
          let newCost = {}
          newCost.transportCostId = null
          newCost.transportArea = result[i].join(";") + ";";
          newCost.costType = "out_range";
          newCost.tip = outRange.tip;
          newCostList.push(newCost)
        }
      }
      if (newCostList.length > 0) {
        modal.pimTransportCostList = newCostList
      }
      //console.log(modal)
      saveFare(modal).then(data=>{
        this.$message({
          type: "success",
          message: "保存成功！",
        });
      })

      this.loadTableData();

      //如果是从商品页来的，回去
      let StepsData = this.$store.state.ProductStepsData;
      //console.log(StepsData);
      if (StepsData.Back) {
        this.$confirm(`是否返回商品页继续之前操作？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push({
            path: "/pim/AddGoods"
          });
        });
      }
    },
    //删除模板
    async delBtn(id) {
      await delFare(id);
      this.loadTableData();
    },
    addFare() {
      this.modelDetail = {
        pimTransportCostList: [
          {
            transportArea: ["default"],
            tip: "",
          },
        ],
      };
      this.dialogVisible = true;
      this.isSee = false;
    },
    clearNoNum(obj) {
      obj.value = obj.value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      obj.value = obj.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      obj.value = obj.value
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
      obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (obj.value.indexOf(".") < 0 && obj.value != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        obj.value = parseFloat(obj.value);
      }
    },
    handleInput2(e) {
      // 通过正则过滤小数点后两位
      e.target.value =
          e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
          /^\d*\.\d*\./g,
          e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(
          /^(\d+)\.(\d\d).*$/,
          "$1.$2"
      );
      this.input = e.target.value;
      //console.log(this.input);
    },
  },
  mixins: [tableMixins],
};
</script>

<style scoped>
.d-tab div {
  white-space: nowrap;
}

.icon-tip {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-image: url("~ASSETS/images/tip.png");
  background-size: cover;
}
</style>
