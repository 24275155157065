<template>
  <div class="whm-supedit">
    <div class="step-title">新增供应商</div>
    <el-form
      :model="whmInfo"
      label-width="140px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item required label="供应商名称:">
        <el-input v-model="whmInfo.supplierName"></el-input>
      </el-form-item>
      <el-form-item required label="地址:">
        <el-input type="textarea" v-model="whmInfo.address"></el-input>
      </el-form-item>                
      <el-form-item required label="联系人:">
        <el-input v-model="whmInfo.contactName"></el-input>
      </el-form-item>
      <el-form-item required label="联系人电话:">
        <el-input type="number" oninput="if(value.length>12)value=value.slice(0,12)" v-model="whmInfo.mobile"></el-input>
      </el-form-item>
      <el-form-item label="收款账号信息:" class="mt55">        
      </el-form-item>
      <el-form-item label="账户名:">
        <el-input v-model="whmInfo.accountName"></el-input>
      </el-form-item>
      <el-form-item label="银行账号:">
        <el-input type="number" oninput="if(value.length>22)value=value.slice(0,22)" class="large-input" v-model="whmInfo.accountBank"></el-input>
      </el-form-item>
      <el-form-item label="开户行:">
        <el-input v-model="whmInfo.openingBank"></el-input>
      </el-form-item>
      <el-form-item label="税号:">
        <el-input v-model="whmInfo.einNumber"></el-input>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model="whmInfo.remark"></el-input>
      </el-form-item>
      <el-form-item label="是否启用:">
        <el-switch v-model="whmInfo.enabled"></el-switch>
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">取消</el-button>
      <el-button type="primary" @click="createWhm">保存供应商</el-button>
    </div>

  </div>
</template>

<script>
import userModule from "@/module/userModule";
import WhmList from "API/whm/WhmList";

export default {
  name: "whmSupEdit",
  data() {
    return {
      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        supplierName: "", //供应商名称
        address: "", //地址
        contactName: "", //联系人
        mobile: "", //联系人电话
        accountName: "", //账户名
        accountBank: "", //银行账号
        openingBank: "", //开户行
        einNumber: "", //税号
        remark: "", //备注
        enabled: true,// 是否启用1是启用0是禁用
      },
    };
  },
  components: {},
  mounted() {
    if(this.$route.params.id){
      this.getGoodsDetail()
    }
  },
  computed: {},
  methods: {
    backPre(){//返回上一页
      this.$router.push("/whm/whmSupplier");
    },
    createWhm() {
      const that = this
      let whmInfo = this.whmInfo      
      if (!whmInfo.supplierName) {
        return this.$message({
          message: "请添加供应商名称",
          type: "warning",
        });
      }    
      if (!whmInfo.address) {
        return this.$message({
          message: "请填写地址",
          type: "warning",
        });
      } 
      if (!whmInfo.contactName) {
        return this.$message({
          message: "请添加联系人",
          type: "warning",
        });
      } 
      if (!whmInfo.mobile) {
        return this.$message({
          message: "请添加联系人电话",
          type: "warning",
        });
      }          
      if(this.$route.params.id){
        whmInfo.supplierId = this.$route.params.id
      }
      //console.log(whmInfo.enabled)      
      whmInfo.enabled = whmInfo.enabled == true ? 1 : 0 //切换状态位数字 1开启 0关闭
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      WhmList.saveSupplier(whmInfo)
        .then((res) => {          
          if(this.$route.params.id){
            if(res.msg == "OK"){
              this.$message({
                  message: '修改成功',
                  type: 'success'
              });
            }
            setTimeout(function() {
              that.$router.push("/whm/whmSupplier");
            }, 300);
          }else{
              if(res.msg == "OK"){
              this.$message({
                  message: '新增成功',
                  type: 'success'
              });
            }
            setTimeout(function() {
              that.$router.push("/whm/whmSupplier");
            }, 300);
          }
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },    
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailSupplier(this.$route.params.id).then((data) => {
        let whmInfo = this.whmInfo
        whmInfo.supplierName = data.supplierName, //供应商名称
        whmInfo.address = data.address, //地址
        whmInfo.contactName = data.contactName, //联系人
        whmInfo.mobile = data.mobile, //联系人电话
        whmInfo.accountName = data.accountName, //账户名
        whmInfo.accountBank = data.accountBank, //银行账号
        whmInfo.openingBank = data.openingBank, //开户行
        whmInfo.einNumber = data.einNumber, //税号
        whmInfo.remark = data.remark, //备注
        whmInfo.enabled = data.enabled == 1 ? true : false
        // whmInfo.skuCode = data.skuCode //库品编码
        // whmInfo.skuName = data.skuName // 库品名称      
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss">
.whm-supedit{ padding:20px 0 60px 20px; background: #fff;}
.whm-supedit .mt55{ margin-top: 55px;}
.whm-supedit .step-title {
  padding: 0 0 40px 6px;
  font-size: 18px;
  color: #333;
}
.whm-supedit .select-btn {
  width: 200px;  
  text-align: center;  
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}
.whm-supedit .steps-btn-wrapper{ margin-top: 80px; display: flex; justify-content: center;}
.whm-supedit .btn-next-step{ margin:20px auto 0 auto; width: 76px; height: 32px; line-height: 32px; text-align: center; color: #fff; background: #1B73E8; border-radius: 2px; font-size: 12px;}
.whm-supedit .large-input .el-input__inner{ width: 200px;}
.whm-supedit .steps-btn-wrapper button{ width: auto;}
.whm-supedit .el-form-item .el-form-item__content > .el-input,
.whm-supedit .el-input--small .el-input__inner {
  width: 150px;
}
.whm-supedit .el-form .el-textarea.el-input--small .el-textarea__inner{ width: 360px; height: 80px}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {-webkit-appearance: none !important;}
input[type="number"]{-moz-appearance: textfield;}
</style>