<template>
  <div id="ProjectList">
    <div class="ProjectList-left">
      <div class="header" v-if="!seeAbandon">
        <el-popover placement="bottom" width="300" v-model="visible" trigger="manual">
          <div>
            <el-input placeholder="采购单标题" v-model="editPurData.contractTitle" style="margin:10px 0px;"></el-input>
            <el-input placeholder="采购单合同号" v-model="editPurData.contractNo" style="margin:10px 0px;"></el-input>
            <el-select placeholder="采购单类型" v-model="editPurData.contractType" style="margin:10px 0px;width: 100%;">
              <el-option
                  v-for="item in contractTypeArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="AddProject">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="savePurContract(1)">确认</el-button>
          </div>
          <el-button @click="showCreateDialog"
                     type="primary" slot="reference">新增采购单
          </el-button>
        </el-popover>
        <i style="font-size:25px;margin-left:26px;" class="el-icon-warning-outline"></i>
        <p style="font-size:14px;margin-left:10px;color:rgba(102, 102, 102, 1);width:350px;line-height:20px">
          当前页面共{{ ProjectList.length }}个采购单
        </p>
        <el-button type="text" @click="switchState"
                   style="margin-left:auto;">查看已废弃
        </el-button>
      </div>
      <div class="header" v-else>
        <el-button type="text" @click="switchState" icon="el-icon-back">返回</el-button>
        <p style="width: 100%;text-align: center;">已废弃采购单</p>
      </div>
      <div style="display: flex;margin-top: 20px;width: 100%;">
        <el-tabs style="width: 10%;"
                 v-if="!seeAbandon"
                 tab-position="left"
                 v-model="searchInfo.transportStates"
                 @tab-click="loadTableData">
          <el-tab-pane label="在途" name="1">
            <span slot="label">
              <el-badge class="mark" :value="ProjectDataCount['1']"/>
              在途
            </span>
          </el-tab-pane>
          <el-tab-pane label="到港" name="2,8">
            <span slot="label">
              <el-badge class="mark" :value="addProjectDataCount(ProjectDataCount['2'],ProjectDataCount['8'])"/>
              到港
            </span>
          </el-tab-pane>
          <el-tab-pane label="报关" name="3">
            <span slot="label">
              <el-badge class="mark" :value="ProjectDataCount['3']"/>
              报关
            </span>
          </el-tab-pane>
          <el-tab-pane label="归档" name="9"></el-tab-pane>
        </el-tabs>
        <div style="width: 90%;">
          <el-tabs v-model="searchInfo.contractType"
                   type="card" @tab-click="loadTableData">
            <el-tab-pane :label="tabName4Milk" name="milk"></el-tab-pane>
            <el-tab-pane :label="tabName4Powder" name="powder"></el-tab-pane>
            <el-tab-pane :label="tabName4Slice" name="slice"></el-tab-pane>
          </el-tabs>
          <!--采购单列表-->
          <div class="Project" v-for="(item, index) in ProjectList" :key="index">
            <div class="Project-item-header">
              <div>{{ item.contractTitle }}</div>
              <el-button type="primary"
                         v-if="!seeAbandon && item.transportState=='1'"
                         size="mini" style="margin-left:10px"
                         @click="showEditDialog(index,false)">
                编辑
              </el-button>
              <el-button type="primary"
                         v-if="item.transportState!='1'"
                         size="mini" style="margin-left:10px"
                         @click="showEditDialog(index,true)">
                查看
              </el-button>
              <el-button type="primary"
                         v-if="!seeAbandon && item.transportState=='1'"
                         size="mini" style="margin-left:10px"
                         @click="contractNextStep(index,2)">
                到港
              </el-button>
              <el-button type="primary"
                         v-if="!seeAbandon && (item.transportState=='2' || item.transportState=='8')"
                         size="mini" style="margin-left:10px"
                         @click="contractNextStep(index,3)">
                报关
              </el-button>
              <el-button type="text"
                         v-if="!seeAbandon && item.transportState=='2'"
                         size="mini" style="margin-left:10px"
                         @click="contractNextStep(index,8)">
                一线入区
              </el-button>
              <p v-if="!seeAbandon && item.transportState=='8'"
                 style="font-size: 12px;color: #99a9bf;margin-left:10px">已一线入区</p>
              <el-button type="primary"
                         v-if="!seeAbandon && item.transportState=='3'"
                         size="mini" style="margin-left:10px"
                         @click="contractNextStep(index,9)">
                归档
              </el-button>
              <el-button type="text"
                         style="margin-left: auto;"
                         @click="shipDetailClick(index)">
                {{ item.shipperName || '未选择承运方' }}
                {{ (item.departPortTime || '未') + '离港' }}
              </el-button>
            </div>
            <div class="Project-item">
              <div class="Project-slider"
                   v-for="(items, sonIndex) in item.childrenList" :key="sonIndex">
                <div class="slider-header">
                  <p>{{ items.title }}</p>
                  <el-tag :type="items.status == '已上传' ? 'success' : 'info'" size="mini">
                    {{ items.status }}
                  </el-tag>
                  <i style="cursor: pointer;"
                     v-if="!seeAbandon && searchInfo.transportStates!='9'"
                     @click="openTaskDetails(item.contractId, items.title,items.fileList)"
                     class="el-icon-edit-outline slider-edit"></i>
                </div>
                <div class="slider-operation">
                  <div v-for="(file, idx) in items.fileList" :key="sonIndex+'file'+idx">
                    <el-image :src="file.fileImg" class="slider-operation-img" fit="cover"/>
                    <div class="show-operate" @click="showOrDownloadFile(file.fileUrl,file.fileType)">
                      <i v-if="file.fileType=='img' || file.fileType=='pdf'"
                         class="el-icon-view"></i>
                      <i v-else class="el-icon-download"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--右侧日志-->
    <div class="ProjectList-right">
      <div class="ProjectList-right-title">动态日志</div>
      <div class="scroll">
        <div class="queryTaskLog" v-for="(item, index) in TaskLogList" :key="index">
          <div class="queryTaskLog-top">
            <div class="queryTaskLog-user">
              {{ item.operatorName }} {{ item.action }}
            </div>
            <div class="queryTaskLog-time">{{ item.operateTime }}</div>
          </div>
          <div class="queryTaskLog-bottom-bottom">
            <div>合同号：{{ item.contractNo }}</div>
          </div>
          <div class="queryTaskLog-bottom" v-if="item.fileFlag">
            <div class="queryTaskLog-line"></div>
            <div class="queryTaskLog-acitivty-file">
              <div class="fileList-log" v-for="(items, sonIndex) in item.fileUrlList" :key="sonIndex">
                <div class="fileList-left">
                  <div class="fileList-img">
                    <img :src="items.fileImg" class="img-cover"/>
                  </div>
                  <div class="fileList-title">
                    <div>{{ items.fileName }}</div>
                    <!--div>{{ items.fileSize }}</div-->
                  </div>
                </div>
                <div class="fileList-operation">
                  <i v-if="items.fileType=='img' || items.fileType=='pdf'"
                     class="el-icon-view"
                     style="cursor: pointer;"
                     @click="showOrDownloadFile(items.fileUrl,items.fileType)"></i>
                  <i v-else
                     class="el-icon-download"
                     style="cursor: pointer;"
                     @click="showOrDownloadFile(items.fileUrl,items.fileType)"></i>
                </div>
              </div>
            </div>
            <!--div v-if="!item.fileFlag" class="queryTaskLog-acitivty-null">
              {{ item.content }}
            </div-->
          </div>
        </div>
      </div>
    </div>
    <!--上传文件-->
    <el-dialog :visible.sync="fileUploadDialogVisible"
               v-loading="popLoading"
               :title="fileUploadData.title+'上传'" width="50vw">
      <div>
        <div class="fileList-list" v-for="(item, idx) in fileUploadData.fileList" :key="idx">
          <div class="fileList-left">
            <div class="fileList-img">
              <img :src="item.fileImg" class="img-cover"/>
            </div>
            <div class="fileList-title">
              <div v-if="idx!=modifyFileNameIdx">{{ item.fileName }}</div>
              <div v-else>
                <el-input v-model="item.fileNameWithOutSuffix"
                          oninput="value=value.replace('#','')"
                          style="width: 400px;">
                  <template slot="append">. {{ item.fileSuffix }}</template>
                </el-input>
              </div>
            </div>
          </div>
          <div v-if="idx!=modifyFileNameIdx" class="fileList-operation">
            <a @click="modifyFileNameOnList(idx)" title="改名">
              <i class="el-icon-edit"></i>
            </a>
            <a v-if="item.fileType=='img' || item.fileType=='pdf'"
               @click="showOrDownloadFile(item.fileUrl,item.fileType)" title="预览">
              <i class="el-icon-view"></i>
            </a>
            <a v-else
               @click="showOrDownloadFile(item.fileUrl,item.fileType)" title="下载">
              <i class="el-icon-download"></i>
            </a>
            <a @click="deleteFileFromList(idx)" title="删除">
              <i class="el-icon-delete"></i>
            </a>
          </div>
          <div v-else class="fileList-operation">
            <a @click="modifyFileNameConfirm(idx,1)" title="确认">
              <i class="el-icon-check"></i>
            </a>
            <a @click="modifyFileNameConfirm(idx,2)" title="取消">
              <i class="el-icon-close"></i>
            </a>
          </div>
        </div>
        <el-upload
            v-if="allowUpload"
            :action="fileUploadUrl"
            :show-file-list="false"
            :limit="9"
            drag
            :disabled="(fileUploading || modifyFileNameIdx!=-1)"
            :before-upload="handleBeforeUpload"
            :on-error="handleUploadError"
            :on-success="handleUploadSuccess">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__text" style="margin-top: 10px;">最多上传{{ allowUploadNum }}个</div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
            :disabled="(fileUploading || modifyFileNameIdx!=-1)"
            @click="fileUploadDialogVisible = false">取消</el-button>
        <el-button
            :disabled="(fileUploading || modifyFileNameIdx!=-1)"
            type="primary" @click="fileUploadDialogConfirm">保存</el-button>
      </span>
    </el-dialog>

    <!--编辑标题和状态-->
    <el-dialog :visible.sync="dialogVisible" title="编辑" width="50vw">
      <div class="edit-item-content" v-if="editDialogStep==0">
        <div class="item-box-long" @click="editDialogStep=1">
          基本信息<i class="el-icon-arrow-right"></i></div>
        <div class="item-box-long" @click="editDialogStep=2">
          货品信息<i class="el-icon-arrow-right"></i></div>
        <div class="item-box-long" @click="editDialogStep=3">
          运输信息<i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="edit-back-bar" v-if="editDialogStep!=0">
        <el-button
            icon="el-icon-arrow-left"
            size="mini" type="text"
            @click="editDialogStep=0">返回
        </el-button>
      </div>
      <div class="content" v-if="editDialogStep==1">
        <div class="label-item">
          <div class="header-left">
            <i class="el-icon-s-flag"></i>
            <p>标题</p>
            <el-input style="width:70%" v-model="editPurData.contractTitle" :disabled="dialogShowOnly"></el-input>
          </div>
        </div>
        <div class="label-item">
          <div class="header-left">
            <i class="el-icon-s-check"></i>
            <p>合同</p>
            <el-input style="width:70%" v-model="editPurData.contractNo"
                      disabled
                      placeholder="请输入合同号"></el-input>
          </div>
        </div>
        <div class="label-item">
          <div class="header-left">
            <i class="el-icon-s-claim"></i>
            <p>状态</p>
            <el-switch v-model="editPurData.projectState"
                       :disabled="dialogShowOnly"
                       active-text="开启" inactive-text="关闭">
            </el-switch>
            <div style="color: red;margin-left: 10px;" v-show="editPurData.projectState==false">* 将本采购单移入关闭列表</div>
          </div>
        </div>
      </div>
      <div class="content" v-if="editDialogStep==2">
        <div style="width: 100%;">
          <div style="display: flex;">
            <p style="line-height: 36px;">
              共计{{ currentContractGoodsList.length }}个产品
            </p>
            <el-button
                style="margin-left: auto;"
                :disabled="dialogShowOnly"
                type="text" icon="el-icon-plus" @click="editThisGoods">
              添加货品
            </el-button>
          </div>
          <el-descriptions style="margin-top: 10px;"
                           size="mini" border
                           :labelStyle="labelStyle"
                           :contentStyle="contentStyle"
                           v-for="(item, index) in currentContractGoodsList" :key="index">
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-shopping-bag-2"></i>
                产品
              </template>
              {{ item.goodsName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-price-tag"></i>
                规格
              </template>
              {{ item.goodsSpec }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-tickets"></i>
                批次
              </template>
              {{ item.goodsBatch }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-box"></i>
                箱数
              </template>
              {{ chargeFormat.thousandsOfInt(item.goodsNum) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-reading"></i>
                数量 & 单位
              </template>
              {{ chargeFormat.thousandsOfInt(item.unitNum) }} {{ item.goodsUnit }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                厂商
              </template>
              {{ item.vendor }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                产期
              </template>
              {{ tools.formatTime(item.productionTime) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                效期
              </template>
              {{ tools.formatTime(item.validityTime) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-edit"></i>
                操作
              </template>
              <div class="descriptions-operate" v-if="!dialogShowOnly">
                <a @click="editThisGoods(item)" title="编辑">
                  <i class="el-icon-edit-outline"></i>
                </a>
                <a @click="copyThisGoods(item)" title="复制">
                  <i class="el-icon-document-copy"></i>
                </a>
                <a @click="deleteThisGoods(item)" title="删除">
                  <i class="el-icon-delete"></i>
                </a>
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <!--新增或编辑-->
          <div style="width: 100%;" v-if="showGoodsEditForm">
            <el-divider><i class="el-icon-folder-add"></i></el-divider>
            <el-descriptions style="margin-top: 10px;"
                             direction="vertical"
                             :title="showGoodsEditFormTitle"
                             :column="3" size="mini" border>
              <template slot="extra">
                <el-button size="mini" @click="cancelSavePurContract42">取消</el-button>
                <el-button type="primary" size="mini" @click="savePurContract42">保存</el-button>
              </template>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-shopping-bag-2"></i>
                  产品
                </template>
                <el-input v-model="editContractGoodsData.goodsName"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-price-tag"></i>
                  规格
                </template>
                <el-input v-model="editContractGoodsData.goodsSpec"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-box"></i>
                  箱数
                </template>
                <el-input v-model="editContractGoodsData.goodsNum"
                          oninput="value=value.replace(/[^0-9]/g,'')"
                          maxLength="10"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-reading"></i>
                  单位
                </template>
                <!--el-input v-model="editContractGoodsData.goodsUnit"></el-input-->
                <el-select placeholder="请选择" v-model="editContractGoodsData.goodsUnit"
                           style="width: 100%;">
                  <el-option
                      v-for="item in unitList"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-files"></i>
                  单位数
                </template>
                <el-input v-model="editContractGoodsData.unitNum"
                          oninput="value=value.replace(/[^0-9]/g,'')"
                          maxLength="10"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  批次
                </template>
                <el-input v-model="editContractGoodsData.goodsBatch"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-date"></i>
                  产期
                </template>
                <el-date-picker
                    v-model="editContractGoodsData.productionTime"
                    style="width: 100%;"
                    type="date">
                </el-date-picker>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-date"></i>
                  效期
                </template>
                <el-date-picker
                    v-model="editContractGoodsData.validityTime"
                    style="width: 100%;"
                    type="date">
                </el-date-picker>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  厂商
                </template>
                <el-input v-model="editContractGoodsData.vendor"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
      <div class="content" v-if="editDialogStep==3">
        <el-form :inline="true" :model="editPurData"
                 label-position="right" label-width="100px">
          <el-form-item label="承运方">
            <el-select v-model="editPurData.shipperId"
                       :disabled="dialogShowOnly"
                       placeholder="请选择" clearable
                       style="width: 220px;">
              <el-option-group
                  v-for="group in shipperList"
                  :key="group.label"
                  :label="group.label">
                <el-option
                    v-for="item in group.options"
                    :key="item.shipperId"
                    :label="item.shipperName"
                    :value="item.shipperId">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="提单号">
            <el-input v-model="editPurData.billLadingNo"
                      style="width: 220px;"
                      :disabled="dialogShowOnly"></el-input>
          </el-form-item>
          <el-form-item label="离港时间">
            <el-date-picker
                v-model="editPurData.departPortTimeDate"
                :disabled="dialogShowOnly"
                style="width: 220px;"
                type="date">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预计到港时间">
            <el-date-picker
                v-model="editPurData.expectPortTimeDate"
                :disabled="dialogShowOnly"
                style="width: 220px;"
                type="date">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" v-if="editDialogStep==1 || editDialogStep==3">
        <el-button @click="hideEditDialog">取消</el-button>
        <el-button type="primary" @click="savePurContract(editDialogStep)">保存</el-button>
      </span>
    </el-dialog>
    <!--状态流转-->
    <el-dialog :visible.sync="stepDialogVisible"
               :title="stepDialogStp==2?'到港':stepDialogStp==8?'一线入区':stepDialogStp==3?'报关':stepDialogStp==9?'归档':''"
               width="40vw">
      <div class="stp-content">
        <div v-if="stepDialogStp==2">
          <p>操作"到港"后，将关闭采购单的编辑功能，基本、货品、运输信息不可修改，请仔细检查。</p>
          <el-date-picker v-model="stepContractData.arrivalPortTime"
                          style="margin-top: 15px;"
                          :disabled="stepDialogDisabled"
                          placeholder="请补充实际到港时间"
                          type="date">
          </el-date-picker>
        </div>
        <div v-if="stepDialogStp==8">
          <p>操作一线入区后，系统将不再每日同步到港通知，后续可操作报关。</p>
        </div>
        <div v-if="stepDialogStp==3">
          <p>操作报关后，可上传报关单和卫检单。</p>
        </div>
        <div v-if="stepDialogStp==9">
          <p>操作归档后，将关闭采购单的编辑和上传文件功能，归档记录。</p>
        </div>
        <div v-if="stepDialogDisabled" style="color: #be0000;margin-top: 20px;">
          {{ stepDialogDisabledMsg }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"
                   :disabled="stepDialogDisabled"
                   @click="contractNextStepConfirm(stepDialogStp)">确认</el-button>
      </span>
    </el-dialog>
    <pdf-preview ref="pdfPreview"/>
    <img-preview ref="imgPreview"/>
    <ship-trail-pop ref="shipTrailPop"/>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import chargeFormat from "COMJS/chargeFormat";

const moment = require("moment");
import axiosHttp from "API/axiosHttp";
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import pdfPreview from "COMPS/preview/pdfPreview.vue"
import imgPreview from "COMPS/preview/imgPreview.vue"
import shipTrailPop from "./ShipTrailPop.vue";
import IoProgressService from "API/IoProgressService";
import purContractService from "API/pur/PurContractService";

export default {
  data() {
    return {
      //采购单
      editPurData: {},
      //常量
      contractTypeArray: [{label: '牛奶', value: 'milk'}, {label: '奶粉', value: 'powder'}, {label: '奶片', value: 'slice'}],
      //查询
      searchInfo: {contractType: 'milk', transportStates: '1'},
      // 新增和编辑弹窗visible
      dialogVisible: false,
      dialogShowOnly: false,
      editDialogStep: 0,
      visible: false,
      //项目列表
      ProjectList: [],
      ProjectDataCount: {},
      ProjectDic: {},
      TaskLogList: [],
      //是否查看废弃采购单
      seeAbandon: false,
      //上传文件dialog
      fileUploadDialogVisible: false,
      fileUploadData: {},
      modifyFileNameIdx: -1,
      fileUploading: false,
      popLoading: false,
      //编辑运输信息
      shipperList: [],
      shipperDic: {},
      //编辑货品信息
      currentContractGoodsList: [],
      editContractGoodsData: {},
      showGoodsEditForm: false,
      showGoodsEditFormTitle: '新增货品',
      unitList: ['瓶', '袋', '粒', '盒', '罐', '桶'],
      //状态流转
      stepContractData: {},
      stepDialogVisible: false,
      stepDialogStp: 2,
      stepDialogDisabled: false,
      stepDialogDisabledMsg: '',
      //上传文件
      requestHeaders: {
        Authorization: userModule.token,
      },
      fileData: {
        module: "pm_task_file",
      },
      //样式
      //content样式
      contentStyle: {'width': '200px'},
      //label样式
      labelStyle: {'width': '120px'}
    };
  },
  computed: {
    chargeFormat() {
      return chargeFormat;
    },
    fileUploadUrl() {
      //保持原文件名keepOriginalFileName=1
      let url = `${axiosHttp.baseURL}/ioProgress/upload`
          + "?keepOriginalFileName=1&module=" + this.fileData.module;
      return url;
    },
    tabName4Milk() {
      if (this.searchInfo.transportStates == '1'
          || this.searchInfo.transportStates == '3') {
        return '牛奶(' + (this.ProjectDataCount[this.searchInfo.transportStates + 'milk'] || '0') + ')'
      }else if(this.searchInfo.transportStates == '2,8'){
        return '牛奶(' + this.addProjectDataCount(this.ProjectDataCount['2milk'],this.ProjectDataCount['8milk']) + ')'
      } else {
        return '牛奶'
      }
    },
    tabName4Powder() {
      if (this.searchInfo.transportStates == '1'
          || this.searchInfo.transportStates == '3') {
        return '奶粉(' + (this.ProjectDataCount[this.searchInfo.transportStates + 'powder'] || '0') + ')'
      }else if(this.searchInfo.transportStates == '2,8'){
        return '奶粉(' + this.addProjectDataCount(this.ProjectDataCount['2powder'],this.ProjectDataCount['8powder']) + ')'
      } else {
        return '奶粉'
      }
    },
    tabName4Slice() {
      if (this.searchInfo.transportStates == '1'
          || this.searchInfo.transportStates == '3') {
        return '奶片(' + (this.ProjectDataCount[this.searchInfo.transportStates + 'slice'] || '0') + ')'
      }else if(this.searchInfo.transportStates == '2,8'){
        return '奶片(' + this.addProjectDataCount(this.ProjectDataCount['2slice'],this.ProjectDataCount['8slice']) + ')'
      } else {
        return '奶片'
      }
    },
    tools() {
      return tools
    },
    allowUpload() {
      let allowUploadFlag = false
      if (this.fileUploadData.fileList) {
        allowUploadFlag = this.fileUploadData.fileList.length < 5;
        if (this.fileUploadData.title === "检测报告") {
          allowUploadFlag = this.fileUploadData.fileList.length < 10;
        } else if (this.fileUploadData.title === "其他单据") {
          allowUploadFlag = this.fileUploadData.fileList.length < 50;
        }
      }
      return allowUploadFlag;
    },
    allowUploadNum() {
      let num = 5;
      if (this.fileUploadData.title === "检测报告") {
        num = 10;
      } else if (this.fileUploadData.title === "其他单据") {
        num = 50;
      }
      return num;
    }
  },
  mounted() {
    this.loadTableData();
    this.loadDataCount();
    this.getShipperData();
  },
  components: {
    pdfPreview,
    imgPreview,
    draggableUpload,
    shipTrailPop,
  },
  watch: {},
  methods: {
    loadTableData() {
      let param = {
        ...this.searchInfo,
        transportStateList: this.searchInfo.transportStates.split(","),
        isDetails: '1',
      };
      purContractService.listByParam(param).then((data) => {
        this.getProjectList(data);
        this.getProjectLog(data);
      });
    },
    loadDataCount() {
      purContractService.countByTransportState().then(res => {
        this.ProjectDataCount = res;
      })
    },
    loadGoodsDataByContractId(contractId) {
      purContractService.listGoodsByContractId(contractId).then(res => {
        this.currentContractGoodsList = res;
      })
    },
    contractNextStep(index, stp) {
      const temp = this.ProjectList[index];
      this.stepDialogVisible = true;
      this.stepDialogStp = stp;
      this.stepContractData = {
        contractId: temp.contractId
      }
      this.stepDialogDisabled = false;
      this.stepDialogDisabledMsg = '';
      if (stp == 2) {
        //到港，一定要有运输信息啊
        if (!temp.shipperId || !temp.billLadingNo || !temp.departPortTime) {
          this.stepDialogDisabled = true;
          this.stepDialogDisabledMsg = "运输信息不完善，不能操作到港"
        }
      } else if (stp == 3) {
        //报关
        if (!temp.documentComplete || temp.documentComplete != '1') {
          this.stepDialogDisabled = true;
          this.stepDialogDisabledMsg = "单据不完整，不能操作报关"
        }
      } else if (stp == 9) {
        //归档
        if (!temp.details || !temp.details.customsDeclarationUrl || !temp.details.sanitaryCheckUrl) {
          this.stepDialogDisabled = true;
          this.stepDialogDisabledMsg = "报关单、卫检单不完整，不能操作归档"
        }
      } else if (stp == 8) {
        //一线入区，百无禁忌
      }
    },
    contractNextStepConfirm(stp) {
      if (stp == 2) {
        //补充到港时间
        if (!this.stepContractData.arrivalPortTime) {
          return this.$message.warning("请补充到港时间");
        }
      }
      let param = {
        ...this.stepContractData,
        transportState: stp
      }
      purContractService.savePurContract(param).then((data) => {
        this.$message.success("保存成功");
        this.loadTableData();
        this.loadDataCount();
        this.stepDialogVisible = false;
      });
    },
    savePurContract(step) {
      if (this.dialogShowOnly) {
        this.dialogVisible = false;
        return;
      }
      if (step == 1) {
        this.savePurContract41();
      } else if (step == 2) {
        this.savePurContract42();
      } else if (step == 3) {
        this.savePurContract43();
      }
    },
    savePurContract41() {
      if (!this.editPurData.contractTitle) {
        return this.$message.warning("标题不能为空");
      }
      if (!this.editPurData.contractNo) {
        return this.$message.warning("合同号不能为空");
      }
      if (!this.editPurData.contractType) {
        return this.$message.warning("类型不能为空");
      }
      //废弃
      if (!this.editPurData.projectState) {
        this.editPurData.transportState = "4";
      }
      let param = {
        ...this.editPurData
      }
      let createdFlag = !param.contractId;
      purContractService.savePurContract(param).then((data) => {
        this.$message.success("保存成功");
        this.loadTableData();
        if (createdFlag) {
          this.loadDataCount();
        }
        this.dialogVisible = false;
        this.visible = false;
      });
    },
    editThisGoods(item) {
      this.showGoodsEditFormTitle = '编辑货品';
      if (!item) {
        //是新增
        item = {}
        this.showGoodsEditFormTitle = '新增货品';
      }
      this.editContractGoodsData = {
        ...item
      };
      this.showGoodsEditForm = true;
    },
    copyThisGoods(item) {
      this.showGoodsEditFormTitle = '复制货品';
      this.editContractGoodsData = {
        ...item,
        contractGoodsId: '',
      };
      this.showGoodsEditForm = true;
    },
    deleteThisGoods(item) {
      if (!item.contractGoodsId) {
        return;
      }
      this.$confirm("确定删除该货品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        purContractService.delContractGoods(item.contractGoodsId).then(() => {
          this.$message.success("删除成功");
          this.loadGoodsDataByContractId(this.editPurData.contractId);
        })
      }).catch(() => {
      });
    },
    cancelSavePurContract42() {
      this.editContractGoodsData = {}
      this.showGoodsEditForm = false;
    },
    savePurContract42() {
      let param = {
        ...this.editContractGoodsData,
        contractId: this.editPurData.contractId
      }
      //格式化成字符串，后台接收自动转为北京时间
      param.productionTime = tools.formatDate(param.productionTime, 'yyyy-MM-dd')
      param.validityTime = tools.formatDate(param.validityTime, 'yyyy-MM-dd')

      purContractService.saveContractGoods(param).then(res => {
        this.$message.success("保存成功");
        this.loadGoodsDataByContractId(this.editPurData.contractId);
        this.cancelSavePurContract42();
      })
    },
    savePurContract43() {
      let param = {
        ...this.editPurData,
        shipperName: this.shipperDic[this.editPurData.shipperId],
        departPortTime: tools.formatDate(this.editPurData.departPortTimeDate, 'yyyy-MM-dd'),
        expectPortTime: tools.formatDate(this.editPurData.expectPortTimeDate, 'yyyy-MM-dd'),
        //arrivalPortTime: tools.formatDate(this.editPurData.arrivalPortTimeDate,'yyyy-MM-dd'),
      };
      purContractService.savePurContract(param).then((data) => {
        this.$message.success("保存成功");
        this.loadTableData();
        this.dialogVisible = false;
        this.visible = false;
      });
    },
    showOrDownloadFile(fileUrl, fileType) {
      if (fileType == 'pdf') {
        this.$refs.pdfPreview.show(fileUrl);
      } else if (fileType == 'img') {
        this.$refs.imgPreview.show(fileUrl);
      } else {
        IoProgressService.download4free(fileUrl);
      }
    },
    switchState() {
      this.seeAbandon = !this.seeAbandon;
      if (this.seeAbandon) {
        this.searchInfo.transportStates = '4'
      } else {
        this.searchInfo.transportStates = '0,1'
      }
      this.loadTableData();
    },

    getProjectList(list) {
      if (!list || list.length == 0) {
        this.ProjectList = []
        this.ProjectDic = {}
        return;
      }
      for (let i in list) {
        let project = list[i]
        let childs = []
        //合同
        childs.push(this.getChild4Project(project.details.contractUrl, "合同", project.contractNo))
        childs.push(this.getChild4Project(project.details.invoiceUrl, "发票"))
        childs.push(this.getChild4Project(project.details.billLadingUrl, "提单"))
        childs.push(this.getChild4Project(project.details.packingUrl, "箱单"))
        childs.push(this.getChild4Project(project.details.coriginUrl, "产地证"))
        childs.push(this.getChild4Project(project.details.sanitaryUrl, "卫生证"))
        childs.push(this.getChild4Project(project.details.testReportUrl, "检测报告"))
        childs.push(this.getChild4Project(project.details.otherDocumentsUrl, "其他单据"))
        if (project.transportState == '3' || project.transportState == '9' || project.transportState == '4') {
          childs.push(this.getChild4Project(project.details.customsDeclarationUrl, "报关单"))
          childs.push(this.getChild4Project(project.details.sanitaryCheckUrl, "卫检单"))
        }
        project.childrenList = childs;
        //放进字典
        this.ProjectDic[project.contractId] = project.contractNo
      }
      this.ProjectList = list;
    },
    getProjectLog(data) {
      let arr = '';
      for (let i in data) {
        arr += data[i].contractId + ",";
      }
      if (arr.length > 0) {
        purContractService.queryLog({contractId: arr}).then((data) => {
          data =
              data != null
                  ? data.map((e) => {
                    return {
                      contractNo: this.ProjectDic[e.contractId] || '',
                      action: e.action,
                      content: e.content,
                      operateTime: moment(e.operateTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                      ),
                      operatorName: e.operatorName,
                      fileFlag: (e.content && e.content.indexOf("http") > -1),
                      fileUrlList:
                          (e.content && e.content.indexOf("http") > -1)
                              ? e.content.split(",").map((z) => {
                                return this.getFileItem(z)
                              }) : []
                    };
                  })
                  : [];
          //data.reverse(); //数组倒序
          this.TaskLogList = data;
        });
      }
    },

    openTaskDetails(contractId, title, fileList) {
      let data = {
        contractId: contractId,
        title: title,
        fileList: fileList
      };
      this.fileUploadData = data;
      this.modifyFileNameIdx = -1;
      this.fileUploadDialogVisible = true;
    },
    handleBeforeUpload(file) {
      if (file.name && file.name.indexOf("#") > -1) {
        this.$message.warning(`请去除文件名内的'#'井字键`);
        return false;
      }
      this.fileUploading = true;
      this.popLoading = true;
    },
    deleteFileFromList(idx) {
      let list = this.fileUploadData.fileList;
      list.splice(idx, 1);
      this.fileUploadData = {
        ...this.fileUploadData,
        fileList: list
      }
    },
    modifyFileNameOnList(idx) {
      let list = this.fileUploadData.fileList;
      list[idx].originalFileName = list[idx].fileName
      this.modifyFileNameIdx = idx;
    },
    async modifyFileNameConfirm(idx, mode) {
      if (mode == 2) {
        //取消改名
        let list = this.fileUploadData.fileList;
        list[idx].fileName = list[idx].originalFileName
        //更新到页面
        this.fileUploadData = {
          ...this.fileUploadData,
          fileList: list
        }
        this.modifyFileNameIdx = -1;
        this.popLoading = false;
        return;
      }
      //确认改名
      let list = this.fileUploadData.fileList;
      list[idx].fileName = list[idx].fileNameWithOutSuffix + '.' + list[idx].fileSuffix
      //loading
      this.popLoading = true;
      //提交服务器
      let param = {
        originalFileName: list[idx].originalFileName,
        newFileName: list[idx].fileName,
        module: this.fileData.module,
        originalFileUrl: list[idx].fileUrl
      }
      let res = await IoProgressService.modifyFileName(param)
      //修改fileUrl
      list[idx].fileUrl = res;
      //更新到页面
      this.fileUploadData = {
        ...this.fileUploadData,
        fileList: list
      }
      //提交修改
      await this.fileUploadDialogConfirm(false);
      //ok
      this.modifyFileNameIdx = -1;
      this.popLoading = false;
    },

    handleUploadSuccess(response, file, fileList) {
      let fileItem = this.getFileItemFromFileObj(file)
      let list = this.fileUploadData.fileList;
      list.push(fileItem);
      this.fileUploadData = {
        ...this.fileUploadData,
        fileList: list
      }
      this.fileUploading = false;
      this.popLoading = false;
    },
    handleUploadError(err, file, fileList) {
      this.fileUploading = false;
      this.popLoading = false;
    },
    async fileUploadDialogConfirm(closeFlag = true) {
      const data = this.fileUploadData;
      let fileUrlStr = ''
      if (data && data.fileList && data.fileList.length > 0) {
        for (const fileListElement of data.fileList) {
          fileUrlStr += fileListElement.fileUrl + "#" + fileListElement.fileName + ","
        }
      }
      if (fileUrlStr && fileUrlStr.length > 0) {
        fileUrlStr = fileUrlStr.substr(0, fileUrlStr.length - 1);
      }
      //保存
      let param = {
        contractId: data.contractId,
      }
      this.fillParam4UpdateDetail(param, fileUrlStr, data.title);
      await purContractService.saveFileUrl(param)
      this.loadTableData();
      if (closeFlag) {
        //清空
        this.fileUploadData = {fileList: []};
        this.fileUploadDialogVisible = false;
      }
    },

    getFileNameWithOutSuffix(fileName) {
      let pre = null;
      if (fileName != null && fileName.indexOf(".") > -1) {
        let idx = fileName.lastIndexOf(".")
        pre = fileName.substr(0, idx)
      }
      return pre;
    },
    getFileSuffix(fileName) {
      let suffix = null;
      if (fileName != null && fileName.indexOf(".") > -1) {
        let idx = fileName.lastIndexOf(".")
        suffix = fileName.substr(idx + 1, fileName.length)
      }
      return suffix;
    },
    judgeFileType(fileName) {
      let fileType = null;
      if (fileName != null) {
        fileName = fileName.toLowerCase()
        if (fileName.indexOf("png") > -1
            || fileName.indexOf("jpg") > -1
            || fileName.indexOf("jpeg") > -1) {
          fileType = "img";
        } else if (fileName.indexOf("pdf") > -1) {
          fileType = "pdf";
        } else {
          fileType = "file";
        }
      }
      return fileType;
    },
    imgIconDispose(fileName) {
      if (fileName != null && fileName.indexOf(".") > -1) {
        fileName = fileName.toLowerCase();
        return fileName == undefined || fileName == ""
            ? require("ASSETS/images/fileIcon.png")
            : fileName.split(".")[1].indexOf("png") != -1
                ? require("ASSETS/images/imgIcon.png")
                : fileName.split(".")[1].indexOf("jpg") != -1
                    ? require("ASSETS/images/imgIcon.png")
                    : fileName.split(".")[1].indexOf("jpeg") != -1
                        ? require("ASSETS/images/imgIcon.png")
                        : fileName.split(".")[1].indexOf("pdf") != -1
                            ? require("ASSETS/images/pdfIcon.png")
                            : fileName.split(".")[1].indexOf("xls") != -1
                                ? require("ASSETS/images/xmlIcon.png")
                                : fileName.split(".")[1].indexOf("falsh") != -1
                                    ? require("ASSETS/images/falshIcon.png")
                                    : fileName.split(".")[1].indexOf("html") != -1
                                        ? require("ASSETS/images/htmlIcon.png")
                                        : fileName.split(".")[1].indexOf("doc") != -1
                                            ? require("ASSETS/images/wordIcon.png")
                                            : require("ASSETS/images/fileIcon.png");
      }
    },

    getChild4Project(url, title, content = '') {
      let child = {
        title: title,
        content: content,
        status: !url ? '未上传' : '已上传',
        fileList: url
            ? url.split(",").map((z) => {
              return this.getFileItem(z)
            }) : []
      }
      //console.log(child)
      return child;
    },
    getFileItem(urlAndName) {
      let arr = urlAndName.split("#")
      let fileName = arr.length > 1 ? arr[1] : ''
      let fileUrl = arr.length > 0 ? arr[0] : ''
      return {
        fileName: fileName,
        fileNameWithOutSuffix: this.getFileNameWithOutSuffix(fileName),
        fileSuffix: this.getFileSuffix(fileName),
        fileUrl: fileUrl,
        fileType: this.judgeFileType(fileName),
        //fileSize: formatFileSize(z.fileSize),
        fileSize: '',
        fileImg: this.imgIconDispose(fileName),
      };
    },
    getFileItemFromFileObj(file) {
      let fileName = file.name;
      fileName = fileName.replaceAll("#", "")
      return {
        fileName: fileName,
        fileNameWithOutSuffix: this.getFileNameWithOutSuffix(fileName),
        fileSuffix: this.getFileSuffix(fileName),
        fileUrl: file.response,
        fileType: this.judgeFileType(fileName),
        //fileSize: formatFileSize(z.fileSize),
        fileSize: file.size,
        fileImg: this.imgIconDispose(fileName),
      };
    },
    showCreateDialog() {
      this.visible = true;
      this.editPurData = {
        contractId: '',
        contractTitle: '',
        contractType: '',
        transportState: '0',
        contractNo: ''
      }
    },
    hideEditDialog() {
      this.dialogVisible = false;
      this.editDialogStep = 0;
      this.dialogShowOnly = false;
    },
    showEditDialog(index, showOnly = false) {
      this.editDialogStep = 0;
      this.dialogVisible = true;
      this.dialogShowOnly = showOnly;
      const temp = this.ProjectList[index];
      let pro = {
        ...temp,
        projectState: temp.transportState == "4" ? false : true,
        departPortTimeDate: tools.parseDate(temp.departPortTime),
        expectPortTimeDate: tools.parseDate(temp.expectPortTime),
        arrivalPortTimeDate: tools.parseDate(temp.arrivalPortTime),
      };
      this.editPurData = pro;
      this.loadGoodsDataByContractId(temp.contractId)
      //console.log(pro)
    },
    fillParam4UpdateDetail(param, fileUrlStr, title) {
      switch (title) {
        case '合同':
          param.contractUrl = fileUrlStr;
          break;
        case '发票':
          param.invoiceUrl = fileUrlStr;
          break;
        case '提单':
          param.billLadingUrl = fileUrlStr;
          break;
        case '箱单':
          param.packingUrl = fileUrlStr;
          break;
        case '产地证':
          param.coriginUrl = fileUrlStr;
          break;
        case '卫生证':
          param.sanitaryUrl = fileUrlStr;
          break;
        case '报关单':
          param.customsDeclarationUrl = fileUrlStr;
          break;
        case '卫检单':
          param.sanitaryCheckUrl = fileUrlStr;
          break;
        case '检测报告':
          param.testReportUrl = fileUrlStr;
          break;
        case '其他单据':
          param.otherDocumentsUrl = fileUrlStr;
          break;
      }
    },
    getShipperData() {
      purContractService.listShipper().then(res => {
        let total = []
        let dic = {}
        if (res && res.length > 0) {
          for (const re of res) {
            dic[re.shipperId] = re.shipperName;
          }
          let list0 = res.filter(e => e.type == '0')
          if (list0.length > 0) {
            total.push({label: '海运', options: list0})
          }
          let list1 = res.filter(e => e.type == '1')
          if (list1.length > 0) {
            total.push({label: '空运', options: list1})
          }
        }
        this.shipperDic = dic;
        this.shipperList = total;
      })
    },
    shipDetailClick(idx) {
      const item = this.ProjectList[idx];
      if (item.shipperId && item.shipperName && item.billLadingNo) {
        //物流轨迹
        this.$refs.shipTrailPop.$emit("openDialog", item);
      } else {
        //打开物流详情
        this.showEditDialog(idx)
        this.editDialogStep = 3;
      }
    },
    addProjectDataCount(count1,count2){
      let total = 0;
      if(count1){
        total += count1
      }
      if(count2){
        total += count2
      }
      return total;
    },
  },

};
</script>

<style scoped lang="scss">
#ProjectList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.ProjectList-left {
  width: 74%;
}

.ProjectList-right {
  width: 25%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 15px;
  background: rgba(255, 255, 255, 1);
  height: 100vh;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}

.ProjectList-right-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-bottom: 20px;
}

.scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Project {
  /*height: 136px;*/
  width: 97.4%;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  /*display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;*/
  padding: 10px 16px;
  margin-top: 10px;
}

.Project-item {
  width: 100%;
  /*height: 96px;*/
  border-radius: 3px;
  display: flex;
  /*overflow-x: auto;*/
  align-items: center;
  /*overflow: hidden;*/
  margin-top: 10px;
  flex-wrap: wrap;
}

.Project-item-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Project-item-header > div:nth-child(1) {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  margin-left: 5px;
}

.Project-slider {
  flex-shrink: 0;
  width: 220px;
  height: 90px;
  margin: 10px;
  padding-right: 12px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#ProjectList > > > .el-checkbox__label {
  color: rgba(102, 102, 102, 1);
}

#ProjectList > > > .el-checkbox__inner {
  /* background: white; */
  border: 1px solid rgba(153, 153, 153, 1);
}

.slider-operation-img {
  cursor: pointer;
  width: 20px;
  height: 26px;
}

.show-operate {
  position: absolute;
  width: 20px;
  height: 26px;
  top: 0px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.slider-operation {
  margin: 10px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slider-operation > * {
  margin-right: 10px;
}

.slider-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
}

.slider-header > * {
  margin-right: 10px;
}

.slider-edit {
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
}

.addTask {
  flex-shrink: 0;
  width: 100px;
  height: 86px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.addTask > div:nth-child(1) {
  color: rgba(196, 196, 196, 1);
  font-size: 25px;
}

.addTask > div:nth-child(2) {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  margin-top: 6px;
}

.AddProject {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 96%;
  height: 100%;
  border-bottom: 1px solid rgba(166, 166, 166, 1);
  padding: 17px 11px 14px 10px;
}

.header-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-left > img {
  width: 14px;
  height: 14px;
}

.header-left > p {
  width: 40px;
  margin: 0px 10px;
}

.label-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0px 15px 0px;
  padding: 10px;
}

.item-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  width: 30%;
}

.item-title > img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}

.item-dispose {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 200px;
  height: 100%;
  /* margin-left: 20px; */
}

.queryTaskLog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0px;
  width: 100%;
  height: 100%;
}

.queryTaskLog-top {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.queryTaskLog-bottom-bottom {
  margin-top: 5px;
  height: 20px;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.queryTaskLog-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.queryTaskLog-line {
  width: 4px;
  height: 13px;
  background: rgba(196, 196, 196, 1);
}

.queryTaskLog-acitivty-null {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  margin-left: 5px;
}

.queryTaskLog-acitivty-file {
  margin-left: 5px;
}

.fileList-log {
  width: 50%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background: rgba(245, 245, 245, 1);
  margin-bottom: 20px;
}

.fileList-left {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.fileList-img > img {
  width: 20px;
  height: 25px;
}

.fileList-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 17px;
}

.fileList-title-append {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 25px;
}

.fileList-title > div {
  color: rgba(128, 128, 128, 1);
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0px;
}

.fileList-title > div:nth-child(1) {
  -webkit-box-orient: vertical;
  word-break: break-all;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
}

.fileList-list {
  width: 65%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background: rgba(245, 245, 245, 1);
  margin-bottom: 20px;
}

.fileList-operation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.fileList-operation > a {
  margin-left: 24px;
  cursor: pointer;
}

.img-cover {
  object-fit: cover;
}

.edit-item-content {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-evenly;
  align-items: center;
}

.item-box-long {
  height: 50px;
  line-height: 50px;
  width: 90%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.edit-back-bar {
  height: 50px;
  width: 100%;
}

.descriptions-operate {
  text-align: center;
}

.descriptions-operate > a {
  margin-left: 20px;
  cursor: pointer;
}

.stp-content {
  width: 100%;
  padding: 40px;
}
</style>
