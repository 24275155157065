<template>
  <div class="logistics-wrap">
    <el-button type="primary" v-print="printObj">打印</el-button>
    <div id="printMe" class="print-me">
        <div class="print-head">
          <div>感谢您选购渥康官方商品！</div>
          <img src="../../../assets/images/wdom2.png" alt="">
        </div>
        <div class="print-con">
          <div class="print-tit">
            渥康产品发货-承运单
          </div>
          <div class="print-lit-tit">出库单号：XXXXXXXXXXXXXXX</div>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table">
            <tr>
              <td>发货方：丽丽亚</td>
              <!--发货时间：是填写的-->
              <td>发货时间：</td>
              <td rowspan="3">发货签名：</td>
            </tr>
            <tr>
              <td>发货人：莉莉安</td>
              <td>发货方电话：18350231299</td>
            </tr>
            <tr>
              <td colspan="2">发货方地址：龙湖</td>              
            </tr>

            <tr>
              <td>收货方：丽丽亚</td>
              <!--收货时间：是填写的-->
              <td>收货时间：</td>
              <td rowspan="3">收货签名：</td>
            </tr>
            <tr>
              <td>收货人：莉莉安</td>
              <td>收货方电话：18350231299</td>
            </tr>
            <tr>
              <td colspan="2">收货方地址：龙湖</td>              
            </tr>

            <tr>
              <td>承运单号：9898878799</td>
              <td>承运时间：：2021 03 09</td>
              <td rowspan="3">承运签名：</td>
            </tr>
            <tr>
              <td>承运人：莉莉安</td>
              <td>承运人电话：18350231299</td>
            </tr>
            <tr>
              <td colspan="2">承运车辆：五菱宏光</td>              
            </tr>
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>
            <tr>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>xxxxxxxx</td>
              <td>2021-03-09</td>
              <td>999</td>
            </tr>            
          </table>
          
          <div class="print-remark">订单备注：XXXXXXXXXXXXXXX</div>
          <div class="p1 mt20">送货方式：送货上门/自提</div>
          <div class="p1">运费金额：¥2,222(以实际称重为准)</div>
          <div class="p1">运费付款：到付/寄付</div>

          <div class="p2 mt20">1.以上签名处等同于公司印鉴，签名后有同样法律效力。</div>
          <div class="p2">2.收货签名视同于对产品数量无异议，如有产品破损，请在备注处标记品名及数量。</div>
          <div class="p2">3.本单据一式三联，由发货方发起，收货方终止，不得涂改，真实数据均具有法律效力。</div>

        </div>
    </div>    
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import boundQuery from "API/whm/boundQuery";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";
import print from 'vue-print-nb'

export default {
  name: "receiveList",
  data() {
    return {
      printObj: {
        id: "printMe",
        popTitle: 'WDOM',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      }
    };
  },
  mixins: [pageTableMixin],
  directives: {
    print   
  },
  mounted() {    
    //this.loadTableData()
    //this.getGoodsDetail();
  },
  methods: {
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailLogistics(this.$route.params.id).then((data) => {
        //console.log(data)
        this.whmInfo = data
        loading.close();
      });
    },
    loadTableData() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,        
      };
      boundQuery.queryByReceiveList(params).then((data) => {
        data.list = data.list.map((e) => {
          return {
            skuCode: e.skuCode,//库品编号
            skuName: e.skuName,//库品名称
            receiveName: e.receiveName,//领用人
            createUser: e.createUser,//操作人
            operNum: e.operNum,//领用数量
            createTime: tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss"),//操作时间
          };
        });
        this.querySuccess(data);
      });      
    },   
  },
};
</script>
<style>
.print-me{ margin-top: 40px; width: 210mm; box-sizing: border-box; padding:25px; background: #fff;}
.print-head{ display: flex; justify-content: space-between; align-items: center; font-size: 24px; padding-bottom: 14px; border-bottom: 1px solid #000;}
.print-head img{ width: 66px;}
.print-tit{ margin-top: 24px; font-size: 36px; text-align: center; color: #333;}
.print-lit-tit{ margin-top: 40px; font-size: 24px;}
.print-table{ margin-top: 28px; width: 100%; border-top: 1px solid #000; border-left: 1px solid #000; font-size: 20px;}
.print-table td,.print-table th{ padding:12px 12px 12px 16px; box-sizing: border-box; border-right: 1px solid #000; border-bottom: 1px solid #000;}
.fs14{ font-size: 24px;}
.print-remark{ margin-top: 60px; font-size: 24px;}
.p1{ padding:12px 0; font-size: 24px;}
.mt20{ margin-top: 20px;}
.p2{ padding:12px 0; font-size: 20px;}
</style>