<template>
  <el-dialog title="指定优先出库" :visible.sync="dialogVisible" width="500px">
    <div class="select-brand-content">
      <div class="search-result">
        <div
          class="search-result-item"          
          v-for="(item, index) in purchaseBatchCodeList"
          :key="index"
          :class="{ active : index == current}"
        >
          <div class="v1">{{item.purchaseBatchCode}}</div>
          <img src="../../../assets/images/top.png" class="to-top" alt="" @click="selectBatch(item,index)">
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确认保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import invQuery from "API/whm/invQuery";

export default {
  data() {
    //console.log(this.$route.params.id)
    return {
      dialogVisible: false,
      purchaseBatchCodeList: [],
      current: null,
      selected: ''
    };
  },
  mounted() {
    this.queryBySort();
  },
  methods: {
    closePriorityDialog(){
      this.$emit("closePriorityDialog");
      this.dialogVisible = false;
    },
    submit() {
      let loading = this.$loading({
          lock: true,
          text: "加载中..."
      });
      let arr = []
      for(let i in this.purchaseBatchCodeList){
        if(this.purchaseBatchCodeList[i].inventoryId){
          arr[i] = this.purchaseBatchCodeList[i].inventoryId
        }        
      }
      arr = arr.toString()
      this.dialogVisible = false;
      let params = {
        inventoryId: arr,
        skuId: this.$route.params.id
      };      
      invQuery.updInvSort(params).then(data => {
        //console.log(data)        
        if(data){          
          this.selected = data
          this.queryBySort(this.$route.params.id)//拿到排序的列表   
          this.$message({
              message: '修改成功',
              type: 'success'
          }); 
          //console.log(this.selected)
          this.$emit("submit",this.selected);
          loading.close();          
        }        
      });            
    },
    selectBatch(item,index) {  
      let arr = [...this.purchaseBatchCodeList.splice(index,1), ...this.purchaseBatchCodeList] //es6写法 合并数组
      this.purchaseBatchCodeList = arr           
    },
    queryBySort(){
      //console.log(this.$route.params.id)
      invQuery.queryBySort(this.$route.params.id).then(data => {
        //console.log(data)
        this.purchaseBatchCodeList = data
      });
    },
  }
};
</script>

<style scoped lang="scss">
.select-brand-content {
  .input-search {
    padding: 20px;
    border-bottom: 1px solid $borderColor;
    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }
  .search-result {
    .search-result-item {
      display: flex;
      align-items: center;      
      line-height: 50px;
      padding: 0 20px;
      color: #666;
      border-bottom: 1px solid $borderColor;
      &:hover {
        background-color: rgba(51, 51, 51, 0.1);
      }
    }
    .search-result-item .v1{ flex: 1}
    .search-result-item .to-top{cursor: pointer;}
  }
  .search-result-item.active,.search-result-item.active:hover{background-color: #E8F1FD;}
}
</style>