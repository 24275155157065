<template>
  <div class="inventory-wrap">
    <el-form label-width="120px" :inline="true" class="inventory-form">
      <el-form-item label="新增盘点:">
        {{options.invalidTime}}
      </el-form-item>
      <el-form-item label="异常类型:">
        盘点
      </el-form-item>
      <el-form-item label="出入库状态:">        
        {{options.storageState}}
      </el-form-item>
    </el-form>    

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      class="inv-table"
    >
      <el-table-column
        prop="skuCode"
        label="库品编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="skuName"
        label="库品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="purchaseBatchCode"
        label="库品批次"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="actualNum"
        label="展示库存"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="checkStoreNum"
        label="实际库存"
        :show-overflow-tooltip="true"
      >        
      </el-table-column>
      <el-table-column
        prop="differenceValue"
        label="变动库存"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="变动原因"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
    </ws-page-table>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import abnormalQuery from "API/whm/abnormalQuery";

export default {
  name: "inventoryEdit",
  data() {
    return {
      value: [],
      options: {},
      searchInfo: {
        checkStoreId: "",
      },
      items: {
        checkStoreId: { filter: "like" },
      },               
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.options = JSON.parse(this.$route.params.id)
    this.loadTableData()
  },
  methods: {
    loadTableData() {
      //this.options.checkStoreId
      this.searchInfo.checkStoreId = this.options.checkStoreId
      if(this.searchInfo.checkStoreId){
        abnormalQuery.detailSpecialCheck(this.pageInfo, this.searchConfig).then((data) => {
          data.list = data.list.map((e) => {
            return {
              skuCode: e.skuCode,//库品编号
              skuName: e.skuName,//库品名称
              purchaseBatchCode: e.purchaseBatchCode,//批次
              actualNum: e.actualNum,//展示库存
              checkStoreNum: e.checkStoreNum,//盘点库存
              differenceValue: e.differenceValue,//盘点差值
              remark: e.remark,
            };
          });
          this.querySuccess(data);
        }); 
      }       
    },       
  },
};
</script>
<style>
.inv-table{ margin-top: 30px;}
.inventory-form{padding-left: 20px;}
.inventory-wrap{ padding: 0 0 40px 20px;}
</style>