<template>
  <div class="login">
    <div class="login-main">
      <div class="form-wrapper">
        <div class="title">
          <i class="logo"></i>
          <!--span>wdom服务系统</span-->
        </div>
        <div class="login-content" v-if="!isForget">
          <div class="desc-wrapper">
            <div class="desc-title">登录</div>
            <div class="desc">登录账号来使用WDOM提供的各种服务</div>
          </div>
          <el-tabs :stretch="true" @tab-click="tabClick" v-loading="isSubmiting">
            <el-tab-pane label="账号密码登录">
              <div class="login-form">
                <ws-input v-model="login.account" placeholder="账号" errorText="请输入账号" :isEmptyError="emptyFlag == 1"></ws-input>
                <ws-input v-model="login.password" placeholder="密码" errorText="请输入密码" type="password" @enter="submitLogin" :isEmptyError="emptyFlag == 2"></ws-input>
                <div class="tools-cell">
                  <span>
                    <el-checkbox v-model="login.remember">记住密码</el-checkbox>
                  </span>
                  <span class="forget-pwd" @click="isForget = true;stepIndex = 1;">忘记密码？</span>
                </div>
                <div class="submit-btn" @click="submitLogin">登录</div>
                <div class="tools-cell">
                  <span class="tips">
                    <i class="el-icon-warning" v-if="errorMessage.length"></i>
                    {{ errorMessage }}
                  </span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="手机验证码登录">
              <div class="login-form" v-loading="isSubmiting">
                <ws-drop-select :list="areaList" :hasDefalut="true" @select="selectArea"></ws-drop-select>
                <ws-input v-model="phoneLogin.phone" placeholder="手机号" maxlength="11" errorText="请输入手机号" :isEmptyError="emptyFlag == 3"></ws-input>
                <ws-input v-model="phoneLogin.code" placeholder="验证码" errorText="请输入验证码" :isEmptyError="emptyFlag == 4"></ws-input>
                <div class="tools-cell">
                  <span class="tips">
                    <i class="el-icon-warning" v-if="errorMessage.length"></i>
                    {{ errorMessage }}
                  </span>
                  <span class="code-btn" @click="sendLoginSms">{{ isSendSms ? smsCountDownTime + 's' : '获取验证码' }}</span>
                </div>
                <div class="submit-btn" @click="submitPhoneLogin">登录</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 忘记密码 开始 -->
        <div class="forget-content" v-if="isForget">
          <div class="desc-wrapper">
            <div class="desc-title">重置密码</div>
            <div class="desc"></div>
          </div>
          <div class="forget-form" v-loading="isForgetFormLoading">
            <div class="step-list">
              <div class="step-item" :class="{'is-active': stepIndex > 0}">
                <div class="setp-title">填写手机号</div>
                <div class="step-line step-one"></div>
              </div>
              <div class="step-item" :class="{'is-active': stepIndex > 1}">
                <div class="setp-title">验证手机号</div>
                <div class="step-line step-two"></div>
              </div>
              <div class="step-item" :class="{'is-active': stepIndex > 2}">
                <div class="setp-title">重置密码</div>
                <div class="step-line step-three"></div>
              </div>
            </div>
            <ws-drop-select :list="areaList" :hasDefalut="true" @select="selectArea" v-if="stepIndex < 3"></ws-drop-select>
            <ws-input v-if="stepIndex < 3" v-model="forget.phone" :disabled="stepIndex == 2" placeholder="手机号" maxlength="11" errorText="请输入手机号" :isEmptyError="emptyFlag == 5"></ws-input>
            <ws-input v-if="stepIndex == 2" v-model="forget.code" placeholder="验证码" errorText="请输入验证码" :isEmptyError="emptyFlag == 6"></ws-input>
            <ws-input v-if="stepIndex == 3" v-model="forget.password" placeholder="新密码" type="password" errorText="请输入新密码" :isEmptyError="emptyFlag == 7"></ws-input>
            <ws-input v-if="stepIndex == 3" type="password" v-model="forget.checkPassword" placeholder="确认密码" errorText="确认密码" :isEmptyError="emptyFlag == 8"></ws-input>
            <div class="success-step" v-if="stepIndex == 4">
              <div class="success-icon"></div>
            </div>
            <div class="tools-cell" v-if="stepIndex != 4">
              <span class="tips">
                <i class="el-icon-warning" v-if="forgetErrorMessage.length"></i>
                {{ forgetErrorMessage }}
              </span>
            </div>
            <template v-if="stepIndex != 4">
              <div class="submit-btn" @click="nextStep">下一步</div>
              <div class="back-btn" @click="backStep">返回</div>
            </template>
            <div class="back-btn" @click="toLogin" v-if="stepIndex == 4">密码已重置，请使用新密码登录</div>
          </div>
        </div>
        <!-- 忘记密码 结束 -->
      </div>
    </div>
    <div class="icpDivClass">
      <a href="https://beian.miit.gov.cn/">{{ icpCode }}</a>
    </div>
  </div>
</template>

<script>
import wsInput from "COMPS/base/input.vue";
import userHttp from "API/bim/userHttp";
import validator from "COMJS/validator";
import wsDropSelect from "COMPS/widgets/dropSelect";
import userModule from "../module/userModule";
import sysUtil from "@/constant/SysUtil";

// 引入base64
const Base64 = require('js-base64').Base64

export default {
  name: "login",
  components: {
    wsInput,
    wsDropSelect,
  },
  data() {
    return {
      icpCode: "鲁ICP备19006398号-3",
      emptyFlag: 0,
      login: {
        account: "",
        password: "",
        remember: false,
      },
      phoneLogin: {
        areaCode: "86",
        phone: "",
        code: "", // 验证码
      },
      forget: {
        areaCode: "86",
        phone: "",
        code: "",
        password: "",
        checkPassword: "",
      },
      areaList: [
        {
          label: "中国 +86",
          value: 86,
        },
        {
          label: "新西兰 +64",
          value: 64,
        },
        {
          label: "澳大利亚 +61",
          value: 61,
        },
      ],
      errorMessage: "",
      forgetErrorMessage: "",
      isSubmiting: false,
      isForgetFormLoading: false,
      isForget: false,
      stepIndex: 1,
      isSendSms: false,
      smsCountDownTime: 60,
      smsTimer: null,
      systemUrl: "",
    };
  },
  watch: {
    isForget() {
      if (this.isSendSms) {
        this.clearSmsInterval();
      }
    },
  },
  created () {
    // 在页面加载时从cookie获取登录信息
    let account = this.getCookie("wdomilk_account")
    let password = Base64.decode(this.getCookie("wdomilk_password"))
    //let password = this.getCookie("wdomilk_password")
    //console.log("created",account,password)
    // 如果存在赋值给表单，并且将记住密码勾选
    let rem = {
      account : "",
      password : "",
      remember : false
    }
    if(account){
      rem.account = account
    }
    if(password){
      rem.password = password
      rem.remember = true
    }
    this.login = rem
  },
  mounted() {
    if (window.location.href.indexOf("wdomilk") > -1) {
      this.icpCode = "鲁ICP备19006398号-3";
    } else if (window.location.href.indexOf("ciit") > -1) {
      this.icpCode = "闽ICP备19009604号-1";
    } else if (window.location.href.indexOf("115.28.186.28") > -1) {
      this.icpCode = "闽ICP备19009604号-1";
    } else if (window.location.href.indexOf("localhost") > -1) {
      this.icpCode = "闽ICP备19009604号-1";
    } //icpCode
  },
  methods: {
    // 账号登录
    submitLogin() {
      this.errorMessage = "";
      this.emptyFlag = 0;
      if (!this.login.account) return (this.emptyFlag = 1);
      if (!this.login.password) return (this.emptyFlag = 2);
      this.isSubmiting = true;
      userHttp
        .login({
          userAccount: this.login.account,
          accountPassword: this.login.password,
          userType: "platform",
        })
        .then((res) => {
          this.loginSuccessfully(res);
          this.setUserInfoToCookie();
        })
        .catch((err) => {
          this.errorMessage = err.response.data.msg;
          this.isSubmiting = false;
        });
    },
    // 手机号登录
    submitPhoneLogin() {
      this.errorMessage = "";
      this.emptyFlag = 0;
      if (!this.phoneLogin.phone) return (this.emptyFlag = 3);
      if (!this.phoneLogin.code) return (this.emptyFlag = 4);
      this.isSubmiting = true;
      userHttp
        .smsLogin({
          phoneNum: this.phoneLogin.phone,
          telAreaCode: this.phoneLogin.areaCode,
          sms: this.phoneLogin.code,
          userType: "platform",
        })
        .then((res) => {
          this.loginSuccessfully(res);
        })
        .catch((err) => {
          this.isSubmiting = false;
          this.errorMessage = err.response.data.msg;
        });
    },
    loginSuccessfully(res) {
      this.$message({ type: "success", message: "登录成功" });
      userModule.setCookiesToken(res.token);
      userModule.setPPUser(JSON.stringify(res));
      /*let OSInfo = tools.getOsInfo()
      systemLogService.sendLoginLog({
        operationIp: returnCitySN["cip"],
        osName: OSInfo.name,
        osVersion: OSInfo.version
      })*/
      this.isSubmiting = false;
      this.$router.push("/");
    },
    tabClick(e) {
      if (e.index == 0) {
        this.phoneLogin.phone = "";
        this.phoneLogin.code = "";
      }
      if (e.index == 1) {
        for (let k in this.login) {
          this.login[k] = "";
        }
      }
      this.errorMessage = "";
    },
    // 获取登录验证码
    sendLoginSms() {
      if (!this.phoneLogin.phone) return (this.emptyFlag = 3);
      if (!validator.mobile(this.phoneLogin.phone)) {
        return (this.errorMessage = "手机号格式不正确");
      }
      var that = this;
      userHttp
        .sendLoginSms({
          phoneNum: this.phoneLogin.phone,
          telAreaCode: this.phoneLogin.areaCode,
        })
        .then((res) => {
          that.smsSuccessfully();
        })
        .catch((err) => {
          that.errorMessage = err.response.data.msg;
        });
    },
    smsSuccessfully() {
      this.isSendSms = true;
      this.smsTimer = setInterval(() => {
        this.smsCountDownTime--;
        if (this.smsCountDownTime === 0) {
          this.clearSmsInterval();
        }
      }, 1000);
    },
    // 清除验证码定时器
    clearSmsInterval() {
      this.isSendSms = false;
      clearInterval(this.smsTimer);
      this.smsTimer = null;
      this.smsCountDownTime = 60;
    },
    // 清除忘记密码表单
    resetForgetForm() {
      this.areaCode = "86";
      this.forget.phone = "";
      this.forget.code = "";
      this.forget.password = "";
      this.forget.checkPassword = "";
    },
    // 选择区号
    selectArea(item) {
      if (this.forget) {
        this.phoneLogin.areaCode = item.value;
      } else {
        this.phoneLogin.areaCode = item.value;
      }
      this.forget.areaCode = item.value;
    },
    selectsystem(item) {
      if (item != "/") {
        this.systemUrl = item.value;
      }
    },
    toLogin() {
      this.isForget = false;
      this.stepIndex = 0;
      this.resetForgetForm();
    },
    // 上一步
    backStep() {
      this.forgetErrorMessage = "";
      if (this.stepIndex == 1) {
        this.resetForgetForm();
        return (this.isForget = false);
      }
      this.stepIndex--;
    },
    // 下一步
    nextStep() {
      this.forgetErrorMessage = "";
      if (this.stepIndex == 1) {
        if (!this.forget.phone) return (this.emptyFlag = 5);
        if (!validator.mobile(this.forget.phone)) {
          return (this.forgetErrorMessage = "手机号格式不正确");
        }
        this.checkPhone();
      } else if (this.stepIndex == 2) {
        this.checkUpdatePwdSms();
      } else if (this.stepIndex == 3) {
        if (!this.forget.password) return (this.emptyFlag = 7);
        if (!this.forget.checkPassword) return (this.emptyFlag = 8);
        if (this.forget.password != this.forget.checkPassword) {
          return (this.forgetErrorMessage = "两次输入的密码不一致");
        }
        this.updatePwd();
      }
    },
    // 忘记密码校验手机号
    checkPhone() {
      this.isForgetFormLoading = true;
      userHttp
        .checkPhone(this.forget.phone, this.forget.areaCode)
        .then(() => {
          userHttp
            .sendUpdatePwdSms({
              phoneNum: this.forget.phone,
              telAreaCode: this.forget.areaCode,
            })
            .then((res) => {
              this.$message({
                message: "验证码已发送",
                type: "success",
              });
              this.stepIndex = 2;
              this.isForgetFormLoading = false;
            })
            .catch((err) => {
              this.errorMessage = err.response.data.msg;
              this.isForgetFormLoading = false;
            });
        })
        .catch((err) => {
          //this.isForgetFormLoading = false;
          this.errorMessage = err.response.data.msg;
          this.isForgetFormLoading = false;
        });
    },
    // 验证修改密码短信
    checkUpdatePwdSms() {
      this.isForgetFormLoading = true;
      userHttp
        .checkUpdatePwdSms({
          phoneNum: this.forget.phone,
          telAreaCode: this.forget.areaCode,
          sms: this.forget.code,
        })
        .then((res) => {
          this.isForgetFormLoading = false;
          this.stepIndex = 3;
        })
        .catch((err) => {
          this.isForgetFormLoading = true;
          this.errorMessage = err.response.data.msg;
        });
    },
    // 修改密码
    updatePwd() {
      this.isForgetFormLoading = true;
      userHttp
        .updatePwd({
          phoneNum: this.forget.phone,
          telAreaCode: this.forget.areaCode,
          newPwd: this.forget.password,
          userType: "platform",
        })
        .then(() => {
          this.isForgetFormLoading = false;
          this.stepIndex = 4;
        })
        .catch((err) => {
          this.isForgetFormLoading = false;
          this.errorMessage = err.response.data.msg;
        });
    },
    // 储存表单信息
    setUserInfoToCookie () {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if(this.login.remember){
        this.setCookie("wdomilk_account",this.login.account)
        // base64加密密码
        let password = Base64.encode(this.login.password)
        //let password = this.login.password
        this.setCookie("wdomilk_password",password)
      }else{
        this.setCookie("wdomilk_account",this.login.account)
        this.setCookie("wdomilk_password","")
      }
    },
    // 获取cookie
    getCookie (key) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + '=')
        if (start !== -1) {
          start = start + key.length + 1
          let end = document.cookie.indexOf(';', start)
          if (end === -1) end = document.cookie.length
          return unescape(document.cookie.substring(start, end))
        }
      }
      return ''
    },
    // 保存cookie
    setCookie (cName, value, expiredays) {
      let exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = cName + '=' + decodeURIComponent(value) +
          ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
    },
  }
};
</script>

<style lang="scss" scoepd>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("~ASSETS/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  z-index: -1;

  .login-main {
    float: right;
    width: 556px;
    padding-top: 150px;
    box-sizing: border-box;
    height: 100%;
    background-color: #fff;

    .form-wrapper {
      width: 360px;
      margin: 0 auto;

      .title {
        .logo {
          display: inline-block;
          vertical-align: middle;
          width: 66px;
          height: 18px;
          background-image: url("~ASSETS/images/login/wdom_logo_1.jpeg");
          background-repeat: no-repeat;
          background-size: 100%;
        }

        > span {
          font-size: 22px;
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .desc-wrapper {
        margin-bottom: 45px;
        margin-top: 22px;

        .desc-title {
          font-size: 24px;
          font-weight: bold;
        }

        .desc {
          margin-top: 8px;
          height: 16px;
          color: #666;
        }
      }

      .el-tabs {
        .el-tabs__nav-wrap::after {
          background-color: rgba(51, 51, 51, 0);
        }

        .el-tabs__item {
          padding: 0;
          border-bottom: 2px solid #e4e7ed; // el-tab ::after样式层级覆盖了is-active
          &:hover {
            color: #f90000;
          }
        }

        .el-tabs__item.is-active {
          color: #f90000;
          // font-weight: bold;
        }

        .el-tabs__active-bar {
          background-color: #f90000;
        }
      }

      .forget-content {
        .forget-form {
          padding-top: 15px;
        }

        .step-list {
          width: 100%;
          align-items: center;
          display: flex;

          .step-item {
            flex: 1;
            text-align: center;
            margin: 0 3px;

            &.is-active {
              .setp-title {
                color: #f90000;
              }

              .step-line {
                background-color: #f90000;

                &::before {
                  background-color: #f90000;
                }
              }
            }

            .setp-title {
              font-size: 14px;
            }

            .step-line {
              position: relative;
              width: 100%;
              height: 2px;
              background-color: #dcdee0;
              margin-top: 15px;

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 15px;
                height: 15px;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                border-radius: 50%;
                color: #fff;
                background-color: #dcdee0;
              }
            }

            .step-one::before {
              content: "1";
            }

            .step-two::before {
              content: "2";
            }

            .step-three::before {
              content: "3";
            }
          }
        }

        .success-step {
          width: 100%;
          margin-top: 28px;

          .success-icon {
            width: 58px;
            height: 58px;
            margin: 0 auto;
            background-image: url("~ASSETS/images/success.png");
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }
      }

      .tools-cell {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        height: 20px;

        .tips {
          font-size: 12px;
          color: #f90000;
        }

        .forget-pwd,
        .code-btn {
          flex: 0 0 80px;
          text-align: right;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .back-btn {
        cursor: pointer;
        margin-top: 26px;
        text-align: center;
        border: 1px solid rgba(51, 51, 51, 1);
        line-height: 50px;
        border-radius: 2px;
      }

      .submit-btn {
        cursor: pointer;
        margin-top: 26px;
        text-align: center;
        background-color: #666;
        line-height: 50px;
        border-radius: 2px;
        color: #fff;

        &:hover {
          background-color: #999;
        }
      }
    }
  }
}
.icpDivClass {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 30px;
  background-color: #d9d9d9;
  line-height: 30px;
  text-align: center;
}
</style>
