<template>
  <el-dialog :title="paramId ? '编辑参数': '添加参数'" :visible.sync="dialogFormVisible" width="50vw">
    <el-form :model="form" v-loading="loading" :rules="rules" v-if="dialogFormVisible">
      <el-form-item  label="参数名称：" prop="cnName" :label-width="formLabelWidth">
        <el-input  v-model.trim="form.cnName"  maxlength="16" show-word-limit ></el-input>
      </el-form-item>
      <el-form-item  label="参数键：" prop="paramKey" :label-width="formLabelWidth">
        <el-input  v-model.trim="form.paramKey" maxlength="64" show-word-limit ></el-input>
      </el-form-item>
      <el-form-item  label="参数值：" prop="defaultValue" :label-width="formLabelWidth">
        <el-input v-model.trim="form.defaultValue" maxlength="128" show-word-limit ></el-input>
      </el-form-item>
      <el-form-item label="备注：" :label-width="formLabelWidth">
        <el-input v-model.trim="form.remark"   maxlength="128" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
    import BimParamService from "API/bim/BimParamService";
    import tools from "COMJS/tools";
    import userModule from "@/module/userModule";

    export default {
        props: {
            paramId: String
        },
        data() {
            var validateCnName = (rule, value, callback) => {
                    let param = {
                        cnName: value,
                        paramId: this.paramId
                    }
                    BimParamService.uniqueValidate(param).then(data => {
                    if(data.cnName === value){
                        callback(new Error("参数名重复，请重新输入"));
                        this.flag= false ;
                    }else{
                        this.flag = true ;
                        callback();
                    }

                });
            };
            var validateParamKey = (rule, value, callback) => {
                    let param = {
                        paramId : this.paramId,
                        paramKey: value
                    };
                    BimParamService.uniqueValidate(param).then(data => {
                        if(data.paramKey === value){
                        callback(new Error("参数键重复,请重新输入"));
                        this.flag1= false;
                    }else{
                        this.flag1 =true ;
                        callback();
                    }
                });
            };

            var validateDefaultValue = (rule, value, callback) =>
            {
                callback();
            };
            return {
                form: {
                    cnName: "",
                    paramKey: "",
                    defaultValue: "",
                    remark: ""
                },
                requestHeaders: {
                    Authorization: userModule.token
                },
                dialogFormVisible: false,
                formLabelWidth: "100px",
                loading: false,
                flag : true,
                flag1 : true,
            rules: {
                    cnName: [
                        { validator: validateCnName, trigger: 'blur'},
                        { required: true, message:"请输入参数名", trigger: 'change' }

                    ],
                    paramKey: [
                        { validator: validateParamKey, trigger: 'blur' },
                        { required: true, message:"请输入参数键", trigger: 'change'}
                    ],
                    defaultValue: [
                        { validator: validateDefaultValue, trigger: 'blur' },
                        { required: true, message:"请输入参数默认值", trigger: 'change' }
                    ]
                }
            };
            },
        watch: {
            dialogFormVisible(value) {
                if (this.paramId) {
                    this.getParamDetail();
                } else {
                    this.resetForm();
                }
            }
        },
        methods: {
            getParamDetail() {
                this.loading = true;
                BimParamService.getParamDetail(this.paramId).then(data => {
                this.form.cnName = data.cnName;
                this.form.paramKey = data.paramKey;
                this.form.defaultValue = data.defaultValue;
                this.form.remark = data.remark;
                this.loading = false;
            });
            },
            submitForm() {
                if (this.form.cnName == "") {
                    return this.$message({
                        message: "请填写参数名称",
                        type: "warning"
                    });
                }
                if (this.form.paramKey == "") {
                    return this.$message({
                        message: "请填写参数键",
                        type: "warning"
                    });
                }
                if (this.form.defaultValue == "") {
                    return this.$message({
                        message: "请填写参数默认值",
                        type: "warning"
                    });
                }

                if(this.flag===false || this.flag1===false){
                    return this.$message({
                        message: "填写的数据不合法，请重新输入",
                        type: "warning"
                    });
                }
                // if (this.form.remark == "") {
                //     return this.$message({
                //         message: "请填写参数备注",
                //         type: "warning"
                //     });
                // }

                let params = {
                    cnName: this.form.cnName,
                    paramKey: this.form.paramKey,
                    defaultValue: this.form.defaultValue,
                    remark: this.form.remark
                };
                this.loading = true;
                if (this.paramId) {
                    params.paramId = this.paramId;
                }
                BimParamService.saveParam(params).then(res => {
                    this.loading = false;
                this.$emit("updateTable");
            });
                this.dialogFormVisible = false;
            },
            // 重置表单
            resetForm() {
                tools.clearObj(this.form);
            }
        }
    };
</script>

<style lang="scss">
  .upload-img-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
    color: $themeColor;
    border: 1px solid #dcdee0;
    border-radius: 0px;
  }
  .xrequired:before {
   content: '* ';
   color: red;
  }
</style>
