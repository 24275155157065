<template>
  <div>
    <div>

      <div slot="toolbar">
        <el-button type="primary" @click.navite="addNodePerson" :disabled="btnDisable">新增</el-button>
        <el-button @click.navite="removeNodePerson" :disabled="btnDisable">删除</el-button>
      </div>
      <ws-page-table
          :data="tableData"
          ref="auditNodePersonConfigurationPageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          selection="single"
      >
        <el-table-column prop="auditRoleName" label="审核角色" min-width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="auditorName" label="审核人员" min-width="90" show-overflow-tooltip></el-table-column>
      </ws-page-table>

    </div>

    <audit-node-person-configuration-edit :enum-map="enumMap" ref="nodePersonEdit"
                                          @editCompleted="loadTableData"></audit-node-person-configuration-edit>
  </div>
</template>

<script>
import auditNodePersonConfigurationEdit from './components/auditNodePersonConfigurationEdit';
import pageTableMixin from "@/mixin/pageTableMixin.js";
import auditFlowService from "API/ba/auditFlowService"
import Objects from "COMJS/Objects"

export default {
  name: "auditNodePersonConfigurationList",
  components: {
    auditNodePersonConfigurationEdit: auditNodePersonConfigurationEdit
  },
  props: {
    nodeRoleInfo: {
      type: Object,
      default: ''
    }
  },
  mixins: [pageTableMixin],
  data() {
    return {
      searchInfo: {auditNodeRoleId: this.nodeRoleInfo.auditNodeRoleId, auditNodePersonCode: '', state: '1'},
      items: {
        auditNodePersonCode: {filter: "like"},
        auditNodeRoleId: {filter: "EQ"},
        state: {filter: "EQ"}
      },
      sorts: {
        auditNodePersonCode: {order: "asc"},
        createTime: {order: "desc"}
      },
      enumMap: {},
      btnDisable: false
    };
  },
  mounted() {
    //this.initPage();
  },
  methods: {

    /**初始化页面*/
    initPage() {
      /*let types = "AGENCY_LEVEL,AGENCY_STATE,OPERATION_TYPE,AUDIT_STATE,AUDIT_ROLE_TYPE,YES_OR_NO";
      bimEnumService.getEnumByTypes(types).then((data) =>{
          this.enumMap = data;
          this.loadTableData();
      }).catch((data) => console.log(data));*/
    },
    /**读取数据*/
    loadTableData() {
      if (Objects.isEmpty(this.searchInfo.auditNodeRoleId)) {
        this.auditNodePersonConfigurationList = [];
        this.pageInfo.total = 0;
      } else {
        this.$refs.auditNodePersonConfigurationPageTable.tableLoading = true;
        auditFlowService.findAuditNodePersonConfigurationPage(this.pageInfo, this.searchConfig).then(data => {
          this.querySuccess(data)
          this.$refs.auditNodePersonConfigurationPageTable.tableLoading = false;
        }).catch(err => {
          this.$refs.auditNodePersonConfigurationPageTable.tableLoading = false;
          console.log(err);
        })
      }

    },
    /**删除*/
    removeNodePerson() {
      let that = this;
      let rows = this.$refs.auditNodePersonConfigurationPageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            that.$refs.auditNodePersonConfigurationPageTable.tableLoading = true;
            let ids = '';
            rows.forEach(o => {
              if (Objects.isEmpty(ids)) {
                ids = o.auditNodePersonId;
              } else {
                ids += ',' + o.auditNodePersonId;
              }
            });
            auditFlowService.removeAuditNodePersonConfigurationInfoByIds(ids).then(() => {
              that.loadTableData();
              that.$refs.auditNodePersonConfigurationPageTable.tableLoading = false;
            }).catch(err => {
              that.$refs.auditNodePersonConfigurationPageTable.tableLoading = false;
            })
          }
      )
    },
    /**新增*/
    addNodePerson() {
      this.$refs.nodePersonEdit.display(this.nodeRoleInfo, 'ADD');
    },
    yesOrNoFormatter(value) {
      let v = "-";
      if (value === '1') {
        v = "是";
      } else {
        v = "否";
      }
      return v;
    }
  },
  watch: {
    nodeRoleInfo(newVal, oldVal) {
      if (Objects.isNotEmpty(newVal)) {
        this.searchInfo.auditNodeRoleId = newVal.auditNodeRoleId;
        this.btnDisable = (newVal.auditRoleType != 'specifyPersons');
      } else {
        this.searchInfo.auditNodeRoleId = '';
        this.btnDisable = true;
      }
      this.loadTableData();
    }

  }
}
</script>

<style scoped>

</style>
