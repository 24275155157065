import axiosHttp from "../axiosHttp";
import tools from "COMJS/tools"

export default {
    queryByInfoAtPage(pageInfo,searchInfo){
        let url = "/dealerSerialInfo/queryByInfoAtPage"
        let param = {
            "pageNo":pageInfo.pageNo,
            "pageSize":pageInfo.pageSize,
            "dealerAccount":searchInfo.dealerAccount,
            "openTime":searchInfo.timeRange.length>0?tools.formatDate(searchInfo.timeRange[0],'yyyy-MM-dd hh:mm:ss'):null,
            "endTime":searchInfo.timeRange.length>1?tools.formatDate(searchInfo.timeRange[1],'yyyy-MM-dd hh:mm:ss'):null
            //"openTime":searchInfo.timeRange.length>0?searchInfo.timeRange[0].valueOf():null,
            //"endTime":searchInfo.timeRange.length>1?searchInfo.timeRange[1].valueOf():null
        }
        //console.log(param.openTime)
        //console.log(param.endTime)
        return axiosHttp.POST(url,param)
    },
    queryAtPage(pageInfo,searchConfig){
        let url = "/dealerSerialInfo/queryAtPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    save(param){
        let url = "/dealerSerialInfo/save"
        return axiosHttp.POST(url,param)
    },
    findBySerialNumber(id) {
        return axiosHttp.GET(`/dealerSerialInfo/findBySerialNumber/${id}`)
    },
    querySerialInStateAtPage(param) {
        return axiosHttp.POST(`/dealerSerialInfo/querySerialInStateAtPage`,param)
    },
    delSerialNumber(list) {
        return axiosHttp.POST(`/dealerSerialInfo/delSerialNumber`,list)
    },

}