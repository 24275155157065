import axiosHttp from "../axiosHttp"

export default {
    restartAll(){
        let url = "/taskDefine/restartAll"
        return axiosHttp.POST(url, {});
    },
    implementOnce(id){
        let url = "/taskDefine/implementOnce"
        return axiosHttp.POST(url, {taskId:id});
    },
    queryTaskAtPage(pageInfo, searchConfig) {
        let url = "/taskDefine/queryTaskAtPage"
        return axiosHttp.POST(url,pageInfo);
    },
    saveTaskDefine(param){
        let url = "/taskDefine/saveTaskDefine"
        return axiosHttp.POST(url,param);
    },
    delTaskById(id){
        let url = `/taskDefine/delTaskById/${id}`
        return axiosHttp.GET(url);
    },
    queryTaskPlanAtPage(id){
        let url = "/taskDefine/queryTaskPlanAtPage"
        let param={
            pageNo:1,
            pageSize:99,
            taskId:id
        }
        return axiosHttp.POST(url,param);
    },
    saveTaskPlanDefine(param){
        let url = "/taskDefine/saveTaskPlanDefine"
        return axiosHttp.POST(url,param,true);
    },
    delTaskPlan(id){
        let url = `/taskDefine/delTaskPlan/${id}`
        return axiosHttp.GET(url);
    }

}