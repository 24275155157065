<template>
  <el-dialog :visible.sync="dialogVisible" title="预览" top="1vh">
    <iframe :src="src"
            width="100%" height="800px"></iframe>
  </el-dialog>
</template>

<script>
export default {
  name: "pdfPreview",
  data() {
    return {
      dialogVisible: false,
      src:'',
    }
  },
  methods:{
    show(src){
      if(src){
        src = src.replace('http://','https://')
        this.src = src;
      }
      this.dialogVisible = true;
    },
    hide(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>

</style>