<template>
  <div>
    <el-dialog :title="title" :visible="dialogVisible" top="10%" @close="closeDialog" :close-on-click-modal="false">
      <el-form :model="config" :rules="rules" ref="editForm" label-width="110px" v-loading="formLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="业务审核编码" prop="auditConfigurationCode">
              <el-input v-model="config.auditConfigurationCode" :maxlength="32"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务审核说明" prop="auditConfigurationName">
              <el-input v-model="config.auditConfigurationName" :maxlength="64"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click.native="closeDialog">关 闭</el-button>
            <el-button type="primary" @click.native="submit">保 存</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>

import {mapActions} from 'vuex';
import Objects from "COMJS/Objects";
import auditFlowService from "API/ba/auditFlowService"

export default {
  name: "auditConfigEdit",
  components: {},
  data() {
    let rules = {
      auditConfigurationCode: [
        {required: true, message: '请输入审核配置编码'},
        {max: 16, message: '长度不能超过32个字符', trigger: 'blur'},
      ],
      auditConfigurationName: [
        {required: true, message: '请输入审核配置说明'},
        {max: 16, message: '长度不能超过64个字符', trigger: 'blur'},
      ],
    }
    return {
      title: '',
      formLoading: false,
      dialogVisible: false,
      scene: '',
      config: {},
      rules: rules
    };
  },
  methods: {
    /**关闭配置弹窗*/
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.editForm.resetFields();
    },
    /**显示审核配置弹窗*/
    display(config, scene) {
      this.scene = scene;
      this.formLoading = true;
      if ('ADD' === scene) {
        this.title = '新增审核配置';
        this.config = {};
        this.formLoading = false;
      } else if ('EDIT' === scene) {
        this.title = '编辑审核配置';
        this.config = Objects.deepCopy({}, config);
        this.formLoading = false;
      }

      this.dialogVisible = true;
    },

    /**提交审核配置*/
    submit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.formLoading = true;
          let config = this.config;
          if ('ADD' === this.scene) {
            auditFlowService.saveAuditConfigurationInfo(config).then(data => {
              this.closeDialog();
              this.formLoading = false;
              this.$emit('editCompleted');
            }).catch((err) => {
              this.formLoading = false;
            });
          } else if ('EDIT' === this.scene) {
            auditFlowService.updateAuditConfigurationInfo(config).then(data => {
              this.closeDialog();
              this.formLoading = false;
              this.$emit('editCompleted');
            }).catch((err) => {
              this.formLoading = false;
            });
          }
        }
      })

    }

  }
}
</script>

<style scoped>

</style>
