import axiosHttp from "../axiosHttp";

export default {
    /*queryWhmPages(params){
        return axiosHttp.GET('/whmSku/query',params)
    },*/
    queryProductRelatePages(pageInfo, searchConfig) { //商品关联库品分页信息
        // console.log(pageInfo)
        // console.log(searchConfig)
        let url = "/whmGoodsToSku/queryAtPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryProductRelateDetail(id) { //根据商品Id查询关联信息
        return axiosHttp.GET(`whmGoodsToSku/findByGoods/${id}`)
    },
    saveProductRelateDetail(params) {
        return axiosHttp.POST("whmGoodsToSku/save", params, false)
    },
    syncMallGoods(params) {
        return axiosHttp.POST("whmGoodsToSku/syncMallGoods", params, false)
    }
}