const frame = {template: '<router-view></router-view>'}

export default {
    path: 'usr',
    component: frame,
    children: [
        {
            name: '用户管理',
            path: 'userManager',
            component: resolve => import('PAGES/usr/userManager.vue')
        }
    ]
}