<template>
  <div>
    <div class="mt15">
      <el-button type="primary" @click="createStep0">创建采购单</el-button>
    </div>
    <div class="mt15">
      <el-tabs v-model="active" type="card" @tab-click="gotoStep">
        <el-tab-pane label="采购单" name="0"></el-tab-pane>
        <el-tab-pane label="发货单" name="1"></el-tab-pane>
        <el-tab-pane label="收货单" name="2"></el-tab-pane>
        <!--
        <el-tab-pane label="调用单" name="3"></el-tab-pane>
        -->
      </el-tabs>
    </div>
    <div class="mt15">
      <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                     @pageChange="loadTableData">
        <el-table-column prop="materialName" label="物料名称"></el-table-column>
        <el-table-column prop="materialSupplierName" label="供应商"></el-table-column>
        <el-table-column prop="batchCode" label="批次号"></el-table-column>
        <el-table-column prop="processNum" label="数量"></el-table-column>
        <el-table-column prop="processTime" label="时间">
          <template slot-scope="scope">
            {{
              scope.row.processTime != null
                  ? format(new Date(scope.row.processTime))
                  : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="shipperName" label="物流"></el-table-column>
        <el-table-column prop="warehouseName" label="仓库"></el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <div class="operate-column">
              <a @click="gotoDetail(scope.row)">
                <i class="el-icon-tickets"/>操作
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <!--step0CreateDialog-->
    <step0-create-dialog @reloadAfterSave="loadTableData()" ref="step0CreateDialog"></step0-create-dialog>
  </div>
</template>

<script>
import tools from "COMJS/tools";
import tableMixins from "@/mixin/pageTableMixin.js";
import materialProcessService from "API/pur/PurMaterialProcessService"
import step0CreateDialog from "./materialProcessDialog/Step0CreateDialog"

export default {
  name: "MaterialProcessManager",
  mixins: [tableMixins],
  components: {
    step0CreateDialog,
  },
  data() {
    return {
      active: 0,
      materialList:[],
    };
  },
  mounted() {
  },
  methods: {
    loadTableData() {
      let param = {
        'processStep': this.active,
        'flagWithName':'1',
        'pageSize': this.pageInfo.pageSize,
        'pageNo': this.pageInfo.pageNo,
      }
      this.$refs.pageTable.tableLoading = true
      materialProcessService.queryPage(param).then(res => {
        //console.log(res.list)
        this.querySuccess(res);
      }).finally(() => {
        this.$refs.pageTable.tableLoading = false;
      })
    },
    gotoStep() {
      this.loadTableData()
    },
    createStep0(){
      this.$refs.step0CreateDialog.show()
    },
    gotoDetail(row){
      let id = row.materialProcessId
      this.$router.push(`/pur/materialDetail/${id}`)
    },
    format(date) {
      return tools.formatDate(date, "yyyy-MM-dd");
    },
  }
}
</script>

<style scoped>
.operate-column{
  display: flex;
}
.operate-column > a {
  margin-right: 20px;
  cursor: pointer;
  color: #00a0e9;
}
.img-icon{
  height: 20px;
  width: 20px;
}
</style>