import { Loading } from 'element-ui';
function drawShareImg(qrcodeSrc) {
    const loading = Loading.service({
        lock: true,
        text: 'Loading'
    });
    let MULT = 2; // 倍数
    let backgroudImgSrc = "/imgs/shareBackground.png"; // 背景图地址
    let c = document.createElement("canvas"),
        ctx = c.getContext("2d");
    c.width = 375 * MULT;
    c.height = 667 * MULT;
    ctx.rect(0, 0, c.width, c.height);
    ctx.fillStyle = "#fff";
    ctx.fill();
    var img_backgroud = new Image();
    var img_qrcode = new Image();

    img_backgroud.src = backgroudImgSrc;
    img_backgroud.onload = function () {
        ctx.drawImage(img_backgroud, 0, 0, c.width, c.height);
        img_qrcode.src = qrcodeSrc;
        img_qrcode.onload = function () {
            ctx.drawImage(
                img_qrcode,
                141 * MULT,
                462 * MULT,
                94 * MULT,
                94 * MULT
            );

            let base64 = c.toDataURL(); //通过canvas.toDataURL转成base64
            let aLink = document.createElement("a");
            aLink.setAttribute("download", "");
            aLink.setAttribute("href", base64);
            document.body.appendChild(aLink);
            console.log(aLink);
            aLink.click();
            setTimeout(() => {
                document.body.removeChild(aLink);
            }, 1000)
            loading.close();
        };
    };
}

export default drawShareImg