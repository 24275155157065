export default function Set(arr = []) {    // 可以传入数组

  let items = {};

  this.size = 0;  // 记录集合中成员的数量

  // has(val)方法
  this.has = function(val) {
    // 对象都有hasOwnProperty方法，判断是否拥有特定属性
    return items.hasOwnProperty(val);
  };
  // add(val)方法
  this.add = function(val) {
    if (!this.has(val)) {
      items[val] = val;
      this.size++;    // 累加集合成员数量
      return true;
    }
    return false;
  };
  // delete(val)方法
  this.delete = function(val) {
    if (this.has(val)) {
      delete items[val];  // 将items对象上的属性删掉
      this.size--;
      return true;
    }
    return false;
  };
  // clear方法
  this.clear = function() {
    items = {};     // 直接将集合赋一个空对象即可
    this.size = 0;
  };

  // keys()方法
  this.keys = function() {
    return Object.keys(items);  // 返回遍历集合的所有键名的数组
  };

  // values()方法
  this.values = function() {
    return Object.values(items);  // 返回遍历集合的所有键值的数组
  };
}