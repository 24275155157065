<template>
  <div class="main-content">
    <div class="tree-ground">
      <level-tree-item
          ref="firstTree"
          treeName="机构"
          :list="groupList"
          :canSee="false"
          :canEdit="true"
          :canDelete="false"
          :canAdd="true"
          @seeDetail="seeDetail"
          @edit="seeEdit"
          @add="addFirstTree"
          @deleteNode="deleteNode"
          @selectNode="getSecondTreeDataInside"
      ></level-tree-item>
      <level-tree-item
          ref="secondTree"
          treeName="主播"
          :list="anchorList"
          :canSee="false"
          :canEdit="true"
          :canAdd="canSecondTreeAdd"
          :canDelete="false"
          @seeDetail="seeDetail"
          @edit="seeEdit"
          @add="addSecondTree"
          @deleteNode="deleteNode"
          @selectNode="getThirdTreeDataInside"
      ></level-tree-item>
      <level-tree-item
          ref="thirdTree"
          treeName="样品单"
          :list="sampleList"
          :canSee="true"
          :canEdit="false"
          :canAdd="canThirdTreeAdd"
          :canDelete="false"
          :isArrow="false"
          @seeDetail="seeDetail"
          @edit="seeEdit"
          @add="addThirdTree"
          @deleteNode="deleteNode"
      ></level-tree-item>
    </div>
    <!--机构信息-->
    <el-dialog title="机构信息" :visible.sync="groupDialogVisible" width="560px">
      <el-form label-width="80px">
        <el-form-item label="机构名称" required>
          <el-input v-model="groupEditData.groupName"
                    :disabled="groupEditData.groupId!=null"
                    placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="机构编码" required>
          <el-input v-model="groupEditData.groupCode"
                    :disabled="groupEditData.groupId!=null"
                    placeholder="编码"></el-input>
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="groupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="groupEditDialogConfirm" v-if="groupEditData.groupId==null">确 认</el-button>
      </div>
    </el-dialog>
    <!--主播信息-->
    <el-dialog title="主播信息" :visible.sync="anchorDialogVisible" width="560px">
      <el-form label-width="80px">
        <el-form-item label="直播平台" required>
          <el-select v-model="anchorEditData.livePlatformCode"
                     :disabled="anchorEditData.anchorId!=null"
                     placeholder="请选择">
            <el-option
                v-for="item in livePlatformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主播名称" required>
          <el-input v-model="anchorEditData.anchorName"
                    :disabled="anchorEditData.anchorId!=null"
                    placeholder="名称"></el-input>
          <!--p style="color: red;">* 主播在平台的昵称，非主播真实姓名</p-->
        </el-form-item>
        <el-form-item label="主播ID">
          <el-input v-model="anchorEditData.anchorPlatformId"
                    :disabled="anchorEditData.anchorId!=null"
                    :placeholder="anchorPlatformIdNick"></el-input>
          <!--p style="color: red;">* 主播在平台的ID，如抖音平台的UID</p-->
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="anchorDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="anchorEditDialogConfirm" v-if="anchorEditData.groupId==null">确 认</el-button>
      </div>
    </el-dialog>
    <!--创建样品单-->
    <el-dialog title="样品记录" :visible.sync="sampleDialogVisible" width="900px">
      <div v-if="sampleCreateData.anchorSampleId!=null">
        <div>
          <span style="margin-right: 20px;">申请时间：{{ datetimeFormatter(sampleCreateData.createTime) }}</span>
          <span v-if="sampleCreateData.sampleAuditState=='0'" style="color: red;">样品单待审核</span>
          <span v-if="sampleCreateData.sampleAuditState=='1'"
                style="margin-right: 20px;">订单号：{{ sampleCreateData.orderCode }}</span>
          <span v-if="sampleCreateData.sampleAuditState=='1'">快递信息：{{ sampleCreateData.expressInfo }}</span>
          <span v-if="sampleCreateData.sampleAuditState=='2'" style="color: red;">样品单审核不通过</span>
          <el-button
              @click="delSampleRecord"
              v-if="sampleCreateData.sampleAuditState=='0'"
              :disabled="sampleCreateData.sampleAuditState!='0'"
              style="float: right; padding: 3px 0" type="text">取消
          </el-button>
        </div>
        <div>
          <p style="margin-top: 10px;">
            <span style="margin-right: 5px;">{{ sampleCreateData.recipientName }}</span>
            <span style="margin-right: 5px;">{{ sampleCreateData.recipientPhoneNum }}</span>
          </p>
          <p style="margin-top: 10px;">
            <span style="margin-right: 5px;">{{ sampleCreateData.recipientProvince }}</span>
            <span style="margin-right: 5px;">{{ sampleCreateData.recipientCity }}</span>
            <span style="margin-right: 5px;">{{ sampleCreateData.recipientCounty }}</span>
            <span>{{ sampleCreateData.recipientAddressDetail }}</span>
          </p>
        </div>
        <el-divider content-position="left">样品信息</el-divider>
        <div style="display: flex;margin-top: 20px;flex-wrap: wrap;">
          <div v-for="g in sampleCreateData.sampleGoodsList" style="display: flex;margin: 10px 10px 0 0;">
            <img class="goods_pic" :src="g.pictureUrl">
            <div style="display: flex;flex-direction:column;justify-content: start;">
              <p>{{ g.cnName }}</p>
              <p>{{ g.goodsCode }}</p>
              <div style="height: 100%;"></div>
              <p>x {{ g.num }}</p>
            </div>
          </div>
        </div>
        <el-divider content-position="left" v-if="sampleCreateData.remark">备注内容</el-divider>
        <span v-if="sampleCreateData.remark">{{ sampleCreateData.remark }}</span>
      </div>
      <div v-else>
        <el-form ref="sampleForm" :model="sampleCreateData" label-width="80px">
          <el-form-item label="收货人" required>
            <el-input v-model="sampleCreateData.recipientName" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" required>
            <el-input v-model="sampleCreateData.recipientPhoneNum"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" required>
            <area-cascader v-model="sampleCreateData.pcc"></area-cascader>
            <el-input
                style="margin-top: 10px;width: 100%;"
                v-model="sampleCreateData.recipientAddressDetail"
                placeholder="请填写详细地址"></el-input>
            <!--
            <span style="color: red;">* 暂不支持海外地址寄样，如遇海外主播，麻烦您自行转寄</span>
            -->
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="sampleCreateData.remark" maxlength="200"
                      placeholder="可填写备注，比如指定快递"
                      style="width: 100%;"></el-input>
          </el-form-item>
          <el-form-item label="选择样品" required>
            <el-button size="small" plain type="primary"
                       @click="$refs.selectGoodsPopup.dialogVisible = true">添加样品
            </el-button>
            <el-table :data="sampleCreateData.goodsList" v-if="sampleCreateData.goodsList.length>0">
              <el-table-column label="样品" prop="cnName" :show-overflow-tooltip="true" min-width="200"/>
              <el-table-column label="编号" prop="goodsCode" width="150"/>
              <el-table-column label="数量" prop="num" width="200">
                <template slot-scope="scope">
                  <el-input-number
                      :min="1" :max="20"
                      size="small" v-model="scope.row.num"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="删除" width="100">
                <template slot-scope="scope">
                  <div>
                    <i @click="delSampleGoods(scope.$index)"
                       style="cursor: pointer;"
                       class="el-icon-circle-close"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" @click="addSampleRecord">确认申请</el-button>
        </div>
      </div>
    </el-dialog>
    <select-goods-popup ref="selectGoodsPopup"
                        goodsOrderType="sample"
                        @submit="selectGoodsToSample"></select-goods-popup>
  </div>
</template>

<script>
import levelTreeItem from "COMPS/widgets/levelTreeItem"
import selectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";
import areaCascader from "COMPS/base/areaCascader.vue";
import lsAnchorService from "@/api/ls/LsAnchorService";
import tools from "COMJS/tools";

export default {
  name: "LsAnchorManager",
  data() {
    return {
      //
      groupList: [],
      anchorList: [],
      sampleList: [],
      groupSelected: {},
      anchorSelected: {},
      sampleSelected: {},
      //
      groupDialogVisible: false,
      groupEditData: {},
      //
      anchorDialogVisible: false,
      anchorEditData: {},
      livePlatformList: [{label: '抖音', value: 'DY'}, {label: '快手', value: 'KS'}, {label: '视频号', value: 'SPH'}],
      //
      sampleDialogVisible: false,
      sampleCreateData: {pcc: {province: "", city: "", area: ""}, goodsList: []},
    }
  },
  components: {
    levelTreeItem,
    areaCascader,
    selectGoodsPopup
  },
  computed: {
    anchorPlatformIdNick() {
      return this.anchorEditData.livePlatformCode == 'DY' ? 'UID' : 'ID';
    },
    canSecondTreeAdd() {
      return this.groupSelected.groupId != null;
    },
    canThirdTreeAdd() {
      return this.anchorSelected.anchorId != null;
    },
  },
  mounted() {
    this.getTreeData();
  },
  methods: {
    getTreeData() {
      let param = {
        flagWithAS: "1"
      }
      lsAnchorService.queryGroupList(param).then(res => {
        this.groupList = res.map((e) => {
          let name = e.groupName
          /*if (e.groupCode) {
            name = name + ' || ' + e.groupCode
          }*/
          let info = ""
          if (e.anchorCount) {
            info = '主播：' + e.anchorCount
          }
          if (e.anchorSampleCount) {
            if (info != "") {
              info = info + '，'
            }
            info = info + '样品：' + e.anchorSampleCount
          }
          if (info != "") {
            name = name + '（' + info + '）';
          }

          return {
            ...e,
            name: name,
            id: e.groupId,
            _type: 'group'
          };
        })
        //表示这个时候有选中了，重新赋值
        if (this.groupSelected && this.groupSelected.groupId != null) {
          let targetId = this.groupSelected.groupId
          let tempList = this.groupList.filter(a => a.groupId == targetId)
          if (tempList && tempList.length > 0) {
            this.getSecondTreeDataInside(tempList[0])
          }
        }
      })
    },
    seeDetail(node) {
      this.seeEdit(node)
    },
    seeEdit(node) {
      if (node._type == 'group') {
        this.groupEditData = {
          ...node
        }
        this.groupDialogVisible = true;
      } else if (node._type == 'anchor') {
        this.anchorEditData = {
          ...node
        }
        this.anchorDialogVisible = true;
      } else if (node._type == 'sample') {
        this.sampleCreateData = {
          ...node
        }
        this.sampleDialogVisible = true;
      }
      //console.log(node)
    },
    deleteNode(node) {
      console.log(node)
    },
    groupEditDialogConfirm() {
      if (!this.groupEditData.groupName) {
        this.$message.warning("请填写机构名称");
        return;
      }
      if (!this.groupEditData.groupCode) {
        this.$message.warning("请填写机构编码");
        return;
      }
      let param = {
        ...this.groupEditData
      }
      let loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      lsAnchorService.createLsGroup(param).then(res => {
        this.getTreeData()
        loading.close()
        this.groupDialogVisible = false
        this.groupEditData = {}
      })
    },
    anchorEditDialogConfirm() {
      if (!this.anchorEditData.livePlatformCode) {
        this.$message.warning("请选择直播平台");
        return;
      }
      if (!this.anchorEditData.anchorName) {
        this.$message.warning("请填写主播名称");
        return;
      }
      let arr = this.livePlatformList.filter(e => this.anchorEditData.livePlatformCode == e.value)
      if (arr && arr.length > 0) {
        this.anchorEditData.livePlatformName = arr[0].label
      }
      this.anchorEditData.groupId = this.groupSelected.groupId
      let param = {
        ...this.anchorEditData
      }
      let loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      lsAnchorService.saveLsAnchor(param).then(res => {
        this.getTreeData()
        loading.close()
        this.anchorDialogVisible = false
        this.anchorEditData = {}
      })
    },
    getSecondTreeDataInside(node) {
      if (this.groupSelected && this.groupSelected.groupId && this.groupSelected.groupId != node.groupId) {
        //换了
        this.anchorSelected = {};
        this.sampleList = []
      }
      this.groupSelected = node;
      let list = node.anchorList;
      this.anchorList = list.map((e) => {
        let name = e.anchorName
        if (e.livePlatformName) {
          name = e.livePlatformName + ' || ' + name
        }
        if (e.anchorSampleList && e.anchorSampleList.length > 0) {
          name = name + '（样品：' + e.anchorSampleList.length + '）'
        }
        return {
          ...e,
          name: name,
          id: e.anchorId,
          _type: 'anchor'
        };
      })
      //表示这个时候有选中了，重新赋值
      if (this.anchorSelected && this.anchorSelected.anchorId != null) {
        let targetId = this.anchorSelected.anchorId
        let tempList = this.anchorList.filter(a => a.anchorId == targetId)
        if (tempList && tempList.length > 0) {
          //console.log(tempList[0])
          this.getThirdTreeDataInside(tempList[0])
        }
      }
    },
    getThirdTreeDataInside(node) {
      this.anchorSelected = node;
      let list = node.anchorSampleList;
      this.sampleList = list.map((e) => {
        let name = ''
        if(e.sampleAuditState=='0'){
          name = "样品单待审核"
        }else if(e.sampleAuditState=='2'){
          name = "样品单审核不通过"
        }else{
          if (e.orderCode) {
            name = e.orderCode + ' || '
          }
          if (e.expressInfo) {
            name = name + e.expressInfo
          }
        }
        return {
          ...e,
          name: name,
          id: e.anchorSampleId,
          _type: 'sample'
        };
      })
    },
    addFirstTree() {
      this.groupEditData = {}
      this.groupDialogVisible = true
    },
    addSecondTree() {
      if (this.groupSelected.groupId == null) {
        return
      }
      this.anchorEditData = {}
      this.anchorDialogVisible = true;
    },
    addThirdTree() {
      if (this.anchorSelected.anchorId == null) {
        return
      }
      this.sampleCreateData = {pcc: {province: "", city: "", area: ""}, goodsList: []};
      this.sampleDialogVisible = true;
    },
    selectGoodsToSample(selected) {
      let temp = this.sampleCreateData.goodsList
      for (const ele of selected) {
        let item = {
          goodsId: ele.goodsId,
          goodsCode: ele.goodsCode,
          cnName: ele.cnName,
          pictureUrl: ele.pictureUrl,
          num: 0
        }
        temp.push(item)
      }
      this.sampleCreateData = {
        ...this.sampleCreateData,
        goodsList: temp
      }
    },
    delSampleGoods(idx) {
      let temp = this.sampleCreateData.goodsList
      temp.splice(idx, 1)
      this.sampleCreateData = {
        ...this.sampleCreateData,
        goodsList: temp
      }
    },
    addSampleRecord() {
      if (!this.sampleCreateData.recipientName
          || !this.sampleCreateData.recipientPhoneNum
          || !this.sampleCreateData.pcc.province
          || !this.sampleCreateData.pcc.city
          || !this.sampleCreateData.pcc.area
          || !this.sampleCreateData.recipientAddressDetail) {
        return this.$message.warning("请完善收件信息");
      }
      if (this.sampleCreateData.goodsList.length == 0) {
        return this.$message.warning("样品不可为空");
      }
      let sampleGoodsList = []
      for (let idx in this.sampleCreateData.goodsList) {
        let item = this.sampleCreateData.goodsList[idx]
        sampleGoodsList.push({
          goodsId: item.goodsId,
          goodsCode: item.goodsCode,
          cnName: item.cnName,
          pictureUrl: item.pictureUrl,
          num: item.num
        })
      }
      let param = {
        anchorId: this.anchorSelected.anchorId,
        recipientName: this.sampleCreateData.recipientName,
        recipientPhoneNum: this.sampleCreateData.recipientPhoneNum,
        recipientProvince: this.sampleCreateData.pcc.province,
        recipientCity: this.sampleCreateData.pcc.city,
        recipientCounty: this.sampleCreateData.pcc.area,
        recipientAddressDetail: this.sampleCreateData.recipientAddressDetail,
        remark: this.sampleCreateData.remark,
        sampleGoodsList: sampleGoodsList,
        applyFromAdm: "1"
      }
      let loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      lsAnchorService.applyAnchorSample(param).then(res => {
        this.getTreeData()
        loading.close()
        this.sampleDialogVisible = false
        this.sampleCreateData = {pcc: {province: "", city: "", area: ""}, goodsList: []};
      })
    },
    delSampleRecord() {
      if (this.sampleCreateData && this.sampleCreateData.anchorSampleId!=null){
        let param = {
          anchorSampleId: this.sampleCreateData.anchorSampleId
        }
        let loading = this.$loading({
          lock: true,
          text: "取消中...",
        });
        lsAnchorService.removeSampleRecord(param).then(res=>{
          this.getTreeData()
          loading.close()
          this.sampleDialogVisible = false
          this.sampleCreateData = {pcc: {province: "", city: "", area: ""}, goodsList: []};
        })
      }

    },
    datetimeFormatter(time, fmt = "yyyy-MM-dd") {
      //return tools.formatDate(new Date(time), "yyyy-MM-dd hh:mm:ss");
      return tools.formatDate(new Date(time), fmt);
    },
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
}

.tree-ground {
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.goods_pic {
  width: 50px;
  /*border: 1px solid rgba(218, 218, 218, 1);*/
}
</style>