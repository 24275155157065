<template>
  <div class="logistics-edit">

    <div class="log-head">
      <div class="log-head-box">
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">发货方：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.senderOrganize"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">发货时间：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.shipTime"></el-input>              
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">发货人：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.senderName"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">发货方电话：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.senderPhone"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="tab-label">发货方地址：</div>
            <div class="tab-inp">
              <el-input class="inp340" disabled v-model="whmInfo.senderAddress"></el-input>
            </div>
        </div>
      </div>

      <div class="log-head-box">
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">收货方：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receivingOrganize"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">收货时间：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.finishTime"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">收货人：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receiverName"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">收货方电话：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receivingPhone"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="tab-label">收货方地址：</div>
            <div class="tab-inp">
              <el-input class="inp340" disabled v-model="whmInfo.receivingAddress"></el-input>
            </div>
        </div>
      </div>

      <div class="log-head-box mt60">
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">承运单号：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.carrierAppCode"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">承运时间：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.carrierTime"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">承运人：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.carrierOrganize"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">承运人电话：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.carrierPhone"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">承运公司：</div>
              <div class="tab-inp">
                <el-input class="inp118" disabled v-model="whmInfo.carrierCompany"></el-input>
              </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">承运车辆：</div>
              <div class="tab-inp">
                <el-input class="inp118" disabled v-model="whmInfo.carrierCar"></el-input>
              </div>
          </div>
        </div>  
      </div>      
    </div>

    <ws-page-table
      :data="goods"
      ref="pageTable"
      :pageable="false"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <!-- <el-table-column prop="goodsName" :show-overflow-tooltip="true" label="商品名称"></el-table-column> -->
      <el-table-column prop="cnName" :show-overflow-tooltip="true" label="商品名称"></el-table-column>
      <el-table-column prop="goodsCode" :show-overflow-tooltip="true" label="商品编号"></el-table-column>
      <el-table-column prop="skuInfoList" :show-overflow-tooltip="true" label="库品信息"></el-table-column>
      <el-table-column prop="goodsBarCode" :show-overflow-tooltip="true" label="商品条形码"></el-table-column>
      <el-table-column prop="skuPurchaseBatchCode" :show-overflow-tooltip="true" label="批次号"></el-table-column>
      <el-table-column prop="productionDate" :show-overflow-tooltip="true" label="生产日期"></el-table-column>
      <el-table-column prop="goodsCount" :show-overflow-tooltip="true" label="商品数量"></el-table-column>
    </ws-page-table>

    <div class="log-bot">
      <div class="log-bot-list">
        <div class="log-bot-lef mr10">订单备注：</div>
        {{whmInfo.orderRemark}}
        <!-- <el-input v-model="whmInfo.orderRemark" type="textarea" disabled></el-input> -->
      </div>
      <div class="log-bot-list">
        <div class="log-bot-lef mr10">备注：</div>
        {{whmInfo.remark}}
        <!-- <el-input v-model="whmInfo.remark" type="textarea" disabled></el-input> -->
      </div>
      <div class="log-bot-list">
        <div class="log-bot-lef">送货方式：</div>
        {{whmInfo.deliveryMethod == "0" ? "到门" : whmInfo.deliveryMethod == "1" ? "自提" : ""}}
      </div>  
      <div class="log-bot-list">
        <div class="log-bot-lef mr10">运费金额：</div>
        {{whmInfo.transportFee}}
      </div> 
      <div class="log-bot-list">
        <div class="log-bot-lef">运费付款：</div>
        {{whmInfo.transportationCostMethod == "0" ? "寄付" : whmInfo.transportationCostMethod == "1" ? "到付" : ""}}
      </div> 
    </div>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],      
      input:"",
      whmInfo: {},
      goods:[],
      senderOrganizeType: [],
      searchInfo:{        
        outboundDocumentsId: ""
      },
      items: {
        outboundDocumentsId: { filter: "EQ" },
      },
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.getOutboundList()
    this.getGoodsDetail();
  },
  methods: {
    selectChange(idx){
      //console.log(val)
      let senderOrganizeType = this.senderOrganizeType[idx]
      //console.log(senderOrganizeType)
      // this.whmInfo = {
        this.whmInfo.outboundSenderInfoId = senderOrganizeType.outboundSenderInfoId,
        this.whmInfo.senderOrganize = senderOrganizeType.senderOrganize,
        this.whmInfo.senderTime = senderOrganizeType.senderTime,
        this.whmInfo.senderName = senderOrganizeType.senderName,
        this.whmInfo.senderPhone = senderOrganizeType.senderTel,
        this.whmInfo.senderAddress = senderOrganizeType.senderAddress        
      //}
      //console.log(this.whmInfo)
    },
    getOutboundList(){
      // WhmList.findDefault().then((data) => {
      //   data.list = data.list.map((e) => {
      //     return {
      //       outboundSenderInfoId: e.outboundSenderInfoId,
      //       senderOrganize: e.senderOrganize,
      //       senderName: e. senderName,
      //       senderTel: e.senderTel,
      //       senderAddress: e.senderAddress,
      //     };
      //   });
      //   this.senderOrganizeType = data.list
      //   this.querySuccess(data);
      // });
      this.pageInfo.pageSize = 1000 //获取所有的发货信息
      WhmList.pageOutboundList(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          return {
            outboundSenderInfoId: e.outboundSenderInfoId,
            senderOrganize: e.senderOrganize,
            senderName: e. senderName,
            senderTel: e.senderTel,
            senderAddress: e.senderAddress,
            defaultOption: e.defaultOption == 1 ? true : false,
          };
        });
        this.senderOrganizeType = data.list
        // this.querySuccess(data);
      });
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailLogistics(this.$route.params.id).then((data) => {
        this.whmInfo = data
        this.goods = this.whmInfo.pimGoodsDTOS
        // this.transport = this.whmInfo.whmOutboundTransport
        loading.close();
      });
    },
    // loadTableData() {
    //   //this.searchInfo.outboundDocumentsId = this.$route.params.id
    //   //WhmList.queryLogisticsDetail(this.pageInfo).then((data) => {  
    //   WhmList.queryLogisticsDetail(this.packageParams).then((data) => { 
    //     console.log(data);
    //     for(let i in data.list){
    //       if(data.list[i].skuList.length > 0){
    //         data.list[i].skuInfoList = ""
    //         data.list[i].skuPurchaseBatchCode = ""
    //         data.list[i].productionDate = ""
    //         for(let j in data.list[i].skuList){
    //           if(data.list[i].skuList[j].skuCode){
    //             data.list[i].skuPurchaseBatchCode += data.list[i].skuList[j].skuCode + ":" + data.list[i].skuList[j].skuPurchaseBatchCode + "+"
    //             data.list[i].skuInfoList += data.list[i].skuList[j].skuCode + "*" + data.list[i].skuList[j].skuNum + "+"
    //             data.list[i].productionDate += data.list[i].skuList[j].skuCode + ":" + tools.formatDate(new Date(data.list[i].skuList[j].productionDate-0), "yyyy-MM-dd hh:mm:ss") + "+"
    //           }
    //         }
    //         data.list[i].skuPurchaseBatchCode = data.list[i].skuPurchaseBatchCode.substring(0, data.list[i].skuPurchaseBatchCode.length-1);
    //         data.list[i].skuInfoList = data.list[i].skuInfoList.substring(0, data.list[i].skuInfoList.length-1);
    //         data.list[i].productionDate = data.list[i].productionDate.substring(0, data.list[i].productionDate.length-1);
    //       }
    //     }    
    //     data.list = data.list.map((e) => {
    //       return {
    //         goodsName: e.goodsName,
    //         goodsCode: e.goodsCode,
    //         skuInfoList: e.skuInfoList,
    //         goodsPurchaseBatchCode: e.goodsPurchaseBatchCode,
    //         goodsBarCode: e.goodsBarCode,
    //         skuPurchaseBatchCode: e.skuPurchaseBatchCode,
    //         productionDate: e.productionDate,
    //         num: e.num,
    //       };
    //     });
    //     this.querySuccess(data);
    //   });      
    // },   
  },
};
</script>
<style>
.logistics-edit .log-head{ margin:0 0 30px 0; display: flex; flex-wrap: wrap;}
.logistics-edit .log-head-box{ width: 50%;}
.logistics-edit .inp118{ width: 138px;}
.logistics-edit .inp340{ width: 412px;}
.logistics-edit .log-head-list{ margin-top: 10px; display: flex; align-items: center;}
.logistics-edit .log-list-lef,.logistics-edit .log-list-rig{ display: flex; align-items: center;}
.logistics-edit .log-list-lef{ margin-right: 16px;}
.logistics-edit .tab-label{ min-width: 120px; text-align: right;}
.logistics-edit .mt60{ margin-top: 30px;}
.logistics-edit .log-bot{ margin-top: 30px;}
.logistics-edit .log-bot-list{ margin-bottom: 20px; display: flex; align-items: center;}
.logistics-edit .log-bot-lef{ width: 90px; text-align: right;}
.logistics-edit .el-textarea textarea{ width: 50%;}
.logistics-edit .el-input-group--prepend .el-input__inner{ width: 200px;}
.logistics-edit .mr10{ margin-right: 6px;}
.logistics-edit .log-btn{ margin-left: 10px; width: 80px;}
.logistics-edit .el-date-editor--date{ width: 118px;}
</style>