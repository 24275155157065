import axiosHttp from "../axiosHttp";

export default {
  queryPmProjectGroupAll(params) {
    return axiosHttp.POST("/pmProjectGroup/queryPmProjectGroupAll", params);
  },
  queryPmProjectAll(params) {
    return axiosHttp.POST("/pmProject/queryPmProjectAll", params);
  },
  save(params) {
    return axiosHttp.POST("/pmProjectGroup/save", params);
  },
  queryTaskAllByProjectIds(params) {
    return axiosHttp.POST("/pmProjectTask/queryTaskAllByProjectIds", params);
  },
  Tasksave(params) {
    return axiosHttp.POST("/pmProjectTask/save", params);
  },
  queryByInfo(params) {
    return axiosHttp.POST("/pmProject/queryByInfo", params);
  },
  queryTaskLog(params) {
    return axiosHttp.POST("/pmProjectTask/queryTaskLog", params);
  },
  ProjectSave(params) {
    return axiosHttp.POST("/pmProject/save", params);
  },
  setProjectState(params) {
    return axiosHttp.POST("/pmProject/setProjectState", params);
  },
  setTaskState(params) {
    return axiosHttp.POST("/pmProjectTask/setTaskState", params);
  },
  queryProjectLog(params) {
    return axiosHttp.POST("/pmProject/queryProjectLog", params);
  },
  queryByInfoTask(params) {
    return axiosHttp.POST("pmProjectTask/queryByInfo", params);
  },
  saveBatch(params) {
    return axiosHttp.POST("/pmProjectTask/saveBatch", params);
  },
  TaskfindById(projectTaskId) {
    return axiosHttp.GET(`/pmProjectTask/findById/${projectTaskId}`);
  },
  listUserInRole(roleCode) {
    return axiosHttp.GET(`/bimUser/listUserInRole/${roleCode}`);
  },
};
