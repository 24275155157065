import axiosHttp from "../axiosHttp";

export default {
    listObjects(){
        return axiosHttp.GET(`/fileManager/listObjects`)
    },
    // download(key){
    //     return axiosHttp.GET(`/fileManager/download?key=${key}`)
    // },
    download(key){
        return axiosHttp.GET(`/fileManager/download?key=${key}`)
    },
    upload(params) {
        return axiosHttp.POST('/fileManager/upload', params, false);
    },
    remove(params) {
        return axiosHttp.POST('/fileManager/remove',params, false);
    },
    mkdir(params) {
        return axiosHttp.POST(`/fileManager/mkdir`, params, false);
    },
}