<template>
  <div class="invlist-wrap">
    <el-form label-width="120px" :inline="true" class="inventory-form">
      <el-form-item>
        <el-button type="primary" @click="addReceive">新增盘点</el-button>
        <el-button type="primary" @click="invRemind">盘点提醒设置</el-button>
        <el-button type="primary" @click="invalidTap">作废</el-button>
      </el-form-item>
    </el-form>  

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :selection="'multi'"
      :checkboxSelect="checkboxSelectFunc"
    >
      <el-table-column
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="invalidTime"
        label="异常时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="storageState"
        label="出入库状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createUserName"
        label="操作人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="toGroupDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>          
          </div>          
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="盘点提醒设置" :visible.sync="remindDialog" width="606px">
      <div class="inv-remid">
        <div>
          <el-date-picker
            v-model="choiceTime"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="mt12">
          <el-button type="primary" @click="addTimes">添加提醒</el-button>
          <span class="ml30">最多添加三个提醒(请先选择日期)</span>
        </div>
      </div>
     
      <ws-page-table
      :data="timeList"
      ref="pageTable"
      :pageable="false"
      :isShadow="false"
    >
        <el-table-column label="" width="520px">
          <template slot-scope="scope">          
            每月{{ scope.row.alarmDate }}日         
          </template>
        </el-table-column>      
        <el-table-column label="">
          <template slot-scope="scope">
            <div class="icon-operation">
              <!-- <a
                href="javascript: void(0);"
                title="编辑"
                @click="editTimes(scope.row)"
              >
                <i class="CCSFont icon-table_1"></i>
              </a> -->
              <a href="javascript: void(0);" title="删除">
                <i
                  class="el-icon-delete-solid"
                  @click="deleteTimes(scope.row.checkStoreAlarmId)"
                ></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>

      <div class="inv-remin-btn">
        <div class="b2" @click="closeClock">关闭</div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import abnormalQuery from "API/whm/abnormalQuery";
import tools from "COMJS/tools";

export default {
  name: "inventoryList",
  data() {
    return {
      pickerOptions: {//大于28号的日期不可选
        disabledDate(time) {
          return time.getDate() > 28;
        }
      },  
      value: [],
      options: [],    
      remindDialog: false,
      timeList: [],//最多三个
      choiceTime: "",
      addedTime: [], 
      showMessage: false,          
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData()
    this.getTimeList()
  },
  methods: {
    checkboxSelectFunc(row){
      if (row.storageState == "作废" || row.storageState == "已提交") {
        return false // 不禁用
     }else{
        return true // 不禁用
     }
    },
    loadTableData() {
      abnormalQuery.queryCheckStorePage(this.pageInfo, this.searchConfig, this.showMessage).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            invalidTime: tools.formatDate(new Date(e.invalidTime), "yyyy-MM-dd hh:mm:ss"), //异常时间
            storageState: e.storageState,//出入库状态
            createUserName: e.createUserName,//操作人
            updateUserName: e.updateUserName,//更新人
            updateTime: e.updateTime,//更新时间
            checkStoreId: e.checkStoreId,//单据id
          };
        });
        this.querySuccess(data);
      });      
    },
    closeClock(){
      this.remindDialog = false
    },
    addTimes(){
      if(!this.choiceTime){
        this.$message({
            message: '请先选择要添加的日期',
            type: 'warning'
        });
        return
      }
      let timesC = tools.formatDate(new Date(this.choiceTime), "yyyy-MM-dd")
      //console.log(timesC.substring(8))
      if(this.timeList.length <= 2){
        let params = {}
        params.alarmDate = timesC.substring(8)
        // for(let i in this.timeList){
        //   if(this.timeList[i].alarmDate)
        // }
        if(this.timeList){
          const hasTime = this.timeList.some(e => {
            return e.alarmDate == params.alarmDate
          })
          if(hasTime){
            this.$message({
              message: '已经添加了该日期的提醒',
              type: 'warning'
            });
            return
          }
        }
        abnormalQuery.clockSave(params).then((res) => {
          this.choiceTime = ""
          this.getTimeList()
        })
      }else{
        this.$message({
            message: '最多只能添加三个提醒',
            type: 'warning'
        });
      }
    },
    // editTimes(row){
    //   console.log(row)
    // },
    deleteTimes(checkStoreAlarmId){
      const that = this;
      this.$confirm(`是否删除该日期提醒？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //console.log(checkStoreAlarmId)
          abnormalQuery.clockDel(checkStoreAlarmId).then(data => {
            //console.log(data)
            that.choiceTime = ""
            that.getTimeList()
          })  
        })
        .catch(() => {});          
    },
    invRemind(){
      this.remindDialog = true
    },
    addReceive(){
      this.$router.push('/whm/inventoryEdit');
    },
    // tableCheckChange(val) {
    //   //console.log(val)
    //   this.checkBoxData = val;
    // },
    invalidTap(){
      //this.$router.push("/whm/receiveEdit");
      let selected = this.$refs.pageTable.getSelectedData();// ******************获取当前选中的*****************
      if(selected.length > 0){
        let params = []
        params = selected.map((e) => {
          return {
            checkStoreId: e.checkStoreId,
            storageState: 2,
          };
        });
        //console.log(arr)
        abnormalQuery.changeAuditState(params).then((data) => {
          //console.log(data)
          if(data.msg == "OK"){
            this.$message({
              message: '作废成功！',
              type: 'warning'
            });
            this.loadTableData()
          }
        })
      }
    },

    getTimeList(){
      abnormalQuery.clockPage().then((data) => {
        this.timeList = data
      })
    },

    toGroupDetail(row){
      row = JSON.stringify(row)
      this.$router.push(`/whm/inventoryDetail/${row}`);
    },
       
  },
};
</script>
<style>
.mt12{ margin-top: 12px;}
.inv-remid{ padding:20px 0 20px 20px; border-bottom: 1px solid #f1f1f1;}
.invlist-wrap .el-table--small th{ margin:0; padding: 0; line-height: 0; height: 0; border-bottom: 0;}
.ml30{ margin-left: 15px; font-size: 14px; color: #666;}
.inventory-form{ padding: 20px 20px 0 20px; background: #fff;}
.invlist-wrap{ padding: 0 0 40px 20px;}
.invlist-wrap .el-dialog__body{ padding: 0; padding-bottom: 30px;}
.inv-remin-btn{ margin-top: 30px; display: flex; justify-content: center;}
.inv-remin-btn .b1,.inv-remin-btn .b2{ text-align: center; border-radius: 2px; font-size: 14px; cursor: pointer;}
.inv-remin-btn .b1{ width: 66px; height: 28px; line-height: 28px; color: #999; border:1px solid #999;}
.inv-remin-btn .b2{ margin-left: 20px; width: 68px; height: 30px; line-height: 30px; color: #fff; background: #1B73E8;}
.invlist-wrap .el-form--inline{ margin-bottom: 20px;}
</style>