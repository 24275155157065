<template>
  <div class="book-wrap">
    <div class="book-header-search">
      <el-form :inline="true">
        <el-form-item label="库品编码:">
          <el-input v-model="searchInfo.skuCode" />
        </el-form-item>
        <el-form-item label="库品名称:">
          <el-input v-model="searchInfo.skuName" />
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="操作时间:">
          <el-date-picker
            v-model="timeArr"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          ></el-date-picker>
          <!-- <el-date-picker
            v-model="timeArr"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>-->
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-button type="primary" @click="searchData">搜索</el-button>
        <el-button @click="exportQuery">导出</el-button>
      </el-form>
    </div>

    <ws-page-table :data="tableData" ref="pageTable">
      <el-table-column prop="skuName" label="库品名称" :show-overflow-tooltip="true" width="360px"></el-table-column>
      <el-table-column prop="skuCode" label="库品编码" :show-overflow-tooltip="true" width="360px"></el-table-column>
      <el-table-column prop="num" label="预定总数" :show-overflow-tooltip="true"></el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import IoProgressService from "API/IoProgressService.js";
import tools from "COMJS/tools";
export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        skuCode: "", //库品编码
        skuName: "", //库品名称
        openTime: "",
        endTime: "",
      },
      items: {
        skuCode: { filter: "like" },
        skuName: { filter: "like" },
        openTime: { filter: "EQ" },
        endTime: { filter: "EQ" },
      },
      timeArr: [],
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
    this.getDefaultDate()
  },
  methods: {
    exportQuery() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let params = {
        pageNo: 1,
        pageSize: 1000,
        openTime:
          this.timeArr && this.timeArr[0]
            ? tools.formatDate(new Date(this.timeArr[0]), "yyyyMM")
            : "",
        endTime:
          this.timeArr && this.timeArr[1]
            ? tools.formatDate(new Date(this.timeArr[1]), "yyyyMM")
            : "",
      };
      WhmList.exportInvReserveds(params)
        .then((data) => {
          let fileName = data.fileName;
          let module = data.module;
          IoProgressService.download(module, fileName, false);
          loading.close();
          that.loadTableData();
        })
        .catch(() => {
          loading.close();
        });
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.openTime =
        this.timeArr && this.timeArr[0]
          ? tools.formatDate(new Date(this.timeArr[0]), "yyyyMM")
          : "";
      this.searchInfo.endTime =
        this.timeArr && this.timeArr[1]
          ? tools.formatDate(new Date(this.timeArr[1]), "yyyyMM")
          : "";
      WhmList.querySkuReservedInfo(this.searchInfo).then((data) => {
        this.tableData = data;
        if (this.$refs.pageTable) {
          this.$refs.pageTable.tableLoading = false;
        }
      });
    },
    getDefaultDate(){
       var date=new Date();
       var nowYear=date.getFullYear();
       var nowMonth = date.getMonth() + 1;
       var nextYear=nowYear+1;
       this.timeArr.push(nowYear+"-"+nowMonth);
       this.timeArr.push(nextYear+"-"+nowMonth)
    }
  },
};
</script>
<style>
.book-header-search {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.mb15 {
  margin: 0 15px;
}
</style>