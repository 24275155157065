<template>
  <div id="ProjectBoard">
    <div class="Project-content">
      <div class="legend">
        <div style="font-size:16px;font-weight:600">图例</div>
        <div class="legend-tag">
          <div class="tag-item" v-for="(item,index) in tagList" :key="index">
            <span class="tag-state" :style="'background:'+ item.color"></span>
            <span class="tag-title">{{item.label}}</span>
          </div>
        </div>
      </div>
      <div class="itemSet">
        <div class="itemSet-title">
          <div style="font-size:16px;font-weight:600;">项目集</div>
          <el-button type="primary" size="mini" @click="addItemSet">新建项目集</el-button>
        </div>
        <ws-page-table :show-header="false" style="width:100%" height="400" :data="tableData" :pageInfo="pageInfo" ref="pageTable" :expandChange='exChange' @pageChange="loadTableData" :cellStyle='cellStyle'>
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="expand">
                <ws-page-table :stripe='true' style="width:100%" :show-header="false" :data="props.row.pmProjectList" :cellStyle='cellStyle' @cellClick='clickProject'>
                  <el-table-column prop="title" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true">
                    <template slot-scope="scope" v-if="scope.row.beginTime!='Invalid date' && scope.row.endTime!='Invalid date'">
                      <span>{{scope.row.beginTime}} - {{scope.row.endTime}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>

                  </el-table-column>
                </ws-page-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="title" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column :show-overflow-tooltip="true">
            <template slot-scope="scope" v-if="scope.row.beginTime!='Invalid date' && scope.row.endTime!='Invalid date'">
              <span>{{scope.row.beginTime}} - {{scope.row.endTime}}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <i v-if='scope.row.edit' style="color:rgba(24, 144, 255, 1);cursor: pointer;" class="el-icon-edit-outline" @click="editItemSet(scope.row)"></i>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
      <div class="item">
        <div class="itemSet-title">
          <div style="font-size:16px;font-weight:600;">项目</div>
          <el-button type="primary" size="mini" @click="goProjectList">项目管理</el-button>
        </div>
        <div class="item-Collapse">
          <el-menu>
            <el-submenu index="1">
              <template slot="title">已关闭</template>
              <ws-page-table :show-header="false" style="width:100%" height="400" :data="closeList" :pageInfo="pageInfo" ref="pageTable" :expandChange='exChange' :cellStyle='cellStyle' @cellClick='clickProject'>
                <el-table-column prop="title" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column :show-overflow-tooltip="true">
                  <template slot-scope="scope" v-if="scope.row.beginTime!='Invalid date' && scope.row.endTime!='Invalid date'">
                    <span>{{scope.row.beginTime}}-{{scope.row.endTime}}</span>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"></el-table-column>
              </ws-page-table>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">进行中</template>
              <ws-page-table :show-header="false" style="width:100%" height="400" :data="waitingList" :pageInfo="pageInfo" ref="pageTable" :expandChange='exChange' :cellStyle='cellStyle' @cellClick='clickProject'>
                <el-table-column prop="title" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column :show-overflow-tooltip="true">
                  <template slot-scope="scope" v-if="scope.row.beginTime!='Invalid date' && scope.row.endTime!='Invalid date'">
                    <span>{{scope.row.beginTime}}-{{scope.row.endTime}}</span>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"></el-table-column>
              </ws-page-table>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="calendar">
      <full-calendar :config="config" :events="events" ref="calendar"></full-calendar>
    </div>
    <addItemSetPop ref="openDialog1" @success='loadTableData'></addItemSetPop>
    <taskPop ref="openTaskPop"></taskPop>
  </div>
</template>

<script>
const moment = require("moment");
import PmBrandService from "API/pm/PmBrandService";
import { FullCalendar } from "vue-full-calendar";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import "fullcalendar/dist/fullcalendar.css";
import addItemSetPop from "../pm/addItemSetPop.vue";
import taskPop from "./taskPop.vue";
export default {
  components: { FullCalendar, addItemSetPop, taskPop },
  data() {
    return {
      tagList: [
        {
          label: "进行中",
          color: "rgba(209, 231, 255, 1)",
        },
        {
          label: "已逾期",
          color: "rgba(255, 209, 209, 1)",
        },
        {
          label: "已完成",
          color: "rgba(214, 255, 219, 1)",
        },
        {
          label: "未开始",
          color: "rgba(229, 229, 229, 1)",
        },
      ],
      waitingList: [],
      closeList: [],
      tableData: [],
      selectDate: "2021-10", //日期选择器选中的月份
      events: [],
      config: {
        firstDay: "1", //以周日为每周的第一天
        buttonText: {
          today: "今天",
          month: "月",
          week: "周",
          day: "日",
        },
        header: {
          left: "myCustomButton",
          center: "prev, title, next",
          right: "",
        },
        theme: false, //是否允许使用jquery的ui主题
        height: "auto",
        slotLabelFormat: "H:mm", // axisFormat 'H(:mm)'
        //slotLabelInterval:1,
        views: {
          month: {
            titleFormat: "YYYY年MMM",
          },
          day: {
            titleFormat: "YYYY年MMMDD日 dddd",
          },
        },
        dayNames: ["日", "一", "二", "三", "四", "五", "六"],
        dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
        slotDuration: "00:30:00",
        minTime: "00:00",
        maxTime: "24:00",
        locale: "zh-cn",
        editable: false, //是否允许修改事件
        selectable: false, //是否允许用户单击或者拖拽日历中的天和时间隙
        eventLimit: false, // 限制一天中显示的事件数，默认false
        allDaySlot: true, //是否显示allDay
        displayEventEnd: false, //是否显示结束时间
        allDayText: "全天",
        navLinks: false, //允许天/周名称是否可点击
        defaultView: "month", //显示默认视图
        eventClick: this.eventClick, //点击事件
        dayClick: this.dayClick, //点击日程表上面某一天
        eventRender: this.eventRender,
        contentHeight: 750,
        customButtons: {
          myCustomButton: {
            text: "日历", // 按钮的展示文本
            click: "",
          },
        },
        eventRender: function (event, element) {
          element.html(event.title);
        },
      },
    };
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      PmBrandService.queryPmProjectGroupAll().then((data) => {
        console.log(data);
        this.tableData = data
          ? data.map((e) => {
              return {
                edit: false,
                projectGroupId: e.projectGroupId,
                title: e.title,
                createTime: moment(e.createTime).format("YYYY-MM-DD"),
                beginTime: moment(e.beginTime).format("YYYY-MM-DD"),
                endTime: moment(e.endTime).format("YYYY-MM-DD"),
                createUser: e.createUser,
                updateTime: e.updateTime,
                updateUser: e.updateUser,
                state: e.state,
                pmProjectList: e.pmProjectList
                  ? e.pmProjectList.map((z) => {
                      return {
                        projectId: z.projectId,
                        title: z.title,
                        projectState: z.projectState,
                        leaderId: z.leaderId,
                        leaderName: z.leaderName,
                        createTime: moment(z.createTime).format("YYYY-MM-DD"),
                        beginTime: moment(z.beginTime).format("YYYY-MM-DD"),
                        endTime: moment(z.endTime).format("YYYY-MM-DD"),
                        createUser: z.createUser,
                        state: z.state,
                        userId: z.userId,
                        projectGroupRelaId: z.projectGroupRelaId,
                      };
                    })
                  : [],
              };
            })
          : [];
      });
      this.getCollapse();
    },
    getCollapse() {
      let paeams = {
        projectState: "all",
      };
      PmBrandService.queryPmProjectAll(paeams).then((data) => {
        data = data.filter((e) => {
          return {
            beginTime: (e.beginTime = moment(e.beginTime).format("YYYY-MM-DD")),
            endTime: (e.endTime = moment(e.endTime).format("YYYY-MM-DD")),
          };
        });
        console.log(data);
        for (let i in data) {
          if (data[i].projectState == "waiting") {
            this.waitingList.push(data[i]);
          }
          if (data[i].projectState == "close") {
            this.closeList.push(data[i]);
          }
        }
      });
    },
    addItemSet() {
      let params = {
        dialogVisible: true,
      };
      this.$refs.openDialog1.$emit("openDialog", params);
    },
    goProjectList() {
      this.$router.push({
        path: "/pm/ProjectList",
      });
    },
    editItemSet(row) {
      let params = {
        dialogVisible: true,
        projectData: row,
      };
      this.$refs.openDialog1.$emit("openDialog", params);
    },
    cellStyle() {
      return {
        "font-size": "14px",
        height: "40px",
        padding: "0",
      };
    },
    queryTaskAllByProjectIds(params) {
      PmBrandService.queryTaskAllByProjectIds(params).then((data) => {
        data = data.filter((e) => e.taskState !== "suspend");
        this.getReservationList(data);
      });
    },
    getReservationList(arrayData) {
      if (arrayData) {
        let newArr = [];
        this.subList = arrayData;
        arrayData.forEach((item) => {
          newArr.push({
            start: this.dealWithTime(
              moment(item.beginTime).format("YYYY-MM-DD")
            ),
            end: this.addDate(
              this.dealWithTime(moment(item.endTime).format("YYYY-MM-DD")),
              1
            ),
            color:
              item.taskState == "waiting"
                ? "rgba(209, 231, 255, 1)"
                : item.taskState == "complete"
                ? "rgba(214, 255, 219, 1)"
                : item.taskState == "overdue"
                ? "rgba(255, 209, 209, 1)"
                : item.taskState == "notStart"
                ? "rgba(229, 229, 229, 1)"
                : "",
            projectTaskId: item.projectTaskId,
            title: this.getTitle(item.title),
          });
        });
        console.log(newArr);
        this.events = newArr;
      } else {
        this.events = [];
      }
    },

    getTitle(title) {
      return '<span class="label"></span>' + title;
    },
    getShowTime(beginDate, endDate) {
      this.form.startDate = this.dealWithTime(beginDate);
      this.form.startTime = this.getHoursMin(beginDate);
      this.form.endDate = this.dealWithTime(endDate);
      this.form.endTime = this.getHoursMin(endDate);
    },
    // 获取时分时间
    getHoursMin(value) {
      return value.substring(11, 16);
    },
    // 处理会议时间格式
    dealWithTime(date) {
      let newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)[0];
      return newDate;
    },
    // 日期加1天
    addDate(date, days) {
      var d = new Date(date);
      d.setDate(d.getDate() + days);
      var mon =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let endD = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      return `${d.getFullYear()}-${mon}-${endD}`;
    },

    changeDate() {
      this.$refs.calendar.fireMethod("gotoDate", this.selectDate);
    },
    // 点击事件
    eventClick(event, jsEvent, pos) {
      let data = {
        projectTaskId: event.projectTaskId,
        TaskSee: true,
      };
      this.$refs.openTaskPop.$emit("openTaskPop", data);
    },
    // 点击当天
    dayClick(day, jsEvent) {
      // alert("dayClick", day, jsEvent);
    },
    eventRender: function (event, element) {
      element.find(".fc-title").attr("title", event.title);
    },
    exChange(row) {
      let params = {
        pmProjectList: [],
      };
      for (let i in row.pmProjectList) {
        let param = {
          projectId: row.pmProjectList[i].projectId,
        };
        params.pmProjectList.push(param);
      }
      this.queryTaskAllByProjectIds(params);
      for (let i in this.tableData) {
        if (row.projectGroupId == this.tableData[i].projectGroupId) {
          this.tableData[i].edit = !this.tableData[i].edit;
        }
      }
    },
    clickProject(row) {
      let params = {
        pmProjectList: [
          {
            projectId: row.projectId,
          },
        ],
      };
      this.queryTaskAllByProjectIds(params);
    },
  },
};
</script>

<style scoped>
#ProjectBoard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.Project-content {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2px;
}
.calendar {
  background: rgba(255, 255, 255, 1);
  height: 100%;
  margin-left: 1%;
  width: 74%;
  padding-top: 20px;
  border-radius: 2px;
}
.calendar >>> .fc-day-header {
  border: none;
  text-align: left;
  line-height: 40px;
  height: 40px;
  position: relative;
  left: 5.57px;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
}
.calendar >>> .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
  margin: 6px;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
}
.calendar >>> .fc-center > button {
  height: 18px;
  background: none;
  border: none;
  box-shadow: none;
}
.calendar >>> .fc-center > button > span {
  font-size: 14px;
}
.calendar >>> .fc-today {
  background: none;
}
.calendar >>> .fc-center > h2 {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 5px;
}
.calendar >>> .fc-left > button {
  background: none;
  border: none;
  box-shadow: none;
  cursor: auto;
  font-weight: 600;
}
.calendar >>> .fc-event {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: rgba(102, 102, 102, 1);
}
.calendar >>> .label {
  width: 10px;
  height: 10px;
  background: rgba(255, 141, 26, 1);
  display: block;
  margin: 0px 5px;
  border-radius: 50%;
}
.calendar >>> .fc-draggable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  width: 92%;
  height: 100%;
  border-radius: 2px;
  background: #ffffff;
}
.legend-tag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.tag-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 50px;
}
.tag-state {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.tag-title {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin-left: 8px;
}

.itemSet {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

#ProjectBoard >>> .el-table tr {
  background-color: #ffffff !important;
  box-shadow: none;
}
.itemSet-title {
  width: 90%;
  height: 30px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
}
.itemSet-title > button {
  margin: 0px 20px;
}
.item {
  margin-top: 10px;
  width: 102%;
  border-radius: 2px;
}
.item-Collapse {
  width: 98%;
  height: 330px;
  overflow: auto;
}
#ProjectBoard >>> .expand .el-table__row {
  cursor: pointer;
}
#ProjectBoard >>> .item-Collapse .el-table__row {
  cursor: pointer;
}
</style>