<template>
  <div class="group-add">
    <div class="step-title"><span v-show="hasId">修改</span><span v-show="!hasId">新增</span>组合库品</div>
    <el-form
      :model="whmInfo"
      label-width="140px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item label="组合编码:">
        <el-input v-model="whmInfo.skuGroupCode"></el-input>
      </el-form-item>
      <el-form-item label="组合名称:">
        <el-input v-model="whmInfo.groupName"></el-input>
      </el-form-item>

      <el-form-item label="组合库品:" class="mt120">
        <el-button type="primary" @click="addGoods">新增库品</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
      :data="goodsList"
      ref="pageTable"
      :pageable="false"
      :showIndex="true"
    >
      <el-table-column label="库品名称" width="360px">
        <template slot-scope="scope">
          <div
            class="select-btn"
            v-if="!scope.row.skuName"
            @click="openskuCodeDialog(scope.$index)"
            >
            请选择库品名称
          </div>
          <div v-else>
            <span>{{ scope.row.skuName }}</span>
            <span
              class="tips-btn"
              style="dispaly: inline-block; margin-left: 15px"
              @click="openskuCodeDialog(scope.$index)"
              >重选库品名称</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="库品数量">
        <!-- <el-input v-model="goodsList.skuNum"></el-input> -->
        <!--onkeyup="value=value.replace(/[^\d]/g,'')"-->
        <template slot-scope="scope">
          <el-input 
            v-model="scope.row.skuNum"
            maxlength="11"
            type="number"
            @keyup.native="setNumFotList(scope.row,scope.$index)"
            :disabled="!scope.row.skuName"
            placeholder="请先选择库品"
            >
          </el-input>
        </template> 
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="删除">
              <i
                class="el-icon-delete-solid"
                @click="deleteGoods(scope.$index)"
              ></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">取消</el-button>
      <el-button type="primary" @click="createWhm">保存</el-button>
    </div>

    <el-dialog :visible.sync="skuCodeDialog" width="50%" class="table-wrap">
      <el-form label-width="160px" class="input-txt-btn">
        <el-input v-model="searchInfo.skuName"></el-input>
        <el-button type="primary" @click="loadTableData">搜索</el-button>
      </el-form>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @current-change="handleCurrentChange"
        @pageChange="loadTableData"
        :selection="'single'"        
      >
        <el-table-column
          prop="skuName"
          label="库品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuCode"
          label="库品编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <div class="btn-blue" @click="skucodeConfirm">确定</div>
        <div class="btn-gray" @click="closeSkucode">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import userModule from "@/module/userModule";
import WhmList from "API/whm/WhmList";
import draggableUpload from "COMPS/base/draggableUpload";
import invQuery from "API/whm/invQuery";

export default {
  name: "invGroupEdit",
  data() {
    return {
      //thumbnailImgs: [], //上传图片的字段
      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        skuId: "",
        skuGroupCode: "",//组合编码
        groupName: "",//组合名称
      },
      skuCodeDialog: false, //库品编码弹窗开关
      searchInfo: {//查询字段
        skuCode: "",
        skuName: "",
        type: 0,
        enabled: 1,
      },
      items: {//查询字段
        skuCode: { filter: "like" },
        skuName: { filter: "like" },
      },
      goodsList: [{
        skuId: "",
        skuCode: "",
        skuName: "",
        skuNum: "",
      }],
      curIdx: 0,
      hasId: false,
    };
  },
  mixins: [pageTableMixin],
  components: {
    draggableUpload,
  },
  mounted() {
    //this.addGoOn();
    const that = this
    if (this.$route.params.id) {
      this.hasId = true
      that.getGoodsDetail()
    }else{
      that.loadTableData()
    }
  },
  methods: {
    closeSkucode(){
      this.skuCodeDialog = false
    },
    getGoodsDetail(){
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      invQuery.detailSkuGroup(this.$route.params.id).then((data) => {
        this.whmInfo = data
        this.goodsList = data.groupDetailList
        loading.close();
      });
    },
    setNumFotList(row,numIdx){//把获取到的num赋值给list
      let goodsList = this.goodsList
      if(row.skuNum > 0){
        this.$set(goodsList[numIdx],"skuNum",row.skuNum)      
      }
    },
    addGoods() {//新增一条新的空数据
      let goodsInfo = {
        skuId: "",
        skuCode: "",
        skuName: "",
        skuNum: "",
      };
      this.goodsList.push(goodsInfo);
    },
    // 删除
    deleteGoods(index) {
      if (this.goodsList.length == 1) return; //只有一条数据的时候不能删除
      this.$confirm("确定删除该库品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.goodsList.splice(index, 1);
        })
        .catch(() => {});      
      //this.computedAllGoodsTotalPrice();
    },
    skucodeConfirm() {
      this.skuCodeDialog = false;
    },
    skucodeCancel() {
      this.skuCodeDialog = false;
      this.currentRow = {
        skuCode: "", //库品编码
        skuName: "", // 库品名称
        thingTypeName: "", //分类
        skuSpecName: "", //规格名
        skuSpec: "", //规格值
        skuUnit: "", //规格单位
        shelfLife: "", //保质期
        actualNum: "", //当前库存
        warningNum: "", //预警值
        soldOutNum: "", //售罄值
      };
    },
    handleCurrentChange(val) {
      //console.log(val)
      let hasrowId = false
      for(let i in this.goodsList){
        if(this.goodsList[i].skuId == val.id){
          this.$message({
            message: "该库品已选中！",
            type: "warning",
          });
          hasrowId = true
        }
      }
      if (val && !hasrowId) {
        //console.log(val)
        let goodsList = this.goodsList
        let curIdx = this.curIdx
        //goodsList[curIdx].skuName = val.skuName
        this.$set(goodsList[curIdx],"skuName",val.skuName)
        this.$set(goodsList[curIdx],"skuCode",val.skuCode)
        this.$set(goodsList[curIdx],"skuId",val.id)
        this.$set(goodsList[curIdx],"skuNum",1)
        //this.currentRow = val;
      }
    },
    openskuCodeDialog(curIdx) {
      //console.log(curIdx)
      this.curIdx = curIdx
      this.skuCodeDialog = true;
    },
    // 查询库品列表
    loadTableData() {
      //this.$refs.pageTable.tableLoading = true;
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            thingTypeName: e.level ? e.level.thingTypeName : "", //分类
            skuSpecName: e.skuSpecName ? e.skuSpecName.name : "", //规格名
            skuSpec: e.skuSpec ? e.skuSpec.name : "", //规格值
            skuUnit: e.skuUnit ? e.skuUnit.name : "", //规格单位
            shelfLife: e.shelfLife, //保质期
            actualNum: e.actualNum, //当前库存
            warningNum: e.warningNum,
            soldOutNum: e.soldOutNum,
          };
        });
        this.querySuccess(data);
      });
    },
    backPre() {
      //返回上一页
      this.$router.push("/whm/whmList");
    },
    createWhm() {
      let arr = []
      let goodsList = this.goodsList
      let whmInfo = this.whmInfo
      const that = this
      arr = goodsList.filter(function(res){
        return res.skuId
      })
      let allNum = false
      //console.log(arr)
      allNum = arr.every(function(val){
        return val.skuNum > 0
      })
      let allIntNum = arr.every(function(val){ 
        //console.log(val)      
        return val.skuNum % 1 === 0 //判断是不是正整数
      })
      if(arr.length == 0){
        return this.$message({
          message: "请选择库品",
          type: "warning",
        });
      }                                 
      if(!allNum || !allIntNum){
        return this.$message({
          message: "请输入正确数量",
          type: "warning",
        });
      }
      if(!whmInfo.skuGroupCode){
        return this.$message({
          message: "请输入组合编码",
          type: "warning",
        });
      }
      if(!whmInfo.groupName){
        return this.$message({
          message: "请输入组合名称",
          type: "warning",
        });
      }
      whmInfo.skuGroupElementList = arr
      whmInfo.state = 1
      //console.log(whmInfo)
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      //console.log(this.$route.params.id)
      if(this.$route.params.id){
        invQuery
        .modifySkuGroup(whmInfo)
        .then((res) => {
          if (res.msg == "OK") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(function () {
              that.$router.push("/whm/whmList");
            }, 300);
          }
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
      }else{
        invQuery
        .saveSkuGroup(whmInfo)
        .then((res) => {        
          if (res.msg == "OK") {
            this.$message({
              message: "新增成功",
              type: "success",
            });          
            setTimeout(function () {
              that.$router.push("/whm/whmList");
            }, 300);
          }         
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
      }
      
    },
  },
};
</script>

<style lang="scss">
.group-add{ padding: 20px 0 60px 0; background: #fff;}
.group-add .el-input--small .el-input__inner {
  width: 150px;
}
.group-add .shelf-life.el-input--small {
  width: 150px;
}
.group-add .step-title {
  padding: 0 0 40px 33px;
  font-size: 18px;
  color: #333;
}
.group-add .select-btn {
  width: 150px;
  text-align: center;
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}
.group-add .whm-sku {
  padding: 20px;
  display: inline-block;
  border: 1px solid #ddd;
}
.whm-sku .select-btn {
  margin-top: 10px;
}
.group-add .select-btn-wrap {
  padding: 12px;
}
.group-add .select-label {
  padding-left: 12px;
  width: 188px;
  background: #f8f8f8;
}
.group-add .c9 {
  margin-left: 12px;
  color: #999;
}
.group-add .steps-btn-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.group-add .btn-next-step {
  margin: 20px auto 0 auto;
  width: 76px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background: #1b73e8;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
}
.group-add .search-result {
  height: 360px;
  overflow-y: auto;
}
.group-add .tips-btn {
  cursor: pointer;
  color: #1b73e8;
}
.group-add .input-txt-btn {
  margin-bottom: 20px;
  display: flex;
}
.group-add .input-txt-btn .el-input {
  width: 160px;
}
.table-wrap .el-dialog__header {
  border-bottom: 0;
}
.table-wrap .el-table .cell {
  line-height: 42px;
}
.group-add .table-wrap .el-input--small .el-input__inner {
  width: 40px;
}
.group-add .input-txt-btn .el-input--small .el-input__inner {
  width: 150px;
}
.btn-blue-wrap {
  display: flex;
  justify-content: flex-end;
}
.btn-blue,
.btn-gray {
  margin-top: 20px;
  width: 80px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  background: #1b73e8;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.btn-gray {
  margin-left: 10px;
  width: 78px;
  height: 34px;
  border: 1px solid #ddd;
  color: #999;
  background: #fff;
}
.mt120 {
  margin-top: 120px;
}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {-webkit-appearance: none !important;}
input[type="number"]{-moz-appearance: textfield;}
.el-table--enable-row-hover .el-table__body tr:active>td {background-color: #E8F1FD;}
</style>