<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="15">
        <div>
          <div slot="toolbar">
            <el-button type="primary" @click.navite="addNodeRole" :disabled="tableData.length>0">新增</el-button>
            <el-button @click.navite="removeNodeRole">删除</el-button>
          </div>
          <ws-page-table
              :data="tableData"
              ref="auditNodeRoleConfigurationPageTable"
              :pageable="true"
              :pageInfo="pageInfo"
              @current-change="nodeRoleClick"
              @pageChange="loadTableData"
              :showIndex="true"
              selection="single"
          >
            <!--<el-table-column fixed type="selection" width="50"></el-table-column>-->
            <el-table-column prop="auditNodeName" label="审核节点名称" min-width="90"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="auditRoleId" label="审核角色名称" min-width="90" :formatter="roleFormatter"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="auditRoleType" label="审核角色指定类型" min-width="90"
                             :formatter="roleTypeFormatter" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" class="ws-iconbtn" @click.native="editNodeRole(scope.row)"><i
                    class="fa fa-edit"></i></el-button>
              </template>
            </el-table-column>
          </ws-page-table>

        </div>
      </el-col>

      <el-col :span="9" class="rightCol">
        <audit-node-person-configuration-list :node-role-info.sync="nodeRoleInfo"
                                              ref="personList"></audit-node-person-configuration-list>
      </el-col>
    </el-row>

    <audit-node-role-configuration-edit :enum-map="enumMap" ref="nodeRoleEdit"
                                        @editCompleted="loadTableData"></audit-node-role-configuration-edit>
  </div>
</template>

<script>
import auditNodeRoleConfigurationEdit from './components/auditNodeRoleConfigurationEdit';
import auditNodePersonConfigurationList from './auditNodePersonConfigurationList';
import pageTableMixin from "@/mixin/pageTableMixin.js";
import auditFlowService from "API/ba/auditFlowService"
import bimRoleService from "API/ba/BimRoleService"
import Objects from "COMJS/Objects"


export default {
  name: "auditNodeRoleConfigurationList",
  components: {
    auditNodeRoleConfigurationEdit: auditNodeRoleConfigurationEdit,
    auditNodePersonConfigurationList: auditNodePersonConfigurationList
  },
  props: {
    nodeInfo: {
      type: Object,
      default: ''
    }
  },
  mixins: [pageTableMixin],
  data() {
    return {
      searchInfo: {auditNodeConfigurationId: this.nodeInfo.auditNodeConfigurationId, auditNodeRoleCode: '', state: '1'},
      items: {
        auditNodeRoleCode: {filter: "like"},
        auditNodeConfigurationId: {filter: "EQ"},
        state: {filter: "EQ"}
      },
      sorts: {
        auditNodeRoleCode: {order: "asc"},
        createTime: {order: "desc"}
      },
      nodeRoleInfo: {},
      tempOnePage: {},
      tempOneSearch: {},
      tempTowPage: {},
      tempTowSearch: {},
      tempThreePage: {},
      tempThreeSearch: {},

      enumMap: {
        enumRole: [],
        enumAuditRoleType: [
          {name: '角色内所有人', value: 'allPersons'},
          {name: '角色内任一人', value: 'anyPersons'},
          {name: '角色内指定人', value: 'specifyPersons'}
        ]
      },
    };
  },
  mounted() {
    this.initPage();
    this.loadRoleInfo();
  },
  created() {
    let that = this;
    /*eventBus.$on('searchInfoAuditConfigurationStorage', (onePageInfo, oneSearchForm, towPageInfo, towSearchForm, threePageInfo, threeSearchForm) => {
      Objects.deepCopy(that.tempOnePage, onePageInfo);
      Objects.deepCopy(that.tempOneSearch, oneSearchForm);

      Objects.deepCopy(that.tempTowPage, towPageInfo);
      Objects.deepCopy(that.tempTowSearch, towSearchForm);

      Objects.deepCopy(that.tempThreePage, threePageInfo);
      Objects.deepCopy(that.tempThreeSearch, threeSearchForm);
    });*/
  },
  destroyed() {
    let that = this;
    //eventBus.$emit('searchInfoAuditConfigurationStorage', that.tempOnePage, that.tempOneSearch, that.tempTowPage, that.tempTowSearch, that.tempThreePage, that.tempThreeSearch);
  },
  methods: {

    /**初始化页面*/
    initPage() {
      /*let types = "AGENCY_LEVEL,AGENCY_STATE,OPERATION_TYPE,AUDIT_STATE,AUDIT_ROLE_TYPE,AUDIT_ROLE_TYPE";
      bimEnumService.getEnumByTypes(types).then((data) => {
          this.enumMap = data;
          this.loadRoleInfo();
          this.loadTableData();
      }).catch((data) => console.log(data));*/
    },
    /**将角色信息放入枚举Map*/
    loadRoleInfo() {
      bimRoleService.getCustomRoleList().then(data => {
        //console.log(data)
        this.enumMap.enumRole = data.list.map(e => {
          return {
            name: e.roleName,
            value: e.roleId
          }
        });
      }).catch(err => {
        console.log(err);
      });
    },
    /**读取数据*/
    loadTableData() {
      auditFlowService.findAuditNodeRoleConfigurationPage(this.pageInfo, this.searchConfig).then(data => {
        this.nodeRoleInfo = {};
        this.$refs.personList.auditNodePersonConfigurationList = [];
        //console.log("=============================")
        //console.log(data.list)
        this.querySuccess(data);
      })
    },
    /**删除*/
    removeNodeRole() {
      let that = this;
      let rows = this.$refs.auditNodeRoleConfigurationPageTable.getCheckedData();
      if (rows.length < 1 || rows[0] == null) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            that.$refs.auditNodeRoleConfigurationPageTable.tableLoading = true;
            let ids = '';
            rows.forEach(o => {
              if (Objects.isEmpty(ids)) {
                ids = o.auditNodeRoleId;
              } else {
                ids += ',' + o.auditNodeRoleId;
              }
            });
            auditFlowService.removeAuditNodeRoleConfigurationInfoByIds(ids).then(() => {
              that.loadTableData();
              that.$refs.auditNodeRoleConfigurationPageTable.tableLoading = false;
            }).catch(err => {
              that.$refs.auditNodeRoleConfigurationPageTable.tableLoading = false;
            })
          }
      )
    },
    /**新增*/
    addNodeRole() {
      this.$refs.nodeRoleEdit.display(this.nodeInfo, 'ADD');
    },
    /**编辑*/
    editNodeRole(row) {
      this.$refs.nodeRoleEdit.display(row, 'EDIT');
    },
    /**查看*/
    viewNodeRole() {

    },
    /**节点角色选择*/
    nodeRoleClick(row) {
      this.nodeRoleInfo = row;
    },
    roleTypeFormatter(row) {
      let v = "-";
      let roleTypeList = this.enumMap.enumAuditRoleType;
      if (row.auditRoleType) {
        v = row.auditRoleType;
        let roleTypeListEnum = roleTypeList.find(o => o.value === row.auditRoleType);
        if (Objects.isNotEmpty(roleTypeListEnum)) {
          v = roleTypeListEnum.name;
        }
      }
      return v;
    },
    roleFormatter(row) {
      let v = "-";
      let roleList = this.enumMap.enumRole;
      if (row.auditRoleId && roleList) {
        v = row.auditRoleId;
        let roleListEnum = roleList.find(o => o.value === row.auditRoleId);
        if (Objects.isNotEmpty(roleListEnum)) {
          v = roleListEnum.name;
        }
      }
      return v;
    },

  }
}
</script>

<style scoped>

</style>
