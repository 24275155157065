import axiosHttp from "../axiosHttp";

export default {
    //GET /whmInventory/query
    queryInvPages(pageInfo,searchConfig){ //入库列表
        let url = "/whmInventory/query?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    //POST /whmInventory/save 
    saveInventory(params){ //新增入库
        return axiosHttp.POST('/whmInventory/save', params, false);
    },
    removeInventory(params){
        return axiosHttp.POST('/whmInventory/removeNum', params, false);
    },
    querySkuAndInvPages(params){ //入库列表
        return axiosHttp.POST('/whmInventory/querySkuAndInvPage', params, false);
    },
    //whmInventory/queryBySort
    queryBySort(skuId){ //查询批次的消耗排序列表
        return axiosHttp.GET(`/whmInventory/queryBySort/${skuId}`);
    },
    //whmInventory/updInvSort 顶置批次的排序
    updInvSort(params){
        return axiosHttp.POST('/whmInventory/updInvSort', params, false);
    },
    //whmSupplier/queryByName 供应商模糊查询列表
    querySupplierByName(searchConfig) {//选择库品单位
        return axiosHttp.GET(`/whmSupplier/queryByName/${searchConfig}`);
    },
    querySkuAndInvRecordPage(params){ //查询批次的入库集
        return axiosHttp.POST('/whmInventory/querySkuAndInvRecordPage', params, false);
    },
    querySkuGroupPage(pageInfo,searchConfig){ //库品分页信息
        let url = "/whmSkuGroup/page?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    //whmSkuGroup/save 库品组合新增
    saveSkuGroup(params){ //新增入库
        return axiosHttp.POST('/whmSkuGroup/save', params, false);
    },
    //whmSkuGroup/detail 库品组合详情
    detailSkuGroup(params){ //新增入库
        //console.log(params)
        return axiosHttp.POST(`/whmSkuGroup/detail/${params}`);
    },
    //whmSkuGroup/modify
    modifySkuGroup(params){ //新增入库
        return axiosHttp.POST('/whmSkuGroup/modify', params, false);
    },
    queryBatchBySku(id){ //新增入库
        return axiosHttp.GET(`/whmInventory/queryBySku/${id}`);
    },
}