import axiosHttp from "../axiosHttp";

export default {
    queryStockPage(pageInfo,searchConfig){ //入库列表
        let url = "/whmStock/queryLog?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },

    queryReceivePage(param){ //入库列表
        let url = "/whmStock/queryReceivePage"
        return axiosHttp.POST(url,param)
    },
}