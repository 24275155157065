<template>
  <div style="display: flex;">
    <div>
      <el-steps :active="active+1" align-center class="left-height" direction="vertical">
        <el-step title="采购" description="从供应商采购物料"></el-step>
        <el-step title="发货" description="采购的物料操作发货"></el-step>
        <el-step title="收货" description="物料到港后确认收进仓库"></el-step>
      </el-steps>
    </div>
    <div>
      <div class="step-div">
        <el-card class="step-card" v-for="(item,idx) in step0List" :key="idx">
          <div slot="header" class="clearfix">
            <span>{{item.materialName}}（{{ formatDate(item.processTime) }}）</span>
            <el-button @click="stepShowDialog(item)"
                style="float: right; padding: 3px 0; color: #333333;" type="text">查看</el-button>
          </div>
          <div class="card-body">
            <div class="card-item">{{'供应商：' + item.materialSupplierName }}</div>
            <div class="card-item">{{'采购数量：' + item.processNum }}</div>
            <div class="card-item">{{'批次：' + item.batchCode }}</div>
            <!--
            <div class="card-item">{{'剩余可发货数量：' + item.remainder }}</div>
            -->
            <el-button type="primary" class="card-btn" @click="createStep1Dialog(item)"
                       icon="el-icon-bottom" :disabled="item.remainder==0">发货</el-button>
          </div>
        </el-card>
      </div>
      <div class="step-div">
        <el-card class="step-card" v-for="(item,idx) in step1List" :key="idx">
          <div slot="header" class="clearfix">
            <span>发货（{{ formatDate(item.processTime) }}）</span>
            <el-button @click="stepShowDialog(item)"
                       style="float: right; padding: 3px 0; color: #333333;" type="text">查看</el-button>
          </div>
          <div class="card-body">
            <div class="card-item">{{'物流：' + item.shipperName }}</div>
            <div class="card-item">{{'提单号：' + item.materialTransport.billLadingNo }}</div>
            <div class="card-item">{{'发货数量：' + item.processNum }}</div>
            <el-button type="primary" class="card-btn" @click="createStep2Dialog(item)"
                       icon="el-icon-bottom" :disabled="item.confirmed=='1'">
              {{item.confirmed=='1'?'已确认':'确认收货'}}
            </el-button>
          </div>
        </el-card>
      </div>
      <div class="step-div">
        <el-card class="step-card" v-for="(item,idx) in step2List" :key="idx">
          <div slot="header" class="clearfix">
            <span v-if="item.materialProcessId==null">待收货</span>
            <span v-else>收货（{{ formatDate(item.processTime) }}）</span>
            <el-button @click="stepShowDialog(item)" v-if="item.materialProcessId!=null"
                       style="float: right; padding: 3px 0; color: #333333;" type="text">查看</el-button>
          </div>
          <div class="card-body">
            <div class="card-item">仓库：{{item.warehouseName || '' }}</div>
            <div class="card-item">确认数量：{{item.processNum || '' }}</div>
          </div>
        </el-card>
      </div>
    </div>
    <!--step1CreateDialog-->
    <step1-create-dialog @reloadAfterSave="loadData()" ref="step1CreateDialog"></step1-create-dialog>
    <!--step2CreateDialog-->
    <step2-create-dialog @reloadAfterSave="loadData()" ref="step2CreateDialog"></step2-create-dialog>
    <!--stepShowDialog-->
    <step-show-dialog @reload="loadData()" ref="stepShowDialog"></step-show-dialog>
  </div>
</template>

<script>
import materialProcessService from "API/pur/PurMaterialProcessService"
import step1CreateDialog from "./materialProcessDialog/Step1CreateDialog"
import step2CreateDialog from "./materialProcessDialog/Step2CreateDialog"
import stepShowDialog from "./materialProcessDialog/StepShowDialog"
import tools from "COMJS/tools";

export default {
  name: "MaterialProcessDetail",
  components: {step1CreateDialog,step2CreateDialog,stepShowDialog},
  computed:{},
  data() {
    return {
      selfId: '',
      selectedId:'',
      active: 0,
      step0List:[],
      step1List:[],
      step2List:[]
    };
  },
  mounted() {
    this.selfId = this.$route.params.id
    this.loadData()
  },
  methods: {
    loadData() {
      materialProcessService.queryAllFamily(this.selfId).then(res=>{
        //console.log(res)
        this.step0List = res.step0List
        this.step1List = res.step1List
        this.step2List = res.step2List
        //reset
        this.active = 0
        //一些计算
        let step2Map = {}
        if(this.step2List && this.step2List.length>0){
          for (let step2item of this.step2List) {
            if(step2item.processNum>0){
              //真的，不是编的
              step2Map[step2item.previousProcessId] = '1'
              this.active = 2
            }
          }
        }
        let step1Num = 0
        if(this.step1List && this.step1List.length>0){
          for (let step1item of this.step1List) {
            step1Num += step1item.processNum
            if(step2Map[step1item.materialProcessId]=='1'){
              step1item.confirmed='1'
            }
          }
          if(this.active==0){
            this.active = 1
          }
        }
        if(this.step0List && this.step0List.length==1){
          this.step0List[0].remainder = this.step0List[0].processNum - step1Num
        }
      })
    },
    createStep1Dialog(step0) {
      this.$refs.step1CreateDialog.show(step0)
    },
    createStep2Dialog(step1) {
      this.$refs.step2CreateDialog.show(step1)
    },
    stepShowDialog(step){
      this.$refs.stepShowDialog.show(step)
    },
    editDialog(row) {

    },
    removeDialog(row) {

    },
    formatDate(time){
      return tools.formatTime(time,'yyyy-MM-dd')
    }
  }
}
</script>

<style scoped>
.left-height {
  height: 900px;
}
.step-div{
  height: 250px;
  margin-left: 50px;
  margin-bottom: 50px;
  display: flex;
}
.step-card {
  height: 200px;
  width: 300px;
  margin-right: 20px;
}
.card-body{
  height: 110px;
  width: 265px;
  position: relative;
}
.card-item{
  font-size: 14px;
  margin-bottom: 18px;
}
.card-btn{
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>