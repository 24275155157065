<template>
  <el-dialog title="查看分类" :visible.sync="dialogVisible" width="700px">
    <el-form label-width="120px">
      <el-form-item label="分类名称：">
        <span>{{info.categoryName}}</span>
      </el-form-item>
      <el-form-item label="分类级别：">
        <span>{{info.level}}</span>
      </el-form-item>
      <el-form-item label="分类图标：">
        <img class="category-icon" :src="info.categoryIconUrl" alt v-if="info.categoryIconUrl" />
        <span v-else>无</span>
      </el-form-item>
      <el-form-item label="分类编号：">
        <span>{{info.categoryCode}}</span>
      </el-form-item>
      <el-form-item label="是否启用：">
        <span>{{info.visible == 1 ? '是' : '否'}}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import PimCategoryService from "API/pim/PimCategoryService.js";

export default {
  props: {
    categoryId: String
  },
  watch: {
    categoryId(value) {
      if (value) {
        this.getCategoryDetail();
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      info: {}
    };
  },
  methods: {
    getCategoryDetail() {
      this.info = {};
      PimCategoryService.getCategoryDetail(this.categoryId).then(data => {
        this.info = data;
      });
    }
  }
};
</script>

<style scoped>
.el-form /deep/ .el-form-item__label {
  font-size: 16px;
}
.el-form /deep/ .el-form-item__content {
  font-size: 16px;
}

.category-icon {
  display: inline-block;
  width: 82px;
  height: 82px;
  object-fit: fill;
}
</style>