import axiosHttp from "../axiosHttp";

export default {
    querySingleItemList(param){
        return axiosHttp.POST("/freshMilkStock/querySingleItemList", param);
    },
    queryRecordPage(param){
        return axiosHttp.POST("/freshMilkStock/queryRecordPage", param);
    },
    queryRecordList(param){
        return axiosHttp.POST("/freshMilkStock/queryRecordList", param);
    },
    entryStockApply(param){
        return axiosHttp.POST("/freshMilkStock/entryStockApply", param);
    }
};
