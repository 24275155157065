<template>
  <div class="ws-input" :class="{'emptyError': isEmptyError && !isFocus && !$_value}">
    <input
      :type="type"
      :disabled="disabled"
      :maxlength="maxlength"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      @keyup.enter="handleEnter"
      :value="$_value"
      ref="inputRef"
    />
    <div
      class="input-tip"
      :style="{'color': isFocus ? '#237279' : '#666'}"
      :class="{'ani': isFocus || $_value != '' }"
      @click="clickInput()"
    >{{$_placeholder}}</div>
  </div>
</template>

<script>
export default {
  name: "wsInput",
  props: {
    value: [String, Number],
    placeholder: String,
    errorText: String,
    maxlength: [String, Number],
    isEmptyError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    }
  },
  data() {
    return {
      isFocus: false
    };
  },
  computed: {
    $_value() {
      return this.value;
    },
    $_placeholder() {
      if (this.isFocus || this.$_value) {
        return this.placeholder;
      } else {
        return this.isEmptyError ? this.errorText : this.placeholder;
      }
    }
  },
  methods: {
    clickInput() {
      this.isFocus = true;
      this.$refs["inputRef"].focus();
    },
    handleFocus(evt) {
      this.isFocus = true;
      this.$emit("focus", evt);
    },
    handleBlur(evt) {
      this.isFocus = false;
      this.$emit("blur", evt);
    },
    handleInput(evt) {
      this.$emit("input", evt.target.value);
    },
    handleEnter(evt) {
      this.$emit("enter", evt.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
input[disabled] {
  background: #fff;
  cursor: not-allowed;
}
.ws-input {
  position: relative;
  &.emptyError {
    input {
      border: 1px solid #e60000;
    }
    .input-tip {
      color: #e60000 !important;
    }
  }
  input {
    position: relative;
    height: 56px;
    margin-top: 30px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 13px 15px;
    z-index: 1;
    &:focus {
      border: 1px solid #237279;
    }
  }
  .input-tip {
    position: absolute;
    left: 8px;
    bottom: 21px;
    padding: 0 5px;
    background-color: #fff;
    color: #666;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: 0px 0px;
    z-index: 1;
  }
  .ani {
    transform: translateY(-24px) scale(0.75);
  }
}
</style>