<template>
    <div id="RecommendEdit">
        <div class="raw">
            <div class="RecommendEdit-title">
                选择原商品（必填）
            </div>
            <div class="pitch-on">
                <div class="pitch-on-items">
                    <div class="label">商品编码</div>
                    <el-input :disabled="isSee" v-model="tableData.goodsCode" size="small" placeholder="请选择商品编码" @focus="$refs.selectGoodsPopup01.dialogVisible = true,pitch='on'"></el-input>
                </div>
                <div class="pitch-on-items">
                    <div class="label">商品名称</div>
                    <el-input v-model="tableData.goodsName" size="small" disabled autosize placeholder="自动填写"></el-input>
                </div>
            </div>
        </div>
        <div class="recommended">
            <div class="RecommendEdit-title">
                推荐商品
                <el-button style="margin-left:23px" type="primary" :disabled="isSee" @click="$refs.selectGoodsPopup.dialogVisible = true">添加推荐商品</el-button>
            </div>
            <ws-page-table style="width:100%" :data="tableData.childGoodsList">
                <el-table-column label="商品编码" prop="goodsCode"></el-table-column>
                <el-table-column label="商品名称" prop="goodsName"></el-table-column>
                <el-table-column label="展示顺序" prop="dispNo"></el-table-column>
                <el-table-column label="排序">
                    <template slot-scope="scope">
                        <a href="javascript: void(0);" @click.stop="sortUp(scope.$index)" v-show="scope.$index!=0" title="向上">
                            <i class="el-icon-upload2 iconT"></i>
                        </a>
                        <a href="javascript: void(0);" @click.stop="sortDown(scope.$index)" v-show="tableData.childGoodsList.length-1!=scope.$index" title="向下">
                            <i class="el-icon-download iconT"></i>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="icon-operation">
                            <a href="javascript: void(0);" @click="goDetele(scope.$index)" title="删除">
                                <i class="el-icon-delete-solid"></i>
                            </a>
                        </div>
                    </template>
                </el-table-column>
            </ws-page-table>
        </div>
        <select-goods-popup ref="selectGoodsPopup01" @submit="getPitchData($event)"></select-goods-popup>
        <select-goods-popup ref="selectGoodsPopup" @submit="getTableData($event)"></select-goods-popup>
        <!--
      <select-goods-popup ref="selectGoodsPopup" @submit="pitch=='on'?getPitchData($event):getTableData($event)"></select-goods-popup>
      -->

        <div style="height:50px"></div>
        <div class="float-button">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" :disabled="isSee" @click="Submit">提交</el-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import selectGoodsPopup from "../spu/selectGoodsPopup";
import { saveBatchRelation } from "@/api/pim/PimRecommend.js";
export default {
    data() {
        return {
            pitch: "",
            isSee: false,
            tableData: {
                goodsId: "",
                goodsName: "",
                goodsCode: "",
                childGoodsList: [],
            },
        };
    },
    mixins: [pageTableMixin],
    mounted() {
        if (this.$route.query.content) {
            console.log(this.$route.query.content);
            this.tableData = this.$route.query.content;
        }
        if (this.$route.query.isSee) {
            this.isSee = true;
        }
    },
    methods: {
        loadTableData() {},
        getPitchData(event) {
            console.log(event);
            this.tableData.goodsId = event[0].goodsId;
            this.tableData.goodsName = event[0].cnName;
            this.tableData.goodsCode = event[0].goodsCode;
            this.pitch = "";
        },
        getTableData(event) {
            console.log(this.tableData);
            for (let i in event) {
                if (event[i].goodsCode == this.tableData.goodsCode) {
                    event.splice(i, 1);
                    this.$message({
                        message: "推荐商品不可为原商品",
                        type: "warning",
                    });
                }
                event[i].dispNo = ++i;
            }
            event = event.map((e) => {
                return {
                    goodsId: e.goodsId,
                    goodsName: e.cnName,
                    goodsCode: e.goodsCode,
                    dispNo: e.dispNo,
                };
            });
            this.tableData.childGoodsList =
                this.tableData.childGoodsList.concat(event);
            this.dispNo();
        },
        sortUp(index) {
            let temp = this.tableData.childGoodsList[index - 1];
            Vue.set(
                this.tableData.childGoodsList,
                index - 1,
                this.tableData.childGoodsList[index]
            );
            Vue.set(this.tableData.childGoodsList, index, temp);
            this.dispNo();
        },
        sortDown(index) {
            let temp = this.tableData.childGoodsList[index + 1];
            Vue.set(
                this.tableData.childGoodsList,
                index + 1,
                this.tableData.childGoodsList[index]
            );
            Vue.set(this.tableData.childGoodsList, index, temp);
            this.dispNo();
        },
        dispNo() {
            let childGoodsList = this.tableData.childGoodsList;
            for (let i in childGoodsList) {
                childGoodsList[i].dispNo = ++i;
            }
        },
        goDetele(index) {
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                // let couponId = this.tableData.couponList[index].couponId;
                // if (couponId) {
                //     BimActivity.delById(couponId).then((data) => {
                //         this.tableData.couponList.splice(index, 1);
                //         this.$message({ type: "success", message: "删除成功" });
                //     });
                // } else {
                this.tableData.childGoodsList.splice(index, 1);
                this.dispNo();
                // }
            });
        },
        Submit() {
            if (this.tableData.goodsId == "") {
                return this.$message({
                    message: "请选择原商品",
                    type: "warning",
                });
            }
            if (this.tableData.childGoodsList.length == 0) {
                return this.$message({
                    message: "推荐商品至少有一条记录",
                    type: "warning",
                });
            }
            let tableData = this.tableData;
            let arr = [];
            for (let i in tableData.childGoodsList) {
                let params = {
                    goodsRelationId: "",
                    parentGoodsId: tableData.goodsId,
                    childGoodsId: tableData.childGoodsList[i].goodsId,
                    dispNo: tableData.childGoodsList[i].dispNo,
                };
                arr.push(params);
            }
            saveBatchRelation(arr).then((data) => {
                this.$message({ type: "success", message: "保存成功" });
                this.$router.push({
                    path: "/pim/RecommendList",
                });
            });
        },
        cancel() {
            this.$router.push({
                path: "RecommendList",
            });
        },
    },
    components: {
        selectGoodsPopup,
    },
};
</script>

<style scoped>
#RecommendEdit {
    display: flex;
    flex-direction: column;
}
.raw {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    height: 192px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
}
.RecommendEdit-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 53px;
    padding-left: 32px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.pitch-on {
    height: 100%;
    padding-left: 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.pitch-on-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 133px;
}
.label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
}
.recommended {
    margin-top: 24px;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
}
#RecommendEdit >>> .pitch-on-items input {
    width: 294px;
}
/* #RecommendEdit >>> .el-table th {
    background-color: rgba(250, 250, 250, 1) !important;
} */
.iconT {
    color: #468dea;
    font-size: 20px;
    margin-right: 10px;
}
.float-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
}
</style>