<template>
    <!--基本信息-->
    <div class="member-detail">
        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">基本信息</div>
            <div class="detail-form">
                <div class="form-item">
                    <span class="form-label">姓名：</span>
                    <span class="form-value">{{ info.AccountName }}</span>
                </div>
                <div class="form-item" v-if="info.accountType=='公司账号'">
                    <span class="form-label">操作人：</span>
                    <span class="form-value">{{ info.fullName }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">账号类型：</span>
                    <span class="form-value">{{ info.accountType }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">电话：</span>
                    <span class="form-value">{{ info.tel }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">微信昵称：</span>
                    <span class="form-value">{{ info.wechatNickname }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">会员昵称：</span>
                    <span class="form-value">{{ info.nickName }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">状态：</span>
                    <span class="form-value">{{ info.userState }} <div v-if="info.userState=='正常会员' || info.userState=='冻结会员'" class="stateButton" @click="StateDispose">{{userButtonState}}</div></span>
                </div>
                <div class="form-item">
                    <span class="form-label">会员有效期：</span>
                    <span class="form-value">{{ info.yearCardEndTime }}</span>
                </div>
            </div>
        </div>
        <!--证件信息-->
        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">证件信息</div>
            <div class="detail-form detail_img-form" style="display: flex">
                <div class="form-item">
                    <span class="form-label">证件照正面：</span>
                    <div class="image-list" v-if="info.frontPhotoUrl">
                        <div class="image-item">
                            <el-image style="width: 82px; height: 82px" :src="info.frontPhotoUrl" :preview-src-list="[info.frontPhotoUrl]"></el-image>
                        </div>
                    </div>
                    <span class="form-value" v-else>无</span>
                </div>
                <div class="form-item" style="margin-left:80px">
                    <span class="form-label" style="width: auto">证件照反面：</span>
                    <div class="image-list" v-if="info.contraryPhotoUrl">
                        <div class="image-item">
                            <el-image style="width: 82px; height: 82px" :src="info.contraryPhotoUrl" :preview-src-list="[info.contraryPhotoUrl]"></el-image>
                        </div>
                    </div>
                    <span class="form-value" v-else>无</span>
                </div>
            </div>
        </div>

        <!--费用信息-->
        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">费用信息</div>
            <div class="detail-form">
                <div class="form-item">
                    <span class="form-label1">账户余额</span>
                    <el-button size="mini" type="primary" style="margin-left: 10px" @click="modifyBalance">修改余额</el-button>
                </div>
                <div class="form-item">
                    <span class="form-label">总计充值金额：</span>
                    <span class="form-value">￥{{ info.cumulativePoints }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">账户余额：</span>
                    <span class="form-value">￥{{ info.point }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">已提现余额：</span>
                    <span class="form-value">￥{{ info.historicalWithdrawBond }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">可提现余额：</span>
                    <span class="form-value">￥{{ info.bond }}</span>
                </div>

                <div class="form-item">
                    <span class="form-label1" style="padding-top: 20px">服务费</span>
                </div>
                <div style="display: flex;">
                  <div>
                    <div class="form-item">
                      <span class="form-label">总计主服务费：</span>
                      <span class="form-value">￥{{ info.totalServiceCharge }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">总计推广服务费：</span>
                      <span class="form-value">￥{{ info.cumulativeSaleProfits }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">总计团队服务费：</span>
                      <span class="form-value">￥{{ info.cumulativeCommissionProfit }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">已提现主服务费：</span>
                      <span class="form-value">￥{{ info.historicalWithdrawProfits }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">可提现主服务费：</span>
                      <span class="form-value">￥{{ info.profits }}</span>
                    </div>
                  </div>
                  <div class="ml15">
                    <div class="form-item">
                      <span class="form-label">总计次服务费：</span>
                      <span class="form-value">￥{{ info.confirmTotalFee }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">总计伙伴服务费：</span>
                      <span class="form-value">￥{{ info.confirmEgalitarianFee }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">总计联盟服务费：</span>
                      <span class="form-value">￥{{ info.confirmAllianceFee }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">已提现次服务费：</span>
                      <span class="form-value">￥{{ info.alreadyMarketFee }}</span>
                    </div>
                    <div class="form-item">
                      <span class="form-label">可提现次服务费：</span>
                      <span class="form-value">￥{{ info.availableMarketFee }}</span>
                    </div>
                  </div>
                </div>


                <div class="form-item">
                    <span class="form-label1" style="padding-top: 20px">成就值</span>
                </div>
                <div class="form-item">
                    <p class="results" v-for="(item,index) in info.results" :key="index">{{ item }}</p>
                </div>
            </div>
        </div>
        <!--余额支付和开票信息-->
        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">余额开票信息（统计余额支付订单的开票信息）</div>
            <div class="detail-form">
                <div class="form-item">
                    <span class="form-label">余额支付总计：</span>
                    <span class="form-value">{{ info.pointDeductionSum }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">其中，开票中金额：</span>
                    <span class="form-value">{{ info.invoiceState0Amount }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">其中，已开票金额：</span>
                    <span class="form-value">{{ info.invoiceState1Amount }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">未开票金额：</span>
                    <span class="form-value">{{ info.invoiceUnAmount }}</span>
                </div>
            </div>
        </div>
        <!--会员关系-->
        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">会员关系</div>
            <div class="detail-form">
                <div class="form-item">
                    <span class="form-label">推荐人姓名：</span>
                    <span class="form-value">{{ info.recommendName }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">上级姓名：</span>
                    <span class="form-value">{{ info.hierarchicalName }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">伙伴人数：</span>
                    <span class="form-value">{{ info.recommendNumber }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">团队银星人数：</span>
                    <span class="form-value">{{ info.hierarchicalG1Number }}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">团队普标人数：</span>
                    <span class="form-value">{{ info.hierarchicalG0Number }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BimMemberService from "API/bim/BimMemberService";
import tools from "COMJS/tools";
import chargeFormat from "COMJS/chargeFormat";

export default {
    data() {
        return {
            info: {},
            id: "",
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.queryDetails();
    },
    methods: {
        queryDetails() {
            let userId = this.id;
            BimMemberService.queryDetails(userId).then((data) => {
                console.log(data);
                data.yearCardEndTime = data.yearCardEndTime
                    ? tools.formatDate(
                          new Date(data.yearCardEndTime),
                          "yyyy-MM-dd hh:mm:ss"
                      )
                    : "无";
                data.userState = this.translateAuthState(data.userState);
                data.hierarchicalName = data.hierarchicalName
                    ? decodeURI(data.hierarchicalName)
                    : "无";
                data.recommendName = data.recommendName
                    ? decodeURI(data.recommendName)
                    : "无";
                data.fullName = data.fullName
                    ? data.fullName
                    : decodeURI(data.nickName);
                data.AccountName = data.companyName
                    ? data.companyName
                    : data.fullName
                    ? data.fullName
                    : decodeURI(data.nickName);
                data.nickName = data.nickName ? decodeURI(data.nickName) : "无";
                data.wechatNickname = data.wechatNickname
                    ? decodeURI(data.wechatNickname)
                    : "无";
                data.tel = data.tel ? data.tel : "无";
                data.cumulativePoints = data.cumulativePoints
                    ? data.cumulativePoints.toFixed(2)
                    : "0.00";
                data.point = data.point ? data.point.toFixed(2) : "0.00";
                data.bond = data.bond ? data.bond.toFixed(2) : "0.00";
                data.historicalWithdrawBond = data.historicalWithdrawBond
                    ? data.historicalWithdrawBond.toFixed(2)
                    : "0.00";
                data.totalServiceCharge = data.totalServiceCharge
                    ? data.totalServiceCharge.toFixed(2)
                    : "0.00";
                data.historicalWithdrawProfits = data.historicalWithdrawProfits
                    ? data.historicalWithdrawProfits.toFixed(2)
                    : "0.00";
                data.profits = data.profits ? data.profits.toFixed(2) : "0.00";
                data.cumulativeSaleProfits = data.cumulativeSaleProfits
                    ? data.cumulativeSaleProfits.toFixed(2)
                    : "0.00";
                data.cumulativeCommissionProfit =
                    data.cumulativeCommissionProfit
                        ? data.cumulativeCommissionProfit.toFixed(2)
                        : "0.00";
                // data.cumulativeEgalitarianProfits = data.cumulativeEgalitarianProfits.toFixed(2);
                // data.cumulativeAllianceProfit = data.cumulativeAllianceProfit.toFixed(2);
                data.recommendNumber = data.recommendNumber;
                data.hierarchicalG1Number = data.hierarchicalG1Number;
                data.hierarchicalG0Number = data.hierarchicalG0Number;
                data.confirmTotalFee = data.confirmTotalFee
                    ? data.confirmTotalFee.toFixed(2)
                    : "0.00";
                data.confirmEgalitarianFee = data.confirmEgalitarianFee
                    ? data.confirmEgalitarianFee.toFixed(2)
                    : "0.00";
                data.confirmAllianceFee = data.confirmAllianceFee
                    ? data.confirmAllianceFee.toFixed(2)
                    : "0.00";
                data.alreadyMarketFee = data.alreadyMarketFee
                    ? data.alreadyMarketFee.toFixed(2)
                    : "0.00";
                data.availableMarketFee = data.availableMarketFee
                    ? data.availableMarketFee.toFixed(2)
                    : "0.00";
                data.accountType = data.companyName ? "公司账号" : "个人账号";
                //余额开票信息
                data.invoiceUnAmount =
                    data.pointDeductionSum -
                    data.invoiceState0Amount -
                    data.invoiceState1Amount;
                data.pointDeductionSum = data.pointDeductionSum
                    ? data.pointDeductionSum.toFixed(2)
                    : "0.00";
                data.invoiceState0Amount = data.invoiceState0Amount
                    ? data.invoiceState0Amount.toFixed(2)
                    : "0.00";
                data.invoiceState1Amount = data.invoiceState1Amount
                    ? data.invoiceState1Amount.toFixed(2)
                    : "0.00";
                data.invoiceUnAmount = data.invoiceUnAmount
                    ? data.invoiceUnAmount.toFixed(2)
                    : "0.00";
                this.info = data;
                //console.log(this.info)
            });
        },
        translateAuthState(authState) {
            let stateStr;
            if (authState == "normal") {
                stateStr = "正常会员";
            } else if (authState == "freeze") {
                stateStr = "冻结会员";
            } else if (authState == "expires" || authState == "expires_heap") {
                stateStr = "过期会员";
            } else if (authState == "cancel_process") {
                stateStr = "注销中会员";
            } else if (authState == "cancel") {
                stateStr = "已注销会员";
            }
            return stateStr;
        },
        modifyBalance() {
            this.$router.push({ path: "/bim/memberAccountModify/" + this.id });
        },
        StateDispose() {
            let content;
            let userId = this.id;
            if (this.info.userState == "正常会员") {
                content = `操作冻结后，账户不可下单且不计算服务费，解冻后服务费不可追回。是否冻结？`;
                this.$confirm(content, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    BimMemberService.lockAccount(userId).then((data) => {
                        this.$message({
                            type: "success",
                            message: "操作成功",
                        });
                        this.queryDetails();
                    });
                });
            }
            if (this.info.userState == "冻结会员") {
                content = `操作后，账户冻结状态解除，是否确认操作？`;
                this.$confirm(content, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    BimMemberService.unlockAccount(userId).then((data) => {
                        this.$message({
                            type: "success",
                            message: "操作成功",
                        });
                        this.queryDetails();
                    });
                });
            }
        },
    },
    computed: {
        userButtonState() {
            return this.info.userState == "正常会员" ? "冻结账号" : "恢复正常";
        },
    },
};
</script>

<style scoped lang="scss">
.member-detail {
    .detail-item {
        margin-bottom: 40px;

        .detail-item_title {
            line-height: 60px;
            font-weight: bold;
            font-size: 18px;
            padding-left: 20px;
            background-color: #f6f9fc;
        }

        .detail-item_title2 {
            line-height: 60px;
            font-weight: bold;
            font-size: 18px;
            padding-left: 20px;
            background-color: #f6f9fc;
        }

        .detail-form {
            font-size: 14px;
            line-height: 20px;
            padding: 30px 20px 20px 0;
            margin-left: 50px;
            .form-item {
                margin-bottom: 14px;

                &:last-of-type {
                    margin-bottom: 0px;
                }
                .results {
                    margin-bottom: 10px;
                }

                .form-label {
                    display: inline-block;
                    text-align: right;
                    width: 150px;
                }

                .form-label1 {
                    display: inline-block;
                    text-align: right;
                    width: 150px;
                    font-weight: bold;
                    font-size: 18px;
                    padding-bottom: 10px;
                }

                .form-value {
                    color: #666;
                }
            }
        }

        .detail_img-form {
            .form-item {
                margin-bottom: 30px;

                .form-label {
                    margin-left: 30px;
                    display: inline-block;
                    margin-right: 10px;
                    margin-top: 8px;
                }

                .image-list {
                    display: inline-block;
                    vertical-align: top;

                    .image-item {
                        display: inline-block;
                        margin-right: 10px;

                        img {
                            width: 82px;
                            height: 82px;
                            object-fit: fill;
                        }
                    }
                }
            }
        }
    }

    table {
        width: 100%;

        .th-tr {
            font-size: 18px;
            background-color: #f6f9fc;
            font-weight: bold;

            th {
                color: #8898aa;

                &:first-of-type {
                    color: #333;
                }
            }
        }

        tr {
            display: block;
            font-size: 16px;
            line-height: 60px;
            padding-left: 20px;
            text-align: left;
            border-bottom: 1px solid #e9ecef;

            &:last-of-type {
                border-bottom: none;
            }

            td {
                display: inline-block;
            }
        }

        .table-col-1 {
            width: 175px;
        }

        .table-col-2 {
            width: 400px;
        }
    }
    .stateButton {
        display: inline-block;
        text-align: center;
        width: 64px;
        height: 20px;
        margin-left: 10px;
        border: 1px solid rgba(24, 144, 255, 1);
        color: rgba(24, 144, 255, 1);
        border-radius: 3px;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>