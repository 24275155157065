<template>
    <div id="chooseUserPop">
        <el-dialog title="选择用户" :visible.sync="show" width="50%">
            <div class="User">
                <div>会员账号：</div>
                <el-autocomplete style="display: block; width: 170px" v-model="value" size="mini" :fetch-suggestions="search" @select="selectGroup" @focus="groupListMe" placeholder="请输入会员账号">
                </el-autocomplete>
            </div>
            <div class="bottomBtn">
            <el-button type="primary" @click="determine">确定</el-button>
            <el-button @click="cancel">取消</el-button>
        </div>
        </el-dialog>
    </div>
</template>

<script>
import BimActivity from "@/api/bim/BimActivity";
export default {
    data() {
        return {
            show: false,
            value: "",
            userId: "",
            thisSearchData: [],
        };
    },
    mounted() {
        this.Dialog();
    },
    watch: {
        value: {
            deep: true,
            handler: function (newVal, oldVal) {
                this.possibleData = []; //这是定义好的用于存放下拉提醒框中数据的数组
                var len = this.thisSearchData.length; //SearchData
                var arr = [];
                for (var i = 0; i < len; i++) {
                    //根据输入框中的值进行模糊匹配
                    if (
                        this.thisSearchData[i].account.indexOf(this.value) >= 0
                    ) {
                        arr.push({
                            value: this.thisSearchData[i].account,
                        });
                    }
                }
                this.possibleData = arr;
            },
        },
    },
    methods: {
        Dialog() {
            this.$on("openDialog", (index) => {
                this.show = true;
                this.index = index;
            });
        },
        determine(){
            let params={
                Useritem:this.Useritem,
                index:this.index,
            }
            this.$emit('UserData', params);
            this.show=false;
        },
        cancel(){
            this.show=false;
        },
        groupListMe() {
            console.log("groupListMe");
            BimActivity.listUserInDistributor()
                .then((data) => {
                    this.thisSearchData = [];
                    this.possibleData = [];
                    this.thisSearchData = data;
                    for (let item of this.thisSearchData) {
                        this.possibleData.push({
                            value: item.account,
                        });
                    }
                    console.log(this.possibleData);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        search(queryString, cb) {
            console.log("search");
            var possibleData = this.possibleData;
            cb(possibleData);
        },
        selectGroup(val) {
            console.log("selectGroup");
            console.log(val);
            for (let i in this.thisSearchData) {
                if (val.value == this.thisSearchData[i].account) {
                    this.Useritem = this.thisSearchData[i];
                }
            }
            this.value = val.value;
        },
    },
};
</script>

<style scoped>
.User{
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: center;
    padding: 50px;
}
.bottomBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
</style>