<template>
    <div>
        <el-dialog title="选择库品单位" :visible.sync="dialogVisible" width="500px">
            <div class="select-brand-content">
                <div class="input-search">
                    <el-input v-model="searchKey"></el-input>
                    <el-button type="primary" @click="queryUnitList">搜索</el-button>
                    <el-button type="primary" @click="showUnit">新增单位</el-button>
                </div>
                <div class="search-result">
                    <div class="search-result-item" @click="selectUnit(item, index)" v-for="(item, index) in unitSearchResult" :key="index" :class="{ active : index == current}">{{item.unitName}}
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">下一步</el-button>
            </span>
        </el-dialog>
        <!--新增单位-->
        <el-dialog title="新增单位'" :visible.sync="unitDialog" @close="closeAddDialog" width="606px">
            <el-form label-width="140px">
                <el-form-item required label="单位名称：">
                    <el-input v-model="currentInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="是否启用：">
                    <el-switch :value="currentInfo.enabled" @click.native="editEnable()"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="unitDialog = false">取消</el-button>
                <el-button type="primary" @click="addUnit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import WhmList from "API/whm/WhmList";
import SearchConfig from "COMJS/query/SearchConfig";

export default {
    data() {
        return {
            currentInfo: {
              name:'',
              enabled:true
            },
            unitDialog: false, //是否显示 添加修改单位窗口
            searchKey: "",
            dialogVisible: false,
            unitSearchResult: [],
            current: null,
            selectedUnit: {
                unitId: "",
                unitName: "",
            },
        };
    },
    computed: {
        unitSearchConfig() {
            return SearchConfig.createNew({
                searchForm: {
                    unitName: this.selectedUnit.unitName,
                },
            });
        },
    },
    mounted() {
        this.queryUnitList();
    },
    methods: {
        submit() {
            this.$emit("submit", this.selectedUnit);
            this.dialogVisible = false;
        },
        selectUnit(item, index) {
            this.selectedUnit = item;
            this.current = index;
        },
        // 查询品牌
        queryUnitList() {
            // console.log(!this.searchKey)
            let params = this.searchKey;
            if (!this.searchKey) {
                params = "all";
            }
            WhmList.queryUnitPages(params).then((data) => {
                this.unitSearchResult = data.map((e) => {
                    return {
                        unitId: e.specUnitId,
                        unitName: e.name,
                    };
                });
            });
        },
        showUnit() {
            this.unitDialog = true;
        },
        closeAddDialog() {
            this.currentInfo.id = "";
            this.currentInfo.name = "";
        },
        addUnit() {
            let params = {
                specUnitId: this.currentInfo.id,
                name: this.currentInfo.name,
                enabled: this.currentInfo.enabled ? 1 : 0,
                specUnitType: 0,
            };
            this.loading = true;
            WhmList.saveUnitSpec(params).then((res) => {
                this.loading = false;
                this.$message({
                    type: "success",
                    message: "添加单位成功!",
                });
                this.currentInfo.name = "";
                this.queryUnitList();
                this.unitDialog = false;
                if (this.currentInfo.id) {
                    this.currentInfo.id = "";
                }
            });
        },
        editEnable() {this.currentInfo.enabled = !this.currentInfo.enabled },
    },
};
</script>

<style scoped lang="scss">
.el-input--small .el-input__inner {
    width: 150px;
}
.select-brand-content {
    .input-search {
        padding: 20px;
        border-bottom: 1px solid $borderColor;
        .el-input {
            width: 200px;
            margin-right: 20px;
        }
    }
    .search-result {
        .search-result-item {
            cursor: pointer;
            line-height: 50px;
            padding: 0 20px;
            color: #666;
            border-bottom: 1px solid $borderColor;
            &:hover {
                background-color: rgba(51, 51, 51, 0.1);
            }
        }
    }
    .search-result-item.active,
    .search-result-item.active:hover {
        background-color: #e8f1fd;
    }
}
</style>