<template>
  <div class="invlist-box">
    <!--
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="经销商账号:">
        <el-input type="text" v-model="searchInfo.dealerAccount"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    -->

    <div class="inv-tab">
      <div class="inv-tab-box">
        <div
            class="inv-tab-list"
            @click="onTabClick(0)"
            :class="{ active: idx == 0 }"
        >
          全部
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(1)"
            :class="{ active: idx == 1 }"
        >
          待审核
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(2)"
            :class="{ active: idx == 2 }"
        >
          审核通过
        </div>
        <div
            class="inv-tab-list"
            @click="onTabClick(3)"
            :class="{ active: idx == 3 }"
        >
          审核驳回
        </div>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column
          prop="createTime"
          label="提交时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="dealerAccount"
          label="经销商账号"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="dealerName"
          label="经销商名称"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="auditState"
          label="审核状态"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="auditTime"
          label="审核时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="operNum"
          label="提现金额"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                v-show="scope.row.auditState == '待审核'"
                href="javascript: void(0);"
                @click="showAuditDialog(scope.row, 'review')"
                class="blue-txt"
                title="审核"
            >
              <i class="icon-review"></i>
            </a>
            <a
                v-show="scope.row.auditState == '审核通过' || scope.row.auditState == '审核驳回'"
                href="javascript: void(0);"
                @click="showAuditDialog(scope.row, 'info')"
                class="blue-txt"
                title="查看"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog title="提现审核" :visible.sync="showDialog" width="500px">
      <div class="dea-txt dea-tit">提现金额：¥ {{ contentObj.withdrawAmount }}</div>
      <div class="dea-txt dea-tit">提现账户信息</div>
      <div class="dea-txt">
        账户名称：{{ contentObj.accountName }}
      </div>
      <div class="dea-txt">银行账号：{{ contentObj.accountNo }}</div>
      <div class="dea-txt">银行名称：{{ contentObj.bankName }}</div>
      <div class="dea-txt" v-show="dealerLog.auditState == '待审核' && infoState == 'review'">
        <!--hz-->
        <span class="sp1">备注：</span>
        <el-input v-model="auditRemark" class="reason"></el-input>
        <span class="ml15">(驳回必填)</span>
      </div>
      <div class="dea-txt dea-tit">
        审核结果：{{ dealerLog.auditState }}
      </div>
      <div class="dea-txt" v-if="dealerLog.auditState == '3'">审核原因：{{ dealerLog.remark }}</div>
      <div class="dea-btn-wrap">
        <el-button type="success" @click="saveCredit('success')" v-show="infoState == 'review'">通过</el-button>
        <el-button type="warning" @click="saveCredit('fail')" v-show="infoState == 'review'">驳回</el-button>
        <el-button type="primary" @click="closeCredit" v-show="infoState == 'info'">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import dealerService from "API/dea/dealerService";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService.js";

export default {
  name: "dealerList",
  data() {
    return {
      searchInfo: {
        dealerAccount: "",
        type:"19",
        auditState: "awaiting,success,fail",
      },
      items: {
        dealerAccount: {filter: "like"},
        type: {filter: "eq"},
        auditState: {filter: "in"},
      },
      idx: 0,
      showDialog: false,
      dealerLog:{},
      contentObj:{},
      infoState: "",
      auditRemark: "",

      auditLogId: "",
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData();
  },
  methods: {
    saveCredit(state) {
      let auditResult = state
      let params = {
        auditConfigurationCode:"F004",
        businessId: this.dealerLog.dealerLogId,
        auditResult:auditResult,
      }
      if (state == 'fail') {
        if (!this.auditRemark) {
          return this.$message({
            message: "请输入驳回原因",
            type: "warning",
          });
        }
      }
      params.auditRemark = this.auditRemark
      dealerService.auditFlowHandle(params).then((data) => {
        this.showDialog = false;
        this.loadTableData();
        return this.$message({
          message: auditResult == 'success'?"通过审核":"驳回审核",
          type: "success",
        });
      });
    },
    closeCredit() {
      this.showDialog = false;
    },
    onTabClick(idx) {
      if (this.$refs.pageTable.tableLoading) {
        //加载完才能切换tab
        return;
      }
      if (idx == 0) {
        this.searchInfo.auditState = "awaiting,success,fail";
      } else if (idx == 1) {
        this.searchInfo.auditState = "awaiting"; //待审核
      } else if (idx == 2) {
        this.searchInfo.auditState = "success"; //审核通过
      } else if (idx == 3) {
        this.searchInfo.auditState = "fail"; //审核失败驳回
      }
      this.idx = idx;
      this.loadTableData();
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;

      dealerService.queryDealerLog(this.pageInfo,this.searchConfig).then((data) => {
        data.list = data.list.map((e) => {
          return {
            ...e,
            auditState:
                e.auditState == "awaiting"
                    ? "待审核"
                    : e.auditState == "success"
                    ? "审核通过"
                        : e.auditState == "fail"
                            ? "审核驳回"
                            : "",
            createTime: e.createTime
                ? tools.formatDate(new Date(e.createTime), "yyyy-MM-dd hh:mm:ss")
                : "",
            auditTime: e.auditTime
                ? tools.formatDate(new Date(e.auditTime), "yyyy-MM-dd hh:mm:ss")
                : "",
          };
        });
        //console.log(data.list)
        this.querySuccess(data);
      });
    },
    showAuditDialog(row, state) {
      this.infoState = state == "review" ? "review" : "info"
      this.dealerLog = row
      let content = row.content
      if(content){
        this.contentObj = JSON.parse(content)
      }else{
        this.contentObj = {}
      }
      //console.log(this.dealerLog)
      this.showDialog = true

    },
  },
};
</script>
<style>
.el-image {
  margin-right: 10px;
}

.icon-review {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/review.png");
  background-size: cover;
}

.dea-tit {
  margin-top: 15px;
}

.reason,
.reason .el-input__inner {
  width: 200px;
}

.invlist-box .demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.invlist-box .el-form--inline .el-form-item {
  margin-left: 10px;
  margin-bottom: 0;
}

.invlist-box .header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}

.invlist-box .ws-page-table .icon-operation a {
  margin-right: 20px;
}

.inv-tab {
  padding-top: 10px;
  padding-bottom: 20px;
  height: 70px;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  background: #fff;
}

.inv-tab-box {
  margin: 0 20px;
  height: 39px;
  display: flex;
  border-left: 1px solid #dcdee0;
  border-bottom: 1px solid #e9ecef;
}

.inv-tab-list {
  width: 90px;
  height: 38px;
  position: relative;
  text-align: center;
  line-height: 38px;
  border-top: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-bottom: 0;
  font-size: 14px;
  cursor: pointer;
}

.inv-tab-list.active:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -3px;
  width: 90px;
  height: 6px;
  background: #fff;
  z-index: 1;
}

.invlist-box .ws-page-table .icon-operation a.blue-txt {
  color: #1b73e8;
}

.dea-txt {
  padding: 9px 0;
  display: flex;
  color: #666;
}

.dea-txt .sp1 {
  white-space: nowrap;
}

.dea-txt .sp2 {
  margin-left: 50px;
}

.dea-btn-wrap {
  text-align: center;
}

.icon-download {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/download.png");
  background-size: cover;
}
</style>