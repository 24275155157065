<template>
    <!-- 废弃 -->
    <div class="product-detail">
        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">基本信息</div>
            <div class="detail-form detail_img-form">
                <div class="form-item">
                    <span class="form-label">聚合名称：</span>
                    <span class="form-value">{{spuInfo.spuName}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">聚合规格：</span>
                    <span class="form-value">{{spuInfo.spuSpec}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">零售价：</span>
                    <span class="form-value">￥{{spuInfo.spuPrice}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">划线价：</span>
                    <span class="form-value">￥{{spuInfo.spuMarkingPrice}}</span>
                </div>
            </div>
        </div>
      <div class="detail-item box-shadow-unify">
        <div class="detail-item_title">图片信息</div>
        <div class="detail-form detail_img-form">
          <div class="form-item">
            <span class="form-label">缩略图：</span>
            <div class="image-list" v-if="spuInfo.spuPicUrl">
              <div class="image-item">
                <el-image
                    style="width: 82px; height: 82px"
                    :src="spuInfo.spuPicUrl"
                    :preview-src-list="[spuInfo.spuPicUrl]"
                ></el-image>
              </div>
            </div>
            <span class="form-value" v-else>无</span>
          </div>
          <div class="form-item">
            <span class="form-label">轮播图：</span>
            <div class="image-list" v-if="rotationImgs.length">
              <div class="image-item" v-for="(item, index) in rotationImgs" :key="index">
                <!-- <img :src="item" alt /> -->
                <el-image
                    style="width: 82px; height: 82px"
                    :src="item"
                    :preview-src-list="rotationImgs"
                ></el-image>
              </div>
            </div>
            <span class="form-value" v-else>无</span>
          </div>
          <div class="form-item">
            <span class="form-label">详情图：</span>
            <div class="image-list" v-if="detailImgs.length">
              <div class="image-item" v-for="(item, index) in detailImgs" :key="index">
                <!-- <img :src="item" alt /> -->
                <el-image
                    style="width: 82px; height: 82px"
                    :src="item"
                    :preview-src-list="detailImgs"
                ></el-image>
              </div>
            </div>
            <span class="form-value" v-else>无</span>
          </div>
        </div>
      </div>

      <div class="detail-item box-shadow-unify">
        <div class="detail-item_title">关联商品</div>
        <ws-page-table :data="spuInfo.relaList" ref="pageTable" style="width:100%;" :pageable="false" :showIndex="true">
          <el-table-column prop="goodsName" label="商品名"></el-table-column>
          <el-table-column prop="goodsCode" label="商品编号"></el-table-column>
          <el-table-column prop="skuSpec" label="规格"></el-table-column>
          <el-table-column prop="relaMode" label="商品关联模式"></el-table-column>
        </ws-page-table>
      </div>

      <div class="detail-item box-shadow-unify">
        <div class="detail-item_title">标签信息</div>
        <ws-page-table :data="spuInfo.tagList" ref="pageTable" style="width:100%;" :pageable="false" :showIndex="true">
          <el-table-column prop="tagContent" label="标签信息"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="enabled" label="是否启用"></el-table-column>
          <el-table-column label="有效时间">
            <template slot-scope="scope">
              <p>{{scope.row.timeIn[0]}}~{{scope.row.timeIn[1]}}</p>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>

        <div class="detail-item box-shadow-unify">
            <div class="detail-item_title">细节展示</div>
            <div class="detail-form detail_img-form">
                <div class="form-item">
                  <span class="form-label">展示销量：</span>
                  <span class="form-value">{{spuInfo.mallShowSale=='1'?'是':'否'}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">位置1：</span>
                    <span class="form-value">{{position1.content}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">位置2：</span>
                    <span class="form-value">{{position2.content}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">位置3：</span>
                    <span class="form-value">{{position3.content}}</span>
                </div>
                <div class="form-item">
                    <span class="form-label">位置4：</span>
                    <span class="form-value">
                      <p class="left100" v-for="item in position4List">{{item.content}}</p>
                    </span>
                </div>
                <div class="form-item">
                    <span class="form-label">位置5：</span>
                    <span class="form-value"><p class="left100" v-for="item in position5List">{{item.content}}</p></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import userModule from "@/module/userModule";
    import IoProgressService from "API/IoProgressService.js";
    import pimGoodsSpuService from "API/pim/PimGoodsSpuService";
    import VueUeditorWrap from 'vue-ueditor-wrap';
    import examplePopup from "COMPS/widgets/examplePopup";
    import draggableUpload from "COMPS/base/draggableUpload";
    import tools from "COMJS/tools.js";

    export default {
        name: "spuEdit",
        data() {
            return {
                spuInfo: {
                    spuId: "",
                    shopId: "",
                    spuName: "",
                    spuPicUrl: "",
                    spuPrice: 0.00,
                    spuMarkingPrice: 0.00,
                    spuKeyword: "",
                    spuSharePicUrl: "",
                    shareMomentsPicUrl:"",
                    spuShareDescribe:"",
                    spuNum: 0,
                    spuSaleCount: 0,
                    mallShowSale:'',
                    isEnable: "",
                    createTime: "",
                    updateTime: "",
                    state: "",
                    photoAlbumList: [],
                    relaList: [],
                    tagList: [],
                    exhibitionList: [],
                },
                position1: {},
                position2: {},
                position3: {},
                position4List: [],
                position5List: [],
                rotationImgs: [], // 轮播
                detailImgs: []
            };
        },
        mounted() {
            this.getGoodsSpuDetail();
        },
        methods: {
            getGoodsSpuDetail() {
                var that = this;
                pimGoodsSpuService.getGoodsSpuDetail(this.$route.params.id).then(data => {
                    that.spuInfo = data;
                    if (that.spuInfo.relaList) {
                        that.spuInfo.relaList = that.spuInfo.relaList.map(e => {
                            if(!e.pimGoods){
                                e.pimGoods = {};
                            }
                            return {
                                goodsName: e.pimGoods.cnName,
                                goodsCode: e.pimGoods.goodsCode,
                                skuSpec: e.skuSpec,
                                relaMode: e.relaMode=="direct" ? "直联商品" : "定制商品"
                            };
                        });
                    }
                    if (that.spuInfo.exhibitionList) {
                        let list1 = that.spuInfo.exhibitionList.filter(e => e.position === "position1");
                        if (list1.length > 0) {
                            that.position1 = list1[0];
                        }
                        let list2 = that.spuInfo.exhibitionList.filter(e => e.position === "position2");
                        if (list2.length > 0) {
                            that.position2 = list2[0];
                        }
                        let list3 = that.spuInfo.exhibitionList.filter(e => e.position === "position3");
                        if (list3.length > 0) {
                            that.position3 = list3[0];
                        }
                        let list4 = that.spuInfo.exhibitionList.filter(e => e.position === "position4");
                        if (list4.length > 0) {
                            that.position4List = list4;
                        }
                        let list5 = that.spuInfo.exhibitionList.filter(e => e.position === "position5");
                        if (list5.length > 0) {
                            that.position5List = list5;
                        }
                        let list6 = that.spuInfo.exhibitionList.filter(e => e.position === "position6");
                        if (list6.length > 0) {
                            that.position6 = list6[0];
                        }
                        that.rotationImgs = that.spuInfo.photoAlbumList
                            .filter(e => e.photoType === "sow").map(e => {
                                return e.photoUrl;
                            });
                        that.detailImgs = that.spuInfo.photoAlbumList
                            .filter(e => e.photoType === "detail").map(e => {
                                return e.photoUrl;
                            });
                    }
                    if(that.spuInfo.tagList){
                        for(var ele of that.spuInfo.tagList){
                            ele.enabled = ele.enabled == '1' ? '启用' : '禁用';
                            ele.timeIn = [ele.beginTime, ele.endTime]
                        }
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    video {
        width: 82px;
        height: 82px;
    }

    .product-detail {
        .detail-item {
            margin-bottom: 40px;
            .detail-item_title {
                line-height: 60px;
                font-weight: bold;
                font-size: 18px;
                padding-left: 20px;
                background-color: #f6f9fc;
            }
            .detail-form {
                font-size: 14px;
                line-height: 20px;
                padding: 30px 20px 20px 0;
                .form-item {
                    margin-bottom: 14px;
                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                    .form-label {
                        display: inline-block;
                        text-align: right;
                        width: 100px;
                    }
                    .form-value {
                        color: #666;
                        .left100 {
                            margin-left: 100px;
                        }
                    }
                }
            }
            .detail_img-form {
                .form-item {
                    margin-bottom: 30px;
                    .form-label {
                        display: inline-block;
                        margin-right: 10px;
                        margin-top: 8px;
                    }
                    .image-list {
                        display: inline-block;
                        vertical-align: top;
                        .image-item {
                            display: inline-block;
                            margin-right: 10px;
                            img {
                                width: 82px;
                                height: 82px;
                                object-fit: fill;
                            }
                        }
                    }
                }
            }
        }
        table {
            width: 100%;
            .th-tr {
                font-size: 18px;
                background-color: #f6f9fc;
                font-weight: bold;
                th {
                    color: #8898aa;
                    &:first-of-type {
                        color: #333;
                    }
                }
            }
            tr {
                display: block;
                font-size: 16px;
                line-height: 60px;
                padding-left: 20px;
                text-align: left;
                border-bottom: 1px solid #e9ecef;
                &:last-of-type {
                    border-bottom: none;
                }
                td {
                    display: inline-block;
                }
            }
            .table-col-1 {
                width: 175px;
            }
            .table-col-2 {
                width: 400px;
            }
        }
    }
</style>