<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">导出编号：</span>
            <el-input
              size="mini"
              v-model="searchInfo.exportCode"
              placeholder="请输入导出编号"
            ></el-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">导出时间：</span>
            <el-date-picker
              v-model="filterTimes"
              @change="changePickerDate"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            <span
              class="filter-time-block"
              @click="filterDate('today')"
              :class="{ 'is-active': filterDateTimer == 'today' }"
              >今</span
            >
            <span
              class="filter-time-block"
              @click="filterDate('yesterday')"
              :class="{ 'is-active': filterDateTimer == 'yesterday' }"
              >昨</span
            >
            <span
              class="filter-time-block"
              @click="filterDate(7)"
              :class="{ 'is-active': filterDateTimer == 7 }"
              >近7天</span
            >
            <span
              class="filter-time-block"
              @click="filterDate(30)"
              :class="{ 'is-active': filterDateTimer == 30 }"
              >近30天</span
            >
          </div>
        </div>
        <div class="form-row">
          <span>
            <el-button size="mini" type="primary" @click="searchData"
              >搜索</el-button
            >
          </span>
        </div>
      </div>
    </div>
    <div class="invited-user-table box-shadow-unify">
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :isShadow="false"
      >
        <el-table-column prop="exportCode" label="编号"></el-table-column>
        <el-table-column prop="packageCount" label="包裹数量"></el-table-column>
        <el-table-column prop="exportTime" label="导出时间">
          <template slot-scope="scope">
            {{
              scope.row.exportTime
                ? format(new Date(scope.row.exportTime))
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{
              scope.row.createTime
                ? format(new Date(scope.row.createTime))
                : "无"
            }}
          </template>
        </el-table-column>
        <!--width=120-->
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a
                href="javascript: void(0);"
                title="下载"
                @click="downloadExcel(scope.row)"
              >
                <i class="icon-download"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
  </div>
</template>
<script>
import pageTableMixin from "@/mixin/pageTableMixin";
// import somOrderExportRecord from "API/som/orderExportRecordService";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService";
import SearchInfo from "../../../js/query/SearchConfig";
import boundQuery from "API/whm/boundQuery";

export default {
  data() {
    return {
      searchInfo: {
        exportCode: "",
        exportType: "",
        exportTimeBeginDate: "",
        exportTimeEndDate: "",
      },
      filterTimes: [],
      filterDateTimer: "", // 快捷选择时间类型
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo.exportType='outbound_export_log'
      boundQuery.queryRecordPages(this.queryParams).then((data) => {
        //console.log(data)
        this.querySuccess(data);
      });
    },
    downloadExcel(row) {
      //console.log(row)
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      let url = row.downloadUrl;
      console.log(url);
      let array = new Array();
      array = url.split(","); //以,分隔
      //console.log(array);
      let len = array.length;
      if (len == 2) {
        var module = array[len - 2];
        var fileName = array[len - 1];
      }
      IoProgressService.download(module, fileName, false);
      loading.close();
      this.loadTableData();
    },
    filterDate(timer) {
      this.filterDateTimer = timer;
      let _time = tools.filterDateMethod(timer);
      if (!this.filterTimes) {
        //鼠标点击x号的时候 filterTimes为null  先初始化为数组，然后吧值填进去
        this.filterTimes = [];
        this.filterTimes.push(_time.startTime, _time.endTime);
      } else {
        this.$set(this.filterTimes, 0, _time.startTime);
        this.$set(this.filterTimes, 1, _time.endTime);
      }
      this.searchInfo.exportTimeBeginDate = _time.startTime;
      this.searchInfo.exportTimeEndDate = _time.endTime;
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.exportTimeBeginDate = "";
        this.searchInfo.exportTimeEndDate = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.exportTimeBeginDate = tools.formatDate(
          new Date(e[0]),
          "yyyy-MM-dd"
        );
        this.searchInfo.exportTimeEndDate = tools.formatDate(
          new Date(e[1]),
          "yyyy-MM-dd"
        );
      }
    },
    format(date) {
      return tools.formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
};
</script>
<style scoped lang="scss">
.invited-user-table {
  .tabs-wrapper {
    padding: 10px 20px;
  }
}
.icon-download {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/download.png");
  background-size: cover;
}
</style>