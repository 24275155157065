<template>
  <div>
    <el-dialog :title="title" :visible="dialogVisible" top="10%" @close="closeDialog" :close-on-click-modal="false">
      <div>
        <ws-page-table
            :data="staffList"
            ref="staffTable"
            :pageable="false"
            selection="multi"
        >
          <el-table-column prop="fullName" label="姓名" min-width="90" show-overflow-tooltip></el-table-column>
          <el-table-column prop="userAccount" label="账号" min-width="90" show-overflow-tooltip></el-table-column>
        </ws-page-table>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeDialog">关 闭</el-button>
        <el-button type="primary" @click.native="submit">保 存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import Objects from "COMJS/Objects";
import auditFlowService from "API/ba/auditFlowService"
import bimRoleService from "API/ba/BimRoleService"

export default {
  name: "auditRolePersonEdit",
  components: {},
  props: {
    enumMap: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {

    return {
      title: '',
      dialogVisible: false,
      scene: '',
      personConfig: {},
      staffList: []
    };
  },
  methods: {
    /**关闭审核人员配置弹窗*/
    closeDialog() {
      this.dialogVisible = false;
    },
    /**显示审核人员配置弹窗*/
    display(personConfig, scene) {
      this.scene = scene;
      if ('ADD' === scene) {
        this.title = '新增审核人员配置';
        this.personConfig = Objects.deepCopy({}, personConfig);
        bimRoleService.findUserByRoleId(personConfig.auditRoleId).then(data => {
          this.staffList = data;
        }).catch(err => {
          this.staffList = [];
        });
      }
      this.dialogVisible = true;
    },

    /**提交审核人员配置*/
    submit() {
      let selectStaffList = this.$refs.staffTable.getCheckedData();
      //组装数据,保存人员列表.
      let nodePersonList = [];
      if (Objects.isEmpty(selectStaffList)) {
        this.$message("请选择审核人员!");
        return;
      } else {
        selectStaffList.forEach(staff => {
          let person = {};
          person.auditNodeRoleId = this.personConfig.auditNodeRoleId;
          person.auditRoleId = this.personConfig.auditRoleId;
          person.auditRoleName = this.personConfig.auditRoleName;
          person.auditorId = staff.userId;
          person.auditorName = staff.fullName;
          nodePersonList.push(person);
        });
        this.dialogVisible = true;
        auditFlowService.saveAuditNodePersonConfigurationList(nodePersonList).then(data => {
          this.dialogVisible = false;
          this.$emit('editCompleted');
        }).catch(err => {
          console.log(err);
          this.dialogVisible = false;
        });
      }


    }

  }
}
</script>

<style scoped>

</style>
