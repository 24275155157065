import axiosHttp from "../axiosHttp";

export default {
  // 库品分页查询
  queryStockPages(pageInfo, searchConfig) {
    let url = "/pimGoodsSku/queryPimGoodsForInventory?"
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  // 修改库存
  /**
   * @param {object} Sku
   * @param {String} Sku.goodsId
   * @param {String} Sku.skuNum
   * @param {String} Sku.changeNum
   * @param {String} Sku.warningInventory
   * @param {String} Sku.warningInventoryPer
   * @param {String} Sku.warningWay
   */
  changeStock(Sku) {
    return axiosHttp.POST('/pimGoodsSku/changeSkuNum', Sku)
  },
    saveStock(Sku) {
        return axiosHttp.POST('/pimGoodsSku/save', Sku)
    }
}