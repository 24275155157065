<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">名称：</span>
            <el-input size="mini" v-model="searchKey"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">订单编号：</span>
            <el-input size="mini" v-model="orderCode"></el-input>
          </div>
        </div>
        <div class="form-row">
          <el-button
              size="mini"
              type="primary"
              style="margin-right: 10px"
              @click="searchData"
          >搜索</el-button>
        </div>
      </div>
    </div>
    <div class="invited-user-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs v-model="activeName" type="card" @tab-click="changeTable">
          <!--
          <el-tab-pane :label="'未处理-已完成订单('+num1+')'" name="1"></el-tab-pane>
          <el-tab-pane :label="'未处理-未完成订单('+num2+')'" name="2"></el-tab-pane>
          -->
          <el-tab-pane label="未处理" name="1"></el-tab-pane>
          <el-tab-pane label="已处理" name="3"></el-tab-pane>
          <!--
          <el-tab-pane :label="'待撤销('+num3+')'" name="4"></el-tab-pane>
          -->
          <el-tab-pane label="待撤销" name="4"></el-tab-pane>
          <el-tab-pane label="已撤销" name="5"></el-tab-pane>
        </el-tabs>
        <el-button
            size="mini"
            @click="isShowPopop = true"
            v-if="this.activeName == '1'||this.activeName=='3'"
        >{{ activeName=='1'?'导出待处理发票':'导出已处理发票' }}
        </el-button>
      </div>

      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="true"
          :isShadow="false"
      >
        <el-table-column prop="invoiceType" label="发票类型"></el-table-column>
        <el-table-column prop="name" label="名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="completeState" label="状态"></el-table-column>
        <el-table-column prop="invoiceAmount" label="金额" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="email" label="邮箱" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
        <!--width=120-->
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" :title="(activeName==='1' || activeName==='4')?'编辑':'查看'"
                 @click="showDetail(scope.row)">
                <i v-if="(activeName==='1' || activeName==='4')" class="CCSFont icon-table_1"></i>
                <i v-else class="CCSFont icon-table_2"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
      <invoice-detail-popup ref="invoiceDetailPopup" @changeTab="changeTab(activeName)" :info="currentInvoiceDetail"
                            :activeName="activeName"></invoice-detail-popup>
    </div>
    <el-dialog title="导出发票信息" :visible.sync="isShowPopop" width="560px">
      <div style="color: #FBC14E;">提醒：</div>
      <div>
        <br/>
        <p>点击确定即导出发票信息excel；</p>
        <br/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPopop = false">取 消</el-button>
        <el-button type="primary" @click="exportInvoice">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import SomInvoiceService from "API/som/SomInvoiceService";
import tools from "COMJS/tools";
import invoiceDetailPopup from "./invoiceDetailPopup.vue"
import IoProgressService from "API/IoProgressService";


export default {
  data() {
    return {
      activeName: "1",
      searchKey: "",
      orderCode:"",
      currentInvoiceDetail: {},
      isShowPopop: false,
      num1: 0,
      num2: 0,
      num3: 0,
    };
  },
  mixins: [pageTableMixin],
  components: {
    invoiceDetailPopup
  },
  mounted() {
    /*SomInvoiceService.getInvoiceStatistics().then(data => {
        this.num1 = data.num1;
        this.num2 = data.num2;
        this.num3 = data.num3;
    });*/
  },
  methods: {
    changeTable() {
      this.clearPage();
      this.loadTableData();
    },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      this.searchInfo = {}
      if (this.activeName == '1') {
        //未处理
        this.searchInfo.completeState = '0';
      } else if (this.activeName == '3') {
        this.searchInfo.completeState = '1';
      } else if (this.activeName == '4') {
        this.searchInfo.revocationState = '0';
      } else if (this.activeName == '5') {
        this.searchInfo.revocationState = '1';
      }
      if (this.searchKey && this.searchKey.length > 0) {
        //console.log(this.searchKey)
        this.searchInfo.searchKey = this.searchKey;
      }
      if (this.orderCode && this.orderCode.length > 0) {
        //console.log(this.searchKey)
        this.searchInfo.orderCode = this.orderCode;
      }
      SomInvoiceService.queryInvoicePages(this.queryParams).then(data => {
        data.list = data.list.map(e => {
          e.createTime = tools.formatDate(
              new Date(e.createTime),
              "yyyy-MM-dd hh:mm:ss"
          );
          e.invoiceType =
              e.invoiceType == 'company_sp' ? '企业专票' : e.invoiceType == 'company' ? '企业普票' : '个人' ;
          e.completeState = this.formatState(e);
          return e;
        });
        this.querySuccess(data);
      });
    },
    changeTab(activeName) {
      //处理之后跳转
      // if (activeName === '4') {
      //   this.activeName = "5"
      // } else {
      //   this.activeName = "3"
      // }
      this.changeTable()
    },
    formatState(date) {
      var state = '';
      if (date.completeState == '1') {
        state = '已处理';
      } else {
        state = '未处理';
      }
      if (date.revocationState == '1') {
        state = state + '-已撤销';
      } else if (date.revocationState == '0') {
        state = state + '-待撤销';
      }
      return state;
    },
    showDetail(data) {
      this.currentInvoiceDetail = data;
      this.$refs.invoiceDetailPopup.dialogVisible = true;
    },
    exportInvoice() {
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      var that = this;
      let param = {};
      if (this.activeName == '1') {
        param = {
          completeState: "0",
          orderState: "1"
        }
      } else if (this.activeName == '3') {
        param = {
          completeState: "1"
        }
      } else {
        //不应该进来
        loading.close();
        return;
      }
      SomInvoiceService.exportSomInvoice(param).then(data => {
        let fileName = data.fileName;
        let module = data.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
        that.isShowPopop = false
        that.loadTableData();
      }).catch(() => {
        loading.close();
      });
    }
  }
};
</script>
<style scoped lang="scss">
.invited-user-table {
  .tabs-wrapper {
    padding: 10px 20px;
  }
}

.icon-download {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/download.png");
  background-size: cover;
}
</style>