<template>
  <div>
    <el-dialog title="偏远和停发地区" :visible.sync="transportFeeDialogVisible">
      <div v-if="!detailVisible" v-loading="dialogLoading">
        <el-table :data="transportFeeDialogData" style="width: 100%">
          <el-table-column prop="transportDescribe" label="描述" width="200"
                           :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="涉及商品">
            <template slot-scope="scope">
              <div class="nowrap-ellipsis">
                <el-tag v-for="(item,idx) in scope.row.linkGoodsList"
                        :key="idx" style="margin:5px;">{{ item.goodsCode }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="查看详情" width="100">
            <template slot-scope="scope">
              <div class="table-operation-column">
                <a href="javascript: void(0);" title="查看"
                   @click="showDetail(scope.row)">
                  <i class="el-icon-tickets">查看</i>
                </a>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer-right">
          <el-button @click.native="transportFeeDialogVisible=false">关 闭</el-button>
        </span>
      </div>
      <div v-else>
        <div class="dialog-header">
          <el-button type="text" @click="detailVisible=false" icon="el-icon-back">返回</el-button>
        </div>
        <div>
          <el-divider>涉及商品</el-divider>
          <el-tag v-for="(item,idx) in transportFeeDetailData.linkGoodsList"
                  :key="idx" style="margin:5px;">{{ item.goodsCode }}
          </el-tag>
          <el-divider v-if="outRangeList.length>0">停发地区</el-divider>
          <table style="margin-top: 20px;" v-if="outRangeList.length>0">
            <tr style="font-size: 14px;">
              <td style="width: 150px;">原因</td>
              <td>地区</td>
            </tr>
            <tr v-for="(item,idx) in outRangeList" :key="idx">
              <td>
                <div>
                  {{ item.tip }}
                </div>
              </td>
              <td>
                <div style="margin-top:10px;display:flex;flex-wrap:wrap;">
                  <el-tag v-for="(tag,idx) in item.tags" :key="tag" type="info" style="margin:5px;">{{ tag }}
                  </el-tag>
                </div>
              </td>
            </tr>
          </table>
          <el-divider>偏远地区及运费</el-divider>
          <el-table :data="transportCostList" style="width: 100%">
            <el-table-column label="偏远地区" minWidth="200px;">
              <template slot-scope="scope">
                <div style="display:flex;flex-wrap:wrap;">
                  <el-tag v-for="(tag,ai) in scope.row.transportAreaList" :key="tag"
                          style="margin:5px;">{{ tag }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="计算方式" width="100">
              <template slot-scope="scope">
                {{ scope.row.costType == "piece" ? '计件' : '计重' }}
              </template>
            </el-table-column>
            <el-table-column label="首" width="100">
              <template slot-scope="scope">
                <div>
                  首{{ scope.row.costType == "piece" ? (scope.row.firstPiece + "件") : (scope.row.firstWeight + "kg") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="首金额" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.firstPrice }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column label="续" width="100">
              <template slot-scope="scope">
                <div>
                  续{{
                    scope.row.costType == "piece" ? (scope.row.continuePiece + "件") : (scope.row.continueWeight + "kg")
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="续金额" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.continuePrice }}元
                </div>
              </template>
            </el-table-column>
            <!--el-table-column prop="tip" width="150">
              <template slot="header">
                <span>原因<span style="color:red"> * </span></span>
              </template>
            </el-table-column-->
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import orderImportBatchService from "API/som/orderImportBatchService";

export default {
  name: "transportFeeDialog",
  data() {
    return {
      transportFeeDialogVisible: false,
      transportFeeDialogData: null,
      dialogLoading: false,
      //
      detailVisible: false,
      transportFeeDetailData: {},
      outRangeList: [],
      transportCostList: []
    }
  },
  mounted() {
    /*this.$on("openDialog", () => {
      this.transportFeeDialogVisible = true;
      this.loadTransportFeeDialogData()
    });*/
  },
  methods: {
    show() {
      this.transportFeeDialogVisible = true;
      this.detailVisible = false;
      this.loadTransportFeeDialogData()
    },
    loadTransportFeeDialogData() {
      if (this.transportFeeDialogData != null) {
        //不是第一次了
        return;
      }
      this.dialogLoading = true;
      this.transportFeeDialogVisible = true;
      orderImportBatchService.queryTransportFeeInfo().then(res => {
        res.forEach((item) => {
          if (item.pimTransportCostList) {
            item.pimTransportCostList.forEach((it) => {
              if (it.transportArea) {
                it.transportAreaList = it.transportArea.split(";");
                let len = it.transportAreaList.length;
                //""要去掉
                it.transportAreaList = it.transportAreaList.filter(e => e != '')
              }
            })
          }
        });
        this.transportFeeDialogData = res;
        this.dialogLoading = false;
      }).catch(() => {
        this.dialogLoading = false;
      });
    },
    showDetail(row) {
      //console.log(row)
      this.transportFeeDetailData = row
      //去掉默认地区
      let allCostList = row.pimTransportCostList.filter(e => e.transportArea.indexOf('default') < 0)
      //其中停发地区
      this.outRangeList = []
      let outRangeItems = allCostList.filter(a => a.costType == 'out_range')
      let tempMap = new Map()
      for (let item of outRangeItems) {
        let oneList = []
        if (tempMap.has(item.tip)) {
          oneList = tempMap.get(item.tip)
        }
        for (let one of item.transportAreaList) {
          oneList.push(one)
        }
        tempMap.set(item.tip, oneList)
      }
      //console.log(tempMap)
      let it = tempMap.entries()
      //console.log(it)
      for (let i = 0; i < tempMap.size; i++) {
        let one = it.next()
        let tip = one.value[0]
        let arr = one.value[1]
        arr = arr.sort(function (str1, str2) {
          return str1.localeCompare(str2, 'zh');
        });
        this.outRangeList.push({'tip': tip, 'tags': arr})
      }
      //其中可发的地区
      this.transportCostList = allCostList.filter(a => a.costType != 'out_range')

      this.detailVisible = true;
    }
  }
}
</script>

<style scoped>
.nowrap-ellipsis {
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>