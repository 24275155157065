<template>
  <div>
    <div class="mt15">
      <el-tabs v-model="selectedWarehouseId" type="card" @tab-click="loadTableData">
        <el-tab-pane :label="item.warehouseName" :name="item.warehouseId"
                     v-for="item in warehouseList" :key="item.warehouseId"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="mt15">
      <el-table :data="stockList"
                ref="stockTable"
                :expand-row-keys="expand_row_keys"
                @row-click="stockTableClick"
                style="width: 100%;margin-bottom: 20px;"
                row-key="keyId"
                :tree-props="{children: 'childList'}">
        <el-table-column prop="materialName" label="物料名称"></el-table-column>
        <el-table-column prop="materialSupplierName" label="供应商"></el-table-column>
        <el-table-column prop="batchCode" label="批次号"></el-table-column>
        <el-table-column prop="sumNum" label="库存"></el-table-column>
        <el-table-column label="记录" width="120" fixed="right">
          <template slot-scope="scope">
            <div class="operate-column">
              <a @click="showStep3ListDialog(scope.row)">
                <i class="el-icon-tickets"/>记录
              </a>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope" v-if="scope.row.batchCode!=null">
            <div class="operate-column">
              <a @click="createStep3Dialog(scope.row)">
                <i class="el-icon-tickets"/>调用
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--step3CreateDialog-->
    <step3-create-dialog @reloadAfterSave="loadTableData()" ref="step3CreateDialog"></step3-create-dialog>
    <!--step3ListDialog-->
    <step3-list-dialog ref="step3ListDialog"></step3-list-dialog>
  </div>
</template>

<script>
import materialProcessService from "API/pur/PurMaterialProcessService"
import warehouseService from "API/pur/PurWarehouseService"
import step3CreateDialog from "./materialProcessDialog/Step3CreateDialog"
import step3ListDialog from "./materialProcessDialog/Step3ListDialog"
import tools from "COMJS/tools";

export default {
  name: "MaterialStockManager",
  components: {step3CreateDialog,step3ListDialog},
  data() {
    return {
      stockList:[],
      expand_row_keys:[],
      //
      selectedWarehouseId: '',
      warehouseList: [],
    };
  },
  mounted() {
    warehouseService.queryList({}).then(res => {
      this.warehouseList = res
      if (res && res.length > 0) {
        this.selectedWarehouseId = res[0].warehouseId
        this.loadTableData()
      }
    })
  },
  methods: {
    loadTableData() {
      if(this.selectedWarehouseId==null
          || this.selectedWarehouseId==''
          || this.selectedWarehouseId==0){
        return
      }
      let param = {
        'warehouseId': this.selectedWarehouseId
      }
      materialProcessService.ListMaterialStockOnGroup(param).then(res => {
        //console.log(res)
        this.stockList = res;
        this.toggleRowExpansion()
      })
    },
    async toggleRowExpansion(){
      //等0.5s然后展开第一行
      //await tools.delay(500)
      if(this.stockList.length>0){
        let key = this.stockList[0].keyId
        this.expand_row_keys = [key]
      }
    },
    showStep3ListDialog(row){
      this.$refs.step3ListDialog.show(row)
    },
    createStep3Dialog(row) {
      this.$refs.step3CreateDialog.show(row)
    },
    stockTableClick(row, column, event){
      if(row.childList && row.childList.length>0){
        // console.log('toggle')
        this.$refs.stockTable.toggleRowExpansion(row)
      }
    }
  }
}
</script>

<style scoped>
.operate-column{
  display: flex;
}
.operate-column > a {
  margin-right: 20px;
  cursor: pointer;
  color: #00a0e9;
}
</style>