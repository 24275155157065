<template>
  <!--批量下单-->
  <div class="batch-list">
    <div class="table-tips">
      <div class="tips-title">模版预览与下载</div>
      <div class="top-temp">
        <div class="bar">
          <div class="bar-0"></div>
          <div class="bar-1">
            <el-switch
                style="margin-left: 10px;"
                v-model="tempTableFlag_2"
                active-text="四列地址"
                inactive-text="一列地址">
            </el-switch>
          </div>
          <div class="bar-2">
            <el-switch
                style="margin-left: 10px;"
                v-model="tempTableFlag_3"
                active-text="两列商品"
                inactive-text="一列商品">
            </el-switch>
          </div>
        </div>
        <div class="table-2">
          <div :class="{tt:1, tt1: item.w==1, tt2: item.w==2, tt3: item.w==3, tt4: item.w==4}"
               v-for="(item,index) of tempTableList">
            {{ item.label }}
          </div>
          <div style="margin-left: 50px;text-align: center;">
            <a href="javascript: void(0);"
               style="cursor: pointer;color:#1ea0ff;text-decoration:underline;"
               @click="exportOrderTemplate">下载模版</a>
            <br/>
            <p style="font-size: 12px;color: #99a9bf;margin-top: 5px;">在售商品随时变动，请及时更新下载</p>
          </div>
        </div>
      </div>
      <div class="mt15">
        <span class="mr5">如您对导入模版或合并订单规则有疑问，可查看</span>
        <el-button type="text" @click="showInstructions=!showInstructions" style="font-size: 14px;">
          导入规则说明
          <i class="el-icon-arrow-down" v-if="!showInstructions"></i>
          <i class="el-icon-arrow-up" v-else></i>
        </el-button>
        <div class="tips-content" v-if="showInstructions">
          <div>
            <p>本功能支持多格式导入下单，但为更精确识别订单信息，请您仔细阅读导入规则后完成导入。</p>
            <p>
              <span>1.</span>
              <span>
                表头标题和顺序无强制要求，但需包含以下信息
                <br/>必填字段：姓名、手机号、地址、商品信息。
                <br/>选填字段：备注、平台单号等。
              </span>
            </p>
            <p>
              <span>2.</span>
              <span>
                地址填写支持两种格式
                <br/>四列格式：省、市、区、详细地址；
                <br/>一列格式：完整地址信息。
              </span>
            </p>
          </div>
          <div>
            <p>
              <span>3.</span>
              <span>
              商品信息填写支持两种格式
              <br/>两列格式：商品编号、商品数量（如有多个商品，可分两行，会自动合并）；
              <br/>一列格式：商品编号*数量（可用“+”连接不同商品，如C3*1+C11*2）。
            </span>
            </p>
            <p>
              <span>4.</span>
              <span>
              订单合并规则
              <br/>当两行或两行以上记录的姓名、电话、地址、平台单号完全一致时，会做合并订单处理。
            </span>
            </p>
          </div>
        </div>
      </div>
      <div class="mt15">
        <span class="mr5">如您有协议预留库存，可查看</span>
        <el-button type="text" @click="reserveInfo" style="font-size: 14px;">
          商品预留信息
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
      <div class="mt15">
        <span class="mr5">根据下单商品的不同，偏远地区可能会收取运费，部分地区可能不发货，可查看</span>
        <el-button type="text" @click="transportFeeShow" style="font-size: 14px;">
          偏远地区和停发地区
          <i class="el-icon-chat-dot-square"></i>
        </el-button>
      </div>

    </div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-upload
              style="display: inline-block;margin-right: 28px; border: none !important;"
              ref="upload"
              :headers="requestHeaders"
              :multiple="false"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl()"
              :limit="1"
              :disabled="importDisable"
              :on-success="onSuccess"
              :on-remove="onRemove">
            <el-button type="primary" :disabled="importDisable" @checkAuthority="isAllow">1.批量下单</el-button>
          </el-upload>
          <el-button type="primary" style="margin-right: 28px;" @click="batchPay">2.批量支付</el-button>
          <el-button type="primary" style="margin-right: 28px;" @click="batchExport">3.批量导出</el-button>
          <el-button type="danger" style="float: right;" @click="batchDelete">删除批次和批次内订单</el-button>
        </div>
      </div>
      <div class="filter_right">
        <!--<el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{operationType:'order_import'}})" v-show="ishow">操作日志</el-button>-->
      </div>
    </div>
    <div class="batch-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs v-model="tabsActiveName" type="card">
          <el-tab-pane label="表格导入" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @pageChange="loadTableData"
          :showIndex="false"
          :isShadow="false"
          :selection="'multi'"
      >
        <el-table-column prop="batch" label="批次" minWidth="140"></el-table-column>
        <el-table-column prop="importTime" label="导入时间" width="180"></el-table-column>
        <el-table-column prop="progress" label="进度">
          <template slot-scope="scope">
            {{ scope.row.progress }}
            <i class="el-icon-loading" v-if="scope.row.progress!='100%'"/>
          </template>
        </el-table-column>
        <el-table-column prop="sumNum" label="总条数"></el-table-column>
        <el-table-column prop="successNum" label="成功条数"></el-table-column>
        <el-table-column prop="failNum" label="失败条数"></el-table-column>
        <el-table-column prop="insertOrderNum" label="合单后订单数"></el-table-column>
        <el-table-column prop="batchOrderPayState" label="支付状态"></el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope">
            <div style="line-height: 20px;white-space:pre-wrap;">
              <p class="a-tag" @click="showBatchGoodsDetail(scope.row.id)">{{ scope.row.total }}</p>
              <p v-if="scope.row.refundAmount>0">已退款 {{ scope.row.refundAmount }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
                class="operation"
                v-if="Number(scope.row.failNum) > 0"
                @click="downloadFail(scope.row)"
            >查看失败原因</span>
            <span class="operation" v-if="isTest" @click="downloadOriginalFile(scope.row)">下载原文件</span>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>
    <el-dialog
        title="支付提醒"
        :visible.sync="noticeDialogVisible"
        width="500px"
        top="20vh"
        @close="noticeDialogVisible=false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <div style="font-size: 20px;margin-top: 20px;line-height:28px;">
        您有 <strong style="font-size: 28px;color: darkred;">{{ noticeInfo.count }}</strong> 批未支付订单，待支付金额 <strong
          style="font-size: 28px;color: darkred;">{{ noticeInfo.amount }}</strong> 元，请于24小时内支付，以完成下单。
      </div>
      <div style="font-size: 14px;margin-top: 40px;line-height:20px;color: #e6503c;">
        <p>提示：</p>
        <p>1、选中一个或多个批次，点按【批量支付】按钮，进行支付。</p>
        <p>2、周一至周六，每日 13:40 截单（以下单并支付成功时间为准），截单后 48 小时内发货。</p>
      </div>
      <div style="text-align: right;margin-top: 10px;">
        <el-button @click="noticeDialogVisible=false" type="primary" style="margin-right: 10px;">确认</el-button>
      </div>
    </el-dialog>

    <!-- 确认弹窗 -->
    <el-dialog
        title="确认"
        :visible.sync="checkDialogVisible"
        width="1000px"
        top="5vh"
        @close="closeCheckDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <div class="comfirm-dialog">
        <ws-page-table
            :data="xlsxData"
            :border="true"
            ref="checkPageTable"
            :isShowHeader="false"
            :cellStyle="cellStyleFunc"
        >
          <el-table-column
              :show-overflow-tooltip="true"
              :prop="String(index)"
              v-for="(colIndex, index) in xlsxThListLength"
              :key="index"
              min-width="110px"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[index] || '' }}</span>
              <i
                  class="el-icon-top"
                  style="color:red;font-weight: bold;"
                  v-if="(xlsxData.length-1 == scope.$index) && scope.row[index]"
              ></i>
            </template>
          </el-table-column>
        </ws-page-table>
        <div class="check-tips">{{ importOrderResult.resultMsg }}</div>
      </div>
      <div class="remark">
        <span>备注：</span>
        <el-input v-model="checkRemark" type="small" placeholder="用于标识本次导入"></el-input>
      </div>
      <div style="text-align: right;margin-top: 10px;">
        <el-button @click="closeCheckDialog" type="primary" style="margin-right: 10px;">取消导入</el-button>
        <el-button
            @click="importOrderConfrim"
            type="primary"
            :disabled="importOrderResult.result != 'success'"
        >确定导入
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="批次金额详情" :visible.sync="batchGoodsDetailVisible">
      <el-table :data="batchGoodsDetailList" style="width: 100%">
        <el-table-column prop="goodsCode" label="编码" width="100"></el-table-column>
        <el-table-column prop="goodsName" label="名称" width="200" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="price" label="单价(元)"></el-table-column>
        <el-table-column prop="count" label="数量"></el-table-column>
        <el-table-column prop="amount" label="总额(元)"></el-table-column>
        <el-table-column prop="priceSystem" label="说明" width="100" :show-overflow-tooltip="true"></el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click.native="batchGoodsDetailVisible=false">关 闭</el-button>
      </span>
    </el-dialog>

    <order-pay-popup @reloadAfterPay="loadTableData()" @showRechargeDeposit="showRechargeDeposit()"
                     ref="orderPayPopup"></order-pay-popup>
    <order-pay-popup-for-adm @reloadAfterPay="loadTableData()" ref="orderPayPopupForAdm"></order-pay-popup-for-adm>
    <recharge-pop @reloadAfterRecharge="loadTableData()" ref="rechargeDeposit"></recharge-pop>
    <transport-fee-dialog ref="transportFeeDialog"></transport-fee-dialog>

    <temporary-dialog/>
  </div>
</template>

<script>
import orderImportBatchService from "API/som/orderImportBatchService";
import IoProgressService from "API/IoProgressService";
import orderOperate from "API/som/orderOperate";
import orderPayPopup from "./orderPayPopup.vue";
import orderPayPopupForAdm from "./orderPayPopupForAdm.vue";
import transportFeeDialog from "PAGES/pim/fareModel/transportFeeDialog";
import temporaryDialog from "./TemporaryDialog";
import rechargePop from "PAGES/dea/rechargePop";
import pageTableMixin from "@/mixin/pageTableMixin";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";
import chargeFormat from "COMJS/chargeFormat.js";

export default {
  name: "orderList",
  data() {
    return {
      searchInfo: {
        beginDate: "",
        endDate: ""
      },
      filterTimes: [],
      tabsActiveName: "first",
      currentInfo: {},
      fileTemplateName: "",
      requestHeaders: {
        Authorization: userModule.token
      },
      // 校验部分
      importOrderResult: {}, // 校验接口返回结果
      xlsxThListLength: [],
      xlsxData: [],
      checkDialogVisible: false,
      checkRemark: "",
      noticeDialogVisible: false,
      noticeInfo: {count: 0, amount: 0},
      userRole: "",
      importDisable: true,
      dataSync: false,
      //
      batchGoodsDetailVisible: false,
      batchGoodsDetailList: [],
      //
      temporaryDialogVisible: true,
      //
      tempTableFlag_2: true,
      tempTableFlag_3: true,
      showInstructions: false,
    };
  },
  computed: {
    isTest: function () {
      if (this.$route.params.test === 'test') {
        return true;
      }
      return false;
    },
    tempTableList() {
      let list = []
      list.push({label: '姓名', w: 1}, {label: '手机', w: 1})
      if (this.tempTableFlag_2) {
        list.push({label: '省', w: 1}, {label: '市', w: 1}, {label: '区/县', w: 1}, {label: '详细地址', w: 1})
      } else {
        list.push({label: '完整地址', w: 4})
      }
      if (this.tempTableFlag_3) {
        list.push({label: '商品编码', w: 1}, {label: '商品数量', w: 1})
      } else {
        list.push({label: '商品编码 * 数量', w: 2})
      }
      list.push({label: '...（其他非必填字段）', w: 2})
      return list;
    }
  },
  components: {
    orderPayPopup,
    orderPayPopupForAdm,
    rechargePop,
    transportFeeDialog,
    temporaryDialog
  },
  mounted() {
    this.isAllow();
    let that = this;
    //20s更新一次
    setInterval(() => {
      if (that.dataSync) {
        that.loadTableData()
      }
    }, 20000)
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
      var that = this;
      this.$refs.pageTable.tableLoading = true;
      orderImportBatchService.queryOrderPages(this.queryParams).then(data => {
        data.list = data.list.map(e => {
          return {
            id: e.orderImportBatchId,
            batch: e.batchCode,
            importTime: tools.formatDate(
                new Date(e.createTime),
                "yyyy-MM-dd hh:mm:ss"
            ),
            progress: e.completePer + "%",
            //progress: "50%",
            sumNum: e.totalCount,
            successNum: e.successCount,
            failNum: e.failCount,
            insertOrderNum: e.insertOrderCount,
            batchOrderPayState: that.batchOrderPayStateTranslate(e),
            waitPayAmount: e.waitPayAmount,
            payAmount: e.payAmount,
            refundAmount: e.refundAmount,
            //total: new BigNumber(e.waitPayAmount).add(e.payAmount).add(e.refundAmount).toNumber(),//add(e.refundAmount)
            total: that.batchTotalTranslate(e),
            status: "未知字段",
            remarks: e.remark,
            fileUrl: e.fileUrl
          };
        });
        let progresslen = data.list.filter(e => e.progress != "100%").length
        if (progresslen > 0) {
          this.dataSync = true;
        } else {
          this.dataSync = false;
        }
        let waitPayList = data.list.filter(e => e.progress == "100%" && e.batchOrderPayState == "未支付" && e.insertOrderNum > 0)
        if (waitPayList.length > 0) {
          if (waitPayList.length != this.noticeInfo.count) {
            this.noticeInfo.count = waitPayList.length;
            let amount = 0;
            for (const waitPayListElement of waitPayList) {
              amount += waitPayListElement.waitPayAmount
            }
            this.noticeInfo.amount = chargeFormat.thousandsOf(amount)
            if (amount > 0) {
              this.noticeDialogVisible = true;
            }
          }
        } else {
          this.noticeDialogVisible = false;
          this.noticeInfo.count = 0;
        }
        this.querySuccess(data);
      });
    },
    batchOrderPayStateTranslate(e) {
      if (e.insertOrderCount > 0) {
        let state = e.batchOrderPayState
        if (state === '0') {
          return '未支付';
        } else if (state === '1') {
          return '已支付';
        } else if (state === '5') {
          return '审核中';
        } else if (state === '6') {
          return '审核拒绝';
        } else if (state === '8') {
          return '部分支付';
        } else if (state === '9') {
          return '支付关闭';
        }
      }
      return '-';
    },
    batchTotalTranslate(e) {
      if (e.insertOrderCount > 0) {
        return e.totalAmount;
      }
      return "";
    },
    showAllData() {
      this.searchInfo.beginDate = "";
      this.searchInfo.endDate = "";
      this.loadTableData();
    },
    isAllow() {
      const loginUser = JSON.parse(userModule.PPUser);
      this.userRole = loginUser.role;
      //请求判断是否存在权限
      orderImportBatchService.isHavePermission().then(data => {
        if (data) {
          this.importDisable = false;
        } else {
          this.importDisable = true;
          if (this.importDisable) {
            this.$alert(
                "当前时间段没有批量导入权限，功能已关闭，如有疑问请联系相关人员咨询。",
                '权限不足', {
                  confirmButtonText: '确定',
                  callback: action => {
                  }
                });
            return;
          }
        }
      })
    },
    isAllow_bak() {
      const loginUser = JSON.parse(userModule.PPUser);
      if (loginUser && loginUser.authState) {
        if (loginUser.authState != 'normal') {
          this.importDisable = true;
          if (this.importDisable) {
            this.$alert(
                "账号当前状态异常，功能已关闭，如有疑问请联系相关人员咨询。",
                '状态异常', {
                  confirmButtonText: '确定',
                  callback: action => {
                  }
                });
            return;
          }
        }
      }
      if (loginUser && loginUser.role) {
        //console.log(loginUser.role)
        if (loginUser.role.indexOf("shop_admin") > -1) {
          // 管理员
          this.userRole = loginUser.role;
          this.importDisable = false;
        } else if (loginUser.role.indexOf("agent_") > -1) {
          // 经销商
          this.userRole = loginUser.role;
          this.importDisable = false;
        } else if (loginUser.role.indexOf("distributor_") > -1) {
          //会员用户需要访问接口判断是否存在权限
          orderImportBatchService.isHavePermission().then(data => {
            if (data) {
              this.importDisable = false;
            } else {
              this.importDisable = true;
              if (this.importDisable) {
                this.$alert(
                    "当前时间段没有批量导入权限，功能已关闭，如有疑问请联系相关人员咨询。",
                    '权限不足', {
                      confirmButtonText: '确定',
                      callback: action => {
                      }
                    });
                return;
              }
            }
          })
        }
      }
      //请求判断是否存在权限

    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.beginDate = "";
        this.searchInfo.endDate = "";
      } else {
        this.searchInfo.beginDate = new Date(e[0]);
        /*tools.formatDate(
                  new Date(e[0]),
                  "yyyy-MM-dd hh:ss:mm"
                );*/
        this.searchInfo.endDate = new Date(e[1]);
        /*tools.formatDate(
                  new Date(e[1]),
                  "yyyy-MM-dd hh:ss:mm"
                );*/
      }
      this.loadTableData();
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("somOrderImport");
      return url;
    },
    /*上传文件之前的钩子*/
    beforeUpload(file) {
      let isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error("暂只支持上传大小为1024k以下表格！");
        return false;
      }

      if (file.type && file.type.length > 0) {
        //包含type
        let isXlsm =
            file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
            file.type === "application/vnd.ms-excel.sheet.macroenabled.12"; //xlsm
        let isXlsx =
            file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; //xlsx
        let isXls = file.type === "application/vnd.ms-excel"; //xls

        if (!(isXlsm || isXlsx || isXls)) {
          this.$message.warning(
              "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
          );
        }
        return isXlsm || isXlsx || isXls;
      } else {
        if (file.name && file.name.length > 5) {
          //文件名存在
          if (
              file.name.substring(file.name.length - 5, file.name.length) ===
              ".xlsx" ||
              file.name.substring(file.name.length - 5, file.name.length) ===
              ".xlsm" ||
              file.name.substring(file.name.length - 4, file.name.length) ===
              ".xls"
          ) {
            return true;
          } else {
            this.$message.warning(
                "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
            );
            return false;
          }
        } else if (
            file.name &&
            file.name.length > 4 &&
            file.name.substring(file.name.length - 4, file.name.length) === ".xls"
        ) {
          return true;
        } else {
          this.$message.warning(
              "上传附件只支持【xlsm、xlsx、xls】格式，请先下载模板填写订单数据后重新上传!"
          );
          return false;
        }
      }
    },
    onSuccess(response, file, fileList) {
      let that = this;
      let splitList = file["response"].split("/");
      this.fileTemplateName = file["response"].split("/")[splitList.length - 1];

      orderImportBatchService
          .checkOrderExcel(this.fileTemplateName)
          .then(data => {
            let colSatus = [
              {
                value: "columnRemark",
                label: "备注"
              },
              {
                value: "columnBatchSequence",
                label: "批次顺序"
              },
              {
                value: "columnThirdOrderNo",
                label: "平台单号"
              },
              {
                value: "columnName",
                label: "姓名"
              },
              {
                value: "columnTel",
                label: "手机号"
              },
              {
                value: "columnFullAddress",
                label: "完整地址"
              },
              {
                value: "columnProvince",
                label: "省"
              },
              {
                value: "columnCity",
                label: "市"
              },
              {
                value: "columnCounty",
                label: "区"
              },
              {
                value: "columnDetailAddress",
                label: "详细地址"
              },
              {
                value: "columnMergeGoods",
                label: "商品信息"
              },
              {
                value: "columnGoodsName",
                label: "商品名称"
              },
              {
                value: "columnGoodsNum",
                label: "商品数量"
              }
            ];
            let hasCols = [];
            this.xlsxData = [];
            this.xlsxThListLength = 0;
            this.importOrderResult = data;
            this.checkDialogVisible = true;
            let _excelList = data.excelContent;
            this.xlsxThListLength = _excelList[0].length;
            // 获取已经识别的列
            colSatus.forEach(col => {
              if (data[col.value] != 999 && data[col.value] != null) {
                hasCols.push({
                  wsName: col.label,
                  colIndex: data[col.value]
                });
              }
            });
            // 筛选掉excel空行
            for (let i in _excelList) {
              if (_excelList[i].filter(e => e != "").length == 0) {
                _excelList.splice(i, 1);
              }
            }
            that.xlsxData = _excelList;
            let lastRow = [];
            // 校验信息放入最后一行
            hasCols.forEach(e => {
              lastRow[e.colIndex] = e.wsName;
            });
            that.xlsxData.push(lastRow);
          })
          .catch(() => {
            this.clearUploadForm();
          });
    },
    onRemove() {
    },
    // 清除upload
    clearUploadForm() {
      this.$refs.upload.clearFiles();
      this.fileTemplateName = null;
    },
    // 修改备注
    changeRemark(row) {
      this.$prompt("请输入备注", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
          .then(({value}) => {
            orderOperate.orderChangeRemark({
              orderId: row.orderId,
              remark: value
            });
          })
          .catch(() => {
          });
    },
    // 校验部分
    importOrderConfrim() {
      this.$confirm("请确认识别的信息类型与您预期一致", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            this.importOrder();
          })
          .catch(() => {
          });
    },
    // 关闭校验弹窗
    closeCheckDialog() {
      this.checkDialogVisible = false;
      this.checkRemark = "";
      this.clearUploadForm();
    },
    // table td标注
    cellStyleFunc({row, column, rowIndex, columnIndex}) {
      let lastRowTd = this.xlsxData[this.xlsxData.length - 1][columnIndex];
      if (lastRowTd && rowIndex < this.xlsxData.length - 1) {
        return {
          "background-color": "#f56c6c"
        };
      }
    },
    // 订单导入
    importOrder() {
      if (!this.fileTemplateName || this.fileTemplateName.length === 0) {
        this.$message.warning(`需上传表格后进行导入操作！`);
        return;
      }
      this.importOrderResult.remark = this.checkRemark;
      const loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      orderImportBatchService
          .importOrderByBatch(this.importOrderResult)
          .then(data => {
            loading.close();
            this.closeCheckDialog();
            this.loadTableData();
          })
          .catch(function (data) {
            loading.close();
            this.closeCheckDialog();
            this.loadTableData();
          });
    },
    downloadOriginalFile(row) {
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      let url = row.fileUrl
      let fileName = url.replace("somOrderImport/", "")
      // xlsx或早期的xls
      let suffix = fileName.split(".")[1]
      let downloadName = row.batch + '_原文件.' + suffix
      //alert(downloadName)
      IoProgressService.download("somOrderImport", fileName, false, downloadName);
      loading.close();
    },
    downloadFail(row) {
      let id = row.id
      let downloadName = row.batch + "_失败原因.xlsx"
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      orderImportBatchService
          .exportOrderImportError(id)
          .then(data => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false, downloadName);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
    },
    reserveInfo() {
      this.$router.push("/pim/reservePriceDetail");
    },
    transportFeeShow() {
      this.$refs.transportFeeDialog.show();
    },
    // 下载模板
    exportOrderTemplate() {
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      let param = {
        addressCol: this.tempTableFlag_2 ? 4 : 1,
        goodsCol: this.tempTableFlag_3 ? 2 : 1,
      }
      let downloadName = "WDOM下单模版.xlsx"
      orderOperate
          .exportOrderTemplate(param)
          .then(data => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false, downloadName);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
    },
    batchPay() {
      var that = this;
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      let list6 = rows.filter(a => a.progress != '100%')
      if (list6 && list6.length > 0) {
        that.$alert('存在未完全导入的记录！', '系统提示');
        return;
      }
      let batchCodeList = rows.map(e => {
        return e.batch;
      });
      if (this.userRole.indexOf("shop_admin") > -1) {
        // 管理员
        this.$refs.orderPayPopupForAdm.openPayPopup({'batchCodeList': batchCodeList});
      } else {
        /** if (this.userRole.indexOf("corporation") > -1) */
        //法人（公司）
        this.$refs.orderPayPopup.openPayPopup({'batchCodeList': batchCodeList});
      }
    },
    batchExport() {
      var that = this;
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      if (rows.length > 1) {
        that.$alert('请只选择一条记录执行导出操作！', '系统提示');
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      orderImportBatchService.exportBatch(rows[0].id, rows[0].batch)
          .then(data => {
            let fileName = data.fileName;
            let module = data.module;
            IoProgressService.download(module, fileName, false);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
    },
    batchDelete() {
      var that = this;
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        that.$alert('请选择记录！', '系统提示');
        return;
      }
      if (rows.length > 1) {
        that.$alert('请只选择一条记录执行删除操作！', '系统提示');
        return;
      }
      that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() =>
          that.$refs.pageTable.tableLoading = true
      ).then(() => {
        return orderImportBatchService.deleteBatch(rows[0].id)
      }).then(response => {
        that.loadTableData();
        that.$refs.pageTable.tableLoading = false;
      }).catch(error => {
        console.log(error);
        that.$refs.pageTable.tableLoading = false;
      })
    },
    showRechargeDeposit() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录！', '系统提示');
        return;
      }
      let batchCodeList = rows.map(e => {
        return e.batch;
      });
      let batchCodes = batchCodeList.join(",");
      this.$refs.rechargeDeposit.show({'model': 'rechargeAndPay', 'batchCodes': batchCodes});
    },
    showBatchGoodsDetail(id) {
      this.batchGoodsDetailVisible = true;
      orderImportBatchService.getBatchAmountDetail(id).then(data => {
        this.batchGoodsDetailList = data;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.batch-list {
  .batch-table {
    .tabs-wrapper {
      padding: 10px 20px;

      .el-tabs__item {
        background: rgba(247, 248, 250, 1);
        border-bottom: 1px solid #dcdee0;

        &.is-active {
          background-color: #fff;
          border-bottom: none;
        }
      }
    }

    background-color: #fff;
  }
}

.el-button {
  border: none !important;
}

.el-upload {
  border: none !important;
}

.comfirm-dialog {
  .remark {
    margin: 10px 0;
  }

  .remark .el-input {
    width: 100%;
  }
}

.tips-content {
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;

  > div {
    flex: 1;
  }

  p {
    display: flex;
    margin: 0;
    line-height: 24px;
  }

  .point {
    font-weight: bold;
    color: red;
  }
}

.comfirm-dialog /deep/ .el-table__header-wrapper {
  display: none !important;
}

.check-tips {
  color: red;
  margin: 15px 0;
}

.a-tag {
  color: #00a2d4;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}

.top-temp {
  .bar {
    height: 30px;
    line-height: 30px;
    display: flex;

    .bar-0 {
      width: 240px;
    }

    .bar-1 {
      width: 480px;
      background-color: #f8d86a;
    }

    .bar-2 {
      width: 240px;
      background-color: #f07c82;
    }
  }

  .table-2 {
    display: flex;
    height: 42px;
    border-left: 1px solid black;

    .tt {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    .tt1 {
      width: 119px;
    }

    .tt2 {
      width: 239px;
    }

    .tt3 {
      width: 359px;
    }

    .tt4 {
      width: 479px;
    }
  }
}

.a-btn {
  cursor: pointer;
  color: #1ea0ff;
  text-decoration: underline;
}

</style>
