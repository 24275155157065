<template>
  <div>
    <el-dialog title="创建收货单" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <el-form :model="dialogData" :rules="dialogRules" ref="dialogForm"
               label-width="110px" v-loading="dialogLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="物料">
              <span>{{ materialProcessStep1.materialName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商">
              <span>{{ materialProcessStep1.materialSupplierName }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="批次">
              <span>{{ materialProcessStep1.batchCode }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货数量">
              <span>{{ materialProcessStep1.processNum }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="发货物流">
              <span>{{ materialProcessStep1.shipperName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提单号">
              <span>{{ materialProcessStep1.materialTransport.billLadingNo }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="离港时间">
              <span>{{ formatDate(materialProcessStep1.materialTransport.departPortTime) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计到港时间">
              <span>{{ formatDate(materialProcessStep1.materialTransport.expectPortTime) }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>收货信息</el-divider>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="实际到港时间" prop="arrivalPortTimeDate">
              <el-date-picker
                  v-model="dialogData.arrivalPortTimeDate"
                  style="width: 100%;"
                  type="date">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入库仓库" prop="warehouseId">
              <el-select v-model="dialogData.warehouseId" placeholder="仓库" style="width: 100%;">
                <el-option
                    v-for="item in warehouseList"
                    :key="item.warehouseId"
                    :label="item.warehouseName"
                    :value="item.warehouseId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="确认数量" prop="processNum">
              <el-input v-model.number="dialogData.processNum" :maxlength="8" placeholder="确认数量"
                        style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">

          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="hide">关 闭</el-button>
        <el-button type="primary" @click.native="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import warehouseService from "API/pur/PurWarehouseService"
import materialProcessService from "API/pur/PurMaterialProcessService"
import tools from "COMJS/tools";

export default {
  name: "Step2CreateDialog",
  data() {
    let checkNum = (rule, value, callback) => {
      if (value > this.materialProcessStep1.processNum) {
        callback(new Error('确认数量不能大于发货数量!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogLoading: false,
      dialogData: {},
      dialogRules: {
        warehouseId: [
          {required: true, message: '请选择入库仓库'},
        ],
        arrivalPortTimeDate: [
          {required: true, message: '请选择实际到港时间'},
        ],
        processNum: [
          {required: true, message: '请填写数量'},
          {type: 'number', message: '输入内容不是有效的数字', trigger: 'blur'},
          {validator: checkNum, trigger: 'blur'}
        ]
      },
      //
      materialProcessStep1: {materialTransport:{}},
      warehouseList: {},
    }
  },
  mounted() {
    this.queryDataList()
  },
  methods: {
    show(step1) {
      this.materialProcessStep1 = step1;
      //console.log(this.materialProcessStep1)
      this.clear()
      this.dialogVisible = true
    },
    hide() {
      this.clear()
      this.dialogVisible = false
    },
    clear() {
      this.dialogData = {}
      try {
        this.$refs.dialogForm.resetFields();
      } catch (e) {
      }
    },
    save() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          let param = {
            ...this.dialogData,
            'previousProcessId': this.materialProcessStep1.materialProcessId,
            'arrivalPortTime': tools.formatDate(this.dialogData.arrivalPortTimeDate, 'yyyy-MM-dd'),
          }
          this.dialogLoading = true;
          materialProcessService.createStep2Process(param).then(res => {
            this.$message.success("保存成功")
            this.$emit("reloadAfterSave");
            this.hide()
          }).finally(()=>{
            this.dialogLoading = false;
          })
        }
      })
    },
    queryDataList() {
      warehouseService.queryList({}).then(res => {
        this.warehouseList = res;
      })
    },
    formatDate(time){
      return tools.formatTime(time,'yyyy-MM-dd');
    }
  }
}
</script>

<style scoped>

</style>