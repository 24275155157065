<template>
  <el-dialog :title="brandId ? '编辑品牌': '添加品牌'" :visible.sync="dialogFormVisible">
    <el-form :model="form" v-loading="loading">
      <el-form-item required label="品牌名称：" :label-width="formLabelWidth">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="品牌简称：" :label-width="formLabelWidth">
        <el-input v-model="form.brandShortName"></el-input>
      </el-form-item>
      <el-form-item required label="品牌图标：" :label-width="formLabelWidth">
        <el-upload
          ref="upload"
          class="avatar-uploader"
          :file-list="fileList"
          :class="{limit: form.iconUrl}"
          accept="image/*"
          :action="uploadUrl()"
          :before-upload="beforeUpload"
          :headers="requestHeaders"
          :on-success="uploadSuccess"
          :on-remove="handleRemove"
          list-type="picture-card"
        >+添加图片</el-upload>
      </el-form-item>
      <el-form-item label="品牌编号：" :label-width="formLabelWidth">
        <el-input v-model="form.brandCode"></el-input>
      </el-form-item>
      <el-form-item label="是否启用：" :label-width="formLabelWidth">
        <el-switch v-model="form.isEnable"></el-switch>
      </el-form-item>
      <el-form-item label="备注：" :label-width="formLabelWidth">
        <el-input v-model="form.remarks"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>


<script>
import PimBrandService from "API/pim/PimBrandService";
import IoProgressService from "API/IoProgressService.js";
import tools from "COMJS/tools";
import userModule from "@/module/userModule";

export default {
  props: {
    brandId: String
  },
  data() {
    return {
      form: {
        name: "",
        brandShortName: "",
        iconUrl: "",
        brandCode: "",
        isEnable: false,
        remarks: ""
      },
      requestHeaders: {
        Authorization: userModule.token
      },
      dialogFormVisible: false,
      formLabelWidth: "100px",
      loading: false,
      fileList: []
    };
  },
  watch: {
    dialogFormVisible(value) {
      if (this.brandId) {
        this.getBrandDetail();
      } else {
        this.resetForm();
      }
    }
  },
  methods: {
    getBrandDetail() {
      this.loading = true;
      PimBrandService.getBrandDetail(this.brandId).then(data => {
        this.form.name = data.brandName;
        this.form.brandShortName = data.brandShortName;
        this.form.iconUrl = data.logoUrl;
        if (data.logoUrl) {
          this.fileList = [
            {
              name: "",
              url: data.logoUrl
            }
          ];
        }
        this.form.brandCode = data.brandCode;
        this.form.isEnable = data.visible == "1";
        this.form.remarks = data.remark;
        this.loading = false;
      });
    },
    submitForm() {
      if (this.form.name == "") {
        return this.$message({
          message: "请填写品牌名称",
          type: "warning"
        });
      }
      if (this.form.iconUrl == "") {
        return this.$message({
          message: "请上传品牌图标",
          type: "warning"
        });
      }
      let params = {
        brandName: this.form.name,
        logoUrl: this.form.iconUrl,
        brandCode: this.form.brandCode,
        brandShortName: this.form.brandShortName,
        visible: this.form.isEnable ? 1 : 0,
        remark: this.form.remarks
      };
      this.loading = true;
      if (this.brandId) {
        params.brandId = this.brandId;
      }
      PimBrandService.saveBarnd(params).then(res => {
        this.loading = false;
        this.$emit("updateTable");
      });
      this.dialogFormVisible = false;
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("brand");
      return url;
    },
    uploadSuccess(url) {
      this.form.iconUrl = url;
    },
    beforeUpload(file) {
      let isLtM = file.size / 1024 / 1024 < 1;
      if (!isLtM) {
        this.$message.error("品牌图标大小不能超过1M");
      }
      return isLtM;
    },
    handleRemove() {
      this.form.iconUrl = "";
    },
    // 重置表单
    resetForm() {
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
      tools.clearObj(this.form);
    }
  }
};
</script>

<style lang="scss">
.upload-img-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: $themeColor;
  border: 1px solid #dcdee0;
  border-radius: 0px;
}
</style>