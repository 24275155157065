<template>
  <div>
    <div>
      <div style="margin-bottom: 20px;display: flex;">
        <el-button
            v-if="!showFreeze"
            type="primary"
            @click="editDialogShow(null)">
          新增人员
        </el-button>
        <el-button
            v-if="!showFreeze"
            type="primary"
            @click="planDialogShow()">
          礼单计划
        </el-button>
        <span v-if="showFreeze" style="color: crimson;font-size: 14px;">
          * 当前查看的是已冻结的人员，可操作恢复，礼单记录和地址信息将保留。
        </span>
        <el-button
            type="text" style="color: crimson;margin-left: auto;"
            @click="showFreeze=!showFreeze;">
          {{ showFreeze ? '返回正常列表' : '查看冻结人员' }}
        </el-button>
      </div>
      <div>
        <el-tabs v-model="tabsActiveName" type="card" @tab-click="loadTableData">
          <el-tab-pane
              :label="item.label" :name="item.value"
              v-for="(item,idx) in staffCategoryList" :key="idx"></el-tab-pane>
        </el-tabs>
        <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="false"
            :isShadow="false"
            :selection="'multi'"
        >
          <el-table-column prop="staffName" label="姓名" minWidth="140">
            <template slot-scope="scope">
              <p style="font-weight: bolder;">{{ scope.row.staffName }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="staffTel" label="手机" minWidth="140"></el-table-column>
          <el-table-column prop="staffNote" label="备注" minWidth="140"></el-table-column>
          <el-table-column prop="lastGiftTime" label="最近一次礼单时间"
                           :formatter="dateFormatter4Table"
                           minWidth="140"></el-table-column>
          <el-table-column label="地址和礼单信息"
                           minWidth="140">
            <template slot-scope="scope">
              <span style="color:red;">
                {{ scope.row.giftInfo }}</span>
              <a href="javascript: void(0);" style="color: #468dea;margin-left: 10px;"
                 @click="giftDialogShow(scope.row)">
                <i class="el-icon-present">查看</i>
              </a>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="280">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a v-if="!showFreeze"
                   href="javascript: void(0);" title="编辑"
                   @click="editDialogShow(scope.row)">
                  <i class="el-icon-edit-outline">编辑</i>
                </a>
                <a v-if="!showFreeze"
                   href="javascript: void(0);" title="冻结"
                   @click="modifyStateDialogShow(scope.row,0)">
                  <i class="el-icon-delete">冻结</i>
                </a>
                <a v-if="showFreeze"
                   href="javascript: void(0);" title="恢复"
                   @click="modifyStateDialogShow(scope.row,1)">
                  <i class="el-icon-delete">恢复</i>
                </a>
              </div>
            </template>
          </el-table-column>
          <template slot="pagination-mid">
            <div style="display: flex; align-items: center;">
              <div style="margin-left: 20px;">
                <el-popover
                    placement="top-start"
                    title="说明"
                    width="200"
                    trigger="hover"
                    content="为选中的人员创建礼单">
                  <el-button slot="reference"
                             @click="handleDoPlanDialogShow">
                    选择建单
                  </el-button>
                </el-popover>
              </div>
              <div style="margin-left: 20px;">
                <el-popover
                    placement="top-start"
                    title="说明"
                    width="200"
                    trigger="hover"
                    content="为所有本类型人员创建礼单">
                  <el-button slot="reference"
                             @click="selectAll">
                    全部建单
                  </el-button>
                </el-popover>
              </div>
            </div>
          </template>
        </ws-page-table>
      </div>
    </div>

    <el-dialog title="礼单计划" :visible.sync="planDialogVisible" width="800px">
      <div v-if="!planEditVisible">
        <el-button type="primary" @click="planEdit(null)" icon="el-icon-plus">添加</el-button>
        <el-table
            :data="planTableData"
            style="width: 100%">
          <el-table-column prop="staffPlanDate" label="日期" width="120px" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p v-if="!!scope.row.staffPlanFestival && !!scope.row.staffPlanFestivalBefore">
                {{ scope.row.staffPlanFestival }} 前{{ scope.row.staffPlanFestivalBefore }}天
              </p>
              <p v-else-if="!!scope.row.staffPlanDate">
                {{ scope.row.staffPlanDate }}
              </p>
              <p v-else>手动</p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsContentCode" label="礼品" width="200px">
            <template slot-scope="scope">
              <el-tag style="margin: 3px;"
                      v-for="(tag,idx) in scope.row.goodsList" :key="idx">
                {{ tag.goodsCode }} * {{ tag.num }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="staffCategoryStr" label="对象" width="120px"></el-table-column>
          <el-table-column prop="smsBlessing" label="祝福语" min-width="200px"
                           :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="编辑"
                   @click="planEdit(scope.row)">
                  <i class="el-icon-edit-outline"></i>
                </a>
                <a style="margin-left: 20px;"
                   href="javascript: void(0);" title="删除"
                   @click="planDelete(scope.row)">
                  <i class="el-icon-delete"></i>
                </a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-button type="text" @click="planEditVisible=false" icon="el-icon-arrow-left">返回</el-button>
        <el-form label-width="100px">
          <el-form-item label="类型" required>
            <el-radio-group v-model="editStaffPlanData.dateType">
              <el-radio label="1">按节日</el-radio>
              <el-radio label="2">按日期</el-radio>
              <el-radio label="3">人工</el-radio>
            </el-radio-group>
            <p style="color: #b70c39;" v-if="editStaffPlanData.dateType==3">* 本计划只用于人工生成礼单</p>
          </el-form-item>
          <el-form-item label="节日" required v-if="editStaffPlanData.dateType==1">
            <el-input placeholder="前几天" v-model="editStaffPlanData.staffPlanFestivalBefore">
              <el-select v-model="editStaffPlanData.staffPlanFestival" slot="prepend"
                         style="width: 100px;"
                         placeholder="节日">
                <el-option
                    v-for="item in festivalList"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
              <template slot="append">天</template>
            </el-input>
            <p style="color: #b70c39;">* 预留两天给地址修改，加上快递时间，建议提前4天以上</p>
          </el-form-item>
          <el-form-item label="日期" required v-if="editStaffPlanData.dateType==2">
            <el-date-picker
                v-model="editStaffPlanData.staffPlanDateTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="范围" required v-if="editStaffPlanData.dateType==1 || editStaffPlanData.dateType==2">
            <el-checkbox-group v-model="editStaffPlanData.staffCategoryArr">
              <el-checkbox label="vip">VIP客户</el-checkbox>
              <el-checkbox label="employee">员工</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="礼品内容" required>
            <!--
            <el-input v-model="editStaffPlanData.goodsContentCode"></el-input>
            -->
            <el-tag
                style="margin-right: 10px;"
                v-for="(item,idx) in editStaffPlanData.goodsList" :key="idx"
                :disable-transitions="false" closable
                @close="handleRemoveFromGoodsList(idx)">
              {{ item.goodsCode }} * {{ item.num }}
            </el-tag>
            <el-button class="button-new-tag" icon="el-icon-plus"
                       size="mini" @click="$refs.selectGoodsPopup.dialogVisible = true">添加
            </el-button>
          </el-form-item>
          <el-form-item label="祝福语" required>
            <el-input v-model="editStaffPlanData.smsBlessing"></el-input>
            <p style="color: #b70c39;">* 短信内容：祝您和家人{{ editStaffPlanData.smsBlessing }}，为您准备了……</p>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" v-if="planEditVisible">
        <el-button @click="planEditVisible = false">取 消</el-button>
        <el-button type="primary" @click="planDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="手动创建礼单" :visible.sync="handleSampleDialogVisible" width="800px">
      <div>
        <el-table :data="planTableData" style="width: 100%">
          <el-table-column prop="staffPlanDate" label="日期">
            <template slot-scope="scope">
              <p v-if="!!scope.row.staffPlanFestival && !!scope.row.staffPlanFestivalBefore">
                {{ scope.row.staffPlanFestival }} 前{{ scope.row.staffPlanFestivalBefore }}天
              </p>
              <p v-else-if="!!scope.row.staffPlanDate">
                {{ scope.row.staffPlanDate }}
              </p>
              <p v-else>手动</p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsContentCode" label="礼品"></el-table-column>
          <el-table-column prop="staffCategoryStr" label="对象"></el-table-column>
          <el-table-column prop="smsBlessing" label="祝福语"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="执行"
                   @click="handleDoPlanDialogConfirm(scope.row.staffPlanId)">
                  <i class="el-icon-s-order">执行</i>
                </a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog title="人员信息" :visible.sync="editDialogVisible" :width="!editStaffData.staffId?'800px':'560px'">
      <el-form label-width="100px">
        <el-form-item label="类型" required>
          <el-select v-model="editStaffData.staffCategory"
                     :disabled="editStaffData.staffId!=null"
                     placeholder="请选择类型">
            <el-option
                v-for="item in staffCategoryList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" required>
          <el-input v-model="editStaffData.staffName"
                    :disabled="editStaffData.staffId!=null"
                    placeholder="人员姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机" required>
          <el-input v-model="editStaffData.staffTel"
                    maxlength="11"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    placeholder="手机号码">
            <template slot="prepend">+ 86</template>
          </el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="editStaffData.staffNote"
                    placeholder="备注"></el-input>
        </el-form-item>
        <!--以下是新增才有-->
        <template v-if="!editStaffData.staffId">
          <el-divider><i class="el-icon-box"> 默认收件信息</i></el-divider>
          <el-form-item label="收件姓名" required>
            <el-input v-model="editStaffAddressData.recipientName"
                      placeholder="收件姓名"></el-input>
            <el-button type="text" style="margin-left: 10px;"
                       @click="editStaffAddressData={...editStaffAddressData,recipientName:editStaffData.staffName}">同上
            </el-button>
          </el-form-item>
          <el-form-item label="收件手机" required>
            <el-input v-model="editStaffAddressData.recipientPhoneNum"
                      maxlength="11"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      placeholder="收件手机">
              <template slot="prepend">+ 86</template>
            </el-input>
            <el-button type="text" style="margin-left: 10px;"
                       @click="editStaffAddressData={...editStaffAddressData,recipientPhoneNum:editStaffData.staffTel}">
              同上
            </el-button>
          </el-form-item>
          <el-form-item label="收件地址" required>
            <area-cascader v-model="editStaffAddressData.pcc"></area-cascader>
            <el-input
                style="margin-top: 10px;width: 582px;"
                v-model="editStaffAddressData.recipientAddressDetail"
                placeholder="请填写详细地址"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <select-goods-popup ref="selectGoodsPopup" :showSelectedNum="true"
                        @submit="selectGoods"></select-goods-popup>
  </div>
</template>

<script>
import lsStaffService from 'API/ls/LsStaffService.js'
import pageTableMixin from "@/mixin/pageTableMixin";
import tools from "COMJS/tools";
import areaCascader from "COMPS/base/areaCascader.vue";
import selectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";

export default {
  name: "LsStaffList",
  mixins: [pageTableMixin],
  components: {
    areaCascader,
    selectGoodsPopup
  },
  data() {
    return {
      showFreeze: false,
      tabsActiveName: 'vip',
      //人员编辑
      editDialogVisible: false,
      editStaffData: {},
      editStaffAddressData: {},
      staffCategoryList: [{value: 'vip', label: 'VIP客户'}, {value: 'employee', label: '员工'}],
      //礼单计划
      planDialogVisible: false,
      planTableData: [],
      planEditVisible: false,
      editStaffPlanData: {},
      festivalList: ['元旦', '春节', '清明', '国际劳动节', '端午节', '中秋节', '国庆节'],
      //手动创建礼单
      handleSampleDialogVisible: false,
      handleAllThisTab: false,
    }
  },
  watch: {
    showFreeze() {
      this.loadTableData();
    }
  },
  mounted() {
    this.loadPlanTableData();
  },
  methods: {
    loadPlanTableData() {
      let param = {}
      lsStaffService.queryPlanList(param).then(res => {
        res = res.map(e => {
          return {
            ...e,
            'staffCategoryStr': !e.staffCategory ? ''
                : e.staffCategory.replace('vip', 'VIP客户').replace('employee', '员工'),
            'goodsList': this.splitGoodsContent(e.goodsContent, e.goodsContentCode)
          }
        })
        this.planTableData = res;
      })
    },
    changePage(i) {
      this.pageInfo.pageNo = i;
      this.loadTableData();
    },
    loadTableData() {
      let param = {
        staffCategory: this.tabsActiveName,
        staffState: this.showFreeze ? 'freeze' : 'normal',
        flagWithGift: '1',
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
      }
      lsStaffService.pageStaffByParam(param).then(res => {
        this.querySuccess(res);
        //this.pageTotal = res.total
      })
    },
    giftDialogShow(row) {
      this.$router.push({
        path: `/ls/lsStaffDetail/${row.staffId}`,
      });
    },
    editDialogShow(item) {
      if (item) {
        this.editStaffData = {
          ...item
        }
      } else {
        this.editStaffData = {};
        this.editStaffAddressData = {pcc: {province: "", city: "", area: ""}};
      }
      this.editDialogVisible = true;
    },
    editDialogConfirm() {
      //console.log(this.editStaffData)
      let saveData = {
        ...this.editStaffData
      }
      if(!this.editStaffData.staffId){
        //新增
        saveData.initStaffAddress = {
          ...this.editStaffAddressData,
          recipientProvince: this.editStaffAddressData.pcc.province,
          recipientCity: this.editStaffAddressData.pcc.city,
          recipientCounty: this.editStaffAddressData.pcc.area,
        }
      }
      this.saveStaffData(saveData);
    },
    modifyStateDialogShow(staffData, status) {
      const h = this.$createElement;
      this.$msgbox({
        title: status == 0 ? '冻结人员' : '恢复人员',
        message: h('div', null, [
          h('p', null, status == 0 ? '人员将被移入冻结列表' : '人员将被恢复至正常列表'),
          h('p', null, status == 0 ? '可在冻结列表操作恢复，原礼单记录和地址信息将会保留' : ''),
          h('p', null, '是否继续？'),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {
        //confirm,cancel
        if (action == 'confirm') {
          this.modifyStateDialogConfirm(staffData, status == 0 ? 'freeze' : 'normal');
        }
      });
    },
    modifyStateDialogConfirm(staffData, staffState) {
      let param = {
        ...staffData,
        staffState: staffState
      }
      this.saveStaffData(param);
    },
    saveStaffData(staffData) {
      if (!staffData.staffCategory
          || !staffData.staffName || !staffData.staffTel) {
        return this.$message.warning("信息不完整，请检查")
      }
      if (staffData.staffTel.length != 11) {
        return this.$message.warning("手机号码格式错误，请检查")
      }
      if (!staffData.staffId) {
        //新增
        if (!staffData.initStaffAddress) {
          return this.$message.warning("请填写初始收货地址")
        }
        if (!staffData.initStaffAddress.recipientName) {
          return this.$message.warning("请填写收件人姓名")
        }
        if (!staffData.initStaffAddress.recipientPhoneNum) {
          return this.$message.warning("请填写收件人手机号码")
        }
        if (staffData.initStaffAddress.recipientPhoneNum.length != 11) {
          return this.$message.warning("手机号码格式错误，请检查")
        }
        if (!staffData.initStaffAddress.recipientProvince || !staffData.initStaffAddress.recipientCity
            || !staffData.initStaffAddress.recipientCounty || !staffData.initStaffAddress.recipientAddressDetail) {
          return this.$message.warning("收件地址信息不完整，请检查！")
        }
      }
      lsStaffService.saveLsStaff(staffData).then(res => {
        this.$message.success(`保存成功！`);
        this.loadTableData();
        this.editDialogVisible = false;
      })
    },
    planDialogShow() {
      this.planDialogVisible = true;
      this.planEditVisible = false;
    },
    planEdit(item) {
      if (item) {
        //在加载的时候，就转换了
        //let goodsList = this.splitGoodsContent(item.goodsContent, item.goodsContentCode)
        this.editStaffPlanData = {
          ...item,
          dateType: item.staffPlanFestival ? '1' : item.staffPlanDate ? '2' : '3',
          staffPlanDateTime: item.staffPlanDate ? tools.parseDate(item.staffPlanDate) : null,
          staffCategoryArr: item.staffCategory ? item.staffCategory.split(",") : [],
          //goodsList: goodsList
        }
      } else {
        this.editStaffPlanData = {dateType: '1', staffCategoryArr: [], goodsList: []};
      }
      this.planEditVisible = true
    },
    planDelete(item) {
      this.$confirm(`确认删除计划?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          'staffPlanId': item.staffPlanId
        }
        lsStaffService.removeLsStaffPlan(param).then(res => {
          this.$message.success('删除成功')
          this.loadPlanTableData();
        })
      });
    },
    selectGoods(selected) {
      if (!selected) {
        return;
      }
      let list = this.editStaffPlanData.goodsList
      if (!list) {
        list = []
      }
      for (let selectedElement of selected) {
        if (!selectedElement.selectedNum
            || selectedElement.selectedNum == 0) {
          continue;
        }
        let addFlag = true;
        for (let listElement of list) {
          if (selectedElement.goodsId == listElement.goodsId) {
            listElement.num += selectedElement.selectedNum;
            addFlag = false;
            continue;
          }
        }
        if (addFlag) {
          list.push({
            goodsId: selectedElement.goodsId,
            goodsCode: selectedElement.goodsCode,
            num: selectedElement.selectedNum,
          })
        }
      }
      this.editStaffPlanData = {
        ...this.editStaffPlanData,
        goodsList: list
      }
    },
    handleRemoveFromGoodsList(idx) {
      let list = this.editStaffPlanData.goodsList
      list.splice(idx, 1)
      this.editStaffPlanData = {
        ...this.editStaffPlanData,
        goodsList: list
      }
    },
    planDialogConfirm() {
      if (this.editStaffPlanData.dateType == 1) {
        if (!this.editStaffPlanData.staffPlanFestival
            || !this.editStaffPlanData.staffPlanFestivalBefore) {
          return this.$message.warning("请完善节日信息")
        }
        this.editStaffPlanData.staffPlanDate = null
      } else if (this.editStaffPlanData.dateType == 2) {
        if (!this.editStaffPlanData.staffPlanDateTime) {
          return this.$message.warning("请完善日期信息")
        }
        this.editStaffPlanData.staffPlanDate = tools.formatDate(this.editStaffPlanData.staffPlanDateTime)
        this.editStaffPlanData.staffPlanFestival = null
        this.editStaffPlanData.staffPlanFestivalBefore = null
      } else if (this.editStaffPlanData.dateType == 3) {
        this.editStaffPlanData.staffPlanDate = null
        this.editStaffPlanData.staffPlanFestival = null
        this.editStaffPlanData.staffPlanFestivalBefore = null
      }
      if (this.editStaffPlanData.dateType == 1 || this.editStaffPlanData.dateType == 2) {
        if (!this.editStaffPlanData.staffCategoryArr
            || this.editStaffPlanData.staffCategoryArr.length == 0) {
          return this.$message.warning("请选择范围")
        } else {
          this.editStaffPlanData.staffCategory = this.editStaffPlanData.staffCategoryArr.join(',')
        }
      } else {
        this.editStaffPlanData.staffCategory = null;
      }
      if (!this.editStaffPlanData.goodsList
          || this.editStaffPlanData.goodsList.length == 0) {
        return this.$message.warning("请选择礼品内容")
      } else {
        let content = ''
        let contentCode = ''
        for (let goodsListElement of this.editStaffPlanData.goodsList) {
          content += (goodsListElement.goodsId + '*' + goodsListElement.num + ",");
          contentCode += (goodsListElement.goodsCode + '*' + goodsListElement.num + ",");
        }
        this.editStaffPlanData.goodsContent = content.substr(0, content.length - 1)
        this.editStaffPlanData.goodsContentCode = contentCode.substr(0, contentCode.length - 1)
      }
      let param = {
        ...this.editStaffPlanData
      }
      lsStaffService.savePlan(param).then(res => {
        this.$message.success('保存成功')
        this.editStaffPlanData = {}
        this.planEditVisible = false;
        this.loadPlanTableData();
      })
    },
    selectAll() {
      if (!this.tableData || this.tableData.length == 0) {
        this.$alert('当前类型无记录！', '系统提示');
        return;
      }
      this.handleSampleDialogVisible = true;
      this.handleAllThisTab = true;
    },
    handleDoPlanDialogShow() {
      let selected = this.$refs.pageTable.getCheckedData();
      if (selected.length < 1) {
        this.$alert('请选择记录！', '系统提示');
        return;
      }
      this.handleSampleDialogVisible = true;
      this.handleAllThisTab = false;
    },
    handleDoPlanDialogConfirm(planId) {
      if (!planId) {
        return;
      }
      let selected = []
      if (!this.handleAllThisTab) {
        selected = this.$refs.pageTable.getCheckedData();
        if (selected.length < 1) {
          this.$alert('请选择记录！', '系统提示');
          return;
        }
      }
      const h = this.$createElement;
      this.$msgbox({
        title: '手动执行计划',
        message: h('div', null, [
          h('p', null, '本操作将会为' + (this.handleAllThisTab ? '本类型所有人员' : '选中的' + selected.length + '名人员')
              + '创建礼单并发送礼单短信'),
          h('p', null, '是否继续？'),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {
        //confirm,cancel
        if (action == 'confirm') {
          this.handleDoPlan(planId, selected, this.handleAllThisTab)
        }
      });
    },
    handleDoPlan(planId, selected, allFlag) {
      let param = {
        staffPlanId: planId,
        staffIdList: selected.map(e => {
          return e.staffId
        }),
        allStaffThisCategory: allFlag ? this.tabsActiveName : '',
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      lsStaffService.handleDoPlan(param).then(res => {
        loading.close();
        this.$message.success("执行成功！")
        this.loadTableData()
        this.handleSampleDialogVisible = false;
      }).catch(() => {
        loading.close();
      });
    },
    splitGoodsContent(goodsContent, goodsContentCode) {
      let goodsList = []
      try {
        let goodsIdNumList = goodsContent.split(",")
        let goodsCodeNumList = goodsContentCode.split(",")
        for (let i = 0; i < goodsIdNumList.length; i++) {
          let goodsIdNum = goodsIdNumList[i]
          let goodsCodeNum = goodsCodeNumList[i]
          let temp1 = goodsIdNum.split("*")
          let temp2 = goodsCodeNum.split("*")
          if (temp1.length == 2 && temp2.length == 2) {
            goodsList.push({goodsId: temp1[0], goodsCode: temp2[0], num: temp1[1]})
          }
        }
      } catch (e) {
      }
      return goodsList;
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>