import axiosHttp from "../axiosHttp"

export default {
  // 枚举类型查询
  getHomeData(pageInfo) {
    let url = "/bimUserData/getHomeData?userType=platform&"
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    return axiosHttp.GET(url);
  },
  //首页通知查询
  isPopup() {
    return axiosHttp.GET("/notice/isPopup")
  },
  //通知已读
  confirmNotice(noticeId) {
    return axiosHttp.GET(`/notice/confirmNotice/${noticeId}`)
  },
}
