<template>
  <div id="passTheAuditPop">
    <el-dialog title="审核通过" :visible.sync="dialogVisible" width="30%">
      <el-row class="row-item">
        <el-col :span="24">售后金额：￥{{chargeFormat.thousandsOf(currentData.order.totalAmount)}}</el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="24">
          <el-radio v-model="radio" label="1">全部退款</el-radio>
        </el-col>
      </el-row>
      <el-row class="row-item" type="flex" align="middle">
        <el-col :span="4">
          <el-radio v-model="radio" label="2">部分退款</el-radio>
        </el-col>
        <el-col :span="6">
          <el-input v-model="refund" type="number" @input="thisMaxValue(currentData.order.totalAmount)" placeholder="扣款金额"></el-input>
        </el-col>
        <el-col :span="3" :offset="1">
          退款金额
        </el-col>
        <el-col :span="6">
          <el-input :value="chargeFormat.thousandsOf(currentData.order.totalAmount - refund)" placeholder="退款金额" disabled></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-input type="textarea" :rows="4" v-model="auditRemark" placeholder="售后说明"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitReturn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userModule from "@/module/userModule";
import chargeFormat from "COMJS/chargeFormat";
import BigNumber from "COMJS/bignumber.min"
import { auditHandle } from "API/som/checkService.js";

export default {
  data() {
    return {
      dialogVisible: false,
      radio: "1",
      refund: 0,
      auditRemark: "",
      currentData: {
        order: {},
      },
      businessAuditLogId: "",
      needToReturn: "",
    };
  },
  computed: {
    chargeFormat() {
      return chargeFormat;
    },
  },
  watch: {
    refund(value) {
      if (value != 0) {
        this.radio = "2";
      }
    },
    radio(value) {
      if (value == "1") {
        this.refund = 0;
      }
    },
  },
  methods: {
    getcurrentData(data) {
      this.currentData = data.currentData;
      this.businessAuditLogId = data.businessAuditLogId;
      this.needToReturn = data.needToReturn;
    },
    commitReturn(i) {
      if (!this.auditRemark) {
        return this.$message.warning("请填写售后说明");
      }
      let loading = this.$loading({
        lock: true,
        text: "提交中...",
      });
      try {
        let totalAmount = new BigNumber(this.currentData.order.totalAmount).minus(this.refund).toNumber();
        //let totalAmount = this.currentData.order.totalAmount - this.refund;
        let params = {
          userId: JSON.parse(userModule.PPUser).userId,
          businessAuditLogId: this.businessAuditLogId,
          auditResult: "success",
          auditRemark: this.auditRemark,
          needToReturn: this.needToReturn,
          totalAmount: totalAmount,
          reduceAmount: Number(this.refund),
        };
        //console.log(params)
        //return;
        auditHandle(params).then((data) => {
          //console.log(data);
          loading.close();
          this.$emit("auditOk", "");
          this.dialogVisible = false;
          Object.assign(this.$data,this.$options.data())
        });
      } catch (error) {
        loading.close();
        this.$emit("auditOk", "");
        this.dialogVisible = false;
        Object.assign(this.$data,this.$options.data())
      }
    },
    thisMaxValue(max) {
      if (this.refund > max) {
        this.refund = max;
      }
      if (this.refund < 0) {
        this.refund = 0;
      }
    },
  },
};
</script>

<style scoped>
.row-item {
  padding: 10px 0;
}
.el-input {
  margin-bottom: 10px;
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>