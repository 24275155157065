<template>
  <div class="product-add">
    <el-form :model="productInfo" label-width="110px" ref="productInfoForm" class="step-form">
      <div class="step-wrapper base-from box-shadow-unify">
        <div class="step-title">基本信息</div>
        <el-form-item required label="商品类型：">
          <el-select v-model="productInfo.goodsType" placeholder="商品类型" style="width: 160px;">
            <el-option
                v-for="item in goodsTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="销售状态：">
          <el-select v-model="productInfo.goodsState" placeholder="商品状态" style="width: 160px;">
            <el-option
                v-for="item in goodsStateList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="商品名称：">
          <el-input v-model="productInfo.cnName" maxlength="35"></el-input>
          <div class="tips">用于消费端展示，最多35字</div>
        </el-form-item>
        <el-form-item required label="商品编号：">
          <el-input v-model="productInfo.goodsCode" @blur="checkReapt(productInfo.goodsCode)"></el-input>
        </el-form-item>
        <el-form-item required label="商品类目：">
          <div v-if="!selectedCategory.selectedFirstCategory"
               class="select-btn"
               @click="$refs.selectCategoryPopup.dialogVisible = true"
          >请选择商品分类
          </div>
          <template v-else>
            <span>{{ selectedCategory.selectedFirstCategory.nodeName || '' }}&nbsp;{{ selectedCategory.selectedSecondCategory.nodeName || '' }}&nbsp;{{ selectedCategory.selectedThirdCategory.nodeName || '' }}</span>
            <span
                class="tips-btn"
                style="dispaly: inline-block;margin-left: 15px;"
                @click="$refs.selectCategoryPopup.dialogVisible = true"
            >重选类目</span>
          </template>
        </el-form-item>
        <el-form-item required label="商品品牌：">
          <div
              class="select-btn"
              v-if="!selectedBrand.brandId"
              @click="$refs.selectBrandPopup.dialogVisible = true"
          >请选择商品品牌
          </div>
          <template v-else>
            <span>{{ selectedBrand.brandName }}</span>
            <span
                class="tips-btn"
                style="dispaly: inline-block;margin-left: 15px;"
                @click="$refs.selectBrandPopup.dialogVisible = true"
            >重选品牌</span>
          </template>
        </el-form-item>
        <el-form-item required label="商品略图：">
          <div>
            <draggable-upload
                :width="82"
                :height="82"
                :limit="1"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="thumbnailImgs"
            ></draggable-upload>
          </div>
          <div class="tips">
            用于展示商品规格
          </div>
        </el-form-item>
        <!--
        <el-form-item required label="上架时间：">
          <el-date-picker
              v-model="saleTimeList"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
          <div class="tips" v-text="saleTimeDesc"></div>
        </el-form-item>
        -->
        <el-form-item required label="上架平台：">
          <el-checkbox-group v-model="platformShelfList">
            <!--
            <el-checkbox label="小程序零售下单"></el-checkbox>
            -->
            <el-checkbox label="后台批量下单"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item required label="上架时间：">
          <el-radio-group v-model="productInfo.onShelfType">
            <el-radio :label="1">立即上架</el-radio>
            <div class="el-inline-radio">
              <el-radio :label="2">定时上架</el-radio>
              <el-date-picker
                  style="width: 200px;"
                  @change="onShelfTimeChange"
                  v-model="productInfo.onShelfTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  :disabled="productInfo.onShelfType!=2"
              ></el-date-picker>
            </div>
            <el-radio :label="3">暂不售卖，放入仓库</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item required label="定时下架：">
          <el-radio-group v-model="productInfo.offShelfType">
            <el-radio :label="1">默认关闭</el-radio>
            <div class="el-inline-radio">
              <el-radio :label="2">定时下架</el-radio>
              <el-date-picker
                  style="width: 200px;"
                  @change="offShelfTimeChange"
                  v-model="productInfo.offShelfTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  :disabled="productInfo.offShelfType!=2"
              ></el-date-picker>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="step-wrapper">
        <div class="step-title">价格信息</div>
        <el-form-item required label="零售价：">
          <el-input v-model="productInfo.retailPrice" @keyup.native='keyupEvent($event,productInfo.retailPrice)'
                    maxlength="14">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item required label="底价：">
          <el-input v-model="productInfo.floorPrice" @keyup.native="keyupEvent($event,productInfo.floorPrice)"
                    maxlength="14">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="划线价：">
          <el-input v-model="productInfo.oldPrice" @keyup.native="keyupEvent($event,productInfo.oldPrice)"
                    maxlength="14">
            <template slot="prepend">￥</template>
          </el-input>
          <div class="tips">
            划线价用于商品详情的价格展示，不填写默认不展示。
            <span
                class="tips-btn"
                @click="$refs.priceExamplePopup.dialogVisible = true"
            >查看示例</span>
          </div>
        </el-form-item>
      </div>

      <div class="step-wrapper">
        <div class="step-title">销售信息</div>
        <el-form-item label="实际销量：">
          <el-input v-model="productInfo.actualSale" :disabled="true">
          </el-input>
          <div class="tips">
            实际销量不可调整，只会因商品下单而增加。
          </div>
        </el-form-item>
        <el-form-item label="虚拟销量：">
          <el-input v-model="productInfo.virtualSale" @keyup.native="keyupEvent($event,productInfo.virtualSale)"
                    maxlength="14">
          </el-input>
          <div class="tips">
            虚拟销量不会因商品成交而增加，只用于销售端展示，展示销量=实际销量+虚拟销量。
          </div>
        </el-form-item>
        <el-form-item required label="剩余可售数量：">
          <el-input v-model="productInfo.availableNum" @keyup.native="keyupEvent($event,productInfo.availableNum)"
                    maxlength="14">
          </el-input>
          <div class="tips">
            剩余可售数量会随商品下单而减少，剩余可售数量为0则商品无法下单。
          </div>
        </el-form-item>
      </div>

      <div class="step-wrapper">
        <div class="step-title">快递信息</div>
        <el-form-item required label="配送方式：">
          <span>快递发货</span>
        </el-form-item>
        <el-form-item required label="运费模版：">
          <el-select v-model="productInfo.transportId" placeholder="运费模版" style="width: 160px;">
            <el-option
                v-for="item in transportTemplateList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="重量：">
          <el-input v-model="productInfo.weight" @keyup.native='keyupEvent($event,productInfo.weight)'
                    maxlength="3">
            <template slot="append">公斤</template>
          </el-input>
        </el-form-item>
      </div>

      <div class="step-wrapper">
        <div class="step-title">分享信息</div>
        <el-form-item required label="分享图：">
          <div>
            <draggable-upload
                :width="82"
                :height="82"
                :limit="1"
                :fileSize="128 * 1024"
                fileSizeText="128k"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="shareImgs"
            ></draggable-upload>
          </div>
          <div class="tips">
            分享到微信对话框
            <span
                class="tips-btn"
                @click="$refs.shareExamplePopup.dialogVisible = true"
            >查看示例</span>
          </div>
        </el-form-item>
        <el-form-item required label="分享图：">
          <div>
            <draggable-upload
                :width="82"
                :height="82"
                :limit="1"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="shareMomentsImgs"
            ></draggable-upload>
          </div>
          <div class="tips">
            分享到微信朋友圈
            <span
                class="tips-btn"
                @click="$refs.shareExamplePopup.dialogVisible = true"
            >查看示例</span>
          </div>
        </el-form-item>
        <el-form-item label="分享描述：">
          <el-input v-model="productInfo.goodsShareDescribe"></el-input>
          <div class="tips">
            将商品分享到微信时，默认展示该标题，最多30字。未填写默认展示商品名称。
            <span
                class="tips-btn"
                @click="$refs.shareExamplePopup.dialogVisible = true"
            >查看示例</span>
          </div>
        </el-form-item>
        <!--
        <el-form-item label="分享等级：">
          <el-radio-group v-model="productInfo.activityViewLevel">
            <el-radio :label="0">一级</el-radio>
            <el-radio :label="1">二级</el-radio>
            <el-radio :label="2">三级</el-radio>
          </el-radio-group>
          <div class="tips">
            分享等级关系到，本次分享接受者的活动展示查看级别。（与展示活动中的展示级别联动）
          </div>
        </el-form-item>
        -->
      </div>

    </el-form>
    <div class="steps-btn-wrapper">
      <el-button type="primary" @click="createGoods">完成</el-button>
    </div>
    <select-category-popup ref="selectCategoryPopup" @submit="selectCategory"></select-category-popup>
    <select-brand-popup ref="selectBrandPopup" @submit="selectBrand"></select-brand-popup>
    <example-popup
        ref="shareExamplePopup"
        title="查看分享示例"
        tips="将商品再微信分享给朋友时，该处填写的内容会展示在商品名称下面"
        :imgUrl="require('ASSETS/images/share-example.png')"
    ></example-popup>
    <example-popup
        ref="priceExamplePopup"
        title="查看划线价示例"
        tips="划线价在商品详情中显示示例："
        :imgUrl="require('ASSETS/images/price-example.png')"
    ></example-popup>
  </div>
</template>

<script>

import pageTableMixin from "@/mixin/pageTableMixin.js";
import PimBrandService from "API/pim/PimBrandService";
import PimCategoryService from "API/pim/PimCategoryService";
import SearchConfig from "COMJS/query/SearchConfig";
import selectCategoryPopup from "./addProductStep/selectCategoryPopup";
import examplePopup from "COMPS/widgets/examplePopup";
import selectBrandPopup from "./addProductStep/selectBrandPopup";
import PimGoodsService from "API/pim/PimGoodsService";
import WhmList from "API/whm/WhmList";
import PimTransportService from "API/pim/PimTransportService";
import tools from "COMJS/tools.js";
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService.js";
import draggableUpload from "COMPS/base/draggableUpload";
import eventBus from "../../../js/eventBus";

export default {
  name: "productAdd",
  //mixins: [pageTableMixin],
  data() {
    return {
      requestHeaders: {
        Authorization: userModule.token,
      },
      //searchInfo: {},
      platformShelfList: [],
      thumbnailImgs: [],
      shareImgs: [],
      shareMomentsImgs: [],
      saleTimeList: [],
      productInfo: {
        goodsType: "",
        goodsState: "",
        shopId: "",
        categoryId: "",
        cnName: "", // 商品名称*3
        // topName: "",
        // bottomName: "",
        inventoryName: "", // 库品名称
        goodsBarCode: "", // 商品条码
        inventoryBarCode: "", // 库品条码
        goodsCode: "", // 商品编号
        inventoryCode: "", // 库品编号
        brandId: "", // 品牌id
        goodsShareDescribe: "", // 分享描述
        retailPrice: "", // 零售价
        floorPrice: "", // 底价
        oldPrice: "", // 划线价
        stockNum: "", // 实际库存
        isShowStockNum: true, // 是否显示库存数量
        enterSpec: "", // 入库规格
        outSpec: "", // 出库规格
        monthlySalesVolume: "", // 月销量
        transportId: "", //运费模版
        weight:0,
        allowOrderType: "",
        activityViewLevel: "0",
        actualSale: 0,//实际销量
        virtualSale: 0,//虚拟销量
        availableNum: 0,//剩余可售数量
        //上下架时间
        onShelfType:1,
        onShelfTime:'',
        offShelfType:1,
        offShelfTime:'',
      },
      selectedBrand: {
        brandId: "",
        brandName: ""
      },
      selectedCategory: {},
      transportTemplateList: [],
      goodsTypeList: [
        {
          id: "common",
          name: "普通商品"
        },
        {
          id: "year_card",
          name: "年卡商品"
        },
        {
          id: "recruit",
          name: "会员礼包商品"
        }
      ],
      goodsStateList: [
        {
          id: "normal",
          name: "可售"
        },
        {
          id: "sell_out",
          name: "售罄"
        }
      ]
    };
  },
  watch: {
    /*"productInfo.onShelfType": function(val) {
      if ('3' == val) {
        this.productInfo.offShelfType = 1
      }
    }*/
  },
  components: {
    selectCategoryPopup,
    examplePopup,
    selectBrandPopup,
    draggableUpload
  },
  mounted() {
    this.getTransportTemplateList();
    this.addGoOn();
  },
  computed: {
    brandSearchConfig() {
      return SearchConfig.createNew({
        searchForm: {
          brandName: this.selectedBrand.brandName
        }
      });
    },
    saleTimeDesc() {
      if (this.saleTimeList && this.saleTimeList.length == 2) {
        if (this.saleTimeList[0] > Date.now()) {
          return "未到上架时间，预售"
        } else if (this.saleTimeList[1] < Date.now()) {
          return "已过上架时间，下架"
        } else {
          return "正常上架"
        }
      } else {
        return "请选择上架时间"
      }
    }
  },
  methods: {
    offShelfTimeChange(e){
      //console.log(e)
    },
    onShelfTimeChange(e){
      //console.log(e)
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中..."
      });
      PimGoodsService.getGoodsDetail(this.$route.params.id).then(data => {
        this.productInfo.shopId = data.shopId;
        this.productInfo.goodsType = data.goodsType;
        this.productInfo.goodsState = data.goodsState;
        this.productInfo.categoryId = data.categoryId;
        this.productInfo.cnName = data.cnName;
        this.productInfo.goodsCode = data.goodsCode;
        //this.productInfo.inventoryName = data.goodsInfo.inventoryName;
        //this.productInfo.goodsBarCode = data.goodsInfo.goodsBarCode;
        //this.productInfo.inventoryBarCode = data.goodsInfo.inventoryBarCode;
        //this.productInfo.inventoryCode = data.goodsInfo.inventoryCode;
        this.productInfo.brandId = data.brandId;
        this.productInfo.sharePictureUrl = data.goodsInfo.sharePictureUrl;
        this.productInfo.shareMomentsPictureUrl = data.goodsInfo.shareMomentsPictureUrl;
        this.productInfo.goodsShareDescribe = data.goodsInfo.goodsShareDescribe;
        this.productInfo.retailPrice = data.initPrice;
        this.productInfo.floorPrice = data.basePrice;
        this.productInfo.oldPrice = data.markingPrice;
        this.productInfo.transportId = data.goodsInfo.transportId;
        this.productInfo.weight = data.goodsInfo.weight;
        this.productInfo.activityViewLevel = data.goodsInfo.activityViewLevel;
        this.productInfo.actualSale = data.goodsInfo.actualSale;//实际销量
        this.productInfo.virtualSale = data.goodsInfo.virtualSale;//实际销量
        this.productInfo.availableNum = data.goodsInfo.availableNum;//实际销量
        //上架时间
        if (data.effectiveTime && data.failureTime) {
          this.saleTimeList = [data.effectiveTime, data.failureTime];
        }
        //上下架时间

        //console.log(data.effectiveTime,data.failureTime)
        let minTimestamp = new Date('1971-01-01 00:00:00').getTime()
        let maxTimestamp = new Date('2038-01-01 00:00:00').getTime()
        let nowTimestamp = new Date().getTime()
        if (data.effectiveTime) {
          if(data.effectiveTime==minTimestamp){
            this.productInfo.onShelfType = 1;
          }else if(data.effectiveTime==maxTimestamp){
            this.productInfo.onShelfType = 3;
          }else{
            if(data.effectiveTime>nowTimestamp){
              this.productInfo.onShelfType = 2;
              this.productInfo.onShelfTime = new Date(data.effectiveTime)
            }else{
              this.productInfo.onShelfType = 1;
            }
          }
          //this.productInfo.onShelfTime = new Date(data.effectiveTime)
        }
        if (data.failureTime) {
          if(data.failureTime==maxTimestamp){
            this.productInfo.offShelfType = 1;
          }else if(data.failureTime==minTimestamp){
            this.productInfo.offShelfType = 1;
            this.productInfo.onShelfType = 3;
            this.productInfo.onShelfTime = '';
          }else{
            this.productInfo.offShelfType = 2;
            this.productInfo.offShelfTime = new Date(data.failureTime)
            if(data.failureTime<nowTimestamp){
              this.productInfo.onShelfType = 3;
              this.productInfo.onShelfTime = '';
            }
          }
          //this.productInfo.offShelfTime = new Date(data.failureTime)
        }

        //上架平台
        if (data.allowOrderType) {
          if (data.allowOrderType.indexOf('wmp') > -1) {
            this.platformShelfList.push("小程序零售下单")
          }
          if (data.allowOrderType.indexOf('import') > -1) {
            this.platformShelfList.push("后台批量下单")
          }
          if (data.allowOrderType.indexOf('sample') > -1) {
            this.platformShelfList.push("样品下单")
          }
        }

        //图
        this.thumbnailImgs = data.pictureUrl
            ? [
              {
                response: data.pictureUrl,
                url: data.pictureUrl
              }
            ]
            : [];
        this.shareImgs = data.goodsInfo.sharePictureUrl
            ? [
              {
                response: data.goodsInfo.sharePictureUrl,
                url: data.goodsInfo.sharePictureUrl
              }
            ]
            : [];
        this.shareMomentsImgs = data.goodsInfo.shareMomentsPictureUrl
            ? [
              {
                response: data.goodsInfo.shareMomentsPictureUrl,
                url: data.goodsInfo.shareMomentsPictureUrl
              }
            ]
            : [];

        if (data.brandId) {
          this.selectedBrand.brandId = data.brandId;
          this.selectedBrand.brandName = data.brandName;
        }
        if (data.categoryId) {
          this.productInfo.categoryId = data.categoryId;
          this.selectedCategory = {
            "selectedFirstCategory": {
              id: data.categoryId,
              nodeName: data.categoryName
            },
            "selectedSecondCategory": {},
            "selectedThirdCategory": {}
          };
        }
        this.$store.commit("saveProductAddInfo", this.productInfo);
        loading.close();
      });
    },
    createGoods() {
      let baseInfo = this.productInfo;
      let _effectiveTime;
      let _failureTime;
      let minTimestamp = new Date('1971-01-01 00:00:00')
      let maxTimestamp = new Date('2038-01-01 00:00:00')
      //let nowTimestamp = new Date()
      if(baseInfo.onShelfType==1){
        _effectiveTime = minTimestamp
      }else if(baseInfo.onShelfType==2){
        if(!baseInfo.onShelfTime){
          return this.$message({
            message: "请选择自定义上架时间",
            type: "warning",
          });
        }
        _effectiveTime = baseInfo.onShelfTime
      }else if(baseInfo.onShelfType==3){
        _effectiveTime = minTimestamp
        _failureTime = minTimestamp
      }
      if(baseInfo.onShelfType!=3){
        if(baseInfo.offShelfType==1){
          _failureTime = maxTimestamp
        }else if(baseInfo.offShelfType==2){
          _failureTime = baseInfo.offShelfTime
        }
      }
      if(/*baseInfo.onShelfType==2 && */_effectiveTime>_failureTime){
        return this.$message({
          message: "自定义上架时间必须在定时下架时间之前",
          type: "warning",
        });
      }

      //console.log(_effectiveTime)
      if (!this.thumbnailImgs.length) {
        return this.$message({
          message: "请添加商品略图",
          type: "warning",
        });
      }
      if (!this.shareImgs.length) {
        return this.$message({
          message: "请添加微信对话框分享图",
          type: "warning",
        });
      }
      if (!this.shareMomentsImgs.length) {
        return this.$message({
          message: "请添加微信朋友圈分享图",
          type: "warning",
        });
      }

      let _allowOrderType = "";
      if (this.platformShelfList.indexOf('小程序零售下单') > -1) {
        _allowOrderType += "wmp,";
      }
      if (this.platformShelfList.indexOf('后台批量下单') > -1) {
        _allowOrderType += "import,";
      }
      if (this.platformShelfList.indexOf('样品下单') > -1) {
        _allowOrderType += "sample,";
      }


      let PimGoods = {
        shopId: baseInfo.shopId,
        goodsType: baseInfo.goodsType,
        goodsState: baseInfo.goodsState,
        categoryId: baseInfo.categoryId,
        cnName: baseInfo.cnName, // 商品名
        goodsCode: baseInfo.goodsCode, // 商品编码
        allowOrderType: _allowOrderType, //上架平台（允许下单途径）
        goodsInfo: {
          //inventoryName: baseInfo.inventoryName, // 库品名
          //inventoryCode: baseInfo.inventoryCode, // 库品编码
          //goodsBarCode: baseInfo.goodsBarCode, // 商品条形码
          //inventoryBarCode: baseInfo.inventoryBarCode, // 库品条形码
          //goodsDescribe: baseInfo.goodsDescribe, // 商品描述
          goodsShareDescribe: baseInfo.goodsShareDescribe, // 商品分享描述
          transportId: baseInfo.transportId,
          weight: baseInfo.weight,
          sharePictureUrl: this.shareImgs.length ? this.shareImgs[0].response : "",
          shareMomentsPictureUrl: this.shareMomentsImgs.length ? this.shareMomentsImgs[0].response : "",
          actualSale: baseInfo.actualSale,
          virtualSale: baseInfo.virtualSale,
          availableNum: baseInfo.availableNum
        },
        brandId: baseInfo.brandId, // 品牌id
        initPrice: baseInfo.retailPrice, // 零售价
        markingPrice: baseInfo.oldPrice, // 划线价
        basePrice: baseInfo.floorPrice, // 底价
        pictureUrl: this.thumbnailImgs.length ? this.thumbnailImgs[0].response : "",
        state: 1, // 状态	1:生效，0：失效，必填
        effectiveTime: _effectiveTime, // 生效时间
        failureTime: _failureTime, // 下架时间
        changeLog: ""
      };
      if (this.$route.params.id) {
        PimGoods.goodsId = this.$route.params.id;
      }
      // console.log(PimGoods);
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
      });
      PimGoodsService.saveGoods(PimGoods)
          .then((res) => {
            loading.close();
            this.$router.push("/pim/productList");
            eventBus.$emit("refresh");
          })
          .catch(() => {
            loading.close();
          });
    },
    compare(property) {
      return function (obj1, obj2) {
        var value1 = obj1[property];
        var value2 = obj2[property];
        return value1 - value2; // 升序
      };
    },

    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, '');
      e.target.value = e.target.value.replace(/\.{2,}/g, '.');
      e.target.value = e.target.value.replace(/^\./g, '0.');
      e.target.value = e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0, e.target.value.length - 1));
      e.target.value = e.target.value.replace(/^0[^\.]+/g, '0')
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
      this.input = e.target.value
      console.log(this.input)
    },

    blurText(e) {
      let boolean = new RegExp("^[1-9][0-9]*$").test(e.target.value)
      if (!boolean) {
        if (e.target.value.length == 1) {
          e.target.value = '';
        } else {
          e.target.value = e.target.value.substr(0, e.target.value.length - 1)
        }
      }
    },
    checkReapt(param) {
      let params = {
        goodsId: this.$route.params.id,
        goodsCode: param
      }
      //console.log(params)
      if (params.goodsCode != '') {
        PimGoodsService.queryGoodsCodeIsReapt(params).then(data => {
          //console.log(data);
          if (data.code == 200) {
            this.$alert('商品编号不允许重复,请重新输入', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'warning',
                  message: '商品编号不允许重复，请重新输入'
                })
              }
            })
          } else {
            this.$message({
              type: 'success',
              message: '商品编号可以使用'
            })
          }
        });
      }
    },
    //输入正整数的方法
    // proving() {
    //     this.formData.bankName = this.formData.bankName.replace(/[^\.\d]/g,'');
    //     this.formData.bankName = this.formData.bankName.replace('.','');
    // },

    // 继续填写
    addGoOn() {
      let info = this.$store.state.productAddInfo;
      let hasInfo = false;
      for (let k in info) {
        if (info[k]) {
          hasInfo = true;
          break;
        }
      }
      if (!hasInfo) {
        if (this.$route.params.id) {
          return this.getGoodsDetail();
        }
        return ''
      }
      this.productInfo = info;
      this.selectedBrand = info.selectedBrand;
      this.selectedCategory = info.selectedCategory;
    },
    selectTransportTemplate(params) {
      this.productInfo.transportId = params.value;
    },
    selectCategory(params) {
      this.productInfo.categoryId =
          params.selectedThirdCategory.id ||
          params.selectedSecondCategory.id ||
          params.selectedFirstCategory.id;
      this.selectedCategory = params;
    },
    selectBrand(params) {
      this.selectedBrand = params;
      this.productInfo.brandId = params.brandId;
      this.productInfo.brandName = params.brandName;
    },
    getTransportTemplateList() {
      PimTransportService.getAllList().then(data => {
        this.transportTemplateList = data.map(e => {
          e.id = e.transportId;
          e.name = e.transportDescribe;
          return e;
        });
      });
    },
    selectGoodsTypeList(params) {
      this.productInfo.goodsType = params.value;
    },
    selectGoodsStateList(params) {
      this.productInfo.goodsState = params.value;
    },
    //=======picture=========
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/components/productAdd.scss";

.el-inline-radio /deep/ .el-radio {
  display: inline-block;
}

.product-add .is-in-pagination .el-input__inner {
  width: 60px;
}
</style>