<template>
  <el-dialog title="选择品牌" :visible.sync="dialogVisible" width="500px">
    <div class="select-brand-content">
      <div class="input-search">
        <el-input v-model="selectedBrand.brandName"></el-input>
        <el-button type="primary" @click="queryBrandList">搜索</el-button>
      </div>
      <div class="search-result">
        <div
          class="search-result-item"
          @click="selectBrand(item)"
          v-for="(item, index) in brandSearchResult"
          :key="index"
        >{{item.brandName}}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">下一步</el-button>
    </span>
  </el-dialog>
</template>

<script>
import PimBrandService from "API/pim/PimBrandService";
import SearchConfig from "COMJS/query/SearchConfig";

export default {
  data() {
    return {
      dialogVisible: false,
      brandSearchResult: [],
      selectedBrand: {
        brandId: "",
        brandName: ""
      }
    };
  },
  computed: {
    brandSearchConfig() {
      return SearchConfig.createNew({
        searchForm: {
          brandName: this.selectedBrand.brandName
        }
      });
    }
  },
  mounted() {
    this.queryBrandList();
  },
  methods: {
    submit() {
      this.$emit("submit", this.selectedBrand);
      this.dialogVisible = false;
    },
    selectBrand(item) {
      this.selectedBrand = item;
    },
    // 查询品牌
    queryBrandList() {
      PimBrandService.queryBrandPages(null, this.brandSearchConfig).then(
        data => {
          this.brandSearchResult = data.list.map(e => {
            return {
              brandId: e.brandId,
              brandName: e.brandName
            };
          });
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.select-brand-content {
  .input-search {
    padding: 20px;
    border-bottom: 1px solid $borderColor;
    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }
  .search-result {
    .search-result-item {
      cursor: pointer;
      line-height: 50px;
      padding: 0 20px;
      color: #666;
      border-bottom: 1px solid $borderColor;
      &:hover {
        background-color: rgba(51, 51, 51, 0.1);
      }
    }
  }
}
</style>