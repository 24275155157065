<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="addMember"
          >新增会员</el-button
        >
        <span class="search-wrapper">
          <el-input
            size="mini"
            v-model="searchInfo.memberName"
            placeholder="请输入会员名称搜索"
          ></el-input>
          <el-button
            class="search-btn"
            size="mini"
            type="primary"
            @click="searchData"
            >搜索</el-button
          >
        </span>
      </div>
    </div>
    <div class="invited-user-table box-shadow-unify">
      <div class="tabs-wrapper">
        <el-tabs v-model="activeName" type="card" @tab-click="changeTable">
          <el-tab-pane label="全部" name="1"></el-tab-pane>
          <el-tab-pane label="未使用" name="2"></el-tab-pane>
          <el-tab-pane label="审核拒绝" name="3"></el-tab-pane>
          <el-tab-pane label="已使用" name="4"></el-tab-pane>
          <el-tab-pane label="审核中" name="5"></el-tab-pane>
          <el-tab-pane label="已过期" name="6"></el-tab-pane>
        </el-tabs>
      </div>
      <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :isShadow="false"
      >
        <el-table-column prop="memberName" label="姓名"></el-table-column>
        <el-table-column prop="memberTel" label="电话"></el-table-column>
        <el-table-column prop="auditState" label="审核状态">
          <template slot-scope="scope">
            {{
              scope.row.auditState == 0
                ? "审核拒绝"
                : scope.row.auditState == 1
                ? "审核通过"
                : "审核中"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="used" label="是否已使用">
          <template slot-scope="scope">
            {{ scope.row.used == 0 ? "未使用" : "已使用" }}
          </template>
        </el-table-column>
        <el-table-column prop="expiryTime" label="创建时间">
          <template slot-scope="scope">
            {{
              scope.row.auditState == 1
                ? format(new Date(scope.row.expiryTime))
                : "无"
            }}
          </template>
        </el-table-column>
        <!--width=120-->
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a
                href="javascript: void(0);"
                title="下载"
                @click="dowloadShareQrcode(scope.row)"
              >
                <i class="icon-download"></i>
              </a>
              <a
                href="javascript: void(0);"
                title="查看"
                @click="showDetail(scope.row.inviteId)"
              >
                <i class="CCSFont icon-table_2"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </ws-page-table>
      <invite-add-popup
        ref="inviteAddPopup"
        @updateTable="loadTableData"
      ></invite-add-popup>
      <invite-detail-popup
        ref="inviteDetailPopup"
        :inviteId="currentId"
      ></invite-detail-popup>
    </div>
  </div>
</template>
<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import drawShareImg from "ASSETS/js/qrcodeShareDownload";
import BimInviteService from "API/bim/BimInviteService";
import inviteAddPopup from "./inviteAddPopup.vue";
import inviteDetailPopup from "./inviteDetailPopup.vue";

import tools from "COMJS/tools";

export default {
  data() {
    return {
      searchInfo: {
        memberName: "",
      },
      items: {
        used: {
          filter: "EQ",
        },
        expiryTime: {
          filter: "LT",
        },
      },
      activeName: 1,
      qrcodeSrc: "",
      result: "",
      NeverAudit: true,
      currentId: "",
    };
  },
  mixins: [pageTableMixin],
  components: {
    inviteAddPopup,
    inviteDetailPopup,
  },
  mounted() {}, //加载页面数据的方法
  // watch: {
  //     'activeName':function(val) { //监听切换状态-计划单
  //         let urlStr = '/index/test?tabid=' + val;
  //         this.$router.push(urlStr);
  //         // 注释，根据val的不同，跳转到不同
  //     },
  // },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      BimInviteService.queryInvitePages(this.pageInfo, this.searchConfig).then(
        (data) => {
          this.querySuccess(data);
        }
      );
    },
    changeTable() {
      this.clearPage();
      if (this.activeName == "1") {
        this.searchInfo = {};
      } else if (this.activeName == "2") {
        this.searchInfo.used = "0";
      } else if (this.activeName == "3") {
        this.searchInfo.auditState = "0";
      } else if (this.activeName == "4") {
        this.searchInfo.used = "1";
      } else if (this.activeName == "5") {
        this.searchInfo.auditState = "2";
      } else if (this.activeName == "6") {
        //已使用 + 截止有效期<当前系统时间
        this.searchInfo.used = "0";
        this.searchInfo.expiryTime = tools.formatDate(
          new Date(),
          "yyyyMMddhhmmss"
        );
      }
      this.loadTableData();
      this.searchInfo = {};
    },
    format(date) {
      return tools.formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    addMember() {
      this.$refs.inviteAddPopup.dialogFormVisible = true;
    },
    dowloadShareQrcode(row) {
      let shareInfoId = row.shareInfoId;
      BimInviteService.getShareCodeImg(shareInfoId).then((data) => {
        if (data) {
          this.qrcodeSrc = "data:image/jpg;base64," + data;
          if (row.auditState == "1" && row.used == "0") {
            drawShareImg(this.qrcodeSrc);
            this.$message({
              message: "操作成功",
              type: "success",
            });
          } else {
            return this.$message({
              message: "只有审核通过且未使用的数据才允许下载~",
              type: "warning",
            });
          }
        }
      });
    },
    showDetail(id) {
      this.currentId = id;
      this.$refs.inviteDetailPopup.dialogVisible = true;
    },
    audit(row, flag) {
      if (flag) {
        this.result = "success";
        let param = {
          inviteId: row.inviteId,
          result: this.result,
        };
        BimInviteService.audit(param).then((data) => {
          if (data.code === "200") {
            this.$message({ type: "success", message: "操作成功" });
          }
          this.loadTableData();
        });
      } else {
        this.result = "fail";
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          let param = {
            inviteId: row.inviteId,
            result: this.result,
            refuseReason: value,
          };
          if (param.refuseReason === null || param.refuseReason == "") {
            return this.$message({
              message: "请填写拒绝原因",
              type: "warning",
            });
          }
          BimInviteService.audit(param).then((data) => {
            if (data.code === "200") {
              this.$message({ type: "success", message: "操作成功" });
            }
            this.loadTableData();
          });
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.invited-user-table {
  .tabs-wrapper {
    padding: 10px 20px;
  }
}
.icon-download {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("~ASSETS/images/download.png");
  background-size: cover;
}
</style>