<template>
  <div class="account-detail">

    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">账户信息</div>
      <div class="detail-form">
        <div class="form-item">
          <span class="form-label">会员姓名：</span>
          <span class="form-value">{{distributorData.name}}</span>
        </div>
        <div class="form-item">
          <span class="form-label">会员账号：</span>
          <span class="form-value">{{distributorData.account}}</span>
        </div>
        <div class="form-item">
          <span class="form-label">总计充值金额：</span>
          <span class="form-value">{{distributorData.cumulativePoints}}</span>
        </div>
        <div class="form-item">
          <span class="form-label">剩余余额：</span>
          <span class="form-value">{{distributorData.point}}</span>
          <el-button type="text" @click="showModifyDialog('points')">修改</el-button>
        </div>
        <div class="form-item">
          <span class="form-label">可提现余额：</span>
          <span class="form-value">{{distributorData.bond}}</span>
          <el-button type="text" @click="showModifyDialog('bond')">修改</el-button>
        </div>
        <div class="form-item">
          <span class="form-label">已提现余额：</span>
          <span class="form-value">{{distributorData.historicalWithdrawBond}}</span>
        </div>
      </div>
    </div>

    <div class="detail-item box-shadow-unify">
      <div class="detail-item_title">修改记录</div>
      <ws-page-table
              :data="tableData"
              ref="pageTable"
              :pageable="true"
              :pageInfo="pageInfo"
              @pageChange="loadTableData"
              :showIndex="true"
              :isShadow="false"
      >
        <el-table-column prop="createTime" label="修改时间"></el-table-column>
        <el-table-column prop="operatorAccount" label="修改人"></el-table-column>
        <el-table-column prop="changeTarget" label="修改内容"></el-table-column>
        <el-table-column prop="changeType" label="修改类型"></el-table-column>
        <el-table-column prop="changeContent" label="修改金额"></el-table-column>
        <el-table-column prop="remark" label="修改理由"></el-table-column>
      </ws-page-table>
    </div>

    <el-dialog :title="changeData.changeTarget=='points'?'修改剩余余额':'修改可提现余额'" :visible.sync="modifyDialogVisible" width="560px">
      <el-form label-width="80px">
        <el-form-item label="修改类型">
          <el-select v-model="changeData.changeType" placeholder="请选择修改类型">
            <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input

                  v-model="changeData.changeContent"
                  @keyup.native="keyupEvent($event,changeData.changeContent)"
                  style="width: 80px; margin-left: 10px"></el-input>
          <span style=" margin-left: 3px">元</span>
        </el-form-item>
        <el-form-item label="修改原因">
          <el-input v-model="changeData.remark" style="width: 345px;"></el-input>
        </el-form-item>
      </el-form>
      <div style="color: red;padding: 0 0 0 20px;">{{tipText}}</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="pointModifyCommit">确 认</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import tools from "COMJS/tools";
import distributionLogService from "API/dim/DimDistributionLogService.js";
import BimMemberService from "API/bim/BimMemberService";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import userModule from "@/module/userModule";

export default {
    name: "memberAccountModify",
    data() {
        return {
            changeTypeList:[
                {
                    value: "add",
                    label: "增加"
                },
                {
                    value: "reduce",
                    label: "减少"
                }
            ],
            searchInfo: {
              distributorId: ""
            },
            distributorData: {
                name: "",
                account: "",
                cumulativePoints: 0,
                point: 0,
                bond: 0,
                historicalWithdrawBond: 0
            },
            userId:"",
            modifyDialogVisible:false,
            changeData:{
                changeTarget:"",
                changeType:"",
                changeContent:"",
                remark:""
            }
            //tipText:""
        };
    },
    mixins: [pageTableMixin],
    mounted() {
        this.loadDistributorData();
    },
    computed: {
        tipText:function() {
            var tips = '';
            if(this.changeData.changeTarget=='points'){
                if(this.changeData.changeType=='add'){
                    tips = "提示：增加剩余余额，总计充值金额会相应增加。操作保留两位小数。";
                }else if(this.changeData.changeType=='reduce'){
                    tips = "提示：减少剩余余额，总计充值金额会相应减少。操作保留两位小数。";
                }
            }else if(this.changeData.changeTarget=='bond'){
                if(this.changeData.changeType=='add'){
                    tips = "提示：增加可提现余额，剩余余额会相应减少。操作保留两位小数。";
                }else if(this.changeData.changeType=='reduce'){
                    tips = "提示：减少可提现余额，剩余余额会相应增加。操作保留两位小数。";
                }
            }
            return tips;
        }
    },
    methods: {
        loadTableData() {
            this.userId = this.$route.params.id;
            this.$refs.pageTable.tableLoading = true;
            this.searchInfo.distributorId = this.userId;
            console.log(this.searchInfo)
            distributionLogService.queryPageBySearchFilter(this.pageInfo, this.searchConfig).then(data => {
                data.list = data.list.map(e => {
                    return {
                        createTime: tools.formatDate(
                            new Date(e.createTime),
                            "yyyy-MM-dd hh:mm:ss"
                        ),
                        operatorAccount: e.operatorAccount,
                        changeTarget: e.changeTarget=='bond'?'可提现余额':'剩余余额',
                        changeType: e.changeType=='add'?'增加':'减少',
                        changeContent: e.changeContent,
                        remark: e.remark
                    };
                });
                this.querySuccess(data);
            });
        },
        loadDistributorData(){
            var that = this;
            BimMemberService.queryDetails(this.userId).then(data =>{
                that.distributorData.name = data.fullName? decodeURI(data.fullName):'无'
                that.distributorData.account = data.tel? data.tel:'无';
                that.distributorData.cumulativePoints = data.cumulativePoints?data.cumulativePoints.toFixed(2):"0.00";
                that.distributorData.point =data.point? data.point.toFixed(2):"0.00";
                that.distributorData.bond =data.bond? data.bond.toFixed(2):"0.00";
                that.distributorData.historicalWithdrawBond = data.historicalWithdrawBond? data.historicalWithdrawBond.toFixed(2):"0.00";
            })
        },
        showModifyDialog(target){
            this.changeData = {};
            this.changeData.changeTarget = target;
            this.modifyDialogVisible = true;
        },
        keyupEvent(e,input){
          e.target.value=e.target.value.replace(/[^\d.]/g, '');
          e.target.value=e.target.value.replace(/\.{2,}/g, '.');
          e.target.value=e.target.value.replace(/^\./g, '0.');
          e.target.value=e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0,e.target.value.length-1));
          e.target.value=e.target.value.replace(/^0[^\.]+/g, '0')
          e.target.value=e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
          this.input=e.target.value
          console.log(this.input)
        },
        pointModifyCommit:tools.throttleNew(function(){
            console.log(this.changeData.changeContent)
            if(!this.changeData.changeType){
                this.$message({
                    message: "请选择修改类型",
                    type: "warning",
                });
                return;
            }
            if(!this.changeData.changeContent || this.changeData.changeContent<=0){
                this.$message({
                    message: "请输入正确的修改金额",
                    type: "warning",
                });
                return;
            }
            this.changeData.distributorId=this.userId;
            //this.changeData.operatorId=JSON.parse(userModule.PPUser).userId;
            this.changeData.operatorAccount=JSON.parse(userModule.PPUser).userAccount;
            var that = this;
            distributionLogService.saveModifyLog(this.changeData).then(data => {
                that.loadTableData();
                that.loadDistributorData();
                that.modifyDialogVisible = false;
            }).catch(error => {
                console.log(error);
                that.$message({
                    message: error,
                    type: "warning",
                });
            });
        },1000),
    }
};
</script>

<style lang="scss" scoped>

  .account-detail {
    .detail-item {
      margin-bottom: 40px;
      .detail-item_title {
        line-height: 60px;
        font-weight: bold;
        font-size: 18px;
        padding-left: 20px;
        background-color: #f6f9fc;
      }
      .detail-form {
        font-size: 14px;
        line-height: 20px;
        padding: 30px 20px 20px 0;
        .form-item {
          margin-bottom: 14px;
          &:last-of-type {
            margin-bottom: 0px;
          }
          .form-label {
            display: inline-block;
            text-align: right;
            width: 150px;
          }
          .form-value {
            display: inline-block;
            color: #666;
            width: 100px;
          }
        }
      }
    }
  }

</style>