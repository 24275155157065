<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-button type="primary" @click="gotoCreator">下单</el-button>
          <div class="form-row-right">

          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">记录时间：</span>
            <el-date-picker
                v-model="filterTimes"
                @change="changePickerDate"
                size="mini"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
            <!--
            <el-input size="mini" v-model="searchInfo.customerName" class="address-dialog-input"></el-input>
            -->
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">客户姓名：</span>
            <el-autocomplete
                popper-class="my-autocomplete"
                v-model="searchInfo.customerName"
                :fetch-suggestions="querySearchCustomer"
                placeholder="请输入内容"
                @select="handleSelectCustomer">
              <i class="el-icon-search el-input__icon" slot="suffix"></i>
              <template slot-scope="{ item }">
                <div class="name">{{ item.customerName }}</div>
                <span class="addr">{{ item.address }}</span>
              </template>
            </el-autocomplete>
          </div>
          <div class="form-item">
            <span class="form-label">收件姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">收件电话：</span>
            <el-input size="mini" v-model="searchInfo.recipientPhoneNum" class="address-dialog-input"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">商品批次：</span>
            <el-input size="mini" v-model="searchInfo.milkBatchCode" class="address-dialog-input">
              <template slot="prepend">D.B.</template>
            </el-input>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
      <div class="filter_right">
      </div>
    </div>
    <div class="operate-bar">
      <div class="operate-bar-right">
        <el-button size="mini" type="default" @click="exportExcel">导出EXCEL</el-button>
        <el-button size="mini" type="danger" @click="deleteOrderList">删除</el-button>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="false"
        :selection="'multi'"
    >
      <el-table-column prop="freshMilkOrderCode" label="订单编号" width="180"></el-table-column>
      <el-table-column prop="customerName" label="客户" width="100"></el-table-column>
      <el-table-column prop="customerWorkUnit" label="单位" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="收件信息" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{ scope.row.recipientName }} {{ scope.row.recipientPhoneNum }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddress }}
          </div>
          <!--
          <div style="line-height: 18px;">
            {{ scope.row.recipientProvince }} {{ scope.row.recipientCity }} {{ scope.row.recipientCounty }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddressDetail }}
          </div>
          -->
        </template>
      </el-table-column>
      <el-table-column label="商品内容" minWidth="150">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            鲜奶两瓶装 {{ scope.row.milkCount }} 份
          </div>
          <div style="line-height: 18px;" v-if="scope.row.giftBoxCount>0">
            礼盒 {{ scope.row.giftBoxCount }} 份
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="milkBatchCode" label="批次" minWidth="110"></el-table-column>
      <el-table-column prop="recordDate" label="记录时间" width="110" :formatter="dateFormatter4Table"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="icon-operation">
            <!--
            <a href="javascript: void(0);" title="再来一单" @click="copyMilkOrder(scope.row)">
              <i class="el-icon-document-add"></i>
            </a>
            -->
            <a href="javascript: void(0);" title="删除">
              <i class="el-icon-delete-solid" @click="deleteOrder(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import areaCascader from "COMPS/base/areaCascader";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import milkOrderService from "API/fm/MilkOrderService";
import customerService from "API/usr/CustomerService";
import IoProgressService from "API/IoProgressService";
import tools from "COMJS/tools";

export default {
  name: "CustomerOrderManager",
  mixins: [pageTableMixin],
  components: {
    areaCascader
  },
  data() {
    return {
      filterTimes: [],
      searchInfo: {
        milkOrderSource:'customer'
      },
      pageInfo: {
        pageSize: 10,
      },
      allCustomerList: [],
    }
  },
  mounted() {
    this.listAllCustomer()
  },
  methods: {
    loadTableData() {
      if (!this.searchInfo.customerName) {
        this.searchInfo.customerId = ''
      }
      let param = {
        ...this.searchInfo,
        milkBatchCode:(this.searchInfo.milkBatchCode && this.searchInfo.milkBatchCode.length>0)
            ?('D.B.'+this.searchInfo.milkBatchCode):'',
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
      }
      milkOrderService.pageMilkOrder(param).then(res => {
        this.querySuccess(res);
      })
    },

    exportExcel() {
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录导出！', '系统提示');
        return;
      }
      milkOrderService.exportExcelCheckRows(rows).then(res => {
        let fileName = res.fileName;
        let module = res.module;
        IoProgressService.download(module, fileName, false);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.recordDateBegin = "";
        this.searchInfo.recordDateEnd = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.recordDateBegin = tools.formatDate(
            new Date(e[0]),
            "yyyy-MM-dd"
        );
        this.searchInfo.recordDateEnd = tools.formatDate(
            new Date(e[1]),
            "yyyy-MM-dd"
        );
      }
    },
    //搜索客户
    listAllCustomer() {
      customerService.queryCustomerList({}).then(res => {
        this.allCustomerList = res;
      })
    },
    querySearchCustomer(queryString, cb) {
      let customerList = this.allCustomerList;
      let results = queryString ? customerList.filter(this.createFilterCustomer(queryString)) : customerList;
      if (results.length == 0) {
        this.searchInfo.customerId = "destory";
      }
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilterCustomer(queryString) {
      return (item) => {
        return (item.customerName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelectCustomer(item) {
      this.searchInfo.customerName = item.customerName;
      this.searchInfo.customerId = item.customerId;
    },
    gotoCreator() {
      this.$router.push("/fm/customerOrderCreator");
    },
    copyMilkOrder(row) {
      let param = {
        freshMilkOrderId: row.freshMilkOrderId
      }
      this.$confirm(`确定再下一单?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        milkOrderService.createCustomerOrderAgain(param).then((data) => {
          this.$message({type: "success", message: "下单成功"});
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    deleteOrderList(){
      let rows = this.$refs.pageTable.getCheckedData();
      if (rows.length < 1) {
        this.$alert('请选择记录！', '系统提示');
        return;
      }
      let ids = rows.map(e=>e.freshMilkOrderId)
      let param = {
        freshMilkOrderIdList: ids
      }
      this.$confirm(`确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        milkOrderService.delMilkOrder(param).then((data) => {
          this.$message({type: "success", message: "删除成功"});
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    deleteOrder(row) {
      let param = {
        freshMilkOrderId: row.freshMilkOrderId
      }
      this.$confirm(`确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        milkOrderService.delMilkOrder(param).then((data) => {
          this.$message({type: "success", message: "删除成功"});
          this.loadTableData();
        });
      }).catch(() => {
      });
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>
.address-dialog-frame {
  border: 1px solid #d3d3d3;
  padding: 20px;
  margin-bottom: 20px;
  /*background-color: #d3d6da;*/
}

.mt10 {
  margin-top: 10px;
}

.address-dialog-input {
  width: 200px !important;
  margin-right: 15px;
}

.address-dialog-input-long {
  width: 630px !important;
}

.border-dashed {
  border-bottom: 1px dashed #3d3d3d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tag-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
}

.tag-row .input-new-tag {
  width: 100px;
}
</style>