import axiosHttp from "../axiosHttp";

export default {
    // 订单导出分页查询
    queryRecordPages(params) {
        return axiosHttp.POST('/somExportRecord/getPageList', params, false);
    },
    // 获取订单详情
    getOrderDetail(orderId) {
        return axiosHttp.GET(`/somOrder/queryDetails/${orderId}`);
    },

    createExportRecord(param) {
        return axiosHttp.POST(`/somExportRecord/query/${orderId}`);
    }
}