<template>
    <div id="RecommendList">
        <div class="Add">
            <el-button type="primary" @click="goRecommendEdit">新建推荐</el-button>
        </div>
        <div class="content">
            <ws-page-table style="width:100%" height="600" :data="tableData" :pageInfo="pageInfo" ref="pageTable" @pageChange="loadTableData" :pageable="true">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div class="expand">
                            <ws-page-table style="width:100%" :data="props.row.childGoodsList">
                                <el-table-column label="" :width="65"></el-table-column>
                                <el-table-column label="推荐商品" prop="goodsName"></el-table-column>
                                <el-table-column label="顺序" prop="dispNo"></el-table-column>
                                <el-table-column label="排序">
                                    <template slot-scope="scope">
                                        <a href="javascript: void(0);" @click.stop="sortUp(props.$index,scope.$index)" v-show="scope.$index!=0" title="向上">
                                            <i class="el-icon-upload2 iconT"></i>
                                        </a>
                                        <a href="javascript: void(0);" @click.stop="sortDown(props.$index,scope.$index)" v-show="tableData[props.$index].childGoodsList.length-1!=scope.$index"
                                            title="向下">
                                            <i class="el-icon-download iconT"></i>
                                        </a>
                                    </template>
                                </el-table-column>
                            </ws-page-table>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="" :width="20"></el-table-column>
                <el-table-column label="商品名称" prop="goodsName" :width="1035"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="icon-operation">
                            <a href="javascript: void(0);" @click="goedit(scope.row,0)" title="编辑">
                                <i class="CCSFont icon-table_1"></i>
                            </a>
                            <a href="javascript: void(0);" @click="goedit(scope.row,1)" title="查看">
                                <i class="CCSFont icon-table_2"></i>
                            </a>
                            <a href="javascript: void(0);" @click="goDelete(scope.row.goodsId)" title="删除">
                                <i class="el-icon-delete-solid"></i>
                            </a>
                        </div>
                    </template>
                </el-table-column>
            </ws-page-table>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import {
    saveBatchRelation,
    queryInfoAtPage,
    delByParentGoodsIds,
} from "@/api/pim/PimRecommend.js";
export default {
    data() {
        return {
            tableData: [],
        };
    },
    mixins: [pageTableMixin],
    methods: {
        loadTableData() {
            queryInfoAtPage(this.pageInfo).then((data) => {
                console.log(data);
                this.tableData = data.list;
                this.querySuccess(data);
            });
        },
        sortUp(propsIndex, index) {
            let temp = this.tableData[propsIndex].childGoodsList[index - 1];
            Vue.set(
                this.tableData[propsIndex].childGoodsList,
                index - 1,
                this.tableData[propsIndex].childGoodsList[index]
            );
            Vue.set(this.tableData[propsIndex].childGoodsList, index, temp);
            this.dispNo(propsIndex);
            this.sort(propsIndex);
        },
        sortDown(propsIndex, index) {
            let temp = this.tableData[propsIndex].childGoodsList[index + 1];
            Vue.set(
                this.tableData[propsIndex].childGoodsList,
                index + 1,
                this.tableData[propsIndex].childGoodsList[index]
            );
            Vue.set(this.tableData[propsIndex].childGoodsList, index, temp);
            this.dispNo(propsIndex);
            this.sort(propsIndex);
        },
        dispNo(propsIndex) {
            let childGoodsList = this.tableData[propsIndex].childGoodsList;
            for (let i in childGoodsList) {
                childGoodsList[i].dispNo = ++i;
            }
        },
        sort(propsIndex) {
            let arr = [];
            for (let i in this.tableData[propsIndex].childGoodsList) {
                let params = {
                    goodsRelationId: "",
                    parentGoodsId: this.tableData[propsIndex].goodsId,
                    childGoodsId:
                        this.tableData[propsIndex].childGoodsList[i].goodsId,
                    dispNo: this.tableData[propsIndex].childGoodsList[i].dispNo,
                };
                arr.push(params);
            }
            saveBatchRelation(arr).then((data) => {
                this.$message({ type: "success", message: "操作成功" });
            });
        },
        goRecommendEdit() {
            this.$router.push({
                path: "RecommendEdit",
            });
        },
        goedit(row, state) {
            if (state == 0) {
                this.$router.push({
                    path: "RecommendEdit",
                    query: {
                        content: row,
                    },
                });
            } else {
                this.$router.push({
                    path: "RecommendEdit",
                    query: {
                        content: row,
                        isSee: true,
                    },
                });
            }
        },
        goDelete(goodsId) {
            this.$confirm(`是否删除?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                delByParentGoodsIds(goodsId).then((data) => {
                    this.$message({ type: "success", message: "操作成功" });
                    this.loadTableData();
                });
            });
        },
    },
};
</script>

<style scoped>
.Add {
    width: 97%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 48px;
    background: rgba(255, 255, 255, 1);
}
.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background: red; */
    margin-top: 24px;
}
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
#RecommendList >>> .el-table__expanded-cell {
    padding: 0;
}
#RecommendList >>> .el-table__body tr td:first-of-type > div {
    padding: 0;
}
#RecommendList >>> .content .el-table th {
    background-color: rgba(250, 250, 250, 1) !important;
}
#RecommendList >>> .expand .el-table th {
    background-color: white !important;
}
/* #RecommendList>>>.el-table th{
    background-color: red !important;
} */
.iconT {
    color: #468dea;
    font-size: 20px;
    margin-right: 10px;
}
.icon-operation > a {
    margin-left: 10px;
}
</style>