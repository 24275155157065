<template>
  <div class="product-category">
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="showAdd">添加分类</el-button>
        <div class="search-wrapper">
          <input-search
            :resultList="searchResultList"
            @input="searchChange"
            @click="searchChange"
            @selectResult="selectResult"
          ></input-search>
          <el-button class="search-btn" size="mini" type="primary" @click="searchResult">搜索</el-button>
        </div>
      </div>
      <div class="filter_right">
        <el-button size="mini" type="primary" @click="$router.push({path:'/ba/systemLog',query:{logType:'category'}})">操作日志</el-button>
      </div>
    </div>
    <level-tree
      ref="levelTree"
      :tree="firstCategoryData"
      firstTreeName="一级分类"
      secondTreeName="二级分类"
      thirdTreeName="三级分类"
      :canEdit="true"
      :canSee="true"
      :canDelete="true"
      @seeDetail="showDetail"
      @edit="showEditCategory"
      @deleteNode="deleteCategory"
      @changeNode="getTreeNode"
    ></level-tree>
    <category-add-popup
      ref="categoryAddPopup"
      :categoryId="currentId"
      @updateCategory="refreshCategory()"
    ></category-add-popup>
    <category-detail-popup ref="categoryDetailPopup" :categoryId="currentId"></category-detail-popup>
  </div>
</template>

<script>
import categoryAddPopup from "./categoryAddPopup.vue";
import categoryDetailPopup from "./categoryDetailPopup.vue";
import PimCategoryService from "API/pim/PimCategoryService.js";
import tools from "COMJS/tools";
import levelTree from "COMPS/widgets/levelTree";
import inputSearch from "COMPS/base/inputSearch";

export default {
  name: "productCategoryList",
  data() {
    return {
      currentNode: {},
      searchResultList: [],
      firstCategoryData: [],
      currentId: "", // 传递给popup的categoryId
      queryInfo: {
        categoryType: 1,
        categoryName: ""
      }
    };
  },
  components: {
    categoryAddPopup,
    categoryDetailPopup,
    levelTree,
    inputSearch
  },
  mounted() {
    this.getAllCategory();
  },
  methods: {
    searchChange(searchValue) {
      this.queryInfo.categoryName = searchValue;
      this.searchResult();
    },
    searchResult() {
      if (!this.queryInfo.categoryName) {
        return;
      }
      PimCategoryService.queryCategory(this.queryInfo).then(data => {
        this.searchResultList = this.trnasCategorySearchResultList(data);
      });
    },
    // 转换搜索结果字段
    trnasCategorySearchResultList(list) {
      let data = [];
      list.forEach(firstNode => {
        if (firstNode.childs.length > 0) {
          firstNode.childs.forEach(secondNode => {
            if (secondNode.childs.length > 0) {
              secondNode.childs.forEach(thirdNode => {
                data.push({
                  firstId: firstNode.nodeId,
                  secondId: secondNode.nodeId,
                  thirdId: thirdNode.nodeId,
                  firstName: firstNode.nodeName,
                  secondName: secondNode.nodeName,
                  thirdName: thirdNode.nodeName,
                  name: `${firstNode.nodeName} > ${secondNode.nodeName} > ${thirdNode.nodeName}`,
                  nodeName: `${firstNode.nodeName} > ${secondNode.nodeName} > ${thirdNode.nodeName}`
                });
              });
            } else {
              data.push({
                firstId: firstNode.nodeId,
                secondId: secondNode.nodeId,
                thirdId: "",
                firstName: firstNode.nodeName,
                secondName: secondNode.nodeName,
                thirdName: "",
                name: `${firstNode.nodeName} > ${secondNode.nodeName}`,
                nodeName: `${firstNode.nodeName} > ${secondNode.nodeName}`
              });
            }
          });
        } else {
          data.push({
            firstId: firstNode.nodeId,
            secondId: "",
            thirdId: "",
            firstName: firstNode.nodeName,
            secondName: "",
            thirdName: "",
            name: firstNode.nodeName,
            nodeName: firstNode.nodeName
          });
        }
      });
      return data;
    },
    selectResult(item) {
      this.$refs["levelTree"].positioningResult(item);
      this.searchResultList = [];
    },
    refreshCategory() {
      this.$refs["levelTree"].clearData();
      this.currentId="";
      this.getAllCategory();
    },
    getAllCategory() {
      PimCategoryService.getAllCategoryTree().then(data => {
        this.firstCategoryData = data.map(e => {
          e.id = e.nodeId;
          e.name = e.nodeName;
          return e;
        });
      });
    },
    getTreeNode(node) {
      //console.log(node)
      this.currentNode = node;
    },
    deleteCategory(item) {
      this.$confirm(`是否删除"${item.name}"分类?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          PimCategoryService.deleteCategory(item.id).then(() => {
            this.refreshCategory();
          });
        })
        .catch(() => {});
    },
    showAdd() {
      this.currentId = "";
      this.$refs.categoryAddPopup.dialogFormVisible = true;
    },
    showDetail(info) {
      this.currentId = info.id;
      this.$refs.categoryDetailPopup.dialogVisible = true;
    },
    showEditCategory(info) {
      this.currentId = info.id;
      this.$refs.categoryAddPopup.dialogFormVisible = true;
    }
  }
};
</script>
