import axiosHttp from "../axiosHttp"

export default {
  //bimUserMail/countUserNoReadByType
  countUserNoReadByType() {
    return axiosHttp.POST('/bimUserMail/countUserNoReadByType')
  },
  //bimUserMail/queryUserMainAtPage 查询消息通知列表
  queryUserMainAtPage(params) {
    return axiosHttp.POST('/bimUserMail/queryUserMainAtPage',params,false)
  },
  //bimUserMail/markAllAsRead
  markAllAsRead(params) {
    return axiosHttp.POST('/bimUserMail/markAllAsRead',params,false)
  },
}