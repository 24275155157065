<template>
  <div id="staffDetail">
    <el-descriptions title="人员信息" :column="4">
      <el-descriptions-item label="类型">{{ staffData.staffCategory }}</el-descriptions-item>
      <el-descriptions-item label="用户名">{{ staffData.staffName }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{ staffData.staffTel }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{ staffData.staffNote }}</el-descriptions-item>
    </el-descriptions>
    <el-divider><i class="el-icon-box"> 地址</i></el-divider>
    <div class="address-list-div">
      <el-table :data="staffAddressList" size="mini">
        <el-table-column prop="recipientName" label="姓名"></el-table-column>
        <el-table-column prop="recipientPhoneNum" label="手机"></el-table-column>
        <el-table-column label="地址" width="600px;" :show-overflow-tooltip="true">
          <template slot-scope="scope" class="address-span">
            <span>{{ scope.row.recipientProvince }}</span>
            <span>{{ scope.row.recipientCity }}</span>
            <span>{{ scope.row.recipientCounty }}</span>
            <span>{{ scope.row.recipientAddressDetail }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最近发货地址">
          <template slot-scope="scope">
            <div v-if="scope.row.lastOrderFlag=='1'">
              <i class="el-icon-check" style="font-weight: bold;"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="编辑"
                 @click="addressEdit(scope.row)">
                <i class="el-icon-edit-outline">编辑</i>
              </a>
              <a style="margin-left: 20px;"
                 href="javascript: void(0);" title="删除"
                 @click="addressDelete(scope.row)">
                <i class="el-icon-delete">删除</i>
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" @click="addressEdit(null)">新增地址</el-button>
    </div>

    <div class="sample-list-div">
      <el-divider><i class="el-icon-present"> 礼单</i></el-divider>
      <el-table :data="staffSampleList" style="width: 100%">
        <el-table-column prop="createTime" label="创建时间" :formatter="dateFormatter4Table"></el-table-column>
        <el-table-column label="礼品内容">
          <template slot-scope="scope">
            <el-tag
                style="margin:5px;"
                v-for="(item,idx) in scope.row.sampleGoodsList" :key="idx">
              {{ item.goodsCode }} * {{ item.num }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收件信息" width="400px;">
          <template slot-scope="scope" class="address-span">
            <p>{{ scope.row.recipientName }}</p>
            <p>{{ scope.row.recipientPhoneNum }}</p>
            <span>{{ scope.row.recipientProvince }}</span>
            <span>{{ scope.row.recipientCity }}</span>
            <span>{{ scope.row.recipientCounty }}</span>
            <span>{{ scope.row.recipientAddressDetail }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderCreateTime" label="下单时间" :formatter="dateFormatter4Table"></el-table-column>
        <el-table-column label="订单编号">
          <template slot-scope="scope">
            <div v-if="scope.row.sampleAuditState=='1'">{{ scope.row.orderCode }}</div>
            <div v-if="scope.row.sampleAuditState=='9'">
              <el-popover
                  :ref="`node-popover-${scope.$index}`"
                  placement="top-start"
                  title="说明"
                  width="200"
                  trigger="hover"
                  :content="scope.row.sampleAuditRemark">
                <i slot="reference" class="el-icon-warning-outline" style="color: red;font-size: 24px;"></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="expressInfo" label="快递信息"></el-table-column>
        <el-table-column label="操作" width="200px;">
          <template slot-scope="scope">
            <div class="icon-operation">
              <a href="javascript: void(0);" title="查看"
                 @click="giftOrderShow(scope.row)">
                <i class="el-icon-view">查看</i>
              </a>
              <a style="margin-left: 20px;"
                 href="javascript: void(0);" title="编辑"
                 @click="giftOrderEdit(scope.row)">
                <i class="el-icon-edit-outline">编辑</i>
              </a>
              <a style="margin-left: 20px;"
                 href="javascript: void(0);" title="删除"
                 @click="giftOrderDelete(scope.row)">
                <i class="el-icon-delete">删除</i>
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="staffSampleList!=null && staffSampleList.length>0" class="sample-tip">
        * 礼单创建后，将发送短信给客户确认地址，有两天的修改期限，两天过后自动生成订单，无需人工操作。
      </div>
    </div>

    <el-dialog title="收件信息" :visible.sync="addressEditDialogVisible" width="800px">
      <el-form label-width="100px">
        <el-form-item label="收件姓名" required>
          <el-input v-model="addressData.recipientName" placeholder="收件姓名"></el-input>
        </el-form-item>
        <el-form-item label="收件手机" required>
          <el-input v-model="addressData.recipientPhoneNum"
                    maxlength="11"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    placeholder="收件手机">
            <template slot="prepend">+ 86</template>
          </el-input>
        </el-form-item>
        <el-form-item label="收件地址" required>
          <area-cascader v-model="addressData.pcc"></area-cascader>
          <el-input
              style="margin-top: 10px;width: 582px;"
              v-model="addressData.recipientAddressDetail"
              placeholder="请填写详细地址"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addressEditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addressEditDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑礼单" :visible.sync="giftEditDialogVisible" width="800px">
      <div v-loading="!!giftEditData.orderId"
           element-loading-text="请点击[查看]，前往订单详情页修改地址"
           element-loading-spinner="el-icon-warning-outline">
        <div>
          <p><span style="color: red;">点击</span>选择收件信息</p>
          <el-card :class="isThisAddress(item)?'address-click address-click-selected':'address-click'"
                   v-for="(item,idx) in staffAddressList" :key="idx">
            <div style="display: flex;align-items: center;"
                 @click="giftEditAddressSelect(item)">
              <div>
                <div style="font-size: 14px;padding: 9px 0;">
                  {{ item.recipientName }} {{ item.recipientPhoneNum }}
                </div>
                <div style="font-size: 14px;padding: 9px 0;">
                  {{ item.recipientProvince }} {{ item.recipientCity }}
                  {{ item.recipientCounty }} {{ item.recipientAddressDetail }}
                </div>
              </div>
              <div v-if="isThisAddress(item)"
                   style="margin-left: auto;font-size: 24px;color: #b70c39;">
                <i class="el-icon-location-outline"></i>
              </div>
            </div>
          </el-card>
        </div>
        <div slot="footer" style="margin-top: 20px;text-align: right;">
          <el-button @click="giftEditDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="giftEditDialogConfirm">确 认</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="删除礼单" :visible.sync="giftRemoveDialogVisible" width="800px">
      <div class="delete-dialog-p">
        <p v-if="!giftEditData.orderId">当前尚未生成订单，可立即删除</p>
        <p v-if="giftEditData.orderId">当前已生成订单，尝试取消订单</p>
        <p v-if="giftEditData.orderId" style="font-size: 14px;color: #a8a8a8;">
          如已经发货，请点击[查看]，前往订单详情页操作售后</p>
        <p style="margin-top: 40px;">确认继续？</p>
        <div slot="footer" style="margin-top: 20px;text-align: right;">
          <el-button @click="giftRemoveDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="giftOrderDeleteConfirm">确 认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lsStaffService from 'API/ls/LsStaffService.js'
import lsAnchorService from 'API/ls/LsAnchorService'
import areaCascader from "COMPS/base/areaCascader.vue";
import tools from "COMJS/tools";

export default {
  name: "LsStaffDetail",
  components: {
    areaCascader
  },
  data() {
    return {
      staffId: '',
      staffData: {},
      //
      staffAddressList: [],
      addressEditDialogVisible: false,
      addressData: {},
      //
      staffSampleList: [],
      giftEditDialogVisible: false,
      giftEditData: {},
      giftRemoveDialogVisible: false,

    }
  },
  mounted() {
    console.log("mounted")
    this.staffId = this.$route.params.staffId
    this.loadStaffData();
    this.loadAddressData();
    this.loadSampleData();
  },
  methods: {
    loadStaffData() {
      let param = {
        'staffId': this.staffId,
      }
      lsStaffService.pageStaffByParam(param).then(res => {
        if (res && res.list && res.list.length > 0) {
          this.staffData = res.list[0]
        }
      })
    },
    loadAddressData() {
      let param = {
        'staffId': this.staffId,
        pageNo: 1,
        pageSize: 100,
      }
      lsStaffService.pageStaffAddressByParam(param).then(res => {
        let list = []
        let maxTime = 0
        let maxTimeIdx = -1
        if (res && res.list && res.list.length > 0) {
          list = res.list
          for (let i = 0; i < list.length; i++) {
            let item = list[i]
            if (item && item.lastOrderTime && item.lastOrderTime > maxTime) {
              maxTime = item.lastOrderTime
              maxTimeIdx = i
            }
          }
          if (maxTimeIdx != -1) {
            list[maxTimeIdx].lastOrderFlag = "1"
          }
        }
        this.staffAddressList = list;
      })
    },
    loadSampleData() {
      let param = {
        staffId: this.staffId,
        flagWithEI: "1",
        flagWithG: "1"
      }
      lsAnchorService.querySampleRecord(param).then(res => {
        this.staffSampleList = res;
      })
    },
    addressEdit(row) {
      if (row) {
        this.addressData = {
          ...row,
          pcc: {province: row.recipientProvince, city: row.recipientCity, area: row.recipientCounty}
        }
      } else {
        this.addressData = {pcc: {province: "", city: "", area: ""}};
      }
      this.addressEditDialogVisible = true;
    },
    addressEditDialogConfirm() {
      let saveData = {
        ...this.addressData,
        staffId: this.staffId,
        recipientProvince: this.addressData.pcc.province,
        recipientCity: this.addressData.pcc.city,
        recipientCounty: this.addressData.pcc.area,
      }
      if (!saveData.recipientName) {
        return this.$message.warning("请填写收件人姓名")
      }
      if (!saveData.recipientPhoneNum) {
        return this.$message.warning("请填写收件人手机号码")
      }
      if (saveData.recipientPhoneNum.length != 11) {
        return this.$message.warning("手机号码格式错误，请检查")
      }
      if (!saveData.recipientProvince || !saveData.recipientCity
          || !saveData.recipientCounty || !saveData.recipientAddressDetail) {
        return this.$message.warning("收件地址信息不完整，请检查！")
      }
      lsStaffService.saveLsStaffAddress(saveData).then(res => {
        this.$message.success(`保存成功！`);
        this.loadAddressData();
        this.addressEditDialogVisible = false;
      })
    },
    addressDelete(row) {
      this.$confirm(`确认删除地址?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          'staffAddressId': row.staffAddressId
        }
        lsStaffService.removeLsStaffAddress(param).then(res => {
          this.$message.success('删除成功')
          this.loadAddressData();
        })
      });
    },
    giftOrderShow(row) {
      if (!row.orderId) {
        this.$alert('礼单尚未生成订单，无法查看！', '系统提示');
        return;
      }
      this.$router.push(`/som/orderDetail/${row.orderId}`)
    },
    giftOrderEdit(row) {
      this.giftEditData = {
        ...row
      }
      this.giftEditDialogVisible = true;
    },
    giftEditAddressSelect(item) {
      this.giftEditData.recipientName = item.recipientName;
      this.giftEditData.recipientPhoneNum = item.recipientPhoneNum;
      this.giftEditData.recipientProvince = item.recipientProvince;
      this.giftEditData.recipientCity = item.recipientCity;
      this.giftEditData.recipientCounty = item.recipientCounty;
      this.giftEditData.recipientAddressDetail = item.recipientAddressDetail;
      this.giftEditData.modifyAddressId = item.staffAddressId
    },
    giftEditDialogConfirm() {
      lsAnchorService.modifyAddressById(this.giftEditData).then(res => {
        this.$message.success('修改成功')
        this.loadSampleData();
        this.giftEditDialogVisible = false;
      })
    },
    giftOrderDelete(row) {
      this.giftEditData = {
        ...row
      }
      this.giftRemoveDialogVisible = true;
    },
    giftOrderDeleteConfirm() {
      let param = {
        anchorSampleId: this.giftEditData.anchorSampleId
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading..."
      });
      lsAnchorService.removeSampleRecord(param).then(res => {
        this.$message.success('操作成功')
        this.loadSampleData();
        this.giftRemoveDialogVisible = false;
        loading.close();
      }).catch(() => {
        loading.close();
      })
    },
    isThisAddress(item) {
      return (this.giftEditData.recipientName == item.recipientName
          && this.giftEditData.recipientPhoneNum == item.recipientPhoneNum
          && this.giftEditData.recipientProvince == item.recipientProvince
          && this.giftEditData.recipientCity == item.recipientCity
          && this.giftEditData.recipientCounty == item.recipientCounty
          && this.giftEditData.recipientAddressDetail == item.recipientAddressDetail
      )
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>
#staffDetail .sample-list-div .el-table >>> .cell {
  line-height: 24px;
}

.sample-list-div {
  margin-top: 80px;
}

#staffDetail .address-list-div .el-table >>> .cell {
  line-height: 24px;
}

.address-list-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.address-list-div > *:nth-child(2) {
  margin-left: 10px;
}

.address-span > span {
  margin-right: 10px;
}

.icon-operation > a {
  cursor: pointer;
  color: #468dea;
  font-size: 16px;
}

.address-click {
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.address-click-selected {
  border: 1px solid #b70c39;
}

.delete-dialog-p > p {
  margin-top: 10px;
  font-size: 20px;
}
.sample-tip{
  margin-top: 10px;
  color: #b70c39;
}
</style>