import axiosHttp from "../axiosHttp"

let versionNo = -1

export default {
  getMenuTree() {
    return axiosHttp.GET(`/bimMenu/getMenuTree`)
  },
  getMenuAllTree(appType) {
    return axiosHttp.GET('/bimMenu/getMenuAllTree?appType='+appType)
  },
  saveOrUpdateMenu(param){
    return axiosHttp.POST(`/bimMenu/saveOrUpdate`,param)
  },
  deleteMenu(id){
    let opt = {'delIds': id};
    return axiosHttp.DELETE(`/bimMenu/delete`,opt)
  },
  moveMenuItem(selectMenuId,targetMenuId,dropType){
    let opt = {'selectMenuId': selectMenuId,'targetMenuId':targetMenuId,'position':dropType};
    return axiosHttp.POST(`/bimMenu/moveMenuItem`,opt)
  },
  moveMenuItemApp(selectMenuId,newIndex){
    let opt = {'selectMenuId': selectMenuId,'newIndex':newIndex};
    return axiosHttp.POST(`/bimMenu/moveMenuItemApp`,opt)
  }

}