<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <el-button type="primary" @click="openAddDialog">新建</el-button>
        </div>
      </div>
      <div class="filter_right">
        <div class="form-row">
          <el-button type="warning" @click="restartAll">重启</el-button>
        </div>
      </div>
    </div>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
    >
      <el-table-column prop="taskName" label="名称"></el-table-column>
      <el-table-column prop="taskObject" label="代码对象"></el-table-column>
      <el-table-column prop="planCount" label="计划数"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation" style="display: flex;justify-content: space-between;">
            <a href="javascript: void(0);" title="计划" style="margin-right: 0px !important; align-items: center;">
              <i class="CCSFont icon-table_2" @click="openPlanDialog(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="编辑" style="margin-right: 0px !important; align-items: center;">
              <i class="CCSFont icon-table_1" @click="openAddDialog(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="删除" style="margin-right: 0px !important; align-items: center;">
              <i class="el-icon-delete-solid" @click="removeTask(scope.row)"></i>
            </a>
            <a href="javascript: void(0);" title="执行" style="margin-right: 0px !important; align-items: center;">
              <i class="CCSFont icon-sidebar_7" @click="implementOnce(scope.row)"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <!--新增-->
    <el-dialog :title="currentInfo.taskId ? '编辑' : '新增'" :visible.sync="addDialogVisible"
               @close="addDialogVisible = false" width="606px">
      <el-form label-width="140px">
        <el-form-item required label="名称：">
          <el-input v-model="currentInfo.taskName"></el-input>
        </el-form-item>
        <el-form-item required label="代码对象：">
          <el-input v-model="currentInfo.taskObject"></el-input>
        </el-form-item>
        <el-form-item required label="备注：">
          <el-input v-model="currentInfo.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="计划" :visible.sync="planDialogVisible"
               @close="planDialogVisible = false" width="800px">
      <div style="color: red; margin-bottom: 10px;">计划新增或编辑，即刷新任务！注意规则的格式和中英文输入法。</div>
      <el-form label-width="120px" :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addOnePlanAtLast">新增定时计划</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="currentInfoPlanList">
        <el-table-column prop="cronExpression" label="规则">
          <template slot-scope="scope">
            <el-input
                :disabled="!scope.row.editFlag"
                v-model="scope.row.cronExpression"
                type="text"/>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <el-input
                :disabled="!scope.row.editFlag"
                v-model="scope.row.remark"
                type="text"/>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small"
                       v-if="!scope.row.editFlag"
                       @click="openPlanEditFlag(scope.row)">编辑
            </el-button>
            <el-button type="text" size="small"
                       v-if="!scope.row.editFlag"
                       @click="removeTaskPlan(scope.$index,scope.row)">删除
            </el-button>
            <el-button type="text" size="small"
                       v-if="scope.row.editFlag"
                       @click="saveTaskPlan(scope.row)">保存
            </el-button>
            <el-button type="text" size="small"
                       v-if="scope.row.editFlag"
                       @click="cancelSaveTaskPlan(scope.$index,scope.row)">取消
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin";
import taskService from "API/ba/taskService";
import Objects from "COMJS/Objects";

export default {
  name: "timedTaskManager",
  mixins: [pageTableMixin],
  data() {
    return {
      addDialogVisible: false,
      currentInfo: {},
      planDialogVisible: false,
      currentInfoPlanList: [],
    }
  },
  mounted() {

  },
  methods: {
    loadTableData() {
      taskService.queryTaskAtPage(this.pageInfo, this.searchConfig).then(data => {
        this.querySuccess(data)
      })
    },
    restartAll() {
      this.$confirm('重启所有任务，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            let loading = this.$loading({
              lock: true,
              text: "所有任务重启中..."
            });
            taskService.restartAll().then(data => {
              //this.loadTableData()
              loading.close()
              this.$alert('所有任务重启完成', '结果', {
                confirmButtonText: '确定',
              });
            }).catch(err=>{
              loading.close()
            })
          }
      )
    },
    openAddDialog(row) {
      if (row && row.taskId) {
        this.currentInfo = row
      } else {
        this.currentInfo = {}
      }
      this.addDialogVisible = true
    },
    addConfirm() {
      taskService.saveTaskDefine(this.currentInfo).then(data => {
        this.loadTableData()
        this.addDialogVisible = false
      })
    },
    removeTask(row) {
      this.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            taskService.delTaskById(row.taskId).then(data => {
              this.loadTableData()
            })
          }
      )
    },
    implementOnce(row) {
      this.$confirm('计划外单次执行本任务，根据任务内容不同可能会对数据产生不可逆影响，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            let loading = this.$loading({
              lock: true,
              text: "任务执行中..."
            });
            taskService.implementOnce(row.taskId).then(data => {
              //this.loadTableData()
              loading.close()
              this.$alert('任务执行完成', '结果', {
                confirmButtonText: '确定',
              });
            }).catch(err=>{
              loading.close()
            })
          }
      )
    },
    openPlanDialog(row) {
      this.currentInfo = row
      this.planDialogVisible = true
      this.loadTaskPlanData()
    },
    loadTaskPlanData() {
      taskService.queryTaskPlanAtPage(this.currentInfo.taskId).then(data => {
        this.currentInfoPlanList = data.list
      })
    },
    addOnePlanAtLast() {
      this.currentInfoPlanList.push({'editFlag': true, 'taskId': this.currentInfo.taskId})
    },
    openPlanEditFlag(row) {
      row.old_cronExpression = row.cronExpression
      row.old_remark = row.remark
      this.$set(row, 'editFlag', true)
    },
    removeTaskPlan(idx, row) {
      this.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() => {
            taskService.delTaskPlan(row.taskPlanId).then(data => {
              //this.currentInfoPlanList.splice(idx, 1)
              this.loadTaskPlanData()
            })
          }
      )
    },
    saveTaskPlan(row) {
      if (Objects.isEmpty(row.cronExpression)) {
        this.$message({type: "error", message: "计划规则不可为空"})
        return
      }
      taskService.saveTaskPlanDefine(row).then(data => {
        //this.$set(row, 'editFlag', false)
        this.loadTaskPlanData()
      })
    },
    cancelSaveTaskPlan(idx, row) {
      if (row.taskPlanId) {
        row.cronExpression = row.old_cronExpression
        row.remark = row.old_remark
        this.$set(row, 'editFlag', false)
      } else {
        this.currentInfoPlanList.splice(idx, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>