<template>
  <div>
    <el-dialog :title="title" :visible="dialogVisible" top="10%" @close="closeDialog" :close-on-click-modal="false">
      <el-form :model="nodeRole" :rules="rules" ref="editForm" label-width="110px" v-loading="formLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="审核节点名称" prop="auditNodeName">
              <el-input v-model="nodeRole.auditNodeName" :disabled="true" :maxlength="32"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="审核角色" prop="auditRoleId">
              <el-select v-model="nodeRole.auditRoleId" filterable clearable placeholder="请选择审核角色"
                         @change="changeRoleId" style="width:200px">
                <el-option v-for="item in enumMap.enumRole" :label="item.name" :value="item.value"
                           :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色指定类型" prop="auditRoleType">
              <el-form-item>
                <el-select v-model="nodeRole.auditRoleType" clearable placeholder="请选择角色指定类型" style="width:200px">
                  <el-option v-for="item in enumMap.enumAuditRoleType" :label="item.name" :value="item.value"
                             :key="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
            <el-button @click.native="closeDialog">关 闭</el-button>
            <el-button type="primary" @click.native="submit">保 存</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>

import Objects from "COMJS/Objects";
import auditFlowService from "API/ba/auditFlowService"

export default {
  name: "auditNodeRoleEdit",
  components: {},
  props: {
    enumMap: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    let rules = {
      auditRoleId: [
        {required: true, message: '请选择角色'}

      ],
      auditRoleType: [
        {required: true, message: '请选择角色指定类型'}
      ],
    }
    return {
      title: '',
      formLoading: false,
      dialogVisible: false,
      scene: '',
      nodeRole: {},
      rules: rules
    };
  },
  methods: {
    /**关闭角色配置弹窗*/
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.editForm.resetFields();
    },
    /**显示审核角色配置弹窗*/
    display(nodeRole, scene) {
      this.scene = scene;
      this.formLoading = true;
      if ('ADD' === scene) {
        this.title = '新增审核角色配置';
        this.nodeRole = Objects.deepCopy({}, nodeRole);
        this.formLoading = false;
      } else if ('EDIT' === scene) {
        this.title = '编辑审核角色配置';
        this.nodeRole = Objects.deepCopy({}, nodeRole);
        this.formLoading = false;
      }

      this.dialogVisible = true;
    },

    /**提交版本审核配置*/
    submit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.formLoading = true;
          let nodeRole = this.nodeRole;
          if ('ADD' === this.scene) {
            auditFlowService.saveAuditNodeRoleConfigurationInfo(nodeRole).then(data => {
              this.closeDialog();
              this.formLoading = false;
              this.$emit('editCompleted');
            }).catch((err) => {
              this.formLoading = false;
            });
          } else if ('EDIT' === this.scene) {
            auditFlowService.updateAuditNodeRoleConfigurationInfo(nodeRole).then(data => {
              this.closeDialog();
              this.formLoading = false;
              this.$emit('editCompleted');
            }).catch((err) => {
              this.formLoading = false;
            });
          }
        }
      })

    },
    changeRoleId(value) {
      let roleList = this.enumMap.enumRole;
      let roleListEnum = roleList.find(o => o.value === value);
      if (Objects.isNotEmpty(roleListEnum)) {
        this.$set(this.nodeRole, 'auditRoleName', roleListEnum.name);
      }

    }

  }
}
</script>

<style scoped>

</style>
