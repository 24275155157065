<template>
  <div class="drop-select" v-click-outside="close">
    <div class="drop-selected" @click="isShowDrop=!isShowDrop">
      <input
        type="text"
        :placeholder="placeholder"
        v-model="selected.label"
        readonly="readonly"
        autocomplete="off"
      />
      <i class="el-icon-arrow-down"></i>
    </div>
    <div
      class="drop-list"
      :class="{'show': isShowDrop}"
      :style="{'height': isShowDrop ? height : '0'}"
    >
      <div
        class="drop-item"
        v-for="item in list"
        :class="{'isSelected': item.value == selected.value}"
        @click="select(item)"
        :key="item.id"
      >
        <span>{{item.label}}</span>
        <i class="el-icon-check" v-if="item.isSelected"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    hasDefalut: Boolean,
    placeholder: String
  },
  data() {
    return {
      isShowDrop: false,
      height: 0,
      selected: {}
    };
  },
  // directives: {
  //   clickOutside: {
  //     bind(el, binding, vnode) {
  //       function clickHandler(e) {
  //         // 这里判断点击的元素是否是本身，是本身，则返回
  //         if (el.contains(e.target)) {
  //           return false;
  //         }
  //         // 判断指令中是否绑定了函数
  //         if (binding.expression) {
  //           // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
  //           binding.value(e);
  //         }
  //       }
  //       // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
  //       el.__vueClickOutside__ = clickHandler;
  //       document.addEventListener("click", clickHandler);
  //     },
  //     update() {},
  //     unbind(el, binding) {
  //       // 解除事件监听
  //       document.removeEventListener("click", el.__vueClickOutside__);
  //       delete el.__vueClickOutside__;
  //     }
  //   }
  // },
  mounted() {
    if (this.hasDefalut) {
      this.selected = this.list[0];
    }
    this.height = this.list.length * 56 + 1 + "px";
  },
  methods: {
    select(item) {
      this.selected = item;
      this.isShowDrop = false;
      this.$emit("select", item);
    },
    close() {
      this.isShowDrop = false
    }
  }
};
</script>

<style lang="scss">
.drop-select {
  cursor: pointer;
  position: relative;
  line-height: 56px;
  height: 56px;
  margin: 0 auto;
  margin-top: 30px;
  // z-index: 2;
  i {
    line-height: 56px;
    float: right;
  }
  .drop-selected {
    position: relative;
    > input {
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      height: 56px;
      padding: 0 35px 0 15px;
      border: none;
      border: 1px solid #999;
      border-radius: 3px;
    }
    > i {
      position: absolute;
      height: 100%;
      right: 5px;
      top: 0;
      width: 30px;
      text-align: center;
      transition: all 0.3s;
    }
  }
  .drop-list {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 1px;
    height: 0;
    transition: height 0.3s ease-out;
    overflow: hidden;
    border-top: none;
    box-sizing: border-box;
    &.show {
      border: 1px solid rgba(220, 222, 224, 1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    .drop-item {
      cursor: pointer;
      padding: 0 15px;
      background-color: #fff;
      &.isSelected {
        color: #be0000;
      }
      &:hover {
        color: #fff;
        background-color: #ff6060;
      }
    }
  }
}
</style>