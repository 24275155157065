import axiosHttp from "../axiosHttp"

export default {
  getPageByParam(pageInfo,searchConfig){
    let url = "/bimRole/pageByParam?"
    if (pageInfo) {
      url = url + pageInfo.buildQueryString();
    }
    url += "&" + searchConfig.buildQueryString();
    return axiosHttp.GET(url);
  },
  getAllRoleList() {
    let url = '/bimRole/findRoleList'
    return axiosHttp.POST(url,{})
  },
  /**
   * 取自定义角色列表
   * @returns {Promise<unknown>}
   */
  getCustomRoleList() {
    let url = '/bimRole/pageByParam?pageNo=1&pageSize=99&params=roleLock:eq:0:str'
    return axiosHttp.GET(url)
  },
  create(param){
    return axiosHttp.POST(`/bimRole/create`,param)
  },
  update(param){
    return axiosHttp.POST(`/bimRole/update`,param)
  },
  delete(ids){
    let param = {
      delIds:ids
    }
    return axiosHttp.POST(`/bimRole/delete`,param)
  },
  addBimUserRole(roleId,userIds){
    let param = {
      roleId:roleId,
      userIds:userIds
    }
    return axiosHttp.POST(`/bimRole/addBimUserRole`,param)
  },

  findUserByRoleCode(roleCode){
    return axiosHttp.POST(`/bimRole/findUserByRole`, {'roleCode':roleCode})
  },
  findUserByRoleId(roleId){
    return axiosHttp.POST(`/bimRole/findUserByRole`, {'roleId':roleId})
  },

  queryByRoleCode(roleCode){
    return axiosHttp.GET(`/bimRole/queryByRoleCode/${roleCode}`)
  },
  saveBimRoleMenus(roleId,menuIdList){
    let param = {
      'roleId':roleId,
      'menuIdList':menuIdList
    }
    return axiosHttp.POST(`/bimRole/saveBimRoleMenus`,param)
  }
}