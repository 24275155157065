<template>
  <div class="ws-sidebar">
    <div class="ws-sidebar-content">
      <div
          class="first-menu-wrapper menu"
          :class="{ 'small-menu': selectedFirstMenu && selectedFirstMenu.subMenus && selectedFirstMenu.subMenus.length > 0 }"
      >
        <ul class="first-menu" v-for="(menu, index) in appMenus" :key="index">
          <li
              @click="selectMenu(menu)"
              :class="{ selected: menu.menuName == selectedFirstMenu.menuName }"
          >
            <i :class="menu.iconName"></i>
            <span>{{ menu.menuName }}</span>
          </li>
        </ul>
      </div>
      <div
          class="second-menu-wrapper menu"
          :style="{ width: selectedFirstMenu && selectedFirstMenu.subMenus && selectedFirstMenu.subMenus.length > 0 ? '160px' : '0' }"
      >
        <div class="second-nav-wrapper">
          <template v-for="subMenu in selectedFirstMenu.subMenus">
            <el-collapse value="1" v-if="subMenu.subMenus && subMenu.subMenus.length>0">
              <el-collapse-item name="1">
                <template slot="title">
                  <i class="el-icon-arrow-down"></i>
                  {{ subMenu.menuName }}
                </template>
                <ul
                    class="second-menu"
                    v-for="(subMenu, index) in subMenu.subMenus"                    
                >
                  <li @click="$router.push(subMenu.menuUrl)">
                    <span :class="{ active: RoutePath == subMenu.menuUrl }">{{
                        subMenu.menuName
                      }}</span>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
            <template v-else>
              <ul class="second-menu">
                <li @click="$router.push(subMenu.menuUrl)">
                  <span :class="{ active: RoutePath == subMenu.menuUrl }">{{
                      subMenu.menuName
                    }}</span>
                </li>
              </ul>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import userModule from "@/module/userModule";
import bimMenuService from "@/api/ba/BimMenuService";
import homePageService from "@/api/bim/homePageService";
import tools from "COMJS/tools";

export default {
  name: "sidebar",
  data() {
    return {
      selectedFirstMenu: {
        menuName: "",
        subMenus: [],
      },
      selectedMenu: {
        name: "",
        menuUrl: "",
      },
      //loginUser: JSON.parse(userModule.PPUser),
      appMenus: [],
    };
  },
  mounted() {
    this.getMenus();
    this.searchMenu();
    this.$store.commit("saveMenus", this.appMenus);
  },
  watch: {
    $route() {
      this.searchMenu();
    },
  },
  computed: {
    RoutePath() {
      //return this.$route.path;
      return this.$route.fullPath;
    },
  },
  methods: {
    //获取菜单数据
    getMenus() {
      let that = this
      bimMenuService.getMenuTree().then(data => {
        that.appMenus = data
        that.showDialog();
        //默认打开第一个菜单
        that.defaultFirstMenu();
      }).catch(() => {
        this.$message({
          type: 'error',
          message: '菜单加载失败，请重新登录!'
        });
      });
    },
    defaultFirstMenu(){
      if(this.appMenus && this.appMenus.length>0){
        this.selectedFirstMenu = this.appMenus[0];
      }
    },
    //选中一级菜单
    selectMenu(menu) {
      //console.log("selectMenu->" + JSON.stringify(menu))
      this.selectedFirstMenu = menu;
      if (menu && menu.menuUrl && menu.menuUrl.length > 0) {
        this.$router.push(menu.menuUrl);
      }
    },
    // 直接输入地址进入时bar高亮检索
    searchMenu() {
      //console.log("searchMenu,this.RoutePath="+this.RoutePath)
      let path = this.RoutePath;
      let menus = this.appMenus;
      this.searchMenuRecursion(path, menus);
    },
    searchMenuRecursion(path, menus) {
      for (let menu of menus) {
        if (this.compareUrl(path, menu)) {
          this.selectedFirstMenu = menu
          return
        }
      }
    },
    compareUrl(url, menu) {
      if (menu && menu.menuUrl && menu.menuUrl.length > 0) {
        if (menu.menuUrl === url) {
          return true
        }
      }
      if (menu.subMenus) {
        for (let item of menu.subMenus) {
          if (this.compareUrl(url,item)){
            return true
          }
        }
      }
      return false
    },
    /**
     * 展示页面弹窗通知，header里还有一个
     */
    showDialog() {
      homePageService.pageNotice(this.appMenus).then(async (data) => {
        if(data.length>0){
          let that = this
          for (let datum of data) {
            //有点时间差，才能一个一个出来，并且相隔一定距离，否则会叠在一起
            await tools.delay(100)
            let notify
            notify = this.$notify({
              title: datum.title,
              message: datum.message,
              duration: 0,
              customClass:'notify-custom-class',
              onClick:()=>{
                that.$router.push(datum.routerPath);
                notify.close()
              }
            });
          }
        }
      });
    },
    signOut() {
      userModule.removeCookiesToken();
      userModule.removePPUser();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
$height: 100%;
.ws-sidebar {
  .ws-sidebar-content {
    display: flex;
    width: 220px;
    height: 100%;
    font-size: 14px;
    line-height: 54px;

    .menu {
      display: inline-block;
      vertical-align: top;
      min-height: 100vh;
      padding-top: 138px;
      box-sizing: border-box;

      > ul > li {
        cursor: pointer;
        height: 54px;
        box-sizing: border-box;
      }
    }

    .first-menu-wrapper {
      flex: 0 0 1;
      width: 220px;
      transition: width 0.3s ease-out;
      // text-align: center;
      color: #666;
      background-color: #f9f9f9;

      &.small-menu {
        width: 100px;

        > ul > li {
          padding-left: 0;
          text-align: center;
        }

        > ul > li > i {
          margin-right: 8px;
        }
      }

      i {
        font-family: "sidebar-iconfont";
      }

      > ul > li {
        padding-left: 50px;

        i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 13px;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        &:hover {
          background-color: rgba(199, 225, 255, 0.3);
        }

        &.selected {
          background-color: #e7f1fd;
          color: $themeColor;
        }
      }
    }

    .second-menu-wrapper {
      transition: width 0.3s ease-out;
      flex: 0 0 1;
      background-color: #fff;

      .el-collapse,
      .el-collapse-item__header,
      .el-collapse-item__wrap {
        border: none;
      }

      .el-collapse-item__header {
        line-height: 54px;
        height: 54px;
      }

      .el-collapse-item__arrow {
        display: none;
      }

      .el-icon-arrow-down {
        margin: 0 8px;
      }

      ul > li {
        cursor: pointer;
        text-align: center;
        line-height: 54px;

        > span {
          display: inline-block;
          width: 100%;
          line-height: 54px;
          // border-radius: 2px;
          transition: all 0.1s;

          &:hover {
            background-color: rgba(199, 225, 255, 0.3);
          }

          &.active {
            background-color: #c7e1ff;
            color: #1b73e8;
          }
        }
      }
    }

    .second-nav-wrapper {
      height: $height - 20%;
      overflow-y: auto;
    }

    .el-collapse-item__content {
      padding-bottom: 0px;
    }
  }
}

/*---滚动条默认显示样式--*/
::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline-offset: -2px;
  outline: 4px solid #f8f8f8;
  border-radius: 4px;
  border: 42px solid #fff;
}

/*---鼠标点击滚动条显示样式--*/
::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
  border-radius: 4px;
}

/*---滚动条大小--*/
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/*---滚动框背景样式--*/
::-webkit-scrollbar-track-piece {
  background-color: #fff;
  border-radius: 4px;
}
</style>