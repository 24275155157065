import axiosHttp from "../axiosHttp";

//经销商的干活
export default {
    queryPages(pageInfo,searchConfig){
        let url = "/faDealerAuth/queryAtPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    save(param){ //保存经销商信息
        return axiosHttp.POST('/faDealerAuth/save',param,false);
    },
}