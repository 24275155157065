<template>
  <div>
    <div class="tip">
      * 质保日期展示在小程序商城上，未列出的库品默认展示最新批次的质保日期
    </div>
    <div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" @click="showDialog">新增记录</el-button>
        </el-form-item>
        <el-form-item label="库品编码:">
          <el-input v-model="searchInfo.skuCode" placeholder="模糊搜索"/>
        </el-form-item>
        <el-form-item label="库品名称:">
          <el-input v-model="searchInfo.skuName" placeholder="模糊搜索"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchData">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo"
                     @pageChange="loadTableData">
        <el-table-column prop="skuCode" label="库品编码"></el-table-column>
        <el-table-column prop="skuName" label="库品名称"></el-table-column>
        <el-table-column prop="warrantyEnable" label="是否启用">
          <template slot-scope="scope">
            <i class="el-icon-check" v-if="scope.row.warrantyEnable=='1'"></i>
            <i class="el-icon-close" v-else></i>
          </template>
        </el-table-column>
        <el-table-column prop="productionDateStr" label="生产日期"></el-table-column>
        <el-table-column prop="doomsdayStr" label="产品效期"></el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <a @click="editWarranty(scope.row)" title="编辑">
              <i class="CCSFont icon-table_1 operate-btn"></i>
            </a>
            <a @click="deleteWarranty(scope.row)" title="删除">
              <i class="el-icon-delete-solid operate-btn"></i>
            </a>
          </template>
        </el-table-column>
      </ws-page-table>
    </div>

    <el-dialog :title="dialogTitle" :visible="dialogVisible" top="10%"
               @close="closeDialog" :close-on-click-modal="false">
      <el-form :model="dialogItem" ref="editForm" label-width="110px" v-loading="dialogLoading"
               element-loading-text="拼命加载中...">
        <el-row :span="24">
          <el-form-item label="库品编码">
            <div
                class="select-btn"
                v-if="!dialogItem.skuCode"
                @click="$refs.selectSkuPopup.dialogVisible = true"
            >
              请选择库品编码
            </div>
            <template v-else>
              <span>{{ dialogItem.skuCode }}</span>
              <span
                  class="operate-btn"
                  style="dispaly: inline-block; margin-left: 15px"
                  @click="$refs.selectSkuPopup.dialogVisible = true"
              >重选库品编码</span
              >
            </template>
          </el-form-item>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="库品名称">
              <span>{{ dialogItem.skuName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="库品有效期">
              <span>{{ dialogItem.shelfLife }} 天</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-form-item label="是否生效">
            <el-switch
                v-model="dialogItem.warrantyEnable"
                active-value="1"
                inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="生产日期" prop="productionDate">
              <el-date-picker
                  v-model="dialogItem.productionDate"
                  type="date"
                  placeholder="选择日期"
                  @change="changeProductionDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品效期" prop="doomsday">
              <el-date-picker
                  v-model="dialogItem.doomsday"
                  type="date"
                  :disabled="true"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click.native="closeDialog">关 闭</el-button>
            <el-button type="primary" @click.native="dialogSubmit">保 存</el-button>
            </span>
    </el-dialog>
    <select-sku-popup ref="selectSkuPopup" @submit="selectSku"></select-sku-popup>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import invWarranty from "API/whm/invWarranty";
import tools from "COMJS/tools";
import selectSkuPopup from "./selectSkuPopup";

export default {
  name: "invWarrantyManager",
  mixins: [pageTableMixin],
  components: {selectSkuPopup},
  data() {
    return {
      searchInfo: {
        skuCode: '',
        skuName: '',
      },
      //
      dialogVisible: false,
      dialogLoading: false,
      dialogTitle: '新建记录',
      dialogItem: {},
    }
  },
  methods: {
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      invWarranty.queryPage(this.pageInfo, this.searchConfig).then(
          (data) => {
            //console.log(data);
            data.list = data.list.map((e) => {
              //console.log(e)
              return {
                ...e,
                productionDateStr: e.warrantyEnable == '1' ?
                    (e.productionDate ? tools.formatDate(new Date(e.productionDate), "yyyy-MM-dd") : "")
                    : "默认展示最新批次的质保日期",
                doomsdayStr: e.warrantyEnable == '1' ?
                    (e.doomsday ? tools.formatDate(new Date(e.doomsday), "yyyy-MM-dd") : "")
                    : ""
              };
            });
            this.querySuccess(data);
          }
      );
    },
    showDialog() {
      this.dialogItem = {warrantyEnable:'1'}
      this.dialogTitle = "新建记录"
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogItem = {}
      this.dialogTitle = "新建记录"
      this.dialogVisible = false
    },
    dialogSubmit(){
      invWarranty.save(this.dialogItem).then(res=>{
        this.loadTableData()
        this.dialogItem = {}
        this.dialogVisible = false
      })
    },
    deleteWarranty(item) {
      let inventoryWarrantyId = item.inventoryWarrantyId
      let that = this;
      this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        invWarranty.delById(inventoryWarrantyId).then(res => {
          that.loadTableData();
        })
      }).catch(() => {
      });
    },
    editWarranty(item) {
      this.dialogItem = {
        ...item
      }
      //console.log(this.dialogItem)
      this.dialogTitle = "编辑记录"
      this.dialogVisible = true
    },
    selectSku(sku) {
      if (sku) {
        //console.log(sku)
        this.$set(this.dialogItem, 'skuCode', sku.skuCode);
        this.$set(this.dialogItem, 'skuName', sku.skuName);
        this.$set(this.dialogItem, 'skuId', sku.id);
        this.$set(this.dialogItem, 'shelfLife', sku.shelfLife);
        this.$set(this.dialogItem, 'productionDate', '');
        this.$set(this.dialogItem, 'doomsday', '');
      }
    },
    changeProductionDate(e){
      if (e && this.dialogItem.shelfLife) {
        let sec = this.dialogItem.shelfLife * 3600 * 24 * 1000
        let doomsday = new Date((e.getTime() + sec))
        this.$set(this.dialogItem, 'doomsday', doomsday);
      }
    },
  }
}
</script>

<style scoped>
.tip {
  color: red;
  margin-bottom: 20px;
}

.operate-btn {
  color: rgba(70, 141, 234, 1);
  margin-right: 20px;
  cursor: pointer;
}

.select-btn {
  cursor: pointer;
  text-align: center;
  width: 200px;
  line-height: 32px;
  border: 1px solid;
  border-radius: 2px;
  color: #999;
}
</style>