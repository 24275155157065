import axiosHttp from "../axiosHttp";

export default {
  // 商品分页查询
  queryGoodsPageByParam(param) {
    let url = "/pimGoods/queryGoodsPage"
    return axiosHttp.POST(url,param);
  },
  //查询
  queryGoodsList(params) {
    return axiosHttp.POST('/pimGoods/queryGoodsList', params, false)
  },
  calculateSaleCountAndAvailableNum() {
    let url = "/pimGoods/calculateSaleCountAndAvailableNum"
    return axiosHttp.GET(url);
  },
  // 保存商品
  saveGoods(PimGoods) {
    return axiosHttp.POST('/pimGoods/savePimGoods', PimGoods)
  },
  saveGoodsTagAndExhibitionAndDetailPhoto(PimGoods) {
    return axiosHttp.POST('/pimGoods/saveGoodsTagAndExhibitionAndDetailPhoto', PimGoods)
  },
  copyFromExistPimGoods(PimGoods){
    return axiosHttp.POST('/pimGoods/copyFromExistPimGoods', PimGoods)
  },
  // 商品详情
  getGoodsDetail(goodId) {
    return axiosHttp.GET(`/pimGoods/fetchAll/${goodId}`)
  },
  // 下架商品
  withdrawGoods(goodsId) {
    return axiosHttp.POST('/pimGoods/withdrawGoods', {
      goodsId
    })
  },
  queryAllGoods(params) {
    return axiosHttp.POST('/pimGoods/queryForCreate', params, false)
  },

  queryGoodsCodeIsReapt(params) {
      return axiosHttp.POST('/pimGoods/queryRepeatGoodsCode', params, false)
  },

  findToSkuCodeInBatchByGoodsId(goodsId) {
    return axiosHttp.GET(`/pimGoods/findToSkuCodeInBatchByGoodsId/${goodsId}`);
  },

  //商城设置 -保存商品活动价格信息
  saveByGoods(params) {
    return axiosHttp.POST('/pimEventGoods/saveByGoods', params, false)
  },
  saveGoodsToSpuActivity(params) {
    return axiosHttp.POST('/pimGoodsSpu/saveGoodsToSpuActivity', params, false)
  },
  getEventByGoods(goodsId) {
    return axiosHttp.GET(`/pimEventGoods/getEventByGoods/${goodsId}`);
  },
  queryBySpuId(spuId) {
    return axiosHttp.GET(`/pimActivity/queryBySpuId/${spuId}`);
  },
  getSpuInGoodsAndActivity(goodsId) {
    return axiosHttp.GET(`/pimGoodsSpu/getSpuInGoodsAndActivity/${goodsId}`);
  },

  exportPimGoodsExcel(param){
    return axiosHttp.POST('/pimGoods/exportPimGoodsExcel', param, false)
  },
    queryAllPrice(param) {
      return axiosHttp.POST('/pimGoods/queryAllPrice', param, false)
    }
}