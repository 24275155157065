<template>
  <div class="delivery-info">
    <el-form :inline="true" class="rec-inline">
      <el-button type="primary" @click="addDelivery">新增信息</el-button>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      :showIndex="true"
    >
      <el-table-column
        prop="senderOrganize"
        label="发货方"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="senderName"
        label="发货人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="senderTel"
        label="发货方手机"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="senderAddress"
        label="发货方地址"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="默认信息"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.defaultOption"
            @click.native="toggleEnable(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="operNum" label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <el-dialog
      title="新增发货信息"
      :visible.sync="deliveryDialog"
      class="abnormal-wrap"
      @close="closeDialog"
      width="660px"
    >
      <el-form label-width="160px">
        <el-form-item label="发货方：">
          <el-input v-model="whmInfo.senderOrganize"></el-input>
        </el-form-item>
        <el-form-item label="发货人：">
          <el-input v-model="whmInfo.senderName"></el-input>
        </el-form-item>
        <el-form-item label="发货人手机：">
          <el-input v-model="whmInfo.senderTel"></el-input>
        </el-form-item>
        <el-form-item label="发货人地址：">
          <el-input
            type="textarea"
            class="area-wrap"
            v-model="whmInfo.senderAddress"
          ></el-input>
        </el-form-item>
        <el-form-item label="默认信息：">
          <el-switch v-model="whmInfo.defaultOption"></el-switch>
        </el-form-item>
      </el-form>
      <div class="btn-blue-wrap">
        <el-button @click="colse">取消</el-button>
        <el-button type="primary" @click="checkMes">保存</el-button>        
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";
import tools from "COMJS/tools";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],
      whmInfo: {
        name: "",
        address: "",
        defaultOption: false,
      },
      deliveryDialog: false,
      isEdit: false,
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData()
  },
  methods: {
    closeDialog(){
      this.whmInfo.senderOrganize = ""
      this.whmInfo.senderName = ""
      this.whmInfo.senderTel = ""
      this.whmInfo.senderAddress = ""
      this.whmInfo.defaultOption = false
    },
    colse(){
      this.deliveryDialog = false
    },
    checkMes(){
      let whmInfo = this.whmInfo
      if (!whmInfo.senderOrganize) {
        return this.$message({
          message: "请添加发货方",
          type: "warning",
        });
      }
      if (!whmInfo.senderName) {
        return this.$message({
          message: "请添加发货人",
          type: "warning",
        });
      }
      if (!whmInfo.senderTel) {
        return this.$message({
          message: "请添加发货人手机",
          type: "warning",
        });
      }
      if (!whmInfo.senderAddress) {
        return this.$message({
          message: "请添加发货人地址",
          type: "warning",
        });
      }          
      if(this.isEdit){
        this.editOutboundFun()
      }else{
        this.whmInfo = {
          senderOrganize: whmInfo.senderOrganize,
          senderName: whmInfo.senderName,
          senderTel: whmInfo.senderTel,
          senderAddress: whmInfo.senderAddress,
          defaultOption: whmInfo.defaultOption == true ? 1 : 0,
        };  
        this.createOutbound()
      }
    },
    createOutbound(){          
      this.loading = true;
      WhmList.saveOutbound(this.whmInfo).then((res) => {
        this.loading = false;
        this.whmInfo = {
          senderOrganize:"",
          senderName: "",
          senderTel: "",
          senderAddress: "",
          defaultOption: false,
        }
        this.deliveryDialog = false        
        this.loadTableData()
        this.$message({
          type: "success",
          message: "添加成功!",
        });        
      });
    },

    editOutboundFun(){
      this.loading = true;
      let params = this.whmInfo
      params.defaultOption = this.whmInfo.defaultOption == true ? 1 : 0
      WhmList.editOutbound(params).then((res) => {
        this.loading = false;
        this.whmInfo = {
          senderOrganize:"",
          senderName: "",
          senderTel: "",
          senderAddress: "",
          defaultOption: false,
        }
        this.deliveryDialog = false
        this.isEdit = false
        this.loadTableData()
        this.$message({
          type: "success",
          message: "修改信息成功!",
        });        
      });
    },
    
    toggleEnable(row) {
      const that = this;
      this.$confirm(`是否切换默认发货信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {                    
          row.defaultOption = !row.defaultOption == true ? 1 : 0
          //console.log(row)
          this.whmInfo = {
            outboundSenderInfoId: row.outboundSenderInfoId,
            defaultOption: row.defaultOption,        
          }
          this.editOutboundFun()
        })
        .catch(() => {});
    },
    addDelivery() {
      this.deliveryDialog = true;
    },
    backPre() {},
    skucodeConfirm() {},
    editEnable() {
      //console.log("666")
      this.whmInfo.defaultOption = !this.whmInfo.defaultOption;
    },
    toEdit(row) {
      this.deliveryDialog = true;
      this.isEdit = true,
      //console.log(row)
      this.whmInfo = {
        outboundSenderInfoId: row.outboundSenderInfoId,
        senderOrganize: row.senderOrganize,
        senderName: row.senderName,
        senderTel: row.senderTel,
        senderAddress: row.senderAddress,
        defaultOption: row.defaultOption,        
      }
    },
    loadTableData() {
      //this.$refs.pageTable.tableLoading = true;
      WhmList.pageOutboundList(this.queryParams).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            outboundSenderInfoId: e.outboundSenderInfoId,
            senderOrganize: e.senderOrganize,
            senderName: e. senderName,
            senderTel: e.senderTel,
            senderAddress: e.senderAddress,
            defaultOption: e.defaultOption == 1 ? true : false,
          };
        });
        this.querySuccess(data);
      });
    },
  },
};
</script>
<style>
.delivery-info .rec-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.delivery-info .btn-blue-wrap {
  display: flex;
  justify-content: center;
}
.delivery-info .el-input__inner{ width: 160px;}
.delivery-info .is-in-pagination .el-input__inner{ width: 40px;}
</style>