<template>
  <div class="whm-add">
    <div class="step-title">供应商详情</div>
    <el-form
      :model="whmInfo"
      label-width="140px"
      ref="whmInfoForm"
      class="step-form"
    >
      <el-form-item label="供应商名称:">
        {{whmInfo.supplierName}}
      </el-form-item>
      <el-form-item label="地址:">
        {{whmInfo.address}}
      </el-form-item>                
      <el-form-item label="联系人:">
        {{whmInfo.contactName}}
      </el-form-item>
      <el-form-item label="联系人电话:">
        {{whmInfo.mobile}}
      </el-form-item>
      <el-form-item label="收款账号信息:" class="mt55">        
      </el-form-item>
      <el-form-item label="账户名:">
        {{whmInfo.accountName}}
      </el-form-item>
      <el-form-item label="银行账号:">
        {{whmInfo.accountBank}}
      </el-form-item>
      <el-form-item label="开户行:">
        {{whmInfo.openingBank}}
      </el-form-item>
      <el-form-item label="税号:">
        {{whmInfo.einNumber}}
      </el-form-item>
      <el-form-item label="备注:">
        {{whmInfo.remark}}
      </el-form-item>
      <el-form-item label="是否启用:">
        {{whmInfo.enabled == 1 ? '是': '否'}}
      </el-form-item>
    </el-form>

    <div class="steps-btn-wrapper">
      <el-button @click="backPre">返回</el-button>
    </div>

  </div>
</template>

<script>
import SearchConfig from "COMJS/query/SearchConfig";
import userModule from "@/module/userModule";
import eventBus from "../../../js/eventBus";
import WhmList from "API/whm/WhmList";

export default {
  name: "whmSupDetail",
  data() {
    return {
      requestHeaders: {
        Authorization: userModule.token,
      },
      whmInfo: {
        supplierName: "", //供应商名称
        address: "", //地址
        contactName: "", //联系人
        mobile: "", //联系人电话
        accountName: "", //账户名
        accountBank: "", //银行账号
        openingBank: "", //开户行
        einNumber: "", //税号
        remark: "", //备注
        enabled: true,// 是否启用1是启用0是禁用
      },
    };
  },
  components: {},
  mounted() {
    if(this.$route.params.id){
      this.getGoodsDetail()
    }
  },
  computed: {},
  methods: {
    backPre(){//返回上一页
      this.$router.push("/whm/whmSupplier");
    },   
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailSupplier(this.$route.params.id).then((data) => {
        let whmInfo = this.whmInfo
        whmInfo.supplierName = data.supplierName, //供应商名称
        whmInfo.address = data.address, //地址
        whmInfo.contactName = data.contactName, //联系人
        whmInfo.mobile = data.mobile, //联系人电话
        whmInfo.accountName = data.accountName, //账户名
        whmInfo.accountBank = data.accountBank, //银行账号
        whmInfo.openingBank = data.openingBank, //开户行
        whmInfo.einNumber = data.einNumber, //税号
        whmInfo.remark = data.remark, //备注
        whmInfo.enabled = data.enabled
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mt55{ margin-top: 55px;}
.whm-add .el-input--small .el-input__inner {
  width: 150px;
}
.step-title {
  padding: 0 0 40px 6px;
  font-size: 18px;
  color: #333;
}
.select-btn {
  width: 200px;  
  text-align: center;  
  line-height: 32px;
  border: 1px solid $borderColor;
  cursor: pointer;
  border-radius: 2px;
  color: #999;
}
.steps-btn-wrapper{ margin-top: 80px; display: flex; justify-content: center;}
.steps-btn-wrapper button{ width: 80px;}
.btn-next-step{ margin:20px auto 0 auto; width: 76px; height: 32px; line-height: 32px; text-align: center; color: #fff; background: #1B73E8; border-radius: 2px; font-size: 12px;}
</style>