<template>
  <div class="area-cascader">
    <el-autocomplete
        class="address-pcc-input"
        popper-class="my-autocomplete"
        v-model="caddress.province"
        :fetch-suggestions="querySearch4Province"
        placeholder="请选择省份"
        @select="handleSelect4Province"
    >
      <i class="el-icon-arrow-down el-input__icon" slot="suffix"></i>
      <template slot-scope="{ item }">
        <div class="name">{{ item.areaName }}</div>
        <!--
        <span class="addr">{{ item.areaCode }}</span>
        -->
      </template>
    </el-autocomplete>
    <el-autocomplete
        class="address-pcc-input"
        popper-class="my-autocomplete"
        v-model="caddress.city"
        :fetch-suggestions="querySearch4City"
        placeholder="请选择市"
        @select="handleSelect4City"
    >
      <i class="el-icon-arrow-down el-input__icon" slot="suffix"></i>
      <template slot-scope="{ item }">
        <div class="name">{{ item.areaName }}</div>
      </template>
    </el-autocomplete>
    <el-autocomplete
        class="address-pcc-input"
        popper-class="my-autocomplete"
        v-model="caddress.area"
        :fetch-suggestions="querySearch4County"
        placeholder="请选择区县"
        @select="handleSelect4County"
    >
      <i class="el-icon-arrow-down el-input__icon" slot="suffix"></i>
      <template slot-scope="{ item }">
        <div class="name">{{ item.areaName }}</div>
      </template>
    </el-autocomplete>
    <!--
    <el-select v-model="caddress.province" placeholder="请选择省份" @change="getCityData" clearable>
      <el-option
        v-for="item in addressArray"
        :key="item.areaCode"
        :label="item.areaName"
        :value="item.areaName"
      ></el-option>
    </el-select>
    <el-select v-model="caddress.city" placeholder="请选择市区" @change="getAreaData" clearable>
      <el-option
        v-for="item in cityArray"
        :key="item.areaCode"
        :label="item.areaName"
        :value="item.areaName"
      ></el-option>
    </el-select>
    <el-select v-model="caddress.area" placeholder="请选择县" @change="onAreaChanged" clearable>
      <el-option
        v-for="item in areaArray"
        :key="item.areaCode"
        :label="item.areaName"
        :value="item.areaName"
      ></el-option>
    </el-select>
    -->
  </div>
</template>

<script>
import BimCommonService from "API/bim/BimCommonService";
import region from "COMJS/region"

export default {
  name: "area-cascader",
  //通过 model 选项配置子组件接收的 prop 名以及派发的事件名
  model: {
    prop: "caddress",
    event: "change"
  },
  props: {
    caddress: {
      type: Object,
      default: {
        province: "",
        city: "",
        area: ""
      }
    },
    showArea:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      addressArray: [], //所有数据
      cityArray: [],
      areaArray: [],
      areaList: []
    };
  },
  mounted() {
    if (this.$store.state.areaList.length) {
      this.areaList = this.$store.state.areaList;
      this.getAddressData();
    } else {
      this.getAreaList();
    }
  },
  methods: {
    getAreaList() {
      this.areaList = region.json
      this.$store.commit("saveAreaList", region.json);
      this.getAddressData();
      return;
      /*
      BimCommonService.getAreaList().then(data => {
        this.areaList = data;
        this.$store.commit("saveAreaList", data);
        this.getAddressData();
      });
       */
    },
    getAddressData() {
      //获取地址
      this.addressArray = this.areaList;
      //默认值赋值获取城市数组
      if (this.caddress.province) {
        for (let ad of this.addressArray) {
          if (ad.areaName === this.caddress.province) {
            this.cityArray = ad.childAreaList;
            //默认赋值获取区域数组
            if (this.caddress.city) {
              for (let area of this.cityArray) {
                if (area.areaName === this.caddress.city) {
                  this.areaArray = area.childAreaList;
                  break;
                }
              }
            }
          }
        }
      }
    },
    querySearch4Province(queryString, cb) {
      //console.log("querySearch4Province",queryString)
      let addressArray = this.addressArray;
      let results = queryString ? addressArray.filter(this.createFilter(queryString)) : addressArray;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearch4City(queryString, cb) {
      //console.log("querySearch",queryString)
      let addressArray = this.cityArray;
      let results = queryString ? addressArray.filter(this.createFilter(queryString)) : addressArray;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearch4County(queryString, cb) {
      //console.log("querySearch",queryString)
      let addressArray = this.areaArray;
      let results = queryString ? addressArray.filter(this.createFilter(queryString)) : addressArray;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (item) => {
        return (item.areaName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelect4Province(item) {
      this.caddress.province = item.areaName;
      this.getCityData(this.caddress.province);
    },
    handleSelect4City(item) {
      this.caddress.city = item.areaName;
      this.getAreaData(this.caddress.city);
    },
    handleSelect4County(item) {
      this.caddress.area = item.areaName;
      this.onAreaChanged(this.caddress.area);
    },
    //选择省份
    getCityData(val) {
      //清空市，和区
      this.caddress.city = "";
      this.caddress.area = "";
      this.$emit("change", this.caddress); //发送改变
      this.cityArray = [];
      this.areaArray = [];
      for (let ad of this.addressArray) {
        if (ad.areaName === val) {
          this.cityArray = ad.childAreaList;
          return;
        }
      }
    },
    getAreaData(val) {
      //清空区
      this.caddress.area = "";
      this.$emit("change", this.caddress); //发送改变
      this.areaArray = [];
      for (let area of this.cityArray) {
        if (area.areaName === val) {
          this.areaArray = area.childAreaList;
          return;
        }
      }
    },
    //地区数据变动后
    onAreaChanged(val) {
      this.$emit("change", this.caddress); //发送改变
    }
  }
};
</script>

<style lang="scss" scoped>
.area-cascader {
  display: inline-block;
  .el-select {
    width: 200px !important;
    margin-right: 15px;
  }
}
.address-pcc-input {
  width: 200px !important;
  margin-right: 15px;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>