import axiosHttp from "./axiosHttp";
import userModule from "../module/userModule"

function download(url, params, opt) {
    var that = this;
    var httpUrl = that.getUrl(url);
    //为了启用session，没有header 需要添加一个参数
    let token = config.getSessionToken();
    if (httpUrl.indexOf("?") > 0) {
        httpUrl += "&"
    } else {
        httpUrl += "?"
    }
    httpUrl += "authtoken_sid=" + token;

    //创建元素
    let aLink = document.createElement('a');//创建元素不挂载
    aLink.setAttribute("download", "");
    aLink.setAttribute("href", httpUrl);
    document.body.appendChild(aLink);//fire fox和ie 一定要加这一句

    //触发下载事件
    aLink.click();
    document.body.removeChild(aLink);
}

export default {
    baseUrl: "/ioProgress/",
    download4free(fileUrl) {
        //创建元素
        let aLink = document.createElement('a'); //创建元素不挂载
        //console.log(httpUrl);
        aLink.setAttribute("download", "");
        aLink.setAttribute("href", fileUrl);
        document.body.appendChild(aLink); //fire fox和ie 一定要加这一句
        //触发下载事件
        aLink.click();
        document.body.removeChild(aLink);
    },
    /*下载文件，并删除文件*/
    download(module, fileName, isDel, downloadName="") {
        if (isDel === null) {
            isDel = false;
        }
        //let httpUrl = 'http://192.168.0.200:8090/ccs-api-web' + `/ioProgress/download?module=${module}&fileName=${fileName}&isDel=${isDel}`;
        let httpUrl = `${axiosHttp.baseURL}${this.baseUrl}download?module=${module}&fileName=${fileName}&isDel=${isDel}`;
        let token = userModule.token;

        if (httpUrl.indexOf("?") > 0) {
            httpUrl += "&"
        } else {
            httpUrl += "?"
        }
        httpUrl += "authtoken_sid=" + token;
        //创建元素
        /* 这种方式downloadName无法生效 */
        let aLink = document.createElement('a'); //创建元素不挂载
        aLink.setAttribute("download", "");
        aLink.setAttribute("href", httpUrl);
        document.body.appendChild(aLink); //fire fox和ie 一定要加这一句
        //触发下载事件
        aLink.click();
        document.body.removeChild(aLink);

        /* 按给定的文件名下载
        const xhr = new XMLHttpRequest();
        xhr.open('GET', httpUrl, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
            const url = window.URL.createObjectURL(xhr.response);
            const a = document.createElement('a');
            a.href = url;
            a.download = downloadName;
            a.click();
            a.remove();
        }*/

    },

    /*获取上传地址*/
    getUploadUrl(module) {
        let url = `${axiosHttp.baseURL}${this.baseUrl}upload`;
        url = url + "?module=" + module;
        return url;
    },
    modifyFileName(param) {
        let url = `${this.baseUrl}modifyFileName`;
        return axiosHttp.POST(url,param);
    }
    /*删除文件*/
    // remove(module, fileName) {
    //     let url = this.baseUrl + "remove?module=" + module + "&fileName=" + fileName;
    //     let tip = { successTip: false };
    //     return httpClient.httpDelete(url, null, tip);
    // }
}