<template>
  <div>
    <el-form label-width="120px" :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="showReceive">新增领用人</el-button>
      </el-form-item>
      <el-form-item label="领用人名称:">
        <el-input v-model="searchInfo.receiveName" />
      </el-form-item>      
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="receiveName"
        label="领用人名"
        :show-overflow-tooltip="true"
        width="260px"
      ></el-table-column>
      
      <el-table-column width="260px" label="是否启用">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.enabled"
            @click.native="toggleEnable(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="备注"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column label="操作" width="260px">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="showDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              href="javascript: void(0);"
              title="编辑"
              @click="toEdit(scope.row)"
            >
              <i class="CCSFont icon-table_1"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    
    <!--新增领用人-->
    <el-dialog :title="this.currentInfo.receiveId ? '编辑领用人' : '新增领用人'" :visible.sync="receiveDialog" @close="closeAddDialog" width="606px">
        <el-form label-width="160px">
          <el-form-item label="领用人名称：">
            <el-input v-model="currentInfo.receiveName"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input type="textarea" class="area-wrap" v-model="currentInfo.remark"></el-input>
          </el-form-item>  
          <el-form-item label="是否启用">
            <el-switch
              :value="currentInfo.enabled"
              @click.native="editEnable()"
            ></el-switch>
          </el-form-item>          
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="receiveDialog = false">取消</el-button>
          <el-button type="primary" @click="addReceive">确 定</el-button>
        </div>
    </el-dialog>

    <!--领用人详情-->
    <el-dialog title="领用人详情" :visible.sync="receiveDetailDialog" width="606px">
        <el-form label-width="140px">
          <el-form-item label="领用人名称：">
            {{receiveDetail.receiveName}}
          </el-form-item>
          <el-form-item label="备注：">
            {{receiveDetail.remark}}
          </el-form-item>
          <el-form-item label="是否启用：">
            {{receiveDetail.enabled == true ? '是' : '否'}}
          </el-form-item>          
        </el-form>        
    </el-dialog>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  name: "whmReceive",
  data() {
    return {
      value: [],
      options: [],
      currentInfo: {
        receiveId: "",
        receiveName: "",
        remark: "",
        enabled: true,
      },
      searchInfo: {
        receiveName: "",
      },
      items: {
        receiveName: { filter: "like" },
      },
      receiveDialog: false, //是否显示 添加修改领用人窗口
      receiveDetailDialog: false, //是否显示 领用人详情窗口
      receiveDetail: {
        receiveName: "",
        remark: "",
        enabled: true,
      },
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.loadTableData();
  },
  methods: {
    closeAddDialog() {
      this.currentInfo.receiveId = ""
      this.currentInfo.receiveName = ""
      this.currentInfo.remark = ""
    },
    //添加、编辑领用人
    addReceive(){
      if(!this.currentInfo.receiveName){
        this.$message({
          type: "warning",
          message: "请添加领用人!",
        });
        return
      }
      let params = {
        receiveId: this.currentInfo.receiveId,
        receiveName: this.currentInfo.receiveName,
        remark: this.currentInfo.remark,
        enabled: this.currentInfo.enabled ? 1 : 0,
      };
      this.loading = true;
      WhmList.saveReceive(params).then((res) => {
        this.loading = false;
        this.$message({
          type: "success",
          message: "添加领用人成功!",
        });              
        this.loadTableData()
        this.receiveDialog = false
        if(this.currentInfo.receiveId){
          this.currentInfo.receiveId = ""
        }
        this.currentInfo.receiveName = "" 
      });
    },
    // 切换 编辑 的状态
    editEnable() {this.currentInfo.enabled = !this.currentInfo.enabled },
    loadTableData() {
      this.$refs.pageTable.tableLoading = true;
      WhmList.whmReceive(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            receiveId: e.receiveId,
            receiveName: e.receiveName,
            remark: e.remark,
            enabled: e.enabled == 1 ? true : false,
          };
        });
        this.querySuccess(data);
      });
    },
    showReceive(){ this.receiveDialog = true },
    showDetail(row) {    
      this.receiveDetail.receiveName = row.receiveName
      this.receiveDetail.enabled = row.enabled
      this.receiveDetail.remark = row.remark
      this.receiveDetailDialog = true
    },
    toEdit(row) {
      //console.log(row)
      this.currentInfo.receiveId = row.receiveId      
      this.currentInfo.receiveName = row.receiveName
      this.currentInfo.remark = row.remark
      this.currentInfo.enabled = row.enabled
      this.receiveDialog = true 
    },
    //切换列表启用状态
    toggleEnable(row) {
      const that = this;
      this.$confirm(`是否切换领用人'${row.receiveName}'的启用状态？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.enabled = !row.enabled == true ? 1 : 0; // 切换状态
          row.id = row.receiveId;
          WhmList.updateReceiveEnable(row).then((res) => {
            that.loadTableData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.demo-form-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.el-form--inline .el-form-item {
  margin-bottom: 0;
}
.header-search {
  margin: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
}
.ws-page-table .icon-operation a {
  margin-right: 20px;
}
.el-form .el-form textarea,.el-form .el-textarea__inner{ width: 200px; height: 80px}
</style>