<template>
  <el-dialog :title="'审核进度'" :visible.sync="dialogVisible" width="700px">
    <el-form label-width="120px" label-height="50px">
      <el-form-item label="姓名：">
        <span>{{info.memberName}}</span>
      </el-form-item>
      <el-form-item label="电话：">
        <span>{{info.memberTel}}</span>
      </el-form-item>
      <el-form-item label="审核通过后">
        <span>{{info.timeSpan}} 小时内有效</span>
      </el-form-item>
    </el-form>
    <div style="height: 200px">
      <el-steps direction="vertical" :active=active size="small"  align-center>
          <el-step :title="info.memberName+'   发起申请'" :description="format((new Date(info.createTime)))" ></el-step>
          <el-step :title="nothing" :description="time" ></el-step>

      </el-steps>
      <div style="display: flex;  margin-left: 28px; margin-bottom: 10px">
        <span class="span" v-show="flag">拒绝原因:{{info.refuseReason}}</span>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

    import BimInviteService from "API/bim/BimInviteService";
    import tools from "COMJS/tools";



    export default {
        props: {
            inviteId: String
        },
        watch: {
            dialogVisible() {
                if (this.inviteId) {
                    this.getInviteDetail();
                }

            }
        },
        data() {
            return {
                dialogVisible: false,
                info: {},
                nothing:"暂未审核",
                time:"",
                active:"1",
                flag:false
            };

        },

        methods: {
            getInviteDetail() {
                this.info = {};
                this.nothing="";
                this.time="";
                BimInviteService.getDetail(this.inviteId).then(data => {
                    this.info = data;
                    if(data.auditState== '1'){
                        this.nothing="审核通过";
                        this.flag=false;
                        this.active="2";
                        // this.time =  this.format((new Date(data.auditTime)));
                        this.time =  this.format((new Date(data.createTime)));
                    }else if(data.auditState=="0"){
                        this.flag=true;
                        this.nothing="审核拒绝";
                        this.active="2";
                        this.time = this.format((new Date(data.auditTime)));
                    }else{
                        this.flag=false;
                        this.nothing="暂未审核";
                        this.time="";
                        this.active="1";
                    }
            });
            },
            format(date){
                return tools.formatDate(date,"yyyy-MM-dd hh:mm:ss")
            }

         }
    };
</script>


<style scoped lang="scss">
  .detail-form {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  .form-item {
    display: flex;
    font-size: 14px;
    margin-bottom: 18px;
  .form-label {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  .form-value {
    flex: 1;
    color: #666;
  }
  }
  .image-item {
    margin-top: 30px;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  }
  .box-title {
    background-color: #f6f9fc;
  }

  label{
    display:inline-block;
    width:100px;

    text-align:right;
  }
  .el-form /deep/ .el-form-item__label {
    font-size: 16px;
  }
  .el-form /deep/ .el-form-item__content {
    font-size: 16px;
  }
  .span{
    color: #1B73E8;
  }
</style>