import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


import messages from '../i18n/langs'
//从localStorage中拿到用户的语言选择，如果没有，那默认中文。
let lan =  localStorage.getItem("locale");
if('cn'!==lan && 'en'!==lan){
    lan = "cn";
}

localStorage.setItem("locale",lan)
const i18n = new VueI18n({
    locale: lan,
    messages
});
//国际化部分的处理，待研究
if('cn'===lan){
    //ELEMENT.locale(ELEMENT.lang.zhCN);
}else {
    //ELEMENT.locale(ELEMENT.lang.en);
}

//Vue.locale('en', ELEMENT.lang.en)
//Vue.locale('cn', ELEMENT.lang.zhCN)
//ELEMENT.locale(ELEMENT.lang.zhCN);
export default i18n;