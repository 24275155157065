<template>
  <div class="tree-box tree-first box-shadow-unify">
    <div class="tree-title">
      {{treeName}}
      <span v-if="list.length">（{{list.length}}）</span>
      <i v-if="canAdd" class="el-icon-circle-plus-outline tree-add-btn" @click="add"></i>
    </div>
    <div class="tree-list">
      <div
        class="tree-item"
        v-for="node in list"
        :class="{'active': node.id === selectedNode.id}"
        @click="selectNode(node)"
        :key="node.id"
      >
        <span class="name">{{node.name}}</span>
        <span class="icon-group">
          <template v-if="node.id === selectedNode.id">
            <a href="javascript: void(0);" title="查看" v-if="canSee" @click="seeDetail(node)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" title="编辑" v-if="canEdit" @click="edit(node)">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <a href="javascript: void(0);" title="删除" v-if="canDelete" @click="deleteNode(node)">
              <i class="el-icon-delete-solid"></i>
            </a>
          </template>
          <i class="el-icon-arrow-right" style="color: #666;" v-if="isArrow"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    treeName: String,
    list: {
      type: Array,
      default: function() {
        return [];
      }
    },
    canAdd:{
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canSee: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    isArrow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedNode: {}
    };
  },
  methods: {
    selectNode(node) {
      this.selectedNode = node;
      this.$emit("selectNode", node);
    },
    seeDetail(node) {
      this.$emit("seeDetail", node);
    },
    edit(node) {
      this.$emit("edit", node);
    },
    add(){
      this.$emit("add", {});
    },
    deleteNode(node) {
      this.$emit("deleteNode", node);
    }
  }
};
</script>

<style lang="scss">
@import "~ASSETS/css/common/tool.scss";
@import "~ASSETS/css/components/levelTree.scss";
</style>