import axiosHttp from "../axiosHttp";

export default {
    listByParam(param) {
        return axiosHttp.POST("/purContract/listByParam", param);
    },
    countByTransportState() {
        return axiosHttp.POST("/purContract/countByGroup", {});
    },
    savePurContract(param) {
        return axiosHttp.POST("/purContract/savePurContract", param);
    },
    queryLog(param) {
        return axiosHttp.POST("/purContract/queryLog", param);
    },
    saveFileUrl(param) {
        return axiosHttp.POST("/purContract/saveFileUrlInDetail", param);
    },
    listShipper() {
        return axiosHttp.POST("/purContract/listShipper", {});
    },
    listGoodsByContractId(contractId) {
        return axiosHttp.POST("/purContract/listGoodsByParam", {contractId: contractId});
    },
    saveContractGoods(param) {
        return axiosHttp.POST("/purContract/saveContractGoods", param);
    },
    delContractGoods(contractGoodsId) {
        return axiosHttp.POST("/purContract/delContractGoods", {contractGoodsId: contractGoodsId});
    },
    queryShipTrailList(param){
        return axiosHttp.POST("/purContract/queryShipTrailList", param);
    }

};
