import QRCode from 'qrcode';

const generateQR = async (text) => {
    try {
        const opts = {
            errorCorrectionLevel: 'H',
            type: 'image/png',
            quality: 0.3,
            margin: 1,
        };
        const qrImage = await QRCode.toDataURL(text, opts);
        //console.log(qrImage);
        return qrImage
        // 你可以在这里将qrImage插入到HTML中的某个元素，比如一个<img>标签
    } catch (err) {
        console.error(err);
    }
};

export default {
    generateQR: generateQR
}