<template>
  <el-dialog title="选择库品" :visible.sync="dialogVisible" width="800px">
    <div class="select-brand-content">
      <div class="input-search">
        <el-input v-model="searchInfo.skuCode" placeholder="库品编码"></el-input>
        <el-button type="primary" @click="loadTableData">搜索</el-button>
      </div>
      <ws-page-table
          :data="tableData"
          ref="pageTable"
          :pageable="true"
          :pageInfo="pageInfo"
          @current-change="handleCurrentChange"
          @pageChange="loadTableData"
          :selection="'single'"
      >
        <el-table-column
            prop="skuName"
            label="库品名称"
            :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
            prop="skuCode"
            label="库品编码"
            :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import WhmList from "API/whm/WhmList";

export default {
  data() {
    return {
      dialogVisible: false,
      selectedSku: {}
    };
  },
  computed: {

  },
  mixins: [pageTableMixin],
  mounted() {
    //this.querySupplierList();
  },
  methods: {
    submit() {
      this.$emit("submit", this.selectedSku);
      this.dialogVisible = false;
    },
    handleCurrentChange(val) {
      if (val) {
        //console.log(val)
        this.selectedSku = val
      }
    },
    loadTableData() {
      //this.$refs.pageTable.tableLoading = true;
      WhmList.queryWhmPages(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            thingTypeName: e.level ? e.level.thingTypeName : "", //分类
            skuSpecName: e.skuSpecName ? e.skuSpecName.name : "", //规格名
            skuSpec: e.skuSpec ? e.skuSpec.name : "", //规格值
            skuUnit: e.skuUnit ? e.skuUnit.name : "", //规格单位
            shelfLife: e.shelfLife, //保质期
            actualNum: e.actualNum, //当前库存
            warningNum: e.warningNum,
            soldOutNum: e.soldOutNum,
          };
        });
        this.querySuccess(data);
      });
    },
  }
};
</script>

<style scoped lang="scss">
.select-brand-content {
  .input-search {
    padding: 20px;
    border-bottom: 1px solid $borderColor;
    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }
  .search-result {
    .search-result-item {
      cursor: pointer;
      line-height: 50px;
      padding: 0 20px;
      color: #666;
      border-bottom: 1px solid $borderColor;
      &:hover {
        background-color: rgba(51, 51, 51, 0.1);
      }
    }
  }
  .search-result-item.active,.search-result-item.active:hover{ background-color:#E8F1FD;}
}
</style>