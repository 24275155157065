<template>
  <div class="logistics-wrap">
    <el-form :inline="true" class="rec-inline1">
      <el-button type="primary" class="mr40" @click="toEdit"
        >创建订单</el-button
      >
      <el-form-item label="信用额度：" v-if="userInfo.accountType == 'agent_credit'">
        ¥{{ userInfo.alsoLineOfCredit }}
      </el-form-item>
      <el-form-item label="剩余余额：" v-if="userInfo.accountType == 'agent_deposit'">
        ¥{{ userInfo.balanceNum }}
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="rec-inline">
      <el-form-item label="商品编码：">
        <el-input v-model="searchInfo.goodsCode"></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker
          v-model="filterTimes"
          @change="changePickerDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="下单状态：">
        <el-select v-model="searchInfo.orderState" clearable placeholder="选择类型">
          <el-option
            v-for="item in orderStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column
        prop="createTime"
        label="下单时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="下单状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="totalAmount"
        label="下单金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="detail" label="商品详情"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
              href="javascript: void(0);"
              title="查看"
              @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a
              v-show="scope.row.status == '待支付'"            
              href="javascript: void(0);"
              title="付款"
              @click="batchPay(scope.row)"
            >
              <i class="icon-pay"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    <!--支付-->
    <order-pay-popup
      @reloadAfterPay="loadTableData()"
      ref="orderPayPopup"
    ></order-pay-popup>
  </div>
</template>

<script>
//import sysConstant from "@/constant/SysConstant";
import pageTableMixin from "@/mixin/pageTableMixin.js";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";
import dealerService from "API/dea/dealerService";
import translate from "ASSETS/js/translate";
import orderPayPopup from "../order/orderPayPopup.vue";
import userModule from "../../../module/userModule";

export default {
  data() {
    return {
      value: [],
      options: [],
      searchInfo: {
        createTimeBeginDate: "",
        createTimeEndDate: "",
        goodsCode: "",
        orderState: "",
      },
      orderStatus: [
        {
          value: "unPaid",
          label: "未支付",
        },
        {
          value: "paid",
          label: "已支付",
        },
      ],
      whmInfo: {},
      filterTimes: [],
      userInfo: {},
    };
  },
  mixins: [pageTableMixin],
  components: {
    orderPayPopup,
  },
  mounted() {
    //this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      dealerService.queryUserDealerInfo(JSON.parse(userModule.PPUser).userId).then((data) => {
        //console.log(data)
        this.userInfo = data
        loading.close();
      });
    },
    toDetail(row){
      this.$router.push(`/som/logisticsDetail/${row.orderId}`);
    },
    batchPay(row) {
      //字符串数组 == 字符串列表
      this.$refs.orderPayPopup.openPayPopup({ 'orderIdList': [row.orderId] });
    },
    changePickerDate(e) {
      if (e === null) {
        this.searchInfo.createTimeBeginDate = "";
        this.searchInfo.createTimeEndDate = "";
      } else {
        this.filterDateTimer = null; //当选择具体时间范围之后  取消快捷时间按钮的选中状态
        this.searchInfo.createTimeBeginDate = tools.formatDate(
          new Date(e[0]),
          "yyyy-MM-dd"
        );
        this.searchInfo.createTimeEndDate = tools.formatDate(
          new Date(e[1]),
          "yyyy-MM-dd"
        );
      }
    },
    toEdit() {
      this.$router.push("/som/logisticsAdd");
    },
    loadTableData() {
      this.getUserInfo();
      this.$refs.pageTable.tableLoading = true;
      WhmList.queryLogisticsOrder(this.queryParams).then((data) => {
        for(let i in data.list){
          data.list[i].detail = ""
          if(data.list[i].orderGoodsList.length > 0){
            for(let j in data.list[i].orderGoodsList){
              data.list[i].detail += data.list[i].orderGoodsList[j].skuBatchNumData + '+'
            }            
          }
          data.list[i].detail = data.list[i].detail.substring(0,data.list[i].detail.length-1)
        }
        data.list = data.list.map((e) => {
          return {
            createTime: tools.formatDate(
              new Date(e.createTime),
              "yyyy-MM-dd hh:mm:ss"
            ),
            orderId: e.orderId,
            status: translate.orderState(e),
            orderCode: e.orderCode,
            totalAmount: e.totalAmount,
            detail: e.detail,          
            remark: e.remark,
          };          
        });
        //console.log(data.list)
        this.querySuccess(data);
      });
    },
  },
};
</script>
<style>
.logistics-wrap .rec-inline {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.logistics-wrap .rec-inline1 {
  padding: 20px 0 0 20px;
  background: #fff;
}
.logistics-wrap .el-form--inline .el-form-item {
  margin-right: 20px;
  margin-bottom: 0;
}
.logistics-wrap .el-input--suffix .el-input__inner {
  width: 200px;
}
.icon-download,
.icon-delivery {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-size: cover;
}
.icon-download {
  background-image: url("~ASSETS/images/download.png");
}
.icon-delivery {
  background-image: url("~ASSETS/images/delivery.png");
}
.logistics-wrap .icon-operation a {
  margin-right: 15px;
}
.mr40 {
  margin-right: 40px;
}
.icon-pay {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 16px;
  background-image: url("~ASSETS/images/pay.png");
  background-size: cover;
}
</style>