import axiosHttp from "../axiosHttp";

export default {
    //
    queryPageList(params) {
        return axiosHttp.POST('/bimUserNotice/pageQueryNotice', params, false);
    },
    //
    getNoticeDetails(noticeId) {
        return axiosHttp.GET(`/bimUserNotice/queryDetails/${noticeId}`);
    },
    saveOrUpdateAndSendNotice(param) {
        return axiosHttp.POST('/bimUserNotice/saveOrUpdateAndSendNotice',param);
    },
    revokeNotice(noticeId){
        const param = {"noticeId":noticeId}
        return axiosHttp.POST('/bimUserNotice/revokeNotice',param,false);
    }
}