<template>
  <div id="returnInformationPop">
    <el-dialog :title="afterDetail.handleState=='wait_return'?'补充退货单号':'售后详情'" :visible.sync="dialogVisible" width="30%">
      <el-row type="flex" align="middle">
        <el-col :span="4">售后原因：</el-col>
        <el-col :span="15">
          <el-input :value="afterDetail.reason" placeholder="售后原因" disabled></el-input>
        </el-col>
      </el-row>
      <el-row class="row-item" type="flex" align="middle">
        <el-col :span="4">客服备注：</el-col>
        <el-col :span="15">
          {{afterDetail.remark}}
        </el-col>
      </el-row>
      <el-row class="row-item">
        <el-col :span="4">售后拍照：</el-col>
        <el-col :span="20">
          <el-image v-for="(item,index) in srcList" :key="index"
                    :fit="'cover'"
                    style="width:100px;height: 100px;margin-right:10px"
                    :src="srcList[index]" :preview-src-list="srcList"></el-image>
        </el-col>
      </el-row>
      <el-row class="row-item" type="flex" align="middle" v-if="afterDetail.handleState=='wait_return'">
        <el-col :span="4">快递公司：</el-col>
        <el-col :span="15">
          <el-select style="width: 100%" v-model='companyValue' placeholder="请选择快递公司">
            <el-option :value="item.value" :label="item.label" v-for="item in backTransportCompanyList" :key="item.value"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="row-item" type="flex" align="middle" v-if="afterDetail.handleState=='wait_return'">
        <el-col :span="4">快递单号：</el-col>
        <el-col :span="15">
          <el-input v-model="backTransportNo" placeholder="请输入快递单号"></el-input>
        </el-col>
      </el-row>
      <el-row style="color:rgba(255, 141, 26, 100)" v-if="afterDetail.handleState=='wait_return'">
        请退回商品，仅接受德邦快递运费到付，其他快递运费请自理。
      </el-row>
      <el-row style="color:rgba(255, 141, 26, 100);margin-top:10px" v-if="afterDetail.handleState=='wait_return'">
        地址：渥康仓库，15269307336，山东省淄博市张店区四宝山街道宝山路北首
      </el-row>
      <el-row>

      </el-row>
      <span slot="footer" class="dialog-footer" v-if="afterDetail.handleState=='wait_return'">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fullBackTransport } from "@/api/som/afterService.js";
export default {
  data() {
    return {
      dialogVisible: false,
      companyValue: "",
      backTransportNo: "",
      srcList: [],
      afterDetail: {},
      backTransportCompanyList: [
        {
          label: "德邦",
          value: "德邦",
        },
        {
          label: "圆通",
          value: "圆通",
        },
        {
          label: "邮政",
          value: "邮政",
        },
        {
          label: "申通",
          value: "申通",
        },
        {
          label: "顺丰",
          value: "顺丰",
        },
        {
          label: "京东",
          value: "京东",
        },
        {
          label: "其他",
          value: "其他",
        },
      ],
    };
  },
  methods: {
    getAfterDetail(data) {
      console.log(data);
      this.srcList = data.imgs ? data.imgs.split(",") : [];
      this.afterDetail = data;
    },
    confirm() {
      let params = {
        orderAfterId: this.afterDetail.orderAfterId,
        backTransportCompany: this.companyValue,
        backTransportNo: this.backTransportNo,
      };
      console.log(params);
      fullBackTransport(params).then((data) => {
        this.$message.success("提交成功");
        this.dialogVisible = false;
        this.$emit("auditOk", "");
      });
    },
  },
};
</script>

<style scoped>
.row-item {
  padding: 10px 0;
}
</style>