<template>
  <div>
    <div class="invoice-search-content">
      <el-form :inline="true" label-width="120px" v-if="activeName=='0'">
        <el-form-item label="支付方式：">
          <el-select v-model="pageInfo.payChannel" @change="searchByKey">
            <el-option v-for="item in payChannelList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <p style="color: #9f9d9d;">* 必选，不同支付方式不能合并开票</p>
        </el-form-item>
        <el-form-item label="支付账号：" v-if="pageInfo.payChannel=='giro'">
          <el-select v-model="pageInfo.payAccountName" @change="searchByKey">
            <el-option v-for="(item,idx) in giroAccountNameList" :key="idx" :label="item" :value="item">
            </el-option>
          </el-select>
          <p style="color: #9f9d9d;">* 必选，不同付款方不能合并开票</p>
        </el-form-item>
      </el-form>

      <el-form :inline="true" label-width="120px" v-if="activeName=='0'">
        <!--
        <el-form-item label="收件人姓名：">
          <el-input v-model="pageInfo.recipientName"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号：">
          <el-input v-model="pageInfo.recipientPhoneNum" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'');"></el-input>
        </el-form-item>
        <el-form-item label="订单号：">
          <el-input v-model="pageInfo.orderCode"></el-input>
        </el-form-item>
        -->
        <el-form-item label="订单批次：">
          <el-input v-model="pageInfo.batchCode"></el-input>
        </el-form-item>
        <el-form-item label="时间范围：">
          <el-date-picker v-model="pageInfo.createTimeDateRange" type="daterange"
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="searchByKey">搜索</el-button>
      </el-form>
      <el-form :inline="true" label-width="100" v-if="activeName=='1' && false">
        <el-form-item label="收件人姓名：">
          <el-input v-model="invoicePageInfo.recipientName"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号：">
          <el-input v-model="invoicePageInfo.recipientPhoneNum" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'');"></el-input>
        </el-form-item>
        <el-form-item label="订单号：">
          <el-input v-model="invoicePageInfo.orderCode"></el-input>
        </el-form-item>
        <el-form-item label="订单批次号：">
          <el-input v-model="invoicePageInfo.batchCode"></el-input>
        </el-form-item>
        <el-button type="primary" @click="searchByKey">搜索</el-button>
      </el-form>
    </div>
    <el-tabs style="margin-top: 20px;" v-model="activeName" type="card">
      <el-tab-pane label="可申请" name="0">
        <div style="margin-left: 10px;margin-top: 10px;">
          <el-button type="primary" :disabled="tableData.length==0" @click="allInvoice">全部开票
          </el-button>
        </div>
        <el-table :data="tableData" ref="pageTable" @selection-change="changeChoose">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="orderCode" label="订单号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="recipientName" label="收件人姓名" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="recipientPhoneNum" label="收件人手机号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="payChannel" label="支付方式" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="batchCode" label="批次号" :show-overflow-tooltip="true"></el-table-column>
          <!-- <el-table-column prop="goodsCode" label="商品编码" :show-overflow-tooltip="true">
</el-table-column> -->
          <el-table-column prop="showTotalAmount" label="实付金额" :show-overflow-tooltip="true">
            <!--<template slot-scope="scope">-->
            <!--{{scope.row.totalAmount}}-->
            <!--</template>-->
          </el-table-column>
          <el-table-column prop="showGoodsPaymentAmount" label="可开票金额" :show-overflow-tooltip="true">
            <!--<template slot-scope="scope">-->
            <!--{{scope.row.goodsPaymentAmount}}-->
            <!--</template>-->
          </el-table-column>
          <el-table-column prop="createTime" label="下单时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ formart(new Date(scope.row.createTime), "yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="finishTime" label="完成时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ formart(new Date(scope.row.finishTime), "yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; justify-content: space-between;align-items: center;">
          <div style="display: flex; align-items: center;">
            <div style="margin-left: 10px;">
              <el-button type="primary" :disabled="checkList.length==0" @click="openModal">下一步</el-button>
            </div>
            <div style="margin-left: 20px;">
              选择{{ showCount }}个订单，可申请金额 ¥ {{ showPrice }}
            </div>
          </div>
          <el-pagination @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已申请" name="1">
        <el-table :data="invoiceList">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="orderCode" label="申请时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ formart(new Date(scope.row.createTime), "yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="invoiceType" label="发票类型" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.invoiceType == 'company_sp' ? '企业专票' : scope.row.invoiceType == 'company' ? '企业普票' : '个人'}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="发票抬头" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="invoiceAmount" label="开票总额" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.invoiceAmount }}
            </template>
          </el-table-column>
          <el-table-column prop="completeState" label="开票状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.stateStr }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="icon-operation" style="display: flex;justify-content: center;">
                <a href="javascript: void(0);" title="查看">
                  <i class="CCSFont icon-table_2" @click="seeInvoice(scope.row.invoiceId)" style="color: #1B73E8;"></i>
                </a>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changeInvoicePage" background layout="prev, pager, next" :total="invoiceTotal"></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!--开发票弹窗-->
    <el-form label-width="150px" class="form" :rules="rules" :model="invoice" ref="ruleForm">
      <el-dialog title="开发票" :visible.sync="dialogVisible" width="40%">
        <div>
          <div style="display: flex; justify-content: space-between;margin-top: 50px;">
            <div>
              共{{ orderCount }}个订单
            </div>
            <div style="display: flex;align-items: center;">
              开票金额：<span style="color: #1B73E8; font-size: 24px;">{{ allPrice }}</span>元
            </div>
          </div>
          <div style="margin-top: 27px;">
            <el-tabs v-model="invoiceType" type="card">
              <el-tab-pane :disabled="disabledWay" label="个人" name="personal"></el-tab-pane>
              <el-tab-pane :disabled="disabledWay" label="企业" name="comp"></el-tab-pane>
            </el-tabs>
            <div>
              <div style="display: flex;">
                <div class="flex1">
                  抬头
                </div>
                <div class="flex2">
                  <el-form-item prop="name" :label="invoiceType=='comp'?'企业名称：':'发票抬头'">
                    <el-input :disabled="disabledChangeName" v-model="invoice.name" :placeholder="invoiceType=='comp'?'企业名称':'姓名'"></el-input>
                  </el-form-item>
                  <el-form-item prop="dutySign" label="企业税号：" v-if="invoiceType=='comp'">
                    <el-input v-model="invoice.dutySign" placeholder="企业税号"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-switch v-model="flag_sp" active-text="专票" inactive-text="普票" v-if="invoiceType=='comp' && allPrice>10000">
                    </el-switch>
                  </el-form-item>
                </div>
              </div>
              <div style="margin-top: 20px;display: flex;" v-if="invoiceType =='personal'">
                <div class="flex1">其他信息</div>
                <div>
                  <el-form-item prop="cardType" label="证件类型：" v-if="invoiceType=='personal'">
                    <el-select v-model="invoice.cardType" placeholder="请选择证件类型" style="width:100%" @change="selectChange($event)">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="idCard" label="证件号码：" v-if="invoiceType=='personal'">
                    <el-input v-model="invoice.idCard" placeholder="证件号码"></el-input>
                  </el-form-item>
                  <el-form-item prop="phoneNum" label="手机号码：">
                    <el-input v-model="invoice.phoneNum" maxlength="11"
                              onkeyup="value=value.replace(/[^\d]/g,'');" placeholder="手机号码"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="margin-top: 20px;display: flex;" v-if="invoiceType =='comp'">
                <div class="flex1">其他信息</div>
                <div>
                  <el-form-item label="银行账户：">
                    <el-input v-model="invoice.bankAccount" onkeyup="value=value.replace(/[^\d]/g,'');" placeholder="银行账户号码"></el-input>
                  </el-form-item>
                  <el-form-item label="开户行：">
                    <el-input v-model="invoice.depositBank" placeholder="开户银行名称"></el-input>
                  </el-form-item>
                  <el-form-item :label="invoiceType=='comp'?'企业地址：':'单位地址：'">
                    <el-input v-model="invoice.companyAddr" :placeholder="invoiceType=='comp'?'企业地址信息':'单位地址信息'"></el-input>
                  </el-form-item>
                  <el-form-item label="电话号码：">
                    <el-input v-model="invoice.phoneNum" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'');" placeholder="单位电话号码"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="margin-top: 20px; display: flex;">
                <div class="flex1">接收方式</div>
                <div>
                  <el-form-item prop="email" label="接收邮箱">
                    <el-input v-model="invoice.email" placeholder="接收发票"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="commitInvoice('ruleForm')">保存提交</el-button>
        </div>
      </el-dialog>
    </el-form>

    <el-dialog title="提示" :visible.sync="dialogVisibleWay" width="40%">
      <div>
        您申请的开票订单中含有余额支付订单，此类订单仅支持开具发票至会员本人。
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="dialogVisibleWay = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = true,dialogVisibleWay = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tableMixins from "@/mixin/pageTableMixin.js";
import BigNumber from "COMJS/bignumber.min"
import tools from "COMJS/tools";
import SomInvoiceService from "API/som/SomInvoiceService";
import somOrderService from "API/som/orderService";
import userInfoService from "API/bim/userInfoService";
import userModule from "../../../module/userModule";
import translate from "ASSETS/js/translate";
import faDealerInfoService from "API/dea/dealerService";
import orderPaymentService from "API/som/orderPaymentService.js"

export default {
  components: {},

  data() {
    return {
      activeName: "",
      searchKey: "",
      //订单查询
      tableData: [],
      options: [
        {
          label: "身份证",
          value: "idCard",
        },
        {
          label: "台胞证",
          value: "twCard",
        },
        {
          label: "护照",
          value: "passport",
        },
      ],
      total: 0,
      showOtherFilter:false,
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        orderType: "import",
        orderState: "finished",
        invoiceFlag: "0",
        afterState: "0",
        payChannel: "giro",
        payAccountName:'',
        orderOperateIdList:[],
        createTimeDateRange:[],
      },
      createTimeEnd4CreditAgent:null,//Date
      payChannelList: [],
      giroAccountNameList:[],
      giroAccountNameMap:{},
      //发票查询
      invoiceList: [],
      invoiceTotal: 0,
      invoicePageInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      //

      invoiceType: "personal",
      flag_sp: false,
      checkList: [],
      invoice: {
        orderIds: [],
      },

      selectOrderIds: [],
      allPrice: 0,
      orderCount: 0,
      showPrice: 0,
      showCount: 0,

      rules: {
        name: [
          {
            required: true,
            message: "请输入抬头",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur",
          },
          {
            required: true,
            message: "不能超过18个字符",
            max: 18,
            trigger: "blur",
          },
        ],
        phoneNum:[
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        cardType: [
          {
            required: true,
            message: "请选择证件类型",
            trigger: "blur",
          },
        ],
      },
      //要开发票的订单含有余额支付，提示弹窗
      dialogVisibleWay: false,
      //开发票，信息录入弹窗
      dialogVisible: false,
      //开发票，是否可切换个人公司
      disabledWay: false,
      disabledChangeName: false, // 限制不能编辑抬头
    };
  },
  watch: {
    invoiceType(newVal) {
      if (newVal == "comp") {
        this.rules.dutySign = [
          {
            required: true,
            message: "请输入企业税号",
            trigger: "blur",
          },
        ];
      } else {
        this.rules.idCard = [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur",
          },
          {
            required: true,
            message: "不能超过18个字符",
            max: 18,
            trigger: "blur",
          },
        ];
      }
    },
  },
  methods: {
    formart: tools.formatDate,
    selectChange(event) {
      //console.log(event);
      if (event == "idCard") {
        this.rules.idCard = [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur",
          },
          {
            required: true,
            message: "身份证号不正确",
            pattern:
                /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            trigger: "blur",
          },
        ];
      } else {
        this.rules.idCard = [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur",
          },
          {
            required: true,
            message: "不能超过18个字符",
            max: 18,
            trigger: "blur",
          },
        ];
      }
    },
    searchByKey() {
      //console.log('searchByKey',this.pageInfo)
      if (this.activeName == "0") {
        this.pageInfo.pageNo = 1;
        this.loadTableData();
      } else {
        this.invoicePageInfo.pageNo = 1;
        this.loadInvoiceData();
      }
    },
    //翻页
    changePage(i) {
      this.pageInfo.pageNo = i;
      this.loadTableData();
    },
    loadTableData() {
      //orderOperateIdList
      if(this.pageInfo.payChannel=='giro' && this.pageInfo.payAccountName!=''){
        let key = this.pageInfo.payAccountName
        let idList = this.giroAccountNameMap[key]
        if(idList && idList.length>0){
          this.pageInfo.orderOperateIdList = idList
        }else{
          this.pageInfo.orderOperateIdList = []
        }
      }else{
        this.pageInfo.orderOperateIdList = []
      }
      //console.log(this.pageInfo.createTimeDateRange)
      if(this.pageInfo.createTimeDateRange
          && this.pageInfo.createTimeDateRange.length==2){
        this.pageInfo.createTimeBeginDate = tools.formatDate(this.pageInfo.createTimeDateRange[0])
        this.pageInfo.createTimeEndDate = tools.formatDate(this.pageInfo.createTimeDateRange[1])
      }else{
        this.pageInfo.createTimeBeginDate = null;
        this.pageInfo.createTimeEndDate = null;
      }
      if(this.createTimeEnd4CreditAgent){
        if(this.pageInfo.createTimeEndDate){
          if(this.pageInfo.createTimeDateRange[1].getTime() < this.createTimeEnd4CreditAgent.getTime()){
            this.pageInfo.createTimeEndDate = tools.formatDate(this.createTimeEnd4CreditAgent);
          }
        }else{
          this.pageInfo.createTimeEndDate = tools.formatDate(this.createTimeEnd4CreditAgent);
        }
      }
      somOrderService.queryOrderPages(this.pageInfo).then(data=>{
        //console.log(data);
        this.total = data.total;
        this.tableData = data.list;
        this.tableData.map((e) => {
          if (e.totalAmount) {
            e.showTotalAmount = e.totalAmount.toFixed(2);
          }
          if (e.goodsPaymentAmount) {
            e.showGoodsPaymentAmount = e.goodsPaymentAmount.toFixed(2);
          }
          if (e.payChannel) {
            e.payChannel = translate.orderPayChannel(e.payChannel);
          }
          return e;
        });
      })
    },
    changeChoose(e) {
      this.checkList = e;
      this.showPrice = 0;
      this.checkList.forEach((item) => {
        //this.showPrice += item.goodsPaymentAmount;
        this.showPrice = new BigNumber(this.showPrice).add(item.goodsPaymentAmount).toNumber()
      });
      this.showCount = this.checkList.length;
    },

    //已经开票的
    changeInvoicePage(i) {
      this.invoicePageInfo.pageNo = i;
      this.loadInvoiceData();
    },
    loadInvoiceData() {
      SomInvoiceService.queryInvoicePages(this.invoicePageInfo).then(data=>{
        this.invoiceTotal = data.total;
        this.invoiceList = data.list;
        this.invoiceList.map((e) => {
          if (e.invoiceAmount) {
            e.invoiceAmount = e.invoiceAmount.toFixed(2);
          }
          if (e.revocationState == "1") {
            e.stateStr = "已撤销";
          } else if (e.revocationState == "0") {
            e.stateStr = "申请撤销";
          } else {
            if (e.completeState == "1") {
              e.stateStr = "已开票";
            } else {
              e.stateStr = "开票中";
            }
          }
          return e;
        });
      })
    },
    seeInvoice(id) {
      this.$router.push("/som/invoiceDetail/" + id);
    },

    allInvoice() {
      //this.invoice.isIssueAllInvoices = 1
      this.openModal(1);
    },
    async openModal4Agent(type){
      this.selectOrderIds = [];
      this.invoice={}
      if (type == 1) {
        //全部开票
        let data = await SomInvoiceService.getInvoiceSizeAndAmount(
            this.pageInfo
        );
        this.allPrice = data.totalAmount;
        this.orderCount = data.totalCount;
        if (this.orderCount == 0) {
          this.$message("无可开票订单");
          return;
        }
        this.selectOrderIds = data.orderIds;
      } else {
        this.allPrice = 0;
        this.checkList.forEach((item) => {
          //this.allPrice += item.goodsPaymentAmount;
          this.allPrice = new BigNumber(this.allPrice).add(item.goodsPaymentAmount).toNumber()
          this.selectOrderIds.push(item.orderId);
        });
        this.orderCount = this.checkList.length;
      }
      this.dialogVisible = true;
      //经销商都是公司
      let dealerInfo = await faDealerInfoService.queryUserDealerInfo(JSON.parse(userModule.PPUser).userId);
      let accountName;
      if (dealerInfo.dealerName && dealerInfo.dealerName.length > 0) {
        accountName = dealerInfo.dealerName;
      }
      if (dealerInfo.dealerType=='1') {
        this.invoiceType = "personal";
      } else {
        this.invoiceType = "comp";
      }
      //this.disabledWay = true;
      this.disabledWay = false;
      this.dialogVisibleWay = false;
      this.disabledChangeName = true;
      this.$set(this.invoice, "name", accountName);
    },
    async openModal(type) {
      if(this._isAgentCredit() || this._isAgentDeposit()){
        await this.openModal4Agent(type);
        return;
      }
      this.disabledWay = false;
      this.selectOrderIds = [];
      this.invoice={}
      let haveBalancePay = false;
      let haveGiroPay = false;
      let different = false;
      let giroPayCount = 0;
      if (type == 1) {
        //全部开票
        let data = await SomInvoiceService.getInvoiceSizeAndAmount(
          this.pageInfo
        );
        this.allPrice = data.totalAmount;
        this.orderCount = data.totalCount;
        if (this.orderCount == 0) {
          this.$message("无可开票订单");
          return;
        }
        haveBalancePay = data.haveBalancePay;
        giroPayCount = data.giroPayCount;
        this.selectOrderIds = data.orderIds;
        if (giroPayCount > 0) {
          if (this.selectOrderIds.length == giroPayCount) {
            //全是转账
            haveGiroPay = true;
          } else {
            this.$message.warning("存在转账支付的订单，请单独开票！");
            return;
          }
        }
      } else {
        this.allPrice = 0;
        this.checkList.forEach((item) => {
          //this.allPrice += item.goodsPaymentAmount;
          this.allPrice = new BigNumber(this.allPrice).add(item.goodsPaymentAmount).toNumber()
          if (this.checkList[0].payChannel != item.payChannel) {
            different = true;
          }
          this.selectOrderIds.push(item.orderId);
        });
        this.orderCount = this.checkList.length;
        if (different) {
          this.$message.warning("请选择相同支付类型的订单。");
          return;
        } else {
          if (this.checkList[0].payChannel == "余额支付") {
            haveBalancePay = true;
          }
          if (this.checkList[0].payChannel == "转账支付") {
            haveGiroPay = true;
          }
        }
      }

      if (haveBalancePay && haveGiroPay) {
        this.$message.warning("同时存在转账支付和余额支付的订单，请分别开票！");
        return;
      }
      if (haveBalancePay) {
        //有余额支付的
        let user = await userInfoService.getUserInfo(
          JSON.parse(userModule.PPUser).userId
        );
        //console.log(user);
        var accountName;
        if (user.bimUserInfo.fullName && user.bimUserInfo.fullName.length > 0) {
          accountName = user.bimUserInfo.fullName;
        } else {
          this.$message.warning(
            "未获取到您的账户实名信息，请登录会员App-工作台-设置-账户安全-实名认证完成实名后操作申请。"
          );
          return;
        }
        if (user.bimUserInfo.companyName) {
          this.invoiceType = "comp";
          accountName = user.bimUserInfo.companyName;
        } else {
          this.invoiceType = "personal";
        }
        this.disabledWay = true;
        this.dialogVisibleWay = true;
        this.disabledChangeName = true;
        this.$set(this.invoice, "name", accountName);
      } else if (haveGiroPay) {
        //有转账支付的
        let data = await SomInvoiceService.getGiroInfoByOrderIdList(
          this.selectOrderIds
        );
        if (data.resultCode != "1") {
          this.$message.warning("存在不同账户名的转账记录，请分别开票！");
          this.dialogVisibleWay = false;
          return;
        }
        this.dialogVisible = true;
        this.disabledWay = false;
        this.disabledChangeName = true;
        this.$set(this.invoice, "name", data.accountName);
      } else {
        this.dialogVisible = true;
        this.disabledWay = false;
        this.disabledChangeName = false;
      }
    },

    async commitInvoice(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.invoiceType == "comp") {
            if (!this.invoice.dutySign) {
              this.$message("请输入企业税号");
              return;
            }
          }
          this.invoice.orderIds = [];
          this.invoice.invoiceAmount = this.allPrice;
          /*if (this.invoice.isIssueAllInvoices == 1) {
              //全部开票

          } else {
              this.checkList.forEach(item => {
                  this.invoice.orderIds.push(item.orderId)
              })
          }*/
          //无论是选择开票还是全部开票，同样的方式，一万个32位的订单id，数据量也不会超过400k
          this.invoice.orderIds = this.selectOrderIds;
          if (this.invoiceType == "personal") {
            this.invoice.invoiceType = "personal";
          } else {
            if (this.flag_sp) {
              this.invoice.invoiceType = "company_sp";
            } else {
              this.invoice.invoiceType = "company";
            }
          }
          await SomInvoiceService.createSomInvoice(this.invoice);
          this.loadTableData();
          this.loadInvoiceData();
          this.activeName = "1";
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
        /^\d*\.\d*\./g,
        e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      this.input = e.target.value;
    },
    async initPage(){
      let lastPaymentBillTime = await faDealerInfoService.getLastPaymentBillTimeOnContinue()
      if(lastPaymentBillTime){
        this.createTimeEnd4CreditAgent = new Date(lastPaymentBillTime);
      }
      let res = await orderPaymentService.getAllGiroAccount();
      if(res && res.nameList && res.nameList.length>0){
        this.giroAccountNameMap = res.nameMap;
        this.giroAccountNameList = res.nameList;
        this.pageInfo.payAccountName = res.nameList[0];
      }else{
        this.giroAccountNameMap = {};
        this.giroAccountNameList = [];
        this.pageInfo.payAccountName = '';
      }
      this.loadTableData();
      this.loadInvoiceData();
    }
  },
  mounted() {
    this.initPage()
    if (this._isAgentDeposit()) {
      //经销商
      this.payChannelList = [
        { label: "余额支付", value: "deposit" }
      ]
      this.pageInfo.payChannel='deposit'
    }else if (this._isAgentCredit()) {
      //信用经销商
      this.payChannelList = [
        { label: "信用支付", value: "credit" },
        { label: "余额支付", value: "deposit" }
      ]
      this.pageInfo.payChannel='credit'
    }else if (this._isDistributor()) {
      //会员
      this.payChannelList = [
        { label: "转账支付", value: "giro" },
        { label: "微信支付", value: "wxpay" },
        { label: "余额支付", value: "balance" },
      ]
      this.pageInfo.payChannel='giro'
    }
  },
};
</script>

<style>
.flex1 {
  width: 20%;
  margin-top: 10px;
}
.invoice-search-content .el-form-item .el-form-item__content > .el-input,.el-select{
  width: 220px;
}

</style>
