<template>
  <div>
    <el-dialog title="查看调用记录" :visible.sync="dialogVisible"
               @close="dialogVisible = false" width="800px">
      <div>
        <el-table :data="step3List" style="width: 100%">
          <el-table-column prop="processTime" label="日期" width="180">
            <template slot-scope="scope">
              {{
                scope.row.processTime != null
                    ? format(scope.row.processTime)
                    : ""
              }}
            </template>
          </el-table-column>
          <el-table-column prop="materialName" label="物料"/>
          <el-table-column prop="batchCode" label="批次"/>
          <el-table-column prop="warehouseName" label="来源仓库"/>
          <el-table-column prop="factoryName" label="目标工厂"/>
          <el-table-column prop="processNum" label="调用数量"/>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="hide">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import materialProcessService from "API/pur/PurMaterialProcessService"
import tools from "COMJS/tools";

export default {
  name: "Step3ListDialog",
  data() {
    return {
      dialogVisible: false,
      step3List: [],
    }
  },
  mounted() {
    //this.queryDataList()
  },
  methods: {
    show(stock) {
      //console.log('stock',stock)
      this.queryList(stock.materialId,stock.batchCode,stock.warehouseId)
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    },
    queryList(materialId,batchCode,warehouseId){
      let param = {
        'materialId':materialId,
        'batchCode':batchCode,
        'warehouseId':warehouseId,
        'processStep':'3',
        'flagWithName':'1'
      }
      materialProcessService.queryList(param).then(res=>{
        //console.log('this.step3List',this.step3List)
        this.step3List = res;
      })
    },
    format(time) {
      return tools.formatTime(time, "yyyy-MM-dd");
    },
  }
}
</script>

<style scoped>

</style>