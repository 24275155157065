import axiosHttp from "../axiosHttp";

export default {
  createImportApply(params) {
    return axiosHttp.POST(
      "/bimUserAuthorization/createImportApply",
      params,
      false
    );
  },
  importApplyPage(params) {
    return axiosHttp.POST(
      "/bimUserAuthorization/importApplyPage",
      params,
      false
    );
  },
  importApplyDetail(authorizationId) {
    return axiosHttp.GET(
      `/bimUserAuthorization/importApplyDetail/${authorizationId}`
    );
  },
};
