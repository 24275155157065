import axiosHttp from "../axiosHttp"

export default {
  login(params) {
    return axiosHttp.GET('/login/loginByAccount', params)
  },
  // 发送登录短信
  sendLoginSms(params) {
    return axiosHttp.GET('/login/sendLoginSms', params)
  },
  // 短信登录
  smsLogin(params) {
    return axiosHttp.GET('/login/loginBySms', params)
  },
  // 发送修改密码短信
  sendUpdatePwdSms(params) {
    return axiosHttp.GET('/login/sendUpdatePwdSms', params)
  },
  // 验证修改密码短信
  checkUpdatePwdSms(params) {
    return axiosHttp.GET('/login/checkUpdatePwdSms', params)
  },
  // 修改密码
  updatePwd(params) {
    return axiosHttp.PUT('/login/smsUpdatePwd', params)
  },
  checkPhone(phoneNum, telAreaCode) {
    return axiosHttp.GET(`/login/checkPhoneNum?phoneNum=${phoneNum}&telAreaCode=${telAreaCode}&userType=platform`)
  }
}