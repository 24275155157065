<template>
  <div class="logistics-edit">

    <div class="log-head">
      <div class="log-head-box">
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">发货方：</div>
            <div class="tab-inp">
              <el-select v-model="whmInfo.senderOrganize" filterable placeholder="选择发货方" @change="selectChange" class="inp118">
                <el-option
                  v-for="(item,index) in senderOrganizeType"
                  :key="item.outboundSenderInfoId"
                  :label="item.senderOrganize"
                  :value="index"                
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">发货时间：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.senderTime"></el-input>              
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">发货人：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.senderName"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">发货方电话：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.senderPhone"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="tab-label">发货方地址：</div>
            <div class="tab-inp">
              <el-input class="inp340" disabled v-model="whmInfo.senderAddress"></el-input>
            </div>
        </div>
      </div>

      <div class="log-head-box">
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">收货方：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receivingOrganize"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">收货时间：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receivingTime"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">收货人：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receiverName"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">收货方电话：</div>
            <div class="tab-inp">
              <el-input class="inp118" disabled v-model="whmInfo.receivingPhone"></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="tab-label">收货方地址：</div>
            <div class="tab-inp">
              <el-input class="inp340" disabled v-model="whmInfo.receivingAddress"></el-input>
            </div>
        </div>
      </div>

      <div class="log-head-box mt60">
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">承运单号：</div>
            <div class="tab-inp">
              <el-input class="inp118" v-model="whmInfo.carrierAppCode"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">承运时间：</div>
            <div class="tab-inp">
              <el-input v-model="whmInfo.carrierTime" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">承运人：</div>
            <div class="tab-inp">
              <el-input class="inp118" v-model="whmInfo.carrierOrganize"></el-input>
            </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">承运人电话：</div>
            <div class="tab-inp">
              <el-input class="inp118" v-model="whmInfo.carrierPhone" type="number" @blur="regPhone"></el-input>
            </div>
          </div>
        </div>

        <div class="log-head-list">
          <div class="log-list-lef">
            <div class="tab-label">承运公司：</div>
              <div class="tab-inp">
                <el-input class="inp118" v-model="whmInfo.carrierCompany"></el-input>
              </div>
          </div>
          <div class="log-list-rig">
            <div class="tab-label">承运车辆：</div>
              <div class="tab-inp">
                <el-input class="inp118" v-model="whmInfo.carrierCar"></el-input>
              </div>
          </div>
        </div>
      </div>      
    </div>

    <ws-page-table
      :data="goods"
      ref="pageTable"
      :pageable="false"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
    >
      <el-table-column prop="cnName" :show-overflow-tooltip="true" label="商品名称"></el-table-column>
      <el-table-column prop="goodsCode" :show-overflow-tooltip="true" label="商品编号"></el-table-column>
      <el-table-column prop="skuInfoList" :show-overflow-tooltip="true" label="库品信息"></el-table-column>
      <el-table-column prop="goodsBarCode" :show-overflow-tooltip="true" label="商品条形码"></el-table-column>
      <el-table-column prop="skuPurchaseBatchCode" :show-overflow-tooltip="true" label="批次号"></el-table-column>
      <el-table-column prop="productionDate" :show-overflow-tooltip="true" label="生产日期"></el-table-column>
      <el-table-column prop="goodsCount" :show-overflow-tooltip="true" label="商品数量"></el-table-column>
    </ws-page-table>

    <div class="log-bot">
      <div class="log-bot-list">
        <div class="log-bot-lef mr10">订单备注：</div>
        <el-input v-model="whmInfo.orderRemark" type="textarea" disabled placeholder="自动获取经销商下单备注，客服可修改，修改后内容不覆盖经销商原备注，只保存在发货单"></el-input>
      </div>
      <div class="log-bot-list">
        <div class="log-bot-lef mr10">备注：</div>
        <el-input v-model="whmInfo.remark" type="textarea"></el-input>
      </div>
      <div class="log-bot-list">
        <div class="log-bot-lef">送货方式：</div>
        <el-select v-model="whmInfo.deliveryMethod" placeholder="选择类型">
          <el-option
            v-for="item in deliveryType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>  
      <div class="log-bot-list">
        <div class="log-bot-lef mr10">运费金额：</div>
        <el-input placeholder="请输入内容" v-model="whmInfo.transportFee">
          <template slot="prepend">￥</template>
        </el-input>
      </div> 
      <div class="log-bot-list">
        <div class="log-bot-lef">运费付款：</div>
        <el-select v-model="whmInfo.transportationCostMethod" placeholder="选择类型">
          <el-option
            v-for="item in transportationCostType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> 
    </div>
    <el-button type="primary" class="log-btn" @click="create">保存</el-button>

  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";
import validator from "COMJS/validator";
import BimEnumService from "API/bim/BimEnumService";

export default {
  name: "receiveList",
  data() {
    return {
      value: [],
      options: [],
      goods:{},
      companySelect: [],// 选择公司
      searchInfo:{        
        outboundDocumentsId: ""
      },
      items: {
        outboundDocumentsId: { filter: "EQ" },
      },
      deliveryType: [
        {
          value: "0",
          label: "到门",
        },
        {
          value: "1",
          label: "自提",
        },
      ],
      transportationCostType: [
        {
          value: "0",
          label: "寄付",
        },
        {
          value: "1",
          label: "到付",
        },
      ], 

      input:"",
      whmInfo: {},
      senderOrganizeType: [],
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    this.getOutboundList()
    this.getGoodsDetail();
    BimEnumService.getEnum("TRANSPORT_COMPANY").then((data) => {
            this.companySelect = data.map((e) => {
                return {
                    value: e.value,
                    label: e.name,
                };
            });
        });
  },
  methods: {
    regPhone(){      
      if (!validator.mobile(this.whmInfo.carrierPhone)) {
        this.whmInfo.carrierPhone = ""
        return this.$message({
          message: "请输入正确的号码",
          type: "warning",
        });
      }
    },
    create(){
      if (!this.whmInfo.outboundSenderInfoId) {
        return this.$message({
          message: "请选择发货方",
          type: "warning",
        });
      }
      if (!this.whmInfo.deliveryMethod) {
        return this.$message({
          message: "请选择送货方式",
          type: "warning",
        });
      }
      if (!this.whmInfo.transportFee) {
        return this.$message({
          message: "请输入运费金额",
          type: "warning",
        });
      }
      if (!this.whmInfo.transportationCostMethod) {
        return this.$message({
          message: "请输入运费付款",
          type: "warning",
        });
      }
      if (!this.whmInfo.carrierCompany) {
        return this.$message({
          message: "请选择承运公司",
          type: "warning",
        });
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      let params = {
        outboundSenderInfoId: this.whmInfo.outboundSenderInfoId,//发货方id        
        outboundDocumentsId: this.$route.params.id,//出库单id
        deliveryMethod: this.whmInfo.deliveryMethod,//送货方式(0 到门，1自提)
        transportFee: this.whmInfo.transportFee,//运费金额
        transportationCostMethod: this.whmInfo.transportationCostMethod,//运费付款 0寄付，1到付
        remark: this.whmInfo.remark,
        carrierAppCode: this.whmInfo.carrierAppCode,//承运单号
        carrierCompany:this.whmInfo.carrierCompany, // 承运方公司
        //carrierTime: this.whmInfo.carrierTime,//承运时间
        carrierPhone: this.whmInfo.carrierPhone,//承运人电话
        carrierCar: this.whmInfo.carrierCar,//承运车辆
        carrierOrganize: this.whmInfo.carrierOrganize,//承运人
      }
      WhmList.editTransport(params).then((data) => {
        if(data.msg == "OK"){
          this.$message({
            message: "保存成功",
            type: "success",
          });          
        }            
        loading.close();
        this.$router.push('/som/logisticsList');
      });
    },
    selectChange(idx){
      let senderOrganizeType = this.senderOrganizeType[idx]
      this.whmInfo.outboundSenderInfoId = senderOrganizeType.outboundSenderInfoId,
      this.whmInfo.senderOrganize = senderOrganizeType.senderOrganize,
      this.whmInfo.senderTime = senderOrganizeType.senderTime,
      this.whmInfo.senderName = senderOrganizeType.senderName,
      this.whmInfo.senderPhone = senderOrganizeType.senderTel,
      this.whmInfo.senderAddress = senderOrganizeType.senderAddress        
    },
    getOutboundList(){
      //this.pageInfo.pageSize = 1000 //获取所有的发货信息
      let params = {
        pageSize: 1000
      }
      WhmList.pageOutboundList(params).then((data) => {
        data.list = data.list.map((e) => {
          return {
            outboundSenderInfoId: e.outboundSenderInfoId,
            senderOrganize: e.senderOrganize,
            senderName: e. senderName,
            senderTel: e.senderTel,
            senderAddress: e.senderAddress,
            defaultOption: e.defaultOption == 1 ? true : false,
          };
        });
        this.senderOrganizeType = data.list
        // this.querySuccess(data);
      });
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailLogistics(this.$route.params.id).then((data) => {
        this.whmInfo = data
        this.goods = this.whmInfo.pimGoodsDTOS
        //this.transport = this.whmInfo.whmOutboundTransport
        loading.close();
      });
    },
    loadTableData() {
      this.searchInfo.outboundDocumentsId = this.$route.params.id
      WhmList.queryLogisticsDetail(this.pageInfo, this.searchConfig).then((data) => {
        for(let i in data.list){
          if(data.list[i].skuList.length > 0){
            data.list[i].skuInfoList = ""
            data.list[i].skuPurchaseBatchCode = ""
            data.list[i].productionDate = ""
            for(let j in data.list[i].skuList){
              if(data.list[i].skuList[j].skuCode){
                data.list[i].skuPurchaseBatchCode += data.list[i].skuList[j].skuCode + ":" + data.list[i].skuList[j].skuPurchaseBatchCode + "+"
                data.list[i].skuInfoList += data.list[i].skuList[j].skuCode + "*" + data.list[i].skuList[j].skuNum + "+"
                data.list[i].productionDate += data.list[i].skuList[j].skuCode + ":" + tools.formatDate(new Date(data.list[i].skuList[j].productionDate-0), "yyyy-MM-dd hh:mm:ss") + "+"
              }
            }
            data.list[i].skuPurchaseBatchCode = data.list[i].skuPurchaseBatchCode.substring(0, data.list[i].skuPurchaseBatchCode.length-1);
            data.list[i].skuInfoList = data.list[i].skuInfoList.substring(0, data.list[i].skuInfoList.length-1);
            data.list[i].productionDate = data.list[i].productionDate.substring(0, data.list[i].productionDate.length-1);
          }
        }
        //console.log(data.list)
        data.list = data.list.map((e) => {                    
          return {
            goodsName: e.goodsName,
            goodsCode: e.goodsCode,
            skuInfoList: e.skuInfoList,
            goodsPurchaseBatchCode: e.goodsPurchaseBatchCode,
            goodsBarCode: e.goodsBarCode,
            skuPurchaseBatchCode: e.skuPurchaseBatchCode,
            productionDate: e.productionDate,
            num: e.num,
          };
        });
        this.querySuccess(data);
      });      
    },   
  },
};
</script>
<style>
.logistics-edit .log-head{ margin:0 0 30px 0; display: flex; flex-wrap: wrap;}
.logistics-edit .log-head-box{ width: 50%;}
.logistics-edit .el-input__inner{ width: 138px;}
.logistics-edit .inp340 .el-input__inner{ width: 412px;}
.logistics-edit .log-head-list{ margin-top: 10px; display: flex; align-items: center;}
.logistics-edit .log-list-lef,.logistics-edit .log-list-rig{ display: flex; align-items: center;}
.logistics-edit .log-list-lef{ margin-right: 16px;}
.logistics-edit .tab-label{ min-width: 120px; text-align: right;}
.logistics-edit .mt60{ margin-top: 30px;}
.logistics-edit .log-bot{ margin-top: 30px;}
.logistics-edit .log-bot-list{ margin-bottom: 20px; display: flex; align-items: center;}
.logistics-edit .log-bot-lef{ width: 90px; text-align: right;}
.logistics-edit .el-textarea textarea{ width: 50%;}
.logistics-edit .el-input-group--prepend .el-input__inner{ width: 200px;}
.logistics-edit .mr10{ margin-right: 6px;}
.logistics-edit .log-btn{ margin-left: 10px; width: 80px;}
.logistics-edit .is-in-pagination .el-input__inner{ width: 40px;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>