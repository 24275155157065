import { render, staticRenderFns } from "./selectCategoryPopup.vue?vue&type=template&id=cf20fd66&scoped=true"
import script from "./selectCategoryPopup.vue?vue&type=script&lang=js"
export * from "./selectCategoryPopup.vue?vue&type=script&lang=js"
import style0 from "./selectCategoryPopup.vue?vue&type=style&index=0&id=cf20fd66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf20fd66",
  null
  
)

export default component.exports