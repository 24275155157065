<script src="../../../mixin/pageTableMixin.js"></script>
<script src="../../../../vue.config.js"></script>
<script src="../../../js/tools.js"></script>
<template>
  <div>
    <el-dialog
      title="添加优惠"
      :visible.sync="visible"
      @close="$emit('update:show', false)"
      :show="show"
    >
      <el-form
        label-position="right"
        label-width="100px"
        style="margin: 20px 0px"
        :model="fromdata"
        ref="fromdata"
      >
        <el-form-item prop="title">
          <p class="form-label">优惠名称：</p>
          <el-input
            maxlength="10"
            show-word-limit
            v-model="fromdata.title"
          ></el-input>
        </el-form-item>
        <el-form-item prop="note">
          <p class="form-label">优惠描述：</p>
          <el-input
            maxlength="15"
            v-model="fromdata.note"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="couponType">
          <p class="form-label">优惠类型：</p>
          <el-select
            @change="selectChang"
            v-model="fromdata.couponType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in campaignTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="fromdata.couponType && fromdata.couponType.length > 0"
        >
          <span class="form-label">优惠金额：</span>
          <div style="display: flex; flex-direction: row">
            <div style="display: flex; flex-direction: column">
              <div
                class="flex-row"
                style="display: flex; flex-direction: row"
                v-for="(item, index) in preferentialAmountArr"
              >
                <el-form-item>
                  <span>满</span>
                  <el-input
                    v-model="item.couponNorm"
                    style="width: 60px; margin: 0px 5px"
                    type="number"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <span>减</span>
                  <el-input
                    v-model="item.couponAmount"
                    style="width: 60px; margin: 0px 5px"
                    type="number"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <div v-if="fromdata.couponType != 'full_reduction_coupon'">
              <i
                @click="addCondition"
                style="
                  margin-left: 10px;
                  color: rgba(42, 130, 228, 1);
                  font-size: 25px;
                  display: flex;
                  align-items: flex-end;
                "
                class="el-icon-circle-plus"
              ></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="isDistributionShare">
          <p class="form-label">分发规则：</p>
          <el-select
            v-model="fromdata.isDistributionShare"
            placeholder="请选择"
          >
            <el-option
              v-for="item in couponTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <p class="form-label">可见时间：</p>
          <el-date-picker
            v-model="TimeArr"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="bottomBtn">
        <el-button type="primary" @click="preservation">保存</el-button>
        <el-button @click="show = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      visible: this.show,
      preferentialAmountArr: [
        {
          couponNorm: "",
          couponAmount: "",
        },
      ],
      TimeArr: [],
      fromdata: {
        title: "",
        note: "",
        couponType: "",
        isDistributionShare: "",
      },
      campaignTypeList: [
        {
          label: "现金券",
          value: "full_reduction_coupon",
        },
        {
          label: "满减",
          value: "full_reduction",
        },
      ],
      couponTypeList: [
        {
          value: "0",
          label: "自动发放",
        },
        {
          value: "1",
          label: "会员分享",
        },
      ],
      couponListObj: {},
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    passInData: {
      type: Object,
    },
  },
  watch: {
    show() {
      this.visible = this.show;
    },
  },
  methods: {
    addCondition() {
      let Obj = {
        couponNorm: "",
        couponAmount: "",
      };
      if (this.fromdata.couponType != "full_reduction_coupon") {
        if (this.preferentialAmountArr.length < 5) {
          this.preferentialAmountArr.push(Obj);
        } else {
          this.$message({ type: "error", message: "最多可添加5个优惠条件。" });
        }
      }
    },

    preservation() {
      if (this.fromdata.title == "") {
        return this.$message({
          message: "请输入优惠名称",
          type: "warning",
        });
      }
      if (this.fromdata.note == "") {
        return this.$message({
          message: "请输入优惠描述",
          type: "warning",
        });
      }
      if (this.fromdata.couponType == "") {
        return this.$message({
          message: "请选择优惠类型",
          type: "warning",
        });
      }
      for (let i in this.preferentialAmountArr) {
        if (
          this.preferentialAmountArr[i].couponAmount == "" ||
          this.preferentialAmountArr[i].couponNorm == ""
        ) {
          return this.$message({
            message: "请把优惠金额填写完整",
            type: "warning",
          });
        }
      }
      if (this.fromdata.isDistributionShare == "") {
        return this.$message({
          message: "请选择分发规则",
          type: "warning",
        });
      }
      if (this.TimeArr == "") {
        return this.$message({
          message: "请选择可见时间",
          type: "warning",
        });
      }
      let data = {};
      // console.log(this.preferentialAmountArr);
      for (let i = 0; i < this.preferentialAmountArr.length; i++) {
        data["couponNorm" + (i + 1)] = this.preferentialAmountArr[i].couponNorm;
        data["couponAmount" + (i + 1)] =
          this.preferentialAmountArr[i].couponAmount;
      }
      this.couponListObj = {
        ...this.couponListObj,
        ...this.fromdata,
        ...data,
        beginTime: moment(this.TimeArr[0]).valueOf(),
        endTime: moment(this.TimeArr[1]).valueOf(),
      };
      console.log(this.couponListObj);
      let boo = true;
      if (this.couponListObj.couponId == "") {
        delete this.couponListObj.couponId;
      }
      for (let i in this.couponListObj) {
        if (this.couponListObj[i] == "") {
          boo = false;
        }
      }
      if (boo) {
        console.log(this.couponListObj);
        this.$emit("couponListObj", this.couponListObj);
      } else {
        this.$message({ type: "error", message: "系统错误，请联系管理员。" });
      }
    },
    emptyFrom() {
      this.fromdata = {
        title: "",
        note: "",
        couponType: "",
        isDistributionShare: "",
      };
      (this.preferentialAmountArr = [
        {
          couponNorm: "",
          couponAmount: "",
        },
      ]),
        (this.TimeArr = []);
      this.couponListObj = {};
    },

    selectChang(e) {
      if (e == "full_reduction") {
        this.couponTypeList = [
          {
            value: "0",
            label: "自动发放",
          },
        ];
      } else {
        (this.couponTypeList = [
          {
            value: "0",
            label: "自动发放",
          },
          {
            value: "1",
            label: "会员分享",
          },
        ]),
          (this.preferentialAmountArr = [
            {
              couponNorm: "",
              couponAmount: "",
            },
          ]);
      }
    },

    editcouponList(index) {
      let couponList = this.passInData.couponList;
      console.log(couponList[index]);
      this.fromdata = couponList[index];
      this.TimeArr = [
        moment(Number(couponList[index].beginTime)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        moment(Number(couponList[index].endTime)).format("YYYY-MM-DD HH:mm:ss"),
      ];
      let data = [];
      let data2 = [];
      console.log(couponList[index]);
      for (let i in couponList[index]) {
        if (i.indexOf("couponAmount") != -1) {
          if (couponList[index][i] != null) {
            let Obj = {
              couponAmount: couponList[index][i],
            };
            data.push(Obj);
          }
        }
        if (i.indexOf("couponNorm") != -1) {
          if (couponList[index][i] != null) {
            let Obj = {
              couponNorm: couponList[index][i],
            };
            data2.push(Obj);
          }
        }
      }
      data.forEach((val, index) => {
        Object.assign(val, data2[index]);
      });
      console.log(data);

      this.preferentialAmountArr = data;
    },
  },
};
</script>
<style scoped>
.bottomBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
</style>