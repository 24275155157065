<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <div class="form-row">
          <div class="form-item">
            <span class="form-label">客户姓名：</span>
            <el-input size="mini" v-model="searchInfo.recipientName"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">客户电话：</span>
            <el-input size="mini" v-model="searchInfo.recipientPhoneNum"></el-input>
          </div>
          <div class="form-item">
            <span class="form-label">奶卡状态：</span>
            <el-select v-model="searchInfo.cardState"
                       clearable
                       placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </div>
      </div>
      <div class="filter_right">
        <!--
        <el-button size="mini" @click="searchData">手动发货</el-button>
        -->
        <el-button size="mini" type="warning" @click="searchData">定时发货设置</el-button>
      </div>
    </div>

    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="true"
        :selection="'multi'"
    >
      <el-table-column prop="recipientName" label="姓名" width="100"></el-table-column>
      <el-table-column prop="recipientPhoneNum" label="电话" width="150"></el-table-column>
      <el-table-column label="地址" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{scope.row.recipientProvince}} {{scope.row.recipientCity}} {{scope.row.recipientCounty}}
          </div>
          <div style="line-height: 18px;">
            {{scope.row.recipientAddressDetail}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="最近发货" width="150">
        <template slot-scope="scope">
          2023-10-10
        </template>
      </el-table-column>
      <el-table-column prop="cardState" label="状态" width="100">
        <template slot-scope="scope">
          {{scope.row.cardState=='normal'?'正常':'暂停'}}
        </template>
      </el-table-column>
      <el-table-column prop="endDate" label="结束时间" width="150"
                       :formatter="dateFormatter4Table"></el-table-column>
      <el-table-column label="期数" width="100">
        <template slot-scope="scope">
          <div>
            {{scope.row.currentIssue}} / {{scope.row.totalIssue}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a
                href="javascript: void(0);"
                title="详情"
                @click="toDetail(scope.row)"
            >
              <i class="CCSFont icon-table_2"></i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import freshMilkService from "API/fm/FreshMilkService";
import tools from "COMJS/tools";

export default {
  name: "FreshMilkCardList",
  mixins: [pageTableMixin],
  data() {
    return {
      searchInfo:{},
      options: [{
        value: '正常',
        label: '正常'
      }, {
        value: '暂停',
        label: '暂停'
      }],
    }
  },
  methods:{
    loadTableData(){
      let param = {
        ...this.searchInfo,
        pageNo:this.pageInfo.pageNo,
        pageSize:this.pageInfo.pageSize,
      }
      freshMilkService.pageByParam(param).then(res=>{
        this.querySuccess(res);
      })
    },
    toDetail(row){
      this.$router.push({
        path: `/fm/freshMilkCardDetail/${row.milkCardId}`,
      });
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>

</style>