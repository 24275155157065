<template>
  <el-dialog :visible.sync="dialogVisible" width="560px" class="table-wrap">
    <div class="goods-dialog-head">
      <div class="v1">库品：{{ skuCode }}</div>
    </div>

    <div class="goods-dialog-cont">
      <ws-page-table
          :data="batchList"
          ref="batchTable"
          :pageable="false"
          @current-change="handleCurrentChange"
          @pageChange="loadTableData"
          :selection="'single'"
      >
        <el-table-column
            prop="purchaseBatchCode"
            label="批次"
            :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import invQuery from "API/whm/invQuery";

export default {
  data() {
    return {
      skuId:'',
      skuCode:'',
      batchList:[],
      dialogVisible: false,
      selectedRow: {}
    };
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    showDialog(skuId, skuCode){
      this.skuId = skuId;
      this.skuCode = skuCode;
      this.dialogVisible = true;
      this.loadTableData();
    },
    submit() {
      this.$emit("submit", this.selectedRow);
      this.dialogVisible = false;
    },
    handleCurrentChange(val) {
      if (val) {
        this.selectedRow = val
      }
    },
    loadTableData() {
      invQuery.queryBatchBySku(this.skuId).then((data) => {
        this.batchList = data;
      });
    },
  }
};
</script>

<style scoped lang="scss">

</style>