/**
 * 验证方式
 */

/*新建用户中的密码验证*/
var validatePass = (rule, value, callback) => {
    if (value.length < 6 || value.length > 18) {
        callback(new Error('密码长度必须为6-18位'));
    } else {
        callback();
    }
};

/*新建用户中的邮箱验证*/
const emailValidator = (rule, value, callback) => {
    if (value == null || value == "") {
        callback();
    }
    var pattern = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
    if (!pattern.test(value)) {
        callback(new Error('邮箱格式错误，请输入正确的邮箱'));
    } else {
        callback();
    }
};

/*新建用户中的手机号验证*/
//todo 未来可能考虑兼容多国手机号，可能不会限制的这么死
const mobileValidator = (value, callback) => {
    //var pattern = /^1[3|4|5|7|8][0-9]{9}$/;
    if(value.length>11 || value.length<8){
        return false
    }
    var pattern = /^[0-9]*$/;
    if (!pattern.test(value)) {
        return false
    } else {
        return true
    }
};

/*IP地址*/
const ipValidator = (rule, value, callback) => {
    if (value == null || value == "") {
        callback();
    }
    var pattern = /^((2[0-4]\d|25[0-5]|[1-9]?\d|1\d{2})\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
    if (!pattern.test(value)) {
        callback(new Error('请输入正确的IP地址'));
    } else {
        callback();
    }
};

/*固定电话*/
const telValidator = (rule, value, callback) => {
    if (value == null || value == "") {
        callback();
    }
    var pattern = /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/;
    if (!pattern.test(value)) {
        callback(new Error('请输入正确的固定电话'));
    } else {
        callback();
    }
};

/*url*/
const urlValidator = (rule, value, callback) => {
    if (value == null || value == "") {
        callback();
    }
    var pattern = /^((http|ftp|https):\/\/)?((((25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)\.){3}(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d))|([\w_\-\.])+\.[a-zA-Z]{2,6}(\.[\w_\-]+)*)(:[0-9]*)?(\/[\w\-_.]+)*(\/)?$/;
    if (!pattern.test(value)) {
        callback(new Error('请输入正确的url地址'));
    } else {
        callback();
    }
};

/*url*/
const digitValidator = (rule, value, callback) => {
    if (value == null) {
        callback();
    }

    let pattern = /^\d+$/;
    if (!pattern.test(value)) {
        callback(new Error('只能输入数字'));
    } else {
        callback();
    }
};

export default {
    'password': validatePass,
    'email': emailValidator,
    'mobile': mobileValidator,
    'ip': ipValidator,
    'tel': telValidator,
    'url': urlValidator,
    'digit': digitValidator
};

