<template>
  <div class="invlist-edit-wrap">
    <el-form :inline="true" class="inventory-form">
      <el-form-item label="库品编码:">
        <el-input v-model="searchInfo.skuCode" />
      </el-form-item>
      <el-button type="primary" @click="searchData">搜索</el-button>
      <el-button type="primary" @click="downloadInv">盘点模板下载</el-button>

      <!--:limit="limitUpload"-->
      <el-upload
        style="display: inline-block; margin-left: 15px"        
        action=""
        :on-change="handleChange"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"        
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button type="primary">盘点表格导入</el-button>
      </el-upload>
    </el-form>
    <el-form :inline="true" class="inventory-form inventory-form1">
      <el-button type="primary" @click="addReceive">新增批次</el-button>
      <el-button type="primary" @click="showCheckStore">保存列表</el-button>
    </el-form>

    <ws-page-table
      :data="tableData"
      ref="pageTable"
      :pageable="true"
      :pageInfo="pageInfo"
      @pageChange="loadTableData"
      class="inv-table"
    >
      <el-table-column
        prop="skuCode"
        label="库品编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="skuName"
        label="库品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="purchaseBatchCode"
        label="库品批次"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="actualNum"
        label="展示库存"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="skuNum"
        label="实际库存"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.skuNum"
            maxlength="11"
            type="number"
            @keyup.native="setNumFotList(scope.row, scope.$index)"
            placeholder="实际库存"
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="变动库存" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.changeNum ? scope.row.changeNum : "自动计算" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="变动原因"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.remark"
            maxlength="140"
            @keyup.native="setReason(scope.row, scope.$index)"
          ></el-input>
        </template>
      </el-table-column>
    </ws-page-table>

    <!-- :fullscreen="true"-->
    <el-dialog
      title="新增批次"
      :visible.sync="skuCodeDialog"
      class="abnormal-wrap"
      @close="closeSkuDialog"
    >
      <el-form class="input-txt-btn">
        <el-form-item label="库品编码:">
          <el-input v-model="searchInfo.skuCode"></el-input>
        </el-form-item>
        <el-button type="primary" @click="loadSkuTableData">搜索</el-button>
      </el-form>
      <ws-page-table
        :data="skuTableData"
        ref="skuPageTable"
        :pageable="true"
        :pageInfo="skuPageInfo"
        @pageChange="loadSkuTableData"
        :selection="'multi'"
      >
        <el-table-column
          prop="skuName"
          label="库品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuCode"
          label="库品编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="purchaseBatchCode"
          label="库品批次"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="actualNum"
          label="展示库存"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <el-button type="primary" @click="skucodeConfirm">确定</el-button>
        <el-button type="primary" @click="backPre">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="新增盘点列表"
      width="80%"
      :visible.sync="skuDetailDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeSkuDetailDialog"
      class="abnormal-list-wrap"
    >
      <ws-page-table :data="skuList" ref="skuDetailPageTable" :pageable="false">
        <el-table-column
          prop="skuCode"
          label="库品编码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuName"
          label="库品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="purchaseBatchCode"
          label="库品批次"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="actualNum"
          label="展示库存"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="skuNum"
          label="实际库存"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="changeNum"
          label="变动库存"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="变动原因"
          prop="remark"
          :show-overflow-tooltip="true"
        ></el-table-column>
      </ws-page-table>
      <div class="btn-blue-wrap">
        <el-button type="primary" @click="saveReceive">确定</el-button>
        <el-button type="primary" @click="resetEdit">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import abnormalQuery from "API/whm/abnormalQuery";
import userModule from "@/module/userModule";
import IoProgressService from "API/IoProgressService";

export default {
  name: "inventoryEdit",
  data() {
    return {
      value: [],
      options: [],

      searchInfo: {
        checkStoreId: "",
        skuCode: "",
      },
      items: {
        skuCode: { filter: "like" },
      },
      skuCodeDialog: false,
      skuDetailDialog: false,
      skuTableData: [],
      skuPageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      selectSkuArr: [],
      // isCanAddLas: false,
      // canAddLasArr: [],
      skuList: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
      detailList: [],
      //firstTimeAdd: false, //判断是不是第一次点击保存列表 如果是第一次请求 加入到最后一页 然后就可以切换页面了
      selectList: [],
      excelList: [],//excel导入的可用数据
    };
  },
  mixins: [pageTableMixin],
  mounted() {
    //this.loadTableData();
    this.loadSkuTableData();
  },
  methods: {
    /*导入excel转为data-Start*/
    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        if (
          this.fileTemp.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          this.fileTemp.type == "application/vnd.ms-excel"
        ) {
          this.importfxx(this.fileTemp);
        } else {
          this.$message({
            type: "warning",
            message: "附件格式错误，请删除后重新上传！",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！",
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;
    },
    importfxx(obj) {
      let that = this;
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      this.file = event.currentTarget.files[0];
      var rABS = false; //是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();
      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        var wb; //读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          //此处引入，用于解析excel
          var XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
          //此处可对数据进行处理
          let excelData = [];
          outdata.map(v => {
            let obj = {}
            obj.remark = v['变动原因（非必填）']
            obj.skuNum = v['库品实际库存']
            obj.purchaseBatchCode = v['库品批次']
            obj.skuCode = v['库品编码']
            excelData.push(obj)
          });
          //过滤掉没有实际库存的数据
          that.excelList = excelData.filter(function (item) {
              return item.skuNum;
          });
          that.loadTableData()
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    /*导入excel转为data-End*/

    loadTableData() {
      //console.log(this.selectList)
      // if (this.selectSkuArr.length > 0 && this.firstTimeAdd) {
      //   this.pageInfo.pageNo = Math.ceil(
      //     this.pageInfo.total / this.pageInfo.pageSize
      //   );
      // }
      this.$refs.pageTable.tableLoading = true;
      let params = {
        isNull: 1,
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        skuCode: this.searchInfo.skuCode,
      };
      abnormalQuery.queryBatchCodeNowInv(params).then((data) => {
        //console.log(data.pages)//获取一共有几页
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            purchaseBatchCode: e.purchaseBatchCode,
            actualNum: e.actualNum,
          };
        });
        //导入的情况下
        if(this.excelList && this.excelList.length > 0){
          //console.log("666")
          for(let i in this.excelList){
            for(let j in data.list){              
              if(this.excelList[i].skuCode == data.list[j].skuCode && this.excelList[i].purchaseBatchCode == data.list[j].purchaseBatchCode){
                data.list[j].skuNum = this.excelList[i].skuNum
                data.list[j].remark = this.excelList[i].remark
                this.setNumFotList(data.list[j], j)
              }
            }
          }
        }
        if (this.detailList.length > 0) {
          data.total += this.detailList.length;
          data.list = data.list.concat(this.detailList);
        }
        if (this.selectSkuArr.length > 0) {
          let selectSkuArr = this.selectSkuArr;
          //data.total += selectSkuArr.length;跳转到最后一页没必要          
          //data.list = data.list.concat(selectSkuArr);
          //let arr = [...this.purchaseBatchCodeList.splice(index,1), ...this.purchaseBatchCodeList] //es6写法 合并数组
          let arr = [...selectSkuArr,...data.list]
          data.list = arr
        }
        if (this.selectList.length > 0) {
          for (let i in data.list) {
            for (let j in this.selectList) {
              if (
                data.list[i].purchaseBatchCode == this.selectList[j].purchaseBatchCode && data.list[i].skuCode == this.selectList[j].skuCode
              ) {
                data.list[i].skuNum = this.selectList[j].skuNum;
                data.list[i].changeNum =
                  this.selectList[j].skuNum - 0 - this.selectList[j].actualNum;
                data.list[i].remark = this.selectList[j].remark;
              }
            }
          }
        }
        //this.firstTimeAdd = false;
        this.querySuccess(data);
      });
    },    
    setNumFotList(row, numIdx) {
      //把获取到的num赋值给list
      if (row.skuNum < 0) {
        row.skuNum = "";
      }
      let changeNum = row.skuNum - 0 - row.actualNum;
      if (row.skuNum == "") {
        changeNum = "";
      }
      this.$set(this.tableData[numIdx], "changeNum", changeNum);

      if (this.selectList.length == 0) {
        //console.log(row)
        this.selectList.push(row);
      }
      //console.log(row)
      if (this.selectList.length > 0 && row.skuNum != "") {
        //添加部分
        let allNum = this.selectList.some(function (val) {
          //如果没有相同的添加之
          return (val.purchaseBatchCode == row.purchaseBatchCode && val.skuCode == row.skuCode)
        });
        if (!allNum) {
          //如果没有相同的批次 就把这个row添加到selectList
          this.selectList.push(row);
        } else {
          for (let i in this.selectList) {
            if (this.selectList[i].purchaseBatchCode == row.purchaseBatchCode && this.selectList[i].skuCode == row.skuCode) {
              this.selectList[i] = row;
            }
          }
        }
      }
      if (this.selectList.length > 0 && row.skuNum == "") {
        //删除对应的数据
        for (let i in this.selectList) {
          if (this.selectList[i].purchaseBatchCode == row.purchaseBatchCode && this.selectList[i].skuCode == row.skuCode) {
            this.selectList.splice(i, 1);
          }
        }
      }
    },
    //保存列表点击事件
    showCheckStore() {
      for (let i in this.selectList) {
        if (this.selectList[i].skuNum && !this.selectList[i].remark) {
          return this.$message({
            message: "请输入对应的修改原因！",
            type: "warning",
          });
        }
      }
      for (let i in this.selectList) {
        if (this.selectList[i].skuNum && this.selectList[i].remark) {
          this.skuList.push(this.selectList[i]);
        }
      }
      this.skuDetailDialog = true;
    },
    //新增盘点列表的保存
    saveReceive() {
      //this.tableData
      if (this.skuList.length == 0) {
        return;
      }
      let params = {
        skuList: [],
        warehouseId: 1,
      };
      params.skuList = this.skuList.map((e) => {
        return {
          checkStoreNum: e.skuNum, //实际库存
          purchaseBatchCode: e.purchaseBatchCode,
          actualNum: e.actualNum,
          skuId: e.id,
          remark: e.remark,
        };
      });
      abnormalQuery.saveCkeckSore(params).then((data) => {
        if (data.msg == "OK") {
          this.$message({
            message: "新增成功！",
            type: "success",
          });
          this.skuDetailDialog = false;
          this.skuList = "";
          //this.loadTableData();
          this.$router.push("/whm/inventoryList");
        }
      });
    },               

    //下载模板
    downloadInv() {
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
      });
      abnormalQuery
        .exportCheckStoreTemplate(this.queryParams)
        .then((data) => {
          let fileName = data;
          let module = "whm_excel";
          IoProgressService.download(module, fileName, false);
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    resetEdit() {
      this.skuDetailDialog = false;
    },
    closeSkuDialog() {
      //记录主页面列表在第几页 关闭的时候跳到那个页面
      //如果主页加上当前选中的行数加起来超过十条 那么就跳转到下一页 并且监听“上一页”的按钮 点击的时候
      this.searchInfo.skuCode = "";
    }, 
    closeSkuDetailDialog() {
      this.skuList = [];
    },
    backPre() {
      this.skuCodeDialog = false;
    },
    //新增批次
    skucodeConfirm() {
      //获取新增批次选中的列表
      this.selectSkuArr = this.$refs.skuPageTable.getSelectedData();
      //console.log(this.selectSkuArr)
      if (this.selectSkuArr.length > 0) {
        //this.firstTimeAdd = true;
        this.loadTableData();
      }
      this.skuCodeDialog = false;
    },
    loadSkuTableData() {
      //this.$refs.pageTable.tableLoading = true;
      let params = {
        isNull: 0,
        pageNo: this.skuPageInfo.pageNo,
        pageSize: this.skuPageInfo.pageSize,
        skuCode: this.searchInfo.skuCode,
      };
      abnormalQuery.queryBatchCodeNowInv(params).then((data) => {
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            id: e.skuId,
            skuCode: e.skuCode,
            skuName: e.skuName,
            purchaseBatchCode: e.purchaseBatchCode,
            actualNum: e.actualNum,
          };
        });
        //this.querySuccess(data);
        this.skuTableData = data.list;
        this.skuPageInfo.total = data.total;
      });
    },
    //变动原因
    setReason(row, numIdx) {
      this.$set(this.tableData[numIdx], "remark", row.remark);
      if (this.selectList.length > 0) {
        //如果有相同的批次 替换字段里面的值
        for (let i in this.selectList) {
          if (
            this.selectList[i].purchaseBatchCode == row.purchaseBatchCode && this.selectList[i].skuCode == row.skuCode && row.remark
          ) {
            this.selectList[i].remark = row.remark;
          }
        }
      }
    },
    addReceive() {
      this.skuCodeDialog = true;
    },
  },
};
</script>
<style>
.abnormal-list-wrap .box-shadow-unify {
  height: 600px;
  overflow-y: scroll;
}
.invlist-edit-wrap {
  padding: 0 0 40px 20px;
}
.invlist-edit-wrap .inventory-form {
  padding: 20px;
  background: #fff;
}
.invlist-edit-wrap .el-form--inline {
  padding-bottom: 0;
}
.invlist-edit-wrap .inventory-form1 {
  margin-bottom: 20px;
  padding: 0 0 20px 20px;
}
.btn-blue-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.invlist-edit-wrap .input-txt-btn .el-form-item__content {
  margin-left: 0;
  width: 200px;
}
.invlist-edit-wrap .input-txt-btn .el-input--small {
  width: 200px;
}
.invlist-edit-wrap .input-txt-btn {
  margin-bottom: 20px;
  display: flex;
}
.invlist-edit-wrap .input-txt-btn .el-form-item--small {
  margin: 0 15px 0 0;
  display: flex;
}
.invlist-edit-wrap .is-in-pagination {
  width: 60px;
}
.el-upload-list--text{ display: none;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>