import axiosHttp from "../axiosHttp";

export default {
    queryPage(pageInfo,searchConfig){
        let url = "/purWarehouse/queryPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    queryList(param){
        let url = "/purWarehouse/queryList"
        return axiosHttp.POST(url,param)
    },
    save(param){
        let url = "/purWarehouse/save"
        return axiosHttp.POST(url,param)
    },
    removeById(id){
        let param = {
            warehouseId : id
        }
        let url = `/purWarehouse/remove`
        return axiosHttp.POST(url,param)
    },

}