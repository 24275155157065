<template>
  <div id="taskPop">
    <el-dialog title="" :showClose="false" :visible.sync="dialogVisible" width="70%" style="height:100%" :before-close='cleanData'>
      <div class="header">
        <div class="header-left">
          <img src="../../../assets/images/title_Icon.png">
          <p>标题</p>
          <el-input :disabled='TaskSee' v-model="from.title" style="width:30%"></el-input>
        </div>
        <div class="header-right">
          <el-button :type="from.taskState=='suspend'?'success':'danger'" :disabled='from.projectState=="close" || from.taskState=="complete"' v-if="!TaskSee" @click="from.taskState=='suspend'?terminatedTask('waiting'):terminatedTask('suspend')">
            {{from.taskState=='suspend'?'重启任务':'终止任务'}}</el-button>
          <el-button type="primary" v-if="!TaskSee" style="margin-right:80px" @click="SaveTask">保存全部</el-button>
          <i @click="cleanData" class="el-icon-close" style="font-size:20px;cursor: pointer;"></i>
        </div>
      </div>
      <div class="taskPop-content">
        <div class="taskPop-left">
          <div class="label-item">
            <div class="item-title">
              <img src="../../../assets/images/state_icon.png">
              <div>
                状态
              </div>
            </div>
            <div v-if="from.taskState!='suspend'" :class="!from.taskStateChecked?'item-dispose':'item-dispose-plus'">
              <el-checkbox :disabled='TaskSee' :style="from.taskStateChecked?'background:rgba(235, 255, 243, 1)':'background:rgba(229, 229, 229, 1)'" class="checkbox" v-model="from.taskStateChecked">
                {{from.taskStateChecked?"已完成":"未完成"}}</el-checkbox>
            </div>
            <div v-else class="item-dispose-suspend">
              <el-checkbox :disabled='true' style="background:rgba(255, 209, 209, 1);" class="checkbox" v-model="from.taskStateChecked">
                已终止</el-checkbox>
            </div>
          </div>
          <div class="label-item">
            <div class="item-title">
              <img src="../../../assets/images/user_icon.png">
              <div>
                执行人
              </div>
            </div>
            <div class="item-dispose">
              <el-autocomplete :disabled='TaskSee' style="display: block; width: 170px" v-model="roleCode" size="mini" :fetch-suggestions="search" @select="selectGroup" @focus="groupListMe" placeholder="请输入负责人账号">
              </el-autocomplete>
            </div>
          </div>
          <div class="label-item">
            <div class="item-title">
              <img src="../../../assets/images/image_icon.png">
              <div>
                时间
              </div>
            </div>
            <div class="item-dispose">
              <el-date-picker :disabled='TaskSee' style="width:80%" v-model="TimeArr" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div class="label-item-area">
            <div class="item-title">
              <img src="../../../assets/images/label_icon.png">
              <div>
                描述
              </div>
            </div>
            <div class="item-dispose">
              <el-input :disabled='TaskSee' type="textarea" class="mytared" :rows="2" placeholder="请输入内容" v-model="from.description">
              </el-input>
            </div>
          </div>
          <div class="label-item-area">
            <div class="item-title">
              <img src="../../../assets/images/label_icon.png">
              <div>
                备注
              </div>
            </div>
            <div class="item-dispose">
              <el-input :disabled='TaskSee' type="textarea" class="mytared" :rows="2" placeholder="请输入内容" v-model="from.remark">
              </el-input>
            </div>
          </div>
          <div class="label-item-img">
            <div class="item-title">
              <img src="../../../assets/images/label_icon.png">
              <div>
                图片<span style="font-size:5px;color:rgba(24, 144, 255, 1)">最多9张</span>
              </div>
            </div>
            <div class="item-dispose">
              <draggableUpload :limit="TaskSee?0:9" v-model="chooseImg" :editImgName='true' :uploadUrl="imgUploadUrl()" :requestHeaders="requestHeaders" />
            </div>
          </div>
          <div class="label-item-file">
            <div class="item-title">
              <img src="../../../assets/images/label_icon.png">
              <div>
                文件<span style="font-size:5px;color:rgba(24, 144, 255, 1)">最多9个</span>
              </div>
            </div>
            <div class="item-dispose">
              <div class="fileList" v-for="(item,index) in fileList" :key="index">
                <div class="fileList-left">
                  <div class="fileList-img">
                    <img :src="item.fileImg">
                  </div>
                  <div class="fileList-title">
                    <div v-if="!item.edit">{{item.fileName}}</div>
                    <el-input v-else v-model="item.fileName" @blur='closeInput(index)'></el-input>
                    <div>{{item.fileSizeText}}</div>
                  </div>
                </div>
                <div class="fileList-operation">
                  <i v-if="!TaskSee" class="el-icon-delete" style="cursor: pointer;" @click="fileDetele(index)"></i>
                  <i v-if="!TaskSee" class="el-icon-edit" style="cursor: pointer;" @click="fileNameEdit(index)"></i>
                  <i class="el-icon-download" style="cursor: pointer;" @click="download(index)"></i>
                </div>
              </div>
              <el-upload v-if="fileList.length<9" class="upload-demo" :action="uploadUrl()" :headers="requestHeaders" :before-upload="beforeUpload" :data="fileData" :on-success="fileSuccess" :show-file-list="false">
                <div class="file" v-if="!TaskSee">
                  <div v-show="!isUploading">+</div>
                  <div v-show="!isUploading">上传文件</div>
                  <div v-show="isUploading" class="Uploading">
                    <i class="el-icon-loading uploadIcon"></i>
                    <span class="uploading">正在上传...</span>
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="taskPop-right">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="所有动态" name="1">
              <div class="queryTaskLog" v-for="(item,index) in TaskLogList" :key="index">
                <div class="queryTaskLog-top">
                  <div class="queryTaskLog-user">{{item.operaterName}} {{item.action}}</div>
                  <div class="queryTaskLog-time">{{item.operateTime}}</div>
                </div>
                <div class="queryTaskLog-bottom">
                  <div class="queryTaskLog-line"></div>
                  <div v-if="item.fileFlag==null" class="queryTaskLog-acitivty-null">{{item.content}}</div>
                  <div v-if="item.fileFlag=='img'" class="queryTaskLog-acitivty-null">
                    <draggableUpload :imgDel='true' :limit="0" v-model="item.fileUrlList" :editImgName='true' :uploadUrl="imgUploadUrl()" :requestHeaders="requestHeaders" />
                    <!-- <el-image style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList"></el-image> -->
                  </div>
                  <div v-if="item.fileFlag=='file'" class="queryTaskLog-acitivty-file">
                    <div class="fileList-log" v-for="(items,sonIndex) in item.fileUrlList" :key="sonIndex">
                      <div class="fileList-left">
                        <div class="fileList-img">
                          <img :src="items.fileImg">
                        </div>
                        <div class="fileList-title">
                          <div>{{items.fileName}}</div>
                          <div>{{items.fileSize}}</div>
                        </div>
                      </div>
                      <div class="fileList-operation">
                        <i class="el-icon-download" style="cursor: pointer;" @click="download(index,sonIndex)"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const moment = require("moment");
import draggableUpload from "COMPS/base/draggableUpload";
import userModule from "@/module/userModule";
import axiosHttp from "API/axiosHttp";
import { formatFileSize } from "@/js/formatFileSize";
import PmBrandService from "API/pm/PmBrandService";
import IoProgressService from "API/IoProgressService";
export default {
  data() {
    return {
      activeNames: ["1"],
      dialogVisible: false,
      isUploading: false,
      TaskSee: false,
      roleCode: "",
      Useritem: {},
      thisSearchData: [],
      TaskLogList: [],
      from: {
        projectTaskId: "", //任务ID（空为添加）
        projectId: "", //项目ID
        leaderId: "", //负责人ID
        description: "",
        title: "",
        remark: "",
        taskStateChecked: false,
      },
      chooseImg: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
      TimeArr: [],
      fileList: [],
      fileData: {
        module: "pm_task_file",
      },
    };
  },
  components: {
    draggableUpload,
  },
  mounted() {
    this.openTaskPop();
  },
  watch: {
    roleCode: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.possibleData = []; //这是定义好的用于存放下拉提醒框中数据的数组
        var len = this.thisSearchData.length; //SearchData
        var arr = [];
        for (var i = 0; i < len; i++) {
          //根据输入框中的值进行模糊匹配
          if (this.thisSearchData[i].account.indexOf(this.roleCode) >= 0) {
            arr.push({
              value: this.thisSearchData[i].account,
            });
          }
        }
        this.possibleData = arr;
      },
    },
  },
  methods: {
    search(queryString, cb) {
      console.log("search");
      var possibleData = this.possibleData;
      cb(possibleData);
    },
    terminatedTask(state) {
      let params = {
        projectTaskId: this.projectTaskId,
        taskState: state,
      };
      let content;
      if (state == "suspend") {
        content = "是否终止当前任务?";
      } else {
        content = "是否重启当前任务?";
      }
      this.$confirm(content, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        PmBrandService.setTaskState(params).then((data) => {
          this.$message({ type: "success", message: "操作成功" });
          this.dialogVisible = false;
          this.$emit("refreshData", true);
        });
      });
    },
    selectGroup(val) {
      console.log("selectGroup");
      console.log(val);
      for (let i in this.thisSearchData) {
        if (val.value == this.thisSearchData[i].account) {
          this.Useritem = this.thisSearchData[i];
        }
      }
      this.roleCode = val.value;
    },
    groupListMe() {
      PmBrandService.listUserInRole("shop_admin")
        .then((data) => {
          this.thisSearchData = [];
          this.possibleData = [];
          this.thisSearchData = data;
          for (let item of this.thisSearchData) {
            this.possibleData.push({
              value: item.account,
            });
          }
          console.log(this.possibleData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    download(index, sonIndex) {
      let module = this.fileData.module;
      console.log(index, sonIndex);
      if (sonIndex != undefined) {
        let row = this.TaskLogList[index].fileUrlList[sonIndex];
        console.log(row);
        if (row.fileUrl != null) {
          let fileName = row.fileUrl.split(this.fileData.module + "/")[1];
          IoProgressService.download(module, fileName, false);
        }
      } else {
        let row = this.fileList[index];
        if (row.fileUrl != null) {
          let fileName = row.fileUrl.split(this.fileData.module + "/")[1];
          IoProgressService.download(module, fileName, false);
        }
      }
    },
    imgIconDispose(fileUrl) {
      console.log(fileUrl);
      if (fileUrl != null) {
        fileUrl = fileUrl.split(this.fileData.module)[1];
        console.log(fileUrl);
        return fileUrl == undefined || fileUrl == ""
          ? require("ASSETS/images/imgIcon.png")
          : fileUrl.split(".")[1].indexOf("png") != -1
          ? require("ASSETS/images/imgIcon.png")
          : fileUrl.split(".")[1].indexOf("jpg") != -1
          ? require("ASSETS/images/imgIcon.png")
          : fileUrl.split(".")[1].indexOf("pdf") != -1
          ? require("ASSETS/images/pdfIcon.png")
          : fileUrl.split(".")[1].indexOf("xls") != -1
          ? require("ASSETS/images/xmlIcon.png")
          : fileUrl.split(".")[1].indexOf("falsh") != -1
          ? require("ASSETS/images/falshIcon.png")
          : fileUrl.split(".")[1].indexOf("html") != -1
          ? require("ASSETS/images/htmlIcon.png")
          : fileUrl.split(".")[1].indexOf("doc") != -1
          ? require("ASSETS/images/wordIcon.png")
          : require("ASSETS/images/imgIcon.png");
      }
    },
    openTaskPop() {
      this.$on("openTaskPop", (data) => {
        console.log(data);
        if (data.projectId) {
          this.from.projectId = data.projectId;
        }
        if (data.projectState) {
          console.log(data.projectState);
          this.from.projectState = data.projectState;
        }
        if (data.projectTaskId) {
          this.getDetail(data.projectTaskId);
          this.projectTaskId = data.projectTaskId;
        }
        if (data.TaskSee) {
          this.TaskSee = data.TaskSee;
        }
        this.dialogVisible = true;
      });
    },
    fileDetele(index) {
      this.$confirm(`是否删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.fileList.splice(index, 1);
      });
    },
    fileNameEdit(index) {
      this.fileList[index].edit = true;
    },
    closeInput(index) {
      this.fileList[index].edit = false;
    },
    getDetail(projectTaskId) {
      PmBrandService.TaskfindById(projectTaskId).then((data) => {
        console.log(data);
        this.from.projectTaskId = data.projectTaskId;
        this.from.projectId = data.projectId;
        this.from.leaderId = data.leaderId;
        this.from.description = data.description;
        this.from.title = data.title;
        this.from.remark = data.remark;
        this.Useritem.userId = data.leaderId;
        this.roleCode = data.leaderAccount;
        this.from.taskState = data.taskState;
        this.from.taskStateChecked =
          data.taskState == "complete" ? true : false;
        this.TimeArr = [data.beginTime, data.endTime];
        if (data.fileList) {
          this.fileList = data.fileList.map((e) => {
            return {
              dispNo: e.dispNo,
              fileName: e.fileName,
              fileType: e.fileType,
              fileUrl: e.fileUrl,
              fileSizeText: formatFileSize(e.fileSize),
              fileSize: e.fileSize,
              projectFileId: e.projectFileId,
              projectId: e.projectId,
              projectTaskId: e.projectTaskId,
              state: e.state,
              fileImg: this.imgIconDispose(e.fileUrl),
              edit: false,
            };
          });
        }
        if (data.imageList) {
          data.imageList = data.imageList.sort((a, b) => a.dispNo - b.dispNo); //排序
          let Arr = [];
          for (let i in data.imageList) {
            let params = {
              projectFileId: data.imageList[i].projectFileId,
              response: data.imageList[i].fileUrl,
              name: data.imageList[i].fileName,
              dispNo: data.imageList[i].dispNo,
            };
            Arr.push(params);
          }
          this.chooseImg = Arr;
        }
      });
      let params = {
        projectTaskId: projectTaskId,
      };
      PmBrandService.queryTaskLog(params).then((data) => {
        data.reverse(); //数组倒序
        data = data.map((e) => {
          return {
            action: e.action,
            content: e.content,
            fileFlag: e.fileFlag,
            operateTime: moment(e.operateTime).format("YYYY-MM-DD HH:mm:ss"),
            operaterId: e.operaterId,
            operaterName: e.operaterName,
            projectId: e.projectId,
            projectLogId: e.projectLogId,
            projectTaskId: e.projectTaskId,
            state: e.state,
            fileUrlList:
              e.fileUrlList != null
                ? e.fileFlag == "file"
                  ? e.fileUrlList.map((z) => {
                      return {
                        fileName: z.fileName,
                        fileUrl: z.fileUrl,
                        fileSize: formatFileSize(z.fileSize),
                        fileImg: this.imgIconDispose(z.fileUrl),
                      };
                    })
                  : e.fileFlag == "img"
                  ? e.fileUrlList.map((z) => {
                      return {
                        response: z.fileUrl,
                        name: z.fileName,
                      };
                    })
                  : []
                : [],
          };
        });
        this.TaskLogList = data;
      });
    },
    imgUploadUrl() {
      let url = `${axiosHttp.baseURL}/ioProgress/upload`;
      url = url + "?module=" + this.fileData.module;
      return url;
    },
    uploadUrl() {
      return `${axiosHttp.baseURL}/ioProgress/upload`;
    },
    beforeUpload(file) {
      console.log(file);
      if (file.name.indexOf("#") != -1) {
        this.$message.warning(`文件不能包含特殊字符'#'`);
        return false;
      }
      this.isUploading = true;
    },
    fileSuccess(response, file) {
      let fileList = this.fileList;
      let params = {
        projectFileId: "",
        fileName: file.name,
        fileUrl: response,
        fileSizeText: formatFileSize(file.size),
        fileSize: file.size,
        fileImg: this.imgIconDispose(response),
        dispNo: fileList.length + 1,
        edit: false,
      };
      fileList.push(params);
      this.$message({ type: "success", message: "上传成功" });
      this.isUploading = false;
    },
    cleanData() {
      this.from = {
        projectTaskId: "", //任务ID（空为添加）
        projectId: "", //项目ID
        leaderId: "", //负责人ID
        description: "",
        title: "",
        remark: "",
        taskStateChecked: false,
      };
      this.chooseImg = [];
      this.TimeArr = [];
      this.fileList = [];
      this.roleCode = "";
      this.Useritem = {};
      this.TaskLogList = [];
      this.dialogVisible = false;
    },
    SaveTask() {
      let from = this.from;
      let TimeArr = this.TimeArr;
      console.log(TimeArr);
      if (from.title == "") {
        return this.$message({
          message: "标题未填写完整",
          type: "warning",
        });
      }
      if (TimeArr == null || TimeArr.length == 0) {
        return this.$message({
          message: "执行时间未填写完整",
          type: "warning",
        });
      }
      let index = 0;
      let fileList = this.fileList.map((z) => {
        return {
          projectFileId: z.projectFileId ? z.projectFileId : "",
          fileName: z.fileName,
          fileUrl: z.fileUrl,
          dispNo: z.dispNo,
          fileSize: z.fileSize,
        };
      });
      let chooseImg = this.chooseImg.map((e) => {
        return {
          projectFileId: e.projectFileId ? e.projectFileId : "",
          fileName: e.name,
          fileUrl: e.response,
          dispNo: (index += 1),
        };
      });

      let params = {
        projectTaskId: from.projectTaskId ? from.projectTaskId : "",
        projectId: from.projectId ? from.projectId : "",
        leaderId: this.Useritem.userId,
        taskState:
          from.taskState != "suspend"
            ? from.taskStateChecked
              ? "complete"
              : "waiting"
            : "suspend",
        title: from.title,
        description: from.description,
        beginTime: moment(TimeArr[0]).valueOf(),
        endTime: moment(TimeArr[1]).valueOf(),
        remark: from.remark,
        imageList: chooseImg,
        fileList: fileList,
      };
      PmBrandService.Tasksave(params).then((data) => {
        this.dialogVisible = false;
        this.$message({ type: "success", message: "保存成功" });
        this.$emit("refreshData", true);
        this.cleanData();
      });
    },
  },
};
</script>

<style scoped>
#taskPop >>> .el-dialog__header {
  border: none;
  display: none;
}
#taskPop >>> .el-dialog__body {
  padding: 0;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 97.5%;
  height: 100%;
  border-bottom: 1px solid rgba(166, 166, 166, 1);
  padding: 17px 11px 14px 10px;
}
.header-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.header-left > img {
  width: 14px;
  height: 14px;
}
.header-left > p {
  width: 40px;
  margin: 0px 10px;
}
.header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.taskPop-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
  position: relative;
  overflow: hidden;
}
.taskPop-left {
  height: 800px;
  width: 60%;
  border-right: 1px solid rgba(166, 166, 166, 1);
  overflow-y: scroll;
}
.taskPop-right {
  height: 100%;
  width: 39%;
  position: absolute;
  right: 0;
  /* display: none; */
  overflow-x: hidden;
  overflow-y: scroll;
}
.label-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  /* height: 60px; */
}
.label-item-img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  /* height: 120px; */
}
.label-item-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  /* height: 150px; */
}
.label-item-file {
  width: 102%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  /* height: 150px; */
}
.item-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  width: 30%;
}
.item-title > img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.item-dispose {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.item-dispose-plus {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.item-dispose-suspend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.item-dispose >>> .checkbox {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding: 6px;
  border-radius: 5px;
}
.item-dispose-plus >>> .checkbox {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding: 6px;
  border-radius: 5px;
}
.item-dispose-suspend >>> .checkbox {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding: 6px;
  border-radius: 5px;
}
.item-dispose-plus >>> .el-checkbox__label {
  color: rgba(105, 199, 143, 1) !important;
}
.item-dispose-suspend >>> .el-checkbox__label {
  color: rgb(226, 99, 109) !important;
}
.item-dispose >>> .el-textarea__inner {
  width: 80%;
  height: 120px;
}
.file {
  width: 100px;
  height: 50px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
}
.file > div:nth-child(1) {
  font-size: 20px;
}
.fileList {
  width: 50%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background: rgba(245, 245, 245, 1);
  margin-bottom: 20px;
}
.fileList-log {
  width: 50%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background: rgba(245, 245, 245, 1);
  margin-bottom: 20px;
}
.fileList-left {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.fileList-img > img {
  width: 20px;
  height: 25px;
}
.fileList-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 17px;
}
.fileList-title > div:nth-child(1) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
}
.fileList-title > div {
  color: rgba(128, 128, 128, 1);
  font-size: 12px;
  margin: 2px 0px;
}
.fileList-operation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.fileList-operation > i {
  margin-left: 24px;
}

#taskPop >>> .el-collapse-item__header {
  padding: 0px 16px;
}
#taskPop >>> .el-collapse-item__content {
  padding: 0px 16px;
}
.queryTaskLog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0px;
}
.queryTaskLog-top {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
.queryTaskLog-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.queryTaskLog-line {
  width: 4px;
  height: 13px;
  background: rgba(196, 196, 196, 1);
}
.queryTaskLog-acitivty-null {
  margin-left: 5px;
}
.queryTaskLog-acitivty-file {
  margin-left: 5px;
}
.Uploading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Uploading > span {
  margin: 0px 5px;
}
</style>
