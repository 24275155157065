<template>
  <el-dialog title="充值" :visible.sync="dialogVisible" width="800px">
    <div v-if="payChannel=='none'">
      <p style="margin-top: 10px;font-size: 14px;">请选择付款方式：</p>
      <div class="none-div-class">
        <div class="none-choose-bord" @click="choosePayChannel('wxpay')">
          <img
              :src="require('ASSETS/images/payment/wxpay.png')"
              height="40px;"
          />
          <p style="margin-top: 5px;font-size: 14px;">微信支付</p>
        </div>
        <div class="none-choose-bord" @click="choosePayChannel('giro')">
          <img
              :src="require('ASSETS/images/payment/giro.png')"
              height="40px;"
          />
          <p style="margin-top: 5px;font-size: 14px;">线下转账</p>
        </div>
      </div>
    </div>
    <div v-show="payChannel=='wxpay'">
      <div v-show="wxpayStep==1">
        <div style="text-align: center">
          <img
              :src="require('ASSETS/images/payment/WePayLogo.png')"
              height="40px;"
          />
        </div>
        <div class="wxpay-div-one">
          <div v-for="(item,index) in defaultAmountList" :key="index"
               @click="chooseAmountAndWxpayToStep2(item)" class="wxpay-div-one-item">
            {{ item }}元
          </div>
        </div>
        <div class="wxpay-div-two">
          <p style="width: 100px;height: 40px;line-height: 40px;text-align: right;">其他金额：</p>
          <div style="width: 200px;">
            <el-input v-model="rechargeAmount" placeholder=""
                      oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
          </div>
          <el-button type="primary" @click="wxpayToStep2">确认</el-button>
        </div>
      </div>
      <div v-show="wxpayStep==2">
        <div style="display: flex; padding-left: 80px">
          <div>
            <div style="text-align: center;margin-top: 30px;font-size: 20px;">
              支付金额：{{ rechargeAmount }}元
            </div>
            <div
                id="qrCodeDom_rechargePop"
                style="width: 146px; height: 146px; margin:100px auto;text-align: center;line-height: 146px;"
            />
            <div style="display: flex; margin-top: 10px">
              <img
                  width="34.74px"
                  height="34.74px"
                  :src="require('ASSETS/images/payment/payment_tip2.png')"
                  alt=""
              />
              <div style="margin-left: 3.7px; line-height: 21px">
                请使用<span style="color: #5cdd61">微信</span
              >扫一扫扫描二维码支付,暂不支持截图识别。
              </div>
            </div>
          </div>
          <img
              :src="require('ASSETS/images/payment/pc_icon_phone-bg.png')"
              width="392px;"
              style="margin-left: 40px"
          />
        </div>
        <div slot="footer">
          <div
              style="
              display: flex;
              justify-content: center;
              width: 100%;
              padding-top: 10px;
            "
          >
            <el-button @click="wxpayStep=1">修改金额</el-button>
            <el-button type="primary" @click="confirmWxpay">确认支付</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payChannel=='giro'">
      <div
          style="
            margin-top: 20px;
            border: 1px solid rgba(218, 218, 218, 1);
            padding: 10px;
          "
      >
        <div style="display: flex">
          <h3 style="margin-top: 9px" class="ws-title3">目标账户</h3>
          <el-form ref="form" :model="companyBank" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="户名">
                  <el-input
                      v-model="companyBank.accountName"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="账号">
                  <el-input
                      v-model="companyBank.accountNo"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开户行">
                  <el-input
                      v-model="companyBank.bankName"
                      :disabled="true"
                      style="width: 400px !important;"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div style="display: flex">
          <h3 style="margin-top: 9px" class="ws-title3">我的账户</h3>
          <el-form ref="form" :model="myBank" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="户名">
                  <el-input v-model="myBank.accountName" style="width: 400px !important;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="账号">
                  <el-input v-model="myBank.accountNo" style="width: 400px !important;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开户行">
                  <el-input v-model="myBank.bankName" style="width: 400px !important;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div style="display: flex">
          <h3 style="margin-top: 9px" class="ws-title3">充值金额</h3>
          <el-form ref="form" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="金额">
                  <el-input v-model="rechargeAmount" @keyup.native='keyupEvent($event,rechargeAmount)'
                            maxlength="14">
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div>
          <el-alert
              title="凭证需体现付款账户姓名、账号、金额及收款账户"
              type="warning"
          ></el-alert>
          <div style="display: flex; margin-top: 20px">
            <h3 class="ws-title3">上传支付凭证</h3>
            <draggable-upload
                :width="82"
                :height="82"
                :limit="1"
                :fileSize="2048 * 1024"
                fileSizeText="2M"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="rechargeImgs"
                style="margin-left: 10px"
            ></draggable-upload>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div
            style="
              display: flex;
              justify-content: center;
              width: 100%;
              padding-top: 10px;
            "
        >
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doRechargeSubmit">提交</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import IoProgressService from "API/IoProgressService";
import Objects from "COMJS/Objects";
import dealerService from "API/dea/dealerService";
import orderPaymentService from "API/som/orderPaymentService";
import QRcode from "qrcodejs2";

export default {
  name: "rechargePop",
  components: {
    draggableUpload,
  },
  data() {
    return {
      dialogVisible: false,
      orderBatchCodes: '',
      rechargeAmount: 0.00,
      payChannel: 'none',
      //wxpay
      wxpayStep: 1,
      defaultAmountList: [500, 1000, 2000, 5000, 10000, 20000],
      outTradeNo:'',
      //giro
      companyBank: {
        accountName: "慧之乳业（青岛）有限公司",
        accountNo: "38010101040045365",
        bankName: "中国农业银行股份有限公司青岛市分行营业部",
      },
      myBank: {},
      rechargeImgs: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
    }
  },
  methods: {
    show(param) {
      //console.log(param)
      this.payChannel = 'none';
      this.wxpayStep = 1;
      if (param.batchCodes) {
        this.orderBatchCodes = param.batchCodes
        //console.log(this.orderBatchCodes)
      }
      this.dialogVisible = true;
    },
    doRechargeSubmit() {
      if (this.rechargeImgs.length == 0) {
        this.$message.error("请上传支付凭证");
        return;
      }
      if (this.rechargeAmount <= 0) {
        this.$message.error("请正确填写金额");
        return;
      }
      if (
          Objects.isEmpty(this.myBank.accountName) ||
          Objects.isEmpty(this.myBank.accountNo) ||
          Objects.isEmpty(this.myBank.bankName)
      ) {
        this.$message.error("请填写账户信息");
        return;
      }
      let param = {
        ...this.myBank,
        rechargeAmount: this.rechargeAmount,
        payChannel: 'giro',
        rechargeVoucher: this.rechargeImgs[0].response,
        orderBatchCodes: this.orderBatchCodes
      }
      //rechargeDeposit
      dealerService.rechargeDeposit(param).then(data => {
        //console.log(data)
        if (data.code == 'success') {
          this.$message({
            type: "success",
            message: "提交成功，等待审核！",
          });
          this.dialogVisible = false;
          this.$emit("reloadAfterRecharge");
        } else {
          this.$message.error(data.msg);
        }
      })
    },
    choosePayChannel(channel) {
      this.payChannel = channel;
      this.wxpayStep = 1;
    },
    chooseAmountAndWxpayToStep2(amount) {
      this.rechargeAmount = amount;
      this.wxpayToStep2();
    },
    wxpayToStep2() {
      this.wxpayStep = 2;
      document.getElementById("qrCodeDom_rechargePop").innerHTML = "二维码加载中...";
      let param = {
        rechargeAmount: this.rechargeAmount,
        payChannel: 'wxpay',
        orderBatchCodes: this.orderBatchCodes
      }
      dealerService.rechargeDeposit(param).then(data => {
        console.log(data)
        if (data.code == 'success' && data.payData) {
          this.setPayData(data.payData);
        } else {
          this.$message.error(data.msg);
        }
      })
    },
    setPayData(payData) {
      let codeUrl = payData["code_url"];
      this.outTradeNo = payData["outTradeNo"];
      document.getElementById("qrCodeDom_rechargePop").innerHTML = "";
      let codeImg = new QRcode("qrCodeDom_rechargePop", {
        width: 146,
        height: 146, // 高度
        text: codeUrl, // 二维码内容
        image: "",
      });
    },
    confirmWxpay(){
      let loading = this.$loading({
        lock: true,
        text: "支付结果确认中...",
      });
      let that = this
      // 原本是等待3s，但好像有遇到网络波动情况，最好再久一点，就5s吧
      setTimeout(function () {
        dealerService
            .checkPayState(that.outTradeNo)
            .then((data) => {
              if (data) {
                if (data["result"] == "success") {
                  that.$message({type: "success", message: "支付成功！"});
                  that.dialogVisible = false;
                  that.$emit("reloadAfterRecharge");
                } else {
                  //支付检查失败，提示
                  that.$message.error(data["msg"]);
                  that.dialogVisible = false;
                  that.$emit("reloadAfterRecharge");
                }
              }
              loading.close();
            })
            .catch((data) => {
              that.$message.error("支付失败");
              loading.close();
            });
      }, 5000);
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("payOrders");
      return url;
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, '');
      e.target.value = e.target.value.replace(/\.{2,}/g, '.');
      e.target.value = e.target.value.replace(/^\./g, '0.');
      e.target.value = e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0, e.target.value.length - 1));
      e.target.value = e.target.value.replace(/^0[^\.]+/g, '0')
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
      this.input = e.target.value
      //console.log(this.input)
    },
  }
}
</script>

<style scoped>
.ws-title3 {
  font-weight: bold;
}

.none-div-class {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 400px;
}

.none-choose-bord {
  border: 1px solid #8a8585;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #8a8585;
}

.wxpay-div-one {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
}

.wxpay-div-one-item {
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #333333;
  margin-right: 20px;
  margin-bottom: 20px;
}

.wxpay-div-two {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.wxpay-div-two > * {
  margin-right: 10px;
}
</style>