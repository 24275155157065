<template>
  <el-dialog
    title="修改收货地址"
    :visible.sync="dialogVisible"
    width="560px"
    v-loading="loading"
    :close-on-click-modal="false"
  >
    <div class="tips-wraning">
      <i class="el-icon-warning"></i>
      <span>仅支持修改一次，请务必在买家知情且同意下修改收货信息</span>
    </div>
    <el-form label-width="100px">
      <el-form-item required label="收货人：">
        <el-input v-model="orderInfo.recipientName"></el-input>
      </el-form-item>
      <el-form-item required label="联系电话：">
        <el-input
          v-model="orderInfo.recipientPhoneNum"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item required label="收货地址：">
        <area-cascader v-model="address"></area-cascader>
      </el-form-item>
      <el-form-item required label="详细地址：">
        <el-input v-model="orderInfo.recipientAddressDetail"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="changeAddress">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import areaCascader from "COMPS/base/areaCascader";
import orderOperate from "API/som/orderOperate";
import validator from "COMJS/validator";
import _ from "lodash";

export default {
  props: {
    info: Object
  },
  data() {
    return {
      orderInfo: {},
      dialogVisible: false,
      address: {
        province: "",
        city: "",
        area: ""
      },
      loading: false
    };
  },
  components: {
    areaCascader
  },
  watch: {
    info: {
      handler(info) {
        this.orderInfo = _.cloneDeepWith(info);
        this.address.province = info.recipientProvince;
        this.address.city = info.recipientCity;
        this.address.area = info.recipientCounty;
      },
      deep: true
    }
  },
  methods: {
    changeAddress() {
      let info = this.orderInfo;
      let address = this.address;
      if (!info.recipientName || !info.recipientPhoneNum || !info.orderId) {
        return this.$message({
          message: "请填写完整收件人信息",
          type: "warning"
        });
      }
      if (
        !address.province ||
        !address.city ||
        !address.area ||
        !info.recipientAddressDetail
      ) {
        return this.$message({
          message: "请填写完整地址信息",
          type: "warning"
        });
      }
      if (!validator.mobile(info.recipientPhoneNum)) {
        return this.$message({
          message: "手机号格式不正确",
          type: "warning"
        });
      }
      let params = {
        changeAddressDetail: info.recipientAddressDetail,
        changeProvince: address.province,
        changeCity: address.city,
        changeCounty: address.area,
        changeName: info.recipientName,
        changeTel: info.recipientPhoneNum,
        orderId: info.orderId
      };
      this.loading = true;
      orderOperate.orderChangeTransport(params).then(() => {
        this.dialogVisible = false;
        this.loading = false;
        this.$emit("changeAddress");
      }).catch(err=>{
          console.log(err);
          this.loading = false;
      });
    }
  }
};
</script>

<style scoepd lang="scss">
.el-dialog__footer {
  border-top: 1px none;
  text-align: right;
}
.tips-wraning {
  padding-left: 20px;
  border: 1px solid #f1924e;
  line-height: 46px;
  background-color: #fff5ed;
  color: #666;
  margin-bottom: 15px;
  .el-icon-warning {
    display: inline-block;
    margin-right: 10px;
    color: #ed6a0d;
  }
}
</style>