import axiosHttp from "../axiosHttp"

export default {
    queryUserPage(param) {
        return axiosHttp.POST('/bimUser/queryUserPage',param)
    },
    modifyPassword(param) {
        return axiosHttp.POST('/bimUser/modifyPassword',param)
    },
    createUser(param) {
        return axiosHttp.POST('/bimUser/createUser',param)
    }
}