const frame = { template: "<router-view></router-view>" };

export default {
  path: "fm",
  component: frame,
  children: [
    {
      name: "鲜奶发货",
      path: "milkOrderDelivery/:mode",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/MilkOrderDelivery.vue"),
    },
    {
      name: "鲜奶统计",
      path: "milkOrderStatistics",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/MilkOrderStatistics.vue"),
    },
    {
      name: "鲜奶入库记录",
      path: "freshMilkRecordList",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/SingleItemRecordList.vue"),
    },
    {
      name: "鲜奶售后",
      path: "milkOrderAfterList",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/MilkOrderAfterList.vue"),
    },
    {
      name: "奶卡列表",
      path: "freshMilkCardList",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/FreshMilkCardList.vue"),
    },
    {
      name: "奶卡详情",
      path: "freshMilkCardDetail/:milkCardId",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/FreshMilkCardDetail.vue"),
    },
    {
      name: "鲜奶订单",
      path: "freshMilkOrderManager",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/FreshMilkOrderManager.vue"),
    },
    {
      name: "客户下单",
      path: "customerOrderCreator",
      meta: { noKeepAlive: false },
      component: (resolve) => import("PAGES/fm/CustomerOrderCreator.vue"),
    },
    {
      name: "客户订单",
      path: "customerOrderManager",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/CustomerOrderManager.vue"),
    },
    {
      name: "客户列表",
      path: "customerManager",
      meta: { noKeepAlive: true },
      component: (resolve) => import("PAGES/fm/CustomerManager.vue"),
    },
  ],
};
