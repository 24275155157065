import axiosHttp from "../axiosHttp";

export default {
  /**
   * @param {*} OrderOperateParam
   * @param {String} OrderOperateParam.changeAddressDetail 修改地址-详细地址
   * @param {String} OrderOperateParam.changeCity  修改地址-市 ,
   * @param {String} OrderOperateParam.changeCounty  修改地址-区县 ,
   * @param {String} OrderOperateParam.changeName  修改地址-姓名 ,
   * @param {String} OrderOperateParam.changeProvince  修改地址-省 ,
   * @param {String} OrderOperateParam.changeRemark  修改订单的备注 ,
   * @param {String} OrderOperateParam.changeTel  修改地址-电话 ,
   * @param {Number} OrderOperateParam.changeTotalAmount 修改价格 ,
   * @param {String} OrderOperateParam.orderId  订单ID ,
   * @param {String} OrderOperateParam.remark  本次修改的备注
   * @returns
   */
  // 取消订单
  cancelOrder(params) {
    return axiosHttp.POST('/somOrderOperate/cancel', params);
  },
    // 批量取消订单
    cancelOrderList(params) {
        return axiosHttp.POST('/somOrderOperate/cancelList', {selectOrderParam:params});
    },
  // 订单改价
  orderChangeAmount(OrderOperateParam) {
    return axiosHttp.POST('/somOrderOperate/changeAmount', OrderOperateParam);
  },
  // 修改备注
  orderChangeRemark(OrderOperateParam) {
    return axiosHttp.POST('/somOrderOperate/changeRemark', OrderOperateParam);
  },
  // 修改地址
  orderChangeTransport(OrderOperateParam) {
    return axiosHttp.POST('/somOrderOperate/changeTransport', OrderOperateParam);
  },
  // 发货
  orderSentOut(params) {
    return axiosHttp.POST('/somOrderOperate/sentOut', params)
  },
  // 完成订单
  doCompleteOrder(params) {
    return axiosHttp.POST('/somOrderOperate/confirmOrderComplete', params);
  },
  // 导出下单模板
  exportOrderTemplate(param) {
    return axiosHttp.POST('/somOrderImportBatch/exportOrderTemplate',param)
  }
}