<template>
  <div class="logistics-wrap">
    <el-button type="primary" @click="printOut">打印</el-button>
    <div id="subOutputRank" class="print-me">
        <div class="print-head">
          <div>感谢您选购渥康官方商品！</div>
          <img src="../../../assets/images/wdom2.png" alt="">
        </div>
        <div class="print-con">
          <div class="print-tit">
            渥康产品发货-承运单
          </div>
          <div class="print-lit-tit">出库单号：{{whmInfo.outboundCode}}</div>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table">
            <tr>
              <td>发货方：{{whmInfo.senderOrganize}}</td>
              <td>发货时间：</td>
              <td rowspan="3">发货签名：</td>
            </tr>
            <tr>
              <td>发货人：{{whmInfo.senderName}}</td>
              <td>发货方电话：{{whmInfo.senderPhone}}</td>
            </tr>
            <tr>
              <td colspan="2">发货方地址：{{whmInfo.senderAddress}}</td>              
            </tr>

            <tr>
              <td>收货方：{{whmInfo.receivingOrganize}}</td>
              <td>收货时间：</td>
              <td rowspan="3">收货签名：</td>
            </tr>
            <tr>
              <td>收货人：{{whmInfo.receiverName}}</td>
              <td>收货方电话：{{whmInfo.receivingPhone}}</td>
            </tr>
            <tr>
              <td colspan="2">收货方地址：{{whmInfo.receivingAddress}}</td>              
            </tr>

            <tr>
              <td>承运单号：{{whmInfo.carrierAppCode}}</td>
              <td>承运时间：{{whmInfo.carrierTime}}</td>
              <td rowspan="3">承运签名：</td>
            </tr>
            <tr>
              <td>承运人：{{whmInfo.carrierOrganize}}</td>
              <td>承运人电话：{{whmInfo.carrierPhone}}</td>
            </tr>
            <tr>
              <td>承运公司：{{whmInfo.carrierCompany}}</td>
              <td>承运车辆：{{whmInfo.carrierCar}}</td>              
            </tr>
          </table>

          <table border="0" cellpadding="0" cellspacing="0" class="print-table fs14">
            <tr>
              <th colspan="7">商品清单</th>
            </tr>
            <tr class="print-table-tr">
              <td>商品名称</td>
              <td>商品编码</td>
              <td>库品信息</td>
              <td>商品条形码</td>
              <td>批次号</td>
              <td>生产日期</td>
              <td>商品数量</td>
            </tr>
            <tr v-for="item in whmInfo.pimGoodsDTOS"
                :key="item.goodsCode"
            >
              <td>{{item.cnName}}</td>
              <td>{{item.goodsCode}}</td>
              <td>{{item.skuInfoList}}</td>
              <td>{{item.goodsBarCode}}</td>
              <td>{{item.skuPurchaseBatchCode}}</td>
              <td>{{item.productionDate}}</td>
              <td>{{item.goodsCount}}</td>
            </tr>            
          </table>
          
          <div class="print-remark">订单备注：{{whmInfo.orderRemark}}</div>
          <div class="p1 mt20">送货方式：{{whmInfo.deliveryMethod == "0" ? "寄付" : whmInfo.deliveryMethod == "1" ? "到付" : ""}}</div>
          <div class="p1">运费金额：¥{{whmInfo.transportFee}}(以实际称重为准)</div>
          <div class="p1">运费付款：{{whmInfo.transportationCostMethod == "0" ? "到门" : whmInfo.transportationCostMethod == "1" ? "自提" : ""}}</div>

          <div class="p2 mt20">1.以上签名处等同于公司印鉴，签名后有同样法律效力。</div>
          <div class="p2">2.收货签名视同于对产品数量无异议，如有产品破损，请在备注处标记品名及数量。</div>
          <div class="p2">3.本单据一式三联，由发货方发起，收货方终止，不得涂改，真实数据均具有法律效力。</div>

        </div>
    </div>    
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import tools from "COMJS/tools";
import WhmList from "API/whm/WhmList";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

export default {
  name: "receiveList",
  data() {
    return {
      whmInfo:{},
      // tableData:{},
      teacherName:"测试pdf",
      searchInfo:{        
        outboundDocumentsId: ""
      },
      items: {
        outboundDocumentsId: { filter: "EQ" },
      },
    };
  },
  mixins: [pageTableMixin],
  directives: {
    print   
  },
  mounted() {
    this.getGoodsDetail();
  },
  methods: {
    printOut() {
      var title = this.teacher;
      html2Canvas(document.querySelector("#subOutputRank"), { 
        allowTaint: true
      }).then(function(canvas) {
        // console.log( canvas);
        // console.log( canvas.width);
        // console.log( canvas.height);
        let contentWidth = canvas.width;//画布总宽度
        let contentHeight = canvas.height;//画布总高度
        let pageHeight = contentWidth / 592.28 * 841.89;//每个页面的高度
        let leftHeight = contentHeight;
        // console.log( contentWidth);
        // console.log( contentHeight);
        // console.log( pageHeight);
        // console.log(leftHeight)
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = 592.28 / contentWidth * contentHeight;
        //console.log(imgHeight)
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        //console.log(pageData)
        let PDF = new JsPDF("", "pt", "a4");
        //console.log(PDF)

        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          let orgImgHeight = imgHeight
          let num = 30
          let i = 0
          while (leftHeight > 0) {
            i++
            // console.log(imgHeight)
            // console.log(num)
            imgHeight = orgImgHeight + num * i            
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 842;
            if (leftHeight > 0) {
              num = 30
              PDF.addPage();
            }
          }
        }
        //return
        PDF.save("渥康产品发货-承运单" + ".pdf");
      });
    },
    getGoodsDetail() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
      });
      WhmList.detailLogistics(this.$route.params.id).then((data) => {
        this.whmInfo = data
        // tableData = this.whmInfo.pimGoodsDTOS
        loading.close();
      });
    },
    loadTableData() {
      this.searchInfo.outboundDocumentsId = this.$route.params.id
      WhmList.queryLogisticsDetail(this.pageInfo, this.searchConfig).then((data) => {     
        for(let i in data.list){
          if(data.list[i].skuList.length > 0){
            data.list[i].skuInfoList = ""
            data.list[i].skuPurchaseBatchCode = ""
            data.list[i].productionDate = ""
            for(let j in data.list[i].skuList){
              if(data.list[i].skuList[j].skuCode){
                data.list[i].skuPurchaseBatchCode += data.list[i].skuList[j].skuCode + ":" + data.list[i].skuList[j].skuPurchaseBatchCode + "+"
                data.list[i].skuInfoList += data.list[i].skuList[j].skuCode + "*" + data.list[i].skuList[j].skuNum + "+"
                data.list[i].productionDate += data.list[i].skuList[j].skuCode + ":" + tools.formatDate(new Date(data.list[i].skuList[j].productionDate-0), "yyyy-MM-dd hh:mm:ss") + "+"
              }
            }
            data.list[i].skuPurchaseBatchCode = data.list[i].skuPurchaseBatchCode.substring(0, data.list[i].skuPurchaseBatchCode.length-1);
            data.list[i].skuInfoList = data.list[i].skuInfoList.substring(0, data.list[i].skuInfoList.length-1);
            data.list[i].productionDate = data.list[i].productionDate.substring(0, data.list[i].productionDate.length-1);
          }
        }    
        data.list = data.list.map((e) => {
          return {
            goodsName: e.goodsName,
            goodsCode: e.goodsCode,
            skuInfoList: e.skuInfoList,
            goodsPurchaseBatchCode: e.goodsPurchaseBatchCode,
            goodsBarCode: e.goodsBarCode,
            skuPurchaseBatchCode: e.skuPurchaseBatchCode,
            productionDate: e.productionDate,
            num: e.num,
          };
        });
        this.querySuccess(data);
      });      
    },   
  },
};
</script>
<style>
.print-me{ margin-top: 40px; width: 210mm; box-sizing: border-box; padding:25px; background: #fff;}
.print-head{ display: flex; justify-content: space-between; align-items: center; font-size: 12px; padding-bottom: 6px; border-bottom: 1px solid #000;}
.print-head img{ width: 33px;}
.print-tit{ margin-top: 12px; font-size: 18px; text-align: center; color: #333;}
.print-lit-tit{ margin-top: 20px; font-size: 12px;}
.print-table{ margin-top: 14px; width: 100%; border-top: 1px solid #000; border-left: 1px solid #000; font-size: 10px;
word-wrap:break-word;word-break:break-all;white-space:normal;}
.print-table td,.print-table th{ padding:4px 4px 4px 6px; box-sizing: border-box; border-right: 1px solid #000; border-bottom: 1px solid #000;}
.fs14{ font-size: 10px;}
.print-remark{ margin-top: 30px; font-size: 12px;}
.p1{ padding:6px 0; font-size: 12px;}
.mt20{ margin-top: 10px;}
.p2{ padding:6px 0; font-size: 10px;}
.print-table-tr td{ white-space: nowrap;}
</style>