<template>
  <div id="information">
    <div class="information-title">商品基础</div>
    <div class="label">
      <div class="label-column">
        <div><span style="color:red">*</span> 商品名称</div>
        <el-input v-model="newGoodsData.cnName" placeholder="请输入商品名称" class="myInput" :disabled='toView' :maxlength='35'
                  show-word-limit></el-input>
        <div class="label-describe">最多填写35个字</div>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 商品规格</div>
        <el-input v-model="newGoodsData.goodsSpec" placeholder="请输入商品规格" class="myInput" :disabled='toView' :maxlength='20'
                  show-word-limit></el-input>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 商品编码</div>
        <el-input v-model="newGoodsData.goodsCode" placeholder="请输入商品编码" class="myInput" :disabled='toView'></el-input>
        <!--@blur="checkReapt(newGoodsData.goodsCode)"-->
        <div class="label-describe">建议使用大小写字母+数字组合，不可使用+、*等计算符号。
          暂无长度限制，用于会员批量导入订单使用。
        </div>
      </div>
    </div>
    <div class="label">
      <!--
      <div class="label-column">
        <div><span style="color:red">*</span> 商品分类</div>
        <el-input class="myInput" :disabled='toView' v-model="newGoodsData.categoryName"
                  @focus="$refs.selectCategoryPopup.dialogVisible = true" placeholder="请选择商品分类">
        </el-input>
      </div>
      -->
      <div class="label-column">
        <div><span style="color:red">*</span> 可售状态</div>
        <el-select v-model="newGoodsData.goodsState" placeholder="请选择可售状态" class="myselect" :disabled='toView'>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 聚合字段</div>
        <el-input class="myInput" :disabled='toView' v-model="newGoodsData.spuGroupName"
                  placeholder="请输入聚合字段">
        </el-input>
        <div class="label-describe">用于聚合多个商品，通过选择规格切换商品。</div>
        <div class="label-describe">如果不需要聚合功能，建议
          <span class="a-tag" @click="syncSpuGroupName">
          同步商品名称
          </span>
        </div>
      </div>
    </div>
    <div class="label">
      <div class="label-column">
        <div><span style="color:red">*</span> 上架时间：</div>
        <div class="myInput">
          <el-radio-group v-model="newGoodsData.onShelfType" :disabled='toView'>
            <el-radio :label="1">立即上架</el-radio>
            <div class="timing label-row-one">
              <el-radio :label="2">定时上架</el-radio>
              <el-date-picker v-model="newGoodsData.effectiveTime" :disabled="newGoodsData.onShelfType!=2" type="datetime"
                              placeholder="选择日期时间">
              </el-date-picker>
            </div>
            <el-radio style="margin-top:20px" :label="3">暂不售卖</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 下架时间：</div>
        <div class="myInput">
          <el-radio-group v-model="newGoodsData.offShelfType" :disabled='toView'>
            <el-radio :label="1">默认关闭</el-radio>
            <div class="timing label-row-one">
              <el-radio :label="2">定时下架</el-radio>
              <el-date-picker v-model="newGoodsData.failureTime" :disabled="newGoodsData.offShelfType!=2" type="datetime"
                              placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="label">
      <div class="label-column">
        <div><span style="color:red">*</span> 是否支持批量导入</div>
        <el-switch class="myInput"
                   v-model="newGoodsData.allowOrderImport" :disabled='toView' active-text="是"
                   inactive-text="否">
        </el-switch>
        <div class="label-describe">选择是，可在批量导入模板看到该商品，并正常导入；选择否，则不可见，且无法导入。</div>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 是否支持样品下单</div>
        <el-switch class="myInput"
                   v-model="newGoodsData.allowOrderSample" :disabled='toView' active-text="是"
                   inactive-text="否">
        </el-switch>
      </div>
    </div>
    <div class="information-title">商品价格</div>
    <div class="label">
      <div class="label-column">
        <div><span style="color:red">*</span> 原价</div>
        <el-input class="myInput" v-model="newGoodsData.initPrice" placeholder="￥0.00" :disabled='toView'></el-input>
        <div class="label-describe">商品原价，在无优惠又能购买的情况下才会使用</div>
      </div>
      <div class="label-column-none">
        <div>划线价</div>
        <el-input class="myInput" v-model="newGoodsData.markingPrice" placeholder="￥0.00" :disabled='toView'></el-input>
        <div class="label-describe">非必须，纯展示用</div>
      </div>
    </div>
    <div class="information-title">商品图片</div>
    <div class="label">
      <div class="label-column">
        <div><span style="color:red">*</span> 商品略图</div>
        <div>
          <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                            :requestHeaders="requestHeaders" v-model="newGoodsData.imgInfo.imgFirst"></draggable-upload>
        </div>
        <div class="label-describe">
          在小程序订单详情或选择商品规格处展示。
        </div>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 分享图（微信对话）</div>
        <div>
          <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                            :fileSize='131072' fileSizeText='128kb' :requestHeaders="requestHeaders"
                            v-model="newGoodsData.imgInfo.imgSecond"></draggable-upload>
        </div>
        <div class="label-describe">
          尺寸比例5:4，小于128kb，分享到微信对话展示
        </div>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 分享图（朋友圈）</div>
        <div>
          <draggable-upload class="myInput" :width="82" :height="82" :limit="1" :uploadUrl="uploadUrl()"
                            :requestHeaders="requestHeaders" v-model="newGoodsData.imgInfo.imgThird"></draggable-upload>
        </div>
        <div class="label-describe">
          分享到微信朋友圈或下载的图片展示。
        </div>
      </div>
    </div>
    <div class="label">
      <div class="label-column-long">
        <div><span style="color:red">*</span> 商品轮播图</div>
        <div>
          <draggable-upload class="myInput" :width="82" :height="82" :limit="10" :uploadUrl="uploadUrl()"
                            :requestHeaders="requestHeaders" v-model="newGoodsData.imgInfo.imgFourth"></draggable-upload>
        </div>
        <div class="label-describe">
          在小程序商品详情页头部轮播
        </div>
      </div>
    </div>
    <div class="information-title">商品快递</div>
    <div class="label">
      <div class="label-column">
        <div class="item-row"><span style="color:red">*</span> 运费模板
          <!---->
          <span v-if="!toView" @click="addFreight" class="AddItem">新增</span>
        </div>
        <el-select class="myselect" v-model="newGoodsData.goodsInfo.transportId" placeholder="运费模版" :disabled='toView'>
          <el-option v-for="item in transportTemplateList" :key="item.id" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="label-column">
        <div><span style="color:red">*</span> 重量(kg)</div>
        <el-input v-model="newGoodsData.goodsInfo.weight" placeholder="请输入商品重量" class="myInput"
                  :disabled='toView'></el-input>
      </div>
    </div>
    <div class="information-title">商品销量</div>
    <div class="label">
      <div class="label-column-none">
        <div>虚拟销量</div>
        <el-input v-model="newGoodsData.goodsInfo.virtualSale" placeholder="请输入虚拟销量" class="myselect"
                  :disabled='toView'></el-input>
      </div>
    </div>
    <!-- <div class="label">
            <div class="label-column-none">
                <div>是否展示销量</div>
                <el-switch style="margin:20px 0px" v-model="newGoodsData.goodsInfo.mallShowSale" active-text="是" inactive-text="否">
                </el-switch>
                <div class="label-describe">用于展示至小程序，展示销量=虚拟销量+真实销量</div>
            </div>
        </div> -->
    <div class="sumbit">
      <el-button @click="back">取消</el-button>
      <el-button type="primary" @click="save" :disabled='toView'>保存</el-button>
      <el-button type="primary" @click="save('next')" :disabled='toView'>下一步并保存</el-button>
    </div>
    <!--
    <select-category-popup ref="selectCategoryPopup" @submit="selectCategory"></select-category-popup>
    -->
  </div>
</template>
<script>
import Vue from "vue";
import Objects from "COMJS/Objects"
import PimTransportService from "API/pim/PimTransportService";
import selectCategoryPopup from "../product/addProductStep/selectCategoryPopup.vue";
import IoProgressService from "API/IoProgressService.js";
import userModule from "@/module/userModule";
import draggableUpload from "COMPS/base/draggableUpload";
import PimGoodsService from "API/pim/PimGoodsService";

export default {
  props: {
    goodsData: {
      type: Object,
      default: {
        goodsInfo:{},
        imgInfo: {},
        allowOrderType:''
      }
    },
    toView: Boolean,
  },
  computed:{

  },
  data() {
    return {
      oldGoodsData:{},
      newGoodsData:{
        goodsInfo:{},
        imgInfo:{}
      },
      transportTemplateList: [],
      requestHeaders: {
        Authorization: userModule.token,
      },
      options: [
        {
          value: "normal",
          label: "可售",
        },
        {
          value: "sell_out",
          label: "售罄",
        },
      ],
    };
  },
  watch: {
    goodsData(){
      //console.log("information","goodsData",this.goodsData);
      let data = this.goodsData
      //判断allowOrderImport
      data.allowOrderImport = (data.allowOrderType.indexOf("import")>-1);
      //allowOrderSample
      data.allowOrderSample = (data.allowOrderType.indexOf("sample")>-1);
      //判断时间
      let minTimestamp = new Date("1971-01-01 00:00:00").getTime();
      let maxTimestamp = new Date("2038-01-01 00:00:00").getTime();
      let nowTimestamp = new Date().getTime();
      if (data.effectiveTime) {
        if (data.effectiveTime == minTimestamp) {
          data.onShelfType = 1;
        } else if (data.effectiveTime == maxTimestamp) {
          data.onShelfType = 3;
        } else {
          if (data.effectiveTime > nowTimestamp) {
            data.onShelfType = 2;
            //this.goodsData.onShelfTime = new Date(data.effectiveTime)
          } else {
            data.onShelfType = 1;
          }
        }
      }
      if (data.failureTime) {
        if (data.failureTime == maxTimestamp) {
          data.offShelfType = 1;
        } else if (data.failureTime == minTimestamp) {
          data.offShelfType = 1;
          data.onShelfType = 3;
          data.onShelfTime = "";
        } else {
          data.offShelfType = 2;
          data.offShelfTime = new Date(data.failureTime);
          if (data.failureTime < nowTimestamp) {
            data.onShelfType = 3;
            data.onShelfTime = "";
          }
        }
      }
      //old
      this.oldGoodsData = JSON.parse(JSON.stringify(data));
      this.newGoodsData = Objects.deepCopy({}, data);
      console.log("information","newGoodsData",this.newGoodsData);
    },
  },
  components: {
    selectCategoryPopup,
    draggableUpload,
  },
  mounted() {
    this.getTransportTemplateList();
  },
  methods: {
    getTransportTemplateList() {
      PimTransportService.getAllList().then((data) => {
        this.transportTemplateList = data.map((e) => {
          e.id = e.transportId;
          e.name = e.transportDescribe;
          return e;
        });
      });
    },
    syncSpuGroupName(){
      let temp = this.newGoodsData;
      temp.spuGroupName = temp.cnName;
      this.newGoodsData = temp;
      //Vue.set(this.newGoodsData, 'spuGroupName', temp.cnName)
    },
    addFreight() {
      this.$router.push({
        path: "fareModel",
      });
      this.$store.commit("setProductStepsData_Back", true);
    },
    checkDataChange(){
      //新建商品
      if(!this.newGoodsData.goodsId) return true;
      //编辑商品
      if (
          JSON.stringify(this.oldGoodsData) != JSON.stringify(this.newGoodsData)
      ) {
        return true;
      }
      return false;
    },
    saveDataChange(){
      let state = this.save();
      return state
    },

    checkDataAvailable(){
      //console.log(this.newGoodsData)
      let goodsData = this.newGoodsData;
      if (!goodsData.cnName) {
        this.$message.warning("商品名称不能为空");
        return false;
      }
      if (!goodsData.goodsCode) {
        this.$message.warning("商品编码不能为空");
        return false;
      }
      if (!goodsData.initPrice) {
        this.$message.warning("商品原价不能为空");
        return false;
      }
      if (goodsData.initPrice<=0) {
        this.$message.warning("商品原价不能为0");
        return false;
      }
      // if (!goodsData.categoryId) {
      //   this.$message.warning("商品分类不能为空");
      //   return false;
      // }
      if (!goodsData.goodsState) {
        this.$message.warning("可售状态不能为空");
        return false;
      }
      if (!goodsData.imgInfo.imgFirst.length) {
      //if (!this.chooseImgFirst.length) {
        this.$message.warning("商品略图不能为空");
        return false;
      }
      if (!goodsData.imgInfo.imgSecond.length) {
      //if (!this.chooseImgSecond.length) {
        this.$message.warning("分享图（微信对话）不能为空");
        return false;
      }
      if (!goodsData.imgInfo.imgThird.length) {
      //if (!this.chooseImgThird.length) {
        this.$message.warning("分享图（朋友圈）不能为空");
        return false;
      }
      if (!goodsData.imgInfo.imgFourth.length) {
        //if (!this.chooseImgSecond.length) {
        this.$message.warning("轮播不能为空");
        return false;
      }
      if (!goodsData.goodsInfo.transportId) {
        this.$message.warning("运费模板不能为空");
        return false;
      }
      if (!goodsData.goodsInfo.weight) {
        this.$message.warning("商品重量不能为空");
        return false;
      }
      return true;
    },
    initEffectiveAndFailureTime(){
      let goodsData = this.newGoodsData;
      //开始结束时间，需要最大最小时间辅助
      let _effectiveTime;
      let _failureTime;
      let minTimestamp = new Date("1971-01-01 00:00:00");
      let maxTimestamp = new Date("2038-01-01 00:00:00");
      if (goodsData.onShelfType == 1) {
        _effectiveTime = minTimestamp;
      } else if (goodsData.onShelfType == 2) {
        if (!goodsData.effectiveTime) {
          this.$message.warning("请选择自定义上架时间")
          return false;
        }
        _effectiveTime = goodsData.effectiveTime;
      } else if (goodsData.onShelfType == 3) {
        _effectiveTime = minTimestamp;
      }

      if (goodsData.onShelfType == 3) {
        //暂时不上架
        _failureTime = minTimestamp;
      }else{
        if (goodsData.offShelfType == 1) {
          //关闭定时下架
          _failureTime = maxTimestamp;
        } else if (goodsData.offShelfType == 2) {
          //定时下架
          _failureTime = goodsData.failureTime;
        }
      }
      if (_effectiveTime > _failureTime) {
        this.$message.warning("自定义上架时间必须在定时下架时间之前")
        return false;
      }
      this.newGoodsData.effectiveTime = _effectiveTime;
      this.newGoodsData.failureTime = _failureTime;
      return true;
    },
    save(next) {
      //判断是否可以保存
      if(!this.checkDataAvailable()){
        return;
      }
      //开始结束时间计算
      if(!this.initEffectiveAndFailureTime()){
        return;
      }

      //轮播图片
      let _sowPhotoAlbums = this.newGoodsData.imgInfo.imgFourth.map((e, i) => {
        return {
          photoUrl: e.response,
          name: e.name,
          state: 1,
          photoType: "sow",
          position: i + 1,
        };
      });
      //是否导入和样品
      let allowOrderTypeTemp = "";
      if(this.newGoodsData.allowOrderImport){
        allowOrderTypeTemp += "import,"
      }
      if(this.newGoodsData.allowOrderSample){
        allowOrderTypeTemp += "sample,"
      }


      let params = {
        state: 1,
        goodsType: "common",
        ...this.newGoodsData,
        goodsId: this.newGoodsData.goodsId ? this.newGoodsData.goodsId : "",
        cnName: this.newGoodsData.cnName, //名称
        goodsCode: this.newGoodsData.goodsCode, //编号
        goodsSpec: this.newGoodsData.goodsSpec, //编号
        goodsState: this.newGoodsData.goodsState, //销售状态
        categoryId: this.newGoodsData.categoryId, //分类
        effectiveTime: this.newGoodsData.effectiveTime, //上架时间
        failureTime: this.newGoodsData.failureTime, //下架时间
        pictureUrl: this.newGoodsData.imgInfo.imgFirst.length
            ? this.newGoodsData.imgInfo.imgFirst[0].response : "", //商品缩略图
        sharePictureUrl: this.newGoodsData.imgInfo.imgSecond.length
            ? this.newGoodsData.imgInfo.imgSecond[0].response : "", //微信对话框图
        shareMomentsPictureUrl: this.newGoodsData.imgInfo.imgThird.length
            ? this.newGoodsData.imgInfo.imgThird[0].response : "", //微信朋友圈图
        //allowOrderType: this.newGoodsData.allowOrderImport ? "import" : "", //是否批量导入
        allowOrderType: allowOrderTypeTemp, //是否批量导入和样品
        spuGroupName: this.newGoodsData.spuGroupName,
        goodsInfo: {
          virtualSale: this.newGoodsData.goodsInfo.virtualSale, //虚拟销量
          transportId: this.newGoodsData.goodsInfo.transportId, //运费模板
          weight: this.newGoodsData.goodsInfo.weight,
          // this.newGoodsData.goodsInfo.mallShowSale == true ? 1 : 0, //是否展示销量
          sharePictureUrl: this.newGoodsData.imgInfo.imgSecond.length
              ? this.newGoodsData.imgInfo.imgSecond[0].response
              : "", //微信对话框图
          shareMomentsPictureUrl: this.newGoodsData.imgInfo.imgThird.length
              ? this.newGoodsData.imgInfo.imgThird[0].response
              : "", //微信朋友圈图
        },
        sowPhotoAlbums: _sowPhotoAlbums,
      };

      PimGoodsService.saveGoods(params).then((res) => {
        this.$message.success("保存成功");

        this.newGoodsData.goodsId = res.goodsId;
        this.oldGoodsData = JSON.parse(JSON.stringify(this.newGoodsData));
        //console.log(res);
        res.categoryName = this.newGoodsData.categoryName;
        this.$emit("returnGoodsData", res);
        if (next == "next") {
          this.$emit("swiStep", 2);
        }
      });
      return 1;
    },

    selectCategory(params) {
      this.newGoodsData.categoryId =
          params.selectedThirdCategory.id ||
          params.selectedSecondCategory.id ||
          params.selectedFirstCategory.id;

      let a =
          params.selectedFirstCategory.nodeName == undefined
              ? " "
              : params.selectedFirstCategory.nodeName;
      let b =
          params.selectedSecondCategory.nodeName == undefined
              ? " "
              : params.selectedSecondCategory.nodeName;
      let c =
          params.selectedThirdCategory.nodeName == undefined
              ? " "
              : params.selectedThirdCategory.nodeName;
      this.newGoodsData.categoryName = a + " " + b + " " + c;
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("pimGoods");
      return url;
    },

    back() {
      this.$confirm(`是否取消？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style scoped>
@import url("./malllCss.css");

#information {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}
</style>