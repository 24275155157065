<template>
  <div>
    <el-form :disabled="unEdit" label-width="100px" label-position="left" :model="eventDetail" :rules="rules">
      <el-form-item label="活动名称" prop="eventName">
        <el-input v-model="eventDetail.eventName"/>
      </el-form-item>
      <el-form-item label="活动时间" prop="time">
        <el-col :span="5">
          <el-date-picker v-model="eventDetail.beginTime" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </el-col>
        <el-col :span="1" style="text-align: center;">至</el-col>
        <el-col :span="5">
          <el-date-picker type="datetime" v-model="eventDetail.endTime" placeholder="选择日期时间">
          </el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="权重">
        <el-input maxlength="8" @keyup.native="keyupEvent($event,eventDetail.weight)" v-model="eventDetail.weight"/>
      </el-form-item>
      <el-form-item label="体系" prop="eventSystem">
        <el-select v-model="eventDetail.eventSystem" placeholder="用户体系">
          <el-option
              v-for="item in eventSystemList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指定角色" v-if="eventDetail.eventSystem=='distributor'">
        <el-radio-group v-model="eventDetail.selectedUsers" @change="changeUser">
          <div>
            <el-radio label="all">所有人</el-radio>
          </div>
          <div style="display:flex; align-items:center;">
            <el-radio label="customize">指定用户</el-radio>
            <el-link type="primary" v-if="eventDetail.selectedUsers !='all' && !unEdit " @click="chooseUsers">选择指定人
            </el-link>
          </div>
        </el-radio-group>
      </el-form-item>
      <div style="width: 500px;margin-bottom: 20px;" v-if="eventDetail.eventSystem=='distributor'">
        <el-table :data="eventDetail.eventDistributorList">
          <el-table-column label="序号" width="100">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="指定人" prop="userName" width="300">
            <template slot-scope="scope">
              {{ decodeURI(scope.row.userName) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: space-between;" class="edit-icon" v-if="!unEdit">
                <i @click="delUser(scope.$index)" style="cursor: pointer;" class="el-icon-circle-close"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px;align-items: center;" v-if="unEdit">
        <span>活动商品</span>
        <el-link type="primary" style="margin-left: 20px;" v-if="!unEdit" @click="chooseGoods">选中商品</el-link>
      </div>
      <div style="width: 95%;margin-top: 20px;margin-bottom: 20px;" v-if="unEdit">
        <el-table :data="eventDetail.eventGoodsList" height="500" border>
          <el-table-column label="商品信息" min-width="200" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div style="display: flex;">
                <img width="50px" height="50px" :src="scope.row.pictureUrl">
                <span style="margin-left: 10px;">{{ scope.row.cnName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="原价" prop="initPrice" width="100"></el-table-column>
          <el-table-column label="联盟业绩" width="200">
            <template slot-scope="scope">
              <div style="display: flex;">
                <span style="white-space: nowrap;">比例：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].achievementPer)"
                          v-model="eventDetail.eventGoodsList[scope.$index].achievementPer"></el-input>
              </div>
              <div style="display: flex;">
                <span>值：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].achievement)"
                          v-model="eventDetail.eventGoodsList[scope.$index].achievement"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="伙伴服务费比例" width="200">
            <template slot-scope="scope">
              <div style="display: flex;">
                <span style="white-space: nowrap;">比例：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].egalitarianPer)"
                          v-model="eventDetail.eventGoodsList[scope.$index].egalitarianPer"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="普通会员" width="200">
            <template slot-scope="scope">
              <div style="display: flex;">
                <span style="white-space: nowrap;">比例：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l0CostPricePer)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l0CostPricePer"></el-input>
              </div>
              <div style="display: flex;">
                <span>值：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l0CostPrice)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l0CostPrice"></el-input>
              </div>
              <div>
                <el-switch v-model="eventDetail.eventGoodsList[scope.$index].l0Visible" active-color="#13ce66"
                           inactive-color="#ff4949">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="普标会员" width="200">
            <template slot-scope="scope">
              <div style="display: flex;">
                <span style="white-space: nowrap;">比例：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l1CostPricePer)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l1CostPricePer"></el-input>
              </div>
              <div style="display: flex;">
                <span>值：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l1CostPrice)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l1CostPrice"></el-input>
              </div>
              <div>
                <el-switch v-model="eventDetail.eventGoodsList[scope.$index].l1Visible" active-color="#13ce66"
                           inactive-color="#ff4949">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="银星会员" width="200">
            <template slot-scope="scope">
              <div style="display: flex;">
                <span style="white-space: nowrap;">比例：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l2CostPricePer)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l2CostPricePer"></el-input>
              </div>
              <div style="display: flex;">
                <span>值：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l2CostPrice)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l2CostPrice"></el-input>
              </div>
              <div>
                <el-switch v-model="eventDetail.eventGoodsList[scope.$index].l2Visible" active-color="#13ce66"
                           inactive-color="#ff4949">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="金星会员" width="200">
            <template slot-scope="scope">
              <div style="display: flex;">
                <span style="white-space: nowrap;">比例：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l3CostPricePer)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l3CostPricePer"></el-input>
              </div>
              <div style="display: flex;">
                <span>值：</span>
                <el-input maxlength="8"
                          @keyup.native="keyupEvent($event,eventDetail.eventGoodsList[scope.$index].l3CostPrice)"
                          v-model="eventDetail.eventGoodsList[scope.$index].l3CostPrice"></el-input>
              </div>
              <div>
                <el-switch v-model="eventDetail.eventGoodsList[scope.$index].l3Visible" active-color="#13ce66"
                           inactive-color="#ff4949">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <i class="el-icon-circle-close" v-if="!unEdit" style="font-size: 25px; cursor: pointer;"
                 @click="delChooseGoods(scope.$index)"></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
      <div style="text-align: right;">
        <el-button type="primary" size="medium" @click="commitEdit">确 定</el-button>
      </div>
    </el-form>
    <el-dialog title="选择金星会员" :visible.sync="userDialogVisible" width="40%">
      <div>
        <el-checkbox-group v-model="checkUserList">
          <!--<div v-for="(item,i) in users" :key="item.userId"> ly-eidt 去掉i会报错 说i没有用到-->
          <div v-for="item in users" :key="item.userId">
            <el-checkbox :label="item">{{ item.fullName }}</el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="userDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitChooseUsers">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="goodsDialogVisible" width="80%">
      <div>
                <span class="search-wrapper" style="display: flex;">
                    <el-input size="mini" v-model="searchInfo.cnName" placeholder="请输入商品名称"></el-input>
                    <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
                </span>
        <ws-page-table :data="tableData" ref="pageTable" :pageable="true" :pageInfo="pageInfo" selection="multi"
                       @pageChange="loadTableData" :showIndex="false">
          <el-table-column prop="goodsName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="goodsCode" label="商品编号" :show-overflow-tooltip="true"></el-table-column>
          <!--
          <el-table-column prop="storeName" label="库品名称" :show-overflow-tooltip="true"></el-table-column>
          -->

          <!--          <el-table-column prop="goodsCategory" label="商品类别"></el-table-column>-->
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <div class="table-img">
                <img :src="scope.row.goodsCover" alt/>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="effectiveStr" label="商品状态"></el-table-column>
          <el-table-column prop="price" label="商品原价（元）" min-width="100"></el-table-column>
        </ws-page-table>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="goodsDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitChooseGoods">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import draggableUpload from "COMPS/base/draggableUpload";
import PimGoodsService from "API/pim/PimGoodsService";
import eventBus from '../../../js/eventBus.js'
import {
  queryEventDetail,
  editEventDetail
} from '@/api/pim/PimPriceEvent.js'
import BimMemberService from "API/bim/BimMemberService"

export default {
  components: {
    draggableUpload
  },
  data() {
    return {
      id: "",
      eventDetail: {
        eventGoodsList: []
      },
      eventSystemList: [
        {
          value: "distributor",
          label: "会员体系"
        },
        {
          value: "agent",
          label: "经销商体系"
        }
      ],
      users: [],
      userDialogVisible: false,
      checkUserList: [],
      goodsDialogVisible: false,
      rules: {
        eventName: [{
          required: true,
          message: '请输入活动名称editPriceEvent',
          trigger: 'blur'
        },],
        eventSystem: [{
          required: true,
          message: '请选择用户体系',
          trigger: 'blur'
        },],
      },
      unEdit: false,
      eventPageInfo: {
        pageSize: 10,
        pageNo: 1
      },
      total: 0
    };
  },
  methods: {
    async getEventDetail() {
      let params = {
        ...this.eventPageInfo,
        eventId: this.id
      }
      this.eventDetail = await queryEventDetail(params)
      this.eventDetail.eventGoodsList = this.eventDetail.pageInfo.list
      this.total = this.eventDetail.pageInfo.total
      this.eventDetail.eventGoodsList.map(item => {
        if (item.l0Visible == 1) {
          item.l0Visible = true
        } else {
          item.l0Visible = false
        }
        if (item.l1Visible == 1) {
          item.l1Visible = true
        } else {
          item.l1Visible = false
        }
        if (item.l2Visible == 1) {
          item.l2Visible = true
        } else {
          item.l2Visible = false
        }
        if (item.l3Visible == 1) {
          item.l3Visible = true
        } else {
          item.l3Visible = false
        }
        return item
      })
    },
    getUser() {
      BimMemberService.queryMemberList().then(data => {
        this.users = data
      });
    },
    chooseUsers() {
      this.userDialogVisible = true
    },
    chooseGoods() {
      this.goodsDialogVisible = true
    },
    loadTableData() {

    },
    translateTotalState(e) {
      //let minTimestamp = new Date('1971-01-01 00:00:00').getTime()
      //let maxTimestamp = new Date('2038-01-01 00:00:00').getTime()
      let nowTimestamp = new Date().getTime()
      if (e.effectiveTime > nowTimestamp || e.failureTime < nowTimestamp) {
        return '下架';
      }
      if (e.goodsState == 'normal') {
        return '正常';
      } else if (e.goodsState == 'sell_out') {
        return '售罄';
      }
    },
    async commitEdit() {
      if (this.eventDetail.eventGoodsList && this.eventDetail.eventGoodsList.length > 0) {
        this.eventDetail.eventGoodsList.map(item => {
          if (item.l0Visible) {
            item.l0Visible = 1
          } else {
            item.l0Visible = 0
          }
          if (item.l1Visible) {
            item.l1Visible = 1
          } else {
            item.l1Visible = 0
          }
          if (item.l2Visible) {
            item.l2Visible = 1
          } else {
            item.l2Visible = 0
          }
          if (item.l3Visible) {
            item.l3Visible = 1
          } else {
            item.l3Visible = 0
          }
          return item
        })
      }
      await editEventDetail(this.eventDetail)
      this.checkFromProductEditorAskBack();
      eventBus.$emit('refresh')
      this.$router.push({
        path: 'priceEvent'
      })
    },
    commitChooseGoods() {
      let list = this.$refs.pageTable.getCheckedData()
      let addItems = list.map(item => {
        // this.pimActivityGoodsRelaDispNo += 1
        return {
          goodsId: item.goodsId,
          // dispNo: this.pimActivityGoodsRelaDispNo,
          cnName: item.goodsName,
          pictureUrl: item.goodsCover,
          initPrice: item.price
        }
      })
      this.eventDetail.eventGoodsList = this.eventDetail.eventGoodsList.concat(addItems)
      let res = []
      this.eventDetail.eventGoodsList.forEach(item => {
        let isExist = false
        res.forEach(item2 => {
          if (item.goodsId == item2.goodsId) {
            isExist = true
          }
        })
        if (!isExist) {
          res.push(item)
        }
      })
      this.eventDetail.eventGoodsList = res
      this.goodsDialogVisible = false
    },
    commitChooseUsers() {
      let list = this.checkUserList.map(item => {
        return {
          distributorId: item.userId,
          userName: item.fullName
        }
      })
      this.eventDetail.eventDistributorList = this.eventDetail.eventDistributorList.concat(list)
      this.userDialogVisible = false
      this.checkUserList = []
    },
    delUser(i) {
      this.eventDetail.eventDistributorList.splice(i, 1)
    },
    delChooseGoods(i) {
      this.eventDetail.eventGoodsList.splice(i, 1)
    },
    changeUser() {
      if (this.eventDetail.selectedUsers == 'all') {
        this.$set(this.eventDetail, 'eventDistributorList', [{
          distributorId: 'all',
          userName: '所有人'
        }])
      } else {
        this.eventDetail.eventDistributorList = []
      }
    },
    keyupEvent(e, input) {
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
          /^\d*\.\d*\./g,
          e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      this.input = e.target.value;
    },
  },
  mixins: [pageTableMixin],
  mounted() {
    this.id = this.$route.query.id
    if (this.id) {
      this.getEventDetail()
    }
    this.unEdit = this.$route.query.unEdit
    this.getUser()
  }
};
</script>

<style></style>
