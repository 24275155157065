<template>
  <div>
    <steps :active="2" :steps="steps"></steps>
    <el-form label-width="110px">
      <div class="box-shadow-unify setting-item">
        <div class="setting-subtitle">招商设置</div>
        <div class="setting-content">
          <el-form-item label="招商商品：" required>
            <el-checkbox v-model="settingForm.isInvestment">设置为招商商品</el-checkbox>
          </el-form-item>
        </div>
      </div>

      <div class="box-shadow-unify setting-item" v-if="!settingForm.isInvestment">
        <div class="setting-subtitle">有效时间</div>
        <div class="setting-content">
          <el-form-item label="有效时间：" required>
            <el-date-picker
              v-model="effectiveTime"
              @change="onChangeDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
        </div>
      </div>

      <div class="box-shadow-unify setting-item">
        <div class="setting-subtitle">价格设置</div>
        <div class="setting-content setting-table">
          <el-table :data="settingForm.members">
            <el-table-column prop="name" label="会员"></el-table-column>
            <el-table-column label="拿货价">
              <template slot-scope="scope">
                <el-input
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  style="width: 80px;"
                  v-model="scope.row.takeDeliveryPrice"
                ></el-input>&nbsp;%
              </template>
            </el-table-column>
            <el-table-column label="金额（元）">
              <template slot-scope="scope">
                <el-input
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  style="width: 145px;"
                  v-model="scope.row.originalPrice"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="是否可见">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.isVisible"></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="box-shadow-unify setting-item" v-if="!settingForm.isInvestment">
        <div class="setting-subtitle">积分设置</div>
        <div class="setting-content">
          <el-form-item label="积分：" required>
            <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="settingForm.integral"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="box-shadow-unify setting-item" v-if="!settingForm.isInvestment">
        <div class="setting-subtitle">联盟业绩核算</div>
        <div class="setting-content">
          <el-form-item label="比例：" required>
            <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="settingForm.proportion"></el-input>
          </el-form-item>
          <el-form-item label="实际金额：" required>
            <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="settingForm.amount">
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
        </div>
      </div>

      <div class="box-shadow-unify setting-item" v-if="settingForm.isInvestment">
        <div class="setting-subtitle">等级关联</div>
        <div class="setting-content">
          <el-form-item label="等级：" required>
            <el-select v-model="settingForm.associationLevel" placeholder="关联等级">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="step-btn">
      <el-button @click="prevStep">上一步</el-button>
      <el-button type="primary" @click="complete">完成</el-button>
    </div>
  </div>
</template>

<script>
import steps from "COMPS/widgets/steps";
import Enum from "ASSETS/js/enum";
import tools from "COMJS/tools";

export default {
  data() {
    return {
      steps: [
        {
          name: "关联商品"
        },
        {
          name: "基本信息"
        },
        {
          name: "设置"
        }
      ],
      effectiveTime: [],
      // 等级关联
      options: [
        {
          label: Enum.member["CLASSD"],
          value: Enum.member["CLASSD"]
        },
        {
          label: Enum.member["CLASSC"],
          value: Enum.member["CLASSC"]
        },
        {
          label: Enum.member["CLASSB"],
          value: Enum.member["CLASSB"]
        },
        {
          label: Enum.member["CLASSA"],
          value: Enum.member["CLASSA"]
        }
      ],
      baseMembers: [
        {
          name: Enum.member["CLASSA"],
          takeDeliveryPrice: "60",
          originalPrice: "",
          isVisible: true
        },
        {
          name: Enum.member["CLASSB"],
          takeDeliveryPrice: "70",
          originalPrice: "",
          isVisible: true
        },
        {
          name: Enum.member["CLASSC"],
          takeDeliveryPrice: "80",
          originalPrice: "",
          isVisible: false
        }
      ],
      nomarlMember: [
        {
          name: Enum.member["CLASSD"],
          takeDeliveryPrice: "100",
          originalPrice: "",
          isVisible: false
        }
      ],
      settingForm: {
        isInvestment: false, // 是否是招商商品
        startTime: "", // 有效开始时间
        endTime: "", // 有效结束时间
        members: [], // 会员价格设置
        associationLevel: "", // 关联等级
        integral: "", // 积分设置
        proportion: "", // 联盟业绩比例
        amount: "" // 联盟业绩实际金额
      }
    };
  },
  components: { steps },
  watch: {
    "form.isInvestment": function(val) {
      if (val) {
        this.form.members = this.baseMembers.concat(this.nomarlMember);
      } else {
        this.form.members = this.baseMembers;
      }
    }
  },
  mounted() {
    this.settingForm.members = this.baseMembers;
    if (JSON.stringify(this.$store.state.virtualGoods.setting) != "{}") {
      this.settingForm = this.$store.state.virtualGoods.setting;
      this.$set(this.effectiveTime, 0, new Date(this.settingForm.startTime));
      this.$set(this.effectiveTime, 1, new Date(this.settingForm.endTime));
    }
  },
  methods: {
    onChangeDate(date) {
      this.settingForm.startTime = tools.formatDate(
        new Date(date[0]),
        "yyyy-MM-dd hh:mm:ss"
      );
      this.settingForm.endTime = tools.formatDate(
        new Date(date[1]),
        "yyyy-MM-dd hh:mm:ss"
      );
    },
    prevStep() {
      this.$store.commit("saveVirtualGoods_setting", this.settingForm);
      this.$router.push("/dim/fillInVirtualGoodsInfo");
    },
    complete(info) {
      let settingInfo = this.settingForm;
      if (settingInfo.isInvestment) {
        if (!settingInfo.associationLevel) {
          return this.$message({
            message: "请选择关联等级",
            type: "warning"
          });
        }
      } else {
        if (!settingInfo.startTime || !settingInfo.endTime) {
          return this.$message({
            message: "请填写有效时间",
            type: "warning"
          });
        }
        if (!settingInfo.integral) {
          return this.$message({
            message: "请填写积分设置",
            type: "warning"
          });
        }
        if (!settingInfo.proportion || !settingInfo.amount) {
          return this.$message({
            message: "请填写联盟业绩核算",
            type: "warning"
          });
        }
      }
      if (
        settingInfo.members.filter(
          e => !e.takeDeliveryPrice || !e.originalPrice
        ).length
      ) {
        return this.$message({
          message: "请填写完整会员价格设置",
          type: "warning"
        });
      }
      this.$store.commit("CLEAR_VIRTUALGOODS");
      this.$router.push("/dim/goodsList");
    }
  }
};
</script>

<style lang="scss" scoped>
.step-btn {
  margin-top: 40px;
  text-align: center;
  .el-button {
    width: 110px;
  }
}
.setting-item {
  margin-bottom: 40px;
  .setting-subtitle {
    padding: 0 20px;
    line-height: 60px;
    font-size: 18px;
    font-weight: bold;
    background-color: #f6f9fc;
  }
  .setting-content {
    padding-top: 20px;
    padding-bottom: 1px;
    &.setting-table {
      padding: 0;
      border-top: 1px solid #999;
    }
  }
}
/deep/ .el-input-group__prepend {
  padding: 0 10px;
}
</style>

