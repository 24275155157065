<template>
  <div>
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="showAdd">新增</el-button>
        <el-button size="mini" type="primary" @click="batchParamDelete">删除</el-button>
        <span class="search-wrapper">
          <el-input size="mini" v-model="searchInfo.cnName" placeholder="请输入参数名称"></el-input>
          <el-button class="search-btn" size="mini" type="primary" @click="searchData">搜索</el-button>
        </span>
      </div>
    </div>
    <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="true"
    >
      <el-table-column fixed type="selection" width="50"></el-table-column>
      <el-table-column prop="cnName" label="参数名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="paramKey" label="参数键" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="defaultValue" label="参数值" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="icon-operation">
            <a href="javascript: void(0);" title="查看" @click="showDetail(scope.row.paramId)">
              <i class="CCSFont icon-table_2"></i>
            </a>
            <a href="javascript: void(0);" title="编辑" @click="showEditParam(scope.row.paramId)">
              <i class="CCSFont icon-table_1"></i>
            </a>
            <!--<a href="javascript: void(0);" title="删除">-->
            <!--<i class="el-icon-delete-solid" @click="deleteBrand(scope.row)"></i>-->
            <!--</a>-->
          </div>
        </template>
      </el-table-column>
    </ws-page-table>
    <param-add-popup ref="paramAddPopup" @updateTable="loadTableData" :paramId="currentId"></param-add-popup>
    <param-detail-popup ref="paramDetailPopup" :paramId="currentId"></param-detail-popup>
  </div>
</template>

<script>
import paramAddPopup from "./paramAddPopup.vue";
import BimParamService from "API/bim/BimParamService";
import pageTableMixin from "@/mixin/pageTableMixin";
import paramDetailPopup from "./paramDetailPopup.vue";

export default {
    name: "paramList",
    data() {
        return {
            searchInfo: {
                cnName: ""
            },
            currentId: ""
        };
    },
    mixins: [pageTableMixin],
    components: {
        paramAddPopup,
        paramDetailPopup
    },
    mounted() {
    },
    methods: {
        loadTableData() {
            this.$refs.pageTable.tableLoading = true;
            BimParamService.queryParamPages(this.pageInfo, this.searchConfig).then(
                data => {
                    this.querySuccess(data);
                }
            );
        },
        showAdd() {
            this.currentId = "";
            this.$refs.paramAddPopup.dialogFormVisible = true;
        },
        showDetail(id) {
            this.currentId = id;
            this.$refs.paramDetailPopup.dialogVisible = true;
        },
        showEditParam(id) {
            this.currentId = id;
            this.$refs.paramAddPopup.dialogFormVisible = true;
        },
        batchParamDelete() {
            let that = this;
            let rows = this.$refs.pageTable.getCheckedData();
            if (rows.length < 1) {
                that.$alert('请选择记录！', '系统提示');
                return;
            }
            that.$confirm('即将执行删除操作，是否确定执行?', '系统提示', {type: 'warning'}).then(() =>
                that.$refs.pageTable.tableLoading = true
            ).then(() => {
                return BimParamService.deleteBimParams(rows)
            }).then(response => {
                that.loadTableData();
                that.$refs.pageTable.tableLoading = false;
            }).catch(error => {
                console.log(error);
                that.$refs.pageTable.tableLoading = false;
            })
        }
    },
    computed: {}
};
</script>
