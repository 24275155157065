<template>
  <div class="whm">
    <div class="title">商品名称：{{ goodsData.cnName }}</div>
    <div class="content">
      <div class="information-title">
        <span style="color:red;margin-right:5px">*</span>
        库品信息
        <!--
        <span v-if="!toView"
              @click="addWhm"
              class="AddItem">新增</span>
              -->
        （不关联库品，即表示不管理库存）
      </div>
      <div class="label">
        <ws-page-table :data="tableData" ref="pageTable" style="width:100%" :pageInfo="pageInfo"
                       @pageChange="loadTableData">
          <el-table-column label="库品名称" prop="skuName" width='250'>
            <template slot-scope="scope">
              <el-input placeholder="请选择库品" v-model="scope.row.skuName" style="width:80%" :disabled='toView'></el-input>
            </template>
          </el-table-column>
          <el-table-column label="库品数量" prop="skuNum" width='450'>
            <template slot="header">
              <div>库品数量<span style="font-size:10px;font-weight: 500; width:380px">(每售出一个商品，该商品对应库品数量被锁定。）</span></div>
            </template>
            <template slot-scope="scope">
              <el-input placeholder="输入数量" v-model="scope.row.skuNum" style="width:40%" :disabled='toView'></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div @click="delRelateArr(scope.$index)"
                   style="color:rgba(24, 144, 255, 1);font-size:14px;cursor: pointer;">删除
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
      <div class="label">
        <el-button type="primary" @click="showDialog" :disabled='toView'>添加库品</el-button>
      </div>
      <div class="sumbit">
        <el-button @click="back">取消</el-button>
        <el-button type="primary" @click="save" :disabled='toView'>保存</el-button>
        <el-button type="primary" @click="save('next')" :disabled='toView'>下一步并保存</el-button>
      </div>
    </div>
    <whmPop :dialogVisible='dialogVisible' @pushRelate='pushRelate' @cancel='cancel'></whmPop>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import relateQuery from "API/whm/relateQuery";
import whmPop from "./whmPop";

export default {
  props: {
    goodsData: Object,
    toView: Boolean,
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      submitRelate: {
        goodsId: "",
        goodsName: "",
        goodsCode: "",
        skuNum: "",
      },
    };
  },
  components: {
    whmPop,
  },
  mounted() {
    //this.monitoring(); // 注册监听事件
  },
  watch: {
    goodsData(newValue) {
      //console.log(newValue);
      this.goodsData = newValue;
      this.submitRelate.goodsId = newValue.goodsId;
      this.submitRelate.goodsCode = newValue.goodsCode;
      this.submitRelate.goodsName = newValue.cnName;
      this.getGoodsSkuData(newValue.goodsId);
    },
  },
  mixins: [pageTableMixin],
  methods: {
    loadTableData() {
    },
    getGoodsSkuData(goodsId) {
      relateQuery.queryProductRelateDetail(goodsId).then((data) => {
        if (data && data.skus) {
          this.tableData = data.skus.map((e) => {
            return {
              skuId: e.skuId,
              skuName: e.skuName,
              // skuCode: e.skuCode,
              skuNum: e.num,
            };
          });
        }
        this.oldtableData = JSON.parse(JSON.stringify(this.tableData));
      });
    },
    checkDataChange() {
      if (
          JSON.stringify(this.oldtableData) != JSON.stringify(this.tableData)
      ) {
        return true;
      }
      return false;
    },
    saveDataChange() {
      let state = this.save();
      return state
    },

    showDialog() {
      this.dialogVisible = true;
    },
    addWhm() {
      this.$router.push({
        path: "/whm/whmEdit",
      });
      this.$store.commit("setProductStepsData_Back", true);
    },
    back() {
      this.$confirm(`是否取消？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.go(-1);
      });
    },
    cancel() {
      this.dialogVisible = false;
    },
    pushRelate(Skulist) {
      if (
          this.tableData.findIndex(
              (target) => target.skuId == Skulist[0].id
          ) == -1
      ) {
        let params = {
          skuId: Skulist[0].id,
          skuName: Skulist[0].skuName,
          skuCode: Skulist[0].skuCode,
          skuNum: "",
        };
        this.tableData.push(params);
      }
    },
    spliceRelate() {
      let tmp = "";
      for (let i = 0; i < this.tableData.length; i++) {
        tmp = this.tableData[i].skuId + "*" + this.tableData[i].skuNum;
        if (i === 0) {
          this.submitRelate.skuNum = tmp;
        } else {
          this.submitRelate.skuNum =
              this.submitRelate.skuNum + "+" + tmp;
        }
        tmp = "";
      }
    },
    save(next) {
      let tableData = this.tableData;
      for (let i in tableData) {
        if (!tableData[i].skuNum) {
          this.$message({
            message: "库品数量未填写完整",
            type: "warning",
          });
          return 0;
        }
      }
      this.spliceRelate();
      //console.log(this.submitRelate);
      relateQuery
          .saveProductRelateDetail(this.submitRelate)
          .then((res) => {
            this.oldtableData = JSON.parse(
                JSON.stringify(this.tableData)
            );
            this.$message({
              type: "success",
              message: "保存成功",
            });
            if (next == "next") {
              this.$emit("swiStep", 5);
            }
          });
      return 1;
    },
    delRelateArr(index) {
      this.$confirm(`是否删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.tableData.splice(index, 1);
      });
    },
  },
};
</script>
<style scoped>
@import url("./malllCss.css");

.content {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}
</style>