<template>
  <el-dialog :title="activeName==='4' ? '发票撤销': '发票处理'" :visible.sync="dialogVisible" width="500px">
    <div>
      <span style="color:#cccccc">Tips: 单击蓝色文本即可复制</span>
    </div>
    <table class="pure-table pure-table-bordered">
      <tbody>
      <tr>
        <td>发票类型</td>
        <!--td>{{ info.invoiceType == 'company_sp' ? '企业专票' : info.invoiceType == 'company' ? '企业普票' : '个人' }}</td-->
        <td>{{ info.invoiceType }}</td>
      </tr>
      <tr class="dutySign">
        <td>发票金额</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.invoiceAmount)">{{ info.invoiceAmount }}</a>
        </td>
      </tr>
      <tr class="dutySign">
        <td>抬头名称</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.name)">{{ info.name }}</a></td>
      </tr>
      <tr class="idCard" v-show="!allShow">
        <td>
          {{ info.cardType == 'idCard' ? '身份证' : info.cardType == 'twCard' ? '台胞证' : info.cardType == 'passport' ? '护照' : '' }}
        </td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.idCard)">{{ info.idCard }}</a></td>
      </tr>

      <tr class="dutySign" v-show="allShow">
        <td>增值税号</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.dutySign)">{{ info.dutySign }}</a></td>
      </tr>
      <tr class="depositBank" v-show="allShow">
        <td>开户银行</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.depositBank)">{{ info.depositBank }}</a></td>
      </tr>
      <tr class="bankAccount" v-show="allShow">
        <td>银行账户</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.bankAccount)">{{ info.bankAccount }}</a></td>
      </tr>
      <tr class="companyAddr" v-show="allShow">
        <td>公司地址</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.companyAddr)">{{ info.companyAddr }}</a></td>
      </tr>
      <tr class="phoneNum">
        <td>联系电话</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.phoneNum)">{{ info.phoneNum }}</a></td>
      </tr>
      <tr class="companyAddr">
        <td>联系邮箱</td>
        <td><a href="javascript: void(0);" title="复制" @click="copy(info.email)">{{ info.email }}</a></td>
      </tr>
      </tbody>
    </table>
    <div slot="footer" class="dialog-footer">
      <el-button type="danger" @click="reject()" v-if="activeName=='1' || activeName=='4'">驳回重开</el-button>
      <el-button type="primary" @click="confirm()" v-if="activeName=='1'">确认处理</el-button>
    </div>
  </el-dialog>
</template>

<script>

import SomInvoiceService from "API/som/SomInvoiceService";
import tools from "COMJS/tools";

export default {
  props: {
    info: {},
    activeName: String
  },
  watch: {
    dialogVisible() {
      //判断发票类型
      if (this.info.invoiceType == '企业专票'
          || this.info.invoiceType == '企业普票') {
        this.allShow = true;
      } else {
        this.allShow = false;
      }
      //this.info.invoiceAmount=this.info.invoiceAmount.toFixed(2);
    }
  },
  data() {
    return {
      allShow: false,
      dialogVisible: false,
    };

  },
  methods: {
    copy(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove()
    },
    reject(){
      let invoice = {
        invoiceId: this.info.invoiceId,
        handleState: ""
      }
      this.$confirm('请确认是否驳回当前发票', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        SomInvoiceService.cancelHandle(invoice).then(data => {
          this.$emit("changeTab", this.activeName)
        });
        this.dialogVisible = false;
        this.loading = false;
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => { });
    },
    confirm() {
      let invoice = {
        invoiceId: this.info.invoiceId,
        handleState: ""
      }
      if (this.activeName === '4') {
        var tip = '请确认是否撤销当前发票的开票状态?';
        invoice.handleState = "1";
      } else {
        var tip = '请确认发票是否处理完毕?';
        invoice.handleState = "0";
      }
      this.$confirm(tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        SomInvoiceService.confirmHandle(invoice).then(data => {
          //console.log(this.activeName)
          this.$emit("changeTab", this.activeName)
        });
        this.dialogVisible = false;
        this.loading = false;
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '操作已取消!',

        });
      });
    }

  }

}
</script>

<style scoped lang="scss">
.detail-form {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  .form-item {
    display: flex;
    font-size: 14px;
    margin-bottom: 18px;

    .form-label {
      display: inline-block;
      width: 100px;
      text-align: right;
    }

    .form-value {
      flex: 1;
      color: #666;
    }
  }

  .image-item {
    margin-top: 30px;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

.box-title {
  background-color: #f6f9fc;
}

label {
  display: inline-block;
  width: 100px;

  text-align: right;
}

.el-form /deep/ .el-form-item__label {
  font-size: 16px;
}

.el-form /deep/ .el-form-item__content {
  font-size: 16px;
}

.span {
  color: #1B73E8;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

tr > td:nth-child(1) {
  width: 150px;
  height: 25px;
}

tr > td:nth-child(2) {

  width: 300px;
}

a {
  text-decoration: underline;
  color: #015BF1;
}

td, th {
  padding: 0;
}

tr td {
  text-align: center;

}

.pure-table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #d4dedd;
  margin-top: 15px;
}

.pure-table caption {
  color: #000;
  font: italic 85%/1 arial, sans-serif;
  padding: 1em 0;
  text-align: center;
}

.pure-table td, .pure-table th {
  border-left: 1px solid #d4dedd;
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: .5em 1em;
}

.pure-table thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}

.pure-table td {
  background-color: transparent;
}

.pure-table-bordered td {
  border-bottom: 1px solid #d4dedd;
}

.pure-table-bordered tbody > tr:last-child > td {
  border-bottom-width: 0;
}

</style>