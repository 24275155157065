import axiosHttp from "../axiosHttp"

export default {
    queryPages(pageInfo, searchConfig) {
        let url = "/merchandiseTag/queryPages?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url);
    },
    saveOrModifyTag(tag) {
        let url = "/merchandiseTag/saveOrModifyTag"
        return axiosHttp.POST(url, tag, false);
    },
    removeTag(id) {
        let url = "/merchandiseTag/removeTag"
        let param = {"tagId": id}
        return axiosHttp.POST(url, param, false);
    }
}