import axiosHttp from "../axiosHttp";

export default {
    queryPage(pageInfo,searchConfig){ //入库列表
        let url = "/whmInventoryWarranty/queryPage?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url)
    },
    save(param){
        let url = "/whmInventoryWarranty/save"
        return axiosHttp.POST(url,param)
    },
    delById(inventoryWarrantyId){
        let url = `/whmInventoryWarranty/delById/${inventoryWarrantyId}`
        return axiosHttp.GET(url)
    }
}