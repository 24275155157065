<template>
  <div>
    <div>
      <div style="display: flex;">
        <el-form :inline="true">
          <el-form-item label="机构:">
            <el-select v-model="searchInfo.groupId" placeholder="请选择" clearable>
              <el-option
                  v-for="item in groupList"
                  :key="item.groupId"
                  :label="item.groupName"
                  :value="item.groupId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="平台:">
            <el-select v-model="searchInfo.livePlatformCode" placeholder="请选择" clearable>
              <el-option
                  v-for="item in livePlatformList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主播:">
            <el-input v-model="searchInfo.anchorNameLike"></el-input>
          </el-form-item>
          <el-form-item label="主播ID:">
            <el-input v-model="searchInfo.anchorPlatformId"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loadTableData">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-tabs v-model="tabsActiveName" type="card" @tab-click="changeTable">
          <el-tab-pane label="对接中" name="touch"></el-tab-pane>
          <el-tab-pane label="合作中" name="coop"></el-tab-pane>
          <el-tab-pane label="已放弃" name="abandon"></el-tab-pane>
        </el-tabs>
        <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="false"
            :isShadow="false"
            :selection="null"
        >
          <el-table-column prop="groupName" label="机构名称" minWidth="140"></el-table-column>
          <el-table-column prop="livePlatformName" label="直播平台" minWidth="140"></el-table-column>
          <el-table-column prop="anchorName" label="主播名称" minWidth="140"></el-table-column>
          <el-table-column prop="anchorPlatformId" label="主播ID" minWidth="140"></el-table-column>
          <el-table-column prop="sampleInfo" label="样品信息" v-if="tabsActiveName=='touch'"
                           minWidth="140">
            <template slot-scope="scope">
              <p :style="{'color': scope.row.sampleInfo == '待审核'?'red' :''}">
                {{ scope.row.sampleInfo }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="lastLiveDate" label="最近上播日期" v-if="tabsActiveName=='coop'"
                           :formatter="dateFormatter4Table"
                           minWidth="140"></el-table-column>
          <el-table-column prop="abandonTime" label="放弃时间" v-if="tabsActiveName=='abandon'"
                           :formatter="datetimeFormatter4Table"
                           minWidth="140"></el-table-column>
          <el-table-column label="操作" width="240">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="编辑"
                   v-if="scope.row.anchorState=='touch' || scope.row.anchorState=='coop'"
                   @click="editDialogShow(scope.row)">
                  <i class="el-icon-edit-outline">编辑</i>
                </a>
                <a href="javascript: void(0);" title="上播"
                   v-if="scope.row.anchorState=='coop'"
                   @click="liveDialogShow(scope.row)">
                  <i class="el-icon-video-camera">上播</i>
                </a>
                <a href="javascript: void(0);" title="样品"
                   v-if="scope.row.anchorState=='touch' || scope.row.anchorState=='coop'"
                   @click="sampleDialogShow(scope.row)">
                  <i class="el-icon-present">样品</i>
                </a>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>

    <el-dialog title="产品上播" :visible.sync="liveDialogVisible" width="860px">
      <el-table :data="liveRecordList">
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="上播时间" prop="liveTime" min-width="200">
          <template slot-scope="scope">
            <el-date-picker
                :disabled="scope.row.anchorLiveId!=null"
                v-model="scope.row.liveTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" min-width="200">
          <template slot-scope="scope">
            <el-input
                :disabled="scope.row.anchorLiveId!=null"
                type="textarea" :rows="2" placeholder="请输入内容"
                v-model="scope.row.remark">
            </el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="主播信息" :visible.sync="anchorDialogVisible" width="560px">
      <el-form label-width="80px">
        <el-form-item label="直播平台">
          <el-select v-model="anchorEditData.livePlatformCode" placeholder="请选择" disabled>
            <el-option
                v-for="item in livePlatformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主播名称">
          <el-input v-model="anchorEditData.anchorName" disabled></el-input>
          <!--p style="color: red;">* 主播在平台的昵称，非主播真实姓名</p-->
        </el-form-item>
        <el-form-item label="主播ID">
          <el-input v-model="anchorEditData.anchorPlatformId" disabled></el-input>
          <!--p style="color: red;">* 主播在平台的ID，如抖音平台的UID</p-->
        </el-form-item>
        <el-form-item label="主播截图">
          <!--:height="82"-->
          <draggable-upload
              :imgDel="true"
              :width="82"
              :limit="1"
              :fileSize="2048 * 1024"
              fileSizeText="2M"
              :uploadUrl="uploadUrl()"
              :requestHeaders="requestHeaders"
              v-model="screenshotArr"
          ></draggable-upload>
          <!--p style="color: red;">* 主播在平台的主页截图，截图需体现主播名称</p-->
        </el-form-item>
        <el-form-item label="状态" required>
          <el-select v-model="anchorEditData.anchorState" placeholder="请选择">
            <el-option
                v-for="item in anchorStateList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <p style="color: red;">* 确认合作后，由管理员变更状态为"合作中"，机构即可登记上播记录。</p>
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="anchorDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="样品记录" :visible.sync="sampleDialogVisible" width="860px">
      <div>
        <el-card class="box-card" v-for="(item,idx) in sampleRecordList" :key="idx">
          <div slot="header" class="clearfix">
            <span style="margin-right: 20px;">申请时间：{{ datetimeFormatter(item.createTime) }}</span>
            <span v-if="item.sampleAuditState=='0'" style="color: red;">样品单待审核</span>
            <span v-if="item.sampleAuditState=='1'" style="margin-right: 20px;">订单号：{{item.orderCode}}</span>
            <span v-if="item.sampleAuditState=='1'">快递信息：{{ item.expressInfo }}</span>
            <span v-if="item.sampleAuditState=='2'" style="color: red;">样品单审核不通过</span>
            <el-button
                @click="auditSample(idx,1)"
                v-if="item.sampleAuditState=='0'"
                style="float: right; padding: 3px 0" type="text">通过(自动生成订单)
            </el-button>
            <el-button
                @click="auditSample(idx,0)"
                v-if="item.sampleAuditState=='0'"
                style="float: right; padding: 3px 0;margin-right: 10px;color: #be0000;" type="text">驳回
            </el-button>
          </div>
          <div style="margin-bottom: 10px;">
            <span style="margin-right: 5px;">{{ item.recipientName }}</span>
            <span style="margin-right: 5px;">{{ item.recipientPhoneNum }}</span>
          </div>
          <div>
            <span style="margin-right: 5px;">{{ item.recipientProvince }}</span>
            <span style="margin-right: 5px;">{{ item.recipientCity }}</span>
            <span style="margin-right: 5px;">{{ item.recipientCounty }}</span>
            <span>{{ item.recipientAddressDetail }}</span>
          </div>
          <el-divider content-position="left">样品信息</el-divider>
          <div style="display: flex;margin-top: 20px;flex-wrap: wrap;">
            <div v-for="g in item.sampleGoodsList" style="display: flex;margin: 10px 10px 0 0;">
              <img class="goods_pic" :src="g.pictureUrl">
              <div style="display: flex;flex-direction:column;justify-content: start;">
                <p>{{ g.cnName }}</p>
                <p>{{ g.goodsCode }}</p>
                <div style="height: 100%;"></div>
                <p>x {{ g.num }}</p>
              </div>
            </div>
          </div>
          <el-divider content-position="left" v-if="item.remark">备注内容</el-divider>
          <span v-if="item.remark">{{ item.remark }}</span>
        </el-card>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import selectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";
import areaCascader from "COMPS/base/areaCascader.vue";
import userModule from "@/module/userModule";
import pageTableMixin from "@/mixin/pageTableMixin";
import lsAnchorService from "@/api/ls/LsAnchorService";
import draggableUpload from "COMPS/base/draggableUpload";
import IoProgressService from "API/IoProgressService";
import Objects from "COMJS/Objects";
import tools from "COMJS/tools";

export default {
  name: "LsAnchorList",
  mixins: [pageTableMixin],
  components: {
    draggableUpload,
    areaCascader,
    selectGoodsPopup
  },
  watch: {},
  data() {
    return {
      tabsActiveName: 'touch',
      groupList: [],
      livePlatformList: [{label: '抖音', value: 'DY'}, {label: '快手', value: 'KS'}, {label: '视频号', value: 'SPH'}],
      searchInfo: {},
      anchorStateList: [{label: '对接中', value: 'touch'}, {
        label: '合作中',
        value: 'coop'
      }/*, {label: '已放弃', value: 'abandon'}*/],
      //
      anchorDialogVisible: false,
      anchorEditData: {},
      screenshotArr: [],
      //
      liveDialogVisible: false,
      liveRecordList: [],
      //
      sampleDialogVisible: false,
      sampleRecordList: [],
      //
      editAnchorId: '',
      groupId: JSON.parse(userModule.PPUser).userId,
      requestHeaders: {
        Authorization: userModule.token,
      },
    }
  },
  mounted() {
    lsAnchorService.queryGroupList({}).then(res => {
      this.groupList = res;
    })
  },
  methods: {
    //
    editDialogShow(row) {
      if (row) {
        this.anchorEditData = Objects.deepCopy({}, row)
        this.screenshotArr = [{response: row.anchorPlatformScreenshot}];
        this.editAnchorId = row.anchorId;
      } else {
        this.anchorEditData = {}
        this.screenshotArr = []
        this.editAnchorId = ''
      }
      this.anchorDialogVisible = true;
    },
    editDialogConfirm() {
      if (Objects.isEmpty(this.anchorEditData.livePlatformCode)) {
        this.$message.error("请选择平台");
        return;
      }
      let arr = this.livePlatformList.filter(e => this.anchorEditData.livePlatformCode == e.value)
      if (arr && arr.length > 0) {
        this.anchorEditData.livePlatformName = arr[0].label
      }
      if (Objects.isEmpty(this.anchorEditData.anchorName)) {
        this.$message.error("请填写主播名称");
        return;
      }
      if (this.screenshotArr.length == 0) {
        this.$message.error("请上传截图");
        return;
      }
      this.anchorEditData.anchorPlatformScreenshot = this.screenshotArr[0].response
      this.anchorEditData.groupId = this.groupId
      lsAnchorService.saveLsAnchor(this.anchorEditData).then(() => {
        this.$message.success("保存成功");
        this.anchorDialogVisible = false;
        this.loadTableData();
      })
    },
    //
    liveDialogShow(row) {
      this.liveDialogVisible = true;
      this.editAnchorId = row.anchorId;
      this.loadLiveData();
    },
    loadLiveData() {
      lsAnchorService.queryLiveRecord(this.editAnchorId).then(res => {
        this.liveRecordList = res;
      })
    },
    //
    sampleDialogShow(row) {
      this.editAnchorId = row.anchorId;
      this.sampleDialogVisible = true;
      this.loadSampleData();
    },
    auditSample(idx, type) {
      let title = ''
      let content = ''
      if (type == 1) {
        //ok
        title = '通过'
        content = '通过样品申请，此操作将在系统内自动生成订单, 是否继续?'
      } else {
        title = '驳回'
        content = '驳回样品申请，是否继续?'
      }
      let item = this.sampleRecordList[idx];
      let param = {
        anchorSampleId: item.anchorSampleId,
        sampleAuditState: type == 1 ? '1' : '2'
      }
      let that = this
      this.$confirm(content, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let loading = that.$loading({
          lock: true,
          text: "提交中...",
        });
        lsAnchorService.auditAnchorSample(param).then(()=>{
          loading.close();
          that.loadSampleData();
        }).catch(()=>{
          loading.close();
        })

      })
    },
    loadSampleData() {
      let param = {
        anchorId: this.editAnchorId,
        flagWithEI: "1",
        flagWithG: "1"
      }
      this.sampleRecordList = []
      lsAnchorService.querySampleRecord(param).then(res => {
        this.sampleRecordList = res;
      })
    },
    //
    changeTable() {
      this.loadTableData();
    },
    loadTableData() {
      let param = {
        ...this.searchInfo,
        anchorState: this.tabsActiveName,
        pageNo:this.pageInfo.pageNo,
        pageSize:this.pageInfo.pageSize,
      }
      lsAnchorService.pageAnchorByParam(param).then(res => {
        res.list = res.list.map(e => {
          return {
            ...e,
            groupName:
                this.groupList.filter(a => a.groupId == e.groupId).length > 0 ?
                    this.groupList.filter(a => a.groupId == e.groupId)[0].groupName : ''
          };
        })
        this.querySuccess(res);
      })
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("anchor");
      return url;
    },
    datetimeFormatter(time) {
      //return tools.formatDate(new Date(time), "yyyy-MM-dd hh:mm:ss");
      return tools.formatDate(new Date(time), "yyyy-MM-dd");
    },
    datetimeFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd hh:mm:ss");
      }
      return v;
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  margin: 10px;
}

.goods_pic {
  width: 50px;
  /*border: 1px solid rgba(218, 218, 218, 1);*/
}

</style>