<template>
  <div>
    <!--div class="table-tips">
      <div style="margin-bottom: 10px;">
        <p style="font-weight: bold;">主播登记规则说明</p>
      </div>
      <div class="tips-content">
        <div style="padding: 10px;">
          <p style="color: #7ea92a;">对接中的主播</p>
          <p>1、最多{{ groupData.anchorTouchLimit || 0 }}个名额，可以申请发送样品</p>
          <p>2、确定合作后，可联系客服将主播移入合作中，释放名额</p>
          <p>3、如果事不可为，也可以选择放弃，释放名额</p>
        </div>
        <div style="padding: 10px;">
          <p style="color: #206bbc;">合作中的主播</p>
          <p>1、请及时为主播登记产品上播时间</p>
          <p>2、超过三个月未上播的主播，将开放对接</p>
        </div>
        <div style="padding: 10px;">
          <p style="color: #b70c39;">已放弃的主播</p>
          <p>1、最多{{ groupData.anchorAbandonLimit || 0 }}个名额</p>
        </div>
      </div>

    </div-->
    <div>
      <div style="margin-bottom: 20px;">
        <el-button
            type="primary"
            @click="editDialogShow(null)">
          主播建联 [{{ touchAnchorTotalCount }}/{{ groupData.anchorAbandonLimit }}]
        </el-button>
      </div>
      <div>
        <el-tabs v-model="tabsActiveName" type="card" @tab-click="changeTable">
          <el-tab-pane label="对接中" name="touch"></el-tab-pane>
          <el-tab-pane label="合作中" name="coop"></el-tab-pane>
          <el-tab-pane label="已放弃" name="abandon"></el-tab-pane>
        </el-tabs>
        <ws-page-table
            :data="tableData"
            ref="pageTable"
            :pageable="true"
            :pageInfo="pageInfo"
            @pageChange="loadTableData"
            :showIndex="false"
            :isShadow="false"
            :selection="null"
        >
          <el-table-column prop="livePlatformName" label="直播平台" minWidth="140"></el-table-column>
          <el-table-column prop="anchorName" label="主播名称" minWidth="140">
            <template slot-scope="scope">
              <p style="font-weight: bolder;">{{ scope.row.anchorName }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="anchorPlatformId" label="主播ID" minWidth="140"></el-table-column>
          <el-table-column prop="sampleInfo" label="样品信息" v-if="tabsActiveName=='touch'"
                           minWidth="140">
            <template slot-scope="scope">
              <span :style="{'color': (scope.row.sampleInfo == '未申请'|| scope.row.sampleInfo == '已驳回')?'red' :''}">
                {{ scope.row.sampleInfo }}</span>
              <a href="javascript: void(0);" style="color: #468dea;margin-left: 10px;"
                 @click="sampleDialogShow(scope.row)">
                <i class="el-icon-present">{{ scope.row.sampleInfo == '未申请' ? '申样' : '查看' }}</i>
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="lastLiveDate" label="最近上播日期" v-if="tabsActiveName=='coop'"
                           :formatter="dateFormatter4Table"
                           minWidth="140"></el-table-column>
          <el-table-column prop="abandonTime" label="放弃时间" v-if="tabsActiveName=='abandon'"
                           :formatter="datetimeFormatter4Table"
                           minWidth="140"></el-table-column>
          <el-table-column label="操作" width="280">
            <template slot-scope="scope">
              <div class="icon-operation">
                <a href="javascript: void(0);" title="编辑"
                   v-if="scope.row.anchorState=='touch'"
                   @click="editDialogShow(scope.row)">
                  <i class="el-icon-edit-outline">编辑</i>
                </a>
                <a href="javascript: void(0);" title="上播"
                   v-if="scope.row.anchorState=='coop'"
                   @click="liveDialogShow(scope.row)">
                  <i class="el-icon-video-camera">上播</i>
                </a>
                <a href="javascript: void(0);" title="合作"
                   v-if="scope.row.anchorState=='touch'"
                   class="a-disabled">
                  <i class="el-icon-lock">合作</i>
                </a>
                <a href="javascript: void(0);" title="放弃"
                   v-if="scope.row.anchorState=='touch'"
                   @click="giveUpDialogShow(scope.row)">
                  <i class="el-icon-delete">放弃</i>
                </a>
              </div>
            </template>
          </el-table-column>
        </ws-page-table>
      </div>
    </div>

    <!--放弃主播-->
    <el-dialog title="放弃主播" :visible.sync="giveUpDialogVisible" width="400px">
      <div style="line-height: 28px;" v-if="giveUpLimitTime!=null">
        <p>已于 {{ giveUpLastSampleTime }} 为该主播申样</p>
        <p>请积极沟通</p>
        <p>或于 {{ giveUpLimitTime }} 后操作放弃</p>
      </div>
      <div style="line-height: 28px;" v-else-if="giveUpLastSampleTime!=null">
        <p>已于 {{ giveUpLastSampleTime }} 为主播申样</p>
        <p>此时放弃：</p>
        <p>会将本条记录移入【<span style="color: #b70c39;">已放弃</span>】列表，
          <span style="color: red">占用</span>放弃名额</p>
      </div>
      <div style="line-height: 28px;" v-else>
        <p>由于尚未为该主播申样</p>
        <p>此时放弃：</p>
        <p>会将本条记录直接删除，<span style="color: red">不占用</span>放弃名额</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="giveUpDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="givUpDialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!--产品上播-->
    <el-dialog title="产品上播" :visible.sync="liveDialogVisible" width="860px">
      <el-button type="primary" @click="addLiveRecord">添加</el-button>
      <el-table :data="liveRecordList">
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="上播时间" prop="liveTime" min-width="200">
          <template slot-scope="scope">
            <el-date-picker
                :disabled="scope.row.anchorLiveId!=null"
                v-model="scope.row.liveTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" min-width="200">
          <template slot-scope="scope">
            <el-input
                :disabled="scope.row.anchorLiveId!=null"
                type="textarea" :rows="2" placeholder="请输入内容"
                v-model="scope.row.remark">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div style="font-size: 24px;">
              <a href="javascript: void(0);" title="删除"
                 v-if="scope.row.anchorLiveId"
                 @click="delLiveRecord(scope.$index)">
                <i class="el-icon-circle-close"></i>
              </a>
              <a href="javascript: void(0);" title="保存"
                 v-if="!scope.row.anchorLiveId"
                 @click="addLiveRecordConfirm">
                <i class="el-icon-circle-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--主播信息-->
    <el-dialog title="主播信息" :visible.sync="anchorDialogVisible" width="560px">
      <el-form label-width="80px">
        <el-form-item label="直播平台" required>
          <el-select v-model="anchorEditData.livePlatformCode"
                     :disabled="anchorEditData.anchorId!=null"
                     placeholder="请选择">
            <el-option
                v-for="item in livePlatformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主播名称" required>
          <el-input v-model="anchorEditData.anchorName"
                    :disabled="anchorEditData.anchorId!=null"
                    placeholder="名称"></el-input>
          <!--p style="color: red;">* 主播在平台的昵称，非主播真实姓名</p-->
        </el-form-item>
        <el-form-item label="主播ID">
          <el-input v-model="anchorEditData.anchorPlatformId"
                    :disabled="anchorPlatformIdDisabled"
                    :placeholder="anchorPlatformIdNick"></el-input>
          <!--p style="color: red;">* 主播在平台的ID，如抖音平台的UID</p-->
        </el-form-item>
        <el-form-item label="主播截图" required>
          <div style="display: flex;align-items: end;">
            <draggable-upload
                ref="screenshotUpload"
                :imgDel="anchorEditData.anchorId!=null"
                :width="82"
                :limit="1"
                :fileSize="2048 * 1024"
                fileSizeText="2M"
                :uploadUrl="uploadUrl()"
                :requestHeaders="requestHeaders"
                v-model="screenshotArr"
            ></draggable-upload>
            <span class="tips-btn" @click="$refs.examplePopup.dialogVisible = true">查看示例</span>
          </div>
          <p style="color: #8d8d8d;">* 主播在平台的主页截图，截图需体现主播名称</p>
        </el-form-item>
        <el-divider/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="anchorDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogConfirm">确 认</el-button>
      </div>
    </el-dialog>
    <!--样品记录-->
    <el-dialog title="样品记录" :visible.sync="sampleDialogVisible" width="900px">
      <div v-if="!sampleDialog4Create">
        <div style="display: flex;align-items: center;">
          <el-button type="primary" icon="el-icon-shopping-bag-1"
                     :disabled="trySample.refuse=='1'"
                     @click="createSampleDialogShow">申请
          </el-button>
          <p v-if="trySample.msg" style="color: red;margin-left: 10px;">* {{ trySample.msg }}</p>
        </div>
        <el-card class="box-card" v-for="(item,idx) in sampleRecordList" :key="idx">
          <div slot="header" class="clearfix">
            <span style="margin-right: 20px;">申请时间：{{ datetimeFormatter(item.createTime) }}</span>
            <span v-if="item.sampleAuditState=='0'" style="color: red;">样品单待审核</span>
            <span v-if="item.sampleAuditState=='1'" style="margin-right: 20px;">订单号：{{ item.orderCode }}</span>
            <span v-if="item.sampleAuditState=='1'" style="margin-right: 20px;">快递信息：{{ item.expressInfo }}</span>
            <span v-if="item.sampleAuditState=='1' && sampleTimer!=null">距样品发送已：{{ sampleTimer }}</span>
            <span v-if="item.sampleAuditState=='2'" style="color: red;">样品单审核不通过</span>
            <el-button
                @click="delSampleRecord(idx)"
                v-if="item.sampleAuditState=='0'"
                :disabled="item.sampleAuditState!='0'"
                style="float: right; padding: 3px 0" type="text">取消
            </el-button>
          </div>
          <div style="position: relative;">
            <p style="margin-bottom: 10px;">
              <span style="margin-right: 5px;">{{ item.recipientName }}</span>
              <span style="margin-right: 5px;">{{ item.recipientPhoneNum }}</span>
            </p>
            <p style="margin-bottom: 10px;">
              <span style="margin-right: 5px;">{{ item.recipientProvince }}</span>
              <span style="margin-right: 5px;">{{ item.recipientCity }}</span>
              <span style="margin-right: 5px;">{{ item.recipientCounty }}</span>
              <span>{{ item.recipientAddressDetail }}</span>
            </p>
            <div style="position:absolute;top: 0px;right: 0px;text-align: end;">
              <p style="margin-bottom: 10px;">{{ editAnchorData.anchorName }}</p>
              <p style="margin-bottom: 10px;">{{ editAnchorData.livePlatformName }}</p>
            </div>
          </div>
          <el-divider content-position="left">样品信息</el-divider>
          <div style="display: flex;margin-top: 20px;flex-wrap: wrap;">
            <div v-for="g in item.sampleGoodsList" style="display: flex;margin: 10px 10px 0 0;">
              <img class="goods_pic" :src="g.pictureUrl">
              <div style="display: flex;flex-direction:column;justify-content: start;">
                <p>{{ g.cnName }}</p>
                <p>{{ g.goodsCode }}</p>
                <div style="height: 100%;"></div>
                <p>x {{ g.num }}</p>
              </div>
            </div>
          </div>
          <el-divider content-position="left" v-if="item.remark">备注内容</el-divider>
          <span v-if="item.remark">{{ item.remark }}</span>
        </el-card>
      </div>
      <div v-else>
        <el-button icon="el-icon-arrow-left" style="margin-bottom: 20px;"
                   @click="sampleDialog4Create = false">返回
        </el-button>
        <el-form ref="sampleForm" :model="sampleCreateData" label-width="80px">
          <el-form-item label="收货人" required>
            <el-input v-model="sampleCreateData.recipientName" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" required>
            <el-input v-model="sampleCreateData.recipientPhoneNum"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" required>
            <area-cascader v-model="sampleCreateData.pcc"></area-cascader>
            <el-input
                style="margin-top: 10px;width: 100%;"
                v-model="sampleCreateData.recipientAddressDetail"
                placeholder="请填写详细地址"></el-input>
            <span style="color: red;">* 暂不支持海外地址寄样，如遇海外主播，麻烦您自行转寄</span>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="sampleCreateData.remark" maxlength="200"
                      placeholder="可填写备注"
                      style="width: 100%;"></el-input>
          </el-form-item>
          <el-form-item label="选择样品" required>
            <el-button size="small" plain type="primary"
                       @click="$refs.selectGoodsPopup.dialogVisible = true">添加样品
            </el-button>
            <el-table :data="sampleCreateData.goodsList" v-if="sampleCreateData.goodsList.length>0">
              <el-table-column label="样品" prop="cnName" :show-overflow-tooltip="true" min-width="200"/>
              <el-table-column label="编号" prop="goodsCode" width="150"/>
              <el-table-column label="数量" prop="num" width="200">
                <template slot-scope="scope">
                  <el-input-number
                      :min="1" :max="20"
                      size="small" v-model="scope.row.num"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="删除" width="100">
                <template slot-scope="scope">
                  <div>
                    <i @click="delSampleGoods(scope.$index)"
                       style="cursor: pointer;"
                       class="el-icon-circle-close"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" @click="addSampleRecord">确认申请</el-button>
        </div>
      </div>
    </el-dialog>

    <example-popup
        ref="examplePopup"
        title="查看示例"
        tips=""
        :imgUrl="require('ASSETS/images/example/ls-example-1.png')"
    ></example-popup>

    <select-goods-popup ref="selectGoodsPopup"
                        goodsOrderType="sample"
                        @submit="selectGoodsToSample"></select-goods-popup>
  </div>
</template>

<script>
import selectGoodsPopup from "PAGES/pim/product/spu/selectGoodsPopup";
import areaCascader from "COMPS/base/areaCascader.vue";
import userModule from "@/module/userModule";
import pageTableMixin from "@/mixin/pageTableMixin";
import lsAnchorService from "@/api/ls/LsAnchorService";
import examplePopup from "COMPS/widgets/examplePopup";
import draggableUpload from "COMPS/base/draggableUpload";
import IoProgressService from "API/IoProgressService";
import Objects from "COMJS/Objects";
import tools from "COMJS/tools";

export default {
  name: "LsAnchorList",
  mixins: [pageTableMixin],
  components: {
    draggableUpload,
    areaCascader,
    examplePopup,
    selectGoodsPopup
  },
  computed: {
    anchorPlatformIdNick() {
      return this.anchorEditData.livePlatformCode == 'DY' ? 'UID' : 'ID';
    }
  },
  watch: {
    /*'sampleCreateData.pcc': {
      deep: true,
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal)
      }
    }*/
  },
  data() {
    return {
      tabsActiveName: 'touch',
      //
      anchorDialogVisible: false,
      anchorEditData: {},
      livePlatformList: [{label: '抖音', value: 'DY'}, {label: '快手', value: 'KS'}, {label: '视频号', value: 'SPH'}],
      anchorPlatformIdDisabled: true,
      screenshotArr: [],
      //
      liveDialogVisible: false,
      liveRecordList: [],
      liveAddDate: null,
      liveAddRemark: '',
      //
      sampleDialogVisible: false,
      sampleDialog4Create: false,
      trySample: {},
      sampleRecordList: [],
      sampleTimer: null,
      sampleCreateData: {pcc: {province: "", city: "", area: ""}, goodsList: []},
      //
      giveUpDialogVisible: false,
      giveUpLastSampleTime: null,
      giveUpLimitTime: null,
      //
      groupData: {},
      groupId: '',
      currentUserId: JSON.parse(userModule.PPUser).userId,
      requestHeaders: {
        Authorization: userModule.token,
      },
      touchAnchorTotalCount: 0,
      editAnchorId: '',
      editAnchorData: {},
    }
  },
  mounted() {
    lsAnchorService.queryGroupList({bimUserId: this.currentUserId}).then(res => {
      this.groupData = res[0];
      this.groupId = this.groupData.groupId;
      this.loadTableData();
    })
    setInterval(this.timerToCountSample, 1000)
  },
  methods: {
    getCostTimeString: tools.getCostTimeString,
    //
    editDialogShow(row) {
      if (this.touchAnchorTotalCount >= this.groupData.anchorAbandonLimit) {
        const h = this.$createElement;
        this.$msgbox({
          title: '消息',
          message: h('div', null, [
            h('p', null, '已经触达申请样品额度的最大值，请完成达人合作或放弃达人，以释放新的样品额度。 '),
            h('p', {style: 'color: red'}, '注：达人一经放弃便不可重新建联!')
          ]),
          showCancelButton: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(action => {
          //confirm,cancel
        });
        return;
      }
      if (row) {
        this.anchorEditData = Objects.deepCopy({}, row)
        this.screenshotArr = [{response: row.anchorPlatformScreenshot}];
        this.editAnchorId = row.anchorId;
      } else {
        this.anchorEditData = {}
        this.screenshotArr = []
        this.editAnchorId = ''
      }
      this.anchorPlatformIdDisabled = !(!this.anchorEditData.anchorPlatformId);
      //展开
      this.anchorDialogVisible = true;
      this.$nextTick(() => {
        //需要手动清空一下，否则数量限制会有问题
        this.$refs.screenshotUpload.clearFiles();
      })
    },
    async editDialogConfirm() {
      if (Objects.isEmpty(this.anchorEditData.livePlatformCode)) {
        this.$message.error("请选择平台");
        return;
      }
      if (Objects.isEmpty(this.anchorEditData.anchorName)) {
        this.$message.error("请填写主播名称");
        return;
      }
      if (this.screenshotArr.length == 0) {
        this.$message.error("请上传截图");
        return;
      }
      this.anchorEditData.groupId = this.groupId
      let res = await lsAnchorService.checkLsAnchorBeforeSave(this.anchorEditData);
      const h = this.$createElement;
      this.$msgbox({
        title: '主播建联',
        message: h('div', null, [
          h('p', null, res.code != 'fail' ? '主播信息一经保存无法修改，请仔细检查' : ''),
          h('p', null, res.msg),
          h('p', null, res.code != 'fail' ? '继续保存？' : ''),
        ]),
        showCancelButton: res.code != 'fail',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {
        //confirm,cancel
        if (action == 'confirm' && res.code != 'fail') {
          this.editDialogConfirmOk();
        }
      });
    },
    editDialogConfirmOk() {
      let arr = this.livePlatformList.filter(e => this.anchorEditData.livePlatformCode == e.value)
      if (arr && arr.length > 0) {
        this.anchorEditData.livePlatformName = arr[0].label
      }
      this.anchorEditData.anchorPlatformScreenshot = this.screenshotArr[0].response
      this.anchorEditData.groupId = this.groupId
      lsAnchorService.saveLsAnchor(this.anchorEditData).then(() => {
        this.$message.success("保存成功");
        this.anchorDialogVisible = false;
        this.loadTableData();
      })
    },
    //
    liveDialogShow(row) {
      this.liveDialogVisible = true;
      this.editAnchorId = row.anchorId;
      this.loadLiveData();
    },
    addLiveRecord() {
      this.liveRecordList.push({});
    },
    async addLiveRecordConfirm() {
      let idx = this.liveRecordList.length - 1
      let item = this.liveRecordList[idx]
      if (!item.liveTime) {
        return this.$message.warning("请填写上播时间");
      }
      let param = {
        anchorId: this.editAnchorId,
        liveTime: item.liveTime,
        remark: item.remark,
      }
      let res = await lsAnchorService.saveLiveRecord(param)
      this.loadLiveData();
      this.loadTableData();
    },
    async delLiveRecord(idx) {
      let item = this.liveRecordList[idx]
      let param = {
        anchorLiveId: item.anchorLiveId
      }
      let res = await lsAnchorService.removeLiveRecord(param)
      this.loadLiveData();
      this.loadTableData();
    },
    loadLiveData() {
      lsAnchorService.queryLiveRecord(this.editAnchorId).then(res => {
        this.liveRecordList = res;
      })
    },
    //
    sampleDialogShow(row) {
      this.editAnchorId = row.anchorId;
      this.editAnchorData = row;
      this.sampleDialogVisible = true;
      this.sampleDialog4Create = false;
      this.loadSampleData();
    },
    createSampleDialogShow() {
      this.sampleDialog4Create = true;
      this.sampleCreateData = {pcc: {province: "", city: "", area: ""}, goodsList: []};
    },
    selectGoodsToSample(selected) {
      let temp = this.sampleCreateData.goodsList
      for (const ele of selected) {
        let item = {
          goodsId: ele.goodsId,
          goodsCode: ele.goodsCode,
          cnName: ele.cnName,
          pictureUrl: ele.pictureUrl,
          num: 0
        }
        temp.push(item)
      }
      this.sampleCreateData = {
        ...this.sampleCreateData,
        goodsList: temp
      }
    },
    delSampleGoods(idx) {
      let temp = this.sampleCreateData.goodsList
      temp.splice(idx, 1)
      this.sampleCreateData = {
        ...this.sampleCreateData,
        goodsList: temp
      }
    },
    async addSampleRecord() {
      if (!this.sampleCreateData.recipientName
          || !this.sampleCreateData.recipientPhoneNum
          || !this.sampleCreateData.pcc.province
          || !this.sampleCreateData.pcc.city
          || !this.sampleCreateData.pcc.area
          || !this.sampleCreateData.recipientAddressDetail) {
        return this.$message.warning("请完善收件信息");
      }
      if (this.sampleCreateData.goodsList.length == 0) {
        return this.$message.warning("样品不可为空");
      }
      let sampleGoodsList = []
      for (let idx in this.sampleCreateData.goodsList) {
        let item = this.sampleCreateData.goodsList[idx]
        sampleGoodsList.push({
          goodsId: item.goodsId,
          goodsCode: item.goodsCode,
          cnName: item.cnName,
          pictureUrl: item.pictureUrl,
          num: item.num
        })
      }
      let param = {
        anchorId: this.editAnchorId,
        recipientName: this.sampleCreateData.recipientName,
        recipientPhoneNum: this.sampleCreateData.recipientPhoneNum,
        recipientProvince: this.sampleCreateData.pcc.province,
        recipientCity: this.sampleCreateData.pcc.city,
        recipientCounty: this.sampleCreateData.pcc.area,
        recipientAddressDetail: this.sampleCreateData.recipientAddressDetail,
        remark: this.sampleCreateData.remark,
        sampleGoodsList: sampleGoodsList
      }
      let res = await lsAnchorService.applyAnchorSample(param)
      this.sampleDialog4Create = false;
      this.loadSampleData();
      this.loadTableData();
    },
    async delSampleRecord(idx) {
      let item = this.sampleRecordList[idx];
      let param = {
        anchorSampleId: item.anchorSampleId
      }
      let res = await lsAnchorService.removeSampleRecord(param)
      this.loadSampleData();
      this.loadTableData();
    },
    loadSampleData() {
      let param = {
        anchorId: this.editAnchorId,
        flagWithEI: "1",
        flagWithG: "1"
      }
      this.sampleRecordList = []
      lsAnchorService.querySampleRecord(param).then(res => {
        this.sampleRecordList = res;
      })
      this.trySample = {refuse: "1"}
      let tryParam = {
        ...param,
        tryGroupId: this.groupId
      }
      lsAnchorService.trySample(tryParam).then(res => {
        this.trySample = res;
      })
    },
    timerToCountSample() {
      //console.log("timerToCountSample")
      if (this.sampleRecordList.length > 0 && this.sampleDialogVisible) {
        let item = this.sampleRecordList[0]
        if (item.orderTransportTime != null) {
          let timer = this.getCostTimeString(new Date(item.orderTransportTime), new Date(), 4)
          this.sampleTimer = timer;
        } else {
          this.sampleTimer = null;
        }
      } else {
        this.sampleTimer = null;
      }
    },
    //
    giveUpDialogShow(row) {
      this.editAnchorId = row.anchorId;
      let param = {
        anchorId: this.editAnchorId
      }
      this.giveUpLastSampleTime = null;
      this.giveUpLimitTime = null;
      let loading = this.$loading({
        lock: true,
        text: "loading...",
      });
      lsAnchorService.querySampleRecord(param).then(res => {
        if (res.length > 0) {
          //取最后一个看看申请时间
          let last = res[res.length - 1]
          this.giveUpLastSampleTime = this.datetimeFormatter(last.createTime, "yyyy-MM-dd hh:mm:ss");
          //是否超过7天了
          let nowTime = new Date().getTime();
          let _7dAfterTime =
              last.createTime + (7 * 24 * 60 * 60 * 1000);
          if (nowTime < _7dAfterTime) {
            this.giveUpLimitTime = this.datetimeFormatter(_7dAfterTime, "yyyy-MM-dd hh:mm:ss");
          }
        }
        loading.close();
        this.giveUpDialogVisible = true;
      }).catch(() => {
        loading.close();
      })
    },
    async givUpDialogConfirm() {
      if (this.giveUpLimitTime != null) {
        return this.giveUpDialogVisible = false;
      }
      let res = await lsAnchorService.giveUpAnchor(this.editAnchorId);
      this.giveUpDialogVisible = false;
      this.loadTableData();
    },
    //
    changeTable() {
      this.loadTableData();
    },
    loadTableData() {
      if (!this.groupId) {
        return;
      }
      let param = {
        groupId: this.groupId,
        anchorState: this.tabsActiveName,
        pageNo:this.pageInfo.pageNo,
        pageSize:this.pageInfo.pageSize,
      }
      this.querySuccess({list: [], total: 0});
      lsAnchorService.pageAnchorByParam(param).then(res => {
        if (this.tabsActiveName == 'touch') {
          this.touchAnchorTotalCount = res.total
        }
        this.querySuccess(res);
      })
    },
    uploadUrl() {
      let url = IoProgressService.getUploadUrl("anchor");
      return url;
    },
    datetimeFormatter(time, fmt = "yyyy-MM-dd") {
      //return tools.formatDate(new Date(time), "yyyy-MM-dd hh:mm:ss");
      return tools.formatDate(new Date(time), fmt);
    },
    datetimeFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd hh:mm:ss");
      }
      return v;
    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style lang="scss" scoped>
.tips-content {
  display: flex;
  margin-bottom: 15px;

  > div {
    flex: 1;
  }

  p {
    display: flex;
    margin: 0;
    line-height: 24px;
  }

  .point {
    font-weight: bold;
    color: red;
  }
}

.box-card {
  margin: 10px;
}

.goods_pic {
  width: 50px;
  /*border: 1px solid rgba(218, 218, 218, 1);*/
}

.tips-btn {
  cursor: pointer;
  color: $themeColor;
  margin-left: 10px;
  height: 30px;
}
</style>