import axiosHttp from "../axiosHttp";

export default {
    // 参数分页查询

    queryParamPages(pageInfo, searchConfig) {
        let url = "/bimParam/pageByParam?"
        if (pageInfo) {
            url = url + pageInfo.buildQueryString();
        }
        url += "&" + searchConfig.buildQueryString();
        return axiosHttp.GET(url);
    },

    saveParam(BimParam) {
        BimParam.state = 1
        return axiosHttp.POST('/bimParam/create', BimParam)
    },
    listMapByParam(paramKeys) {
        return axiosHttp.GET(`/bimParam/listMapByParam?paramKeys=${paramKeys}`)
    },
    saveList(BimParamList) {
        return axiosHttp.POST('/bimParam/saveList', BimParamList)
    },
    // 获取参数详情
    getParamDetail(paramId) {
        return axiosHttp.GET(`/bimParam/getById/${paramId}`)
    },
    // 删除单个/批量参数
    deleteBimParams(checkBimParams) {
        let delIds;
        if (checkBimParams && checkBimParams.length > 0) {
            for (let i = 0; i < checkBimParams.length; i++) {
                if (delIds == null) {
                    delIds = checkBimParams[i].paramId;
                } else {
                    delIds += "," + checkBimParams[i].paramId;
                }
            }

            let opt = {'delIds': delIds};
            return axiosHttp.DELETE('/bimParam/delete', opt)
        }
    },
    uniqueValidate(BimParam) {
        BimParam.state = 1;
        return axiosHttp.POST('/bimParam/checkUnique', BimParam, false)
    }
}