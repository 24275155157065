import * as TYPES from './mutation-types'
import Vue from "vue"

export default {
    // 省市区
    saveAreaList(state, areaList) {
        state.areaList = areaList
    },
    // 菜单
    saveMenus(state, menus) {
        state.menus = menus
    },
    // 重置商城商品信息
    [TYPES.CLEAR_PRODUCT](state) {
        state.productAddInfo = {}
        state.productAttrList = []
        state.productImgs = []
    },
    // 保存商品基本信息
    saveProductAddInfo(state, productInfo) {
        state.productAddInfo = productInfo
    },
    // 保存商品属性列表
    saveProductAttrList(state, productAttrList) {
        state.productAttrList = productAttrList
    },
    // 保存商品图片
    saveProductImgs(state, productImgs) {
        state.productImgs = productImgs
    },
    // 保存实体商品信息
    savePhysicalGoods(state, physicalGoods) {
        state.physicalGoods = physicalGoods
    },
    // 重置实体商品信息
    [TYPES.CLEAR_PHYSICALGOODS](state) {
        Vue.set(state.physicalGoods, 'relatedProducts', [])
        Vue.set(state.physicalGoods, 'setting', {})
    },
    //保存商品关联库品
    saveProductRelateSku(state, productRelateSku) {
        state.productRelateInfo = productRelateSku;
    },
    // 保存实体商品关联商品
    savePhysicalGoods_relatedProducts(state, relatedProducts) {
        state.physicalGoods.relatedProducts = relatedProducts
    },
    // 保存实体商品设置
    savePhysicalGoods_setting(state, setting) {
        state.physicalGoods.setting = setting
    },
    // 保存虚拟商品信息
    saveVirtualGoods(state, virtualGoods) {
        state.virtualGoods = virtualGoods
    },
    // 重置虚拟商品信息
    [TYPES.CLEAR_VIRTUALGOODS](state) {
        Vue.set(state.virtualGoods, 'relatedProducts', [])
        Vue.set(state.virtualGoods, 'goodsInfo', {})
        Vue.set(state.virtualGoods, 'setting', {})
    },
    // 保存虚拟商品关联商品
    saveVirtualGoods_relatedProducts(state, relatedProducts) {
        state.virtualGoods.relatedProducts = relatedProducts
    },
    // 保存虚拟商品基本信息
    saveVirtualGoods_goodsInfo(state, goodsInfo) {
        state.virtualGoods.goodsInfo = goodsInfo
    },
    // 保存虚拟商品关联设置
    saveVirtualGoods_setting(state, setting) {
        state.virtualGoods.setting = setting
    },
    setPageNo(state, pageNo) {
        state.pageNo = pageNo
    },
    // 保存商品基本信息
    // saveProductAddInfo(state, productInfo) {
    //     state.productAddInfo = productInfo
    // },
    // 保存库品基本信息
    saveSkuAddInfo(state, skuInfo) {
        //console.log(skuInfo)
        state.skuInfo = skuInfo
    },
    /*使用方式
    this.$store.commit("saveSkuAddInfo", this.tableList);
    this.$store.state.skuInfo;
    */
    setCount(state, counts) {
        state.counts = counts
    },

    setSpecifyBackUrl(state, specifyBackUrl){
        state.specifyBackUrl = specifyBackUrl
    },

    setProductStepsData(state,ProductStepsData){
        state.ProductStepsData=ProductStepsData
    },
    setProductStepsData_Back(state,Back){
        state.ProductStepsData.Back=Back;
    }
}