<template>
  <div class="inventory-wrap">
    <el-form :inline="true" class="inventory-form">
      <el-form-item label="订单编号:">
        <el-input v-model="searchInfo.orderCode"></el-input>
      </el-form-item>
      <el-form-item label="流水时间:">
        <!-- <el-date-picker
          v-model="searchInfo.openTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
        <span class="mb15">至</span>
        <el-date-picker
          v-model="searchInfo.endTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker> -->
        <el-date-picker
            v-model="searchInfo.createTime"
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">搜索</el-button>
        <!--
        <el-button type="primary" @click="exportAmounts">导出</el-button>
        -->
      </el-form-item>
    </el-form>

    <ws-page-table
        :data="tableData"
        :tableRowClassName="tableRowClassName"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        class="inv-table"
    >
      <el-table-column
          prop="createTime"
          label="时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="skuCode"
          label="库品编码"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="skuName"
          label="库品名称"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="operNum"
          label="变动数量"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="lockNum"
          label="锁定库存"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="availableNum"
          label="可用库存"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="reserveNum"
          label="预留库存"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="actualNum"
          label="总库存"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="operationType"
          label="变动类型"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="orderCode"
          label="相关订单"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
    </ws-page-table>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import abnormalQuery from "API/whm/abnormalQuery";
import stockService from "API/whm/whmStockService";
import tools from "COMJS/tools";
import IoProgressService from "API/IoProgressService";

export default {
  name: "invAmounts",
  data() {
    return {
      searchInfo: {
        skuId: '',
        orderCode:'',
        createTime: []
      },
      items: {
        skuId: {filter: "eq", dataType: "str"},
        orderCode: {filter: "eq", dataType: "str"},
        createTime: {filter: "between", dataType: "date"},
      },
      searchCreateTime: "",
    };
  },
  mixins: [pageTableMixin],
  created() {
    let skuId = this.$route.query.skuId
    if (skuId) {
      this.searchInfo.skuId = skuId;
    }
  },
  mounted() {
    //this.loadTableData();
  },
  methods: {
    loadTableData() {
      if (this.searchInfo.createTime === null) {
        this.searchInfo.createTime = ""
      }
      //this.searchInfo.openTime = this.serchCreateTime ? tools.formatDate(new Date(this.serchCreateTime[0]), "yyyy-MM-dd") : "";
      //this.searchInfo.endTime = this.serchCreateTime ? tools.formatDate(new Date(this.serchCreateTime[1]), "yyyy-MM-dd") : "";

      this.$refs.pageTable.tableLoading = true;
      stockService.queryStockPage(this.pageInfo, this.searchConfig).then((data) => {
        //console.log(data)
        data.list = data.list.map((e) => {
          //console.log(e)
          return {
            createTime: tools.formatDate(
                new Date(e.createTime),
                "yyyy-MM-dd hh:mm:ss"
            ), //时间
            skuCode: e.skuCode, //库品编号
            skuName: e.skuName, //库品名称
            //purchaseBatchCode: e.purchaseBatchCode, //库品批次
            operNum: e.operNum, //变动数量
            actualNum: e.newActualNum, //现库存数量（修改后）
            lockNum: e.newLockNum,
            availableNum: e.newAvailableNum,
            reserveNum: e.newReservedNum,
            logState: e.logState,
            operationType:
                e.type == 0
                    ? "下单锁定库存"
                    : e.type == 1
                    ? "退货解锁（未出库）"
                    : e.type == 2
                        ? "发货解锁（已出库）"
                        : e.type == 3
                            ? "领用"
                            : e.type == 4
                                ? "退货（已出库）"
                                : e.type == 5
                                    ? "入库"
                                    : e.type == 20
                                        ? "取消发货"
                                    : e.type == 13
                                        ? "盘点修改"
                                        : e.type == 14
                                            ? "其他出入库"
                                            : e.type == 15
                                                ? "调整-扣减"
                                                : "", //变动类型
            orderCode: e.orderCode,
          };
        });
        this.querySuccess(data);
      });
    },
    tableRowClassName({row, rowIndex}) {
      if (row.logState == 'rollback') {
        return 'rollback-line';
      }
      return '';
    }
  },
};
</script>
<style>
.inv-table {
  margin-top: 20px;
}

.inventory-form {
  padding-left: 20px;
}

.inventory-wrap {
  padding: 0 0 40px 20px;
}

.mb15 {
  margin: 0 15px;
}
.rollback-line{
  text-decoration:line-through;
  text-decoration-color: red;
}
</style>