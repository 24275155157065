<template>
  <div>
    <el-descriptions title="详细信息" :column="int_5">
      <el-descriptions-item label="姓名">{{ cardInfo.recipientName }}</el-descriptions-item>
      <el-descriptions-item label="电话">{{ cardInfo.recipientPhoneNum }}</el-descriptions-item>
      <el-descriptions-item label="地址" :span="int_3">
        {{ cardInfo.recipientProvince }}
        {{ cardInfo.recipientCity }}
        {{ cardInfo.recipientCounty }}
        {{ cardInfo.recipientAddressDetail }}
      </el-descriptions-item>
      <el-descriptions-item label="总期数">{{ cardInfo.totalIssue }}</el-descriptions-item>
      <el-descriptions-item label="已使用">{{ cardInfo.currentIssue }}</el-descriptions-item>
      <el-descriptions-item label="开始时间">{{ formatTime(cardInfo.beginDate) }}</el-descriptions-item>
      <el-descriptions-item label="结束时间">{{ formatTime(cardInfo.endDate) }}</el-descriptions-item>
      <el-descriptions-item label="下单手机号">{{ customerUserAccount }}</el-descriptions-item>
    </el-descriptions>
    <div>
    <div style="font-size: 18px;margin: 20px 0px 10px 0px;display: flex;">
      <div style="width: 100px;">
        每期明细
      </div>
      <div style="width: 100%;"></div>
      <el-button size="mini" type="warning" @click="searchData">暂停奶卡</el-button>
      <el-button size="mini" type="warning" @click="searchData">延后几周</el-button>
      <el-button size="mini" type="warning" @click="searchData">修改地址</el-button>
    </div>
    <ws-page-table
        :data="tableData"
        ref="pageTable"
        :pageable="true"
        :pageInfo="pageInfo"
        @pageChange="loadTableData"
        :showIndex="false"
        :selection="'multi'"
    >
      <el-table-column label="期数" width="100">
        <template slot-scope="scope">
          <div>
            {{ scope.row.issueNum }} / {{ cardInfo.totalIssue }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="年/周" width="120">
        <template slot-scope="scope">
          <div>
            {{ scope.row.calendarYear }}年/{{ scope.row.calendarWeek }}周
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收件信息" minWidth="250">
        <template slot-scope="scope">
          <div style="line-height: 18px;">
            {{ scope.row.recipientName }} {{ scope.row.recipientPhoneNum }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientProvince }} {{ scope.row.recipientCity }} {{ scope.row.recipientCounty }}
          </div>
          <div style="line-height: 18px;">
            {{ scope.row.recipientAddressDetail }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品信息" minWidth="150">
        <template slot-scope="scope">
          <div v-if="scope.row.milkCount>0" style="line-height: 18px;">
            鲜奶 {{ scope.row.milkCount }}份
          </div>
          <div v-if="scope.row.giftBoxCount>0" style="line-height: 18px;">
            礼盒 {{ scope.row.giftBoxCount }}份
          </div>
          <div v-if="scope.row.giftCardCount>0" style="line-height: 18px;">
            礼品卡 {{ scope.row.giftCardCount }}份
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          {{
            scope.row.subscribeOrderReady == '0' ? '未生成'
                : scope.row.transportState == '0' ? '待发货'
                : '已发货'
          }}
        </template>
      </el-table-column>
      <el-table-column label="发货时间" width="120">
        <template slot-scope="scope">
          {{
            scope.row.transportState == '1' ? formatTime(scope.row.actualShippingDate)
                : formatTime(scope.row.expectShippingDate)
          }}
        </template>
      </el-table-column>
      <el-table-column label="快递信息" width="150">
        <template slot-scope="scope" v-if="scope.row.transportNo">
          {{ scope.row.transportNo }}
          ({{ scope.row.transportCompany }})
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <div class="icon-operation">
            <!--
            <a href="javascript: void(0);" title="手动生成"
               v-if="scope.row.transportState=='0'"
               @click="gotoOrderDetail(scope.row)">
              <i class="el-icon-edit-outline">手动生成</i>
            </a>
            -->
            <a href="javascript: void(0);" title="订单信息"
               v-if="scope.row.transportState=='1'"
               @click="gotoOrderDetail(scope.row)">
              <i class="el-icon-edit-outline">订单信息</i>
            </a>
            <a href="javascript: void(0);" title="售后信息"
               v-if="scope.row.transportState=='1'"
               @click="gotoOrderDetail(scope.row)">
              <i class="el-icon-edit-outline">售后信息</i>
            </a>
          </div>
        </template>
      </el-table-column>
    </ws-page-table>

    </div>
  </div>
</template>

<script>
import pageTableMixin from "@/mixin/pageTableMixin.js";
import freshMilkService from "API/fm/FreshMilkService";
import tools from "COMJS/tools";
import userInfoService from "API/bim/userInfoService";

export default {
  name: "FreshMilkCardList",
  mixins: [pageTableMixin],
  data() {
    return {
      int_5:5,
      int_3:3,
      milkCardId: '',
      cardInfo: {},
      customerUserAccount:'',
    }
  },
  mounted() {
    this.milkCardId = this.$route.params.milkCardId
    this.loadCardInfo();
    //this.loadTableData();
  },
  methods: {
    formatTime(date) {
      return tools.formatTime(date, "yyyy-MM-dd")
    },
    loadCardInfo() {
      let param = {
        milkCardId: this.milkCardId
      }
      freshMilkService.queryByParam(param).then(res => {
        this.cardInfo = res;
        this.getUserInfo(res.customerUserId);
        //翻页
        let pageNo = this.cardInfo.currentIssue/10 + 1
        this.pageInfo.pageNo = pageNo
        this.loadTableData()
      })
    },
    loadTableData() {
      if(!this.cardInfo.milkCardId){
        return;
      }
      let param = {
        milkCardId: this.milkCardId,
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
      }
      freshMilkService.pageMilkCardRecordByParam(param).then(res => {
        console.log(res)
        this.querySuccess(res);
      })
    },
    getUserInfo(userId){
      if(!userId){
        return
      }
      userInfoService.getUserInfo(userId).then(res=>{
        if(res){
          this.customerUserAccount = res.userAccount
        }
      })
    },
    gotoOrderDetail(row){

    },
    dateFormatter4Table(row, column) {
      let v = "";
      if (row[column.property]) {
        v = tools.formatDate(new Date(row[column.property]), "yyyy-MM-dd");
      }
      return v;
    },
  }
}
</script>

<style scoped>

</style>