<template>
  <div class="product-category whm-category">
    <div class="filter-form">
      <div class="filter_left">
        <el-button size="mini" type="primary" @click="showAdd">新增分类</el-button>
        <div class="search-wrapper">
          <input-search
            :resultList="searchResultList"
            @input="searchChange"
            @click="searchChange"
            @selectResult="selectResult"
          ></input-search>
          <el-button class="search-btn" size="mini" type="primary" @click="searchResult">搜索</el-button>
        </div>
      </div>
    </div>
    <level-tree
      ref="levelTree"
      :tree="firstCategoryData"
      firstTreeName="一级分类"
      secondTreeName="二级分类"
      thirdTreeName="三级分类"
      :canEdit="true"
      :canSee="true"
      :canDelete="true"
      @seeDetail="showDetail"
      @edit="showEditCategory"
      @deleteNode="deleteCategory"
      @changeNode="getTreeNode"
    ></level-tree>
    <category-add-popup
      ref="categoryAddPopup"
      :categoryId="currentId"
      @updateCategory="refreshCategory()"
    ></category-add-popup>
    <category-detail-popup ref="categoryDetailPopup" :categoryId="currentId"></category-detail-popup>
  </div>
</template>

<script>


import categoryAddPopup from "./categoryAddPopup.vue";
import categoryDetailPopup from "./categoryDetailPopup.vue";
// import PimCategoryService from "API/pim/PimCategoryService.js";
import levelTree from "COMPS/widgets/levelTree";
import inputSearch from "COMPS/base/inputSearch";
import WhmList from "API/whm/WhmList";

export default {
  name: "whmCategory",
  data() {
    return {
      currentNode: {},
      searchResultList: [],
      firstCategoryData: [],
      currentId: "", // 传递给popup的categoryId
      queryInfo: {
        categoryType: 1,
        categoryName: ""
      }
    };
  },
  components: {
    categoryAddPopup,
    categoryDetailPopup,
    levelTree,
    inputSearch
  },
  mounted() {
    this.getAllCategory();
  },
  methods: {
    searchChange(searchValue) {
      //console.log(searchValue)
      this.queryInfo.categoryName = searchValue;
      this.searchResult();
    },
    searchResult() {
      //console.log(this.queryInfo)
      if (!this.queryInfo.categoryName) {
        return;
      }
      // WhmList.queryCategory(this.queryInfo).then(data => {
      //   this.searchResultList = this.trnasCategorySearchResultList(data);
      // });
      WhmList.queryCategoryWhm({
        thingTypeName: this.queryInfo.categoryName
      }).then(data => {
        //console.log(data)
        this.searchResultList = this.trnasCategorySearchResultList(data);
      });
    },
    // 转换搜索结果字段
    trnasCategorySearchResultList(list) {
      //console.log(list)
      let data = [];
      list.forEach(firstNode => {
        if (firstNode.childs && firstNode.childs.length > 0) {
          firstNode.childs.forEach(secondNode => {
            if (secondNode.childs && secondNode.childs.length > 0) {
              secondNode.childs.forEach(thirdNode => {
                data.push({
                  firstId: firstNode.nodeId,
                  secondId: secondNode.nodeId,
                  thirdId: thirdNode.nodeId,
                  firstName: firstNode.nodeName,
                  secondName: secondNode.nodeName,
                  thirdName: thirdNode.nodeName,
                  name: `${firstNode.nodeName} > ${secondNode.nodeName} > ${thirdNode.nodeName}`,
                  nodeName: `${firstNode.nodeName} > ${secondNode.nodeName} > ${thirdNode.nodeName}`
                });
              });
            } else {
              data.push({
                firstId: firstNode.nodeId,
                secondId: secondNode.nodeId,
                thirdId: "",
                firstName: firstNode.nodeName,
                secondName: secondNode.nodeName,
                thirdName: "",
                name: `${firstNode.nodeName} > ${secondNode.nodeName}`,
                nodeName: `${firstNode.nodeName} > ${secondNode.nodeName}`
              });
            }
          });
        } else {
          data.push({
            firstId: firstNode.nodeId,
            secondId: "",
            thirdId: "",
            firstName: firstNode.nodeName,
            secondName: "",
            thirdName: "",
            name: firstNode.nodeName,
            nodeName: firstNode.nodeName
          });
        }
      });
      return data;
    },
    selectResult(item) {
      this.$refs["levelTree"].positioningResult(item);
      this.searchResultList = [];
    },
    refreshCategory() {
      this.$refs["levelTree"].clearData();
      this.currentId="";
      this.getAllCategory();
    },
    getAllCategory() {
      WhmList.queryCategoryWhm({
        thingTypeName: this.thingTypeName
      }).then(data => {
        //console.log(data)
        this.firstCategoryData = data.map(e => {
          e.id = e.nodeId;
          e.name = e.nodeName;
          return e;
        });
      });
    },
    getTreeNode(node) {
      this.currentNode = node;
    },
    deleteCategory(item) {
      this.$confirm(`是否删除"${item.name}"分类?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          WhmList.deleteCategory(item.id).then(() => {
            this.refreshCategory();
          });
        })
        .catch(() => {});
    },
    showAdd() {
      this.currentId = "";
      this.$refs.categoryAddPopup.dialogFormVisible = true;
    },
    showDetail(info) {
      this.currentId = info.id;
      this.$refs.categoryDetailPopup.dialogVisible = true;
    },
    showEditCategory(info) {
      this.currentId = info.id;
      this.$refs.categoryAddPopup.dialogFormVisible = true;
    }
  }
};
</script>
<style>
.whm-category .filter-form{ margin-bottom: 0; padding-bottom: 20px;}
</style>