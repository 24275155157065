<template>
  <el-dialog title="选择商品" :visible.sync="dialogVisible" width="1220px">
    <div class="select-brand-content">
      <div class="input-search-wrapper">
        <div class="search-wrapper">
          <input-search
            :resultList="searchResultList"
            @input="searchChange"
            @click="searchChange"
            @selectResult="selectResult"
          ></input-search>
          <el-button class="search-btn" size="mini" type="primary" @click="queryCategoryList">搜索</el-button>
        </div>
      </div>
      <level-tree
        ref="levelTree"
        :tree="firstCategoryData"
        firstTreeName="一级分类"
        secondTreeName="二级分类"
        thirdTreeName="三级分类"
        @changeAllNode="getAllNode"
      ></level-tree>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">下一步</el-button>
    </span>
  </el-dialog>
</template>

<script>
import PimCategoryService from "API/pim/PimCategoryService.js";
import levelTree from "COMPS/widgets/levelTree";
import tools from "COMJS/tools";
import inputSearch from "COMPS/base/inputSearch";

export default {
  name: "productCategoryList",
  data() {
    return {
      dialogVisible: false,
      submitParams: {},
      searchResultList: [],
      firstCategoryData: [],
      queryInfo: {
        categoryType: 1,
        categoryName: ""
      }
    };
  },
  components: {
    levelTree,
    inputSearch
  },
  mounted() {
    this.getAllCategory();
  },
  methods: {
    searchChange(searchValue) {
      this.queryInfo.categoryName = searchValue;
      this.queryCategoryList();
    },
    queryCategoryList() {
      if (!this.queryInfo.categoryName) {
        return;
      }
      PimCategoryService.queryCategory(this.queryInfo).then(data => {
        this.searchResultList = this.trnasCategorySearchResultList(data);
      });
    },
    // 转换搜索结果字段
    trnasCategorySearchResultList(list) {
      let data = [];
      list.forEach(firstNode => {
        if (firstNode.childs.length > 0) {
          firstNode.childs.forEach(secondNode => {
            if (secondNode.childs.length > 0) {
              secondNode.childs.forEach(thirdNode => {
                data.push({
                  firstId: firstNode.nodeId,
                  secondId: secondNode.nodeId,
                  thirdId: thirdNode.nodeId,
                  firstName: firstNode.nodeName,
                  secondName: secondNode.nodeName,
                  thirdName: thirdNode.nodeName,
                  name: `${firstNode.nodeName} > ${secondNode.nodeName} > ${thirdNode.nodeName}`,
                  nodeName: `${firstNode.nodeName} > ${secondNode.nodeName} > ${thirdNode.nodeName}`
                });
              });
            } else {
              data.push({
                firstId: firstNode.nodeId,
                secondId: secondNode.nodeId,
                thirdId: "",
                firstName: firstNode.nodeName,
                secondName: secondNode.nodeName,
                thirdName: "",
                name: `${firstNode.nodeName} > ${secondNode.nodeName}`,
                nodeName: `${firstNode.nodeName} > ${secondNode.nodeName}`
              });
            }
          });
        } else {
          data.push({
            firstId: firstNode.nodeId,
            secondId: "",
            thirdId: "",
            firstName: firstNode.nodeName,
            secondName: "",
            thirdName: "",
            name: firstNode.nodeName,
            nodeName: firstNode.nodeName
          });
        }
      });
      return data;
    },
    selectResult(item) {
      this.$refs["levelTree"].positioningResult(item);
      this.searchResultList = [];
    },
    getAllCategory() {
      PimCategoryService.getAllCategoryTree().then(data => {
        this.firstCategoryData = data.map(e => {
          e.id = e.nodeId;
          e.name = e.nodeName;
          return e;
        });
      });
    },
    getAllNode(obj) {
      let params = {
        selectedFirstCategory: obj.selectedFirstTree,
        selectedSecondCategory: obj.selectedSecondTree,
        selectedThirdCategory: obj.selectedThirdTree
      };
      this.submitParams = params;
    },
    submit() {
      this.$emit("submit", this.submitParams);
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/common/tool.scss";
/deep/ .el-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    border-top: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.input-search-wrapper {
  padding: 20px;
  border-bottom: 1px solid $borderColor;
  /deep/ .el-input {
    width: 500px;
  }
  .search-btn {
    margin-left: 20px;
  }
}
</style>