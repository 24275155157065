import axiosHttp from "../axiosHttp";

export default {
    queryPages(queryParams) {
        let url = "/pimGoodsSpu/queryPages"
        return axiosHttp.POST(url,queryParams);
    },
    // 详情
    getGoodsSpuDetail(spuId) {
        return axiosHttp.GET(`/pimGoodsSpu/fetchAll/${spuId}`)
    },
    savePimGoodsSpu(param) {
        return axiosHttp.POST(`/pimGoodsSpu/savePimGoodsSpu`,param)
    },
    updateExhibition(param) {
        return axiosHttp.POST(`/pimGoodsSpu/updateExhibition`,param)
    },
    //spu标签保存接口
    goodsSpuSaveTags(param){
        return axiosHttp.POST("",param)
    }
}