import axiosHttp from "../axiosHttp";

export const fetchList = data=>{
    return axiosHttp.POST('dimVirtualPackage/getPageVirtualPackageList',data,false)
}

export const fetchDetail = data=>{
    return axiosHttp.POST('dimVirtualPackage/getVirtualPackageByParam',data,false)
}

export const modifyUpdateMax = data=>{
    return axiosHttp.POST('dimVirtualPackage/modifyUpdateMax',data,false)
}

export const manualCreateOrderFromVirtualPackage = data=>{
    return axiosHttp.POST('dimVirtualPackage/manualCreateOrderFromVirtualPackage',data,false)
}

export const makeNormal = data=>{
    return axiosHttp.POST('dimVirtualPackage/makeNormal',data,false)
}

// 改变奶卡发货状态
export const changePackageState = data=>{
    return axiosHttp.POST('dimVirtualPackage/changePackageStateByVirtualPackageId',data,false)
}

export const adminUpdateYearCardAppointGoods = data=>{
    return axiosHttp.POST('dimVirtualPackage/adminUpdateYearCardAppointGoods',data,false)
}

export const adminSyncAppointGoodsToUnTransportOrder = data=>{
    return axiosHttp.POST('dimVirtualPackage/adminSyncAppointGoodsToUnTransportOrder',data,false)
}
export const getCancelCardData = data=> {
    return axiosHttp.POST('dimVirtualPackage/getCancelCardData', data, false)
}
export const cancelCardAndRefund = data=> {
    return axiosHttp.POST('dimVirtualPackage/cancelCardAndRefund', data, false)
}