<template>
  <div class="ws-home-container">
    <div class="ws-home_left">
      <div class="todo-matter box-shadow-unify">
        <div class="box-title">
          <span>未完成项</span>
        </div>
        <div class="matter-group">
          <div class="matter-item" :class="{'notclickn':isActive}" @click="$router.push(item.toUrl)"
               v-for="(item, index) in matterList" :key="index">
            <div class="num">{{ item.value || 0 }}</div>
            <div class="matter-text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="data-block-group">
        <div class="data-block-item box-shadow-unify" v-for="(item, index) in dataBlockList" :key="index">
          <div class="icon-wrapper">
            <img :src="item.iconUrl"/>
          </div>
          <div class="block-name">{{ item.name }}</div>
          <div class="block-num">
            <span v-if="item.isMonMy" class="rmb">￥</span>
            <span>{{ item.value || 0 }}</span>
          </div>
        </div>
      </div>
      <div class="update-item">更新时间：{{ gettime }}</div>
      <div class="shortcut-enter box-shadow-unify" v-if="isAdmin">
        <div class="box-title">快捷入口</div>
        <div class="shortcut-enter_group">
          <div class="shortcut-enter_item" @click="$router.push(item.toUrl)" v-for="(item, index) in quickEnterList"
               :key="index">
            <div class="shortcut-enter_icon">
              <img :src="item.iconUrl" alt/>
            </div>
            <div class="shortcut-enter_label">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="sys-log-table box-shadow-unify" v-if="isAdmin">
      <div class="box-title">
        <span>系统日志</span>
        <div class="tools">
          <span class="more" @click="$router.push('/ba/systemLog')">查看全部</span>
        </div>
      </div>
      <div class="log-content">
        <ws-time-line :timeLineList="logTableData"></ws-time-line>
      </div>
      <!-- <el-table :data="logTableData">
        <el-table-column prop="content" label="操作事件"></el-table-column>
        <el-table-column prop="name" label="账户名"></el-table-column>
        <el-table-column prop="time" label="操作时间"></el-table-column>
      </el-table>-->
    </div>

    <el-dialog custom-class="line-content" :title="noticeItem.title?noticeItem.title:'提示'"
               :visible.sync="centerDialogVisible" width="30%" :fullscreen="noticeItem.fullscreen" :show-close="false"
               :close-on-click-modal="false">
      <el-dialog width="30%" title="提示" :visible.sync="innerVisible1" append-to-body>
        <span>您需要同意本通知内容才能继续使用WDOM，若您不同意本通知内容，很遗憾我们将无法继续为您提供服务。</span>
        <div class="dialog-content">
          <el-button type="primary" @click="innerVisible1=false">查看通知</el-button>
          <el-button @click="innerVisible2=true,innerVisible1=false">仍不同意</el-button>
        </div>
      </el-dialog>
      <el-dialog width="30%" title="提示" :visible.sync="innerVisible2" append-to-body>
        <span>十分抱歉，若您不同意本通知内容，我们将无法继续为您提供服务。</span>
        <div class="dialog-content">
          <el-button type="primary" @click="innerVisible2=false">我再想想</el-button>
          <el-button @click="innerVisible2=false,innerVisible3=true">注销账户</el-button>
        </div>
      </el-dialog>
      <el-dialog width="30%" title="提示" :visible.sync="innerVisible3" append-to-body>
        <span>请打开App操作会员注销</span>
        <div class="dialog-content">
          <el-button type="primary" @click="exitLogin">退出登录</el-button>
        </div>
      </el-dialog>
      <span>{{ noticeItem.content }}</span>
      <span slot="footer" class="dialog-footer">
                <div class="dialog-content">
                    <el-button type="primary" :disabled="countdown!=1" @click="noticeItem.fullscreen?agree():confirm()">{{ noticeItem.fullscreen ? '同 意' : '确 认' }}<span
                        v-if="countdown!=1">({{ countdown }})</span>
                    </el-button>
                    <el-button @click="notAgree" v-if="noticeItem.fullscreen">不同意</el-button>
                </div>
                <el-checkbox class="checkbox" v-if="!noticeItem.fullscreen" v-model="checked">不再提醒</el-checkbox>
            </span>
    </el-dialog>

  </div>
</template>
<script>
import BimHomeData from "API/bim/BimHomeData";
import wsTimeLine from "COMPS/base/timeLine";
import Paging from "COMJS/query/Paging";
import tools from "COMJS/tools";
import chargeFormat from "COMJS/chargeFormat";
import userModule from "@/module/userModule";

export default {
  data() {
    return {
      countdown: 0,
      noticeItem: "",
      checked: false,
      timer: null,
      centerDialogVisible: false,
      innerVisible1: false,
      innerVisible2: false,
      innerVisible3: false,
      gettime: tools.formatDate(new Date(), "yyyy-MM-dd hh:mm:ss"), //当前时间
      isAdmin: false,
      isActive: true,
      logTableData: [],
      matterList: [],
      noticeArr: {
        content: "",
        button1: "查看通知",
        button2: "仍不同意",
      },
      matterListForA: [
        {
          name: "待付款",
          value: "",
          toUrl: {
            path: "/som/orderList",
            query: {orderState: "unPaid"},
          },
        },
        {
          name: "待发货",
          value: "",
          toUrl: {
            path: "/som/orderList",
            query: {orderState: "unShipped"},
          },
        },
        {
          name: "待收货",
          value: "",
          toUrl: {
            path: "/som/orderList",
            query: {orderState: "unReceived"},
          },
        },
        {
          name: "待售后",
          value: 0,
          toUrl: "/som/afterSale",
        },
        {
          name: "物流异常",
          value: 0,
          toUrl: "/som/orderList",
        },
        /*{
          name: "待评价",
          value: 0,
          toUrl: "/som/orderList",
        },*/
      ],
      matterListForD: [
        {
          name: "待付款",
          value: "",
          toUrl: {
            path: "/som/orderList",
            query: {orderState: "unPaid"},
          },
        },
        {
          name: "待发货",
          value: "",
          toUrl: {
            path: "/som/orderList",
            query: {orderState: "unShipped"},
          },
        },
        {
          name: "待收货",
          value: "",
          toUrl: {
            path: "/som/orderList",
            query: {orderState: "unReceived"},
          },
        },
        {
          name: "物流异常",
          value: 0,
          toUrl: "/som/orderList",
        },
      ],
      dataBlockList: [
        {
          name: "今日成交订单数",
          value: "",
          iconUrl: require("ASSETS/images/home/home_data_block_1.png"),
        },
        {
          name: "今日成交金额",
          value: "",
          isMonMy: true,
          iconUrl: require("ASSETS/images/home/home_data_block_2.png"),
        },
        {
          name: "今日成交客户数",
          value: "",
          iconUrl: require("ASSETS/images/home/home_data_block_3.png"),
        },
        {
          name: "今日客单价",
          value: "",
          isMonMy: true,
          iconUrl: require("ASSETS/images/home/home_data_block_4.png"),
        },
        {
          name: "今日新增浏览量",
          value: "",
          iconUrl: require("ASSETS/images/home/home_data_block_5.png"),
        },
      ],
      quickEnterList: [
        {
          name: "添加商品",
          iconUrl: require("ASSETS/images/home/quick_enter_1.png"),
          //toUrl: "/pim/productEdit",
          toUrl: "/pim/AddGoods?newGoods=true",
        },
        {
          name: "客服下单",
          iconUrl: require("ASSETS/images/home/quick_enter_2.png"),
          toUrl: "/som/manualOrder",
        },
        {
          name: "批量发货",
          iconUrl: require("ASSETS/images/home/quick_enter_3.png"),
          //toUrl: "/som/shipmentList",
          toUrl: "/whm/outList",
        },
        {
          name: "批量下单",
          iconUrl: require("ASSETS/images/home/quick_enter_4.png"),
          toUrl: "/som/orderBatchList",
        },
      ],
      pageInfo: Paging.createNew(),
    };
  },
  components: {
    wsTimeLine,
  },
  mounted() {
    BimHomeData.isPopup().then((data) => {
      if (data && data.length > 0) {
        this.noticeList = data;
        console.log(data);
        if (
            data.findIndex((target) => target.noticeType == "2") != -1
        ) {
          sessionStorage.setItem("homeNoticeState", true);
        } else {
          sessionStorage.setItem("homeNoticeState", false);
        }
        let notice = this.forceNoticePop(data);
        this.noticeItem = notice;
        if (this.noticeItem != "") {
          this.centerDialogVisible = true;
        }
        this.sendCode();
      }
    });
    const loginUser = JSON.parse(userModule.PPUser);
    this.isAdmin = false;
    this.isActive = true;
    this.matterList = this.matterListForD;
    if (loginUser && loginUser.role) {
      if (loginUser.role.indexOf("shop_admin") > -1) {
        //this.appMenus = this.adminMenus;
        this.isAdmin = true;
        this.isActive = false;
        this.matterList = this.matterListForA;
      }
    }
    this.getData();
  },
  methods: {
    exitLogin() {
      sessionStorage.removeItem("homeNoticeState");
      userModule.removeCookiesToken();
      userModule.removePPUser();
      this.$router.push("/login");
      this.$message({
        type: "success",
        message: "退出成功!",
      });
    },
    showTheNotice() {
      let notice = this.forceNoticePop(this.noticeList);
      if (notice) {
        this.noticeItem = notice;
        this.centerDialogVisible = true;
        this.sendCode();
      }
    },
    confirm() {
      if (this.checked == true) {
        this.confirmNoticePop(this.noticeItem.noticeId, 1);
      } else {
        this.confirmNoticePop(this.noticeItem.noticeId, 0);
      }
      this.centerDialogVisible = false;
      this.showTheNotice();
    },
    agree() {
      sessionStorage.removeItem("homeNoticeState");
      this.confirmNoticePop(this.noticeItem.noticeId, 1);
      this.centerDialogVisible = false;
      this.showTheNotice();
      if (
          this.noticeList.findIndex(
              (target) => target.noticeType == "2"
          ) != -1
      ) {
        sessionStorage.setItem("homeNoticeState", true);
      } else {
        sessionStorage.setItem("homeNoticeState", false);
      }
    },
    notAgree() {
      this.innerVisible1 = true;
    },
    forceNoticePop(noticeList) {
      if (noticeList && noticeList.length > 0) {
        let iterator = noticeList[0];
        if (noticeList[0].noticeType == "2") {
          iterator.fullscreen = true;
          this.countdown = 11;
          return iterator;
        } else {
          this.countdown = 6;
          return iterator;
        }
      }
    },
    confirmNoticePop(customNoticeId, flag) {
      console.log(customNoticeId, flag);
      let noticeList = this.noticeList;
      if (noticeList && noticeList.length > 0) {
        this.noticeList = noticeList.filter(
            (item) => item.noticeId != customNoticeId
        );
      }
      if (flag == 1) {
        BimHomeData.confirmNotice(customNoticeId);
      }
      // this.forceNoticePop(this.noticeList);
    },
    sendCode() {
      this.loading(); //启动定时器
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countdown === 1) {
          this.clearTimer(); //关闭定时器
        } else {
          this.loading();
        }
      }, 1000);
    },
    loading() {
      //启动定时器
      this.countdown--; //定时器减1
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer);
      this.timer = null;
    },
    getData() {
      let pageInfo = this.pageInfo;
      pageInfo.pageSize = 13;
      BimHomeData.getHomeData(pageInfo).then((data) => {
        if (data && data.userLogList) {
          this.logTableData = data.userLogList.map((e) => {
            return {
              id: e.userLogId,
              time: tools.formatDate(
                  new Date(e.createTime),
                  "MM月dd日 hh:mm"
              ),
              events: e.operationDescribe,
            };
          });
        }

        //console.log(this.logTableData);
        this.dataBlockList.forEach((e) => {
          switch (e.name) {
            case "今日成交订单数":
              e.value = data.orderCensusData.todayCount;
              break;
            case "今日成交金额":
              e.value = chargeFormat.thousandsOf(
                  data.orderCensusData.todayAmount
              );
              break;
            case "今日成交客户数":
              e.value = data.orderCensusData.todayCustomer;
              break;
            case "今日客单价":
              e.value = chargeFormat.thousandsOf(
                  data.orderCensusData.unitPrice
              );
              break;
            case "今日新增浏览量":
              e.value = data.newViews;
              break;
          }
        });
        this.matterList.forEach((e) => {
          switch (e.name) {
            case "待付款":
              e.value = data.orderCensusData.unpaidCount;
              break;
            case "待发货":
              e.value = data.orderCensusData.unShippedCount;
              break;
            case "待售后":
              e.value = data.orderCensusData.afterSaleCount;
              break;
            case "待评价":
              e.value = data.orderCensusData.unEvaluatedCount;
              break;
            case "物流异常":
              e.value = data.errorTransportCount;
              break;
            case "待收货":
              e.value = data.unReceivedCount;
              break;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~ASSETS/css/common/tool.scss";

.ws-home-container {
  display: flex;
  min-width: 1200px;

  .box-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecef;

    .more {
      cursor: pointer;
      font-size: 14px;
      color: #999;
      font-weight: normal;
    }
  }

  .ws-home_left {
    flex: 1;

    .todo-matter {
      background-color: #fff;

      .matter-group {
        display: flex;
        width: 100%;

        .matter-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 110px;

          &:hover {
            background-color: #fbfbfb;

            .matter-text {
              color: #1b73e8;
            }
          }

          .num {
            color: #1b73e8;
            font-size: 26px;
          }

          .matter-text {
            color: #666;
            margin-top: 10px;
          }
        }
      }
    }

    .data-block-group {
      display: flex;
      // display: grid;
      // width: calc(100% - 160px);
      // grid-column-gap: 40px;
      // grid-template-columns: repeat(5, 20%);
      margin-top: 40px;

      .data-block-item {
        flex: 1;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        padding: 12px 5px;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        height: 146px;

        > div {
          width: 100%;
        }

        &:last-child {
          margin-right: 0px;
        }

        .icon-wrapper {
          > img {
            width: 62px;
            height: 62px;
          }
        }

        .block-name {
          color: #666;
        }

        .block-num {
          // @include Mult-line(1);
          margin-top: 10px;
          font-size: 28px;
          font-weight: bold;

          .rmb {
            font-size: 16px;
            color: #666;
          }
        }
      }
    }

    .update-item {
      margin: 20px 0;
      font-size: 14px;
      color: #666;
    }

    .shortcut-enter_group {
      display: flex;

      .shortcut-enter_item {
        cursor: pointer;
        padding: 20px 40px 24px 40px;
        text-align: center;

        &:hover {
          background-color: #fbfbfb;
        }

        .shortcut-enter_icon {
          > img {
            width: 74px;
            height: 74px;
          }
        }

        .shortcut-enter_label {
          margin-top: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .sys-log-table {
    flex: 0 0 340px;
    margin-left: 40px;
    background-color: #fff;

    .log-content {
      padding: 25px 20px;
    }
  }
}

.notclickn {
  pointer-events: none;
}

.dialog-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-top: 20px;
}

.line-content {
  white-space: pre-wrap;
}
</style>
