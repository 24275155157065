import sysConstant from "./SysConstant";

export default {
  isDistributor(role) {
    return !(!role) && role.indexOf(sysConstant.ROLE_CODE_DISTRIBUTOR) > -1;
  },
  isAgentCredit(role) {
    return !(!role) && role.indexOf(sysConstant.ROLE_CODE_AGENT__CREDIT) > -1;
  },
  isAgentDeposit(role) {
    return !(!role) && role.indexOf(sysConstant.ROLE_CODE_AGENT__DEPOSIT) > -1;
  },
  isS2ep(role) {
    return !(!role) && role.indexOf(sysConstant.ROLE_CODE_S2EP) > -1;
  },
}